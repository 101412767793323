import React, {useState, useEffect} from 'react'
import { useTranslation } from "react-i18next";
import {GetAlertsComponent, setObjectByPath, getConstantTextFields, validateConstantValues, translateType,truncateDecimals} from "./utils"
import TextField from "@material-ui/core/TextField";
import NumberFormat from 'react-number-format';
import _ from "lodash"
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
//MANIPULAÇÃO DE TEXTO - Dividir Texto


export function StringSplitBox (props) {
    const { t } = useTranslation();
    let {node} = props
    let {read_only} = props
    let {handleConfigurationOpen} = props
    let {handleNodeRemove} = props

    return (
        <div>
            {!read_only ?    
                <div style={{display:"flex", alignItems: "center"}}>
                    <div 
                        style={{display:"flex", width:"100%", cursor:"pointer", margin: "0px"}} 
                        onClick={() => handleConfigurationOpen(node)}
                    >
                        <div style={{display: "flex",flexDirection: "column",margin: "auto 0px"}}>
                            <div className={["normalText","normalMediumSize"].join(" ")}>
                                {t("Dividir em")}'{t(node.node.properties.to_split)}', {t("coletar índice")} {t(truncateDecimals(node.node.properties.get_index,0))}
                            </div>
                            <div className={["normalText","normalSmallSize"].join(" ")}>
                            {
                                node.node.properties.max_split ? 
                                truncateDecimals(node.node.properties.max_split,0) === 1 ?
                                t("Máximo de ") + t(truncateDecimals(node.node.properties.max_split,0)) + t(" divisão") :
                                t("Máximo de ") + t(truncateDecimals(node.node.properties.max_split,0)) + t(" divisões") :
                                t("Dividir em todas as ocorrências")
                            }
                            </div>
                        </div>
                        {(node.node.alerts || []).length > 0?
                        <GetAlertsComponent alerts={node.node.alerts} />  : null}       
                    </div>
                    <IconButton style={{width: 32, height: 32}} aria-label="duplicate" onClick={() => props.handleNodeDuplication(node)}>
                        <ContentCopyIcon style={{width: 20, height: 20}} />
                    </IconButton>
                    <IconButton style={{width: 32, height: 32}} aria-label="close" onClick={() => handleNodeRemove(node)}>
                        <CloseIcon style={{width: 20, height: 20}} />
                    </IconButton>
                </div>
            :
                <div style={{display:"flex"}}>
                    <div 
                        style={{display:"flex", width:"100%", cursor:"pointer", margin: "0px"}} 
                        onClick={() => handleConfigurationOpen(node)}
                    >
                        <div style={{display: "flex",flexDirection: "column",margin: "auto 0px"}}>
                            <div className={["normalText","normalMediumSize"].join(" ")}>
                                {t("Dividir em")}'{t(node.node.properties.to_split)}', {t("coletar índice")} {t(truncateDecimals(node.node.properties.get_index,0))}
                            </div>
                            <div className={["normalText","normalSmallSize"].join(" ")}>
                            {
                                node.node.properties.max_split ? 
                                truncateDecimals(node.node.properties.max_split,0) === 1 ?
                                t("Máximo de ") + t(truncateDecimals(node.node.properties.max_split,0)) + t(" divisão") :
                                t("Máximo de ") + t(truncateDecimals(node.node.properties.max_split,0)) + t(" divisões") :
                                t("Dividir em todas as ocorrências")
                            }
                            </div>
                        </div>   
                    </div>
                </div>
            }
        </div>
    )
}

export function StringSplitConfiguration(props){
    const { t } = useTranslation();
    let {handleConfigurationClose} = props
    let {handleNodeChange} = props
    let {node} = props
    let {read_only} = props

    const [nodeState, setNodeState] = useState(node)

    useEffect(()=>{
        setNodeState(node)
    },[node])

    const handleNodeStateChange = (obj_key,value) => {
        let new_node = _.cloneDeep(nodeState)
        if (value === undefined) value = null
        setObjectByPath(new_node.node,obj_key,value)
        setNodeState(new_node)
    }

    const handleSave = () => {
        handleConfigurationClose()
        handleNodeChange(nodeState)
    }

    const validateAllValues = () => {
        let to_split_validation = validateConstantValues("string",nodeState.node.properties.to_split)
        let get_index_validation = validateConstantValues("number",nodeState.node.properties.get_index)
        let max_split_validation = true
        if (nodeState.node.properties.max_split){
            max_split_validation = validateConstantValues("number",nodeState.node.properties.max_split)
        }
        return to_split_validation && get_index_validation && max_split_validation
    }


    return(
        <div style={{flexGrow:"1", padding: "20px", display:"flex", flexDirection:"column"}}>
            <div style={{display: "flex"}}>
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>{t(node.node.description)}</span>
                <Tooltip title={<div className="tooltipText"><ul>{node.node.helperText.map((help,index) => (<li key={index}>{t(help)}</li>))}</ul></div>}>
                    <HelpIcon style={{fontSize: "14px", display:"flex", margin:"auto 5px"}}/>
                </Tooltip>
            </div>
            <div className="internalCardContainer" style={{display:"flex", flexDirection: "column"}}>
                <TextField
                    label={t("Dividir em")}
                    style={{textAlign:"center", width:"80%", margin:"3px auto"}}                
                    error={!validateConstantValues("string",nodeState.node.properties.to_split)}
                    {...getConstantTextFields("string").props}
                    value={nodeState.node.properties.to_split}
                    onChange={(e) => handleNodeStateChange("properties.to_split", e.target.value)}
                    InputProps={{
                        readOnly: read_only
                      }}
                />
               <NumberFormat
                    label={t("Coletar índice")}
                    style={{textAlign:"center", width:"80%", margin:"3px auto"}}
                    error={!validateConstantValues("number",nodeState.node.properties.get_index)}
                    {...getConstantTextFields("number").props}
                    value={nodeState.node.properties.get_index}
                    onValueChange={(e) => handleNodeStateChange("properties.get_index", e.floatValue)}
                    InputProps={{
                        readOnly: read_only
                      }}  
                />      
                <NumberFormat
                    label={t("Máximo de Divisões")}
                    style={{textAlign:"center", width:"80%", margin:"3px auto"}}
                    {...getConstantTextFields("number").props}
                    value={nodeState.node.properties.max_split}
                    onValueChange={(e) => handleNodeStateChange("properties.max_split", e.floatValue)}
                    InputProps={{
                        readOnly: read_only
                      }}  
                />      
            </div>
            <div style={{display:"flex", margin: "auto auto 5px auto"}}>
                <div
                    className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")}
                    style={{width:"120px"}}
                    onClick={handleConfigurationClose}
                >
                    {read_only ? "X" : t("discard")}
                </div>
                {!read_only && 
                <div
                    className={
                        validateAllValues() 
                        ?
                        ["button", "standard", "normalText", "normalMediumSize"].join(" ")
                        :
                        ["button", "standard", "normalText", "normalMediumSize","disabled"].join(" ")}
                    style={{width:"120px"}}
                    onClick={validateAllValues() ? handleSave : null}
                >
                    {t("save")}
                </div>}   
            </div>
        </div>
    )
}

export class StringSplitBoxClass {
    constructor(rulesContext){
        this.rulesContext = rulesContext
    }
    validate(node){
        let put_error = false
        let alerts = []
        //Validate if node has at least one children
        if((node.node.children || []).length !== 1){
            put_error = true
            alerts.push("Este nó precisa ter apenas um filho")
        }
        //Validate if children of the node is string
        else{
            let childrenType = node.node.children[0].instance.getType(node.node.children[0])
            if (childrenType !== "string"){
                put_error = true
                alerts.push("O filho deste nó deve ter um retorno 'Texto'. Atualmente é " + translateType(childrenType))
            }
        }
        if (!validateConstantValues("string",node.node.properties.to_split)){
            put_error = true
            alerts.push("A propriedade 'Dividir em' deve ser um 'Texto' válido")
        }
        if (!validateConstantValues("number",node.node.properties.get_index)){
            put_error = true
            alerts.push("A propriedade 'Coletar índice' deve ser um 'Número' válido")
        }
        if (node.node.properties.max_split && !validateConstantValues("number",node.node.properties.max_split)){
            put_error = true
            alerts.push("A propriedade 'Máximo de Divisões' deve ser um 'Número' válido ou nulo")
        }
        //Validate if parent is valid
        if (node.parentNode != null) {
            if(node.parentNode.on_error){
                put_error = true
                alerts.push("O nó superior está inválida")
            }
        }
        return {validated: !put_error, alerts: alerts}
    }

    getType(node){
        return "string"
    }
}