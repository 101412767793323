import React from "react";
import { formatScore } from "../../utils/utils";

function ScoreBox(props) {

    if (props.fraud_status_events == null || props.fraud_status_events.length === 0 || 
        props.fraud_status_events[0].decision_metadata == null || props.fraud_status_events[0].decision_metadata.score == null) {
		return (
            <div className="analysisCard" style={{ display: "flex", flexDirection: "column"}}>
                <div className={["blueText", "subtitleSize"].join(" ")}>Score</div>
                <div style={{display: 'flex', flexDirection:"row", margin:"auto"}}>
                    <div  className={["labelText", "subtitleSize"].join(" ")}>
                        Informação Indisponível
                    </div>
                </div>
            </div>
		);    
	}

    else {
        let scoreObject = formatScore(props.fraud_status_events[0].decision_metadata.score)
		return (
            <div className="analysisCard" style={{ display: "flex", flexDirection: "column"}}>
                <div className={["blueText", "subtitleSize"].join(" ")}>Score</div>
                <div style={{display: 'flex', flexDirection:"row", margin:"auto", alignItems:"center"}}>
                    <div
                        className={"normalText titleSize"} 
                        style={{backgroundColor: scoreObject.color, margin: "5px", padding:"5px", borderRadius:"4px"}}
                    >
                        <span className="bold">{props.fraud_status_events[0].decision_metadata.score}</span>
                    </div>
                    <div
                        className={"labelText subtitleSize"} 
                        style={{margin: "5px 0px", padding:"5px"}}
                    >
                        <span style={{marginLeft:"5px"}}>{scoreObject.text}</span>
                    </div>
                </div>
            </div>
		);
    }
}

export default ScoreBox;