import { Collapse, IconButton } from "@material-ui/core"
import React, { useEffect } from "react"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { getCardIcon } from "../../utils/utils"
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import { CCFTable } from "./CCFTable"


export const CCFCard = ({ ccf_data, isPDF }) => {

    const { t } = useTranslation()

    const [expanded, setExpanded] = useState(false)
    const [firstTime, setFirstTime] = useState(true)
    let initialExpand = true

    if (initialExpand !== expanded && firstTime === true) {
        setExpanded(initialExpand)
    }

    const handleExpand = (event) => {
        event.stopPropagation()
        setFirstTime(false)
        setExpanded(!expanded)
    }

    useEffect(()=>{
        if(isPDF){
            setExpanded(true)
        }
    }, [isPDF])

    return (
        <div className="avoidPageBreakInside analysisCard">
            <div style={{ display: "flex" }}>
                <div
                    className={["blueText", "subtitleSize", "bold"].join(" ")}
                    style={{ margin: "auto 0px" }}
                >
                    {t("Cheques Sem Fundo - CCF")}
                </div>
                <div style={{ margin: "auto 10px" }}>
                    {getCardIcon(ccf_data.has_ccf_annotations)}
                </div>
                {ccf_data?.details?.length > 0 &&
                    <IconButton style={{ marginLeft: "auto" }} onClick={handleExpand}>
                        {expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </IconButton>
                }
            </div>
            {ccf_data.details.length > 0 &&
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <div
                        className={["normalText", "normalMediumSize", "internalCardContainer"].join(" ")}
                    >
                        <CCFTable ccf_annotations={ccf_data.details} />
                    </div>
                </Collapse>
            }
        </div>
    )
}
