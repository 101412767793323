import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import React from "react";
import { CircularProgress } from '@material-ui/core';
import {singleOrPluralNrOfRentals, removeArray, validateNest} from "../../../utils/utils"

const greenIcon = new L.Icon({
  iconUrl: require("../../../../../assets/markerIcons/marker-icon-2x-green.png"),
  shadowUrl: require("../../../../../assets/markerIcons/marker-shadow.png"),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const redIcon = new L.Icon({
  iconUrl: require("../../../../../assets/markerIcons/marker-icon-2x-red.png"),
  shadowUrl: require("../../../../../assets/markerIcons/marker-shadow.png"),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const goldIcon = new L.Icon({
  iconUrl: require("../../../../../assets/markerIcons/marker-icon-2x-gold.png"),
  shadowUrl: require("../../../../../assets/markerIcons/marker-shadow.png"),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

function StoresMap(props) {

    if (props.pastRentals === null) {
        return (
            <CircularProgress style={{margin:"auto"}}/>
        )
    }

    let validatedPastRentals = validateNest(props.pastRentals, "remove", "info", "address", "coordinates", "latitude")
    validatedPastRentals = validateNest(validatedPastRentals, "remove", "info", "address", "coordinates", "longitude")
    let validatedStoresInfo = validateNest(props.storeInfo, null, "address", "coordinates", "latitude")
    validatedStoresInfo = validateNest(validatedStoresInfo, null, "address", "coordinates", "longitude")
    validatedStoresInfo = validateNest(validatedStoresInfo, null, "code")

    let isRentalEqualDev = false
    let rental_markers = null;
    const isRentalValid = validatedStoresInfo.rental_store == null ? false : true
    const isDevolutionValid = validatedStoresInfo.devolution_store == null ? false : true
    if (isRentalValid && isDevolutionValid){
        isRentalEqualDev = validatedStoresInfo.rental_store.code === validatedStoresInfo.devolution_store.code;
    }
    else {
        isRentalEqualDev = false
    }

  if (validatedPastRentals === undefined) {
    rental_markers = (
      <React.Fragment>
        {isRentalValid ? 
          <RentalMarker
            equalbool={isRentalEqualDev}
            inrentalbool={false}
            coordinates={validatedStoresInfo.rental_store.address.coordinates}
            code={validatedStoresInfo.rental_store.code}
          /> : null}
        {isRentalEqualDev ? null : 
          isDevolutionValid ? 
            <DevolutionMarker
              inrentalbool={false}
              coordinates={validatedStoresInfo.devolution_store.address.coordinates}
              code={validatedStoresInfo.devolution_store.code}
            /> : null
        }
      </React.Fragment>
    );
  } 
  else {
    let isRentalInPast = false
    if (isRentalValid){
      isRentalInPast = Object.keys(validatedPastRentals).includes(
        validatedStoresInfo.rental_store.code)
    }
    else {
      isRentalInPast =false
    }
    let isDevolutionInPast = false
    if (isDevolutionValid){
      isDevolutionInPast = Object.keys(validatedPastRentals).includes(
        validatedStoresInfo.devolution_store.code)
    }
    else {
      isDevolutionInPast =false
    }
    rental_markers = (
      <div>
        {isRentalValid ? 
          <RentalMarker
            equalbool={isRentalEqualDev}
            inrentalbool={isRentalInPast}
            coordinates={validatedStoresInfo.rental_store.address.coordinates}
            code={validatedStoresInfo.rental_store.code}
            rentalInPast={validatedPastRentals[validatedStoresInfo.rental_store.code]}
          /> : null}
        {isRentalEqualDev ? null : 
          isDevolutionValid ? 
            <DevolutionMarker
              inrentalbool={isDevolutionInPast}
              coordinates={validatedStoresInfo.devolution_store.address.coordinates}
              code={validatedStoresInfo.devolution_store.code}
              rentalInPast={validatedPastRentals[validatedStoresInfo.devolution_store.code]}
            /> : null
        }
        <PastRentalsMarker
          storeInfo={validatedStoresInfo}
          pastRentals={validatedPastRentals}
        />
      </div>
    );
  }

  return (
    <Map
        className="crStore"
        center={validatedStoresInfo.rental_store == null ? [0,0]:
        [
          validatedStoresInfo.rental_store.address.coordinates.latitude,
          validatedStoresInfo.rental_store.address.coordinates.longitude,
        ]
      }
      zoom={validatedStoresInfo.rental_store == null ? 1 : 12}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      {rental_markers}
    </Map>
  );
}

export default StoresMap;

function RentalMarker(props) {
  let is_devolution_store_string = props.equalbool
    ? "Loja de Retirada e Devolução"
    : "Loja de Retirada";

  return (
    <Marker
      position={[props.coordinates.latitude, props.coordinates.longitude]}
      icon={greenIcon}
    >
      <Popup>
        {props.code} <br /> {is_devolution_store_string} <br />{" "}
        {props.inrentalbool ? singleOrPluralNrOfRentals(props.rentalInPast.value) : null}
      </Popup>
    </Marker>
  );
}

function DevolutionMarker(props) {
  return (
    <Marker
      position={[props.coordinates.latitude, props.coordinates.longitude]}
      icon={redIcon}
    >
      <Popup>
        {props.code} <br /> Loja de Devolução <br />{" "}
        {props.inrentalbool ? singleOrPluralNrOfRentals(props.rentalInPast.value)
          : null}
      </Popup>
    </Marker>
  );
}

function PastRentalsMarker(props) {
  let listOfPastRentals = Object.keys(props.pastRentals);
  const isRentalValid = props.storeInfo.rental_store == null ? false : true
  const isDevolutionValid = props.storeInfo.devolution_store == null ? false : true
  const removeRental = isRentalValid ? props.storeInfo.rental_store.code : ""
  const removeDevolution = isDevolutionValid ? props.storeInfo.devolution_store.code : ""
  let removeRentalDevolution = removeArray(
    [...listOfPastRentals],
    removeRental,
    removeDevolution
  );

  return (
    <div>
      {removeRentalDevolution.map((pastRental, index) => (
        <Marker
          key={index}
          position={[
            props.pastRentals[pastRental].info.address.coordinates.latitude,
            props.pastRentals[pastRental].info.address.coordinates.longitude,
          ]}
          icon={goldIcon}
        >
          <Popup>
            {pastRental} <br />{" "}
            {singleOrPluralNrOfRentals(props.pastRentals[pastRental].value)}
          </Popup>
        </Marker>
      ))}
    </div>
  );
}
