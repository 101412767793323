import React, { useState, useContext } from "react";
import AuthContext from "../../../context/auth-context";
import axios from "axios";
import Switch from "@material-ui/core/Switch";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import ButtonDescription from "../../@GeneralComponents/ButtonDescription";
import PermissionIcon from "@material-ui/icons/PermIdentity";
import EmailIcon from "@material-ui/icons/Email";


const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#0b1f82",
    },
  },
});

function UserCard(props) {

  let user_data = useContext(AuthContext).user_data;
  const { t } = useTranslation();

  const [disableAll, setDisableAll] = useState(false);
  const [isActive, setIsActive] = useState(props.user.enabled);

  const handleRequestFinish = (severity, message) => {
    props.setSnackBarStatus({
      opened: true,
      severity: severity,
      message: message,
    });
    props.onUpdate(
      user_data.business_group_key
        ? props.user.company_key
          ? { company_key: props.user.company_key }
          : null
        : null
    );
    setDisableAll(false);
  };

  const selectButtonBackgroundColor = (hovering, active) => {
    if (!active || !hovering) return "#fff";
    return "#F3F3F3"
  }

  const handleStatusChange = (checked, user) => {
    if (disableAll) return;
    setDisableAll(true);
    props.setSnackBarStatus({
      opened: true,
      severity: "info",
      message: "Espere um momento enquanto a sua requisição é processada",
    });
    let payload = { enabled: checked };
    if (user.company_key) payload["company_key"] = user.company_key;
    axios
      .put("/dash/user/" + user.username, payload)
      .then((response) => {
        handleRequestFinish(
          "success",
          checked
            ? "Usuário reativado com sucesso"
            : "Usuário desativado com sucesso"
        );
      })
      .catch((error) => {
        handleRequestFinish(
          "error",
          checked
            ? "Não foi possível reativar este usuário"
            : "Não foi possível desativar este usuário"
        );
      });
  };

  const handleEmailInvite = () => {
    axios
      .put("/dash/user/" + props.user.username + "/invite")
      .then((response) => {
        props.setSnackBarStatus({
          opened: true,
          severity: "success",
          message: "Convite enviado",
        });
        setDisableAll(false);
      })
      .catch((error) => {
        props.setSnackBarStatus({
          opened: true,
          severity: "error",
          message: "Houve um problema ao convidar este usuário",
        });
        setDisableAll(false);
      });
  };

  const user_data_inline = [
    props.user.document_number,
    props.user.email
  ]

  const managePermissionsHandler = (event) => {
    props.setSelectedUserEmail(event.target.id)
    props.onUpdate(
      user_data.business_group_key
        ? props.user.company_key
          ? { company_key: props.user.company_key }
          : null
        : null
    )
  }
  const [isHover, setIsHover] = useState(false)

  return (
    <div 
      className={props.user.email===props.selectedUserEmail? "border-1-blue listCard2 collapse":"border-1-transparent listCard2 collapse"}
      style={{width: "100%"}}
    >
      <div style={{display:"flex", displayDirection:"row", justifyContent: "space-between", alignItems:"center"}} >
        <div style={{ display: "flex", flexDirection:"column"}} >
          <div className="normalText normalMediumSize">
            {props.user.first_name+" "+props.user.last_name}
          </div>
          {user_data_inline.map((value, index)=>(
          <div key={index} className="labelText normalSmallSize" style={{wordBreak: "break-all"}}>
            {value}
          </div>
          ))}
        </div>
        <div style={{ display: "flex", flexDirection:"row", alignItems:"center", minWidth:"160px"}} >
          <button
            id={props.user.email}
            className="normalText normalSmallSize buttonCard"
            style={{ padding:"5px", cursor: "pointer", backgroundColor: selectButtonBackgroundColor(isHover, props.user.email!==props.selectedUserEmail) }}
            onClick={managePermissionsHandler}
            isActive={props.user.email===props.selectedUserEmail}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            >
              <PermissionIcon 
                id={props.user.email}
                className="button standard"
                style={{width: "30px", height: "30px", margin:"1.5px auto", padding:"4px", backgroundColor: props.user.email===props.selectedUserEmail ? "#919191" : "#0B1F82"}}
                />
              <div
                id={props.user.email}
                style={{color: props.user.email===props.selectedUserEmail ? "#919191" : "#0B1F82", margin:"0px", maxWidth:"70px"}}
              >
                {t("manage_permissions")}
              </div>
          </button>
          {props.user.email_verified? 
          <button
            className="listCardItem buttonCard"
            >
            <ThemeProvider theme={theme}>
              <Switch
                checked={isActive}
                color="secondary"
                onChange={(event) => {
                  if (!props.isManagingItself) {
                    handleStatusChange(event.target.checked, props.user);
                    setIsActive(!isActive);
                  }
                }}
              />
            </ThemeProvider>
            <p className={isActive? "blue normalText normalSmallSize":"gray normalText normalSmallSize" } style={{margin:"6px"}}>
              {isActive ? t("Ativo") : t("Inativo")}
            </p>
          </button>
          :
          <ButtonDescription
            buttonClassName="normalText normalSmallSize buttonCard"
            text="resend_invite"
            onClickButton={handleEmailInvite}
            >
            <EmailIcon className="button standard" style={{width: "30px", height: "30px", margin:"1.5px auto", padding:"6px"}}/>
          </ButtonDescription>
          }
        </div>
      </div>
      <div>
      </div>
    </div>
  );
}

export default UserCard;
