import React, { useState } from "react";
import HelpIcon from "@material-ui/icons/Help"
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Tooltip from "@material-ui/core/Tooltip";
import SectionContainer from "../../../@GeneralComponents/SectionContainer";
import MultiSelectDropdown from "./MultiSelectDropdown/MultiSelectDropdown";
import MultiSelectDropdownRulesViewer from "./MultiSelectDropdown/MultiSelectDropdownRulesViewer";
import Button from "../../../@GeneralComponents/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { t } from "i18next";
import { default_lists_permissions } from "../../utils";
import BaseCheckbox from "../../../@GeneralComponents/BaseCheckbox";

const RolesGroups = (props) => {

  const {listsStates,listsSetStates,listsOptions,listsToggleOption, getInitialState, listsLoaded, isWhitelist, rulesViewerOptions, pageState, eventsIsLoaded} = props
  const rulesViewerOptionsList = rulesViewerOptions.map((item) => {
    return item.name
  })

  const [selectedRulesViewer, setSelectedRulesViewer] = useState(pageState.fetchedData.roles.filter(item => rulesViewerOptionsList.includes(item)))

  const rulesToggleOption = ((role) => {
    if (selectedRulesViewer.includes(role.name)) {
      let updatedRulesviewer =  selectedRulesViewer
      updatedRulesviewer = updatedRulesviewer.filter(item => item !== role.name)
      setSelectedRulesViewer(updatedRulesviewer)
    } else {
      setSelectedRulesViewer([
        ...selectedRulesViewer,
        role.name
      ])
    }
  })

  const {
    checkedListsViewer,
    selectedListsToReadList,
    selectedListsToCreateItem,
    selectedListsToDeleteItem,
    selectedListsToReadItem,
    selectedListsToUpdateItem
  } = listsStates
  
  const {
    setCheckedListsViewer,
    setSelectedListsToReadList,
    setSelectedListsToCreateItem,
    setSelectedListsToDeleteItem,
    setSelectedListsToReadItem,
    setSelectedListsToUpdateItem
  } = listsSetStates

  const handleChangePermission = (role, status) => {
    if (role.name==="lists_viewer") {
      setCheckedListsViewer(status)
    }

    if (status === true) {
      handleAddPermission(props.user, role.name);
      if (props.permissionsToRemove.roles.includes(role.name)) {
        props.setPermissionsToRemove({roles: props.permissionsToRemove.roles.filter((indexedRole) => (indexedRole !== role.name)), company_key: props.user.company_key})
      } else {
        props.setPermissionsToAdd({roles: [...props.permissionsToAdd.roles, role.name], company_key: props.user.company_key})
      }
    } else {
      handleRemovePermission(props.user, role.name);
      if (props.permissionsToAdd.roles.includes(role.name)) {
        props.setPermissionsToAdd({roles: props.permissionsToAdd.roles.filter((indexedRole) => (indexedRole !== role.name)), company_key: props.user.company_key})
      } else {
        props.setPermissionsToRemove({roles: [...props.permissionsToRemove.roles, role.name], company_key: props.user.company_key})
      }
    }
  };

  const handleAddPermission = (user, role) => {
    let payload = { roles: [role] };
    if (user.company_key) payload["company_key"] = user.company_key;
  };

  const handleRemovePermission = (user, role) => {
    let payload = { roles: [role] };
    if (user.company_key) payload["company_key"] = user.company_key;
  };

  const [expandedContainer, setExpandedContainer] = useState(false);
  const expandedContainerHandler = event => {
    event.stopPropagation();
    setExpandedContainer(!expandedContainer);
  };

  return (
    <SectionContainer
      sectionClasses="internalSectionContainer"
      sectionTitle={props.rolesGroup.name}
      sectionTitleStyle={{fontWeight:"400"}}
      sectionSubtitle={props.rolesGroup.description}
      sectionSubtitleClassName="labelText normalSmallSize"
      isCollape={true}
      collapeState={expandedContainer}
      collapeStateHandler={expandedContainerHandler}
    >
      {props.rolesGroup.roles.map((role, index) => (
        ((role.name!=="read_onboarding_filtered_pending_status_natural_persons")||(role.name==="read_onboarding_filtered_pending_status_natural_persons"&&isWhitelist))&&
          (props.initialGroup==="choose_permissions_separately")?
          <div style={{marginLeft:"12px"}} key={`${props.rolesGroup.manager_role}_${role.name}`} >
            <FormControl component="fieldset">
              <FormGroup aria-label="position" column="true">
                <FormControlLabel
                  style={{alignItems: "center"}}
                  control={<input
                      style={{minWidth:"18px", minHeight:"18px", margin: "8px 0px"}}
                      type={"checkbox"}
                      defaultChecked={getInitialState(role.name)}
                      onChange={(event) => handleChangePermission(role, event.target.checked)}
                    />}
                  label={
                    <div style={{alignItems:"center", margin:"4px"}}>
                      <span className="normalText normalMediumSize">{role.description}</span>
                      {role.message&&
                        <Tooltip
                          title={<em className="tooltipText">{role.message}</em>}
                          placement="right"
                        >
                        <HelpIcon className="gray backgroundWhite" style={{width: "15px", height: "15px", borderRadius:"7.5px", margin:"1px"}}/>
                        </Tooltip>}
                    </div>}
                />
              </FormGroup>
            </FormControl>
          </div>
          :
          <BaseCheckbox
            key={`${props.rolesGroup.manager_role}_${role.name}`}
            id={role.name}
            checked={getInitialState(role.name)}
            label={role.description}
            checkboxStyles={{display:"flex", alignItems: "center", margin:"5px 0px"}}
            inputStyles={{minWidth:"18px", minHeight:"18px", margin:"2px"}}
          />
      ))}
      {(props.rolesGroup.name === "Listas")&&checkedListsViewer&&
      <div style={{display:"flex", flexDirection:"column"}}>
        <div className="multiSelectDropdown normalSmallSize normalText">
          {listsLoaded?
            default_lists_permissions.map((permission)=>(
              <div key={permission.name} style={{display:"flex", flexDirection:"column", margin:"10px 0px"}}>
                <div style={{alignItems:"center"}}>
                  <span className="normalText normalMediumSize">{t(permission.description)}</span>
                </div>
                <div className="labelText normalSmallSize">
                  {t("Select lists to add permissions.")}
                </div>
                <MultiSelectDropdown
                  permission_name={permission.name}
                  options={listsOptions}
                  selected={permission.name==="read_list"?selectedListsToReadList:
                            permission.name==="create_item"?selectedListsToCreateItem:
                            permission.name==="delete_item"?selectedListsToDeleteItem:
                            permission.name==="read_item"?selectedListsToReadItem:
                            permission.name==="update_item"&&selectedListsToUpdateItem}
                  setSelected={permission.name==="read_list"?setSelectedListsToReadList:
                              permission.name==="create_item"?setSelectedListsToCreateItem:
                              permission.name==="delete_item"?setSelectedListsToDeleteItem:
                              permission.name==="read_item"?setSelectedListsToReadItem:
                              permission.name==="update_item"&&setSelectedListsToUpdateItem}
                  toggleOption={listsToggleOption}
                />
              </div>
            ))
          :
          <div style={{display:"flex", flexDirection:"column"}}>
            <div className="labelText normalSmallSize">
              {t("Loading lists...")}
            </div>
            <CircularProgress className="blue" style={{width:"15px",height:"15px", margin:"5px"}}/>
          </div>}
        </div>
        <Button
          button_option="standard"
          onClick={() => {
            props.saveLists(
              listsOptions,
              selectedListsToReadList,
              selectedListsToCreateItem,
              selectedListsToDeleteItem,
              selectedListsToReadItem,
              selectedListsToUpdateItem
            )
          }}
          buttonLabel={t("save_lists")}
          buttonStyle={{minWidth:"10%", margin: "10px 0px 10px auto"}}
          isLoading={props.saveLoading}
        />
      </div>}
      {(props.rolesGroup.name === "Regras")&&
      <div style={{display:"flex", flexDirection:"column"}}>
      {eventsIsLoaded?
        <div>
          <div className="multiSelectDropdown normalSmallSize normalText">
          <MultiSelectDropdownRulesViewer
          options={rulesViewerOptions}
          toggleOption={rulesToggleOption}
          selectedRulesViewer={selectedRulesViewer}
          permissionsToRemove={props.permissionsToRemove}
          setPermissionsToRemove={props.setPermissionsToRemove}
          setPermissionsToAdd={props.setPermissionsToAdd}
          permissionsToAdd={props.permissionsToAdd}
          user={props.user}
          />
          </div>
        </div>
      :<div style={{display:"flex", flexDirection:"column"}}>
        <div className="labelText normalSmallSize">
          {t("Loading lists...")}
        </div>
        <CircularProgress className="blue" style={{width:"15px",height:"15px", margin:"5px"}}/>
      </div>}
      </div>}
    </SectionContainer>
  );
}

export default RolesGroups;
