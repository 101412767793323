import React,{useContext} from "react";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import moment from 'moment'
import {getWithdrawalStatusIcon, getReasonDescription } from "../../utils/utilsWithdrawal"
import {formatAsMoney, getAnalysisStatusColor, 
        getAnalysisStatusSiglum, getAnalysisStatus} from "../../utils/utils"
import Tooltip from "@material-ui/core/Tooltip";
import AuthContext from "../../../../context/auth-context";

function WithdrawalCard(props){
    let {user_data} = useContext(AuthContext)

    return (
        <Card className="listCard">
            <Link
                className={["listCardItem", "link", "subtitleSize", "breakWord", "bold"].join(" ")}
                style={{flex:"1 1 110px", textAlign:"center"}}
                to={"/withdrawal/" + props.withdrawal.withdrawal_key}
            >
                {props.withdrawal.id}
            </Link>
            {user_data.business_group_key &&
            <div 
                className={["listCardItem", "normalText"].join(" ")}
                style={{flex:"1 1 80px", textAlign:"center"}}
            >
                {props.withdrawal.company_name}
            </div>}
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")} 
                style={{flex:"1 1 130px", textAlign:"center"}}  
            >
                {formatAsMoney(props.withdrawal.amount, "-")}
            </div>
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                style={{flex:"1 1 100px", textAlign:"center"}}
            >
                {(props.withdrawal.client || {}).document_number || "-"}
            </div>
            <div 
                className={["listCardItem", "normalText", "normalMediumSize", "bold"].join(" ")} 
                style={{flex:"1 1 45px", textAlign:"center", cursor: "default"}}  
            >
                <Tooltip title={<p className="tooltipText" style={{textAlign: "center"}}>
                    {getAnalysisStatus(props.withdrawal.analysis_status)}
                    <br/>{getReasonDescription(props.withdrawal)}
                </p>}>
                <div className={["circle", "small"].join(" ")} style={{backgroundColor:getAnalysisStatusColor(props.withdrawal.analysis_status)}}>
                    {getAnalysisStatusSiglum(props.withdrawal.analysis_status)}
                </div>
                </Tooltip>
            </div>
            <div 
                className={["listCardItem", "normalText", "subtitleSize","noWrap"].join(" ")}
                style={{flex:"1 1 110px", textAlign:"center"}}
            >
                {moment(props.withdrawal.withdrawal_date).format("DD/MM/YYYY HH:mm:ss")}
            </div>
            <div 
                className={["listCardItem", "normalText", "normalMediumSize", "bold"].join(" ")}  
                style={{flex:"1 1 35px", textAlign:"center", cursor: "default"}}  
            >
                {getWithdrawalStatusIcon(props.withdrawal.withdrawal_status, "small", true)}
            </div>
        </Card>
    )
}

export default WithdrawalCard