import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {getTableRowClass} from "../../../utils/utils"

const useStyles = makeStyles((theme) => ({
        tableStyle: {
            border: "none",
            boxShadow: "none",
            width:"50%"
        },
        blueText: {
            textAlign: "center",
            fontFamily: "Open Sans",
            fontWeight: "600",
            color: "#0b1f82"
        },
        tableHeader: {
            textAlign: "center",
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: "normal",
            color: '#6F6F6F',
            fontSize: "16px",
            lineHeight: "22px",
            padding: "0px",
        }
}));

function StatisticsTableV1(props) {
    const classes = useStyles();
    useTheme();

    let {destination_statistics} = props
    let {statisticsType} = props

    return (
        <Paper className={classes.tableStyle}>
        <Table style={{ padding: "8px" }}>
            <TableHead>
            <TableRow>
                <TableCell className={classes.tableHeader}></TableCell>
                <TableCell className={classes.tableHeader}>3 dias</TableCell>
                <TableCell className={classes.tableHeader}>30 dias</TableCell>
                <TableCell className={classes.tableHeader}>6 meses</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
                <TableRow key={0} className={getTableRowClass(0)}>
                    <TableCell className={classes.tableHeader}>Pagamentos</TableCell>
                    <TableCell className={classes.blueText}>{destination_statistics[statisticsType].settlements.d3}</TableCell>
                    <TableCell className={classes.blueText}>{destination_statistics[statisticsType].settlements.d30}</TableCell>
                    <TableCell className={classes.blueText}>{destination_statistics[statisticsType].settlements.m6}</TableCell>
                </TableRow>
                <TableRow key={1} className={getTableRowClass(1)}>
                    <TableCell className={classes.tableHeader}>Fraudes Reportadas</TableCell>
                    <TableCell className={classes.blueText}>{destination_statistics[statisticsType].reported_frauds.d3}</TableCell>
                    <TableCell className={classes.blueText}>{destination_statistics[statisticsType].reported_frauds.d30}</TableCell>
                    <TableCell className={classes.blueText}>{destination_statistics[statisticsType].reported_frauds.m6}</TableCell>
                </TableRow>
                <TableRow key={2} className={getTableRowClass(2)}>
                    <TableCell className={classes.tableHeader}>Alertas de PLD</TableCell>
                    <TableCell className={classes.blueText}>{destination_statistics[statisticsType].reported_aml_cft.d3}</TableCell>
                    <TableCell className={classes.blueText}>{destination_statistics[statisticsType].reported_aml_cft.d30}</TableCell>
                    <TableCell className={classes.blueText}>{destination_statistics[statisticsType].reported_aml_cft.m6}</TableCell>
                </TableRow>
                <TableRow key={3} className={getTableRowClass(3)}>
                    <TableCell className={classes.tableHeader}>Fraudes Confirmadas</TableCell>
                    <TableCell className={classes.blueText}>{destination_statistics[statisticsType].confirmed_frauds.d3}</TableCell>
                    <TableCell className={classes.blueText}>{destination_statistics[statisticsType].confirmed_frauds.d30}</TableCell>
                    <TableCell className={classes.blueText}>{destination_statistics[statisticsType].confirmed_frauds.m6}</TableCell>
                </TableRow>
                <TableRow key={4} className={getTableRowClass(4)}>
                    <TableCell className={classes.tableHeader}>PLDs Confirmados</TableCell>
                    <TableCell className={classes.blueText}>{destination_statistics[statisticsType].confirmed_aml_cft.d3}</TableCell>
                    <TableCell className={classes.blueText}>{destination_statistics[statisticsType].confirmed_aml_cft.d30}</TableCell>
                    <TableCell className={classes.blueText}>{destination_statistics[statisticsType].confirmed_aml_cft.m6}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
        </Paper>
    );
}

export default StatisticsTableV1;