import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
  },
  tableText: {
    fontFamily: "Open Sans",
    fontWeight: 'normal',
    fontSize: "12px",
    lineHeight: "16px",
    color: "#141414",
    textAlign: "center",
    margin: "10px",
    padding: "6px",
    minWidth: "120px"
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: '#6F6F6F',
    fontSize: "14px",
    lineHeight: "19px",
    padding: "6px",
  },
  even: {
    background: "#F3F3F3",
  },
  odd: {
    background: "#FFFFFF",
  },
}));

function IbamaTable(props) {
  const classes = useStyles();
  useTheme();

  const getClass = (rowIndex) => {
    if (rowIndex % 2 === 0) {
      return classes.even;
    }

    return classes.odd;
  };

  return (
    <Paper className={classes.tableStyle}>
      <Table style={{ padding: "8px" }}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader}>TAD</TableCell>
            <TableCell className={classes.tableHeader}>Nome</TableCell>
            <TableCell className={classes.tableHeader}>Infração</TableCell>
            <TableCell className={classes.tableHeader}>Data de Inclusão</TableCell>
            <TableCell className={classes.tableHeader}>Cidade</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.ibama_data.map((violation,index) => (
            <TableRow
              key={index}
              className={getClass(index)}
            >
              <TableCell className={classes.tableText}>
                {violation.tad_number}
              </TableCell>
              <TableCell style={{whiteSpace:"nowrap"}} className={classes.tableText}>
                {violation.name}
              </TableCell>
              <TableCell className={classes.tableText}>
                {violation.violation}
              </TableCell>
              <TableCell className={classes.tableText}>
                {violation.date == null ? "-" : moment(violation.date).format('DD/MM/YYYY')}
              </TableCell>
              <TableCell className={classes.tableText}>
                {violation.city == null ? "-" : violation.city + " - "+ violation.uf}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default IbamaTable;
