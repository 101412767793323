import React from 'react'
import DataField from "../../../utils/DataField"
import {titleCase,upperCase,checkNested} from "../../../utils/utils"
import moment from "moment"

function NewRGOCR(props){
    const { document_verification } = props
    const { RGOCR } = props

    return(
        <div style={{display:"flex", width:"70%", flexDirection: "column"}}>
            <DataField
                    title={"Nome"}
                    label={titleCase(RGOCR.name, null)}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={checkNested(document_verification, "rg", "name", "flag") ? document_verification.rg.name.flag : null}
                    tooltipText={checkNested(document_verification, "rg", "name", "flag") ? document_verification.rg.name.flag === "positive" ? "Nome validado" : 
                        (checkNested(document_verification, "rg", "name", "description") && checkNested(document_verification, "rg", "name","similarity")) ? 
                        "Nome encontrado em Bureau: " + titleCase(document_verification.rg.name.description) + " (Similaridade de " + (document_verification.rg.name.similarity*100).toFixed(0)  + "% )": 
                        checkNested(document_verification, "rg", "name", "description") ? "Nome encontrado em Bureau: " + titleCase(document_verification.rg.name.description) : 
                        checkNested(document_verification, "rg", "name","similarity") ? "Similaridade de " + (document_verification.rg.name.similarity*100).toFixed(0)  + "%" : "Indisponível" :  "Indisponível"}
            />
            <DataField
                    title={"CPF"}
                    label={RGOCR.cpf_number ? RGOCR.cpf_number : null}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={checkNested(document_verification, "rg", "document_number", "flag") ? document_verification.rg.document_number.flag : null}
                    tooltipText={checkNested(document_verification, "rg", "document_number", "flag") ? document_verification.rg.document_number.flag === "positive" ? "CPF validado" : 
                    checkNested(document_verification.rg.document_number.description) ? "CPF encontrado em Bureau: " + titleCase(document_verification.rg.document_number.description) :
                    "Indisponível"  : "Indisponível"}
            />                    
            <DataField
                    title={"Data de Nascimento"}
                    label={RGOCR.birthdate ? moment(RGOCR.birthdate).format('DD/MM/YYYY') : null}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={checkNested(document_verification, "rg", "birthdate", "flag") ? document_verification.rg.birthdate.flag : null}
                    tooltipText={checkNested(document_verification, "rg", "birthdate", "flag") ? document_verification.rg.birthdate.flag === "positive" ? "Data validada" : 
                    checkNested(document_verification.rg.birthdate.description) ? "Data de nascimento encontrada em Bureau: " + moment(document_verification.rg.birthdate.description).format('DD/MM/YYYY') :
                    "Indisponível" : "Indisponível"}
            />
            <DataField
                    title={"Nome do Pai"}
                    label={titleCase(RGOCR.father_name, null)}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={checkNested(document_verification, "rg", "father_name", "flag") ? document_verification.rg.father_name.flag : null}
                    tooltipText={checkNested(document_verification, "rg", "father_name", "flag") ? document_verification.rg.father_name.flag === "positive" ? "Nome validado" : 
                        (checkNested(document_verification, "rg", "father_name", "description") && checkNested(document_verification, "rg", "father_name","similarity")) ? 
                        "Nome encontrado em Bureau: " + titleCase(document_verification.rg.father_name.description) + " (Similaridade de " + (document_verification.rg.father_name.similarity*100).toFixed(0)  + "% )": 
                        checkNested(document_verification, "rg", "father_name", "description") ? "Nome encontrado em Bureau: " + titleCase(document_verification.rg.father_name.description) : 
                        checkNested(document_verification, "rg", "father_name","similarity") ? "Similaridade de " + (document_verification.rg.father_name.similarity*100).toFixed(0)  + "%" : "Indisponível" :  "Indisponível"}
            />
            <DataField
                    title={"Nome da Mãe"}
                    label={titleCase(RGOCR.mother_name, null)}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={checkNested(document_verification, "rg", "mother_name", "flag") ? document_verification.rg.mother_name.flag : null}
                    tooltipText={checkNested(document_verification, "rg", "mother_name", "flag") ? document_verification.rg.mother_name.flag === "positive" ? "Nome validado" : 
                        (checkNested(document_verification, "rg", "mother_name", "description") && checkNested(document_verification, "rg", "mother_name","similarity")) ? 
                        "Nome encontrado em Bureau: " + titleCase(document_verification.rg.mother_name.description) + " (Similaridade de " + (document_verification.rg.mother_name.similarity*100).toFixed(0)  + "% )": 
                        checkNested(document_verification, "rg", "mother_name", "description") ? "Nome encontrado em Bureau: " + titleCase(document_verification.rg.mother_name.description) : 
                        checkNested(document_verification, "rg", "mother_name","similarity") ? "Similaridade de " + (document_verification.rg.mother_name.similarity*100).toFixed(0)  + "%" : "Indisponível" :  "Indisponível"}
            />
            <DataField
                    title={"RG"}
                    label={upperCase(RGOCR.rg_number, null)}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={checkNested(document_verification, "rg", "rg_number", "flag") ? document_verification.rg.rg_number.flag : null}
                    tooltipText={checkNested(document_verification, "rg", "rg_number", "flag") ? document_verification.rg.rg_number.flag === "positive" ? "Número do RG validado" : 
                    checkNested(document_verification.rg.rg_number.description) ? "Número de RG encontrado em Bureau: " + titleCase(document_verification.rg.rg_number.description) :
                    "Indisponível" : "Indisponível"}
                    
            />
            <DataField
                    title={"Orgão Emissor do RG"}
                    label={upperCase(RGOCR.rg_issuer, null)}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={checkNested(document_verification, "rg", "rg_issuer", "flag") ? document_verification.rg.rg_issuer.flag : null}
                    tooltipText={checkNested(document_verification, "rg", "rg_issuer", "flag") ? document_verification.rg.rg_issuer.flag === "positive" ? "Orgão emissor do RG validado" : 
                    checkNested(document_verification.rg.rg_issuer.description) ? "Orgão emissor encontrado em Bureau: " + upperCase(document_verification.rg.rg_issuer.description) :
                    "Indisponível" : "Indisponível"}
            />
            <DataField
                    title={"Estado Emissor do RG"}
                    label={upperCase(RGOCR.rg_issuer_state, null)}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={checkNested(document_verification, "rg", "rg_issuer_state", "flag") ? document_verification.rg.rg_issuer_state.flag : null}
                    tooltipText={checkNested(document_verification, "rg", "rg_issuer_state", "flag") ? document_verification.rg.rg_issuer_state.flag === "positive" ? "Estado emissor do RG validado" : 
                    checkNested(document_verification.rg.rg_issuer_state.description) ? "Estado emissor encontrado em Bureau: " + upperCase(document_verification.rg.rg_issuer_state.description) :
                    "Indisponível" : "Indisponível"}
            />
            <DataField
                title={"Data de Emissão do RG"}
                label={RGOCR.issuance_date ? moment(RGOCR.issuance_date).format('DD/MM/YYYY') : null}
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="5px"
                check={checkNested(document_verification, "rg", "rg_issuance_date", "flag") ? document_verification.rg.rg_issuance_date.flag : null}
                tooltipText={checkNested(document_verification, "rg", "rg_issuance_date", "flag") ? document_verification.rg.rg_issuance_date.flag === "positive" ? "Data validada" : 
                checkNested(document_verification.rg.rg_issuance_date.description) ? "Data de emissão do RG encontrado em Bureau: " + moment(document_verification.rg.rg_issuance_date.description).format('DD/MM/YYYY') :
                "Indisponível" : "Indisponível"}
            />
        </div>
    )
}

export default NewRGOCR