import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper"
import {formatAsMoney} from '../../../utils/utils'
import {formatAsCPFCNPJ} from '../../../../Utils/utils'
import moment from "moment";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
  },
  tableText: {
    fontFamily: "Open Sans",
    fontWeight: 'normal',
    fontSize: "12px",
    lineHeight: "16px",
    color: "#141414",
    textAlign: "center",
    margin: "10px 4px",
    padding: "6px",
    minWidth: "100px"
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: '#6F6F6F',
    fontSize: "14px",
    lineHeight: "19px",
    padding: "6px",
  },
  even: {
    background: "#F3F3F3",
  },
  odd: {
    background: "#FFFFFF",
  },
}));

  function SerasaNegativeNotesTable(props) {
    const {t} = useTranslation()
    const classes = useStyles();
    useTheme();
    let {negative_notes_data} = props

  const getClass = (rowIndex) => {
    if (rowIndex % 2 === 0) {
      return classes.even;
    }

    return classes.odd;
  };

    const tableHeader = (firstOccurence) => {
      return (
          <TableRow>
              {firstOccurence.company_name !== undefined ? 
                  <TableCell className={classes.tableHeader}>{t("company_name")}</TableCell>
                  : null}
              {firstOccurence.notary_name && <TableCell className={classes.tableHeader}>{t("notarys_office")}</TableCell>}
              {firstOccurence.debt_title !== undefined ? 
                  <TableCell className={classes.tableHeader}>{t("debt_title")}</TableCell>
                  : null}

              {firstOccurence.financial_institution !== undefined ? 
                  <TableCell className={classes.tableHeader}>{t("financial_institution")}</TableCell>
                  : null}

              {firstOccurence.event_date !== undefined ? 
                  <TableCell className={classes.tableHeader}>{t("event_date")}</TableCell>
                  : null}

              {firstOccurence.inclusion_date !== undefined ? 
                  <TableCell className={classes.tableHeader}>{t("inclusion_date")}</TableCell>
                  : null}

              {firstOccurence.expiration_date !== undefined ? 
                  <TableCell className={classes.tableHeader}>{t("expiration_date")}</TableCell>
                  : null}

              {firstOccurence.amount !== undefined ? 
                  <TableCell className={classes.tableHeader}>{t("occurrence_amount")}</TableCell>
                  : null}

              {firstOccurence.bankrupt_cnpj !== undefined ? 
                  <TableCell className={classes.tableHeader}>{t("bankrupt_cnpj")}</TableCell>
                  : null}

              {firstOccurence.participation_type !== undefined ? 
                  <TableCell className={classes.tableHeader}>{t("participation_type")}</TableCell>
                  : null}

              {firstOccurence.city !== undefined ? 
                  <TableCell className={classes.tableHeader}>{t("city")}</TableCell>
                  : null}

              {firstOccurence.state !== undefined ? 
                  <TableCell className={classes.tableHeader}>{t("state")}</TableCell>
                  : null}
              {firstOccurence.company_segment !== undefined ? 
                  <TableCell className={classes.tableHeader}>{t("inquiried_company_segment")}</TableCell>
                  : null}
              {firstOccurence.days_from_last_inquiry !== undefined ? 
                  <TableCell className={classes.tableHeader}>{t("days_from_last_inquiry")}</TableCell>
                  : null}
              {firstOccurence.document_number && <TableCell className={classes.tableHeader}>{t("document_number")}</TableCell>}
              {firstOccurence.document_type && <TableCell className={classes.tableHeader}>{t("document_type")}</TableCell>}
              {firstOccurence.issuing_authority && <TableCell className={classes.tableHeader}>{t("issuing_authority")}</TableCell>}
              {firstOccurence.detailed_reason && <TableCell className={classes.tableHeader}>{t("reason")}</TableCell>}
              {firstOccurence.occurrence_state && <TableCell className={classes.tableHeader}>{t("occurrence_state")}</TableCell>}
              {firstOccurence.ownership_percentage&&<TableCell className={classes.tableHeader}>{t("ownership_percentage")}</TableCell>}
              {firstOccurence.status&&<TableCell className={classes.tableHeader}>{t("status")}</TableCell>}
          </TableRow>
      );
  };

  const negativeNotesOccurencies = negative_notes_data.occurencies||negative_notes_data.data
  const negativeNotes = negativeNotesOccurencies.map( (negativeNote ,index) => {
      return (
          <TableRow key={index} className={getClass(index)}>
              {negativeNote.company_name &&
                <TableCell style={{wordBreak:"break-all"}} className={classes.tableText}>
                  {negativeNote.company_name ? negativeNote.company_name : " - "} 
                </TableCell>}

              {negativeNote.notary_name &&
                <TableCell style={{wordBreak:"break-all"}} className={classes.tableText}>
                  {negativeNote.notary_name ? negativeNote.notary_name : " - "} 
                </TableCell>}

              {negativeNote.debt_title !== undefined ?
                <TableCell style={{wordBreak:"break-all"}} className={classes.tableText}>
                {negativeNote.debt_title ? negativeNote.debt_title : " - "}
                </TableCell>
              :null}

              {negativeNote.financial_institution !== undefined ?
                <TableCell style={{wordBreak:"break-all"}} className={classes.tableText}>
                {negativeNote.financial_institution ? negativeNote.financial_institution : " - "}
                </TableCell>
              :null}

              {negativeNote.event_date !== undefined ?
                <TableCell style={{wordBreak:"break-all"}} className={classes.tableText}>
                {negativeNote.event_date ? moment(negativeNote.event_date).format("YYYY-MM-DD") : " - "}
                </TableCell>
              :null}

              {negativeNote.inclusion_date !== undefined ?
                <TableCell style={{wordBreak:"break-all"}} className={classes.tableText}>
                {negativeNote.inclusion_date ? moment(negativeNote.inclusion_date).format("YYYY-MM-DD") : " - "}
                </TableCell>
              :null}

              {negativeNote.expiration_date !== undefined ?
                <TableCell style={{wordBreak:"break-all"}} className={classes.tableText}>
                {negativeNote.expiration_date ? negativeNote.expiration_date : " - "}
                </TableCell>
              :null}

              {negativeNote.amount !== undefined ?
                <TableCell style={{wordBreak:"break-all"}} className={classes.tableText}>
                {negativeNote.amount ? formatAsMoney("BRL", negativeNote.amount) : " - "}
                </TableCell>
              :null}

              {negativeNote.bankrupt_cnpj !== undefined ?
                <TableCell style={{wordBreak:"break-all"}} className={classes.tableText}>
                {negativeNote.bankrupt_cnpj ? negativeNote.bankrupt_cnpj : " - "}
                </TableCell>
                :null}

              {negativeNote.participation_type !== undefined ?
                <TableCell style={{wordBreak:"break-all"}} className={classes.tableText}>
                  {negativeNote.participation_type ? negativeNote.participation_type : " - "}
                </TableCell>
                :null}

              {negativeNote.city !== undefined ?
                <TableCell style={{wordBreak:"break-all"}} className={classes.tableText}>
                  {negativeNote.city ? negativeNote.city : " - "}
                </TableCell>
                :null}

              {negativeNote.state !== undefined ?
                <TableCell style={{wordBreak:"break-all"}} className={classes.tableText}>
                  {negativeNote.state ? negativeNote.state : " - "}
                </TableCell>
                :null}
              {negativeNote.company_segment !== undefined ? 
                <TableCell style={{wordBreak:"break-all"}} className={classes.tableText}>
                  {negativeNote.company_segment ? negativeNote.company_segment : " - "}
                </TableCell>
                  : null}

              {negativeNote.days_from_last_inquiry !== undefined ? 
                <TableCell style={{wordBreak:"break-all"}} className={classes.tableText}>
                  {negativeNote.days_from_last_inquiry ? negativeNote.days_from_last_inquiry : " - "}
                </TableCell>
                  : null}

              {negativeNote.document_number &&
                <TableCell style={{wordBreak:"break-all"}} className={classes.tableText}>
                  {negativeNote.document_number ? formatAsCPFCNPJ(negativeNote.document_number) : " - "} 
                </TableCell>}
              {negativeNote.document_type &&
                <TableCell style={{wordBreak:"break-all"}} className={classes.tableText}>
                  {negativeNote.document_type ? negativeNote.document_type : " - "} 
                </TableCell>}
              {negativeNote.issuing_authority &&
                <TableCell style={{wordBreak:"break-all"}} className={classes.tableText}>
                  {negativeNote.issuing_authority ? negativeNote.issuing_authority : " - "} 
                </TableCell>}
              {negativeNote.detailed_reason &&
                <TableCell style={{wordBreak:"break-all"}} className={classes.tableText}>
                  {negativeNote.detailed_reason ? negativeNote.detailed_reason : " - "} 
                </TableCell>}
              {negativeNote.occurrence_state &&
                <TableCell style={{wordBreak:"break-all"}} className={classes.tableText}>
                  {negativeNote.occurrence_state ? negativeNote.occurrence_state : " - "} 
                </TableCell>}
              {negativeNote.ownership_percentage &&
                <TableCell style={{wordBreak:"break-all"}} className={classes.tableText}>
                  {negativeNote.ownership_percentage ? `${negativeNote.ownership_percentage}%` : " - "} 
                </TableCell>}
              {negativeNote.status &&
                <TableCell style={{wordBreak:"break-all"}} className={classes.tableText}>
                  {negativeNote.status ? negativeNote.status : " - "} 
                </TableCell>}
          </TableRow>
          );
  });


  return (
  <Paper className={classes.tableStyle}>
      {negative_notes_data.total_number_of_events > 0 ?
          <div>
            <span>
                {t("total_number_of_events")}
            </span>
            <span style={{color: '#6F6F6F', marginLeft: '6px'}}>
                {negative_notes_data.total_number_of_events}
            </span>
          </div>
          : null}

      {negative_notes_data.total_amount > 0 ?
          <div style={{marginTop: '5px'}}>
            <span>
                {t("total_amount_of_events")}
              
            </span>
            <span style={{color: '#6F6F6F', marginLeft: '6px'}}>
                {formatAsMoney("BRL", negative_notes_data.total_amount)}
            </span>
          </div>
          : null}

      <Table style={{ padding: "8px", marginBottom: '26px' }}>
          <TableHead>
              {tableHeader(negativeNotesOccurencies[0])}
          </TableHead>

          <TableBody>
              {negativeNotes}
          </TableBody>
      </Table>
  </Paper>
  );
}

export default SerasaNegativeNotesTable;