import React from "react";

function CardListHeader(props){
    return (
        <div className="listCardHeader">
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                style={{flex:"1 1 120px", textAlign:"center"}}
            >
                Tipo de Transação
            </div>
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                style={{flex:"1 1 120px", textAlign:"center"}}
            >
                Chave de Bloqueio
            </div>
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                style={{flex:"1 1 55px", textAlign:"center"}}
            >
                Tipo do Bloqueio
            </div>
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                style={{flex:"1 1 55px", textAlign:"center"}}
            >
                Origem
            </div>
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                style={{flex:"1 1 55px", textAlign:"center"}}
            >
                Data
            </div>
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                style={{flex:"1 1 55px", textAlign:"center"}}
            >
                Status
            </div>
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                style={{flex:"0 1 70px", textAlign:"center"}}
            />
        </div>
    )
}

export default CardListHeader