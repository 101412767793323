//Importing modules
import React, { useReducer } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios"
import { CircularProgress } from '@material-ui/core';
import {decisionReducer} from "../../../../Utils/requestUtils"
import {getConfirmationIconDialog} from "../../../utils/utils"



//Defining DeleteListDialog component
function DeleteListDialog(props) {
    //Extracting data from props
    let {listContents, list_key, setReloadListContentsTable, companyKey, userData, onClose} = props


    //Making request to dash API 
    const [dialogState, dispatchDialogState] = useReducer(
        decisionReducer,
        {isLoading: false, isError: false, finished:false}
    )


    //Defining function to handle Dash API request on confirmation button press
    const handleConfirm = () =>{
        let removedKeys = listContents.reduce((acc,item) => {
            if (item.isSelected === true) {
                acc = [...acc, item.list_item_key]
            }
            return acc
        },[])
        let payload=  { "list_item_key": removedKeys}
        dispatchDialogState({type:"send_request_init"})
        let requestHeaders = {headers:{}}
        if (userData.business_group_key) {
            requestHeaders = {headers:{company_key:companyKey}}
        }
        setTimeout(function () {
            axios.put('/dash/lists/' + list_key +'/item', payload, requestHeaders).then(response => {
                dispatchDialogState({type:"send_request_success"})
              }).catch(error => {
                if ((error.response || {}).status === 403) dispatchDialogState({type: "send_request_failure_403"})
                else if ((error.response || {}).status === 409) dispatchDialogState({type: "send_request_failure_409"})  
                else dispatchDialogState({type: "send_request_failure"})    
              });
        }, 1000);
    }

    if (dialogState.finished && dialogState.isLoading){
        return (
            <Dialog 
                open={props.open} 
                onClose={onClose}
                keepMounted={false}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle style={{paddingBottom: "0px"}} className={["blueText", "normalMediumSize"].join(" ")}>Confirmação de Revisão</DialogTitle>
                <DialogContent>
                    <div style={{display:"flex", width: "310px", height:"217px"}}>
                        <CircularProgress style={{margin:"auto"}} />
                    </div>
                </DialogContent>
            </Dialog>
        )
    }
    else if (dialogState.finished){
        let confirmationData = {    
            message: dialogState.message,
            success: !dialogState.isError
        }
        return (
            <Dialog 
                open={props.open} 
                onClose={onClose}                 
                keepMounted={false}
                disableBackdropClick
            >
                <DialogTitle style={{paddingBottom: "0px"}} className={["blueText", "normalMediumSize"].join(" ")}>Confirmação de Revisão</DialogTitle>
                <DialogContent>
                <div style={{width: "310px", height:"217px"}}>
                    <div className="internalCardContainer" style={{display:"flex", height:"40%", justifyContent:"center"}}>
                        {getConfirmationIconDialog(confirmationData.success)}
                    </div>
                    <div className="internalCardContainer" style={{display:"flex", textAlign:"center", justifyContent:"center"}}>
                        {confirmationData.message}
                    </div>
                    <div className="internalCardContainer" style={{display:"flex", justifyContent:"center"}}>
                        <div 
                            className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                            onClick={() => {
                                onClose()
                                setReloadListContentsTable(true)
                            }} 
                        >
                            VOLTAR À TELA INICIAL 
                        </div>
                    </div>
                </div>
                </DialogContent>
            </Dialog>
        )
    }
    else {
        return (
            <Dialog 
            open={props.open} 
            onClose={onClose} 
            keepMounted={false}
            >
                <DialogTitle style={{paddingBottom: "0px"}} className={["blueText", "normalMediumSize"].join(" ")}>Confirmação de Revisão</DialogTitle>
                <DialogContent>
                    <div 
                        style={{width:"310px", height: "120px", display:"flex", flexDirection:"column", alignItems:"center"}}
                    >
                        <div 
                            className={["internalCardContainer", "redText", "normalMediumSize"].join(" ")} 
                            style={{width:"90%", textAlign:"center"}}>
                            Tem certeza que deseja excluir este(s) item(ns)?
                        </div>
                        <div className="internalCardContainer" style={{display:"flex", marginTop: "10px"}}>
                            <div
                                className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")}
                                style={{width:"120px", fontWeight:"600"}}
                                onClick={props.onClose}
                            >
                                Cancelar
                            </div>
                            <div
                                className={["button", "reproved", "normalText", "normalMediumSize"].join(" ")}
                                style={{width:"120px", fontWeight:"600", paddingTop:"7px"}}
                                onClick={handleConfirm}
                            >
                                Excluir
                            </div>   
                        </div>
                    </div>
              </DialogContent>
            </Dialog>
        )   
    }
}


export default DeleteListDialog;