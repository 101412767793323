import React from 'react'
import {getStatus, getStatusColor} from "../../utils/utils"
import TimeCounter from "./TimeCounter/TimeCounter"

function TimerBox (props) {

    let {fraud_status} = props.reservation 

    if(fraud_status === "pending" || fraud_status === "in_manual_analysis"){
        return (
            <TimeCounter reservation={props.reservation}/>
        )
    }
    else{
        return(
            <div className="analysisCard" style={{height:"100%", borderWidth:"1px", borderStyle:"solid", borderColor:getStatusColor(fraud_status)}}>
                <div 
                    className={["normalText", "substitleSize", "bold"].join(" ")} 
                    style={{margin:"auto", color:getStatusColor(fraud_status)}}
                >
                    {getStatus(fraud_status)}                    
                </div>
            </div>
        )
    }
}

export default TimerBox