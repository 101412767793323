import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {titleCase} from "../../../../utils/utils"
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
  },
  tableText: {
    fontFamily: "Open Sans",
    fontWeight: 'normal',
    fontSize: "12px",
    lineHeight: "16px",
    color: "#141414",
    textAlign: "center",
    margin: "10px",
    padding: "6px",
    minWidth: "120px"
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: '#6F6F6F',
    fontSize: "14px",
    lineHeight: "19px",
    padding: "6px",
  },
  even: {
    background: "#F3F3F3",
  },
  odd: {
    background: "#FFFFFF",
  },
}));

function InterpolTable(props) {
  const classes = useStyles();
  useTheme();

  const getClass = (rowIndex) => {
    if (rowIndex % 2 === 0) {
      return classes.even;
    }

    return classes.odd;
  };         

  return (
    <Paper className={classes.tableStyle}>
      <Table style={{ padding: "8px" }}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader}>Nome Sancionado</TableCell>
            <TableCell className={classes.tableHeader}>Tipo da Sanção</TableCell>
            <TableCell className={classes.tableHeader}>Nacionalidades</TableCell>
            <TableCell className={classes.tableHeader}>Idioma</TableCell>
            <TableCell className={classes.tableHeader}>Acusação</TableCell>
            <TableCell className={classes.tableHeader}>Imagem</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.interpol_data.map((sanction,index) => (
            <TableRow
              key={index}
              className={getClass(index)}
            > 
              <TableCell className={classes.tableText}>
                {titleCase(sanction.sanction_name, "-")}
              </TableCell>
              <TableCell className={classes.tableText}>
                {titleCase(sanction.type, "-")}
              </TableCell>
              <TableCell className={classes.tableText}>
                {sanction.nationalities == null ? "-" : sanction.nationalities.length === 0 ? "-" : sanction.nationalities.join("; ")}
              </TableCell>
              <TableCell className={classes.tableText}>
                {titleCase(sanction.language_spoken, "-")}
              </TableCell>
              <TableCell className={classes.tableText}>
                {titleCase(sanction.charges, "-")}
              </TableCell>
              <TableCell className={classes.tableText}>
                <a
                  href={sanction.image}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Link
                </a>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default InterpolTable;