import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
  },
  tableText: {
    fontFamily: "Open Sans",
    fontWeight: 'normal',
    fontSize: "12px",
    lineHeight: "16px",
    color: "#141414",
    textAlign: "center",
    margin: "10px",
    padding: "6px",
    minWidth: "120px"
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: '#6F6F6F',
    fontSize: "14px",
    lineHeight: "19px",
    padding: "6px",
  },
  even: {
    background: "#F3F3F3",
  },
  odd: {
    background: "#FFFFFF",
  },
}));

function UNSCTable(props) {
  const classes = useStyles();
  useTheme();

  let counter = 0

  const getClass = (rowIndex) => {
    counter = counter + 1
    if (rowIndex % 2 === 0) {
      return classes.even;
    }

    return classes.odd;
  };         

  return (
    <Paper className={classes.tableStyle}>
      <Table style={{ padding: "8px" }}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader}>Chave de Referência</TableCell>
            <TableCell className={classes.tableHeader}>Tipo de Chave</TableCell>
            <TableCell className={classes.tableHeader}>Nome</TableCell>
            <TableCell className={classes.tableHeader}>Outros Nomes</TableCell>
            <TableCell className={classes.tableHeader}>Local de Nascimento</TableCell>
            <TableCell className={classes.tableHeader}>Endereço</TableCell>
            <TableCell className={classes.tableHeader}>Designação</TableCell>
            <TableCell className={classes.tableHeader}>Informação Extra</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.unsc_data.map((key,index) => (
            key.information == null ? 
            <TableRow
              key={10*index}
              className={getClass(counter)}
            > 
              <TableCell style={{whiteSpace:"nowrap"}} className={classes.tableText}>
                {key.search_key}
              </TableCell>
              <TableCell style={{whiteSpace:"nowrap"}} className={classes.tableText}>
                {key.search_key_type === 'name'? 'Nome': key.search_key_type === 'address' ? 'Endereço' : null}
              </TableCell>
              <TableCell style={{whiteSpace: "nowrap"}} className={classes.tableText}>
                Nada encontrado
              </TableCell>
              <TableCell className={classes.tableText}>
                {}
              </TableCell>
              <TableCell className={classes.tableText}>
                {}
              </TableCell>
              <TableCell className={classes.tableText}>
                {}
              </TableCell>
              <TableCell className={classes.tableText}>
                {}
              </TableCell>
              <TableCell className={classes.tableText}>
                {}
              </TableCell>
            </TableRow>
            :
            key.information.map((match,index_2) => (
            <TableRow
              key={100*index + index_2}
              className={getClass(counter)}
            > 
              <TableCell style={{whiteSpace:"nowrap"}} className={classes.tableText}>
                {key.search_key}
              </TableCell>
              <TableCell style={{whiteSpace:"nowrap"}} className={classes.tableText}>
                {key.search_type === 'name'? 'Nome': key.search_type === 'address' ? 'Endereço' : key.search_type}
              </TableCell>
              <TableCell style={{whiteSpace: "nowrap"}} className={classes.tableText}>
                {match.name}
              </TableCell>
              <TableCell className={classes.tableText}>
                {match.other_names && match.other_names.length > 0 ? match.other_names.map(e => e.name).join("; "): "-"}
              </TableCell>
              <TableCell className={classes.tableText}>
              {match.place_of_birth && match.place_of_birth.length > 0? match.place_of_birth[0].city == null? "-":
               match.place_of_birth[0].city + " - " + match.place_of_birth[0].country: "-"}
              </TableCell>
              <TableCell className={classes.tableText}>
                {match.address && match.address.length > 0 ? match.address[0].full_address == null ? match.address[0].note : match.address[0].full_address : " - " }
              </TableCell>
              <TableCell className={classes.tableText}>
                {match.designation == null ? "-" : match.designation.join("; ")}
              </TableCell>
              <TableCell className={classes.tableText}>
                {match.extra_information == null ? "-" : match.extra_information}
              </TableCell>
            </TableRow>
            ))))}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default UNSCTable;
