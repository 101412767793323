import React from "react";
import InputContent from "./InputContent"
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

function NewListDialog(props) {
    
  const handleDialogClose = () => {
    props.onClose()
  }

  let dialogContent = <InputContent 
                        businessGroupCompanies={props.businessGroupCompanies}
                        user_data={props.user_data}
                        setPageParams={props.setPageParams}
                        local_storage_object_preffix={props.local_storage_object_preffix}
                        onClose={handleDialogClose}
                      />

    return (
        <Dialog 
        open={props.open} 
        onClose={handleDialogClose}
        disableBackdropClick
        maxWidth="lg"
        keepMounted={false}>
            <DialogTitle 
                className={['blueText', "subtitleSize"].join(" ")} 
                onClose={handleDialogClose}
            >
                Nova Lista
            </DialogTitle>
            <DialogContent >
                <div style={{width:"700px", minHeight: "160px", display:"flex", flexDirection:"column", marginBottom:"30px"}}>
                    {dialogContent}
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default NewListDialog