import React, {useState} from "react";
import InterpolTable from "./InterpolTable/InterpolTable";
import Collapse from "@material-ui/core/Collapse";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import IconButton from "@material-ui/core/IconButton";
import {getCardIcon} from "../../../utils/utils"

function InterpolCard(props) {

    const [expanded, setExpanded] = useState(false);
    const [firstTime, setFirstTime] = useState(true);

    const {interpol} = props

    let initialExpand = interpol.flag === "positive"? false : true
    if (initialExpand !== expanded && firstTime===true){
        setExpanded(initialExpand);
    }
    const handleExpand = (event) => {
        event.stopPropagation();
        setFirstTime(false)
        setExpanded(!expanded);
    };

    return (
        <div className="analysisCard">
            <div style={{display:"flex"}}>
                <div 
                    className={["blueText", "subtitleSize", "bold"].join(" ")}
                    style={{margin:"auto 0px"}}
                >
                    Interpol
                </div>
                <div style={{margin:"auto 10px"}}>
                    {getCardIcon(interpol.flag)}
                </div>
                {!!(((interpol || {}).data || []).length) ? 
                <IconButton style={{marginLeft: "auto"}} onClick={handleExpand}>
                    {expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </IconButton>             
                : null}                    
            </div>
            {!!(((interpol || {}).data || []).length) ? 
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <div 
                    className={["normalText", "normalMediumSize", "internalCardContainer"].join(" ")}
                >
                    <InterpolTable interpol_data={interpol.data}/>
                </div>
            </Collapse> 
            : null}     
        </div>
        );
}

export default InterpolCard