import React, { useEffect, useState } from "react"; // ARRUMAR 
import { Collapse, IconButton } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import {formatAsDate} from "../../utils/utils";

function SCRError(props) {

  let {scr_data: {reference_date, validation_error_message}, showDropDown, isPDF, initialExpand } = props; // verificar showDropDown, isPDF
  
  const [expandedHistory, setExpandedHistory] = useState(initialExpand);

  const handleExpandHistory = (event) => {
    event.stopPropagation();
    setExpandedHistory(!expandedHistory);
  };

  useEffect(() => {
    if (!showDropDown) {
      setExpandedHistory(true);
    }
    if (isPDF) {
      setExpandedHistory(true);
    }
  }, [showDropDown, isPDF]);

  return (
    <div className={isPDF 
          ? "SCRtableContainer_width_pdf" 
          : "SCRtableContainer_width"}>
      {showDropDown && (
        <div className="internalCardContainer pagebreak SCRtableContainer">
          <div className={["blueText", "normalMediumSize", "bold"].join(" ")} style={{ padding: "10px" }}>{formatAsDate(reference_date)}</div>
          <IconButton style={{ marginLeft: "auto" }} onClick={handleExpandHistory}>
            {expandedHistory ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </IconButton>
        </div>
      )}
      <Collapse in={expandedHistory} timeout="auto" unmountOnExit>
          <div className="labelText normalMediumSize" style={{margin: '10px 10px 10px 20px'}}>
          {validation_error_message.description}
          </div>
      </Collapse>
    </div>
  );
}

export default SCRError;
