import React, { useEffect, useReducer, useCallback, useContext, useState } from "react"
import { dataFetchReducer, useStateConnectedToCache } from "../../Utils/requestUtils"
import Filter from "./Filter/Filter"
import Pagination from "@material-ui/lab/Pagination"
import { CircularProgress } from '@material-ui/core'
import axios from "axios"
import LockCard from "./LockCard/LockCard"
import NewLockDialog from "./NewLockDialog/NewLockDialog"
import ErrorBoundary from '../../Utils/ErrorBoundary'
import AuthContext from "../../../context/auth-context"
import { useHistory } from "react-router-dom"

function LocksInbox(props) {
    let history = useHistory()

    let user_data = useContext(AuthContext).user_data
    if (!user_data.roles.includes("card_issuance_lock_viewer")) {
        history.push("")
    }

    let local_storage_object_preffix = "ciLock"

    let initialFilters = {
        "lock_status": "locked"
    }

    const [openNewLock, setOpenNewLock] = useState(false)
    const [pageParams, setPageParams] = useStateConnectedToCache(local_storage_object_preffix, initialFilters)
    const [pageState, dispatchPageState] = useReducer(
        dataFetchReducer,
        { fetchedData: null, isLoading: true, isError: false }
    )

    const doRequest = useCallback(
        (payload) => {
            dispatchPageState({ type: "data_fetch_init" })
            axios.get("/dash/card_issuance_backoffice/locks", {
                params: payload
            }).then(response => {
                dispatchPageState({
                    type: "data_fetch_success",
                    payload: response.data
                })
            }).catch(error => {
                if ((error.response || {}).status === 403) dispatchPageState({ type: "data_fetch_failure_403" })
                else if ((error.response || {}).status === 404) dispatchPageState({ type: "data_fetch_failure_404" })
                else dispatchPageState({ type: "data_fetch_failure" })
            })
        }, []
    )

    useEffect(() => {
        let payload = {
            "page_number": pageParams.page - 1,
            "page_rows": 25,
            "append_business_group_locks": true
        }
        Object.keys(pageParams.filters).forEach((key, index) => {
            payload[key] = pageParams.filters[key]
        })
        const timer_ = setTimeout(() => {
            doRequest(payload)
        }, 300)
        return () => {
            clearTimeout(timer_)
        }
    }, [doRequest, pageParams, user_data.roles])

    const handleChangePage = (event, page, filters) => {
        if (pageState.page === page) {
            return
        }
        setPageParams({ page: page, filters: filters })
    }

    const handleOpenNewLockClick = () => {
        setOpenNewLock(true)
    }

    const handleNewLockDialogClose = () => {
        setOpenNewLock(false)
    }

    const contentContainer = (dataList) => {

        let nr_of_cards = dataList['data'].length
        let cards = dataList['data'].map((lock) => (
            <ErrorBoundary key={lock.lock_key}>
                <LockCard
                    lock={lock}
                    local_storage_object_preffix={local_storage_object_preffix}
                    setPageParams={setPageParams}
                />
            </ErrorBoundary>
        ))

        if (nr_of_cards === 0) {
            return (
                <div className='emptyContainer'>
                    <p className={["blueText", "subtitleSize"].join(" ")}>
                        Nenhum bloqueio a ser analisado
                    </p>
                </div>
            )
        }

        else {
            return (
                <div className='cardContainer'>
                    <div className="listCardHeader">
                        {user_data.business_group_key ?
                            <div
                                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                                style={{ flex: "2 1 180px", textAlign: "center" }}
                            >
                                Companhia
                            </div> : null}
                        <div
                            className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                            style={{ flex: "1 1 110px", textAlign: "center" }}
                        >
                            Entidade Bloqueada
                        </div>
                        <div
                            className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                            style={{ flex: "1 1 110px", textAlign: "center" }}
                        >
                            Usuário/Adquirente
                        </div>
                        <div
                            className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                            style={{ flex: "1 1 110px", textAlign: "center" }}
                        >
                            Origem
                        </div>
                        <div
                            className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                            style={{ flex: "1 1 110px", textAlign: "center" }}
                        >
                            Data
                        </div>
                        <div
                            className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                            style={{ flex: "1 1 110px", textAlign: "center" }}
                        >
                            Status
                        </div>
                        <div
                            className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                            style={{ flex: "0 1 70px", textAlign: "center" }}
                        />
                    </div>
                    {cards}
                </div>
            )
        }
    }

    if (pageState.fetchedData) {
        return (
            <div style={{ width: "auto", paddingBottom: "30px" }}>
                <div style={{ display: "flex" }}>
                    <div>
                        <p className={["blueText", "titleSize"].join(" ")}>BLOQUEIOS</p>
                    </div>
                    <div style={{ margin: "auto 20px auto auto" }}>
                        {user_data.roles.includes('card_issuance_lock_analyzer') ?
                            <div
                                className={["normalText", "button", "standard"].join(" ")}
                                onClick={handleOpenNewLockClick}
                            >
                                + NOVO BLOQUEIO
                            </div> : null
                        }
                    </div>
                </div>
                <ErrorBoundary doNothing>
                    <Filter
                        local_storage_object_preffix={local_storage_object_preffix}
                        initialFilters={initialFilters}
                        setPageParams={setPageParams}
                    />
                </ErrorBoundary>
                <div>{contentContainer(pageState.fetchedData)}</div>
                <div>
                    <Pagination
                        className="pagination"
                        page={pageParams.page}
                        count={parseInt(pageState.fetchedData["number_of_pages"])}
                        onChange={(e, page) => handleChangePage(e, page, pageParams.filters)}
                    />
                </div>
                <NewLockDialog
                    open={openNewLock}
                    onClose={handleNewLockDialogClose}
                    user_data={user_data}
                    setPageParams={setPageParams}
                    local_storage_object_preffix={local_storage_object_preffix}
                />
            </div>
        )
    }
    else if (pageState.isError) {
        return (
            <div style={{ width: "auto" }}>
                <div style={{ display: "flex" }}>
                    <div>
                        <p className={["blueText", "titleSize"].join(" ")}>BLOQUEIOS</p>
                    </div>
                    <div style={{ margin: "auto 20px auto auto" }}>
                        {user_data.roles.includes('card_issuance_lock_analyzer') ?
                            <div
                                className={["normalText", "button", "standard"].join(" ")}
                                onClick={handleOpenNewLockClick}
                            >
                                + NOVO BLOQUEIO
                            </div> : null
                        }
                    </div>
                </div>
                <ErrorBoundary doNothing>
                    <Filter
                        local_storage_object_preffix={local_storage_object_preffix}
                        initialFilters={initialFilters}
                        setPageParams={setPageParams}
                    />
                </ErrorBoundary>
                <div style={{ height: "55vh", display: "flex" }}>
                    <p className={["blueText", "titleSize"].join(" ")} style={{ margin: "auto", textAlign: "center" }}>
                        {pageState.errorMessage}
                    </p>
                </div>
                <NewLockDialog
                    open={openNewLock}
                    onClose={handleNewLockDialogClose}
                    user_data={user_data}
                    setPageParams={setPageParams}
                    local_storage_object_preffix={local_storage_object_preffix}
                />
            </div>
        )
    }
    else if (pageState.isLoading) {
        return (
            <div style={{ width: "auto" }}>
                <div style={{ display: "flex" }}>
                    <div>
                        <p className={["blueText", "titleSize"].join(" ")}>BLOQUEIOS</p>
                    </div>
                    <div style={{ margin: "auto 20px auto auto" }}>
                        {user_data.roles.includes('card_issuance_lock_analyzer') ?
                            <div
                                className={["normalText", "button", "standard"].join(" ")}
                                onClick={handleOpenNewLockClick}
                            >
                                + NOVO BLOQUEIO
                            </div> : null
                        }
                    </div>
                </div>
                <div className='circularProgressCenter'>
                    <CircularProgress />
                </div>
                <NewLockDialog
                    open={openNewLock}
                    onClose={handleNewLockDialogClose}
                    user_data={user_data}
                    setPageParams={setPageParams}
                    local_storage_object_preffix={local_storage_object_preffix}
                />
            </div>
        )
    }
    else return null

}

export default LocksInbox