import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "8px"
  },
  label: {
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#141414",
  },
  data: {
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#6F6F6F",
  },
}));


  function FilterEntry(props) {
    const classes = useStyles();
    useTheme();
    const { t } = useTranslation();
    let filterValue = props.data;
    let is_date_field = ['initial_date','final_date'].includes(props.name)

    if (props.name === 'csv_rows_limit') {
        return null
    }

    if (filterValue === null) {
        return null
    }

  return (
    <div className={classes.container}>
      <span className={classes.label}>{t(props.name)}: </span>
      {!is_date_field && <span className={classes.data}>{t(filterValue)}</span>}
      {is_date_field && <span className={classes.data}>{t("date_format", {date_format: props.data})}</span>}
    </div>
  );
}

export default FilterEntry;
