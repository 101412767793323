import React, {useEffect} from "react";
import Collapse from "@material-ui/core/Collapse";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import IconButton from "@material-ui/core/IconButton";
import SCRTable from "../../../Cards/SCRTable/SCRTable";
import DoughnutChart from '../../../utils/Components/DoughnutChart'
import { colorBeige, colorBlueQIShadow, colorPinkQIShadow, formatAsDate, getMapRiskCalculationTreeObj, getTotalBRLandForeignCurrencyObj, formatAsMoney, getMappedDatasetObj, handleHTML2Img } from "../../../utils/utils";
import moment from 'moment'
import LineChart from "../../../utils/Components/LineChart";
import BarChart from "../../../utils/Components/BarChart";
import SCRError from "../../../Cards/SCRTable/SCRError";
import { useTranslation } from "react-i18next";
import DataField from "../../../../@GeneralComponents/DataField";
import NumberDescription from "../../../../@GeneralComponents/NumberDescription";

function SCR(props) {
    const { t } = useTranslation();

    const {scr, setExpandedSCR, expandedSCR, preparePDF, setScrToExpire2Img, setScrOverdue2Img, setScrHistoryTotal2Img, setScrHistoryToExpireOverdue2Img, setHasSCRImg, setHasSCRHistoryImg} = props

    const {scr_data, start_relationship_date, report_start_date, report_end_date} = scr

    let scr_data_no_error = scr_data.filter(scr => (scr||{}).validation_error_message == null)

    const handleExpand = (event) => {
        event.stopPropagation();
        setExpandedSCR(!expandedSCR);
    };

    let showSCRdata = scr_data_no_error.length > 0
    let hasSCRHistory = scr_data.length > 1 
    let hasSCRHistoryNoErrors = scr_data_no_error.length > 1 

    const dataset_list = hasSCRHistoryNoErrors && getMappedDatasetObj(scr_data_no_error) 

    let scr_data_ref_month = scr_data_no_error.splice(0,1)

    const mapped_risk_obj = showSCRdata ? getMapRiskCalculationTreeObj((scr_data_ref_month[0] || {}).risk_calculation_tree) : ""
    const mapped_risk_obj_foreign_currency = showSCRdata ? getMapRiskCalculationTreeObj((scr_data_ref_month[0] || {}).risk_calculation_tree_foreign_currency) : ""

    const mapped_total_risk_obj = showSCRdata ? getTotalBRLandForeignCurrencyObj(mapped_risk_obj, mapped_risk_obj_foreign_currency) : ""

    let credit_to_be_expired_mod01_to_13_sum_formatted = showSCRdata ? formatAsMoney("BRL", mapped_total_risk_obj['credit_to_be_expired_modality_01_to_13_sum'])  : ""
    let credit_overdue_mod01_to_13_sum_formatted = showSCRdata ? formatAsMoney("BRL", mapped_total_risk_obj['credit_overdue_modality_01_to_13_sum']) : ""
    let loan_portfolio_sum_formatted = showSCRdata ? formatAsMoney("BRL", mapped_total_risk_obj['loan_portfolio_sum']) : ""
    let score_to_be_expired = showSCRdata ? mapped_total_risk_obj['score_to_be_expired'] : ""
    let score_overdue = showSCRdata ? mapped_total_risk_obj['score_overdue'] : ""

    let scr_data_history =  showSCRdata && scr_data.filter(scr => (scr || {}).reference_date !== scr_data_ref_month[0].reference_date)
  
    useEffect(()=>{
        if(showSCRdata && preparePDF){
            setHasSCRImg(true)
            handleHTML2Img('scr2Img', setScrToExpire2Img)
            handleHTML2Img('scr2Img2', setScrOverdue2Img)
        }
        if(hasSCRHistoryNoErrors && preparePDF){
            setHasSCRHistoryImg(true)
            handleHTML2Img('scr_history_total_to_img', setScrHistoryTotal2Img)
            handleHTML2Img('scr_history_to_expire_overdue_to_img', setScrHistoryToExpireOverdue2Img)
        }
    })

    return (
        <>
        <div className="analysisCard" style={{display: 'flex'}}>
        {showSCRdata 
            ? <div style={{display:"flex"}}>
                <div 
                    className={["blueText", "subtitleSize", "bold"].join(" ")}
                    style={{margin:"auto 0px"}}
                >
                    {t("scr_title")}
                </div>
                    <IconButton style={{marginLeft: "auto"}} onClick={handleExpand}>
                        {expandedSCR ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </IconButton>  
            </div>
            : <div style={{display:"flex"}}>
                <div 
                    className={["blueText", "subtitleSize", "bold"].join(" ")}
                    style={{margin:"15px 0px 10px 0px"}}
                >
                    {t("scr_title")}
                </div>
            </div>
        }
        {showSCRdata 
            ? <Collapse in={expandedSCR} timeout="auto" unmountOnExit>
                <div style={{display:"grid", gridTemplateColumns: '31.5% 67.5%', gap:"20px"}}>
                    <div className="analysisCard">
                        <div className={["blueText", "subtitleSize"].join(" ")} style={{marginBottom:"10px"}}>{t("general")}</div>
                        {report_start_date&&report_end_date&&
                        <DataField
                            title={t("consulted_period")}
                            label={formatAsDate(report_start_date)+" - "+formatAsDate(report_end_date)}
                            size={"normalMediumSize"}
                            titleNoWrap={false}
                        />}
                        {start_relationship_date&&
                        <DataField
                            title={t("relationship_start_date")}
                            label={moment(start_relationship_date).format("DD/MM/YYYY")}
                            size={"normalMediumSize"}
                            titleNoWrap={false}
                        />}
                    </div>
                    <div className="analysisCard">
                        <div className={["blueText", "subtitleSize"].join(" ")} style={{marginBottom:"10px"}}>{t("month_data")}</div>
                        <div style={{display:"grid", gridTemplateColumns: "30% 17.5% 17.5% 17.5% 17.5%"}}>
                            <div className="flexColumnContainer">
                                {(scr_data_ref_month[0]||{}).reference_date&&
                                <DataField
                                    title={t("reference_month")}
                                    label={formatAsDate((scr_data_ref_month[0] || {}).reference_date)}
                                    size={"normalMediumSize"}
                                    titleNoWrap={false}
                                />}
                                {(scr_data_ref_month[0]||{}).financial_institution_count&&
                                <DataField
                                    title={t("financial_institution_count")}
                                    label={(scr_data_ref_month[0] || {}).financial_institution_count}
                                    size={"normalMediumSize"}
                                    titleNoWrap={false}
                                />}
                                {mapped_total_risk_obj&&
                                <DataField
                                    title={t("total_responsability_per_total_risk")}
                                    label={mapped_total_risk_obj['total_risk']?
                                    `${((mapped_total_risk_obj['total_responsability_sum']/mapped_total_risk_obj['total_risk'])*100).toFixed(2).replace(".", ",")} %`
                                    :"0,00%"}
                                    size={"normalMediumSize"}
                                    titleNoWrap={false}
                                />}
                            </div>
                            <NumberDescription
                                total={mapped_total_risk_obj['loan_portfolio_sum']?
                                `${((mapped_total_risk_obj['credit_to_be_expired_modality_01_to_13_sum']/mapped_total_risk_obj['loan_portfolio_sum'])*100).toFixed(2).replace(".", ",")} %`
                                :"0,00 %"}
                                description={t("credit_to_be_expired_per_loan_portfolio")}
                                secondDescription={`${credit_to_be_expired_mod01_to_13_sum_formatted}/ ${loan_portfolio_sum_formatted}`}
                            />
                            <div id="scr2Img">
                                <DoughnutChart
                                    cutout={0}
                                    size='100px'
                                    backgroundColor={[colorBeige,colorBlueQIShadow]}
                                    dataValue={[(100 - score_to_be_expired), score_to_be_expired]}
                                    chartTitle={t("credit_to_be_expired_per_loan_portfolio")}
                                    id="credit_to_be_expired_per_loan_portfolio"
                                />
                            </div>
                            <NumberDescription
                                total={mapped_total_risk_obj['loan_portfolio_sum']?
                                `${((mapped_total_risk_obj['credit_overdue_modality_01_to_13_sum']/mapped_total_risk_obj['loan_portfolio_sum'])*100).toFixed(2).replace(".", ",")} %`
                                :"0,00 %"}
                                description={t("overdue_credit_loan_portfolio")}
                                secondDescription={`${credit_overdue_mod01_to_13_sum_formatted}/ ${loan_portfolio_sum_formatted}`}
                            />
                            <div id="scr2Img2">
                                <DoughnutChart
                                    cutout={0}
                                    size='100px'
                                    backgroundColor={[colorBeige,colorPinkQIShadow]}
                                    dataValue={[(100 - score_overdue), score_overdue]}
                                    chartTitle={t("overdue_credit_loan_portfolio")}
                                    id="overdue_credit_loan_portfolio"
                                />
                            </div>
                        </div>
                        <div className={["labelText", "normalSmallSize"].join(" ")} style={{fontStyle: 'italic', textAlign:"right"}}>
                            {t("amount_subject_to_exchange_rate_variation")}
                        </div>
                    </div>
                </div>



                <div >
                    <SCRTable scr_data={scr_data_ref_month[0]} showDropDown={false}/>
                </div>
                {hasSCRHistory && 
                    <>
                        <div style={{display: 'flex', margin: '30px 10px'}}>
                            <div 
                                className={["blueText", "subtitleSize", "bold", 'internalColapse'].join(" ")}
                                style={{margin:"auto 0px"}}
                            >
                                {t("historic")}
                            </div>  
                        </div>
                        {hasSCRHistoryNoErrors && 
                            <div style={{display: 'flex', flexDirection: 'row', margin: '10px', maxWidth: '100%'}}> 
                                <div id="scr_history_to_expire_overdue_to_img" className="internalCardContainer" style={{margin:'0px 20px', width: '45%'}}>
                                    <div 
                                        className={["blueText", "normalMediumSize", "bold", 'internalColapse'].join(" ")}
                                        style={{margin:"0px 20px", padding:"10px 40px", textAlign: 'center'}}
                                    >
                                        {t("credit_to_be_expired_and_credit_overdue_per_month")}
                                    </div>  
                                    <LineChart 
                                        id="scr_history_toBeExpired_overdue_chart"
                                        labels = {dataset_list.dataset_labels}
                                        dataValue_toBeExpired = {dataset_list.dataset_to_be_expired}
                                        dataValue_overdue = {dataset_list.dataset_overdue}
                                    />
                                </div>
                                <div id="scr_history_total_to_img" className="internalCardContainer" style={{margin:'0px 20px', width: '45%'}}>
                                <div 
                                        className={["blueText", "normalMediumSize", "bold", 'internalColapse'].join(" ")}
                                        style={{margin:"0px 20px", padding:"10px 40px", textAlign: 'center'}}
                                    >
                                        {t("total_responsability_per_total_risk")}
                                    </div> 
                                    <BarChart
                                        id="scr_history_total_risk_chart"
                                        labels = {dataset_list.dataset_labels}
                                        label1={t("total_responsability_per_total_risk")}
                                        dataValue1 = {dataset_list.dataset_total}
                                    />
                                </div>
                            </div>}
                            {scr_data_history.map((scr, index) => 
                            {return (scr.operation_items.length===0||scr.operation_items[0].length===0) 
                                ? <SCRError key={index} scr_data={scr} showDropDown={true}/>
                                : <SCRTable key={index} scr_data={scr} showDropDown={true} ref_month_reference_date={(scr_data_ref_month[0] || {}).reference_date}/>
                            }
                        )}
                    </>}
            </Collapse>
            : 
            <div 
                className={["labelText", "normalMediumSize", 'internalColapse'].join(" ")}
                style={{margin:"10px"}}
            >
                {scr_data.map((scr, index) => 
                    <SCRError key={index} scr_data={scr} showDropDown={true} initialExpand={true}/>
                    )}
            </div>  
        }
        </div>
        </>
    );
}

export default SCR