import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {Paper, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import { formatAsMoney } from "../../../utils/utils";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
  },
  tableText: {
    fontFamily: "Open Sans",
    fontWeight: 'normal',
    fontSize: "12px",
    lineHeight: "16px",
    color: "#141414",
    textAlign: "center",
    margin: "10px",
    padding: "6px",
    maxWidth: "80px"
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: '#6F6F6F',
    fontSize: "14px",
    lineHeight: "19px",
    padding: "6px",
    maxWidth: "80px"
  },
  even: {
    background: "#F3F3F3",
  },
  odd: {
    background: "#FFFFFF",
  },
}));

function DAFNTable(props) {
  const { t } = useTranslation();

  const classes = useStyles();
  useTheme();

  const getClass = (rowIndex) => {
    if (rowIndex % 2 === 0) {
      return classes.even;
    }

    return classes.odd;
  };

  const isLegalPerson = props.person_type === "legal"

  return (
    <Paper className={classes.tableStyle}>
      <Table style={{ padding: "8px" }}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader}>{t("registration_date")}</TableCell>
            <TableCell className={classes.tableHeader}>{isLegalPerson ? t("CNPJ") : t("CPF")}</TableCell>
            <TableCell className={classes.tableHeader}>{t("name")}</TableCell>
            <TableCell className={classes.tableHeader}>{t("debt_origem")}</TableCell>
            <TableCell className={classes.tableHeader}>{t("on_trial")}</TableCell>
            <TableCell className={classes.tableHeader}>Status</TableCell>
            <TableCell className={classes.tableHeader}>{t("debtor_type")}</TableCell>
            <TableCell className={classes.tableHeader}>{t("unit_in_charge")}</TableCell>
            <TableCell className={classes.tableHeader}>{t("amount")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.dafn_data.map((debt,index) => (
            <TableRow
              key={index}
              className={getClass(index)}
            >
              <TableCell className={classes.tableText}>
                {debt.date == null ? "-" : t("date_format", {date_format: debt.date})}
              </TableCell>
              <TableCell style={{whiteSpace:"nowrap"}} className={classes.tableText}>
                {debt.document_number}
              </TableCell>
              <TableCell className={classes.tableText}>
                {debt.name}
              </TableCell>
              <TableCell className={classes.tableText}>
                {t(debt.mainly_revenue)}
              </TableCell>
              <TableCell className={classes.tableText}>
                {debt.judged === true ? t("yes") : debt.judged === false ? t("no") : "-"}
              </TableCell>
              <TableCell className={classes.tableText}>
                {debt.status}
              </TableCell>
              <TableCell className={classes.tableText}>
                {t(debt.debtor_type)}
              </TableCell>
              <TableCell className={classes.tableText}>
                {debt.city == null ? "-" : debt.city + " - "+ debt.uf}
              </TableCell>
              <TableCell className={classes.tableText}>
                {formatAsMoney("BRL", debt.value)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default DAFNTable;
