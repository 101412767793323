import React, {useState, useEffect, useContext} from 'react'
import { useTranslation } from "react-i18next";
import {GetAlertsComponent,setObjectByPath,translateType} from "./utils"
import TextField from "@material-ui/core/TextField";
import MenuItem from '@material-ui/core/MenuItem';
import _ from "lodash"
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import RulesContext from "../../../../context/rules-context"
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export function OCRDataRetrievalBox (props) {
    const { t } = useTranslation();
    let {node} = props
    let {read_only} = props
    let {handleConfigurationOpen} = props
    let {handleNodeRemove} = props

    let template = node.node.properties.template
    let ocr_data_path = node.node.properties.ocr_data_path
    let box_settings = useContext(RulesContext).boxes[node.node.type].settings[template]

    let templateDescription = (box_settings || {description:""}).description 
    let ocrData = (templateDescription === "" 
                        ? 
                        "" 
                        :
                        box_settings.mapping.filter(ocrData => ocrData.path === ocr_data_path)[0])

    return (
        <div>
            {!read_only ?
                <div style={{display:"flex", alignItems: "center"}}>
                    <div 
                        style={{display:"flex", width:"100%", cursor:"pointer", margin: "0px"}} 
                        onClick={() => handleConfigurationOpen(node)}
                    >
                        <div style={{display: "flex",flexDirection: "column",margin: "auto 0px"}}>
                            <div className={["normalText","normalMediumSize"].join(" ")}>
                            {t(templateDescription)}
                            </div>
                            <div className={["normalText","normalSmallSize"].join(" ")}>
                            {t((ocrData || {"description": ""}).description)}
                            </div>
                        </div>
                        {(node.node.alerts || []).length > 0?
                        <GetAlertsComponent alerts={node.node.alerts} />  : null}       
                    </div>
                    <IconButton style={{width: 32, height: 32}} aria-label="duplicate" onClick={() => props.handleNodeDuplication(node)}>
                        <ContentCopyIcon style={{width: 20, height: 20}} />
                    </IconButton>
                    <IconButton style={{width: 32, height: 32}} aria-label="close" onClick={() => handleNodeRemove(node)}>
                        <CloseIcon style={{width: 20, height: 20}} />
                    </IconButton>
                </div>
                :
                <div style={{display:"flex"}}>
                    <div 
                        style={{display:"flex", width:"100%", cursor:"pointer", margin: "0px"}} 
                        onClick={() => handleConfigurationOpen(node)}
                    >
                        <div style={{display: "flex",flexDirection: "column",margin: "auto 0px"}}>
                            <div className={["normalText","normalMediumSize"].join(" ")}>
                            {t(templateDescription)}
                            </div>
                            <div className={["normalText","normalSmallSize"].join(" ")}>
                            {t((ocrData || {"description": ""}).description)}
                            </div>
                        </div>      
                    </div>
                </div>
            }
        </div>
    )
}

export function OCRDataRetrievalConfiguration(props){
    const { t } = useTranslation();
    let {node} = props
    let {read_only} = props
    let {handleNodeChange} = props
    let {handleConfigurationClose} = props

    let ocrOptions = useContext(RulesContext).boxes[node.node.type].settings

    const [nodeState, setNodeState] = useState(node)

    useEffect(()=>{
        setNodeState(node)
    },[node])

    const handleTemplateChange = (e) => {
        let new_node = _.cloneDeep(nodeState)
        setObjectByPath(new_node.node,"properties.template",e.target.value)
        new_node.node.properties.ocr_data_path = ""
        setNodeState(new_node)
    }

    const handleOCRDataChange = (e) => {
        let new_node = _.cloneDeep(nodeState)
        setObjectByPath(new_node.node,"properties.ocr_data_path",e.target.value)
        setNodeState(new_node)
    }

    const handleSave = () => {
        handleConfigurationClose()
        handleNodeChange(nodeState)
    }

    const validateAllValues = () => {
        let ocr_data_path_validation = nodeState.node.properties.ocr_data_path != null && nodeState.node.properties.ocr_data_path !== ""
        let template_validation = nodeState.node.properties.template != null && nodeState.node.properties.template !== ""
        return ocr_data_path_validation && template_validation
    }

    return(
        <div style={{flexGrow:"1", padding: "20px", display:"flex", flexDirection:"column"}}>
            <div style={{display: "flex"}}>
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>{t(node.node.description)}</span>
                <Tooltip title={<div className="tooltipText"><ul>{node.node.helperText.map((help,index) => (<li key={index}>{t(help)}</li>))}</ul></div>}>
                    <HelpIcon style={{fontSize: "14px", display:"flex", margin:"auto 5px"}}/>
                </Tooltip>
            </div>
            <div className="internalCardContainer" style={{display:"flex", flexDirection: "column", width:'300px'}}>
                <TextField
                    label={t("Template")}
                    style={{textAlign:"center", width:"80%", margin:"3px auto"}}
                    className="filterTextbox" 
                    size="small" fullWidth 
                    value={nodeState.node.properties.template}
                    onChange={handleTemplateChange}
                    select
                    InputProps={{
                        readOnly: read_only
                      }}
                >
                    {Object.keys(ocrOptions).map((template, index) => (
                        <MenuItem key={index} value={template}><em className="normalText">{t(ocrOptions[template].description)}</em></MenuItem>
                    ))}
                </TextField>             
            </div>
            <div className="internalCardContainer" style={{display:"flex", flexDirection: "column", width:'300px'}}>
                <TextField
                    label={t("Campo")}
                    style={{textAlign:"center", width:"80%", margin:"3px auto"}}
                    disabled={nodeState.node.properties.template == null || nodeState.node.properties.template === ""}
                    className="filterTextbox" 
                    size="small" fullWidth 
                    value={nodeState.node.properties.ocr_data_path}
                    onChange={handleOCRDataChange}
                    select
                    InputProps={{
                        readOnly: read_only
                      }}
                >
                    {(ocrOptions[nodeState.node.properties.template] || {"mapping":[]}).mapping.map((ocrData, index) => (
                        <MenuItem key={index} value={ocrData.path}><em className="normalText">{t(ocrData.description) + " ("+t(translateType(ocrData.type))+")"}</em></MenuItem>
                    ))}
                </TextField>             
            </div>
            <div style={{display:"flex", margin: "auto auto 5px auto"}}>
                <div
                    className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")}
                    style={{width:"120px"}}
                    onClick={handleConfigurationClose}
                >
                    {read_only ? "X" : t("discard")}
                </div>
                {!read_only && 
                <div
                    className={
                        validateAllValues()
                        ?
                        ["button", "standard", "normalText", "normalMediumSize"].join(" ")
                        :
                        ["button", "standard", "normalText", "normalMediumSize","disabled"].join(" ")}
                    style={{width:"120px"}}
                    onClick={validateAllValues() ? handleSave : null}
                >
                    {t("save")}
                </div>}   
            </div>
        </div>
    )
}

export class OCRDataRetrievalBoxClass {
    constructor(rulesContext){
        this.rulesContext = rulesContext
    }
    validate(node){
        let put_error = false
        let alerts = []
        //Validate if node has no children
        if((node.node.children || []).length !== 0){
            put_error = true
            alerts.push("Este nó não deve possuir filhos")
        }
        if(!node.node.properties.template || node.node.properties.template === ""){
            put_error = true
            alerts.push("Clique no nó deste Dados do OCR, abra a configuração e escolha um template válido")
        }
        if(!node.node.properties.ocr_data_path || node.node.properties.ocr_data_path === ""){
            put_error = true
            alerts.push("Clique no nó deste Dados do OCR, abra a configuração e escolha um campo válido")
        }
        //Validate if parent is valid
        if (node.parentNode != null) {
            if(node.parentNode.on_error){
                put_error = true
                alerts.push("O nó superior está inválida")
            }
        }
        return {validated: !put_error, alerts: alerts}
    }

    getType(node){
        let template = node.properties.template
        let ocr_data_path = node.properties.ocr_data_path
        let box_settings = this.rulesContext.boxes[node.type].settings[template]
        let ocrData = box_settings ? box_settings.mapping.filter(ocrData => ocrData.path === ocr_data_path)[0] : undefined
        return (ocrData || {type:""}).type
    }
}