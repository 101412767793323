import React, {useState, useContext} from "react";
import Collapse from "@material-ui/core/Collapse";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import IconButton from "@material-ui/core/IconButton";
import DataField from "../../utils/DataField"
import {getInfractionReportType, getInfractionReportStatus, getInfractionReportCreator} from "../../utils/utilsInfractionReport"
import {clientAge} from "../../utils/utils"
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import moment from 'moment'
import InfractionDialog from "./InfractionDialog/InfractionDialog"
import AuthContext from "../../../../context/auth-context"

const useStyles = makeStyles((theme) => ({
    tableStyle: {
      border: "none",
      boxShadow: "none",
    },
    tableText: {
      fontFamily: "Open Sans",
      fontWeight: 'normal',
      fontSize: "12px",
      lineHeight: "16px",
      color: "#141414",
      textAlign: "center",
      margin: "10px",
      padding: "6px",
      minWidth: "120px"
    },
    tableHeader: {
      textAlign: "center",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: "normal",
      color: '#6F6F6F',
      fontSize: "14px",
      lineHeight: "19px",
      padding: "6px",
    },
    even: {
      background: "#F3F3F3",
    },
    odd: {
      background: "#FFFFFF",
    },
  }));


function getPossibleActions(creator, actual_status,roles){
    let actionObject
    let possibleActions = []
    if (roles.includes("update_pix_transactions")){
        if (creator === "client" && actual_status !== "created") {
            actionObject = {
                buttonClass: "cancel",
                buttonAction: "cancelled",
                buttonDescription: "Cancelar Relato"
            }
            possibleActions.push(actionObject)
        }
        else if(creator === "external"){
            if (actual_status === "received"){
                actionObject = {
                    buttonClass: "onlyboarder",
                    buttonAction: "acknowledged",
                    buttonDescription: "Reconhecer Relato"
                }
                possibleActions.push(actionObject)
            }
            else if (actual_status === "acknowledged") {
                actionObject = {
                    buttonClass: "approved",
                    buttonAction: "closed_agreed",
                    buttonDescription: "Confirmar Relato"
                }
                possibleActions.push(actionObject)
                actionObject = {
                    buttonClass: "reproved",
                    buttonAction: "closed_disagreed",
                    buttonDescription: "Negar Relato"
                }
                possibleActions.push(actionObject)
            }
        }
    }
    return possibleActions
}

function InfractionReportsCard(props) {
    const classes = useStyles();
    useTheme();

    let roles = useContext(AuthContext).user_data.roles

    const {transaction_key} = props.transaction
    const {infraction_reports} = props.transaction

    const [expanded, setExpanded] = useState(false);

    const [infractionReportKey, setInfractionReportKey] = useState(null);
    const [infractionNewStatus, setInfractionNewStatus] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);

    const handleButtonClick = (new_status, infraction_report_key) => {
        setInfractionReportKey(infraction_report_key)
        setInfractionNewStatus(new_status)
        setOpenDialog(true)
    }

    const handleDialogClose = () => {
		setOpenDialog(false);
	  };

    const handleExpand = (event) => {
        event.stopPropagation();
        setExpanded(!expanded);
    };

    const getClass = (rowIndex) => {
        if (rowIndex % 2 === 0) {
          return classes.even;
        }
    
        return classes.odd;
      };   

    return (
        <div className="analysisCard">
            <div style={{display:"flex"}}>
                <div 
                    className={["blueText", "subtitleSize", "bold"].join(" ")}
                    style={{margin:"auto 0px"}}
                >
                    Relatos
                </div>
                <IconButton style={{marginLeft: "auto"}} onClick={handleExpand}>
                    {expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </IconButton>                             
            </div>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
            <div className={["internalCollapse", "oneColumn"].join(" ")}>
                {infraction_reports.map((report, index) => (
                    <div key={`report-${index}`} className="internalCardContainer" style={{display:"flex", flexDirection: "column"}}>
                        <DataField
                            title={"Tipo:"}
                            label={getInfractionReportType(report.infraction_report_type)}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="5px"
                        />
                        <DataField
                            title={"Status:"}
                            label={getInfractionReportStatus(report.infraction_report_status)}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="5px"
                        />
                        <DataField
                            title={"Criador:"}
                            label={getInfractionReportCreator(report.infraction_report_creator)}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="5px"
                        />
                        <DataField
                            title={"Data de Criação:"}
                            label={clientAge(report.infraction_report_date, null, "years_months")}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="5px"
                        />
                        <DataField
                            title={"Detalhes:"}
                            label={report.infraction_report_details}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="5px"
                        />
                        <div className={["internalCardContainer", "blueText", "normalMediumSize"].join(" ")}>
                            Eventos do Relato
                        </div>
                        <div>
                            <Paper className={classes.tableStyle}>
                            <Table style={{ padding: "8px" }}>
                                <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tableHeader}>Status</TableCell>
                                    <TableCell className={classes.tableHeader}>Data</TableCell>
                                    <TableCell className={classes.tableHeader}>Analista Responsável</TableCell>
                                    <TableCell className={classes.tableHeader}>E-mail</TableCell>
                                    <TableCell className={classes.tableHeader}>Observações</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {(report.infraction_report_events || []).map((event,index) => (
                                    <TableRow
                                        key={index}
                                        className={getClass(index)}
                                    > 
                                    <TableCell className={classes.tableText}>
                                        {getInfractionReportStatus(event.new_status)}
                                    </TableCell>
                                    <TableCell className={classes.tableText}>
                                        {moment(event.event_date).format('DD/MM/YYYY')}
                                    </TableCell>
                                    <TableCell className={classes.tableText}>
                                        {event.analyst_name || "Não Aplicável"}
                                    </TableCell>
                                    <TableCell className={classes.tableText}>
                                        {event.analyst_email || "Não Aplicável"}
                                    </TableCell>
                                    <TableCell className={classes.tableText}>
                                        {event.observation || "-"}
                                    </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                            </Paper>
                        </div>
                        {getPossibleActions(report.infraction_report_creator, report.infraction_report_status,roles).length > 0?
                        <div className="internalCardContainer" style={{display: "flex"}}>
                            {getPossibleActions(report.infraction_report_creator, report.infraction_report_status,roles).map((action, index) => (
                                <div 
                                    key={`action-${index}`}
                                    className={["button", action.buttonClass, "normalText", "normalMediumSize"].join(" ")}
                                    style={index === 0 ? {marginLeft:"auto"}: null}
                                    onClick={() => handleButtonClick(action.buttonAction, report.infraction_report_key)}
                                >
                                    {action.buttonDescription}
                                </div>
                            ))}
                        </div> : null}
                    </div>))}
                </div>
            </Collapse> 
            <InfractionDialog
                open={openDialog}
                onClose={handleDialogClose}
                infraction_new_status={infractionNewStatus}
                infraction_report_key={infractionReportKey}
                transaction_key={transaction_key}
            />
        </div>
    );
}

export default InfractionReportsCard