import React from "react";
import { useTranslation } from "react-i18next";
import ValidatedImage from "../../utils/ValidatedImage.js"

function FaceReconCard(props){

    let { name, registration_key } = props

    const { t } = useTranslation()
    
    return (
        <div className="analysisCard" style={{height: "100%", marginRight: "20px"}}>
            <div className={["blueText", "subtitleSize"].join(" ")}>{t(name)}</div>
            <div className="internalCardContainer" style={{display:"flex", flexGrow:"1", flexDirection: "row", maxHeight: "300px"}}>
                <div style={{display:"flex", width:"100%", height:"100%", flexDirection: "column"}}>
                    <div style={{display:"block", width:"100%", height:"100%", margin: "0 auto", overflow:"hidden", position:"relative", alignItems: "center", justifyContent: "center",borderRadius: "5px" }}>
                        <ValidatedImage
                            image_key={registration_key}
                            dash_api_endpoint_url={'/dash/face_recognition/image'}
                            imageStyle = {{margin:"auto", width:"100%", height:"100%", borderRadius: "5px", objectFit:"cover"}}
                            imageAlt = {t(name)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}


export default FaceReconCard
