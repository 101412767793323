import React from "react";
import {Cell, BarChart, Bar } from 'recharts';
import getSymbolFromCurrency from 'currency-symbol-map';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import {fieldMapper, sum} from "../../utils/utils";


function TinyBarChart (props){
    //Defining graph information and initial state
    const weeklyValue   = props.data.map(item => fieldMapper(item,props.field))
    const weekDateRange = props.data.map(item => fieldMapper(item,"date"))
    const totalValue    = weeklyValue.reduce(sum)

    //Defining graph state handler
    const [activeIndex, setActiveIndex] = React.useState(null)
    const [activeItem, setActiveItem] = React.useState(totalValue)
    const [activeDate, setActiveDate] = React.useState("Últimas " + weeklyValue.length + " Semanas")


    //Defining click action handler
    const handleClick = (data,index) => {
        if (activeIndex === index) {
            setActiveIndex(null)
            setActiveItem(totalValue)
            setActiveDate("Últimas " + weeklyValue.length + " Semanas")
        }
        else {
            setActiveIndex(index)
            setActiveItem(weeklyValue[index])
            setActiveDate(weekDateRange[index])

        }
    }

    return (
    <div>
        <BarChart width={150} height={70} data={props.data}>
            <Bar dataKey={props.field} onClick={handleClick}>
                {
                props.data.map((entry, index) => (
                    <Cell cursor="pointer" fill={index === activeIndex ? '#154058' : '#0b1f82' } key={`cell-${index}`}/>
                ))
                }
            </Bar>    
        </BarChart>
        <div style={{marginRight:"30px", marginTop:"0px"}} className="internalCardContainer">
            <div className={["labelText", "normalMediumSize", "bold"].join(" ")}>
                {props.text}
            </div>
            {props.isMoney ? 
            <div className={["normalText", "moneySize", "bold"].join(" ")}>
                {activeItem == null ? "-" : getSymbolFromCurrency(props.currency) + " " + (activeItem/100).toLocaleString('de-DE', {minimumFractionDigits: 2})}
            </div> :
            <div className={["normalText", "moneySize", "bold"].join(" ")}>
                {activeItem == null ? "-" : activeItem}
            </div>
            }
            <div style={{display:"flex", flexDirection:"row"}} className={["labelText", "normalSmallSize", "bold"].join(" ")}>
                <CalendarTodayIcon fontSize={"inherit"}/>
                <div style={{marginLeft:"5px"}}>
                    {activeDate}
                </div>
            </div>
         </div>
    </div>
    );
}

export default TinyBarChart
