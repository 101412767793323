import React from 'react'
import DataField from "../../utils/DataField"
import {formatAsMoneyUniversal} from "../../utils/utils"
import blueWireTransfer from '../../../../assets/cardEntryIcons/blueWireTransfer.svg';
import moment from 'moment'

function OperationCard(props) {

    let { operation } = props

    return (
        <div className="analysisCard">
            <div style={{display:"flex"}}>
                <img style={{margin:"auto 8px auto 0px"}} src={blueWireTransfer} alt="operations Logo"/>
                <div className={["blueText", "subtitleSize"].join(" ")}>Operação</div>
            </div>
            <div className="internalCardContainer" style={{display: "flex", flexDirection: "column"}}>
                <div className="internalCardContainer" style={{display:"flex", marginTop:"5px"}}>
                    <div style={{width:"100%", display: "flex", flexDirection: "row"}}>
                            <div style={{width:"50%"}}>
                                <div className={["blueText", "normalMediumSize", "bold"].join(" ")}>
                                    ORIGEM
                                </div>
                                <div className={["labelText", "normalMediumSize", "bold"].join(" ")}>
                                    {props.operation.source_country}
                                </div>
                                <div className={["normalText", "mediumMoneySize", "bold"].join(" ")} >
                                    {formatAsMoneyUniversal(operation.source_currency, operation.source_amount, "-")}
                                </div>
                            </div>
                    </div>
                </div>
                <div className="internalCardContainer" style={{display:"flex", marginTop:"5px"}}>
                    <div style={{width:"100%", display: "flex", flexDirection: "row"}}>
                        <div style={{width:"50%"}}>
                                <div className={["blueText", "normalMediumSize", "bold"].join(" ")}>
                                    DESTINO
                                </div>
                                <div className={["labelText", "normalMediumSize", "bold"].join(" ")}>
                                    {props.operation.destination_country}
                                </div>
                                <div className={["normalText", "mediumMoneySize", "bold"].join(" ")} >
                                    {formatAsMoneyUniversal(operation.destination_currency, operation.destination_amount, "-")}
                                </div>
                            </div>
                    </div>
                </div>
                <DataField
                    title={"Data da Operação"}
                    label={moment(operation.operation_date).format("DD/MM/YYYY")}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                />
                <DataField
                    title={"Horário da Operação"}
                    label={moment(operation.operation_date).format("HH:mm:ss")}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                />
                <DataField
                    title={"Origem do recurso"}
                    label={operation.resource_origin}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                />
                <DataField
                    title={"Número de cédulas"}
                    label={operation.source_number_of_bills}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                />
                
            </div>
        </div>
    )
}

export default OperationCard