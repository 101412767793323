import React from 'react'
import DataField from "../../utils/DataField";
import {formatAddress} from "../../utils/utils"
import merchantIcon from "../../../../assets/cardEntryIcons/MerchantIcon.svg"


function BranchCard(props) {
    let { operation } = props
    let branch = operation.branch

    return (
        <div className="analysisCard">
            <div style={{display:'flex'}}>
                <img style={{margin:"auto 8px auto 0px"}} src={merchantIcon} alt="people Logo"/>
                <div className={["blueText", "subtitleSize"].join(" ")}>
                    Casa de Câmbio
                </div>
            </div>
            <div className={["blueText", "normalSmallSize"].join(" ")}>
                {(branch || {}).id}
            </div>
            <DataField
                title={"Endereço"}
                label={formatAddress((branch|| {}).address, null)}
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="5px"
            />
        </div>
    )
}

export default BranchCard