import React, { useCallback, useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormField from "../../../utils/Components/FormComponents/FormField";
import { useTranslation } from "react-i18next";
import useInput from "../../../utils/hooks/use-input";
import { formatToBrMoney, justNumbers, valueIsNotZero } from "../../../utils/utils";
import Button from "../../../utils/Components/Button";
import FormDropdownList from "../../../utils/Components/FormComponents/FormDropdownList";

function ApproveDialog (props) {

    const { open, onClose, onSubmit, setAnalystInput, loading } = props;

    const { t } = useTranslation()

    const {
        value: enteredMonthlyIncome,
        isValid: enteredMonthlyIncomeIsValid,
        hasError: monthlyIncomeInputHasError,
        valueChangeHandler: monthlyIncomeChangeHandler,
        inputBlurHandler: monthlyIncomeBlurHandler,
        reset: resetMonthlyIncomeInput,
    } = useInput(valueIsNotZero);

    const [enteredOccupation, setOccupation] = useState("REGISTERED_PRIVATE");
    const occupationList = ["REGISTERED_PRIVATE","REGISTERED_PUBLIC","RETIRED","SELF_EMPLOYED","EMPLOYER"]

    const occupationChangeHandler = (enteredOccupation) => {
        setOccupation(enteredOccupation);
    };
    
    const handleDialogClose = () => {
        onClose()
        resetMonthlyIncomeInput()
        setOccupation("REGISTERED_PRIVATE")
    }

    const formIsValid = useCallback(() => {
        if (enteredMonthlyIncomeIsValid&&enteredOccupation!=='') return true
        else return false
    },[enteredMonthlyIncomeIsValid,enteredOccupation])

    useEffect(()=>{
        if (formIsValid()) {
            setAnalystInput({
                "monthly_income": Number(justNumbers(enteredMonthlyIncome)),
                "occupation": String(enteredOccupation)
            })
        }
    },[formIsValid, setAnalystInput, enteredMonthlyIncome,enteredOccupation])

    const submitHandler = () => {
        onSubmit()
        resetMonthlyIncomeInput()
        setOccupation("REGISTERED_PRIVATE")
    }

    return(
        <Dialog
            open={open} 
            onClose={handleDialogClose}
            keepMounted={false}
        >           
            <DialogTitle 
                className={['blueText', "subtitleSize"].join(" ")} 
            >
                {t("rule_execution_approvement")}
            </DialogTitle>
            <DialogContent>
                <div style={{width:"400px",  display:"flex", flexDirection:"column", marginBottom:"30px"}}>
                    <form>
                        <FormField
                            labelValue={"monthly_income"}
                            onChange={monthlyIncomeChangeHandler}
                            onBlur={monthlyIncomeBlurHandler}
                            fieldValue={formatToBrMoney(justNumbers(enteredMonthlyIncome))}
                            hasError={monthlyIncomeInputHasError}
                            disabled={loading}
                        />
                        <FormDropdownList
                            listName={"occupation"}
                            items={occupationList}
                            onChangeFilter={occupationChangeHandler}
                            selected={enteredOccupation}
                            disabled={loading}
                        />
                    </form>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                        <Button
                            button_option={formIsValid()?"standard":"standard_disabled_gray"}
                            buttonLabel={t("submit_action")}
                            onClick={() => formIsValid()?submitHandler():null}
                        />
                    </div>
                </div>
            </DialogContent>        
        </Dialog>
    )
}

export default ApproveDialog;