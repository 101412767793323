import React from "react";

function Devices(props) {


  return (
    <React.Fragment>
      <div className="analysisCard">
        <div className={["blueText", "subtitleSize"].join(" ")}>Dispositivos</div>
        <div style={{display:"flex", flexGrow:"1"}}>
            <div className={["labelText", "subtitleSize"].join(" ")} style={{margin:"auto"}}>
                Informação Indisponível
            </div>
        </div>      
      </div>
    </React.Fragment>
  );
}

export default Devices;
