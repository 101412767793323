import React from "react";
import DataField from "../../../utils/DataField";
import { titleCase, clientAge, checkNested, formatAsMoney, formatPhone, formatAddress, translatePhoneType, formatBureauInfo, getProperMetadata } from "../../../utils/utils";
import MapIcon from "@material-ui/icons/Map";
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useTranslation } from "react-i18next";

function LegacyClientProfile(props) {
    let decision_metadata = getProperMetadata(props.legal_person.analysis_status_events);
    let registration_data = decision_metadata.registration_data;
    
    const { t } = useTranslation();

    let show_email_tooltip = false
    let email_slice = 0;
    if (((props.legal_person.emails || []).length > 0) && (((registration_data || {})["e-mail"]|| [{}]).length > 1)) { 
        show_email_tooltip = true;
        email_slice = 1;
    }else if (((props.legal_person.emails || []).length === 0) && (( (registration_data || {})["e-mail"] || []).length > 0)) {
        show_email_tooltip = true;
        email_slice = 0;
    }else {
        show_email_tooltip = false;
        email_slice = 0;
    }

    let show_phones_tooltip = false
    let phone_slice = 0;
    if (((props.legal_person.phones || []).length > 0) && ((((registration_data || {}).phone_number || [{}]).length) > 1)) {
        show_phones_tooltip = true;
        phone_slice = 1;
    }else if (((props.legal_person.phones || []).length === 0) && ((((registration_data || {}).phone_number || []).length) > 0)) {
        show_phones_tooltip = true;
        phone_slice = 0;
    }else {
        show_phones_tooltip = false;
        phone_slice = 0;
    }

    let show_address_tooltip = false
    let address_slice = 0;
    if ((props.legal_person.address != null) && ((((registration_data || {}).address || [{}]).length) > 1)) {
        show_address_tooltip = true;
        address_slice = 1; 
    }else if ((props.legal_person.address == null) && ((((registration_data || {}).address || []).length) > 0)) { 
        show_address_tooltip = true;
        address_slice = 0;
    }else {
        show_address_tooltip = false;
        address_slice = 0;
    }

    const renderLeftDataFields = () => (
        <div className="internalCardContainer" style={{ display: "flex", flexDirection: "column" }}>
            <div className={["blueText", "subtitleSize"].join(" ")}>{t("sent_basic_data")}</div>
            <DataField
                title={"Razão Social"}
                label={titleCase(props.legal_person.legal_name, "-")}
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="5px"
            />
            <DataField
                title={"Nome Fantasia"}
                label={titleCase(props.legal_person.trading_name, "-")}
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="5px"
            />
            <DataField
                title={"CNPJ"}
                label={props.legal_person.document_number || "-"}
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="5px"
            />
            <DataField
                title={"Data de Fundação"}
                label={clientAge(props.legal_person.foundation_date, "-")}
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="5px"
            />
            {
                (checkNested(registration_data, "tier", "flag") || checkNested(props.legal_person, "tier")) ?
                    <DataField
                        title={"Porte"}
                        label={props.legal_person.tier || "-"}
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        margin="5px"
                    />
                    : null
            }
            <DataField
                title={"Website"}
                label={props.legal_person.website || "-"}
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="5px"
            />
            <DataField
                title={"Telefone"}
                label={(props.legal_person.phones || []).length > 0 ?
                    formatPhone(props.legal_person.phones[0], "-") : "-"
                }
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="5px"
            >
                {(props.legal_person.phones || []).length > 0 ?
                    <Tooltip title={<p className="tooltipText">{translatePhoneType(props.legal_person.phones[0].type)}</p>} arrow placement="right">
                        <HelpIcon style={{ fontSize: "14px", verticalAlign: "middle" }} />
                    </Tooltip> : null}
            </DataField>
            <DataField
                title={"Email"}
                label={(props.legal_person.emails || []).length > 0 ?
                    props.legal_person.emails[0].email || "-" : "-"
                }
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="5px"
            >
            </DataField>
            <DataField
                title={"Endereço"}
                label={formatAddress(props.legal_person.address, "-")}
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="5px"
            >
                {props.legal_person.address ?
                    <a
                        href={"https://www.google.com/maps/search/?api=1&query=" +
                            formatAddress(props.legal_person.address, "-").replace(/ /g, "%20").replace(/,/, '%2C')}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <MapIcon style={{ verticalAlign: "middle", width: "20px", height: "20px", cursor: "pointer", color: "#777", margin: "auto 5px" }} />
                    </a> : null}
            </DataField>
        </div>
    );

    const renderRightDataFields = () => (
        <div className="internalCardContainer" style={{ display: "flex", flexDirection: "column" }}>
            <div className={["blueText", "subtitleSize"].join(" ")}>{t("available_basic_data")}</div>
            <DataField
                title={"Razão Social"}
                label={titleCase(registration_data.legal_name.description, "-")}
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="5px"
            />
            <DataField
                title={"Nome Fantasia"}
                label={titleCase(registration_data.trading_name.description, "-")}
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="5px"
            />
            <DataField
                title={"Data de Fundação"}
                label={clientAge(registration_data.foundation_date.description, "-")}
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="5px"
            />
            {
                (checkNested(registration_data, "tier", "flag") || checkNested(props.legal_person, "tier")) ?
                    <DataField
                        title={"Porte"}
                        label={registration_data.tier.description || "-"}
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        margin="5px"
                    />
                    : null
            }
            <DataField
                title={"Telefone"}
                label={(registration_data.phone_number || []).length > 0 ?
                    formatPhone(registration_data.phone_number[0], "-") : "-"
                }
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="5px"
            >
                {((registration_data || {}).phone_number || [{}]).length > 1 ?
                    <Tooltip title={<p className="tooltipText">{formatBureauInfo((registration_data.phone_number || []).slice(1), "telefones")}</p>}>
                        <MoreHorizIcon style={{ verticalAlign: "middle" }} />
                    </Tooltip> : null}
            </DataField>
            <DataField
                title={"Email"}
                label={(registration_data['e-mail'] || []).length > 0 ?
                    registration_data['e-mail'][0]['email'] : "-"
                }
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="5px"
            >
                {((registration_data || {})["e-mail"] || [{}]).length > 1 ?
                    <Tooltip title={<p className="tooltipText">{formatBureauInfo((registration_data["e-mail"] || []).slice(1), "emails")}</p>}>
                        <MoreHorizIcon style={{ verticalAlign: "middle" }} />
                    </Tooltip> : null}
            </DataField>
            {
                registration_data.address.map((string_address) => (
                    <DataField
                    title={"Endereço"}
                    label={formatAddress(string_address, "-")}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px">
            </DataField>
            ))
        }
        </div>
    );

    return (
        (props.legal_person.legal_name || props.legal_person.trading_name) ? (
            <div className="analysisCard" style={{height:"100%"}}>
            <div className={["blueText", "subtitleSize"].join(" ")}>{t("basic_data_validation")}</div>
            <div className="internalCardContainer"  style={{display: "flex", flexDirection: "column"}}>
                <DataField
                    title={"Razão Social"}
                    label={titleCase(props.legal_person.legal_name, "-")}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={checkNested(registration_data, "legal_name", "flag") ? registration_data.legal_name.flag : null}
                    tooltipText={checkNested(registration_data, "legal_name", "flag") ? registration_data.legal_name.flag === "positive" ? "Nome validado" : 
                        checkNested(registration_data, "legal_name", "description") ? "Nome encontrado em Bureau: " + titleCase(registration_data.legal_name.description) : null : null
                    }
                />
                <DataField
                    title={"Nome Fantasia"}
                    label={titleCase(props.legal_person.trading_name, "-")}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={checkNested(registration_data, "trading_name", "flag") ? registration_data.trading_name.flag : null}
                    tooltipText={checkNested(registration_data, "trading_name", "flag") ? registration_data.trading_name.flag === "positive" ? "Nome validado" : 
                        checkNested(registration_data, "trading_name", "description") ? "Nome encontrado em Bureau: " + titleCase(registration_data.trading_name.description) : null : null
                    }
                />
                <DataField
                    title={"CNPJ"}
                    label={props.legal_person.document_number || "-"}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                />
                <DataField
                    title={"Data de Fundação"}
                    label={clientAge(props.legal_person.foundation_date,"-")}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={checkNested(registration_data, "foundation_date", "flag") ? registration_data.foundation_date.flag : null}
                    tooltipText={checkNested(registration_data, "foundation_date", "flag") ? registration_data.foundation_date.flag === "positive" ? "Data validada" : 
                        checkNested(registration_data, "foundation_date", "description") ? "Data de Fundação em Bureau: " + clientAge(registration_data.foundation_date.description) : null : null
                    }
                />
                <DataField
                    title={"Faturamento"}
                    label={formatAsMoney(props.legal_person.annual_revenues, "-")}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                />
                {
                    (checkNested(registration_data, "tier", "flag") || checkNested(props.legal_person, "tier")) ? 
                    <DataField
                        title={"Porte"}
                        label={props.legal_person.tier || "-"}
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        margin="5px"
                        check={checkNested(registration_data, "tier", "flag") ? registration_data.tier.flag : null}
                        tooltipText={checkNested(registration_data, "tier", "flag") ? registration_data.tier.flag === "positive" ? "Porte Validado" : 
                            checkNested(registration_data, "tier", "description") ? "Porte: " + registration_data.tier.description : null : null
                        }
                        />
                    : null
                }
                <DataField
                    title={"Website"}
                    label={props.legal_person.website || "-"}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                />
                <DataField
                    title={"Telefone"}
                    label={(props.legal_person.phones || []).length > 0 ? 
                        formatPhone(props.legal_person.phones[0], "-") : "-"
                    }
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={((registration_data || {}).phone_number || []).length > 0 ? registration_data.phone_number[0].flag : null}
                    tooltipText={((registration_data || {}).phone_number || []).length > 0 ? registration_data.phone_number[0].description : null}
                >
                    {(props.legal_person.phones || []).length > 0 ? 
                    <Tooltip title={<p className="tooltipText">{translatePhoneType(props.legal_person.phones[0].type)}</p>} arrow placement="right">
                        <HelpIcon style={{fontSize: "14px", verticalAlign:"middle"}}/>
                    </Tooltip> : null
                    }
                    {show_phones_tooltip ?
                    <Tooltip title={<p className="tooltipText">{formatBureauInfo(registration_data.phone_number.slice(phone_slice), "telefones")}</p>}>
                        <MoreHorizIcon style={{verticalAlign:"middle"}}/>
                    </Tooltip> : null}
                </DataField>
                <DataField
                    title={"Email"}
                    label={(props.legal_person.emails || []).length > 0 ? 
                        props.legal_person.emails[0].email || "-" : "-"
                    }
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={((registration_data || {})['e-mail'] || []).length > 0 ? registration_data['e-mail'][0].flag : null}
                    tooltipText={((registration_data || {})['e-mail'] || []).length > 0 ? registration_data['e-mail'][0].description : null}
                >
                {show_email_tooltip ?
                    <Tooltip title={<p className="tooltipText">{formatBureauInfo(registration_data["e-mail"].slice(email_slice), "emails")}</p>}>
                        <MoreHorizIcon style={{verticalAlign:"middle"}}/>
                    </Tooltip> : null}
                </DataField>
                <DataField
                    title={"Endereço"}
                    label={formatAddress(props.legal_person.address,"-")}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={((registration_data || {}).address || []).length > 0 ? registration_data.address[0].flag : null}
                    tooltipText={((registration_data || {}).address || []).length > 0 ? registration_data.address[0].description : null}
                >
                    {props.legal_person.address ?
                    <a
                        href={"https://www.google.com/maps/search/?api=1&query=" + 
                            formatAddress(props.legal_person.address,"-").replace(/ /g, "%20").replace(/,/, '%2C')}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <MapIcon style={{verticalAlign: "middle", width: "20px", height:"20px",cursor:"pointer", color:"#777",margin: "auto 5px"}} />
                    </a>: null}
                    { show_address_tooltip ?
                    <Tooltip title={<p className="tooltipText">{formatBureauInfo(registration_data.address.slice(address_slice), "endereços")}</p>}>
                        <MoreHorizIcon style={{verticalAlign:"middle"}}/>
                    </Tooltip> : null}
                </DataField>
            </div>
        </div>
        ) : (
            <div className="analysisCard" style={{ height: "100%", display: 'flex', flexDirection: 'column', overflowY: 'auto', maxHeight: '80vh' }}>
                <div style={{ display: "flex" }}>
                    <div style={{ flex: 1 }}>
                        {renderLeftDataFields()}
                    </div>
                    <div style={{ width: '1px', backgroundColor: 'rgba(0, 0, 0, 0.1)', margin: '0 10px' }}></div>
                    <div style={{ flex: 1 }}>
                        {renderRightDataFields()}
                    </div>
                </div>
            </div>
        )
    );
}

export default LegacyClientProfile;