import React from "react";
import FilterBox from "../../../Utils/FilterBox/FilterBox"

function Filter(props) {
	
    const { setPageParams } = props

    let filterTypes = {
        document_number: {
            type: "brazilian_document_numbers",
            disabled: false,
            validation:true,
            validation_rule: {type: "multiple_equal", values:[14,18]},
            description: "CPF/CNPJ"
        },
        dict_operation_date: {
            type: "date",
            disabled: false,
            validation:false,
            validation_rule: null,
            description: "Data"
        },
        dict_key_type: {
            type: "enum",
            enumList: [
                ["CPF", "cpf"], 
                ["CNPJ","cnpj"],
                ["Email","email"], 
                ["EVP","evp"],  
                ["Telefone", "phone"]
            ],
            disabled: false,
            validation:false,
            validation_rule: null,
            description: "Tipo da Chave"
          },
        dict_key_value: {
            type: "string",
            disabled: false,
            validation:false,
            validation_rule: null,
            description: "Valor da Chave"
        },
        dict_operation_type: {
            type: "enum",
            enumList: [
                ["Cadastro", "registration"],
                ["Portabilidade", "claim_portability"],                
                ["Reivindicação", "claim_ownership"]
            ],
            disabled: false,
            validation:false,
            validation_rule: null,
            description: "Tipo de Operação"
        },
        dict_operation_direction:{
            type: "enum",
            enumList: [
                ["Doador", "donor"],
                ["Recebedor","claimer"]
            ],
            disabled: false,
            validation:false,
            validation_rule: null,
            description: "Direção da Operação"
        },
        dict_operation_id: {
            type: "string",
            disabled: false,
            validation:false,
            validation_rule: null,
            description: "ID da DICT"
        },
        dict_operation_status: {
            type: "enum",
            enumList: [                 
                ["Criado", "created"],
                ["Reprovado","reproved"],
                ["Esperando resolução da contraparte","waiting_resolution"],
                ["Cancelado pelo Cliente", "cancelled_by_client"],
                ["Cancelado pela Contraparte", "cancelled_by_counterpart"],
                ["Aprovado pela Contraparte","confirmed"],
                ["Confirmado pelo Banco Central", "completed"],
            ],
            disabled: false,
            validation:false,
            validation_rule: null,
            description: "Status da Operação"
          },
        analysis_status: {
            type: "enum",
            enumList: [
                ["Nenhum", ""],
                ["Pendente", "pending"],
                ["Aprovado Manualmente", "manually_approved"],
                ["Reprovado Manualmente", "manually_reproved"],
                ["Aprovado Automaticamente", "automatically_approved"],
                ["Reprovado Automaticamente", "automatically_reproved"],
                ["Aprovado por tempo", "approved_by_time"],
                ["Reprovado por tempo", "reproved_by_time"]
            ],
            disabled: false,
            validation:false,
            description: "Status da Análise"
        }
    }

    return (
        <FilterBox 
            setPageParams={setPageParams}
            filterTypes={filterTypes}
            local_storage_object_preffix={props.local_storage_object_preffix}
        />
    )

}

export default Filter