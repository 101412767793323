import i18n from "i18next";
import Backend from "i18next-http-backend";
import moment from "moment";
import { initReactI18next } from "react-i18next";

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    // debug: true,

    fallbackLng: "pt-BR", 
    supportedLngs: ["en", "pt-BR"], 
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/assets/i18n/{{ns}}/{{lng}}.json`,
      expirationTime: 30 * 1000, // all 30 seconds the cache should be deleted
      // requestOptions: {
      //   cache: 'no-store', // testando não guardar o cache
      // }
    },
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
      format: function(value, format, lng){
        if(format === 'date_time' && lng === 'en') return moment(value).format('MM/DD/YYYY HH:mm:ss');
        if(format === 'date_time' && lng === 'pt-BR') return moment(value).format('DD/MM/YYYY HH:mm:ss');
        if(format === 'date' && lng === 'en') return moment(value).format('MM/DD/YYYY');
        if(format === 'date' && lng === 'pt-BR') return moment(value).format('DD/MM/YYYY');
        if(format === 'birthdate') return moment().diff(moment(value), "years")
        }
    },
    react: {
      wait: true,
      // useSuspense: false
    },
  });

export default i18n;
