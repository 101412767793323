import React, {useContext, useState} from "react";
import {useHistory} from 'react-router-dom'
import AuthContext from '../../../context/auth-context'
import RentalAgreementsInbox from "./RentalAgreementsInbox";
import RentalAgreementsInboxSAC from "./RentalAgreementsInboxSAC";

function RentalAgreements(props) {
    let history = useHistory()
    let user_data = useContext(AuthContext).user_data

	const showRentalAgreementsList = user_data.roles.includes("rental_agreements_viewer")
	const showRentalAgreementsListLast24Hrs = user_data.roles.includes("rental_agreements_viewer_last_24hrs")
	const showRentalAgreementsSAC = user_data.roles.includes("read_car_rental_agreements_filtered_sac")
	const is_role_manager = user_data.roles.includes("rental_agreements_manager")

	if (!showRentalAgreementsList&&!showRentalAgreementsListLast24Hrs&&!showRentalAgreementsSAC){
        history.push("")
    }

	const [changeView, setChangeView] = useState(true)

	if (is_role_manager&&showRentalAgreementsList&&showRentalAgreementsSAC) {
		return (
			changeView ?
			<RentalAgreementsInbox is_role_manager={is_role_manager} handleViewChange={()=>setChangeView(!changeView)}/>
			: <RentalAgreementsInboxSAC is_role_manager={is_role_manager} handleViewChange={()=>setChangeView(!changeView)}/>
		)
	}
	else if (showRentalAgreementsList||showRentalAgreementsListLast24Hrs) {
		return <RentalAgreementsInbox is_role_manager={false}/>
	}
	else if (showRentalAgreementsSAC){
		return <RentalAgreementsInboxSAC is_role_manager={false}/>
	}
    else return null
	}

export default (RentalAgreements);
