import React, {useState} from "react";
import CardOrderLockTypes from "./AvailableLockTypes/CardOrderLockTypes"
// import CardIssuanceLockTypes from "./AvailableLockTypes/CardIssuanceLockTypes"
import BankslipLockTypes from "./AvailableLockTypes/BankslipLockTypes"
import BillPaymentLockTypes from "./AvailableLockTypes/BillPaymentLockTypes"
import WireTransferLockTypes from "./AvailableLockTypes/WireTransferLockTypes"
import WithdrawalLockTypes from "./AvailableLockTypes/WithdrawalLockTypes"
import TransactionLockTypes from "./AvailableLockTypes/TransactionLockTypes"
import DictOperationLockTypes from "./AvailableLockTypes/DictOperationLockTypes"

function SelectLockTypeContent (props){

    let { setLockType, setLockedEventType, lockedEventType, roles } = props
    const [openCardOrderLockDialog, setOpenCardOrderLockDialog] = useState(lockedEventType === 'card_order')
    // const [openCardIssuanceLockDialog, setOpenCardIssuanceLockDialog] = useState(false)
    const [openBankslipLockDialog, setOpenBankslipLockDialog] = useState(lockedEventType === 'bankslip')
    const [openBillPaymentLockDialog, setOpenBillPaymentLockDialog] = useState(lockedEventType === 'bill_payment')
    const [openWireTransferLockDialog, setOpenWireTransferLockDialog] = useState(lockedEventType === 'wire_transfer')
    const [openWithdrawalLockDialog, setOpenWithdrawalLockDialog] = useState(lockedEventType === 'withdrawal')
    const [openTransactionLockDialog, setOpenTransactionLockDialog] = useState(lockedEventType === 'pix_transaction')
    const [openDictOperationLockDialog, setOpenDictOperationLockDialog] = useState(lockedEventType === 'pix_dict_operation')

    if (openCardOrderLockDialog===true){
        return (<CardOrderLockTypes setLockType={setLockType}/>)
    }
    // else if (openCardIssuanceLockDialog===true){
    //     return (<CardIssuanceLockTypes setLockType={setLockType}/>)
    // }
    else if (openBankslipLockDialog===true){
        return (<BankslipLockTypes setLockType={setLockType}/>)
    }
    else if (openBillPaymentLockDialog===true){
        return (<BillPaymentLockTypes setLockType={setLockType}/>)
    }
    else if (openWireTransferLockDialog===true){
        return (<WireTransferLockTypes setLockType={setLockType}/>)
    }
    else if (openWithdrawalLockDialog===true){
        return (<WithdrawalLockTypes setLockType={setLockType}/>)
    }
    else if (openTransactionLockDialog===true){
        return (<TransactionLockTypes setLockType={setLockType}/>)
    }
    else if (openDictOperationLockDialog===true){
        return (<DictOperationLockTypes setLockType={setLockType}/>)
    }
    else return (
        <div style={{ flexWrap:"wrap", display:"flex", justifyContent: "space-evenly", margin:"auto 0px"}}>
            {roles.includes('update_card_order_locks')?
                <div
                    className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")} 
                    onClick={() => {setOpenCardOrderLockDialog(true); setLockedEventType('card_order')}} 
                    style={{width:"30%", margin:"10px 1px"}}
                >
                    Card Order
                </div>
                :null
            }
            {/* {roles.includes('card_issuance_lock_analyzer')?
                <div
                    className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")} 
                    onClick={() => setOpenCardIssuanceLockDialog(true)} 
                    style={{width:"30%", margin:"10px 1px"}}
                >
                    Card Issuance
                </div>
                :null
            } */}
            {roles.includes('create_bankslip_locks')?
                <div
                    className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")} 
                    onClick={() => {setOpenBankslipLockDialog(true); setLockedEventType('bankslip')}}
                    style={{width:"30%", margin:"10px 1px"}}
                >
                    Boleto
                </div>
                :null
            }
            {roles.includes('create_bill_payment_locks')?
                <div
                    className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")} 
                    onClick={() => {setOpenBillPaymentLockDialog(true); setLockedEventType('bill_payment')}}
                    style={{width:"30%", margin:"10px 1px"}}
                >
                    Pagamento de Contas
                </div>
                :null
            }
            {roles.includes('create_wire_transfer_locks')?
                <div
                    className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")} 
                    onClick={() => {setOpenWireTransferLockDialog(true); setLockedEventType('wire_transfer')}}
                    style={{width:"30%", margin:"10px 1px"}}
                >
                    Transferencia Bancária
                </div>
                :null
            }
            {roles.includes('create_withdrawal_locks')?
                <div
                    className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")} 
                    onClick={() => {setOpenWithdrawalLockDialog(true); setLockedEventType('withdrawal')}}
                    style={{width:"30%", margin:"10px 1px"}}
                >
                    Saque
                </div>
                :null
            }
            {roles.includes('create_pix_transaction_locks')?
                <div
                    className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")} 
                    onClick={() => {setOpenTransactionLockDialog(true); setLockedEventType('pix_transaction')}}
                    style={{width:"30%", margin:"10px 1px"}}
                >
                    Transação PIX
                </div>
                :null
            }
            {roles.includes('create_pix_dict_operation_locks')?
                <div
                    className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")} 
                    onClick={() => {setOpenDictOperationLockDialog(true); setLockedEventType('pix_dict_operation')}}
                    style={{width:"30%", margin:"10px 1px"}}
                >
                    Operação na DICT
                </div>
                :null
            }
        </div>
    )
}

export default SelectLockTypeContent