import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    width: 100%;
`;

export const Input = styled.input`
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 40px;
    color: ${(props) => props.$contentcolor};
    transition: border-color 0.3s ease;
    &:focus {
        border-color: ${(props) => props.$focusedcolor};
        outline-color: ${(props) => props.$focusedcolor};
    }
`;

export const Label = styled.label`
    position: absolute;
    box-sizing: border-box;
    padding: 0 4px;
    top: 50%;
    left: 10px;
    font-size: 14px;
    transform: translateY(-50%);
    color: #999;
    background-color: #fff;
    pointer-events: none;
    transition: all 0.3s ease;

    ${(props) => (props.$content || props.$focus === 'focused') && `
        top: 0;
        font-size: ${props.$focus === 'focused' ? '12px' : '10px'};
        color: ${props.$focusedcolor};
    `}
`;

export const Error = styled.div`
    position: absolute;
    right: 8px;
    top: 10px;
    display: ${(props) => props.$display};
    align-items: center;
`;