import React from 'react'
import DataField from "../../../utils/DataField"
import {titleCase, upperCase, checkNested} from "../../../utils/utils"
import moment from "moment"

function LegacyRGOCR(props){
    const { rg_verification } = props
    const { RGOCR } = props

    return(
        <div style={{display:"flex", width:"70%", flexDirection: "column"}}>
            <DataField
                    title={"Nome"}
                    label={titleCase(RGOCR.name, null)}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={checkNested(rg_verification, "name", "flag") ? rg_verification.name.flag : null}
                    tooltipText={checkNested(rg_verification, "name", "flag") ? rg_verification.name.flag === "positive" ? "Nome validado" : 
                        (checkNested(rg_verification, "name", "description") && checkNested(rg_verification, "name","similarity")) ? 
                        "Nome encontrado em Bureau: " + titleCase(rg_verification.name.description) + " (Similaridade de " + (rg_verification.name.similarity*100).toFixed(0)  + "% )": 
                        checkNested(rg_verification, "name", "description") ? "Nome encontrado em Bureau: " + titleCase(rg_verification.name.description) : 
                        checkNested(rg_verification, "name","similarity") ? "Similaridade de " + (rg_verification.name.similarity*100).toFixed(0)  + "%" : "Indisponível" :  "Indisponível"}
            />
            <DataField
                    title={"CPF"}
                    label={RGOCR.cpf_number ? RGOCR.cpf_number : null}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={checkNested(rg_verification, "document_number", "flag") ? rg_verification.document_number.flag : null}
                    tooltipText={checkNested(rg_verification, "document_number", "flag") ? rg_verification.document_number.flag === "positive" ? "CPF validado" : 
                    checkNested(rg_verification.document_number.description) ? "CPF encontrado em Bureau: " + titleCase(rg_verification.document_number.description) :
                    "Indisponível" : "Indisponível"}
            />
            <DataField
                    title={"Data de Nascimento"}
                    label={RGOCR.birthdate ? moment(RGOCR.birthdate).format('DD/MM/YYYY') : null}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={checkNested(rg_verification, "birthdate", "flag") ? rg_verification.birthdate.flag : null}
                    tooltipText={checkNested(rg_verification, "birthdate", "flag") ? rg_verification.birthdate.flag === "positive" ? "Data validada" : 
                    checkNested(rg_verification.birthdate.description) ? "Data de nascimento encontrada em Bureau: " + moment(rg_verification.birthdate.description).format('DD/MM/YYYY') :
                    "Indisponível" : "Indisponível"}
            />
            <DataField
                    title={"Nome do Pai"}
                    label={titleCase(RGOCR.father_name, null)}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={checkNested(rg_verification, "father_name", "flag") ? rg_verification.father_name.flag : null}
                    tooltipText={checkNested(rg_verification, "father_name", "flag") ? rg_verification.father_name.flag === "positive" ? "Nome validado" : 
                        (checkNested(rg_verification, "father_name", "description") && checkNested(rg_verification, "father_name","similarity")) ? 
                        "Nome encontrado em Bureau: " + titleCase(rg_verification.father_name.description) + " (Similaridade de " + (rg_verification.father_name.similarity*100).toFixed(0)  + "% )": 
                        checkNested(rg_verification, "father_name", "description") ? "Nome encontrado em Bureau: " + titleCase(rg_verification.father_name.description) : 
                        checkNested(rg_verification, "father_name","similarity") ? "Similaridade de " + (rg_verification.father_name.similarity*100).toFixed(0)  + "%" : "Indisponível" :  "Indisponível"}
            />
            <DataField
                    title={"Nome da Mãe"}
                    label={titleCase(RGOCR.mother_name, null)}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={checkNested(rg_verification, "mother_name", "flag") ? rg_verification.mother_name.flag : null}
                    tooltipText={checkNested(rg_verification, "mother_name", "flag") ? rg_verification.mother_name.flag === "positive" ? "Nome validado" : 
                        (checkNested(rg_verification, "mother_name", "description") && checkNested(rg_verification, "mother_name","similarity")) ? 
                        "Nome encontrado em Bureau: " + titleCase(rg_verification.mother_name.description) + " (Similaridade de " + (rg_verification.mother_name.similarity*100).toFixed(0)  + "% )": 
                        checkNested(rg_verification, "mother_name", "description") ? "Nome encontrado em Bureau: " + titleCase(rg_verification.mother_name.description) : 
                        checkNested(rg_verification, "mother_name","similarity") ? "Similaridade de " + (rg_verification.mother_name.similarity*100).toFixed(0)  + "%" : "Indisponível" :  "Indisponível"}
            />
            <DataField
                    title={"RG"}
                    label={upperCase(RGOCR.rg_number, null)}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={checkNested(rg_verification, "rg", "number", "flag") ? rg_verification.rg.number.flag : null}
                    tooltipText={checkNested(rg_verification, "rg", "number", "flag") ? rg_verification.rg.number.flag === "positive" ? "Número do RG validado" : 
                    checkNested(rg_verification.rg.number.description) ? "Número de RG encontrado em Bureau: " + titleCase(rg_verification.rg.number.description) :
                    "Indisponível" : "Indisponível"}
            />
            <DataField
                    title={"Estado Emissor do RG"}
                    label={titleCase(RGOCR.rg_issuer_state, null)}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={checkNested(rg_verification, "rg", "issuer_state", "flag") ? rg_verification.rg.issuer_state.flag : null}
                    tooltipText={checkNested(rg_verification, "rg", "issuer_state", "flag") ? rg_verification.rg.issuer_state.flag === "positive" ? "Estado emissor do RG validado" : 
                    checkNested(rg_verification.rg.issuer_state.description) ? "Estado emissor do RG encontrado em Bureau: " + titleCase(rg_verification.rg.issuer_state.description) :
                    "Indisponível" : "Indisponível"}
            />
            <DataField
                    title={"Data de Emissão do RG"}
                    label={RGOCR.issuance_date ? moment(RGOCR.issuance_date).format('DD/MM/YYYY') : null}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={checkNested(rg_verification, "rg", "issuance_date", "flag") ? rg_verification.rg.issuance_date.flag : null}
                    tooltipText={checkNested(rg_verification, "rg", "issuance_date", "flag") ? rg_verification.rg.issuance_date.flag === "positive" ? "Data validada" : 
                    checkNested(rg_verification.rg.issuance_date.description) ? "Data de emissão do RG encontrado em Bureau: " + moment(rg_verification.rg.issuance_date.description).format('DD/MM/YYYY') :
                    "Indisponível" : "Indisponível"}
            />
        </div>
    )
}

export default LegacyRGOCR