import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {titleCase} from "../../../utils/utils"
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
    width: "calc(100% - 40px)"
  },
  tableText: {
    fontFamily: "Open Sans",
    fontWeight: 'normal',
    fontSize: "12px",
    lineHeight: "16px",
    color: "#141414",
    textAlign: "center",
    margin: "10px",
    padding: "6px",
    minWidth: "120px"
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: '#6F6F6F',
    fontSize: "14px",
    lineHeight: "19px",
    padding: "6px",
  },
  even: {
    background: "#F3F3F3",
  },
  odd: {
    background: "#FFFFFF",
  },
}));

function CEAFTable(props) {
  const classes = useStyles();
  useTheme();

  const getClass = (rowIndex) => {
    if (rowIndex % 2 === 0) {
      return classes.even;
    }

    return classes.odd;
  };         

  if (!Array.isArray(props.ceaf_data) || props.ceaf_data.length <= 0) {
    return <></>;
  }

  return (
    <Paper className={classes.tableStyle}>
      <Table style={{ padding: "8px" }}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader}>Tipo da Sanção</TableCell>
            <TableCell className={classes.tableHeader}>Base Legal</TableCell>
            <TableCell className={classes.tableHeader}>Órgão Administrativo</TableCell>
            <TableCell className={classes.tableHeader}>Estado do Orgão Administrativo</TableCell>
            <TableCell className={classes.tableHeader}>Número do Processo Administrativo</TableCell>
            <TableCell className={classes.tableHeader}>Posição</TableCell>
            <TableCell className={classes.tableHeader}>Número da Ordem de Punição</TableCell>
            <TableCell className={classes.tableHeader}>Pagina da Ordem de Punição</TableCell>
            <TableCell className={classes.tableHeader}>Data de Publicação da Ordem de Punição</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.ceaf_data.map((sanction,index) => (
            <TableRow
              key={index}
              className={getClass(index)}
            > 
              <TableCell className={classes.tableText}>
                {titleCase(sanction.type, "-")}
              </TableCell>
              <TableCell className={classes.tableText}>
                {titleCase(sanction.legal_basis, "-")}
              </TableCell>
              <TableCell className={classes.tableText}>
                {titleCase(sanction.administrative_organ, "-")}
              </TableCell>
              <TableCell className={classes.tableText}>
                {titleCase(sanction.administrative_organ_state, "-")}
              </TableCell>
              <TableCell className={classes.tableText}>
                {titleCase(sanction.administrative_process_number, "-")}
              </TableCell>
              <TableCell className={classes.tableText}>
                {titleCase(sanction.position, "-")}
              </TableCell>
              <TableCell className={classes.tableText}>
                {titleCase(sanction.punishment_order_number, "-")}
              </TableCell>
              <TableCell className={classes.tableText}>
                {titleCase(sanction.punishment_order_publication_page, "-")}
              </TableCell>
              <TableCell className={classes.tableText}>
              {titleCase(moment(sanction.punishment_order_publication_date).format("DD/MM/YYYY - HH:mm:ss"), "-")}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default CEAFTable;
