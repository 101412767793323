import React from "react";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import moment from 'moment'
import {getOperationCodeInfo, getOperationStatusIcon } from "../../utils/utilsOperations"
import {getAnalysisStatusColor, 
        getAnalysisStatusSiglum, getAnalysisStatus, formatAsMoneyUniversal} from "../../utils/utils"
import Tooltip from "@material-ui/core/Tooltip";

function OperationsCard(props){
    return (
        <Card className="listCard">
            <Link
                className={["listCardItem", "link", "subtitleSize", "breakWord", "bold"].join(" ")}
                style={{flex:"1 1 60px", textAlign:"center"}}
                to={"/operation/" + props.operation.operation_key}
            >
                {props.operation.id}
            </Link>
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")} 
                style={{flex:"1 1 100px", textAlign:"center"}}  
            >
                {formatAsMoneyUniversal(props.operation.source_currency, props.operation.source_amount, "-")}
            </div>
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                style={{flex:"1 1 110px", textAlign:"center"}}
            >
                {props.operation.client.document_number}
            </div>
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")} 
                style={{flex:"1 1 60px", textAlign:"center", cursor: "default"}}  
            >
                <Tooltip title={<p className="tooltipText">{getOperationCodeInfo(props.operation.operation_code)}</p>}>
                <div>
                    {props.operation.operation_code}
                </div>
                </Tooltip>
            </div>             
            <div 
                className={["listCardItem", "normalText", "normalMediumSize", "bold"].join(" ")} 
                style={{flex:"1 1 45px", textAlign:"center", cursor: "default"}}  
            >
                <Tooltip title={<p className="tooltipText">{getAnalysisStatus(props.operation.analysis_status)}</p>}>
                <div className={["circle", "small"].join(" ")} style={{backgroundColor:getAnalysisStatusColor(props.operation.analysis_status)}}>
                    {getAnalysisStatusSiglum(props.operation.analysis_status)}
                </div>
                </Tooltip>
            </div>
            <div 
                className={["listCardItem", "normalText", "subtitleSize","noWrap"].join(" ")}
                style={{flex:"1 1 110px", textAlign:"center"}}
            >
                {moment(props.operation.operation_date).format("DD/MM/YYYY HH:mm:ss")}
            </div>
            <div 
                className={["listCardItem", "normalText", "normalMediumSize", "bold"].join(" ")}  
                style={{flex:"1 1 35px", textAlign:"center", cursor: "default"}}  
            >
                {getOperationStatusIcon(props.operation.operation_status, "small", true)}
            </div>
        </Card>
    )
}

export default OperationsCard