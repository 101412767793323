import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import moment from 'moment'
import {formatAsMoney, getAgencyInfo} from "../../utils/utils"
import {getInfractionReportType, getInfractionReportStatus, getInfractionReportStatusColor, getInfractionReportStatusSiglum} from "../../utils/utilsInfractionReport"
import Tooltip from "@material-ui/core/Tooltip";
import AuthContext from "../../../../context/auth-context";

function InfractionReportCard(props){

    props.infractionReport.manual_analysis_limit_date = moment(props.infractionReport.infraction_report_date).add(15, 'days')

    let counter_part_account_object = (props.infractionReport.transaction_data.transaction_direction === 'received' ? 
                            props.infractionReport.transaction_data.source_account : 
                            props.infractionReport.transaction_data.destination_account)
    
    let {user_data} = useContext(AuthContext)

    return (
        <Card className="listCard">
            <Link
                className={["listCardItem", "link", "subtitleSize", "breakWord", "bold"].join(" ")}
                style={{flex:"1 1 110px", textAlign:"center"}}
                to={"/pix-transaction/" + props.infractionReport.transaction_data.transaction_key}
            >
                {props.infractionReport.transaction_data.id}
            </Link>
            {user_data.business_group_key &&
            <div 
                className={["listCardItem", "normalText"].join(" ")}
                style={{flex:"1 1 80px", textAlign:"center"}}
            >
                {props.infractionReport.company_name}
            </div>}
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")} 
                style={{flex:"1 1 130px", textAlign:"center"}}  
            >
                {getInfractionReportType(props.infractionReport.infraction_report_type)}
            </div>
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                style={{flex:"1 1 100px", textAlign:"center"}}
            >
                {formatAsMoney(props.infractionReport.transaction_data.amount, "-")}
            </div>
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                style={{flex:"1 1 100px", textAlign:"center"}}
            >
                {getAgencyInfo(counter_part_account_object)[0] || "-"}
            </div>
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")} 
                style={{flex:"1 1 150px", textAlign:"center"}}  
            >
                {moment(props.infractionReport.manual_analysis_limit_date).format("DD/MM/YYYY HH:mm:ss")}
            </div>
            <div 
                className={["listCardItem", "normalText", "normalMediumSize", "bold"].join(" ")} 
                style={{flex:"1 1 45px", textAlign:"center", cursor: "default"}}  
            >
                <Tooltip title={<p className="tooltipText">{getInfractionReportStatus(props.infractionReport.infraction_report_status)}</p>}>
                <div className={["circle", "small"].join(" ")} style={{backgroundColor:getInfractionReportStatusColor(props.infractionReport.infraction_report_status)}}>
                    {getInfractionReportStatusSiglum(props.infractionReport.infraction_report_status)}
                </div>
                </Tooltip>
            </div>
            <div 
                className={["listCardItem", "normalText", "subtitleSize","noWrap"].join(" ")}
                style={{flex:"1 1 110px", textAlign:"center"}}
            >
                {moment(props.infractionReport.infraction_report_date).format("DD/MM/YYYY HH:mm:ss")}
            </div>
        </Card>
    )
}

export default InfractionReportCard