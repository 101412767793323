import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function TopSecondaryBarItem (props) {

  const { page, selectedPage, setSelectedPage, routes } = props
  const { t } = useTranslation()

  let history = useHistory()

  const handleClick = (event) => {
    const page = event.target.id
    setSelectedPage(page)
    history.push(routes[page])
  }

  return (
    <div id={page} className="topSecondaryBarItem" onClick={(event) => handleClick(event)}>
      <div 
        id={page}
        className={selectedPage===page?"topSecondaryBarTextSelection":null}
        onClick={(event) => handleClick(event)}
      >
        {t(page)}
      </div>
    </div>
  )
}