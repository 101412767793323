import React, { useState, useEffect } from "react";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import moment from "moment";
import "react-dates/lib/css/_datepicker.css";
import TextField from "@material-ui/core/TextField";
import InputMask from "react-input-mask";
import MenuItem from "@material-ui/core/MenuItem";

function Filter(props) {
  const [focus, setFocus] = useState(null);
  useEffect(() => {
    moment.locale("pt");
    moment.updateLocale("pt", {
      months: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
    });
    moment.updateLocale("pt", {
      weekdaysMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
    });
  });

  const handleFocusChange = (focus) => {
    setFocus(focus);
  };

  if (props.filter.type === "masked_string") {
    return (
      <div
        className={
          props.filter.error === true
            ? "filterContainerError"
            : "filterContainer"
        }
      >
        <div className="filterDescription">{props.filter.description}</div>
        <InputMask
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              props.onBlur();
            }
          }}
          disabled={props.filter.disabled}
          mask={props.filter.mask}
          maskPlaceholder=""
          onBlur={(e) => { props.onChange(props.filter_name, e.target.value); }}
          onChange={(e) => { props.onChange(props.filter_name, e.target.value); }}
          value={props.value}
        >
          <TextField className="filterTextbox" size="small" fullWidth />
        </InputMask>
      </div>
    );
  } else if (props.filter.type === "enum") {
    return (
      <div className="filterContainer">
        <div className="filterDescription">{props.filter.description}</div>
        <TextField
          disabled={props.filter.disabled}
          style={{ textAlign: "center" }}
          onBlur={(e) => { props.onChange(props.filter_name, e.target.value); }}
          onChange={(e) => { props.onChange(props.filter_name, e.target.value); }}
          className="filterTextbox"
          size="small"
          fullWidth
          value={props.value}
          select
        >
          {Object.keys(props.filter.enumList).map((enumValue, index) => (
            <MenuItem key={index} value={enumValue}>
              <em className="normalText">{props.filter.enumList[enumValue]}</em>
            </MenuItem>
          ))}
        </TextField>
      </div>
    );
  } else if (props.filter.type === "date_range") {
    return (
      <div className="filterContainer">
        <div className="filterDescription">{props.filter.description}</div>
        <DateRangePicker
          disabled={props.filter.disabled}
          startDatePlaceholderText={"Início"}
          endDatePlaceholderText={"Final"}
          startDate={ props.value["startDate"] }
          startDateId="initial_date_id"
          endDate={ props.value["endDate"] }
          endDateId="final_date_id"
          onDatesChange={({ startDate, endDate }) => 
            props.onChange(props.filter_name, {
              startDate: startDate,
              endDate: endDate,
            })
          }
          focusedInput={focus}
          showDefaultInputIcon={false}
          onFocusChange={(focus) => {
            handleFocusChange(focus);
          }}
            isOutsideRange={(day) => {return false}}
          small={true}
          displayFormat={() => "DD/MM"}
          readOnly={false}
        />
      </div>
    );
  } else {
    return (
      <div className="filterContainer">
        <div className="filterDescription">{props.filter.description}</div>
        <TextField
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              props.onBlur();
            }
          }}
          disabled={props.filter.disabled}
          onBlur={(e) => {
            props.onBlur(e.value);
          }}
          onChange={(e) => {
            props.onChange(e.value);
          }}
          className="filterTextbox"
          size="small"
          value={props.filter.value}
          fullWidth
        />
      </div>
    );
  }
}

export default Filter;
