import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

function NodeRemovalConfirmation(props) {
  const { t } = useTranslation();

    const handleConfirmDelete = () => {
        props.deleteNodeFromTree(props.node)
        props.onClose()
    }
    return (
        <Dialog 
            open={props.open} 
            onClose={props.onClose} 
            keepMounted={false}     
            disableBackdropClick
        >
            <DialogTitle 
                className={['blueText', "subtitleSize"].join(" ")} 
                onClose={props.onClose}
            >
                {t("Confirmar remoção")}
            </DialogTitle>
            <DialogContent>
                <div 
                    style={{
                        width:"366px", height: "160px", 
                        display:"flex", flexDirection:"column", 
                        marginBottom:"30px", alignItems:"center"
                    }}>
                    <div 
                        className={["internalCardContainer", "normalText", "normalMediumSize"].join(" ")} 
                        style={{width:"90%", textAlign:"center"}}>
                        {t("Você tem certeza que deseja remover o nó do tipo ")}{t(props.node.node.description)}? <br />
                        {t("Lembre-se que todos o galho de nós abaixo desse elemento será removido!")}
                    </div>
                    <div
                        className={["button", "standard"].join(" ")} 
                        style={{width:"30%", marginBottom:"30px", marginTop: "auto", textDecoration: "none"}}
                        onClick={handleConfirmDelete}
                    >
                        {t("Continuar")}
                    </div>
                </div>                
            </DialogContent>
        </Dialog>
    )
}

export default NodeRemovalConfirmation;