import React, { useEffect, useContext, useReducer } from "react";
import { dataFetchReducer } from "../../Utils/requestUtils";
import { useParams } from "react-router-dom";
import axios from "axios";
import DictCard from "./DictCard/DictCard"
import DeviceCard from "./DeviceCard/DeviceCard"
import ClientCard from "./ClientCard/ClientCard"
import HistoricCard from "./HistoricCard/HistoricCard"
import EnvolvedParties from "./EnvolvedParties/EnvolvedParties"
import AlertCard from "./AlertCard/AlertCard"
import TimerBox from "./TimerBox/TimerBox"
import ScoreBox from "./ScoreBox/ScoreBox"
import ObservationCard from "./ObservationCard/ObservationCard"
import moment from "moment"
import ErrorBoundary from "../../Utils/ErrorBoundary";
import AuthContext from "../../../context/auth-context"
import {useHistory} from "react-router-dom"
import NextTransaction from "../../Utils/NextTransaction/NextTransaction";

function Dict(props){
    let history = useHistory()

    let roles = useContext(AuthContext).user_data.roles
    if (!roles.includes("read_pix_dict_operations")){
        history.push("")
    }

    let { dict_operation_key } = useParams();

    const [dict_operation, dispatchDictOperation] = useReducer(
        dataFetchReducer,
        {fetchedData: null, isLoading: true, isError: false} 
    )

    useEffect(() => {
		dispatchDictOperation({type: "data_fetch_init"})
		const timer = setTimeout(() => {  
			axios.get('/dash/pix_backoffice/dict_operation/' + dict_operation_key).then( response => {
                dispatchDictOperation({
                    type: "data_fetch_success",
                    payload: response.data
                })   
			}).catch(error => {
                if ((error.response || {}).status === 403) dispatchDictOperation({type: "data_fetch_failure_403"})
                else if ((error.response || {}).status === 404) dispatchDictOperation({type: "data_fetch_failure_404"})  
                else dispatchDictOperation({type: "data_fetch_failure"})    
			})	
		}, 500);
		return () => {
			clearTimeout(timer)
        }
    }, [dict_operation_key])
    

    if (dict_operation.fetchedData){
        dict_operation.fetchedData.manual_analysis_limit_date = moment().add(2,"minutes")
        return (
            <div style={{minWidth:"900px", paddingBottom: "30px"}}>
                <div className="analysisCardContainer">
                    <ErrorBoundary>
                        <div style={{display:"flex", flexDirection:"column"}}>
                            <div className={["blueText", "titleSize"].join(" ")} style={{margin:"5px 0px"}}>
                                DICT {dict_operation.fetchedData.id}
                            </div>
                            <div className={["labelText", "subtitleSize"].join(" ")} style={{margin:"5px 0px"}}>
                                {moment(dict_operation.fetchedData.dict_operation_creation_date).format("DD/MM/YYYY - HH:mm:ss")}
                            </div>
                        </div>
                    </ErrorBoundary>
                </div>
                <NextTransaction history={history} transaction_key={dict_operation_key} path={"/pix-dict"} suffix={"dict_operation"} page_name={"DICTS"}/> 
                <div style={{display: "flex", margin: "10px 0px"}}>
                    <div style={{width: "33%"}}>
                        <div className="analysisCardContainer">
                            <ErrorBoundary>
                                <DictCard dict_operation={dict_operation.fetchedData}/>
                            </ErrorBoundary>      
                        </div>
                        {dict_operation.fetchedData.source ? 
                        <div className="analysisCardContainer">
                            <ErrorBoundary>
                                <DeviceCard dict_operation={dict_operation.fetchedData}/>
                            </ErrorBoundary>   
                        </div> : null}
                        <div className="analysisCardContainer">
                            <ErrorBoundary>
                                <ClientCard dict_operation={dict_operation.fetchedData}/>
                            </ErrorBoundary>      
                        </div>
                    </div>
                    <div style={{width: "67%"}}>
                        <div style={{display:"flex"}}>
                            <div style={{width: "50%"}}>
                                <div className="analysisCardContainer">
                                    <ErrorBoundary>
                                        <TimerBox dict_operation={dict_operation.fetchedData}/>
                                    </ErrorBoundary>
                                </div>
                                <div className="analysisCardContainer">
                                    <ErrorBoundary>
                                        <ScoreBox 
                                            analysis_events={dict_operation.fetchedData.analysis_events}
                                            analysis_status={dict_operation.fetchedData.analysis_status}
                                        />
                                    </ErrorBoundary>
                                </div>
                            </div>
                            <div style={{width: "50%"}}>
                                <div className="analysisCardContainer">
                                    <ErrorBoundary>
                                        <AlertCard analysis_events={dict_operation.fetchedData.analysis_events}/>
                                    </ErrorBoundary>   
                                </div>
                            </div>
                        </div>
                        <div className="analysisCardContainer" style={{ minHeight: "250px"}}>
                            <ErrorBoundary>
                                <EnvolvedParties dict_operation={dict_operation.fetchedData} />
                            </ErrorBoundary>   
                        </div>
                    </div>
                </div>
                <div className="analysisCardContainer">
                    <ErrorBoundary>
                        <HistoricCard dictOperation={dict_operation.fetchedData}/>
                    </ErrorBoundary>
                </div>
                <div className="analysisCardContainer">
                    <ErrorBoundary>
                        <ObservationCard dict_operation={dict_operation.fetchedData}/>
                    </ErrorBoundary>			
                </div>
            </div>
        )
    }
	else if(dict_operation.isError){
		return (
            <div style={{height: "80vh", display:"flex"}}>
                <p className={["blueText", "titleSize"].join(" ")} style={{margin:"auto", textAlign:"center"}}>
                    {dict_operation.errorMessage}
                </p>
            </div>
		)
	}
  
  return null;
}

export default Dict