import React, { useState, Component, useEffect } from "react";
import DataField from "../../utils/DataField"
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import HelpIcon from "@material-ui/icons/Help";
import PriceDescriptionDialog from "./PriceDescriptionDialog/PriceDescriptionDialog";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import {checkNested, titleCase, formatAsMoney, getRentalDaysFormat, getReservationFormatForReservation} from "../../utils/utils"

function Rental(props) {

    let [priceDescriptionOpen, setPriceDescriptionOpen] = useState(false);
    let [carGroup, setCarGroup] = useState(null);

    useEffect(() => {
        var car_groups = require("../../../../assets/json/group_cars.json");
        let car_group_ = checkNested(props.reservation, "car", "model_group") ? car_groups[props.reservation.car.model_group] : null
        setCarGroup(car_group_);
    }, [props]);

    let cupon_used = true;
    let cupon_icon = cupon_used ? (
        <MonetizationOnIcon style={{fontSize: "14px", display:"flex", margin:"auto", color: "#30933F"}} />
    ) : (
        <MoneyOffIcon style={{fontSize: "14px", display:"flex", margin:"auto"}} />
    );

    const handlePriceDescriptionOpenClick = () => {
        setPriceDescriptionOpen(true);
    };

    const handlePriceDescriptionClose = () => {
        setPriceDescriptionOpen(false);
    };


    let car_group = (
        <DataField
            title={"Grupo"}
            label={checkNested(props.reservation, "car", "model_group") ? props.reservation.car.model_group : "Indisponível"} 
            size={"normalMediumSize"}
            titleNoWrap={true}
            margin="10px"
        />
    );

    if (carGroup) {
        car_group = (
            <DataField
                title={"Grupo"}
                label={carGroup.model_group + " - " + carGroup.group_description + "  "}
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="10px"
            >
                <Tooltip title={<p className="tooltipText">{carGroup.cars.join("; ")}</p>} arrow placement="right">
                    <HelpIcon style={{fontSize: "14px", display:"flex", margin:"auto 5px"}}/>
                </Tooltip>
            </DataField>
        );
    }

    let rental_store_type = props.reservation.rental_store_type ? ` - ${props.reservation.rental_store_type}` : ""

    return (
        <div className="analysisCard">
        <div className={["blueText", "subtitleSize"].join(" ")}>Locação</div>
        <div className={["labelText", "normalSmallSize"].join(" ")}>
            {props.reservation.rental_store + rental_store_type +
            ", " +
            titleCase((props.reservation || {}).channel, "-")}
        </div>
        <div>
            {car_group}
            <DataField
                title={"Canal de Venda"}
                label={titleCase((props.reservation || {}).sales_channel, "Indisponível")}
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="10px"
            />
            <DataField
                title={"Data da Reserva"}
                label={getReservationFormatForReservation(props.reservation)}
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="10px"
            />
            <DataField
                title={"Data de Retirada"}
                label={moment(props.reservation.rental_agreement_date).format("DD/MM/YYYY - HH:mm")}
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="10px"
            />
            <DataField
                title={"Data de Devolução"}
                label={getRentalDaysFormat(props.reservation)}
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="10px"
            />
            <DataField
                title={"Valor Total do Aluguel"}
                label={checkNested(props.reservation, "final_price") ? 
                    formatAsMoney(props.reservation.final_price, "-") : "-"
                }
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="10px"
            >
                <div style={{display:"flex"}}>
                    {cupon_icon}
                    <span
                        className={["blueText", "normalMediumSize", "link"].join(" ")}
                        onClick={handlePriceDescriptionOpenClick}
                    >
                        Ver detalhes
                    </span>

                </div>
            </DataField>
            <DataField
                title={"Valor da Pré-Autorização"}
                label={checkNested(props.reservation, "pre_authorization_amount") ? 
                    formatAsMoney(props.reservation.pre_authorization_amount, "-") : "-"
                }
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="10px"
            />
            <Coverages coverages={props.reservation.coverages} />
        </div>
        <PriceDescriptionDialog
            open={priceDescriptionOpen}
            onClose={handlePriceDescriptionClose}
            reservation={props.reservation}
        />
        </div>
    );
}

export default Rental;

class Coverages extends Component {
    constructor(props) {
        super(props);
        this.coverages = this.props.coverages;
    }

    render() {
        if (this.coverages == null) this.coverages = []
        if (this.coverages.length === 0 || this.coverages.length === 1) {
        return (
            <DataField
                title={"Proteção Escolhida"}
                label={this.coverages.length === 0 ? "O cliente não selecionou qualquer proteção" :
                    (this.coverages[0].description || "-") + " - " + formatAsMoney(this.coverages[0].price)
                }
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="10px"
            />
        );
        } 
        else {
        return (
            <div>
            <DataField
                title={"Proteção Escolhida"}
                label={""}
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="10px"
            />
            <ul style={{ margin: "0" }}>
                {this.coverages.map((coverage, index) => (
                <li
                    key={index}
                    className={["labelText", "normalMediumSize"].join(" ")}
                    style={{margin: "3px"}}
                >
                    {(coverage.description || "-") + " - " + formatAsMoney(coverage.price)}
                </li>
                ))}
            </ul>
            </div>
        );
        }
    }
}
