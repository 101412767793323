import React, {useEffect, useReducer, useCallback, useContext, useState} from "react";
import { dataFetchReducer, useStateConnectedToCache } from "../../Utils/requestUtils";
import Filter from "./Filter/Filter";
import Pagination from "@material-ui/lab/Pagination";
import { CircularProgress } from '@material-ui/core';
import axios from "axios";
import LockCard from "./LockCard/LockCard";
import NewLockDialog from "./NewLockDialog/NewLockDialog"
import ErrorBoundary from '../../Utils/ErrorBoundary'
import AuthContext from "../../../context/auth-context"
import {useHistory} from "react-router-dom"

function LocksInbox(props) {
    let history = useHistory()

    let user_data = useContext(AuthContext).user_data
    if (!user_data.roles.includes("read_card_order_locks")){
        history.push("")
    }

    let local_storage_object_preffix = "coLock"

    let initialFilters = {
        "lock_status": "blocked"
    }

    const [openNewLock, setOpenNewLock] = useState(false)
    const [pageParams, setPageParams] = useStateConnectedToCache(local_storage_object_preffix, initialFilters)
    const [refreshPage, setRefreshPage] = useState(false)
    const [pageState, dispatchPageState] = useReducer(
        dataFetchReducer,
        {fetchedData: null, isLoading: true, isError: false} 
    )

    const doRequest = useCallback(
        (payload) => {
            dispatchPageState({type: "data_fetch_init"})  
            axios.get("/dash/card_order/locks", {
                params: payload
            }).then(response=>{
                dispatchPageState({
                    type: "data_fetch_success",
                    payload: response.data
                })          
            }).catch(error => {
                if ((error.response || {}).status === 403) dispatchPageState({type: "data_fetch_failure_403"})
                else if ((error.response || {}).status === 404) dispatchPageState({type: "data_fetch_failure_404"})  
                else dispatchPageState({type: "data_fetch_failure"})    
            })
        },[]
    )

    useEffect(() => {
        let payload = {
            "page_number": pageParams.page - 1,
            "page_rows": 25
        }
        Object.keys(pageParams.filters).forEach((key,index) => {
            payload[key] = pageParams.filters[key]
        })
        const timer_ = setTimeout(() => {
            doRequest(payload)
        }, 300);
        return () => {
            clearTimeout(timer_)
		}	
    },[doRequest,pageParams, refreshPage])

    const handleRefreshPage = (goFirstPage=true) => {
        if (goFirstPage) setPageParams({page: 1, filters: JSON.parse(sessionStorage.getItem(local_storage_object_preffix + 'PageParams')).filters})
        else setRefreshPage(!refreshPage)
    }
    
    const handleChangePage = (event, page, filters) => {
		if (pageState.page === page) {
			return
		}
		setPageParams({page: page, filters: filters})
    }
    
    const handleOpenNewLockDialog = () => {
		setOpenNewLock(true);
	};
	
	const handleCloseNewLockDialog = () => {
		setOpenNewLock(false);
	};

	const contentContainer = (dataList) => {

		let nr_of_cards = dataList['data'].length
		let cards = dataList['data'].map((lock) => (
			<ErrorBoundary key={lock.lock_key}>
                <LockCard 
                    lock={lock} 
                    handleRefreshPage={handleRefreshPage}
                />
			</ErrorBoundary>
		))

		if (nr_of_cards === 0) {
			return (
			<div className='emptyContainer'>
				<p className={["blueText", "subtitleSize"].join(" ")}>
					Nenhum bloqueio a ser analisado
				</p>
			</div>
			)
		}

		else {
			return (
                <div className='cardContainer'>
                    <div className="listCardHeader">
                        {user_data.business_group_key ?
                        <div 
                            className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                            style={{flex:"2 1 90px", textAlign:"center"}}
                        >
                            Companhia
                        </div> : null}
                        <div 
                            className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                            style={{flex:"1 1 55px", textAlign:"center"}}
                        >
                            Tipo do Bloqueio
                        </div>
                        <div 
                            className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                            style={{flex:"1 1 55px", textAlign:"center"}}
                        >
                            Documento Bloqueado
                        </div>
                        <div 
                            className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                            style={{flex:"1 1 55px", textAlign:"center"}}
                        >
                            Origem
                        </div>
                        <div 
                            className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                            style={{flex:"1 1 55px", textAlign:"center"}}
                        >
                            Data
                        </div>
                        <div 
                            className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                            style={{flex:"1 1 55px", textAlign:"center"}}
                        >
                            Status
                        </div>
                        <div 
                            className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                            style={{flex:"0 1 70px", textAlign:"center"}}
                        />
                    </div>
                    {cards}
                </div>
            )
		}
	}

    const getPageContent = () => {
        if (pageState.fetchedData) {
            return (
                <React.Fragment>
                    <ErrorBoundary doNothing>
                        <Filter 
                            local_storage_object_preffix={local_storage_object_preffix} 
                            initialFilters={initialFilters}
                            setPageParams={setPageParams} 
                        />
                    </ErrorBoundary>
                    {contentContainer(pageState.fetchedData)}
                    <div>
                        <Pagination 
                            className="pagination" 
                            page={pageParams.page} 
                            count={parseInt(pageState.fetchedData["number_of_pages"])}
                            onChange={(e, page) => handleChangePage(e, page, pageParams.filters)}
                        />
                    </div>
                </React.Fragment>
            );
        }
        else if (pageState.isError){
            return (
                <React.Fragment>
                    <ErrorBoundary doNothing>
                        <Filter 
                            local_storage_object_preffix={local_storage_object_preffix} 
                            initialFilters={initialFilters}
                            setPageParams={setPageParams} 
                        />
                    </ErrorBoundary>
                    <div style={{height: "55vh", display:"flex"}}>
                        <p className={["blueText", "titleSize"].join(" ")} style={{margin:"auto", textAlign:"center"}}>
                            {pageState.errorMessage}
                        </p>
                    </div>
                </React.Fragment>	
            )
        }
        else if (pageState.isLoading) {
            return(
                <React.Fragment>
                    <div className='circularProgressCenter'>
                        <CircularProgress />
                    </div>
                </React.Fragment>	
            )
        }

    }
    return (
        <div style={{ width: "auto" }}>
            <div style={{display:"flex"}}>
                <div>
                    <p className={["blueText", "titleSize"].join(" ")}>BLOQUEIOS</p>
                </div>
                <div style={{margin: "auto 20px auto auto"}}>
                    {user_data.roles.includes('create_card_order_locks') ?
                    <div
                        className={["normalText", "button", "standard"].join(" ")}
                        onClick={handleOpenNewLockDialog}
                    >
                        + NOVO BLOQUEIO
                    </div>: null
                    }
                </div>	
            </div>		
            {getPageContent()}
            <NewLockDialog
                open={openNewLock}
                onClose={handleCloseNewLockDialog}
                handleRefreshPage={handleRefreshPage}
            />
        </div>
    )
	
}

export default LocksInbox