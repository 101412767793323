import React, {useState} from "react";
import SelectLockTypeContent from "./SelectLockTypeContent"
import InputContent from "./InputContent"
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

function NewLockDialog(props) {

    let {open} = props
    let {onClose} = props
    let {handleRefreshPage} = props
    let {presetLockType} = props
    let {presetLockedEventType} = props

    const [lockType,setLockType] = useState(presetLockType ?? null)
    const [lockedEventType,setLockedEventType] = useState(presetLockedEventType ?? null)

    const handleDialogClose = () => {
        onClose()
        setTimeout(() => {
            setLockedEventType(presetLockedEventType ?? null)
            setLockType(presetLockType ?? null)
        }, 100)
    }
    
    let dialogContent = null

    if (!lockType){
        dialogContent = <SelectLockTypeContent 
            roles={props.roles} 
            setLockType={setLockType}
            setLockedEventType={setLockedEventType}
            lockedEventType={lockedEventType}
        />
    }
    else (
        dialogContent = <InputContent 
            setLockType={setLockType}
            setPageParams={props.setPageParams}
            lockType={lockType}
            lockedEventType={lockedEventType}
            local_storage_object_preffix={props.local_storage_object_preffix}
            onClose={handleDialogClose}
            handleRefreshPage={handleRefreshPage}
        />
    )

    return (
        <Dialog 
        open={open} 
        onClose={handleDialogClose}
        disableBackdropClick
        keepMounted={false}>
            <DialogTitle 
                className={['blueText', "subtitleSize"].join(" ")} 
                onClose={handleDialogClose}
            >
                Novo Bloqueio
            </DialogTitle>
            <DialogContent >
                <div style={{width:"366px", minHeight: "160px", display:"flex", flexDirection:"column", marginBottom:"30px"}}>
                    {dialogContent}
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default NewLockDialog