import React from 'react';
import ReactDOM from 'react-dom';
import './assets/styles/index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import "./assets/styles/DatePicker_theme_override.css"
import "./assets/styles/generalStyles.scss"
import "./assets/styles/map.css"
import "./assets/styles/ruleEngineStyles.css"

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
