import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Chart from "chart.js/auto";

function LineChart(props) {
  const {t} = useTranslation()
  const [chart, setChart] = useState();

  useEffect(() => {

    if(chart){
      chart.destroy()
    }

    const label_credit_to_be_expired = `${t("credit_to_be_expired")}(R$)`
    const label_credit_overdue = `${t("overdue_credit")}(R$)`
    const ctx = document.getElementById(props.id).getContext('2d', {willReadFrequently: true});

    const data = {
        labels: props.labels, 
        datasets: [{
          label: label_credit_to_be_expired,
          data: props.dataValue_toBeExpired, 
          fill: true,
          tension: 0.1,
          backgroundColor: 'rgba(79, 204, 237, 0.05)',
          borderColor: 'rgb(79, 204, 237, 0.7)',
          pointBackgroundColor: 'rgb(79, 204, 237)',
          pointBorderColor: 'rgb(79, 204, 237)',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgb(79, 204, 237)',
          pointBorderWidth: 2
        },{
          label: label_credit_overdue,
          data: props.dataValue_overdue,
          fill: true,
          tension: 0.1,
          backgroundColor: 'rgba(254, 64, 128, 0.05)',
          borderColor: 'rgb(254, 64, 128, 0.7)',
          pointBackgroundColor: 'rgb(254, 64, 128)',
          pointBorderColor: 'rgb(254, 64, 128)',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgb(254, 64, 128)',
          pointBorderWidth: 2
        }]
    }
    
    const lineChart = new Chart(ctx, {
      type: 'line',
      options: {
        elements: {
          line: {
            borderWidth: 4
          }
        }
      },
      data: data
    });

    setChart(lineChart)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    return (
        <div>
          <canvas id={props.id} width="500" ></canvas>
        </div>
    );
  }
  

export default LineChart;

