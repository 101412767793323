import React from "react";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckIcon from "@material-ui/icons/Check";

const colorGreen = '#73C580'
const colorRed ='#D91A1A'
const colorGrey = 'rgb(170, 167, 153)'
const colorYellow = "#FFBB24"

const getIndicatorFlagIcon = (flag) => {
    let margin_ = "auto"
    switch(flag){
        case "positive":
            return (<CheckIcon style={{verticalAlign: "middle", width:"20px", height:"20px", color:colorGreen, marginTop:"auto",marginBottom:"auto", marginRight:margin_,marginLeft:margin_}}/>)
        case "negative":
            return (<ErrorOutlineIcon style={{verticalAlign:"middle", width:"20px", height:"20px", color:colorRed, marginTop:"auto",marginBottom:"auto", marginRight:margin_,marginLeft:margin_}}/>)
        case "neutral":
            return(<ErrorOutlineIcon style={{verticalAlign:"middle", width:"20px", height:"20px", color:colorYellow, marginTop:"auto",marginBottom:"auto", marginRight:margin_,marginLeft:margin_}}/>)
        default:
            return(<ErrorOutlineIcon style={{verticalAlign:"middle", width:"20px", height:"20px", color:colorGrey, marginTop:"auto",marginBottom:"auto", marginRight:margin_,marginLeft:margin_}}/>)
    }
}

const getIndicatorFlagColor = (flag) => {
    switch(flag){
        case "positive":
            return colorGreen
        case "negative":
            return colorRed
        case "increase_pre_authorization":
        case "neutral":
            return colorYellow
        default:
            return colorGrey
    }
}

const getStatusColor = (status) => {
    switch(status){
        case "approve":
            return colorGreen
        case "reprove":
            return colorRed
        case "increase_pre_authorization":
        case "neutral":
                    return colorYellow
        default:
            return colorRed
    }
}

const getStatus = (status) => {
    switch(status){
        case "approve":
          	return "Aprovar";
        case "reprove":
            return "Reprovar";
        case "neutral":
            return "Neutro"
        case "increase_pre_authorization":
            return "Majorar Pré Autorização"
        default:
            return status;
    }
}

function Suggestion(props) {
  return (
    <div className="analysisCard" style={{height:"100%", borderWidth:"1px", borderStyle:"solid", borderColor:getStatusColor(props.suggestion.decision)}}>
        <div className={["blueText", "subtitleSize"].join(" ")}>Sugestão</div>
        <div 
            className={["normalText", "substitleSize", "bold"].join(" ")} 
            style={{margin:"auto", color:getStatusColor(props.suggestion.decision)}} >
            {getStatus(props.suggestion.decision)}                    
        </div>
        <div className="internalCardContainer" style={{display:"flex", flexGrow:"1"}}>
            <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                {props.suggestion.reason_description}
            </div>
        </div>
        { (props.suggestion.indicators || []).length > 0 ?
        <React.Fragment>
        <div className={["blueText", "subtitleSize"].join(" ")}>Motivos</div>
        <div className="internalCardContainer" style={{display:"flex", flexGrow:"1", width:"100%"}}>
            <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"0"}}>
                {props.suggestion.indicators.map((indicator, index) => (
                        <div key={index} style={{display: "flex", margin: "5px 0px"}}>
                            <div style={{display:"flex", margin:"auto 0px"}}>
                                {getIndicatorFlagIcon(indicator.flag)}
                            </div>
                            <div className={["normalText", "normalSmallSize", "labelSpace"].join(" ")} style={{marginTop:"auto",marginBottom:"auto", color: getIndicatorFlagColor(indicator.flag)}}>
                                {indicator.description}
                            </div>                         
                        </div>
                    ))}
            </div>
        </div></React.Fragment> : null}
      </div>
  );
}

export default Suggestion;
