import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next"
import { GetAlertsComponent, setObjectByPath, validateConstantValues } from "./utils"
import _ from "lodash"
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Tooltip from "@material-ui/core/Tooltip"
import HelpIcon from "@material-ui/icons/Help"
import Checkbox from '@material-ui/core/Checkbox'
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TimeDeltaTextField from './Components/TimeDeltaTextField'
//SCAN DO DISPOSITIVO - Mesmo usuário com mesmo dispositivo

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#0b1f82"
        },
    }
})



export function DeviceScanUserSameDevicesBox(props) {
    const { t } = useTranslation()
    let { node } = props
    let { read_only } = props
    let { handleConfigurationOpen } = props
    let { handleNodeRemove } = props

    function getVisualPattern(value) {
        let weekSplit = value.split("W")
        let weekValue = ""
        if (weekSplit.length > 1) {
            weekValue = weekSplit[0].replace(/\D/g, "")
            weekValue = /^0*$/.test(weekValue) ? "" : weekValue + t(" Semanas, ")
            value = weekSplit[1]
        }
        let daySplit = value.split("D")
        let dayValue = ""
        if (daySplit.length > 1) {
            dayValue = daySplit[0].replace(/\D/g, "")
            dayValue = /^0*$/.test(dayValue) ? "" : dayValue + t(" Dias, ")
            value = daySplit[1]
        }
        let hourSplit = value.split("H")
        let hourValue = ""
        if (hourSplit.length > 1) {
            hourValue = hourSplit[0].replace(/\D/g, "")
            hourValue = /^0*$/.test(hourValue) ? "" : hourValue + t(" Horas, ")
            value = hourSplit[1]
        }
        let minuteSplit = value.split("M")
        let minuteValue = ""
        if (minuteSplit.length > 1) {
            minuteValue = minuteSplit[0].replace(/\D/g, "")
            minuteValue = /^0*$/.test(minuteValue) ? "" : minuteValue + t(" Minutos, ")
            value = minuteSplit[1]
        }
        let secondSplit = value.split("S")
        let secondValue = ""
        if (secondSplit.length > 1) {
            secondValue = secondSplit[0].replace(/\D/g, "")
            secondValue = /^0*$/.test(secondValue) ? "" : secondValue + t(" Segundos")
            value = secondSplit[1]
        }
        return weekValue + dayValue + hourValue + minuteValue + secondValue
    }

    let registrationKey = node.node.properties.external_user_id_search ? "ID de Registro" : ""
    let documentNumber = node.node.properties.document_number_search ? "Documento" : ""

    let searchString = (node.node.properties.external_user_id_search && node.node.properties.document_number_search
        ?
        registrationKey + " e " + documentNumber
        :
        registrationKey + documentNumber)

    let companyString = node.node.properties.outside_company_search ? " em toda a Base QITech KYC" : " apenas dentro da companhia"
    return (
        <div>
            {!read_only ?
                <div style={{display:"flex", alignItems: "center"}}>
                    <div
                        style={{ display: "flex", width: "100%", cursor: "pointer", margin: "0px" }}
                        onClick={() => handleConfigurationOpen(node)}
                    >
                        <div style={{ display: "flex", flexDirection: "column", margin: "auto 0px" }}>
                            <div className={["normalText", "normalMediumSize"].join(" ")}>
                                {t(node.node.description)}
                            </div>
                            <div className={["normalText", "normalSmallSize"].join(" ")}>
                                {t("Busca por: ") + t(searchString) + companyString}
                                {node.node.properties.timedelta_value
                                    ?
                                    t(" nos últimos ") + t(getVisualPattern(node.node.properties.timedelta_value))
                                    :
                                    t(" em todo o histórico")}
                            </div>
                        </div>
                        {(node.node.alerts || []).length > 0 ?
                            <GetAlertsComponent alerts={node.node.alerts} /> : null}
                    </div>
                    <IconButton style={{width: 32, height: 32}} aria-label="duplicate" onClick={() => props.handleNodeDuplication(node)}>
                        <ContentCopyIcon style={{width: 20, height: 20}} />
                    </IconButton>
                    <IconButton style={{width: 32, height: 32}} aria-label="close" onClick={() => handleNodeRemove(node)}>
                        <CloseIcon style={{width: 20, height: 20}} />
                    </IconButton>
                </div>
                :
                <div style={{ display: "flex" }}>
                    <div
                        style={{ display: "flex", width: "100%", cursor: "pointer", margin: "0px" }}
                        onClick={() => handleConfigurationOpen(node)}
                    >
                        <div style={{ display: "flex", flexDirection: "column", margin: "auto 0px" }}>
                            <div className={["normalText", "normalMediumSize"].join(" ")}>
                                {t(node.node.description)}
                            </div>
                            <div className={["normalText", "normalSmallSize"].join(" ")}>
                                {t("Busca por: ") + t(searchString) + companyString}
                                {node.node.properties.timedelta_value
                                    ?
                                    t(" nos últimos ") + t(getVisualPattern(node.node.properties.timedelta_value))
                                    :
                                    t(" em todo o histórico")}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export function DeviceScanUserSameDevicesConfiguration(props) {
    const { t } = useTranslation()
    let { node } = props
    let { read_only } = props
    let { handleNodeChange } = props
    let { handleConfigurationClose } = props

    const [nodeState, setNodeState] = useState(node)

    useEffect(() => {
        setNodeState(node)
    }, [node])

    const handleNodeStateChange = (obj_key, value) => {
        let new_node = _.cloneDeep(nodeState)
        setObjectByPath(new_node.node, obj_key, value)
        setNodeState(new_node)
    }

    const handleSave = () => {
        handleConfigurationClose()
        handleNodeChange(nodeState)
    }

    const validateAllValues = () => {
        let timedelta_validation = (nodeState.node.properties.timedelta_value
            ?
            validateConstantValues("timedelta", nodeState.node.properties.timedelta_value)
            :
            true)

        let search_scope_validation = nodeState.node.properties.document_number_search || nodeState.node.properties.external_user_id_search
        return (search_scope_validation && timedelta_validation)
    }

    return (
        <div style={{ flexGrow: "1", padding: "20px", display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex" }}>
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>{t(node.node.description)}</span>
                <Tooltip title={<div className="tooltipText"><ul>{node.node.helperText.map((help, index) => (<li key={index}>{t(help)}</li>))}</ul></div>}>
                    <HelpIcon style={{ fontSize: "14px", display: "flex", margin: "auto 5px" }} />
                </Tooltip>
            </div>
            <div className="internalCardContainer" style={{ display: "flex", flexDirection: "column" }}>
                <ThemeProvider theme={theme}>
                    <div>
                        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                            <div style={{ display: "flex" }}>
                                <Checkbox
                                    onChange={(e) => handleNodeStateChange("properties.document_number_search", e.target.checked)}
                                    checked={nodeState.node.properties.document_number_search}
                                    color="primary"
                                />
                                <div>
                                    <p
                                        className={["normalText", "normalMediumSize"].join(" ")}
                                    >
                                        {t("Busca por Documento")}
                                    </p>
                                </div>
                            </div>
                            <div style={{ display: "flex" }}>
                                <Checkbox
                                    onChange={(e) => handleNodeStateChange("properties.external_user_id_search", e.target.checked)}
                                    checked={nodeState.node.properties.external_user_id_search}
                                    color="primary"
                                />

                                <div>
                                    <p
                                        className={["normalText", "normalMediumSize"].join(" ")}
                                    >
                                        {t("Busca por ID de Registro")}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <Checkbox
                                onChange={(e) => handleNodeStateChange("properties.outside_company_search", e.target.checked)}
                                checked={nodeState.node.properties.outside_company_search}
                                color="primary"
                            />
                            <div>
                                <p
                                    className={["normalText", "normalMediumSize"].join(" ")}
                                >
                                    {t("Busca em toda a Base QITech KYC")}
                                </p>
                            </div>
                        </div>
                        <TimeDeltaTextField
                            value={nodeState.node.properties.timedelta_value ? nodeState.node.properties.timedelta_value : ""}
                            error={nodeState.node.properties.timedelta_value ? !validateConstantValues("timedelta", nodeState.node.properties.timedelta_value) : false}
                            valueChangeFunction={(value) => handleNodeStateChange("properties.timedelta_value", value)}
                            label={"Período de Tempo considerado"}
                            read_only={read_only}
                        />
                    </div>
                </ThemeProvider>
            </div>
            <div style={{ display: "flex", margin: "auto auto 5px auto" }}>
                <div
                    className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")}
                    style={{ width: "120px" }}
                    onClick={handleConfigurationClose}
                >
                    {read_only ? "X" : t("discard")}
                </div>
                {!read_only &&
                    <div
                        className={
                            validateAllValues()
                                ?
                                ["button", "standard", "normalText", "normalMediumSize"].join(" ")
                                :
                                ["button", "standard", "normalText", "normalMediumSize", "disabled"].join(" ")}
                        style={{ width: "120px" }}
                        onClick={validateAllValues() ? handleSave : null}
                    >
                        {t("save")}
                    </div>}
            </div>
        </div>
    )
}

export class DeviceScanUserSameDevicesBoxClass {
    constructor(rulesContext) {
        this.rulesContext = rulesContext
    }
    validate(node) {
        let put_error = false
        let alerts = []
        //Validate if node has no children
        if ((node.node.children || []).length !== 0) {
            put_error = true
            alerts.push("Este nó não deve possuir filhos")
        }
        if (!validateConstantValues("bool", node.node.properties.document_number_search)) {
            put_error = true
            alerts.push("A propriedade 'Busca por Documento' deve ser um 'Binário' válido")
        }
        if (!validateConstantValues("bool", node.node.properties.external_user_id_search)) {
            put_error = true
            alerts.push("A propriedade 'Busca por ID de Registro' deve ser um 'Binário' válido")
        }
        if (!validateConstantValues("bool", node.node.properties.outside_company_search)) {
            put_error = true
            alerts.push("A propriedade 'Busca em toda a Base QITech KYC' deve ser um 'Binário' válido")
        }
        if (!node.node.properties.document_number_search && !node.node.properties.external_user_id_search) {
            put_error = true
            alerts.push("Pelo menos um dos métodos de busca deve ser escolhido")
        }
        if (node.node.properties.timedelta_value) {
            if (!validateConstantValues("timedelta", node.node.properties.timedelta_value)) {
                put_error = true
                alerts.push("Deve ser fornecido um período de tempo válido")
            }
        }
        //Validate if parent is valid
        if (node.parentNode != null) {
            if (node.parentNode.on_error) {
                put_error = true
                alerts.push("O nó superior está inválida")
            }
        }
        return { validated: !put_error, alerts: alerts }
    }

    getType(node) {
        return "bool"
    }
}