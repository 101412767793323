import React from "react";
import Collapse from "@material-ui/core/Collapse";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import IconButton from "@material-ui/core/IconButton";
import BoavistaNegativeNotesCard from "./BoavistaNegativeNotesCard/BoavistaNegativeNotesCard";
import BoavistaBasicDataCard from "./BoavistaBasicDataCard/BoavistaBasicDataCard";
import BoavistaScoreCard from "./BoavistaScoreCard/BoavistaScoreCard";
import BoavistaIncomeCard from "./BoavistaIncomeCard/BoavistaIncomeCard";

function Boavista(props) {

    let {boavista,
        expandedBoavista, 
        setExpandedBoavista,
        preparePDF,
        setHasBoavistaInquiriesImg,
        setBoavistaInquiries2Img,
        boavistaInquiries2Img
    } = props

    const boavista_data = boavista.data[0]

    const handleExpand = (event) => {
        event.stopPropagation();
        setExpandedBoavista(!expandedBoavista);
    };

    return (
        <div className="analysisCard">
            <div style={{display:"flex"}}>
                <div 
                    className={["blueText", "subtitleSize", "bold"].join(" ")}
                    style={{margin:"auto 0px"}}
                >
                    Boa Vista
                </div>
                <IconButton style={{marginLeft: "auto"}} onClick={handleExpand}>
                    {expandedBoavista ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </IconButton>
            </div>
            <Collapse in={expandedBoavista} timeout="auto" unmountOnExit>
                <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                    <div style={{display: "grid", gridTemplateColumns: '60% 40%'}}>
                        {(boavista_data)? 
                            <BoavistaBasicDataCard data={boavista_data}/>
                        : null}
                        <div style={{display: "flex", flexDirection: "column"}} >
                            {(boavista_data || {}).score_boavista.score ?
                                <BoavistaScoreCard score={boavista_data.score_boavista.score}/>
                            : null}
                            {(boavista_data || {}).income_boavista ?
                                <BoavistaIncomeCard income={boavista_data.income_boavista}/>
                            : null}
                        </div>
                    </div>
                    {(boavista_data || {}).debts&& 
                    <BoavistaNegativeNotesCard 
                        preparePDF={preparePDF}
                        negative_notes_data={boavista_data.debts}
                        has_negative_note={boavista_data.debts.is_available}
                        negative_note_type={"debts"}
                        tableStyle={"10% 35% 10% 10% 10% 10% 7.5%"}
                        isPDFTableStyle={"10% 30% 10% 10% 10% 10% 7.5% 7.5% 5%"}
                        />}
                    {(boavista_data || {}).inquiries&&
                    <BoavistaNegativeNotesCard 
                        preparePDF={preparePDF}
                        negative_notes_data={boavista_data.inquiries}
                        has_negative_note={boavista_data.inquiries.is_available}
                        negative_note_type={"inquiries"} 
                        tableStyle={"33.3% 33.3% 33.3%"}
                        setHasBoavistaInquiriesImg = {setHasBoavistaInquiriesImg}
                        setBoavistaInquiries2Img = {setBoavistaInquiries2Img}
                        boavistaInquiries2Img = {boavistaInquiries2Img}
                        />}
                    {(boavista_data || {}).protests ? 
                    <BoavistaNegativeNotesCard
                        negative_notes_data={boavista_data.protests}
                        has_negative_note={boavista_data.protests.is_available}
                        negative_note_type={"protests"}
                        tableStyle={"20% 20% 20% 20% 20%"}
                        props={props}/>
                    : null}
                </div>
            </Collapse>
        </div>
    );
}

export default Boavista
