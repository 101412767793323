import React from 'react';

const companies_context = React.createContext({
    onboarding_list: null,
    card_issuance_backoffice_list: null,
    lists_companies: null,
    pix_companies: null,
    credit_analysis_companies: null,
    lists_feature_toggle: null,
    settings: null,
    dashboard_settings: null,
    bill_payment_companies: null,
    bankslip_companies: null,
    wire_transfer_companies: null,
    withdrawal_companies: null
});

export default companies_context;