import React, { useEffect, useCallback, useContext, useState, useReducer } from "react";
import {dataFetchReducer} from "../../Utils/requestUtils"
import { useParams } from "react-router-dom";
import Observation from "./Observation/Observation";
import Alerts from "./Alerts/Alerts";
import Rental from "./Rental/Rental";
import ScoreBox from "./ScoreBox/ScoreBox";
import Suggestion from "./Suggestion/Suggestion"
import Devices from "./Devices/Devices"
import Reason from "./Reason/Reason";
import TimerBox from "./TimerBox/TimerBox"
import ClientProfile from "./ClientProfile/ClientProfile";
import Store from "./Store/Store";
import History from "./History/History";
import AnalystModal from "../RentalAgreement/AnalystModal/AnalystModal"
import axios from "axios";
import ErrorBoundary from "../../Utils/ErrorBoundary"
import { translateClientType} from "../utils/utils"
import {useHistory} from 'react-router-dom'
import AuthContext from '../../../context/auth-context'

function Reservation (props) {
    let history = useHistory()
    let user_data = useContext(AuthContext).user_data

    if (!user_data.roles.includes("read_car_rental_reservations")){
        history.push("")
    }

	let { reservation_key } = useParams();

    const [reservation, dispatchReservation] = useReducer(
        dataFetchReducer,
        {fetchedData: null, isLoading: true, isError: false} 
    )
    const [currentAnalyst, setCurrentAnalyst] = useState(null) 
    const [analystDialogOpen, setAnalystDialogOpen] = useState(true)

    const analystHeartBeat = useCallback(
        (isMounted) => {
            let payload = {
                analystMounted: isMounted
            }
            axios.put('/dash/car_rental/reservation/' + reservation_key + "/analyst_heartbeat",payload).then( response => { 
                setCurrentAnalyst(response.data.analyst)
			}).catch(error => {console.log(error)})
        },[reservation_key]
    )

	useEffect(() => {
		dispatchReservation({type: "data_fetch_init"})
		const timer = setTimeout(() => {
			axios.get('/dash/car_rental/reservation/' + reservation_key).then( response => { 
                dispatchReservation({
                    type: "data_fetch_success",
                    payload: response.data
                })   
			}).catch(error => {
                if ((error.response || {}).status === 403) dispatchReservation({type: "data_fetch_failure_403"})
                else if ((error.response || {}).status === 404) dispatchReservation({type: "data_fetch_failure_404"})  
                else dispatchReservation({type: "data_fetch_failure"})    
			})	
		}, 500);
		return () => {
			clearTimeout(timer)
		}		
    }, [reservation_key])
    
    useEffect(() => {
        if ((reservation.fetchedData || {}).fraud_status === "pending" || 
        (reservation.fetchedData || {}).fraud_status === "in_manual_analysis"){
            analystHeartBeat(true)
            const timer = setInterval(() => {
                analystHeartBeat(true)
            }, 15000);
            return () => {
                clearInterval(timer)
                analystHeartBeat(false)
            }
        }	
	}, [analystHeartBeat, reservation])

 	if (reservation.fetchedData) {
        let reason = ((reservation.fetchedData.fraud_status_events || [{}])[0].decision_metadata || {}).reason_description
        if (reservation.fetchedData.rental_store){
            reservation.fetchedData.rental_store = reservation.fetchedData.rental_store.toUpperCase()
        }
        if (reservation.fetchedData.devolution_store){
            reservation.fetchedData.devolution_store = reservation.fetchedData.devolution_store.toUpperCase()
        }

        let suggestion = (((reservation.fetchedData.fraud_status_events || [{}])[0].decision_metadata || {}) || {}).suggestion
		return(
            <div style={{display:"flex", flexDirection:"column", paddingBottom: "30px", minWidth:"900px"}}>
                <div className="analysisCardContainer">
                    <div className={["blueText", "titleSize"].join(" ")}>
                        Reserva {reservation.fetchedData.reservation_code} {(reservation.fetchedData.client || {}).type ? "- Cliente " + translateClientType(reservation.fetchedData.client.type) : null}
                    </div>
                </div>
                <div style={{display:"flex"}}>
                    <div style={{display:"flex", flexDirection:"column", width: "45%"}}>
                        <div className="analysisCardContainer">
                            <ErrorBoundary>
                                <ClientProfile reservation={reservation.fetchedData} />
                            </ErrorBoundary>                            
                        </div>
                        <div className="analysisCardContainer" style={{flexGrow:"1"}}>
                            <ErrorBoundary>
                                <Store reservation={reservation.fetchedData} />
                            </ErrorBoundary>  
                        </div>
                    </div>
                    <div style={{display:"flex", flexDirection:"column", width: "55%"}}>
                        <div style={{display:"flex", width: "100%"}}>
                            <div className="analysisCardContainer" style={{width: "50%"}}>
                                <ErrorBoundary>
                                    <TimerBox reservation={reservation.fetchedData} />
                                </ErrorBoundary>  
                            </div>
                            <div className="analysisCardContainer" style={{width: "50%"}}>
                                <ErrorBoundary>
                                    <ScoreBox fraud_status_events={reservation.fetchedData.fraud_status_events}/>
                                </ErrorBoundary>  
                            </div>
                        </div>
                        <div style={{display:"flex", width: "100%", minHeight: "300px"}}>
                            <div style={{margin: "10px", display:"flex", flexDirection: "column", width: "50%"}}>
                                {reason ? 
                                <div className="analysisCardContainer" style={{margin: "0px 0px 10px 0px"}} >
                                    <ErrorBoundary>
                                        <Reason reason={reason}/>
                                    </ErrorBoundary>  
                                </div> : null}
                                {suggestion ?
                                <div className="analysisCardContainer" style={{margin: "10px 0px 0px 0px", flexGrow:"1"}}>
                                    <ErrorBoundary>
                                        <Suggestion suggestion={suggestion}/>
                                    </ErrorBoundary>  
                                </div> :
                                <div className="analysisCardContainer" style={{margin: "10px 0px 0px 0px", flexGrow:"1"}}>
                                    <ErrorBoundary>
                                        <Devices />
                                    </ErrorBoundary>  
                                </div> }
                            </div>
                            <div className="analysisCardContainer" style={{width: "50%"}}>
                                <ErrorBoundary>
                                    <Alerts fraud_status_events={reservation.fetchedData.fraud_status_events}/>
                                </ErrorBoundary>  
                            </div>
                        </div>
                        <div className="analysisCardContainer" style={{flexGrow:"1"}}>
                            <ErrorBoundary>
                                <Rental reservation={reservation.fetchedData} />
                            </ErrorBoundary>  
                        </div>
                    </div>
                </div>
                <div style={{display:"flex" , width: "100%"}}>
                    <div className="analysisCardContainer" style={{width: "55%"}}>
                        <ErrorBoundary>
                            <History reservation_key={reservation_key}/>
                        </ErrorBoundary>  
                    </div>
                    <div className="analysisCardContainer" style={{width: "45%", display: "block"}}>
                        <ErrorBoundary>
                            <Observation reservation={reservation.fetchedData}/> 
                        </ErrorBoundary>  
                    </div>
                </div>
                {["pending", "in_manual_analysis"].includes(reservation.fetchedData.fraud_status) &&
                    currentAnalyst != null && currentAnalyst.analyst_key !== user_data.user_key && analystDialogOpen ?
                    <AnalystModal
                        currentAnalyst={currentAnalyst}
                        open={analystDialogOpen}
                        analysisType={"reservation"}
                        onClose={() => setAnalystDialogOpen(false)}
                    /> : null}
            </div>
		);
	}
	else if(reservation.isError){
		return (
			<div style={{height: "80vh", display:"flex"}}>
				<p className={["blueText", "titleSize"].join(" ")} style={{margin:"auto", textAlign:"center"}}>
					{reservation.errorMessage}
				</p>
			</div>
		)
	}
  
  return null;
}

export default Reservation;