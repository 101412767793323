import React, {Component} from 'react'
import PropTypes from 'prop-types';
import { DragSource } from 'react-dnd';
import StandardLibraryComponent from "./StandardLibraryComponent"

const nodeGeneralTypes = 'GeneralNode'; //////////IMPORTANT

const externalNodeSpec = {
  beginDrag: componentProps => ({ node: { ...componentProps.node } }),
};
const externalNodeCollect = (connect /* , monitor */) => ({
  connectDragSource: connect.dragSource(),
});

class externalNodeBaseComponent extends Component {
  render() {
    const { connectDragSource, node } = this.props;

    return connectDragSource(<div style={{display: 'inline-block',width:"100%"}}><StandardLibraryComponent node={node} /></div>, { dropEffect: 'copy' });
  }
}
externalNodeBaseComponent.propTypes = {
  node: PropTypes.shape({ description: PropTypes.string }).isRequired,
  connectDragSource: PropTypes.func.isRequired,
};
const ExternalNodeComponent = DragSource(
    nodeGeneralTypes,
    externalNodeSpec,
    externalNodeCollect
)(externalNodeBaseComponent);

export default ExternalNodeComponent