import React, { useState, useEffect } from "react";
import Timer from "./Timer";
import moment from 'moment';
import { getAnalysisStatus, getAnalysisStatusColor } from "../../../utils/utils"

function TimeCounter (props) {

    let {bill_payment} = props

    const [totalSeconds, setTotalSeconds] = useState(null)

    const tick = (totalSeconds) => {
        totalSeconds = totalSeconds-1
        setTotalSeconds(totalSeconds)
    }

    //ON first render calculate the amount of time left
    useEffect(() => {
        const timer = setTimeout(() => {
        let limit = moment(bill_payment.manual_analysis_limit_date)
        let now = moment()
        let totalSeconds = limit.diff(now, 'seconds')
        setTotalSeconds(totalSeconds)
        },500)
        return function cleanup() {
        setTotalSeconds(null)
        clearTimeout(timer)  
        }
    },[bill_payment.manual_analysis_limit_date])

    //Every second reduce the totalSeconds
    useEffect(()=> {
        if (totalSeconds){
            const timer = setTimeout(function () {tick(totalSeconds)} , 1000)
            return () => {
                clearTimeout(timer)
            }
        }    
    },[totalSeconds])

    if (totalSeconds > 0){
        let remaining_minutes = Math.floor(totalSeconds/60)
        let remaining_seconds = totalSeconds%60
        return (
            <div className="analysisCard" style={{height:"100%"}}>
                <div style={{margin: "auto",display: "flex"}}>
                    <Timer limit_date={bill_payment.manual_analysis_limit_date} time={{minutes: remaining_minutes, seconds: remaining_seconds}}/>
                </div>
            </div>
        ) 
    }
    else if (totalSeconds != null && !isNaN(totalSeconds)){
        return (
            <div className="analysisCard" style={{height:"100%", borderWidth:"1px", borderStyle:"solid", borderColor:getAnalysisStatusColor("approved_by_time")}}>
                <div 
                    className={["normalText, substitleSize", "bold"].join(" ")} 
                    style={{margin:"auto", color:getAnalysisStatusColor("approved_by_time")}}
                >
                    {getAnalysisStatus("approved_by_time")}                    
                </div>
            </div>
        ) 
    }
    else {
        return (
            <div className="analysisCard" style={{height:"100%",  borderWidth:"1px", borderStyle:"solid", borderColor:getAnalysisStatusColor("invalid")}}>
                <div 
                    className={["normalText, substitleSize", "bold"].join(" ")} 
                    style={{margin:"auto", color:getAnalysisStatusColor("invalid")}}
                >
                    {getAnalysisStatus("Prazo Limite: -- : -- : --")}                    
                </div>
            </div>
        )
    }
}

export default TimeCounter;