import React from 'react'

function TransactionLockTypes (props){

    let { setLockType,lockTypePrefix } = props

    return (
        <div style={{ flexWrap:"wrap", display:"flex", justifyContent: "space-evenly", margin:"auto 0px"}}>
            <div
                className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")} 
                onClick={() => setLockType(lockTypePrefix+"_document_number")} 
                style={{width:"30%", margin:"10px 0"}}
            >
                Documento
            </div>
            {/* <div
                className={["button", "standard", "disabled", "onlyboarder", "normalText", "normalMediumSize"].join(" ")} 
                onClick={null} 
                style={{width:"30%", margin:"10px 0"}}
            >
                Conta
            </div> */}
        </div>
    )
}

export default TransactionLockTypes