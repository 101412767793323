import React, { useState } from "react";
import axios from "axios";
import DownloadCSVErrorDialog from "./DownloadCSVErrorDialog/DownloadCSVErrorDialog"
import CSVLimitSizeDialog from "./CSVLimitSizeDialog/CSVLimitSizeDialog"
import GeoShapeDialog from '../../Lists/List/ListOverviewCard/GeoShapeDialog';
import moment from 'moment'

function ExportCSVnoFilters (props) {

    const { csv_endpoint, file_name, hasGeoShapeField } = props

    let [isLoadingCSV, setIsLoadingCSV] = useState(false); 
   
    const downloadCSV = () => {
		setIsLoadingCSV(true)
		axios({
			url: csv_endpoint,
			method: 'GET',
			responseType: 'blob',
		  }).then((response) => {
            setIsLoadingCSV(false);
            
			(response.headers['csv_line_number'] === response.headers['csv_limit_line_number']) 
            ?
            handleCSVLimitSizeDialogOpen()
            : 
            handleCSVLimitSizeDialogClose();

			let datetime = moment().format("YYYY-MM-DDTHH-mm-SS")
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', file_name + datetime + '.csv' );
			document.body.appendChild(link);
			link.click();
		  }).catch(error => {
			setIsLoadingCSV(false)
			handleDownloadCSVErrorDialogOpen()
		})
	}; 
   
	let [downloadCSVErrorDialog, setDownloadCSVErrorDialog] = useState(false); 

	const handleDownloadCSVErrorDialogOpen = () => {
		setDownloadCSVErrorDialog(true);
	};
	
	const handleDownloadCSVErrorDialogClose = () => {
		setDownloadCSVErrorDialog(false);
	};

	let [csvLimitSizeDialog, setCSVLimitSizeDialog] = useState(false); 

	const handleCSVLimitSizeDialogOpen = () => {
		setCSVLimitSizeDialog(true);
	};
	
	const handleCSVLimitSizeDialogClose = () => {
		setCSVLimitSizeDialog(false);
	};

    const[openGeoShapeDialog, setOpenGeoShapeDialog] = useState(false)

    const [ dialogTitle, setDialogTitle ] = useState("")
	const handleClick = (event) => {
		hasGeoShapeField ? setOpenGeoShapeDialog(true) : downloadCSV()
        setDialogTitle(event.target.innerText)
	}

		return (
			<div>
				{isLoadingCSV ? 
				<div 
					style={{ padding: "5px 30px", cursor: "wait", margin:"5px" }}
					className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
				>
					Carregando
				</div> 
				: 
				<div
					style={{ padding: "5px 30px", margin:"5px" }}
					className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
					onClick={handleClick}                        
				>
					Exportar CSV
				</div>}
				<GeoShapeDialog
                    open={openGeoShapeDialog}
                    onClose={() => setOpenGeoShapeDialog(false)}
                    dialogTitle={dialogTitle}
					type="exportCSV"
                />
                <DownloadCSVErrorDialog
                    open={downloadCSVErrorDialog}
                    onClose={handleDownloadCSVErrorDialogClose}
                />
                <CSVLimitSizeDialog
                    open={csvLimitSizeDialog}
                    onClose={handleCSVLimitSizeDialogClose}
                />
			</div>
		);
	
}

export default ExportCSVnoFilters;