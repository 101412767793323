import React, {useContext, useState} from 'react'
import TextField from "@material-ui/core/TextField";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { setAllowChanges, getAlertStatusText, replaceAll } from "../../utils/utilsAlert"
import AuthContext from "../../../../context/auth-context"
import TreatedDialog from "./Dialogs/TreatedDialog"
import LockDialog from "./Dialogs/LockDialog"
import UnlockDialog from "./Dialogs/UnlockDialog"
import moment from "moment"

const theme = createMuiTheme({
    overrides: { MuiInputBase: { root:{
        fontFamily: "Open Sans",
        fontSize: "14px",
        lineHeight: "19px"
    }}},
    palette:{
        primary: {
            main:"#0b1f82"
        },
    }
  });

function ObservationCard(props) {

    let [openTreatedDialog, setOpenTreatedDialog] = useState(false);
    let [openUnlockDialog, setOpenUnblockDialog] = useState(false);
    let [openLockDialog, setOpenBlockDialog] = useState(false);
    let [observationBox, setObservationBox] = useState();
    

    const handleDialogOpenClick = (dialog_type) => {
        let stateFunction
        switch (dialog_type){
            case "treated":
                stateFunction = setOpenTreatedDialog
                break
            case "lock":
                stateFunction = setOpenBlockDialog
                break
            case "unlock":
                stateFunction = setOpenUnblockDialog
                break
            default:
                throw new Error()
        }
        stateFunction(true)
    };

    const handleDialogClose = (dialog_type) => {
        let stateFunction
        switch (dialog_type){
            case "treated":
                stateFunction = setOpenTreatedDialog
                break
            case "lock":
                stateFunction = setOpenBlockDialog
                break
            case "unlock":
                stateFunction = setOpenUnblockDialog
                break
            default:
                throw new Error()
        }
        stateFunction(false)
    };

    const handleObservationBoxChange = (event) => {
        if (event.target.value.length === 0){
            setObservationBox(null)
        }
        else{
            setObservationBox(event.target.value)
        }
    };

    let roles = useContext(AuthContext).user_data.roles
    let allowChanges = setAllowChanges(props.alert.alert_status, roles)

    let observation = null
    let analyst = null
    if (!allowChanges){
        let last_event = props.alert.alert_events == null || props.alert.alert_events.length ===0? null :
            props.alert.alert_events[props.alert.alert_events.length-1]
        if (last_event){
            observation = last_event.observation
            let string = last_event.analyst_name == null 
                            ? last_event.event_date == null                
                                ? null 
                                : "Alerta %status no dia %day às %time.\n"
                            : "Alerta %status por %name, %email no dia %day às %time.\n"
            let mapObj = {
                "%status": getAlertStatusText(last_event.new_status).toLowerCase(),
                "%name": last_event.analyst_name,
                "%email": last_event.analyst_email,
                "%day": moment(last_event.event_date).format("DD/MM/YYYY"),
                "%time": moment(last_event.event_date).format("HH:mm"),
            }
            analyst = string == null ? null :replaceAll(string, mapObj)
        }
    }

    return (
        <div className="analysisCard">
            <div style={{display: "flex"}}>
                <span className={["blueText", "subtitleSize"].join(" ")}>Revisão</span>
            </div>
            <div className="internalCardContainer">
                <ThemeProvider theme={theme} >
                <TextField         
                    fullWidth
                    multiline
                    variant="outlined"
                    rows="5"
                    color="primary"
                    defaultValue={allowChanges 
                        ? null 
                        : observation == null 
                            ? analyst
                            : analyst != null 
                                ? `${analyst} ${observation}`
                                : observation}
                    disabled={!allowChanges}
                    onChange={handleObservationBoxChange}
                />
                </ThemeProvider>
            </div>
            {!allowChanges ? null :
            <div style={{display: "flex"}}>
                {/* <div className={["button", "link", "normalMediumSize"].join(" ")} style={{marginLeft:"auto"}}>
                    Silenciar
                </div> */}
                <div 
                    className={["button", "reproved", "normalText", "normalMediumSize"].join(" ")}
                    style={{marginLeft:"auto"}}
                    onClick={props.alert.lock_key ? () => handleDialogOpenClick("unlock") : () => handleDialogOpenClick("lock")}
                >
                    {props.alert.lock_key ? "Desbloquear" : "Bloquear"}
                </div>
                <div 
                    className={["button", "approved", "normalText", "normalMediumSize"].join(" ")} 
                    onClick={() => handleDialogOpenClick("treated")}
                >
                    Finalizar 
                </div>
            </div>
            }
            {!allowChanges ? null :
            <React.Fragment>
                <TreatedDialog
                    alert={props.alert}
                    data={observationBox}
                    open={openTreatedDialog}
                    onClose={() => handleDialogClose("treated")}
                />
                {props.alert.lock_key ?
                <UnlockDialog
                    alert={props.alert}
                    data={observationBox}
                    open={openUnlockDialog}
                    onClose={() => handleDialogClose("unlock")}
                /> :
                <LockDialog
                    alert={props.alert}
                    data={observationBox}
                    open={openLockDialog}
                    onClose={() => handleDialogClose("lock")}
                />}
            </React.Fragment>
            }
        </div>
    )
}

export default ObservationCard