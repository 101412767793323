import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AuthContext from "../../../context/auth-context";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import ChangePasswordDialog from "./ChangePassword";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  avatar: {
    margin: "0px",
    width: theme.spacing(4),
    height: theme.spacing(4),
    backgroundColor: "#a5a5a5"
  },
  avatarText: {
    fontSize: "14px",
    color: "white"
  },
  menuItem:{
    fontFamily: "Open Sans",
    fontWeight: 400,
    cursor: "default",
    fontSize: "14px",
    color:"gray"
  },
  nameMenuItem: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    cursor: "default",
    color:"gray",
    margin: 0,
    padding: "8px 16px 16px 16px",
    fontSize: "14px",
    minHeight: "20px",
    lineHeight: 1.5,
    letterSpacing: "0.00938em",
    paddingBottom: "6px",
    minWidth: "200px"
  },
  profilePaper: {
    width: "calc(100% - 8px)",
    backgroundColor: "#f1f2f7",
    display: "flex",
    textOverflow: "ellipsis",
    justifyContent: "center",
    gap: "8px",
    alignItems: "center",
    flexDirection: "row",
    cursor: "pointer"
  }
}));

const Profile = props => {
  const { t } = useTranslation();

  const classes = useStyles();
  useTheme();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [
    changePasswordDialogOpened,
    setChangePasswordDialogOpened
  ] = React.useState(false);

  const [
    changePasswordSnackbarStatus,
    setChangePasswordSnackbarStatus
  ] = React.useState({ opened: false, severity: null });

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClosePasswordDialog = success => {
    setChangePasswordDialogOpened(false);
    setChangePasswordSnackbarStatus({
      opened: true,
      severity: success == null ? null : success ? "success" : "error"
    });
  };

  const handleOpenPasswordDialog = () => {
    handleClose();
    setChangePasswordDialogOpened(true);
  };

  const handleCloseSnackBar = () => {
    setChangePasswordSnackbarStatus({
      opened: false,
      severity: changePasswordSnackbarStatus["severity"]
    });
  };

  const handleLogout = keycloak_adapter => {
    history.push("/");
    localStorage.removeItem("@kyc_session");
    sessionStorage.clear()
    keycloak_adapter.logout();
  };

  return (
    <AuthContext.Consumer>
      {context => (
        <React.Fragment>
          <div
            className={classes.profilePaper + " labelText"}
            onClick={handleClick}
          >
            <Avatar className={classes.avatar}>
              <div className={classes.avatarText}>
                {context.user_data.first_name.charAt(0) +
                  context.user_data.last_name.charAt(0)}
              </div>
            </Avatar>
            {props.expanded ? context.user_data.first_name + " " + context.user_data.last_name : null}
          </div>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            className={classes.menu}
          >
            <p className={classes.nameMenuItem}>{context.user_data.name}</p>
            <MenuItem className={classes.menuItem} onClick={() => handleOpenPasswordDialog()}>
              {t("Alterar Senha")}
            </MenuItem>
            <MenuItem className={classes.menuItem} onClick={() => handleLogout(context.keycloak_adapter)}>
              Logout
            </MenuItem>
          </Menu>
          {changePasswordDialogOpened ? (
            <ChangePasswordDialog
              open={changePasswordDialogOpened}
              onClose={handleClosePasswordDialog}
            />
          ) : null}
          {changePasswordSnackbarStatus["severity"] != null ?
          <Snackbar
            open={changePasswordSnackbarStatus["opened"]}
            autoHideDuration={5000}
            onClose={handleCloseSnackBar}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={handleCloseSnackBar}
              severity={changePasswordSnackbarStatus["severity"]}
            >
              {changePasswordSnackbarStatus["severity"] === "success"
                ? "Senha alterada com sucesso!"
                : "Falha ao alterar senha, entre em contato conosco"}
            </MuiAlert>
          </Snackbar> : null}
        </React.Fragment>
      )}
    </AuthContext.Consumer>
  );
};

export default Profile;
