import React, { useState, useContext } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useStateConnectedToCache } from "../../../Utils/requestUtils";
import axios from "axios"
import ErrorBoundary from "../../../Utils/ErrorBoundary";
import FilterFraudReport from "../Filter/FilterFraudReport"
import AuthContext from "../../../../context/auth-context";
import DownloadCSVErrorDialog from "../DownloadCSVErrorDialog/DownloadCSVErrorDialog"
import CSVLimitSizeDialog from "../CSVLimitSizeDialog/CSVLimitSizeDialog"
import moment from 'moment'


function FraudReportDialog(props) {
    let {open, onClose } = props

    let local_storage_object_preffix= "wire_transfer_fraud"

    let roles = useContext(AuthContext).user_data.roles;

    const [pageParams, setPageParams] = useStateConnectedToCache(
        local_storage_object_preffix
    );

    let [isLoadingCSV, setIsLoadingCSV] = useState(false); 

    let [downloadCSVErrorDialog, setDownloadCSVErrorDialog] = useState(false); 
 
    const handleDownloadCSVErrorDialogOpen = () => {
        setDownloadCSVErrorDialog(true);
    };	
    const handleDownloadCSVErrorDialogClose = () => {
        setDownloadCSVErrorDialog(false);
        onClose()
    };
    let [csvLimitSizeDialog, setCSVLimitSizeDialog] = useState(false); 
 
    const handleCSVLimitSizeDialogOpen = () => {
        setCSVLimitSizeDialog(true);
    };	
    const handleCSVLimitSizeDialogClose = () => {
        setCSVLimitSizeDialog(false);
        onClose()

    };
  
    const downloadCSV = () => {
        setIsLoadingCSV(true)
        let csv_filters = {}
        Object.keys(pageParams.filters).forEach((key,index) => {
            csv_filters[key] = pageParams.filters[key]
        })
        axios({
            url: '/dash/wire_transfer/fraud_events/csv',
            method: 'GET',
            responseType: 'blob',
            params: csv_filters
        }).then((response) => {
            setIsLoadingCSV(false);
            
            (response.headers['csv_line_number'] === response.headers['csv_limit_line_number']) 
            ?
            handleCSVLimitSizeDialogOpen()
            : 
            handleCSVLimitSizeDialogClose();

            let datetime = moment().format("YYYY-MM-DDTHH-mm-SS")
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Wire_Transfer_Fraud_Events' + datetime + '.csv' );
            document.body.appendChild(link);
            link.click();
        }).catch(error => {
            setIsLoadingCSV(false)
            handleDownloadCSVErrorDialogOpen()
        })
    };

    return (
        <div>
            <Dialog 
            open={open} 
            onClose={onClose} 
            keepMounted={false}
            >
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginRight:"24px"}}>
                    <DialogTitle style={{paddingBottom: "0px"}} className={["blueText", "normalMediumSize"].join(" ")}>Relatório de Fraude</DialogTitle>
                    <IconButton size="small" onClick={onClose}>
                        <CloseIcon fontSize="small"/> Sair
                    </IconButton >
                </div>
                
                <DialogContent >
                <div style={{height: "300px"}}>
                    <ErrorBoundary doNothing><FilterFraudReport local_storage_object_preffix={local_storage_object_preffix} setPageParams={setPageParams} /></ErrorBoundary>
                    <div style={{display: "flex", flexDirection:"column"}}>
                        {roles.includes("update_wire_transfers") ?
                            isLoadingCSV ? 
                            <div 
                                style={{ padding: "5px 20px", cursor: "wait", margin:"5px" }}
                                className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                            >
                                Carregando
                            </div> 
                            : 
                            <div className="internalCardContainer" style={{display:"flex", justifyContent:"center"}}>
                                <div
                                    style={{ padding: "5px 30px", margin:"5px", width:"150px" }}
                                    className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                                    onClick={downloadCSV}                        
                                >
                                    Exportar CSV
                                </div>
                            </div> : null}
                </div>
                </div> 
                </DialogContent>
                </Dialog>
                <DownloadCSVErrorDialog
                open={downloadCSVErrorDialog}
                onClose={handleDownloadCSVErrorDialogClose}
                />
                <CSVLimitSizeDialog
                    open={csvLimitSizeDialog}
                    onClose={handleCSVLimitSizeDialogClose}
                />
        </div>
    )
}

export default FraudReportDialog;