import React, { useReducer, useContext } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";
import axios from "axios"
import { CircularProgress } from '@material-ui/core';
import ConfirmationContent from "./ConfirmationContent"
import {decisionReducer} from "../../../../../Utils/requestUtils"
import AuthContext from "../../../../../../context/auth-context"

function TreatedDialog(props) {
    const history = useHistory()
    let {isValidAlert} = props
    let {observation} = props
    let {alert_key} = props.alert
    let {open} = props
    let {onClose} = props
    let company_key = useContext(AuthContext).user_data.company_key 


    let title = isValidAlert ? "Finalizar Alerta - Válido" : "Finalizar Alerta - Inválido" 

    const [dialogState, dispatchDialogState] = useReducer(
        decisionReducer,
        {isLoading: false, isError: false, finished:false}
    )

    const handleApprove = () =>{
        dispatchDialogState({type:"send_request_init"})
        let payload={
            alert_status: isValidAlert ? "solved_true_positive" : "solved_false_positive"
        }
        if (observation != null && observation !== ""){
            payload ={...payload, observation: observation}
        }
        setTimeout(function () {
            axios.put('/dash/alerts/alert/' + alert_key, payload).then(response => { 
                dispatchDialogState({type:"send_request_success"})
              }).catch(error => { 
                if ((error.response || {}).status === 403) dispatchDialogState({type: "send_request_failure_403"})
                else if ((error.response || {}).status === 409) dispatchDialogState({type: "send_request_failure_409"})  
                else dispatchDialogState({type: "send_request_failure"})    
              });
        }, 1000);
    }

    let alertsArray = JSON.parse(sessionStorage.getItem('alertsArray')) 

    const handlePageRedirect = () => { 
        let index = alertsArray.findIndex(alert => alert === alert_key)
        alert_key = alertsArray[index+1]
        history.push("/banking-alert/" + alert_key)
    };

    if (dialogState.finished && dialogState.isLoading){
        return (
            <Dialog 
                open={open} 
                onClose={() => history.push("/default-alerts-inbox")} 
                keepMounted={false}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle 
                    style={{paddingBottom: "0px"}} 
                    className={["blueText", "normalMediumSize"].join(" ")}
                >
                    {title}
                </DialogTitle>
                <DialogContent>
                    <div style={{display:"flex", width: "310px", height:"217px"}}>
                        <CircularProgress style={{margin:"auto"}} />
                    </div>
                </DialogContent>
            </Dialog>

        )
    }
    else if (dialogState.finished){
        let confirmationData = {    
            message: dialogState.message,
            success: !dialogState.isError
        }
        return (
            <Dialog 
                open={open} 
                onClose={() => history.push("/default-alerts-inbox")} 
                keepMounted={false}
                disableBackdropClick
            >
                <DialogTitle style={{paddingBottom: "0px"}} className={["blueText", "normalMediumSize"].join(" ")}>Confirmação de Revisão</DialogTitle>
                <DialogContent>
                    <ConfirmationContent company_key={company_key} history={history} onClose={handlePageRedirect} data={confirmationData} alertKey_params={alert_key}/>
                </DialogContent>
            </Dialog>
        )
    }
    return (
        <Dialog 
        open={open} 
        onClose={onClose} 
        keepMounted={false}
        >
            <DialogTitle 
                style={{paddingBottom: "0px"}} 
                className={["blueText", "normalMediumSize"].join(" ")}
            >
                {title}
            </DialogTitle>
            <DialogContent>
                <div>
                    <div>
                        <TextField
                            fullWidth
                            multiline
                            variant="outlined"
                            margin="dense"
                            rows="5"
                            defaultValue={observation}
                            disabled={true}
                        />
                    </div>
                    <div className="internalCardContainer" style={{display:"flex", justifyContent:"center"}}>
                        <div 
                            className={["button", "approved", "normalText", "normalMediumSize"].join(" ")}
                            onClick={handleApprove}
                        >
                            Finalizar 
                        </div>
                        <div 
                            className={["button", "reproved", "normalText", "normalMediumSize"].join(" ")}
                            onClick={onClose}
                        >
                            Cancelar 
                        </div>
                  </div>
              </div>
          </DialogContent>
        </Dialog>
    )
}

export default TreatedDialog;