import React from 'react'
import Tooltip from "@material-ui/core/Tooltip";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import PublishIcon from '@material-ui/icons/Publish';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import DoneIcon from '@material-ui/icons/Done';
import operation_code_info_json from "../../../assets/json/currencyExchangeOperationCode.json"


export const colorGreen = '#73C580'
export const colorRed ='#D91A1A'
export const colorYellow = "#FFBB24"
export const colorOrange = '#F77B21'
export const colorVelvet ='#DD5C5C'
export const colorGrey = 'rgb(170, 167, 153)'

export const getOperationStatusText = (status) => {
    switch(status){
        case "created":
            return "Operação Aguardando Confirmação"
        case "executed":
            return "Operação Confirmada"
        case "cancelled":
            return "Operação Cancelada"
        default:
            return status
    }
}

export const getOperationStatusColor = (status) => {
    switch(status){
        case "created":
            return colorYellow
        case "executed":
            return colorGreen
        case "cancelled":
            return colorRed
        default:
            return colorGrey
    }
}

export const getOperationStatusIcon = (status, icon_size_class, showTooltip=false) => {
    let circle_size
    let icon_size
    let icon_component
    let icon_backgroud_color = getOperationStatusColor(status)
    if (icon_size_class === "big"){
        circle_size = ""
        icon_size = "45px"
    }
    else if (icon_size_class === "small"){
        circle_size = "small"
        icon_size = "18px"
    }
    else throw new Error("Please a new size is needed for setup")
    switch(status){
        case "executed":
            icon_component = <DoneIcon style={{width:icon_size, height:icon_size}}/>
            break       
        case "created":
            icon_component = <PublishIcon  style={{width:icon_size, height:icon_size}}/>
            break   
        case "cancelled":
            icon_component = <ErrorOutlineIcon style={{width:icon_size, height:icon_size}}/>
            break 
        default:
            icon_component = <HelpOutlineIcon  style={{width:icon_size, height:icon_size}}/>
            break   
    }
    if (showTooltip){
        return (
            <Tooltip title={<p className="tooltipText">{getOperationStatusText(status)}</p>}>
                <div className={["circle", circle_size].join(" ")} style={{backgroundColor: icon_backgroud_color}}>
                    {icon_component}
                </div>
            </Tooltip>
        )
    }
    else{
        return (
            <div className={["circle", circle_size].join(" ")} style={{backgroundColor: icon_backgroud_color}}>
                {icon_component}
            </div>
        )
    }
}

export const getOperationCodeInfo = (operation_code) => {
        for(let key in operation_code_info_json){
            if(key === operation_code){
                return operation_code_info_json[key]
            }
        }
    };
