import React from "react";
import Tooltip from "@material-ui/core/Tooltip"
import CheckIcon from "@material-ui/icons/Check";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

function DataField(props) {

    if (props.label == null) return null

    let displayType = "inline"
    let marginSize= "5px"
    let marginRight = "5px"
    let titleClasses = ["normalText", props.size]
    let labelClasses = ["labelText", props.size, "labelSpace"]
    let labelStyles = {verticalAlign: "middle"}
    let titleStyles = {verticalAlign: "middle"}
    let imageTitleStyles = {verticalAlign: "-0.4em", marginRight: "10px"}

    if (props.titleNoWrap){
        titleClasses.push("noWrap")
    }
    if (props.labelNoWrap){
        labelClasses.push("noWrap")
    }

    if (props.disabled){
        titleClasses.push("disabled")
        labelClasses.push("disabled")
    }

    if (props.margin){
        marginSize=props.margin
    }

    if (props.marginRight){
        marginRight=props.marginRight
    }

    if (props.display){
        displayType=props.display
    }

    if (props.labelStyles){
        labelStyles={...labelStyles, ...props.labelStyles}
    }

    if (props.titleStyles){
        titleStyles={...titleStyles, ...props.titleStyles}
    }

    if (props.labelClasses){
        labelClasses = props.labelClassesOverride ? [props.labelClasses, props.size, "labelSpace"] : [...labelClasses, ...props.labelClasses]
    }

    if (props.titleClasses){
        titleClasses=[...titleClasses, ...props.titleClasses]
    }

    const colorGreen = '#73C580'
    const colorRed ='#D91A1A'
    const colorYellow = "#FFBB24"
    const colorGrey = 'rgb(170, 167, 153)'

    const getAlertsFlagIcon = (flag, margin=null, size="20px") => {
        let margin_ = "auto"
        if (margin !=null ){
            margin_ = margin
        }
        switch(flag){
            case "positive":
                return (<CheckIcon style={{verticalAlign: "middle", width:size, height:size, color:colorGreen, marginTop:"auto",marginBottom:"auto", marginRight:margin_,marginLeft:margin_}}/>)
            case "negative":
                return (<ErrorOutlineIcon style={{verticalAlign:"middle", width:size, height:size, color:colorRed, marginTop:"auto",marginBottom:"auto", marginRight:margin_,marginLeft:margin_}}/>)
            case "neutral":
                return(<ErrorOutlineIcon style={{verticalAlign:"middle", width:size, height:size, color:colorYellow, marginTop:"auto",marginBottom:"auto", marginRight:margin_,marginLeft:margin_}}/>)
            default:
                return(<ErrorOutlineIcon style={{verticalAlign:"middle", width:size, height:size, color:colorGrey, marginTop:"auto",marginBottom:"auto", marginRight:margin_,marginLeft:margin_}}/>)
        }
    }

    if (props.titleImage) {
        return (
            <div style={{display: displayType, marginTop: marginSize, marginBottom: marginSize, marginRight: marginRight, lineHeight: "20px"}}>
                <span className={titleClasses.join(" ")} style={imageTitleStyles}>
                <Tooltip title={props.titleTooltipText == null ? "" : <p className="tooltipText">{props.titleTooltipText}</p>}>
                    <img src={props.titleImage} alt={props.alt}/>
                </Tooltip>
                </span>
                <span className={labelClasses.join(" ")} style={labelStyles}>
                    {props.label}
                </span>
                {props.check == null ? null : 
                <Tooltip title={props.tooltipText == null ? "" : <p className="tooltipText">{props.tooltipText}</p>}>
                    {getAlertsFlagIcon(props.check, "5px")}
                </Tooltip>}
                {props.children}        
            </div>
        );
    }
    else {
        return (
            <div style={{display: displayType, marginTop: marginSize, marginBottom: marginSize, marginRight: marginRight, lineHeight: "20px"}}>
                <span className={titleClasses.join(" ")} style={titleStyles}>
                    {props.title}:
                </span>
                <span className={labelClasses.join(" ")} style={labelStyles}>
                    {props.label}
                </span>
                {props.check == null ? null : 
                <Tooltip title={props.tooltipText == null ? "" : <p className="tooltipText">{props.tooltipText}</p>}>
                    {getAlertsFlagIcon(props.check, "5px")}
                </Tooltip>}
                {props.children}        
            </div>
        );
    }
}

export default DataField;