import React, { useEffect } from "react";
import { CircularProgress } from '@material-ui/core';
import axios from "axios";
import AlertsTable from "./AlertsTable/AlertsTable"
import Pagination from "@material-ui/lab/Pagination";

function AlertsHistoric(props) {

    const document_number = props.alert.link_key_type === "customer" ? props.alert.customer_document_number : props.alert.seller_document_number
    const link_key_type     = props.alert.link_key_type

    const {alert_key} = props.alert
    const {occurrence_date} = ((props.alert.triggers || [])[(props.alert.triggers||[]).length -1] || {})
  
    const [userHistoric, setUserHistoric] = React.useState(null)
    const [tablePage, setTablePage] = React.useState(1)
  
    useEffect(() => {
        if (userHistoric === null){
            if (document_number == null) setUserHistoric(undefined)
            else{
                let params = link_key_type === "customer" ?
                {
                    "page_number": tablePage -1,
                    "page_rows": 10,
                    "customer_document_number": document_number,
                    "final_date": occurrence_date
                } : {
                    "page_number": tablePage -1,
                    "page_rows": 10,
                    "seller_document_number": document_number,
                    "final_date": occurrence_date
                } 
                const timer = setTimeout(() => {
                    axios.get('/dash/card_order/alerts', {
                        params: params
                    }).then(response=>{
                        let filteredAlerts = response.data.data.filter(alert=>alert.alert_key !== alert_key)
                        let filteredResponseObject = {...response.data, data: filteredAlerts}
                        setUserHistoric(filteredResponseObject)
                    }).catch(error=>{
                        setUserHistoric(undefined)
                    })
        
                }, 500);
                return () => {
                    clearTimeout(timer)
                }
            }
        }
    }, [userHistoric,tablePage, alert_key,document_number,occurrence_date, link_key_type])

    const handleChangePage = (event, page) => {
		if (tablePage === page) {
			return
		}
        setTablePage(page)
        setUserHistoric(null)
	}
  
    if (userHistoric === null) {
      return (
        <div style={{display: "flex", height: "160px"}}>
            <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                <CircularProgress />
            </div>
        </div>
      );    
    }
    else if (userHistoric === undefined || userHistoric.data.length === 0){
      return (
        <div style={{display: "flex", height: "160px"}}>
            <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                Informação Indisponível
            </div>
        </div>
      )
    }
    else {
      return (
          <div>
              <div>
                  <AlertsTable historic={userHistoric.data} />
              </div>
              <div style={{display:"flex", marginTop: "10px"}}>
                <Pagination style={{marginLeft:"auto"}} className='pagination' page={tablePage} count={userHistoric['number_of_pages']} onChange={(e,page) => handleChangePage(e, page)} />
              </div>
          </div>
      );
    }
  }
  
  export default AlertsHistoric;