import React from "react";
import Button from "../Button";
import { useTranslation } from "react-i18next";

const FormButton = (props) => {

  const { formIsValid, onSubmit, business_group_key, handleBackClick } = props
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      {!formIsValid? (
        <Button
          button_option="standard_disabled_gray"
          buttonLabel={t("submit_action")}
        />
      ) : (
        <Button
          button_option="standard"
          onClick={onSubmit}
          buttonLabel={t("submit_action")}
        />
      )}
      {business_group_key == null ? null : (
        <Button
          button_option="cancel"
          onClick={handleBackClick}
          buttonLabel={t("cancel_action")}
        />
      )}
    </div>
  );
};

export default FormButton;
