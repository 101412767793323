import React, {useState, useEffect, useContext} from 'react'
import { useTranslation } from "react-i18next";
import {GetAlertsComponent, validateConstantValues, getConstantTextFields} from "./utils"
import NumberFormat from 'react-number-format';
import TextField from "@material-ui/core/TextField";
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import RulesContext from "../../../../context/rules-context"
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

let this_box_type = "number"

export function ActionInterestRateBox (props) {
    const {t} = useTranslation()
    let {node, read_only, handleConfigurationOpen, handleNodeRemove} = props

    return (
        <div>
                <div style={{display:"flex", alignItems: "center"}}>
                    <div 
                        style={{display:"flex", width:"100%", cursor:"pointer", margin: "0px"}} 
                        onClick={() => handleConfigurationOpen(node)}
                    >
                        <div style={{display: "flex",flexDirection: "column",margin: "auto 0px"}}>
                            <div className={["normalText","normalMediumSize"].join(" ")}>
                            {node.node.properties.interest_rate_type ? t(node.node.properties.interest_rate_type) : ""}
                            </div>
                            <div className={["normalText","normalSmallSize"].join(" ")}>
                            {`${node.node.properties.interest_rate_value} %`} 
                            </div>
                        </div>
                        {!read_only && (node.node.alerts || []).length > 0 ?
                        <GetAlertsComponent alerts={node.node.alerts} />  : null}  
                    </div>
                    {!read_only && <><IconButton style={{width: 32, height: 32}} aria-label="duplicate" onClick={() => props.handleNodeDuplication(node)}>
                        <ContentCopyIcon style={{width: 20, height: 20}} />
                    </IconButton>
                    <IconButton style={{width: 32, height: 32}} aria-label="close" onClick={() => handleNodeRemove(node)}>
                        <CloseIcon style={{width: 20, height: 20}} />
                    </IconButton></>}
                </div>
        </div>
    )
}

export function ActionInterestRateConfiguration(props){
    const { t } = useTranslation();
    let {node, read_only, handleNodeChange, handleConfigurationClose} = props

    let interestRateTypeOptions = useContext(RulesContext).boxes[node.node.type].settings.interest_rate_type_options
    let standardTextField = getConstantTextFields(this_box_type)

    const [nodeState, setNodeState] = useState(node)

    useEffect(()=>{
        setNodeState(node)
    },[node])

    const handleNodeStateChange = (obj_key, value) => {
        let new_node = {...nodeState}
        new_node.node.properties[obj_key] = value
        setNodeState(new_node)
    }

    const handleSave = () => {
        handleConfigurationClose()
        handleNodeChange(nodeState)
    }

    const validateAllValues = () => {
        return (
            (!!nodeState.node.properties.interest_rate_type && nodeState.node.properties.interest_rate_type.length >0) &&
            (!!nodeState.node.properties.interest_rate_value && Number(nodeState.node.properties.interest_rate_value) >0)
        )
    }

    return(
        <div style={{flexGrow:"1", padding: "20px", display:"flex", flexDirection:"column"}}>
            <div style={{display: "flex"}}>
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>{t(node.node.description)}</span>
                <Tooltip title={<div className="tooltipText"><ul>{node.node.helperText.map((help,index) => (<li key={index}>{t(help)}</li>))}</ul></div>}>
                    <HelpIcon style={{fontSize: "14px", display:"flex", margin:"auto 5px"}}/>
                </Tooltip>
            </div>
            <div className="internalCardContainer" style={{display:"flex", flexDirection: "column", maxWidth: "300px"}}>
            <TextField 
                label={t("interest_type")}
                style={{textAlign:"center", width:"80%", margin:"3px auto"}}
                className="filterTextbox" 
                size="small" fullWidth 
                value={nodeState.node.properties.interest_rate_type}
                onChange={(e) => handleNodeStateChange("interest_rate_type", e.target.value)}
                InputProps={{
                    readOnly: read_only
                  }}
                select
            >
                {interestRateTypeOptions.map((option, index) => (
                    <MenuItem key={index} value={option}><em className="normalText">{t(option)}</em></MenuItem>
                ))}
            </TextField>
            <NumberFormat
                    style={{textAlign:"center", width:"80%", margin:"3px auto"}}
                    error={!validateConstantValues(this_box_type,nodeState.node.properties.interest_rate_value)}
                    {...standardTextField.props}
                    value={nodeState.node.properties.interest_rate_value}
                    onValueChange={(e) => handleNodeStateChange("interest_rate_value", e.floatValue)}
                    InputProps={{
                        readOnly: read_only
                      }}
                />            
            </div>
                <div style={{display:"flex", margin: "auto auto 5px auto"}}>
                    <div
                        className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")}
                        style={{width:"120px"}}
                        onClick={handleConfigurationClose}
                    >
                        {read_only ? "X" : t("discard")}
                    </div>
                    {!read_only && 
                    <div
                        className={
                            validateAllValues()
                            ?
                            ["button", "standard", "normalText", "normalMediumSize"].join(" ")
                            :
                            ["button", "standard", "normalText", "normalMediumSize","disabled"].join(" ")}
                        style={{width:"120px"}}
                        onClick={validateAllValues() ? handleSave : null}
                    >
                        {t("save")}
                    </div>}   
                </div>
        </div>
    )
}

export class ActionInterestRateBoxClass {
    constructor(rulesContext){
        this.rulesContext = rulesContext
    }
    
    validate(node){
        let put_error = false
        let alerts = []

        let interestRateTypeOptions = this.rulesContext.boxes[node.node.type].settings.interest_rate_type_options

        if((node.node.children || []).length !== 0){
            put_error = true
            alerts.push("box_validation_no_children_allowed")
        }
        if (!interestRateTypeOptions.includes(node.node.properties.interest_rate_type)){
            put_error = true
            alerts.push("box_validation_interest_type_rate_instruction")
        }
        //Validate value is not length 0
        if(!validateConstantValues(this_box_type, node.node.properties.interest_rate_value)){
            put_error = true
            alerts.push("box_validation_no_null_value_allowed")
        }
        //Validate if parent is valid
        if (node.parentNode != null) {
            if(node.parentNode.on_error){
                put_error = true
                alerts.push("box_validation_invalid_parent_node")
            }
        }
        return {validated: !put_error, alerts: alerts}
    }

    getType(node){
        return 'void'
    }
}