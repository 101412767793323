import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from 'moment'
import {getTableRowClass, formatCard, checkNested} from "../../../utils/utils"
import {getTransactionStatusIcon, getTransactionBlockStatusIcon, getTransactionStatusText, getTransactionStatusColor, getAnalysisStatus, getAnalysisStatusSiglum, getAnalysisStatusColor} from "../../../utils/utilsOrder"
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import getSymbolFromCurrency from 'currency-symbol-map'

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
  },
  blueText: {
    fontFamily: "Open Sans",
    fontWeight: "600",
    color: "#0b1f82"
},
  tableText: {
    fontFamily: "Open Sans",
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "center",
    margin: "10px",
    padding: "6px",
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: '#6F6F6F',
    fontSize: "14px",
    lineHeight: "22px",
    padding: "6px",
  }
}));

function SellerHistoricTable(props) {
  const classes = useStyles();
  
  useTheme();

  return (
    <Paper className={classes.tableStyle}>
      <Table style={{ padding: "8px" }}>
        <TableHead>
          <TableRow>
          <TableCell className={classes.tableHeader}>Pedido</TableCell>
            <TableCell className={classes.tableHeader}>Documento do Comprador</TableCell>
            <TableCell className={classes.tableHeader}>Cartão</TableCell>
            <TableCell className={classes.tableHeader}>Valor</TableCell>
            <TableCell className={classes.tableHeader}>Status da Análise</TableCell>
            <TableCell className={classes.tableHeader}>Data da Venda</TableCell>
            <TableCell className={classes.tableHeader}>Status da Transação</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((order,index) => (
            <TableRow
              key={index}
              className={getTableRowClass(index)}
            >
              <TableCell
                className={[classes.tableText, classes.blueText, "breakWord", "link"].join(" ")}
              >
                <Link
                    className="link"
                    to={"/order/" + order.order_key}
                >
                    {order.id}
                </Link>
              </TableCell> 
              <TableCell className={[classes.tableText, "breakWord"].join(" ")}>
                  {order.customer.document_number == null ? "-" : order.customer.document_number}
              </TableCell>
              <TableCell className={[classes.tableText, "breakWord"].join(" ")}> 
                {checkNested(order.payment.transactions[0], "bin") & checkNested(order.payment.transactions[0], "last_4") ? 
                formatCard(order.payment.transactions[0].bin, order.payment.transactions[0].last_4) : "-"}         
              </TableCell>
              <TableCell className={classes.tableText}>
                {order.payment.total_amount == null ? "-" : getSymbolFromCurrency(order.payment.currency) + " " + (order.payment.total_amount/100).toLocaleString('de-DE', {minimumFractionDigits: 2})}
              </TableCell>
              <TableCell className={classes.tableText}>
                  <Tooltip title={<p className="tooltipText">{getAnalysisStatus(order.analysis_status)}</p>}>
                      <div className={["circle", "small"].join(" ")} style={{backgroundColor:getAnalysisStatusColor(order.analysis_status)}}>
                          {getAnalysisStatusSiglum(order.analysis_status)}
                      </div>
                  </Tooltip>
              </TableCell>
              <TableCell className={classes.tableText}>
                {moment(order.order_date).format("DD/MM/YYYY - HH:mm:ss")}
              </TableCell>
              <TableCell className={classes.tableText}>
                <Tooltip title={<p className="tooltipText">{getTransactionStatusText(order.payment.transactions[0].status)}</p>}>
                  {order.payment.transactions.length > 1 ?
                  <div className={["circle", "small"].join(" ")}>
                    {getTransactionBlockStatusIcon(order.payment.transactions)}                 
                  </div>: 
                  <div className={["circle", "small"].join(" ")} style={{backgroundColor: getTransactionStatusColor(order.payment.transactions[0].status)}}>
                    {getTransactionStatusIcon(order.payment.transactions[0].status, "small", true)}             
                  </div>}
                </Tooltip>
            </TableCell>    
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default SellerHistoricTable;
