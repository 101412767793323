import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import AuthContext from "../../../context/auth-context";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import axios from 'axios';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  dialog: {
    minWidth: "500px"
  },
  dialogTitle: {
    fontFamily: "OpenSans",
    fontWeight: 600,
    fontSize: "15px",
    color: "#0b1f82"
  },
  dialogTextFields: {
    fontFamily: "Open Sans",
    textAlign: "center",
    minWidth: "300px"
  },
  cancelButton: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: "12px",
    color: "#898989",
    borderRadius: "30px",
    textTransform: "capitalize",
    margin: "10px"
  },
  confirmButton: {
    border: "1.5px solid #0b1f82",
    boxSizing: "border-box",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.12)",
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "center",
    color: "#0b1f82",
    borderRadius: "30px",
    textTransform: "capitalize",
    margin: "10px"
  },
  textField: {
    fontFamily: "Open Sans",
    fontSize: "3px",
    width: "60%"
  }
}));

const ChangePassword = props => {

  const { t } = useTranslation();
  const classes = useStyles();
  useTheme();
  let [values, setValues] = useState({
    new_password: "",
    confirm_password: ""
  });

  let [errors, setErrors] = useState({
    new_password: {
      error: false,
      error_message: " ",
      verified: false
    },
    confirm_password: {
      error: false,
      error_message: " ",
      verified: false
    },
    form: {
      valid: false
    }
  });

  const onChangeHandler = event => {
    let newErrors = { ...errors };
    let newValues = { ...values };
    newValues[event.target.name] = event.target.value;

    let passwordRegexOneUpperCase = RegExp("(?=.*[A-Z])");
    let passwordRegexOneLowerCase = RegExp("(?=.*[a-z])");
    let passwordRegexOneNumber = RegExp("(?=.*[0-9])");
    let passwordRegexOneSpecialCaracter = RegExp("(?=.*(?=.*[#?!@$ %^&*-].*[#?!@$ %^&*-]).+)");
    switch (event.target.name) {
      case "new_password":
        if (event.target.value.length < 8) {
          newErrors["new_password"] = {
            error: true,
            verified: true,
            error_message: t("Senha inválida - Mínimo 8 caracteres")
          };
        } else if (!passwordRegexOneUpperCase.test(event.target.value)) {
          newErrors["new_password"] = {
            error: true,
            verified: true,
            error_message: t("Senha inválida - Deve conter pelo menos 1 letra maiúscula")
          };
        } else if (!passwordRegexOneLowerCase.test(event.target.value)) {
          newErrors["new_password"] = {
            error: true,
            verified: true,
            error_message: "Senha inválida - Deve conter pelo menos 1 letra minúscula"
          };
        } else if (!passwordRegexOneNumber.test(event.target.value)) {
          newErrors["new_password"] = {
            error: true,
            verified: true,
            error_message: "Senha inválida - Deve conter pelo menos 1 número"
          };
        } else if (!passwordRegexOneSpecialCaracter.test(event.target.value)) {
          newErrors["new_password"] = {
            error: true,
            verified: true,
            error_message: "Senha inválida - Deve conter pelo menos 2 caracteres especiais"
          };
        }else {
          newErrors["new_password"] = {
            error: false,
            verified: true,
            error_message: " "
          };
        }

        if (event.target.value !== newValues["confirm_password"]) {
          newErrors["confirm_password"] = {
            error: true,
            verified: true,
            error_message: "Confirmação de senha inválida"
          };
        } else {
          newErrors["confirm_password"] = {
            error: false,
            verified: true,
            error_message: " "
          };
        }
        break;
      case "confirm_password":
        if (event.target.value !== values["new_password"]) {
          newErrors["confirm_password"] = {
            error: true,
            verified: true,
            error_message: "Confirmação de senha inválida"
          };
        } else {
          newErrors["confirm_password"] = {
            error: false,
            verified: true,
            error_message: " "
          };
        }
        break;
      default:
    }

    newErrors["form"]["valid"] = getFormValid(newErrors);
    setErrors(newErrors);
    setValues(newValues);
  };

  const getFormValid = errors => {
    return !Object.keys(errors).reduce((accumulator, key) => {
      if (key === "form") return accumulator;
      return accumulator || errors[key]["error"] || !errors[key]["verified"];
    }, false);
  };

  const onChangePassword = (username) => {
    axios.put("/dash/user/" + username + "/password", {"new_password": values["new_password"]}).then(response => {
      props.onClose(true);
    }).catch(response => {
      props.onClose(false);
    })
  };

  return (
    <AuthContext.Consumer>
      {context => (
        <React.Fragment>
          <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="change-password-dialog"
            className={classes.dialog}
          >
            <DialogTitle
              className={classes.dialogTitle}
              id="change-password-dialog"
            >
              {t("Nova Senha")}
            </DialogTitle>
            <DialogContent>
            <p style={{color: "#6F6F6F"}}>{t("A nova senha deve seguir os seguintes critérios de segurança:")}</p> 
              <ul style={{color: "#6F6F6F"}}>
                <li>{t("ter no mínimo ")}<strong>{t("8 caracteres")}</strong>;</li>
                <li>{t("ter no mínimo ")}<strong>{t("1 letra minúscula")}</strong>;</li>
                <li>{t("ter no mínimo ")}<strong>{t("1 letra maiúscula")}</strong>;</li>
                <li>{t("ter no mínimo ")}<strong>{t("1 número")}</strong>;</li>
                <li>{t("ter no mínimo ")}<strong>{t("2 caracteres especiais")}</strong>;</li>
                <li>{t("não ter sido utilizada nas últimas ")}<strong>{t("5 vezes")}</strong>;</li>
              </ul>
              <form className={classes.dialogTextFields}>
                <input type="text" autoComplete="username" name="username" hidden readOnly value={context.user_data.email} />
                <TextField
                  name="new_password"
                  label={t("Nova Senha")}
                  type="password"
                  className={classes.textField}
                  onChange={onChangeHandler}
                  error={errors["new_password"]["error"]}
                  helperText={errors["new_password"]["error_message"]}
                  fullWidth
                  autoComplete="new-password"
                  style={{minWidth: '350px'}}
                />
                <TextField
                  name="confirm_password"
                  label={t("Confirme a Nova Senha")}
                  type="password"
                  className={classes.textField}
                  onChange={onChangeHandler}
                  error={errors["confirm_password"]["error"]}
                  helperText={errors["confirm_password"]["error_message"]}
                  fullWidth
                  autoComplete="new-password"
                  style={{minWidth: '350px'}}
                />
              </form>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => props.onClose(null)}
                className={classes.cancelButton}
                color="primary"
              >
                {t("Cancelar")}
              </Button>
              <Button
                onClick={() => onChangePassword(context.user_data.email)}
                className={classes.confirmButton}
                color="primary"
                disabled={!errors["form"]["valid"]}
              >
                {t("Confirmar")}
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      )}
    </AuthContext.Consumer>
  );
};

export default ChangePassword;
