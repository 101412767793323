import React from "react";
import { formatScore, formatDecisionSmaller } from "../../utils/utilsTransaction";

function ScoreBox(props) {

    let score           = (((props.analysis_events || [])[0] || {}).decision_metadata || {}).score
    let reason          = (((props.analysis_events || [])[0] || {}).decision_metadata || {}).reason
    let description     = (((props.analysis_events || [])[0] || {}).decision_metadata || {}).reason_description
    let decisionObject  = formatDecisionSmaller(props.analysis_status)
    let scoreObject     = formatScore(score)

    if (description != null && description !== undefined) {
        reason = description
    }

    if (score==null || score===undefined){
        return (
            <div className="analysisCardNoFlex" style={{width:"100%"}}>
                <div style={{margin:"auto"}}>
                <div style={{display:"flex", alignItems:"center", justifyContent:'center'}}>
                    <div 
                        className={["circle", "medium", "bold", "normalText", "subtitleSize"].join(" ")} 
                        style={{color:"white", margin: "0px 8px 0px 0px", backgroundColor: decisionObject.color}}
                    >
                        {decisionObject.icon}
                    </div>
                    <div style={{display:"flex", flexDirection:"column"}} >
                        <div className="decisionText">
                            Decisão
                        </div>
                        <div className={["normalText", "subtitleSize", "bold"].join(" ")} 
                            style={{color: decisionObject.color}}>
                            {decisionObject.text}
                        </div>
                    </div>
                </div>
                <div style={{display:"flex", justifyContent:'center'}}>
                    <div className={["blueText", "subtitleSize"].join(" ")} style={{margin:"0px 5px auto 0px"}}>
                        Razão: 
                    </div>
                    <div className={["labelText", "subtitleSize"].join(" ")}
                        style={{margin:"0px 0px auto 0px"}}>
                        {(reason === null || reason===undefined)?"Razão Indisponível":reason}
                    </div>
                </div>
                </div>
            </div>
        );
    }
    else {
        return (
            <div className="analysisCardNoFlex">
                <div style={{display:"flex", alignItems:"center", marginLeft:"50px", justifyContent:'center'}}>
                    <div 
                        className={["circle", "bold", "normalText", "subtitleSize"].join(" ")} 
                        style={{color:"white", marginRight:"8px", marginTop:"auto", marginLeft:0,
                                backgroundColor: scoreObject.color,
                                flex:'0 0 60px'}}
                    >
                        {score}
                    </div>
                    <div
                        style={{display:"flex", alignItems:"center", 
                        flexWrap:"wrap", minHeight:"50px", paddingRight:"5px",
                        margin:"auto 0px"}}
                    >
                        <div className="decisionText">
                            Score
                        </div>
                        <div className={["normalText", "subtitleSize", "bold"].join(" ")} 
                            style={{margin:"auto 0px", color: scoreObject.color, width:"100%"}}>
                            {scoreObject.text}
                        </div>
                    </div>
                </div>
                <div style={{display:"flex", marginTop:"15px", justifyContent:'center'}}>
                    <div className={["blueText", "subtitleSize"].join(" ")} style={{margin:"0px 5px auto 0px"}}>
                        Razão: 
                    </div>
                    <div className={["labelText", "subtitleSize"].join(" ")}
                        style={{margin:"0px 0px auto 0px"}}>
                        {(reason === null || reason===undefined)?"Razão Indisponível":reason}
                    </div>
                </div>
            </div>
        );
    }
}

export default ScoreBox;