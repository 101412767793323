import React, {useContext, useState} from "react";
import { useTranslation } from "react-i18next";
import AuthContext from "../../context/auth-context";
import {useHistory} from "react-router-dom"
import ReportsTemplatesInbox from "./ReportsTemplatesInbox/ReportsTemplatesInbox";
import ReportsList from "./ReportsList/ReportsList"

function ReportsInbox() {
    const {t} = useTranslation()
    let history = useHistory()
    let user_data = useContext(AuthContext).user_data
    
    sessionStorage.removeItem("reportFilterObject") 
    sessionStorage.removeItem("reportPageParams")

    const [isReloading, setIsReloading] = useState(false)
    const handleReloadPage = () => {
        setIsReloading(!isReloading)
    }

    let roles = useContext(AuthContext).user_data.roles;
    if (!(['read_reports', 'read_reports_onboarding_natural_person', 'read_reports_onboarding_legal_person'].some(role => roles.includes(role)))) {
        history.push("");
    }

    return (
        <div style={{ width: "auto", paddingBottom: "30px" }}>
            <div style={{display:"flex"}}>
                <p className="blueText titleSize">{t("reports_title")}</p>
            </div>
            <div style={{display: 'grid', gap: '50px'}}> 
                {user_data.roles.includes("create_reports") &&
                    <ReportsTemplatesInbox isReloading={isReloading} handleReloadPage={handleReloadPage}/>
                }
                <ReportsList isReloading={isReloading} handleReloadPage={handleReloadPage}/>
            </div>
        </div>
    );
}

export default ReportsInbox;