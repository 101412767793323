import React, { useEffect, useContext, useReducer, useState } from "react";
import { dataFetchReducer } from "../../Utils/requestUtils";
import { useParams } from "react-router-dom";
import axios from "axios";
import TransactionCard from "./TransactionCard/TransactionCard"
import UserCard from "./UserCard/UserCard"
import MerchantCard from "./MerchantCard/MerchantCard"
import EventsTimeline from "./EventsTimeline/EventsTimeline"
import Summary from "./Summary/Summary"
import PaymentCard from "./PaymentCard/PaymentCard"
import {titleCase} from "../utils/utils"
import moment from "moment"
import ErrorBoundary from "../../Utils/ErrorBoundary";
import AuthContext from "../../../context/auth-context"
import {useHistory} from "react-router-dom"
import FraudDialog from "../Fraud/FraudDialog"

function Transaction(props){
    let history = useHistory()

    let roles = useContext(AuthContext).user_data.roles

    if (!roles.includes("card_issuance_transaction_viewer")){
        history.push("")
    }

    let { transaction_key } = useParams();

    const [transaction, dispatchTransaction] = useReducer(
        dataFetchReducer,
        {fetchedData: null, isLoading: true, isError: false} 
    )

    const can_write = roles.includes("card_issuance_transaction_analyzer")
    
    const [openDialog, setOpenDialog] = useState(false)
    
    function handleFraudStatus(){
        setOpenDialog(true)
    }

    const handleDialogClose = () => {
        setOpenDialog(false)
    };

    useEffect(() => {
		dispatchTransaction({type: "data_fetch_init"})
		const timer = setTimeout(() => {
			axios.get('/dash/card_issuance_backoffice/transaction/' + transaction_key).then( response => {
                dispatchTransaction({
                    type: "data_fetch_success",
                    payload: response.data
                })   
			}).catch(error => {
                if ((error.response || {}).status === 403) dispatchTransaction({type: "data_fetch_failure_403"})
                else if ((error.response || {}).status === 404) dispatchTransaction({type: "data_fetch_failure_404"})  
                else dispatchTransaction({type: "data_fetch_failure"})    
			})	
		}, 500);
		return () => {
			clearTimeout(timer)
        }
    }, [transaction_key])

    if (transaction.fetchedData){

        const showFraudButton =  ["authorized","not_authorized"].includes(transaction.fetchedData.transaction_status)

        return (
            <div style={{minWidth:"900px", paddingBottom: "30px"}}>
                <div className="analysisCardContainer" style={{flexDirection:"column"}}>
                    <ErrorBoundary>
                        <div style={{display:"flex", flexDirection:"column", width: "100%"}}>
                            <div style={{display:"flex", justifyContent: "space-between"}}>
                                <div className={["blueText", "titleSize"].join(" ")} style={{margin:"5px 0px"}}>
                                    {titleCase(transaction.fetchedData.company_name, null) + " - Transação " + transaction.fetchedData.id}
                                </div>
                                {(showFraudButton && can_write) &&
                                    <div
                                        className={["button", "reproved", "normalText", "normalMediumSize"].join(" ")}
                                        style={{display: "flex", alignItems: "center", width: "150px", fontWeight:"600"}}
                                        onClick={handleFraudStatus}
                                    >
                                        Reportar como fraude
                                    </div>}
                                </div>
                                <div className={["labelText", "subtitleSize"].join(" ")} style={{margin:"5px 0px"}}>
                                    {moment(transaction.fetchedData.authorization_date).format("DD/MM/YYYY - HH:mm:ss")}
                            </div>
                        </div>
                    </ErrorBoundary>
                </div>
                <div style={{display: "flex", margin: "10px 0px"}}>
                    <div style={{width: "33%"}}>
                        <div className="analysisCardContainer">
                            <ErrorBoundary>
                                <TransactionCard transaction={transaction.fetchedData}/>
                            </ErrorBoundary>      
                        </div>
                        <div className="analysisCardContainer">
                            <ErrorBoundary>
                                <MerchantCard transaction={transaction.fetchedData}/>
                            </ErrorBoundary>   
                        </div>
                    </div>
                    <div style={{width: "33%"}}>
                        <div className="analysisCardContainer" style={{ minHeight: "250px"}}>
                            <ErrorBoundary>
                                <Summary transaction={transaction.fetchedData}/>
                            </ErrorBoundary>   
                        </div>
                        <div className="analysisCardContainer" style={{ minHeight: "250px"}}>
                            <ErrorBoundary>
                                <PaymentCard transaction={transaction.fetchedData}/>
                            </ErrorBoundary>                            
                        </div>
                        <div className="analysisCardContainer" style={{ minHeight: "250px"}}>
                            <ErrorBoundary>
                                <UserCard transaction={transaction.fetchedData}/>
                            </ErrorBoundary> 
                        </div>
                    </div>
                    <div style={{width: "33.3%"}}>
                        <div className="analysisCardContainer">
                            <ErrorBoundary>
                                <EventsTimeline transaction={transaction.fetchedData}/>
                            </ErrorBoundary>
                        </div>
                    </div>
                </div>
                <FraudDialog
                    open={openDialog}
                    onClose={handleDialogClose}
                    transaction={transaction.fetchedData}
                />
            </div>
        )
    }
	else if(transaction.isError){
		return (
            <div style={{height: "80vh", display:"flex"}}>
                <p className={["blueText", "titleSize"].join(" ")} style={{margin:"auto", textAlign:"center"}}>
                    {transaction.errorMessage}
                </p>
            </div>
		)
	}

    return null;
}

export default Transaction