import React, { useReducer, useEffect, useState} from 'react';
import DeleteListDialog from "./DeleteListContentDialog/DeleteListDialog"
import { CircularProgress, Tooltip } from '@material-ui/core';
import listIcon from '../../../../assets/cardEntryIcons/order.svg';
import axios from "axios";
import {dataFetchReducer, useStateConnectedToCache} from "../../../Utils/requestUtils";
import ErrorBoundary from "../../../Utils/ErrorBoundary"
import Pagination from "@material-ui/lab/Pagination";
import ListContentsTable from "./ListContentsTable/ListContentsTable";
import InsertItem from './InsertItem/InsertItem';
import Filter from './Filter/Filter'


function ListContentsCard(props) {

    let { list, permissions, companyKey, userData, handleReloadPage, local_storage_object_preffix } = props

    const [listContents, setListContents] = useState([])

    const [pageParams, setPageParams] = useStateConnectedToCache(local_storage_object_preffix)

    //Defining state to trigger table reload
    const [reloadListContentsTable, setReloadListContentsTable] = useState(false)


    //Defining function to check if any item of the table is selected
    const checkItemSelected = () => {
        let isItemSelected = listContents.reduce((acc,item) => {
            if (acc === false && item.isSelected === true) {
                acc = true
            }
            return acc
        },false)
        return isItemSelected
    }


    //Defining DeleteListDialog open a close actions
    const [openDeleteListDialog, setOpenDeleteListDialog] = useState(false);
    const handleOpenDeleteListDialog = () => {
        setOpenDeleteListDialog(true)
    }
    const handleCloseDeleteListDialog = () => {
        setOpenDeleteListDialog(false)
    }


    //Defining table pagination state control
    const [tablePage, setTablePage] = useState(1)

    const handleChangePage = (event, page) => {
		if (tablePage === page) {
			return
		}
        setTablePage(page)
    }
    

    //Making request to Dash API to retrieve list information
    const [fieldData, dispatchData] = useReducer(
        dataFetchReducer,
        {fetchedData: null, isLoading: true, isError: false} 
    )

    useEffect(() => {
        dispatchData({type: "data_fetch_init"}) 
        let payload = {
            "page_number": tablePage - 1,
            "page_rows": 25
        }
        Object.keys(pageParams.filters).forEach((key,index) => {
            payload[key] = pageParams.filters[key]
        })
        payload["username"] = userData.email
        let requestConfigs = {headers:{},
                              params:payload}
        if (userData.business_group_key) {
            requestConfigs.headers = {company_key:companyKey}
        }
		const timer = setTimeout(() => {
			axios.get('/dash/lists/' + list.list_key + '/items', requestConfigs ).then( response => {
                let initialListContentsState = response.data.data.reduce((acc,item) => {
                    let currentListContentObject = {...item, isSelected:false}
                    acc = [...acc,currentListContentObject]
                    return acc
                },[])
                setListContents(initialListContentsState)
                dispatchData({
                    type: "data_fetch_success",
                    payload: response.data
                })
			}).catch(error => {
                if ((error.response || {}).status === 403) dispatchData({type: "data_fetch_failure_403"})
                else if ((error.response || {}).status === 404) dispatchData({type: "data_fetch_failure_404"})  
                else dispatchData({type: "data_fetch_failure"})    
			})	
        }, 500);
		return () => {
            setReloadListContentsTable(false)
			clearTimeout(timer)
        }
    }, [list, tablePage, reloadListContentsTable, companyKey, userData, pageParams])


    //Rendering card depending on request response
    if (fieldData.fetchedData && fieldData.fetchedData.data && fieldData.fetchedData.data.length === 0){
        return (
            <div className="analysisCard" style={{width:"100%", flexGrow:"1"}}>
                <div style={{display: "flex", marginBottom:"-10px"}}>
                    <img className="cardTitleIconStyle" src={listIcon} alt="Icon Logo" />
                    <span className={["blueText", "subtitleSize", "labelSpace", "breakWord"].join(" ")}>Itens da Lista</span>
                </div>
                <div>
                    {permissions.create_item&&
                    <InsertItem companyKey={companyKey} userData={userData} list={list} handleReloadPage={handleReloadPage} />}
                </div>
                <div style={{display:"flex", margin:"20px auto", flexDirection:"column", verticalAlign: "center", textAlign: "center"}}
                     className={["blueText"].join(" ")}>
                    <span>A lista está vazia</span>
                </div>
            </div>
        )
    }
    else if (fieldData.fetchedData) {
        return(
            <div className="analysisCard" style={{width:"100%", flexGrow:"1"}}>
                <div style={{display:"flex", justifyContent: "space-between"}}>
                <div >
                    <img className="cardTitleIconStyle" src={listIcon} alt="Icon Logo" />
                    <span className={["blueText", "subtitleSize", "labelSpace", "breakWord"].join(" ")}>Itens da Lista</span>
                </div>
                {(list.fields[0].type.enum === 'string' || list.fields[0].type.enum === 'number') ?
                    <ErrorBoundary doNothing>
                        <Filter local_storage_object_preffix={local_storage_object_preffix} setPageParams={setPageParams} key_field_header={list.fields[0].name} key_field_type={list.fields[0].type.enum}/>
                    </ErrorBoundary>
                    : null}
                <div>
                    {permissions.delete_item&&
                    <Tooltip title={<ul className="tooltipText" style={{marginLeft: '-40px',listStyle: 'none', textAlign: 'center'}}>
                        <li> 1. Selecione os itens a serem deletados, clicando em suas respectivas caixas de seleção. </li>
                        <li> 2. Clique neste botão de 'Excluir itens'</li>
                        <li> - Para selecionar todos os itens, utiliza a caixa de seleção do cabeçalho.</li>
                    </ul>}>
                    <div 
                        style={{padding: "4px 30px", margin:"5px", width: "150px", float: "right"}}
                        className={checkItemSelected() ?
                            ["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ") :
                            ["button", "disabled", "onlyboarder", "normalText", "normalMediumSize"].join(" ")}
                        onClick={checkItemSelected() ?
                                handleOpenDeleteListDialog:
                                null}
                    >
                        Excluir Itens
                    </div> 
                    </Tooltip>}
                    {permissions.create_item&&
                    <InsertItem companyKey={companyKey} userData={userData} list={list} handleReloadPage={handleReloadPage} />}
                </div>
                </div>
                <div style={{display:"flex", marginTop:"20px",flexDirection:"column", overflow:"auto", whiteSpace: "nowrap"}}>
                    <ListContentsTable setListContents={setListContents} listContents={listContents} list={list} permissions={permissions} companyKey={companyKey} userData={userData} handleReloadPage={handleReloadPage} />
                </div>
                <div style={{display:"flex", marginTop: "10px"}}>
                    <Pagination style={{marginLeft:"auto"}} className='pagination' page={tablePage} count={fieldData.fetchedData.number_of_pages} onChange={(e,page) => handleChangePage(e, page)} />
                </div>
                <DeleteListDialog
                    listContents={listContents}
                    list_key={list.list_key}
                    open={openDeleteListDialog}
                    companyKey={companyKey}
                    userData={userData}
                    setReloadListContentsTable = {setReloadListContentsTable}
                    onClose={handleCloseDeleteListDialog}
                />
            </div>
        )
    }
    else if(fieldData.isError){
		return (
			<div className="analysisCard">
				<p className={["blueText", "titleSize"].join(" ")} style={{margin:"auto", textAlign:"center"}}>
					{fieldData.errorMessage}
				</p>
			</div>
		)
    }
    else if(fieldData.isLoading){
		return (
			<div className="analysisCard">
                <div style={{display:"flex", height:"300px"}}>
                    <CircularProgress style={{margin:"auto"}} />
                </div>
			</div>
		)
    }
    else return null;

}


export default ListContentsCard