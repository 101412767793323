import React from "react";
import FilterBox from "../../../Utils/FilterBox/FilterBox"

function FilterFraudReport(props) {
	
    const { setPageParams } = props

    let filterTypesFraud = {
        bill_payment_date: {
            type: "date",
            disabled: false,
            validation:false,
            validation_rule: null,
            description: "Data"
        }
    }

    return (
        <FilterBox 
            setPageParams={setPageParams}
            filterTypes={filterTypesFraud}
            local_storage_object_preffix={props.local_storage_object_preffix}
        />
    )

}

export default FilterFraudReport