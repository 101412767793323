import React, { useEffect } from "react";
import PastRentals from "./PastRentals/PastRentals";
import StoresMap from "./StoresMap/StoresMap";
import StoresHeader from "./StoresHeader/StoresHeader";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import axios from "axios"
import {pastRentalsIntoStoreObject, checkNested} from "../../utils/utils"


function Store(props) {

  const [storeInfo, setStoresInfo] = React.useState({
    rental_store: null,
    devolution_store: null
  })
  const [pastRentals, setPastRentals] = React.useState(null);

    useEffect(() => {
        const timer = setTimeout(() => {
            const local_stores = require("../../../../assets/json/stores.json");
            let storesInfo = {
                rental_store: local_stores[props.reservation.rental_store],
                devolution_store: local_stores[props.reservation.devolution_store],
            }
            setStoresInfo(storesInfo)
            if (!checkNested(props.reservation, "client", "document_number")) setPastRentals(undefined)
            else{
                axios.get('/dash/car_rental/rental_agreements', {
                    params: {
                        "page_number": 0,
                        "page_rows": 25,
                        "document_number": props.reservation.client.document_number,
                        "remove_safe_clients": true
                    }
                }).then(response => {
                    let pastRentalsList = response.data.data
                    let pastRentalsListWithoutOpen = pastRentalsList.filter(function(ra) {
                        return ra.car_status !== 'created'
                    })
                    let newPastRentals = pastRentalsIntoStoreObject(pastRentalsListWithoutOpen, local_stores);
                    setPastRentals(newPastRentals);
                }).catch(error => {
                    console.log(error.response)
                    setPastRentals(undefined)
                })
            }
        }, 500);
        return () => {
        clearTimeout(timer);
        };
    }, [props.reservation]);

    return (
        <React.Fragment>
            <div className="analysisCard">
                <div>
                    <StoresHeader
                        storeInfo={storeInfo}
                        reservation={props.reservation}
                    />
                </div>
                <div className="internalCardContainer">
                    <div style={{display:"flex", minHeight: "192px"}}>
                        <StoresMap pastRentals={pastRentals} storeInfo={storeInfo} />
                    </div>
                </div>
                <div className="internalCardContainer" style={{display: "flex", flexDirection:"column", flexGrow: "1"}}>
                    <div style={{display:"flex", alignItems: "center"}}>
                        <LocationOnIcon />
                        <div className={["normalText", "normalMediumSize"].join(" ")}>
                            Locações Passadas:
                        </div>
                    </div>
                    <div style={{display:"flex", flexGrow: "1"}}>
                        <PastRentals data={pastRentals} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Store;