import React from 'react';

const rules_context = React.createContext({
    signals_list: null,
    scripts_list: null,
    fields_mapper_list: null,
    processors_variables: null,
    boxes: null
});

export default rules_context;