import React from 'react'
import {getAnalysisStatusColor, getAnalysisStatus} from "../../utils/utilsOrder"
import DataField from "../../utils/DataField";

function Summary(props) {

    let {analysis_events} = props.order

    if (analysis_events == null || analysis_events.length === 0) {
        return(
            <div className="analysisCard">
                <div style={{display: "flex", flexGrow:"1"}}>
                    <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                        Informação Indisponível
                    </div>
                </div>
            </div>
        )
    }

    let {decision_metadata} = analysis_events[0]
    let custom_variables_list = decision_metadata?.custom_variables ? Object.entries(decision_metadata.custom_variables) : []
    let user_variables_list = decision_metadata?.user_variables ? decision_metadata.user_variables : []

    if (decision_metadata == null) {decision_metadata = {}}
    
    return (
        <div className="analysisCard">
            <div style={{display: "flex", flexDirection: "column"}}>
                <div style={{display: "flex", flexDirection: "column",marginBottom:"10px"}}>
                    <div className={["labelText", "normalSmallSize", "noWrap", "bold"].join(" ")}
                            style={{marginBottom:"5px"}}>
                        DECISÃO:
                    </div>
                    <div 
                        className={["labelText", "subtitleSize", "bold"].join(" ")} 
                        style={{color:getAnalysisStatusColor(props.order.analysis_status), marginBottom:"5px"}}
                    >
                        {getAnalysisStatus(props.order.analysis_status)}
                    </div>
                </div>
                <div style={{display: "flex", flexDirection:"column",marginBottom:"10px"}}>
                    <div className={["labelText", "normalSmallSize", "noWrap", "bold"].join(" ")}
                            style={{marginBottom:"5px"}}>

                        MOTIVO:
                    </div>
                    <div 
                        className={["normalText", "subtitleSize", "bold"].join(" ")} 
                    >
                        {decision_metadata.reason == null ? "-" : decision_metadata.reason_description}
                    </div>
                </div>
            </div>
            {!!custom_variables_list.length && 
                    <>
                        <div className={["normalMediumSize","bold"].join(" ")} style={{marginTop: '15px'}}>Variáveis de saída:</div>
                        {custom_variables_list.map((custom_variable, index) => (
                            <div key={index} style={{display: 'flex', flexDirection: 'column'}}>
                                <DataField
                                    title={custom_variable[0]}
                                    label={custom_variable[1].toString()}
                                    size={"normalMediumSize"}
                                    titleNoWrap={false}
                                    isTranslated={false}
                                />
                            </div>
                            ))}
                    </>}
            {!!user_variables_list.length && 
            <>
                <div className={["normalMediumSize","bold"].join(" ")} style={{marginTop: '15px'}}>
                    Variáveis do motor:</div>
                {user_variables_list.map((custom_variable, index) => (
                    <div key={index} style={{display: 'flex', flexDirection: 'column'}}>
                        <DataField
                            title={custom_variable.variable_name}
                            label={custom_variable.variable_value.toString()}
                            size={"normalMediumSize"}
                            titleNoWrap={false}
                        />
                    </div>))}
            </>}
        </div>
    )
}

export default Summary