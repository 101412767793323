import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ValidatedImage from "../../../utils/ValidatedImage"
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Link } from "react-router-dom";
import moment from 'moment'
import { titleCase } from "../../../utils/utils"

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: "16px",
    color: "#0b1f82",
    padding: "16px 24px 0px 24px",
  },
  dialogContent: {
    textAlign: "center",
    width: "400px",
    marginBottom: "10px",
    float: "left",
  },
  cardSlider: {
    width: "300px",
    height: "75%",
    margin: "auto auto 0 auto",
    padding: "auto",
  },
  centralizeArrow: {
    height: "40%",
  },
  arrows: {
    color: "#0b1f82",
  },
  mainCard: {
    position: "relative",
    display: "inline-block",
    textAlign: "center",
    width: "auto",
    height: "auto",
    float: "center",
    margin: "auto",
  },
  buttonPrevious: {
    cursor: "pointer",
    width: "25%",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: "#ddddf9",
    },
  },
  buttonNext: {
    cursor: "pointer",
    width: "25%",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: "#ddddf9",
    },
  },
  label: {
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "19px",
    color: "#000",
  },
  data: {
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "19px",
    color: "#6F6F6F",
  },
  paragraph: {
    margin: "0",
    width: "100%",
  },
}));

function ProfilePictureDialog(props) {
  const classes = useStyles();
  useTheme();

  const [counter, setCounter] = useState(0);

  const clickNext = () => {
    if (counter + 1 === props.data.length) {
      setCounter(0);
    } else {
      setCounter(counter + 1);
    }
  };

  const clickPrevious = () => {
    if (counter === 0) {
      setCounter(props.data.length - 1);
    } else {
      setCounter(counter - 1);
    }
  };

  return (
        <Dialog open={props.open} onClose={props.onClose} keepMounted={false}>
            <DialogTitle className={classes.dialogTitle}>Fotos</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <div style={{display:"flex", width:"300px", margin:"auto", justifyContent: "center"}}>
                    <div className="labelSpace" style={{order: "2", width:"300px", display:"flex"}}>
                        {(props.data[counter].client || {}).face_picture ?
                        <ValidatedImage
                        image_key={props.data[counter].client.face_picture}
                        dash_api_endpoint_url={'/dash/car_rental/image/profile_picture'}
                        imageStyle = {{margin:"auto", width: "300px", minHeight: "150px", maxHeight:"600px", borderRadius: "5px"}}
                        imageAlt = {"Foto do Cliente"}
                        /> :
                        <div 
                            className={["labelText", "normalMediumSize"].join(" ")} 
                            style={{textAlign:"center", margin:"auto"}}
                        >
                            Informação Indisponível
                        </div>                        
                        }
                    </div>
                    {props.data.length < 2 ? null :
                    <React.Fragment>
                    <div onClick={clickPrevious} className={classes.buttonPrevious} style={{order: "1", display:"flex", minWidth:"40px"}}>
                        <ArrowBackIosIcon style={{margin:"auto"}} className={classes.arrows} />
                    </div>
                    <div onClick={clickNext} className={classes.buttonPrevious} style={{order: "3", display:"flex", marginLeft:"5px", minWidth:"40px"}}>
                        <ArrowForwardIosIcon style={{margin:"auto"}} className={classes.arrows} />
                    </div>
                    </React.Fragment>}
                </div>
                <div>
                    <p className={classes.paragraph}>
                        <span className={classes.label}>Locação: </span>
                        <Link 
                            to={"/rental-agreement/" + props.data[counter].rental_agreement_key} 
                            className={["normalMediumSize", "link"].join(" ")}
                        >
                            {props.data[counter].rental_agreement_code}
                        </Link>
                    </p>
                    <p className={classes.paragraph}>
                        <span className={classes.label}>Nome: </span>
                        <span className={classes.data}>
                            {(props.data[counter].client || {}).name ? 
                            titleCase(props.data[counter].client.name, "Indisponível") : "Indisponível"}
                        </span>
                    </p>
                    <p className={classes.paragraph}>
                        <span className={classes.label}>Data: </span>
                        <span className={classes.data}>{moment(props.data[counter].rental_agreement_date).format("DD/MM/YYYY")}</span>
                    </p>
                    <p className={classes.paragraph}>
                        <span className={classes.label}>Loja de Retirada: </span>
                        <span className={classes.data}>{props.data[counter].rental_store}</span>
                    </p>
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default ProfilePictureDialog;
