import React, {useState} from "react";
import PEPRelatedTable from "./PEPRelatedTable/PEPRelatedTable";
import Collapse from "@material-ui/core/Collapse";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import IconButton from "@material-ui/core/IconButton";
import {getCardIcon} from "../../../utils/utils"
import { useTranslation } from 'react-i18next';

function PEPRelatedCard(props) {

    const [expanded, setExpanded] = useState(false);
    const [firstTime, setFirstTime] = useState(true);
    const { t } = useTranslation()

    const {related_pep} = props
    let initialExpand = related_pep.flag === "positive"? false : true
    if (initialExpand !== expanded && firstTime===true){
        setExpanded(initialExpand);
    }
    const handleExpand = (event) => {
        event.stopPropagation();
        setFirstTime(false)
        setExpanded(!expanded);
    };

    return (
        <div className="analysisCard">
            <div style={{display:"flex"}}>
                <div 
                    className={["blueText", "subtitleSize", "bold"].join(" ")}
                    style={{margin:"auto 0px"}}
                >
                    {t("related_pep")}
                </div>
                <div style={{margin:"auto 10px"}}>
                    {getCardIcon(related_pep.flag)}
                </div>
                {related_pep.data.length === 0 ? null :
                <IconButton style={{marginLeft: "auto"}} onClick={handleExpand}>
                    {expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </IconButton>             
                }                    
            </div>
            {related_pep.data.length === 0 ? null :
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <div 
                    className={["normalText", "normalMediumSize", "internalCardContainer"].join(" ")}
                >
                    <PEPRelatedTable related_pep_data={related_pep.data}/>
                </div>
            </Collapse> 
            }     
        </div>
        );
}

export default PEPRelatedCard