import React from "react";

class ErrorBoundary extends React.Component {

	constructor(props) {
	  super(props);
	  this.state = { error: null, errorInfo: null };
	}
	
	componentDidCatch(error, errorInfo) {
	  this.setState({
		error: error,
		errorInfo: errorInfo
	  })
	}
	
	render() {
        if (this.state.errorInfo) {
            if(this.props.doNothing) return null
            return (
                <p style={{display:"flex"}}>
                  <span className={["normalText", "normalMediumSize"].join(" ")}>
                    Error:
                  </span>
                  <span className={["labelText", "normalMediumSize", "labelSpace"].join(" ")}>
                    Please contact suporte.caas@qitech.com.br
                  </span>
                </p>
            );
        }
        return this.props.children;
    }  
  }
export default ErrorBoundary