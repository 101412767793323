import React, { useEffect } from "react";
import { CircularProgress } from '@material-ui/core';
import axios from "axios";
import OrderTable from "./OrderTable/OrderTable"
import Pagination from "@material-ui/lab/Pagination";


function OrderHistoric(props) {

    const document_number   = props.alert.link_key_type === "customer" ? props.alert.customer_document_number : props.alert.seller_document_number
    const link_key_type     = props.alert.link_key_type
  
    const [userHistoric, setUserHistoric] = React.useState(null)
    const [tablePage, setTablePage] = React.useState(1)
  
    useEffect(() => {
        if (userHistoric === null){
            if (document_number == null) setUserHistoric(undefined)
            else{
                let params = link_key_type === "customer" ?
                {
                    "page_number": tablePage -1,
                    "page_rows": 10,
                    "customer_document_number": document_number
                } : {
                    "page_number": tablePage -1,
                    "page_rows": 10,
                    "seller_document_number": document_number
                } 
                  const timer = setTimeout(() => {
                    axios.get('/dash/card_order/orders', {
                        params: params
                    }).then(response=>{
                        setUserHistoric(response.data)
                    }).catch(error=>{
                        setUserHistoric(undefined)
                    })
                }, 500);
                return () => {
                  clearTimeout(timer)
                }                
            }
        }
    }, [userHistoric,tablePage,document_number, link_key_type])

    const handleChangePage = (event, page) => {
		if (tablePage === page) {
			return
		}
        setTablePage(page)
        setUserHistoric(null)
	}
  
    if (userHistoric === null) {
      return (
        <div style={{display: "flex", height: "160px"}}>
            <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                <CircularProgress />
            </div>
        </div>
      );    
    }
    else if (userHistoric === undefined || userHistoric.data.length === 0){
      return (
        <div style={{display: "flex", height: "160px"}}>
            <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                Informação Indisponível
            </div>
        </div>
      )
    }
    else {
        return (
                <div>
                    <div>
                        <OrderTable historic={userHistoric.data} />
                    </div>
                    <div style={{display:"flex", marginTop: "10px"}}>
                        <Pagination style={{marginLeft:"auto"}} className='pagination' page={tablePage} count={userHistoric['number_of_pages']} onChange={(e,page) => handleChangePage(e, page)} />
                    </div>
                </div>
        );
    }
  }
  
  export default OrderHistoric;