import styled from "styled-components";

export const PlatformContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
`;

export const PlatformText = styled.div`
    font-family: "Open Sans";
    color: #545454;
    margin-top: 4px;
`;

export const Line = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
`;

export const Label = styled.div`
    font-family: "Open Sans";
    color: black;
`;

export const Content = styled.div`
    font-family: "Open Sans";
    color: #545454;
`;

export const Subtitle = styled.div`
    font-family: "Open Sans";
    font-weight: bold;
    margin-bottom: 8px;
    color: #0b1f82;
`;

export const Tab = styled.div`
    margin-left: 16px;
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const Indicator = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    border-radius: 4px;
    font-family: "Open Sans";
    background-color: ${(props) => props.category ? '#82ff9555' : '#ff828255'};
    color: ${(props) => props.category ? '#00c41d' : '#ac0505'};
`;

export const Column = styled.div`
    display: flex;
    width: calc(50% - 16px);
    flex-direction: column;
`;

export const Divider = styled.div`
    height: 100%;
    width: 1px;
    background-color: #54545420;
`;

export const VersionWarning = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    background-color: #ff828222;
    border-radius: 10px;
    margin: 16px 0;
    padding: 16px;
`;

export const WarningTitle = styled.div`
    font-family: "Open Sans";
    color: #a60505;
    font-size: 16px;
    font-weight: bold;
`;

export const WarningText = styled.div`
    font-size: 14px;
    font-family: "Open Sans";
`;

export const WarningFooting = styled.div`
    font-size: 11px;
    font-family: "Open Sans";
`;

export const WarningInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const Version = styled.b`
    text-transform: uppercase;
`;