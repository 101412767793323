import React, {useState, useEffect} from "react";
import CompaniesContent from "./CompaniesContent"
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import InputContent from "./InputContent";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

function NewAnalyzeDialog(props) {

    const { setPageParams, onClose, local_storage_object_preffix, open, companiesList, user_data } = props 

    const [selectCompany, setSelectCompany] = useState(null)
    const { t } = useTranslation();

    useEffect(() => {
        if (user_data.business_group_key){
            setSelectCompany({
                status: true
            })
        }
        else{
            setSelectCompany({
                status: false,
                company_key: user_data.company_key
            })
        }
    },[user_data])

    const handleDialogClose = () => {
        onClose()
        setTimeout(() => {
            if (user_data.business_group_key){
                setSelectCompany({
                    status: true
                })
            }
            else{
                setSelectCompany({
                    status: false,
                    company_key: user_data.company_key
                })
            }}, 500)
    }
    
    let dialogContent = null

    if (selectCompany===null){
        dialogContent = null
    }
    else if (selectCompany.status){
        dialogContent = <CompaniesContent setSelectCompany={setSelectCompany} companiesList={companiesList}/>
    }
    else (
        dialogContent = 
            <InputContent
                business_group_key={user_data.business_group_key}
                company_key={selectCompany.company_key}
                setSelectCompany={setSelectCompany}
                setPageParams={setPageParams}
                onClose={onClose}
                person_type={local_storage_object_preffix.split("_")[1]}
                local_storage_object_preffix={local_storage_object_preffix}
            />
    )

    return (
        <Dialog
        open={open} 
        onClose={handleDialogClose}
        disableBackdropClick
        keepMounted={false}>           
            <DialogTitle 
                className={['blueText', "subtitleSize"].join(" ")} 
                onClose={handleDialogClose}
            >
                {t("new_analysis")}
            </DialogTitle>
            <DialogContent >
                <div style={{width:"400px",  display:"flex", flexDirection:"column", marginBottom:"30px"}}>
                    {dialogContent}
                </div>
            </DialogContent>        
        </Dialog>
    )
}

export default NewAnalyzeDialog