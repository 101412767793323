export const translateLockType = (lockType) => {
    switch(lockType){
        case "seller":
            return "Payout do Vendedor"
        case "seller_transactional":
            return "Transacional do Vendedor"
        case "customer":
            return "Cliente"
        default:
            return lockType
    }
}

export const translateLockSource = (lockSource) => {
    switch(lockSource){
        case "manually_input":
            return "Inserção Manual"
        case "manually_alert":
            return "Alerta Manual"
        case "automatically_alert":
            return "Alerta Automático"
        default:
            return lockSource
    }
}

export const translateLockStatus = (lockStatus) => {
    switch(lockStatus){
        case "blocked":
            return "Bloqueado"
        case "unblocked":
            return "Desbloqueado"
        default:
            return lockStatus
    }
}

export let lockFilterTypes = {
    customer_document_number: {
        type: "brazilian_document_numbers",
        disabled: false,
        validation:true,
        validation_rule: {type: "multiple_equal", values:[14,18]},
        description: "CPF/CNPJ do Comprador"
    },
    seller_document_number: {
        type: "brazilian_document_numbers",
        disabled: false,
        validation:true,
        validation_rule: {type: "multiple_equal", values:[14,18]},
        description: "CPF/CNPJ do Vendedor"
    },
    lock_source: {
        type: "enum",
        enumList: [
            ["Nenhum", ""],
            [translateLockSource("manually_input"), "manually_input"],
            [translateLockSource("manually_alert"), "manually_alert"],
            [translateLockSource("automatically_alert"), "automatically_alert"]
        ],
        disabled: false,
        validation:false,
        description: "Fonte do Bloqueio"
        },
    lock_type: {
        type: "enum",
        enumList: [
            ["Nenhum", ""],
            [translateLockType("customer"), "customer"],
            [translateLockType("seller"), "seller"],
            [translateLockType("seller_transactional"), "seller_transactional"]
        ],
        disabled: false,
        validation:false,
        description: "Tipo do Bloqueio"
        },
    lock_status: {
        type: "enum",
        enumList: [
            ["Nenhum", ""],
            [translateLockStatus("blocked"), "blocked"],
            [translateLockStatus("unblocked"), "unblocked"]
        ],  
        disabled: false,
        validation:false,
        description: "Status"
    }
}
