//Importing modules
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import EditInputContent from "./EditInputContent"

//Defining dialog styles
const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });



function ManualEditDialog(props){

  let { list, listItem, onClose, handleReloadPage, userData, companyKey} = props

  return(
      <Dialog 
      open={props.open} 
      onClose={onClose}
      disableBackdropClick
      maxWidth="lg"
      keepMounted={false}>
          <DialogTitle 
              className={['blueText', "subtitleSize"].join(" ")} 
              onClose={onClose}
          >
              Editar Item
          </DialogTitle>
          <DialogContent >
              <div style={{minHeight: "100px", minWidth: "300px", display:"flex", flexDirection:"column", marginBottom:"20px", width:"max-content",overflow:"hidden", padding:"8px 24px"}}>
                  <EditInputContent 
                  onClose={onClose} 
                  listItem={listItem} 
                  handleReloadPage={handleReloadPage}
                  companyKey={companyKey}
                  userData={userData}
                  list={list}
                  />
              </div>
          </DialogContent>
      </Dialog>
  )
}



//Exporting Manual Edit Dialog component as default
export default ManualEditDialog