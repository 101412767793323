import React from 'react'
import DataField from "../../utils/DataField"
import {formatAsMoney} from "../../utils/utils"
import blueBankslip from '../../../../assets/cardEntryIcons/blueBankslip.svg';
import moment from 'moment'

function BankslipCard(props) {

    let { bankslip } = props

    return (
        <div className="analysisCard">
            <div style={{display:"flex"}}>
                <img style={{margin:"auto 8px auto 0px"}} src={blueBankslip} alt="withdrawal Logo"/>
                <div className={["blueText", "subtitleSize"].join(" ")}>Boleto</div>
            </div>
            <div className="internalCardContainer"  style={{display: "flex", flexDirection: "column"}}>
                <div className="internalCardContainer">
                    <div style={{display:"flex", marginTop:"5px"}}>
                        <div style={{width:"65%"}}>
                            <div className={["labelText", "normalMediumSize", "bold"].join(" ")}>
                                VALOR PAGO
                            </div>
                            <div className={["normalText", "moneySize", "bold"].join(" ")}>
                                {formatAsMoney(bankslip.amount, "-")}
                            </div>
                        </div>
                        <div style={{width:"35%"}}>
                            <div className={["labelText", "normalSmallSize", "bold"].join(" ")}>
                                VALOR DO DOCUMENTO
                            </div>
                            <div className={["normalText", "reducedMoneySize", "bold"].join(" ")}>
                                {formatAsMoney(bankslip.document_amount, "-")}
                            </div>
                        </div>
                    </div>
                    <div style={{display:"flex", marginTop:"5px"}}>
                        <div style={{width:"35%"}}>
                            <div className={["labelText", "normalSmallSize", "bold"].join(" ")}>
                                JUROS
                            </div>
                            <div className={["normalText", "reducedMoneySize", "bold"].join(" ")}>
                                {formatAsMoney(bankslip.interest_amount, "-")}
                            </div>
                        </div>
                        <div style={{width:"30%"}}>
                            <div className={["labelText", "normalSmallSize", "bold"].join(" ")}>
                                DESCONTO
                            </div>
                            <div className={["normalText", "reducedMoneySize", "bold"].join(" ")}>
                                {formatAsMoney(bankslip.discount_amount, "-")}
                            </div>
                        </div>
                        <div style={{width:"35%"}}>
                            <div className={["labelText", "normalSmallSize", "bold"].join(" ")}>
                                OUTRAS DEDUÇÕES
                            </div>
                            <div className={["normalText", "reducedMoneySize", "bold"].join(" ")}>
                                {formatAsMoney(bankslip.other_deduction_amount, "-")}
                            </div>
                        </div>
                    </div>
                </div>
                <DataField
                    title={"Data de Emissão"}
                    label={moment(bankslip.bankslip_issuing_date).format("DD/MM/YYYY")}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                />
                <DataField
                    title={"Data de Vencimento"}
                    label={moment(bankslip.bankslip_due_date).format("DD/MM/YYYY")}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                />
                <DataField
                    title={"Data de Pagamento"}
                    label={moment(bankslip.bankslip_payment_date).format("DD/MM/YYYY")}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                />
                <div className="internalCardContainer">
                    <div>Descrição</div>
                    <div className={["labelText", "subtitleSize"].join(" ")} style={{margin:"5px auto"}}>
                        {bankslip.description}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BankslipCard