import React, { useEffect, useContext, useReducer, useCallback, useState } from "react";
import AuthContext from "../../context/auth-context"
import CompaniesContext from "../../context/companies-context"
import {dataFetchReducer} from "../Utils/requestUtils"
import TextField from "@material-ui/core/TextField";
import MenuItem from '@material-ui/core/MenuItem';
import UserCard from "./UserCard/UserCard"
import UserInvite from "./Dialogs/UserInvite";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import axios from "axios";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorBoundary from "../Utils/ErrorBoundary";
import { useTranslation } from "react-i18next";
import UserRolesManagement from "./UserRolesManagement/UserRolesManagement";
import Button from "../@GeneralComponents/Button";
import Pagination from "@material-ui/lab/Pagination";
import PaginationItem from '@material-ui/lab/PaginationItem';
import * as S from './styles'
import SearchBar from "../../components/common/SearchBar";

function UserManagement(props) {
    let user_data = useContext(AuthContext).user_data
    let companiesContext = useContext(CompaniesContext)

    const [companiesList, setCompaniesList] = useState([])
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        let updatedCompanies = []
        for (const item in companiesContext) {
            if (companiesContext[item] && companiesContext[item].companies) {
                updatedCompanies = [
                    ...updatedCompanies,
                    ...companiesContext[item].companies
                ]
            }
        }
        const uniqueCompanyKeys = new Set(updatedCompanies.map(updatedCompanies => updatedCompanies.company_key))
        
        const uniqueUpdatedCompanies = Array.from(uniqueCompanyKeys).map(company_key => {
            return updatedCompanies.find(company => company.company_key === company_key);
          });

        setCompaniesList(uniqueUpdatedCompanies)
    }, [companiesContext])

    const { t } = useTranslation();

    const [expanded, setExpanded] = useState(true);
    const [companyValue, setCompanyValue] = useState(null)
    const [inviteDialogOpened, setInviteDialogOpened] = useState(false);
    const [snackBarStatus, setSnackBarStatus] = useState({
        opened: false,
        severity: "success",
        message: "" 
    });

    const [mainUserList, dispatchMainUserList] = useReducer(
        dataFetchReducer,
        {fetchedData: [], isLoading: true, isError: false} 
    )
    const [secondaryUserList, dispatchSecondaryUserList] = useReducer(
        dataFetchReducer,
        {fetchedData: [], isLoading: true, isError: false} 
    )

    const [userCardPage, setUserCardPage] = useState(1);
    const [secondaryUserCardPage, setSecondaryUserCardPage] = useState(1);
    const [lockMainUserList, setLockMainUserList] = useState(false);
    const [lockSecondaryUserList, setLockSecondaryUserList] = useState(false);

    const doRequest = useCallback((payload=null) => {

            const page_rows = 10;

            let dispatchFunction
            let selected_page
            let baseList
            let locked
            let setLocked
            let searchQuery

            if (payload) {
                dispatchFunction = dispatchSecondaryUserList;
                selected_page = secondaryUserCardPage;
                baseList = secondaryUserList.fetchedData
                locked = lockSecondaryUserList
                setLocked = setLockSecondaryUserList
                searchQuery = secondaryUserListSearchQuery


            } else {
                dispatchFunction = dispatchMainUserList;
                selected_page = userCardPage;
                baseList = mainUserList.fetchedData
                locked = lockMainUserList
                setLocked = setLockMainUserList
                searchQuery = mainUserListSearchQuery
            }

            if (locked || (baseList.length > 0 && selected_page < baseList.length - 1)) return // Recúo de página

            dispatchFunction({type: "data_fetch_init"});

            let url = `/dash/users?page_number=${selected_page === 1 ? 0 : selected_page}&page_rows=${selected_page === 1 ? 2 * page_rows : page_rows}`

            if (searchQuery && searchQuery.length > 0) {
                url = url + `&search=${searchQuery}`
            }

            axios.get(url, { params: payload })
            .then((response) => {
                let unparsed_users = response.data
                let users = baseList
                if (selected_page === 1) {
                    if (unparsed_users.length > page_rows) {
                        users = [unparsed_users.slice(0, page_rows), unparsed_users.slice(page_rows, 2 * page_rows)];
                    } else {
                        users = [unparsed_users];
                        setLocked(true);
                    }
                } else {
                    if (unparsed_users.length !== 0) {
                        users = [...users, unparsed_users];
                    } else {
                        setLocked(true);
                    }
                }
                
                dispatchFunction({
                    type: "data_fetch_success",
                    payload: users
                })
            })
            .catch((error) => {
                if      ((error.response || {}).status === 403) dispatchFunction({type: "data_fetch_failure_403"})
                else if ((error.response || {}).status === 404) dispatchFunction({type: "data_fetch_failure_404"})  
                else    dispatchFunction({type: "data_fetch_failure"})    
            })
        // eslint-disable-next-line
        }, [secondaryUserCardPage, userCardPage, refresh]);

    useEffect(() => {
        const timer_ = setTimeout(() => {
            doRequest()
        }, 500);
        return () => {
            clearTimeout(timer_)
		}	
    },[doRequest]);

    useEffect(() => {
        if (companyValue){
            const timer_ = setTimeout(() => {
                doRequest({company_key: companyValue})
            }, 500);
            return () => {
                clearTimeout(timer_)
            }	
        }
    },[doRequest,companyValue]);

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') return
        setSnackBarStatus({...snackBarStatus, opened: false});
    };

    const handleExpand = (event) => {
        event.stopPropagation();
        setExpanded(!expanded);
    };

    const [selectedUserEmail, setSelectedUserEmail] = useState("")

    const [groups, dispatchGroups] = useReducer(
        dataFetchReducer,
        {fetchedData: null, isLoading: true, isError: false} 
    )

    const doGroupsRequest = useCallback(
        () => {
        dispatchGroups({type: "data_fetch_init"}) 
        axios
        .get(`/dash/group`)
        .then(response => {
            dispatchGroups({type:"data_fetch_success",payload:response.data})
        })
        .catch(error => {
            if ((error.response || {}).status === 403) dispatchGroups({type: "data_fetch_failure_403"})
            else if ((error.response || {}).status === 404) dispatchGroups({type: "data_fetch_success",payload:[]})
            else dispatchGroups({type: "data_fetch_failure"})
        })},[]
    )

    useEffect(() => {
        const timer = setTimeout(() => {
            doGroupsRequest()
        }, 500);
        return () => {clearTimeout(timer)}
    }, [doGroupsRequest])
    
    const contentContainer = (userList, userCardPage, setUserCardPage) => {
        if (userList.isLoading){
            return(
                <div className='emptyContainer' style={{height:"97px", display:"flex", alignItems:"center", margin: "10px 0px"}}>
                    <CircularProgress style={{width:"20px",height:"20px", margin:"auto"}}/>
                </div>
            )
        }
        else if(userList.isError){
            return (
                <div style={{height: "200px", display:"flex"}}>
                    <p className={["blueText", "titleSize"].join(" ")} style={{margin:"auto", textAlign:"center"}}>
                        {userList.errorMessage}
                    </p>
                </div>
            )
        }
        else if((userList.fetchedData[0] || []).length === 0){
            return (
                <div className='emptyContainer' style={{ margin: "10px 0px" }}>
                    <p className={["blueText", "subtitleSize"].join(" ")}>
                        {t("Nenhum usuário disponível")}
                    </p>
                </div>
            )
        }
        else if ((userList.fetchedData || []).length > 0) {
            let pageCount = userList.fetchedData.length + userCardPage - 2;
            if (pageCount === 1) pageCount = 2;
            let userListSubset = userList.fetchedData;
            return (
                <S.ContentContainerWrapper>
                    {userListSubset[(userCardPage - 1)].sort((a, b) => a.first_name === b.first_name ? 0 : a.first_name > b.first_name ? 1 : -1).map((user) => (
                    <ErrorBoundary key={`${user.email}index${userCardPage}`}>
                        <UserCard
                            selectedUserEmail={selectedUserEmail}
                            setSelectedUserEmail={setSelectedUserEmail}
                            user={user}
                            setSnackBarStatus={setSnackBarStatus}
                            onUpdate={()=>{}}
                            isManagingItself={user.username === user_data.username}
                        />
                    </ErrorBoundary>))}
                    <S.PageSelectorWrapper>
                    <Pagination 
                    style={{marginLeft:"auto"}}
                    className='pagination'
                    page={userCardPage}
                    count={userListSubset.length} 
                    onChange={(e, page) => setUserCardPage(page)}
                    renderItem={(item) => {
                        if (![userCardPage - 1, userCardPage, userCardPage + 1].includes(item.page)) {
                            return <></>
                        }
                        return <PaginationItem {...item} />
                    }}
                    />
                    </S.PageSelectorWrapper>
                </S.ContentContainerWrapper>
            )
        }
    }

    const [permissionSnackbarOpen, setPermissionSnackbarOpen] = useState(false);
    const [mainUserListSearchQuery, setMainUserListSearchQuery] = useState("");
    const [secondaryUserListSearchQuery, setSecondaryUserListSearchQuery] = useState("");

    const handleMainListReset = () => {
        setUserCardPage(1);
        setLockMainUserList(false);
        dispatchMainUserList({ type: "data_fetch_reset", payload: [] })
        setRefresh(!refresh)
    }

    const handleSecondaryListReset = () => {
        setSecondaryUserCardPage(1);
        setLockSecondaryUserList(false);
        dispatchSecondaryUserList({ type: "data_fetch_reset", payload: [] });
        setRefresh(!refresh);
    }

    const [resetMainList, setResetMainList] = useState(false);
    const [resetSecondaryList, setResetSecondaryList] = useState(false);

    useEffect(() => {
        handleMainListReset();
    // eslint-disable-next-line
    }, [resetMainList])
    
    useEffect(() => {
        handleSecondaryListReset();
    // eslint-disable-next-line
    }, [resetSecondaryList])

return (
    <S.Container>
        <S.SectionTitle>{t("PERFIL E PERMISSÕES")}</S.SectionTitle>
        <S.Wrapper>
            <S.LeftSection>
                <S.LeftSectionHeader>
                    <S.UsersLabel>
                        {user_data.business_group_key ? `Business Group | ${t("Usuários")}` : t("Usuários")}
                    </S.UsersLabel>
                    <Button
                        button_option="standard"
                        onClick={() => setInviteDialogOpened(true)}
                        buttonLabel={t("invite_user")}
                        buttonStyle={{width: "200px", margin: "4px 0px 4px auto"}}
                    />
                    <div style={{display:"flex"}}>
                        {user_data.business_group_key ?
                        <div>
                            <IconButton onClick={handleExpand}>
                                {expanded ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                            </IconButton>
                        </div> : null}
                    </div>
                </S.LeftSectionHeader>
                <SearchBar label="Pesquisar usuários" content={mainUserListSearchQuery} setContent={setMainUserListSearchQuery} refresher={resetMainList} setRefresher={setResetMainList}/>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    {contentContainer(mainUserList, userCardPage, setUserCardPage)}
                </Collapse>
                {user_data.business_group_key&&
                <div style={{display: "flex", flexDirection:"column"}}>
                    <div className={["blueText", "subtitleSize"].join(" ")} style={{ margin: "10px 0px" }}>
                        {`Business Group | ${t("Companhias")}` }
                    </div>
                    {companiesList.length > 0 ?
                    <React.Fragment>
                        <div className="filterContainer" style={{width:"100%"}}>
                            <TextField 
                                style={{textAlign:"center"}} 
                                onBlur={props.onBlur} 
                                onChange={(e) => {
                                    setCompanyValue(e.target.value);
                                    setSecondaryUserCardPage(1);
                                    setLockSecondaryUserList(false);
                                    dispatchSecondaryUserList({ type: "data_fetch_reset", payload: [] });
                                    setRefresh(!refresh);
                                }} 
                                className="filterTextbox" 
                                size="small" fullWidth 
                                value={companyValue || ""} 
                                select
                            >
                                {companiesList.sort((a, b) => a.name === b.name ? 0 : a.name > b.name ? 1 : -1).map((companyInfo, index)=>(
                                    <MenuItem key={index} value={companyInfo.company_key}><em className="normalText">{companyInfo.name}</em></MenuItem>
                                ))}
                            </TextField>
                            <IconButton style={{float: "right"}} size="small" onClick={()=> setCompanyValue(null)}>
                                <CloseIcon fontSize="small"/>
                            </IconButton>
                        </div>
                        {companyValue ? 
                        <div style={{paddingTop: 24, paddingBottom: 24}}>
                            <SearchBar label="Pesquisar usuários" content={secondaryUserListSearchQuery} setContent={setSecondaryUserListSearchQuery} refresher={resetSecondaryList} setRefresher={setResetSecondaryList}/>
                        </div> : null}
                        {companyValue ? contentContainer(secondaryUserList, secondaryUserCardPage, setSecondaryUserCardPage) : null}       
                    </React.Fragment> :null}
                </div>}
            </S.LeftSection>
            <S.RightSection>
                {selectedUserEmail===""||
                mainUserList===null||
                mainUserList.isLoading||
                mainUserList.isError||
                ((mainUserList.fetchedData[userCardPage - 1] || []).length === 0)||
                (mainUserList.fetchedData[userCardPage - 1].filter((user)=>(user.email===selectedUserEmail))||[]).length!==1||
                groups.isLoading?
                null:
                <UserRolesManagement
                    groups={groups.fetchedData.groups}
                    selectedUserEmail={selectedUserEmail}
                    user={mainUserList.fetchedData[userCardPage - 1].filter((user)=>(user.email===selectedUserEmail))[0]}
                    onUpdateGroups={doGroupsRequest}
                    onUpdate={doRequest}
                    snackbarSetter={setPermissionSnackbarOpen}
                />}
                {!user_data.business_group_key||
                !companiesList.length > 0||
                !companyValue||
                selectedUserEmail===""||
                secondaryUserList===null||
                secondaryUserList.isLoading||
                secondaryUserList.isError||
                ((secondaryUserList.fetchedData[secondaryUserCardPage - 1] || []).length === 0)||
                (secondaryUserList.fetchedData[secondaryUserCardPage - 1].filter((user)=>(user.email===selectedUserEmail))||[]).length!==1||
                groups.isLoading?
                null:
                <UserRolesManagement
                    groups={groups.fetchedData.groups}
                    selectedUserEmail={selectedUserEmail}
                    user={secondaryUserList.fetchedData[secondaryUserCardPage - 1].filter((user)=>(user.email===selectedUserEmail))[0]}
                    onUpdateGroups={doGroupsRequest}
                    onUpdate={doRequest}
                />}
            </S.RightSection>
        </S.Wrapper>
        <UserInvite 
            open={inviteDialogOpened} 
            onClose={()=>setInviteDialogOpened(false)} 
            setSnackBarStatus={setSnackBarStatus}
            onUpdate={doRequest}
        />
        <Snackbar
                open={snackBarStatus["opened"]}
                autoHideDuration={10000}
                onClose={handleCloseSnackBar}
            >
            <MuiAlert
                elevation={6}
                variant="filled"
                onClose={handleCloseSnackBar}
                severity={snackBarStatus["severity"]}
            >
                {snackBarStatus["message"]}
            </MuiAlert>
        </Snackbar>
        <Snackbar
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                open={permissionSnackbarOpen}
                autoHideDuration={6000}
                onClose={() => setPermissionSnackbarOpen(false)}
            >
            <MuiAlert
                elevation={6}
                variant="filled"
                onClose={handleCloseSnackBar}
                severity={snackBarStatus["severity"]}
            >
                {"Permissões alteradas com sucesso"}
            </MuiAlert>
        </Snackbar>
    </S.Container>
);
}

export default UserManagement;