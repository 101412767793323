import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #cccc;
    border-radius: 10px;
    padding: 21px;
    display:flex;
    flex-direction: column;
`;

export const Header = styled.div`
    margin-bottom: 16px;
`;

export const Title = styled.div`
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: bold;
    color: #0b1f82;
`;
export const Subtitle = styled.div`
    font-family: 'Open Sans';
    color: #0b1f82;
    font-weight: bold;
    font-size: 14px;
    margin-top: 12px;
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin: 24px 0 12px 0;
    padding-bottom: 12px;
    flex-wrap: wrap;
    height: auto;
    row-gap: 32px;
    margin-bottom: 16px;
`;

export const Item = styled.div`
    border-left: 0.5px solid #cccc;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 296px;
`;

export const Value = styled.div`
    font-family: 'Open Sans';
    font-size: 14px;
`;

export const Line = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
`;

export const Label = styled.div`
    font-family: 'Open Sans';
    font-weight: bold;
    font-size: 14px;
`;

export const TopicWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: fit-content;
`;

export const Message = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 32px 0 32px 0;
    color: #777;
    font-family: 'Open Sans';
    font-size: 14px;
`;