import React from 'react'
import Tooltip from "@material-ui/core/Tooltip";
import {colorGreen, colorRed, colorGrey, colorYellow, colorOrange} from "./utils"
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckIcon from "@material-ui/icons/Check";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import PublishIcon from '@material-ui/icons/Publish';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import BlockIcon from '@material-ui/icons/Block';
import ClearIcon from '@material-ui/icons/Clear';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';



//Translating source browser where the order was made
export const getSourceBrowser = (status) => {
    switch(status){
        case "chrome":
            return "Google Chrome"
        case "firefox":
            return "Mozilla Firefox"
        case "safari":
            return "Safari"
        case "app":
            return "Aplicativo"
        default:
            return status
    }
}


//Translating source platform where the order was made
export const getSourcePlatform = (status) => {
    switch(status){
        case "android":
            return "Android"
        case "windows":
            return "Windows"
        case "ios":
            return "IOS"
        case "linux":
            return "Linux"
        default:
            return status
    }
}


//Translating source platform where the order was made
export const getPaymentType = (status) => {
    switch(status){
        case "credit":
            return "Crédito"
        case "debit":
            return "Débito"
        default:
            return status
    }
}


//Joining browser and platform together to show to user
export const getBrowserPlatformName = (channel, platform) => {
    let translated_browser = getSourceBrowser(channel)
    let translated_platform = getSourcePlatform(platform)
    if (channel && platform) return (translated_platform + " via " + translated_browser)
    else if(channel) return translated_browser
    else if(platform) return translated_platform
    else return null
}


//Translating analysis status
export const getAnalysisStatus = (status) => {
    switch(status){
        case "pending":
        case "in_manual_analysis":
          	return "Pendente";
        case "manually_approved":
            return ("Aprovado Manualmente");
        case "automatically_approved":
            return ("Aprovado Automaticamente");
        case "manually_reproved":
            return ("Reprovado Manualmente");
        case "automatically_reproved":
            return ("Reprovado Automaticamente");
        case "approved_by_time":
            return "Aprovado por tempo";
        case "not_analised":
            return "Não analisado";
        default:
            return status;
    }
}


//Generating analysis status identification letter to be shown inside colored circle on cards
export const getAnalysisStatusSiglum = (status) => {
    switch(status){
        case "pending":
        case "in_manual_analysis":
          	return "P";
        case "manually_approved":
            return "M";
        case "manually_reproved":
            return "M";
        case "automatically_approved":
            return "A";
        case "automatically_reproved":
            return "R";
        case "approved_by_time":
            return "T";
        case "not_analised":
            return "NA";
        default:
            return "?";
    }
}


//Define if the user can make changes on the order
export const setAllowChanges = (status, roles) => {
    if (!roles.includes("update_card_order_orders")){
        return false
    }
    switch(status){
        case "in_manual_analysis":
            return true
        default:
            return false
    }
}


//Defining colors for each analysis status
export const getAnalysisStatusColor = (status) => {
    switch(status){
        case "automatically_approved":
        case "manually_approved":
        case "approved_by_time":
            return colorGreen
        case "automatically_reproved":
        case "manually_reproved":
            return colorRed
        default:
            return colorGrey
    }
}


//Format decision_api score
export const formatScore = (score) => {
    let color
    let text
    let icon
    if (score > 800) {
        color = colorRed
        text = "ARRISCADO"
        icon = <ErrorOutlineIcon style={{width:"30px", height:"30px", color:color, margin:"auto 0px"}}/>
    } 
    else if (score > 500) {
        color = colorYellow
        text = "MODERADO"
        icon = <ErrorOutlineIcon style={{width:"30px", height:"30px", color:color, margin:"auto 0px"}}/>
    } 
    else {
        color = colorGreen
        text = "SEGURO"
        icon = <CheckIcon style={{width:"30px", height:"30px", color:color, margin:"auto 0px"}}/>
    }

    return ({color:color, text:text, icon:icon})
}


//Translate transaction status
export const getTransactionStatusText = (status) => {
    switch(status){
        case "created":
            return "Transação Requisitada"
        case "authorized":
            return "Transação Autorizada"
        case "captured":
            return "Transação Capturada"
        case "not_authorized":
            return "Transação Não Autorizada"
        case "cancelled":
            return "Transação Cancelada"
        case "chargeback":
            return "Transação recebeu Chargeback" //Dar uma olhada nessa tradução
        default:
            return status
    }
}


//Define transaction status icon color
export const getTransactionStatusColor = (status) => {
    switch(status){
        case "authorized":
            return colorGreen
        case "not_authorized":
            return colorRed
        case "captured":
            return colorGreen
        case "created":
            return colorYellow
        case "cancelled":
            return colorOrange
        case "chargeback":
            return colorOrange
        default:
            return colorGrey
    }
}


//Define transaction status Icon
export const getTransactionStatusIcon = (status, icon_size_class, showTooltip=false) => {
    let circle_size
    let icon_size
    let icon_component
    let icon_backgroud_color = getTransactionStatusColor(status)
    if (icon_size_class === "big"){
        circle_size = ""
        icon_size = "45px"
    }
    else if (icon_size_class === "small"){
        circle_size = "small"
        icon_size = "18px"
    }
    else throw new Error("Please a new size is needed for setup")
    switch(status){
        case "authorized":
            icon_component = <DoneIcon  style={{width:icon_size, height:icon_size}}/>
            break
        case "not_authorized":
            icon_component = <ClearIcon  style={{width:icon_size, height:icon_size}}/>
            break   
        case "captured":
            icon_component = <DoneAllIcon  style={{width:icon_size, height:icon_size}}/>
            break       
        case "created":
            icon_component = <PublishIcon  style={{width:icon_size, height:icon_size}}/>
            break
        case "cancelled":
            icon_component = <BlockIcon  style={{width:icon_size, height:icon_size}}/>
            break
        case "chargeback":
            icon_component = <MoneyOffIcon  style={{width:icon_size, height:icon_size}}/>
            break   
        default:
            icon_component = <HelpOutlineIcon  style={{width:icon_size, height:icon_size}}/>
            break   
    }
    if (showTooltip){
        return (
            <Tooltip title={<p className="tooltipText">{getTransactionStatusText(status)}</p>}>
                <div className={["circle", circle_size].join(" ")} style={{backgroundColor: icon_backgroud_color}}>
                    {icon_component}
                </div>
            </Tooltip>
        )
    }
    else{
        return (
            <div className={["circle", circle_size].join(" ")} style={{backgroundColor: icon_backgroud_color}}>
                {icon_component}
            </div>
        )
    }
}


//Getting decision signals icon
export const getSignalsFlagIcon = (flag) => {
    switch(flag){
      case "positive":
        return (<CheckIcon style={{width:"20px", height:"20px", color:colorGreen, margin:"auto"}}/>)
      case "negative":
        return (<ErrorOutlineIcon style={{width:"20px", height:"20px", color:colorRed, margin:"auto"}}/>)
      case "neutral":
      default:
        return(<ErrorOutlineIcon style={{width:"20px", height:"20px", color:colorGrey, margin:"auto"}}/>)
    }
  }

  //Getting analysis status icon
  export const getAnalysisStatusIcon = (status, decrease=false) => {
    let iconSize = "45px"
    if (decrease) {
      iconSize = "15px"
    }
    switch(status){
      case "automatically_approved":
        return (<CheckIcon className="fraudStatusIcon" style={{width:iconSize, height:iconSize}}/>)
      case "automatically_declined":
      case "automatically_reproved":
        return (<ClearIcon className="fraudStatusIcon" style={{width:iconSize, height:iconSize}}/>)
      case "pending":
      default:
        return(<HelpOutlineIcon className="fraudStatusIcon" style={{width:iconSize, height:iconSize}}/>)
    }
  }


  //Get all transactions status
  export const getTransactionBlockStatusIcon = (transactions) => {
    let iconSize = "30px"  
    let hasStarted = false
    let isDone = true
    let isAllNull = true

    transactions.forEach((transaction,index) => {
        isAllNull           = isAllNull ? transaction.status == null : false
        transaction.status  = transaction.status == null ? transaction.status : "no_status"
        hasStarted          = hasStarted ? true : ["chargeback","authorized","not_authorized","captured", "cancelled"].includes(transaction.status)
        isDone              = isDone ? !["created","authorized","not_authorized", "no_status"].includes(transaction.status) : false
    })

    if (hasStarted === false && isDone === false){
        return(
            <Tooltip title={<p className="tooltipText"> Nenhuma transação foi finalizada</p>}>
                <ErrorOutlineIcon className="fraudStatusIcon" style={{width:iconSize, height:iconSize, fill:colorRed}}/>
            </Tooltip>
        )
    }
    else if (hasStarted === true && isDone === true){
        return(
            <Tooltip title={<p className="tooltipText"> Todas as transações foram finalizadas</p>}>
                <ErrorOutlineIcon className="fraudStatusIcon" style={{width:iconSize, height:iconSize, fill:colorGreen}}/>
            </Tooltip>
        )    }
    else if (hasStarted === true && isDone === true){
        return(
            <Tooltip title={<p className="tooltipText"> Todas as transações foram finalizadas</p>}>
                <ErrorOutlineIcon className="fraudStatusIcon" style={{width:iconSize, height:iconSize, fill:colorYellow}}/>
            </Tooltip>
        )    }
    else {
        return(
            <Tooltip title={<p className="tooltipText"> Todas as transações não possuem status definido</p>}>
                <HelpOutlineIcon className="fraudStatusIcon" style={{width:iconSize, height:iconSize, fill:colorGrey}}/>
            </Tooltip>
        )    }
  }