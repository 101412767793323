import React from 'react'
import DataField from "../../utils/DataField"
import PersonTypeIcon from "./PersonTypeIcon/PersonTypeIcon"
import people from '../../../../assets/cardEntryIcons/people.svg';
import {formatAddress,formatPhone, formatAsMoney} from "../../utils/utils"
import { getSalesChannel } from "../../utils/utils"
import ClientMap from "./ClientMap/ClientMap"
import ErrorBoundary from "../../../Utils/ErrorBoundary";
import FaceReconCard from "../FaceReconCard/FaceReconCard";
function ClientCard(props) {
    let { wireTransfer } = props
    let client = wireTransfer.client

    let address = formatAddress(client.address)

    return (
        <div className="analysisCard">
            <div style={{display:'flex'}}>
                <img style={{margin:"auto 8px auto 0px"}} src={people} alt="people Logo"/>
                <div className={["blueText", "subtitleSize"].join(" ")}>Cliente</div>
            </div>
            <div className={["blueText", "normalSmallSize"].join(" ")}>{(client || {}).id}</div>
            <div className="internalCardContainer" style={{display: "flex", marginTop:"15px"}}>
                <PersonTypeIcon personType={client.type}/>
                <div className={["subtitleSize"].join(" ")} style={{width:"70%"}}>
                    <div className="internalCardContainer"  style={{display: "flex", flexDirection: "column"}}>
                    {wireTransfer.face_recognition_key && (
                        <ErrorBoundary>
                        <FaceReconCard
                            name={"client_selfie"}
                            registration_key={wireTransfer.face_recognition_key}
                        />
                        </ErrorBoundary>
                    )}
                        <DataField
                            title={"Nome"}
                            label={(client || {}).name}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="5px"
                        />
                        <DataField
                            title={"CPF/CNPJ"}
                            label={(client || {}).document_number}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="5px"
                        />
                        <DataField
                            title={"Telefone"}
                            label={formatPhone((client || {}).phone, null)}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="5px"
                        />
                        <DataField
                            title={"Canal"}
                            label={getSalesChannel((client || {}).sales_channel)}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="5px"
                        />
                        <DataField
                            title={"Segmento"}
                            label={(client || {}).segment}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="5px"
                        />
                        {(client||{}).monthly_income&&
                        <DataField
                            title={"Renda Mensal:"}
                            label={formatAsMoney((client || {}).monthly_income)}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                        />}
                        {(client||{}).declared_assets&&
                        <DataField
                            title={"Bens Declarados:"}
                            label={formatAsMoney((client || {}).declared_assets)}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                        />}
                    </div>
                </div>
            </div>
            <div className="internalCardContainer">
                <ClientMap address={address}/>
            </div>
            <DataField
                title={"Endereço"}
                label={formatAddress((client || {}).address, null)}
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="5px"
            />
        </div>
    )
}

export default ClientCard
