import React from 'react'
import people from '../../../../assets/cardEntryIcons/people.svg';
import peopleGreen from '../../../../assets/cardEntryIcons/peopleGreen.svg';
import Tooltip from "@material-ui/core/Tooltip"
import Account from './Account/Account';
import PersonInformation from './PersonInformation/PersonInformation'

function EnvolvedParties(props) {
    let {wireTransfer} = props

    return (
        <div className="analysisCard">
            <div className="internalCardContainer" style={{display: "flex"}}>
                <img style={{margin:"auto 8px auto 0px"}} src={people} alt="people Logo"/>  
                <div className={["blueText", "subtitleSize"].join(" ")}>Partes Envolvidas</div>      
            </div>
            <div className="internalCardContainer" style={{marginTop:"15px"}}>
                <div style={{width:"100%", backgroundColor:"#DEF2FE", 
                            borderRadius:"12px", display:'flex', marginBottom:"20px"}}>
                    <div style={{width:"50%", padding:"20px"}}>
                        <div className={["blueText", "normalSize"].join(" ")} style={{display:"flex"}}>
                            PAGADOR
                            {wireTransfer.wire_transfer_direction === "sent"?
                            <Tooltip title={<p className="tooltipText">Cliente</p>}>
                                <div style={{display: "flex", margin: "auto auto auto 10px"}}>
                                    <img className="pinIconStyle" src={peopleGreen} alt="clientIcon Logo" />  
                                </div>
                            </Tooltip> 
                            :null}
                        </div>
                        <PersonInformation person={wireTransfer.source_account.owner}/>
                    </div>
                    <div style={{width:"50%", padding:"20px"}}>
                        <div className={["blueText", "normalSize"].join(" ")}>DADOS BANCÁRIOS</div>
                        <Account accountObject={wireTransfer.source_account}/>
                    </div>
                </div>
                <div style={{width:"100%", backgroundColor:"#DEF2FE", 
                            borderRadius:"12px", display:'flex', marginBottom:"20px"}}>
                    <div style={{width:"50%", padding:"20px"}}>
                        <div className={["blueText", "normalSize"].join(" ")} style={{display:"flex"}}>
                            RECEBEDOR
                            {wireTransfer.wire_transfer_direction === "received"?
                            <Tooltip title={<p className="tooltipText">Cliente</p>}>
                                <div style={{display: "flex", margin: "auto auto auto 10px"}}>
                                    <img className="pinIconStyle" src={peopleGreen} alt="clientIcon Logo" />  
                                </div>
                            </Tooltip> 
                            :null}
                        </div>
                        <PersonInformation person={wireTransfer.destination_account.owner}/>
                    </div>
                    <div style={{width:"50%", padding:"20px"}}>
                        <div className={["blueText", "normalSize"].join(" ")}>DADOS BANCÁRIOS</div>
                        <Account accountObject={wireTransfer.destination_account}/>
                    </div>
                </div>
                {/* <div style={{width:"50%"}}>
                    <div className={["blueText", "normalSize"].join(" ")} style={{display:"flex"}}>
                        PAGADOR
                        {wireTransfer.wire_transfer_direction === "sent"?
                        <Tooltip title={<p className="tooltipText">Cliente</p>}>
                            <div style={{display: "flex", margin: "auto auto auto 10px"}}>
                                <img className="pinIconStyle" src={peopleGreen} alt="clientIcon Logo" />  
                            </div>
                        </Tooltip> 
                        :null}
                    </div>
                    <PersonInformation person={wireTransfer.source_account.owner}/>
                    <div className={["blueText", "normalSize"].join(" ")}>Dados Bancários</div>
                    <Account accountObject={wireTransfer.source_account}/>
                </div>
                <div style={{width:"50%"}}>
                    <div className={["blueText", "normalSize"].join(" ")} style={{display:"flex"}}>
                        RECEBEDOR
                        {wireTransfer.wire_transfer_direction === "received"?
                        <Tooltip title={<p className="tooltipText">Cliente</p>}>
                            <div style={{display: "flex", margin: "auto auto auto 10px"}}>
                                <img className="pinIconStyle" src={peopleGreen} alt="clientIcon Logo" />  
                            </div>
                        </Tooltip> 
                        :null}
                    </div>
                    <PersonInformation person={wireTransfer.destination_account.owner}/>
                    <div className={["blueText", "normalSize"].join(" ")}>Dados Bancários</div>
                    <Account accountObject={wireTransfer.destination_account}/>
                </div> */}
            </div>
        </div>
    )
}

export default EnvolvedParties