import React from "react";
import { useTranslation } from "react-i18next";
import Collapse from "@material-ui/core/Collapse";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import IconButton from "@material-ui/core/IconButton";
import DoughnutChart from "../../../utils/Components/DoughnutChart";
import SerasaNegativeNotesCard from "../../../Cards/SerasaNegativeNotesCard/SerasaNegativeNotesCard";
import {
    colorPinkQIShadow,
    colorBlueQIShadow,
    colorBeige,
    getScoreInterpretation,
    titleCase
} from "../../../utils/utils";
import moment from "moment";
import NumberDescription from "../../../../@GeneralComponents/NumberDescription";
import AnalysisCardContainer from "../../../utils/Components/AnalysisCardContainer";
import DataField from "../../../utils/DataField";

function Serasa(props) {
    const { t } = useTranslation()

    let { serasa, expandedSerasa, setExpandedSerasa } = props

    let {
        riskscoring,
        name,
        document_number,
        document_status,
        foundation_date,
    } = serasa;

    let {
        score, 
        percentual_default_probability, 
        message, 
        risk_level, 
        suggested_market_practice
    } = riskscoring

    const handleExpand = (event) => {
        event.stopPropagation();
        setExpandedSerasa(!expandedSerasa);
    };

    let showRiskScoring = !!score

    return (
        <div className="analysisCard">
            <div style={{ display: "flex" }}>
                <div
                    className={["blueText", "subtitleSize", "bold"].join(" ")}
                    style={{ margin: "auto 0px" }}
                >
                    Serasa
                </div>
                <IconButton style={{ marginLeft: "auto" }} onClick={handleExpand}>
                    {expandedSerasa ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </IconButton>
            </div>
            <Collapse in={expandedSerasa} timeout="auto" unmountOnExit>
                <div style={{ display: "flex", flexDirection: "column", width: "100%", gap: "10px" }}>
                    <div style={{ display: "flex", width: "102%" }}>
                        <div style={{ display: "flex", flexDirection: "column", width: "98%" }}>
                            <div style={{ display: "grid", gridTemplateColumns: "50% 50%" }}>
                                <AnalysisCardContainer grow={true}>
                                    <div className="analysisCard">
                                        <div className="blueText subtitleSize" style={{ marginBottom: "10px" }}>{t("Dados básicos")}</div>
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            {name &&
                                                <DataField
                                                    title={t("name")}
                                                    label={titleCase(name, "-")}
                                                    size={"normalMediumSize"}
                                                    titleNoWrap={false}
                                                />}
                                            {document_number &&
                                                <DataField
                                                    title={t("document_number")}
                                                    label={document_number}
                                                    size={"normalMediumSize"}
                                                    titleNoWrap={false}
                                                />}
                                            {document_status &&
                                                <DataField
                                                    title={t("document_status")}
                                                    label={titleCase(t(document_status), "-")}
                                                    size={"normalMediumSize"}
                                                    titleNoWrap={false}
                                                />}
                                            {foundation_date &&
                                                <DataField
                                                    title={t("foundation_date")}
                                                    label={moment(foundation_date).format("DD/MM/YYYY")}
                                                    size={"normalMediumSize"}
                                                    titleNoWrap={false}
                                                />}
                                        </div>
                                    </div>
                                </AnalysisCardContainer>
                                {showRiskScoring && (
                                    <AnalysisCardContainer grow={true}>
                                        <div className="analysisCard">
                                            <div className="blueText subtitleSize" style={{ marginBottom: "10px" }}>{t("serasa_risk_scoring")}</div>
                                            <div style={{ display: "grid", gridTemplateColumns: "25% 25% 25% 25%" }}>
                                                <NumberDescription
                                                    description="/1000"
                                                    total={score}
                                                    secondDescription={t("serasa_score")}
                                                />
                                                <div id="serasaScore">
                                                    <DoughnutChart
                                                        cutout={0}
                                                        size='100px'
                                                        backgroundColor={[colorBeige, colorBlueQIShadow]}
                                                        dataValue={[1000 - score, score]}
                                                        chartTitle={t("serasa_score")}
                                                        id="serasa_score"
                                                    />
                                                </div>
                                                <NumberDescription
                                                    description={t("serasa_defaulted_probability")}
                                                    total={`${percentual_default_probability.toFixed(1).replace(".", ",")}%`}
                                                />
                                                <div id="serasaProbability">
                                                    <DoughnutChart
                                                        cutout={0}
                                                        size='100px'
                                                        backgroundColor={[colorBeige, colorPinkQIShadow]}
                                                        dataValue={[100 - percentual_default_probability, percentual_default_probability]}
                                                        chartTitle={t("serasa_defaulted_probability")}
                                                        id="serasa_defaulted_probability"
                                                    />
                                                </div>
                                            </div>
                                            <DataField
                                                title={t("score_interpretation")}
                                                label={message ? t(message) : t(getScoreInterpretation(score, "natural_person"))}
                                                size={"normalMediumSize"}
                                                titleNoWrap={false}
                                            />
                                            {risk_level &&
                                                <DataField
                                                    title={t("risk_level")}
                                                    label={t(risk_level)}
                                                    size={"normalMediumSize"}
                                                    titleNoWrap={false}
                                                />}
                                            {suggested_market_practice &&
                                                <DataField
                                                    title={t("suggested_market_practice")}
                                                    label={t(suggested_market_practice)}
                                                    size={"normalMediumSize"}
                                                    titleNoWrap={false}
                                                />}
                                        </div>
                                    </AnalysisCardContainer>
                                )}
                                {!showRiskScoring && (
                                    <AnalysisCardContainer grow={true}>
                                        <div className="analysisCard">
                                            <div className="blueText subtitleSize">{t("serasa_risk_scoring")}</div>
                                            <div className="alignCenter" style={{ margin: "40px auto" }}>
                                                <NumberDescription
                                                    description={t("serasa_score_not_available")}
                                                    secondDescription={((serasa || {}).riskscoring || {}).message ? serasa.riskscoring.message : ""}
                                                    total=""
                                                />
                                            </div>
                                        </div>
                                    </AnalysisCardContainer>
                                )}
                            </div>
                        </div>
                    </div>
                    {(serasa || {}).pefin ?
                        <div style={{ margin: '5px 10px' }}>
                            <SerasaNegativeNotesCard negative_notes_data={serasa.pefin} has_negative_note={serasa.pefin.has_pefin} negative_note_type={"Pefin"} />
                        </div>
                        : null}
                    {(serasa || {}).refin ?
                        <div style={{ margin: '5px 10px' }}>
                            <SerasaNegativeNotesCard negative_notes_data={serasa.refin} has_negative_note={serasa.refin.has_refin} negative_note_type={"Refin"} />
                        </div>
                        : null}
                    {(serasa || {}).dishonoured_check ?
                        <div style={{ margin: '5px 10px' }}>
                            <SerasaNegativeNotesCard negative_notes_data={serasa.dishonoured_check} has_negative_note={serasa.dishonoured_check.has_dishonoured_check} negative_note_type={t("dishonoured_check")} />
                        </div>
                        : null}
                    {(serasa || {}).overdue_debts ?
                        <div style={{ margin: '5px 10px' }}>
                            <SerasaNegativeNotesCard negative_notes_data={serasa.overdue_debts} has_negative_note={serasa.overdue_debts.has_overdue_debts} negative_note_type={t("overdue_debts")} />
                        </div>
                        : null}
                    {(serasa || {}).lawsuits ?
                        <div style={{ margin: '5px 10px' }}>
                            <SerasaNegativeNotesCard negative_notes_data={serasa.lawsuits} has_negative_note={serasa.lawsuits.has_lawsuits} negative_note_type={t("lawsuits")} />
                        </div>
                        : null}
                    {(serasa || {}).protests ?
                        <div style={{ margin: '5px 10px' }}>
                            <SerasaNegativeNotesCard negative_notes_data={serasa.protests} has_negative_note={serasa.protests.has_protests} negative_note_type={t("protests")} />
                        </div>
                        : null}
                    {(serasa || {}).bankruptcy ?
                        <div style={{ margin: '5px 10px' }}>
                            <SerasaNegativeNotesCard negative_notes_data={serasa.bankruptcy} has_negative_note={serasa.bankruptcy.has_bankruptcy} negative_note_type={t("bankruptcy")} />
                        </div>
                        : null}
                    {(serasa || {}).spc_mentions ?
                        <div style={{ margin: '5px 10px' }}>
                            <SerasaNegativeNotesCard negative_notes_data={serasa.spc_mentions} has_negative_note={serasa.spc_mentions.has_spc_mentions} negative_note_type={t("spc_mentions")} />
                        </div>
                        : null}
                </div>
            </Collapse>
        </div>
    );
}

export default Serasa
