import React, {useState} from "react";
import { Map, TileLayer, Marker } from "react-leaflet";
import L from "leaflet";
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { CircularProgress } from '@material-ui/core';

const provider = new OpenStreetMapProvider();

const markerIcon = new L.Icon({
    iconUrl: require("../../../../../assets/cardEntryIcons/MapMarker.svg"),
    iconSize: [25, 41],
    iconAnchor: [12, 41],
  });

function ClientMap(props){

    const [address, setAddress] = useState(null)

    if (address===null) {
        provider.search({ query: props.address }).then(function (result) {
            setAddress(result[0])
        }).catch(error => {
            setAddress(undefined)
        });
    }
    if (address === null) {
        return(
            <div style={{display: "flex", height: "192px"}}>
                <CircularProgress style={{margin:"auto"}}/>
            </div>
        )
    }
    else if (address === undefined){
        return (
            <div style={{display: "flex", height: "192px"}}>
                <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                    Mapa Indisponível
                </div>
            </div>
        )
    }
    else {
        return (
            <Map
                className="ciTransactionMerchant"
                center={[address.y, address.x]}
                zoom={14}
            >   
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
            <Marker
                position={[address.y, address.x]}
                icon={markerIcon}
            />
          </Map>
        )
    }

}

export default ClientMap