import React from "react";
import BoavistaSummaryDateAmountCard from "../BoavistaSummaryData/BoavistaSummaryDateAmount";
import BoavistaSummaryTotal from "../BoavistaSummaryData/BoavistaSummaryTotal";
import DoughnutChart from "../../../../utils/Components/DoughnutChart";
import { useTranslation } from "react-i18next";
import { colorBlueQIShadow, colorDarkBlueQIShadow, colorPinkQIShadow, colorBeige } from "../../../../utils/utils";

function BoavistaDebtsSummaryCard(props) {
    
    const { debts } = props
    const { t } = useTranslation()

    const isOldestAndHighestOccurrenceTheSameHandler = (debts) => {
        if (debts.oldest_occurrence_date===debts.highest_occurrence_date&&debts.oldest_occurrence_amount===debts.highest_occurrence_amount) {
            return true
        }
        else return false
    }
    const isOldestAndHighestOccurrenceTheSame=isOldestAndHighestOccurrenceTheSameHandler(debts)

    return (
        <div style={{display: "grid",gridTemplateColumns: "10% 14% 10% 14% 10% 14% 14% 14%",marginTop:"25px",marginBottom:"25px"}}>
            <BoavistaSummaryDateAmountCard
                description={"oldest_occurrence"}
                date={debts.oldest_occurrence_date}
                amount={debts.oldest_occurrence_amount}
            />
            {(debts||{}).total_amount&&(debts||{}).oldest_occurrence_amount&&
                <DoughnutChart
                    cutout={0}
                    size='100px'
                    backgroundColor={[colorBeige,colorBlueQIShadow]}
                    dataValue={[(debts.total_amount-debts.oldest_occurrence_amount),debts.oldest_occurrence_amount]}
                    chartTitle={t("oldest_occurrence_amount")}
                    id="oldest_occurrence_amount"
                />}
            <BoavistaSummaryDateAmountCard
                description={"highest_occurrence"}
                date={debts.highest_occurrence_date}
                amount={debts.highest_occurrence_amount}
            />
            {(debts||{}).total_amount&&(debts||{}).highest_occurrence_amount&&isOldestAndHighestOccurrenceTheSame?
                <DoughnutChart
                    cutout={0}
                    size='100px'
                    backgroundColor={[colorBeige,colorBlueQIShadow]}
                    dataValue={[(debts.total_amount-debts.highest_occurrence_amount),debts.highest_occurrence_amount]}
                    chartTitle={t("highest_occurrence_amount")}
                    id="highest_occurrence_amount"
                />
                :
                <DoughnutChart
                    cutout={0}
                    size='100px'
                    backgroundColor={[colorPinkQIShadow,colorBeige]}
                    dataValue={[debts.highest_occurrence_amount, (debts.total_amount-debts.highest_occurrence_amount)]}
                    chartTitle={t("highest_occurrence_amount")}
                    id="highest_occurrence_amount"
                />}
            <BoavistaSummaryTotal
                description={"total_amount"}
                total={debts.total_amount}
            />
            {(debts||{}).total_amount&&(debts||{}).highest_occurrence_amount&&isOldestAndHighestOccurrenceTheSame?
                <DoughnutChart
                    cutout={0}
                    size='100px'
                    backgroundColor={[colorDarkBlueQIShadow,colorBlueQIShadow]}
                    dataValue={[(debts.total_amount-debts.highest_occurrence_amount),debts.highest_occurrence_amount]}
                    chartTitle={t("total_amount")}
                    id="total_amount"
                />
                :
                <DoughnutChart
                    cutout={0}
                    size='100px'
                    backgroundColor={[colorPinkQIShadow,colorDarkBlueQIShadow,colorBlueQIShadow]}
                    dataValue={[debts.highest_occurrence_amount,(debts.total_amount-debts.highest_occurrence_amount-debts.oldest_occurrence_amount),debts.oldest_occurrence_amount]}
                    chartTitle={t("total_amount")}
                    id="total_amount"
                />}
            <BoavistaSummaryTotal
                description={"total_number_of_events"}
                total={debts.total_number_of_events}
            />
            <BoavistaSummaryTotal
                description={"total_number_of_guarantors"}
                total={debts.total_number_of_guarantors}
            />
        </div>
    );
}

export default BoavistaDebtsSummaryCard
                            