import React,{useContext} from "react";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import moment from 'moment'
import {getBankslipStatusIcon, getReasonDescription } from '../../utils/utilsBankslip'
import {formatAsMoney, getAnalysisStatusColor, 
        getAnalysisStatusSiglum, getAnalysisStatus} from "../../utils/utils"
import Tooltip from "@material-ui/core/Tooltip";
import AuthContext from "../../../../context/auth-context";

function BankslipCard(props){

    let is_fraud = (props.bankslip.fraud_status === "fraud")

    let {user_data} = useContext(AuthContext)

    return (
        <Card className="listCard">
            <Link
                className={
                    is_fraud ? 
                    ["listCardItem", "linkRed", "subtitleSize", "breakWord", "bold"].join(" ")
                    :
                    ["listCardItem", "link", "subtitleSize", "breakWord", "bold"].join(" ")}
                style={{flex:"1 1 110px", textAlign:"center"}}
                to={"/bankslip/" + props.bankslip.bankslip_key}
            >
                {is_fraud ?
                    <Tooltip title={<p className="tooltipText">Transação reportada como fraude</p>}>
                        <div style={{display:"flex", justifyContent: "center", alignItems: "center"}}>
                            {props.bankslip.id}
                        </div>
                    </Tooltip> :
                    <div>
                        {props.bankslip.id}
                    </div>
                    }
            </Link>
            {user_data.business_group_key &&
            <div 
                className={["listCardItem", "normalText"].join(" ")}
                style={{flex:"1 1 80px", textAlign:"center"}}
            >
                {props.bankslip.company_name}
            </div>}
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")} 
                style={{flex:"1 1 90px", textAlign:"center"}}  
            >
                {formatAsMoney(props.bankslip.amount, "-")}
            </div>
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                style={{flex:"1 1 130px", textAlign:"center"}}
            >
                {(props.bankslip.payer || {}).document_number || "-"}
            </div>
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")} 
                style={{flex:"1 1 130px", textAlign:"center"}}  
            >
                {(props.bankslip.recipient || {}).document_number || "-"}
            </div>
            <div 
                className={["listCardItem", "normalText", "normalMediumSize", "bold"].join(" ")} 
                style={{flex:"1 1 45px", textAlign:"center", cursor: "default"}}  
            >
                <Tooltip title={<p className="tooltipText" style={{textAlign: "center"}}>
                    {getAnalysisStatus(props.bankslip.analysis_status)}
                    <br/>{getReasonDescription(props.bankslip)}
                </p>}>
                <div className={["circle", "small"].join(" ")} style={{backgroundColor:getAnalysisStatusColor(props.bankslip.analysis_status)}}>
                    {getAnalysisStatusSiglum(props.bankslip.analysis_status)}
                </div>
                </Tooltip>
            </div>
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                style={{flex:"1 1 110px", textAlign:"center"}}
            >
                {moment(props.bankslip.bankslip_payment_date).format("DD/MM/YYYY HH:mm:ss")}
            </div>
            <div 
                className={["listCardItem", "normalText", "normalMediumSize", "bold"].join(" ")}  
                style={{flex:"1 1 35px", textAlign:"center", cursor: "default"}}  
            >
                {getBankslipStatusIcon(props.bankslip.bankslip_status, "small", true)}
            </div>
        </Card>
    )
}

export default BankslipCard