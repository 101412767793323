import React, {useContext} from "react";
import FilterBox from "../../../Utils/FilterBox/FilterBox"
import { getBranchesInArrayFormat } from "../../utils/utils";
import CompaniesContext from "../../../../context/companies-context"

function Filter(props) {
	
    const { setPageParams } = props

    const enumISPBList = getBranchesInArrayFormat()

    const companies= useContext(CompaniesContext)?.pix_companies?.companies

    let filterTypes = {
        document_number: {
            type: "brazilian_document_numbers",
            disabled: false,
            validation:true,
            validation_rule: {type: "multiple_equal", values:[14,18]},
            description: "CPF/CNPJ"
        },
        transaction_date: {
            type: "date",
            disabled: false,
            validation:false,
            validation_rule: null,
            description: "Data"
        },
        lower_amount_limit: {
            type: "value",
            disabled: false,
            validation:false,
            validation_rule: null,
            description: "Valor Mínimo"
        },
        upper_amount_limit: {
            type: "value",
            disabled: false,
            validation:false,
            validation_rule: null,
            description: "Valor Máximo"
        },
        external_id: {
            type: "string",
            disabled: false,
            validation:false,
            validation_rule: null,
            description: "ID da Transação"
          },
        dict_key_value: {
            type: "string",
            disabled: false,
            validation:false,
            validation_rule: null,
            description: "Chave DICT"
        },
        source_account_participant: {
            type: "enum",
            enumList: enumISPBList,
            disabled: false,
            validation:false,
            validation_rule: null,
            description: "Instituição de Origem"
        },
        destination_account_participant: {
            type: "enum",
            enumList: enumISPBList,
            disabled: false,
            validation:false,
            validation_rule: null,
            description: "Instituição de Destino"
        },
        transaction_status: {
            type: "enum",
            enumList: [
                ["Esperando Processamento", "created"],
                ["Recebida do BC", "received"],
                ["Enviada ao BC", "sent"]
            ],
            disabled: false,
            validation:false,
            validation_rule: null,
            description: "Status da Transação"
          },
        analysis_status: {
            type: "enum",
            enumList: [
                ["Pendente", "pending"],
                ["Aprovado Manualmente", "manually_approved"],
                ["Reprovado Manualmente", "manually_reproved"],
                ["Aprovado Automaticamente", "automatically_approved"],
                ["Reprovado Automaticamente", "automatically_reproved"],
                ["Aprovado por tempo", "approved_by_time"],
                ["Reprovado por tempo", "reproved_by_time"]
            ],
            disabled: false,
            validation:false,
            description: "Status da Análise"
        },
        fraud_status: {
            type: "enum",
            enumList: [
                ["Reportada como fraude", "fraud"],
                ["Não Reportada como fraude", "created"],                
            ],
            disabled: false,
            validation:false,
            description: "Status de Fraude"
        },
        order_by: {
            type: "enum",
            enumList: [
                  ["CPF/CNPJ","document_number"],
                  ["Data", "transaction_date"],
                  ["Valor", "amount"],
                  ["Instituição de Origem", "source_account_participant"],
                  ["Instituição de Destino", "destination_account_participant"]
              ],
            description: "Ordenação por"
          },
        sort_order: {
            type: "enum",
            enumList: [
                ["Ascendente", "asc"],
                ["Descendente", "desc"]
            ],
            description: "Tipo de Ordenação"
        }
    }

    if (companies){
        let enumList = companies
                            .sort((a, b) => a.name === b.name ? 0 : a.name > b.name ? 1 : -1)
                            .map(company =>{return [company.name, company.company_key]})

        filterTypes['company_key'] = {
            type: "enum",
            enumList: [
                ...enumList
            ],  
            disabled: false,
            validation:false,
            description: "Cliente"
        }
    }

    return (
        <FilterBox 
            setPageParams={setPageParams}
            filterTypes={filterTypes}
            local_storage_object_preffix={props.local_storage_object_preffix}
        />
    )
}

export default Filter