import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { useTranslation } from "react-i18next";
import { getConfirmationIcon } from "../../../utils/utils";
import Button from "../../../utils/Components/Button";

function RequestConfirmationDialog (props) {

    const { open, formSubmissionStatus, onClose } = props;

    const { t } = useTranslation()

    return(
        <Dialog
            open={open} 
            onClose={onClose}
            keepMounted={false}
        >           
            <DialogContent >
            <div style={{display: "flex",flexDirection: "column",alignContent: "center",flexGrow: "1", margin: "10px"}}>
                <div style={{ margin: "auto" }}>
                    {getConfirmationIcon(formSubmissionStatus.success)}
                </div>
                <div style={{ margin: "auto" }}>{formSubmissionStatus.message}</div>
                <Button
                    button_option={"standard"}
                    onClick={onClose}
                    buttonLabel={t("go_back_action")}
                />
            </div>
            </DialogContent>        
        </Dialog>
    )
}

export default RequestConfirmationDialog;