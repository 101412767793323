import React from "react";
import {getAlertsFlagIcon} from "./utils"
import Tooltip from "@material-ui/core/Tooltip"

function DataField(props) {

    if (props.label == null) return null

    let displayType = "inline"
    let marginSize= "5px"
    let titleClasses = ["normalText", props.size]
    let labelClasses = ["labelText", props.size, "labelSpace"]
    let labelStyles = {verticalAlign: "middle"}
    let titleStyles = {verticalAlign: "middle"}

    if (props.titleNoWrap){
        titleClasses.push("noWrap")
    }
    if (props.labelNoWrap){
        labelClasses.push("noWrap")
    }

    if (props.disabled){
        titleClasses.push("disabled")
        labelClasses.push("disabled")
    }

    if (props.margin){
        marginSize=props.margin
    }

    if (props.display){
        displayType=props.display
    }

    if (props.labelStyles){
        labelStyles={...labelStyles, ...props.labelStyles}
    }

    if (props.titleStyles){
        titleStyles={...titleStyles, ...props.titleStyles}
    }

    if (props.labelClasses){
        labelClasses=[...labelClasses, ...props.labelClasses]
    }

    if (props.titleClasses){
        titleClasses=[...titleClasses, ...props.titleClasses]
    }

  return (
    <div style={{display: displayType, marginTop: marginSize, marginBottom: marginSize, lineHeight: "20px"}}>
        <span className={titleClasses.join(" ")} style={titleStyles}>
            {props.title}
        </span>
        <span className={labelClasses.join(" ")} style={labelStyles}>
            {props.label}
        </span>
        {props.check == null ? null : 
        <Tooltip title={props.tooltipText == null ? "" : <p className="tooltipText">{props.tooltipText}</p>}>
            {getAlertsFlagIcon(props.check, "5px")}
        </Tooltip>}
        {props.children}        
    </div>
  );
}

export default DataField;