import React from "react";
import { Link } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

function CurrentAnalystDialog(props) {
    return (
        <Dialog 
            open={props.open} 
            onClose={props.onClose} 
            keepMounted={false}     
            disableBackdropClick
        >
            <DialogTitle 
                className={['blueText', "subtitleSize"].join(" ")} 
                onClose={props.onClose}
            >
                Analista já atribuído
            </DialogTitle>
            <DialogContent>
                <div 
                    style={{
                        width:"366px", height: "160px", 
                        display:"flex", flexDirection:"column", 
                        marginBottom:"30px", alignItems:"center"
                    }}>
                    <div 
                        className={["internalCardContainer", "normalText", "normalMediumSize"].join(" ")} 
                        style={{width:"90%", textAlign:"center"}}>
                        O analista {props.currentAnalyst.analyst_name} está analisando essa reserva. {"\n"} Você deseja mesmo continuar?
                    </div>
                    <Link 
                        className={["button", "standard"].join(" ")} 
                        style={{width:"30%", marginBottom:"30px", marginTop: "auto", textDecoration: "none"}}
                        to={"/reservation/" + props.reservation_key}
                    >
                        Continuar
                    </Link>
                </div>                
            </DialogContent>
        </Dialog>
    )
}

export default CurrentAnalystDialog;