import React, { useEffect, useReducer, useCallback, useContext, useState } from "react";
import {
  dataFetchReducer,
  useStateConnectedToCache,
} from "../../Utils/requestUtils";
import Filter from "./Filter/Filter";
import EventGroupFilter from "./EventGroupFilter/EventGroupFilter"
import Pagination from "@material-ui/lab/Pagination";
import { CircularProgress } from "@material-ui/core";
import axios from "axios";
import AlertCard from "./AlertCard/AlertCard";
import ErrorBoundary from "../../Utils/ErrorBoundary";
import { getFilterGroupOptions, 
    setAlertsInSessionStorage, 
    setGetAlertsParamsInSessionStorage } from "../utils/utilsAlert" 
import AuthContext from "../../../context/auth-context";
import { useHistory } from "react-router-dom";
import CardListHeader from "./CardListHeader/CardListHeader";
import ExportCSV from "../../Utils/ExportCSV/ExportCSV"

function AlertsInbox(props) {
    sessionStorage.removeItem("alertsArray") 
    sessionStorage.removeItem("alertsParams") 
    sessionStorage.removeItem("alertsNumberOfPages") 

    let history = useHistory();

    let roles = useContext(AuthContext).user_data.roles;
    if (!(['read_banking_alerts', 'read_currency_exchange_alerts'].some(role => roles.includes(role)))) {
        history.push("");
    }

    let filterGroupOptions = getFilterGroupOptions(roles)
    let local_storage_object_preffix = "alerts";
    let presetEventGroup = filterGroupOptions[0]

    const [pageParams, setPageParams] = useStateConnectedToCache(
        local_storage_object_preffix
    );

    if (pageParams.cachedEventGroup !==undefined){
        presetEventGroup = pageParams.cachedEventGroup
    }
    
    const [eventGroup, setEventGroup] = useState(presetEventGroup)

    const [pageState, dispatchPageState] = useReducer(dataFetchReducer, {
        fetchedData: null,
        isLoading: true,
        isError: false,
    });

    const [paramsObj, setParamsObj] = useState() 

    const doRequest = useCallback(
        (payload) => {
            dispatchPageState({type: "data_fetch_init"})
            axios.get("/dash/alerts/alerts", {
                params: payload
            }).then(response=>{
                dispatchPageState({
                    type: "data_fetch_success",
                    payload: response.data
                })
            }).catch(error => {
                if ((error.response || {}).status === 403) dispatchPageState({type: "data_fetch_failure_403"})
                else if ((error.response || {}).status === 404) dispatchPageState({type: "data_fetch_failure_404"})  
                else dispatchPageState({type: "data_fetch_failure"})    
            })
        },[]
    )

    useEffect(() => {
        let payload = {
            "page_number": pageParams.page - 1,
            "page_rows": 25,
            "event_group": eventGroup===undefined?'invalid_event_group':eventGroup
        }
        Object.keys(pageParams.filters).forEach((key,index) => {
            payload[key] = pageParams.filters[key]
        })
        const timer_ = setTimeout(() => {
            doRequest(payload)
            setParamsObj({...payload}) 
        }, 300);
        return () => {
            clearTimeout(timer_)
		}	
    },[doRequest, pageParams, eventGroup])

    const handleChangePage = (event, page, filters) => {
		if (pageState.page === page) {
			return
		}
		setPageParams({page: page, filters: filters})
	}

    const handleFilterGroupChange = (e) => {
        let key = e.target.value
        setEventGroup(key)
        setPageParams(
            {
                ...pageParams,
                cachedEventGroup: key
            }
        )
    }


    const contentContainer = (dataList) => {

		let nr_of_cards = dataList['data'].length
		let cards = dataList['data'].map((alert) => (
			<ErrorBoundary key={alert.alert_key}>
				<AlertCard eventGroup={eventGroup} alert={alert}/>
			</ErrorBoundary>
		))

		if (nr_of_cards === 0) {
			return (
			<div className='emptyContainer'>
				<p className={["blueText", "subtitleSize"].join(" ")}>
					Nada a ser analisado
				</p>
			</div>
			)
		}

		else {
            return (
                <div className='cardContainer'>
                    <CardListHeader />
                    {cards}
                </div>
            )
		}
	}

    if (pageState.fetchedData) {

    setAlertsInSessionStorage(pageState.fetchedData['data']) 
    setGetAlertsParamsInSessionStorage(paramsObj, pageState.fetchedData["number_of_pages"]) 
    
		return (
			<div style={{ width: "auto", paddingBottom: "30px" }}>
				<div style={{display:"flex"}}>
					<div >
						<p className={["blueText", "titleSize"].join(" ")}>ALERTAS</p>
					</div>
                    <div style={{margin: "auto 20px auto auto"}}>
                    {roles.includes("read_csv_banking_alerts") &&
                        <ExportCSV 
                            pageParams={pageParams} 
                            csv_endpoint='/dash/alerts/alerts/csv'
                            file_name = "Alerts"
                            is_able ={true}/> }
                    </div>		
				</div>				
				<ErrorBoundary doNothing>
                    <div style={{display:"flex"}}>
                        <EventGroupFilter 
                            handleFilterGroupChange={handleFilterGroupChange} 
                            filterGroupOptions={filterGroupOptions}
                            initialGroup={eventGroup}
                        />
                        <Filter 
                            local_storage_object_preffix={local_storage_object_preffix} 
                            setPageParams={setPageParams}
                            eventGroup={eventGroup}
                        />
                    </div>
                </ErrorBoundary>
				<div>{contentContainer(pageState.fetchedData)}</div>
				<div>
                    <Pagination 
                        className="pagination" 
                        page={pageParams.page} 
                        count={parseInt(pageState.fetchedData["number_of_pages"])}
                        onChange={(e, page) => handleChangePage(e, page, pageParams.filters)}
                    />
				</div>
			</div>
		);
	}
	else if (pageState.isError){
		return (
			<div style={{ width: "auto" }}>
				<div style={{display:"flex"}}>
					<div >
						<p className={["blueText", "titleSize"].join(" ")}>ALERTAS</p>
					</div>		
				</div>
                <div style={{height: "55vh", display:"flex"}}>
                    <p className={["blueText", "titleSize"].join(" ")} style={{margin:"auto", textAlign:"center"}}>
                        {pageState.errorMessage}
                    </p>
                </div>
			</div>
		)
	}
	else if (pageState.isLoading) {
		return(
			<div style={{ width: "auto" }}>
				<p className={["blueText", "titleSize"].join(" ")}>ALERTAS</p>
				<div className='circularProgressCenter'>
					<CircularProgress />
				</div>
			</div>
		)
    }
    else return null
}

export default AlertsInbox;