import React, { useState } from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

function MultiSelectDropdown (props) {

  const handleChangePermission = (role, status) => {
    if (status === true) {
      handleAddPermission(props.user, role.name);
      if (props.permissionsToRemove.roles.includes(role.name)) {
        props.setPermissionsToRemove({roles: props.permissionsToRemove.roles.filter((indexedRole) => (indexedRole !== role.name)), company_key: props.user.company_key})
      } else {
        props.setPermissionsToAdd({roles: [...props.permissionsToAdd.roles, role.name], company_key: props.user.company_key})
      }
    } else {
      handleRemovePermission(props.user, role.name);
      if (props.permissionsToAdd.roles.includes(role.name)) {
        props.setPermissionsToAdd({roles: props.permissionsToAdd.roles.filter((indexedRole) => (indexedRole !== role.name)), company_key: props.user.company_key})
      } else {
        props.setPermissionsToRemove({roles: [...props.permissionsToRemove.roles, role.name], company_key: props.user.company_key})
      }
    }
  };

  const handleAddPermission = (user, role) => {
    let payload = { roles: [role] };
    if (user.company_key) payload["company_key"] = user.company_key;
  };

  const handleRemovePermission = (user, role) => {
    let payload = { roles: [role] };
    if (user.company_key) payload["company_key"] = user.company_key;
  };

  const { options, toggleOption, selectedRulesViewer } = props

  const [expanded, setExpanded] = useState(false)

  return (
    <div style={{display:"flex", flexDirection: "column", width: "100%"}}>
      <div 
        className="multiSelectDropdownSelected"
        onClick={()=>(setExpanded(!expanded))}
      >
          <div>{selectedRulesViewer.length} selected</div>
          {expanded?
          <ArrowDropUpIcon style={{color:"#878787", width:"18px",  height:"18px"}}/>:
          <ArrowDropDownIcon style={{color:"#878787", width:"18px",  height:"18px"}}/>}
      </div>
      {expanded&&
      <div>
        <ul className="multiSelectDropdownOptions">
            {options
            .map(option => {
              const isSelected = selectedRulesViewer.includes(option.name)
              return(
                <li key={option.description} className="multiSelectDropdownOption" onClick={() => {toggleOption(option); handleChangePermission(option, !isSelected);}}>
                    <input
                      type="checkbox"
                      checked={isSelected}
                      className="multiSelectDropdownCheckbox"
                      onChange={() => {}} 
                    ></input>
                    <span>{`${option.description}`}</span>
                </li>
              )
            })}
        </ul>
      </div>}
    </div>
  )
}

export default MultiSelectDropdown;