//Importing public modules
import React from 'react';
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from '@material-ui/icons/Clear';
import whiteChipIcon from '../../../assets/cardEntryIcons/whiteChip.svg';



//Format card number to appear on order card in ordersinbox
export const formatCard = (bin, last4) => {
    bin = bin == null ? "" : bin.toString()
    last4 = last4 == null ? "" : last4.toString()
    let size1 = bin.length
    let size2 = last4.length
    let missing= 16 - size1 -size2
    let a = "*".repeat(missing)
    let cardNumber = bin + a + last4
    return cardNumber.replace(/\S{4}/g, '$& ')
}

const colorGreen = '#73C580'

const colorRed ='#D91A1A'

//Create card ilustration
export const getCard = (card) => {
    return (
      <div className="blackCard">
        <img src={whiteChipIcon} className="brandStyleBlackCard" alt="chip Logo"/>
        <div className={["cardNumberStyleBlackCard", "normalTextWhite", "normalSmallSize"].join(" ")} style={{letterSpacing: 1.5}}>
            {`${card.bin}••••••${card.last_four_digits}`}
        </div>
        <div className={["validDateStyleBlackCard", "normalTextWhite", "normalSmallSize"].join(" ")} style={{letterSpacing: 1.5}}>
            {card.expiration_date == null ? null : card.expiration_date}
        </div>
      </div>
    )
}


//Check if nested key exists inside object
export function checkNested (obj /*, level1, level2, ... levelN*/) {
  if (obj == null) return false
  var args = Array.prototype.slice.call(arguments, 1);

  for (var i = 0; i < args.length; i++) {
      if (!obj || !obj.hasOwnProperty(args[i])) {
      return false;
      }
      obj = obj[args[i]];
  }
  return true;
}


//Get dialog check icon
export const getConfirmationIconDialog = (success) => {
  if (success){
      return (<CheckIcon className="fraudStatusIcon" style={{color: colorGreen, width:"100px", height:"100px"}}/>)
  }
  else{
      return (<ClearIcon className="fraudStatusIcon" style={{color: colorRed, width:"100px", height:"100px"}}/>)
  }
}

//Get field check icon
export const getConfirmationIconField = (success) => {
  if (success){
      return (<CheckIcon className="fraudStatusIcon" style={{color: colorGreen, width:"20px", height:"20px", margin:"auto 0 15px 0"}}/>)
  }
  else{
      return (<ClearIcon className="fraudStatusIcon" style={{color: colorRed, width:"20px", height:"20px", margin:"auto 0 15px 0"}}/>)
  }
}