import React, {useState, useEffect, useContext} from 'react'
import { useTranslation } from "react-i18next";
import {setObjectByPath, GetAlertsComponent} from "./utils"
import TextField from "@material-ui/core/TextField";
import MenuItem from '@material-ui/core/MenuItem';
import _ from "lodash"
import IconButton from '@material-ui/core/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import RulesContext from "../../../../context/rules-context";
import { translateType } from './utils';

export function MachineLearningBox (props) {
    let {node} = props
    let {read_only} = props
    let {handleConfigurationOpen} = props
    let {handleNodeRemove} = props

    let settingsList = useContext(RulesContext).boxes[node.node.type].settings
    let selectedModelSettings = settingsList.filter(settings => settings.model_hash === node.node.properties.model_hash)

    return (
        <div>
            {!read_only ?
                <div style={{display:"flex", alignItems: "center"}}>
                    <div style={{display:"flex", width:"100%", cursor:"pointer", margin: "auto 0px"}} onClick={() => handleConfigurationOpen(node)}>
                        {((selectedModelSettings||[])[0]||{}).model_name? selectedModelSettings[0].model_name:""}
                        {(node.node.alerts || []).length > 0?
                        <GetAlertsComponent alerts={node.node.alerts} />  : null} 
                    </div>
                    <IconButton style={{width: 32, height: 32}} aria-label="duplicate" onClick={() => props.handleNodeDuplication(node)}>
                        <ContentCopyIcon style={{width: 20, height: 20}} />
                    </IconButton>
                    <IconButton style={{width: 32, height: 32}} aria-label="close" onClick={() => handleNodeRemove(node)}>
                        <CloseIcon style={{width: 20, height: 20}} />
                    </IconButton>
                </div>
                :
                <div style={{display:"flex"}}>
                    <div style={{display:"flex", width:"100%", cursor:"pointer", margin: "auto 0px"}} onClick={() => handleConfigurationOpen(node)}>
                        {((selectedModelSettings || [])[0]||{}).model_name?selectedModelSettings[0].model_name:""}
                    </div>
                </div>
            }
        </div>
    )
}

export function MachineLearningConfiguration(props){
    const { t } = useTranslation();
    let {node} = props
    let {read_only} = props
    let {handleNodeChange} = props
    let {handleConfigurationClose} = props

    let settingsList = useContext(RulesContext).boxes[node.node.type].settings

    const [nodeState, setNodeState] = useState(node)

    useEffect(()=>{
        setNodeState(node)
    },[node])

    const handleEventChange = (e) => {
        let new_node = _.cloneDeep(nodeState)
        setObjectByPath(new_node.node, "properties.model_hash", e.target.value)
        setNodeState(new_node)
    }

    const handleSave = () => {
        handleConfigurationClose()
        handleNodeChange(nodeState)
    }

    const validateAllValues = () => {
        return (
            (nodeState.node.properties.model_hash && nodeState.node.properties.model_hash.length >0)
        )
    }

    return(
        <div style={{flexGrow:"1", padding: "20px", display:"flex", flexDirection:"column"}}>
            <div style={{display: "flex"}}>
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>{t(node.node.description)}</span>
                <Tooltip title={<div className="tooltipText"><ul>{node.node.helperText.map((help,index) => (<li key={index}>{t(help)}</li>))}</ul></div>}>
                    <HelpIcon style={{fontSize: "14px", display:"flex", margin:"auto 5px"}}/>
                </Tooltip>
            </div>
            <div className="internalCardContainer" style={{display:"flex", flexDirection: "column"}}>
            <TextField 
                label={t("Variável")}
                style={{textAlign:"center", width:"80%", margin:"3px auto"}}
                className="filterTextbox" 
                size="small" fullWidth 
                value={nodeState.node.properties.model_hash}
                onChange={handleEventChange}
                InputProps={{
                    readOnly: read_only
                }}
                select
            >
                {settingsList.map((settings) => (
                    <MenuItem key={`menu-item-${settings.model_hash}`} value={settings.model_hash}><em className="normalText">{`${settings.model_name} (${t(translateType(settings.type))})`}</em></MenuItem>
                ))}
            </TextField>    
            </div>
                <div style={{display:"flex", margin: "auto auto 5px auto"}}>
                    <div
                        className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")}
                        style={{width:"120px"}}
                        onClick={handleConfigurationClose}
                    >
                        {read_only ? "X" : t("discard")}
                    </div>
                    {!read_only && 
                    <div
                        className={
                            validateAllValues()
                            ?
                            ["button", "standard", "normalText", "normalMediumSize"].join(" ")
                            :
                            ["button", "standard", "normalText", "normalMediumSize","disabled"].join(" ")}
                        style={{width:"120px"}}
                        onClick={validateAllValues() ? handleSave : null}
                    >
                        {t("save")}
                    </div>}   
                </div>
        </div>
    )
}

export class MachineLearningBoxClass {
    constructor(rulesContext){
        this.rulesContext = rulesContext
    }
    validate(node){
        let put_error = false
        let alerts = []

        let settingsList =  this.rulesContext.boxes[node.node.type].settings
        let modelHashs = []
        for (let settings of settingsList) {
            modelHashs.push(settings.model_hash)
        }

        if((node.node.children || []).length !== 0){
            put_error = true
            alerts.push("Este nó não deve possuir filhos")
        }
        if (!modelHashs.includes(node.node.properties.model_hash)){
            put_error = true
            alerts.push("Clique no nó deste Variáveis do Processador, abra a configuração e escolha uma opção válida")
        }
        //Validate if parent is valid
        if (node.parentNode != null) {
            if(node.parentNode.on_error){
                put_error = true
                alerts.push("O nó superior está inválida")
            }
        }
        return {validated: !put_error, alerts: alerts}
    }

    getType(node){
        let settingsList = this.rulesContext.boxes.machine_learning_box.settings
        let selectedModelSettings = settingsList.filter(settings => settings.model_hash === node.properties.model_hash)
        return ((selectedModelSettings||[])[0]||{}).type?((selectedModelSettings||[])[0]||{}).type:""
    }
}