import React from 'react'
import peopleIcon from '../../../../assets/cardEntryIcons/people.svg';
import merchantIcon from '../../../../assets/cardEntryIcons/MerchantIcon.svg';
import emailIcon from '../../../../assets/cardEntryIcons/emailBlue.svg';
import clockIcon from '../../../../assets/cardEntryIcons/Clock.svg';
import phoneIconBlue from '../../../../assets/cardEntryIcons/phoneBlue.svg';
import mapMarkerIcon from '../../../../assets/cardEntryIcons/MapMarker.svg';
import DataField from "../../utils/DataField"
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {formatPhone, formatAddress, titleCase, clientAge, checkNested} from "../../utils/utils"

function UserCard(props) {
    let alert_generator = {}

    switch(props.alert.link_key_type){
        case "customer":
            alert_generator.data = props.alert.link_key_data.customer
            alert_generator.icon = peopleIcon
            alert_generator.type = "Comprador"
            break
        case "seller":
            alert_generator.data = props.alert.link_key_data.seller
            alert_generator.icon = merchantIcon
            alert_generator.type = "Vendedor"
            break
        default:
            throw new Error()
    }

    return (
        <div className="analysisCard">
            <div style={{display: "flex"}}>
                <img className="cardTitleIconStyle" src={alert_generator.icon} alt="Icon Logo" />
                <span className={["blueText", "subtitleSize", "labelSpace", "breakWord"].join(" ")}>{[alert_generator.type, "| Dados"].join(" ")}</span>
            </div>
            <div className="internalCardContainer" style={{display: "flex", flexDirection: "column"}}>
                <div style={{display: "inline", marginTop: "5px", marginBottom: "5px", marginLeft:"0px", lineHeight: "20px", fontSize: "22px"}}>
                    {titleCase(alert_generator.data.name, "-")}
                    {alert_generator.url ? <a href={alert_generator.url} style={{color:"#0b1f82", verticalAlign: "-0.2em", marginLeft: "10px"}}><ExitToAppIcon/></a> : null} 
                </div>
                {(alert_generator.data.document_number) ? 
                <div style={{display: "inline", marginTop: "5px", marginBottom: "5px", marginLeft:"0px", lineHeight: "20px"}}>
                    <span className={["labelText", props.size, "labelSpace"].join(" ")} style={{verticalAlign: "middle", marginLeft:"0px"}}>
                        {alert_generator.data.document_number || "-"}
                    </span>
                </div> : null}
                {alert_generator.data.birthdate ?
                <DataField
                    title={"Data de Nascimento"}
                    label={clientAge(alert_generator.data.birthdate, "-")}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    display={"inline"}
                /> : null}
                {alert_generator.data.registration_date ?
                <DataField
                    titleImage={clockIcon}
                    titleTooltipText="Data de Registro"
                    alt={"clockIcon Logo"}
                    label={clientAge(alert_generator.data.registration_date, 
                                     "-", 
                                     "months_days",
                                     checkNested(props.alert,"link_key_data","order_date") ? props.alert.link_key_data.order_date : null)}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    display={"inline"}
                /> : null}
                {(alert_generator.data.address) ? 
                <DataField
                    titleImage={mapMarkerIcon}
                    titleTooltipText="Endereço"
                    alt={"mapMarkerIcon Logo"}
                    label={formatAddress(alert_generator.data.address) || "-"}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    display={"inline"}
                > 
                </DataField>: null}
                {(alert_generator.data.phone) ? 
                <DataField
                    titleImage={phoneIconBlue}
                    titleTooltipText="Telefone"
                    alt={"phoneIconBlue Logo"}
                    label={formatPhone(alert_generator.data.phone, "-")}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    display={"inline"}
                /> : null} 
                {(alert_generator.data.email) ? 
                <DataField
                    titleImage={emailIcon}
                    titleTooltipText="Email"
                    alt={"emailIcon Logo"}
                    label={alert_generator.data.email || "-"}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    display={"inline"}
                /> : null}
            </div>
        </div>
    )
}

export default UserCard