import styled from 'styled-components';

export const Title = styled.p`
    font-family: "Open Sans";
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    color: #0b1f82;
    margin-top: 8px;
`;

export const AddCardButton = styled.div`
    padding: 12px 20px 12px 20px;
    background-color: ${(props) => props.gray ? '#858585' : '#0b1f82'};
    gap: 8px;
    flex-direction: row;
    display: flex;
    border-radius: 10px;
    cursor: pointer;
    &:hover {
        background-color: ${(props) => props.gray ? '#454545' : '#091968'};
    }
    transition: 0.5s;
`;

export const ButtonText = styled.p`
    color: white;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.5px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
`;

export const Container = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 32px;
`;

export const Heading = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
`;

export const ActiveCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 32px 40px 32px;
    gap: 32px;
    background-color: #eaeaea;
    justify-content: center;
    border-radius: 16px;
    filter: drop-shadow(2px 4px 2px #aaa);
`;

export const NoCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 40px 32px 40px 32px;
    background-color: #eaeaea;
    border-radius: 16px;
    filter: drop-shadow(2px 4px 2px #aaa);
`;

export const NotificationTitle = styled.p`
    margin: 0;
    font-size: 20px;
    color: #0b1f82;
    font-weight: bold;
    width: fit-content;
`;

export const Message = styled.p`
    font-size: 14px;
    color: #333333;
    margin: 0;
    width: 100%;
`;
 