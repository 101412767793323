import React, {useContext} from "react";
import FilterBox from "../../../Utils/FilterBox/FilterBox"
import CompaniesContext from "../../../../context/companies-context";

function Filter(props) {

    const { setPageParams, listsNameEnum } = props
    let businessGroupCompanies = useContext(CompaniesContext).lists_companies

    let filterTypes = {
        list_key: {
            type: "enum",
            enumList: listsNameEnum,
            disabled: false,
            validation:false,
            description: "Nome da Lista"
        }
    }

    if (businessGroupCompanies){
        let enumList = businessGroupCompanies.business_group_data
        enumList = enumList
                    .sort((a, b) => a.company_name === b.company_name ? 0 : a.company_name > b.company_name ? 1 : -1)
                    .map(company =>{return [company.company_name, company.company_key]})

        filterTypes['company_key'] = {
            type: "enum",
            enumList: [
                ...enumList
            ],  
            disabled: false,
            validation:false,
            description: "Cliente"
        }
    }

    return (
        <FilterBox 
            setPageParams={setPageParams}
            filterTypes={filterTypes}
            local_storage_object_preffix={props.local_storage_object_preffix}
        />
    )
}

export default Filter