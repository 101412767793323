import React from "react";
import Tooltip from '@material-ui/core/Tooltip';
import {getPastRentalsFormat, singleOrPluralNrOfRentals, singleOrPluralPlaces, colorGrey} from "../../../utils/utils"
import { CircularProgress } from '@material-ui/core';

function PastRentals(props) {

    if (props.data === null) {
        return (
            <CircularProgress style={{margin:"auto"}}/>
        )       
    }
    else if (props.data === undefined) {
        return (
            <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto", padding: " 0 20px"}}>
                Houve um problema na recuperação das locações passadas deste CPF. Tente atualizar a página.
            </div>
        )       
    }
    else if (Object.keys(props.data).length === 0) {
        return (
            <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto", padding: " 0 20px"}}>
                Não há locações passadas para este CPF
            </div>
        )        
    }
    else {
        let firstKeys = Object.keys(props.data).length < 5 ? Object.keys(props.data) : Object.keys(props.data).slice(0,4)
        return (
            <div style={{padding: " 0 20px"}}>
                <ul>
                    {firstKeys.map((pastLocation, index) => (
                    <li key={index} style={{marginBottom: "5px"}} className={["labelText", "normalMediumSize"].join(" ")}>
                        {props.data[pastLocation].info == null ? pastLocation : 
                        pastLocation + " - " + getPastRentalsFormat(props.data[pastLocation].info)}{" ("}
                        {singleOrPluralNrOfRentals(props.data[pastLocation]["value"])}
                    </li>
                    ))}
                </ul>
                {Object.keys(props.data).length < 5 ? null :
                <Tooltip title={
                    <p className="tooltipText">{
                    Object.keys(props.data).slice(4,Object.keys(props.data).length).map((pastLocation,index) => (
                        <li key={index} style={{color:"white", marginBottom: "5px"}} className={["labelText", "normalMediumSize"].join(" ")}>
                            {props.data[pastLocation].info == null ? pastLocation : 
                            pastLocation + " - " + getPastRentalsFormat(props.data[pastLocation].info)}{" ("}
                            {singleOrPluralNrOfRentals(props.data[pastLocation]["value"])}
                        </li>))}
                    </p>}
                >
                    <div 
                        className={["normalText", "normalMediumSize", "statusTag"].join(" ")} 
                        style={{display:"inline", backgroundColor:colorGrey }}>
                        {singleOrPluralPlaces(Object.keys(props.data).length - 4)}   
                    </div>
                </Tooltip>}
            </div>
                            
        )        
    }
}
export default PastRentals