import React, { useState, useEffect, useReducer, useCallback, useContext } from "react";
import {
  dataFetchReducer,
  useStateConnectedToCache,
} from "../../Utils/requestUtils";
import Filter from "./Filter/Filter";
import EventTypeFilter from "./EventTypeFilter/EventTypeFilter"
import Pagination from "@material-ui/lab/Pagination";
import { CircularProgress } from "@material-ui/core";
import NewLockDialog from "./NewLockDialog/NewLockDialog"
import axios from "axios";
import LockCard from "./LockCard/LockCard";
import ErrorBoundary from "../../Utils/ErrorBoundary";
import { getFilterEventTypeOptions } from "../utils/utilsLock"
import AuthContext from "../../../context/auth-context";
import { useHistory } from "react-router-dom";
import CardListHeader from "./CardListHeader/CardListHeader";

function LocksInbox(props) {
    let history = useHistory();
    let user_data = useContext(AuthContext).user_data
    let roles = useContext(AuthContext).user_data.roles;
    if(![
        "read_bankslip_locks",
        "read_bill_payment_locks",
        "read_wire_transfer_locks",
        "read_pix_transaction_locks",
        "read_pix_dict_operation_locks",
        "read_withdrawal_locks"
      ].some(role => roles.includes(role))){
        history.push("");
      } 

    let filterEventTypeOptions = getFilterEventTypeOptions(roles)
    let local_storage_object_preffix = "locks";
    let presetEventType = filterEventTypeOptions[0]

    const [refreshPage, setRefreshPage] = useState(false)
    const [openNewLock, setOpenNewLock] = useState(false)
    const [pageParams, setPageParams] = useStateConnectedToCache(
        local_storage_object_preffix
    );

    if (pageParams.cachedEventType !==undefined){
        presetEventType = pageParams.cachedEventType
    }
    
    const [eventType, setEventType] = useState(presetEventType)
    const [pageState, dispatchPageState] = useReducer(dataFetchReducer, {
        fetchedData: null,
        isLoading: true,
        isError: false,
    });

    const doRequest = useCallback(
        (payload) => {
            dispatchPageState({type: "data_fetch_init"})
            axios.get("/dash/alerts/locks", {
                params: payload
            }).then(response=>{
                dispatchPageState({
                    type: "data_fetch_success",
                    payload: response.data
                })          
            }).catch(error => {
                if ((error.response || {}).status === 403) dispatchPageState({type: "data_fetch_failure_403"})
                else if ((error.response || {}).status === 404) dispatchPageState({type: "data_fetch_failure_404"})  
                else dispatchPageState({type: "data_fetch_failure"})    
            })
        },[]
    )

    useEffect(() => {
        let payload = {
            "page_number": pageParams.page - 1,
            "page_rows": 25
        }
        
        if (eventType !== undefined && eventType !== "all"){
            payload["locked_event_type"] = eventType
        }

        Object.keys(pageParams.filters).forEach((key,index) => {
            payload[key] = pageParams.filters[key]
        })
        const timer_ = setTimeout(() => {
            doRequest(payload)
        }, 300);
        return () => {
            clearTimeout(timer_)
		}	
    },[doRequest, pageParams, refreshPage, eventType])

    const handleRefreshPage = (goFirstPage=true) => {
        if (goFirstPage) setPageParams({page: 1, filters: JSON.parse(sessionStorage.getItem(local_storage_object_preffix + 'PageParams')).filters})
        else setRefreshPage(!refreshPage)
    }

    const handleFilterEventTypeChange = (e) => {
        let key = e.target.value
        setEventType(key)
        setPageParams(
            {
                ...pageParams,
                cachedEventType: key
            }
        )
    }

    const handleChangePage = (event, page, filters) => {
		if (pageState.page === page) {
			return
		}
		setPageParams({page: page, filters: filters})
	}

    const handleOpenNewLockClick = () => {
		setOpenNewLock(true);
	  };
	
    const handleNewLockDialogClose = () => {
    setOpenNewLock(false);
    };

    const contentContainer = (dataList) => {

		let nr_of_cards = dataList['data'].length
		let cards = dataList['data'].map((lock) => (
			<ErrorBoundary key={lock.lock_key}>
				<LockCard lock={lock} handleRefreshPage={handleRefreshPage}/>
			</ErrorBoundary>
		))

		if (nr_of_cards === 0) {
			return (
			<div className='emptyContainer'>
				<p className={["blueText", "subtitleSize"].join(" ")}>
					Nada a ser analisado
				</p>
			</div>
			)
		}

		else {
            return (
                <div className='cardContainer'>
                    <CardListHeader />
                    {cards}
                </div>
            )
		}
	}

    if (pageState.fetchedData) {
		return (
			<div style={{ width: "auto", paddingBottom: "30px" }}>
				<div style={{display:"flex"}}>
					<div >
						<p className={["blueText", "titleSize"].join(" ")}>BLOQUEIOS</p>
					</div>
                    <div style={{margin: "auto 20px auto auto"}}>
                        {[
                            "create_bankslip_locks",
                            "create_bill_payment_locks",
                            "create_wire_transfer_locks",
                            "create_pix_transaction_locks",
                            "create_pix_dict_operation_locks",
                            "create_withdrawal_locks"
                          ].some(role => roles.includes(role))
                        ? <div
                            className={["normalText", "button", "standard"].join(" ")}
                            onClick={handleOpenNewLockClick}
                        >
                            + NOVO BLOQUEIO
                        </div>: null
                        }
					</div>
				</div>			
				<ErrorBoundary doNothing>
                    <div style={{display:"flex"}}>
                        <EventTypeFilter 
                            handleFilterEventTypeChange={handleFilterEventTypeChange} 
                            filterEventTypeOptions={filterEventTypeOptions}
                            initialEventType={eventType}
                        />
                        <Filter 
                            local_storage_object_preffix={local_storage_object_preffix} 
                            setPageParams={setPageParams} 
                            eventType={eventType}
                        />
                    </div>
                </ErrorBoundary>
				<div>{contentContainer(pageState.fetchedData)}</div>
				<div>
                    <Pagination 
                        className="pagination" 
                        page={pageParams.page} 
                        count={parseInt(pageState.fetchedData["number_of_pages"])}
                        onChange={(e, page) => handleChangePage(e, page, pageParams.filters)}
                    />
                    <NewLockDialog
                        open={openNewLock}
                        onClose={handleNewLockDialogClose}
                        handleRefreshPage={handleRefreshPage}
                        setPageParams={setPageParams}
                        user_data={user_data}
                        roles={roles}
                        local_storage_object_preffix={local_storage_object_preffix}
				    />
				</div>
			</div>
		);
	}
	else if (pageState.isError){
		return (
			<div style={{ width: "auto" }}>
				<div style={{display:"flex"}}>
					<div >
						<p className={["blueText", "titleSize"].join(" ")}>BLOQUEIOS</p>
					</div>		
				</div>
                <div style={{height: "55vh", display:"flex"}}>
                    <p className={["blueText", "titleSize"].join(" ")} style={{margin:"auto", textAlign:"center"}}>
                        {pageState.errorMessage}
                    </p>
                </div>
			</div>
		)
	}
	else if (pageState.isLoading) {
		return(
			<div style={{ width: "auto" }}>
				<p className={["blueText", "titleSize"].join(" ")}>BLOQUEIOS</p>
				<div className='circularProgressCenter'>
					<CircularProgress />
				</div>
			</div>
		)
    }
    else return null
}

export default LocksInbox;