import React, { useState, Fragment } from "react";
import DataField from "../../../utils/DataField";
import { titleCase, clientAge, checkNested, translateGender, formatPhone, formatAddress, translatePhoneType, formatBureauInfo, getProperMetadata } from "../../../utils/utils";
import MapIcon from "@material-ui/icons/Map";
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import DescriptionIcon from "@material-ui/icons/Description";
import { useTranslation } from "react-i18next";
import OCRDialog from "../../../utils/Components/OCRDialog";

function LegacyClientProfile(props) {
    const decision_metadata = getProperMetadata(props.natural_person.analysis_status_events);
    const registration_data = decision_metadata.registration_data;
    let score_unico = ((props.natural_person.custom_data || {}).unico_response || {}).score;

    if (!score_unico) {
        score_unico = (props.natural_person.custom_data || {}).score_unico;
    }

    const { t } = useTranslation();
    const [OCRPictureOpen, setOCRPictureOpen] = useState(false);

    const handleOCRPictureOpen = () => {
        setOCRPictureOpen(true);
    };

    const handleOCRPictureClose = () => {
        setOCRPictureOpen(false);
    };

    const renderLeftDataFields = () => (
        <div className="internalCardContainer" style={{ display: "flex", flexDirection: "column" }}>
            <div className={["blueText", "subtitleSize"].join(" ")}>{t("sent_basic_data")}</div>
            <DataField
                title={"Nome"}
                label={titleCase(props.natural_person.name, "-")}
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="5px"
            />
            <DataField
                title={"Nome da Mãe"}
                label={titleCase(props.natural_person.mother_name, "-")}
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="5px"
            />
            <DataField
                title={"CPF"}
                label={props.natural_person.document_number || "-"}
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="5px"
            />
            {
                (checkNested(registration_data, "nationality", "flag") || checkNested(props.natural_person, "nationality")) ?
                    <DataField
                        title={"Nacionalidade"}
                        label={props.natural_person.nationality || "-"}
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        margin="5px"
                    />
                    : null
            }
            <DataField
                title={"Gênero"}
                label={translateGender(props.natural_person.gender) || "-"}
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="5px"
            />
            <DataField
                title={"Data de Nascimento"}
                label={clientAge(props.natural_person.birthdate, "-")}
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="5px"
                tooltipText={checkNested(registration_data, "birthdate", "flag") ? registration_data.birthdate.flag === "positive" ? "Data validada" :
                    checkNested(registration_data, "birthdate", "description") ? "Data de Nascimento em Bureau: " + clientAge(registration_data.birthdate.description) : null : null
                }
            />
            <DataField
                title={"Telefone"}
                label={(props.natural_person.phones || []).length > 0 ?
                    formatPhone(props.natural_person.phones[0], "-") : "-"
                }
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="5px"
            >
            </DataField>
            <DataField
                title={"Email"}
                label={(props.natural_person.emails || []).length > 0 ?
                    props.natural_person.emails[0].email || "-" : "-"
                }
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="5px"
            >
            </DataField>
            <DataField
                title={"Endereço"}
                label={formatAddress(props.natural_person.address, "-")}
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="5px"
                tooltipText={((registration_data || {}).address || []).length > 0 ? registration_data.address[0].description : null}
            >
                {props.natural_person.address ?
                    <a
                        href={"https://www.google.com/maps/search/?api=1&query=" + 
                            formatAddress(props.natural_person.address, "-").replace(/ /g, "%20").replace(/,/, '%2C')}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <MapIcon style={{ verticalAlign: "middle", width: "20px", height: "20px", cursor: "pointer", color: "#777", margin: "auto 5px" }} />
                    </a> : null}
                {((registration_data || {}).address || [{}]).length > 1 ?
                    <Tooltip title={<p className="tooltipText">{formatBureauInfo((registration_data.address || []).slice(1), "endereços")}</p>}>
                        <MoreHorizIcon style={{ verticalAlign: "middle" }} />
                    </Tooltip> : null}
                {checkNested(props.natural_person, "address", "ocr_key") ?
                    <Fragment>
                        <DescriptionIcon onClick={handleOCRPictureOpen} style={{ verticalAlign: "middle", width: "20px", height: "20px", cursor: "pointer", color: "#777", margin: "auto 5px" }} />
                        <OCRDialog
                            name={"proof_of_address"}
                            open={OCRPictureOpen}
                            onClose={handleOCRPictureClose}
                            source_image={props.natural_person.address.ocr_key}
                        />
                    </Fragment> : null
                }
            </DataField>
    </div>
    );

    const renderRightDataFields = () => (
        <div className="internalCardContainer" style={{ display: "flex", flexDirection: "column" }}>
            <div className={["blueText", "subtitleSize"].join(" ")}>{t("available_basic_data")}</div>
            <DataField
                title={"Nome"}
                label={titleCase(registration_data.name.description, "-")}
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="5px"
            />
            <DataField
                title={"Nome da Mãe"}
                label={titleCase(registration_data.mother_name.description, "-")}
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="5px"
            />
            {
                (checkNested(registration_data, "nationality", "flag") || checkNested(props.natural_person, "nationality")) ?
                    <DataField
                        title={"Nacionalidade"}
                        label={registration_data.nationality.description || "-"}
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        margin="5px"
                    />
                    : null
            }
            <DataField
                title={"Gênero"}
                label={
                    checkNested(registration_data, "gender", "description")
                    ? titleCase(registration_data.gender.description, "-")
                    : "Descrição não disponível"
                }
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="5px"
                check={
                    checkNested(registration_data, "gender", "flag")
                    ? registration_data.gender.flag
                    : null
                }
                tooltipText={
                    checkNested(registration_data, "gender", "flag")
                    ? (
                        registration_data.gender.flag === "positive"
                        ? "Gênero validado"
                        : (
                            checkNested(registration_data, "gender", "description")
                            ? translateGender(registration_data.gender.description)
                            : null
                        )
                    )
                    : null
                }
            />      
            <DataField
                title={"Data de Nascimento"}
                label={clientAge(registration_data.birthdate.description, "-")}
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="5px"
            />
            <DataField
                title={"Telefone"}
                label={(registration_data.phone_number || []).length > 0 ?
                    formatPhone(registration_data.phone_number[0], "-") : "-"
                }
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="5px"
            >
                {((registration_data || {}).phone_number || [{}]).length > 1 ?
                    <Tooltip title={<p className="tooltipText">{formatBureauInfo((registration_data.phone_number || []).slice(1), "telefones")}</p>}>
                        <MoreHorizIcon style={{ verticalAlign: "middle" }} />
                    </Tooltip> : null}
            </DataField>
            <DataField
                title={"Email"}
                label={(registration_data['e-mail'] || []).length > 0 ?
                    registration_data['e-mail'][0]['email'] : "-"
                }
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="5px"
            >
                {((registration_data || {})["e-mail"] || [{}]).length > 1 ?
                    <Tooltip title={<p className="tooltipText">{formatBureauInfo((registration_data["e-mail"] || []).slice(1), "emails")}</p>}>
                        <MoreHorizIcon style={{ verticalAlign: "middle" }} />
                    </Tooltip> : null}
            </DataField>
            {
                registration_data.address.map((string_address) => (
                    <DataField
                    title={"Endereço"}
                    label={formatAddress(string_address, "-")}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px">
            </DataField>
            ))
        }
        </div>
    );

    return (
        (props.natural_person.name || props.natural_person.mother_name) ? (
            <div className="analysisCard" style={{ height: "100%" }}>
                <div className={["blueText", "subtitleSize"].join(" ")}>{t("basic_data")}</div>
                <div className="internalCardContainer" style={{ display: "flex", flexDirection: "column" }}>
                    <DataField
                        title={"Nome"}
                        label={titleCase(props.natural_person.name, "-")}
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        margin="5px"
                        check={checkNested(registration_data, "name", "flag") ? registration_data.name.flag : null}
                        tooltipText={checkNested(registration_data, "name", "flag") ? registration_data.name.flag === "positive" ? "Nome validado" :
                            checkNested(registration_data, "name", "description") ? "Nome encontrado em Bureau: " + titleCase(registration_data.name.description) : null : null
                        }
                    />
                    <DataField
                        title={"Nome da Mãe"}
                        label={titleCase(props.natural_person.mother_name, "-")}
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        margin="5px"
                        check={checkNested(registration_data, "mother_name", "flag") ? registration_data.mother_name.flag : null}
                        tooltipText={checkNested(registration_data, "mother_name", "flag") ? registration_data.mother_name.flag === "positive" ? "Nome validado" :
                            checkNested(registration_data, "mother_name", "description") ? "Nome encontrado em Bureau: " + titleCase(registration_data.mother_name.description) : null : null
                        }
                    />
                    <DataField
                        title={"CPF"}
                        label={props.natural_person.document_number || "-"}
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        margin="5px"
                    />
                    {
                        (checkNested(registration_data, "nationality", "flag") || checkNested(props.natural_person, "nationality")) ?
                            <DataField
                                title={"Nacionalidade"}
                                label={props.natural_person.nationality || "-"}
                                size={"normalMediumSize"}
                                titleNoWrap={true}
                                margin="5px"
                                check={checkNested(registration_data, "nationality", "flag") ? registration_data.nationality.flag : null}
                                tooltipText={checkNested(registration_data, "nationality", "flag") ? registration_data.nationality.flag === "positive" ? "Nacionalidade validada" :
                                    checkNested(registration_data, "nationality", "description") ? registration_data.nationality.description : null : null
                                }
                            />
                            : null
                    }
                    <DataField
                        title={"Gênero"}
                        label={translateGender(props.natural_person.gender) || "-"}
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        margin="5px"
                        check={checkNested(registration_data, "gender", "flag") ? registration_data.gender.flag : null}
                        tooltipText={checkNested(registration_data, "gender", "flag") ? registration_data.gender.flag === "positive" ? "Gênero validado" :
                            checkNested(registration_data, "gender", "description") ? translateGender(registration_data.gender.description) : null : null
                        }
                    />
                    <DataField
                        title={"Data de Nascimento"}
                        label={clientAge(props.natural_person.birthdate, "-")}
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        margin="5px"
                        check={checkNested(registration_data, "birthdate", "flag") ? registration_data.birthdate.flag : null}
                        tooltipText={checkNested(registration_data, "birthdate", "flag") ? registration_data.birthdate.flag === "positive" ? "Data validada" :
                            checkNested(registration_data, "birthdate", "description") ? "Data de Nascimento em Bureau: " + clientAge(registration_data.birthdate.description) : null : null
                        }
                    />
                    <DataField
                        title={"Telefone"}
                        label={(props.natural_person.phones || []).length > 0 ?
                            formatPhone(props.natural_person.phones[0], "-") : "-"
                        }
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        margin="5px"
                        check={((registration_data || {}).phone_number || []).length > 0 ? registration_data.phone_number[0].flag : null}
                        tooltipText={((registration_data || {}).phone_number || []).length > 0 ? registration_data.phone_number[0].description : null}
                    >
                        {(props.natural_person.phones || []).length > 0 ?
                            <Tooltip title={<p className="tooltipText">{translatePhoneType(props.natural_person.phones[0].type)}</p>} arrow placement="right">
                                <HelpIcon style={{ fontSize: "14px", verticalAlign: "middle" }} />
                            </Tooltip> : null
                        }
                        {((registration_data || {}).phone_number || [{}]).length > 1 ?
                            <Tooltip title={<p className="tooltipText">{formatBureauInfo((registration_data.phone_number || []).slice(1), "telefones")}</p>}>
                                <MoreHorizIcon style={{ verticalAlign: "middle" }} />
                            </Tooltip> : null}
                    </DataField>
                    <DataField
                        title={"Email"}
                        label={(props.natural_person.emails || []).length > 0 ?
                            props.natural_person.emails[0].email || "-" : "-"
                        }
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        margin="5px"
                        check={((registration_data || {})['e-mail'] || []).length > 0 ? registration_data['e-mail'][0].flag : null}
                        tooltipText={((registration_data || {})['e-mail'] || []).length > 0 ? registration_data['e-mail'][0].description : null}
                    >
                        {((registration_data || {})["e-mail"] || [{}]).length > 1 ?
                            <Tooltip title={<p className="tooltipText">{formatBureauInfo((registration_data["e-mail"] || []).slice(1), "emails")}</p>}>
                                <MoreHorizIcon style={{ verticalAlign: "middle" }} />
                            </Tooltip> : null}
                    </DataField>
                    <DataField
                        title={"Endereço"}
                        label={formatAddress(props.natural_person.address, "-")}
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        margin="5px"
                        check={((registration_data || {}).address || []).length > 0 ? registration_data.address[0].flag : null}
                        tooltipText={((registration_data || {}).address || []).length > 0 ? registration_data.address[0].description : null}
                    >
                        {props.natural_person.address ?
                            <a
                                href={"https://www.google.com/maps/search/?api=1&query=" + 
                                    formatAddress(props.natural_person.address, "-").replace(/ /g, "%20").replace(/,/, '%2C')}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <MapIcon style={{ verticalAlign: "middle", width: "20px", height: "20px", cursor: "pointer", color: "#777", margin: "auto 5px" }} />
                            </a> : null}
                        {((registration_data || {}).address || [{}]).length > 1 ?
                            <Tooltip title={<p className="tooltipText">{formatBureauInfo((registration_data.address || []).slice(1), "endereços")}</p>}>
                            <MoreHorizIcon style={{ verticalAlign: "middle" }} />
                            </Tooltip> : null}
                        {checkNested(props.natural_person, "address", "ocr_key") ?
                            <Fragment>
                                <DescriptionIcon onClick={handleOCRPictureOpen} style={{ verticalAlign: "middle", width: "20px", height: "20px", cursor: "pointer", color: "#777", margin: "auto 5px" }} />
                                <OCRDialog
                                    name={"proof_of_address"}
                                    open={OCRPictureOpen}
                                    onClose={handleOCRPictureClose}
                                    source_image={props.natural_person.address.ocr_key}
                                />
                            </Fragment> : null
                        }
                    </DataField>
                    {score_unico &&
                        <>
                            <div className={["normalMediumSize", "bold"].join(" ")} style={{ marginTop: '15px' }}>
                                {`${t("additional_data")}:`}
                            </div>
                            <DataField
                                title={t("score_unico")}
                                label={score_unico}
                                size={"normalMediumSize"}
                                titleNoWrap={false}
                            />
                        </>
                    }
                </div>
            </div>
        ) : (
            <div className="analysisCard" style={{ height: "100%", display: 'flex', flexDirection: 'column', overflowY: 'auto', maxHeight: '80vh' }}>
                <div style={{ display: "flex" }}>
                    <div style={{ flex: 1 }}>
                        {renderLeftDataFields()}
                    </div>
                    <div style={{ width: '1px', backgroundColor: 'rgba(0, 0, 0, 0.1)', margin: '0 10px' }}></div>
                    <div style={{ flex: 1 }}>
                        {renderRightDataFields()}
                    </div>
                </div>
            </div>
        )
    );
}

export default LegacyClientProfile;