import React from "react";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import { CircularProgress } from '@material-ui/core';

const markerIcon = new L.Icon({
    iconUrl: require("../../../../../../../assets/cardEntryIcons/MapMarkerRed.svg"),
    iconSize: [25, 41],
    iconAnchor: [12, 41],
  });

function MerchantMap(props){

    if (props.merchants === null) {
        return(
            <div style={{display: "flex", height: "192px", margin:"auto"}}>
                <CircularProgress style={{margin:"auto"}}/>
            </div>
        )
    }
    else if (props.merchants === undefined || props.merchants.length ===0){
        return (
            <div style={{display: "flex", height: "192px", margin:"auto"}}>
                <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                    Informação Indisponível
                </div>
            </div>
        )
    }
    else {
        return (
            <Map
            className="ciAlertHistoric"
            center={[props.merchants[0].coordinates[0],props.merchants[0].coordinates[1]]}
            zoom={8}
            >
                <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
                {props.merchants.map((merchant, index)=>(
                <Marker
                key={index}
                position={[merchant.coordinates[0],merchant.coordinates[1]]}
                icon={markerIcon}
                >
                    <Popup>
                        <div style={{display: "flex", margin: "5px 0px"}}>
                            <div className={["blueText", "normalSmallSize", "noWrap"].join(" ")}>
                                {merchant.company_name} - {merchant.merchant_id}
                            </div>
                        </div>
                        <div style={{display: "flex", margin: "5px 0px"}}>
                            <div className={["normalText", "normalSmallSize", "noWrap"].join(" ")}>
                                Cartão:
                            </div>
                            <div className={["labelText", "normalSmallSize", "labelSpace"].join(" ")}>
                                {merchant.payment_card}
                            </div>
                        </div>
                        <div style={{display: "flex", margin: "5px 0px"}}>
                            <div className={["normalText", "normalSmallSize", "noWrap"].join(" ")}>
                                Nome:
                            </div>
                            <div className={["labelText", "normalSmallSize", "labelSpace"].join(" ")}>
                                {merchant.name}
                            </div>
                        </div>  
                        <div style={{display: "flex", margin: "5px 0px"}}>
                            <div className={["normalText", "normalSmallSize", "noWrap"].join(" ")}>
                                Data:
                            </div>
                            <div className={["labelText", "normalSmallSize", "labelSpace"].join(" ")}>
                                {merchant.authorization_date}
                            </div>
                        </div> 
                        <div style={{display: "flex", margin: "5px 0px"}}>
                            <div className={["normalText", "normalSmallSize", "noWrap"].join(" ")}>
                                Valor:
                            </div>
                            <div className={["labelText", "normalSmallSize", "labelSpace"].join(" ")}>
                                {merchant.amount}
                            </div>
                        </div>  
                    </Popup>
                </Marker>
                ))}
          </Map>
        )
    }

}

export default MerchantMap