import React, {useState} from "react";
import Collapse from "@material-ui/core/Collapse";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import IconButton from "@material-ui/core/IconButton";
import CompaniesRelatedTable from "./CompaniesRelatedTable/CompaniesRelatedTable";

function CompaniesRelatedCard(props) {

    const {related_companies} = props

    const [expanded, setExpanded] = useState(false);

    const handleExpand = (event) => {
        event.stopPropagation();
        setExpanded(!expanded);
    };
    return (
        <div className="analysisCard">
            <div style={{display:"flex"}}>
            <div 
                className={["blueText", "subtitleSize", "bold"].join(" ")}
                style={{margin:"auto 0px"}}
            >
                Empresas Relacionadas
            </div>
            {related_companies.data.length === 0 ? 
                <IconButton style={{marginLeft: "auto"}} onClick={handleExpand}>
                    {expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </IconButton>  
                : null           
                }                    
            </div>

            {related_companies.data.length === 0 ? 
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <div className={["labelText", "normalMediumSize", "internalCardContainer"].join(" ")}
                        style={{textAlign: "center"}}>
                    Sem registro de empresas relacionadas para o CPF
                </div>
            </Collapse> :
                <div className={["normalText", "normalMediumSize", "internalCardContainer"].join(" ")}>
                    <CompaniesRelatedTable related_companies_data={related_companies.data}/>
                </div>
            }
        </div>
    );
}


export default CompaniesRelatedCard