import React from 'react';
import {categoryColors} from "../Boxes/utils"
import Tooltip from "@material-ui/core/Tooltip";
import { useTranslation } from "react-i18next";

function classnames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

function StandardLibraryComponent (props){
    const { t } = useTranslation();
    let {node} = props

    let nodeTitle = node.description

    return(
        <Tooltip enterDelay={1000} enterNextDelay={1000} title={<div className="tooltipText"><ul>{node.helperText.map((help,index) => (<li key={index}>{t(help)}</li>))}</ul></div>}>
            <div style={{minWidth:"135px", cursor: "pointer", display:"flex", padding:"3px"}}>
                <div style={{
                    borderTopLeftRadius: "4px",
                    borderBottomLeftRadius: "4px",
                    padding:"2px 3px", 
                    minHeight:"25px", 
                    margin:"0px", 
                    display:"flex",
                    backgroundColor: categoryColors(node.category)
                }}>
                    <img src={node.logoSVG} alt="boxLogo" style={{margin:"auto"}}/>
                </div>
                <div style={{borderTopRightRadius: "4px", borderBottomRightRadius: "4px",padding:"5px", minHeight:"25px", margin:"auto 0px", backgroundColor:"white", flexGrow:"1"}}>
                    <div style={{width:"100%", padding:"0px"}} className={classnames("normalText", "normalSmallSize")}>
                        {t(nodeTitle)}
                    </div>
                </div>
            </div>
        </Tooltip>

    )
}

export default StandardLibraryComponent;