import React from "react";
import { formatScore } from "../../utils/utilsOrder";

function ScoreBox(props) {

    let score = ((((props.analysis_events || [])[0] || {}).decision_metadata || {}).model || {}).score
    
    if (score == null) {
		return (
			<div className="analysisCard" style={{flexDirection:"row", justifyContent: "space-evenly"}}>
                <div 
                    className={["blueText", "scoreSize"].join(" ")} 
                    style={{marginTop: "auto", marginBottom:"auto"}}
                >
                    SCORE: 
                </div>
                <div  className={["labelText", "scoreSize", "labelSpace"].join(" ")} >
                    Informação Indisponível
                </div>
			</div>
		);    
	}
  	else {
        let scoreObject = formatScore(score)
		return (
			<div className="analysisCard" style={{flexDirection:"row", justifyContent: "space-evenly"}}>
                <div 
                    className={["blueText", "scoreSize"].join(" ")} 
                    style={{marginTop: "auto", marginBottom:"auto"}}
                >
                    SCORE: 
                </div>
                <div 
                    className={["circle", "medium", "bold", "normalText", "scoreSize"].join(" ")} 
                    style={{color:"white", backgroundColor: scoreObject.color, margin: "auto 0px"}}
                >
                    {score}
                </div>
                <div className={["normalText", "scoreSize", "bold"].join(" ")} style={{margin:"auto 0px", color: scoreObject.color}}>
                    {scoreObject.text}
                </div>
			</div>
		);

  }



}

export default ScoreBox;