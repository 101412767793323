import React from "react"
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    textAlign: "center",
    marginBottom: "10px",
    float: "left",
  }
}));

function CSVLimitSizeDialog(props) {
  const classes = useStyles();
  useTheme();

  return (
        <Dialog open={props.open} onClose={props.onClose} keepMounted={false}>
            <DialogContent className={classes.dialogContent}>
                <div style={{ width: "330px", height:"267px"}}>
                    <div className="internalCardContainer" style={{display:"flex", height:"30%", justifyContent:"center"}}>
                        <InfoOutlinedIcon style={{width:"60px", height:"60px", color:"#0b1f82"}}/>           
                    </div>
                    <div className="internalCardContainer" style={{display:"flex", textAlign:"center", justifyContent:"center", flexDirection:"column"}}>
                        <p style={{margin:0}}>O número de análises retornadas na busca excede o limite de exportação.</p>
                        <p>O arquivo gerado foi restrito para conter apenas as <b>100.000</b> análises mais recentes.</p>            
                    </div>
                    <div className="internalCardContainer" style={{display:"flex", justifyContent:"center"}}>
                        <div 
                            className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                            onClick={props.onClose}
                        >
                            FECHAR
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default CSVLimitSizeDialog;