import React, {useState, useEffect} from 'react'
import { useTranslation } from "react-i18next";
import {GetAlertsComponent, setObjectByPath, getConstantTextFields, validateConstantValues, translateType, truncateDecimals} from "./utils"
import NumberFormat from 'react-number-format';
import _ from "lodash"
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import Checkbox from '@material-ui/core/Checkbox';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
//MANIPULAÇÃO DE TEXTO - Coletar parte do Texto

const theme = createMuiTheme({
    palette:{
        primary: {
            main:"#0b1f82"
        },
    }
  });


export function StringSliceBox (props) {
    const { t } = useTranslation();
    let {node} = props
    let {read_only} = props
    let {handleConfigurationOpen} = props
    let {handleNodeRemove} = props

    return (
        <div>
            {!read_only ?
                <div style={{display:"flex", alignItems: "center"}}>
                    <div 
                        style={{display:"flex", width:"100%", cursor:"pointer", margin: "0px"}} 
                        onClick={() => handleConfigurationOpen(node)}
                    >
                        <div style={{display: "flex",flexDirection: "column",margin: "auto 0px"}}>
                            <div className={["normalText","normalMediumSize"].join(" ")}>
                                {
                                    node.node.properties.from_start 
                                    ? 
                                    t("Coletar do começo")
                                    : 
                                    t("Coletar a partir do índice ") + t(truncateDecimals(node.node.properties.start_index,0))
                                }
                            </div>
                            <div className={["normalText","normalSmallSize"].join(" ")}>
                                {
                                    node.node.properties.to_end 
                                    ? 
                                    t("Até o fim")
                                    : 
                                    t("Até o índice ") + t(truncateDecimals(node.node.properties.end_index,0))
                                }
                            </div>
                        </div>
                        {(node.node.alerts || []).length > 0?
                        <GetAlertsComponent alerts={node.node.alerts} />  : null}       
                    </div>
                    <IconButton style={{width: 32, height: 32}} aria-label="duplicate" onClick={() => props.handleNodeDuplication(node)}>
                        <ContentCopyIcon style={{width: 20, height: 20}} />
                    </IconButton>
                    <IconButton style={{width: 32, height: 32}} aria-label="close" onClick={() => handleNodeRemove(node)}>
                        <CloseIcon style={{width: 20, height: 20}} />
                    </IconButton>
                </div>
            :
                <div style={{display:"flex"}}>
                    <div 
                        style={{display:"flex", width:"100%", cursor:"pointer", margin: "0px"}} 
                        onClick={() => handleConfigurationOpen(node)}
                    >
                        <div style={{display: "flex",flexDirection: "column",margin: "auto 0px"}}>
                            <div className={["normalText","normalMediumSize"].join(" ")}>
                                {
                                    node.node.properties.from_start 
                                    ? 
                                    t("Coletar do começo")
                                    : 
                                    t("Coletar a partir do índice ") + t(truncateDecimals(node.node.properties.start_index,0))
                                }
                            </div>
                            <div className={["normalText","normalSmallSize"].join(" ")}>
                                {
                                    node.node.properties.to_end 
                                    ? 
                                    t("Até o fim")
                                    : 
                                    t("Até o índice ") + t(truncateDecimals(node.node.properties.end_index,0))
                                }
                            </div>
                        </div>     
                    </div>
                </div>
            }
        </div>
    )
}

export function StringSliceConfiguration(props){
    const { t } = useTranslation();
    let {handleConfigurationClose} = props
    let {handleNodeChange} = props
    let {node} = props
    let {read_only} = props

    const [nodeState, setNodeState] = useState(node)

    useEffect(()=>{
        setNodeState(node)
    },[node])

    const handleNodeStateChange = (obj_key,value) => {
        let new_node = _.cloneDeep(nodeState)
        if (value === undefined) value = null
        setObjectByPath(new_node.node,obj_key,value)
        setNodeState(new_node)
    }

    const handleSave = () => {
        handleConfigurationClose()
        handleNodeChange(nodeState)
    }

    const validateAllValues = () => {
        let start_validation = true
        if (!nodeState.node.properties.from_start){
            start_validation = validateConstantValues("number",nodeState.node.properties.start_index)
        }
        let end_validation = true
        if (!nodeState.node.properties.to_end){
            end_validation = validateConstantValues("number",nodeState.node.properties.end_index)
        }
        return start_validation && end_validation
    }

    return(
        <div style={{flexGrow:"1", padding: "20px", display:"flex", flexDirection:"column"}}>
            <div style={{display: "flex"}}>
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>{t(node.node.description)}</span>
                <Tooltip title={<div className="tooltipText"><ul>{node.node.helperText.map((help,index) => (<li key={index}>{t(help)}</li>))}</ul></div>}>

                    <HelpIcon style={{fontSize: "14px", display:"flex", margin:"auto 5px"}}/>
                </Tooltip>
            </div>                        
            <div className="internalCardContainer" style={{display:"flex", flexDirection: "column"}}>
                <ThemeProvider theme={theme}>
                    <div style={{display:"flex", justifyContent:"space-evenly"}}>
                        <div style={{display:"flex"}}>
                            <Checkbox
                                onChange={(e) => handleNodeStateChange("properties.from_start", e.target.checked)}
                                checked={nodeState.node.properties.from_start}
                                color="primary"
                            />
                            <div>
                                <p 
                                    className={["normalText", "normalMediumSize"].join(" ")} 
                                >   
                                    {t("Do começo")}
                                </p>
                            </div>
                        </div>
                        <div style={{display:"flex"}}>
                            <Checkbox
                                onChange={(e) => handleNodeStateChange("properties.to_end", e.target.checked)}
                                checked={nodeState.node.properties.to_end}
                                color="primary"
                            />
                           
                            <div>
                                <p 
                                    className={["normalText", "normalMediumSize"].join(" ")} 
                                >   
                                    {t("Ao fim")}
                                </p>
                            </div>
                        </div>
                    </div>
                </ThemeProvider>
                <NumberFormat
                    label={t("Índice Inicial")}
                    style={{textAlign:"center", width:"80%", margin:"3px auto"}}
                    disabled={nodeState.node.properties.from_start}
                    {...getConstantTextFields("number").props}
                    value={nodeState.node.properties.start_index}
                    onValueChange={(e) => handleNodeStateChange("properties.start_index", e.floatValue)}
                    InputProps={{
                        readOnly: read_only
                      }}
                />   
                <NumberFormat
                    label={t("Índice Final")}
                    style={{textAlign:"center", width:"80%", margin:"3px auto"}}
                    disabled={nodeState.node.properties.to_end}
                    {...getConstantTextFields("number").props}
                    value={nodeState.node.properties.end_index}
                    onValueChange={(e) => handleNodeStateChange("properties.end_index", e.floatValue)}
                    InputProps={{
                        readOnly: read_only
                      }}
                />      
            </div>
            <div style={{display:"flex", margin: "auto auto 5px auto"}}>
                <div
                    className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")}
                    style={{width:"120px"}}
                    onClick={handleConfigurationClose}
                >
                    {read_only ? "X" : t("discard")}
                </div>
                {!read_only && 
                <div
                    className={
                        validateAllValues() 
                        ?
                        ["button", "standard", "normalText", "normalMediumSize"].join(" ")
                        :
                        ["button", "standard", "normalText", "normalMediumSize","disabled"].join(" ")}
                    style={{width:"120px"}}
                    onClick={validateAllValues() ? handleSave : null}
                >
                    {t("save")}
                </div>}   
            </div>
        </div>
    )
}

export class StringSliceBoxClass {
    constructor(rulesContext){
        this.rulesContext = rulesContext
    }
    validate(node){
        let put_error = false
        let alerts = []
        //Validate if node has at least one children
        if((node.node.children || []).length !== 1){
            put_error = true
            alerts.push("Este nó precisa ter apenas um filho")
        }
        //Validate if children of the node is string
        else{
            let childrenType = node.node.children[0].instance.getType(node.node.children[0])
            if (childrenType !== "string"){
                put_error = true
                alerts.push("O filho deste nó deve ter um retorno 'Texto'. Atualmente é " + translateType(childrenType))
            }
        }
        if (!validateConstantValues("bool",node.node.properties.from_start)){
            put_error = true
            alerts.push("A propriedade 'Do começo' deve ser um 'Binário' válido")
        }
        else if (!node.node.properties.from_start && !validateConstantValues("number",node.node.properties.start_index)){
            put_error = true
            alerts.push("Caso 'Do começo' não esteja marcado a propriedade 'Índice Inicial' deve ser um 'Número' validado")
        }
        if (!validateConstantValues("bool",node.node.properties.to_end)){
            put_error = true
            alerts.push("A propriedade 'Ao fim' deve ser um 'Binário' válido")
        }
        else if (!node.node.properties.to_end && !validateConstantValues("number",node.node.properties.end_index)){
            put_error = true
            alerts.push("Caso 'Ao fim' não esteja marcado a propriedade 'Índice Final' deve ser um 'Número' validado")
        }
        //Validate if parent is valid
        if (node.parentNode != null) {
            if(node.parentNode.on_error){
                put_error = true
                alerts.push("O nó superior está inválida")
            }
        }
        return {validated: !put_error, alerts: alerts}
    }

    getType(node){
        return "string"
    }
}