import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { justFloat } from "../RulesEngine/utils";

export const GeoPointInput = (props) => {
    
    const { id, labelValue, geoPoint, onChange, onBlur, error, errorMessage } = props
    const { t } = useTranslation();
    const [isFocused, setIsFocused] = useState(false);

    function handleChange (event, pointType) {
        const { value } = event.target;
        let newGeoPoint = geoPoint
        if (pointType==="north") {
            newGeoPoint[0] = justFloat(value)
        } else if (pointType==="east") {
            newGeoPoint[1] = justFloat(value)
        }
        onChange(newGeoPoint);
    }

    const handleBlur = (event, pointType) => {
        const { value } = event.target;
        let newGeoPoint = geoPoint
        if (pointType==="north") {
            newGeoPoint[0] = justFloat(value)
        } else if (pointType==="east") {
            newGeoPoint[1] = justFloat(value)
        }
        setIsFocused(false);
        onBlur(newGeoPoint);
    };
    
    const handleFocus = () => {
        setIsFocused(true);
    };

    let showMainLabelValue=true
    if (props.showMainLabelValue===false) {
        showMainLabelValue=false
    }
    
    return (
        <>
            {showMainLabelValue&&
            <label className="normalText normalSmallSize" htmlFor={id}>{`${t(labelValue)}`}</label>}
            <div  style={{display:"grid", gridTemplateColumns:"49.5% 49.5%", gap:"1%"}}>
                <div className="labelInput">
                    <label className="normalText normalSmallSize" htmlFor={id}>{t("north")}</label>
                    <input
                        className={!isFocused&&error?"errorInput":null}
                        id={id}
                        name={id}
                        placeholder={props.placeholder?props.placeholder:null}
                        value={geoPoint[0]}
                        onChange={(event) => handleChange(event, "north")}
                        disabled={props.isDisabled?props.isDisabled:false}
                        onBlur={(event) => handleBlur(event, "north")}
                        onFocus={handleFocus}
                    />
                </div>
                <div className="labelInput">
                    <label className="normalText normalSmallSize" htmlFor={id}>{t("east")}</label>
                    <input
                        className={!isFocused&&error?"errorInput":null}
                        id={id}
                        name={id}
                        placeholder={props.placeholder?props.placeholder:null}
                        value={geoPoint[1]}
                        onChange={(event) => handleChange(event, "east")}
                        disabled={props.isDisabled?props.isDisabled:false}
                        onBlur={(event) => handleBlur(event, "east")}
                        onFocus={handleFocus}
                    />
                </div>
            </div>
            <span className="labelInput normalSmallSize__error" style={{height:"13px"}}>
                {!isFocused&&error?t(errorMessage):null}
            </span>
        </>
    );
}
