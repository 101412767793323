import React from 'react';
import { useTranslation } from 'react-i18next';

function ButtonDescription (props) {

  const { text, onClickButton } = props;

  const { t } = useTranslation();

  let buttonClassName = "normalText normalSmallSize"
  if (props.buttonClassName) {
    buttonClassName = props.buttonClassName
  }

  return (
    <button
    className={buttonClassName}
    style={{ display:"flex", flexDirection:"column", padding:"5px", textAlign: "center"}}
    onClick={onClickButton}
    >
      {props.children}
      <p className="blue" style={{margin:"0px", maxWidth:"70px"}}>
        {t(text)}
      </p>
    </button>
  )
}

export default ButtonDescription;