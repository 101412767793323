import React, {useState} from 'react'
import TextField from "@material-ui/core/TextField";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { setAllowChanges, replaceAll } from "../../utils/utils"
import ConfirmationDialog from "./ConfirmationDialog/ConfirmationDialog";
import moment from "moment"
import { useTranslation } from 'react-i18next';

const theme = createMuiTheme({
    overrides: { MuiInputBase: { root:{
        fontFamily: "Open Sans",
        fontSize: "14px",
        lineHeight: "19px",

    }}},
    palette:{
        primary: {
            main:"#0b1f82"
        },
    }
  });

   
function Observation(props) {

    const { t } = useTranslation()

    const {credit_analysis, credit_proposal_natural_person_key, user_data, isChained} = props 

    const [openDialog, setOpenDialog] = useState(false)
    const [manualAnalysisStatus, setManualAnalysisStatus] = useState(null)
  
    let [observationBox, setObservationBox] = useState("")

    const handleOpenClick = (status) => {
        setManualAnalysisStatus(status)
        setOpenDialog(true)
    };

    const handleDialogClose = () => {
        setOpenDialog(false)
        setTimeout(function (){setManualAnalysisStatus(null)}, 300)
    };

    const handleObservationBoxChange = (event) => {
        if (event.target.value.length === 0){
            setObservationBox(null)
        }
        else{
            setObservationBox(event.target.value)
        }
    };

    let roles = user_data.roles
    let allowChanges = setAllowChanges(credit_analysis.analysis_status, roles, "np")

    let observation = null
    let analyst = null

    if (!allowChanges){
        let last_event = (credit_analysis.credit_proposal_natural_person_analysis_events == null || credit_analysis.credit_proposal_natural_person_analysis_events.length ===0) ? null :
            credit_analysis.credit_proposal_natural_person_analysis_events[credit_analysis.credit_proposal_natural_person_analysis_events.length-1]
        if (last_event){
            observation = last_event.observation
            let string = last_event.analyst_name == null ? null : "Análise feita por %name, %email no dia %day às %time.\n"
            let mapObj = {
                "%name": last_event.analyst_name,
                "%email": last_event.analyst_email,
                "%day": moment(last_event.event_date).format("DD/MM/YYYY"),
                "%time": moment(last_event.event_date).format("HH:mm"),
            }
            analyst = string == null ? null :replaceAll(string, mapObj)
        }
    }

    return (
        <div className="analysisCard">
            <span className={["blueText", "subtitleSize"].join(" ")}>Revisão</span>
            <div className="internalCardContainer">
                <ThemeProvider theme={theme} >
                    <TextField         
                        fullWidth
                        multiline
                        variant="outlined"
                        color="primary"
                        defaultValue={allowChanges ? null : observation == null ? (analyst) : (analyst + observation)}
                        disabled={!allowChanges}
                        onChange={handleObservationBoxChange}
                    />
                </ThemeProvider>
            </div>
            {!isChained&&allowChanges &&
            <div style={{display: "flex", justifyContent: "flex-end", marginTop: "25px"}}>
                <div 
                    className={["button", "reproved", "normalText", "normalMediumSize"].join(" ")} 
                    onClick={() => handleOpenClick("manually_reproved")}
                    style={{marginLeft:"auto"}}
                >
                    {t("reprove")}
                </div>
                <div 
                    className={["button", "approved", "normalText", "normalMediumSize"].join(" ")}
                    onClick={() => handleOpenClick("manually_approved")}
                >
                    {t("approve")}
                </div>
            </div>}
            {!isChained&&allowChanges &&
            <ConfirmationDialog
                open={openDialog}
                manualAnalysisStatus={manualAnalysisStatus}
                onClose={handleDialogClose}
                observation_data={observationBox}
                credit_proposal_natural_person_key={credit_proposal_natural_person_key}
            />}
        </div>
    )
}

export default Observation