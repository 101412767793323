import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import DeleteMany from "./DeleteMany";

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

function DeleteManyDialog(props){

  let { list, onClose, handleReloadPage, companyKey, userData } = props

  return(
      <Dialog 
      open={props.open} 
      onClose={onClose}
      disableBackdropClick
      maxWidth="lg"
      keepMounted={false}>
          <DialogTitle 
              className={['blueText', "subtitleSize"].join(" ")} 
              onClose={onClose}
          >
              Exclusão Manual de Itens
          </DialogTitle>
          <DialogContent >
              <div style={{minHeight: "200px", minWidth: "350px", display:"flex", flexDirection:"column", marginBottom:"20px", width:"max-content",overflow:"hidden", padding:"8px 24px"}}>
                  <DeleteMany 
                  onClose={onClose} 
                  list={list} 
                  handleReloadPage={handleReloadPage}
                  companyKey={companyKey}
                  userData={userData}
                  />
              </div>
          </DialogContent>
      </Dialog>
  )
}

export default DeleteManyDialog
