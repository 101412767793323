import React from "react";
import TextField from "@material-ui/core/TextField";
import IconButton from '@mui/material/IconButton';
import * as S from './styles'
import { Search, Close } from "@material-ui/icons";


// interface SearchBarProps {
//     label: string;
//     content: string;
//     setContent: React.Dispatch<React.SetStateAction<string>>;
//     refresher?: Boolean;
//     setRefresher: React.Dispatch<React.SetStateAction<bolean>>;
// }

const SearchBar = ({label, content, setContent, setRefresher, refresher}) => {
    return(
        <S.Container>
            <TextField id="outlined-basic"
                       label={label}
                       variant="outlined"
                       value={content}
                       onChange={(e) => setContent(e.target.value)}
                       onKeyDown={(e) => {
                            console.log(`The key ${e.key} has been pressed!`)
                            if (e.key === 'Enter') {
                                setRefresher(!refresher);
                            }
                       }}
                       fullWidth  
            />
        <IconButton aria-label="pesquisar"
                    onClick={() => setRefresher(!refresher)}
                    style={{width: 56, height: 56}}
        >
            <Search />
        </IconButton>
        <IconButton aria-label="limpar"
                    onClick={() => {
                        setContent("");
                        setRefresher(!refresher);
                    }}
                    style={{width: 56, height: 56}}
        >
            <Close />
        </IconButton>
        </S.Container>
    );
}

export default SearchBar;