import React, {
  useEffect,
  useContext,
  useReducer,
  useRef,
  useState,
  useCallback
} from "react";
import { dataFetchReducer } from "../../Utils/requestUtils";
import AuthContext from "../../../context/auth-context";
import { useParams } from "react-router-dom";
import axios from "axios";
import {CircularProgress } from '@material-ui/core'
import ErrorBoundary from '../../Utils/ErrorBoundary'
import {useHistory} from 'react-router-dom'
import ReactToPrint from "react-to-print";
import Button from "../utils/Components/Button";
import { useTranslation } from "react-i18next";
import CreditAnalysisNaturalPersonCards from "./CreditAnalysisNaturalPersonCards";
import { ChainedAnalysisNaturalPersonCards } from "./ChainedAnalysisNaturalPersonCards";

function CreditAnalysisNaturalPerson(props) {
  const { t } = useTranslation();

  const [preparePDF, setPreparePDF] = useState(false);

  const pdf = useRef();

  const [buttonExport2PDF, setButtonExport2PDF] = useState(false);

  const [expandedSCR, setExpandedSCR] = useState(true);
  const [expandedSerasa, setExpandedSerasa] = useState(true);
  const [expandedBoavista, setExpandedBoavista] = useState(true);
  const [expandedQuod, setExpandedQuod] = useState(true);
  const [expandedScorePositivo, setExpandedScorePositivo] = useState(true);

  let history = useHistory();
  let user_data = useContext(AuthContext).user_data;

  if (!user_data.roles.includes("read_credit_analysis_natural_persons")) {
    history.push("");
  }

  let { credit_proposal_natural_person_key } = useParams();
  
  const [registrationId, setRegistrationId] = useState(null)
  const [credit_analysis, dispatchCreditAnalysis] = useReducer(
    dataFetchReducer,
    { fetchedData: null, isLoading: true, isError: false }
  );

  const getNaturalPersonByKey = useCallback(()=>{
    dispatchCreditAnalysis({ type: "data_fetch_init" });
    const timer = setTimeout(() => {
      axios
        .get(
          "/dash/credit_analysis/natural_person/" +
            credit_proposal_natural_person_key
        )
        .then((response) => {
          setRegistrationId((response.data||{}).registration_id)
          dispatchCreditAnalysis({
            type: "data_fetch_success",
            payload: response.data,
          });
        })
        .catch((error) => {
          if ((error.response || {}).status === 403)
            dispatchCreditAnalysis({ type: "data_fetch_failure_403" });
          else if ((error.response || {}).status === 404)
            dispatchCreditAnalysis({ type: "data_fetch_failure_404" });
          else dispatchCreditAnalysis({ type: "data_fetch_failure" });
        });
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  },[credit_proposal_natural_person_key])

  useEffect(() => {
    getNaturalPersonByKey()
  }, [getNaturalPersonByKey]);

  const [chainedNaturalPersons, dispatchChainedNaturalPersons] = useReducer(
    dataFetchReducer,
    { fetchedData: null, isLoading: true, isError: false }
  );

  const showChainedNaturalPerson = [
    "438a3f9b-9681-4e0d-bc58-350d2ed98983", //Zaig bg production
    "946fedd1-ae5d-430e-9d27-1a754801e705", //Zaig bg sandbox
    "456-456-456" //Zaig bg local
  ].includes(user_data.business_group_key)

  const getNaturalPersons = useCallback(() => {
    const timer = setTimeout(() => {
      let params = {
        "registration_id": registrationId
      }
      axios
        .get("/dash/credit_analysis/natural_persons", {params:params})
        .then((response) => {
          dispatchChainedNaturalPersons({
            type: "data_fetch_success",
            payload: response.data,
          });
        })
        .catch((error) => {
          if ((error.response || {}).status === 403)
            dispatchChainedNaturalPersons({ type: "data_fetch_failure_403" });
          else if ((error.response || {}).status === 404)
            dispatchChainedNaturalPersons({ type: "data_fetch_failure_404" });
          else dispatchChainedNaturalPersons({ type: "data_fetch_failure" });
        });
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  },[registrationId])

  useEffect(() => {
    if (showChainedNaturalPerson&&registrationId) {
      getNaturalPersons()
    }
  },[showChainedNaturalPerson, registrationId, getNaturalPersons])

  if (credit_analysis.fetchedData) {
    const pageStyle = `
            @page {
                size: 200mm 290mm;
            }
            @media print {
                html, body {
                    height: initial !important;
                    overflow: initial !important;
                    -webkit-print-color-adjust: exact;
                }
                .pagebreak {
                    page-break-before: always;

                  }
            }
            `;
    return (
      <div>
        <div
          style={{
            minWidth: "900px",
            overflow: "hidden",
            paddingBottom: "30px",
          }}
        >
          <div
            className="analysisCardContainer"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <ErrorBoundary>
              <div>
                <div className={["blueText", "titleSize"].join(" ")} style={{ margin: "5px 0px" }}>
                  {t("credit_analysis_natural_person_subtitle")}
                </div>
                {user_data.business_group_key!==null&&
                <div className={["labelText", "subtitleSize"].join(" ")} style={{ margin: "5px 0px" }}>
                  {credit_analysis.fetchedData.company_name}
                </div>}
                <div className={["labelText", "subtitleSize"].join(" ")} style={{ margin: "5px 0px" }}>
                  {credit_analysis.fetchedData.is_manual_analysis
                    ? `${t("manual_request")} `
                    : t("client") +
                      " #" +
                      (credit_analysis.fetchedData.id&&`${credit_analysis.fetchedData.id} `)}
                  {(credit_analysis.fetchedData || {}).created_at &&
                    t("created_date", {
                      created_date: credit_analysis.fetchedData.created_at,
                    })}
                </div>
              </div>
              {preparePDF ? (
                <div style={{ display: "flex" }}>
                  <ReactToPrint
                    trigger={() => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginBottom: "20px",
                        }}
                      >
                        {buttonExport2PDF ? (
                          <Button
                            button_option={"standard"}
                            buttonLabel={t("label_pdf_available")}
                          />
                        ) : (
                          <Button
                            button_option={"standard_disabled_white"}
                            buttonLabel={t("label_loading_pdf")}
                          />
                        )}
                      </div>
                    )}
                    content={() => pdf.current}
                    pageStyle={pageStyle}
                    documentTitle={`${t(
                      "credit_analysis_natural_person_subtitle"
                    )}}_${credit_analysis.fetchedData.document_number}`}
                  />
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "20px",
                  }}
                >
                  <Button
                    button_option={"standard"}
                    buttonLabel={t("label_export_pdf")}
                    onClick={() => {
                      setExpandedSCR(true);
                      setExpandedSerasa(true);
                      setExpandedBoavista(true);
                      setExpandedScorePositivo(true);
                      setPreparePDF(true);
                    }}
                  />
                </div>
              )}
            </ErrorBoundary>
          </div>
          {chainedNaturalPersons.fetchedData&&
          chainedNaturalPersons.fetchedData.data
          .filter((naturalPerson)=>(naturalPerson.credit_proposal_natural_person_key!==credit_proposal_natural_person_key))
          .map((naturalPerson)=>(
          <ChainedAnalysisNaturalPersonCards 
            preparePDF={preparePDF}
            setButtonExport2PDF={setButtonExport2PDF}
            pdf={pdf}
            credit_proposal_natural_person_key={naturalPerson.credit_proposal_natural_person_key}
            expandedStates={{expandedSCR, setExpandedSCR, expandedSerasa, setExpandedSerasa, expandedBoavista, setExpandedBoavista, expandedQuod, setExpandedQuod}}
          />))}
          {credit_analysis.fetchedData&&
          <CreditAnalysisNaturalPersonCards 
            credit_analysis={credit_analysis}
            preparePDF={preparePDF}
            setButtonExport2PDF={setButtonExport2PDF}
            pdf={pdf}
            credit_proposal_natural_person_key={credit_proposal_natural_person_key}
            expandedStates={{expandedSCR, setExpandedSCR, expandedSerasa, setExpandedSerasa, expandedBoavista, setExpandedBoavista, expandedQuod, setExpandedQuod, expandedScorePositivo, setExpandedScorePositivo}}
            getNaturalPersonByKey={getNaturalPersonByKey}
            isChained={false}
          />}
        </div>
      </div>
    );
  } else if (credit_analysis.isLoading) {
		return(
			<div style={{ width: "auto" }}>
				<div className='circularProgressCenter'>
					<CircularProgress />
				</div>
			</div>
		)
  } else if (credit_analysis.isError||(showChainedNaturalPerson&&chainedNaturalPersons.isError)) {
    return (
      <div style={{ height: "80vh", display: "flex" }}>
        <p
          className={["blueText", "titleSize"].join(" ")}
          style={{ margin: "auto", textAlign: "center" }}
        >
          {t(credit_analysis.errorMessage)}
        </p>
      </div>
    );
  }

  return null;
}

export default CreditAnalysisNaturalPerson;
