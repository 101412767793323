import React, {useState} from "react";
import BankslipLockTypes from "./AvailableLockTypes/BankslipLockTypes"
import BillPaymentLockTypes from "./AvailableLockTypes/BillPaymentLockTypes"
import WireTransferLockTypes from "./AvailableLockTypes/WireTransferLockTypes"
import WithdrawalLockTypes from "./AvailableLockTypes/WithdrawalLockTypes"
import TransactionLockTypes from "./AvailableLockTypes/TransactionLockTypes"
import DictOperationLockTypes from "./AvailableLockTypes/DictOperationLockTypes"

function SelectLockTypeContent (props){

    let {   setLockType, linkKeyType, 
            setLockedEventType, roles
        } = props
    const [openBankslipLockDialog, setOpenBankslipLockDialog] = useState(false)
    const [openBillPaymentLockDialog, setOpenBillPaymentLockDialog] = useState(false)
    const [openWireTransferLockDialog, setOpenWireTransferLockDialog] = useState(false)
    const [openWithdrawalLockDialog, setOpenWithdrawalLockDialog] = useState(false)
    const [openTransactionLockDialog, setOpenTransactionLockDialog] = useState(false)
    const [openDictOperationLockDialog, setOpenDictOperationLockDialog] = useState(false)

    if (openBankslipLockDialog===true){
        return (<BankslipLockTypes lockTypePrefix={linkKeyType} setLockType={setLockType}/>)
    }
    else if (openBillPaymentLockDialog===true){
        return (<BillPaymentLockTypes lockTypePrefix={linkKeyType} setLockType={setLockType}/>)
    }
    else if (openWireTransferLockDialog===true){
        return (<WireTransferLockTypes lockTypePrefix={linkKeyType} setLockType={setLockType}/>)
    }
    else if (openWithdrawalLockDialog===true){
        return (<WithdrawalLockTypes lockTypePrefix={linkKeyType} setLockType={setLockType}/>)
    }
    else if (openTransactionLockDialog===true){
        return (<TransactionLockTypes lockTypePrefix={linkKeyType} setLockType={setLockType}/>)
    }
    else if (openDictOperationLockDialog===true){
        return (<DictOperationLockTypes lockTypePrefix={linkKeyType} setLockType={setLockType}/>)
    }
    else return (
        <div style={{ flexWrap:"wrap", display:"flex", justifyContent: "space-evenly", margin:"auto 0px"}}>
            {roles.includes('create_bankslip_locks')?
                <div
                    className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")} 
                    onClick={() => {setOpenBankslipLockDialog(true); setLockedEventType('bankslip')}}
                    style={{width:"30%", margin:"10px 1px"}}
                >
                    Boleto
                </div>
                :null
            }
            {roles.includes('create_bill_payment_locks')?
                <div
                    className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")} 
                    onClick={() => {setOpenBillPaymentLockDialog(true); setLockedEventType('bill_payment')}}
                    style={{width:"30%", margin:"10px 1px"}}
                >
                    Pagamento de Contas
                </div>
                :null
            }
            {roles.includes('create_wire_transfer_locks')?
                <div
                    className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")} 
                    onClick={() => {setOpenWireTransferLockDialog(true); setLockedEventType('wire_transfer')}}
                    style={{width:"30%", margin:"10px 1px"}}
                >
                    Transferencia Bancária
                </div>
                :null
            }
            {roles.includes('create_withdrawal_locks')?
                <div
                    className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")} 
                    onClick={() => {setOpenWithdrawalLockDialog(true); setLockedEventType('withdrawal')}}
                    style={{width:"30%", margin:"10px 1px"}}
                >
                    Saque
                </div>
                :null
            }
            {roles.includes('create_pix_transaction_locks')?
                <div
                    className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")} 
                    onClick={() => {setOpenTransactionLockDialog(true); setLockedEventType('pix_transaction')}}
                    style={{width:"30%", margin:"10px 1px"}}
                >
                    Transação PIX
                </div>
                :null
            }
            {roles.includes('create_pix_dict_operation_locks')?
                <div
                    className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")} 
                    onClick={() => {setOpenDictOperationLockDialog(true); setLockedEventType('pix_dict_operation')}}
                    style={{width:"30%", margin:"10px 1px"}}
                >
                    Operação na DICT
                </div>
                :null
            }
        </div>
    )
}

export default SelectLockTypeContent