//Importing public modules modules
import React, {useEffect, useState} from "react";
import axios from "axios";
import { CircularProgress } from '@material-ui/core';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css'
import InnerImageZoom from 'react-inner-image-zoom'

//Defining ValidatedImage function
function ValidatedImage(props) {
    //Defining default image style and default alt text
    let imageStyle  = {margin:"auto", borderRadius: "5px"}
    let imageAlt    = ""
    
    
    //Extracting configurations variables from props
    if (props.imageStyle){
        imageStyle={...imageStyle, ...props.imageStyle}
    }

    if (props.imageAlt){
        imageAlt = props.imageAlt
    }
    

    //Getting image key and endpoint to make request
    const { image_key } = props
    const { dash_api_endpoint_url } = props
    

    //Declaring image variable
    const [image, setImage] = useState(null)


    //Making request to dash api to get image
    useEffect(() => {
        const timer = setTimeout(() => {
            if (image_key && dash_api_endpoint_url){
                setImage(null)
                axios.get(dash_api_endpoint_url + "/" + image_key,{responseType:"arraybuffer"}).then( response => {
                    let image = Buffer.from(response.data, 'binary').toString('base64')
                    if (image == null) throw new Error()
                    setImage(image);             
                }).catch(error => {
                    setImage(undefined)
                })
            }
            else {
                setImage(undefined)
            }
        }, 500);
        return () => {
            clearTimeout(timer)
        }
    }, [image_key, dash_api_endpoint_url])


    //Defining div to be rendered
    if (image === null){
        return (          
            <div 
                className={["labelText", "normalMediumSize"].join(" ")} 
                style={{textAlign:"center", margin:"auto"}}
            >
                <CircularProgress style={{margin:"auto"}} />
            </div>
        ) 
    }
    else {
        if (props.zoom_enabled) {
            return(<InnerImageZoom
                style   = {imageStyle}
                src     = {"data:image/jpg;base64," + image}
                alt     = {imageAlt}
                zoomScale = {1.5}
            />);
        }
        else {return (
            <img
                style   = {imageStyle}
                src     = {"data:image/jpg;base64," + image}
                alt     = {imageAlt}
            />
        );
        }
    }

}

export default ValidatedImage;
