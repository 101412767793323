import {colorGreen, colorGrey, colorVelvet, colorYellow, colorRed} from "./utils"


export const getInfractionReportType = (status) => {
    switch (status) {
        case "compliance":
            return "Compliance"
        case "fraud":
            return "Fraude"
        default:
            return status
    }
}

export const getInfractionReportCreator = (status) => {
    switch (status) {
        case "external":
            return "Externo"
        case "client":
            return "Cliente"
        default:
            return status
    }
}

export const getInfractionReportStatus = (status) => {
    switch(status){
        case "created":
            return "Criado";
        case "not_sent":
          	return "Não enviado";
        case "sent":
            return ("Enviado ao Banco Central");
        case "received":
            return ("Recebido");
        case "acknowledged":
            return ("Reconhecido por ambas as partes");
        case "closed_agreed":
            return ("Concluído de acordo");
        case "closed_disagreed":
            return "Concluído em desacordo";
        case "cancelled":
            return "Cancelado";
        default:
            return status;
    }
}
  
export const getInfractionReportStatusSiglum = (status) => {
    switch(status){
        case "created":
            return "C";
        case "not_sent":
          	return "N";
        case "sent":
            return ("E");
        case "received":
            return ("R");
        case "acknowledged":
            return ("R");
        case "closed_agreed":
            return ("A");
        case "closed_disagreed":
            return "D";
        case "cancelled":
            return "C";
        default:
            return status;
    }
}

export const getInfractionReportStatusColor = (status) => {
    switch(status){
        case "not_sent":
        case "closed_disagreed":
          	return colorRed;
        case "sent":
        case "received":
            return colorYellow;
        case "acknowledged":
            return colorVelvet;
        case "closed_agreed":
            return colorGreen;
        case "created":
        case "cancelled":
        default:
            return colorGrey;
    }
}

