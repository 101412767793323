import React from "react";
import { useTranslation } from "react-i18next";
import ScorePositivoSummaryTotal from "../ScorePositivoSummaryData/ScorePositivoSummaryTotal";

function ScorePositivoIncomeCard (props) {

  const { income } = props
  const { t } = useTranslation()

  return (
    <div  className="avoidPageBreakInside" style={{padding:"10px"}}>
      <div className="analysisCard" style={{height:"100%"}}>
        <div className={["blueText", "subtitleSize"].join(" ")} style={{marginBottom: "10px"}}>{t("Renda presumida")}</div>
        <div style={{display:"flex", justifyContent: "center", alignItems: "center"}}>
          <ScorePositivoSummaryTotal
              description={'amount'}
              total={income}
          />
        </div>
      </div>
    </div>
  )
}

export default ScorePositivoIncomeCard