import React from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Button from "../../../../@GeneralComponents/Button";

function ConfirmDeleteGroup (props) {

    const { t } = useTranslation();

    return (
        <Dialog
        open={props.open}
        onClose={props.onClose}
        keepMounted={false}
        fullWidth={true}
        maxWidth={"sm"}
        >
            <DialogTitle
                disableTypography
                className={['blueText', "subtitleSize", "dialogTitleWithIcon"].join(" ")}
                style={{padding:"20px 24px 0px"}}
            >
                {t("confirm_delete_group")}
                <IconButton onClick={props.onClose}>
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent >
                <div style={{display:"flex", flexDirection:"column"}}>
                    <div className="normalText normalMediumSize" style={{textAlign:"center"}}>
                        {t("confirm_delete_group_message")}
                    </div>
                    <Button
                        button_option={"standard"}
                        onClick={props.onDeleteGroup}
                        buttonLabel={t("confirm")}
                        buttonStyle={{minWidth:"252px", margin: "20px auto"}}
                        isLoading={props.saveEdit.isLoading}
                    />
                </div>
            </DialogContent>
        </Dialog>
    );
};
export default ConfirmDeleteGroup;
