import React from "react";
import DataField from "../../utils/DataField";
import { titleCase, formatAsMoney, formatAddress, formatListOfObjects } from "../../utils/utils"
import { useTranslation } from "react-i18next";
import MapIcon from "@material-ui/icons/Map";

function CreditAnalysisClientProfile (props) {

    const { t } = useTranslation()
    const { credit_analysis } = props
    
    return (
        <div className="analysisCard" style={{height:"100%"}}>
            <div className={["blueText", "subtitleSize"].join(" ")}>{t("basic_data")}</div>
            <div className="internalCardContainer" style={{display: "flex", flexDirection: "column"}}>
                <DataField
                    title={t("document_number")}
                    label={credit_analysis.document_number || t("not_available")}
                    size={"normalMediumSize"}
                    titleNoWrap={false}
                />
                {credit_analysis.name&& 
                <DataField
                    title={t("name")}
                    label={titleCase(credit_analysis.name, "-")}
                    size={"normalMediumSize"}
                    titleNoWrap={false}
                />}
                {credit_analysis.mother_name&& 
                <DataField
                    title={t("mother_name")}
                    label={titleCase(credit_analysis.mother_name, "-")}
                    size={"normalMediumSize"}
                    titleNoWrap={false}
                />}
                {credit_analysis.nationality&& 
                <DataField
                    title={t("nationality")}
                    label={titleCase(credit_analysis.nationality, "-")}
                    size={"normalMediumSize"}
                    titleNoWrap={false}
                />}
                {credit_analysis.birthdate&& 
                <DataField
                    title={t("birthdate")}
                    label={t("birthdate_format", {birthdate: credit_analysis.birthdate })}
                    size={"normalMediumSize"}
                    titleNoWrap={false}
                />}
                {credit_analysis.gender&& 
                <DataField
                    title={t("gender")}
                    label={titleCase(t(credit_analysis.gender), "-")}
                    size={"normalMediumSize"}
                    titleNoWrap={false}
                />}
                {credit_analysis.phones&& 
                <DataField
                    title={t("phones")}
                    label={formatListOfObjects(credit_analysis.phones, "phones", t)}
                    size={"normalMediumSize"}
                    titleNoWrap={false}
                />}
                {credit_analysis.email&&
                <DataField
                    title={t("email")}
                    label={credit_analysis.email ? credit_analysis.email : "-"}
                    size={"normalMediumSize"}
                    titleNoWrap={false}
                />}
                {credit_analysis.address&&
                <DataField
                    title={t("address")}
                    label={formatAddress(credit_analysis.address,"-")}
                    size={"normalMediumSize"}
                    titleNoWrap={false}
                >
                    <a
                        href={"https://www.google.com/maps/search/?api=1&query=" + 
                            formatAddress(credit_analysis.address,t("-")).replace(/ /g, "%20").replace(/,/, '%2C')}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <MapIcon style={{verticalAlign: "middle", width: "20px", height:"20px",cursor:"pointer", color:"#777",margin: "auto 5px"}} />
                    </a>
                </DataField>}
                {credit_analysis.monthly_income&&
                <DataField
                    title={t("monthly_income")}
                    label={credit_analysis.monthly_income ? formatAsMoney("BRL", credit_analysis.monthly_income) : t("not_available")}
                    size={"normalMediumSize"}
                    titleNoWrap={false}
                />}
                {credit_analysis.declared_assets&&
                <DataField
                    title={t("declared_assets")}
                    label={credit_analysis.declared_assets ? formatAsMoney("BRL", credit_analysis.declared_assets) : t("not_available")}
                    size={"normalMediumSize"}
                    titleNoWrap={false}
                />}
            </div>
        </div>
    );
}

export default CreditAnalysisClientProfile;



