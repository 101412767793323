import React, {useState} from 'react'
import { useTranslation } from "react-i18next";
import TextField from '@material-ui/core/TextField';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { getAnalyzeDialogType, getConfirmationIcon } from "../utils/utils"
import InputMask from "react-input-mask";
import axios from 'axios'
import { CircularProgress } from '@material-ui/core';
import ModalNaturalPerson from './ModalNaturalPerson';
import ModalLegalPerson from './ModalLegalPerson';

const theme = createMuiTheme({
    palette:{
        primary: {
            main:"#0b1f82"
        },
        secondary: {
            main: "#FF0000"
        },
    }
  });

function InputContent (props){
    const { t } = useTranslation();
    const [error, setError] = useState(true)
    const [value, setValue] = useState({
            document_number: "",
            name:""
    })
    const [pendingState, setPendingState] = useState(false)
    const [status, setStatus] = useState(null)
    const [personList, setPersonList] = useState("")
    const [openModal, setOpenModal] = useState(false)
    const [nameToSearch, setNameToSearch] = useState("")
    const [analyzedObj, setAnalyzedObj] = useState({})

    const {boxType, person_type} = props

    let inputObject = getAnalyzeDialogType(props.local_storage_object_preffix)

    const handleChange = (event) => {
        setValue({...value, [event.target.name]: event.target.value})
        if (event.target.name === 'document_number' && event.target.value.length !== inputObject.validation_size){ 
            setError(true)
        }
        else {
            setError(false)
        }
      }

    const handleBackClick = () => {
        props.setSelectCompany({
            status:true,
        })
    }

    const handleReturn = () => {
        props.onClose()
        props.setPageParams({page: 1, filters: JSON.parse(sessionStorage.getItem(props.local_storage_object_preffix + 'PageParams')).filters})
    }

    const onSubmit = (event) => {
        if (error) return
        let payload = {'document_number': value.document_number ? value.document_number : event.target.id}
        let requestHeaders = { headers:{'company_key': props.company_key}}
        setPendingState(true)
        axios.post('/dash/onboarding/' + inputObject.post_endpoint, payload, requestHeaders).then(response => {
            setPendingState(false)
            if (response.data.analysis_status === "pending" || response.data.analysis_status === "in_queue"){
                setStatus({success: true, message: "Em Processamento."})
            }
            else{
                setStatus({success: true, message: "Requisição processada com sucesso"})
            }
        }).catch(error => {
            setPendingState(false)
            let errorMessage = "Não foi possível processar sua requisição"
            if ((error.response || {}).status === 400) {
                errorMessage = "O documento inserido não é um número de documento válido."
            }
            if ((error.response || {}).status === 401) {
                if (((error.response || {}).data || {}).message === "maximum_demo_analysis_limit_reached") {
                    errorMessage = "O limite máximo de requisições teste na sua conta demonstração foi excedido."
                }
                else if (((error.response || {}).data || {}).message === "account_manual_requests_unauthorized") {
                    errorMessage = "Sua conta não permite análises manuais."
                }
            }
            if ((error.response || {}).status === 402) {
                errorMessage = "Requisição não autorizada pela carteira, verifique seus dados de pagamento e cadastro da cartão na página de pagamentos."
            }
            setStatus({success: false, message: errorMessage})
        });
    }


    const onSubmitQueryByName = () => {

        let name_to_search = value.name.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
        setNameToSearch(value.name)

        setPendingState(true)
        axios.get(`/dash/external_data_proxy/bigboost/${person_type}/basic_data?name=${name_to_search}`)
            .then(response => {
                let analyzed = {}
                for (let i = 0; i < response.data['data'].length; i++){
                    analyzed[i] = false
                }
                setAnalyzedObj({...analyzed})
                setPendingState(false)
                setStatus({success: true})
                setPersonList(response.data) 
        }).catch(error => {
            setPendingState(false)
            let errorMessage = "Não foi possível processar sua requisição" 
            if ((error.response || {}).status === 404 && error.response?.data?.message === "too_many_results") errorMessage = t("too_many_results") 
            setStatus({success: false, message: errorMessage})
        });
      }
    
    if (pendingState && !personList){
        return(
            <div style={{display:"flex", flexGrow:"1"}}>
                <CircularProgress style={{margin:"auto"}}/>
            </div>
        )
    }
    if (status != null) {
        return(
            <>
                {personList && openModal? 
                    person_type === 'natural_person'? 
                        <ModalNaturalPerson 
                            closeModal={handleReturn}
                            nameToSearch={nameToSearch}
                            personList={personList} analyzedObj={analyzedObj} setAnalyzedObj={setAnalyzedObj} onSubmit={onSubmit}
                        />
                        : 
                        <ModalLegalPerson 
                            closeModal={handleReturn} nameToSearch={nameToSearch}
                            personList={personList} analyzedObj={analyzedObj} setAnalyzedObj={setAnalyzedObj} onSubmit={onSubmit}
                        />
                    :
                    <div style={{ display:"flex", flexDirection:"column", flexGrow:"1"}}>
                        <div style={{margin:"auto"}}>
                            {getConfirmationIcon(status.success)}
                        </div>
                        <div style={{margin:"auto", textAlign: 'center'}}>
                            {status.message}
                        </div>
                        <div
                            onClick={handleReturn} 
                            className={["button","standard", "normalText", "normalMediumSize"].join(" ")} 
                            style={{margin:"20px auto 20px auto", width:"auto"}}
                        >
                            VOLTAR À PÁGINA INICIAL
                        </div>
                    </div>
                }
            </>
        )
    }

    return (
        <div style={{ display:"flex", flexDirection:"column", flexGrow:"1"}}>
            <div style={{margin:"10px auto"}}>
                {boxType=== 'queryByName' ?
                <ThemeProvider theme={theme}>
                    <TextField 
                        value={value.name}
                        name="name"
                        onChange={handleChange}
                        autoFocus 
                        id="standard-basic" 
                        color={error? "secondary": "primary"} 
                        label={"Digite o nome completo"}
                        onKeyPress= {(e) => {if (e.key === 'Enter') {onSubmitQueryByName(); setOpenModal(true)}}} 
                    />
            </ThemeProvider>
            :
                <ThemeProvider theme={theme}>
                    <InputMask 
                        mask={inputObject.mask_type}
                        maskPlaceholder=""
                        value={value.document_number}
                        name="document_number"
                        onChange={handleChange}
                    >
                        <TextField 
                            autoFocus 
                            id="standard-basic" 
                            color={error? "secondary": "primary"} 
                            label={"Digite o " + inputObject.label}
                            onKeyPress= {(e) => {if (e.key === 'Enter') {onSubmit()}}} 
                        />
                    </InputMask>
                </ThemeProvider>}
            </div>
            <div style={{display:"flex", flexDirection:"row", justifyContent: "center", margin:"auto 0px 20px 0px"}}>
                <div
                    className={error ? ["button","standard", "disabled", "normalText", "normalMediumSize"].join(" ") : ["button","standard", "normalText", "normalMediumSize"].join(" ")} 
                    onClick={boxType === 'queryByName' ? () => {onSubmitQueryByName(); setOpenModal(true)} : onSubmit } 
                    style={{width:"40%"}}
                >
                    {boxType === 'queryByName' ? "Pesquisar" : "Finalizar"}
                </div>
                {props.business_group_key == null ? null:
                <div
                    className={["button","cancel"].join(" ")} 
                    onClick={handleBackClick} 
                    style={{width:"40%"}}
                >
                    Retornar
                </div>
                }
            </div>
        </div>
    )

}

export default InputContent