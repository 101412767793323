import React, {useReducer, useEffect, useContext} from 'react'
import peopleIcon from '../../../../../assets/cardEntryIcons/people.svg';
import {dataFetchReducer} from "../../../../Utils/requestUtils"
import { CircularProgress } from '@material-ui/core';
import AuthContext from "../../../../../context/auth-context"
import axios from 'axios'
import {titleCase, clientAge, formatPhone, formatExtraInfo} from "../../../utils/utils"
import DataField from "../../../utils/DataField"
import Tooltip from "@material-ui/core/Tooltip";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import {Link} from 'react-router-dom'

function UserCard(props) {

    let user_data = useContext(AuthContext).user_data

    const [cardholder, dispatchCardholder] = useReducer(
        dataFetchReducer,
        {fetchedData: null, isLoading: true, isError: false} 
    )

    useEffect(() => {
        let payload = {}
        if (user_data.business_group_key){
            payload["company_key"] = props.alert.company_key
        }
		dispatchCardholder({type: "data_fetch_init"})
		const timer = setTimeout(() => {
			axios.get(
                '/dash/onboarding/registration_id/' + props.alert.cardholder_id, 
                {params: payload})
                .then( response => {
                    dispatchCardholder({
                        type: "data_fetch_success",
                        payload: response.data
                    })   
            }).catch(error => {
                if ((error.response || {}).status === 403) dispatchCardholder({type: "data_fetch_failure_403"})
                else if ((error.response || {}).status === 404) dispatchCardholder({type: "data_fetch_failure_404"})  
                else dispatchCardholder({type: "data_fetch_failure"})    
			})	
		}, 500);
		return () => {
			clearTimeout(timer)
        }
    }, [props.alert, user_data])



    if (cardholder.isLoading){
        return (
            <div className="analysisCard">
            <div style={{display: "flex"}}>
                <img className="cardTitleIconStyle" src={peopleIcon} alt="peopleIcon Logo" />
                <span className={["blueText", "subtitleSize", "labelSpace", "breakWord"].join(" ")}>Usuário {props.alert.cardholder_id}</span>
            </div>
            <div style={{display: "flex", flexGrow:"1"}}>
                <CircularProgress style={{margin:"auto"}}/>
            </div>
        </div>
        )
    }
    else if (cardholder.fetchedData){
        if (cardholder.fetchedData.client_type === 'natural_person') {
            return (
                <div className="analysisCard">
                    <div style={{display: "flex"}}>
                        <img className="cardTitleIconStyle" src={peopleIcon} alt="peopleIcon Logo" />
                        <span className={["blueText", "subtitleSize", "labelSpace", "breakWord"].join(" ")}>Usuário {props.alert.cardholder_id}</span>
                    </div>
                    <div className="internalCardContainer" style={{display: "flex", flexDirection: "column"}}>
                        <DataField
                            title={"CPF"}
                            label={cardholder.fetchedData.document_number || "-"}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="5px"
                            display={"inline"}
                        />
                        {cardholder.fetchedData.name ? 
                        <DataField
                            title={"Nome"}
                            label={titleCase(cardholder.fetchedData.name, "-")}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="5px"
                            display={"inline"}
                        /> : null}
                        {cardholder.fetchedData.birthdate ?
                        <DataField
                            title={"Data de Nascimento"}
                            label={clientAge(cardholder.fetchedData.birthdate, "-")}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="5px"
                            display={"inline"}
                        /> : null}
                        {cardholder.fetchedData.registration_date ?
                        <DataField
                            title={"Data de Cadastro"}
                            label={clientAge(cardholder.fetchedData.registration_date, "-", "years_months")}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="5px"
                            display={"inline"}
                        /> : null}
                        {(cardholder.fetchedData.phones || []).length > 0 ? 
                        <DataField
                            title={"Telefone"}
                            label={formatPhone(cardholder.fetchedData.phones[0], "-")}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="5px"
                            display={"inline"}
                        > 
                        {(cardholder.fetchedData.phones || []).length > 1 ?
                            <Tooltip title={<p className="tooltipText">{formatExtraInfo(cardholder.fetchedData.phones, "telefones")}</p>}>
                                <MoreHorizIcon style={{verticalAlign:"middle"}}/>
                            </Tooltip> : null}
                        </DataField>: null}
                        {(cardholder.fetchedData.emails || []).length > 0 ? 
                        <DataField
                            title={"Email"}
                            label={cardholder.fetchedData.emails[0].email || "-"}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="5px"
                            display={"inline"}
                        > 
                        {(cardholder.fetchedData.emails || []).length > 1 ?
                            <Tooltip title={<p className="tooltipText">{formatExtraInfo(cardholder.fetchedData.emails, "emails")}</p>}>
                                <MoreHorizIcon style={{verticalAlign:"middle"}}/>
                            </Tooltip> : null}
                        </DataField>: null}
                        {user_data.roles.includes("card_issuance_alert_read_onboarding_natural_person_by_key") ? 
                        <Link 
                            to={"/natural-person/" + cardholder.fetchedData.natural_person_key}
                            style={{marginRight: "auto"}} 
                            className={["link", "blueText", "normalMediumSize", "bold"].join(" ")}
                        >
                            Ver Cadastro
                        </Link>
                        :null}
                    </div>
                </div>
            )
        }
        else{
            return (
                <div className="analysisCard">
                    <div style={{display: "flex"}}>
                        <img className="cardTitleIconStyle" src={peopleIcon} alt="peopleIcon Logo" />
                        <span className={["blueText", "subtitleSize", "labelSpace", "breakWord"].join(" ")}>Usuário {props.alert.cardholder_id}</span>
                    </div>
                    <div className="internalCardContainer" style={{display: "flex", flexDirection: "column"}}>
                        <DataField
                            title={"CNPJ"}
                            label={cardholder.fetchedData.document_number || "-"}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="5px"
                            display={"inline"}
                        />
                        {cardholder.fetchedData.legal_name ? 
                        <DataField
                            title={"Razão Social"}
                            label={titleCase(cardholder.fetchedData.legal_name, "-")}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="5px"
                            display={"inline"}
                        /> : null}
                        {cardholder.fetchedData.trading_name ?
                        <DataField
                            title={"Nome Fantasia"}
                            label={titleCase(cardholder.fetchedData.trading_name, "-")}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="5px"
                            display={"inline"}
                        /> : null}
                        {cardholder.fetchedData.registration_date ?
                        <DataField
                            title={"Data de Cadastro"}
                            label={clientAge(cardholder.fetchedData.registration_date, "-", "years_months")}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="5px"
                            display={"inline"}
                        /> : null}
                        {(cardholder.fetchedData.phones || []).length > 0 ? 
                        <DataField
                            title={"Telefone"}
                            label={formatPhone(cardholder.fetchedData.phones[0], "-")}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="5px"
                            display={"inline"}
                        > 
                        {(cardholder.fetchedData.phones || []).length > 1 ?
                            <Tooltip title={<p className="tooltipText">{formatExtraInfo(cardholder.fetchedData.phones, "telefones")}</p>}>
                                <MoreHorizIcon style={{verticalAlign:"middle"}}/>
                            </Tooltip> : null}
                        </DataField>: null}
                        {(cardholder.fetchedData.emails || []).length > 0 ? 
                        <DataField
                            title={"Email"}
                            label={cardholder.fetchedData.emails[0].email || "-"}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="5px"
                            display={"inline"}
                        > 
                        {(cardholder.fetchedData.emails || []).length > 1 ?
                            <Tooltip title={<p className="tooltipText">{formatExtraInfo(cardholder.fetchedData.emails, "emails")}</p>}>
                                <MoreHorizIcon style={{verticalAlign:"middle"}}/>
                            </Tooltip> : null}
                        </DataField>: null}
                        {user_data.roles.includes("card_issuance_alert_read_onboarding_legal_person_by_key") ? 
                        <Link 
                            to={"/legal-person/" + cardholder.fetchedData.legal_person_key}
                            style={{marginRight: "auto"}} 
                            className={["link", "blueText", "normalMediumSize", "bold"].join(" ")}
                        >
                            Ver Cadastro
                        </Link>
                        :null}
                    </div>
                </div>
            )
        }
    }
    else if (cardholder.isError){
        return (
            <div className="analysisCard">
                <div style={{display: "flex"}}>
                    <img className="cardTitleIconStyle" src={peopleIcon} alt="peopleIcon Logo" />
                    <span className={["blueText", "subtitleSize", "labelSpace", "breakWord"].join(" ")}>Usuário {props.alert.cardholder_id}</span>
                </div>
                <div style={{display: "flex", flexGrow:"1"}}>
                    <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                        Informação Indisponível
                    </div>
                </div>
            </div>
        )
    }
    else return null
}

export default UserCard