import React from 'react'
import { useTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";

export default function Button (props){
        
    const { t } = useTranslation();

    const button_options = {
        standard_disabled: "button standard disabled normalText normalMediumSize",
        standard_disabled_white: "button standard disabled white normalText normalMediumSize",
        standard: "button standard normalText normalMediumSize",
        standard_onlyboarder:"button onlyboarder normalText normalMediumSize",
        standard_disabled_gray: "button standard disabled gray normalText normalMediumSize",
        standard_disabled_blue: "button standard disabled blue normalText normalMediumSize",
        cancel: "button cancel"
    }
    let buttonStyle = {minWidth:"40%", margin: "10px auto", alignItems: "center"}
    if (props.buttonStyle){
        buttonStyle=props.buttonStyle
    }

    if (props.isLoading) {
        return (
        <div
            className={button_options[props.button_option]}
            style={buttonStyle}
        >
            <CircularProgress style={{width:"12px", height:"12px", margin:"auto", color:"white"}}/>
        </div>
        )
    } else if (props.tooltip) {
        return (
        <Tooltip 
            title={<p className="tooltipText">{props.tooltip}</p>}
        >
            <div
                className={button_options[props.button_option]}
                onClick={props.onClick}
                style={props.children?{...buttonStyle, display: "flex", alignItems:"center"}:buttonStyle}
            >
                {props.children}
                {t(props.buttonLabel)}
            </div>
        </Tooltip>
        )
    } else {
        return (
            <div
                className={button_options[props.button_option]}
                onClick={props.onClick}
                style={props.children?{...buttonStyle, display: "flex", alignItems:"center"}:buttonStyle}
            >
                {props.children}
                {t(props.buttonLabel)}
            </div>
        )
    }
}