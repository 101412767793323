import React from 'react'
import {getAnalysisStatus, getAnalysisStatusColor} from "../../utils/utilsTransaction"
import TimeCounter from "./TimeCounter/TimeCounter"

function TimerBox (props) {
    if(props.dict_operation.analysis_status === "pending" || props.dict_operation.analysis_status === "in_manual_analysis"){
        return (
            <TimeCounter dict_operation={props.dict_operation}/>
        )
    }
    else{
        return(
            <div className="analysisCard" style={{height:"100%", borderWidth:"1px", borderStyle:"solid", borderColor:getAnalysisStatusColor(props.dict_operation.analysis_status)}}>
                <div 
                    className={["normalText", "substitleSize", "bold"].join(" ")} 
                    style={{margin:"auto", color:getAnalysisStatusColor(props.dict_operation.analysis_status)}}
                >
                    {getAnalysisStatus(props.dict_operation.analysis_status)}                    
                </div>
            </div>
        )
    }
}

export default TimerBox