import React, { useState} from 'react'
import ManualInsertDialog from './ManualInsertDialog/ManualInsertDialog';

function InsertItem (props){
    let {userData, companyKey} = props

    const[openManualInsertDialog,setOpenManualInsertDialog] = useState(false)

    return(
        <>
            <div 
                style={{ padding: "5px 30px", margin:"5px", width: "150px", float: "right"}}
                className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                onClick={() => setOpenManualInsertDialog(true)}
            >
                Inserir Item
            </div>
            <ManualInsertDialog
                open={openManualInsertDialog}
                list={props.list}
                companyKey={companyKey}
                userData={userData}
                handleReloadPage = {props.handleReloadPage}
                onClose={() => setOpenManualInsertDialog(false)}
            />
        </>
    )
}

export default InsertItem 