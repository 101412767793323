import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {getTableRowClass} from "../../../utils/utils"

const useStyles = makeStyles((theme) => ({
        tableStyle: {
            border: "none",
            boxShadow: "none",
            width:"50%"
        },
        blueText: {
            textAlign: "center",
            fontFamily: "Open Sans",
            fontWeight: "600",
            color: "#0b1f82"
        },
        tableText: {
            fontFamily: "Open Sans",
            fontSize: "12px",
            lineHeight: "16px",
            textAlign: "center",
            color: '#6F6F6F',
            margin: "10px",
            padding: "6px",
          },
        tableHeader: {
            textAlign: "center",
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: "normal",
            color: '#6F6F6F',
            fontSize: "16px",
            lineHeight: "22px",
            padding: "0px",
        }
}));

function StatisticsTableV2(props) {
    const classes = useStyles();
    useTheme();

    let {destination_statistics} = props
    let {statisticsType} = props

    console.log(destination_statistics)

    let row_counter = 0;

    if (!destination_statistics[statisticsType]) {
        return (
            <div className={["labelText", "normalMediumSize"].join(" ")}>Nenhuma informação disponível para essa tabela</div>
        );
    }

    return (
        <Paper className={classes.tableStyle}>
        <Table style={{ padding: "8px" }}>
            <TableHead>
            <TableRow>
                <TableCell className={classes.tableHeader}></TableCell>
                <TableCell className={classes.tableHeader}>90 dias</TableCell>
                <TableCell className={classes.tableHeader}>12 meses</TableCell>
                <TableCell className={classes.tableHeader}>60 meses</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
                {destination_statistics[statisticsType].application_frauds &&
                <TableRow key={0} className={getTableRowClass(row_counter++)}>
                    <TableCell className={classes.tableHeader}>Fraudes de aplicativo</TableCell>
                    <TableCell className={classes.blueText}>{destination_statistics[statisticsType].application_frauds.d90}</TableCell>
                    <TableCell className={classes.blueText}>{destination_statistics[statisticsType].application_frauds.m12}</TableCell>
                    <TableCell className={classes.blueText}>{destination_statistics[statisticsType].application_frauds.m60}</TableCell>
                </TableRow>}
                {destination_statistics[statisticsType].distinct_accounts &&
                <TableRow key={1} className={getTableRowClass(row_counter++)}>
                    <TableCell className={classes.tableHeader}>Contas distintas</TableCell>
                    <TableCell className={classes.blueText}>{destination_statistics[statisticsType].distinct_accounts.d90}</TableCell>
                    <TableCell className={classes.blueText}>{destination_statistics[statisticsType].distinct_accounts.m12}</TableCell>
                    <TableCell className={classes.blueText}>{destination_statistics[statisticsType].distinct_accounts.m60}</TableCell>
                </TableRow>}
                {destination_statistics[statisticsType].distinct_fraud_reporters &&
                <TableRow key={3} className={getTableRowClass(row_counter++)}>
                    <TableCell className={classes.tableHeader}>Reportadores distintos de fraude</TableCell>
                    <TableCell className={classes.blueText}>{destination_statistics[statisticsType].distinct_fraud_reporters.d90}</TableCell>
                    <TableCell className={classes.blueText}>{destination_statistics[statisticsType].distinct_fraud_reporters.m12}</TableCell>
                    <TableCell className={classes.blueText}>{destination_statistics[statisticsType].distinct_fraud_reporters.m60}</TableCell>
                </TableRow>}
                {destination_statistics[statisticsType].mule_accounts &&
                <TableRow key={2} className={getTableRowClass(row_counter++)}>
                    <TableCell className={classes.tableHeader}>Contas laranja</TableCell>
                    <TableCell className={classes.blueText}>{destination_statistics[statisticsType].mule_accounts.d90}</TableCell>
                    <TableCell className={classes.blueText}>{destination_statistics[statisticsType].mule_accounts.m12}</TableCell>
                    <TableCell className={classes.blueText}>{destination_statistics[statisticsType].mule_accounts.m60}</TableCell>
                </TableRow>}
                {destination_statistics[statisticsType].other_frauds &&
                <TableRow key={4} className={getTableRowClass(row_counter++)}>
                    <TableCell className={classes.tableHeader}>Outras fraudes</TableCell>
                    <TableCell className={classes.blueText}>{destination_statistics[statisticsType].other_frauds.d90}</TableCell>
                    <TableCell className={classes.blueText}>{destination_statistics[statisticsType].other_frauds.m12}</TableCell>
                    <TableCell className={classes.blueText}>{destination_statistics[statisticsType].other_frauds.m60}</TableCell>
                </TableRow>}
                {destination_statistics[statisticsType].rejected_reports &&
                <TableRow key={5} className={getTableRowClass(row_counter++)}>
                    <TableCell className={classes.tableHeader}>Reportes rejeitados</TableCell>
                    <TableCell className={classes.blueText}>{destination_statistics[statisticsType].rejected_reports.d90}</TableCell>
                    <TableCell className={classes.blueText}>{destination_statistics[statisticsType].rejected_reports.m12}</TableCell>
                    <TableCell className={classes.blueText}>{destination_statistics[statisticsType].rejected_reports.m60}</TableCell>
                </TableRow>}
                {destination_statistics[statisticsType].scammer_accounts &&
                <TableRow key={6} className={getTableRowClass(row_counter++)}>
                    <TableCell className={classes.tableHeader}>Contas golpistas</TableCell>
                    <TableCell className={classes.blueText}>{destination_statistics[statisticsType].scammer_accounts.d90}</TableCell>
                    <TableCell className={classes.blueText}>{destination_statistics[statisticsType].scammer_accounts.m12}</TableCell>
                    <TableCell className={classes.blueText}>{destination_statistics[statisticsType].scammer_accounts.m60}</TableCell>
                </TableRow>}
                {destination_statistics[statisticsType].settlements &&
                <TableRow key={7} className={getTableRowClass(row_counter++)}>
                    <TableCell className={classes.tableHeader}>Pagamentos</TableCell>
                    <TableCell className={classes.blueText}>{destination_statistics[statisticsType].settlements.d90}</TableCell>
                    <TableCell className={classes.blueText}>{destination_statistics[statisticsType].settlements.m12}</TableCell>
                    <TableCell className={classes.blueText}>{destination_statistics[statisticsType].settlements.m60}</TableCell>
                </TableRow>}
                {destination_statistics[statisticsType].total_frauds_transaction_amount &&
                <TableRow key={8} className={getTableRowClass(row_counter++)}>
                    <TableCell className={classes.tableHeader}>Total de fraudes transacionais</TableCell>
                    <TableCell className={classes.blueText}>{destination_statistics[statisticsType].total_frauds_transaction_amount.d90}</TableCell>
                    <TableCell className={classes.blueText}>{destination_statistics[statisticsType].total_frauds_transaction_amount.m12}</TableCell>
                    <TableCell className={classes.blueText}>{destination_statistics[statisticsType].total_frauds_transaction_amount.m60}</TableCell>
                </TableRow>}
            </TableBody>
        </Table>
        </Paper>
    );
}

export default StatisticsTableV2;