import moment from "moment"

export const generateDashboards = (roles, company_key, companies, companies_credit_analysis, business_group_key) => {
    let companiesEnum = {}
    let companiesCreditAnalysisEnum = {}

    if (companies){
    companies.forEach((company, index) =>{
        companiesEnum[company.company_key] = company.name;
    })}
    
    if (companies_credit_analysis){
    companies_credit_analysis.forEach((company, index) =>{
        companiesCreditAnalysisEnum[company.company_key] = company.name;
    })}

    console.log("companiesCreditAnalysisEnum", companiesCreditAnalysisEnum)

    let dashboards = roles.reduce((acc, role) => {
        let dashboard
        if (role === "read_pix_transaction_dashboard"){
            dashboard = {
                name: "Transações PIX",
                enum: "pix_transaction_dashboard",
                filters: {
                    dateRange: {
                        type: "date_range",
                        description: "Data",
                        defaultValue: {
                            endDate: moment().endOf('day'),
                            startDate: moment().subtract(30, 'd').startOf('day'),
                        },
                        disabled: false
                    }
                }
            }
            acc.push(dashboard)
        }
        else if (role === "read_wire_transfer_dashboard"){
            dashboard = {
                name: "Transferências",
                enum: "wire_transfer_dashboard",
                filters: {
                    dateRange: {
                        type: "date_range",
                        description: "Data",
                        defaultValue: {
                            endDate: moment().endOf('day'),
                            startDate: moment().subtract(30, 'd').startOf('day'),
                        },
                        disabled: false
                    }
                }
            }
            acc.push(dashboard)
        }
        else if (role === "read_bankslip_dashboard"){
            dashboard = {
                name: "Boletos",
                enum: "bankslip_dashboard",
                filters: {
                    dateRange: {
                        type: "date_range",
                        description: "Data",
                        defaultValue: {
                            endDate: moment().endOf('day'),
                            startDate: moment().subtract(30, 'd').startOf('day'),
                        },
                        disabled: false
                    }
                }
            }
            acc.push(dashboard)
        }
        else if (role === "read_alerts_dashboard"){
            dashboard = {
                name: "Alertas",
                enum: "alerts_dashboard",
                filters: {
                    dateRange: {
                        type: "date_range",
                        description: "Data",
                        defaultValue: {
                            endDate: moment().endOf('day'),
                            startDate: moment().subtract(30, 'd').startOf('day'),
                        },
                        disabled: false
                    }
                }
            }
            acc.push(dashboard)
        }
        else if (role === "read_pix_dict_operation_dashboard"){
            dashboard = {
                name: "Operações na DICT",
                enum: "pix_dict_operation_dashboard",
                filters: {
                    dateRange: {
                        type: "date_range",
                        description: "Data",
                        defaultValue: {
                            endDate: moment().endOf('day'),
                            startDate: moment().subtract(30, 'd').startOf('day'),
                        },
                        disabled: false
                    }
                }
            }
            acc.push(dashboard)
        }
        else if (role === "read_bill_payment_dashboard"){
            dashboard = {
                name: "Pagamento de Contas",
                enum: "bill_payment_dashboard",
                filters: {
                    dateRange: {
                        type: "date_range",
                        description: "Data",
                        defaultValue: {
                            endDate: moment().endOf('day'),
                            startDate: moment().subtract(30, 'd').startOf('day'),
                        },
                        disabled: false
                    }
                }
            }
            acc.push(dashboard)
        }
        else if (role === "read_onboarding_dashboard"){
            if (company_key === '336fd1a2-a3df-4194-af12-c8e144cf6fdd') {
                dashboard = {
                    name: "Onboarding",
                    enum: "onboarding_dashboard_company_abastece_ai",
                    filters: {
                        dateRange: {
                            type: "date_range",
                            description: "Data",
                            defaultValue: {
                                endDate: moment().endOf('day'),
                                startDate: moment().subtract(30, 'd').startOf('day'),
                            },
                            disabled: false
                        }
                    }
                }
            } 
            else if (business_group_key === 'abe8ca59-1f9b-4f52-8d85-d209057a18aa') {
                dashboard = {
                    name: "Onboarding",
                    enum: "onboarding_dashboard_magazine_luiza",
                    filters: {
                        dateRange: {
                            type: "date_range",
                            description: "Data",
                            defaultValue: {
                                endDate: moment().endOf('day'),
                                startDate: moment().subtract(30, 'd').startOf('day'),
                            },
                            disabled: false
                        }
                    }
                }
            }
            else {
                dashboard = {
                    name: "Onboarding",
                    enum: "onboarding_dashboard",
                    filters: {
                        dateRange: {
                            type: "date_range",
                            description: "Data",
                            defaultValue: {
                                endDate: moment().endOf('day'),
                                startDate: moment().subtract(30, 'd').startOf('day'),
                            },
                            disabled: false
                        }
                    }
                }
            }
            acc.push(dashboard)
        }
        else if (role === "read_onboarding_filtered_company_dashboard") { 
            if (business_group_key === 'bff7e9ea-9381-407b-8da9-2c8d3092bda4') {
                dashboard = {
                    name: "Onboarding - Cliente",
                    enum: "ONBOARDING_FILTERED_COMPANY_DASHBOARD_QI_TECH",
                    filters: {
                        dateRange: {
                            type: "date_range",
                            description: "Data",
                            defaultValue: {
                                endDate: moment().endOf('day'),
                                startDate: moment().subtract(30, 'd').startOf('day'),
                            },
                            disabled: false
                        }
                    }
                }
            }
            else if (business_group_key === '065a33b1-74b6-47f6-85ab-4c78656c2278') {
                dashboard = {
                    name: "Onboarding",
                    enum: "onboarding_dashboard_abastece_ai",
                    filters: {
                        CompanyKeyParameter: {
                            type: "enum",
                            description: "Cliente",
                            enumList: companiesEnum,
                            defaultValue: Object.keys(companiesEnum)[0]
                        },
                        dateRange: {
                            type: "date_range",
                            description: "Data",
                            defaultValue: {
                                endDate: moment().endOf('day'),
                                startDate: moment().subtract(30, 'd').startOf('day'),
                            },
                            disabled: false
                        }
                    }
                }
            }
            else {
                dashboard = {
                    name: "Onboarding - Cliente",
                    enum: "onboarding_filtered_company_dashboard",
                    filters: {
                        CompanyKeyParameter: {
                            type: "enum",
                            description: "Cliente",
                            enumList: companiesEnum,
                            defaultValue: Object.keys(companiesEnum)[0]
                        },
                        dateRange: {
                            type: "date_range",
                            description: "Data",
                            defaultValue: {
                                endDate: moment().endOf('day'),
                                startDate: moment().subtract(30, 'd').startOf('day'),
                            },
                            disabled: false
                        }
                    }
                }
            }
            acc.push(dashboard)
        }
        else if (role === "read_credit_analysis_filtered_company_dashboard") { 
            dashboard = {
                name: "Credit Analysis - Customer",
                enum: "credit_analysis_filtered_company_dashboard",
                filters: {
                    CompanyKeyParameter: {
                        type: "enum",
                        description: "Cliente",
                        enumList: companiesCreditAnalysisEnum,
                        defaultValue: Object.keys(companiesCreditAnalysisEnum)[0]
                    },
                    dateRange: {
                        type: "date_range",
                        description: "Data",
                        defaultValue: {
                            endDate: moment().endOf('day'),
                            startDate: moment().subtract(30, 'd').startOf('day'),
                        },
                        disabled: false
                    }
                }
            }
            acc.push(dashboard)
        }
        else if (role === "rental_agreements_dashboard_viewer"){
            dashboard = {
                name: "Aluguéis",
                enum: "rental_agreements_dashboard",
                filters: {
                    dateRange: {
                        type: "date_range",
                        description: "Data",
                        defaultValue: {
                            endDate: moment().endOf('day'),
                            startDate: moment().subtract(30, 'd').startOf('day'),
                        },
                        disabled: false
                    }
                }
            }
            acc.push(dashboard)
        }
        
        return acc
    }, [])
    dashboards = dashboards.filter(function(dashboard) {
        return dashboard != null;
    });
    return dashboards
}