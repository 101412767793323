import React from "react";
import Card from "@material-ui/core/Card";
import {
  getAnalysisStatusColor,
  getAnalysisStatusSiglum,
  calculateDaysDifference
} from "../../utils/utils"
import Tooltip from "@material-ui/core/Tooltip";
import { useTranslation } from "react-i18next";

function KeyCard(props){
  const { t } = useTranslation();
  let { apikey, setOpenKeyActionDialog} = props

  let key = apikey.api_key

  if (!key) {
    key = apikey.web_token
  }

  let status = "active"
  if (apikey.deactivated_on !== null) {
    status = "deactivated"
  }


  const disableButton = status === "deactivated"

  return (
    <Card className="listCard">
      <div
        className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
        style={{flex:"1 1 130px", textAlign:"center"}}
      >
        {key}
      </div>
      <div
        className={["listCardItem", "normalText", "normalMediumSize", "bold"].join(" ")}
        style={{flex:"1 1 45px", textAlign:"center", cursor: "default"}}
      >
        <Tooltip title={<p className="tooltipText" style={{textAlign: "center"}}>
          {t(status)}
        </p>}>
        <div className={["circle", "small"].join(" ")} style={{backgroundColor:getAnalysisStatusColor(status)}}>
          {getAnalysisStatusSiglum(status)}
        </div>
        </Tooltip>
      </div>
      <div
        className={["listCardItem", "normalText", "subtitleSize","noWrap"].join(" ")}
        style={{flex:"1 1 110px", textAlign:"center"}}
      >
        {calculateDaysDifference(new Date(apikey.created_at), new Date())} {t('days')}
      </div>
      { disableButton ? (
        <div
        className={["listCardItem", "bold", "normalSmallSize"].join(" ")}
        style={{flex:"1 1 25px", textAlign:"center",}}
      ></div>
      ):
      <div
        className={["listCardItem", "normalText", "normalMediumSize", "bold"].join(" ")}
        style={{flex:"1 1 20px", textAlign:"center", cursor: "default"}}
      >
        <button
          style={{
            backgroundColor: '#d96159',
            color: '#fff',
            border: 'none',
            cursor: "pointer",
            padding: '5px 15px',
            margin:"5px",
            width:"150px"
          }}
          className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
          onClick={() => setOpenKeyActionDialog({'open': true, 'action': 'deactivate_key', 'key': key})}
          disabled={status === "deactivated"}
        >
        {t('deactivate')}
        </button>
      </div>
      }
    </Card>
  )
}

export default KeyCard
