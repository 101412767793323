//Importing modules
import React, { useState, useReducer } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import uploadIcon from "../../../../../assets/cardEntryIcons/Upload.svg"
import {decisionReducer} from "../../../../Utils/requestUtils";
import {getConfirmationIconDialog,translateErrorMessage} from "../../../utils/utils";
import { CircularProgress } from '@material-ui/core';
import axios from "axios";


//Defining dialog styling
const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });


//Defining  dialog title styling
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


//Defining DeleteCSVDialog component
function DeleteCSVDialog(props){

    //Extracting data from props
    const { list, handleReloadPage, userData, companyKey } = props


    //Extracting
    const [uploadedFile,setUploadedFile] = useState(null)
    const [uploadedFileName, setUploadedFileName] = useState(null)
    const [customizedErrorMessage, setCustomizedErrorMessage] = useState("")
    const hiddenFileInput = React.useRef(null);
    let fileReader


    //
    const [dialogState, setDialogState] = useReducer(
      decisionReducer,
      {isLoading: false, isError: false, finished:false}
    ) 

    const onSubmit = () => {
      let payload = {base64_file:uploadedFile}
      setDialogState({type:"send_request_init"})
      let requestHeaders = {headers:{}}
      if (userData.business_group_key) {
          requestHeaders = {headers:{company_key:companyKey}}
      }
      axios.put('/dash/lists/list/' + list.list_key + '/items/csv', payload, requestHeaders).then(response => {
          setDialogState({type:"send_request_success"})
      }).catch(error => {
          setDialogState({type: "send_request_failure"})
          setCustomizedErrorMessage(error.response.data.message)
      });
    }

    //
    const handleDialogClose = () => {
      setUploadedFile(null)
      setUploadedFileName(null)
      props.onClose()
    }


    //
    const handleUploadedFile = (file) => {
      fileReader = new FileReader()
      fileReader.onloadstart = setUploadedFile(undefined)
      fileReader.onloadend = handleFileRead
      fileReader.readAsArrayBuffer(file)
      setUploadedFileName(file.name)
    }
    

    //
    const getFileUploadMessage = (file) => {
      let message
      if (file === null) {
        message = "Para excluir itens da lista por arquivo, anexe um arquivo .csv clicando no botão abaixo."
      }
      else if (file === undefined){
        message = "Carregando Arquivo"
      }
      else {
        message = uploadedFileName
      }
      return message
    }


    //
    const handleFileRead = (e) => {
        const content = fileReader.result;
        let binary = '';
        let bytes = new Uint8Array( content );
        let len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode( bytes[ i ] );
        }
        setUploadedFile(btoa(binary))
    }

    
    //
    const handleUploadButtonClick = e => {
      hiddenFileInput.current.click();
    };


    //
    if (dialogState.finished && dialogState.isLoading){
      return (
          <Dialog 
              open={props.open} 
              onClose={handleDialogClose}
              keepMounted={false}
              disableBackdropClick
              disableEscapeKeyDown
          >
              <DialogTitle 
                style={{paddingBottom: "0px"}} 
                className={["blueText", "normalMediumSize"].join(" ")}
                onClose={handleDialogClose}
              >
                Exclusão de itens via CSV
              </DialogTitle>
              <DialogContent>
                  <div style={{display:"flex", width: "310px", height:"217px"}}>
                      <CircularProgress style={{margin:"auto"}} />
                  </div>
              </DialogContent>
          </Dialog>
      )
  }
  else if (dialogState.finished){
      let confirmationData = {    
          message: dialogState.message,
          success: !dialogState.isError
      }
      return (
          <Dialog 
              open={props.open} 
              onClose={handleDialogClose}                 
              keepMounted={false}
              disableBackdropClick
          >
              <DialogTitle 
                style={{paddingBottom: "0px"}} 
                className={["blueText", "normalMediumSize"].join(" ")}
                onClose={() => {
                  handleDialogClose()
                  handleReloadPage()
                }}
              >
                Exclusão de itens via CSV
              </DialogTitle>
              <DialogContent>
              <div style={{width: "310px", minHeight:"217px"}}>
                  <div className="internalCardContainer" style={{display:"flex", height:"40%", justifyContent:"center"}}>
                      {getConfirmationIconDialog(confirmationData.success)}
                  </div>
                  <div className="internalCardContainer" style={{display:"flex", textAlign:"center", justifyContent:"center"}}>
                      {confirmationData.success ? confirmationData.message : translateErrorMessage(customizedErrorMessage)}
                  </div>
                  <div className="internalCardContainer" style={{display:"flex", justifyContent:"center"}}>
                    <div 
                        className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                        onClick={() => {
                            handleDialogClose()
                            handleReloadPage()
                        }} 
                    >
                        VOLTAR À TELA INICIAL
                    </div>
                </div>
              </div>
              </DialogContent>
          </Dialog>
      )
  }
  else{
    return(
      <Dialog 
      open={props.open} 
      onClose={handleDialogClose}
      disableBackdropClick
      maxWidth="lg"
      keepMounted={false}>
          <DialogTitle 
              className={['blueText', "subtitleSize"].join(" ")} 
              onClose={handleDialogClose}
          >
              Exclusão de itens via CSV
          </DialogTitle>
          <DialogContent >
              <div style={{width:"320px", minHeight: "217px", display:"flex", flexDirection:"column", marginBottom:"30px"}}>
                  <div className={["normalMediumSize"].join(" ")} style={{marginTop:"40px",marginBottom:"50px", textAlign:"center"}}>
                      {getFileUploadMessage(uploadedFile)}
                  </div>
                  <div style={{display:"flex",flexDirection:"row"}}>
                    <div className={["button","blueText", "dialogInfoSize"].join(" ")} style={{margin:"auto", textAlign:"center"}} onClick={handleUploadButtonClick}>
                        <img className="cardTitleIconStyle" style={{height: "20px", marginRight:"10px"}} src={uploadIcon} alt="uploadIcon Logo" />
                        {uploadedFile === null ? "Anexar Arquivo" : "Substituir Arquivo"}
                    </div>
                    <input
                          type="file"
                          accept=".csv"
                          ref={hiddenFileInput}
                          onChange={e => handleUploadedFile(e.target.files[0])}
                          style={{display:"none"}}
                    />
                  </div>
                  <div onClick={uploadedFile == null ? null : onSubmit} 
                       style={{display:"flex", marginTop: "40px", marginLeft:"auto", cursor:"pointer", paddingLeft:"20px", paddingRight:"20px", fontWeight:"600"}}
                       className={uploadedFile == null ? 
                       ["button","standard", "disabled", "normalText", "normalMediumSize"].join(" ") : 
                       ["button","standard", "normalText", "normalMediumSize"].join(" ")}>
                    ENVIAR
                  </div>
              </div>
          </DialogContent>
      </Dialog>
    )
  }

}

export default DeleteCSVDialog