import React, { useReducer, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import axios from "axios"
import { CircularProgress } from '@material-ui/core';
import ConfirmationContent from "./ConfirmationContent"
import {decisionReducer} from "../../../../Utils/requestUtils"
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createMuiTheme({
    overrides: { MuiInputBase: { root:{
        fontFamily: "Open Sans",
        fontSize: "14px",
        lineHeight: "19px",
        background: "#F7F7F7"

    }}},
    palette:{
        primary: {
            main:"#0b1f82"
        },
        secondary:{
            main:"#D91A1A"
        }
    }
  });

const getInfractionReportStatus = (status) => {
    switch (status) {
        case "acknowledged":
            return "Reconhecer Relato"
        case "closed_agreed":
            return "Confirmar Relato"
        case "closed_disagreed":
            return "Negar Relato"
        case "cancelled":
            return "Cancelar Relato"
        default:
            return status
    }
}

function InfractionDialog(props) {

    const {infraction_new_status} = props
    const {infraction_report_key} = props
    const {transaction_key} = props

    let title = getInfractionReportStatus(infraction_new_status)

    const [dialogState, dispatchDialogState] = useReducer(
        decisionReducer,
        {isLoading: false, isError: false, finished:false}
    )
    
    const [observation, setObservation] = useState("")

    const handleObservationBoxChange = (event) => {
        setObservation(event.target.value)
    };

    const handleDialogClose = () => {
        props.onClose()
        setTimeout(()=>{
            dispatchDialogState({type:"default_state"})
            setObservation("")
        },300)
    }

    const handleFinish = () =>{
        dispatchDialogState({type:"send_request_init"})
        let payload={
            infraction_report_status: infraction_new_status
        }
        if (observation && observation !== ""){
            payload = {...payload, observation: observation}
        }
        setTimeout(function () {
            axios.put('/dash/pix/transaction/'+ transaction_key +'/infraction_report/' + infraction_report_key, payload).then(response => {
                dispatchDialogState({type:"send_request_success"})
              }).catch(error => { 
                if ((error.response || {}).status === 403) dispatchDialogState({type: "send_request_failure_403"})
                else if ((error.response || {}).status === 409) dispatchDialogState({type: "send_request_failure_409"})  
                else dispatchDialogState({type: "send_request_failure"})    
              });
        }, 1000);

    }

    if (dialogState.finished && dialogState.isLoading){
        return (
            <Dialog 
                open={props.open} 
                onClose={handleDialogClose} 
                keepMounted={false}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle style={{paddingBottom: "0px"}} className={["blueText", "normalMediumSize"].join(" ")}>{title}</DialogTitle>
                <DialogContent>
                    <div style={{display:"flex", width: "310px", height:"217px"}}>
                        <CircularProgress style={{margin:"auto"}} />
                    </div>
                </DialogContent>
            </Dialog>

        )
    }
    else if (dialogState.finished){
        let confirmationData = {    
            message: dialogState.message,
            success: !dialogState.isError
        }
        return (
            <Dialog 
                open={props.open} 
                onClose={handleDialogClose} 
                keepMounted={false}
                disableBackdropClick
            >
                <DialogTitle style={{paddingBottom: "0px"}} className={["blueText", "normalMediumSize"].join(" ")}>Confirmação de Revisão</DialogTitle>
                <DialogContent>
                    <ConfirmationContent onClose={handleDialogClose} data={confirmationData} />
                </DialogContent>
            </Dialog>
        )
    }
    return (
        <Dialog 
            open={props.open} 
            onClose={handleDialogClose} 
            keepMounted={false}
        >
            <DialogTitle style={{paddingBottom: "0px"}} className={["blueText", "normalMediumSize"].join(" ")}>{title}</DialogTitle>
            <DialogContent>
                <div>
                    <div>
                        <ThemeProvider theme={theme}>
                        <TextField
                            fullWidth
                            multiline
                            variant="outlined"
                            rows="5"
                            color="primary"
                            defaultValue={""}
                            disabled={false}
                            onChange={handleObservationBoxChange}
                        />
                        </ThemeProvider>
                    </div>
                    <div className="internalCardContainer" style={{display:"flex", justifyContent:"center"}}>
                        <div 
                            className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                            onClick={handleFinish}
                        >
                            Confirmar
                        </div>
                        <div 
                            className={["button", "cancel", "normalText", "normalMediumSize"].join(" ")}
                            onClick={handleDialogClose}
                        >
                            Cancelar 
                        </div>
                  </div>
              </div>
          </DialogContent>
        </Dialog>
    )
}

export default InfractionDialog;