import React, { useState, Component, useEffect } from "react";
import DataField from "../../utils/DataField"
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import HelpIcon from "@material-ui/icons/Help";
import PriceDescriptionDialog from "./PriceDescriptionDialog/PriceDescriptionDialog";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import {checkNested, titleCase, formatAsMoney, getReservationFormat, getRentalDaysFormat} from "../../utils/utils"

function Rental(props) {

    let [priceDescriptionOpen, setPriceDescriptionOpen] = useState(false);
    let [carGroup, setCarGroup] = useState(null);

    useEffect(() => {
        var car_groups = require("../../../../assets/json/group_cars.json");
        let car_group_ = checkNested(props.rental_agreement, "car", "model_group") ? car_groups[props.rental_agreement.car.model_group] : null
        setCarGroup(car_group_);
    }, [props]);

    let cupon_used = true;
    let cupon_icon = cupon_used ? (
        <MonetizationOnIcon style={{fontSize: "14px", display:"flex",color: "#30933F"}} />
    ) : (
        <MoneyOffIcon style={{fontSize: "14px", display:"flex"}} />
    );

    const handlePriceDescriptionOpenClick = () => {
        setPriceDescriptionOpen(true);
    };

    const handlePriceDescriptionClose = () => {
        setPriceDescriptionOpen(false);
    };


    let car_group = (
        <DataField
            title={"Grupo"}
            label={checkNested(props.rental_agreement, "car", "model_group") ? props.rental_agreement.car.model_group : "Indisponível"} 
            size={"normalMediumSize"}
            titleNoWrap={true}
            margin="10px"
        />
    );

    if (carGroup) {
        car_group = (
            <DataField
                title={"Grupo"}
                label={carGroup.model_group + " - " + carGroup.group_description + "  "}
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="10px"
            >
                <Tooltip title={<p className="tooltipText">{carGroup.cars.join("; ")}</p>} arrow placement="right">
                    <HelpIcon style={{fontSize: "14px", display:"flex", margin:"auto 5px"}}/>
                </Tooltip>
            </DataField>
        );
    }

    let rental_store_type = props.rental_agreement.rental_store_type ? ` - ${props.rental_agreement.rental_store_type}` : ""

    return (
        <div className="analysisCard">
        <div className={["blueText", "subtitleSize"].join(" ")}>Locação</div>
        <div className={["labelText", "normalSmallSize"].join(" ")}>
            {props.rental_agreement.rental_store + rental_store_type + 
            ", " +
            titleCase((props.rental_agreement.reservation || {}).channel, "-")}
        </div>
        <div>
            {car_group}
            <DataField
                title={"Grupo de Upgrade"}
                label={(((props.rental_agreement||{}).car||{}).upgrade_model_group||"-")}                 
                size={"normalMediumSize"}
                titleNoWrap={true}
            />
            <DataField
                title={"Canal de Venda"}
                label={titleCase((props.rental_agreement.reservation || {}).sales_channel, "Indisponível")}
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="10px"
            />
            <DataField
                title={"Data da Reserva"}
                label={getReservationFormat(props.rental_agreement)}
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="10px"
                display={"inline"}
            />
            <DataField
                title={"Data de Retirada"}
                label={moment(props.rental_agreement.rental_agreement_date).format("DD/MM/YYYY - HH:mm")}
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="10px"
            />
            <DataField
                title={"Data de Devolução"}
                label={getRentalDaysFormat(props.rental_agreement)}
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="10px"
            />
            <DataField
                title={"Nome da Tarifa"}
                label={checkNested(props.rental_agreement, "fare_name") ? props.rental_agreement.fare_name : "-"}
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="10px"
                display={"inline"}
            />
            <DataField
                title={"Valor Total do Aluguel"}
                label={checkNested(props.rental_agreement, "final_price") ? 
                    formatAsMoney(props.rental_agreement.final_price, "-") : "-"
                }
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="10px"
            >
                <div style={{display:"flex", alignItems:"center"}}>
                    {cupon_icon}
                    <span
                        className={["blueText", "normalMediumSize", "link"].join(" ")}
                        onClick={handlePriceDescriptionOpenClick}
                    >
                        Ver detalhes
                    </span>
                </div>
            </DataField>
            <DataField
                title={"Valor da Pré-Autorização"}
                label={checkNested(props.rental_agreement, "pre_authorization_amount") ? 
                    formatAsMoney(props.rental_agreement.pre_authorization_amount, "-") : "-"
                }
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="10px"
            />
            <Coverages coverages={props.rental_agreement.coverages} />
            <DataField
                title={"Tipo de Voucher"}
                label={checkNested(props.rental_agreement, "billing", "voucher_type") ? props.rental_agreement.billing.voucher_type || "-" : "-"}
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="10px"
            >
            </DataField>

        </div>
        <PriceDescriptionDialog
            open={priceDescriptionOpen}
            onClose={handlePriceDescriptionClose}
            rental_agreement={props.rental_agreement}
        />
        </div>
    );
}

export default Rental;

class Coverages extends Component {
    constructor(props) {
        super(props);
        this.coverages = this.props.coverages;
    }

    render() {
        if (this.coverages == null) this.coverages = []
        if (this.coverages.length === 0 || this.coverages.length === 1) {
        return (
            <DataField
                title={"Proteção Escolhida"}
                label={this.coverages.length === 0 ? "O cliente não selecionou nenhuma proteção" :
                    (this.coverages[0].description || "-") + " - " + formatAsMoney(this.coverages[0].price)
                }
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="10px"
                display="inline"
            />
        );
        } 
        else {
        return (
            <div>
            <DataField
                title={"Proteção Escolhida"}
                label={""}
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="10px"
                display="inline"
            />
            <ul style={{ margin: "0" }}>
                {this.coverages.map((coverage, index) => (
                <li
                    key={index}
                    className={["labelText", "normalMediumSize"].join(" ")}
                    style={{margin: "3px"}}
                >
                    {(coverage.description || "-") + " - " + formatAsMoney(coverage.price)}
                </li>
                ))}
            </ul>
            </div>
        );
        }
    }
}
