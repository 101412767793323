import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { getOperationStatusIcon } from "../../../../utils/utilsOperations"
import moment from 'moment'
import {getTableRowClass,getAnalysisStatus,
        getAnalysisStatusSiglum, getAnalysisStatusColor, formatAsMoneyUniversal} from "../../../../utils/utils"
import { Link } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import { getOperationCodeInfo } from "../../../../utils/utilsOperations";

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
  },
  blueText: {
    fontFamily: "Open Sans",
    fontWeight: "600",
    color: "#0b1f82"
},
  tableText: {
    fontFamily: "Open Sans",
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "center",
    margin: "10px",
    padding: "6px",
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: '#6F6F6F',
    fontSize: "14px",
    lineHeight: "19px",
    padding: "6px",
  }
}));

function OperationTable(props) {
    const classes = useStyles();
    useTheme();

    return (
        <Paper className={classes.tableStyle}>
            <Table style={{ padding: "8px" }}>
                <TableHead>
                <TableRow>
                    <TableCell className={classes.tableHeader}>ID</TableCell>                    
                    <TableCell className={classes.tableHeader}>Valor de Origem</TableCell>
                    <TableCell className={classes.tableHeader}>Valor de Destino</TableCell>
                    <TableCell className={classes.tableHeader}>Contraparte</TableCell>
                    <TableCell className={classes.tableHeader}>Tipo</TableCell>
                    <TableCell className={[classes.tableHeader]} style={{width: "10%"}}>Status de Análise</TableCell>
                    <TableCell className={[classes.tableHeader]} style={{width: "10%"}}>Data</TableCell>
                    <TableCell className={[classes.tableHeader]} style={{width: "10%"}}>Status da Operação</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                    {props.historic.map((transaction,index) => (
                    <TableRow
                        key={index}
                        className={getTableRowClass(index)}
                    > 
                        <TableCell
                            className={[classes.tableText, classes.blueText, "breakWord", "link"].join(" ")}
                        >
                            <Link
                                className="link"
                                to={"/currency_exchange" + transaction.operation_key}
                            >
                                {transaction.operation_key}
                            </Link>
                        </TableCell>
                        <TableCell className={classes.tableText}>
                            {formatAsMoneyUniversal(transaction.source_currency, transaction.source_amount, "-")}
                        </TableCell>
                        <TableCell className={classes.tableText}>
                            {formatAsMoneyUniversal(transaction.destination_currency, transaction.destination_amount, "-")}
                        </TableCell>
                        <TableCell className={[classes.tableText, "breakWord"].join(" ")}>
                            {transaction.counterpart.name}            
                        </TableCell>
                        <TableCell className={classes.tableText}>
                            <Tooltip title={<p className="tooltipText">{getOperationCodeInfo(transaction.operation_code)}</p>}>
                                <div className={[classes.tableText, "textOverflow"].join(" ")}>
                                {transaction.operation_code}  
                                </div>
                            </Tooltip>
                        </TableCell>
                        <TableCell className={classes.tableText}>
                            <Tooltip title={<p className="tooltipText">{getAnalysisStatus(transaction.analysis_status)}</p>}>
                                <div className={["circle", "small"].join(" ")} style={{backgroundColor:getAnalysisStatusColor(transaction.analysis_status)}}>
                                    {getAnalysisStatusSiglum(transaction.analysis_status)}
                                </div>
                            </Tooltip>
                        </TableCell>
                        <TableCell className={classes.tableText}>
                            {moment(transaction.operation_date).format("DD/MM/YYYY HH:mm:ss")}
                        </TableCell>
                        <TableCell className={classes.tableText}>
                            {getOperationStatusIcon(transaction.operation_status, "small", true)}                            
                        </TableCell>
                    </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );
}

export default OperationTable;
