import React from "react"
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import ISBPArray from "../../../../../assets/json/isbp.json"
import moment from "moment"

const useStyles = makeStyles((theme) => ({
    tableStyle: {
        border: "none",
        boxShadow: "none",
    },
    tableText: {
        fontFamily: "Open Sans",
        fontWeight: "normal",
        fontSize: "12px",
        lineHeight: "16px",
        color: "#141414",
        textAlign: "center",
        margin: "10px",
        padding: "6px",
        minWidth: "120px"
    },
    tableHeader: {
        textAlign: "center",
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: "normal",
        color: "#6F6F6F",
        fontSize: "14px",
        lineHeight: "19px",
        padding: "6px",
    },
    even: {
        background: "#F3F3F3",
    },
    odd: {
        background: "#FFFFFF",
    },
}))

export const CCFTable = ({ ccf_annotations }) => {

    const { t } = useTranslation()

    const classes = useStyles()
    useTheme()

    const getClass = (rowIndex) => {
        if (rowIndex % 2 === 0) {
            return classes.even
        }

        return classes.odd
    }

    const uppercaseFirstLetter = string => string[0].toUpperCase() + string.substring(1)


    return (
        <Paper className={classes.tableStyle}>
            <Table style={{ padding: "8px" }}>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableHeader}>{"Agência"}</TableCell>
                        <TableCell className={classes.tableHeader}>{t("Banco")}</TableCell>
                        <TableCell className={classes.tableHeader}>{t("Quantidade de Eventos")}</TableCell>
                        <TableCell className={classes.tableHeader}>{t("Data do Último Evento")}</TableCell>
                        <TableCell className={classes.tableHeader}>{t("Razão")}</TableCell>
                        <TableCell className={classes.tableHeader}>{t("Tipo Pessoa")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {ccf_annotations.map((annotation, index) => (
                        <TableRow
                            key={index}
                            className={getClass(index)}
                        >
                            <TableCell className={classes.tableText}>
                                {annotation.financial_institution_branch == null ? "-" : annotation.financial_institution_branch}
                            </TableCell>
                            <TableCell className={classes.tableText}>
                                {annotation.isbp ? ISBPArray[String(annotation.isbp)] : "-"}
                            </TableCell>
                            <TableCell className={classes.tableText}>
                                {annotation.number_of_events ? annotation.number_of_events : "-"}
                            </TableCell>
                            <TableCell className={classes.tableText}>
                                {annotation.last_event_date ? moment(annotation.last_event_date).format("DD/MM/YYYY") : "-"}
                            </TableCell>
                            <TableCell className={classes.tableText}>
                                {annotation.reason ? uppercaseFirstLetter(annotation.reason) : "-"}
                            </TableCell>
                            <TableCell className={classes.tableText}>
                                {annotation.person_type ? annotation.person_type === "F" ? "Pessoa Física" : "Pessoa Jurídica" : "-"}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper >
    )
}
