import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom'
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from 'moment'
import {getTableRowClass} from "../../../Utils/utils"
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  paperStyle: {
    border: "none",
    boxShadow: "none",
  },
  tableStyle: {
    padding: "8px",
  },
  blueText: {
    fontFamily: "Open Sans",
    fontWeight: "600",
    color: "#0b1f82"
},
  tableText: {
    fontFamily: "Open Sans",
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "center",
    margin: "10px",
    padding: "6px",
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: '#6F6F6F',
    fontSize: "14px",
    lineHeight: "19px",
    padding: "6px"
}
}));

function RuleHistoryTable(props) {
    const classes = useStyles();
    useTheme();
    const { t } = useTranslation();

    const { ruleHistory } = props

    const getInitialsFromName = (fullName) => {
        let fullNameInArr = fullName.split(" ")
        const first_name = fullNameInArr[0]
        const last_name = fullNameInArr[fullNameInArr.length - 1]

        return first_name.charAt(0) + last_name.charAt(0)
    }
    
    return (
        <Paper className={classes.paperStyle}>
        <Table className={classes.tableStyle}>
            <TableHead>
                <TableRow>
                    <TableCell className={classes.tableHeader}>{t("Nome")}</TableCell>
                    <TableCell className={classes.tableHeader}>{t("Alteração")}</TableCell>
                    <TableCell className={classes.tableHeader}>{t("Alterado por")}</TableCell>
                    <TableCell className={classes.tableHeader}>{t("Data")}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            {ruleHistory.map((rule, index)=> (
                <TableRow
                key={index}
                className={getTableRowClass(index)}
                > 
                    <TableCell
                        className={[classes.tableText, classes.blueText, "breakWord","link"].join(" ")}
                    >
                        <Link to={"/rule/" + rule.rule_key + "/" + rule.version}>
                            {rule.name ? rule.name : "-"}
                        </Link>
                    </TableCell>
                    <TableCell className={classes.tableText}>
                        {rule.description ? rule.description : "-"}
                    </TableCell>
                    <TableCell className={classes.tableText}>
                        {rule.analyst_name ?
                        <Tooltip title={<p className={["tooltipText", "centerText"].join(" ")}>
                            {rule.analyst_name}<br></br>{rule.analyst_email}</p>}>
                            <div className={["centerText","labelSpace"].join(" ")}>
                                {getInitialsFromName(rule.analyst_name)}
                            </div>
                        </Tooltip>
                         : "-"}
                    </TableCell>
                    <TableCell className={[classes.tableText].join(" ")}>
                    {rule.version_date ? moment(rule.version_date).format("DD/MM/YYYY HH:mm:ss") : "-"}
                    </TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        </Paper>
    );
}

export default RuleHistoryTable;
