import React from 'react'
import DataField from "../../../../utils/DataField"
import { titleCase, clientAge, getAgencyInfo} from "../../../../utils/utils"
import { getAccountType } from "../../../../utils/utils"

function Account(props) {

    let { accountObject } = props
    let [institution_name, branch_object] = getAgencyInfo(accountObject)
    
    let institution_name_string = ""
    if (institution_name) institution_name_string = " - " + titleCase(institution_name, null)

    let branch_string = ""
    if (branch_object) {
        let branch_name = titleCase(branch_object.name, "")
        let branch_city = titleCase((branch_object.address || {}).city, "")
        let branch_uf = (branch_object.address || {}).uf || ""
        branch_string = " - " + branch_name + ", " + branch_city + " - " + branch_uf
    }

    return (
        <div className="internalCardContainer" style={{display: "flex", flexDirection: "column"}}>
            <DataField
                title={"Nome do Titular"}
                label={titleCase(((accountObject || {}).owner || {}).name, null)}
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="5px"
            />   
            <DataField
                title={((accountObject || {}).owner || {}).type === "legal_person" ? "CNPJ" : "CPF"}
                label={((accountObject || {}).owner || {}).document_number}
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="5px"
            /> 
            <DataField
                title={"Banco"}
                label={(accountObject || {}).participant ? accountObject.participant + institution_name_string : null}
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="5px"
            />
            <DataField
                title={"Agencia"}
                label={(accountObject || {}).branch ? accountObject.branch + branch_string : null}
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="5px"
            />
            <DataField
                title={"Conta"}
                label={(accountObject || {}).account_number}
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="5px"
            />
            <DataField
                title={"Tipo de Conta"}
                label={(accountObject || {}).account_type ? accountObject.account_type + " - " + getAccountType(accountObject.account_type) : null}
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="5px"
            />
            <DataField
                title={"Data de Abertura"}
                label={clientAge((accountObject || {}).opening_date, null, "years_months")}
                size={"normalMediumSize"}
                titleNoWrap={true}
                margin="5px"
            />
        </div>
    )
}

export default Account