import React, { useEffect, useContext, useReducer, useState } from "react";
import { dataFetchReducer } from "../../Utils/requestUtils";
import { useParams } from "react-router-dom";
import axios from "axios";
import TransactionCard from "./TransactionCard/TransactionCard"
// import DeviceCard from "./DeviceCard/DeviceCard"
import ClientCard from "./ClientCard/ClientCard"
import CustomDataCard from "./CustomDataCard/CustomDataCard"
import HistoricCard from "./HistoricCard/HistoricCard"
import EnvolvedParties from "./EnvolvedParties/EnvolvedParties"
import AlertCard from "./AlertCard/AlertCard"
import TimerBox from "./TimerBox/TimerBox"
import NewLockDialog from "../../Locks/LocksInbox/NewLockDialog/NewLockDialog";
import ScoreBox from "./ScoreBox/ScoreBox"
import ObservationCard from "./ObservationCard/ObservationCard"
import InfractionReportsCard from "./InfractionReportsCard/InfractionReportsCard"
import CreateInfractionReportDialog from "./CreateInfractionReportDialog/CreateInfractionReportDialog"
import moment from "moment"
import ErrorBoundary from "../../Utils/ErrorBoundary";
import AuthContext from "../../../context/auth-context"
import {useHistory} from "react-router-dom"
import FraudCard from "./Fraud/FraudCard/FraudCard"
import FraudHistory from "./Fraud/FraudHistory/FraudHistory"
import FraudDialog from "./Fraud/FraudDialog/FraudDialog";
import ArrowBack from "@material-ui/icons/ArrowBack";
import ArrowForward from "@material-ui/icons/ArrowForward";
import Tooltip from "@material-ui/core/Tooltip";
import RufraCard from "./RufraCard/RufraCard";
import DeviceScan from "./DeviceScan/DeviceScan";


      
function Transaction(props){
    let history = useHistory()
    let is_fraud = false 

    let roles = useContext(AuthContext).user_data.roles
    if (!roles.includes("read_pix_transactions")){
        history.push("")
    }
    let can_write = roles.includes("update_pix_transactions")

    let { transaction_key } = useParams();

    const [openDialog, setOpenDialog] = useState(false)

    const handleFraudStatus = () => {
        setOpenDialog(true)
    };

    const handleDialogClose = () => {
        setOpenDialog(false)
    };

    const handleDialogSuccess = () => {
        if (roles.includes("create_pix_transaction_locks")) {
            setOpenDialog(false);
            handleLockDialogOpen();
        }
    };

    const [openLockDialog, setOpenLockDialog] = useState(false)

    const handleLockDialogOpen = () => {
        setOpenLockDialog(true);
    }

    const handleLockDialogClose = () => {
        setOpenLockDialog(false);
        history.push("/pix-transactions-inbox")
    }

    const [transaction, dispatchTransaction] = useReducer(
        dataFetchReducer,
        {fetchedData: null, isLoading: true, isError: false} 
    )
    let [createInfractionReportDialog, setCreateInfractionReportDialog] = useState(false);

    const handleCreateInfractionReportDialogOpenClick = () => {
		setCreateInfractionReportDialog(true);
	  };
	
	  const handleCreateInfractionReportDialogCloseClick = () => {
		setCreateInfractionReportDialog(false);
	  };

    useEffect(() => {
		dispatchTransaction({type: "data_fetch_init"})
		const timer = setTimeout(() => {
			axios.get('/dash/pix_backoffice/transaction/' + transaction_key).then( response => {
                dispatchTransaction({
                    type: "data_fetch_success",
                    payload: response.data
                })   
			}).catch(error => {
                if ((error.response || {}).status === 403) dispatchTransaction({type: "data_fetch_failure_403"})
                else if ((error.response || {}).status === 404) dispatchTransaction({type: "data_fetch_failure_404"})  
                else dispatchTransaction({type: "data_fetch_failure"})    
			})	
		}, 500);
		return () => {
			clearTimeout(timer)
        }
    }, [transaction_key])

    const enableInfractionPost = (roles, transaction) => {
        let falseReportList = ['cancelled', 'closed_disagreed']
        let enablePost = false
        if (roles.includes("update_pix_transactions") && transaction.transaction_status !== "created") {
            let trueReports = (transaction.infraction_reports || []).filter(
                report => !falseReportList.includes(report.infraction_report_status))
            if (trueReports.length === 0) enablePost = true
        }
        return enablePost
    }
    
    const transactionsArray = JSON.parse(sessionStorage.getItem('transactionsArray')) || []

    const [indexState, setIndexState] = useState(transactionsArray.findIndex(transaction => transaction === transaction_key))

    const handleNextTransactionButtonClick = () => {
        let index = transactionsArray.findIndex(transaction => transaction === transaction_key)
        setIndexState(index+1)
        transaction_key = transactionsArray[index+1]
        history.push("/pix-transaction/" + transaction_key)
    }

    const handlePreviousTransactionButtonClick = () => {
        let index = transactionsArray.findIndex(transaction => transaction === transaction_key)
        setIndexState(index-1)
        transaction_key = transactionsArray[index-1]
        history.push("/pix-transaction/" + transaction_key)
    }

    if (transaction.fetchedData){
        transaction.fetchedData.manual_analysis_limit_date = moment().add(2,"minutes")
        
        is_fraud = transaction.fetchedData.fraud_status === "fraud"
        
        return (
            <div style={{minWidth:"900px", paddingBottom: "30px"}}>
                <div className="analysisCardContainer">
                    <ErrorBoundary>
                        <div style={{display:"flex", flexDirection:"column", width: "100%"}}>
                            <div style={{display:"flex", justifyContent: "space-between"}}>
                                <div className={["blueText", "titleSize"].join(" ")} style={{margin:"5px 0px"}}>
                                    Transação {transaction.fetchedData.id}
                                </div>
                                    <div>
                                    {!is_fraud && can_write &&
                                        <div
                                            className={["button", "reproved", "normalText", "normalMediumSize"].join(" ")                            }
                                            style={{marginBottom: "5px"}}
                                            onClick={handleFraudStatus}
                                        >
                                            Reportar como FRAUDE
                                        </div>}
                                    {enableInfractionPost(roles, transaction.fetchedData) ?
                                    <div 
                                        className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                                        onClick={handleCreateInfractionReportDialogOpenClick}                        
                                    >
                                        Relatar esta transação
                                    </div>
                                    :null}
                                </div>
                            </div>
                            <div className={["labelText", "subtitleSize"].join(" ")} style={{margin:"5px 0px"}}>
                                {moment(transaction.fetchedData.transaction_date).format("DD/MM/YYYY - HH:mm:ss")}
                            </div>
                        </div>
                    </ErrorBoundary>
                </div>
                {transactionsArray.length ? 
                <div className="analysisCardContainer" style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}> 
                    <div>
                        {indexState !== 0 &&
                            <Tooltip title={<p className="tooltipText">Transação Anterior</p>}>
                            <div 
                                className={["circle", 'small'].join(" ")} 
                                style={{backgroundColor:  'rgb(211, 206, 206)', cursor: "pointer"}}
                                onClick={handlePreviousTransactionButtonClick}
                            >
                                <ArrowBack />
                            </div>
                            
                            </Tooltip>}
                    </div>
                    <div>
                        {indexState !== (transactionsArray.length - 1) &&
                            <Tooltip title={<p className="tooltipText">Próxima Transação</p>}>
                            <div 
                                className={["circle", 'small'].join(" ")} 
                                style={{backgroundColor:  'rgb(211, 206, 206)', cursor: "pointer", marginRight: "1px"}}
                                onClick={handleNextTransactionButtonClick}
                            >
                                <ArrowForward />
                            </div>
                            </Tooltip>}
                        {indexState === (transactionsArray.length - 1) &&
                            <div 
                                className={["button", "onlyboarder", "normalMediumSize"].join(" ")}
                                style={{cursor: "pointer", marginRight: "1px", width:"150px"}}
                                onClick={() => history.push("/pix-transactions-inbox")}
                            >
                                Voltar para Transações
                            </div>
                        }
                    </div>
                </div> : null}
                <div style={{display: "flex", flexDirection:"row", margin: "10px 0px"}}>
                    <div style={{width: "33%", flexDirection:"column", display:"flex"}}>
                        <div className="analysisCardContainer" style={{flexGrow: "1"}}>
                            <ErrorBoundary>
                                <TransactionCard transaction={transaction.fetchedData} />
                            </ErrorBoundary>      
                        </div>
                        {/* {transaction.fetchedData.source ? 
                        <div className="analysisCardContainer">
                            <ErrorBoundary>
                                <DeviceCard transaction={transaction.fetchedData}/>
                            </ErrorBoundary>   
                        </div> : null} */}
                        <div className="analysisCardContainer" style={{flexGrow: "1"}}>
                            <ErrorBoundary>
                                <ClientCard transaction={transaction.fetchedData} />
                            </ErrorBoundary>      
                        </div>
                        {transaction.fetchedData.custom_data&&
                        <div className="analysisCardContainer" style={{flexGrow: "1"}}>
                            <ErrorBoundary>
                                <CustomDataCard transaction={transaction.fetchedData} />
                            </ErrorBoundary>      
                        </div>}
                    </div>
                    <div style={{width: "67%"}}>
                        <div style={{display:"flex"}}>
                            <div style={{width: "50%", flexDirection:"column", display:"flex"}}>
                                <div className="analysisCardContainer" style={{flexGrow: "1"}}>
                                    <ErrorBoundary>
                                        <TimerBox transaction={transaction.fetchedData}/>
                                    </ErrorBoundary>
                                </div>
                                <div className="analysisCardContainer" style={{flexGrow: "1"}}>
                                    <ErrorBoundary>
                                        <ScoreBox 
                                            analysis_events={transaction.fetchedData.analysis_events}
                                            analysis_status={transaction.fetchedData.analysis_status}
                                        />
                                    </ErrorBoundary>
                                </div>
                            </div>
                            <div style={{width: "50%", flexDirection:"column", display:"flex"}}>
                            {is_fraud && 
                                <div className="analysisCardContainer">
                                    <ErrorBoundary>
                                        <FraudCard />                                    
                                    </ErrorBoundary>   
                                </div>}
                                <div className="analysisCardContainer" style={{flexGrow: "1"}}>
                                    <ErrorBoundary>
                                        <AlertCard analysis_events={transaction.fetchedData.analysis_events}/>
                                    </ErrorBoundary>   
                                </div>
                            </div>
                        </div>
                        <div className="analysisCardContainer" style={{ minHeight: "250px"}}>
                            <ErrorBoundary>
                                <EnvolvedParties transaction={transaction.fetchedData} />
                            </ErrorBoundary>   
                        </div>
                        {transaction.fetchedData.source ?
                        <div className="analysisCardContainer" style={{flexGrow: "1", display: 'flex', flexDirection: "column"}}>
                            <ErrorBoundary>
                                <DeviceScan transaction={transaction.fetchedData} />
                            </ErrorBoundary>
                        </div>
                        : null}
                    </div>
                </div>
                { transaction.fetchedData.analysis_events && transaction.fetchedData.analysis_events[0].decision_metadata && transaction.fetchedData.analysis_events[0].decision_metadata.checker_rufra_data ?
                <div className="analysisCardContainer" style={{flexGrow: "1"}}>
                    <ErrorBoundary>
                        <RufraCard rufra_data={transaction.fetchedData.analysis_events[0].decision_metadata.checker_rufra_data}/>
                    </ErrorBoundary>
                </div>
                : null}
                <div className="analysisCardContainer">
                    <ErrorBoundary>
                        <HistoricCard transaction={transaction.fetchedData}/>
                    </ErrorBoundary>
                </div>
                {(transaction.fetchedData.infraction_reports || []).length > 0 ? 
                <div className="analysisCardContainer">
                    <ErrorBoundary>
                        <InfractionReportsCard transaction={transaction.fetchedData}/>
                    </ErrorBoundary>
                </div> : null}
                {is_fraud &&
                    <div className="analysisCardContainer" >
                        <ErrorBoundary>
                            <FraudHistory transaction={transaction.fetchedData} can_write={can_write} dashAPIEndpointUrl={"dash/pix_backoffice/transaction/"}/>
                        </ErrorBoundary>   
                    </div>}
                {!["automatically_approved","automatically_reproved"].includes(transaction.fetchedData.analysis_status)&&
                <div className="analysisCardContainer">
                    <ErrorBoundary>
                        <ObservationCard transaction={transaction.fetchedData}/>
                    </ErrorBoundary>			
                </div>}
                {enableInfractionPost(roles, transaction.fetchedData) ?
				<CreateInfractionReportDialog
                    open={createInfractionReportDialog}
                    onClose={handleCreateInfractionReportDialogCloseClick}
                    transaction_key={transaction.fetchedData.transaction_key}
                />:null}
                <FraudDialog
                    open={openDialog}
                    onClose={handleDialogClose}
                    onSuccess={handleDialogSuccess}
                    transaction={transaction.fetchedData}
                />
                <NewLockDialog
                    open={openLockDialog}
                    onClose={handleLockDialogClose}
                    roles={roles}
                    presetLockedEventType="pix_transaction"
                    handleRefreshPage={() => {}}
                />
            </div>
        )
    }
	else if(transaction.isError){
		return (
            <div style={{height: "80vh", display:"flex"}}>
                <p className={["blueText", "titleSize"].join(" ")} style={{margin:"auto", textAlign:"center"}}>
                    {transaction.errorMessage}
                </p>
            </div>
		)
	}
  
  return null;
}

export default Transaction