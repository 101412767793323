import React, {useContext} from "react";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import {getSeverityColor, getSeverityStatus, 
        getSeverityStatusSiglum, getAlertStatusColor, 
        getAlertStatusText, getAlertStatusTextSiglum, 
        formatIrregularity, getLinkKeyTypeSiglum,
        getAlertUrlFromEventGroup} from "../../utils/utilsAlert"
import moment from 'moment'
import Tooltip from "@material-ui/core/Tooltip";
import AuthContext from "../../../../context/auth-context";
import {whitelist_ck_show_banking_alert_event_type, formatAsMoney} from "../../utils/utils"
import {getEventTypeName} from "../../utils/utilsAlert"

function AlertCard(props){

    let company_key = useContext(AuthContext).user_data.company_key 
    let business_group_key = useContext(AuthContext).user_data.business_group_key 

    let showEventType = whitelist_ck_show_banking_alert_event_type.includes(company_key)

    return (
        <Card className="listCard">
            { showEventType ?
                <div 
                    className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                    style={{flex:"1 1 100px", textAlign:"center"}}
                >
                    {props.alert.event_type ?
                        getEventTypeName(props.alert.event_type)
                    : "-"}
                </div> 
                :
                null
            }
            {business_group_key&&
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                style={{flex:"2 1 180px", textAlign:"center"}}
            >
                {props.alert.company_name}
            </div>
            }
            <Link
                className={["listCardItem", "link", "subtitleSize", "breakWord", "bold"].join(" ")}
                style={{flex:"2 1 180px", textAlign:"center"}}
                to={getAlertUrlFromEventGroup(props.alert.event_group) + props.alert.alert_key}
            >
                {(props.alert.link_key||props.alert.alert_key)}
            </Link>
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                style={{flex:"1 1 45px", textAlign:"center"}}
            >
                {formatAsMoney(props.alert.amount)}
            </div>
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                style={{flex:"1 1 45px", textAlign:"center"}}
            >
                {getLinkKeyTypeSiglum(props.alert.link_key_type)}
            </div>
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                style={{flex:"1 1 45px", textAlign:"center"}} 
            >
                {formatIrregularity(props.alert.irregularity_type, false, true)}
            </div>
            <Tooltip title={<p className="tooltipText">Quantidade de gatilhos no alerta</p>}>
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                style={{flex:"1 1 80px", textAlign:"center"}}
            >
                <div className="triggerNumberStyle">
                    {props.alert.triggers_count}
                </div>               
            </div>
            </Tooltip>
            <div 
                className={["listCardItem", "normalText", "normalMediumSize", "bold"].join(" ")} 
                style={{flex:"1 1 45px", textAlign:"center", cursor: "default"}}  
            >
                <Tooltip title={<p className="tooltipText">{"Severidade do alerta: " + getSeverityStatus(props.alert.risk_level)}</p>}>
                    <div className={["circle", "small"].join(" ")} style={{backgroundColor:getSeverityColor(props.alert.risk_level)}}>
                        {getSeverityStatusSiglum(props.alert.risk_level)}
                    </div>
                </Tooltip>
            </div>
            <div 
                className={["listCardItem", "normalText", "normalMediumSize", "bold"].join(" ")} 
                style={{flex:"1 1 45px", textAlign:"center", cursor: "default"}}  
            >
                <Tooltip title={<p className="tooltipText">{getAlertStatusText(props.alert.alert_status)}</p>}>
                    <div className={["circle", "small"].join(" ")} style={{backgroundColor:getAlertStatusColor(props.alert.alert_status)}}>
                        {getAlertStatusTextSiglum(props.alert.alert_status)}
                    </div>
                </Tooltip>
            </div>
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                style={{flex:"1 1 110px", textAlign:"center"}}
            >
                {moment(props.alert.occurrence_date).format("DD/MM/YYYY HH:mm:ss")}
            </div>
        </Card>
    )
}

export default AlertCard