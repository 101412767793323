import React from "react";
import DataField from "../../utils/DataField";
import {formatAsMoneyUniversal} from "../../utils/utils"
import moment from 'moment'

function ImportDeclarationCard(props) {

    let { import_declarations } = props.operation

    if (import_declarations == null) {
        return (
        <div className="analysisCard">
            <div className={["blueText", "subtitleSize"].join(" ")}>Declaração de Importação</div>
            <div style={{display:"flex", flexGrow:"1"}}>
                <div className={["labelText", "subtitleSize"].join(" ")} style={{margin:"auto"}}>
                    Não há Declaração de Importação
                </div>
            </div>
        </div>
        );        
    }
    else{
        return (
            <div className="analysisCard">
                <div className={["blueText", "subtitleSize"].join(" ")}>Declaração de Importação</div>
                <div className="internalCardContainer">
                    {import_declarations.map((importDeclaration, index) => (
                        <div key={index} style={{display: "flex", flexDirection:"column", margin: "5px 0px"}}>
                            <div className={["normalText", "mediumMoneySize", "bold"].join(" ")} >
                                {formatAsMoneyUniversal (importDeclaration.currency, importDeclaration.amount, "-")}
                            </div>
                            <DataField
                                title={"Valor da Declaração"}
                                label={formatAsMoneyUniversal (importDeclaration.currency, importDeclaration.amount, "-")}
                                size={"normalMediumSize"}
                                titleNoWrap={true}
                                margin="5px"
                            />
                            <DataField
                                title={"Data da Declaração"}
                                label={moment(importDeclaration.import_declaration_date).format("DD/MM/YYYY")}
                                size={"normalMediumSize"}
                                titleNoWrap={true}
                                margin="5px"
                            />
                            <DataField
                                title={"Horário da Declaração"}
                                label={moment(importDeclaration.import_declaration_date).format("HH:mm:ss")}
                                size={"normalMediumSize"}
                                titleNoWrap={true}
                                margin="5px"
                            />
                        </div>
                    ))}
                </div>
            </div>
            );
    }
}

export default ImportDeclarationCard;
