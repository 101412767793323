import React from 'react'
import moment from 'moment'
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from '@material-ui/icons/Clear';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import redCross from '../../../assets/cardEntryIcons/redCross.svg';
import greenCheck from '../../../assets/cardEntryIcons/greenCheck.svg';
import whiteChipIcon from '../../../assets/cardEntryIcons/whiteChip.svg';
import fraudLogo from '../../../assets/cardEntryIcons/fraud.svg';
// import {getAnalysisStatusColor,getAnalysisStatusSiglum} from "./utilsOrder"
import Tooltip from "@material-ui/core/Tooltip"
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';



//Functions used to sum values under the same key inside and object that is part of an array
export function fieldMapper(item,field){
    return item[field];
}
  
export  function sum(prev, next){
    return prev + next;
}


export const formatAsMoney = (value, nullCase=null) => {
    if (value == null) return nullCase
    return ("R$ " + (value/100).toLocaleString('de-DE', {minimumFractionDigits: 2}))    
}

export const getTableRowClass = (rowIndex) => {
    if (rowIndex % 2 === 0) {
      return "even";
    }
  
    return "odd";
  }


//Check if nested key exists inside object
export function checkNested (obj /*, level1, level2, ... levelN*/) {
    if (obj == null) return false
    var args = Array.prototype.slice.call(arguments, 1);

    for (var i = 0; i < args.length; i++) {
        if (!obj || !obj.hasOwnProperty(args[i])) {
        return false;
        }
        obj = obj[args[i]];
    }
    return true;
}


//Format card number to appear on order card in ordersinbox
export const formatCard = (bin, last4) => {
    bin = bin == null ? "" : bin.toString()
    last4 = last4 == null ? "" : last4.toString()
    let size1 = bin.length
    let size2 = last4.length
    let missing= 16 - size1 -size2
    let a = "*".repeat(missing)
    let cardNumber = bin + a + last4
    return cardNumber.replace(/\S{4}/g, '$& ')
}


//Create card ilustration
export const getCard = (card) => {
    return (
      <div className="blackCard">
        <img src={whiteChipIcon} className="brandStyleBlackCard" alt="chip Logo"/>
        <div className={["cardNumberStyleBlackCard", "normalTextWhite", "normalSmallSize"].join(" ")}>
            {formatCard(card.bin, card.last_4)}
        </div>
        <div className={["validDateStyleBlackCard", "normalTextWhite", "normalSmallSize"].join(" ")}>
            {card.expiration_date == null ? null : moment(card.expiration_date).format("MM/YYYY")}
        </div>
      </div>
    )
}


//Render match icon for historic table
const getMatchIcon = (bool) => {
    if (bool == null) {
        return (
            <Tooltip title={<p className="tooltipText">Indisponível</p>}>
                <HelpOutlineIcon style={{color: colorGrey}}/>
            </Tooltip>
        )
    }
    else if (bool) {
        return (
            <Tooltip title={<p className="tooltipText">Item confirmado</p>}>
                <CheckIcon style={{color: colorGreen}}/>
            </Tooltip>
        )
    }
    else{
        return (
            <Tooltip title={<p className="tooltipText">Item divergente</p>}>
                <ClearIcon style={{color: colorRed}}/>
            </Tooltip>
        )
    }
}


//Convert historic into table object
export const historicIntoTableObject = (historicalData, maxRows) => {
    let numberOfPhoneCheck = 0;
    let numberOfLocCheck = 0;
    let numberOfDocumentCheck = 0;
    let numberOfEmailCheck = 0;
    let numberOfCreditCardCheck = 0;

    let slicedData = maxRows == null ? historicalData : historicalData.slice(0,maxRows)

    let tableData =slicedData.map((order) => {
        let tableRow = { ...order };

        tableRow.phoneCheckIcon = !checkNested(tableRow, "matches", "phone") ? 
            getMatchIcon(null) : getMatchIcon(tableRow.matches.phone)

        tableRow.locationCheckIcon = !checkNested(tableRow, "matches", "postal_code") ? 
            getMatchIcon(null) : getMatchIcon(tableRow.matches.postal_code)

        tableRow.documentCheckIcon = !checkNested(tableRow, "matches", "document_number") ? 
            getMatchIcon(null) : getMatchIcon(tableRow.matches.document_number)

        tableRow.emailCheckIcon = !checkNested(tableRow, "matches", "email") ? 
            getMatchIcon(null) : getMatchIcon(tableRow.matches.email)

        tableRow.creditCardCheckIcon = !checkNested(tableRow, "matches", "card") ? 
            getMatchIcon(null) : getMatchIcon(tableRow.matches.card)

        if (checkNested(tableRow, "matches", "phone") && tableRow.matches.phone === true) {
          numberOfPhoneCheck++;
        }
    
        if (checkNested(tableRow, "matches", "postal_code") && tableRow.matches.postal_code === true) {
          numberOfLocCheck++;
        }
    
        if (checkNested(tableRow, "matches", "document_number") && tableRow.matches.document_number === true) {
          numberOfDocumentCheck++;
        }
    
        if (checkNested(tableRow, "matches", "email") && tableRow.matches.email === true) {
          numberOfEmailCheck++;
        }

        if (checkNested(tableRow, "matches", "card") && tableRow.matches.card === true) {
          numberOfCreditCardCheck++;
        }
    
        // tableRow.statusIcon = (
        //     <div className={["circle", "small"].join(" ")} style={{backgroundColor:getAnalysisStatusColor(tableRow.analysis_status)}}>
        //         {getAnalysisStatusSiglum(tableRow.analysis_status)}
        //     </div>          
        // );
    
        return tableRow;
    });

    return {
        tableData: tableData,
        phoneCheck: numberOfPhoneCheck,
        locCheck: numberOfLocCheck,
        documentCheck: numberOfDocumentCheck,
        emailCheck: numberOfEmailCheck,
        cardCheck: numberOfCreditCardCheck
    }


};

export const titleCase = (str, nullCase) => {
    if (str == null) {
      return nullCase
    }
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  };

export function removeDuplicatesBy(keyFn, array) {
    var mySet = new Set();
    return array.filter(function(x) {
        var key = keyFn(x), isNew = !mySet.has(key);
        if (isNew) mySet.add(key);
        return isNew;
    });
}

export function replaceAll(str,mapObj){
  var re = new RegExp(Object.keys(mapObj).join("|"),"gi");

  return str.replace(re, function(matched){
      return mapObj[matched.toLowerCase()];
  });
}

export const formatAddress = (address, nullCase) => {
    if (address == null) return nullCase
    let street = titleCase(address.street, "")
    let number = address.number ? address.number : ""
    let neighborhood = titleCase(address.neighborhood, "")
    let city = titleCase(address.city, "")
    let complement = address.complement ? address.complement : ""
    let uf = address.uf ? address.uf : ""

    let addressString = number !== "" ? street + ", " + number : street
    addressString = complement !== "" ? addressString + ", " + complement : addressString
    addressString = neighborhood !== "" ? addressString + ", " + neighborhood : addressString
    addressString = city !== "" ? addressString +  ", " + city : addressString
    addressString = uf !== "" ? addressString +  " - " + uf : addressString
    return (addressString)
  }
  
  export const formatPhone  = (phoneObj,nullCase) => {
      if (phoneObj == null) return nullCase
  
      let ddiString = phoneObj.internation_dial_code == null ? "" : phoneObj.internation_dial_code + " "
      let dddString = phoneObj.area_code == null ? "" : "(" + phoneObj.area_code + ") "
      let numberString = phoneObj.number == null ? "" : phoneObj.number
  
      return ddiString + dddString + numberString
  
  }

  export const getConfirmationIcon = (status) => {
    if (status){
        return (<CheckIcon className="fraudStatusIcon" style={{color: colorGreen, width:"40px", height:"40px"}}/>)
    }
    else{
        return (<ClearIcon className="fraudStatusIcon" style={{color: colorRed, width:"40px", height:"40px"}}/>)
    }
  }

  export const getAlertsFlagIcon = (flag, margin=null, size="20px") => {
    let margin_ = "auto"
    if (margin !=null ){
        margin_ = margin
    }
    switch(flag){
        case "positive":
            return (<CheckIcon style={{verticalAlign: "middle", width:size, height:size, color:colorGreen, marginTop:"auto",marginBottom:"auto", marginRight:margin_,marginLeft:margin_}}/>)
        case "negative":
            return (<ErrorOutlineIcon style={{verticalAlign:"middle", width:size, height:size, color:colorRed, marginTop:"auto",marginBottom:"auto", marginRight:margin_,marginLeft:margin_}}/>)
        case "neutral":
            return(<ErrorOutlineIcon style={{verticalAlign:"middle", width:size, height:size, color:colorYellow, marginTop:"auto",marginBottom:"auto", marginRight:margin_,marginLeft:margin_}}/>)
        default:
            return(<ErrorOutlineIcon style={{verticalAlign:"middle", width:size, height:size, color:colorGrey, marginTop:"auto",marginBottom:"auto", marginRight:margin_,marginLeft:margin_}}/>)
    }
  }
  export const getBoolIcon = (bool) => {
    if (bool == null) return null
    if (bool){
      return (
        <img src={greenCheck} alt="GreenCheck Logo" />      
      )
    }
    else {
      return (
        <img src={redCross} alt="redCross Logo" />    
      )
    }
  }

  export const getAlertsFlagColor = (flag) => {
    switch(flag){
        case "positive":
            return colorGreen
        case "negative":
            return colorRed
        case "attention":
        case "neutral":
            return colorYellow
        default:
            return colorGrey
    }
}
  export const clientAge = (birthdate, nullCase=null, showCase="years", referenceDate=null, showTime=false) => {
    if (birthdate == null) return nullCase
    let extraString
    let birthdate_ = moment(birthdate)
    let now = referenceDate === null ? moment() : moment(referenceDate)
    
    let years = now.diff(birthdate_, 'year');
    let yearString = years ===1 ? years + " ano" : years + " anos"
    birthdate_.add(years, 'years');
    let months = now.diff(birthdate_, 'months');
    let monthString = months ===1 ? months + " mês" : months + " meses"
    birthdate_.add(months, 'months');
    let days = now.diff(birthdate_, 'days');
    let dayString = days ===1 ? days + " dia" : days + " dias"
    birthdate_.add(days, 'days');
    switch(showCase){
        case "months_days":
            if (years >= 1) {
                extraString = "(Mais de um ano)"
            }
            else if (months === 0) {
                extraString = " (" + dayString + ")"
            }
            else {
                extraString = " (" + monthString + ")"
            }
            break
        case "years_months":
            if (years === 0){
                extraString = " (" + months + " meses)"
            }
            else {
                extraString = " (" + yearString + " e " + monthString + ")"
            }
            break
        case "years":
            if (years === 0){
                extraString = " (Menos de um ano)"
            }
            else {
                extraString = " (" + yearString + ")"
            }
            break
        default:
            throw new Error()
    }
    let outputFormat = "DD/MM/YYYY"
    if (showTime) outputFormat = "DD/MM/YYYY - HH:mm:SS"

    return moment(birthdate).format(outputFormat) + extraString
  };

export const setAllowChanges = (status, roles, role_to_be_verified) => {
    if (!roles.includes(role_to_be_verified)){
        return false
    }
    switch(status){
        case "pending":
        case "in_manual_analysis":
            return true
        default:
            return false
    }
}

export const colorGreen = '#73C580'
export const colorRed ='#D91A1A'
export const colorYellow = "#FFBB24"
export const colorOrange = '#F77B21'
export const colorVelvet ='#DD5C5C'
export const colorGrey = 'rgb(170, 167, 153)'


const formatEmail  = (emailObj,nullCase) => {
    if (emailObj == null) return nullCase
    return (emailObj.email == null ? "" : emailObj.email)
}


export const formatBureauInfo = (object, identifier) => {
    let formatFunction
    switch(identifier){
        case "endereços":
            formatFunction = formatAddress
            break
        case "telefones":
            formatFunction = formatPhone
            break
        case "emails":
            formatFunction = formatEmail
            break
        default:
    }
    return (
        <div>
            Outros {identifier}:
            <ul>
                {object.map((item,index) => (
                    <li key={index}>
                        {formatFunction(item)} - {item.description}
                    </li>
                ))}
            </ul>
        </div>
    )
}

export const formatIrregularity = (props) => {
    let text
    let icon
    let {asTooltip} = props
    let {additionalStyle} = props
    text = "Fraude"
    icon = fraudLogo
    if (asTooltip){
        return (
            <div 
                className={["circle","small"].join(" ")}
                style={{display:"flex", justifyContent:"center", ...additionalStyle}}
            >
                <Tooltip title={<p className="tooltipText">{text}</p>}>
                    <img style={{width:"16px"}} src={icon} alt="fraud Logo" />
                </Tooltip>
            </div>

        )
    }
}

export function validateCPF(cpf) {
    if (typeof cpf !== "string") return false
    cpf = cpf.replace(/[^\d]+/g,'')

    if (
        !cpf ||
        cpf.length !== 11 ||
        cpf === "00000000000" ||
        cpf === "11111111111" ||
        cpf === "22222222222" ||
        cpf === "33333333333" ||
        cpf === "44444444444" ||
        cpf === "55555555555" ||
        cpf === "66666666666" ||
        cpf === "77777777777" ||
        cpf === "88888888888" ||
        cpf === "99999999999" 
    ) return false

    var sum = 0
    var remainder
    for (var count = 1; count <= 9; count++) 
        sum = sum + parseInt(cpf.substring(count-1, count)) * (11 - count)
    remainder = (sum * 10) % 11
    if ((remainder === 10) || (remainder === 11))  remainder = 0
    if (remainder !== parseInt(cpf.substring(9, 10)) ) return false

    sum = 0
    for (count = 1; count <= 10; count++) 
        sum = sum + parseInt(cpf.substring(count-1, count)) * (12 - count)
    remainder = (sum * 10) % 11
    if ((remainder === 10) || (remainder === 11))  remainder = 0
    if (remainder !== parseInt(cpf.substring(10, 11))) return false

    return true
}
export function validateCNPJ(cnpj) {
    if (typeof cnpj !== "string") return false
    cnpj = cnpj.replace(/[^\d]+/g,'');
    if(cnpj === '') return false;
    if (cnpj.length !== 14) return false;

    if (cnpj === "00000000000000" || 
        cnpj === "11111111111111" || 
        cnpj === "22222222222222" || 
        cnpj === "33333333333333" || 
        cnpj === "44444444444444" || 
        cnpj === "55555555555555" || 
        cnpj === "66666666666666" || 
        cnpj === "77777777777777" || 
        cnpj === "88888888888888" || 
        cnpj === "99999999999999") return false;

    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0,tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
            pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado.toString() !== digitos.charAt(0)) return false;
        
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0,tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado.toString() !== digitos.charAt(1)) return false;
           
    return true;
}


export let orderFilterTypes = {        
    customer_document_number: {
        type: "brazilian_document_numbers",
        disabled: false,
        validation:true,
        validation_rule: {type: "multiple_equal", values:[14,18]},
        description: "CPF/CNPJ do Comprador"
    },
    seller_document_number: {
        type: "brazilian_document_numbers",
        disabled: false,
        validation:true,
        validation_rule: {type: "multiple_equal", values:[14,18]},
        description: "CPF/CNPJ do Vendedor"
    },
    order_date: {
        type: "date",
        disabled: false,
        validation:false,
        validation_rule: null,
        description: "Data"
    },
    card_order_id: {
        type: "string",
        disabled: false,
        validation:false,
        validation_rule: null,
        description: "Número do Pedido"
    },
    analysis_status: {
        type: "enum",
        enumList: [
            ["Nenhum", ""],
            ["Pendente", "pending"],
            ["Aprovado Manualmente", "manually_approved"],
            ["Reprovado Manualmente", "manually_reproved"],
            ["Aprovado Automaticamente", "automatically_approved"],
            ["Reprovado Automaticamente", "automatically_reproved"],
            ["Aprovado por tempo", "approved_by_time"]
        ],
        disabled: false,
        validation:false,
        description: "Status da Análise"
    }
}


export let alertFilterTypes = {
    customer_document_number: {
        type: "brazilian_document_numbers",
        disabled: false,
        validation:true,
        validation_rule: {type: "multiple_equal", values:[14,18]},
        description: "CPF/CNPJ do Comprador"
    },
    seller_document_number: {
        type: "brazilian_document_numbers",
        disabled: false,
        validation:true,
        validation_rule: {type: "multiple_equal", values:[14,18]},
        description: "CPF/CNPJ do Vendedor"
    },
    ocurrence_date: {
        type: "date",
        disabled: false,
        validation:false,
        validation_rule: null,
        description: "Data"
    },
    alert_status: {
        type: "enum",
        enumList: [
            ["Nenhum", ""],
            ["Pendente", "in_manual_analysis"],
            ["Tratado", "solved"]
        ],
        disabled: false,
        validation:false,
        description: "Status"
      },
    risk_level: {
        type: "enum",
        enumList: [
            ["Nenhum", ""],
            ["Baixo Risco", "low"],
            ["Médio Risco", "medium"],
            ["Alto Risco", "high"],
            ["Crítico", "critical"]
        ],
        disabled: false,
        validation:false,
        description: "Risco"
    }
}