import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import Filter from "./Filter/Filter";
import {getFilterInitialSate, addFilters, filterChange, changeApply, filterClose, dateChange, verifyRequest, getSelectItens} from "./utilsFilter"

const theme = createMuiTheme({
  overrides: { MuiInputBase: { input:{ padding: '6px 0px 3px 5px'}}}
});

function FilterBox(props) {
	
    const { setPageParams } = props
    let { filterTypes } = props
    let { initialFilters } = props
    const { t } = useTranslation()

    let filterObjectName = props.local_storage_object_preffix + "FilterObject"

    let single_filters = props.local_storage_object_preffix === "bankslip_fraud" ||
                      props.local_storage_object_preffix === "bill_payment_fraud" ||
                      props.local_storage_object_preffix === "pix_transaction_fraud" ||
                      props.local_storage_object_preffix === "wire_transfer_fraud" ||
                      props.local_storage_object_preffix === 'ra_sac'
                      
    const useStateConnectedToCache = () => {
        const [filterObject, setFilterObject] = useState(
            JSON.parse(sessionStorage.getItem(filterObjectName)) || getFilterInitialSate(filterTypes, initialFilters)
        );
        useEffect(() => {
            sessionStorage.setItem(filterObjectName, JSON.stringify(filterObject))
        },[filterObject]);
        return [filterObject, setFilterObject]
    }

    let [filterObject, setFilterObject] = useStateConnectedToCache()

    const handleFilterAdd = (event) => {
        let new_filterObject = { ...filterObject }
        new_filterObject = addFilters(event.target.value, new_filterObject)
        setFilterObject(new_filterObject)
      }; 
    
    const handleFilterClose = (column_name) => {
        let new_filterObject = { ...filterObject }
        let values = filterClose(column_name, new_filterObject)
        new_filterObject = values[0]
        let doRequest = values[1]
        if (doRequest){
            if (filterTypes[column_name].type === "date"){
                handleDateRequest(column_name, true)
            }else{
                handleFilterRequest(column_name)
            }
        }
        setFilterObject(new_filterObject) 
    }
    
    /////Don't include Date Filter
    const handleFilterChange = (event, column_name) => {
        let new_filterObject = { ...filterObject }
        new_filterObject = filterChange(event.target.value, column_name, filterTypes[column_name], new_filterObject)
        setFilterObject(new_filterObject) 
    }

    /////Don't include Date Filter
	const handleFilterRequest = (column_name) => {
        if (filterObject.params[column_name].error) return //Error dont make request
        if (filterObject.params[column_name].value.length === 0 && 
            !filterObject.params[column_name].applied) return //Not applied and length 0 dont make request

        let new_filterObject = { ...filterObject }
        new_filterObject = changeApply(new_filterObject)
        setFilterObject(new_filterObject)
        setPageParams({page: 1, filters: filterObject.toPass}) 
    }

    /////Only for Date Filter
    const handleDateChange = (newDates, column_name) => {
        let new_filterObject = { ...filterObject }
        new_filterObject = dateChange(column_name, newDates, new_filterObject)
        setFilterObject(new_filterObject)
    }
    
    /////Only for Date Filter
    const handleDateRequest = (column_name, forceRequest=false) => {
        let new_filterObject = { ...filterObject }
        let values = verifyRequest(column_name, new_filterObject)
        new_filterObject = values[0]
        let doRequest = forceRequest ? true : values[1]  
        if (doRequest) {
            setPageParams({page: 1, filters: filterObject.toPass})
        }
        setFilterObject(new_filterObject)
    }

    const getFraudFilter = ( filterTypes) => {
      let single_filters_columns = Object.keys(filterTypes)
      const filters_fraud = single_filters_columns.map((column_name, index) => (
        <ThemeProvider key={index} theme={theme}>
          <Filter
              key={column_name}
              filter_type={filterTypes[column_name]}
        filter={filterObject.params[column_name]}
        value={
                  filterTypes[column_name].type === "date" ?
                  {initialDate: filterObject.params[column_name].initial_date_value, finalDate:filterObject.params[column_name].final_date_value} :
                  filterObject.params[column_name].value
              }
        onClose={() => handleFilterClose(column_name)}
              onChange={
                  filterTypes[column_name].type === "date" ?
                  (newDates) => handleDateChange(newDates, column_name) :
                  (e) => handleFilterChange(e,column_name)
              }
        onBlur={
                  filterTypes[column_name].type === "date" ?
                  () => handleDateRequest(column_name) :
                  () => handleFilterRequest(column_name)}
          />
        </ThemeProvider>
        ));
        return filters_fraud
    }

    const filters = filterObject.filters.map((column_name, index) => (
      <ThemeProvider key={index} theme={theme}>
        <Filter
            key={column_name}
            filter_type={filterTypes[column_name]}
			filter={filterObject.params[column_name]}
			value={
                filterTypes[column_name].type === "date" ?
                {initialDate: filterObject.params[column_name].initial_date_value, finalDate:filterObject.params[column_name].final_date_value} :
                filterObject.params[column_name].value
            }
			onClose={() => handleFilterClose(column_name)}
            onChange={
                filterTypes[column_name].type === "date" ?
                (newDates) => handleDateChange(newDates, column_name) :
                (e) => handleFilterChange(e,column_name)
            }
			onBlur={
                filterTypes[column_name].type === "date" ?
                () => handleDateRequest(column_name) :
                () => handleFilterRequest(column_name)}
        />
      </ThemeProvider>
      ));

  
    return (
      <div className="filterBoxContainer normalSmallSize">
        {!single_filters && 
        <div className="filterBox">
          <ThemeProvider theme={theme}>
            <Select
              className="selectedFilter normalSmallSize"
              displayEmpty
              renderValue={() => t("filter")}
              onChange={handleFilterAdd}
              value={filterObject.select_value}
            >
              {getSelectItens(filterObject)}
            </Select>
          </ThemeProvider>
        </div>}
        <div style={{display: "flex", flexWrap: "Wrap"}}>
          {single_filters ? 
          <div style={{display: "flex", justifyContent: "row"}}>{getFraudFilter(filterTypes)}</div> : 
          filters}
        </div>
      </div>
    );
}

export default FilterBox