import React,{useState} from "react";
import { useHistory } from "react-router-dom";
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
        input: {
            color: "white"
        }
    }));

const SearchBar = (props) => {

    const { setSelectedProduct, setSelectedPage } = props;
    
    const classes = useStyles();
    useTheme()

    let history = useHistory() 

    const [searchString, setSearchString] = useState("")

    const handleChange = (e) => {
        let newSearchString = e.target.value
        setSearchString(newSearchString)
    } 

    const stringToPass = (string) => {
        let newSearchString2 = string.trim()
        return newSearchString2 = newSearchString2.replace(/[/]/g, '');
    }
    
    const handleSubmitEnter = (e) => {
        if(e.key === "Enter"){
            const newStringToPass = stringToPass(searchString)
            history.push("/search-banking-inbox/" + newStringToPass)
            setSearchString("")
            setSelectedProduct("search_banking")
            setSelectedPage("search_banking")
        }
    }

    const handleSubmitIcon = () => {
            const newStringToPass = stringToPass(searchString)
            history.push("/search-banking-inbox/" + newStringToPass)
            setSearchString("")
    }
    
    const handleClose = () => {
        setSearchString("")
    }

    return (
        <div className="searchAny">
            <SearchIcon style={{color:"gray", width:"18px",  height:"18px", margin: "5px 0px 5px 5px"}} onClick={handleSubmitIcon}/>
            <input
                className="normalSmallSize normalText"
                type="text"
                style={{padding:"10px 0px"}}
                fullwidth="true" 
                value={searchString}
                onChange={handleChange}
                onKeyPress={handleSubmitEnter}
                inputprops={{ className: classes.input }}
            />
            <IconButton style={{float: "right"}} size="small" onClick={handleClose}>
                <CloseIcon fontSize="small" style={{ color: "#f1f2f7" }}/>
            </IconButton>
        </div>
        );
};

export default SearchBar;
