import React, {useState, useReducer} from 'react';
import TextField from '@material-ui/core/TextField'; 
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import {getTextField, translateFieldValue, validateConstantValues, getConfirmationIconDialog, getDatetimeFormatted, translateErrorMessage} from "../../../../utils/utils";
import NumberFormat from 'react-number-format';
import InputMask from "react-input-mask";
import _ from "lodash";
import {decisionReducer} from "../../../../../Utils/requestUtils";
import { CircularProgress } from '@material-ui/core';
import TimeDeltaTextField from "../../../../utils/TimeDeltaTextField";
import GeoPointTextField from "../../../../utils/GeoPointTextField";

const useStyles = makeStyles((theme) => ({
    tableStyle: {
      border: "none",
      boxShadow: "none",
      width:"max-content"
    },
    blueText: {
      fontFamily: "Open Sans",
      fontWeight: "600",
      color: "#0b1f82"
  },
    tableText: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      lineHeight: "16px",
      textAlign: "center",
      margin: "10px",
      padding: "10px"
    },
    tableHeader: {
      textAlign: "center",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: "normal",
      color: '#6F6F6F',
      fontSize: "14px",
      lineHeight: "19px",
      padding: "10px",
    }
}));

function InputContent (props){
    const classes = useStyles();
    let { list, onClose, handleReloadPage, companyKey, userData } = props 

    const referenceFieldProperties = list.fields.reduce((acc,item) => {
        acc.push({fieldName: item.name, fieldType: item.type.enum})
        return acc
    },[])


    const getLineRepresentationObject = () => {
        let lineRepresentationObject = referenceFieldProperties.reduce((acc,item) => {
            let initialValue
            let initialError
            if (item.fieldType === "geo_point") {
                initialValue = [null, null] 
                initialError = true
            } 
            else {
                initialValue = ""
                initialError = true
            }
            acc[item.fieldName] = {value:initialValue,
                                   type:item.fieldType,
                                   error:initialError}
            return acc
        },{})
        lineRepresentationObject.isEditable = true

        return lineRepresentationObject
    }

    const translateType = (type) => {
        switch(type){
            case "bool":
                return ("Binário")
            case "number":
                return ("Número")
            case "string":
                return ("Texto")
            case "datetime":
                return ("Data e Hora")
            case "time":
                return ("Hora")
            case "timedelta":
                return ("Período de Tempo")
            case "geo_point":
                return ("Coordenada Geográfica")
            case "geo_shape":
                return ("Polígono Geográfico")
            case "":
                return ("Selecione")
            default:
                throw new Error("Invalid type" + type)
        }
    }

    const [inputContent, setInputContent] = useState(_.cloneDeep(getLineRepresentationObject()))

    const [customizedErrorMessage, setCustomizedErrorMessage] = useState("")  

    const handleChangeValue = (newValue, fieldName) => {
        let newInputContent = _.cloneDeep(inputContent)
        newInputContent[fieldName].value = newValue
        newInputContent[fieldName].error = true
        newInputContent[fieldName].error = validateConstantValues(newInputContent[fieldName].type, newInputContent[fieldName].value)
        setInputContent(newInputContent)
    }

    const checkEditableFieldsError = () => {
        let isError = referenceFieldProperties.reduce((acc, item) => {
            if (inputContent[item.fieldName].error === true) acc = true
            return acc
        },false)
        return isError
    }

    const handleConfirm = () => {
        let newInputContent = _.cloneDeep(inputContent)

        newInputContent.isEditable = false

        onSubmit(newInputContent)
    }

    const [dialogState, setDialogState] = useReducer(
        decisionReducer,
        {isLoading: false, isError: false, finished:false}
    ) 

    const onSubmit = (newInputContent) => {
        let list_items = []

        let lineObject = newInputContent

        delete lineObject.isEditable 
        
        for (let fieldName in lineObject){
            if(lineObject[fieldName]['type'] === 'string'){
                lineObject[fieldName]['value'] = lineObject[fieldName]['value'].trim()
            }
        }

        let items = Object.keys(lineObject).reduce((acc,item) => {
            acc[item] = lineObject[item]['value']
            return acc
        },{})

        list_items.push(items)
        
        let payload = {list_item: list_items}
        setDialogState({type:"send_request_init"})
        let requestHeaders = {headers:{}}
        if (userData.business_group_key) {
            requestHeaders = {headers:{company_key:companyKey}}
        }
        axios.post('/dash/lists/' + list.list_key + '/item', payload, requestHeaders).then(response => {
            setDialogState({type:"send_request_success"})
        }).catch(error => {
            setDialogState({type: "send_request_failure"})
            setCustomizedErrorMessage(error.response.data.message)
        });
    }

    const renderTextField= (field) => {
        let fieldObject = inputContent[field.fieldName]
        let standardField = getTextField(fieldObject.type)

        switch(field.fieldType) {
            case "number":
                return (
                    <NumberFormat 
                        {...standardField.props}
                        error={fieldObject.error}
                        value={fieldObject.value}
                        onValueChange={(e) => handleChangeValue(e.floatValue,field.fieldName)}
                        label={translateType(field.fieldType)}
                        variant="outlined"
                        size="small"
                    />
                )    
            case "bool":
                return (                        
                    <TextField 
                        {...standardField.props} 
                        error={fieldObject.error}
                        value={fieldObject.value}
                        onChange={(e) => handleChangeValue(e.target.value,field.fieldName)}
                        label={translateType(field.fieldType)}
                        variant="outlined"
                        size="small"
                    />
                )
            case "string":
                return (
                    <TextField 
                        {...standardField.props}
                        error={fieldObject.error}
                        value={fieldObject.value}
                        onChange={(e) => handleChangeValue(e.target.value,field.fieldName)}
                        label={translateType(field.fieldType)}
                        variant="outlined"
                        size="small"    
                    />
                )    
            case "datetime":
                return (
                    <InputMask 
                        {...standardField.props} 
                        error={fieldObject.error}
                        value={fieldObject.error ? fieldObject.value : translateFieldValue(fieldObject.value, fieldObject.type)}
                        onChange={(e) => handleChangeValue(getDatetimeFormatted(e.target.value,field.fieldType),field.fieldName)}
                        label={translateType(field.fieldType)}
                        variant="outlined"
                        size="small"    
                    />
                )
            case "time":
                return (
                    <InputMask 
                        {...standardField.props} 
                        error={fieldObject.error}
                        value={fieldObject.value}
                        onChange={(e) => handleChangeValue(e.target.value,field.fieldName)}
                        label={translateType(field.fieldType)}
                        variant="outlined"
                        size="small"    
                    />
                ) 
            case "timedelta":
                return (
                    <TimeDeltaTextField
                        value={fieldObject.value}
                        error={fieldObject.error}
                        valueChangeFunction={(value) => handleChangeValue(value,field.fieldName)}
                        label={null}
                    />
                )
            case "geo_point":
                return (
                <GeoPointTextField
                        value = {fieldObject.value}
                        error = {fieldObject.error}
                        valueChangeFunction = {(value) => handleChangeValue(value, field.fieldName)}
                    />
                )        
            default:
                throw new Error ("Invalid field Type")
        }
    }

    if(dialogState.finished && dialogState.isLoading){
		return (
            <div style={{width: "310px", minHeight:"217px", display:"flex"}}>
                <CircularProgress style={{margin:"auto"}} />
            </div>
		)
    }
    else if (dialogState.finished){
        let confirmationData = {    
            message: dialogState.message,
            success: !dialogState.isError
        }
		return (
        <div style={{width: "310px", minHeight:"217px"}}>
            <div className="internalCardContainer" style={{display:"flex", height:"40%", justifyContent:"center"}}>
                {getConfirmationIconDialog(confirmationData.success)}
            </div>
            <div className={["internalCardContainer", "normalMediumSize"].join(" ")} style={{display:"flex", textAlign:"center", justifyContent:"center"}}>
                {confirmationData.success ? confirmationData.message : translateErrorMessage(customizedErrorMessage)}
            </div>
            <div className="internalCardContainer" style={{display:"flex", justifyContent:"center"}}>
                <div 
                    className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                    onClick={() => {
                        onClose()
                        handleReloadPage()
                    }} 
                >
                    VOLTAR À TELA INICIAL 
                </div>
            </div>
        </div>
		)
    }
    else {
        return(
            <Paper className={classes.tableStyle}>
            <div className={['blueText', "normalMediumSize"].join(" ")} style={{marginTop:"40px", marginBottom:"20px", fontWeight:"600", backgroundColor:"#EFEFEF", padding:"10px 15px"}}>
                <div style={{display:"flex", flexDirection:"column", marginTop:"5px"}}>
                    <Table style={{ padding: "8px" }}>
                        <TableHead>
                            <TableRow>
                                {referenceFieldProperties.map((fieldProperties, index) => 
                                (<TableCell key={index} className={classes.tableHeader}>
                                        {fieldProperties.fieldName}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                                <TableRow> 
                                    {referenceFieldProperties.map((field, innerIndex) =>
                                    <TableCell padding={"default"} 
                                    key={innerIndex} 
                                    className={classes.tableText}
                                    style={{width:"200px"}}>
                                        {renderTextField(field)}
                                    </TableCell>)}
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            </div>
            <div>
                <div className="internalCardContainer" style={{display:"flex", marginTop: "10px", marginBottom: "0px"}}>
                    <div
                        className={
                            !(checkEditableFieldsError()) ?
                            ["button", "standard", "normalText", "normalMediumSize"].join(" ") :
                            ["button", "disabled", "standard", "normalText", "normalMediumSize"].join(" ")
                        }
                        style={{width:"120px", fontWeight:"600", paddingTop:"7px", margin:"0 0 0 auto"}}
                        onClick={!(checkEditableFieldsError()) ? handleConfirm : null}
                    >
                        Salvar
                    </div>   
                </div>
            </div>
            </Paper>
        )
    }
}

export default InputContent




