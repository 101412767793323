import React from 'react'
import { useTranslation } from "react-i18next";
import {GetAlertsComponent, translateType} from "./utils"
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
//DADOS - Incrementar Score 

export function ScoreModifierBox (props) {
    const { t } = useTranslation();
    let {node} = props
    let {read_only} = props
    let {handleConfigurationOpen} = props
    let {handleNodeRemove} = props

    return (
        <div>
            {!read_only ?
                <div style={{display:"flex", alignItems: "center"}}>
                    <div style={{display:"flex", width:"100%", cursor:"pointer", margin: "auto 0px"}} onClick={() => handleConfigurationOpen(node)}>
                            {t(node.node.description)}
                            {(node.node.alerts || []).length > 0?
                            <GetAlertsComponent alerts={node.node.alerts} />  : null} 
                    </div>
                    <IconButton style={{width: 32, height: 32}} aria-label="duplicate" onClick={() => props.handleNodeDuplication(node)}>
                        <ContentCopyIcon style={{width: 20, height: 20}} />
                    </IconButton>
                    <IconButton style={{width: 32, height: 32}} aria-label="close" onClick={() => handleNodeRemove(node)}>
                        <CloseIcon style={{width: 20, height: 20}} />
                    </IconButton>
                </div>
                :
                <div style={{display:"flex"}}>
                    <div style={{display:"flex", width:"100%", cursor:"pointer", margin: "auto 0px"}} onClick={() => handleConfigurationOpen(node)}>
                        {t(node.node.description)}
                    </div>
                </div>
            }
        </div>
    )
}

export function ScoreModifierConfiguration(props){
    const { t } = useTranslation();
    let {handleConfigurationClose} = props
    let {node} = props
    let {read_only} = props

    return(
        <div style={{flexGrow:"1", padding: "20px", display:"flex", flexDirection:"column"}}>
            <div style={{display: "flex"}}>
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>{t(node.node.description)}</span>
                <Tooltip title={<div className="tooltipText"><ul>{node.node.helperText.map((help,index) => (<li key={index}>{t(help)}</li>))}</ul></div>}>
                    <HelpIcon style={{fontSize: "14px", display:"flex", margin:"auto 5px"}}/>
                </Tooltip>
            </div>
            <div className={["internalCardContainer", "normalText", "normalMediumSize"].join("  ")} style={{display:"flex", flexDirection: "column"}}>
                {t("Não há configurações disponíveis para esse nó.")}
            </div>
            <div style={{display:"flex", margin: "auto auto 5px auto"}}>
                <div
                    className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")}
                    style={{width:"120px"}}
                    onClick={handleConfigurationClose}
                >
                    {read_only ? "X" : t("discard")}
                </div>
            </div>
        </div>
    )
}

export class ScoreModifierBoxClass {
    constructor(rulesContext){
        this.rulesContext = rulesContext
    }
    validate(node){
        let put_error = false
        let alerts = []
        //Validate if node has at least one children
        if((node.node.children || []).length !== 1){
            put_error = true
            alerts.push("Este nó precisa ter pelo menos um único filho")
        }
        else{
            let childType = node.node.children[0].instance.getType(node.node.children[0])
            if (childType !== "number") {
                put_error = true
                alerts.push("O filho deste nó precisa ter um retorno 'Número'. Atualmente ele retorna " + translateType(childType))
            }
        }
        //Validate if parent is valid
        if (node.parentNode != null) {
            if(node.parentNode.on_error){
                put_error = true
                alerts.push("O nó superior está inválida")
            }
        }
        return {validated: !put_error, alerts: alerts}
    }

    getType(node){
        return "void"
    }
}