import React, { useState } from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import Collapse from "@material-ui/core/Collapse";
import DataField from "../../utils/DataField"
import { formatPhone, formatAddress, time_feature, getStatusColor, getStatus, getStatusSiglum, checkNested, clientAge, formatAsMoney, getReservationFormat, getRentalDaysFormat, translateGender} from "../../utils/utils"
import moment from 'moment'
import PriceDescriptionDialog from "../../RentalAgreement/Rental/PriceDescriptionDialog/PriceDescriptionDialog";
import ValidatedImage from "../../utils/ValidatedImage";
import { Tooltip } from "@material-ui/core";


function RentalAgreementCardSAC(props) {

    const [expanded, setExpanded] = useState(true);
    
    const handleExpand = (event) => {
        event.stopPropagation();
        setExpanded(!expanded);
    };

    let [priceDescriptionOpen, setPriceDescriptionOpen] = useState(false);

    const handlePriceDescriptionOpenClick = () => {
        setPriceDescriptionOpen(true);
    };

    const handlePriceDescriptionClose = () => {
        setPriceDescriptionOpen(false);
    };

    let getPictureContent = (picture) => {
        if (picture == null) {
            return (          
                <div 
                    className={["labelText", "normalMediumSize"].join(" ")} 
                    style={{textAlign:"center", margin:"auto"}}
                >
                    Informação Indisponível
                </div>
            )
        } 
        else{
            return (
                <ValidatedImage
                    image_key={picture}
                    dash_api_endpoint_url={'/dash/car_rental/image/profile_picture'}
                    imageStyle = {{margin:"auto", width:"234px", maxHeight:"234px", borderRadius: "5px"}}
                    imageAlt = {"Foto do Cliente"}
                />
            );
        }
    };

    return (
        <Card className={["listCard", "collapse"].join(" ")} >
            <div style={{display:'grid', gridTemplateColumns: '20% 20% 20% 13% 9% 13% 5%'}}>
                <div 
                    className={["listCardItem", "link", "subtitleSize", "blueText"].join(" ")}
                    style={{flex:"1 1 180px", textAlign:"center"}}
                >
                    Locação #{props.rental_agreement.rental_agreement_code}
                </div> 
                <div 
                    className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                    style={{flex:"1 1 200px", textAlign:"center"}}
                >
                    CPF: {checkNested(props.rental_agreement,"client","document_number") ? props.rental_agreement.client.document_number : "-"}
                </div>
                <div 
                    className={["listCardItem", "labelText", "subtitleSize"].join(" ")}
                    style={{flex:"1 1 200px", textAlign:"center"}}
                >
                    {time_feature(props.rental_agreement)}
                </div>
                <div 
                    className={["listCardItem", "normalText", "subtitleSize", "bold"].join(" ")} 
                    style={{flex:"1 1 45px", textAlign:"center", cursor: "default"}}  
                >
                    {props.rental_agreement.external_reason}
                </div>
                <div 
                    className={["listCardItem", "normalText", "normalMediumSize", "bold"].join(" ")} 
                    style={{flex:"1 1 45px", textAlign:"center", cursor: "default"}}  
                >
                    <Tooltip title={<p className="tooltipText">{getStatus(props.rental_agreement.fraud_status)}</p>}>
                    <div className={["circle", "small"].join(" ")} style={{backgroundColor:getStatusColor(props.rental_agreement.fraud_status)}}>
                        {getStatusSiglum(props.rental_agreement.fraud_status)}
                    </div>
                    </Tooltip>
                </div>
                <div 
                    className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                    style={{flex:"1 1 100px", textAlign:"center"}}
                >
                    Grupo: {props.rental_agreement.car.model_group}
                </div>
                <div>
                    <IconButton onClick={handleExpand}>
                        {expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </IconButton>
                </div>
            </div>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
            <div className="internalCollapse" style={{display:'grid', gridTemplateColumns: '20% 40% 40%'}}>
                <div style={{display:"flex", width:"120px", minHeight:"130px"}}>
                    {getPictureContent((props.rental_agreement.client || {}).face_picture)}
                </div>
                <div style={{marginLeft:"20px"}}> 
                    <div className={["blueText", "subtitleSize"].join(" ")} style={{marginBottom:"10px"}}>Dados Básicos</div>
                    <div> 
                        <DataField
                            title={"Nome"}
                            label={checkNested(props.rental_agreement, "client", "name") ? 
                                props.rental_agreement.client.name : "-"
                            }
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                        />
                        <DataField
                            title={"Gênero"}
                            label={
                                checkNested(props.rental_agreement, "client", "gender") ? 
                                translateGender(props.rental_agreement.client.gender) : "-"
                            }
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                        />
                        <DataField
                            title={"Data de Nascimento"}
                            label={
                                checkNested(props.rental_agreement, "client", "birthdate") ? 
                                clientAge(props.rental_agreement.client.birthdate) : "-"
                            }
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                        />
                        <DataField
                            title={"Nome da Mãe"}
                            label={checkNested(props.rental_agreement, "client", "mother_name") ? 
                                props.rental_agreement.client.mother_name : "-"
                            }
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                        />
                        <DataField
                            title={"Email"}
                            label={checkNested(props.rental_agreement, "client", "email") ? 
                                props.rental_agreement.client.email : "-"
                            }
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                        />
                        <DataField
                            title={"Telefone"}
                            label={checkNested(props.rental_agreement, "client", "phones") ? 
                                    props.rental_agreement.client.phones.length > 0 
                                        ? formatPhone(props.rental_agreement.client.phones[0], "-") : "-" : "-"
                            }
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                        />
                        <DataField
                            title={"Endereço"}
                            label={checkNested(props.rental_agreement, "client", "residential_address") ? formatAddress(props.rental_agreement.client.residential_address,"-") : "-"}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                        />
                    </div>
                </div>
                <div style={{marginLeft:"20px"}}> 
                    <div className={["blueText", "subtitleSize"].join(" ")} style={{marginBottom:"10px"}}>Locação</div>
                    <div> 
                        <DataField
                            title={"Grupo"}
                            label={props.rental_agreement.car?.model_group 
                                ? props.rental_agreement.car?.model_group.group_description 
                                    ? props.rental_agreement.car?.model_group + " " + props.rental_agreement.car?.model_group.group_description
                                    : props.rental_agreement.car?.model_group 
                                : "Indisponível"}                 
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                        />
                        <DataField
                            title={"Grupo de Upgrade"}
                            label={(((props.rental_agreement||{}).car||{}).upgrade_model_group||"-")}                 
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                        />
                        <DataField
                            title={"Loja de Retirada"}
                            label={checkNested(props.rental_agreement, "rental_store") ? 
                                props.rental_agreement.rental_store : "-"
                            }
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                        />
                        <DataField
                            title={"Data da Reserva"}
                            label={getReservationFormat(props.rental_agreement)}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                        />
                        <DataField
                            title={"Data da Retirada"}
                            label={moment(props.rental_agreement.rental_agreement_date).format("DD/MM/YYYY - HH:mm")}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                        />
                        <DataField
                            title={"Data de Devolução"}
                            label={getRentalDaysFormat(props.rental_agreement)}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                        />
                        <DataField
                            title={"Tarifa"}
                            label={props.rental_agreement.fare_name ? 
                                props.rental_agreement.fare_name : "-"
                            }
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                        />
                        <DataField
                            title={"Valor Total"}
                            label={checkNested(props.rental_agreement, "final_price") ? 
                                formatAsMoney(props.rental_agreement.final_price, "-") : "-"
                            }
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            display={"inline"}
                        />
                        <div style={{display:"inline", marginLeft: '10px'}}>
                            <span
                                className={["blueText", "normalMediumSize", "link"].join(" ")}
                                onClick={handlePriceDescriptionOpenClick}
                            >
                                Ver detalhes
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {props.rental_agreement.observation ?
                <div>
                    <div className={["blueText", "subtitleSize"].join(" ")} style={{marginBottom:"10px"}}>
                        Observação
                    </div>
                    <div>
                        {props.rental_agreement.observation||""}
                    </div>
                </div> :
                null
            }
            <PriceDescriptionDialog
                open={priceDescriptionOpen}
                onClose={handlePriceDescriptionClose}
                rental_agreement={props.rental_agreement}
            />
            </Collapse>
        </Card>
    );
}

export default RentalAgreementCardSAC;
