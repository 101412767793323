import React, {useState, useContext, useEffect} from 'react'
import { useTranslation } from "react-i18next";
import {GetAlertsComponent,setObjectByPath, validateConstantValues, translateType, colorVelvet} from "./utils"
import TextField from "@material-ui/core/TextField";
import MenuItem from '@material-ui/core/MenuItem';
import RulesContext from "../../../../context/rules-context"
import _ from "lodash"
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import DeleteIcon from '@material-ui/icons/Delete';
import TimeDeltaTextField from './Components/TimeDeltaTextField';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export function EventFieldVariabilityBox (props) {
    const { t } = useTranslation();
    let {node} = props
    let {read_only} = props
    let {handleConfigurationOpen} = props
    let {handleNodeRemove} = props
    let rulesList = useContext(RulesContext)
    let accumulated_event = rulesList.fields_mapper_list.filter(rule => rule.path === node.node.properties.variable_field_path)[0]
    let referencedString = node.node.properties.referenced_field_paths.reduce((acc, path, idx, src) => {
        let event = rulesList.fields_mapper_list.filter(rule => rule.path === path)[0]
        let description = (event || {description:""}).description
        if (idx === 0) acc = acc + description
        else if (idx === src.length -1) acc = acc + " e " + description
        else acc = acc + ", " + description
        return acc
    }, "")

    function getVisualPattern(value){
        let dateSplit = value.split("T")[0]

        let yearSplit = dateSplit.split("Y")
        let yearValue = ""
        if (yearSplit.length > 1){
            yearValue = yearSplit[0].replace(/\D/g, "");
            yearValue = /^0*$/.test(yearValue) ? "" :  yearValue + t(" Anos, ")
            dateSplit = yearSplit[1]
        }

        let monthSplit = dateSplit.split("M")
        let monthValue = ""
        if (monthSplit.length > 1){
            monthValue = monthSplit[0].replace(/\D/g, "");
            monthValue = /^0*$/.test(monthValue) ? "" :  monthValue + t(" Meses, ")
            dateSplit = monthSplit[1]
        }

        let weekSplit = dateSplit.split("W")
        let weekValue = ""
        if (weekSplit.length > 1){
            weekValue = weekSplit[0].replace(/\D/g, "");
            weekValue = /^0*$/.test(weekValue) ? "" :  weekValue + t(" Semanas, ")
            dateSplit = weekSplit[1]
        }

        let daySplit = dateSplit.split("D")
        let dayValue = ""
        if (daySplit.length > 1){
            dayValue = daySplit[0].replace(/\D/g, "");
            dayValue = /^0*$/.test(dayValue) ? "" :  dayValue + t(" Dias, ")
            dateSplit = daySplit[1]
        }

        let timeSplit = value.split("T").length > 1 ? value.split("T")[1] : ""

        let hourSplit = timeSplit.split("H")
        let hourValue = ""
        if (hourSplit.length > 1){
            hourValue = hourSplit[0].replace(/\D/g, "");
            hourValue = /^0*$/.test(hourValue) ? "" :  hourValue + t(" Horas, ")
            timeSplit = hourSplit[1]
        }
        let minuteSplit = timeSplit.split("M")
        let minuteValue = ""
        if (minuteSplit.length > 1){
            minuteValue = minuteSplit[0].replace(/\D/g, "");
            minuteValue = /^0*$/.test(minuteValue) ? "" :  minuteValue + t(" Minutos, ")
            timeSplit = minuteSplit[1]
        }
        let secondSplit = timeSplit.split("S")
        let secondValue = ""
        if (secondSplit.length > 1){
            secondValue = secondSplit[0].replace(/\D/g, "");
            secondValue = /^0*$/.test(secondValue) ? "" :  secondValue + t(" Segundos")
        }
        return yearValue + monthValue + weekValue + dayValue + hourValue + minuteValue + secondValue
    }
    
    return (
        <div>
            {!read_only ?
                <div style={{display:"flex", alignItems: "center"}}>
                    <div 
                        style={{display:"flex", width:"100%", cursor:"pointer", margin: "0px"}} 
                        onClick={() => handleConfigurationOpen(node)}
                    >
                        <div style={{display: "flex",flexDirection: "column",margin: "auto 0px"}}>
                            <div className={["normalText","normalMediumSize"].join(" ")}>
                            {t("Contar variações de")} {t((accumulated_event || {description:""}).description)} {t("para o mesmo")} {t(referencedString)}
                            </div>
                            <div className={["normalText","normalSmallSize"].join(" ")}>
                            {t("Com no mínimo")} {t(node.node.properties.match_number)} {t("correspondência(s)")}
                            {node.node.properties.timedelta_value 
                            ? 
                            t(" nos últimos ") + t(getVisualPattern(node.node.properties.timedelta_value))
                            :
                            t(" em todo o histórico")}
                            </div>
                        </div>
                        {(node.node.alerts || []).length > 0?
                        <GetAlertsComponent alerts={node.node.alerts} />  : null}       
                    </div>
                    <IconButton style={{width: 32, height: 32}} aria-label="duplicate" onClick={() => props.handleNodeDuplication(node)}>
                        <ContentCopyIcon style={{width: 20, height: 20}} />
                    </IconButton>
                    <IconButton style={{width: 32, height: 32}} aria-label="close" onClick={() => handleNodeRemove(node)}>
                        <CloseIcon style={{width: 20, height: 20}} />
                    </IconButton>
                </div>
            :
                <div style={{display:"flex"}}>
                    <div 
                        style={{display:"flex", width:"100%", cursor:"pointer", margin: "0px"}} 
                        onClick={() => handleConfigurationOpen(node)}
                    >
                        <div style={{display: "flex",flexDirection: "column",margin: "auto 0px"}}>
                            <div className={["normalText","normalMediumSize"].join(" ")}>
                            {t("Contar variações de")} {t((accumulated_event || {description:""}).description)} {t("para o mesmo")} {t(referencedString)}
                            </div>
                            <div className={["normalText","normalSmallSize"].join(" ")}>
                            {t("Com no mínimo")} {t(node.node.properties.match_number)} {t("correspondência(s)")}
                            {node.node.properties.timedelta_value 
                            ? 
                            t(" nos últimos ") + t(getVisualPattern(node.node.properties.timedelta_value))
                            :
                            t(" em todo o histórico")}
                            </div>
                        </div>   
                    </div>
                </div>
            }
        </div>
    )
}

export function EventFieldVariabilityConfiguration(props){
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
    PaperProps: {
        style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 280,
        },
    },
    };

    const { t } = useTranslation();
    let {node} = props
    let {read_only} = props
    let {handleNodeChange} = props
    let {handleConfigurationClose} = props
    let rulesList = useContext(RulesContext)
    let settingsFilters = (useContext(RulesContext).boxes[node.node.type].settings || {}).filters || []

    const [nodeState, setNodeState] = useState(node)

    useEffect(()=>{
        setNodeState(node)
    },[node])

    const validateAllValues = () => {
        let doesHaveDuplicates = nodeState.node.properties.referenced_field_paths.some(
            (val, i) => nodeState.node.properties.referenced_field_paths.indexOf(val) !== i
        )
        if (doesHaveDuplicates) return false

        let referenced_field_validation = nodeState.node.properties.referenced_field_paths.filter(
            path => !path || path.length === 0).length === 0
        let match_number_validation =  validateConstantValues("number",nodeState.node.properties.match_number)
        let variable_field_validation = (nodeState.node.properties.variable_field_path && nodeState.node.properties.variable_field_path.length >0)
        let timedelta_validation = nodeState.node.properties.timedelta_value 
                                    ? 
                                    validateConstantValues("timedelta",nodeState.node.properties.timedelta_value)
                                    :
                                    true
        return variable_field_validation && referenced_field_validation && timedelta_validation && match_number_validation
    }

    const handleAdd = () => {
        let new_node = _.cloneDeep(nodeState)
        new_node.node.properties.referenced_field_paths = [...new_node.node.properties.referenced_field_paths, ""]
        setNodeState(new_node)
    }

    const handleRemove = (index) => {
        let new_node = _.cloneDeep(nodeState)
        new_node.node.properties.referenced_field_paths.splice(index,1)
        if (new_node.node.properties.match_number > new_node.node.properties.referenced_field_paths.length){
            setObjectByPath(new_node.node,"properties.match_number",new_node.node.properties.referenced_field_paths.length)
        }
        setNodeState(new_node)
    }

    const handleMatchChange = (e) => {
        let new_node = _.cloneDeep(nodeState)
        setObjectByPath(new_node.node,"properties.match_number",e.target.value)
        setNodeState(new_node)
    }

    const handleReferenceEventChange = (e, index) => {
        let new_node = _.cloneDeep(nodeState)
        new_node.node.properties.referenced_field_paths.splice(index,1,e.target.value)
        setNodeState(new_node)
    }

    const handleEventChange = (e) => {
        let new_node = _.cloneDeep(nodeState)
        setObjectByPath(new_node.node,"properties.variable_field_path",e.target.value)
        setNodeState(new_node)
    }


    const handleValueChange = (path, val) => {
        let new_node = _.cloneDeep(nodeState)
        if (val === "P") val = null
        setObjectByPath(new_node.node,path,val)
        setNodeState(new_node)
    }

    const handleSave = () => {
        handleConfigurationClose()
        handleNodeChange(nodeState)
    }


    const handleFilterChange = (event, path) => {
        let new_node = _.cloneDeep(nodeState)

        const { value } = event.target
        
        if(!new_node.node.properties.filters){
            new_node.node.properties.filters = {}
        }

        if(!new_node.node.properties.filters[path]){
            new_node.node.properties.filters[path] =[]
        }

        new_node.node.properties.filters[path] = value;

        if(new_node.node.properties.filters[path].length === 0){
            delete new_node.node.properties.filters[path]
        }

        if(_.size(new_node.node.properties.filters)=== 0) {
            delete new_node.node.properties.filters
        }

        setNodeState(new_node)
    };

    let options_dictionary = {}
    for(let filter of settingsFilters){
        options_dictionary[filter.path] = {};
        for(let option of filter.options){
            options_dictionary[filter.path][option.enum] = option.description
        }
    }

    let filter_rendered_values = {}
    for(let filter of settingsFilters){
        let translated_values = []
        for(let selected_option of ((nodeState.node.properties.filters||{})[filter.path]||[])){
            translated_values.push(options_dictionary[filter.path][selected_option])
        }
        filter_rendered_values[filter.path] = translated_values.join(', ')
    }


    return(
        <div style={{flexGrow:"1", padding: "20px", display:"flex", flexDirection:"column", width: "100%"}}>
            <div style={{display: "flex"}}>
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>{t(node.node.description)}</span>
                <Tooltip title={<div className="tooltipText"><ul>{node.node.helperText.map((help,index) => (<li key={index}>{t(help)}</li>))}</ul></div>}>
                    <HelpIcon style={{fontSize: "14px", display:"flex", margin:"auto 5px"}}/>
                </Tooltip>
            </div>
            <div className="internalCardContainer" style={{display:"flex", flexDirection: "column"}}>
                <TextField 
                    label={t("Campo Variado")}
                    style={{textAlign:"center", width:"80%", margin:"3px auto"}}
                    error={nodeState.node.properties.variable_field_path === ""}
                    className="filterTextbox" 
                    size="small" fullWidth 
                    value={nodeState.node.properties.variable_field_path || ""}
                    onChange={handleEventChange}
                    select
                    InputProps={{
                        readOnly: read_only
                      }}
                >
                    {rulesList.fields_mapper_list.map((field, index) => (
                        <MenuItem key={index} value={field.path}><em className="normalText">{t(field.description) + " ("+ t(translateType(field.type))+ ")"}</em></MenuItem>
                    ))}
                </TextField>
                {nodeState.node.properties.referenced_field_paths.map((row,index) => (
                    <div key={index} style={{display:"flex", width:"80%", margin:"3px auto"}}>
                        <TextField 
                            label={t("Campo de Referência")}
                            error={
                                nodeState.node.properties.referenced_field_paths[index] === "" || 
                                nodeState.node.properties.referenced_field_paths.filter(
                                    path => path === nodeState.node.properties.referenced_field_paths[index]
                                ).length >1
                            }
                            style={{textAlign:"center"}}
                            className="filterTextbox" 
                            size="small" fullWidth 
                            value={nodeState.node.properties.referenced_field_paths[index] || ""}
                            onChange={(e) => handleReferenceEventChange(e,index)}
                            select
                            InputProps={{
                                readOnly: read_only
                              }}
                        >
                            {rulesList.fields_mapper_list.map((field, field_index) => (
                                <MenuItem key={field_index} value={field.path}><em className="normalText">{t(field.description) + " ("+ t(translateType(field.type))+ ")"}</em></MenuItem>
                            ))}
                        </TextField>
                        {index !== 0 && !read_only
                        ?
                        <DeleteIcon onClick={() => handleRemove(index)} style={{fill:colorVelvet, margin:"auto 0px", cursor: "pointer"}}/>
                        :
                        null}
                    </div>
                ))}
                {!read_only && 
                <div
                    style={{cursor:"pointer", textAlign:"center", width:"80%", margin:"3px auto"}}
                    onClick={handleAdd}
                    className={["blueText", "normalSMallSize"].join(" ")}
                >
                    {t("+ NOVO CAMPO DE REFERÊNCIA")}
                </div>}
                <TextField 
                    label={t("Número de Correspondências")}
                    style={{ width:"80%", margin:"3px auto"}}
                    className="filterTextbox" 
                    size="small" fullWidth 
                    value={nodeState.node.properties.match_number}
                    onChange={handleMatchChange}
                    select
                    InputProps={{
                        readOnly: read_only
                      }}
                >
                    {nodeState.node.properties.referenced_field_paths.map((field, index) => (
                        <MenuItem key={index} value={index+1}>
                            <em className="normalText">
                                <br></br>
                                {index+1}
                            </em>
                        </MenuItem>
                    ))}
                </TextField>
                {settingsFilters.map(filter => 
                    <FormControl key={filter.path} style={{ textAlign:"center", width:"80%", margin:"3px auto"}}>
                    <InputLabel id="demo-multiple-checkbox-label">{t(filter.description)}</InputLabel>
                    <br></br>
                    <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={((nodeState.node.properties.filters||{})[filter.path]||[])}
                    onChange={(event) => handleFilterChange(event, filter.path)}
                    input={<Input />}
                    renderValue={(selected) => (filter_rendered_values[filter.path])}
                    MenuProps={MenuProps}
                    InputProps={{
                        readOnly: read_only
                      }}
                    >
                    {filter.options.map((option) => (
                        <MenuItem key={option.enum} value={option.enum} name={option.description}>
                        <Checkbox color="primary" checked={((nodeState.node.properties.filters||{})[filter.path]||[]).includes(option.enum)} />
                        <ListItemText primary={t(option.description)} />
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>)}
                <TimeDeltaTextField
                    value={nodeState.node.properties.timedelta_value ? nodeState.node.properties.timedelta_value : ""}
                    error={nodeState.node.properties.timedelta_value ? !validateConstantValues("timedelta",nodeState.node.properties.timedelta_value) : false}
                    valueChangeFunction={(value) => handleValueChange("properties.timedelta_value", value)}
                    label={t("Período de Tempo considerado")}
                    read_only={read_only}
                />               
            </div>
            <div style={{display:"flex"}}>
                <div
                    className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")}
                    style={{width:"50%"}}
                    onClick={handleConfigurationClose}
                >
                    {read_only ? "X" : t("discard")}
                </div>
                {!read_only && 
                <div
                    className={
                        validateAllValues()
                        ?
                        ["button", "standard", "normalText", "normalMediumSize"].join(" ")
                        :
                        ["button", "standard", "normalText", "normalMediumSize","disabled"].join(" ")}
                    style={{width:"50%"}}
                    onClick={validateAllValues() ? handleSave : null}
                >
                    {t("save")}
                </div>}   
            </div>
        </div>
    )
}

export class EventFieldVariabilityBoxClass {
    constructor(rulesContext){
        this.rulesContext = rulesContext
    }
    validate(node){
        let put_error = false
        let alerts = []
        //Validate if node has one or two children
        if((node.node.children || []).length !== 0){
            put_error = true
            alerts.push("Este nó não deve possuir filhos")
        }
        if(node.node.properties.variable_field_path === ""){
            put_error = true
            alerts.push("Clique neste nó, abra a configuração e escolha um Campo Variado")
        }
        if((node.node.properties.referenced_field_paths || []).length === 0){
            put_error = true
            alerts.push("Este nó deve ter opções escolhidas como Campo de Referência")
        }
        else{
            let doesHaveDuplicates = node.node.properties.referenced_field_paths.some((val, i) => node.node.properties.referenced_field_paths.indexOf(val) !== i)
            if (doesHaveDuplicates){
                put_error = true
                alerts.push("Este nó não deve ter Campos de Referência duplicados")
            }
            let invalidFields = node.node.properties.referenced_field_paths.filter(path => !path || path.length === 0)
            if (invalidFields.length > 0){
                put_error = true
                alerts.push("Todos os Campos de Referência deste nó devem ter opções válidas")
            }
        }
        if(!validateConstantValues("number",node.node.properties.match_number)){
            put_error = true
            alerts.push("Este nó deve ter um valor válido para Número de Correspondências")
        }
        if (node.node.properties.timedelta_value) {
            if (!validateConstantValues("timedelta",node.node.properties.timedelta_value)){
                put_error = true
                alerts.push("Deve ser fornecido um período de tempo válido")
            }
        }
        //Validate if parent is valid
        if (node.parentNode != null) {
            if(node.parentNode.on_error){
                put_error = true
                alerts.push("O nó superior está inválido")
            }
        }
        return {validated: !put_error, alerts: alerts}
    }

    getType(node){
        return "number"
    }
}
