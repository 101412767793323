import React from 'react'
import { useTranslation } from "react-i18next";

export default function Button (props){
        
    const { t } = useTranslation();

    const button_options = {
        standard_disabled: "button standard disabled normalText normalMediumSize",
        standard_disabled_white: "button standard disabled white normalText normalMediumSize",
        standard: "button standard normalText normalMediumSize",
        cancel: "button cancel",
        standard_disabled_gray: "button standard disabled gray normalText normalMediumSize",
    }

    return(
        <div
            className={button_options[props.button_option]}
            onClick={props.onClick} 
            style={{minWidth:"40%", margin: "20px auto auto"}}
        >
            {t(props.buttonLabel)}
        </div>
    )
}