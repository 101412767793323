import React, {useState, useEffect} from 'react'
import {removeDuplicatesBy, titleCase} from "../../../../utils/utils"
import {getSeverityColor,getSeverityStatus, getTriggerRisk} from "../../../../utils/utilsAlert"
import moment from "moment"
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import {Tooltip, IconButton} from "@material-ui/core";

function Triggers(props){

    let {triggers} = props

    const [noDuplicateTriggers, setNoDuplicateTriggers] = useState(null)

    useEffect(() => {
        if (triggers && triggers.length > 0){
            let reversedTriggers = triggers.reverse()
            let maximizedTrigger = getTriggerRisk(reversedTriggers)
            let noDuplicateTriggers_ = removeDuplicatesBy(trigger => trigger.category, maximizedTrigger)

            let triggers_per_category_quantity = triggers.reduce((acc, item) => {
                return {...acc, [item.category]: (acc[item.category] || 0) + 1}  
            },{})

            for (let trigger of noDuplicateTriggers_){
                console.log("triggers_per_category_quantity[trigger.category]", triggers_per_category_quantity[trigger.category])
                trigger["category_quantity"] = triggers_per_category_quantity[trigger.category]
            }
            setNoDuplicateTriggers(noDuplicateTriggers_)
        }
	},[triggers])

    if (triggers == null || triggers.length ===0){
        return (
            <div style={{display: "flex", flexGrow: "1"}}>
                <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                    Nenhum gatilho disponível
                </div>
            </div>
        )
    }
    
    if (noDuplicateTriggers){
        return (
            <div className="internalCardContainer">
                {noDuplicateTriggers.map((trigger,index)=>(
                <div key={index} style={{display: "flex", margin: "5px auto", justifyContent: 'space-between', alignItems: 'center'}}>
                        <Tooltip title={<p className="tooltipText">{getSeverityStatus(trigger.risk_level)}</p>}>
                            <div style={{display:"flex", margin:"auto 0px"}}>
                                <ErrorOutlineIcon style={{color:getSeverityColor(trigger.risk_level)}}/>
                            </div>
                        </Tooltip>
                    <div className="labelSpace" style={{maxWidth: '80%'}}>
                            <div className={["normalText", "normalMediumSize"].join(" ")}>
                                {titleCase(trigger.category_description, trigger.category)+ " | " + moment(trigger.trigger_group.occurrence_date).format("DD/MM/YYYY - HH:mm:ss")}
                            </div>
                        <div className={["labelText", "normalSmallSize"].join(" ")} >
                            {trigger.description}
                        </div>
                    </div>   
                    <Tooltip title={<p className="tooltipText">{"Quantidade de gatilhos por tipo "}</p>}>
                        <div>
                            <IconButton size="small" >
                                {trigger.category_quantity}
                            </IconButton>
                        </div> 
                    </Tooltip>  
                </div>
                ))}
            </div>
        )        
    }
    return null

}

export default Triggers