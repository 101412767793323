//Importing modules
import React from 'react'
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from '@material-ui/icons/Clear';
import MenuItem from '@material-ui/core/MenuItem';
import NumberFormat from 'react-number-format';
import InputMask from "react-input-mask";
import TextField from '@material-ui/core/TextField';
import moment from 'moment';



//Check table row class to render different collors
export const getTableRowClass = (rowIndex) => {
    if (rowIndex % 2 === 0) {
      return "even";
    }
    return "odd";
}



//Check if nested key exists inside object
export function checkNested (obj /*, level1, level2, ... levelN*/) {
    if (obj == null) return false
    var args = Array.prototype.slice.call(arguments, 1);

    for (var i = 0; i < args.length; i++) {
        if (!obj || !obj.hasOwnProperty(args[i])) {
        return false;
        }
        obj = obj[args[i]];
    }
    return true;
}



//Change case of first letter of each word in a string to UpperCase
export const titleCase = (str, nullCase) => {
    if (str == null) {
      return nullCase
    }
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
};



//Render confirmation or rejection icon for dialogs results screen
export const getConfirmationIconDialog = (status) => {
    if (status){
        return (<CheckIcon className="fraudStatusIcon" style={{color: colorGreen, width:"80px", height:"80px"}}/>)
    }
    else{
        return (<ClearIcon className="fraudStatusIcon" style={{color: colorRed, width:"80px", height:"80px"}}/>)
    }
}
  


//Render default confirmation or rejection icon
export const getConfirmationIcon = (status) => {
    if (status){
        return (<CheckIcon className="fraudStatusIcon" style={{color: colorGreen, width:"40px", height:"40px"}}/>)
    }
    else{
        return (<ClearIcon className="fraudStatusIcon" style={{color: colorRed, width:"40px", height:"40px"}}/>)
    }
}



//Exporting function to render specific text field for each data type
export function getTextField(type){
    switch(type) {
        case "string":
            return(
                <TextField 
                    className="inputTextbox" 
                />                     
            )
        case "bool":
            return(
                <TextField 
                    className="boolTextbox" 
                    select
                >
                    <MenuItem key={0} value={true}><em className="dropDownTextFieldText">Verdadeiro</em></MenuItem>
                    <MenuItem key={1} value={false}><em className="dropDownTextFieldText">Falso</em></MenuItem>
                </TextField>                      
            )
        case "number":
            return(
                <NumberFormat
                    customInput={TextField}
                    type="text"
                    decimalSeparator={","}
                    className="inputTextbox" 
                />
            )
        case "datetime":
            return(
                <InputMask 
                    mask={"99/99/9999 99:99:99"}
                    maskPlaceholder=""
                >
                    <TextField 
                        autoFocus
                        size="small"
                        className="inputTextbox" 
                    />
                </InputMask>
            )
        case "time":
            return(
                <InputMask 
                    mask={"99:99:99"}
                    maskPlaceholder=""
                >
                    <TextField 
                        autoFocus
                        size="small"
                        className="inputTextbox" 
                    />
                </InputMask>
            )
        case "timedelta":
                return(
                    <InputMask 
                        mask={"999 Di\\as, 99 Hor\\as, 99 Minutos"}
                        maskPlaceholder=""
                    >
                        <TextField 
                            autoFocus
                            size="small"
                            className="inputTextbox" 
                        />
                    </InputMask>
                )
        case "geo_point":
            const digit = /[\d]/
            const signal = /[+-]/
                return(
                    <InputMask 
                        mask={[signal,digit,digit,digit,".",digit,digit,digit,digit,digit,digit,","," ",signal,digit,digit,".",digit,digit,digit,digit,digit,digit]}
                        maskPlaceholder=""
                    >
                        <TextField 
                            autoFocus
                            size="small"
                            className="inputTextbox" 
                        />
                    </InputMask>
                )
        default:
            throw new Error ("Invalid field Type " + type)
    }
}



//Format datetime to standard form
export const getDatetimeFormatted = (value, type) => {
    if(type === "datetime" && moment(value, "DD/MM/YYYY HH:mm:ss",true).isValid()){
        return moment(value, "DD/MM/YYYY HH:mm:ss", true).format("YYYY-MM-DDTHH:mm:ssZ") 
    }
    else if (type === "timedelta" && /^[0-9]{3} Dias, [0-9]{2} Horas, [0-9]{2} Minutos$/.test(value)) {
        return "P" + value.replace("Dias", "DT").replace("Horas", "H").replace("Minutos", "M").replaceAll(" ", "").replaceAll(",","")
    }
    else return value
}



//Validate inputs on each type of text boxes
export const validateConstantValues = (constant_type,value) => {
    switch(constant_type) {
        case "string":
            if(typeof value !== "string" || value.length === 0) return true
            else return false
        case "bool":
            if (typeof value !== "boolean") return true
            else return false
        case "number":
            if (typeof value !== "number") return true
            else return false
        case "datetime":
            return !moment(value, moment.ISO_8601, true).isValid()
        case "time":
            return !moment(value, "HH:mm:ss", true).isValid()
        case "timedelta":
            return !(/^P((-)?[0-9]+W)?((-)?[0-9]+D)?(T((-)?[0-9]+H)?((-)?[0-9]+M)?((-)?[0-9]+S)?)?$/.test(value))
        case "geo_point":
            if (Array.isArray(value)){
                if (value.length === 2) {
                    if (value[0] != null && value[0] >= -180 && value[0] <= 180 && value[1] != null && value[1] >= -90 && value[1] <= 90) {
                        return false
                    }
                }
            }
            return true
        default:
            throw new Error ("Invalid field Type " + constant_type)
    }
}


//Translate FieldValue to string
export const translateFieldValue = (fieldValue, fieldType) => {
    let translatedFieldValue = ""
    if (fieldType === "bool") {
        if (fieldValue === true) {
            translatedFieldValue = "Verdadeiro"
        }
        else if (fieldValue === false) {
            translatedFieldValue = "Falso"
        }
    }
    else if (fieldType === "datetime") {
        translatedFieldValue =  moment(fieldValue, moment.ISO_8601, true).format("DD/MM/YYYY HH:mm:ss") 
    }
    else if (fieldType === "timedelta") {
        let periodArray = fieldValue.split("T")
        if (periodArray.length > 1) {
            let translatedDateArray = periodArray[0].split("").reduce((acc, currentValue, index, sourceArray) => {
                if (index > 0 && sourceArray[index-1] > 1){
                    acc = acc + currentValue.replace("W", " Semanas, ").replace("D", " Dias, ")
                }
                else {
                    acc = acc + currentValue.replace("W", " Semana, ").replace("D", " Dia, ")
                }
                return acc
            }, "")
            translatedDateArray = translatedDateArray.slice(1,translatedDateArray.length)
            let translatedTimeArray = periodArray[1].split("").reduce((acc, currentValue, index, sourceArray) => {
                if (index > 0 && sourceArray[index-1] > 1){
                    acc = acc + currentValue.replace("H", " Horas, ").replace("M", " Minutos, ").replace("S", " Segundos, ")
                }
                else {
                    acc = acc + currentValue.replace("H", " Hora, ").replace("M", " Minuto, ").replace("S", " Segundo, ")
                }
                return acc
            }, "")
            translatedTimeArray = translatedTimeArray.slice(0,translatedTimeArray.length-2)
            translatedFieldValue = String(translatedDateArray +  translatedTimeArray)
        }
        else {
            let translatedDateArray = periodArray[0].split("").reduce((acc, currentValue, index, sourceArray) => {
                if (index > 0 && sourceArray[index-1] > 1){
                    acc = acc + currentValue.replace("W", " Semanas, ").replace("D", " Dias, ")
                }
                else{
                    acc = acc + currentValue.replace("W", " Semana, ").replace("D", " Dia, ")
                }
                return acc
            }, "")
            translatedFieldValue = translatedDateArray.slice(1,translatedDateArray.length-2)
        }
    }
    else if (fieldType === "geo_shape") {
        translatedFieldValue = "Polígono"
    }
    else if (fieldType === "geo_point"){
        let latitudeString = Math.abs(String(fieldValue[1])) + "°"
        latitudeString = fieldValue[1] < 0 ? "South " + latitudeString : "North " + latitudeString
        
        let longitudeString = Math.abs(String(fieldValue[0])) + "°"
        longitudeString = fieldValue[0] < 0 ? "West " + longitudeString : "East " + longitudeString

        translatedFieldValue =  latitudeString + ", " +longitudeString
    }
    else {
        translatedFieldValue = String(fieldValue)
    }
    return translatedFieldValue
}



//Translate error messages
export const translateErrorMessage = (errorMessage) => {
    let translatedErrorMessage = ""
    if (errorMessage === "Invalid number of fields"){
        translatedErrorMessage = <p>O arquivo csv fornecido possui linhas com número de colunas diferente ao da lista de destino.</p>
    }
    else if (errorMessage === "Invalid number of headers"){
        translatedErrorMessage = <p>O arquivo csv fornecido possui cabeçalho com número de colunas diferente ao da lista de destino. Certifique-se de usar ";" como separador.</p>
    }
    else if (errorMessage === "Invalid headers"){
        translatedErrorMessage = <p>O arquivo csv fornecido possui cabeçalho diferente ao da lista de destino.</p>
    }
    else if (errorMessage === "Invalid field content"){
        translatedErrorMessage = <p>O arquivo csv fornecido possui campos com dados de tipo diferente do tipo permitido pela tabela de destino.</p>
    }
    else if (errorMessage === "Invalid csv"){
        translatedErrorMessage = <p>O arquivo fornecido não é um arquivo csv válido.</p>
    }
    else if (errorMessage === "Empty csv"){
        translatedErrorMessage = <p>O arquivo fornecido não possui nenhum conteúdo no corpo.</p>
    }
    else if (errorMessage === "Duplicated Entries"){
        translatedErrorMessage = <p>O arquivo fornecido possui valores chave duplicados (primeira coluna) em relação ao conteúdo da lista. Para tratar os valores chave duplicados, escolha uma das opções a seguir: "Ignorar" ou "Atualizar".</p>
    }
    else if (errorMessage === "CSV maximum size exceeded") {
        translatedErrorMessage = <p>Tamanho máximo de arquivo CSV atingido. Só é possível enviar arquivos CSV com até 1000 itens de lista.</p>
    }
    else {
        translatedErrorMessage = <p>Ocorreu um problema com o processamento da sua solicitação. Tente novamente, caso o problema persista entre em contato através do email <b>suporte.caas@qitech.com.br</b>, informando esta mensagem e a página que ocorreu o problema.</p>
    }
    return translatedErrorMessage
}




//Check if any field is of geo_shape type
export const checkForGeoShapeField = (fields) => {
    let isGeoShapeField = fields.reduce((acc, currentValue) => {
        if (currentValue.type.enum === "geo_shape") {
            acc = true
        }
        else if (acc === true) {
            acc = true
        }
        else {
            acc = false
        }
            return acc
    }, false)
    return isGeoShapeField
}



//Exporting standard collors
export const colorGreen = '#73C580'
export const colorRed ='#D91A1A'
export const colorYellow = "#FFBB24"
export const colorOrange = '#F77B21'
export const colorVelvet ='#DD5C5C'
export const colorGrey = 'rgb(170, 167, 153)'
export const colorBlue = '#0b1f82'