import React from 'react'
import {getConfirmationIcon} from "../../../utils/utils"

function ConfirmationContent (props) {
    return(
        <div style={{ width: "310px", height:"217px", margin:"auto"}}>
            <div className="internalCardContainer" style={{display:"flex", height:"40%", justifyContent:"center"}}>
                {getConfirmationIcon(props.data.success)}
            </div>
            <div className="internalCardContainer" style={{display:"flex", textAlign:"center", justifyContent:"center"}}>
                {props.data.message}
            </div>
            <div className="internalCardContainer" style={{display:"flex", justifyContent:"center"}}>
                <div 
                    className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                    onClick={props.onClose}
                >
                    VOLTAR À TELA INICIAL 
                </div>
            </div>
        </div>
    )
}

export default ConfirmationContent