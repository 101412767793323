import React, { useState, useEffect } from "react";
import ValidatedImage from "../../utils/ValidatedImage"
import CompanyStatuteDialog from "./CompanyStatuteDialog/CompanyStatuteDialog"
import { CircularProgress } from '@material-ui/core';
import axios from "axios";
import { useTranslation } from "react-i18next";

function CompanyStatuteCard(props){

    const { t } = useTranslation()

    const { ocr, ocr_type } = props

    const [OCR, setOCR] = useState(null)
    const [OCRFileType, setOCRFileType] = useState(null)
    const [OCRPictureOpen, setOCRPictureOpen] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (ocr.ocr_key){
                axios.get('/dash/ocr/' + ocr.ocr_key).then( response => {
                    let ocr_data = response.data.processed_object
                    if (ocr_data == null) throw new Error()
                    setOCR(ocr_data);             
                }).catch(error => {
                    setOCR(undefined)
                })
            }
        }, 500);
        return () => {
            clearTimeout(timer)
        }
    }, [ocr])

    useEffect(() => {
        const timer = setTimeout(() => {
            if (ocr.ocr_key){
                axios.get(`/dash/ocr/image/${ocr.ocr_key}/file_type`).then( response => {
                    let hasPDF = response.data.pdf
                    if (hasPDF) {
                        setOCRFileType("pdf");
                    } else {
                        setOCRFileType("image");
                    }
                }).catch(error => {
                    setOCRFileType(undefined)
                })
            }
        }, 500);
        return () => {
            clearTimeout(timer)
        }
    }, [ocr])

    let getPictureContent = (picture) => {
        if (picture === undefined) {
            return (          
                <div 
                    className={["labelText", "normalMediumSize"].join(" ")} 
                    style={{textAlign:"center", margin:"auto"}}
                >
                    {t("information_unavailable")}
                </div>
            )
        } 
        else{
            return (
                <ValidatedImage
                    image_key={picture}
                    dash_api_endpoint_url={'/dash/ocr/image'}
                    imageStyle = {{margin:"auto", width:"100%", height:"100%",position:"absolute", top:"-9999px",bottom:"-9999px",left:"-9999px",right:"-9999px", borderRadius: "5px", objectFit:"cover"}}
                    imageAlt = {"Foto do Documento"}
                    fileType={OCRFileType}
                />
            );
        }
    };

    if (OCR === null || OCRFileType===null) {
        return (
            <div style={{minHeight: "250px"}} className="analysisCard">
                <div className={["blueText", "subtitleSize"].join(" ")}>{t(ocr_type)}</div>
                <div style={{display:"flex", flexGrow:"1"}}>
                    <CircularProgress style={{margin:"auto"}} />
                </div>
            </div>
        );    
    }
    else if (OCR === undefined || OCRFileType===undefined){
        return (
            <div style={{minHeight: "250px"}} className="analysisCard">
                <div className={["blueText", "subtitleSize"].join(" ")}>{t(ocr_type)}</div>
                <div style={{display:"flex", flexGrow:"1"}}>
                    <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                        {t("information_unavailable")}
                    </div>
                </div>
            </div>
        )
    }
    else {
        return (
            <div style={{minHeight: "600px", width:"100%"}} className="analysisCard">
                <div className={["blueText", "subtitleSize"].join(" ")}>{t(ocr_type)}</div>
                <div className="internalCardContainer" style={{display:"flex", flexGrow:"1", maxHeight:"600px",flexDirection: "row", width:"100%"}}>
                    <div style={{display:"flex", maxHeight:"64vw", flexDirection: "column", width:"100%"}}>
                        <div style={{display:"block", width:"100%", height:"90%", margin: "0 auto", overflow:"hidden", position:"relative", alignItems: "center", justifyContent: "center",borderRadius: "5px"}}>
                            {ocr.ocr_key && getPictureContent( ocr.ocr_key )}
                        </div>
                        {ocr.ocr_key&&
                        <div 
                            onClick={()=>setOCRPictureOpen(true)}
                            className={["blueText", "normalSmallSize", "link"].join(" ")} 
                            style={{margin:"10px", textAlign:"center"}}
                        >
                            {`${t("see")} ${t(OCRFileType)}`}
                        </div>}
                    </div>
                    {ocr.ocr_key&&
                        <CompanyStatuteDialog
                            open={OCRPictureOpen}
                            onClose={()=>setOCRPictureOpen(false)}
                            image_list={[ocr.ocr_key]}
                            fileType={OCRFileType}
                    />}
                </div>
            </div>
        );
    }
}

export default CompanyStatuteCard