import React, { useEffect, useState, useReducer, useCallback, useContext } from "react"
import { dataFetchReducer, useStateConnectedToCache } from "../../Utils/requestUtils"
import Filter from "./Filter/Filter"
import Pagination from "@material-ui/lab/Pagination"
import { CircularProgress } from '@material-ui/core'
import axios from "axios"
import TransactionCard from "./TransactionCard/TransactionCard"
import ErrorBoundary from './../../Utils/ErrorBoundary'
import AuthContext from "../../../context/auth-context"
import { useHistory } from "react-router-dom"
import CreateDownloadErrorDialog from "./CreateDownloadErrorDialog/CreateDownloadErrorDialog"
import CreateCSVLimitSizeDialog from "./CreateCSVLimitSizeDialog/CreateCSVLimitSizeDialog"


function TransactionsInbox(props) {
    let history = useHistory()

    let user_data = useContext(AuthContext).user_data
    if (!user_data.roles.includes("card_issuance_transaction_viewer")) {
        history.push("")
    }

    let local_storage_object_preffix = "ciTransaction"

    const [pageParams, setPageParams] = useStateConnectedToCache(local_storage_object_preffix)

    const [pageState, dispatchPageState] = useReducer(
        dataFetchReducer,
        { fetchedData: null, isLoading: true, isError: false }
    )

    const doRequest = useCallback(
        (payload) => {
            dispatchPageState({ type: "data_fetch_init" })
            axios.get("/dash/card_issuance_backoffice/transactions", {
                params: payload
            }).then(response => {
                dispatchPageState({
                    type: "data_fetch_success",
                    payload: response.data
                })
            }).catch(error => {
                console.log(error)
                if ((error.response || {}).status === 403) dispatchPageState({ type: "data_fetch_failure_403" })
                else if ((error.response || {}).status === 404) dispatchPageState({ type: "data_fetch_failure_404" })
                else dispatchPageState({ type: "data_fetch_failure" })
            })
        }, []
    )

    let [isLoadingCSV, setIsLoadingCSV] = useState(false)

    const download_csv = () => {
        setIsLoadingCSV(true)
        let csv_filters = {}
        Object.keys(pageParams.filters).forEach((key, index) => {
            csv_filters[key] = pageParams.filters[key]
        })
        axios({
            url: '/dash/card_issuance_backoffice/transactions/csv',
            method: 'GET',
            responseType: 'blob',
            params: csv_filters
        }).then((response) => {
            setIsLoadingCSV(false);
            (response.headers['csv_line_number'] === response.headers['csv_limit_line_number']) ? handleCreateCSVLimitSizeDialogOpenClick() : handleCreateCSVLimitSizeDialogCloseClick()
            var currentdate = new Date()
            var datetime = String(currentdate.getFullYear()).padStart(4, '0') + "-"
                + String(currentdate.getMonth() + 1).padStart(2, '0') + "-"
                + String(currentdate.getDate()).padStart(2, '0')
                + "T"
                + String(currentdate.getHours()).padStart(2, '0') + "-"
                + String(currentdate.getMinutes()).padStart(2, '0') + "-"
                + String(currentdate.getSeconds()).padStart(2, '0')
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'Transações_' + datetime + '.csv')
            document.body.appendChild(link)
            link.click()
        }).catch(error => {
            setIsLoadingCSV(false)
            console.log(error)
            handleCreateDownloadErrorDialogOpenClick()
        })
    }

    let [createDownloadErrorDialog, setCreateDownloadErrorDialog] = useState(false)

    const handleCreateDownloadErrorDialogOpenClick = () => {
        setCreateDownloadErrorDialog(true)
    }

    const handleCreateDownloadErrorDialogCloseClick = () => {
        setCreateDownloadErrorDialog(false)
    }

    let [createCSVLimitSizeDialog, setCreateCSVLimitSizeDialog] = useState(false)

    const handleCreateCSVLimitSizeDialogOpenClick = () => {
        setCreateCSVLimitSizeDialog(true)
    }

    const handleCreateCSVLimitSizeDialogCloseClick = () => {
        setCreateCSVLimitSizeDialog(false)
    }

    useEffect(() => {
        let payload = {
            "page_number": pageParams.page - 1,
            "page_rows": 25
        }
        Object.keys(pageParams.filters).forEach((key, index) => {
            payload[key] = pageParams.filters[key]
        })
        const timer_ = setTimeout(() => {
            doRequest(payload)
        }, 300)
        return () => {
            clearTimeout(timer_)
        }
    }, [doRequest, pageParams])

    const handleChangePage = (event, page, filters) => {
        if (pageState.page === page) {
            return
        }
        setPageParams({ page: page, filters: filters })
    }

    const contentContainer = (dataList) => {

        let nr_of_cards = dataList['data'].length
        let cards = dataList['data'].map((transaction) => (
            <ErrorBoundary key={transaction.transaction_key}>
                <TransactionCard transaction={transaction} />
            </ErrorBoundary>
        ))

        if (nr_of_cards === 0) {
            return (
                <div className='emptyContainer'>
                    <p className={["blueText", "subtitleSize"].join(" ")}>
                        Nenhuma transação a ser analisada
                    </p>
                </div>
            )
        }

        else {
            return (
                <div className='cardContainer'>
                    <div className="listCardHeader">
                        <div
                            className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                            style={{ flex: "1 1 130px", textAlign: "center" }}
                        >
                            ID
                        </div>
                        {user_data.business_group_key ?
                            <div
                                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                                style={{ flex: "2 1 180px", textAlign: "center" }}
                            >
                                Companhia
                            </div> : null}
                        <div
                            className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                            style={{ flex: "3 1 230px", textAlign: "center" }}
                        >
                            Merchant
                        </div>
                        <div
                            className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                            style={{ flex: "1 1 180px", textAlign: "center" }}
                        >
                            Cartão
                        </div>
                        <div
                            className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                            style={{ flex: "1 1 40px", textAlign: "center" }}
                        >
                            Documento do Comprador
                        </div>
                        <div
                            className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                            style={{ flex: "1 1 130px", textAlign: "center" }}
                        >
                            Valor
                        </div>
                        <div
                            className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                            style={{ flex: "1 1 50px", textAlign: "center" }}
                        >
                            Modo de Entrada
                        </div>
                        <div
                            className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                            style={{ flex: "1 1 45px", textAlign: "center" }}
                        >
                            Status de Fraude
                        </div>
                        <div
                            className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                            style={{ flex: "1 1 110px", textAlign: "center" }}
                        >
                            Data de Autorização
                        </div>
                        <div
                            className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                            style={{ flex: "1 1 45px", textAlign: "center" }}
                        >
                            Status da Transação
                        </div>
                    </div>
                    {cards}
                </div>
            )
        }
    }

    if (pageState.fetchedData) {
        return (
            <div style={{ width: "auto", paddingBottom: "30px" }}>
                <div style={{ display: "flex" }}>
                    <div >
                        <p className={["blueText", "titleSize"].join(" ")}>TRANSAÇÕES</p>
                    </div>
                    <div style={{ margin: "auto 50px auto auto" }}>
                        {isLoadingCSV ?
                            <div
                                style={{ padding: "5px 20px", cursor: "wait" }}
                                className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                            >
                                Carregando
                            </div> : <div
                                style={{ padding: "5px 30px" }}
                                className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                                onClick={download_csv}
                            >
                                Exportar
                            </div>}
                    </div>
                </div>
                <ErrorBoundary doNothing><Filter local_storage_object_preffix={local_storage_object_preffix} setPageParams={setPageParams} /></ErrorBoundary>
                <div>{contentContainer(pageState.fetchedData)}</div>
                <div>
                    <Pagination
                        className="pagination"
                        page={pageParams.page}
                        count={parseInt(pageState.fetchedData["number_of_pages"])}
                        onChange={(e, page) => handleChangePage(e, page, pageParams.filters)}
                    />
                </div>
                <CreateDownloadErrorDialog
                    open={createDownloadErrorDialog}
                    onClose={handleCreateDownloadErrorDialogCloseClick}
                />
                <CreateCSVLimitSizeDialog
                    open={createCSVLimitSizeDialog}
                    onClose={handleCreateCSVLimitSizeDialogCloseClick}
                />
            </div>
        )
    }
    else if (pageState.isError) {
        return (
            <div style={{ width: "auto" }}>
                <div style={{ display: "flex" }}>
                    <div >
                        <p className={["blueText", "titleSize"].join(" ")}>TRANSAÇÕES</p>
                    </div>
                </div>
                <ErrorBoundary doNothing><Filter local_storage_object_preffix={local_storage_object_preffix} setPageParams={setPageParams} /></ErrorBoundary>
                <div style={{ height: "55vh", display: "flex" }}>
                    <p className={["blueText", "titleSize"].join(" ")} style={{ margin: "auto", textAlign: "center" }}>
                        {pageState.errorMessage}
                    </p>
                </div>
            </div>
        )
    }
    else if (pageState.isLoading) {
        return (
            <div style={{ width: "auto" }}>
                <p className={["blueText", "titleSize"].join(" ")}>TRANSAÇÕES</p>
                <div className='circularProgressCenter'>
                    <CircularProgress />
                </div>
            </div>
        )
    }
    else return null

}

export default TransactionsInbox