import React from 'react'
import {getAlertsFlagIcon,getAlertsFlagColor} from "../../utils/utils"

function Alerts(props) {

    if (props.fraud_status_events == null || props.fraud_status_events.length === 0 || 
        props.fraud_status_events[0].decision_metadata == null || props.fraud_status_events[0].decision_metadata.alerts == null) {
        return (
        <div className="analysisCard">
            <div className={["blueText", "subtitleSize"].join(" ")}>Alertas</div>
            <div style={{display:"flex", flexGrow:"1"}}>
                <div className={["labelText", "subtitleSize"].join(" ")} style={{margin:"auto"}}>
                    Informação Indisponível
                </div>
            </div>
        </div>
        );        
    }
    else{
        return (
            <div className="analysisCard">
                <div className={["blueText", "subtitleSize"].join(" ")}>Alertas</div>
                <div className="internalCardContainer">
                    {props.fraud_status_events[0].decision_metadata.alerts.map((alert, index) => (
                        <div key={index} style={{display: "flex", margin: "5px 0px"}}>
                            <div style={{display:"flex", margin:"auto 0px"}}>
                                {getAlertsFlagIcon(alert.type)}
                            </div>
                            <div className={["normalText", "normalSmallSize", "labelSpace"].join(" ")} style={{marginTop:"auto",marginBottom:"auto", color: getAlertsFlagColor(alert.type)}}>
                                {alert.message}
                            </div>                         
                        </div>
                    ))}
                </div>
            </div>
            );
    }
}

    export default Alerts;
