import React from 'react'
import peopleIcon from "../../../../assets/cardEntryIcons/people.svg"
import merchantIcon from '../../../../assets/cardEntryIcons/MerchantIcon.svg';
import OrderHistoric from "./OrderHistoric/OrderHistoric"
import AlertsHistoric from "./AlertsHistoric/AlertsHistoric"

function HistoricCard(props) {

    let alert_generator = {}

    switch(props.alert.link_key_type){
        case "customer":
            alert_generator.icon = peopleIcon
            alert_generator.type = "Comprador"
            break
        case "seller":
            alert_generator.icon = merchantIcon
            alert_generator.type = "Vendedor"
            break
        default:
            throw new Error()
    }

    return (
        <div className="analysisCard">
            <div style={{display: "flex"}}>
                <img className="cardTitleIconStyle" src={alert_generator.icon} alt="Icon Logo" />
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>{[alert_generator.type, "| Históricos"].join(" ")}</span>
            </div>
            <div className="internalCardContainer">
                <div className={["blueText", "normalSmallSize"].join(" ")}>
                    Histórico de Pedidos
                </div>
                <div>
                    <OrderHistoric alert={props.alert}/>
                </div>                
            </div>
            <div className="internalCardContainer">
                <div className={["blueText", "normalSmallSize"].join(" ")}>
                    Histórico de Alertas
                </div>
                <div>
                    <AlertsHistoric alert={props.alert}/>
                </div>                
            </div>
        </div>
    )
}

export default HistoricCard