import React from "react";
import { useTranslation } from "react-i18next";

function NumberDescription(props) {

    const { description, total } = props

    const { t } = useTranslation()

    return (
        <div>
            <div 
                className={["normalText","analysisCardSummaryLargeSize","analysisCardSummaryColor", "alignCenter"].join(" ")}
                style={{padding:"10px"}}
            >
                {total}
            </div>
            <div className={["normalText","normalMediumSize","analysisCardSummaryColor", "alignCenter"].join(" ")}>
                {t(description)}
            </div>
            {props.secondDescription&&
                <div className={["normalText","normalMediumSize","analysisCardSummaryColor", "alignCenter"].join(" ")}>
                    {t(props.secondDescription)}
                </div>}
        </div>
    )
}


export default NumberDescription