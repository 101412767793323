import React from 'react'
import DataField from "../../utils/DataField"
import { getDictKeyType, getDictType, getDictStatusColor, getDictStatus } from "../../utils/utilsDictOperation"
import moment from 'moment'

function DictCard(props) {


    return (
        <div className="analysisCard">
            <div className={["blueText", "subtitleSize"].join(" ")}>DICT</div>
            <div className="internalCardContainer" style={{ display: "flex", flexDirection: "column" }}>
                <DataField
                    title={"Chave:"}
                    label={getDictKeyType(props.dict_operation.dict_key.key_type) + " - " + props.dict_operation.dict_key.key_value}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                />
                <DataField
                    title={"Tipo do pedido:"}
                    label={getDictType(props.dict_operation.dict_operation_type)}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                />
                <DataField
                    title={"Situação:"}
                    label={getDictStatus(props.dict_operation.dict_operation_status)}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    labelClasses={["bold"]}
                    labelStyles={{ color: getDictStatusColor(props.dict_operation.dict_operation_status) }}
                />
                <DataField
                    title={"Data e Hora:"}
                    label={moment(props.dict_operation.dict_operation_creation_date).format("DD/MM/YYYY HH:mm:ss")}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                />
            </div>
        </div>
    )
}

export default DictCard