import React from 'react'
import TimeCounter from "./TimeCounter/TimeCounter"
import { getAnalysisStatus, getAnalysisStatusColor } from "../../utils/utils"

function TimerBox (props) {
    let {wireTransfer} = props
    if(wireTransfer.analysis_status === "pending" || wireTransfer.analysis_status === "in_manual_analysis"){
        return (
            <TimeCounter wire_transfer={wireTransfer}/>
        )
    }
    else{
        return(
            <div className="analysisCard" style={{height:"100%", borderWidth:"1px", borderStyle:"solid", borderColor:getAnalysisStatusColor(wireTransfer.analysis_status)}}>
                <div 
                    className={["normalText", "substitleSize", "bold"].join(" ")} 
                    style={{margin:"auto", color:getAnalysisStatusColor(wireTransfer.analysis_status)}}
                >
                    {getAnalysisStatus(wireTransfer.analysis_status)}                    
                </div>
            </div>
        )
    }
}

export default TimerBox