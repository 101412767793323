import React, { useContext } from 'react'
import peopleIcon from "../../../../../assets/cardEntryIcons/people.svg"
import AuthContext from "../../../../../context/auth-context"
import BankslipHistoric from '../../../../@GeneralComponents/BankslipHistoric'
import BillPaymentHistoric from "../../../../@GeneralComponents/BillPaymentHistoric"
import WithdrawalHistoric from "../../../../@GeneralComponents/WithdrawalHistoric"
import DictOperationHistoric from '../../../../@GeneralComponents/DictOperationHistoric'
import BankingAlertHistoric from '../../../../@GeneralComponents/BankingAlertHistoric'
import TransactionHistoric from '../../../../@GeneralComponents/TransactionHistoric'
import WireTransferHistoric from '../../../../@GeneralComponents/WireTransferHistoric'

function HistoricCard(props) {

    let {alert} = props

    const {showEventType} = props

    let roles = useContext(AuthContext).user_data.roles
    let alert_generator = {}

    switch(alert.link_key_type){
        case "client":
            alert_generator.type = "client"
            alert_generator.icon = peopleIcon
            alert_generator.name = "Cliente"
            break
        case "payer":
            alert_generator.type = "payer"
            alert_generator.icon = peopleIcon
            alert_generator.name = "Pagador"
            break
        case "recipient":
            alert_generator.type = "recipient"
            alert_generator.icon = peopleIcon
            alert_generator.name = "Recebedor"
            break
        default:
            throw new Error()
    }

    let alert_triggers_transactions = []
    for (let trigger_group of alert.triggers){
        const { source_evaluated_object_key} = trigger_group.trigger_group
        alert_triggers_transactions.push(source_evaluated_object_key)
    }

    let client_document_number= alert.link_key

    let maximum_trigger_occurrence_date = Math.max.apply(Math, alert.triggers.map(function(a) { return new Date(a.trigger_group.occurrence_date); }));
    
    maximum_trigger_occurrence_date = new Date(maximum_trigger_occurrence_date)
    maximum_trigger_occurrence_date = maximum_trigger_occurrence_date.toISOString();

    return (
        <div className="analysisCard">
            <div style={{display: "flex"}}>
                <img className="cardTitleIconStyle" src={alert_generator.icon} alt="peopleIcon Logo" />
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>{alert_generator.name} | Históricos</span>
            </div>
            <div className="internalCardContainer">
                {roles.includes("read_banking_alerts")?
                    <div>
                        <div className={["blueText", "normalSmallSize"].join(" ")}>
                            Histórico de Alertas
                        </div>
                        <BankingAlertHistoric
                            link_key={alert.link_key}
                            final_date={alert.occurrence_date}
                            alert_key={alert.alert_key}
                            showEventType={showEventType}

                        />
                    </div>:null}
                {roles.includes("read_bankslips")?
                <div>
                    <div className={["blueText", "normalSmallSize"].join(" ")}>
                        Histórico de Transações Boletos
                    </div>
                    <BankslipHistoric 
                        document_number={client_document_number}
                        final_date={maximum_trigger_occurrence_date}
                        alert_triggers_transactions={alert_triggers_transactions}
                    />
                </div>:null}
                {roles.includes("read_wire_transfers")?
                <div>
                    <div className={["blueText", "normalSmallSize"].join(" ")}>
                        Histórico de Transferência
                    </div>
                    <WireTransferHistoric 
                        document_number={client_document_number}
                        final_date={maximum_trigger_occurrence_date}
                        alert_triggers_transactions={alert_triggers_transactions}
                    />
                </div>:null}
                {roles.includes("read_bill_payments")&&alert_generator.type!=='recipient'?
                <div>
                    <div className={["blueText", "normalSmallSize"].join(" ")}>
                        Histórico de Pagamento de Contas
                    </div>
                    <BillPaymentHistoric 
                        document_number={client_document_number}
                        final_date={maximum_trigger_occurrence_date}
                        alert_triggers_transactions={alert_triggers_transactions}
                    />
                </div>:null}
                {roles.includes("read_withdrawals")&&alert_generator.type!=='recipient'&&alert_generator.type!=='payer'?
                <div>
                    <div className={["blueText", "normalSmallSize"].join(" ")}>
                        Histórico de Saques
                    </div>
                    <WithdrawalHistoric 
                        document_number={client_document_number}
                        final_date={maximum_trigger_occurrence_date}
                        alert_triggers_transactions={alert_triggers_transactions}
                    />
                </div>:null}
                {roles.includes("read_pix_dict_operations")?
                <div>
                    <div className={["blueText", "normalSmallSize"].join(" ")}>
                        Histórico de Mudanças na DICT
                    </div>
                    <DictOperationHistoric
                        document_number={client_document_number}
                        final_date={maximum_trigger_occurrence_date}
                        alert_triggers_transactions={alert_triggers_transactions}
                    />
                </div>:null}
                {roles.includes("read_pix_transactions")?
                <div>
                    <div className={["blueText", "normalSmallSize"].join(" ")}>
                        Histórico de Transações Pix
                    </div>
                    <TransactionHistoric
                        document_number={client_document_number}
                        final_date={maximum_trigger_occurrence_date}
                        alert_triggers_transactions={alert_triggers_transactions}
                    />
                </div>:null}
            </div>
        </div>
    )
}

export default HistoricCard