import React from 'react'
import {getIndicatorsFlagIcon,getIndicatorsFlagColor} from "../../utils/utils"

function Indicators(props) {

    return (
        <div className="analysisCard">
            <div className={["blueText", "subtitleSize"].join(" ")}>Indicadores</div>
            <div className="internalCardContainer">
                {props.indicators.map((indicator, index) => (
                    <div key={index} style={{display: "flex", margin: "10px 0px"}}>
                        <div style={{display:"flex", margin:"auto 0px"}}>
                            {getIndicatorsFlagIcon(indicator.flag)}
                        </div>
                        <div 
                            className={["normalText", "normalMediumSize", "labelSpace"].join(" ")} 
                            style={{
                                marginTop:"auto",
                                marginBottom:"auto", 
                                color: getIndicatorsFlagColor(indicator.flag)
                            }}
                        >
                            {indicator.description}
                        </div>                         
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Indicators;