import React, { useReducer, useState } from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import TextField from "@material-ui/core/TextField"
import { useHistory } from "react-router-dom"
import axios from "axios"
import { getAlertStatusDecision, getTerminalsList, getPayload } from "../../../utils/utilsAlert"
import { getAcquirer, formatCard, colorRed } from "../../../utils/utils"
import { CircularProgress } from '@material-ui/core'
import ConfirmationContent from "./ConfirmationContent"
import { decisionReducer } from "../../../../Utils/requestUtils"
import Checkbox from '@material-ui/core/Checkbox'
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"
import Autocomplete from '@material-ui/lab/Autocomplete'

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#0b1f82"
        },
    }
})

function LockDialod(props) {
    const history = useHistory()

    const [dialogState, dispatchDialogState] = useReducer(
        decisionReducer,
        { isLoading: false, isError: false, finished: false }
    )

    const [complianceReport, setComplianceReport] = useState(false)
    const [onlyTerminal, setOnlyTerminal] = useState(false)
    const [terminalValue, setTerminalValue] = useState(null)

    let terminalsList = getTerminalsList(props.alert.triggers)
    let payloadInfo = getPayload(props.alert)

    const handleApprove = () => {
        if (onlyTerminal && terminalValue == null) {
            return
        }
        if (onlyTerminal) {
            payloadInfo['terminal_id'] = terminalValue
            payloadInfo['lock_type'] = "terminal"
        }
        else {
            payloadInfo['lock_type'] = props.alert.link_key_type === "merchant" ? "merchant" : "card"
        }
        let payload = {
            ...payloadInfo,
            alert_status: getAlertStatusDecision(props.alert.irregularity_type, props.alert.lock_key, complianceReport, 'lock'),
            lock_action: "generate_lock",
            lock_source: "manually_alert"
        }
        if (props.data != null) {
            payload = { ...payload, observation: props.data }
        }
        dispatchDialogState({ type: "send_request_init" })
        setTimeout(function () {
            axios.put('/dash/card_issuance_backoffice/alert/' + props.alert.alert_key, payload).then(response => {
                dispatchDialogState({ type: "send_request_success" })
            }).catch(error => {
                if ((error.response || {}).status === 403) dispatchDialogState({ type: "send_request_failure_403" })
                else if ((error.response || {}).status === 409) dispatchDialogState({ type: "send_request_failure_409" })
                else dispatchDialogState({ type: "send_request_failure" })
            })
        }, 1000)

    }

    if (dialogState.finished && dialogState.isLoading) {
        return (
            <Dialog
                open={props.open}
                onClose={() => history.push("/card-issuance-alerts-inbox")}
                keepMounted={false}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle style={{ paddingBottom: "0px" }} className={["blueText", "normalMediumSize"].join(" ")}>Bloquear</DialogTitle>
                <DialogContent>
                    <div style={{ display: "flex", width: "310px", height: "217px" }}>
                        <CircularProgress style={{ margin: "auto" }} />
                    </div>
                </DialogContent>
            </Dialog>

        )
    }
    else if (dialogState.finished) {
        let confirmationData = {
            message: dialogState.message,
            success: !dialogState.isError
        }
        return (
            <Dialog
                open={props.open}
                onClose={() => history.push("/card-issuance-alerts-inbox")}
                keepMounted={false}
                disableBackdropClick
            >
                <DialogTitle style={{ paddingBottom: "0px" }} className={["blueText", "normalMediumSize"].join(" ")}>Confirmação de Revisão</DialogTitle>
                <DialogContent>
                    <ConfirmationContent onClose={() => history.push("/card-issuance-alerts-inbox")} data={confirmationData} />
                </DialogContent>
            </Dialog>
        )
    }
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            keepMounted={false}
        >
            <DialogTitle style={{ paddingBottom: "0px" }} className={["blueText", "normalMediumSize"].join(" ")}>Bloquear</DialogTitle>
            <DialogContent>
                <div>
                    <div>
                        <TextField
                            fullWidth
                            multiline
                            variant="outlined"
                            margin="dense"
                            rows="5"
                            defaultValue={props.data}
                            disabled={true}
                        />
                    </div>
                    {props.alert.irregularity_type === "fraud" ? null :
                        <div className='internalCardContainer'>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <ThemeProvider theme={theme}>
                                    <Checkbox
                                        onChange={e => setComplianceReport(e.target.checked)}
                                        checked={complianceReport}
                                        color="primary"
                                    />
                                </ThemeProvider>
                                <div>
                                    <p
                                        className={["normalText", "normalMediumSize"].join(" ")}
                                    >
                                        Reportado para o COAF
                                    </p>
                                </div>
                            </div>
                        </div>}
                    <div className="internalCardContainer">
                        {props.alert.link_key_type === "merchant" ?
                            <div
                                className={["normalText", "normalMediumSize"].join(" ")}
                            >
                                Você tem certeza que deseja Bloquear o seguinte Merchant:
                                <div style={{ display: "flex", margin: "5px 0px", justifyContent: "center" }}>
                                    <div className={["normalText", "normalMediumSize"].join(" ")}>
                                        ID:
                                    </div>
                                    <div className={["labelText", "normalMediumSize", "labelSpace"].join(" ")}>
                                        {props.alert.merchant_id}
                                    </div>
                                </div>
                                <div style={{ display: "flex", margin: "5px 0px", justifyContent: "center" }}>
                                    <div className={["normalText", "normalMediumSize"].join(" ")}>
                                        Adquirente:
                                    </div>
                                    <div className={["labelText", "normalMediumSize", "labelSpace"].join(" ")}>
                                        {getAcquirer(props.alert.acquirer_id)}
                                    </div>
                                </div>
                            </div> :
                            <div
                                className={["normalText", "normalMediumSize"].join(" ")}
                            >
                                Você tem certeza que deseja Bloquear o seguinte Usuário:
                                <div style={{ display: "flex", margin: "5px 0px", justifyContent: "center" }}>
                                    <div className={["normalText", "normalMediumSize"].join(" ")}>
                                        ID:
                                    </div>
                                    <div className={["labelText", "normalMediumSize", "labelSpace"].join(" ")}>
                                        {props.alert.cardholder_id}
                                    </div>
                                </div>
                                <div style={{ display: "flex", margin: "5px 0px", justifyContent: "center" }}>
                                    <div className={["normalText", "normalMediumSize"].join(" ")}>
                                        Cartão:
                                    </div>
                                    <div className={["labelText", "normalMediumSize", "labelSpace"].join(" ")}>
                                        {formatCard(props.alert.bin, props.alert.last4)}
                                    </div>
                                </div>
                            </div>}
                        {props.alert.link_key_type === "cardholder" ? null : terminalsList.length === 0 ? null :
                            <div className='internalCardContainer'>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <ThemeProvider theme={theme}>
                                        <Checkbox
                                            onChange={e => setOnlyTerminal(e.target.checked)}
                                            checked={onlyTerminal}
                                            color="primary"
                                        />
                                    </ThemeProvider>
                                    <div>
                                        <p
                                            className={["normalText", "normalMediumSize"].join(" ")}
                                        >
                                            Bloquear apenas um terminal
                                        </p>
                                    </div>
                                </div>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <ThemeProvider theme={theme}>
                                        <Autocomplete
                                            disabled={!onlyTerminal}
                                            id="combo-box-demo"
                                            value={terminalValue}
                                            onChange={(event, newValue) => {
                                                setTerminalValue(newValue)
                                            }}
                                            options={terminalsList}
                                            style={{ width: 200 }}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    autoFocus
                                                    label="Selecione o terminal"
                                                    color="primary"
                                                    variant="outlined"
                                                />}
                                        />
                                    </ThemeProvider>
                                </div>
                                <div className={["normalText", "normalSmallSize"].join(" ")} style={{ color: colorRed, textAlign: "center" }}>
                                    {onlyTerminal && terminalValue == null ? "Selecione um terminal na caixa acima" : null}
                                </div>
                            </div>}
                    </div>
                    <div className="internalCardContainer" style={{ display: "flex", justifyContent: "center" }}>
                        <div
                            className={["button", "approved", "normalText", "normalMediumSize"].join(" ")}
                            style={{ minWidth: "70px" }}
                            onClick={handleApprove}
                        >
                            SIM
                        </div>
                        <div
                            className={["button", "reproved", "normalText", "normalMediumSize"].join(" ")}
                            style={{ minWidth: "70px" }}
                            onClick={props.onClose}
                        >
                            NÃO
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default LockDialod