import React from "react";
import { useTranslation } from "react-i18next";

export function RuleEngineButton (props) {
    
    const { label, onClick } = props;
    const { t } = useTranslation()

    return (
        <button 
            className="normalSmallSize blueText ruleEngineButton whiteBlue"
            onClick={onClick}
        >
            {t(label)}
        </button>
    )
}