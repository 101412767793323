import styled from 'styled-components'

export const ConfigurationBoxContainer = styled.div`
    background-color: white;
    width: 320px;
    align-self: center;
    padding: 0;
    margin: 0;
    max-height: 75%;
    overflow-y: auto;
    border-radius: 4px;
    border: 1px solid #d6d6d6;
`;