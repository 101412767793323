import React, { useState, useEffect, useContext } from "react";
import TextField from "@material-ui/core/TextField";
import ConfirmationDialog from "./ConfirmationDialog/ConfirmationDialog";
import moment from 'moment';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import AuthContext from "../../../../context/auth-context"
import {setAllowChanges, replaceAll} from "../../utils/utils"

const theme = createMuiTheme({
    overrides: { MuiInputBase: { root:{
        fontFamily: "Open Sans",
        fontSize: "14px",
        lineHeight: "19px",

    }}},
    palette:{
        primary: {
            main:"#0b1f82"
        },
    }
  });

function Observation(props) {

  let roles = useContext(AuthContext).user_data.roles
  let allowChanges = setAllowChanges(props.transaction.analysis_status, roles, "update_pix_transactions")

  const [openDialog, setOpenDialog] = useState(false)
  const [isApproved, setIsApproved] = useState(null)

  let [observationBox, setObservationBox] = useState(null)

  let [disableChange, setDisableChange] = useState(false)
  let [difference, setDifference] = useState(null)

  //Calculate initial difference between NOW and limit_date
    useEffect(() => {
        if(allowChanges){
            const timer = setTimeout(() => {
                const now = moment()
                const limit = moment(props.transaction.manual_analysis_limit_date)
                const diff = limit.diff(now, 'seconds')
                setDifference(diff)
            },500)
            return () => {
                clearTimeout(timer)
            }
        }
    },[allowChanges, props.transaction.manual_analysis_limit_date])

    useEffect(() => {
        if (difference === null) {
        return      
        }
        else if (difference < 0){
        setDisableChange(true)
        }
        else {
        const timer = setTimeout(() => {
            setDisableChange(true)
        },difference*1000)
        return () => {
            clearTimeout(timer)
        }
        }
    },[difference])

    const handleApprovedOpenClick = () => {
        setIsApproved(true)
        setOpenDialog(true)
    };

    const handleReprovedOpenClick = () => {
        setIsApproved(false)
        setOpenDialog(true)
    };

    const handleDialogClose = () => {
        setOpenDialog(false)
        setTimeout(function (){setIsApproved(null)}, 300)
    };

    const handleObservationBoxChange = (event) => {
        if (event.target.value.length === 0){
            setObservationBox(null)
        }
        else{
            setObservationBox(event.target.value)
        }
    };

    let observation = null
    let analyst = null
    if (!allowChanges){
        let last_event = props.transaction.analysis_events == null || props.transaction.analysis_events.length ===0? null :
            props.transaction.analysis_events[props.transaction.analysis_events.length-1]
        if (last_event){
            observation = last_event.observation
            let string = last_event.analyst_name == null ? null : "Análise feita por %name, %email no dia %day às %time.\n"
            let mapObj = {
                "%name": last_event.analyst_name,
                "%email": last_event.analyst_email,
                "%day": moment(last_event.event_date).format("DD/MM/YYYY"),
                "%time": moment(last_event.event_date).format("HH:mm"),
            }
            analyst = string == null ? null :replaceAll(string, mapObj)
        }
    }

    return (
        <div className="analysisCard">
            <div className={["blueText", "subtitleSize"].join(" ")}>Observação</div>
            <ThemeProvider theme={theme}>
                <TextField
                    fullWidth
                    multiline
                    color="primary"
                    variant="outlined"
                    margin="dense"
                    rows="5"
                    disabled= {!(allowChanges && !disableChange)}
                    onChange={handleObservationBoxChange}
                    defaultValue={allowChanges ? disableChange ? 
                        "Aprovado automaticamente devido ao esgotamento do tempo" : null : 
                        observation == null ? (analyst) : (analyst + observation)
                    }
                />
            </ThemeProvider>
            {(allowChanges && !disableChange) ? 
            <div>
                <div style={{display:"flex", marginTop: "10px"}}>
                    <div 
                        className={["button", "approved", "normalText", "normalMediumSize"].join(" ")} 
                        style={{color:"#141414", marginLeft: "auto"}}
                        onClick={handleApprovedOpenClick}
                    >
                        APROVAR 
                    </div>     
                    <div 
                        className={["button", "reproved", "normalText", "normalMediumSize"].join(" ")} 
                        style={{color:"#141414", marginRight: "10px"}}
                        onClick={handleReprovedOpenClick}
                    >
                        REPROVAR 
                    </div>     
                </div>
                <ConfirmationDialog
                    open={openDialog}
                    isApproved={isApproved}
                    observation={observationBox}
                    onClose={handleDialogClose}
                    transaction={props.transaction}
                />
            </div> : null
            }
        </div>
    )        
}

export default Observation;