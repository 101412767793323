import React from 'react'

function SelectLockTypeContent (props){

    let { setLockType } = props

    return (
        <div style={{ display:"flex", justifyContent: "space-evenly", margin:"auto 0px"}}>
            <div
                className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")} 
                onClick={() => setLockType("card")} 
                style={{width:"30%"}}
            >
                Cartão
            </div>
            <div
                className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")} 
                onClick={() => setLockType("merchant")} 
                style={{width:"30%"}}
            >
                Merchant
            </div>
            <div
                className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")} 
                onClick={() => setLockType("terminal")} 
                style={{width:"30%"}}
            >
                Terminal
            </div>

        </div>

    )
}

export default SelectLockTypeContent