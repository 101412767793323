import React, { useState} from "react";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import moment from 'moment'
import DeleteListDialog from "./DeleteListDialog/DeleteListDialog";
import DeleteIcon from '@material-ui/icons/Delete';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import usSvg from "../../../../assets/us.svg";
import brSvg from "../../../../assets/br.svg";
import Tooltip from "@material-ui/core/Tooltip";


function ListCard(props){

    const {user_data} = props
    const [openDeleteListDialog, setOpenDeleteListDialog] = useState(false);

    return (
        <Card className="listCard">
            {user_data.business_group_key &&   
                <div 
                    className={["listCardItem", "normalText", "normalMediumSize", "bold"].join(" ")} 
                    style={{flex:"1 1 45px", textAlign:"center", cursor: "default"}}  
                >
                    {props.list.company_name ? props.list.company_name : "-"}
                </div>}
            <Link
                className={["listCardItem", "link", "subtitleSize", "breakWord", "bold"].join(" ")}
                style={{flex:"1 1 110px", textAlign:"center"}}
                to={"/list/" + props.list.list_key}
            >
                {props.list.name}
            </Link>
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")} 
                style={{flex:"1 1 130px", textAlign:"center"}}  
            >
                {props.list.description == null ? "-" : props.list.description}
            </div>
            <div 
                className={["listCardItem", "normalText", "subtitleSize","noWrap"].join(" ")}
                style={{flex:"1 1 110px", textAlign:"center"}}
            >
                {moment(props.list.created_at).format("DD/MM/YYYY HH:mm:ss")}
            </div>
            {user_data.roles.includes("multiple_environment_viewer") ?
                ((props.list.region === "sa_east_1") ? 
                <div 
                className={["listCardItem", "normalText", "subtitleSize","noWrap"].join(" ")}
                style={{flex:"1 1 110px", textAlign:"center"}}
                >
                    <Tooltip title={
                        <p className="tooltipText" style={{textAlign: "center"}}>
                        Hospedado em ambiente Brasil
                        </p>}>
                        <img src={brSvg} alt="Brazil Flag" width="30" height="30"/>
                    </Tooltip>
                </div>    : 
                <div 
                className={["listCardItem", "normalText", "subtitleSize","noWrap"].join(" ")}
                style={{flex:"1 1 110px", textAlign:"center"}}
                >
                    <Tooltip title={
                        <p className="tooltipText" style={{textAlign: "center"}}>
                        Hospedado em ambiente Estados Unidos 
                        </p>}>
                        <img src={usSvg} alt="Brazil Flag" width="30" height="30"/>
                    </Tooltip>
                </div> )
            : null}
            {!props.list.is_owner?
                <div 
                    className={["listCardItem", "blueText", "subtitleSize", "breakWord", "bold"].join(" ")}
                    style={{flex:"1 1 110px", textAlign:"center"}} 
                    >
                        <div style={{marginRight:"5px"}}>
                            <PeopleAltIcon/>
                        </div>
                        Lista Compartilhada
                </div>
                :
                user_data.roles.includes("lists_analyzer")?
                <div 
                    className={["listCardItem", "redText", "subtitleSize", "breakWord", "bold"].join(" ")}
                    style={{flex:"1 1 110px", textAlign:"center", cursor: "pointer"}} 
                    onClick={() => setOpenDeleteListDialog(true)} 
                    >
                        <DeleteIcon/>
                        Excluir Lista
                </div>
                :
                <div style={{flex:"1 1 110px"}}> </div>}

            <DeleteListDialog
                open={openDeleteListDialog}
                user_data={props.user_data}
                handleUpdatePage={props.handleUpdatePage}
                local_storage_object_preffix={props.local_storage_object_preffix} 
                setPageParams={props.setPageParams}       
                list={props.list}     
            />
        </Card>
    )
}

export default ListCard