import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {getTableRowClass, getAgencyInfo }  from "../../../PIX/utils/utils";
import { Link } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import moment from 'moment'
import { getDictType } from "../../../PIX/utils/utilsDictOperation";
import {matchedFieldTranslation} from '../../utils/utils'

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
  },
  blueText: {
    fontFamily: "Open Sans",
    fontWeight: "600",
    color: "#0b1f82"
},
redText: {
    fontFamily: "Open Sans",
    fontWeight: "600",
    color: "#D91A1A"
},
  tableText: {
    fontFamily: "Open Sans",
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "center",
    margin: "10px",
    padding: "6px",
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: '#6F6F6F',
    fontSize: "14px",
    lineHeight: "19px",
    padding: "6px",
  }
}));

const getMatchedFields = (transactions) => {
    let matchedFieldsArray = []
    for(let transaction of transactions){
        matchedFieldsArray.push(Object.keys(transaction['highlight']))
    }
    for(let matchedField of matchedFieldsArray){
        if(matchedField.indexOf('company_key')>-1){
                let ckIndex = matchedField.indexOf('company_key')
                matchedField.splice(ckIndex,1)
        }
    }
    return matchedFieldsArray 
}

const getAgencies=(dict_operation_account_object)=>{
    let [institution_name] = getAgencyInfo(dict_operation_account_object)
    if(institution_name){
        return institution_name
    }else{
        return dict_operation_account_object.participant
    }
}

function DictOperationSearchTable(props) {

    const matchedFieldsArray = getMatchedFields(props.historic)
    
    const classes = useStyles();
    useTheme();

    return (
        <Paper className={classes.tableStyle}>
        <Table style={{ padding: "8px"}}>
            <TableHead>
            <TableRow>
                <TableCell className={classes.tableHeader} style={{ width: "5%" }}>ID</TableCell>
                <TableCell className={classes.tableHeader} style={{ width: "7%" }}>Tipo de Pedido</TableCell>
                <TableCell className={classes.tableHeader} style={{ width: "8%" }}>Chave</TableCell>
                <TableCell className={classes.tableHeader} style={{ width: "15%" }}>Nome Origem</TableCell>
                <TableCell className={classes.tableHeader} style={{ width: "8%" }}>Doc Origem</TableCell>
                <TableCell className={classes.tableHeader} style={{ width: "15%" }}>Nome Destino</TableCell>
                <TableCell className={classes.tableHeader} style={{ width: "8%" }}>Doc Destino</TableCell>
                <TableCell className={classes.tableHeader} style={{ width: "7%" }}>Instituição Origem</TableCell>
                <TableCell className={classes.tableHeader} style={{ width: "7%" }}>Instituição Destino</TableCell>
                <TableCell className={classes.tableHeader} style={{ width: "10%" }}>Data do Pedido</TableCell>
                <TableCell className={classes.tableHeader} style={{ width: "10%" }}>Campo encontrado</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {props.historic.map((dict_operation,index) => (
                <TableRow
                key={index}
                className={getTableRowClass(index)}
                > 
                <TableCell
                    className={[classes.tableText, classes.blueText, "link", "idOverflow"].join(" ")}
                >
                    <Link
                        className="link"
                        to={"/pix-dict/" + dict_operation.dict_operation_key}
                    >
                        {dict_operation.id}
                    </Link>
                </TableCell>
                <TableCell className={classes.tableText}>
                    <div className={
                        matchedFieldsArray[index].includes("dict_operation_type")
                        ? "blueTextSearchAny"
                        : null}
                    >
                        {getDictType(dict_operation.dict_operation_type)}
                    </div>
                </TableCell>
                <TableCell className={classes.tableText} >
                    {dict_operation.dict_key.key_value?
                        <Tooltip title={<p className={["tooltipText","centerText"].join(" ")}>{dict_operation.dict_key.key_value}</p>}>
                            <div className={
                                matchedFieldsArray[index].includes("dict_key.key_value")
                                ? ["textOverflow2","centerText","labelSpace","blueTextSearchAny"].join(" ")
                                : ["textOverflow2","centerText","labelSpace"].join(" ")}
                            >
                                {dict_operation.dict_key.key_value}
                            </div>
                        </Tooltip>
                        :
                        <div className={null}>{" - "}</div>}
                </TableCell>
                <TableCell className={classes.tableText} >
                    {dict_operation.source_account
                        ? <div className={
                            matchedFieldsArray[index].includes("source_account.owner.name")
                            ? "blueTextSearchAny"
                            : null}
                            >
                                {dict_operation.source_account.owner.name}
                            </div>
                        : " - "}
                </TableCell>
                <TableCell className={classes.tableText}>
                    {dict_operation.source_account
                        ? <div className={
                            matchedFieldsArray[index].includes("source_account.owner.document_number")
                            ? "blueTextSearchAny"
                            : null}
                            >
                                {dict_operation.source_account.owner.document_number}
                            </div>
                        : " - "}
                </TableCell>
                <TableCell className={classes.tableText} >
                    <div className={
                        matchedFieldsArray[index].includes("destination_account.owner.name")
                        ? "blueTextSearchAny"
                        : null}
                        >
                        {dict_operation.destination_account.owner.name}
                    </div>
                </TableCell>
                <TableCell className={classes.tableText} >
                    <div className={
                        matchedFieldsArray[index].includes("destination_account.owner.document_number")
                        ? "blueTextSearchAny"
                        : null}
                        >
                        {dict_operation.destination_account.owner.document_number}
                    </div>
                </TableCell>
                <TableCell className={[classes.tableText].join(" ")}>
                    {dict_operation.source_account 
                        ? <Tooltip title={<p className={["tooltipText","centerText"].join(" ")}>{getAgencies(dict_operation.source_account)}</p>}>
                            <div className={
                                matchedFieldsArray[index].includes("dict_operation.source_account.participant")
                                ? ["textOverflow2","centerText","labelSpace","blueTextSearchAny"].join(" ")
                                : ["textOverflow2","centerText","labelSpace"].join(" ")}
                            >
                                {getAgencies(dict_operation.source_account)}
                            </div>
                        </Tooltip>
                        : " - "}
                </TableCell>
                <TableCell className={[classes.tableText].join(" ")}>
                    {dict_operation.destination_account 
                        ? <Tooltip title={<p className={["tooltipText","centerText"].join(" ")}>{getAgencies(dict_operation.destination_account)}</p>}>
                            <div className={
                                matchedFieldsArray[index].includes("dict_operation.destination_account.participant")
                                ? ["textOverflow2","centerText","labelSpace","blueTextSearchAny"].join(" ")
                                : ["textOverflow2","centerText","labelSpace"].join(" ")}
                            >
                                {getAgencies(dict_operation.destination_account)}
                            </div>
                        </Tooltip>
                        : " - "}
                </TableCell>
                <TableCell className={classes.tableText}>
                    {moment(dict_operation.dict_operation_creation_date).format("DD/MM/YYYY HH:mm")}
                </TableCell>
                <TableCell className={classes.tableText}>
                    {matchedFieldsArray[index].map(matchedField => matchedFieldTranslation(matchedField)).join(", ")}
                </TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        </Paper>
    );
}

export default DictOperationSearchTable;
