import React from 'react';
import TimeLineCard from "./TimeLineCard/TimeLineCard"
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import {getTimeLineColor,getTimeLineIcon} from "../../utils/utils"
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
  overrides: { 
    MuiTimelineItem: { 
      missingOppositeContent:{ "&:before": {
        padding:"0px",
        flex: 0
      }}
    },
    MuiTimelineConnector:{
      root:{
        minHeight:"80px",
        marginTop: "10px",
        marginBottom: "10px"
      }
    },
    MuiTimeline: { 
        root:{
            padding: "0px 16px"
        }
    },
    MuiTimelineContent: { 
        root:{
            padding: "0px 0px 0px 16px",
            marginBottom: "10px"
        }
    },
    MuiTimelineDot: { 
        root:{
            marginTop: "0px",
            marginBottom: "0px"
        }
    },
    MuiSvgIcon: { 
        root:{
            width:null,
            height:null
        }
    },
  }
});

export default function EventsTimeline(props) {

  let {transaction_events} = props.transaction
  if (transaction_events == null) return null
  else if(transaction_events.length === 0) return null
  else {
    let eventsSize  = transaction_events.length
    return (
      <ThemeProvider theme={theme}>
        <Timeline>
          {transaction_events.map((event,index) => (
            <TimelineItem key={index}>
            <TimelineSeparator>
              <TimelineDot style={{backgroundColor: getTimeLineColor(event.new_status), width:"42px", height:"42px"}}>
                {getTimeLineIcon(event.new_status)}
              </TimelineDot>
              {index === (eventsSize-1) ? null : <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent><TimeLineCard position={index} event={event}/></TimelineContent>
          </TimelineItem>
          ))}
        </Timeline>
      </ThemeProvider>
    );
  }
}