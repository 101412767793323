import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import DescriptionIcon from "@material-ui/icons/Description";
import OCRDialog from "../../utils/Components/OCRDialog/OCRDialog";
import { IconButton } from "@material-ui/core";

function BankStatement (props) {

    const { bankStatementPictureOpen, handleBankStatementPictureOpen, handleBankStatementPictureClose, sourceImageList } = props

    const { t } = useTranslation()

    return (
        <div className="analysisCard">
            <div style={{ display: "flex", alignItems:"center"}}>
                <div className={["blueText", "subtitleSize"].join(" ")} style={{margin:"5px 0px"}}>{t("bank_statement")}</div>
                <IconButton
                    onClick={handleBankStatementPictureOpen}
                    style={{margin:"auto auto auto 10px"}}
                >
                    <DescriptionIcon
                        style={{
                        verticalAlign: "middle",
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                        color: "#777"
                        }}
                    />
                </IconButton>
                <Fragment>
                <OCRDialog
                    name={"bank_statement"}
                    open={bankStatementPictureOpen}
                    onClose={handleBankStatementPictureClose}
                    sourceImageList={sourceImageList}
                />
                </Fragment>
            </div>
        </div>
    )
}

export default BankStatement;