import React, {useState, useEffect, useContext} from 'react'
import { useTranslation } from "react-i18next";
import {GetAlertsComponent, setObjectByPath} from "./utils"
import TextField from "@material-ui/core/TextField";
import MenuItem from '@material-ui/core/MenuItem';
import _ from "lodash"
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import RulesContext from "../../../../context/rules-context"
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
//BÁSICAS - Verificar Bloqueios 

export function VerifyLockBox (props) {
    const { t } = useTranslation();
    let {node} = props
    let {read_only} = props
    let {handleConfigurationOpen} = props
    let {handleNodeRemove} = props

    let lockTypes = useContext(RulesContext).boxes[node.node.type].settings.lock_types
    let lockType = lockTypes.filter(option => option.enum === node.node.properties.lock_type)[0]

    return (
        <div>
            {!read_only ?
                <div style={{display:"flex", alignItems: "center"}}>
                    <div 
                        style={{display:"flex", width:"100%", cursor:"pointer", margin: "0px"}} 
                        onClick={() => handleConfigurationOpen(node)}
                    >
                        <div style={{display: "flex",flexDirection: "column",margin: "auto 0px"}}>
                            <div className={["normalText","normalMediumSize"].join(" ")}>
                            {t("Existe Bloqueio")}
                            </div>
                            <div className={["normalText","normalSmallSize"].join(" ")}>
                            {t((lockType || {description:""}).description)}
                            </div>
                        </div>
                        {(node.node.alerts || []).length > 0?
                        <GetAlertsComponent alerts={node.node.alerts} />  : null}       
                    </div>
                    <IconButton style={{width: 32, height: 32}} aria-label="duplicate" onClick={() => props.handleNodeDuplication(node)}>
                        <ContentCopyIcon style={{width: 20, height: 20}} />
                    </IconButton>
                    <IconButton style={{width: 32, height: 32}} aria-label="close" onClick={() => handleNodeRemove(node)}>
                        <CloseIcon style={{width: 20, height: 20}} />
                    </IconButton>
                </div>
                :
                <div style={{display:"flex"}}>
                    <div 
                        style={{display:"flex", width:"100%", cursor:"pointer", margin: "0px"}} 
                        onClick={() => handleConfigurationOpen(node)}
                    >
                        <div style={{display: "flex",flexDirection: "column",margin: "auto 0px"}}>
                            <div className={["normalText","normalMediumSize"].join(" ")}>
                            {t("Existe Bloqueio")}
                            </div>
                            <div className={["normalText","normalSmallSize"].join(" ")}>
                            {t((lockType || {description:""}).description)}
                            </div>
                        </div>  
                    </div>
                </div>
            }
        </div>
    )
}

export function VerifyLockConfiguration(props){
    const { t } = useTranslation();
    let {node} = props
    let {read_only} = props
    let {handleNodeChange} = props
    let {handleConfigurationClose} = props

    let lockTypes = useContext(RulesContext).boxes[node.node.type].settings.lock_types

    const [nodeState, setNodeState] = useState(node)
    
    useEffect(()=>{
        setNodeState(node)
    },[node])

    const handleNodeStateChange = (obj_key,value) => {
        let new_node = _.cloneDeep(nodeState)
        setObjectByPath(new_node.node,obj_key,value)
        setNodeState(new_node)
    }

    const handleSave = () => {
        handleConfigurationClose()
        handleNodeChange(nodeState)
    }

    const validateAllValues = () => {
        return (nodeState.node.properties.lock_type && nodeState.node.properties.lock_type.length >0)
    }


    return(
        <div style={{flexGrow:"1", padding: "20px", display:"flex", flexDirection:"column"}}>
            <div style={{display: "flex"}}>
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>{t(node.node.description)}</span>
                <Tooltip title={<div className="tooltipText"><ul>{node.node.helperText.map((help,index) => (<li key={index}>{t(help)}</li>))}</ul></div>}>

                    <HelpIcon style={{fontSize: "14px", display:"flex", margin:"auto 5px"}}/>
                </Tooltip>
            </div>
            <div className="internalCardContainer" style={{display:"flex", flexDirection: "column"}}>
            <TextField 
                label={t("Decisão")}
                style={{textAlign:"center", width:"80%", margin:"3px auto"}}
                className="filterTextbox" 
                size="small" fullWidth 
                value={nodeState.node.properties.lock_type}
                onChange={(e) => handleNodeStateChange("properties.lock_type", e.target.value)}
                select
                InputProps={{
                    readOnly: read_only
                  }}
            >
                {lockTypes.map((option, index) => (
                    <MenuItem key={index} value={option.enum}><em className="normalText">{t(option.description)}</em></MenuItem>
                ))}
            </TextField>          
            </div>
            <div style={{display:"flex", margin: "auto auto 5px auto"}}>
                <div
                    className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")}
                    style={{width:"120px"}}
                    onClick={handleConfigurationClose}
                    >
                    {read_only ? "X" : t("discard")}
                </div>
                {!read_only && 
                <div
                    className={
                        validateAllValues()
                        ?
                        ["button", "standard", "normalText", "normalMediumSize"].join(" ")
                        :
                        ["button", "standard", "normalText", "normalMediumSize","disabled"].join(" ")}
                    style={{width:"120px"}}
                    onClick={validateAllValues() ? handleSave : null}
                >
                    {t("save")}
                </div>}   
            </div>
        </div>
    )
}

export class VerifyLockBoxClass {
    constructor(rulesContext){
        this.rulesContext = rulesContext
    }

    validate(node){
        let put_error = false
        let alerts = []

        let lockTypes = this.rulesContext.boxes[node.node.type].settings.lock_types

        let values_list = lockTypes.reduce((acc, option) => {
            acc.push(option.enum)
            return acc
        },[])

        //Validate if node has no children
        if((node.node.children || []).length !== 0){
            put_error = true
            alerts.push("O nó de Decisão não pode ter filhos")
        }
        //Validate if a decision was choosen
        if (!values_list.includes(node.node.properties.lock_type)){
            put_error = true
            alerts.push("Clique no nó desta Decisão, abra a configuração e escolha uma decisão válida")
        }
        //Validate if parent is valid
        if (node.parentNode != null) {
            if(node.parentNode.on_error){
                put_error = true
                alerts.push("O nó superior está inválido")
            }
        }
        return {validated: !put_error, alerts: alerts}
    }

    getType(node){
        return "bool"
    }
}