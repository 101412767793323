import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import axios from 'axios'

const theme = createMuiTheme({
    palette:{
        primary: {
            main:"#0b1f82"
        },
        secondary: {
            main: "#FF0000"
        }
    }
});


const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

function RenameRuleDialogs(props) {
    const { t } = useTranslation();

    let {onClose, handleRequestFinish,rule} = props

    const [ruleName, setRuleName] = useState(rule.name)
    const [isLoading, setIsLoading] = useState(false) 

    const validateAllValues = () => {
        return ruleName.length > 0 
    }

    const handleSaveRule = () => {
        setIsLoading(true)
        let payload={
            name:ruleName,
            description: t("Alteração do Nome da Regra")
        }
        let url = '/dash/rule_engine/rule/' + rule.rule_key + "?environment=" + rule.environment
        setTimeout(function () {
            axios.put(url, payload).then(response => {
                    handleRequestFinish("success", "Regra editada com sucesso")
                }).catch(error => {
                    handleRequestFinish("error", "Não foi possível editar essa regra")
                });
        }, 500);    

    }

    const handleClose = () => {
        onClose()
        setTimeout(function () {
            setRuleName(rule.name)
            setIsLoading(false)
        }, 500);    
    }

    
    return (
        <Dialog 
            open={props.open} 
            onClose={handleClose}
            disableBackdropClick
            disableEscapeKeyDown
            keepMounted={false}
        >
            <DialogTitle 
                className={['blueText', "subtitleSize"].join(" ")} 
                onClose={handleClose}
            >
                {t("Renomear Regra")}
            </DialogTitle>
            <DialogContent >
                <ThemeProvider theme={theme}>
                <div style={{padding:"20px", display:"flex", flexDirection:"column", marginBottom:"30px", width:"320px", "height" :"260px"}}>
                    {isLoading 
                    ?
                    <CircularProgress style={{margin:"auto"}}/>
                    :
                    <React.Fragment>
                    <div className="internalCardContainer">
                        <TextField
                            error={!ruleName.length > 0}
                            label={t("Nome da Regra")}
                            style={{textAlign:"center"}} 
                            className="filterTextbox" 
                            size="small" fullWidth 
                            value={ruleName}
                            onChange={(e) => setRuleName(e.target.value)}
                            autoFocus
                        />  
                    </div>
                    <div className="internalCardContainer" style={{display:"flex", marginTop: "10px"}}>
                        <div
                            className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")}
                            style={{width:"120px"}}
                            onClick={handleClose}
                        >
                           {t("discard")}
                        </div>
                        <div
                            className={
                                validateAllValues()
                                ?
                                ["button", "standard", "normalText", "normalMediumSize"].join(" ")
                                :
                                ["button", "standard", "normalText", "normalMediumSize","disabled"].join(" ")}
                            style={{width:"120px"}}
                            onClick={validateAllValues() ? () => handleSaveRule() : null}
                        >
                            {t("save")}
                        </div>   
                    </div>
                    </React.Fragment>
                    }
                </div>
                </ThemeProvider>  
            </DialogContent>
        </Dialog>
    )
}

export default RenameRuleDialogs