import React, { useState, useReducer, useEffect, useCallback, useContext } from "react";
import { CircularProgress, Dialog } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { LabelInput } from "../../../@GeneralComponents/LabelInput";
import FilterOption from "../../../@GeneralComponents/FilterOption";
import { dataFetchReducer } from "../../../Utils/requestUtils";
import { getTypeComponents } from "../BoxTypeMapper";
import {
    datetimeMask,
    isGeoPoint,
    isGeoShape,
    timeMask,
    isDatetime,
    isDate,
    isTime,
    getDatetimeFormatted,
    justFloat,
    stringToFloat
} from "../../utils";
import { GeoPointInput } from "../../../@GeneralComponents/GeoPointInput";
import { GeoShapeInput } from "../../../@GeneralComponents/GeoShapeInput";
import { TimedeltaInput } from "../../../@GeneralComponents/TimedeltaInput";
import DataField from "../../../@GeneralComponents/DataField";
import Button from "../../../@GeneralComponents/Button";
import LabIcon from "../../../../assets/rulesIcon/lab.svg";
import AuthContext from "../../../../context/auth-context";

export function SimulateRuleDialog(props) {
    let user_data = useContext(AuthContext).user_data
    let businessGroupKey = (user_data||{}).business_group_key

    const { open, onCloseDialog, ruleKey, companyKey } = props
    const { t } = useTranslation()

    const [fieldsValues, setFieldsValues] = useState(null)
    const [errors, setErrors] = useState({});
    const [requiredFields, dispatchRequiredFields] = useReducer(dataFetchReducer, {
        fetchedData: null,
        isLoading: true,
        isError: false,
    });

    const [canUseMultiEnvironment, setCanUseMultiEnvironment] = useState(false);
    const [selectedEnvironment, setSelectedEnvironment] = useState(null);

    useEffect(() => {
        setCanUseMultiEnvironment(user_data.roles.includes("use_sa_rule_engine"))
    }, [user_data.roles])

    const [simulateState, dispatchSimulateState] = useReducer(dataFetchReducer, {
        fetchedData: null,
        isLoading: false,
        isError: false,
    });

    const removeDuplicatedUnderscore = useCallback((string) => { 
        return string.replaceAll("____", "_").replaceAll("___", "_").replaceAll("__", "_")
    },[])

    const removeUnderscoreFromStartEnd = useCallback((string) => {
        let removedString = string
        if (string[0] === "_") {
            removedString = string.substring(1);
        }
        if (string.charAt(string.length - 1) === '_') {
            removedString = removedString.slice(0, -1);
        }
        return removedString
    },[])

    const formatJsonString = useCallback((string)=>{
        let removedJsonMarks = string.replace(/[^\w\s]/gi, '_')
        let cleanedString = removeUnderscoreFromStartEnd(removeDuplicatedUnderscore(removedJsonMarks))
        return cleanedString
    },[removeUnderscoreFromStartEnd,removeDuplicatedUnderscore])

    const getFieldNameIdType = useCallback((field) => {
        const boxType = field.box_type
        const variableType = field.variable_type
        const propertiesString = formatJsonString(JSON.stringify(field.properties))
        let variableTypeDescription = `(${t(variableType)})`
        if (["datetime","time"].includes(variableType)) {
            variableTypeDescription = `(${t(variableType)} - ${t("América/São Paulo")})`
        }
        return {
            fieldId: `${boxType}_${variableType}_${propertiesString}`,
            fieldName: `${t(boxType)} ${variableTypeDescription}`,
            fieldType: variableType
        } 
    }, [formatJsonString,t])

    useEffect(()=>{
        if (requiredFields.fetchedData&&!fieldsValues) {
            let initialFieldsValues = {}
            requiredFields.fetchedData.forEach((field)=>{
                const { fieldId, fieldType } = getFieldNameIdType(field)
                if (fieldType==="bool") {
                    initialFieldsValues[fieldId] = "false"
                } else if (fieldType==="number") {
                    initialFieldsValues[fieldId] = 0
                } else if (fieldType==="geo_shape") {
                    initialFieldsValues[fieldId] = [[0,0]]
                } else if (fieldType==="geo_point") {
                    initialFieldsValues[fieldId] = [0,0]
                } else if (fieldType==="timedelta") {
                    initialFieldsValues[fieldId] = {
                        years: 0,
                        months: 0,
                        days: 0,
                        hours: 0,
                        minutes: 0,
                        seconds: 0
                    }
                } else if (["string","datetime","time"].includes(fieldType)) {
                    initialFieldsValues[fieldId] = ""
                }
            })
            setFieldsValues(initialFieldsValues)
        }
    },[requiredFields.fetchedData, fieldsValues, getFieldNameIdType])

    const errorMessages = {
        string: "string_error_message",
        number: "number_error_message",
        bool: "bool_error_message",
        datetime: "datetime_error_message",
        time: "time_error_message",
        timedelta: "timedelta_error_message",
        geo_point: "geo_point_error_message",
        geo_shape: "geo_shape_error_message"
    }

    const placeholders = {
        string: "",
        number: "",
        bool: "",
        datetime: t("DD/MM/AAAA ou DD/MM/AAAA hh:mm:ss"),
        time: "hh:mm:ss",
        timedelta: {
            "years": "YYYY",
            "months": "MM",
            "days": "DD",
            "hours": "hh",
            "minutes": "mm",
            "seconds": "ss"
        },
        geo_point: "",
        geo_shape: ""
    }
    const getEnvByRuleKey = ((rule_key, requestHeaders)=>{
        const timer = setTimeout(() => {
          axios
            .get("/dash/rule_engine/rule/" + rule_key + "/environment", requestHeaders)
            .then((response) => {
                setSelectedEnvironment(response.data.environment);
                return response.data.environment;
            })
          }, 500);
        return () => {
          clearTimeout(timer);
        };
      })
      
    useEffect(() => {
        dispatchRequiredFields({ type: "data_fetch_init" });
        const timer = setTimeout(() => {
            let requestHeaders = {}
            if (businessGroupKey) {
                requestHeaders = {headers:{"COMPANY-KEY":companyKey}}
            }
            if (canUseMultiEnvironment) {
                getEnvByRuleKey(ruleKey, requestHeaders)
            }
            if (canUseMultiEnvironment && selectedEnvironment === null) return;
            axios
                .get(`/dash/rule_engine/rule/${ruleKey}/simulate?environment=${selectedEnvironment ?? "production"}`, requestHeaders)
                .then((response) => {
                    dispatchRequiredFields({
                        type: "data_fetch_success",
                        payload: response.data,
                    });
                })
                .catch((error) => {
                    if ((error.response || {}).status === 403)
                        dispatchRequiredFields({ type: "data_fetch_failure_403" });
                    else if ((error.response || {}).status === 404)
                        dispatchRequiredFields({ type: "data_fetch_failure_404" });
                    else dispatchRequiredFields({ type: "data_fetch_failure" });
                });
        }, 500);
        return () => {
            clearTimeout(timer);
        };
    }, [ruleKey, businessGroupKey, companyKey, canUseMultiEnvironment, selectedEnvironment]);
    
    const handleValueChange = (id, value) => {
        setFieldsValues({
            ...fieldsValues,
            [id]: value
        })
        setErrors({
            ...errors,
            [id]: ""
        });
    }

    const validateInput = {
        variable_name: () => true,
        string: () => true,
        number: () => true,
        bool: (value) => ["true","false"].includes(value),
        datetime: (value) => isDatetime(value)||isDate(value),
        time: (value) => isTime(value),
        timedelta: () => true,
        geo_point: (value) => isGeoPoint(value),
        geo_shape: (value) => isGeoShape(value)
    }

    const handleBlur = (type, id, value) => {
        const error = !validateInput[type](value);
        setErrors({
            ...errors,
            [id]: error
        });
    };

    function formatInputName (fieldType, value) {
        switch (fieldType) {
            case ("number"):
                return justFloat(value)
            case ("datetime"):
                return datetimeMask(value)
            case ("time"):
                return timeMask(value)
            default:
                return value
        }
    }

    const isFormValid = useCallback(() =>{
        let isFormValid = true
        requiredFields.fetchedData.forEach((field,index) => {
            const { fieldId, fieldType } = getFieldNameIdType(field)
            const value = fieldsValues[fieldId]
            const isInputValid = validateInput[fieldType](value)
            if (!isInputValid) {
                isFormValid = false
            }
        })
        return isFormValid
    },[requiredFields.fetchedData,fieldsValues,getFieldNameIdType,validateInput])


    const formatSimulateInputs = useCallback((requiredFields) => {
        let simulate_inputs = []
        requiredFields.fetchedData.forEach((field,index)=>{
            const { fieldId, fieldType } = getFieldNameIdType(field)
            let value = ""
            if (fieldType==="timedelta") {
                const timedelta = fieldsValues[fieldId]
                value = "P"
                if (timedelta.years) {value += timedelta.years + "Y"}
                if (timedelta.months) {value += timedelta.months + "M"}
                if (timedelta.days) {value += timedelta.days + "D"}
                if (timedelta.hours) {value += timedelta.hours + "H"}
                if (timedelta.minutes) {value += timedelta.minutes + "M"}
                if (timedelta.seconds) {value += timedelta.seconds + "S"}
                if (value==="P") {value = "P0S"}
            } else if (fieldType==="datetime"){
                value = getDatetimeFormatted(fieldsValues[fieldId])
            } else if (fieldType==="number"){
                value = stringToFloat(fieldsValues[fieldId])
            } else if (fieldType==="bool"){
                if (fieldsValues[fieldId]==="true") {
                    value = true
                } else {
                    value = false
                }
            } else if (fieldType==="geo_point"){
                const geoPoint = fieldsValues[fieldId]
                value = [stringToFloat(geoPoint[0]),stringToFloat(geoPoint[1])]
            } else if (fieldType==="geo_shape"){
                const geoShape = fieldsValues[fieldId]
                let formattedGeoShape = []
                geoShape.forEach((geoPoint)=>{
                    let newGeoPoint = [stringToFloat(geoPoint[0]),stringToFloat(geoPoint[1])]
                    formattedGeoShape.push(newGeoPoint)
                })
                value = formattedGeoShape
            } else {
                value = fieldsValues[fieldId]
            }
            const fieldInput = {
                "box_type": field.box_type,
                "properties": field.properties,
                "value": value
            }
            simulate_inputs.push(fieldInput)
        })
        return simulate_inputs
    },[fieldsValues, getFieldNameIdType])

    const simulateRule = useCallback(()=>{
        const timer = setTimeout(() => {
            dispatchSimulateState({ type: "data_fetch_init" });
            let requestHeaders = {}
            if (businessGroupKey) {
                requestHeaders = {headers:{"COMPANY-KEY":companyKey}}
            }
            const payload = {
                "simulate_inputs": formatSimulateInputs(requiredFields)
            }
            if (canUseMultiEnvironment) {
                getEnvByRuleKey(ruleKey, requestHeaders)
            }
            if (canUseMultiEnvironment && selectedEnvironment === null) return;
            axios
            .post(`/dash/rule_engine/rule/${ruleKey}/simulate?environment=${selectedEnvironment ?? "production"}`, payload, requestHeaders)
            .then((response) => {
                dispatchSimulateState({
                    type: "data_fetch_success",
                    payload: response.data
                });
            })
            .catch((error) => {
                if ((error.response || {}).status === 403)
                dispatchSimulateState({ type: "data_fetch_failure_403" });
                else if ((error.response || {}).status === 404)
                dispatchSimulateState({ type: "data_fetch_failure_404" });
                else dispatchSimulateState({ type: "data_fetch_failure" });
            });
        }, 500);
        return () => {
            clearTimeout(timer);
        };
    },[businessGroupKey, formatSimulateInputs, requiredFields, canUseMultiEnvironment, selectedEnvironment, ruleKey, companyKey])

    const inputField = (fieldName, fieldId, fieldType) => {
        if (fieldType==="bool"){
            return (
                <FilterOption
                    id={fieldId}
                    labelValue={fieldName}
                    filters={fieldsValues}
                    setFilters={setFieldsValues}
                    options={["false", "true"]}
                    hasEmptyOption={false}
                />
            )
        } else if (fieldType==="geo_point"){
            return (
                <GeoPointInput
                    id={fieldId}
                    labelValue={fieldName}
                    geoPoint={fieldsValues[fieldId]}
                    onChange={(value) => handleValueChange(fieldId, value)}
                    onBlur={(value) => handleBlur(fieldType, fieldId, value)}
                    error={errors[fieldId]}
                    errorMessage={errorMessages[fieldType]}
                    placeholder={placeholders[fieldType]}
                />
            )
        } else if (fieldType==="geo_shape"){
            return (
                <GeoShapeInput
                    id={fieldId}
                    labelValue={fieldName}
                    geoShape={fieldsValues[fieldId]}
                    onChange={(value) => handleValueChange(fieldId, value)}
                    onBlur={(value) => handleBlur(fieldType, fieldId, value)}
                    error={errors[fieldId]}
                    errorMessage={errorMessages[fieldType]}
                    placeholder={placeholders[fieldType]}
                />
            )
        } else if (fieldType==="timedelta"){
            return (
                <TimedeltaInput
                    id={fieldId}
                    labelValue={fieldName}
                    timedelta={fieldsValues[fieldId]}
                    onChange={(value) => handleValueChange(fieldId, value)}
                    onBlur={(value) => handleBlur(fieldType, fieldId, value)}
                    error={errors[fieldId]}
                    errorMessage={errorMessages[fieldType]}
                />
            )
        } else {
            return (
                <LabelInput
                    id={fieldId}
                    labelValue={fieldName}
                    value={fieldsValues[fieldId]}
                    onChange={(value) => handleValueChange(fieldId, value)}
                    onBlur={(value) => handleBlur(fieldType, fieldId, value)}
                    formatValue={(value) => formatInputName(fieldType, value)}
                    error={errors[fieldId]}
                    errors={errors}
                    fieldId={fieldId}
                    fieldType={fieldType}
                    errorMessage={errorMessages[fieldType]}
                    placeholder={placeholders[fieldType]}
                />
            )
        } 
    }

    const testResultContent = () => {
        if (simulateState.isLoading) {
            return (
                <CircularProgress style={{width:"20px", height:"20px", margin:"auto"}}/>
            )
        } else if (simulateState.isError) {
            return (
                <div className="normalSmallSize bold" style={{margin:"auto"}}>
                    {`${t("error_message")}. ${t("get_in_contact_with_suporte.caas@qitech.com.br")}`}
                </div>
            )
        } else if (!simulateState.fetchedData) {
            return null
        } else if (simulateState.fetchedData) {
            return (
                <>
                    <div className="normalSmallSize bold">{t("test_result")}</div>
                    {simulateState.fetchedData.decision&&
                    <DataField
                        title={t("decision")}
                        label={t(simulateState.fetchedData.decision)}
                        size={"normalSmallSize"}
                        titleNoWrap={true}
                        margin="5px"
                    />}
                    {(simulateState.fetchedData.decision_metadata||{}).reason&&
                    <DataField
                        title={t("reason")}
                        label={t((simulateState.fetchedData.decision_metadata||{}).reason)}
                        size={"normalSmallSize"}
                        titleNoWrap={true}
                        margin="5px"
                    />}
                    {(simulateState.fetchedData.decision_metadata||{}).reason_description&&
                    <DataField
                        title={t("reason_description")}
                        label={t(simulateState.fetchedData.decision_metadata.reason_description)}
                        size={"normalSmallSize"}
                        titleNoWrap={true}
                        margin="5px"
                    />}
                    {Object.keys((simulateState.fetchedData.decision_metadata||{}).custom_variables||{})>0&&
                    <DataField
                        title={t("custom_variables")}
                        label={""}
                        size={"normalSmallSize"}
                        titleNoWrap={true}
                        margin="5px"
                    />}
                    {(simulateState.fetchedData.decision_metadata||{}).custom_variables&&
                    Object.keys(simulateState.fetchedData.decision_metadata.custom_variables)
                    .map((key)=>(
                    <DataField
                        title={key}
                        label={String(simulateState.fetchedData.decision_metadata.custom_variables[key])}
                        size={"marginLeft15 normalSmallSize"}
                        titleNoWrap={true}
                        margin="0px"
                    />))}
                </>
            )
        }
    }

    if (!fieldsValues) {
        return null
    } else {
        return (
            <Dialog
                open={open}
                onClose={() => onCloseDialog()}
                fullWidth
                maxWidth="md"
            >
                <div className="dialogContent">
                    <header className="dialogContentHeader blueText">
                        <span>{t("test_rule")}</span>
                        <IconButton
                            onClick={() => onCloseDialog()}
                        >
                            <CloseIcon />
                        </IconButton>
                    </header>
                    {requiredFields.fetchedData.map((requiredField, index)=>{
                        const { fieldName, fieldId, fieldType } = getFieldNameIdType(requiredField)
                        return (
                            <div className="normalText" key={fieldId}>
                                {getTypeComponents({
                                    type: requiredField.box_type,
                                    handleConfigurationOpen: () => null,
                                    handleNodeRemove: () => null,
                                    userVariablesAvailable: [],
                                    node: {
                                        node: {
                                            type:requiredField.box_type,
                                            properties: requiredField.properties
                                        }
                                    },
                                    read_only: true
                                }).box_component}
                                {inputField(fieldName, fieldId, fieldType)}
                            </div>
                        )
                    })}
                    <Button
                        tooltip={isFormValid()?null:t("invalid_simulate_rule_form")}
                        button_option={isFormValid()?"standard":"standard_disabled"}
                        buttonLabel="simulate_rule"
                        onClick={() => isFormValid()?simulateRule():null}
                        buttonStyle={{gap:"10px", margin: "15px auto", minWidth: "140px"}}
                        isLoading={simulateState.isLoading}
                    >
                        <img src={LabIcon} alt="Lab Icon" style={{height: "15px", width: "15px", scale:"1.3"}}/>
                    </Button>
                    <div className="internalCardContainer decisionCardContainer lighterGrayBackground" style={{display: "flex", flexDirection: "column", minHeight: "146px"}}>
                        {testResultContent()}
                    </div>
                </div>
            </Dialog>
        )
    }
}