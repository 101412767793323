import React, {useContext} from "react";
import AuthContext from "../../../../context/auth-context";

function CardListHeader(props){
    let {user_data} = useContext(AuthContext)

    return (
        <div className="listCardHeader">
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                style={{flex:"1 1 110px", textAlign:"center"}}
            >
                ID do Boleto
            </div>
            {user_data.business_group_key &&
            <div 
                className={["listCardItem", "normalText"].join(" ")}
                style={{flex:"1 1 80px", textAlign:"center"}}
            >
                Companhia
            </div>}
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                style={{flex:"1 1 90px", textAlign:"center"}}
            >
                Valor
            </div>
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                style={{flex:"1 1 130px", textAlign:"center"}}
            >
                Pagador
            </div>
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                style={{flex:"1 1 130px", textAlign:"center"}}
            >
                Recebedor
            </div>
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                style={{flex:"1 1 45px", textAlign:"center"}}
            >
                Status de Análise
            </div>
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                style={{flex:"1 1 110px", textAlign:"center"}}
            >
                Data do Boleto
            </div>
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                style={{flex:"1 1 35px", textAlign:"center"}}
            >
                Status do Boleto
            </div>
        </div>
    )
}

export default CardListHeader