import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
import {getCircleStatusIcon,validateCNPJ, validateCPF, getTableRowClass, titleCase,getPartnerValidationIcon} from "../../../utils/utils"

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
  },
  tableText: {
    fontFamily: "Open Sans",
    fontWeight: 'normal',
    fontSize: "12px",
    lineHeight: "16px",
    color: "#141414",
    textAlign: "center",
    margin: "10px",
    padding: "6px",
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: '#6F6F6F',
    fontSize: "14px",
    lineHeight: "19px",
    padding: "6px",
  }
}));

function PartnersTable(props) {
    let {list_of_partners} = props
    let {maxRows} = props

    const classes = useStyles();
    useTheme();

    //Column Status show if all of them has keys and status
    let analyzedPartners = list_of_partners.filter(
        partner => partner.analysis_status && (partner.natural_person_key || partner.legal_person_key)
    )
    let showStatusColumn = analyzedPartners.length > 0
    //Column Validated show if at least one true from bureau and one true from payload
    let partnersFromPayload = list_of_partners.filter(partner => partner.is_bureau_partner)
    let partnersFromQSA = list_of_partners.filter(partner => partner.is_payload_partner)
    let showValidatedColumn = partnersFromPayload.length > 0 && partnersFromQSA.length > 0

    return (
        <Paper className={classes.tableStyle}>
        <Table style={{ padding: "8px" }}>
            <TableHead>
            <TableRow>
                <TableCell className={classes.tableHeader}>Nome</TableCell>
                <TableCell className={classes.tableHeader}>CPF/CNPJ</TableCell>
                {showStatusColumn ? <TableCell className={classes.tableHeader}>Status</TableCell> : null}
                {showValidatedColumn ? <TableCell className={classes.tableHeader}>Validado</TableCell> : null}
            </TableRow>
            </TableHead>
            <TableBody>
            {list_of_partners.slice(0,maxRows).map((partner,index) => (
                <TableRow
                    key={index}
                    className={getTableRowClass(index)}
                >
                    <TableCell className={classes.tableText}>
                        {showStatusColumn 
                        ?
                        partner.natural_person_key
                        ?
                        <Link className="link" to={"/natural-person/" + partner.natural_person_key}>
                            {titleCase(partner.name, "-")}
                        </Link>
                        :
                        partner.legal_person_key
                        ?
                        <Link className="link" to={"/legal-person/" + partner.legal_person_key}>
                            {titleCase(partner.name, "-")}
                        </Link>
                        :
                        titleCase(partner.name, "-")
                        :
                        titleCase(partner.name, "-")}                        
                    </TableCell>
                    <TableCell className={classes.tableText}>
                        {partner.document_number == null ? "-" : 
                            validateCPF(partner.document_number) 
                            ? 
                            partner.document_number.replace(/[^\d]+/g,'').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,"$1.$2.$3-$4") 
                            :
                            validateCNPJ(partner.document_number) 
                            ? partner.document_number.replace(/[^\d]+/g,'').replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"$1.$2.$3/$4-$5")
                            :
                            partner.document_number}
                    </TableCell>
                    {showStatusColumn ?
                    <TableCell className={classes.tableText}>
                        {getCircleStatusIcon(partner.analysis_status)}
                    </TableCell> : null}
                    {showValidatedColumn
                    ?
                    <TableCell className={classes.tableText}>
                        {getPartnerValidationIcon(partner)}
                    </TableCell> : null}
                </TableRow>
            ))}
            </TableBody>
        </Table>
        </Paper>
    );
}

export default PartnersTable;