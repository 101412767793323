import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { justFloat } from "../RulesEngine/utils";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from "@material-ui/core/IconButton";
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

export const GeoShapeInput = (props) => {
    
    const { id, labelValue, geoShape, onChange, onBlur, error, errorMessage } = props
    const { t } = useTranslation();
    const [isFocused, setIsFocused] = useState(false);

    function handleChange (event, pointType, index) {
        const { value } = event.target;
        let newGeoShape = geoShape
        let newGeoPoint = newGeoShape[index]
        if (pointType==="north") {
            newGeoPoint[0] = justFloat(value)
        } else if (pointType==="east") {
            newGeoPoint[1] = justFloat(value)
        }
        newGeoShape[index] = newGeoPoint
        onChange(newGeoShape)
    }

    const handleBlur = (event, pointType, index) => {
        const { value } = event.target;
        let newGeoShape = geoShape
        let newGeoPoint = newGeoShape[index]
        if (pointType==="north") {
            newGeoPoint[0] = justFloat(value)
        } else if (pointType==="east") {
            newGeoPoint[1] = justFloat(value)
        }
        setIsFocused(false)
        newGeoShape[index] = newGeoPoint
        onBlur(newGeoShape)
    }
    
    const handleFocus = () => {
        setIsFocused(true);
    };

    const onAddGeoPoint = () => {
        let newGeoShape = geoShape
        newGeoShape.push([0,0])
        onChange(newGeoShape)
    }

    const onRemoveGeoPoint = () => {
        let newGeoShape = geoShape
        newGeoShape.pop()
        onChange(newGeoShape)
    }

    return (
        <>
            <div>
                <label className="normalText normalSmallSize" htmlFor={id}>{`${t(labelValue)}`}</label>
                <IconButton
                    onClick={()=>onAddGeoPoint()}
                    style={{margin:"auto 0 auto auto", position:"absolute", right: "0", transform: "translate(-50%, -50%)"}}
                >
                    <AddCircleIcon 
                        className={"blue"}
                    />
                </IconButton>
                <IconButton
                    onClick={()=>((geoShape||[[]]).length===1)?null:onRemoveGeoPoint()}
                    style={{margin:"auto 0 auto auto", position:"absolute", right: "0", transform: "translate(-150%, -50%)", opacity:((geoShape||[[]]).length===1)?"50%":null, cursor:((geoShape||[[]]).length===1)?"not-allowed":null}}
                >
                    <RemoveCircleIcon 
                        className={"blue"}
                    />
                </IconButton>
            </div>
            {geoShape.map((geoPoint, index)=>(
            <div key={index} style={{display:"grid", gridTemplateColumns:"49.5% 49.5%", gap:"1%"}}>
                <div className="labelInput">
                    <label className="normalText normalSmallSize" htmlFor={id}>{`${index+1}ª ${t("geo_point")} - ${t("north")}`}</label>
                    <input
                        className={!isFocused&&error?"errorInput":null}
                        id={id}
                        name={id}
                        placeholder={props.placeholder?props.placeholder:null}
                        value={geoPoint[0]}
                        onChange={(event) => handleChange(event, "north", index)}
                        disabled={props.isDisabled?props.isDisabled:false}
                        onBlur={(event) => handleBlur(event, "north", index)}
                        onFocus={handleFocus}
                    />
                </div>
                <div className="labelInput">
                    <label className="normalText normalSmallSize" htmlFor={id}>{`${index+1}ª ${t("geo_point")} - ${t("east")}`}</label>
                    <input
                        className={!isFocused&&error?"errorInput":null}
                        id={id}
                        name={id}
                        placeholder={props.placeholder?props.placeholder:null}
                        value={geoPoint[1]}
                        onChange={(event) => handleChange(event, "east", index)}
                        disabled={props.isDisabled?props.isDisabled:false}
                        onBlur={(event) => handleBlur(event, "east", index)}
                        onFocus={handleFocus}
                    />
                </div>
            </div>))}
            <span className="labelInput normalSmallSize__error" style={{height:"13px"}}>
                {!isFocused&&error?t(errorMessage):null}
            </span>
        </>
    );
}
