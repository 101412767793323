import React from 'react'
import Account from './Account/Account'
import peopleIcon from '../../../../../assets/cardEntryIcons/people.svg';
// import merchantIcon from '../../../../../assets/cardEntryIcons/MerchantIcon.svg';
// import emailIcon from '../../../../../assets/cardEntryIcons/emailBlue.svg';
// import clockIcon from '../../../../../assets/cardEntryIcons/Clock.svg';
// import phoneIconBlue from '../../../../../assets/cardEntryIcons/phoneBlue.svg';
// import mapMarkerIcon from '../../../../../assets/cardEntryIcons/MapMarker.svg';
// import DataField from "../../../utils/DataField"
// import ExitToAppIcon from '@material-ui/icons/ExitToApp';
// import {formatPhone, formatAddress, 
//         titleCase, clientAge, checkNested} from "../../../utils/utils"

function UserCard(props) {
    
    let search_data     = props.alert.triggers[0].trigger_group.search_data
    let alert_generator = {}

    switch(props.alert.link_key_type){
        case "client":
            alert_generator.type = "client"
            alert_generator.icon = peopleIcon
            alert_generator.name = "Cliente"
            break
        case "payer":
            alert_generator.type = "payer"
            alert_generator.icon = peopleIcon
            alert_generator.name = "Pagador"
            break
        case "recipient":
            alert_generator.type = "recipient"
            alert_generator.icon = peopleIcon
            alert_generator.name = "Recebedor"
            break
        default:
            throw new Error()
    }

    let document_number=search_data[alert_generator.type+"_document_number"]
    let account = {
        "participant": search_data[alert_generator.type+"_account_participant"],
        "branch": search_data[alert_generator.type+"_account_branch"],
        "account_number": search_data[alert_generator.type+"_account_number"]+'-'+search_data[alert_generator.type+"_account_digit"],
        "account_type": search_data[alert_generator.type+"_account_type"]
    }
    let name=search_data[alert_generator.type+"_name"]

    return (
        <div className="analysisCard">
            <div style={{display: "flex"}}>
                <img className="cardTitleIconStyle" src={alert_generator.icon} alt="Icon Logo" />
                <span className={["blueText", "subtitleSize", "labelSpace", "breakWord"].join(" ")}>{[alert_generator.name, "| Dados"].join(" ")}</span>
            </div>
            <div className="internalCardContainer" style={{display: "flex", flexDirection: "column"}}>
                {(name) ? 
                <div style={{display: "inline", marginTop: "5px", marginLeft:"0px", lineHeight: "20px"}}>
                    <span className={["labelText"].join(" ")} style={{verticalAlign: "middle", marginLeft:"0px"}}>
                        {name || "-"}
                    </span>
                </div> : null}
                {(document_number) ? 
                <div>
                    <span className={["labelText", props.size, "labelSpace"].join(" ")} style={{verticalAlign: "middle", marginLeft:"0px"}}>
                        {document_number || "-"}
                    </span>
                </div> : null}
            </div>
            {(account.participant && account.account_number && account.account_type && account.branch) ?
            <Account accountObject={account}/> : "Dados da conta não disponível."}
        </div>
    )
}

export default UserCard