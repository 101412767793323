import React from "react";
import FilterBox from "../../../../Utils/FilterBox/FilterBox"

function Filter(props) {

    const { setPageParams, key_field_header, key_field_type } = props

    let filterTypes = {
        key_field_value: {
            type: key_field_type,
            disabled: false,
            validation:false,
            description: key_field_header
        }
    }

    return (
        <FilterBox 
            setPageParams={setPageParams}
            filterTypes={filterTypes}
            local_storage_object_preffix={props.local_storage_object_preffix}
        />
    )

}

export default Filter