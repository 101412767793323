import React, {useEffect,useCallback, useContext, useReducer, useState, useRef} from "react";
import {useStateConnectedToCache, dataFetchReducer} from "../../Utils/requestUtils"
import Filter from "./Filter/Filter"
import RentalAgreementCard from "./RentalAgreementCard/RentalAgreementCard";
import Pagination from "@material-ui/lab/Pagination";
import { CircularProgress } from "@material-ui/core";
import axios from "axios";
import ErrorBoundary from "../../Utils/ErrorBoundary"
import {useHistory} from 'react-router-dom'
import AuthContext from '../../../context/auth-context'
import AnalyzeDialog from "./AnalyzeDialog/AnalyzeDialog"
import Counter from "../Counter/counter";

function RentalAgreementsInbox(props) {
    let history = useHistory()
    let user_data = useContext(AuthContext).user_data

    if (!(user_data.roles.includes("rental_agreements_viewer")||user_data.roles.includes("create_rental_agreements")||user_data.roles.includes("rental_agreements_viewer_last_24hrs"))){
      history.push("")
    }

    let local_storage_object_preffix = "ra"
    
    const [pageParams, setPageParams] = useStateConnectedToCache(local_storage_object_preffix)

    const [pageState, dispatchPageState] = useReducer(
        dataFetchReducer,
        {fetchedData: null, isLoading: true, isError: false} 
    )

    const doRequest = useCallback(
      (payload, isRoutine) => {
        if (!isRoutine) {
			dispatchPageState({type: "data_fetch_init"})  
        }
        axios.get("/dash/car_rental/rental_agreements", 
				{params: payload}
				).then(response=>{
          dispatchPageState({
              type: "data_fetch_success",
              payload: response.data
          })          
        }).catch(error => {
          if ((error.response || {}).status === 403) dispatchPageState({type: "data_fetch_failure_403"})
          else if ((error.response || {}).status === 404) dispatchPageState({type: "data_fetch_failure_404"})  
          else dispatchPageState({type: "data_fetch_failure"})    
        })
      },
			[]
    )

	let [analyzeBox, setAnalyzeBox] = useState(false);

	const handleAnalyzeBoxOpenClick = () => {
		setAnalyzeBox(true);
	};
	
	const handleAnalyzeBoxCloseClick = () => {
		setAnalyzeBox(false);
	};

	const timerRef = useRef(null);
    useEffect(() => {
		const startInterval = (payload) => {
            stopInterval()

			timerRef.current = setInterval(() => {
			  doRequest(payload, true);
			}, 15000);
		};

        const stopInterval = () => {
            clearInterval(timerRef.current)
        }

		let payload = {
			"page_number": pageParams.page - 1,
			"page_rows": 25
		};

		if (!user_data.roles.includes("rental_agreements_viewer")&&user_data.roles.includes("rental_agreements_viewer_last_24hrs")) {
			payload["filter_last_24hrs"] = true
		}
		Object.keys(pageParams.filters).forEach((key,index) => {
			if (key === "rne") {
			  	payload["document_number"] = pageParams.filters[key]
			}
			else {
				payload[key] = pageParams.filters[key]
			}
		})

		const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
				startInterval(payload)
			} else {
				stopInterval()
			}
		};
		document.addEventListener('visibilitychange', handleVisibilityChange);

        const initial_delay_timeout = setTimeout(()=> {
            doRequest(payload, false)
        }, 300);

		startInterval(payload)
        
		return () => {
            clearTimeout(initial_delay_timeout)
            stopInterval()
			document.removeEventListener('visibilitychange', handleVisibilityChange);
		}	
    },[doRequest,pageParams,user_data.roles])
	
  const handleChangePage = (e, page, filters) => {
	if (pageParams.page === page) {
		return
	}
    setPageParams({page: page, filters: filters})
  }   

	const contentContainer = (dataList) => {
		let nr_of_cards = dataList['data'].length
		let cards = dataList['data'].map((ra) => (
			<ErrorBoundary key={ra.rental_agreement_key}>
				<RentalAgreementCard rental_agreement={ra}/>
			</ErrorBoundary>
		))

		if (nr_of_cards === 0) {
			return (
			<div className='emptyContainer'>
				<p className={["blueText", "subtitleSize"].join(" ")}>
					Nenhum aluguel a ser analisado
				</p>
			</div>
			)
		}

		else {
			return <div className='cardContainer'>{cards}</div>
		}
	}

	if (pageState.fetchedData) {
		return (
		<div style={{ width: "100%", paddingBottom: "30px"}}>
			<div style={{display:"flex"}}>
				<p className={["blueText", "titleSize"].join(" ")}>ALUGUÉIS</p>
				<div style={{ margin: "auto 30px auto auto" }}>
				{user_data.roles.includes("create_rental_agreements")&&
					<div
						style={{ padding: "5px 30px", "margin": "5px", minWidth: props.is_role_manager && '200px'}}
						className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
						onClick={handleAnalyzeBoxOpenClick}                        
					>
						+ Nova Análise
					</div>}
				{props.is_role_manager&&
					<div
						style={{ padding: "5px 30px", "margin": "5px", minWidth: props.is_role_manager && '200px'}}
						className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
						onClick={props.handleViewChange}                        
					>
						Ir para Aluguéis SAC
					</div>}
				</div>
			</div>
			<div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 16}}>
				<Counter />
			</div>
			{(user_data.roles.includes("rental_agreements_viewer")||user_data.roles.includes("rental_agreements_viewer_last_24hrs"))&&
			<div>
				<ErrorBoundary doNothing>
					<Filter local_storage_object_preffix={local_storage_object_preffix} setPageParams={setPageParams} />
				</ErrorBoundary>
				<div>
					<ErrorBoundary>
						{contentContainer(pageState.fetchedData)}
					</ErrorBoundary>
				</div>
				<div>
					<ErrorBoundary doNothing>
						<Pagination 
							className="pagination" 
							page={pageParams.page} 
							count={parseInt(pageState.fetchedData["number_of_pages"])}
							onChange={(e, page) => handleChangePage(e, page, pageParams.filters)}
						/>
					</ErrorBoundary>
				</div>
			</div>}
			<AnalyzeDialog
				user_data={user_data}
				open={analyzeBox}
				onClose={handleAnalyzeBoxCloseClick}
				setPageParams={setPageParams}
				local_storage_object_preffix={local_storage_object_preffix}
			/>
		</div>
		)
	}
	else if (pageState.isError){
		return (
			<div style={{ width: "auto" }}>
				<div style={{display:"flex"}}>
					<div >
						<p className={["blueText", "titleSize"].join(" ")}>ALUGUÉIS</p>
					</div>	
				</div>			
				<ErrorBoundary doNothing><Filter local_storage_object_preffix={local_storage_object_preffix} setPageParams={setPageParams} /></ErrorBoundary>
                <div style={{height: "55vh", display:"flex"}}>
                    <p className={["blueText", "titleSize"].join(" ")} style={{margin:"auto", textAlign:"center"}}>
                        {pageState.errorMessage}
                    </p>
                </div>
			</div>
		)
	}
	else if (pageState.isLoading) {
		return(
			<div style={{ width: "auto" }}>
				<p className={["blueText", "titleSize"].join(" ")}>ALUGUÉIS</p>
				<div className="circularProgressCenter">
					<CircularProgress />
				</div>
			</div>
		)
    }
    else return null
	}

export default (RentalAgreementsInbox);