export const colorRed ='#D91A1A'
export const colorGreen = '#73C580'
export const colorGrey = 'rgb(170, 167, 153)'

export const getAnalysisStatusSiglum = (status) => {
    switch(status){
        case "deactivated":
          	return "D";
        case "active":
            return "A";
        default:
            return "?";
    }
}

export const getAnalysisStatusColor = (status) => {
    switch(status){
        case "active":
            return colorGreen
        default:
            return colorGrey
    }
}

export const calculateDaysDifference = (date1, date2) => {
    const oneDayInMilliseconds = 1000 * 60 * 60 * 24;

    const startDate = new Date(date1);
    const endDate = new Date(date2);

    const differenceInMilliseconds = Math.abs(endDate - startDate);

    const differenceInDays = Math.ceil(differenceInMilliseconds / oneDayInMilliseconds);

    return differenceInDays;
}

export const handleRequestError = (error, dispatch) => {
    console.error("Error: ", error);
    dispatch({ type: "data_fetch_failure" });
};
