import React, {useState} from 'react'
import TextField from '@material-ui/core/TextField';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { getConfirmationIcon } from "../../utils/utils"
import InputMask from "react-input-mask";
import axios from 'axios'
import { CircularProgress } from '@material-ui/core';
import {validateCNPJ, validateCPF} from "../../utils/utils"

const theme = createMuiTheme({
    palette:{
        primary: {
            main:"#0b1f82"
        },
        secondary: {
            main: "#FF0000"
        },
    }
  });

function InputContent (props){
    let {lockType} = props
    let {setLockType} = props
    let {personType} = props
    let {setPersonType} = props
    let {handleRefreshPage} = props
    let {onClose} = props

    let documentMask = personType === "natural_person" ? "999.999.999-99" : "99.999.999/9999-99"
    let documentName = personType === "natural_person" ? "CPF" : "CNPJ"
    let lockTypeTranslate = lockType === "customer" ? "Comprador" : "Vendedor"

    const [pendingState, setPendingState] = useState(false)
    const [documentNumber, setDocumentNumber] = useState("")
    const [status, setStatus] = useState(null)
    const [observationBox, setObservationBox] = useState("");
    const [documentError, setDocumentError] = useState(true)

    const validateDocument = (document) => {
        if (personType === "natural_person") return !validateCPF(document)
        else return !validateCNPJ(document)
    }
    
    const handleChange = (event, item) => {
        setDocumentError(validateDocument(event.target.value))
        setDocumentNumber(event.target.value)
    }

    const handleBackClick = () => {
        setLockType(null)
        setPersonType(null)
    }

    const handleReturn = () => {
        onClose()
        handleRefreshPage()         
    }

    const handleObservationBoxChange = (event) => {
        if (event.target.value.length === 0){
            setObservationBox(null)
        }
        else{
            setObservationBox(event.target.value)
        }
    };

    const onSubmit = () => {
        if (documentError) return
        let payload = {
            "lock_type": lockType,
            "lock_source": "manually_input"
        }

        if (lockType === "customer") payload['customer_document_number'] = documentNumber
        else payload['seller_document_number'] = documentNumber

        if (observationBox){
            payload['observation'] = observationBox
        }

        setPendingState(true)
        axios.post('/dash/card_order/lock', payload).then(response => {
            setPendingState(false)   
            setStatus({success: true, message: "Requisição processada com sucesso"})
        }).catch(error => {
            setPendingState(false)
            setStatus({success: false, message: "Não foi possível processar sua requisição"})
        });
      }


    
    if (pendingState){
        return(
            <div style={{display:"flex", flexGrow:"1"}}>
                <CircularProgress style={{margin:"auto"}}/>
            </div>
        )
    }
    if (status != null) {
        return(
            <div style={{ display:"flex", flexDirection:"column", flexGrow:"1"}}>
                <div style={{margin:"auto"}}>
                    {getConfirmationIcon(status.success)}
                </div>
                <div style={{margin:"auto"}}>
                    {status.message}
                </div>
                <div
                    onClick={handleReturn} 
                    className={["button","standard", "normalText", "normalMediumSize"].join(" ")} 
                    style={{margin:"auto auto 20px auto", width:"auto"}}
                >
                    VOLTAR À PÁGINA INICIAL
                </div>
            </div>
        )
    }

    return (
        <div style={{ display:"flex", flexDirection:"column", flexGrow:"1"}}>
            <div style={{display: "flex",justifyContent: "center",flexDirection: "column",margin: "0 auto 10px auto",width: "60%"}}>
            <ThemeProvider theme={theme}>
                <InputMask 
                    mask={documentMask}
                    maskPlaceholder=""
                    value={documentNumber}
                    onChange={handleChange}
                >
                    <TextField 
                        color={documentError? "secondary": "primary"}
                        label={documentName + " do " + lockTypeTranslate}
                    />
                </InputMask>  
                <TextField  
                    style={{marginTop: "10px"}}    
                    fullWidth
                    multiline
                    variant="outlined"
                    rows="5"
                    label={"Observações"}
                    color="primary"
                    onChange={handleObservationBoxChange}
                />
            </ThemeProvider>               
            </div>
            <div style={{display:"flex", flexDirection:"row", justifyContent: "center", margin:"auto 0px 20px 0px"}}>
                <div
                    className={documentError ? ["button","standard", "disabled", "normalText", "normalMediumSize"].join(" ") : ["button","standard", "normalText", "normalMediumSize"].join(" ")} 
                    onClick={onSubmit} 
                    style={{width:"40%"}}
                >
                    Finalizar
                </div>
                <div
                    className={["button","cancel"].join(" ")} 
                    onClick={handleBackClick} 
                    style={{width:"40%"}}
                >
                    Retornar
                </div>
            </div>
        </div>
    )

}

export default InputContent