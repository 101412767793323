import React, {useState} from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from 'moment'
import {getTableRowClass} from "../Utils/utils"
import Tooltip from "@material-ui/core/Tooltip";
import FileDownloadButton from "../Utils/FileDownload/FileDownloadButton/FileDownloadButton"
import FileDownloadErrorDialog from "../Utils/FileDownload/FileDownloadErrorDialog/FileDownloadErrorDialog";

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
  },
  blueText: {
    fontFamily: "Open Sans",
    fontWeight: "600",
    color: "#0b1f82"
},
  tableText: {
    fontFamily: "Open Sans",
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "center",
    margin: "10px",
    padding: "6px",
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: '#6F6F6F',
    fontSize: "14px",
    lineHeight: "19px",
    padding: "6px",
  }
}));

function FraudHistoryTable(props) {
    const classes = useStyles();
    useTheme();

    const { fraud_history } = props

    const { transaction_key, dashAPIEndpointUrl } = props

    const getInitialsFromName = (fullName) => {
        let fullNameInArr = fullName.split(" ")
        const first_name = fullNameInArr[0]
        const last_name = fullNameInArr[fullNameInArr.length - 1]

        return first_name.charAt(0) + last_name.charAt(0)
    }

    const [fileErrorDialogOpen, setFileErrorDialogOpen] = useState(false)

	const [fileErrorDialogType, setFileErrorDialogType] = useState("")

	const handleFileErrorDialogOpen = (errorType) => {
		setFileErrorDialogType(errorType)
		setFileErrorDialogOpen(true)
	};
	
	const handleFileErrorDialogClose = () => {
		setFileErrorDialogType("")
		setFileErrorDialogOpen(false)
	};
    
    return (
        <Paper className={classes.tableStyle}>
        <Table style={{ padding: "8px" }}>
            <TableHead>
                <TableRow>
                    <TableCell className={classes.tableHeader} style={{width: "70%"}}>Observação</TableCell>
                    <TableCell className={classes.tableHeader}>Autor</TableCell>
                    <TableCell className={classes.tableHeader}>Data</TableCell>
                    <TableCell className={classes.tableHeader}>Anexo</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            {fraud_history.map((history, index)=> (
                <TableRow
                key={index}
                className={getTableRowClass(index)}
                > 
                    <TableCell
                        className={[classes.tableText, "breakWord"].join(" ")}
                        style={{width: "70%", padding: "20px"}}
                    >
                        {history.observation}
                    </TableCell>
                    <TableCell className={classes.tableText}>
                        {history.analyst_name ?
                        <Tooltip title={<p className={["tooltipText", "centerText"].join(" ")}>{history.analyst_name}<br></br>{history.analyst_email}</p>}>
                            <div className={["textOverflow","centerText","labelSpace"].join(" ")}>
                                {getInitialsFromName(history.analyst_name)}
                            </div>
                        </Tooltip>
                         : "-"}
                    </TableCell>
                    <TableCell className={[classes.tableText].join(" ")}>
                        {history.event_date ? moment(history.event_date).format("DD/MM/YYYY HH:mm:ss") : "-"}
                    </TableCell>
                    <TableCell className={[classes.tableText].join(" ")}>
                        {history.attachment_key ? 
                        <div>
                            <FileDownloadButton 
                                attachment_content_type={history.attachment_content_type} 
                                attachment_key={history.attachment_key}
                                attachment_name={history.attachment_name}
                                transaction_key={transaction_key}
                                dashAPIEndpointUrl={dashAPIEndpointUrl}
                                handleFileDownloadErrorDialogOpenClick={handleFileErrorDialogOpen}
                            /> 
                            <Tooltip title={<p className={["tooltipText", "centerText"].join(" ")}>{history.attachment_name}</p>}>
                                <div className={["textOverflow","centerText","labelSpace"].join(" ")}>
                                    {history.attachment_name}
                                </div>
                            </Tooltip> 
                        </div> : 
                        "-"}
                    </TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        <FileDownloadErrorDialog open={fileErrorDialogOpen}
                                    onClose={handleFileErrorDialogClose}
                                    errorType={fileErrorDialogType}/>
        </Paper>
    );
}

export default FraudHistoryTable;
