import React from "react";
import { useTranslation } from "react-i18next";

const LanguageSelector = (props) => {
const { i18n } = useTranslation();

const {language, setLanguage} = props

const changeLanguage = (event) => {
    setLanguage(event.target.value)
    i18n.changeLanguage(event.target.value);
};

return (
    <div className="languageSelectorContainer">
        <input type="radio" value="pt-BR" name="language" id="userLanguageSelectionPT" checked={language === 'pt-BR'} onChange={changeLanguage}/> 
        <label className="bold labelText normalSmallSize" htmlFor="userLanguageSelectionPT">pt-BR</label>
        <input type="radio" value="en" name="language" id="userLanguageSelectionEN" checked={language === 'en'} onChange={changeLanguage}/> 
        <label className="bold labelText normalSmallSize" htmlFor="userLanguageSelectionEN">en</label>
    </div>
);
};

export default LanguageSelector;


