import React, { useContext } from 'react'
import peopleIcon from "../../../../assets/cardEntryIcons/people.svg"
import AuthContext from "../../../../context/auth-context"
import BankslipHistoric from '../../../@GeneralComponents/BankslipHistoric'
import BillPaymentHistoric from '../../../@GeneralComponents/BillPaymentHistoric'
import WireTransferHistoric from '../../../@GeneralComponents/WireTransferHistoric'
import WithdrawalHistoric from "../../../@GeneralComponents/WithdrawalHistoric"
import TransactionHistoric from '../../../@GeneralComponents/TransactionHistoric'
import DictOperationHistoric from "../../../@GeneralComponents/DictOperationHistoric"


function HistoricCard(props) {

    let {billPayment} = props
    let roles = useContext(AuthContext).user_data.roles
    let client = billPayment.payer

    return (
        <div className="analysisCard">
            <div style={{display: "flex"}}>
                <img className="cardTitleIconStyle" src={peopleIcon} alt="peopleIcon Logo" />
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>Cliente | Históricos</span>
            </div>
            <div className="internalCardContainer">
                {roles.includes("read_bankslips")?
                <div>
                    <div className={["blueText", "normalSmallSize"].join(" ")}>
                        Histórico de Transações Boletos
                    </div>
                    <BankslipHistoric 
                        document_number={client.document_number}
                        final_date={billPayment.bill_payment_date}
                    />
                </div>:null}
                {roles.includes("read_wire_transfers")?
                <div>
                    <div className={["blueText", "normalSmallSize"].join(" ")}>
                        Histórico de Transferência
                    </div>
                    <WireTransferHistoric 
                        document_number={client.document_number}
                        final_date={billPayment.bill_payment_date}
                    />
                </div>:null}
                {roles.includes("read_bill_payments")?
                <div>
                    <div className={["blueText", "normalSmallSize"].join(" ")}>
                        Histórico de Pagamento de Contas
                    </div>
                    <BillPaymentHistoric 
                        document_number={client.document_number}
                        final_date={billPayment.bill_payment_date}
                        bill_payment_key={billPayment.bill_payment_key}
                    />
                </div>:null}
                {roles.includes("read_withdrawals")?
                <div>
                    <div className={["blueText", "normalSmallSize"].join(" ")}>
                        Histórico de Saques
                    </div>
                    <WithdrawalHistoric 
                        document_number={client.document_number}
                        final_date={billPayment.bill_payment_date}
                    />
                </div>:null}
                {roles.includes("read_pix_dict_operations")?
                <div>
                    <div className={["blueText", "normalSmallSize"].join(" ")}>
                        Histórico de Mudanças na DICT
                    </div>
                    <DictOperationHistoric 
                        document_number={client.document_number}
                        final_date={billPayment.bill_payment_date}
                    />
                </div>:null}
                {roles.includes("read_pix_transactions")?
                <div>
                    <div className={["blueText", "normalSmallSize"].join(" ")}>
                        Histórico de Transações Pix
                    </div>
                    <TransactionHistoric 
                        document_number={client.document_number}
                        final_date={billPayment.bill_payment_date}
                    />
                </div>:null}
            </div>
        </div>
    )
}

export default HistoricCard