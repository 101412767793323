import React, { useEffect, useCallback, useReducer, useContext, useState } from "react"
import { useHistory } from "react-router-dom"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Pagination from "@material-ui/lab/Pagination"
import { dataFetchReducer, useStateConnectedToCache } from "../../Utils/requestUtils"
import { CircularProgress } from '@material-ui/core'
import CreditAnalysisLegalPersonCard from "./CreditAnalysisLegalPersonCard/CreditAnalysisLegalPersonCard"
import Filter from "./Filter/Filter"
import NewAnalyzeDialog from '../NewAnalyzeDialog/NewAnalyzeDialog'
import AuthContext from "../../../context/auth-context"
import axios from "axios"
import ErrorBoundary from '../../Utils/ErrorBoundary'
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: "Open Sans",
        fontWeight: 600,
        fontSize: "22px",
        lineHeight: "27px",
        color: "#0b1f82",
        width: "auto"

    },
    error: {
        fontFamily: "Open Sans",
        fontWeight: 600,
        fontSize: "22px",
        lineHeight: "27px",
        color: "#0b1f82",
        marginUp: "300px"
    },
    subtitle: {
        fontFamily: "Open Sans",
        fontWeight: 600,
        fontSize: "18px",
        lineHeight: "25px",
        color: "#0b1f82",
    },
    cardContainer: {
        width: "100%",
        display: "block",
    },
    pagination: {
        float: "right"
    },
    emptyContainer: {
        width: "100%",
        verticalAlign: "middle",
        textAlign: "center",
        padding: "30px",
        background: "white",
        boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.2)",
        borderRadius: "10px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        minWidth: "1100px"
    },
    buttonAnalyze: {
        boxSizing: "border-box",
        boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.12)",
        backgroundColor: "#0b1f82",
        fontFamily: "Open Sans",
        fontSize: "13px",
        fontWeight: "bold",
        lineHeight: "18px",
        textAlign: "center",
        color: "#ffffff",
        borderRadius: "30px",
        textTransform: "capitalize",
        margin: "10px 7px 10px 7px",
        padding: "8px 20px 8px 20px",
        minWidth: "100px",
        "&:hover": {
            backgroundColor: "#204e68",
        }
    },
}))

function CreditAnalysisLegalPersonsInbox(props) {
    let history = useHistory()
    let user_data = useContext(AuthContext).user_data
    const classes = useStyles()
    useTheme()

    const { t } = useTranslation()

    if (!user_data.roles.includes("read_credit_analysis_legal_persons")) {
        history.push("")
    }

    let local_storage_object_preffix = "credit_lp"

    const [pageParams, setPageParams] = useStateConnectedToCache(local_storage_object_preffix)

    const [pageState, dispatchPageState] = useReducer(
        dataFetchReducer,
        { fetchedData: null, isLoading: true, isError: false }
    )

    const doRequest = useCallback(
        (payload) => {
            dispatchPageState({ type: "data_fetch_init" })
            axios.get("/dash/credit_analysis/legal_persons", {
                params: payload
            }).then(response => {
                dispatchPageState({
                    type: "data_fetch_success",
                    payload: response.data
                })
            }).catch(error => {
                if ((error.response || {}).status === 403) dispatchPageState({ type: "data_fetch_failure_403" })
                else if ((error.response || {}).status === 404) dispatchPageState({ type: "data_fetch_failure_404" })
                else dispatchPageState({ type: "data_fetch_failure" })
            })
        }, []
    )

    useEffect(() => {
        let payload = {
            "page_number": pageParams.page - 1,
            "page_rows": 25
        }
        Object.keys(pageParams.filters).forEach((key, index) => {
            payload[key] = pageParams.filters[key]
        })
        const timer_ = setTimeout(() => {
            doRequest(payload)
        }, 100)
        return () => {
            clearTimeout(timer_)
        }
    }, [doRequest, pageParams])

    const [companiesList, setCompaniesList] = useState(null)

    const doRequestCompaniesList = useCallback(
        () => {
            axios.get('/dash/business_group/credit_analysis/companies').then(response => {
                setCompaniesList(response.data)
            }).catch(error => { console.log((error.response || {}).status) })
        }, [])

    useEffect(() => {
        doRequestCompaniesList()
    }, [doRequestCompaniesList])

    const handleChangePage = (event, page, filters) => {
        if (pageState.page === page) {
            return
        }
        setPageParams({ page: page, filters: filters })
    }

    let [analyzeBox, setAnalyzeBox] = useState(false)

    const handleAnalyzeBoxOpenClick = () => {
        setAnalyzeBox(true)
    }

    const handleAnalyzeBoxCloseClick = () => {
        setAnalyzeBox(false)
    }

    const contentContainer = (dataList) => {

        const nr_of_cards = dataList['data'].length
        let cards = dataList['data'].map((credit_analysis, index) => (
            <ErrorBoundary key={index}>
                <CreditAnalysisLegalPersonCard key={credit_analysis.credit_proposal_legal_person_key} credit_analysis={credit_analysis} user_data={user_data} />
            </ErrorBoundary>
        ))

        if (nr_of_cards === 0) {
            return (
                <div className={classes.emptyContainer}>
                    <p className={classes.subtitle}>
                        {t("Nenhum caso a ser analisado")}
                    </p>
                </div>
            )
        }

        else {
            return (
                <div className='cardContainer'>
                    <div className="listCardHeader">
                        {user_data.business_group_key ?
                            <div
                                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                                style={{ flex: "1 1 120px", textAlign: "center" }}
                            >
                                {t("Companhia")}
                            </div>
                            :
                            null}
                        <div
                            className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                            style={{ flex: "1 1 120px", textAlign: "center" }}
                        >
                            {t("External_id")}
                        </div>
                        <div
                            className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                            style={{ flex: "1 1 120px", textAlign: "center" }}
                        >
                            {t("Razão Social")}
                        </div>
                        <div
                            className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                            style={{ flex: "1 1 145px", textAlign: "center" }}
                        >
                            {t("Documento")}
                        </div><div
                            className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                            style={{ flex: "1 1 145px", textAlign: "center" }}
                        >
                            {t("Valor")}
                        </div>
                        <div
                            className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                            style={{ flex: "1 1 45px", textAlign: "center" }}
                        >
                            {t("Status da Análise")}
                        </div>
                        <div
                            className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                            style={{ flex: "1 1 70px", textAlign: "center" }}
                        >
                            {t("Data de Análise")}
                        </div>
                        <div
                            className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                            style={{ flex: "0 1 70px", textAlign: "center" }}
                        />
                    </div>
                    {cards}
                </div>
            )
        }
    }

    if (pageState.fetchedData) {
        return (
            <div style={{ width: "auto", paddingBottom: "30px" }}>
                <div style={{ display: "flex", alignItems:"center" }}>
                    <p className="blueText titleSize">{t("credit_analysis_legal_person_title")}</p>
                    <div style={{ margin: "auto 20px auto auto" }}>
                        {user_data.roles.includes('create_credit_analysis_legal_persons')
                            ?
                            <div
                                style={{ padding: "5px 30px", "margin": "5px" }}
                                className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                                onClick={handleAnalyzeBoxOpenClick}
                            >
                                {t("+ Nova Análise")}
                            </div>
                            : null
                        }
                    </div>
                </div>
                <ErrorBoundary doNothing>
                    <Filter local_storage_object_preffix={local_storage_object_preffix} setPageParams={setPageParams} companiesList={companiesList} />
                </ErrorBoundary>
                <div>{contentContainer(pageState.fetchedData)}</div>
                <div>
                    <Pagination
                        className="pagination"
                        page={pageParams.page}
                        count={parseInt(pageState.fetchedData["number_of_pages"])}
                        onChange={(e, page) => handleChangePage(e, page, pageParams.filters)}
                    />
                </div>
                <NewAnalyzeDialog
                    user_data={user_data}
                    companiesList={companiesList}
                    open={analyzeBox}
                    onClose={handleAnalyzeBoxCloseClick}
                    setPageParams={setPageParams}
                    local_storage_object_preffix={local_storage_object_preffix}
                />
            </div>
        )
    }
    else if (pageState.isError) {
        return (
            <div style={{ width: "auto" }}>
                <div style={{ display: "flex" }}>
                    <p className="blueText titleSize">{t("credit_analysis_legal_person_title")}</p>
                </div>
                <div style={{ height: "55vh", display: "flex" }}>
                    <p className={["blueText", "titleSize"].join(" ")} style={{ margin: "auto", textAlign: "center" }}>
                        {pageState.errorMessage}
                    </p>
                </div>
            </div>
        )
    }
    else if (pageState.isLoading) {
        return (
            <div style={{ width: "auto" }}>
                <p className="blueText titleSize">{t("credit_analysis_legal_person_title")}</p>
                <div className='circularProgressCenter'>
                    <CircularProgress />
                </div>
            </div>
        )
    }
    else return null

}

export default CreditAnalysisLegalPersonsInbox