import React, { useReducer, useState } from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import TextField from "@material-ui/core/TextField"
import { useHistory } from "react-router-dom"
import axios from "axios"
import { getAlertStatusDecision } from "../../../utils/utilsAlert"
import { CircularProgress } from '@material-ui/core'
import ConfirmationContent from "./ConfirmationContent"
import { decisionReducer } from "../../../../Utils/requestUtils"
import Checkbox from '@material-ui/core/Checkbox'
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#0b1f82"
        },
    }
})

function TreatedDialog(props) {
    const history = useHistory()

    const [dialogState, dispatchDialogState] = useReducer(
        decisionReducer,
        { isLoading: false, isError: false, finished: false }
    )

    const [complianceReport, setComplianceReport] = useState(false)

    const handleApprove = () => {
        dispatchDialogState({ type: "send_request_init" })
        let payload = {
            alert_status: getAlertStatusDecision(props.alert.irregularity_type, props.alert.lock_key, complianceReport, 'treated')
        }
        if (props.data != null) {
            payload = { ...payload, observation: props.data }
        }
        setTimeout(function () {
            axios.put('/dash/card_issuance_backoffice/alert/' + props.alert.alert_key, payload).then(response => {
                dispatchDialogState({ type: "send_request_success" })
            }).catch(error => {
                if ((error.response || {}).status === 403) dispatchDialogState({ type: "send_request_failure_403" })
                else if ((error.response || {}).status === 409) dispatchDialogState({ type: "send_request_failure_409" })
                else dispatchDialogState({ type: "send_request_failure" })
            })
        }, 1000)

    }

    if (dialogState.finished && dialogState.isLoading) {
        return (
            <Dialog
                open={props.open}
                onClose={() => history.push("/card-issuance-alerts-inbox")}
                keepMounted={false}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle style={{ paddingBottom: "0px" }} className={["blueText", "normalMediumSize"].join(" ")}>Finalizar alerta</DialogTitle>
                <DialogContent>
                    <div style={{ display: "flex", width: "310px", height: "217px" }}>
                        <CircularProgress style={{ margin: "auto" }} />
                    </div>
                </DialogContent>
            </Dialog>

        )
    }
    else if (dialogState.finished) {
        let confirmationData = {
            message: dialogState.message,
            success: !dialogState.isError
        }
        return (
            <Dialog
                open={props.open}
                onClose={() => history.push("/card-issuance-alerts-inbox")}
                keepMounted={false}
                disableBackdropClick
            >
                <DialogTitle style={{ paddingBottom: "0px" }} className={["blueText", "normalMediumSize"].join(" ")}>Confirmação de Revisão</DialogTitle>
                <DialogContent>
                    <ConfirmationContent onClose={() => history.push("/card-issuance-alerts-inbox")} data={confirmationData} />
                </DialogContent>
            </Dialog>
        )
    }
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            keepMounted={false}
        >
            <DialogTitle style={{ paddingBottom: "0px" }} className={["blueText", "normalMediumSize"].join(" ")}>Finalizar alerta</DialogTitle>
            <DialogContent>
                <div>
                    <div>
                        <TextField
                            fullWidth
                            multiline
                            variant="outlined"
                            margin="dense"
                            rows="5"
                            defaultValue={props.data}
                            disabled={true}
                        />
                    </div>
                    {props.alert.irregularity_type === "fraud" ? null :
                        <div className='internalCardContainer'>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <ThemeProvider theme={theme}>
                                    <Checkbox
                                        onChange={e => setComplianceReport(e.target.checked)}
                                        checked={complianceReport}
                                        color="primary"
                                    />
                                </ThemeProvider>
                                <div>
                                    <p
                                        className={["normalText", "normalMediumSize"].join(" ")}
                                    >
                                        Reportado para o COAF
                                    </p>
                                </div>
                            </div>
                        </div>}
                    <div className="internalCardContainer" style={{ display: "flex", justifyContent: "center" }}>
                        <div
                            className={["button", "approved", "normalText", "normalMediumSize"].join(" ")}
                            onClick={handleApprove}
                        >
                            Finalizar
                        </div>
                        <div
                            className={["button", "reproved", "normalText", "normalMediumSize"].join(" ")}
                            onClick={props.onClose}
                        >
                            Cancelar
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default TreatedDialog