import React, { useContext } from 'react'
import peopleIcon from "../../../../assets/cardEntryIcons/people.svg"
import AuthContext from "../../../../context/auth-context"
import OperationHistoric from "./OperationHistoric/OperationHistoric"

function HistoricCard(props) {

    let { operation } = props
    let roles = useContext(AuthContext).user_data.roles

    return (
        <div className="analysisCard">
            <div style={{display: "flex"}}>
                <img className="cardTitleIconStyle" src={peopleIcon} alt="peopleIcon Logo" />
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>Cliente | Históricos</span>
            </div>
            <div className="internalCardContainer">
                {roles.includes("operations_viewer")?
                <div>
                    <div className={["blueText", "normalSmallSize"].join(" ")}>
                        Histórico de Operações
                    </div>
                    <OperationHistoric 
                        document_number={operation.client.document_number}
                        final_date={operation.operation_date}
                    />
                </div>:null}
            </div>
        </div>
    )
}

export default HistoricCard