import React, { useEffect } from "react";
import { CircularProgress } from '@material-ui/core';
import axios from "axios";
import TransactionTable from "./TransactionTable/TransactionTable"
import Pagination from "@material-ui/lab/Pagination";
import MerchantMap from "./MerchantMap/MerchantMap"
import {formatMerchantMapObj} from "../../../../utils/utilsAlert"
import {isOpenAlert} from "../../../../utils/utilsAlert"

function TransactionHistoric(props) {

    const {link_key, alert_triggers_transactions, alert_triggers_sms_validation} = props
  
    const [userHistoric, setUserHistoric] = React.useState(null)
    const [tablePage, setTablePage] = React.useState(1)
    const [mapObject, setMapObject] = React.useState(null)
  
    useEffect(() => {
        if (userHistoric === null){
            let params = {
				"page_number": tablePage -1,
                "page_rows": 10,
                "cardholder_id": link_key[0],
                "bin": link_key[1],
                "last4": link_key[2]
            }
            if (!isOpenAlert(props.alert.alert_status)){
                params["final_date"] = props.alert.alert_events[props.alert.alert_events.length -1].event_date
            }
          	const timer = setTimeout(() => {
                axios.get('/dash/card_issuance_backoffice/transactions', {
					params: params
				}).then(response=>{
                    setUserHistoric(response.data)
                }).catch(error=>{
                    setUserHistoric(undefined)
                })

            }, 500);
            return () => {
              clearTimeout(timer)
            }
        }
    }, [userHistoric,tablePage,link_key,props.alert.alert_status, props.alert.alert_events])

    const handleChangePage = (event, page) => {
		if (tablePage === page) {
			return
		}
        setTablePage(page)
        setUserHistoric(null)
	}
  
    if (userHistoric === null) {
      return (
        <div style={{display: "flex", height: "160px"}}>
            <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                <CircularProgress />
            </div>
        </div>
      );    
    }
    else if (userHistoric === undefined || userHistoric.data.length === 0){
      return (
        <div style={{display: "flex", height: "160px"}}>
            <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                Informação Indisponível
            </div>
        </div>
      )
    }
    else {
        if (mapObject === null) {
            formatMerchantMapObj(userHistoric.data).then((values) => {
                let filtered = values.filter(function (el) {
                    return el != null;
                  });
                setMapObject(filtered)
              }).catch(error=>{
                  console.log(error)
                setMapObject(undefined)
            })
        }
        return (
                <div>
                    <div>
                        <TransactionTable historic={userHistoric.data} alert_triggers_transactions={alert_triggers_transactions} alert_triggers_sms_validation={alert_triggers_sms_validation}/>
                    </div>
                    <div style={{display:"flex", marginTop: "10px"}}>
                        <Pagination style={{marginLeft:"auto"}} className='pagination' page={tablePage} count={userHistoric['number_of_pages']} onChange={(e,page) => handleChangePage(e, page)} />
                    </div>
                    <div className="internalCardContainer">
                        <MerchantMap merchants={mapObject}/>
                    </div>
                </div>
        );
    }
  }
  
  export default TransactionHistoric;