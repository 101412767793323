import React, { useState, useEffect, useContext } from "react";
import ConfirmationDialog from "./ConfirmationDialog/ConfirmationDialog";
import moment from 'moment';
import clockIcon from '../../../../assets/cardEntryIcons/Clock.svg';
import DataField from "../../utils/DataField"
import AuthContext from "../../../../context/auth-context"
import ObservationTextField from "../../../../components/common/ObservationTextField";

const setAllowChanges = (status, roles, role_to_be_verified) => {
    if (!roles.includes(role_to_be_verified)){
        return false
    }
    switch(status){
        case "pending":
        case "in_manual_analysis":
            return true
        default:
            return false
    }
}


function Observation(props) {

    let {bankslip} = props

    let roles = useContext(AuthContext).user_data.roles
    let allowChanges = setAllowChanges(bankslip.analysis_status, roles, "update_bankslips")

    const [openDialog, setOpenDialog] = useState(false)
    const [isApproved, setIsApproved] = useState(null)

    const [observationBox, setObservationBox] = useState("")

    const [disableChange, setDisableChange] = useState(false)
    const [difference, setDifference] = useState(null)

  //Calculate initial difference between NOW and limit_date
    useEffect(() => {
        if(allowChanges && bankslip.manual_analysis_limit_date != null){
            const timer = setTimeout(() => {
                const now = moment()
                const limit = moment(bankslip.manual_analysis_limit_date)
                const diff = limit.diff(now, 'seconds')
                setDifference(diff)
            },500)
            return () => {
                clearTimeout(timer)
            }
        }
    },[allowChanges, bankslip.manual_analysis_limit_date])

    useEffect(() => {
        if (difference === null) {
        return      
        }
        else if (difference < 0){
        setDisableChange(true)
        }
        else {
        const timer = setTimeout(() => {
            setDisableChange(true)
        },difference*1000)
        return () => {
            clearTimeout(timer)
        }
        }
    },[difference])

    const handleApprovedOpenClick = () => {
        setIsApproved(true)
        setOpenDialog(true)
    };

    const handleReprovedOpenClick = () => {
        setIsApproved(false)
        setOpenDialog(true)
    };

    const handleDialogClose = () => {
        setOpenDialog(false)
        setTimeout(function (){setIsApproved(null)}, 300)
    };

    let observation = null
    let analyst = null
    let event_date = null
    if (!allowChanges){
        let last_event = bankslip.analysis_events == null || bankslip.analysis_events.length ===0? null :
            bankslip.analysis_events[bankslip.analysis_events.length-1]
        if (last_event){
            observation = last_event.observation
            analyst     = last_event.analyst_name
            event_date  = last_event.event_date
        }
    }

    return (
        <div className="analysisCard">
            <div className={["blueText", "subtitleSize"].join(" ")}>Observação</div>
            {(allowChanges && !disableChange) ? 
            <div>
                <ObservationTextField content={observationBox} setContent= {setObservationBox} service="bankslip"/>
                <div style={{display:"flex", marginTop: "10px"}}>
                    <div 
                        className={["button", "approved", "normalText", "normalMediumSize"].join(" ")} 
                        style={{color:"#141414", marginLeft: "auto"}}
                        onClick={handleApprovedOpenClick}
                    >
                        APROVAR 
                    </div>
                    <div 
                        className={["button", "reproved", "normalText", "normalMediumSize"].join(" ")} 
                        style={{color:"#141414", marginRight: "10px"}}
                        onClick={handleReprovedOpenClick}
                    >
                        REPROVAR 
                    </div>
                </div>
                <ConfirmationDialog
                    open={openDialog}
                    isApproved={isApproved}
                    observation={observationBox}
                    onClose={handleDialogClose}
                    bankslip={bankslip}
                />
            </div> 
            : 
            <div style={{display:"flex", flexDirection:"column"}}>
                <div style={{display: "flex", flexGrow:"1", marginTop: "20px", marginBottom: "13px", marginLeft:"0px", lineHeight: "29px", fontSize: "22px", fontWeight:"500"}}>
                    {allowChanges ? disableChange ? 
                        "Aprovado automaticamente devido ao esgotamento do tempo" : "-" : 
                        observation == null ? ("Nenhum comentário") : ("\"" + observation + "\"")
                    }
                </div>
                <div style={{display:"flex", flexGrow:"1", flexDirection:"row"}}>
                    <DataField
                        title= "Analista"
                        alt={"clockIcon Logo"}
                        label={allowChanges ? disableChange ? null : "-" :
                            analyst != null ?  
                            analyst : "-"}
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        margin="5px"
                        marginRight="40px"
                        display={"inline"}
                    />
                    <DataField
                        titleImage={clockIcon}
                        titleTooltipText="Data da Observação"
                        alt={"clockIcon Logo"}
                        label={allowChanges ? disableChange ? event_date ?  
                            moment(event_date).format("DD/MM/YYYY - HH:mm") : "-": "-" : moment(event_date).format("DD/MM/YYYY - HH:mm")}
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        margin="5px"
                        display={"inline"}
                    />
                </div>
            </div>
            }
        </div>
    )        
}

export default Observation;