import React, { useReducer, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { TextField } from "@mui/material";
import { CircularProgress } from '@material-ui/core';
import InputAdornment from '@mui/material/InputAdornment';
import ConfirmationContent from "./ConfirmationContent/ConfirmationContent";
import { decisionReducer } from "../../Utils/requestUtils";
import { justNumbers, stringFormatToMoney } from "../utils/utils"

function ChargebackDialog(props) {
    
    let {transaction, open, onClose, chargeback_type} = props

    const [dialogState, dispatchDialogState] = useReducer(
        decisionReducer,
        {isLoading: false, isError: false, finished:false}
    )

    const history = useHistory()

    const [partialAmount, setPartialAmountBox] = useState("0,00")
    const handlePartialAmountBoxChange = (event) => {
        if (event.target.value.length === 0) {
            setPartialAmountBox("0,00")
        }
        else {
            setPartialAmountBox(stringFormatToMoney(justNumbers(event.target.value)))
        }
    };

    const [responseCode, setResponseCodeBox] = useState("")
    const handleResponseCodeBoxChange = (event) => {
        if (event.target.value.length === 0) {
            setResponseCodeBox("")
        }
        else {
            setResponseCodeBox(justNumbers(event.target.value))
        }
    };

    const resetChargebackType = () => {
        setResponseCodeBox("")
        setPartialAmountBox("0,00")
        onClose()
    }

    const handleFinish = () => {
        dispatchDialogState({type:"send_request_init"})
        let payload = {}
        payload.transaction_status = chargeback_type
        if (chargeback_type==="partial_chargeback") {
            payload.partial_amount = Number(justNumbers(partialAmount))
        }
        if (responseCode!=="") {
            payload.response_code = String(responseCode)
        }

        setTimeout(function () {
            axios.put('/dash/card_issuance/transaction/' + transaction.transaction_key, payload).then(response => {
                dispatchDialogState({type:"send_request_success"})
            }).catch(error => { 
                if ((error.response || {}).status === 403) dispatchDialogState({type: "send_request_failure_403"})
                else if ((error.response || {}).status === 409) dispatchDialogState({type: "send_request_failure_409"})  
                else dispatchDialogState({type: "send_request_failure"})    
            });
        }, 1000);
    }

    if (dialogState.finished && dialogState.isLoading){
        return (
            <Dialog 
                open={open} 
                onClose={() => history.push("/transaction-inbox")} 
                keepMounted={false}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle style={{paddingBottom: "0px"}} className={["blueText", "normalMediumSize"].join(" ")}>Salvar histórico de fraude</DialogTitle>
                <DialogContent>
                    <div style={{display:"flex", width: "450px", height:"310px"}}>
                        <CircularProgress style={{margin:"auto"}} />
                    </div>
                </DialogContent>
            </Dialog>

        )
    }
    else if (dialogState.finished){
        let confirmationData = {    
            message: dialogState.message,
            success: !dialogState.isError
        }
        return (
            <Dialog 
                open={open} 
                onClose={() => history.push("/transaction-inbox")} 
                keepMounted={false}
                disableBackdropClick
            >
                <DialogTitle style={{paddingBottom: "0px"}} className={["blueText", "normalMediumSize"].join(" ")}>Confirmação de revisão</DialogTitle>
                <DialogContent>
                    <div style={{display:"flex", flexDirection:"column", width: "450px", height:"310px"}}>
                        <ConfirmationContent onClose={() => history.push("/transaction-inbox")} data={confirmationData} />
                    </div>
                </DialogContent>
            </Dialog>
        )
    }

    return (
        <Dialog
        open={open} 
        onClose={onClose}
        keepMounted={false}
        >
            {chargeback_type==="partial_chargeback"?
                <DialogTitle 
                    style={{paddingBottom: "0px", marginLeft:"10px", marginBottom:"20px"}} className={["blueText", "normalMediumSize"].join(" ")}>
                    Estorno Parcial
                </DialogTitle>
                :<DialogTitle 
                    style={{paddingBottom: "0px", marginLeft:"10px", marginBottom:"20px"}} className={["blueText", "normalMediumSize"].join(" ")}>
                    Estorno Completo
                </DialogTitle>}
            <div style={{display:"flex", justifyContent:"center", marginLeft:"20px", marginRight:"20px", marginBottom:"10px"}}>
                <TextField
                    error={(responseCode.length===1)}
                    id="outlined-number"
                    style={{display:"flex",justifyContent:"center", alignItems:"center", width: "318px", fontWeight:"600", textAlign:"center"}}
                    fullWidth
                    type = "text"
                    label="Código de Retorno"
                    value={responseCode}
                    onChange={handleResponseCodeBoxChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        maxLength: 2
                    }}
                ></TextField>
            </div>
            {chargeback_type==="partial_chargeback"?
                <div style={{display:"flex", justifyContent:"center", marginLeft:"20px", marginRight:"20px", marginBottom:"10px"}}>
                    <TextField
                        error={(partialAmount==="0,00")}
                        id="outlined-start-adornment-number"
                        style={{display:"flex",justifyContent:"center", alignItems:"center", width: "318px", fontWeight:"600", textAlign:"center"}}
                        fullWidth
                        type = "text"
                        label="Valor do Estorno Parcial"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                        }}
                        value={partialAmount}
                        onChange={handlePartialAmountBoxChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        required
                    ></TextField>
                </div>
                :null}
            {(chargeback_type==="partial_chargeback"&&partialAmount==="0,00")||(responseCode.length===1)?
                <div style={{display:"flex", justifyContent: "space-between", marginLeft:"20px", marginRight:"20px", marginBottom:"20px"}}>
                    <div
                        className={["button", "approved", "disabled", "normalText", "normalMediumSize"].join(" ")}
                        style={{display: "flex", justifyContent:"center", alignItems: "center", width: "150px", fontWeight:"600"}}
                        onClick={handleFinish}
                        >Salvar
                    </div>
                    <div
                        className={["button", "cancel", "normalText", "normalMediumSize"].join(" ")}
                        style={{display: "flex", justifyContent:"center", alignItems: "center", width: "150px", fontWeight:"600"}}
                        onClick={resetChargebackType}
                        >Cancelar
                    </div>
                </div>
                :
                <div style={{display:"flex", justifyContent: "space-between", marginLeft:"20px", marginRight:"20px", marginBottom:"20px"}}>
                    <div
                        className={["button", "approved", "normalText", "normalMediumSize"].join(" ")}
                        style={{display: "flex", justifyContent:"center", alignItems: "center", width: "150px", fontWeight:"600"}}
                        onClick={handleFinish}
                        >Salvar
                    </div>
                    <div
                        className={["button", "cancel", "normalText", "normalMediumSize"].join(" ")}
                        style={{display: "flex", justifyContent:"center", alignItems: "center", width: "150px", fontWeight:"600"}}
                        onClick={resetChargebackType}
                        >Cancelar
                    </div>
                </div>}
        </Dialog>
    )
}

export default ChargebackDialog;