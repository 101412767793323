import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ApproveDialog from './ApproveDialog/ApproveDialog';
import axios from 'axios';
import RequestConfirmationDialog from './RequestConfirmationDialog/RequestConfirmationDialog';
import moment from "moment"
import ObservationTextField from '../../../../components/common/ObservationTextField';


function ManualAnalysisBeforeRuleExecution (props) {

    const { credit_proposal_natural_person_key, credit_analysis, isManualAnalysisBeforeRule } = props;

    const { t } = useTranslation();
    
    const [openApproveDialog, setOpenApproveDialog] = useState(false)
    const [analystInput, setAnalystInput] = useState({})
    const [formSubmissionStatus, setFormSubmissionStatus] = useState(null);

    const [observationBox, setObservationBox] = useState("")

    const [loading, setLoading] = useState(false)
    
    const updateStatusRequest = useCallback((analysisStatus, observationBox) => {
        setLoading(true)
        const timer = setTimeout(() => {
            let payload = {
                "analysis_status": analysisStatus,
                "analyst_inputs": analystInput,
                "observation": observationBox
            }
            axios
            .put(`/dash/credit_analysis/natural_person/${credit_proposal_natural_person_key}`, payload)
            .then(() => {
                setFormSubmissionStatus({ success: true, message: t("success_message") });
                setOpenApproveDialog(false)
            }).catch(() => {
                setFormSubmissionStatus({ success: false, message: t("error_message") });
                setOpenApproveDialog(false)
                setLoading(false)
            });
        }, 500);
        return () => {
            clearTimeout(timer);
        };
    },[analystInput, setFormSubmissionStatus, t, setOpenApproveDialog, credit_proposal_natural_person_key])

    const handleReproveRuleExecution = () => {
        updateStatusRequest("manually_reproved", observationBox)
    };

    const handleApproveRuleExecution = () => {
        setOpenApproveDialog(true)
    }

    const handleCloseApproveDialog = () => {
        setAnalystInput({})
        setOpenApproveDialog(false)
    }

    const handleCloseRequestConfirmationDialog = () => {
        setFormSubmissionStatus(null)
        props.getNaturalPersonByKey()
    }

    useEffect(()=>{
        if (!isManualAnalysisBeforeRule) {
            const events = (credit_analysis||{}).credit_proposal_natural_person_analysis_events
            const approvedEvent = events.filter((event)=>(event.new_status==="manually_approved_before_rule_execution"))[0]
            const { analyst_name, analyst_email, event_date, observation } = approvedEvent
            const day = (moment(event_date).format("DD/MM/YYYY")||null)
            const time = (moment(event_date).format("HH:mm")||null)
            let lastEventObservationText = `Análise para a execução da regra feita por ${analyst_name}, ${analyst_email} no dia ${day} às ${time}.`
            if (observation) {
                lastEventObservationText = lastEventObservationText + `\n${observation}`
                setObservationBox(lastEventObservationText)
            } else {
                setObservationBox(lastEventObservationText)
            }
        }
    },[credit_analysis,isManualAnalysisBeforeRule])

    return (
        <div className="analysisCard">
            <span className={["blueText", "subtitleSize"].join(" ")}>{isManualAnalysisBeforeRule?t("rule_execution_decision"):t("review")}</span>
            <div className="internalCardContainer">
                <ObservationTextField content={observationBox} setContent={setObservationBox} service="credit_analysis" disable={!isManualAnalysisBeforeRule} />
            </div>
            {isManualAnalysisBeforeRule&&
            <div style={{display: "flex", justifyContent: "flex-end", alignItems:"center"}}>
                <div 
                    className={["button", "reproved", "normalText", "normalMediumSize"].join(" ")} 
                    onClick={() => loading?null:handleReproveRuleExecution()}
                    style={{marginLeft:"auto"}}
                >
                    {t("reprove_rule_execution")}
                </div>
                <div 
                    className={["button", "approved", "normalText", "normalMediumSize"].join(" ")}
                    onClick={() => loading?null:handleApproveRuleExecution()}
                >
                    {t("approve_rule_execution")}
                </div>
                <ApproveDialog
                    open={openApproveDialog}
                    onClose={() => loading?null:handleCloseApproveDialog()}
                    setAnalystInput={setAnalystInput}
                    onSubmit={() => updateStatusRequest("manually_approved_before_rule_execution", observationBox)}
                    loading={loading}
                />
                {(formSubmissionStatus!==null)&&
                <RequestConfirmationDialog
                    open={formSubmissionStatus!==null?true:false}
                    onClose={()=>handleCloseRequestConfirmationDialog()}
                    formSubmissionStatus={formSubmissionStatus}
                />}
            </div>}
        </div>
    )

} 

export default ManualAnalysisBeforeRuleExecution;