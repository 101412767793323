import React, { useState } from "react";
import ArrowBack from "@material-ui/icons/ArrowBack";
import ArrowForward from "@material-ui/icons/ArrowForward";
import Tooltip from "@material-ui/core/Tooltip";

function NextTransaction (props){

 let { history, transaction_key, path, suffix, page_name } = props   

 const transactionsArray = JSON.parse(sessionStorage.getItem(`${suffix}sArray`)) || []

 const [indexState, setIndexState] = useState(transactionsArray.findIndex(transaction => transaction === transaction_key))

 const handleNextTransactionButtonClick = () => {
     let index = transactionsArray.findIndex(transaction => transaction === transaction_key)
     setIndexState(index+1)
     transaction_key = transactionsArray[index+1]
     history.push(path + "/" + transaction_key)
 }

 const handlePreviousTransactionButtonClick = () => {
     let index = transactionsArray.findIndex(transaction => transaction === transaction_key)
     setIndexState(index-1)
     transaction_key = transactionsArray[index-1]
     history.push(path + "/" + transaction_key)
 }

 return (
     <div>
        {transactionsArray.length ? 
        <div className="analysisCardContainer" style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}> 
            <div>
                {indexState !== 0 &&
                    <Tooltip title={<p className="tooltipText">Transação Anterior</p>}>
                    <div 
                        className={["circle", 'small'].join(" ")} 
                        style={{backgroundColor:  'rgb(211, 206, 206)', cursor: "pointer"}}
                        onClick={handlePreviousTransactionButtonClick}
                    >
                        <ArrowBack />
                    </div>
                    </Tooltip>}
            </div>
            <div>
                {indexState !== (transactionsArray.length - 1) &&
                    <Tooltip title={<p className="tooltipText">Próxima Transação</p>}>
                    <div 
                        className={["circle", 'small'].join(" ")} 
                        style={{backgroundColor:  'rgb(211, 206, 206)', cursor: "pointer", marginRight: "1px"}}
                        onClick={handleNextTransactionButtonClick}
                    >
                        <ArrowForward />
                    </div>
                    </Tooltip>}
                {indexState === (transactionsArray.length - 1) &&
                    <div 
                        className={["button", "onlyboarder", "normalMediumSize"].join(" ")}
                        style={{cursor: "pointer", marginRight: "1px", width:"150px"}}
                        onClick={() => history.push(`${path}s-inbox`)}
                    >
                        {"Voltar para " + page_name}
                    </div>
                }
            </div>
        </div> : null}
     </div>
 )
}

export default NextTransaction