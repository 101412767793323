import React, {useState, useReducer} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from "@material-ui/core/TextField";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { CircularProgress } from '@material-ui/core';
import {decisionReducer} from "../../../Utils/requestUtils"
import axios from 'axios'
import ConfirmationContent from "./ConfirmationContent"

const theme = createMuiTheme({
    overrides: { MuiInputBase: { root:{
        fontFamily: "Open Sans",
        fontSize: "14px",
        lineHeight: "19px",
        background: "#F7F7F7"

    }}},
    palette:{
        primary: {
            main:"#0b1f82"
        },
        secondary:{
            main:"#D91A1A"
        }
    }
  });


const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: "16px",
    color: "#0b1f82",
    padding: "16px 24px 0px 24px",
  },
  dialogContent: {
    width: "450px",
    height: "300px",
    marginBottom: "10px",
    float: "left",
  },
  textClass: {
	fontFamily: "Open Sans",
	fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "19px"
  }
}));

function CreateInfractionReportDialog(props) {
    const {transaction_key} = props

    const classes = useStyles();
    useTheme();

    const [reportType, setReportType] = useState("fraud")
    const handleChange = (e) => {
        setReportType(e.target.value)
    }
    
    const [detailsValue, setDetailsValue] = useState("")
    const [detailsError, setDetailsError] = useState(true)

    const handledetailsBoxChange = (event) => {
        if(event.target.value.length === 0)setDetailsError(true)
        else{
            setDetailsError(false)
        }
        setDetailsValue(event.target.value)
    };

    const [dialogState, dispatchDialogState] = useReducer(
        decisionReducer,
        {isLoading: false, isError: false, finished:false}
    )

    const handleDialogClose = () => {
        props.onClose()
        setTimeout(()=>{
            dispatchDialogState({type:"default_state"})
            setDetailsValue("")
            setDetailsError(true)
            setReportType("fraud")
        },300)
    }

    const handleSendReport = () =>{
        if (!detailsError) {
            dispatchDialogState({type:"send_request_init"})
            let payload={
                infraction_report_type: reportType,
                infraction_report_creator: "client",
                infraction_report_details: detailsValue
            }
            setTimeout(function () {
                axios.post('/dash/pix/transaction/' + transaction_key + "/infraction_report", payload).then(response => {
                    dispatchDialogState({type:"send_request_success"})
                }).catch(error => {  
                    if ((error.response || {}).status === 403) dispatchDialogState({type: "send_request_failure_403"})
                    else if ((error.response || {}).status === 409) dispatchDialogState({type: "send_request_failure_409"})  
                    else dispatchDialogState({type: "send_request_failure"})    
                });
            }, 1000);
        }       
    }
    if (dialogState.finished && dialogState.isLoading){
        return (
            <Dialog 
                open={props.open} 
                onClose={handleDialogClose} 
                keepMounted={false}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle style={{paddingBottom: "0px"}} className={["blueText", "normalMediumSize"].join(" ")}>Finalizar alerta</DialogTitle>
                <DialogContent>
                    <div style={{display:"flex", width: "310px", height:"217px"}}>
                        <CircularProgress style={{margin:"auto"}} />
                    </div>
                </DialogContent>
            </Dialog>
        )
    }
    else if (dialogState.finished){
        let confirmationData = {    
            message: dialogState.message,
            success: !dialogState.isError
        }
        return (
            <Dialog 
                open={props.open} 
                onClose={handleDialogClose} 
                keepMounted={false}
                disableBackdropClick
            >
                <DialogTitle style={{paddingBottom: "0px"}} className={["blueText", "normalMediumSize"].join(" ")}>Confirmação de Revisão</DialogTitle>
                <DialogContent>
                    <ConfirmationContent onClose={handleDialogClose} data={confirmationData} />
                </DialogContent>
            </Dialog>
        )

    }
    return (
        <Dialog 
        open={props.open} 
        onClose={handleDialogClose} 
        keepMounted={false}>
        <DialogTitle className={classes.dialogTitle}>Gerar um Relato</DialogTitle>
        <DialogContent className={classes.dialogContent}>
                <ThemeProvider theme={theme}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Tipo do Relato</FormLabel>
                        <RadioGroup color='primary' aria-label="reportType" name="reportType" value={reportType} onChange={handleChange}>
                            <FormControlLabel 
                                value="fraud" 
                                control={
                                    <Radio 
                                        className={classes.textClass} 
                                        color='primary'
                                    />} 
                                label="Fraude" 
                            />
                            <FormControlLabel 
                                value="compliance" 
                                control={
                                    <Radio 
                                        className={classes.textClass} 
                                        color='primary'
                                    />} 
                                label="Compliance" 
                            />
                        </RadioGroup>
                    </FormControl>
                    <TextField
                        fullWidth
                        multiline
                        error={detailsError}
                        label={detailsError ? "Obrigatório" : null}
                        color={detailsError ? "secondary" : "primary"}
                        variant="outlined"
                        margin="dense"
                        rows="5"
                        value={detailsValue}
                        onChange={handledetailsBoxChange}
                    />
                </ThemeProvider>
                <div className="internalCardContainer" style={{marginBottom: "0px", display:"flex", justifyContent:"center"}}>
                    <div 
                        className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                        onClick={handleSendReport}
                    >
                        Enviar Relato
                    </div>
                    <div 
                        className={["button", "cancel", "normalText", "normalMediumSize"].join(" ")}
                        onClick={handleDialogClose}
                    >
                        Cancelar 
                    </div>
                </div>
        </DialogContent>
        </Dialog>
    );
}

export default CreateInfractionReportDialog;
