import React from "react";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import Tooltip from "@material-ui/core/Tooltip";
import moment from 'moment'
import { getAnalysisStatusColor, getAnalysisStatusSiglum, getAnalysisStatus } from "../../utils/utils"
import {getKeyTypeIcon, getDictTypeIcon, getReasonDescription } from "../../utils/utilsDictOperation"
import {getAgencyInfo } from "../../utils/utils"
//import merchantGreyLogo from '../../../../assets/cardEntryIcons/MerchantIconGrey.svg';

function DictCard(props){

    let counter_part_account_object = (props.dict_operation.dict_operation_direction === 'claimer' ? 
                            props.dict_operation.source_account : 
                            props.dict_operation.destination_account)


    let [institution_name] = getAgencyInfo(counter_part_account_object)

    return (
        <Card className="listCard">
            <Link
                className={["listCardItem", "link", "subtitleSize", "breakWord", "bold"].join(" ")}
                style={{flex:"1 1 100px", textAlign:"center"}}
                to={"/pix-dict/" + props.dict_operation.dict_operation_key}
            >
                {props.dict_operation.id}
            </Link>
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                style={{flex:"1 1 45px", textAlign:"center"}}
            >
                {getDictTypeIcon(props.dict_operation.dict_operation_type)}
            </div>
            <div 
                className={["listCardItem", "normalText", "normalMediumSize", "bold"].join(" ")} 
                style={{flex:"1 1 45px", textAlign:"center", cursor: "default"}}  
            >
                {getKeyTypeIcon(props.dict_operation.dict_key.key_type, props.dict_operation.dict_key.key_value)}
            </div>
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                style={{flex:"1 1 100px", textAlign:"center"}}
            >
                {(props.dict_operation.client || {}).document_number || "-"}
            </div>
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                style={{flex:"2 1 110px", textAlign:"center"}}
            >
                {props.dict_operation.dict_operation_type !== "registration" ? 
                institution_name ? 
                <Tooltip title={<p className="tooltipText">{institution_name}</p>}>
                    <div style={{display:"flex", justifyContent:"center"}}>
                        {/* <img src={merchantGreyLogo} alt="name Logo" /> */}
                        <div className={["textOverflowInbox","centerText","labelSpace"].join(" ")}>{institution_name}</div>
                    </div>
                </Tooltip> : (counter_part_account_object || {}).participant : "Não Aplicável"}
            </div>
            <div 
                className={["listCardItem", "normalText", "normalMediumSize", "bold"].join(" ")} 
                style={{flex:"1 1 45px", textAlign:"center", cursor: "default"}}  
            >
                <Tooltip title={<p className="tooltipText" style={{textAlign: "center"}}>
                    {getAnalysisStatus(props.dict_operation.analysis_status)}
                    <br/>{getReasonDescription(props.dict_operation)}
                </p>}>
                <div className={["circle", "small"].join(" ")} style={{backgroundColor:getAnalysisStatusColor(props.dict_operation.analysis_status)}}>
                    {getAnalysisStatusSiglum(props.dict_operation.analysis_status)}
                </div>
                </Tooltip>
            </div>
            <div 
                className={["listCardItem", "normalText", "subtitleSize","noWrap"].join(" ")}
                style={{flex:"1 1 110px", textAlign:"center"}}
            >
                {moment(props.dict_operation.dict_operation_creation_date).format("DD/MM/YYYY HH:mm:ss")}
            </div>
        </Card>
    )
}

export default DictCard