import React, { useEffect, useReducer, useCallback, useContext} from "react";
import { dataFetchReducer,useStateConnectedToCache } from "../../Utils/requestUtils";
import { CircularProgress } from '@material-ui/core';
import { Link } from "react-router-dom";
import axios from "axios";
import RuleCard from "./RuleCard/RuleCard";
import Filter from "./Filter/Filter";
import ErrorBoundary from "../../Utils/ErrorBoundary"
import AuthContext from "../../../context/auth-context"
import {useHistory} from "react-router-dom"
import Pagination from "@material-ui/lab/Pagination";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useTranslation } from "react-i18next";

function RulesInbox(props) {
    let history = useHistory()
    const { t } = useTranslation();

    let user_data = useContext(AuthContext).user_data
    let roles = user_data.roles
    if (!roles.includes("rule_viewer")){
        history.push("")
    }
    
    let role_filters = []
    const managerRoles = {
        read_rules_onboarding_natural_person: ["onboarding_natural_person", "onboarding_natural_person_partner", "onboarding_natural_person_manual_analysis", "onboarding_natural_person_reanalysis"],
        read_rules_bankslip:["bankslip", "bankslip_alert", "bankslip_expiration"],
        read_rules_pix_limits: ["pix_recipient_list_append", "pix_recipient_list_limit_update_request", "pix_limit_update_request"],
        read_rules_bill_payment:["bill_payment", "bill_payment_alert", "bill_payment_expiration"],
        read_rules_card_order:["card_order_alert", "card_order_order"],
        read_rules_car_rental:["car_rental_rental_agreement", "car_rental_rental_agreement_expiration", "car_rental_rental_agreement_suggestion", "car_rental_reservation"],
        read_rules_credit_analysis_legal_person:["credit_analysis_legal_person", "credit_analysis_legal_person_manual_analysis"],
        read_rules_credit_analysis_natural_person:["credit_analysis_natural_person", "credit_analysis_natural_person_manual_analysis"],
        read_rules_currency_exchange:["currency_exchange_operation", "currency_exchange_operation_alert"],
        read_rules_onboarding_legal_person:["onboarding_legal_person", "onboarding_legal_person_manual_analysis", "onboarding_legal_person_partner"],
        read_rules_pix_dict_operation:["pix_dict_operation", "pix_dict_operation_alert", "pix_dict_operation_expiration"],
        read_rules_pix_transaction:["pix_transaction", "pix_transaction_alert", "pix_transaction_expiration"],
        read_rules_wire_transfer:["wire_transfer", "wire_transfer_alert", "wire_transfer_expiration"],
        read_rules_withdrawal:["withdrawal", "withdrawal_alert"]
    }

    for (const role of roles){
        if (role in managerRoles){
            role_filters = [
                ...role_filters,
                ...managerRoles[role]
            ]
        }
    }

    let local_storage_object_preffix = "ruleEngine"

    const [snackBarStatus, setSnackBarStatus] = React.useState({
        opened: false,
        severity: "success",
        message: ""
    });

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') return
        setSnackBarStatus({...snackBarStatus, opened: false});
    };

    const [pageParams, setPageParams] = useStateConnectedToCache(local_storage_object_preffix)
    const [pageState, dispatchPageState] = useReducer(
        dataFetchReducer,
        {fetchedData: null, isLoading: true, isError: false} 
    )

    const [company, dispatchCompany] = useReducer(
        dataFetchReducer,
        {fetchedData: null, isLoading: true, isError: false} 
    )

    const fetchRulesList = useCallback(
        (payload) => {
            dispatchPageState({type: "data_fetch_init"})  
            const timer = setTimeout(() => {
                axios.get('/dash/rule_engine/rules',{params: payload}).then( response => {
                    dispatchPageState({
                        type: "data_fetch_success",
                        payload: response.data
                    })
                }).catch(error => {
                    if ((error.response || {}).status === 403) dispatchPageState({type: "data_fetch_failure_403"})
                    else if ((error.response || {}).status === 404) dispatchPageState({type: "data_fetch_failure_404"})  
                    else dispatchPageState({type: "data_fetch_failure"})    
                })	
            }, 500);
            return () => {
                clearTimeout(timer)
            }	
        },[]
    )
        
    let fetchCompany = useCallback(
                () => {
                    dispatchCompany({type: "data_fetch_init"})  
                    if(user_data.business_group_key){
                        const timer = setTimeout(() => {
                            axios.get('/dash/rule_engine/companies').then( response => {
                                dispatchCompany({
                                    type: "data_fetch_success",
                                    payload: response.data
                                })
                            }).catch(error => {
                                if ((error.response || {}).status === 403) dispatchCompany({type: "data_fetch_failure_403"})
                                else if ((error.response || {}).status === 404) dispatchCompany({type: "data_fetch_failure_404"})  
                                else dispatchCompany({type: "data_fetch_failure"})    
                            })	
                        }, 500);
                        return () => {
                            clearTimeout(timer)
                        }
                    } 
                    else {
                        const timer = setTimeout(() => {
                            axios.get('/dash/rule_engine/company').then( response => {
                                dispatchCompany({
                                    type: "data_fetch_success",
                                    payload: response.data
                                })
                            }).catch(error => {
                                if ((error.response || {}).status === 403) dispatchCompany({type: "data_fetch_failure_403"})
                                else if ((error.response || {}).status === 404) dispatchCompany({type: "data_fetch_failure_404"})  
                                else dispatchCompany({type: "data_fetch_failure"})    
                            })	
                        }, 500);
                        return () => {
                            clearTimeout(timer)
                        }	
                    }

                }
                ,[user_data.business_group_key]
            )

    const handleUpdate = () => {
        let payload = {
            "page_number": pageParams.page - 1,
            "page_rows": 15
        }
        Object.keys(pageParams.filters).forEach((key,index) => {
            payload[key] = pageParams.filters[key]
        })
        fetchCompany()
        fetchRulesList(payload)
    }

    useEffect(() => {
        let payload = {
            "page_number": pageParams.page - 1,
            "page_rows": 15
        }
        Object.keys(pageParams.filters).forEach((key,index) => {
            payload[key] = pageParams.filters[key]
        })
        const timer_ = setTimeout(() => {
            fetchRulesList(payload)
            fetchCompany()
        }, 300);
        return () => {
            clearTimeout(timer_)
		}	
    },[fetchRulesList, fetchCompany, pageParams])
    
    const handleChangePage = (event, page, filters) => {
		if (pageState.page === page) {
			return
		}
		setPageParams({page: page, filters: filters})
	}

	const contentContainer = (ruleList, company) => {

		let nr_of_cards = ruleList['data'].length
		let cards = ruleList['data'].map((rule) => (
			<ErrorBoundary key={rule.rule_key}>
                <RuleCard 
                    rule={rule} 
                    handleUpdate={handleUpdate} 
                    setSnackBarStatus={setSnackBarStatus} 
                    company={company}
                    contextData={user_data}
                />
			</ErrorBoundary>
		))
        if (nr_of_cards === 0) {
			return (
			<div className='emptyContainer'>
				<p className={["blueText", "subtitleSize"].join(" ")}>
                    {t("Nenhuma regra configurada")}
				</p>
			</div>
			)
		}

		else {
            let gridTemplateColumns = ""
            if (user_data.business_group_key && roles.includes("multiple_environment_viewer")) {
              gridTemplateColumns = "12% 14% 15% 14% 9% 11% 11% 6% 4% 4%"
            } else if (user_data.business_group_key && !roles.includes("multiple_environment_viewer")){
              gridTemplateColumns = "15% 14% 18% 14% 9% 11% 11% 4% 4%"
            } else if (!user_data.business_group_key && roles.includes("multiple_environment_viewer")){
              gridTemplateColumns = "15% 23% 12% 10% 12% 12% 6% 5% 5%"
            } else if (!user_data.business_group_key && !roles.includes("multiple_environment_viewer")) {
              gridTemplateColumns = "18% 23% 15% 10% 12% 12% 5% 5%"
            }
          
			return (
                <div className='cardContainer'>
                    <div className="listCardHeader" style={{display: "grid", gridTemplateColumns: gridTemplateColumns}}>
                        <div 
                            className={["listCardItem", "normalText", "normalMediumSize"].join(" ")}
                        >
                            {t("Evento")}
                        </div>
                        {user_data.business_group_key&&<div 
                            className={["listCardItem", "normalText", "normalMediumSize"].join(" ")}
                        >
                            {t("client")}

                        </div>}
                        <div 
                            className={["listCardItem", "normalText", "normalMediumSize"].join(" ")}
                        >
                            {t("Nome")}
                        </div>
                        <div 
                            className={["listCardItem", "normalText", "normalMediumSize"].join(" ")}
                        >
                            {t("Data da Última Versão")}
                        </div>
                        <div 
                            className={["listCardItem", "normalText", "normalMediumSize"].join(" ")}
                        >
                            Status
                        </div>
                        <div 
                            className={["listCardItem", "normalText", "normalMediumSize"].join(" ")}
                        >
                            {t("Data da Criação")}
                        </div>
                        <div 
                            className={["listCardItem", "normalText", "normalMediumSize"].join(" ")}
                        >
                            {t("rule_draft")}
                        </div>
                        {roles.includes("multiple_environment_viewer") ? <div 
                            className={["listCardItem", "normalText", "normalMediumSize"].join(" ")}
                        >
                            Região
                        </div>: null}
                        <div 
                            className={["listCardItem", "normalText", "normalMediumSize"].join(" ")}
                        >
                        </div>

                        <div 
                            className={["listCardItem", "normalText", "normalMediumSize"].join(" ")}
                        >
                        </div>
                    </div>
                    {cards}
                </div>
            )
		}
    }  
    if (pageState.fetchedData && company.fetchedData) {
		return (
			<div style={{ width: "auto", paddingBottom: "30px" }}>
				<div style={{display:"flex"}}>
                    <div>
                        <p className={["blueText", "titleSize"].join(" ")}>{t("MOTOR DE REGRAS")}</p>
                    </div>
                    <div style={{display:"flex", margin: "auto 50px auto auto", flexDirection:"row"}}>
                    <Link
                        style={{ padding: "5px 30px", textDecoration:"none" }}
                        className={["button", "link", "standard", "normalText", "normalMediumSize"].join(" ")}
                        to={"/rule/new_rule/1"}
                    >
                        {t("CRIAR NOVA REGRA")}
                    </Link>
                    </div>
                </div>		
				<ErrorBoundary doNothing>
                    <Filter 
                        local_storage_object_preffix={local_storage_object_preffix} 
                        setPageParams={setPageParams}
                        company={company.fetchedData}
                        role_filters={role_filters}
                    />
                    </ErrorBoundary>
				<div className="cardList">{contentContainer(pageState.fetchedData, company.fetchedData)}</div>
                <Pagination 
                    className="pagination" 
                    page={pageParams.page} 
                    count={parseInt(pageState.fetchedData["number_of_pages"])}
                    onChange={(e, page) => handleChangePage(e, page, pageParams.filters)}
                />
                <Snackbar
                    open={snackBarStatus["opened"]}
                    autoHideDuration={10000}
                    onClose={handleCloseSnackBar}
                >
                    <MuiAlert
                        elevation={6}
                        variant="filled"
                        onClose={handleCloseSnackBar}
                        severity={snackBarStatus["severity"]}
                    >
                        <div className={["normalText", "subtitleSize", "bold"].join(" ")}>
                            {t(snackBarStatus["message"])}
                        </div>
                    </MuiAlert>
                </Snackbar>
			</div>
		);
	}
	else if (pageState.isError || company.isError){
		return (
			<div style={{ width: "auto" }}>
				<div style={{display:"flex"}}>
					<div >
						<p className={["blueText", "titleSize"].join(" ")}>{t("MOTOR DE REGRAS")}</p>
					</div>		
				</div>			
				<ErrorBoundary doNothing>
                    <Filter local_storage_object_preffix={local_storage_object_preffix} setPageParams={setPageParams} />
                    <Filter 
                        local_storage_object_preffix={local_storage_object_preffix} 
                        setPageParams={setPageParams}
                        company={company.fetchedData} />
                </ErrorBoundary>
                <div style={{height: "55vh", display:"flex"}}>
                    <p className={["blueText", "titleSize"].join(" ")} style={{margin:"auto", textAlign:"center"}}>
                        {pageState.errorMessage || company.errorMessage}
                    </p>
                </div>
			</div>
		)
	}
	else if (pageState.isLoading || company.isLoading) {
		return(
			<div style={{ width: "auto" }}>
				<p className={["blueText", "titleSize"].join(" ")}>{t("MOTOR DE REGRAS")}</p>
				<div className='circularProgressCenter'>
					<CircularProgress />
				</div>
			</div>
		)
    }
    else return null
	
}

export default RulesInbox