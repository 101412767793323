import React, { useEffect, useState } from "react"
import axios from "axios"
import { CircularProgress } from '@material-ui/core'
import DataField from "../../../utils/DataField"

function PartnershipCard(props) {
    const { partnership_key } = props

    const [partnershipData, setPartnershipData] = useState(null)

    useEffect(() => {
        axios.get('/dash/onboarding/legal_person/' + partnership_key).then(response => {
            setPartnershipData(response.data)
        }).catch(error => {
            setPartnershipData({})
        })
    }, [partnership_key])

    return (
        <div className="analysisCard">
            <div className={["blueText", "subtitleSize"].join(" ")}>Sócio da Pessoa Jurídica</div>
            <div className="internalCardContainer" style={{ display: "flex", flexDirection: "column" }}>
                {partnershipData ?
                    <>
                        <DataField
                            title={"Nome da Empresa"}
                            label={partnershipData.legal_name}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="10px"
                        />
                        <DataField
                            title={"CNPJ"}
                            label={partnershipData.document_number}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            labelHyperlink={"/legal-person/" + partnership_key}
                            margin="10px"
                        />
                    </>
                    : <CircularProgress style={{ margin: "auto" }} />}

            </div>
        </div>
    )
}

export default PartnershipCard
