import React from "react";

function CardListHeader(props){
    return (
        <div className="listCardHeader">
            <div 
                className={["listCardItem", "normalText"].join(" ")}
                style={{flex:"1 1 100px", textAlign:"center"}}
            >
                ID da DICT
            </div>
            <div 
                className={["listCardItem", "normalText"].join(" ")}
                style={{flex:"1 1 45px", textAlign:"center"}}
            >
                Tipo da Operação
            </div>
            <div 
                className={["listCardItem", "normalText"].join(" ")}
                style={{flex:"1 1 45px", textAlign:"center"}}
            >
                Chave da DICT
            </div>
            <div 
                className={["listCardItem", "normalText"].join(" ")}
                style={{flex:"1 1 100px", textAlign:"center"}}
            >
                Documento do Cliente
            </div>
            <div 
                className={["listCardItem", "normalText"].join(" ")}
                style={{flex:"2 1 110px", textAlign:"center"}}
            >
                Contraparte
            </div>
            <div 
                className={["listCardItem", "normalText"].join(" ")}
                style={{flex:"1 1 45px", textAlign:"center"}}
            >
                Status de Fraude
            </div>
            <div 
                className={["listCardItem", "normalText"].join(" ")}
                style={{flex:"1 1 110px", textAlign:"center"}}
            >
                Data da Operação
            </div>
        </div>
    )
}

export default CardListHeader