import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import EnvolvedPartiesTable from "./EnvolvedPartiesTable/EnvolvedPartiesTable"

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: "14px",
    color: "#FF0000",
    textAlign: "center",
  },
  dialogTitle: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: "15px",
    color: "#0b1f82",
  },
  courtContainer: {
    height: "200px",
    display: "table-cell",
    verticalAlign: "middle",
    textAlign: "center",
    minWidth: "300px"
  },
  disabledLabel: {
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "19px",
    color: "#999",
  },
  dialogContent: {
      marginBottom: "20px"
  }
}));

const PartiesDialog = (props) => {
  const classes = useStyles();
  useTheme();

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      keepMounted={false}
    >
      <DialogTitle className={classes.dialogTitle}>
       Pessoas Envolvidas
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <EnvolvedPartiesTable parties={props.processes_data[props.index].parties}/>
      </DialogContent>
    </Dialog>
  );
};

export default PartiesDialog;
