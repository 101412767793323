import React from 'react'
import DataField from "../../utils/DataField"
import {formatAsMoney} from "../../utils/utils"
import {getTransactionStatusColor,getTransactionStatusText, getCaptureType} from "../../utils/utilsTransaction"
import moment from 'moment'

function TransactionCard(props) {

    return (
        <div className="analysisCard">
            <div className={["blueText", "subtitleSize"].join(" ")}>Transação</div>
            <div className="internalCardContainer"  style={{display: "flex", flexDirection: "column"}}>
                <div className="internalCardContainer">
                    <div className={["labelText", "normalMediumSize", "bold"].join(" ")}>
                        Valor da Transação
                    </div>
                    <div className={["normalText", "moneySize", "bold"].join(" ")}>
                        {formatAsMoney(props.transaction.amount, "-")}
                    </div>
                </div>
                <DataField
                    title={"Data e Hora:"}
                    label={moment(props.transaction.transaction_date).format("DD/MM/YYYY HH:mm:ss")}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                />
                <DataField
                    title={"Situação:"}
                    label={getTransactionStatusText(props.transaction.transaction_status)}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    labelClasses={["bold"]}
                    labelStyles={{color:getTransactionStatusColor(props.transaction.transaction_status)}}
                />
                <DataField
                    title={"Método de Captura:"}
                    label={getCaptureType(props.transaction.capture_method)}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                />
            </div>
        </div>
    )
}

export default TransactionCard