import React from "react";
import { getCardIcon } from "../../../utils/utils"

function SintegraCard(props) {
    const {sintegra} = props

    return (
        <div className="analysisCard">
            <div style={{display:"flex"}}>
                <div 
                    className={["blueText", "subtitleSize", "bold"].join(" ")}
                    style={{margin:"auto 0px"}}
                >
                    Sintegra
                </div>
                <div style={{margin:"auto 10px"}}>
                    {getCardIcon(sintegra.flag)}
                </div>
            </div>
        </div>
        );
}


export default SintegraCard