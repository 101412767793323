import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {getTableRowClass, titleCase} from "../../../utils/utils";
import ProductsDialog from "../ProductsDialog/ProductsDialog";
import getSymbolFromCurrency from 'currency-symbol-map';

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
  },
  blueText: {
    fontFamily: "Open Sans",
    fontWeight: "600",
    color: "#0b1f82"
},
  tableText: {
    fontFamily: "Open Sans",
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "center",
    margin: "10px",
    padding: "6px",
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: '#6F6F6F',
    fontSize: "14px",
    lineHeight: "19px",
    padding: "6px",
  }
}));

function ProductTable(props) {
  const classes = useStyles();
  
  useTheme();

  const currency = props.order.payment.currency

  const [dialogOpen, setDialogOpen] = React.useState(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <Paper className={classes.tableStyle}>
      <Table style={{ padding: "8px" }}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader}>Produto</TableCell>
            <TableCell className={classes.tableHeader}>Quantidade</TableCell>
            <TableCell className={classes.tableHeader}>Valor Unitário</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.order.products.map((product,index) => (
            <TableRow
              key={index}
              className={getTableRowClass(index)}
            > 
              <TableCell className={[classes.tableText, "breakWord"].join(" ")}>
                  {titleCase(product.name,"-")}
              </TableCell>
              <TableCell className={classes.tableText}>
                {product.quantity == null ? "-" : product.quantity}    
              </TableCell>
              <TableCell className={classes.tableText}>
                {product.unit_cost == null ? "-" : getSymbolFromCurrency(currency) + " " + (product.unit_cost/100).toLocaleString('de-DE', {minimumFractionDigits: 2})}
              </TableCell>  
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {props.order.products.length > 4 ?
      <div 
        className={["blueText", "normalMediumSize", "link"].join(" ")} 
        style={{marginLeft:"auto", marginTop:"16px", textAlign:"right"}}
        onClick={handleDialogOpen}
      >
        Ver mais
      </div> : null}
      <ProductsDialog
          open={dialogOpen}
          onClose={handleDialogClose}
          order={props.order}
      />
    </Paper>
  );
}

export default ProductTable;
