import React, { useState } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { getEventGroupName } from "../../utils/utilsAlert"

const theme = createMuiTheme({
    overrides: { MuiInputBase: { input:{ padding: '6px 0px 3px 5px'}}}
  });  

function EventGroupFilter(props) {

    const [currentValue, setCurrentValue] = useState(props.initialGroup)

    return(
        <div className="filterBoxContainer normalSmallSize">
            <div className="filterBox">
            <ThemeProvider theme={theme}>
                <Select 
                    className="selectedFilter normalSmallSize"
                    displayEmpty
                    renderValue={currentValue===undefined?() => "Grupo de Eventos":() => getEventGroupName(currentValue)}
                    value={currentValue}
                    onChange={(e)=> {
                        props.handleFilterGroupChange(e)
                        setCurrentValue(e.target.value)
                    }}
                >
                    {props.filterGroupOptions.map(
                        eventGroup => 
                            (
                                <MenuItem key={eventGroup} value={eventGroup}>{getEventGroupName(eventGroup)}</MenuItem>
                            )
                    )}
                </Select>
            </ThemeProvider> 
            </div>
        </div>
    )
}

export default EventGroupFilter;