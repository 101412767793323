import React, { useState, useReducer } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";
import axios from "axios"
import { CircularProgress } from '@material-ui/core';
import ConfirmationContent from "./ConfirmationContent"
import Checkbox from '@material-ui/core/Checkbox';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import {formatAsMoney, getIncreasePriceValue, upgradeStatusTranslation} from "../../../utils/utils"
import {decisionReducer} from "../../../../Utils/requestUtils"

const theme = createMuiTheme({
    palette:{
        primary: {
            main:"#0b1f82"
        },
    }
  });

function ConfirmationDialog(props) {
    const history = useHistory()

    const {upgradeStatus} = props

    let is_upgrade_status_reviewed = !!upgradeStatus

    const [dialogState, dispatchDialogState] = useReducer(
        decisionReducer,
        {isLoading: false, isError: false, finished:false}
    )
        
    let rule_pre_authorization_amount = (((props.ra.fraud_status_events || [{}])[0]).decision_metadata || {}).pre_authorization_amount
    let is_rule_pre_authorization_amount_defined = !(rule_pre_authorization_amount === null || rule_pre_authorization_amount === undefined)

    const [increaseCheck, setIncreaseCheck] = useState(is_rule_pre_authorization_amount_defined)

    let new_pre_authorization_amount = getIncreasePriceValue(props.ra)
    
    let final_pre_authorization_amount = null
    if (!rule_pre_authorization_amount && new_pre_authorization_amount){
        final_pre_authorization_amount = new_pre_authorization_amount
    }else if (rule_pre_authorization_amount) {
        final_pre_authorization_amount = rule_pre_authorization_amount
    }
  
    const handleFinish = (fraud_status) =>{
        dispatchDialogState({type:"send_request_init"})
        let payload={
            fraud_status: fraud_status,
        }
        if (props.data != null){
            payload ={...payload, observation: props.data}
        }
        if (increaseCheck){
            payload ={...payload, pre_authorization_amount: final_pre_authorization_amount}
        }
        if(is_upgrade_status_reviewed){
            payload ={...payload, upgrade_status: upgradeStatus}
        }
        setTimeout(function () {
            axios.put('/dash/car_rental/rental_agreement/' + props.ra.rental_agreement_key, payload).then(response => {
                dispatchDialogState({type:"send_request_success"})
              }).catch(error => {
                if ((error.response || {}).status === 403) dispatchDialogState({type: "send_request_failure_403"})
                else if ((error.response || {}).status === 409) dispatchDialogState({type: "send_request_failure_409"})  
                else dispatchDialogState({type: "send_request_failure"})    
              });
        }, 1000);

    }

    if (dialogState.finished && dialogState.isLoading){
        return (
            <Dialog 
                open={props.open} 
                onClose={() => history.push("/rental-agreements-inbox")} 
                keepMounted={false}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle style={{paddingBottom: "0px"}} className={["blueText", "normalMediumSize"].join(" ")}>Finalizar alerta</DialogTitle>
                <DialogContent>
                    <div style={{display:"flex", width: "310px", height:"217px"}}>
                        <CircularProgress style={{margin:"auto"}} />
                    </div>
                </DialogContent>
            </Dialog>

        )
    }
    else if (dialogState.finished){
        let confirmationData = {    
            message: dialogState.message,
            success: !dialogState.isError
        }
        return (
            <Dialog 
                open={props.open} 
                onClose={() => history.push("/rental-agreements-inbox")} 
                keepMounted={false}
                disableBackdropClick
            >
                <DialogTitle style={{paddingBottom: "0px"}} className={["blueText", "normalMediumSize"].join(" ")}>Confirmação de Revisão</DialogTitle>
                <DialogContent>
                    <ConfirmationContent onClose={() => history.push("/rental-agreements-inbox")} data={confirmationData} />
                </DialogContent>
            </Dialog>
        )

    }
    return (
        <Dialog 
        open={props.open} 
        onClose={props.onClose} 
        keepMounted={false}
        >
            <DialogTitle style={{paddingBottom: "0px"}} className={["blueText", "normalMediumSize"].join(" ")}>Finalizar alerta</DialogTitle>
            <DialogContent>
                <div>
                    <div>                            
                        <TextField
                            fullWidth
                            multiline
                            variant="outlined"
                            label="Observação"
                            margin="dense"
                            defaultValue={props.data ? props.data : "Sem Observação"}
                            disabled={true}
                        />
                    </div>
                    {!!upgradeStatus &&
                        <div>
                            <TextField
                                fullWidth
                                multiline
                                label="Upgrade Status"
                                variant="outlined"
                                margin="dense"
                                defaultValue={upgradeStatusTranslation(upgradeStatus)}
                                disabled={true}
                            />
                        </div>
                    }
                    {props.isApproved ? final_pre_authorization_amount ?
                    <div className='internalCardContainer'>
                        <div style={{display:"flex", justifyContent: "center"}}>
                            <ThemeProvider theme={theme}>
                            <Checkbox
                                onChange={e => (is_rule_pre_authorization_amount_defined ? null : setIncreaseCheck(e.target.checked))}
                                checked={increaseCheck}
                                color="primary"
                            />
                            </ThemeProvider>
                            <div>
                                <p 
                                    className={["normalText", "normalMediumSize"].join(" ")} 
                                >   
                                    Majorar Pré Autorização
                                </p>
                            </div>
                        </div>
                        <div 
                            className={increaseCheck ? ["normalText", "normalMediumSize"].join(" ") : 
                                ["labelText", "normalMediumSize"].join(" ")}
                            style={{textAlign:"center"}}
                        >
                            {formatAsMoney(final_pre_authorization_amount, null)}
                        </div>
                    </div> : null : null                
                    }
                    <div className="internalCardContainer" style={{display:"flex", justifyContent:"center"}}>
                        {props.isApproved === true ?
                        <div 
                            className={["button", "approved", "normalText", "normalMediumSize"].join(" ")}
                            onClick={() => handleFinish("manually_approved")}
                        >
                            Aprovar
                        </div> :
                        props.isApproved === false ?
                        <div 
                            className={["button", "reproved", "normalText", "normalMediumSize"].join(" ")}
                            onClick={() => handleFinish("manually_reproved")}
                        >
                            Reprovar
                        </div> : null
                        }
                        <div 
                            className={["button", "cancel", "normalText", "normalMediumSize"].join(" ")}
                            onClick={props.onClose}
                        >
                            Cancelar 
                        </div>
                  </div>
              </div>
          </DialogContent>
        </Dialog>
    )
}

export default ConfirmationDialog;