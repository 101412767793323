import React from "react";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import {checkNested} from "../../utils/utils"
import {getSeverityColor,getSeverityStatus,getSeverityStatusSiglum,getAlertStatusColor,getAlertStatusText, getAlertStatusTextSiglum} from "../../utils/utilsAlert"
import moment from 'moment'
import merchantGreyLogo from '../../../../assets/cardEntryIcons/MerchantIconGrey.svg';
import peopleGreyLogo from '../../../../assets/cardEntryIcons/peopleGrey.svg';
import Tooltip from "@material-ui/core/Tooltip";

function AlertCard(props){
    return (
        <Card className="listCard">
            <Link
                className={["listCardItem", "link", "subtitleSize", "breakWord", "bold"].join(" ")}
                style={{flex:"2 1 180px", textAlign:"center"}}
                to={"/card-order-alert/" + props.alert.alert_key}
            >
                {props.alert.link_key_type === "customer" ? 
                checkNested(props.alert.link_key_data, "customer", "name") ? props.alert.link_key_data.customer.name : 
                "-" :
                checkNested(props.alert.link_key_data, "seller", "name") ? props.alert.link_key_data.seller.name :
                "-"}
            </Link>
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                style={{flex:"2 1 45px", textAlign:"center"}}
            >
                {props.alert.link_key_type === "customer" ?
                    <Tooltip title={<p className="tooltipText">{"Comprador"}</p>}>
                        <img src={peopleGreyLogo} alt="Comprador" />
                    </Tooltip> :
                    <Tooltip title={<p className="tooltipText">{"Vendedor"}</p>}>
                        <img style= {{width:"20px"}} src={merchantGreyLogo} alt="Vendedor" />
                    </Tooltip>}
            </div>            
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                style={{flex:"2 1 45px", textAlign:"center"}}
            >
                <div className="labelSpace">
                    {props.alert.link_key_type === "customer" ? props.alert.customer_document_number : props.alert.seller_document_number}
                </div>
            </div>
            <div 
                className={["listCardItem", "normalText", "normalMediumSize", "bold"].join(" ")} 
                style={{flex:"1 1 45px", textAlign:"center", cursor: "default"}}  
            >
                <Tooltip title={<p className="tooltipText">{"Severidade do alerta: " + getSeverityStatus(props.alert.risk_level)}</p>}>
                    <div className={["circle", "small"].join(" ")} style={{backgroundColor:getSeverityColor(props.alert.risk_level)}}>
                        {getSeverityStatusSiglum(props.alert.risk_level)}
                    </div>
                </Tooltip>
            </div>
            <Tooltip title={<p className="tooltipText">Quantidade de gatilhos no alerta</p>}>
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                style={{flex:"1 1 80px", textAlign:"center"}}
            >
                <div className="triggerNumberStyle">
                    {props.alert.triggers == null || props.alert.triggers.length === 0 ? null : props.alert.triggers.length}
                </div>               
            </div>
            </Tooltip>
            <div 
                className={["listCardItem", "normalText", "normalMediumSize", "bold"].join(" ")} 
                style={{flex:"1 1 45px", textAlign:"center", cursor: "default"}}  
            >
                <Tooltip title={<p className="tooltipText">{getAlertStatusText(props.alert.alert_status)}</p>}>
                    <div className={["circle", "small"].join(" ")} style={{backgroundColor:getAlertStatusColor(props.alert.alert_status)}}>
                        {getAlertStatusTextSiglum(props.alert.alert_status)}
                    </div>
                </Tooltip>
            </div>
            <div 
                className={["listCardItem", "normalText", "subtitleSize","noWrap"].join(" ")}
                style={{flex:"1 1 110px", textAlign:"center"}}
            >
                {moment(props.alert.occurrence_date).format("DD/MM/YYYY HH:mm:ss")}
            </div>
        </Card>
    )
}

export default AlertCard