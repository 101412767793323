import React from "react"

function DataField(props) {

    let displayType = "flex"
    let marginSize = "5px"
    let titleClasses = ["normalText", props.size]
    let labelClasses = ["labelText", props.size, "labelSpace"]
    let labelStyles = null

    if (props.titleNoWrap) {
        titleClasses.push("noWrap")
    }
    if (props.labelNoWrap) {
        labelClasses.push("noWrap")
    }

    if (props.disabled) {
        titleClasses.push("disabled")
        labelClasses.push("disabled")
    }

    if (props.margin) {
        marginSize = props.margin
    }

    if (props.display) {
        displayType = props.display
    }

    if (props.labelStyles) {
        labelStyles = props.labelStyles
    }



    return (
        <div style={{ display: displayType, marginTop: marginSize, marginBottom: marginSize }}>
            <span style={{ margin: "auto 0px" }} className={titleClasses.join(" ")}>
                {props.title}:
            </span>
            <span className={labelClasses.join(" ")} style={labelStyles}>
                {props.label}
            </span>
            {props.children}
        </div>
    )
}

export default DataField