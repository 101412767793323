import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import TextField from "@material-ui/core/TextField";
import moment from 'moment';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import AuthContext from "../../../../context/auth-context"
import {setAllowChanges} from "../../utils/utils"
import { Fragment } from "react";
import axios from "axios";

const theme = createMuiTheme({
    overrides: { MuiInputBase: { root:{
        fontFamily: "Open Sans",
        fontSize: "14px",
        lineHeight: "19px",

    }}},
    palette:{
        primary: {
            main:"#0b1f82"
        },
    }
  });

function Messages(props) {

  let roles = useContext(AuthContext).user_data.roles
  let allowChanges = setAllowChanges(props.rental_agreement.fraud_status, roles)

  let [retrievedFirstMessages, setRetrievedFirstMessages] = useState(false)
  let [retrievedMessages, setRetrievedMessages] = useState("")
  let [currentMessage, setCurrentMessage] = useState("")
  let [disableChange, setDisableChange] = useState(false)
  let [difference, setDifference] = useState(null)
  const rental_agreement_key = props.rental_agreement.rental_agreement_key

  if (retrievedFirstMessages === false) {
      setRetrievedFirstMessages(true)
    axios.get('/dash/car_rental/rental_agreement/' + rental_agreement_key + "/messages")
    .then( response => { setMessages(response) })
    .catch(error => {console.log(error)})
  }
  
  useEffect(() => {
        if(allowChanges){
            const timer = setTimeout(() => {
                const now = moment()
                const limit = moment(props.rental_agreement.manual_analysis_limit_date)
                const diff = limit.diff(now, 'seconds')
                setDifference(diff)
            },500)
            return () => {
                clearTimeout(timer)
            }
        }
    },[allowChanges, props.rental_agreement.manual_analysis_limit_date])

    const messageRetrieve = useCallback(
        () => {
            if((allowChanges && !disableChange)){
                setRetrievedFirstMessages(true)
                axios.get('/dash/car_rental/rental_agreement/' + rental_agreement_key + "/messages")
                .then( response => { setMessages(response) })
                .catch(error => {console.log(error)})
            }
        },[allowChanges, disableChange, rental_agreement_key]
    )

    const timerRef = useRef(null);
    useEffect(() => {		
        const startInterval = () => {
            timerRef.current = setInterval(() => {
                messageRetrieve();
            }, 5000);
        };

        document.addEventListener('visibilitychange', () => {
			if (document.visibilityState === 'visible') {
				startInterval()
			} else {
				clearInterval(timerRef.current)
			}
		});

        startInterval();

        return () => {			
            clearInterval(timerRef.current)
        }
	}, [messageRetrieve])

    useEffect(() => {
        if (difference === null) { return }
        else if (difference < 0){ setDisableChange(true) }
        else {
            const timer = setTimeout(() => { setDisableChange(true) },difference*1000)
            return () => {
                clearTimeout(timer)
            }
        }
    },[difference])

    function setMessages(messages){
        const messages_text = messages.data.map((message) => {return moment(message.created_at).format('HH:mm:ss') + " - " + message.analyst_name + ": " + message.message })
        const retrieved_messages = messages_text.join("\n")
        setRetrievedMessages(retrieved_messages)
    }

    const handleCurrentMessageChange = (event) => {
        if (event.target.value.length === 0){
            setCurrentMessage(null)
        }
        else{
            setCurrentMessage(event.target.value)
        }
    };

    const submitMessage = () => {
        const payload = {
            "message": currentMessage,
            "visible_to_store": true
        }
        
        axios.post('/dash/car_rental/rental_agreement/' + rental_agreement_key + "/message", payload)
            .then( response => {
                    setCurrentMessage("")
                    axios.get('/dash/car_rental/rental_agreement/' + rental_agreement_key + "/messages")
                        .then( response => { setMessages(response) })
                        .catch(error => {console.log(error)})
			})
            .catch(error => {console.log(error)})
    }
    
    const handleMessageKeypress = e => {
        if (e.key === "Enter") {
            submitMessage();
        }
    }

    const handleSubmitMessage = (event) => {
        submitMessage();
    }

    return (
        <div className="analysisCard">
            <div className={["blueText", "subtitleSize"].join(" ")}>Mensagens</div>
            <ThemeProvider theme={theme}>
                <TextField
                    fullWidth
                    multiline
                    color="primary"
                    variant="outlined"
                    margin="dense"
                    rows="5"
                    disabled= {true}
                    onChange={handleCurrentMessageChange}
                    defaultValue={retrievedMessages}
                />
            </ThemeProvider>
            {(allowChanges && !disableChange)? 
            <Fragment>
                <div>
                    <div style={{display:"flex"}}>
                        <ThemeProvider theme={theme}>
                            <TextField
                                fullWidth
                                color="primary"
                                variant="outlined"
                                margin="dense"
                                rows="1"
                                disabled= {!(allowChanges && !disableChange)}
                                onChange={handleCurrentMessageChange}
                                value={currentMessage}
                                onKeyPress={handleMessageKeypress}
                            />
                        </ThemeProvider>
                        <div 
                            className={["button", "approved", "normalText", "normalMediumSize"].join(" ")} 
                            style={{color:"#141414", marginTop: "10px", marginBottom: "10px"}}
                            onClick={handleSubmitMessage}
                        >
                            ENVIAR
                        </div>
                    </div>
                </div> 
            </Fragment> : null
            }
        </div>
    )        
}

export default Messages;