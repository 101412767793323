import React from "react";
import FilterBox from "../../../../Utils/FilterBox/FilterBox"
import i18next from "i18next";

function Filter(props) {
	
    const { setPageParams, filters, local_storage_object_preffix } = props

    const {t} = i18next

    let filterTypes = filters.filter(filter => filter.name !== 'final_date' && filter.name !=='csv_rows_limit')
   
    let filterObj = {}

    filterTypes.map(filter => {
        if(filter.name === 'initial_date'){
            filterObj['date'] = {
                type: filter.type,
                disabled: false,
                validation: false, 
                description: t("date")
            }
        }
        else if(filter.name === 'document_number'){
            filterObj[filter.name] = {
                type: filter.type,
                maskType: filter.maskType,
                disabled: false,
                validation: true, 
                validation_rule: {type: "equal", value: filter.maskType.length},
                description: t(filter.name)
            }
        } else if (filter.type === 'enum'){
            let enumList = filter.values.map(value => ([value, value]))
            console.log(enumList)

            filterObj[filter.name] = {
                type: filter.type,
                disabled: false,
                enumList: enumList,
                validation: false, 
                description: t(filter.name)
            }
        } else {
            filterObj[filter.name] = {
                type: filter.type,
                disabled: false,
                validation: false, 
                description: t(filter.name)
            }
        }
        return filter
    })

    return (
        <FilterBox 
            setPageParams={setPageParams}
            filterTypes={filterObj}
            local_storage_object_preffix={local_storage_object_preffix}
        />
    )

}

export default Filter