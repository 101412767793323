import React from "react";
import { Map, TileLayer, Marker } from "react-leaflet";
import L from "leaflet";


const markerIcon = new L.Icon({
    iconUrl: require("../../../../../assets/cardEntryIcons/MapMarker.svg"),
    iconSize: [25, 41],
    iconAnchor: [12, 41],
  });

function MerchantMap(props){

    if (props.coordinates === undefined){
        return (
            <div style={{display: "flex", height: "192px"}}>
                <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                    Informação Indisponível
                </div>
            </div>
        )
    }
    else {
        return (
            <Map
            className="ciTransactionMerchant"
            center={props.coordinates}
            zoom={14}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker
                position={props.coordinates}
                icon={markerIcon}
            />
          </Map>
        )
    }

}

export default MerchantMap