import styled from 'styled-components'

export const ReportButton = styled.div`
    padding: 8px 16px;
    margin: 8px;
    cursor: pointer;
    text-align: center;
    background-color: #0b1f82;
    color: #fff;
    border-radius: 24px;
    &:hover {
        background-color: #020F4D;
    }
    transition: .25s;
`;
