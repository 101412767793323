
export const buildInputSettings = (company_key, scr_input_settings) => {

  let input_settings = {
    "collect_additional_basic_data": {
      "client_since": false,
      "monthly_income": false,
      "monthly_revenue": false,
    },
    "use_scr": false,
    "collect_scr_consent": false,
    "collect_financial_data": {
      "credit_type": false,
      "credit_proposal_amount": false,
      "interest_type": false,
      "number_of_installments": false,
    }
  }

  const company_scr_settings = scr_input_settings.find(element => element.company_key === company_key).scr_settings

  if (company_scr_settings.use_scr) {
    input_settings['use_scr'] = true
  }

  let risel_company_key = ["789-789-789", "2255c314-4dd4-4b8c-bb6a-507fe19b3a49"]
  let smart_company_key = ["100-100-100", "17376ecd-4625-47fc-be03-664e9b939054", "f3aa6d04-a67a-4540-9ecb-0f476fa9d090"]

  if ( risel_company_key.some(company_key_item => company_key_item === company_key) ) {
    input_settings['collect_financial_data']['credit_type'] = true
    input_settings['collect_additional_basic_data']['client_since'] = true
  }

  if ( smart_company_key.some(company_key_item => company_key_item === company_key) ) {
    input_settings['collect_financial_data']['credit_type'] = true
    input_settings['collect_financial_data']['credit_proposal_amount'] = true
    input_settings['collect_financial_data']['interest_type'] = true
    input_settings['collect_financial_data']['number_of_installments'] = true
    input_settings['collect_additional_basic_data']['monthly_income'] = true
    input_settings['collect_additional_basic_data']['monthly_revenue'] = true
  }

  return input_settings
}

