import React from "react";
import FilterBox from "../../../Utils/FilterBox/FilterBox"
import {credit_enum_translations_function, analysis_status_type_enumList} from '../../utils/utils'
import { useTranslation } from "react-i18next";

function Filter(props) {
    
    const { t } = useTranslation();
	
    const { setPageParams, companiesList } = props

    const companies = (companiesList ||{}).companies

    const analysis_status_enum = credit_enum_translations_function(analysis_status_type_enumList)

    let filterTypes = {
        external_id: {
            type: "string",
            disabled: false,
            validation:false,
            description: t("External_id")
        },
        document_number: {
            type: "masked_string",
            maskType:"999.999.999-99",
            disabled: false,
            validation:true,
            validation_rule: {type: "equal", value:14},
            description: t("Documento")
        },
        status: {
            type: "enum",
            enumList: analysis_status_enum,
            disabled: false,
            validation:false,
            description: t("Status da Análise")
        },
        credit_proposal_date: {
            type: "date",
            disabled: false,
            validation:false,
            description: t("Data")
        },
    }

    if (companies){
        let enumList = companies.map(company =>{
            return [company.name, company.company_key]
        })
        filterTypes['company_key'] = {
            type: "enum",
            enumList: [
                ...enumList
            ],  
            disabled: false,
            validation:false,
            description: t("Cliente")
        }
    }



    return (
        <FilterBox 
            setPageParams={setPageParams}
            filterTypes={filterTypes}
            local_storage_object_preffix={props.local_storage_object_preffix}
        />
    )

}

export default Filter