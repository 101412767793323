import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
// import moment from 'moment'
import {getTableRowClass, checkNested,formatCard} from "../../../utils/utils"
import TransactionsDialog from "../TransactionsDialog/TransactionsDialog"
import {getTransactionStatusIcon, getTransactionStatusText, getPaymentType, getTransactionStatusColor} from "../../../utils/utilsOrder"
import Tooltip from "@material-ui/core/Tooltip";
import getSymbolFromCurrency from 'currency-symbol-map'

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
  },
  blueText: {
    fontFamily: "Open Sans",
    fontWeight: "600",
    color: "#0b1f82"
},
  tableText: {
    fontFamily: "Open Sans",
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "center",
    margin: "10px",
    padding: "6px",
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: '#6F6F6F',
    fontSize: "14px",
    lineHeight: "19px",
    padding: "6px",
  }
}));

function TransactionTable(props) {
  const classes = useStyles();
  
  useTheme();

  const [dialogOpen, setDialogOpen] = React.useState(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const currency = props.payment.currency

  return (
    <Paper className={classes.tableStyle}>
      <Table style={{ padding: "8px" }}>
        <TableHead>
          <TableRow>
            {/* <TableCell className={classes.tableHeader}>ID</TableCell>
            <TableCell className={classes.tableHeader}>Nome do Portador</TableCell> */}
            <TableCell className={classes.tableHeader}>Cartão</TableCell>
            {/* <TableCell className={classes.tableHeader}>Vencimento do Cartão</TableCell> */}
            <TableCell className={classes.tableHeader}>Valor</TableCell>
            <TableCell className={classes.tableHeader}>Parcelas</TableCell>
            <TableCell className={classes.tableHeader}>Modalidade</TableCell>
            <TableCell className={classes.tableHeader}>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.payment.transactions.map((transaction,index) => (
            <TableRow
              key={index}
              className={getTableRowClass(index)}
            > 
              {/* <TableCell className={[classes.tableText, "breakWord"].join(" ")}>
                  {transaction.id}
              </TableCell> */}
              {/* <TableCell className={classes.tableText}>
                {transaction.cardholder_name == null ? "-" : titleCase(transaction.cardholder_name, "-")}    
              </TableCell> */}
              <TableCell className={[classes.tableText, "breakWord"].join(" ")}> 
                {checkNested(transaction, "bin") & checkNested(transaction, "last_4") ? 
                formatCard(transaction.bin, transaction.last_4) : "-"}         
              </TableCell>
              {/* <TableCell className={classes.tableText}>
                {moment(transaction.expiration_date).format("YYYY/MM")}
              </TableCell> */}
              <TableCell className={classes.tableText}>
                {transaction.amount == null ? "-" : getSymbolFromCurrency(currency) + " " + (transaction.amount/100).toLocaleString('de-DE', {minimumFractionDigits: 2})}
              </TableCell>
              <TableCell className={[classes.tableText, "breakWord"].join(" ")}>
                {checkNested(transaction, "installments") ? transaction.installments : "1"}
              </TableCell>
              <TableCell className={classes.tableText}>
                {checkNested(transaction, "payment_type") ? getPaymentType(transaction.payment_type) : "-"}
              </TableCell>
              <TableCell className={classes.tableText}>
                <Tooltip title={<p className="tooltipText">{getTransactionStatusText(transaction.status)}</p>}>
                  <div className={["circle", "small"].join(" ")} style={{backgroundColor: getTransactionStatusColor(transaction.status)}}>
                      {getTransactionStatusIcon(transaction.status, "small", true)}
                  </div>
                </Tooltip>
            </TableCell>    
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div 
        className={["blueText", "normalMediumSize", "link"].join(" ")} 
        style={{marginLeft:"auto", marginTop:"16px", textAlign:"right"}}
        onClick={handleDialogOpen}
      >
        Ver mais
      </div>
      <TransactionsDialog
          open={dialogOpen}
          onClose={handleDialogClose}
          payment={props.payment}
      />
    </Paper>
  );
}

export default TransactionTable;
