import React from 'react'
import transactionIcon from '../../../../assets/cardEntryIcons/creditcard.svg';
import getSymbolFromCurrency from 'currency-symbol-map'
import TransactionsTable from "./TransactionsTable/TransactionsTable"
import {getCard, checkNested} from "../../utils/utils"
import {getPaymentType} from "../../utils/utilsOrder"
import DataField from "../../utils/DataField";



function PaymentCard(props) {
    return (
        <div className="analysisCard">
            <div style={{display: "flex"}}>
                <img className="cardTitleIconStyle" src={transactionIcon} alt="transactionIcon Logo" />
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>Pagamento</span>   
            </div>
            <div style={{marginRight:"30px"}} className="internalCardContainer">
                {props.order.payment.transactions.length === 1 ?
                <div className="internalCardContainer">
                    <div style={{display:"flex"}}>
                        {getCard(props.order.payment.transactions[0])}
                    </div>
                </div> : null}
                <div style={{display:"flex", flexDirection:"row", marginTop:"33px"}}>
                    <div style={{display:"flex", flexDirection:"column"}}>
                        <div className={["labelText", "normalMediumSize", "bold"].join(" ")}>
                            Valor Total da Transação
                        </div>
                        <div className={["normalText", "moneySize", "bold"].join(" ")}>
                            {props.order.payment.total_amount == null ? "-" : 
                            props.order.payment.shipping_amount == null ?
                            getSymbolFromCurrency(props.order.payment.currency) + " " + (props.order.payment.total_amount/100).toLocaleString('de-DE', {minimumFractionDigits: 2}) :
                            getSymbolFromCurrency(props.order.payment.currency) + " " + ((props.order.payment.total_amount + props.order.payment.shipping_amount)/100).toLocaleString('de-DE', {minimumFractionDigits: 2})}
                        </div>
                        <div className={["labelText", "normalMediumSize", "bold"].join(" ")}>
                            {props.order.payment.currency}
                        </div>
                    </div>
                    {props.order.payment.transactions.length === 1 ?
                    <div style={{display:"flex", flexDirection:"column", flexGrow:"1", textAlign:"right"}}>
                        <DataField
                            title={"Modalidade"}
                            label= {checkNested(props.order.payment.transactions[0], "payment_type") ? getPaymentType(props.order.payment.transactions[0].payment_type) : "-"}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="0px"
                            display={"inline"}
                        />
                        <DataField
                            title={"Parcelas"}
                            label= {checkNested(props.order.payment.transactions[0], "installments") ? props.order.payment.transactions[0].installments : "1"}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="5px"
                            display={"inline"}
                        />
                    </div> : null}
                </div>
                { props.order.payment.transactions.length > 0 ?
                <div style={{marginUp:"30px"}} className="internalCardContainer">
                    <div>
                        <div className={["blueText", "normalMediumSize", "bold"].join(" ")}>
                            TRANSAÇÕES
                        </div>
                        <div>
                            <TransactionsTable payment={props.order.payment} />
                        </div>
                    </div>
                </div> : null}
            </div>
        </div>
    )
}

export default PaymentCard