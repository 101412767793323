import React from 'react'
import DataField from "../../utils/DataField"
import {formatAsMoney} from "../../utils/utils"
import blueBillPayment from '../../../../assets/cardEntryIcons/blueBillPayment.svg';
import moment from 'moment'

function BillPaymentCard(props) {

    let { billPayment } = props

    return (
        <div className="analysisCard">
            <div style={{display:"flex"}}>
                <img style={{margin:"auto 8px auto 0px"}} src={blueBillPayment} alt="withdrawal Logo"/>
                <div className={["blueText", "subtitleSize"].join(" ")}>Pagamento de Conta</div>
            </div>
            <div className="internalCardContainer"  style={{display: "flex", flexDirection: "column"}}>
                <div className="internalCardContainer">
                    <div style={{display:"flex", marginTop:"5px"}}>
                        <div style={{width:"100%"}}>
                            <div className={["labelText", "normalMediumSize", "bold"].join(" ")}>
                                VALOR DA CONTA
                            </div>
                            <div className={["normalText", "moneySize", "bold"].join(" ")}>
                                {formatAsMoney(billPayment.amount, "-")}
                            </div>
                        </div>
                    </div>
                </div>
                <DataField
                    title={"Data de Emissão"}
                    label={moment(billPayment.bill_issuing_date).format("DD/MM/YYYY")}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                />
                <DataField
                    title={"Data de Vencimento"}
                    label={moment(billPayment.bill_due_date).format("DD/MM/YYYY")}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                />
                <DataField
                    title={"Data de Pagamento"}
                    label={moment(billPayment.bill_payment_date).format("DD/MM/YYYY")}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                />
                <div className="internalCardContainer">
                    <div>Descrição do Serviço</div>
                    <div className={["labelText", "subtitleSize"].join(" ")} style={{margin:"5px auto"}}>
                        {billPayment.service_description}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BillPaymentCard