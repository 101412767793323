import React, { useEffect, useCallback, useReducer, useContext, useState, useRef } from "react"
import { dataFetchReducer, useStateConnectedToCache } from "../../Utils/requestUtils"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import NaturalPersonCard from "./NaturalPersonCard/NaturalPersonCard"
import Filter from "./Filter/Filter"
import OrderBy from "../utils/OrderBy"
import NaturalPersonCardAnalyst from "./NaturalPersonCard/NaturalPersonCardAnalyst"
import Pagination from "@material-ui/lab/Pagination"
import {CircularProgress } from '@material-ui/core'
import AnalyzeDialog from "../AnalyzeDialog/AnalyzeDialog"
import AuthContext from "../../../context/auth-context"
import CompaniesContext from "../../../context/companies-context"
import axios from "axios"
import ErrorBoundary from '../../Utils/ErrorBoundary'
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: "Open Sans",
        fontWeight: 600,
        fontSize: "22px",
        lineHeight: "27px",
        color: "#0b1f82",
        width: "auto"

    },
    error: {
        fontFamily: "Open Sans",
        fontWeight: 600,
        fontSize: "22px",
        lineHeight: "27px",
        color: "#0b1f82",
        marginUp: "300px"
    },
    subtitle: {
        fontFamily: "Open Sans",
        fontWeight: 600,
        fontSize: "18px",
        lineHeight: "25px",
        color: "#0b1f82",
    },
    cardContainer: {
        width: "100%",
        display: "block",
    },
    pagination: {
        float: "right"
    },
    emptyContainer: {
        width: "100%",
        verticalAlign: "middle",
        textAlign: "center",
        padding: "30px",
        background: "white",
        boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.2)",
        borderRadius: "10px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        minWidth: "1100px"
    },
    buttonAnalyze: {
        boxSizing: "border-box",
        boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.12)",
        backgroundColor: "#0b1f82",
        fontFamily: "Open Sans",
        fontSize: "13px",
        fontWeight: "bold",
        lineHeight: "18px",
        textAlign: "center",
        color: "#ffffff",
        borderRadius: "30px",
        textTransform: "capitalize",
        margin: "10px 7px 10px 7px",
        padding: "8px 20px 8px 20px",
        minWidth: "100px",
        "&:hover": {
            backgroundColor: "#204e68",
        }
    },
}))

function NaturalPersonsInbox(props) {
    const history = useHistory()
    const user_data = useContext(AuthContext).user_data
    const companies_data = useContext(CompaniesContext)
    const dashboard_settings_onboarding_natural_person = companies_data?.dashboard_settings?.onboarding_natural_person
    const showQueryByName = dashboard_settings_onboarding_natural_person?.query_by_name
    const isAbasteceAi = ["336fd1a2-a3df-4194-af12-c8e144cf6fdd","06773840-e91a-4bde-859e-102b5429911b"].includes(user_data.company_key)

    const [clicked, setClicked] = useState({
        "in_manual_analysis": false,
        "on_hold": false
    })

    const [orderBy, setOrderBy] = useState(null)

    const classes = useStyles()
    useTheme()

    const { t } = useTranslation()

    if (!user_data.roles.includes("read_onboarding_natural_persons")) {
        history.push("")
    }

    let local_storage_object_preffix = "np"

    const [pageParams, setPageParams] = useStateConnectedToCache(local_storage_object_preffix)

    const [pageState, dispatchPageState] = useReducer(
        dataFetchReducer,
        { fetchedData: null, isLoading: true, isError: false }
    )

    let [analyzeBox, setAnalyzeBox] = useState(false)
    let [batchAnalysis, setBatchAnalysis] = useState(false)

    const doRequest = useCallback(
        (payload) => {
            dispatchPageState({ type: "data_fetch_init" })
            axios.get("/dash/onboarding/natural_persons", {
                params: payload
            }).then(response => {
                dispatchPageState({
                    type: "data_fetch_success",
                    payload: response.data
                })
            }).catch(error => {
                if ((error.response || {}).status === 403) dispatchPageState({ type: "data_fetch_failure_403" })
                else if ((error.response || {}).status === 404) dispatchPageState({ type: "data_fetch_failure_404" })
                else dispatchPageState({ type: "data_fetch_failure" })
            })
        }, []
    )

    const timerRef = useRef(null);
    useEffect(() => {
        if (analyzeBox === false) {
            const startInterval = (payload) => {
                stopInterval()
                timerRef.current = setInterval(() => {
                doRequest(payload, true);
                }, 20000);
            };

            const stopInterval = () => {
                clearInterval(timerRef.current)
            }

            let payload = {
                "page_number": pageParams.page - 1,
                "page_rows": 25
            }

            if (orderBy){
                payload.order_by = orderBy
            }

            Object.keys(pageParams.filters).forEach((key, index) => {
                payload[key] = pageParams.filters[key]
            })

            const handleVisibilityChange = () => {
                if (user_data.roles.includes("onboarding_analyst_heartbeat")) {
                    if (document.visibilityState === 'visible') {
                        startInterval(payload)
                    } else {
                        stopInterval()
                    }
                }
            };
            document.addEventListener('visibilitychange', handleVisibilityChange);

            const timer_ = setTimeout(() => {
                doRequest(payload)
            }, 300)

            if (user_data.roles.includes("onboarding_analyst_heartbeat")) {
                startInterval(payload)
            }

            return () => {
                clearTimeout(timer_)
                stopInterval()
                document.removeEventListener('visibilitychange', handleVisibilityChange);
            }
        }
    }, [doRequest, user_data.roles, pageParams, orderBy, analyzeBox])

    const handleChangePage = (event, page, filters) => {
        if (pageState.page === page) {
            return
        }
        setPageParams({ page: page, filters: filters })
    }
    const handleChangeOrderBy = (orderBy) => {
        setOrderBy(orderBy)
    }

    let [boxType, setboxType] = useState('')

    const handleAnalyzeBoxOpenClick = (type) => {
        setBatchAnalysis(false)
        setAnalyzeBox(true)
        setboxType(type)
    }

    const handleAnalyzeBoxBatchOpenClick = (type) => {
        setBatchAnalysis(true)
        setAnalyzeBox(true)
        setboxType(type)
    }

    const handleAnalyzeBoxCloseClick = () => {
        setAnalyzeBox(false)
    }

    const contentContainer = (dataList) => {

        const nr_of_cards = dataList['data'].length
        let cards = dataList['data'].map((natural_person, index) => (
            <ErrorBoundary key={index}>
                {natural_person.analyst_request === true ?
                    <NaturalPersonCardAnalyst key={natural_person.natural_person_key} natural_person={natural_person} user_data={user_data} /> :
                    <NaturalPersonCard key={natural_person.natural_person_key} natural_person={natural_person} user_data={user_data} />}
            </ErrorBoundary>
        ))

        if (nr_of_cards === 0) {
            return (
                <div className={classes.emptyContainer}>
                    <p className={classes.subtitle}>
                        {t("Nenhum caso a ser analisado")}
                    </p>
                </div>
            )
        }

        else {
            return (
                <div className='cardContainer'>
                    <div className="listCardHeader">
                        <div
                            className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                            style={{ flex: "2 1 120px", textAlign: "center" }}
                        >
                            ID
                        </div>
                        {user_data.business_group_key&&
                            <div
                                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                                style={{ flex: "2 1 200px", textAlign: "center" }}
                            >
                                Companhia
                            </div>}
                        <div
                            className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                            style={{ flex: "2 1 250px", textAlign: "center" }}
                        >
                            Nome
                        </div>
                        <div
                            className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                            style={{ flex: "1 1 145px", textAlign: "center" }}
                        >
                            Documento
                        </div>
                        <div
                            className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                            style={{ flex: "1 1 45px", textAlign: "center" }}
                        >
                            Status
                        </div>
                        <div
                            className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                            style={{ flex: "1 1 110px", textAlign: "center" }}
                        >
                            Data de Análise
                        </div>
                        <div
                            className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                            style={{ flex: "0 1 70px", textAlign: "center" }}
                        >
                        </div>
                    </div>
                    {cards}
                </div>
            )
        }
    }

    if (pageState.fetchedData) {
        return (
            <div style={{ width: "auto", paddingBottom: "30px" }}>
                <div style={{ display: "flex" }}>
                    <p className={["blueText", "titleSize"].join(" ")}>{t("CADASTROS DE PESSOA FÍSICA")}</p>
                    <div style={{ margin: "auto 20px auto auto", display: "flex"}}>
                        {user_data.roles.includes('create_onboarding_natural_persons') && !user_data.roles.includes('read_onboarding_filtered_pending_status_natural_persons') ? (
                            <div
                                style={{ padding: "5px 30px" }}
                                className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                                onClick={() => handleAnalyzeBoxOpenClick('manualAnalysis')}
                            >
                                {t("+ Nova Análise")}
                            </div>
                        ) : null}
                        {user_data.roles.includes('batch_analysis_viewer') && user_data.roles.includes('create_onboarding_natural_persons') && !user_data.roles.includes('read_onboarding_filtered_pending_status_natural_persons') ? (
                            <div
                                style={{ padding: "5px 30px" }}
                                className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                                onClick={() => handleAnalyzeBoxBatchOpenClick('manualAnalysis')}
                            >
                                Análise em Lote
                            </div>
                        ) : null}
                        {showQueryByName && user_data.roles.includes('create_onboarding_query_by_name') && !user_data.roles.includes('read_onboarding_filtered_pending_status_natural_persons') ? (
                            <div
                                style={{ padding: "5px 30px" }}
                                className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                                onClick={() => handleAnalyzeBoxOpenClick('queryByName')}
                            >
                                {t("+ Consulta por Nome")}
                            </div>
                        ) : null}
                    </div>
                </div>
                <div style={{ display: "flex" }}>
                    {user_data.roles.includes('read_onboarding_filtered_pending_status_natural_persons') ? null :
                        <div style={{display: "flex", flexDirection:"column", gap:"4px"}}>
                            <ErrorBoundary doNothing>
                                <Filter 
                                    local_storage_object_preffix={local_storage_object_preffix} 
                                    setPageParams={setPageParams} 
                                    is_read_filtered={user_data.roles.includes('read_onboarding_filtered_pending_status_natural_persons')}
                                    pageParams={pageParams}
                                    isAbasteceAi={isAbasteceAi}
                                    clicked={clicked}
                                    setClicked={setClicked}
                                />
                            </ErrorBoundary>
                        </div>
                    }
                    {!user_data.roles.includes('order_onboarding_natural_persons') ? null :
                        <div style={{display: "flex", flexDirection:"row-reverse", gap:"4px", margin: "5px 20px 0 auto"}}>
                            <ErrorBoundary doNothing>
                                <OrderBy 
                                    handleChangeOrderBy={handleChangeOrderBy}
                                    selectedValue={orderBy}
                                />
                            </ErrorBoundary>
                        </div>
                    }
                </div>
                <div>{contentContainer(pageState.fetchedData)}</div>
                <div>
                    <Pagination
                        className="pagination"
                        page={pageParams.page}
                        count={parseInt(pageState.fetchedData["number_of_pages"])}
                        onChange={(e, page) => handleChangePage(e, page, pageParams.filters)}
                    />
                </div>
                <AnalyzeDialog
                    boxType={boxType}
                    person_type='natural_person'
                    user_data={user_data}
                    open={analyzeBox}
                    batchAnalysis={batchAnalysis}
                    onClose={handleAnalyzeBoxCloseClick}
                    setPageParams={setPageParams}
                    local_storage_object_preffix={local_storage_object_preffix}
                />
            </div>
        )
    }
    else if (pageState.isError) {
        return (
            <div style={{ width: "auto" }}>
                <p className={["blueText", "titleSize"].join(" ")}>{t("CADASTROS DE PESSOA FÍSICA")}</p>
                <ErrorBoundary doNothing><Filter local_storage_object_preffix={local_storage_object_preffix} setPageParams={setPageParams} /></ErrorBoundary>
                <div style={{ height: "55vh", display: "flex" }}>
                    <p className={["blueText", "titleSize"].join(" ")} style={{ margin: "auto", textAlign: "center" }}>
                        {pageState.errorMessage}
                    </p>
                </div>
            </div>
        )
    }
    else if (pageState.isLoading) {
        return (
            <div style={{ width: "auto" }}>
                <p className={["blueText", "titleSize"].join(" ")}>{t("CADASTROS DE PESSOA FÍSICA")}</p>
                <div className="circularProgressCenter">
                    <CircularProgress />
                </div>
            </div>
        )
    }
    else return null

}

export default (NaturalPersonsInbox)
