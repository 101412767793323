import React from "react";
import { useTranslation } from "react-i18next";

function BoavistaNoData(props) {

    const { t } = useTranslation();

    return (
        <div className="analysisCard">
            <div style={{display:"flex", margin:"10 px"}}>
                <div 
                    className={["blueText", "subtitleSize", "bold"].join(" ")}
                    style={{margin:"auto 0px"}}
                >
                    Boa Vista
                </div>
            </div>
            <div
                className={["labelText", "normalMediumSize", "labelSpace"].join(" ")} 
                style={{
                    marginTop:"20px",
                    marginBottom:"20px",
                    alignContent:"center",
                    textAlign:"center"
                }}
            >
                {t("there_is_no_data")} 
            </div>
        </div>
    );
}

export default BoavistaNoData
