import React from 'react';
import * as S from './styles';
import Test from "../../assets/test.svg"
import { Switch } from '@material-ui/core';
import { t } from 'i18next'

export default function LegacyCallout(props) {
    const {legacy, setLegacy, text} = props;

    return (
        <S.Container>
            <img src={Test} alt="" width={24} height={24} />
            <S.Message>{t(text)}</S.Message>
            <S.SwitchWrapper>
                <Switch checked={legacy} onChange={() => setLegacy(!legacy)} color='primary'/>
                <S.Label>{t('Visualização antiga')}</S.Label>
            </S.SwitchWrapper>
        </S.Container>
    );
}