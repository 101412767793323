import React from 'react'
import DataField from "../../../utils/DataField"
import { titleCase, clientAge, getAgencyInfo} from "../../../utils/utils"
import {getAccountType} from "../../../utils/utilsTransaction"

function Account(props) {

    let account_object
    if (props.account_type === "source") {
        account_object = props.transaction.source_account
    }
    else if (props.account_type === "destination") {
        account_object = props.transaction.destination_account
    }
    else throw new Error("Account Type not defined")

    let [institution_name, branch_object] = getAgencyInfo(account_object)
    
    let institution_name_string = ""
    if (institution_name) institution_name_string = " - " + titleCase(institution_name, null)

    let branch_string = ""
    if (branch_object) {
        let branch_name = titleCase(branch_object.name, "")
        let branch_city = titleCase((branch_object.address || {}).city, "")
        let branch_uf = (branch_object.address || {}).uf || ""
        branch_string = " - " + branch_name + ", " + branch_city + " - " + branch_uf
    }

        return (
            <div className="internalCardContainer" style={{display: "flex", flexDirection: "column"}}>
                    <DataField
                        title={"Nome do Titular:"}
                        label={titleCase(((account_object || {}).owner || {}).name, null)}
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        margin="5px"
                    />   
                    <DataField
                        title={((account_object || {}).owner || {}).type === "legal_person" ? "CNPJ:" : "CPF:"}
                        label={((account_object || {}).owner || {}).document_number}
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        margin="5px"
                    /> 
                    <DataField
                        title={"Banco:"}
                        label={(account_object || {}).participant ? account_object.participant + institution_name_string : null}
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        margin="5px"
                    />
                    <DataField
                        title={"Agencia:"}
                        label={(account_object || {}).branch ? account_object.branch + branch_string : null}
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        margin="5px"
                    />
                    <DataField
                        title={"Conta:"}
                        label={(account_object || {}).account_number + "-" + (account_object || {}).account_digit}
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        margin="5px"
                    />
                    <DataField
                        title={"Tipo de Conta:"}
                        label={(account_object || {}).account_type ? account_object.account_type + " - " + getAccountType(account_object.account_type) : null}
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        margin="5px"
                    />
                    <DataField
                        title={"Data de Abertura:"}
                        label={clientAge((account_object || {}).opening_date, null, "years_months")}
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        margin="5px"
                    />
            </div>
    )
}

export default Account