import React, {useState, useContext} from "react";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import Collapse from "@material-ui/core/Collapse";
import { colorGreen } from "../../utils/utils"
import moment from 'moment'
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import UnlockDialog from "./UnlockDialog/UnlockDialog"
import unlockLogo from '../../../../assets/cardEntryIcons/unlockIcon.svg'
import {translateLockType, translateLockSource, 
        translateLockStatus, getEventGroup} from "../../utils/utilsLock"
import { getEventTypeName } from "../../utils/utilsLock"
import AuthContext from "../../../../context/auth-context";

function LockCard(props){
    let {lock} = props
    let {handleRefreshPage} = props

    let roles = useContext(AuthContext).user_data.roles;

    const [openUnlockDialog, setOpenUnlockDialog] = useState(false);
    const [expanded, setExpanded] = useState(false);

    const handleExpand = (event) => {
        event.stopPropagation();
        setExpanded(!expanded);
    };

    return (
        <Card className={["listCard","collapse"].join(" ")}>
            <div style={{display:"flex", justifyContent: "space-between"}}>
            <div 
                    className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                    style={{flex:"1 1 120px", textAlign:"center"}}
                >
                    {getEventTypeName(lock.locked_event_type)}
                </div>
                <div 
                    className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                    style={{flex:"1 1 120px", textAlign:"center"}}
                >
                    {lock.lock_link_key}
                </div>
                <div 
                    className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                    style={{flex:"1 1 55px", textAlign:"center"}}
                >
                    {translateLockType(lock.lock_type)}
                </div>
                {lock.lock_source !=='manual_alert_analysis'?
                <div 
                    className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                    style={{flex:"1 1 55px", textAlign:"center"}}
                >
                    {translateLockSource(lock.lock_source)}
                </div>:
                <Link 
                    className={["listCardItem", "blueText", "link", "subtitleSize"].join(" ")}
                    style={{flex:"1 1 55px", textAlign:"center"}}
                    to={"/"+getEventGroup(lock.locked_event_type)+"-alert/" + lock.alert_key}
                >
                    <div style={{display:"flex", justifyContent:"center"}}>
                        <div className="labelSpace">
                            {translateLockSource(lock.lock_source)}
                        </div>
                    </div>
                </Link>}
                <div 
                        className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                        style={{flex:"1 1 55px", textAlign:"center"}}
                >
                    {moment(lock.lock_date).format("DD/MM/YYYY HH:mm:ss")}
                </div>
                {lock.lock_status === "blocked" && roles.includes('update_banking_locks')
                    ? <div 
                        className={["listCardItem", "link", "subtitleSize", "bold"].join(" ")}
                        style={{flex:"1 1 55px", textAlign:"center"}}
                        onClick={() => setOpenUnlockDialog(true)}
                    >
                        <div style={{display:"flex", justifyContent:"center"}}>
                            <img src={unlockLogo} alt="Unlock Logo" />
                            <div className="labelSpace">Desbloquear</div>
                        </div>
                    </div> 
                    : <div 
                        className={["listCardItem", "normalText", "subtitleSize", "bold"].join(" ")}
                        style={{flex:"1 1 55px", textAlign:"center", color: colorGreen}}
                    >
                        {translateLockStatus(lock.lock_status)}
                    </div>}
                <div 
                    className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                    style={{flex:"0 1 70px", textAlign:"center"}}
                >
                    <IconButton onClick={handleExpand}>
                        {expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </IconButton>
                </div>
            </div>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
            <div style={{display: "flex"}}>
                {lock.lock_events ? lock.lock_events.map((event, index) => (
                    <div key={index} style={{margin: "0px auto 0px 0px" }}>
                        <div key={index} style={{display: "flex", flexDirection: "column", margin: "20px 20px 20px 20px"}}>
                            <div className={["blueText", "subtitleSize"].join(" ")}>
                                {event.new_status === "blocked" ? "Bloqueio - ": "Desbloqueio - "}
                                { moment(event.event_date).format("DD/MM/YYYY HH:mm:ss") }
                            </div>
                            {event.new_status === "blocked" && lock.lock_source === "automatically_alert" ?
                            <div style={{display: "flex", margin: "5px 0px"}}>
                                <div className={["normalText", "normalMediumSize"].join(" ")}>
                                    Alerta gerado automaticamente por gatilho crítico.
                                </div>                  
                            </div> :
                            <React.Fragment>            
                            <div style={{display: "flex", margin: "5px 0px"}}>
                                <div className={["normalText", "normalMediumSize"].join(" ")}>
                                    Responsável:
                                </div>
                                <div className={["labelText", "normalMediumSize", "labelSpace"].join(" ")}>
                                {event.analyst_name}
                                </div>                    
                            </div>
                            <div style={{display: "flex", margin: "5px 0px"}}>
                                <div className={["normalText", "normalMediumSize"].join(" ")}>
                                    E-mail:
                                </div>
                                <div className={["labelText", "normalMediumSize", "labelSpace"].join(" ")}>
                                    {event.analyst_email}
                                </div>                    
                            </div>
                            <div style={{display: "flex", margin: "5px 0px"}}>
                                <div className={["normalText", "normalMediumSize"].join(" ")}>
                                    Razão:
                                </div>
                                <div className={["labelText", "normalMediumSize", "labelSpace"].join(" ")}>
                                    {event.lock_reason ? event.lock_reason : "Informação não disponível"}
                                </div>                    
                            </div>
                            {event.observation ? 
                            <div style={{display: "flex", margin: "5px 0px"}}>
                                <div className={["normalText", "normalMediumSize"].join(" ")}>
                                    Observação:
                                </div>
                                <div className={["labelText", "normalMediumSize", "labelSpace"].join(" ")}>
                                    {event.observation}
                                </div>                    
                            </div>: null }
                            {event.new_status === "unblocked" ?
                            <div style={{display: "flex", margin: "5px 0px"}}>
                                <div className={["normalText", "normalMediumSize"].join(" ")}>
                                    Data do Desbloqueio:
                                </div>
                                <div className={["labelText", "normalMediumSize", "labelSpace"].join(" ")}>
                                    {moment(event.event_date).format("DD/MM/YYYY HH:mm:ss")}
                                </div>                    
                            </div>: null }
                            </React.Fragment>}
                        </div>
                    </div>                    
                )) : null}
            </div>
            </Collapse>
            {lock.lock_status === "blocked" ?
            <UnlockDialog
                open={openUnlockDialog}
                onClose={() => setOpenUnlockDialog(false)}
                lock={lock}
                handleRefreshPage={handleRefreshPage}        
            /> : null
            }
        </Card>
    )
}

export default LockCard