import React from "react";
import { useTranslation } from "react-i18next";
import Table from "@material-ui/core/Table";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip'
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { getTableRowClass } from "../../utils/utils";

const useStyles = makeStyles((theme) => ({
    tableStyle: {
      border: "none",
      boxShadow: "none",
    },
    blueText: {
      fontFamily: "Open Sans",
      fontWeight: "600",
      color: "#0b1f82"
  },
  redText: {
      fontFamily: "Open Sans",
      fontWeight: "600",
      color: "#D91A1A"
  },
    tableText: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      lineHeight: "16px",
      textAlign: "center",
      color: '#6F6F6F',
      margin: "10px",
      padding: "6px",
    },
    tableHeader: {
      textAlign: "center",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: "bold",
      color: '#6F6F6F',
      fontSize: "14px",
      lineHeight: "19px",
      padding: "6px",
    }
  }));

function RufraCard(props) {
    const classes = useStyles();
    useTheme();
    const { rufra_data } = props

    const { t } = useTranslation()

    const formatDocument = (document) => {
        if (document.length === 11) {
            return document.substring(0, 3) + "." + document.substring(3, 6) + "." + document.substring(6, 9) + "-" + document.substring(9, 11)
        }
        if (document.length === 14) {
            return document.substring(0, 2) + "." + document.substring(2, 5) + "." + document.substring(5, 8) + "/" + document.substring(8, 12) + "-" + document.substring(12, 14)
        }
        return document;
    }

    const formatPhone = (phone) => {
        return phone.substring(0, 3) + " (" + phone.substring(3, 5) + ") " + phone.substring(5, phone.length);
    }

    return (
        <div className="analysisCard" style={{height: "100%"}}>
            <div className={["blueText", "subtitleSize"].join(" ")}>{t("rufra_information")}</div>
            <div className="internalCardContainer" style={{display:"flex", flexGrow:"1", flexDirection: "column"}}>
                <div style={{display:"grid", width:"100%", height:"fit-content", gridTemplateColumns: "auto auto auto", padding: "12px"}}>
                    <div style={{display: "flex", flexDirection: "column", gap: "8px", width: "100%", alignItems: "center"}}>
                        <div className={["labelText", "normalMediumSize", "bold"].join(" ")}>Score</div>
                        <div className={["normalText", "moneySize", "bold"].join(" ")}>{rufra_data.score}</div>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", gap: "8px", width: "100%", alignItems: "center"}}>
                        <div className={["labelText", "normalMediumSize", "bold"].join(" ")}>{t('risk_level')}</div>
                        <div className={["normalText", "moneySize", "bold"].join(" ")}>{t(rufra_data.risk_level)}</div>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", gap: "8px", width: "100%", alignItems: "center"}}>
                        <div className={["labelText", "normalMediumSize", "bold"].join(" ")}>{t('decision_sugestion')}</div>
                        <div className={["normalText", "moneySize", "bold"].join(" ")} style={{color: rufra_data.decision_suggestion === "reject" ? "#A00" : "#0AA"}}>{t(rufra_data.decision_suggestion)}</div>
                    </div>
                </div>

                {rufra_data.pepvip ? 
                <>
                <div className={["blueText", "normalMediumSize", "bold"].join(" ")} style={{marginTop: 16, marginBottom: 16}}>Indicadores PEPVIP</div>
                <Paper className={classes.tableStyle}>
                    <Table>
                        <TableHead className={getTableRowClass(0)}>
                            <TableCell className={classes.tableHeader}>Categoria</TableCell>
                            <TableCell className={classes.tableHeader}>Número do documento</TableCell>
                            <TableCell className={classes.tableHeader}>Indicador PEP</TableCell>
                            <TableCell className={classes.tableHeader}>Indicador VIP</TableCell>
                        </TableHead>
                        {rufra_data.pepvip.user ? 
                        <TableRow className={getTableRowClass(1)}>
                            <TableCell className={classes.tableText}>Usuário</TableCell>
                            <TableCell className={classes.tableText}>{formatDocument(rufra_data.pepvip.user.document_number) ?? "-"}</TableCell>
                            <TableCell className={classes.tableText}>{rufra_data.pepvip.user.pep_indicator   ?? "-"}</TableCell>
                            <TableCell className={classes.tableText}>{rufra_data.pepvip.user.vip_indicator   ?? "-"}</TableCell>        
                        </TableRow>
                        : null}
                        {rufra_data.pepvip.recipient ? 
                        <TableRow className={getTableRowClass(rufra_data.pepvip.user ? 2 : 1)}>
                            <TableCell className={classes.tableText}>Recebedor</TableCell>
                            <TableCell className={classes.tableText}>{formatDocument(rufra_data.pepvip.recipient.document_number) ?? "-"}</TableCell>
                            <TableCell className={classes.tableText}>{rufra_data.pepvip.recipient.pep_indicator   ?? "-"}</TableCell>
                            <TableCell className={classes.tableText}>{rufra_data.pepvip.recipient.vip_indicator   ?? "-"}</TableCell>        
                        </TableRow>
                        : null}
                    </Table>
                </Paper>
                </>
                : null}
                {rufra_data.origin ? 
                <>
                <div className={["blueText", "normalMediumSize", "bold"].join(" ")} style={{marginTop: 32, marginBottom: 8}}>Análise da conta de origem</div>
                    {rufra_data.origin.map((document_data) => (
                        <div key={document_data.value}>
                        <div className={["blueText", "normalMediumSize"].join(" ")} style={{marginTop: 16, marginBottom: 8}}>
                            {document_data.type === "document_number" ? formatDocument(document_data.value) : document_data.type === "phone_number" ? formatPhone(document_data.value) : document_data.value}
                        </div>
                        <div style={{display:"grid", width:"100%", height:"fit-content", gridTemplateColumns: "auto auto auto auto auto", padding: "12px"}}>
                            <div style={{display: "flex", flexDirection: "column", gap: "8px", width: "100%", alignItems: "center"}}>
                                <div className={["labelText", "normalMediumSize", "bold"].join(" ")}>Fraudes em 7 dias</div>
                                <div className={["normalText", "moneySize", "bold"].join(" ")}>{document_data.fraud_ocurrances ? document_data.fraud_ocurrances["7D"] : 0}</div>
                            </div>
                            <div style={{display: "flex", flexDirection: "column", gap: "8px", width: "100%", alignItems: "center"}}>
                                <div className={["labelText", "normalMediumSize", "bold"].join(" ")}>Fraudes em 30 dias</div>
                                <div className={["normalText", "moneySize", "bold"].join(" ")}>{document_data.fraud_ocurrances ? document_data.fraud_ocurrances["30D"] : 0}</div>
                            </div>
                            <div style={{display: "flex", flexDirection: "column", gap: "8px", width: "100%", alignItems: "center"}}>
                                <div className={["labelText", "normalMediumSize", "bold"].join(" ")}>Fraudes em 180 dias</div>
                                <div className={["normalText", "moneySize", "bold"].join(" ")}>{document_data.fraud_ocurrances ? document_data.fraud_ocurrances["180D"] : 0}</div>
                            </div>
                            <div style={{display: "flex", flexDirection: "column", gap: "8px", width: "100%", alignItems: "center"}}>
                                <div className={["labelText", "normalMediumSize", "bold"].join(" ")}>Fraudes no histórico</div>
                                <div className={["normalText", "moneySize", "bold"].join(" ")}>{document_data.fraud_ocurrances ? document_data.fraud_ocurrances.history : 0}</div>
                            </div>
                            <div style={{display: "flex", flexDirection: "column", gap: "8px", width: "100%", alignItems: "center"}}>
                                <div className={["labelText", "normalMediumSize", "bold"].join(" ")}>Indicador Rufra</div>
                                <div className={["normalText", "moneySize", "bold"].join(" ")}>{document_data.rufra_indicator}</div>
                            </div>
                        </div>
                        {document_data.frauds ? 
                        <Paper className={classes.tableStyle} style={{marginTop: 24}}>
                            <Table>
                                <TableHead className={getTableRowClass(0)}>
                                    <TableCell className={classes.tableHeader}>Documento</TableCell>
                                    <TableCell className={classes.tableHeader}>Nome</TableCell>
                                    <TableCell className={classes.tableHeader}>Data</TableCell>
                                    <TableCell className={classes.tableHeader}>Tipo</TableCell>
                                    <TableCell className={classes.tableHeader}>Razão</TableCell>
                                    <TableCell className={classes.tableHeader}>Canal</TableCell>
                                    <TableCell className={classes.tableHeader}>Fraude Confirmada?</TableCell>
                                    <TableCell className={classes.tableHeader}>Origem</TableCell>
                                </TableHead>
                                {document_data.frauds.map((fraud, index) => (
                                    <TableRow key={fraud.document_number + "|" + fraud.raw_date} className={getTableRowClass(index + 1)}>
                                        <TableCell className={classes.tableText}>{formatDocument(fraud.document_number)}</TableCell>
                                        <TableCell className={classes.tableText}>{fraud.name}</TableCell>
                                        <TableCell className={classes.tableText}>{fraud.raw_date}</TableCell>
                                        <TableCell className={classes.tableText}>{fraud.rufra_type}</TableCell>
                                        <Tooltip title={fraud.rufra_reason.description}>
                                            <TableCell className={classes.tableText}>{fraud.rufra_reason.name}</TableCell>
                                        </Tooltip>
                                        <TableCell className={classes.tableText}>{fraud.channel}</TableCell>
                                        <TableCell className={classes.tableText} style={{fontWeight: "bold", color: fraud.confirmed ? "#A00" : "#AA0"}}>{fraud.confirmed ? "Sim" : "Não"}</TableCell>
                                        <TableCell className={classes.tableText}>{fraud.origin}</TableCell>
                                    </TableRow>
                                ))}
                            </Table>
                        </Paper>
                        : null}
                        </div>
                    ))}
                </>
                : null}
                {rufra_data.destination ? 
                <>
                <div className={["blueText", "normalMediumSize", "bold"].join(" ")} style={{marginTop: 32, marginBottom: 8}}>Análise da conta de destino</div>
                    {rufra_data.destination.map((document_data) => (
                        <div key={document_data.value}>
                        <div className={["blueText", "normalMediumSize"].join(" ")} style={{marginTop: 16, marginBottom: 8}}>
                            {document_data.type === "document_number" ? formatDocument(document_data.value) : document_data.type === "phone_number" ? formatPhone(document_data.value) : document_data.value}
                        </div>
                        <div style={{display:"grid", width:"100%", height:"fit-content", gridTemplateColumns: "auto auto auto auto auto", padding: "12px"}}>
                            <div style={{display: "flex", flexDirection: "column", gap: "8px", width: "100%", alignItems: "center"}}>
                                <div className={["labelText", "normalMediumSize", "bold"].join(" ")}>Fraudes em 7 dias</div>
                                <div className={["normalText", "moneySize", "bold"].join(" ")}>{document_data.fraud_ocurrances ? document_data.fraud_ocurrances["7D"] : 0}</div>
                            </div>
                            <div style={{display: "flex", flexDirection: "column", gap: "8px", width: "100%", alignItems: "center"}}>
                                <div className={["labelText", "normalMediumSize", "bold"].join(" ")}>Fraudes em 30 dias</div>
                                <div className={["normalText", "moneySize", "bold"].join(" ")}>{document_data.fraud_ocurrances ? document_data.fraud_ocurrances["30D"] : 0}</div>
                            </div>
                            <div style={{display: "flex", flexDirection: "column", gap: "8px", width: "100%", alignItems: "center"}}>
                                <div className={["labelText", "normalMediumSize", "bold"].join(" ")}>Fraudes em 180 dias</div>
                                <div className={["normalText", "moneySize", "bold"].join(" ")}>{document_data.fraud_ocurrances ? document_data.fraud_ocurrances["180D"] : 0}</div>
                            </div>
                            <div style={{display: "flex", flexDirection: "column", gap: "8px", width: "100%", alignItems: "center"}}>
                                <div className={["labelText", "normalMediumSize", "bold"].join(" ")}>Fraudes no histórico</div>
                                <div className={["normalText", "moneySize", "bold"].join(" ")}>{document_data.fraud_ocurrances ? document_data.fraud_ocurrances.history : 0}</div>
                            </div>
                            <div style={{display: "flex", flexDirection: "column", gap: "8px", width: "100%", alignItems: "center"}}>
                                <div className={["labelText", "normalMediumSize", "bold"].join(" ")}>Indicador Rufra</div>
                                <div className={["normalText", "moneySize", "bold"].join(" ")}>{document_data.rufra_indicator}</div>
                            </div>
                        </div>
                        {document_data.frauds ? 
                        <Paper className={classes.tableStyle} style={{marginTop: 24}}>
                            <Table>
                                <TableHead className={getTableRowClass(0)}>
                                    <TableCell className={classes.tableHeader}>Documento</TableCell>
                                    <TableCell className={classes.tableHeader}>Nome</TableCell>
                                    <TableCell className={classes.tableHeader}>Data</TableCell>
                                    <TableCell className={classes.tableHeader}>Tipo</TableCell>
                                    <TableCell className={classes.tableHeader}>Razão</TableCell>
                                    <TableCell className={classes.tableHeader}>Canal</TableCell>
                                    <TableCell className={classes.tableHeader}>Fraude Confirmada?</TableCell>
                                    <TableCell className={classes.tableHeader}>Origem</TableCell>
                                </TableHead>
                                {document_data.frauds.map((fraud, index) => (
                                    <TableRow key={fraud.document_number + "|" + fraud.raw_date} className={getTableRowClass(index + 1)}>
                                        <TableCell className={classes.tableText}>{formatDocument(fraud.document_number)}</TableCell>
                                        <TableCell className={classes.tableText}>{fraud.name}</TableCell>
                                        <TableCell className={classes.tableText}>{fraud.raw_date}</TableCell>
                                        <TableCell className={classes.tableText}>{fraud.rufra_type}</TableCell>
                                        <Tooltip title={fraud.rufra_reason.description}>
                                            <TableCell className={classes.tableText}>{fraud.rufra_reason.name}</TableCell>
                                        </Tooltip>
                                        <TableCell className={classes.tableText}>{fraud.channel}</TableCell>
                                        <TableCell className={classes.tableText} style={{fontWeight: "bold", color: fraud.confirmed ? "#A00" : "#AA0"}}>{fraud.confirmed ? "Sim" : "Não"}</TableCell>
                                        <TableCell className={classes.tableText}>{fraud.origin}</TableCell>
                                    </TableRow>
                                ))}
                            </Table>
                        </Paper>
                        : null}
                        </div>
                    ))}
                </>
                : null}
            </div>
        </div>
    );
}


export default RufraCard
