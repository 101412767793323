import React, { useEffect, useContext, useReducer, useState } from "react";	
import {dataFetchReducer} from "../../../Utils/requestUtils"
import { useParams } from "react-router-dom";
import axios from "axios";
import ErrorBoundary from "../../../Utils/ErrorBoundary"
import AuthContext from "../../../../context/auth-context"
import {useHistory} from "react-router-dom"
import moment from 'moment'
import AlertsCard from './AlertsCard/AlertsCard'
import UserCard from './UserCard/UserCard'
import HistoricCard from './HistoricCard/HistoricCard'
import ObservationCard from './ObservationCard/ObservationCard'
import NewLockDialog from './NewLockDialog/NewLockDialog'
import ArrowBack from "@material-ui/icons/ArrowBack";
import ArrowForward from "@material-ui/icons/ArrowForward";
import Tooltip from "@material-ui/core/Tooltip";
import {whitelist_ck_show_banking_alert_event_type} from "../../utils/utils"

function BankingAlert(props){

    let company_key = useContext(AuthContext).user_data.company_key 

    let showEventType = whitelist_ck_show_banking_alert_event_type.includes(company_key)
    
    let { alert_key } = useParams(); 
    
    let history = useHistory()

    let roles = useContext(AuthContext).user_data.roles

    if (!roles.includes("read_banking_alerts")){
        history.push("/default-alerts-inbox/")
    }

    const [refreshPage, setRefreshPage] = useState(false)

    const [openCreateLockDialog, setOpenCreateLockDialog] = useState(false)
    
    const [alert, dispatchAlert] = useReducer(
        dataFetchReducer,
        {fetchedData: null, isLoading: true, isError: false} 
    )

    let enableLockActions = (
        (alert.fetchedData || {}).alert_status === "in_manual_analysis" 
        && ['create_bankslip_locks','create_bill_payment_locks','create_wire_transfer_locks',
        'create_withdrawal_locks','create_pix_transaction_locks','create_pix_dict_operation_locks'].some(role => roles.includes(role)) )

    useEffect(() => {
        dispatchAlert({type: "data_fetch_init"}) 
		const timer = setTimeout(() => {
			axios.get('/dash/alerts/alert/' + alert_key).then( response => {
                dispatchAlert({
                    type: "data_fetch_success",
                    payload: response.data
                })   
			}).catch(error => {
                if ((error.response || {}).status === 403) dispatchAlert({type: "data_fetch_failure_403"})
                else if ((error.response || {}).status === 404) dispatchAlert({type: "data_fetch_failure_404"})  
                else dispatchAlert({type: "data_fetch_failure"})    
			})	
		}, 500);
		return () => {
			clearTimeout(timer)
        }
    }, [alert_key, refreshPage])

    const handleCreateLockButtonClick = () => {
        setOpenCreateLockDialog(true)
    }

    const alertsArray = JSON.parse(sessionStorage.getItem('alertsArray')) || []

    const [indexState, setIndexState] = useState(alertsArray.findIndex(alert => alert === alert_key))

    const handleNextAlertButtonClick = () => {
        let index = alertsArray.findIndex(alert => alert === alert_key)
        setIndexState(index+1)
        alert_key = alertsArray[index+1]
        history.push("/banking-alert/" + alert_key)
    }

    const handlePreviousAlertButtonClick = () => {
        let index = alertsArray.findIndex(alert => alert === alert_key)
        setIndexState(index-1)
        alert_key = alertsArray[index-1]
        history.push("/banking-alert/" + alert_key)
    }

    const handleRefreshPage = () => {
        setRefreshPage(!refreshPage)
    }

    const handleCreateLockDialogClose = (refresh=false) => {
        if (refresh) handleRefreshPage()
		setOpenCreateLockDialog(false);
	};

    if (alert.fetchedData){
        return (
            <div style={{minWidth:"900px", paddingBottom: "30px"}}>
                <div className="analysisCardContainer">
                    <ErrorBoundary>
                        <div>
                            <div className={["blueText", "titleSize"].join(" ")} style={{margin:"5px 0px"}}>
                                    {"Alerta " + alert.fetchedData.link_key}
                            </div>
                            <div className={["labelText", "subtitleSize"].join(" ")} style={{margin:"5px 0px"}}>
                                {moment(alert.fetchedData.occurrence_date).format("DD/MM/YYYY - HH:mm:ss")}
                            </div>
                        </div>
                            <div style={{margin: "auto 1px auto auto"}}>
                                {enableLockActions ? 
                                <div 
                                    className={["button", "standard", "normalText", "normalMediumSize"].join(" ")} 
                                    style={{textAlign:"center", margin:"auto", width:"150px"}}
                                    onClick={handleCreateLockButtonClick}
                                >
                                    Criar Bloqueio
                                </div>
                                :null}
                            </div>
                    </ErrorBoundary>
                </div>
                <div className="analysisCardContainer" style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}> 
                    <div>
                        {indexState !== 0 &&
                            <Tooltip title={<p className="tooltipText">Alerta Anterior</p>}>
                            <div 
                                className={["circle", 'small'].join(" ")} 
                                style={{backgroundColor:  'rgb(211, 206, 206)', cursor: "pointer"}}
                                onClick={handlePreviousAlertButtonClick}
                            >
                                <ArrowBack />
                            </div>
                            </Tooltip>}
                    </div>
                    <div>
                        {indexState !== (alertsArray.length - 1) &&
                            <Tooltip title={<p className="tooltipText">Próximo Alerta</p>}>
                            <div 
                                className={["circle", 'small'].join(" ")} 
                                style={{backgroundColor:  'rgb(211, 206, 206)', cursor: "pointer", marginRight: "1px"}}
                                onClick={handleNextAlertButtonClick}
                            >
                                <ArrowForward />
                            </div>
                            </Tooltip>}
                        {indexState === (alertsArray.length - 1) &&
                            <div 
                                className={["button", "onlyboarder", "normalMediumSize"].join(" ")}
                                style={{cursor: "pointer", marginRight: "1px", width:"150px"}}
                                onClick={() => history.push("/default-alerts-inbox")}
                            >
                                Voltar para Alertas
                            </div>
                        }
                    </div>
                </div>
                <div style={{display: "flex", flexGrow: 1}}>
                    <div className="analysisCardContainer" style={{width: "40%"}}>
                        <ErrorBoundary>   
                            <AlertsCard alert={alert.fetchedData} showEventType={showEventType}/>
                        </ErrorBoundary>
                    </div>
                    <div className="analysisCardContainer" style={{width: "60%"}}>
                        <ErrorBoundary>   
                            <UserCard alert={alert.fetchedData} />
                        </ErrorBoundary>
                    </div>
                </div>
                <div style={{display: "flex"}}>
                    <div  className="analysisCardContainer"  style={{minHeight: "250px", display: "flex", flexGrow: 1}}>
                        <ErrorBoundary>   
                            <HistoricCard alert={alert.fetchedData} showEventType={showEventType}/>
                        </ErrorBoundary>
                    </div>
                </div>
                <div style={{display: "flex"}}>
                    <div className="analysisCardContainer" style={{ minHeight: "170px", display: "flex", flexGrow: 1}}>
                        <ErrorBoundary>
                            <ObservationCard alert={alert.fetchedData}/>
                        </ErrorBoundary>			
                    </div>
                </div>
                {alert.fetchedData.alert_status === "in_manual_analysis" ?
                <NewLockDialog
                    open={openCreateLockDialog}
                    onClose={handleCreateLockDialogClose}
                    handleRefreshPage={handleRefreshPage}
                    alert={alert.fetchedData}
                    roles={roles}
                /> : null}
            </div>
        )
    }
    else if(alert.isError){
        return (
            <div style={{height: "80vh", display:"flex"}}>
                <p className={["blueText", "titleSize"].join(" ")} style={{margin:"auto", textAlign:"center"}}>
                    {alert.errorMessage}
                </p>
            </div>
        )
    }
    else return null;
}

export default BankingAlert