import styled from 'styled-components';
import Card from "@material-ui/core/Card";

export const CreditCardContainer = styled(Card)`
    min-width: 50%;
`;

export const InformationColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
`;

export const CardNumber = styled.div`
    margin-top: 16px;
    font-family: "Open Sans";
    letter-spacing: 1.5px;
    color: black;
`;

export const CardStatusContainer = styled.div`
    border: 2px solid ${(props) => props.valid ? "#73C580" : "#DE3B3B"};
    color: ${(props) => props.valid ? "#73C580" : "#DE3B3B"};
    border-radius: 8px;
    padding: 4px 8px;
    font-weight: bold;
    display: flex;
    gap: 12px;
    justify-content: center;
    align-items: center;
`;