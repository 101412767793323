import React from 'react';
import { useTranslation } from 'react-i18next';

function ColoredStatus (props) {
  
  const { status, isButton } = props

  const { t } = useTranslation()

  const statusStyle = {
    has_rule_draft: {
      backgroundColor: 	"#ffcc99"
    },
    active: {
      backgroundColor: "#B1DA9F"
    },
    inactive: {
      backgroundColor: "#cccccc"
    }
  }

  return (
    <p 
      className={isButton?"button coloredStatus normalMediumSize normalText": "coloredStatus normalMediumSize normalText"} 
      style={{backgroundColor: statusStyle[status].backgroundColor, ...props.style}}
      onClick={()=>props.onClick?props.onClick():null}
      >
      {t(status)}
    </p>
  )
}

export default ColoredStatus;
