import React, { useReducer, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import axios from "axios"
import { CircularProgress } from '@material-ui/core';
import ConfirmationContent from "./ConfirmationContent"
import {decisionReducer} from "../../../../Utils/requestUtils"
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createMuiTheme({
    palette:{
        primary: {
            main:"#0b1f82"
        },
    }
  });

function UnlockDialog(props) {
    let {lock} = props
    let {onClose} = props
    let {open} = props
    let {handleRefreshPage} = props

    let lockType = lock.lock_type === "customer" ? "Comprador" : "Vendedor"
    let lockTypeDocument = lock.lock_type === "customer" ? lock.customer_document_number : lock.seller_document_number

    const [dialogState, dispatchDialogState] = useReducer(
        decisionReducer,
        {isLoading: false, isError: false, finished:false}
    )

    const [observationBox, setObservationBox] = useState("")

    const handleObservationBoxChange = (event) => {
        setObservationBox(event.target.value)
    };

    const handleApprove = () =>{
        dispatchDialogState({type:"send_request_init"})
        let payload={
            lock_status: "unblocked"
        }
        if (observationBox && observationBox.length > 0){
            payload['observation'] = observationBox
        }
        setTimeout(function () {
            axios.put('/dash/card_order/lock/' + lock.lock_key, payload).then(response => {
                dispatchDialogState({type:"send_request_success"})
              }).catch(error => {
                if ((error.response || {}).status === 403) dispatchDialogState({type: "send_request_failure_403"})
                else if ((error.response || {}).status === 409) dispatchDialogState({type: "send_request_failure_409"})  
                else dispatchDialogState({type: "send_request_failure"})    
              });
        }, 1000);
    }

    const handleReturn = () => {
        onClose()
        handleRefreshPage()
    }

    if ((dialogState.finished && dialogState.isLoading)){
        return (
            <Dialog 
                open={open} 
                onClose={onClose} 
                keepMounted={false}
                disableBackdropClick
            >
                <DialogTitle style={{paddingBottom: "0px"}} className={["blueText", "normalMediumSize"].join(" ")}>Desbloquear</DialogTitle>
                <DialogContent>
                    <div style={{display:"flex", width: "310px", height:"217px"}}>
                        <CircularProgress style={{margin:"auto"}} />
                    </div>
                </DialogContent>
            </Dialog>

        )
    }
    else if (dialogState.finished){
        let confirmationData = {    
            message: dialogState.message,
            success: !dialogState.isError
        }
        return (
            <Dialog 
                open={open} 
                onClose={handleReturn} 
                keepMounted={false}
                disableBackdropClick
            >
                <DialogTitle style={{paddingBottom: "0px"}} className={["blueText", "normalMediumSize"].join(" ")}>Confirmação de Revisão</DialogTitle>
                <DialogContent>
                    <ConfirmationContent onClose={handleReturn} data={confirmationData} />
                </DialogContent>
            </Dialog>
        )
    }
    else{
        return (
            <Dialog 
            open={open} 
            onClose={onClose} 
            keepMounted={false}
            >
                <DialogTitle style={{paddingBottom: "0px"}} className={["blueText", "normalMediumSize"].join(" ")}>Desbloquear</DialogTitle>
                <DialogContent>
                    <div>
                        <div className="internalCardContainer">
                            <div 
                                className={["normalText", "normalMediumSize"].join(" ")}
                            >
                                Você tem certeza que deseja desbloquear o seguinte {lockType}:
                                <div style={{display: "flex", margin: "5px 0px", justifyContent: "center"}}>
                                    <div className={["normalText", "normalMediumSize"].join(" ")}>
                                        Documento:
                                    </div>
                                    <div className={["labelText", "normalMediumSize", "labelSpace"].join(" ")}>
                                        {lockTypeDocument}
                                    </div>                    
                                </div>
                            </div>
                        </div>
                        <ThemeProvider theme={theme}>
                            <TextField  
                                fullWidth
                                multiline
                                variant="outlined"
                                rows="5"
                                label={"Observações"}
                                color="primary"
                                onChange={handleObservationBoxChange}
                            />
                        </ThemeProvider>
                        <div className="internalCardContainer" style={{display:"flex", justifyContent:"center"}}>
                            <div 
                                className={["button", "approved", "normalText", "normalMediumSize"].join(" ")}
                                style={{minWidth: "70px"}}
                                onClick={handleApprove}
                            >
                                SIM 
                            </div>
                            <div 
                                className={["button", "reproved", "normalText", "normalMediumSize"].join(" ")}
                                style={{minWidth: "70px"}}
                                onClick={onClose}
                            >
                                NÃO
                            </div>
                      </div>
                  </div>
              </DialogContent>
            </Dialog>
        )
    }
}

export default UnlockDialog;