import React, {useState} from 'react';
import Account from "./Account/Account";
import StatisticsTableV1 from "./StatisticsTable/StatisticsTableV1";
import StatisticsTableV2 from "./StatisticsTable/StatisticsTableV2";
import Tooltip from "@material-ui/core/Tooltip";
import peopleGreen from '../../../../assets/cardEntryIcons/peopleGreen.svg';

function EnvolvedParties(props) {

    const [statisticsType, setStatisticsType] = useState("key")

    return (
        <div className="analysisCard">
            <div style={{display: "flex"}}>
                <span className={["blueText", "subtitleSize"].join(" ")}>Partes Envolvidas</span>      
            </div>
            <div className="internalCardContainer" style={{display: "flex"}}>
                {props.dict_operation.source_account ?
                <div style={{width: "50%", display: "flex", flexDirection: "column", margin: "5px"}}>
                    <div style={{display: "flex"}}>
                        <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>Doador</span>
                        {props.dict_operation.dict_operation_direction === "donor"?
                        <Tooltip title={<p className="tooltipText">Cliente</p>}>
                            <div style={{display: "flex", margin: "auto auto auto 10px"}}>
                                <img className="pinIconStyle" src={peopleGreen} alt="clientIcon Logo" />  
                            </div>
                        </Tooltip> 
                        :null}        
                    </div>
                    <Account dict_operation={props.dict_operation} account_type={"source"} />
                </div> : null}
                {props.dict_operation.destination_account ?
                <div style={{width: "50%", display: "flex", flexDirection: "column", margin: "5px"}}>
                    <div style={{display: "flex"}}>
                        <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>Recebedor</span>
                        {props.dict_operation.dict_operation_direction === "claimer"?
                        <Tooltip title={<p className="tooltipText">Cliente</p>}>
                            <div style={{display: "flex", margin: "auto auto auto 10px"}}>
                                <img className="pinIconStyle" src={peopleGreen} alt="clientIcon Logo" />  
                            </div>
                        </Tooltip> 
                        :null}        
                    </div>
                    <Account dict_operation={props.dict_operation} account_type={"destination"} />
                </div> : null}
            </div>
            {props.dict_operation.destination_statistics ? 
            <div className="internalCardContainer">
                <div className={["blueText", "subtitleSize"].join(" ")}>Estatísticas do Recebedor</div>
                <div className="internalCardContainer" style={{display:"flex", justifyContent: "center"}}>
                    <div 
                        className={["blueText", "normalMediumSize", "link"].join(" ")}
                        style={statisticsType === "key" ? {margin:"10px", padding: "0 5px"} : {color: "#C4C4C4", margin:"10px", padding: "0 5px"}}
                        onClick={()=>setStatisticsType("key")}
                    >
                        Chave
                    </div>
                    {props.dict_operation.destination_statistics.account ?
                        <div 
                            className={["blueText", "normalMediumSize", "link"].join(" ")}
                            style={statisticsType === "account" ? {margin:"10px", padding: "0 5px"} : {color: "#C4C4C4", margin:"10px", padding: "0 5px"}}
                            onClick={()=>setStatisticsType("account")}
                        >
                            Conta
                        </div>
                    : null}
                    <div 
                        className={["blueText", "normalMediumSize", "link"].join(" ")}
                        style={statisticsType === "owner" ? {margin:"10px", padding: "0 5px"} : {color: "#C4C4C4", margin:"10px", padding: "0 5px"}}
                        onClick={()=>setStatisticsType("owner")}
                    >
                        Proprietário
                    </div>
                </div>
                <div className="internalCardContainer" style={{display: "flex", justifyContent: "center"}}>
                {props.dict_operation.destination_statistics.account ?
                        <StatisticsTableV1 statisticsType={statisticsType} destination_statistics={props.dict_operation.destination_statistics}/>
                        : <StatisticsTableV2 statisticsType={statisticsType} destination_statistics={props.dict_operation.destination_statistics}/> }
                </div>                
            </div> : null}
        </div>
    )
}

export default EnvolvedParties;