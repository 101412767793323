export const translateLockType = (lockType) => {
    switch(lockType){
        case "seller":
            return "Payout do Vendedor"
        case "seller_transactional":
            return "Transacional do Vendedor"
        case "customer":
            return "Comprador"
        case "client_document_number":
            return "Documento do Cliente"
        case "payer_document_number":
            return "Documento do Pagador"
        case "recipient_document_number":
            return "Documento do Recebedor"
        case "client_account":
            return "Conta do Cliente"
        case "payer_account":
            return "Conta do Pagador"
        case "recipient_account":
            return "Conta do Recebedor"
        default:
            return lockType
    }
}

export const translateLockSource = (lockSource) => {
    switch(lockSource){
        case "manual_input":
            return "Manual"
        case "manual_alert_analysis":
            return "Alerta Manual"
        case "automatic":
            return "Automático"
        default:
            return lockSource
    }
}

export const translateLockStatus = (lockStatus) => {
    switch(lockStatus){
        case "blocked":
            return "Bloqueado"
        case "unblocked":
            return "Desbloqueado"
        default:
            return lockStatus
    }
}

export const getEventGroup = (event_type)=>{
    switch (event_type){
        case "bankslip":
        case "bill_payment":
        case "wire_transfer":
        case "withdrawal":
        case "pix_transaction":
        case "pix_dict_operation":
            return "banking"
        case "card_order":
            return "card-order"
        case "card_issuance":
            return "card-issuance"
        default:
            return null
    }
}

export const getEventTypeName = (event_type)=>{
    switch (event_type){
        case "all":
            return "Todos"
        case "bankslip":
            return "Boleto"
        case "bill_payment":
            return "Pagamento de Contas"
        case "wire_transfer":
            return "Transferencia Bancária"
        case "withdrawal":
            return "Saque"
        case "pix_transaction":
            return "Transação PIX"
        case "pix_dict_operation":
            return "Operação na Dict"
        case "card_order":
            return "Card Order"
        default:
            return event_type
    }
}

export const getEventTypeFromRole = (role) => {
    switch(role){
        case "read_bankslip_locks":
            return("bankslip")
        case "read_bill_payment_locks":
            return("bill_payment")
        case "read_wire_transfer_locks":
            return("wire_transfer")
        case "read_withdrawal_locks":
            return("withdrawal")
        case "read_pix_transaction_locks":
            return("pix_transaction")
        case "read_pix_dict_operation_locks":
            return("pix_dict_operation")
        case "read_card_order_locks":
            return("card_order")
        default:
            return null
    }
}

export const getFilterEventTypeOptions = (roles) => {
    let event_types = []
    event_types.push('all')

    for (let i in roles) {
        let event_type = getEventTypeFromRole(roles[i])
        if (event_type!==null){
            event_types=event_types.filter(inboudEventType => inboudEventType!==event_type);
            event_types.push(event_type);
        }
    }

    return event_types
}

export const getLockTypesFromEventType = (eventType) => {
    switch (eventType){
        case "bankslip":
        case "wire_transfer":
        case "pix_transaction":
            return [
                ["Documento do Cliente","client_document_number"],
                ["Documento do Pagador","payer_document_number"],
                ["Documento do Recebedor","recipient_document_number"]
            ]
        case "bill_payment":
        case "withdrawal":
            return [
                ["Documento do Cliente","client_document_number"]
            ]
        case "pix_dict_operation":
            return [
                ["Documento do Cliente","client_document_number"],
                ["Documento do Doador","payer_document_number"],
                ["Documento do Reinvidicador","recipient_document_number"]
            ]
        case "card_order":
            return [
                ["Comprador","customer"],
                ["Payout do Vendedor","seller"],
                ["Transacional do Vendedor","seller_transactional"]
            ]
        default:
            return []
    }
}