import React from "react";
import FilterBox from "../../../Utils/FilterBox/FilterBox"

function Filter(props) {
	
    const { setPageParams } = props

    let filterTypes = {
        document_number: {
            type: "masked_string",
            maskType:"999.999.999-99",
            disabled: false,
            validation:true,
            validation_rule: {type: "equal", value:14},
            description: "CPF"
        },
        reservation_date: {
            type: "date",
            disabled: false,
            validation:false,
            description: "Data da Reserva"
        },
        reservation_code: {
            type: "string",
            disabled: false,
            validation:false,
            description: "Número da Reserva"
        },
        rental_store: {
            type: "string",
            disabled: false,
            validation:false,
            description: "Loja de Retirada"
        },
        status: {
            type: "enum",
            enumList: [
                ["Pendente", "pending"],
                ["Aprovado Manualmente", "manually_approved"],
                ["Reprovado Manualmente", "manually_reproved"],
                ["Aprovado Automaticamente", "automatically_approved"],
                ["Reprovado Automaticamente", "automatically_reproved"],
                ["Aprovado por tempo", "approved_by_time"]
            ],
            disabled: false,
            validation:false,
            description: "Status"
        }
    }

    return (
        <FilterBox 
            setPageParams={setPageParams}
            filterTypes={filterTypes}
            local_storage_object_preffix={props.local_storage_object_preffix}
        />
    )

}

export default Filter