import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
  },
  tableText: {
    fontFamily: "Open Sans",
    fontWeight: 'normal',
    fontSize: "12px",
    lineHeight: "16px",
    color: "#141414",
    textAlign: "center",
    margin: "10px",
    padding: "6px",
    maxWidth: "80px"
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: '#6F6F6F',
    fontSize: "14px",
    lineHeight: "19px",
    padding: "6px",
    maxWidth: "80px"
  },
  even: {
    background: "#F3F3F3",
  },
  odd: {
    background: "#FFFFFF",
  },
}));

function LavaJatoTable(props) {
  const classes = useStyles();
  useTheme();

  const getClass = (rowIndex) => {
    if (rowIndex % 2 === 0) {
      return classes.even;
    }

    return classes.odd;
  };

  return (
    <Paper className={classes.tableStyle}>
      <Table style={{ padding: "8px", marginBottom: "30px" }}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader}>Nome encontrado:</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow className={getClass(0)}>
              <TableCell className={classes.tableText}>
              {props.lava_jato_data[0].lava_jato_name}
              </TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
}

export default LavaJatoTable;
