import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";

const theme = createMuiTheme({
    palette:{
        primary: {
            main:"#0b1f82"
        },
        secondary: {
            main: "#FF0000"
        }
    }
});


const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

function DeleteRuleDialog(props) {
    const { t } = useTranslation();

    let {onClose, onConfirm} = props

    const [isLoading, setIsLoading] = useState(false) 

    const handleClose = () => {
        onClose()
        setTimeout(function () {
            setIsLoading(false)
        }, 500);    
    }

    
    return (
        <Dialog 
            open={props.open} 
            onClose={handleClose}
            disableBackdropClick
            disableEscapeKeyDown
            keepMounted={false}
        >
            <DialogTitle 
                className={['blueText', "subtitleSize"].join(" ")} 
                onClose={handleClose}
            >
                {t("delete_rule")}
            </DialogTitle>
            <p style={{width: "100%", textAlign: "center", fontFamily: "Open Sans"}}>{t('delete_rule_description')}</p>
            <DialogContent>
                <ThemeProvider theme={theme}>
                <div style={{display:"flex", alignItems: "flex-end", width:"320px"}}>
                    {isLoading 
                    ?
                    <CircularProgress style={{margin:"auto"}}/>
                    :
                    <React.Fragment>
                    <div className="internalCardContainer" style={{display:"flex", width: "100%", flexDirection:"row", justifyContent: "space-between", marginTop: "10px"}}>
                        <div
                            className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")}
                            style={{width:"120px", margin: 0}}
                            onClick={handleClose}
                        >
                           {t("cancel")}
                        </div>
                        <div
                            className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                            style={{width:"120px", margin: 0}}
                            onClick={onConfirm}
                        >
                            {t("delete")}
                        </div>   
                    </div>
                    </React.Fragment>
                    }
                </div>
                </ThemeProvider>  
            </DialogContent>
        </Dialog>
    )
}

export default DeleteRuleDialog;