import React from "react";
import DataField from "../../utils/DataField"
import {clientAge, titleCase, checkNested, getProperMetadata} from "../../utils/utils"
import moment from 'moment';
import { useTranslation } from "react-i18next";

function CNHProfile(props) {

    const { t } = useTranslation()

    let decision_metadata = getProperMetadata(props.natural_person.analysis_status_events)

    const informationIsUnavailable = (decision_metadata.cnh_database === null)||
    (Object.keys(decision_metadata.cnh_database).length === 0)||
    ((decision_metadata.cnh_database||{}).flag==='neutral')

    if (informationIsUnavailable) {
        return (
            <div className="analysisCard" style={{minHeight: "250px", height:"100%"}}>
                <div className={["blueText", "subtitleSize"].join(" ")}>Validação Base de CNHs</div>
                <div style={{display:"flex", flexGrow:"1"}}>
                    <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                        {t("information_unavailable")}
                    </div>
                </div>
            </div>
        );
    }

    let {cnh_database} = decision_metadata

    return (
        <div className={["analysisCard"].join(" ")}>
            <div className={["blueText", "subtitleSize"].join(" ")}>Validação Base de CNHs</div>
            <div className="internalCardContainer" style={{display: "flex", flexDirection: "column"}}>
                <DataField
                    title={"Nome"}
                    label={titleCase(props.natural_person.name, null)}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={cnh_database.name == null ? null : cnh_database.name.flag}
                    tooltipText={
                        checkNested(cnh_database, "name", "similarity") ?
                        "Similaridade de " + (cnh_database.name.similarity*100).toLocaleString('de-DE', {maximumFractionDigits: 1}) + "%" :
                        "Indisponível"
                    }
                />
                <DataField
                    title={"Data de Nascimento"}
                    label={clientAge(props.natural_person.birthdate, null)}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={cnh_database.birthdate == null ? null : cnh_database.birthdate.flag}
                    tooltipText={cnh_database.birthdate == null ? "Indisponível" : cnh_database.birthdate.flag === "positive" ? "Validado" :
                    cnh_database.birthdate.flag === "negative" ? "Data divergente" : "Indisponível"
                    }
                />
                <DataField
                    title={"Nome da Mãe"}
                    label={titleCase(props.natural_person.mother_name, null)}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={cnh_database.mother_name == null ? null : cnh_database.mother_name.flag}
                    tooltipText={
                        checkNested(cnh_database, "mother_name", "similarity") ?
                        "Similaridade de " + (cnh_database.mother_name.similarity*100).toLocaleString('de-DE', {maximumFractionDigits: 1}) + "%" :
                        "Indisponível"
                    }
                />
                <DataField
                    title={"Nome do Pai"}
                    label={titleCase(props.natural_person.father_name, null)}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={cnh_database.father_name == null ? null : cnh_database.father_name.flag}
                    tooltipText={
                        checkNested(cnh_database, "father_name", "similarity") ?
                        "Similaridade de " + (cnh_database.father_name.similarity*100).toLocaleString('de-DE', {maximumFractionDigits: 1}) + "%" :
                        "Indisponível"
                    }
                />
                <DataField
                    title={"Número do RG"}
                    label={checkNested(props.natural_person, "documents", "rg", "number") ? props.natural_person.documents.rg.number : null}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={checkNested(cnh_database, "rg", "number", "flag") ? cnh_database.rg.number.flag : null}
                    tooltipText={
                        checkNested(cnh_database, "rg", "number", "similarity") ?
                        "Similaridade de " + (cnh_database.rg.number.similarity*100).toLocaleString('de-DE', {maximumFractionDigits: 1}) + "%" :
                        "Indisponível"
                    }
                />
                <DataField
                    title={"Órgão Emissor"}
                    label={checkNested(props.natural_person, "documents", "rg", "issuer") ? props.natural_person.documents.rg.issuer : null}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={checkNested(cnh_database, "rg", "issuer", "flag") ? cnh_database.rg.issuer.flag : null}
                    tooltipText={
                        cnh_database.rg == null ? "Indisponível" : cnh_database.rg.issuer == null ? "Indisponível" : cnh_database.rg.issuer.flag === "positive" ? "Validado" :
                        cnh_database.rg.issuer.flag === "negative" ? "Órgão divergente" : "Indisponível"
                    }
                />
                <DataField
                    title={"Estado Emissor"}
                    label={checkNested(props.natural_person, "documents", "rg", "issuer_state") ? props.natural_person.documents.rg.issuer_state : null}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={checkNested(cnh_database, "rg", "issuer_state", "flag") ? cnh_database.rg.issuer_state.flag : null}
                    tooltipText={
                        cnh_database.rg == null ? "Indisponível" : cnh_database.rg.issuer_state == null ? "Indisponível" : cnh_database.rg.issuer_state.flag === "positive" ? "Validado" :
                        cnh_database.rg.issuer_state.flag === "negative" ? "Estado divergente" : "Indisponível"
                    }
                />
                <DataField
                    title={"Número da CNH"}
                    label={checkNested(props.natural_person, "documents", "cnh", "register_number") ? props.natural_person.documents.cnh.register_number : null}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={checkNested(cnh_database, "cnh", "register_number", "flag") ? cnh_database.cnh.register_number.flag : null}
                    tooltipText={
                        cnh_database.cnh == null ? "Indisponível" : cnh_database.cnh.register_number == null ? "Indisponível" : cnh_database.cnh.register_number.flag === "positive" ? "Validado" :
                        cnh_database.cnh.register_number.flag === "negative" ? "Número divergente" : "Indisponível"
                    }
                />
                <DataField
                    title={"Categoria da Habilitação"}
                    label={checkNested(props.natural_person, "documents", "cnh", "category") ? props.natural_person.documents.cnh.category : null}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={checkNested(cnh_database, "cnh", "category", "flag") ? cnh_database.cnh.category.flag : null}
                    tooltipText={
                        cnh_database.cnh == null ? "Indisponível" : cnh_database.cnh.category == null ? "Indisponível" : cnh_database.cnh.category.flag === "positive" ? "Validado" :
                        cnh_database.cnh.category.flag === "negative" ? "Categoria divergente" : "Indisponível"
                    }
                />
                <DataField
                    title={"Data de Validade"}
                    label={checkNested(props.natural_person, "documents", "cnh", "expiration_date") ? 
                        moment(props.natural_person.documents.cnh.expiration_date).format('DD/MM/YYYY') : null
                    }
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={checkNested(cnh_database, "cnh", "expiration_date", "flag") ? cnh_database.cnh.expiration_date.flag : null}
                    tooltipText={
                        cnh_database.cnh == null ? "Indisponível" : cnh_database.cnh.expiration_date == null ? "Indisponível" : cnh_database.cnh.expiration_date.flag === "positive" ? "Validado" :
                        cnh_database.cnh.expiration_date.flag === "negative" ? "Categoria divergente" : "Indisponível"
                    }
                />
                <DataField
                    title={"Data de Emissão"}
                    label={checkNested(props.natural_person, "documents", "cnh", "issuance_date") ? 
                        clientAge(props.natural_person.documents.cnh.issuance_date, null) : null
                    }
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={checkNested(cnh_database, "cnh", "issuance_date", "flag") ? cnh_database.cnh.issuance_date.flag : null}
                    tooltipText={
                        cnh_database.cnh == null ? "Indisponível" : cnh_database.cnh.issuance_date == null ? "Indisponível" : cnh_database.cnh.issuance_date.flag === "positive" ? "Validado" :
                        cnh_database.cnh.issuance_date.flag === "negative" ? "Categoria divergente" : "Indisponível"
                    }
                />
                <DataField
                    title={"Data da 1ª Habilitação"}
                    label={checkNested(props.natural_person, "documents", "cnh", "first_issuance_date") ? 
                        clientAge(props.natural_person.documents.cnh.first_issuance_date, null) : null
                    }
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={checkNested(cnh_database, "cnh", "first_issuance_date", "flag") ? cnh_database.cnh.first_issuance_date.flag : null}
                    tooltipText={
                        cnh_database.cnh == null ? "Indisponível" : cnh_database.cnh.first_issuance_date == null ? "Indisponível" : cnh_database.cnh.first_issuance_date.flag === "positive" ? "Validado" :
                        cnh_database.cnh.first_issuance_date.flag === "negative" ? "Categoria divergente" : "Indisponível"
                    }
                />
            </div>
        </div>
    )
}

export default CNHProfile;