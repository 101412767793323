import React from "react";
import { useTranslation } from "react-i18next";
import BoavistaSummaryTotal from "../BoavistaSummaryData/BoavistaSummaryTotal";

function BoavistaScoreCard (props){

  const { score } = props
  const { t } = useTranslation()

  return (
    <div  className="avoidPageBreakInside" style={{padding:"10px"}}>
      <div className="analysisCard" style={{height:"100%"}}>
        <div className={["blueText", "subtitleSize"].join(" ")} style={{marginBottom: "10px"}}>{t("score_boavista")}
        </div>
          <BoavistaSummaryTotal
            total={score}
            description={'/1000'}
            secondDescription={'score'}
          />
      </div>
    </div>
  )
}

export default BoavistaScoreCard