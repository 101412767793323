import React from "react";
import { useTranslation } from "react-i18next";
import { formatScorePositivoData } from "../../../../utils/utils";

function ScorePositivoSummaryTotal(props) {

    const {description, total} = props

    const { t } = useTranslation()

    return (
        <div>
            <div 
                className={["normalText","analysisCardSummaryLargeSize","analysisCardSummaryColor", "alignCenter"].join(" ")}
                style={{padding:"10px"}}
            >
                {formatScorePositivoData(description, t(total))}
            </div>
            <div className={["normalText","normalMediumSize","analysisCardSummaryColor", "alignCenter"].join(" ")}>
                {t(description)}
            </div>
        </div>
    )
}


export default ScorePositivoSummaryTotal