import React, {useState} from "react";
import Collapse from "@material-ui/core/Collapse";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import IconButton from "@material-ui/core/IconButton";
import {serasa_translations} from "../../utils/utils";
import SerasaNegativeNotesTable from "./SerasaNegativeNotesTable/SerasaNegativeNotesTable";

function SerasaNegativeNotesCard(props) {

    const [expanded, setExpanded] = useState(true);

    let {negative_notes_data, negative_note_type, has_negative_note} = props
    
    const handleExpand = (event) => {
        event.stopPropagation();
        setExpanded(!expanded);
    };

    return (
        <div className="analysisCard" >
            <div style={{display:"flex", minHeight:"48px", alignItems:"center"}}>
                <div 
                    className={["blueText", "subtitleSize", "bold"].join(" ")}
                    style={{margin:"auto 0px"}}
                >
                    {negative_note_type}
                </div>
                <div style={{margin:"auto 10px"}}>
                    {has_negative_note ?  serasa_translations.negative.siglum : serasa_translations.positive.siglum}
                </div>
                {has_negative_note &&
                <IconButton style={{marginLeft: "auto"}} onClick={handleExpand}>
                    {expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </IconButton>             
                }                    
            </div>
            {has_negative_note &&
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <div className={["normalText", "normalMediumSize", "internalCardContainer"].join(" ")}>
                    <SerasaNegativeNotesTable negative_notes_data={negative_notes_data}/>
                </div>
            </Collapse> 
            }     
        </div>
    );
}

export default SerasaNegativeNotesCard