import React from 'react'
import alarmIcon from "../../../../../assets/cardEntryIcons/alarm.svg"
import {formatIrregularity, getSeverityStatus, getSeverityColor, getAlertStatusText,getAlertStatusColor} from "../../../utils/utilsAlert"
import Triggers from "./Triggers/Triggers"

function AlertsCard(props) {

    return (
        <div className="analysisCard">
            <div style={{display: "flex"}}>
                <img className="cardTitleIconStyle" style={{maxHeight: "19px"}} src={alarmIcon} alt="alarmIcon Logo" />
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>Alerta</span>
                <div className={["listCardItem", "normalText", "normalSmallSize"].join(" ")} style={{marginLeft:"auto"}}>
                    {formatIrregularity(props.alert.irregularity_type,true)}
                </div>
                <div className={["listCardItem", "normalText", "normalSmallSize"].join(" ")} >
                    <div className="statusTag" style={{backgroundColor:getSeverityColor(props.alert.risk_level)}}>
                        {getSeverityStatus(props.alert.risk_level)}
                    </div>
                </div>
            </div>
            <div className="internalCardContainer">
                <div style={{display:"flex"}}>
                    <div className={["normalText", "normalMediumSize", "noWrap"].join(" ")}>
                        Status:
                    </div>
                    <div 
                        className={["normalText", "normalMediumSize", "labelSpace", "bold"].join(" ")} 
                        style={{color:getAlertStatusColor(props.alert.alert_status)}}
                    >
                        {getAlertStatusText(props.alert.alert_status)}
                    </div>
                </div>
            </div>
            <div className="internalCardContainer" style={{display:"flex", flexDirection: "column", flexGrow:"1"}}>
                <div className={["blueText", "normalSmallSize"].join(" ")}>
                    Gatilhos
                </div>
                <Triggers triggers={props.alert.triggers}/>
            </div>
        </div>
    )
}

export default AlertsCard


