import React, { useEffect, useContext, useState, useReducer, useCallback } from "react";
import { dataFetchReducer } from "../../Utils/requestUtils";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useHistory } from "react-router-dom";
import AuthContext from "../../../context/auth-context";
import { CircularProgress } from "@material-ui/core";
import NewRuleDialog from "./Dialogs/NewRuleDialog";
import { listSorter } from "../utils"
import RuleTreeEngine from "./RuleTreeEngine";
import RulesContext from "../../../context/rules-context";

function RuleTree(props) {
  let history = useHistory();
  let user_data = useContext(AuthContext).user_data;

  if (!user_data.roles.includes("rule_viewer")) {
    history.push("");
  }

  let { rule_key, version } = useParams();

  const [ruleDefinition, setRuleDefinition] = useState(null);

  const [canUseMultiEnvironment, setCanUseMultiEnvironment] = useState(null);

  useEffect(() => {
    setCanUseMultiEnvironment(user_data.roles.includes("use_sa_rule_engine"))
  }, [user_data.roles])

  const [rule, dispatchRule] = useReducer(dataFetchReducer, {
    fetchedData: null,
    isLoading: true,
    isError: false,
  });

  const [companyEvent, dispatchCompanyEvent] = useReducer(dataFetchReducer, {
    fetchedData: null,
    isLoading: true,
    isError: false,
  });

  const [lists, dispatchLists] = useReducer(dataFetchReducer, {
    fetchedData: null,
    isLoading: true,
    isError: false,
  });

  const [processorsVariables, dispatchProcessorsVariables] = useReducer(
    dataFetchReducer,
    { fetchedData: null, isLoading: true, isError: false }
  );

  const [signals, dispatchSignals] = useReducer(dataFetchReducer, {
    fetchedData: null,
    isLoading: true,
    isError: false,
  });

  const [scripts, dispatchScripts] = useReducer(dataFetchReducer, {
    fetchedData: null,
    isLoading: true,
    isError: false,
  });

  const [company, dispatchCompany] = useReducer(dataFetchReducer, {
    fetchedData: null,
    isLoading: true,
    isError: false,
  });

  const [selectedEnvironment, setSelectedEnvironment] = useState(null)

  
  const getEnvByRuleKey = (rule_key) => {
    axios.get("/dash/rule_engine/rule/" + rule_key + "/environment").then((response) => {
      setSelectedEnvironment(response.data.environment);
    });
  };

  useEffect(() => {
    if (canUseMultiEnvironment) {
      getEnvByRuleKey(rule_key)
    }
  }, [canUseMultiEnvironment, rule_key])

  const toRuleDraft = useCallback(() => {
    if (canUseMultiEnvironment === null) return;
    if (canUseMultiEnvironment && selectedEnvironment === null) return;
    dispatchRule({ type: "data_fetch_init" });
    let params = { environment: 'production' };
    if (selectedEnvironment) {
      params = { environment: selectedEnvironment}
    }
    const timer = setTimeout(() => {
      let rule_draft = null;
      axios
        .get("/dash/rule_engine/rule/" + rule_key + "/rule_draft", { params: params })
        .then((response) => {
          rule_draft = response.data;
          setRuleDefinition({
            rule_key: rule_key,
            name: rule_draft.name,
            description: rule_draft.description,
            event_type: rule_draft.event_type.enum,
            is_current_version: false,
            version: rule_draft.rule.version,
            version_date: rule_draft.rule.version_date,
            company_key: rule_draft.company_key || "",
            tree_data: rule_draft.tree_data,
            user_variables: rule_draft.user_variables,
            isDraft: true,
            hasRuleDraft: true,
            environment: selectedEnvironment
          });
          
        })
        .catch((error) => {
          if ((error.response || {}).status === 403)
            dispatchRule({ type: "data_fetch_failure_403" });
          else if ((error.response || {}).status === 404)
            dispatchRule({ type: "data_fetch_failure_404" });
          else dispatchRule({ type: "data_fetch_failure" });
        });
      }, 500);
    return () => {
      clearTimeout(timer);
    };
  },[canUseMultiEnvironment, rule_key, selectedEnvironment])

  const toRule = useCallback(()=>{
    if (canUseMultiEnvironment === null) return;
    if (canUseMultiEnvironment && selectedEnvironment === null) return;
    dispatchRule({ type: "data_fetch_init" });
    let params = { 
      environment: "production",
      version: version };
      if (selectedEnvironment) {
        params.environment = selectedEnvironment;
      }
    const timer = setTimeout(() => {
      axios
        .get("/dash/rule_engine/rule/" + rule_key, { params: params })
        .then((response) => {
          let rule = response.data;
          dispatchRule({
            type: "data_fetch_success",
            payload: rule,
          });
          setRuleDefinition({
            rule_key: rule_key,
            tree_data: rule.tree_data,
            name: rule.name,
            description: rule.description,
            event_type: rule.event_type.enum,
            is_current_version: rule.is_current_version,
            version: rule.version,
            version_date: rule.version_date,
            company_key: rule.company_key || "",
            user_variables: rule.user_variables,
            isDraft: false,
            hasRuleDraft: rule.has_rule_draft,
            environment: selectedEnvironment
          });
        })
        .catch((error) => {
          if ((error.response || {}).status === 403)
            dispatchRule({ type: "data_fetch_failure_403" });
          else if ((error.response || {}).status === 404)
            dispatchRule({ type: "data_fetch_failure_404" });
          else dispatchRule({ type: "data_fetch_failure" });
        });
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [canUseMultiEnvironment, selectedEnvironment, version, rule_key]);

  useEffect(() => {
    if (rule_key !== "new_rule" && version !== "rule_draft") {
      toRule()
    } else if (version === "rule_draft") {
      toRuleDraft()
    }
  }, [rule_key, version, toRule, toRuleDraft]);

  useEffect(() => {
    dispatchCompany({ type: "data_fetch_init" });
    if (!user_data.business_group_key) {
      const timer = setTimeout(() => {
        axios
          .get("/dash/rule_engine/company")
          .then((response) => {
            dispatchCompany({
              type: "data_fetch_success",
              payload: response.data,
            });
          })
          .catch((error) => {
            if ((error.response || {}).status === 403)
              dispatchCompany({ type: "data_fetch_failure_403" });
            else if ((error.response || {}).status === 404)
              dispatchCompany({ type: "data_fetch_failure_404" });
            else dispatchCompany({ type: "data_fetch_failure" });
          });
      }, 500);
      return () => {
        clearTimeout(timer);
      };
    } else {
      const timer = setTimeout(() => {
        axios
          .get("/dash/rule_engine/companies")
          .then((response) => {
            dispatchCompany({
              type: "data_fetch_success",
              payload: response.data,
            });
          })
          .catch((error) => {
            if ((error.response || {}).status === 403)
              dispatchCompany({ type: "data_fetch_failure_403" });
            else if ((error.response || {}).status === 404)
              dispatchCompany({ type: "data_fetch_failure_404" });
            else dispatchCompany({ type: "data_fetch_failure" });
          });
      }, 500);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [rule_key, user_data.business_group_key]);

  useEffect(() => {
    let payload = {
      page_number: 0,
      page_rows: 1000,
      region: rule.environment
    };
    dispatchLists({ type: "data_fetch_init" });

    const timer = setTimeout(() => {
      axios
        .get("/dash/lists/lists", { params: payload })
        .then((response) => {
          dispatchLists({
            type: "data_fetch_success",
            payload: response.data,
          });
        })
        .catch((error) => {
          if ((error.response || {}).status === 403)
            dispatchLists({ type: "data_fetch_failure_403" });
          else if ((error.response || {}).status === 404)
            dispatchLists({ type: "data_fetch_failure_404" });
          else dispatchLists({ type: "data_fetch_failure" });
        });
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [rule_key, rule.environment]);

  useEffect(() => {
    if (ruleDefinition) {
      let payload = {
        page_number: 0,
        page_rows: 25,
        event_type: ruleDefinition.event_type,
        environment: ruleDefinition.environment
      };

      dispatchSignals({ type: "data_fetch_init" });
      const timer = setTimeout(() => {
        axios
          .get("/dash/signals/signals", {
            params: payload,
            headers: {
              "COMPANY-KEY": ruleDefinition.company_key,
            },
          })
          .then((response) => {
            dispatchSignals({
              type: "data_fetch_success",
              payload: response.data,
            });
          })
          .catch((error) => {
            if ((error.response || {}).status === 403)
              dispatchSignals({ type: "data_fetch_failure_403" });
            else if ((error.response || {}).status === 404)
              dispatchSignals({ type: "data_fetch_failure_404" });
            else dispatchSignals({ type: "data_fetch_failure" });
          });
      }, 500);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [ruleDefinition]);

  useEffect(() => {
    if (ruleDefinition) {
      let payload = {
        page_number: 0,
        page_rows: 25,
        event_type: ruleDefinition.event_type,
      };

      dispatchScripts({ type: "data_fetch_init" });
      const timer = setTimeout(() => {
        axios
          .get("/dash/scripts/scripts", {
            params: payload,
            headers: {
              "COMPANY-KEY": ruleDefinition.company_key,
            },
          })
          .then((response) => {
            dispatchScripts({
              type: "data_fetch_success",
              payload: response.data,
            });
          })
          .catch((error) => {
            if ((error.response || {}).status === 403)
              dispatchScripts({ type: "data_fetch_failure_403" });
            else if ((error.response || {}).status === 404)
              dispatchScripts({ type: "data_fetch_failure_404" });
            else dispatchScripts({ type: "data_fetch_failure" });
          });
      }, 500);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [ruleDefinition]);

  useEffect(() => {
    if (ruleDefinition) {
      dispatchProcessorsVariables({ type: "data_fetch_init" });
      let headersRequest = {
        headers: {
          "COMPANY-KEY": ruleDefinition.company_key,
        }
      };
      const timer = setTimeout(() => {
        axios
          .get(
            "/dash/rule_engine/company_event/" +
              ruleDefinition.event_type +
              "/processors_variables?environment=" +
              ruleDefinition.environment,
            headersRequest
          )
          .then((response) => {
            dispatchProcessorsVariables({
              type: "data_fetch_success",
              payload: response.data,
            });
          })
          .catch((error) => {
            if ((error.response || {}).status === 403)
              dispatchProcessorsVariables({ type: "data_fetch_failure_403" });
            else if ((error.response || {}).status === 404)
              dispatchProcessorsVariables({ type: "data_fetch_failure_404" });
            else dispatchProcessorsVariables({ type: "data_fetch_failure" });
          });
      }, 500);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [ruleDefinition]);

  useEffect(() => {
    if (ruleDefinition) {
      dispatchCompanyEvent({ type: "data_fetch_init" });
      let headersRequest = {
        headers: {
          "COMPANY-KEY": ruleDefinition.company_key,
        },
      };
      const timer = setTimeout(() => {
        axios
          .get(
            "/dash/rule_engine/company_event/" + ruleDefinition.event_type + "?environment=" + ruleDefinition.environment,
            headersRequest
          )
          .then((response) => {
            dispatchCompanyEvent({
              type: "data_fetch_success",
              payload: response.data,
            });
          })
          .catch((error) => {
            if ((error.response || {}).status === 403)
              dispatchCompanyEvent({ type: "data_fetch_failure_403" });
            else if ((error.response || {}).status === 404)
              dispatchCompanyEvent({ type: "data_fetch_failure_404" });
            else dispatchCompanyEvent({ type: "data_fetch_failure" });
          });
      }, 500);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [ruleDefinition]);

  if (!ruleDefinition && rule_key === "new_rule" && company.fetchedData) {
    return (
      <div>
        <NewRuleDialog
          setRuleDefinition={setRuleDefinition}
          company={company.fetchedData}
          rule_key={rule_key}
          open={true}
        />
      </div>
    );
  } else {
    if (
      companyEvent.fetchedData &&
      lists.fetchedData &&
      company.fetchedData &&
      signals.fetchedData &&
      scripts.fetchedData &&
      processorsVariables.fetchedData
    ) {
      return (
        <RulesContext.Provider
          value={{
            lists: listSorter(lists.fetchedData.data,"name"),
            signals_list: listSorter(signals.fetchedData.data,"description"),
            scripts_list: listSorter(scripts.fetchedData.data,"description"),
            fields_mapper_list: listSorter(companyEvent.fetchedData.field_mapping.mappings,"description"),
            processors_variables: listSorter(processorsVariables.fetchedData,"description"),
            boxes: companyEvent.fetchedData.boxes
          }}
        >
          <RuleTreeEngine 
            ruleDefinition={ruleDefinition}
            toRuleDraft={toRuleDraft}
          />
        </RulesContext.Provider>
      );
    } else if (
      rule.isError ||
      companyEvent.isError ||
      lists.isError ||
      company.isError ||
      signals.isError ||
      scripts.isError ||
      processorsVariables.isError
    ) {
      return (
        <div style={{ height: "80vh", display: "flex" }}>
          <p
            className={["blueText", "titleSize"].join(" ")}
            style={{ margin: "auto", textAlign: "center" }}
          >
            {rule.errorMessage ||
              companyEvent.errorMessage ||
              lists.errorMessage ||
              company.errorMessage ||
              signals.errorMessage ||
              scripts.errorMessage ||
              processorsVariables.errorMessage}
          </p>
        </div>
      );
    } else if (
      rule.isLoading ||
      companyEvent.isLoading ||
      lists.isLoading ||
      company.isLoading ||
      signals.isLoading ||
      scripts.isLoading ||
      processorsVariables.isLoading
    ) {
      return (
        <div style={{ height: "100%", display: "flex" }}>
          <CircularProgress style={{ margin: "auto" }} />
        </div>
      );
    } else return null;
  }
}

export default RuleTree;
