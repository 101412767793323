import React, {useState} from "react";
import Collapse from "@material-ui/core/Collapse";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import IconButton from "@material-ui/core/IconButton";
import DataField from "../../utils/DataField"
import {clientAge} from "../../utils/utils"
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import moment from "moment"
import UnlockDialog from "./UnlockDialog/UnlockDialog"
import {translateLockType, translateLockSource, translateLockStatus} from "../../utils/utilsLock"

const useStyles = makeStyles((theme) => ({
    tableStyle: {
      border: "none",
      boxShadow: "none",
    },
    tableText: {
      fontFamily: "Open Sans",
      fontWeight: "normal",
      fontSize: "12px",
      lineHeight: "16px",
      color: "#141414",
      textAlign: "center",
      margin: "10px",
      padding: "6px",
      minWidth: "120px"
    },
    tableHeader: {
      textAlign: "center",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: "normal",
      color: "#6F6F6F",
      fontSize: "14px",
      lineHeight: "19px",
      padding: "6px",
    },
    even: {
      background: "#F3F3F3",
    },
    odd: {
      background: "#FFFFFF",
    },
  }));

function LocksCard(props) {
    const classes = useStyles();
    useTheme();

    let {locks} = props
    let {handleRefreshPage} = props
    let {enableLockActions} = props

    const [expanded, setExpanded] = useState(false);

    const [lockKey, setLockKey] = useState(null);
    const [openUnlockDialog, setOpenUnlockDialog] = useState(false);

    const handleButtonClick = (lock_key) => {
        setLockKey(lock_key)
        setOpenUnlockDialog(true)
    }

    const handleDialogClose = (refresh=false) => {
        if (refresh) handleRefreshPage()
		setOpenUnlockDialog(false);
	  };

    const handleExpand = (event) => {
        event.stopPropagation();
        setExpanded(!expanded);
    };

    const getClass = (rowIndex) => {
        if (rowIndex % 2 === 0) {
          return classes.even;
        }
    
        return classes.odd;
      };   

    return (
        <div className="analysisCard">
            <div style={{display:"flex"}}>
                <div 
                    className={["blueText", "subtitleSize", "bold"].join(" ")}
                    style={{margin:"auto 0px"}}
                >
                    Bloqueios
                </div>
                <IconButton style={{marginLeft: "auto"}} onClick={handleExpand}>
                    {expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </IconButton>                             
            </div>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
            <div className={["internalCollapse", "oneColumn"].join(" ")}>
                {locks.map((lock, index) => (
                    <div className="internalCardContainer" style={{display:"flex", flexDirection: "column"}}>
                        <DataField
                            title={"Tipo"}
                            label={translateLockType(lock.lock_type)}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="5px"
                        />
                        <DataField
                            title={"Status"}
                            label={translateLockStatus(lock.lock_status)}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="5px"
                        />
                        <DataField
                            title={"Fonte de Criação"}
                            label={translateLockSource(lock.lock_source)}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="5px"
                        />
                        <DataField
                            title={"Data do Bloqueio"}
                            label={clientAge(lock.lock_date, null, "years_months")}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="5px"
                        />
                        <div className={["internalCardContainer", "blueText", "normalMediumSize"].join(" ")}>
                            Eventos do Bloqueio
                        </div>
                        <div>
                            <Paper className={classes.tableStyle}>
                            <Table style={{ padding: "8px" }}>
                                <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tableHeader}>Status</TableCell>
                                    <TableCell className={classes.tableHeader}>Data</TableCell>
                                    <TableCell className={classes.tableHeader}>Analista Responsável</TableCell>
                                    <TableCell className={classes.tableHeader}>E-mail</TableCell>
                                    <TableCell className={classes.tableHeader}>Observações</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {(lock.lock_events || []).map((event,index) => (
                                    <TableRow
                                        key={index}
                                        className={getClass(index)}
                                    > 
                                    <TableCell className={classes.tableText}>
                                        {translateLockStatus(event.new_status)}
                                    </TableCell>
                                    <TableCell className={classes.tableText}>
                                        {moment(event.event_date).format("DD/MM/YYYY")}
                                    </TableCell>
                                    <TableCell className={classes.tableText}>
                                        {event.analyst_name || "Não Aplicável"}
                                    </TableCell>
                                    <TableCell className={classes.tableText}>
                                        {event.analyst_email || "Não Aplicável"}
                                    </TableCell>
                                    <TableCell className={classes.tableText}>
                                        {event.observation || "-"}
                                    </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                            </Paper>
                        </div>
                        {lock.lock_status === "blocked" && enableLockActions ?
                        <div className="internalCardContainer" style={{display: "flex"}}>
                            <div 
                                className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")}
                                style={index === 0 ? {marginLeft:"auto"}: null}
                                onClick={() => handleButtonClick(lock.lock_key)}
                            >
                                Desbloquear
                            </div>
                        </div> : null}
                    </div>))}
                </div>
            </Collapse> 
            <UnlockDialog
                open={openUnlockDialog}
                onClose={handleDialogClose}
                lockKey={lockKey}
            />
        </div>
    );
}

export default LocksCard;