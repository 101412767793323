import React, {useState} from "react";
import { getCardIcon } from "../../../utils/utils"
import { IconButton, Collapse } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import CriminalRecordsTable from "./CriminalRecordsTable/CriminalRecordsTable";
import Tooltip from '@material-ui/core/Tooltip';


function CriminalRecordsCard(props) {

    let {criminal_records} = props

    const [expanded, setExpanded] = useState(false);
    const [firstTime, setFirstTime] = useState(true);

    let initialExpand = criminal_records.flag === "positive"? false : true
    if (initialExpand !== expanded && firstTime===true){
        setExpanded(initialExpand);
    }
    const handleExpand = (event) => {
        event.stopPropagation();
        setFirstTime(false)
        setExpanded(!expanded);
    };

    return (
        <div className="analysisCard">
            <div style={{display:"flex"}}>
                <div 
                    className={["blueText", "subtitleSize", "bold"].join(" ")}
                    style={{margin:"auto 0px"}}
                >
                    Antecedentes Criminais
                </div>
                <div style={{ margin: "auto 10px" }}>
                    <Tooltip title={criminal_records.description}>
                    {getCardIcon(criminal_records.flag)}
                    </Tooltip>
                </div>
                {((criminal_records.data || [])[0] || {})?.result_file_link ? 
                <IconButton style={{marginLeft: "auto"}} onClick={handleExpand}>
                    {expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </IconButton>             
                : null}                    
            </div>
            {((criminal_records.data || [])[0] || {})?.result_file_link ?
            <Collapse in={expanded} timeout="auto" unmountOnExit>
            <div 
                className={["normalText", "normalMediumSize", "internalCardContainer"].join(" ")}
            >
                <CriminalRecordsTable criminal_records_data={criminal_records.data} criminal_records_description={criminal_records.description}/>
            </div>
        </Collapse> 
        : null}                
        </div>
        );
}

export default CriminalRecordsCard