import React from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import DataField from "../../../../../containers/@GeneralComponents/DataField"
const useStyles = makeStyles((theme) => ({
  errorMessage: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: "14px",
    color: "#FF0000",
    textAlign: "center",
  },
  dialogTitle: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: "15px",
    color: "#0b1f82",
  },
  courtContainer: {
    height: "200px",
    display: "table-cell",
    verticalAlign: "middle",
    textAlign: "center",
    minWidth: "300px"
  },
  disabledLabel: {
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "19px",
    color: "#999",
  },
  dialogContent: {
    marginBottom: "20px"
  }
}))

const CourtDialog = (props) => {
  const classes = useStyles()
  useTheme()

  const { processes_data, index, open, onClose } = props

  return (
      <Dialog
        open={open}
        onClose={onClose}
        keepMounted={false}
      >
        <DialogTitle className={classes.dialogTitle}>
          Tribunal
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <DataField
              title={"Nome"}
              label={(processes_data[index] || {}).court_data?.court_name ?? '-'}
            />
            <DataField
              title={"Tipo"}
              label={(processes_data[index] || {}).court_data?.court_type ?? '-'}
            />
            <DataField
              title={"Nível"}
              label={(processes_data[index] || {}).court_data?.court_level ?? '-'}
            />
            <DataField
              title={"Corpo Julgador"}
              label={(processes_data[index] || {}).court_data?.court_judging_body ?? '-'}
            />
          </div>
        </DialogContent>
      </Dialog>
    )
}

export default CourtDialog
