import React from "react"
import { formatScore } from "../../utils/utils"

function ScoreBox(props) {

    let { fraud_status_events } = props

    if (fraud_status_events == null || fraud_status_events.length === 0 ||
        fraud_status_events[0].decision_metadata == null || fraud_status_events[0].decision_metadata.score == null ||
        fraud_status_events[0].decision_metadata.score === {}) {
        return (
            <div className="analysisCard" style={{ flexDirection: "row" }}>
                <div
                    className={["blueText", "subtitleSize"].join(" ")}
                    style={{ marginTop: "auto", marginBottom: "auto" }}
                >
                    Score:
                </div>
                <div className={["labelText", "subtitleSize", "labelSpace"].join(" ")} >
                    Informação Indisponível
                </div>
            </div>
        )
    }

    else {
        let scoreObject = formatScore(fraud_status_events[0].decision_metadata.score)
        return (
            <div className="analysisCard" style={{ flexDirection: "row", justifyContent: "space-evenly" }}>
                <div
                    className={["blueText", "subtitleSize"].join(" ")}
                    style={{ marginTop: "auto", marginBottom: "auto" }}
                >
                    Score:
                </div>
                <div
                    className={["circle", "medium", "bold", "normalText", "subtitleSize"].join(" ")}
                    style={{ color: "white", backgroundColor: scoreObject.color, margin: "auto 0px" }}
                >
                    {fraud_status_events[0].decision_metadata.score}
                </div>
                <div className={["normalText", "subtitleSize", "bold"].join(" ")} style={{ margin: "auto 0px", color: scoreObject.color }}>
                    {scoreObject.text}
                </div>
                {scoreObject.icon}
            </div>
        )

    }



}

export default ScoreBox