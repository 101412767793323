import React, { useEffect, useReducer, useState, useContext } from "react";
import CreditAnalysisNaturalPersonCards from "./CreditAnalysisNaturalPersonCards";
import { dataFetchReducer } from "../../Utils/requestUtils";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { IconButton } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import Collapse from "@material-ui/core/Collapse";
import AuthContext from "../../../context/auth-context";

export function ChainedAnalysisNaturalPersonCards(props) {

  const { t } = useTranslation()
  let user_data = useContext(AuthContext).user_data;

  const { preparePDF, setButtonExport2PDF, pdf, credit_proposal_natural_person_key, expandedStates } = props
  const { expandedSCR, setExpandedSCR, expandedSerasa, setExpandedSerasa, expandedBoavista, setExpandedBoavista, expandedQuod, setExpandedQuod } = expandedStates

  const [expandedChained, setExpandedChained] = useState(false)
  const handleExpand = (event) => {
    event.stopPropagation();
    setExpandedChained(!expandedChained);
  };

  const [chainedCreditAnalysis, dispatchChainedCreditAnalysis] = useReducer(
    dataFetchReducer,
    { fetchedData: null, isLoading: true, isError: false }
  );

  useEffect(() => {
    dispatchChainedCreditAnalysis({ type: "data_fetch_init" });
    const timer = setTimeout(() => {
      axios
        .get("/dash/credit_analysis/natural_person/" + credit_proposal_natural_person_key)
        .then((response) => {
          dispatchChainedCreditAnalysis({
            type: "data_fetch_success",
            payload: response.data,
          });
        })
        .catch((error) => {
          if ((error.response || {}).status === 403)
            dispatchChainedCreditAnalysis({ type: "data_fetch_failure_403" });
          else if ((error.response || {}).status === 404)
            dispatchChainedCreditAnalysis({ type: "data_fetch_failure_404" });
          else dispatchChainedCreditAnalysis({ type: "data_fetch_failure" });
        });
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [credit_proposal_natural_person_key]);

    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ flexGrow: "1" }} className="analysisCardContainer">
          <div className="analysisCard">
            <div style={{ display: "flex", alignItems:"center", height: "48px"}}>
              <div className={["blueText", "subtitleSize"].join(" ")}>{t("other_request")}</div>
              {chainedCreditAnalysis.fetchedData&&
              <IconButton style={{ marginLeft: "auto" }} onClick={handleExpand}>
                {expandedChained ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </IconButton>}
            </div>
            {chainedCreditAnalysis.fetchedData&&
            <Collapse in={expandedChained} timeout="auto" unmountOnExit>
              <div>
                {user_data.business_group_key!==null&&
                  <div className={["labelText", "subtitleSize", "marginTopBottom2"].join(" ")}>
                    {chainedCreditAnalysis.fetchedData.company_name}
                  </div>}
                <div className={["labelText", "subtitleSize", "marginTopBottom2"].join(" ")}>
                  {chainedCreditAnalysis.fetchedData.is_manual_analysis?
                  `${t("manual_request")} `:
                  `${t("client")} ` + (chainedCreditAnalysis.fetchedData.id?`#${chainedCreditAnalysis.fetchedData.id} `:"")}
                  {(chainedCreditAnalysis.fetchedData || {}).created_at && t("created_date", {created_date: chainedCreditAnalysis.fetchedData.created_at})}
                </div>
              </div>
              {chainedCreditAnalysis.fetchedData&&
              <CreditAnalysisNaturalPersonCards
                credit_analysis={chainedCreditAnalysis}
                preparePDF={preparePDF}
                setButtonExport2PDF={setButtonExport2PDF}
                pdf={pdf}
                credit_proposal_natural_person_key={credit_proposal_natural_person_key}
                expandedStates={{ expandedSCR, setExpandedSCR, expandedSerasa, setExpandedSerasa, expandedBoavista, setExpandedBoavista, expandedQuod, setExpandedQuod }}
                isChained={true}
              />}
            </Collapse>}
          </div>
        </div>
      </div>
    )
}