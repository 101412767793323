import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from 'moment'
import {formatAsMoneyMinimum2Digits} from "../../../../utils/utils"

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
  },
  tableText: {
    fontFamily: "Open Sans",
    fontWeight: 'normal',
    fontSize: "12px",
    lineHeight: "16px",
    color: "#141414",
    textAlign: "center",
    margin: "10px",
    padding: "6px",
    maxWidth: "80px"
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: '#6F6F6F',
    fontSize: "14px",
    lineHeight: "19px",
    padding: "6px",
    maxWidth: "80px"
  },
  even: {
    background: "#F3F3F3",
  },
  odd: {
    background: "#FFFFFF",
  },
}));

function SocialAssistanceTable(props) {
  const classes = useStyles();
  useTheme();

  const getClass = (rowIndex) => {
    if (rowIndex % 2 === 0) {
      return classes.even;
    }

    return classes.odd;
  };

  return (
    <Paper className={classes.tableStyle}>
      <Table style={{ padding: "8px", marginBottom: "30px" }}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader}>Atualmente Recebe Assistência</TableCell>
            <TableCell className={classes.tableHeader}>Recebeu Bolsa Família nos últimos 12 meses</TableCell>
            <TableCell className={classes.tableHeader}>Quantidade Total de Assistências Ativas</TableCell>
            <TableCell className={classes.tableHeader}>Renda</TableCell> 
            <TableCell className={classes.tableHeader}>Total de Assistências Recebidas</TableCell> 
            <TableCell className={classes.tableHeader}>Valor Total Recebido</TableCell> 
            <TableCell className={classes.tableHeader}>Quantidade Total de Parcelas Recebidas</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow className={getClass(0)}>
              <TableCell className={classes.tableText}>
              {props.social_assistance_data[0].is_receiving_assistance === false ? "não" :  "sim"}
              </TableCell>
              <TableCell className={classes.tableText}>
              {props.social_assistance_data[0].received_bolsa_familia_last_twelve_months === false ? "não" :  "sim"}
              </TableCell>
              <TableCell className={classes.tableText}>
              {props.social_assistance_data[0].total_active_assistances}
              </TableCell>
              <TableCell className={classes.tableText}>
              {props.social_assistance_data[0].total_income ? 
              formatAsMoneyMinimum2Digits(props.social_assistance_data[0].total_income * 100)
            : "Informação não disponível"}
              </TableCell>
              <TableCell className={classes.tableText}>
                {props.social_assistance_data[0].total_assistances}
              </TableCell>
              <TableCell className={classes.tableText}>
              {props.social_assistance_data[0].total_amount_received ? 
              formatAsMoneyMinimum2Digits(props.social_assistance_data[0].total_amount_received * 100)
            : "-"}
              </TableCell>
              <TableCell className={classes.tableText}>
              {props.social_assistance_data[0].total_installments_received}
              </TableCell>
            </TableRow>
        </TableBody>
      </Table>
      {props.social_assistance_data[0].social_assistances.length > 0 &&
        <>
          <div className={["blueText", "normalMediumSize", "bold"].join(" ")}
              style={{margin:"10px"}}>
              Detalhes
          </div> 
          <Table style={{ padding: "8px" }}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeader}>Programa</TableCell> 
                <TableCell className={classes.tableHeader}>Valor</TableCell>
                <TableCell className={classes.tableHeader}>Quantidade de Parcelas</TableCell>
                <TableCell className={classes.tableHeader}>Data de Início</TableCell>
                <TableCell className={classes.tableHeader}>Data de Término</TableCell>
                <TableCell className={classes.tableHeader}>Cidade</TableCell>
                <TableCell className={classes.tableHeader}>Estado</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.social_assistance_data[0].social_assistances.map((assistance,index) => (
                <TableRow
                  key={index}
                  className={getClass(index)}
                >
                  <TableCell className={classes.tableText}>
                    {assistance.name ? assistance.name : "-"}
                  </TableCell>
                  <TableCell className={classes.tableText}>
                    {assistance.assistance_amount ? formatAsMoneyMinimum2Digits(assistance.assistance_amount * 100) : "-"}
                  </TableCell>
                  <TableCell className={classes.tableText}>
                    {assistance.total_installments ? assistance.total_installments : "-"}
                  </TableCell>
                  <TableCell className={classes.tableText}>
                    {assistance.assistance_start_date == null ? "-" : moment(assistance.assistance_start_date).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell className={classes.tableText}>
                    {assistance.assistance_end_date == null ? "-" : moment(assistance.assistance_end_date).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell className={classes.tableText}>
                    {assistance.city ? assistance.city : "-"}
                  </TableCell>
                  <TableCell className={classes.tableText}>
                    {assistance.state ? assistance.state : "-"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      }
    </Paper>
  );
}

export default SocialAssistanceTable;
