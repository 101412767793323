import React, {useState, useEffect} from 'react'
import { useTranslation } from "react-i18next";
import {GetAlertsComponent,translateType,setObjectByPath,validateConstantValues} from "./utils"
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from "@material-ui/core/Tooltip";
import _ from "lodash"
import Checkbox from '@material-ui/core/Checkbox';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import HelpIcon from "@material-ui/icons/Help";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
//MANIPULAÇÃO DE TEXTO - Texto Contém 

const theme = createMuiTheme({
    palette:{
        primary: {
            main:"#0b1f82"
        },
    }
  });

export function StringContainBox (props) {
    const { t } = useTranslation();
    let {node} = props
    let {read_only} = props
    let {handleConfigurationOpen} = props
    let {handleNodeRemove} = props

    return (
        <div>
            {!read_only ?
                <div style={{display:"flex", alignItems: "center"}}>
                    <div style={{display:"flex", width:"100%", cursor:"pointer", margin: "auto 0px"}} onClick={() => handleConfigurationOpen(node)}>
                        {t("Texto contém texto")}
                        {(node.node.alerts || []).length > 0?
                        <GetAlertsComponent alerts={node.node.alerts} />  : null} 
                    </div>
                    <IconButton style={{width: 32, height: 32}} aria-label="duplicate" onClick={() => props.handleNodeDuplication(node)}>
                        <ContentCopyIcon style={{width: 20, height: 20}} />
                    </IconButton>
                    <IconButton style={{width: 32, height: 32}} aria-label="close" onClick={() => handleNodeRemove(node)}>
                        <CloseIcon style={{width: 20, height: 20}} />
                    </IconButton>
                </div>
            :
                <div style={{display:"flex"}}>
                    <div style={{display:"flex", width:"100%", cursor:"pointer", margin: "auto 0px"}} onClick={() => handleConfigurationOpen(node)}>
                        {t("Texto contém texto")}
                    </div>
                </div>
            }
        </div>
    )
}

export function StringContainConfiguration(props){
    const { t } = useTranslation();
    let {node} = props
    let {read_only} = props
    let {handleNodeChange} = props
    let {handleConfigurationClose} = props

    const [nodeState, setNodeState] = useState(node)

    useEffect(()=>{
        setNodeState(node)
    },[node])

    const handleNodeStateChange = (obj_key,value) => {
        let new_node = _.cloneDeep(nodeState)
        setObjectByPath(new_node.node,obj_key,value)
        setNodeState(new_node)
    }

    const handleSave = () => {
        handleConfigurationClose()
        handleNodeChange(nodeState)
    }

    const validateAllValues = () => {
        return validateConstantValues("bool",nodeState.node.properties.decode_escape_string)
    }

    return(
        <div style={{flexGrow:"1", padding: "20px", display:"flex", flexDirection:"column"}}>
            <div style={{display: "flex"}}>
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>{t(node.node.description)}</span>
                <Tooltip title={<div className="tooltipText"><ul>{node.node.helperText.map((help,index) => (<li key={index}>{t(help)}</li>))}</ul></div>}>
                    <HelpIcon style={{fontSize: "14px", display:"flex", margin:"auto 5px"}}/>
                </Tooltip>
            </div>
            <div className="internalCardContainer" style={{display:"flex", flexDirection: "column"}}>
                <ThemeProvider theme={theme}>
                    <div>
                        <div style={{display:"flex", justifyContent:"space-evenly"}}>
                            <div style={{display:"flex"}}>
                                <Checkbox
                                    onChange={(e) => handleNodeStateChange("properties.decode_escape_string", e.target.checked)}
                                    checked={nodeState.node.properties.decode_escape_string}
                                    color="primary"
                                />
                                <div>
                                    <p 
                                        className={["normalText", "normalMediumSize"].join(" ")} 
                                    >   
                                        {t("Considerar caracteres especiais")}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </ThemeProvider>        
            </div>
            <div style={{display:"flex", margin: "auto auto 5px auto"}}>
                <div
                    className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")}
                    style={{width:"120px"}}
                    onClick={handleConfigurationClose}
                >
                    {read_only ? "X" : t("discard")}
                </div>
                {!read_only && 
                <div
                    className={
                        validateAllValues()
                        ?
                        ["button", "standard", "normalText", "normalMediumSize"].join(" ")
                        :
                        ["button", "standard", "normalText", "normalMediumSize","disabled"].join(" ")}
                    style={{width:"120px"}}
                    onClick={validateAllValues() ? handleSave : null}
                >
                    {t("save")}
                </div>}   
            </div>
        </div>
    )
}

export class StringContainBoxClass {
    constructor(rulesContext){
        this.rulesContext = rulesContext
    }
    validate(node){
        let put_error = false
        let alerts = []
        //Validate if node has at least one children
        if((node.node.children || []).length !== 2){
            put_error = true
            alerts.push("Este nó precisa ter exatamente 2 filhos")
        }
        //Validate if children of the node is string
        else{
            let non_string_children = node.node.children.filter((children) => {
                return children.instance.getType(children) !== "string"
            })
            if (non_string_children.length > 0){
                put_error = true
                let typeString = non_string_children.reduce((acc, children, index, arr) => {
                    let endString = index === arr.length -1 ? "." : ", "
                    return acc.concat(translateType(children.instance.getType(children)) + endString)
                }, "")
                alerts.push("Todos os filhos deste nó devem ter um retorno Texto. Atualmente existem " + typeString)
            } 
        }
        if (!validateConstantValues("bool",node.node.properties.decode_escape_string)){
            put_error = true
            alerts.push("A propriedade 'Busca em toda a Base QITech KYC' deve ser um 'Binário' válido")
        }
        //Validate if parent is valid
        if (node.parentNode != null) {
            if(node.parentNode.on_error){
                put_error = true
                alerts.push("O nó superior está inválida")
            }
        }
        return {validated: !put_error, alerts: alerts}
    }

    getType(node){
        return "bool"
    }
}