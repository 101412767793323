import React, {useState, useEffect} from 'react'
import {removeDuplicatesBy, titleCase} from "../../../../utils/utils"
import {getSeverityColor,getSeverityStatus, getTriggerRisk} from "../../../../utils/utilsAlert"
import moment from "moment"
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import HelpIcon from "@material-ui/icons/Help";
import Tooltip from "@material-ui/core/Tooltip";

function Triggers(props){

    const [noDuplicateTriggers, setNoDuplicateTriggers] = useState(null)

    useEffect(() => {
        if (props.triggers && props.triggers.length > 0){
            let reversedTriggers = props.triggers.reverse()
            let maximizedTrigger = getTriggerRisk(reversedTriggers)
            let noDuplicateTriggers_ = removeDuplicatesBy(trigger => trigger.category, maximizedTrigger)
            setNoDuplicateTriggers(noDuplicateTriggers_)
        }
	},[props.triggers])

    if (props.triggers == null || props.triggers.length ===0){
        return (
            <div style={{display: "flex", flexGrow:"1"}}>
                <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                    Nenhum gatilho disponível
                </div>
            </div>
        )
    }
    if (noDuplicateTriggers) {
        return (
            <div className="internalCardContainer">
                {noDuplicateTriggers.map((trigger,index)=>(
                <div key={index} style={{display: "flex", margin: "5px auto"}}>
                    <Tooltip title={<p className="tooltipText">{getSeverityStatus(trigger.risk_level)}</p>}>
                        <div style={{display:"flex", margin:"auto 0px"}}>
                            <ErrorOutlineIcon style={{color:getSeverityColor(trigger.risk_level)}}/>
                        </div>
                    </Tooltip>                    
                    <div className="labelSpace">
                        <div style={{display:"flex"}}>
                            <div className={["normalText", "normalMediumSize"].join(" ")}>
                                {titleCase(trigger.category_description, trigger.category)+ " | " + moment(trigger.occurrence_date).format("DD/MM/YYYY - HH:mm:ss")}
                            </div>
                            {(trigger.terminalList || []).length > 0 ? 
                            <Tooltip 
                                title={
                                    <div className="tooltipText">
                                        Lista de Terminais do Gatilho:
                                        <ul>
                                            {trigger.terminalList.map((terminal, index)=>(
                                                <li key={index}>{terminal}</li>
                                            ))}
                                        </ul>
                                    </div>
                                } 
                                arrow placement="right">
                                <HelpIcon style={{fontSize: "14px", display:"flex", margin:"auto 5px"}}/>
                            </Tooltip> : null
                            }
                        </div>
                        <div className={["labelText", "normalSmallSize"].join(" ")}>
                            {trigger.description}
                        </div>
                    </div>        
                </div>
                ))}
            </div>
        )
    }
    return null
}

export default Triggers