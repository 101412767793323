import React from "react";
import FormLabel from "./FormLabel";
import FileUploadToBase64 from "../../../../Utils/FileUploadToBase64";
import "../../../../../assets/styles/generalStyles.scss"

const FormUploadFile = props => {


    return(
    <div>
      <FormLabel
      value={props.labelValue}
      />
      <FileUploadToBase64
        onDone={props.onDone}
        accept={props.accept}
        fileSize={props.fileSize}
        maxFileSizeAlert={props.maxFileSizeAlert}
      />
      <div className={"formSmallWhiteSpace"}></div>
    </div>
  )
}

export default FormUploadFile;