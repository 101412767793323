import React from 'react'
import people from '../../../../assets/cardEntryIcons/people.svg';
import peopleGreen from '../../../../assets/cardEntryIcons/peopleGreen.svg';
import Tooltip from "@material-ui/core/Tooltip"
import PersonInformation from './PersonInformation/PersonInformation'
import DataField from "../../utils/DataField"
import {formatAddress, formatPhone} from "../../utils/utils"

function EnvolvedParties({ operation }) {

    let source;
    let destination;
    if(operation.operation_direction === "sent"){
        source = operation.client
        destination = operation.counterpart
    }else{
        source = operation.counterpart
        destination = operation.client
    }

    return (
        <div className="analysisCard">
            <div className="internalCardContainer" style={{display: "flex"}}>
                <img style={{margin:"auto 8px auto 0px"}} src={people} alt="people Logo"/>  
                <div className={["blueText", "subtitleSize"].join(" ")}>Partes Envolvidas</div>      
            </div>
            <div className="internalCardContainer" style={{marginTop:"15px"}}>
                <div style={{width:"100%", backgroundColor:"#DEF2FE", borderRadius:"12px", display:'flex', marginBottom:"20px"}}>
                    <div style={{width:"50%", padding:"20px"}}>
                        <div className={["blueText", "normalSize"].join(" ")} style={{display:"flex"}}>
                            ORIGEM
                            {operation.operation_direction === "sent"?
                            <Tooltip title={<p className="tooltipText">Cliente</p>}>
                                <div style={{display: "flex", margin: "auto auto auto 10px"}}>
                                    <img className="pinIconStyle" src={peopleGreen} alt="clientIcon Logo" />  
                                </div>
                            </Tooltip> 
                            :null}
                        </div>
                        {operation.operation_direction === "sent" ?
                        <PersonInformation person={operation.client}/>
                        : <PersonInformation person={operation.counterpart}/>}
                    </div>
                    <div style={{width:"50%", padding:"20px", marginTop: "20px"}}>
                            <div style={{display:"flex", flexDirection: "column"}}>
                                <DataField
                                    title={"Email"}
                                    label={source.email} 
                                    size={"normalMediumSize"}
                                    titleNoWrap={true}
                                    margin="5px"
                                />
                                <DataField
                                    title={"Telefone"}
                                    label={formatPhone((source || {}).phone, null)}
                                    size={"normalMediumSize"}
                                    titleNoWrap={true}
                                    margin="5px"
                                />
                                <DataField
                                    title={"Endereço"}
                                    label={formatAddress ((source|| {}).address, null)}
                                    size={"normalMediumSize"}
                                    titleNoWrap={true}
                                    margin="5px"
                                />
                            </div>
                        </div>
                </div>
                <div style={{width:"100%", backgroundColor:"#DEF2FE",borderRadius:"12px", display:'flex', marginBottom:"20px"}}>
                    <div style={{width:"50%", padding:"20px"}}>
                        <div className={["blueText", "normalSize"].join(" ")} style={{display:"flex"}}>
                            DESTINO
                            {operation.operation_direction === "received" ?
                            <Tooltip title={<p className="tooltipText">Cliente</p>}>
                                <div style={{display: "flex", margin: "auto auto auto 10px"}}>
                                    <img className="pinIconStyle" src={peopleGreen} alt="clientIcon Logo" />  
                                </div>
                            </Tooltip> 
                            :null}
                        </div>
                        {operation.operation_direction === "sent" ?
                        <PersonInformation person={operation.counterpart}/>
                        : <PersonInformation person={operation.client}/>}
                    </div>
                    <div style={{width:"50%", padding:"20px", marginTop: "20px"}}>
                            <div style={{display:"flex", flexDirection: "column"}}>
                                <DataField
                                    title={"Email"}
                                    label={destination.email}
                                    size={"normalMediumSize"}
                                    titleNoWrap={true}
                                    margin="5px"
                                />
                                <DataField
                                    title={"Telefone"}
                                    label={formatPhone((destination || {}).phone, null)}
                                    size={"normalMediumSize"}
                                    titleNoWrap={true}
                                    margin="5px"
                                />
                                <DataField
                                    title={"Endereço"}
                                    label={formatAddress ((destination || {}).address, null)}
                                    size={"normalMediumSize"}
                                    titleNoWrap={true}
                                    margin="5px"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default EnvolvedParties