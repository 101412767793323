import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import Collapse from "@material-ui/core/Collapse";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import IconButton from "@material-ui/core/IconButton";
import {serasa_translations} from "../../utils/utils"
import SerasaPartnersTable from "./SerasaPartnersTable/SerasaPartnersTable";

function SerasaPartnersCard(props) {
    const {t} = useTranslation()
    const [expanded, setExpanded] = useState(true);

    let {partners_data, report_type} = props
    let hasPartnersData = !!partners_data.length

    const handleExpand = (event) => {
        event.stopPropagation();
        setExpanded(!expanded);
    };

    return (
        <div className="analysisCard" >
            <div style={{display:"flex"}}>
                <div 
                    className={["blueText", "subtitleSize", "bold"].join(" ")}
                    style={{margin:"auto 0px"}}
                >
                    {t("company_partners")}
                </div>
                <div style={{margin:"auto 10px"}}>
                    {hasPartnersData
                        ? partners_data.has_resctrictions  
                            ? serasa_translations.negative.siglum 
                            : serasa_translations.positive.siglum
                        : null}
                </div>
                <IconButton style={{marginLeft: "auto"}} onClick={handleExpand}>
                    {expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </IconButton>             
            </div>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                {hasPartnersData ? 
                    <div className={["normalText", "normalMediumSize", "internalCardContainer"].join(" ")}>
                        <SerasaPartnersTable partners_data={partners_data} report_type={report_type}/>
                    </div>
                    :
                    t("company_partners_not_available")
                }
            </Collapse> 
        </div>
    );
}

export default SerasaPartnersCard