import React from "react";
import {getProperMetadata, credit_translations, formatAsMoney} from "../../utils/utils"
import { useTranslation } from "react-i18next";
import DataField from "../../utils/DataField";

function Summary(props) {    
    const { t } = useTranslation();

    const {credit_analysis, scoreToImg} = props

    let decision_metadata = credit_analysis.credit_proposal_natural_person_analysis_events ? getProperMetadata(credit_analysis.credit_proposal_natural_person_analysis_events) : "" // NP

    if (!decision_metadata || Object.keys(decision_metadata || {}).length === 0) {
		return (
            <div className="analysisCard">
                <div className={["blueText", "subtitleSize"].join(" ")}>{t("summary")}</div>
                <div style={{margin:"auto"}} className={["labelText", "normalMediumSize"].join(" ")}>{t("no_information_available_message")}</div>
            </div>
		);        
  	}
  	else{
        let score = (decision_metadata || {}).tree_score
        let { risk_level } = (decision_metadata || {})
        let credit_proposals = decision_metadata ? (decision_metadata || {}).credit_proposals : false
        let number_of_installments = (decision_metadata || {}).number_of_installments ? (decision_metadata || {}).number_of_installments : false
        let credit_limit_amount = (decision_metadata || {}).credit_limit_amount ? formatAsMoney('BRL',(decision_metadata || {}).credit_limit_amount) : false
        let annual_interest_rate = ((decision_metadata || {}).interest_rates || {}).annual_interest_rate ? ((decision_metadata || {}).interest_rates || {}).annual_interest_rate : false
        let cdi_percentage = ((decision_metadata || {}).interest_rates || {}).cdi_percentage ? ((decision_metadata || {}).interest_rates || {}).cdi_percentage : false
        let custom_variables_list = decision_metadata?.custom_variables ? Object.entries(decision_metadata.custom_variables) : []
        let user_variables_list = decision_metadata?.user_variables ? decision_metadata.user_variables : []

		return (
			<div className="analysisCard">
				<div className={["blueText", "subtitleSize"].join(" ")}>{t("summary")}</div>
                <div style={{display: "flex", flexDirection:"column"}}>
                {!!score &&
                    <div>
                        <img src={scoreToImg} alt="Score" />    
                    </div>}
                    {credit_analysis.analysis_status&&
                    <DataField
                        title={t("analysis_status")}
                        label={t(credit_analysis.analysis_status)}
                        size={"normalMediumSize"}
                        titleNoWrap={false}
                        labelStyles={{color: credit_translations.analysis_status[credit_analysis.analysis_status].color}}
                        />}
                    {!!risk_level&&
                    <DataField
                        title={t("risk_level")}
                        label={risk_level}
                        size={"normalMediumSize"}
                        titleNoWrap={false}
                        />}
                    {decision_metadata.reason&&
                    <DataField
                        title={t("reason")}
                        label={t(decision_metadata.reason_description||decision_metadata.reason)}
                        size={"normalMediumSize"}
                        titleNoWrap={false}
                        />}
                    {credit_limit_amount&&
                    <DataField
                        title={t("credit_limit_amount")}
                        label={credit_limit_amount}
                        size={"normalMediumSize"}
                        titleNoWrap={false}
                        />}
                    {annual_interest_rate&&
                    <DataField
                        title={t("annual_interest_rate")}
                        label={`${annual_interest_rate} %`}
                        size={"normalMediumSize"}
                        titleNoWrap={false}
                        />}
                    {cdi_percentage&&
                    <DataField
                        title={t("cdi_percentage")}
                        label={`${cdi_percentage} %`}
                        size={"normalMediumSize"}
                        titleNoWrap={false}
                        />}
                    {number_of_installments&&
                    <DataField
                        title={t("number_of_installments")}
                        label={number_of_installments}
                        size={"normalMediumSize"}
                        titleNoWrap={false}
                        />}
                    {credit_proposals && 
                        <div className={["normalText", "normalMediumSize", "bold"].join(" ")} style={{marginTop:"20px", marginBottom:"10px"}}>
                            <span>{`${t("credit_proposals")}:`} </span>
                        </div>}
                    {credit_proposals&&credit_proposals.map((proposal, index) => (
                            <div key={`proposal_to_pdf_${index}`} style={{display:"flex", flexDirection: "column"}}>
                                <div style={{display: 'grid', gridTemplateColumns: "28% 27% 45%"}}>
                                    {proposal.credit_amount&&
                                    <DataField
                                        title={`${index + 1}. ${t("amount")}`}
                                        label={`${formatAsMoney('BRL', proposal.credit_amount)}`}
                                        size={"normalMediumSize"}
                                        titleNoWrap={false}
                                        />}
                                    {proposal.number_of_installments&&
                                    <DataField
                                        title={t("number_of_installments")}
                                        label={proposal.number_of_installments}
                                        size={"normalMediumSize"}
                                        titleNoWrap={false}
                                        />}
                                    {proposal.interest_rates.annual_interest_rate&&proposal.interest_rates.cdi_percentage&&
                                    <DataField
                                        title={t("rates")}
                                        label={`${proposal.interest_rates.annual_interest_rate} ${t("interest_rate_unit_year")} ${t("and")} ${proposal.interest_rates.cdi_percentage} ${t("interest_rate_unit_cdi")}`}
                                        size={"normalMediumSize"}
                                        titleNoWrap={false}
                                        />}
                                    {proposal.interest_rates.annual_interest_rate&&!proposal.interest_rates.cdi_percentage&&
                                    <DataField
                                        title={t("rates")}
                                        label={`${proposal.interest_rates.annual_interest_rate} ${t("interest_rate_unit_year")}`}
                                        size={"normalMediumSize"}
                                        titleNoWrap={false}
                                        />}
                                    {!proposal.interest_rates.annual_interest_rate&&proposal.interest_rates.cdi_percentage&&
                                    <DataField
                                        title={t("rates")}
                                        label={`${proposal.interest_rates.cdi_percentage} ${t("interest_rate_unit_cdi")}`}
                                        size={"normalMediumSize"}
                                        titleNoWrap={false}
                                        />}
                                </div>
                            </div>
                        ))}
                    {!!custom_variables_list.length && 
                    <>
                        <div className={["normalMediumSize","bold"].join(" ")} style={{marginTop: '15px'}}>Variáveis de saída:</div>
                        {custom_variables_list.map((custom_variable, index) => (
                        <div key={index} style={{display: 'flex', flexDirection: 'column'}}>
                            <DataField
                                title={custom_variable[0]}
                                label={custom_variable[1].toString()}
                                size={"normalMediumSize"}
                                titleNoWrap={false}
                                isTranslated={false}
                            />
                        </div>
                        ))}
                    </>}
                    {!!user_variables_list.length && 
                    <>
                        <div className={["normalMediumSize","bold"].join(" ")} style={{marginTop: '15px'}}>
                            Variáveis do motor:</div>
                        {user_variables_list.map((custom_variable, index) => (
                            <div key={index} style={{display: 'flex', flexDirection: 'column'}}>
                                <DataField
                                    title={custom_variable.variable_name}
                                    label={custom_variable.variable_value.toString()}
                                    size={"normalMediumSize"}
                                    titleNoWrap={false}
                                />
                            </div>))}
                    </>}
                </div>
			</div>
		);
  	}
}

export default Summary;
