import React, {useState} from 'react'
import TextField from '@material-ui/core/TextField';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { getConfirmationIcon } from "../../utils/utils"
import InputMask from "react-input-mask";
import axios from 'axios'
import { CircularProgress } from '@material-ui/core';

const theme = createMuiTheme({
    palette:{
        primary: {
            main:"#0b1f82"
        },
        secondary: {
            main: "#FF0000"
        },
    }
  });

function InputContent (props){
    let {handleRefreshPage} = props
    let {setLockType} = props
    let {onClose} = props
    let {lockType} = props

    let inputObjects
    if (lockType === "terminal"){
        inputObjects = {
            lock_link_key:{
                description: "ID do Terminal",
                value: "",
                mask: false,
                error: true
            }
        }
    }
    // else if (lockType === "merchant"){
    //     inputObjects = {
    //         lock_link_key:{
    //             description: "ID do merchant",
    //             value: "",
    //             mask: false,
    //             error: true
    //         }
    //     }
    // }
    // else if (lockType === "card"){
    //     inputObjects = {
    //         lock_link_key:{
    //             description: "Numero do Cartao",
    //             value: "",
    //             mask: false,
    //             error: true
    //         }
    //     }
    // }
    else if (lockType === "payer_document_number"){
        inputObjects = {
            lock_link_key:{
                description: "Documento do Pagador",
                type: "brazilian_document",
                value: "",
                mask: true,
                error: true
            }
        }
    }
    else if (lockType === "recipient_document_number"){
        inputObjects = {
            lock_link_key:{
                description: "Documento do Recebedor",
                type: "brazilian_document",
                value: "",
                mask: true,
                error: true
            }
        }
    }
    else if (lockType === "client_document_number"){
        inputObjects = {
            lock_link_key:{
                description: "Documento do Cliente",
                type: "brazilian_document",
                value: "",
                mask: true,
                error: true
            }
        }
    }
    else if (lockType === "customer"){
        inputObjects = {
            lock_link_key:{
                description: "Documento do Comprador",
                type: "brazilian_document",
                value: "",
                mask: true,
                error: true
            }
        }
    }
    else if (lockType === "seller"){
        inputObjects = {
            lock_link_key:{
                description: "Documento do Vendedor",
                type: "brazilian_document",
                value: "",
                mask: true,
                error: true
            }
        }
    }
    else if (lockType === "seller_transactional"){
        inputObjects = {
            lock_link_key:{
                description: "Documento do Vendedor",
                type: "brazilian_document",
                value: "",
                mask: true,
                error: true
            }
        }
    }

    const [object, setObject] = useState(inputObjects)
    const [pendingState, setPendingState] = useState(false)
    const [status, setStatus] = useState(null)
    const [observationBox, setObservationBox] = useState();
    const [reason, setReason] = useState();
    const [maskType, setMaskType] = useState(null)

    const handleChange = (event, item) => {
        let newObject = {...object}
        newObject[item].value = event.target.value
        if (event.target.value.length === 0) {
            newObject[item].error = true
        }
        else {
            newObject[item].error = false
        }

        if (inputObjects[item].type === 'brazilian_document') {
            if (event.target.value.length > 14) {
                setMaskType('99.999.999/9999-99')
            }
            else {
                setMaskType('999.999.999-999')
            }
        }

        if (newObject[item].validation_size){
            if (event.target.value.length !== newObject[item].validation_size){
                newObject[item].error = true
            }
            else {
                newObject[item].error = false
            }
        }
        setObject(newObject)
    }

    const handleBackClick = () => {
        setLockType(null)
    }

    const handleReturn = () => {
        handleRefreshPage()
        onClose()
    }

    const validate = (object) => {
        let error_keys = Object.keys(object).filter(item => {
            return object[item].error === true
        })
        if (error_keys.length > 0) return false
        else return true
    }

    const handleObservationBoxChange = (event) => {
        if (event.target.value.length === 0){
            setObservationBox(null)
        }
        else{
            setObservationBox(event.target.value)
        }
    };

    const handleReasonInputChange = (event) => {
        if (event.target.value.length === 0){
            setReason(null)
        }
        else{
            setReason(event.target.value)
        }
    };

    const onSubmit = () => {
        if (!validate(object)) return
        let payload = Object.keys(object).reduce((acc, item) =>{
            if(object[item].value != null){
                acc[item] = object[item].value
            }
            return acc
        },{})
        if (observationBox){
            payload['observation'] = observationBox
        }
        if(reason){
            payload['lock_reason'] = reason
        }
        payload['lock_source'] = 'manual_input'
        payload['locked_event_type'] = props.lockedEventType
        payload['lock_type'] = lockType
        setPendingState(true)
        axios.post('/dash/alerts/lock', payload).then(response => {
            setPendingState(false)   
            setStatus({success: true, message: "Requisição processada com sucesso"})
        }).catch(error => {
            setPendingState(false)
            setStatus({success: false, message: "Não foi possível processar sua requisição"})
        });
      }


    
    if (pendingState){
        return(
            <div style={{display:"flex", flexGrow:"1"}}>
                <CircularProgress style={{margin:"auto"}}/>
            </div>
        )
    }
    if (status != null) {
        return(
            <div style={{ display:"flex", flexDirection:"column", flexGrow:"1"}}>
                <div style={{margin:"auto"}}>
                    {getConfirmationIcon(status.success)}
                </div>
                <div style={{margin:"auto"}}>
                    {status.message}
                </div>
                <div
                    onClick={handleReturn} 
                    className={["button","standard", "normalText", "normalMediumSize"].join(" ")} 
                    style={{margin:"auto auto 20px auto", width:"auto"}}
                >
                    VOLTAR À PÁGINA INICIAL
                </div>
            </div>
        )
    }

    return (
        <div style={{ display:"flex", flexDirection:"column", flexGrow:"1"}}>
            <div style={{display: "flex",justifyContent: "center",flexDirection: "column",margin: "0 auto 10px auto",width: "60%"}}>
            <ThemeProvider theme={theme}>
                {Object.keys(object).map((item, index) => (
                    <InputMask 
                        key={index}
                        mask={object[item].mask ? maskType : null}
                        maskPlaceholder=""
                        value={object[item].value}
                        onChange={(e) => handleChange(e, item)}
                    >
                        <TextField 
                            color={object[item].error? "secondary": "primary"}
                            label={object[item].description}
                        />
                    </InputMask>                    
                ))}
                <TextField  
                    style={{marginTop: "10px"}}    
                    fullWidth
                    multiline
                    variant="outlined"
                    label={"Razão"}
                    color="primary"
                    onChange={handleReasonInputChange}
                />
                <TextField  
                    style={{marginTop: "10px"}}    
                    fullWidth
                    multiline
                    variant="outlined"
                    rows="5"
                    label={"Observações"}
                    color="primary"
                    onChange={handleObservationBoxChange}
                />
            </ThemeProvider>               
            </div>
            <div style={{display:"flex", flexDirection:"row", justifyContent: "center", margin:"auto 0px 20px 0px"}}>
                <div
                    className={!validate(object) ? ["button","standard", "disabled", "normalText", "normalMediumSize"].join(" ") : ["button","standard", "normalText", "normalMediumSize"].join(" ")} 
                    onClick={onSubmit} 
                    style={{width:"40%"}}
                >
                    Finalizar
                </div>
                <div
                    className={["button","cancel"].join(" ")} 
                    onClick={handleBackClick} 
                    style={{width:"40%"}}
                >
                    Retornar
                </div>
            </div>
        </div>
    )

}

export default InputContent