import React, {useContext} from "react";
import AuthContext from "../../../../context/auth-context";

function CardListHeader(props){

    let {user_data} = useContext(AuthContext)

    return (
        <div className="listCardHeader">
            <div 
                className={["listCardItem", "normalText"].join(" ")}
                style={{flex:"1 1 80px", textAlign:"center"}}
            >
                ID da Transação
            </div>
            {user_data.business_group_key &&
            <div 
                className={["listCardItem", "normalText"].join(" ")}
                style={{flex:"1 1 80px", textAlign:"center"}}
            >
                Companhia
            </div>}
            <div 
                className={["listCardItem", "normalText"].join(" ")}
                style={{flex:"1 1 80px", textAlign:"center"}}
            >
                Valor
            </div>
            <div 
                className={["listCardItem", "normalText"].join(" ")}
                style={{flex:"1 1 45px", textAlign:"center"}}
            >
                Relatos
            </div>
            <div 
                className={["listCardItem", "normalText"].join(" ")}
                style={{flex:"1 1 110px", textAlign:"center"}}
            >
                Documento do Cliente
            </div>
            <div 
                className={["listCardItem", "normalText"].join(" ")}
                style={{flex:"1 1 80px", textAlign:"center"}}
            >
                Direção
            </div>
            <div 
                className={["listCardItem", "normalText"].join(" ")}
                style={{flex:"1 1 100px", textAlign:"center"}}
            >
                Contraparte
            </div>
            <div 
                className={["listCardItem", "normalText"].join(" ")}
                style={{flex:"1 1 50px", textAlign:"center"}}
            >
                Status da Análise
            </div>
            <div 
                className={["listCardItem", "normalText"].join(" ")}
                style={{flex:"1 1 80px", textAlign:"center"}}
            >
                Data da Transação
            </div>
            <div 
                className={["listCardItem", "normalText"].join(" ")}
                style={{flex:"1 1 35px", textAlign:"center"}}
            >
                Status da Transação
            </div>
        </div>
    )
}

export default CardListHeader