import React from 'react'
import moment from 'moment'
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from '@material-ui/icons/Clear';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import FlightIcon from '@material-ui/icons/Flight';
import Tooltip from "@material-ui/core/Tooltip";

//Translators
export const getStatusColor = (status) => {
    switch(status){
        case "automatically_approved":
        case "manually_approved":
        case "approved_by_time":
            return colorGreen
        case "reproved_by_time":
        case "automatically_reproved":
        case "manually_reproved":
            return colorRed
        case "not_applied":
            return null;
        case "manually_challenged":
            return colorYellow;
        default:
            return colorGrey
    }
}

export const getStatus = (status) => {
    switch(status){
        case "pending":
        case "in_manual_analysis":
          	return "Pendente";
        case "manually_approved":
            return ("Aprovado manualmente");
        case "automatically_approved":
            return ("Aprovado automaticamente");
        case "manually_reproved":
            return ("Reprovado manualmente");
        case "automatically_reproved":
            return ("Reprovado automaticamente");
        case "approved_by_time":
            return "Aprovado por tempo";
        case "reproved_by_time":
            return "Reprovado por tempo";
        case "not_analised":
            return "Não analisado";
        case "not_applied":
            return "Não se aplica";
        case "manually_challenged":
            return "Contestado manualmente"
        default:
            return status;
    }
}

export const getStatusSiglum = (status) => {
    switch(status){
        case "pending":
        case "in_manual_analysis":
          	return "P";
        case "manually_approved":
        case "manually_reproved":
            return "M";
        case "automatically_approved":
        case "automatically_reproved":
            return "A";
        case "approved_by_time":
        case "reproved_by_time":
            return "T";
        case "not_analised":
            return "NA";
        case "manually_challenged":
            return "C";
        default:
            return "?";
    }
}

export const getPictureType = (type) => {
    switch(type){
        case "fraud":
            return "Fraude"
        case "similar_match":
            return "Similar"
        case "registration":
            return "Cadastro"
        default:
            return "-"
    }
}

export const getPictureStatus = (type) => {
    switch(type){
        case "positive":
            return "Aprovado"
        case "negative":
            return "Fraude"
        default:
            return type
    }
}

export const getCarStatus = (type) => {
    switch(type){
        case "created":
            return "Cadastrado"
        case "rented":
            return "Em Aberto"
        case "returned":
            return "Devolvido"
        case "recovered":
            return "Recuperado"
        case "written_off":
            return "Perdido"
        case "pending":
            return "Pendente"
        default:
            return type
    }
}

export const translateGender = (gender) => {
    switch (gender) {
      case "male":
        return "masculino";
      case "female":
        return "feminino";
      default:
        return gender;
    }
  };

export const translatePhoneType = (type) => {
    switch (type) {
        case "residential":
            return "Telefone Residencial";
        case "comercial":
            return "Telefone Comercial";
        case "mobile":
            return "Telefone Celular";
        default:
            return type;
    }
};

export const translateClientType = (type) => {
    switch (type) {
        case "natural_person":
            return "Pessoa Física";
        case "legal_person":
            return "Pessoa Jurídica";
        case "replacement":
            return "Substituição";
        case "fleet":
            return "Frota";
        case "uber":
            return "Uber";
        case "enterprise":
            return "Enterprise";
        case "agencia":
            return "Agência de Viagens";
        default:
            return type;
    }
};

export const correctRentalStoryType = (type) => {
    switch (type) {
        case "AEROPORTO":
            return "Aeroporto";
        case "LOJA DE RUA":
            return "Loja de Rua";
        case "MERCADO":
            return "Mercado";
        case "SHOPPING":
            return "Shopping";
        case "TERMINAL":
            return "Terminal";
        default:
            return type;
    }
};

export const getValidationText = (validationData) => {
    if (validationData === null) {return "Houve um problema ao enviar o link para este telefone"}
    else if (validationData != null){
        switch(validationData.status){
            case "denied":
                return "O link de confirmação foi negado"
            case "message_submitted":
                return "O link de confirmação foi enviado e aguarda resposta"
            case "expired":
                return "O link de confirmação foi enviado e expirou"
            case "validated":
                return "O link de confirmação foi enviado validado"
            default:
                return validationData.status
        }
    }
}

//React Components Builders
export const time_feature = (ra) => {
    switch(ra.fraud_status){
        case "pending":
        case "in_manual_analysis":
            return (
                <div>
                    Prazo Limite: {moment(ra.manual_analysis_limit_date).format("HH:mm:ss").toString()}
                </div>
            )
        default:
            return (
                <div>
                    {moment(ra.created_at).format("DD/MM/YYYY - HH:mm").toString()}
                </div>
            )
    }
}

export const getStoresContent = (storesInfo, key_store) => {
    return (
        <div style={{display:"flex", alignItems: "center", justifyContent:"center"}}>
            <Tooltip title={checkNested(storesInfo[key_store], "address") ? <p className="tooltipText">{formatAddress(storesInfo[key_store].address, null)}</p>: null}>
                <div className={["labelText", "normalMediumSize"].join(" ")}>
                    {storesInfo[key_store].code + " - " + titleCase(storesInfo[key_store].description, "")}
                </div>
            </Tooltip>
            {storesInfo[key_store].on_airport ?
            <Tooltip title={<p className="tooltipText">No aeroporto</p>}>
                <FlightIcon style={{marginLeft:"10px"}} />
            </Tooltip> : null }
        </div>
    )
}

export const getAlertsFlagIcon = (flag, margin=null) => {
    let margin_ = "auto"
    if (margin !=null ){
        margin_ = margin
    }
    switch(flag){
        case "positive":
            return (<CheckIcon style={{verticalAlign: "middle", width:"20px", height:"20px", color:colorGreen, marginTop:"auto",marginBottom:"auto", marginRight:margin_,marginLeft:margin_}}/>)
        case "negative":
            return (<ErrorOutlineIcon style={{verticalAlign:"middle", width:"20px", height:"20px", color:colorRed, marginTop:"auto",marginBottom:"auto", marginRight:margin_,marginLeft:margin_}}/>)
        case "neutral":
        case "attention":
            return(<ErrorOutlineIcon style={{verticalAlign:"middle", width:"20px", height:"20px", color:colorYellow, marginTop:"auto",marginBottom:"auto", marginRight:margin_,marginLeft:margin_}}/>)
        default:
            return(<ErrorOutlineIcon style={{verticalAlign:"middle", width:"20px", height:"20px", color:colorGrey, marginTop:"auto",marginBottom:"auto", marginRight:margin_,marginLeft:margin_}}/>)
    }
  }

//ColorUtils
export const getPictureColor = (type, variable) => {
    if (type==="similarity"){
        if (variable > 80){
            return colorGreen
        }
        else if (variable > 50){
            return colorYellow
        }
        else {
            return colorRed
        }
    }
    else if(type==="status"){
        if (variable === "similar_match"){
            return colorGreen
        }
        else if(variable === "fraud"){
            return colorRed
        }
        else return null
    }
    else return null
}

export const getAlertsFlagColor = (flag) => {
    switch(flag){
        case "positive":
            return colorGreen
        case "negative":
            return colorRed
        case "attention":
        case "neutral":
            return colorYellow
        default:
            return colorGrey
    }
}

export const getCarStatusColor = (type) => {
    switch(type){
        case "created":
            return colorGrey
        case "rented":
        case "returned":
            return colorGreen
        case "recovered":
        case "written_off":
            return colorRed
        default:
            return colorGrey
    }
}

export const getCurrentAnalystColor = (analyst) => {
    if (analyst == null) {
        return colorGreen
    }
    else{
        return colorRed
    }
}

export const getValidationColor = (validationData) => {
    if (validationData === null) {return colorGrey}
    else if (validationData != null){
        switch(validationData.status){
            case "denied":
                return colorRed
            case "message_submitted":
                return colorYellow
            case "expired":
                return colorGrey
            case "validated":
                return colorGreen
            default:
                return colorGrey
        }
    }
}

const colorGreen = '#73C580'

export const colorRed ='#D91A1A'

export const colorLightRed ='#e37171'

export const colorGrey = 'rgb(170, 167, 153)'

export const colorLightGrey = '#d1cfc2'

const colorYellow = "#FFBB24"

//General Format Utils
export const getCurrentAnalystText = (analyst) => {
    if (analyst == null) {
        return "Nenhum analista responsável por esse aluguel"
    }
    else{
        return (
            "O analista " + analyst.analyst_name + " está analisando esse aluguel"
        )
    }
}

export const getCurrentAnalystTextReservation = (analyst) => {
    if (analyst == null) {
        return "Nenhum analista responsável por esta reserva"
    }
    else{
        return (
            "O analista " + analyst.analyst_name + " está analisando esta reserva"
        )
    }
}

export const formatUrl = (bucketName, pictureKey) => {
    return "https://" + bucketName + ".s3.amazonaws.com/" + pictureKey
}

export const formatAsMoney = (value, nullCase=null) => {
    if (value == null) return nullCase
    return ("R$ " + (value/100).toLocaleString('de-DE', {minimumFractionDigits: 2}))    
}

export const formatBureauInfo = (object, identifier) => {
    let formatFunction
    switch(identifier){
        case "endereços":
            formatFunction = formatAddress
            break
        case "telefones":
            formatFunction = formatPhone
            break
        case "emails":
            formatFunction = formatEmail
            break
        default:
    }
    return (
        <div>
            Outros {identifier}:
            <ul>
                {object.map((item,index) => (
                    <li key={index}>
                        {formatFunction(item)} - {item.description}
                    </li>
                ))}
            </ul>
        </div>
    )
}

export const getReservationFormat = (ra) => {
    if (!checkNested(ra, "reservation", "reservation_date")) return "Não foi realizada pré-reserva"
    else if (ra.rental_agreement_date == null) {
        return (
            moment(ra.reservation.reservation_date || ra.reservation_date).format("DD/MM/YYYY")
        )    
    }
    else {
        let difference_in_days = moment(ra.rental_agreement_date).diff(moment(ra.reservation.reservation_date || ra.reservation_date), "days");
        let dayString = difference_in_days === 1 ? "dia" : "dias";
        return (
            moment(ra.reservation.reservation_date || ra.reservation_date).format("DD/MM/YYYY") + 
            " (" + difference_in_days + " " + dayString + " de antecedência)"
        );
    }
}

export const getReservationFormatForReservation = (reservation) => {
    let difference_in_days = moment(reservation.rental_agreement_date).diff(moment(reservation.reservation_date), "days");
    let dayString = difference_in_days === 1 ? "dia" : "dias";
    return (
        moment(reservation.reservation_date).format("DD/MM/YYYY") + 
        " (" + difference_in_days + " " + dayString + " de antecedência)"
    );
}


export const getRentalDaysFormat = (ra) => {
    if (!checkNested(ra, "car_rental_estimated_final_date")) return "Indisponível"
    else if (ra.rental_agreement_date == null) {
        return (
            moment(ra.car_rental_estimated_final_date).format("DD/MM/YYYY")
        )    
    }
    else {
        let difference_in_days = moment(ra.car_rental_estimated_final_date).diff(moment(ra.rental_agreement_date), "days") + 1;
        let dayString = difference_in_days === 1 ? "diária" : "diárias";
        return (
            moment(ra.car_rental_estimated_final_date).format("DD/MM/YYYY") + 
            " (" + difference_in_days + " " + dayString + ")"
        );
    }
}

export const getPastRentalsFormat = (pastLocation) => {
    return (titleCase(pastLocation.description, null) + " - " + formatAddress(pastLocation.address, null))
}

export const formatScore = (score) => {
    let color
    let text
    let icon
    if (score > 800) {
        color = colorRed
        text = "Arriscado"
    } 
    else if (score > 500) {
        color = colorYellow
        text = "Moderado"
    } 
    else {
        color = colorGreen
        text = "Seguro"
    }

    return ({color:color, text:text, icon:icon})
}

export const quiz_score_translation = {
    very_low_risk: {
        color: colorGreen, 
        description: "Risco Muito Baixo"
    },
    low_risk: {
        color: colorGreen, 
        description: "Risco Baixo"
    },
    medium_risk: {
        color: colorYellow, 
        description: "Risco Médio"
    },
    high_risk: {
        color: colorRed, 
        description: "Risco Alto"
    },
    very_high_risk: {
        color: colorRed, 
        description: "Risco Muito Alto"
    },
}

export const formatAddress  = (addressObj,nullCase) => {
    if (addressObj == null) return nullCase

    let streetString = addressObj.street == null ? "" : titleCase(addressObj.street, "") + ", "
    let numberString = addressObj.number == null ? "" : addressObj.number + ", "
    let neighborhoodString = addressObj.neighborhood == null ? "" : titleCase(addressObj.neighborhood, "") + ", "
    let cityString = titleCase(addressObj.city, "")
    let stateString = addressObj.state == null ? "" : " - " + addressObj.state + ", "
    let postalCodeString = addressObj.postal_code == null ? "" : addressObj.postal_code

    return streetString + numberString + neighborhoodString + cityString + stateString + postalCodeString

}

export const formatPhone  = (phoneObj,nullCase) => {
    if (phoneObj == null) return nullCase

    let ddiString = phoneObj.internation_dial_code == null ? "" : phoneObj.internation_dial_code + " "
    let dddString = phoneObj.area_code == null ? "" : "(" + phoneObj.area_code + ") "
    let numberString = phoneObj.number == null ? "" : phoneObj.number

    return ddiString + dddString + numberString

}

const formatEmail  = (emailObj,nullCase) => {
    if (emailObj == null) return nullCase
    return (emailObj.email == null ? "" : emailObj.email)
}

export const clientAge = (birthdate, nullCase=null) => {
    if (birthdate == null) return nullCase
    return (moment(birthdate).format('DD/MM/YYYY') + " (" + moment().diff(moment(birthdate),'years') + ' anos)')
  };

export const singleOrPluralPlaces = (number) => {
    if (number === 1) {
        return ("+" + number + " local")            
    }
    else if (number > 1) {
        return ("+" + number + " locais")                              
    }
}
export const singleOrPluralNrOfRentals = (number) => {
    if (parseInt(number) === 1) {
        return (number + " locação)")            
    }
    else if (parseInt(number) > 1) {
        return (number + " locações)")                              
    }
}

export const getTableRowClass = (rowIndex) => {
    if (rowIndex % 2 === 0) {
      return "even";
    }
  
    return "odd";
  }

//Functions Utils
export const pastRentalsIntoStoreObject = (pastRentals, stores) => {
    const nrOfRentals = pastRentals.length;
    var storesArray = [];
    for (var i = 0; i < nrOfRentals; i++) {
        if (pastRentals[i].rental_store){
            storesArray.push(pastRentals[i].rental_store.toUpperCase());
        }        
    }
    var storesObject = storesArray.reduce(function (obj, b) {
        obj[b] = ++obj[b] || 1;
        return obj;
    }, {});
    for (var store in storesObject) {
        storesObject[store] = { value: storesObject[store], info: stores[store] };
    }
    return storesObject;
};

export const getConfirmationIcon = (success) => {
    if (success){
        return (<CheckIcon className="fraudStatusIcon" style={{color: colorGreen, width:"40px", height:"40px"}}/>)
    }
    else{
        return (<ClearIcon className="fraudStatusIcon" style={{color: colorRed, width:"40px", height:"40px"}}/>)
    }
  }

const getMatchIcon = (bool) => {
    if (bool == null) {
        return (
            <Tooltip title={<p className="tooltipText">Indisponível</p>}>
                <HelpOutlineIcon style={{color: colorGrey}}/>
            </Tooltip>
        )
    }
    else if (bool) {
        return (
            <Tooltip title={<p className="tooltipText">Item confirmado</p>}>
                <CheckIcon style={{color: colorGreen}}/>
            </Tooltip>
        )
    }
    else{
        return (
            <Tooltip title={<p className="tooltipText">Item divergente</p>}>
                <ClearIcon style={{color: colorRed}}/>
            </Tooltip>
        )
    }
}

export const historicIntoTableObject = (historicalData, maxRows) => {
    let numberOfPhoneCheck = 0;
    let numberOfLocCheck = 0;
    let numberOfDocumentCheck = 0;
    let numberOfEmailCheck = 0;

    let slicedData = maxRows == null ? historicalData : historicalData.slice(0,maxRows)

    let tableData =slicedData.map((rental) => {
        let tableRow = { ...rental };

        tableRow.phoneCheckIcon = !checkNested(tableRow, "matches", "phone") ? 
            getMatchIcon(null) : getMatchIcon(tableRow.matches.phone)

        tableRow.locationCheckIcon = !checkNested(tableRow, "matches", "postal_code") ? 
            getMatchIcon(null) : getMatchIcon(tableRow.matches.postal_code)

        tableRow.documentCheckIcon = !checkNested(tableRow, "matches", "document_number") ? 
            getMatchIcon(null) : getMatchIcon(tableRow.matches.document_number)

        tableRow.emailCheckIcon = !checkNested(tableRow, "matches", "email") ? 
            getMatchIcon(null) : getMatchIcon(tableRow.matches.email)

        if (checkNested(tableRow, "matches", "phone") && tableRow.matches.phone === true) {
          numberOfPhoneCheck++;
        }
    
        if (checkNested(tableRow, "matches", "postal_code") && tableRow.matches.postal_code === true) {
          numberOfLocCheck++;
        }
    
        if (checkNested(tableRow, "matches", "document_number") && tableRow.matches.document_number === true) {
          numberOfDocumentCheck++;
        }
    
        if (checkNested(tableRow, "matches", "email") && tableRow.matches.email === true) {
          numberOfEmailCheck++;
        }
    
        tableRow.statusIcon = (
          <div className="statusTag" style={{backgroundColor: getCarStatusColor(tableRow.car_status)}}>
            <span>{getCarStatus(tableRow.car_status)}</span>
          </div>
        );
    
        return tableRow;
    });

    return {
        tableData: tableData,
        phoneCheck: numberOfPhoneCheck,
        locCheck: numberOfLocCheck,
        documentCheck: numberOfDocumentCheck,
        emailCheck: numberOfEmailCheck
    }


};

export function checkNested (obj /*, level1, level2, ... levelN*/) {
    if (obj == null) return false
    var args = Array.prototype.slice.call(arguments, 1);

    for (var i = 0; i < args.length; i++) {
        if (!obj || !obj.hasOwnProperty(args[i])) {
        return false;
        }
        obj = obj[args[i]];
    }
    return true;
}

export function validateNest (obj, decision /*, level1, level2, ... levelN*/) {
    if (obj == null) return obj
    let newObj = {...obj}
    var args = Array.prototype.slice.call(arguments, 2);
    Object.keys(newObj).forEach(item => {
        if (!checkNested(newObj[item], ...args)) {
            if (decision === "remove"){
                delete newObj[item]
            }
            else{
                newObj[item] = decision
            }
        }
    })
    return newObj
}

export const titleCase = (str, nullCase) => {
    if (str == null){
      return nullCase
    }
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  };

export const setAllowChanges = (status, roles) => {
    if (!roles.includes("rental_agreements_analyzer")){
        return false
    }
    switch(status){
        case "pending":
        case "in_manual_analysis":
            return true
        default:
            return false
    }
}

export const setAllowChangesReservations = (status, roles) => {
    if (!roles.includes("update_car_rental_reservations")){
        return false
    }
    switch(status){
        case "pending":
        case "in_manual_analysis":
            return true
        default:
            return false
    }
}

export function replaceAll(str,mapObj){
    var re = new RegExp(Object.keys(mapObj).join("|"),"gi");

    return str.replace(re, function(matched){
        return mapObj[matched.toLowerCase()];
    });
}

export function removeArray(arr) {
    var what,
    a = arguments,
    L = a.length,
    ax;
    while (L > 1 && arr.length) {
        what = a[--L];
        while ((ax = arr.indexOf(what)) !== -1) {
            arr.splice(ax, 1);
        }
    }
    return arr;
}

export const getIncreasePriceValue = (ra) => {
    let descriptionList
    let value=500000
    if ((ra.coverages || []).length > 0){
        descriptionList = ra.coverages.reduce((acc, coverageObj) => {
            acc.push(coverageObj.description)
            return acc        
        },[])
        descriptionList = descriptionList.filter(description => (
            Object.keys(protectionList).includes(description)
        ))
        let protectionSiglum = descriptionList.length > 0 ? protectionList[descriptionList[0]] : null
        const protection_source = require("../../../assets/json/increasePriceTable.json");
        let model_group = (ra.car || {}).model_group
        value = (protection_source[model_group] || {})[protectionSiglum]
    }
    
    if(value == null){
        value = 500000
    }
    return value
}

const protectionList = {
    "P PARCIAL ISENTO": "PP",
    "PC ISENTA - (SEM PRÉ)": "PC",
    "PP - ESPECIAL": "PP",
    "PP - EUROP ISENTO": "PP",
    "PP - FLYTOUR": "PP",
    "PP - ISENTA (UBER) PRÉ A R$1,00": "PP",
    "PP - ISENTA (UBER)3 - PRÉ DE R$1,00": "PP",
    "PP - ISENTA SEM PRÉ": "PP",
    "PP - ISENTO": "PP",
    "PP - ISENTO (FLYTOUR)": "PP",
    "PP - ISENTO 50% PRÉ": "PP",
    "PP - ITAÚ": "PP",
    "PP - LITORAL": "PP",
    "PP - MAPFRE": "PP",
    "PP - MONDIAL": "PP",
    "PP - SULAMÉRICA ISENTO": "PP",
    "PP ISENTA - EMBRAER (PRÉ R$1,00)": "PP",
    "PP ISENTA - LET'S/VIX/SALUTE": "PP",
    "PP ISENTA - PRÉ A R$1,00": "PP",
    "PP ISENTA - PRÉ DIFERENCIADA": "PP",
    "PP ISENTA - REPLACEMENT": "PP",
    "PP ISENTA SEM PRÉ AUTORIZAÇÃO": "PP",
    "PP LITORAL (PRÉ R$700,00)": "PP",
    "PROTEÇÃO COMPLETA":"PC",
    "PROTEÇÃO COMPLETA - COLABORADOR":"PC",                                            
    "PROTEÇÃO COMPLETA (SEM PRÉ)":"PC",                                                                
    "PROTEÇÃO COMPLETA PF - COLABORADOR":"PC",                                                   
    "PROTEÇÃO COMPLETA PF (SEM PRÉ)":"PC",                                                        
    "PROTEÇÃO COMPLETA PRÉ R$ 1,00":"PC",
    "PROTEÇÃO PARCIAL": "PP",                                                                                                                                   
    "PROTEÇÃO PARCIAL - (UBER)": "PP",                                                         
    "PROTEÇÃO PARCIAL - 50% DA PRÉ AUTORIZAÇÃO": "PP",               
    "PROTEÇÃO PARCIAL - COLABORADOR": "PP",             
    "PROTEÇÃO PARCIAL - DÓLAR": "PP",       
    "PROTEÇÃO PARCIAL - ISENTA": "PP",                                                         
    "PROTEÇÃO PARCIAL - RP1": "PP",                                                                            
    "PROTEÇÃO PARCIAL - TREND P": "PP",                                                                        
    "PROTEÇÃO PARCIAL (LP)": "PP",
    "PROTEÇÃO PARCIAL (SEM PRÉ)": "PP",                                                                        
    "PROTEÇÃO PARCIAL (TREND PRO)": "PP",                                                                      
    "PROTEÇÃO PARCIAL EMBRAER": "PP",                                                                          
    "PROTEÇÃO PARCIAL PF (SEM PRÉ)": "PP",                                                                     
    "PROTEÇÃO PARCIAL PRÉ R$ 1,00": "PP",
    "PROTEÇÃO SUPER":"PS",
    "PROTEÇÃO SUPER - COLABORADOR":"PS",                                                                      
    "PROTEÇÃO SUPER (SEM PRÉ)":"PS",                                                                          
    "PROTEÇÃO SUPER (TREND) PARIDADE":"PS",                                                                   
    "PROTEÇÃO SUPER PF - COLABORADOR":"PS",                                                               
    "PROTEÇÃO SUPER PF (SEM PRÉ)":"PS",                                                                    
    "PROTEÇÃO SUPER PRÉ R$ 1,00":"PS",
    "PROTEÇÃO SUPER ZERO":"PSZ",
    "PROTEÇÃO SUPER ZERO - COLABORADOR":"PSZ",
    "PROTEÇÃO SUPER ZERO (SEM PRÉ)":"PSZ",                                                                 
    "PROTEÇÃO SUPER ZERO PF - COLABORADOR":"PSZ",                                                       
    "PROTEÇÃO SUPER ZERO PF (SEM PRÉ)":"PSZ",                                                                  
    "PROTEÇÃO SUPER ZERO PRÉ R$ 1,00":"PSZ",
    "PSZ - ISENTO": "PSZ"
}

export const upgradeStatusTranslation = (upgradeStatus) => {
    switch(upgradeStatus){
        case "in_manual_analysis":
            return "Pendente"
        case "automatically_approved":
            return "Aprovado Automaticamente"
        case "automatically_reproved":
            return "Reprovado Automaticamente"
        case "manually_approved":
            return "Aprovado Manualmente"
        case "manually_reproved":
            return "Reprovado Manualmente"
        case "approved_by_time":
            return "Aprovado por tempo";
        case "reproved_by_time":
            return "Reprovado por tempo";
        default:
            return upgradeStatus
    }       
}
