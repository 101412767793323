import React from "react"
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import moment from "moment"

const useStyles = makeStyles((theme) => ({
    tableStyle: {
        border: "none",
        boxShadow: "none",
    },
    tableText: {
        fontFamily: "Open Sans",
        fontWeight: "normal",
        fontSize: "12px",
        lineHeight: "16px",
        color: "#141414",
        textAlign: "center",
        margin: "10px",
        padding: "6px",
        minWidth: "120px"
    },
    tableHeader: {
        textAlign: "center",
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: "normal",
        color: "#6F6F6F",
        fontSize: "14px",
        lineHeight: "19px",
        padding: "6px",
    },
    even: {
        background: "#F3F3F3",
    },
    odd: {
        background: "#FFFFFF",
    },
}))

export const InquiryTable = ({ inquiries }) => {

    const { t } = useTranslation()

    const classes = useStyles()
    useTheme()

    const getClass = (rowIndex) => {
        if (rowIndex % 2 === 0) {
            return classes.even
        }

        return classes.odd
    }

    return (
        <Paper className={classes.tableStyle}>
            {inquiries.inquiry_count_last_30_days > 0 &&
          <div>
            <span> Total de Consultas nos últimos 30 dias:</span>
            <span style={{color: '#6F6F6F', marginLeft: '6px'}}>{inquiries.inquiry_count_last_30_days}</span>
          </div>}
          {inquiries.inquiry_count_last_31_to_60_days > 0 &&
          <div>
            <span> Total de Consultas entre 31 e 60 dias atrás:</span>
            <span style={{color: '#6F6F6F', marginLeft: '6px'}}>{inquiries.inquiry_count_last_31_to_60_days}</span>
          </div>}
          {inquiries.inquiry_count_last_61_to_90_days > 0 &&
          <div>
            <span> Total de Consultas entre 61 e 90 dias atrás:</span>
            <span style={{color: '#6F6F6F', marginLeft: '6px'}}>{inquiries.inquiry_count_last_61_to_90_days}</span>
          </div>}
          {inquiries.inquiry_count_more_90_days > 0 &&
          <div style={{marginBottom: '26px'}}>
            <span> Total de Consultas há mais de 90 dias:</span>
            <span style={{color: '#6F6F6F', marginLeft: '6px'}}>{inquiries.inquiry_count_more_90_days}</span>
          </div>}

            <Table style={{ padding: "8px" }}>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableHeader}>{t("Data da Consulta")}</TableCell>
                        <TableCell className={classes.tableHeader}>{t("Total de Consultas")}</TableCell>
                        <TableCell className={classes.tableHeader}>{t("Segmento da Empresa Consultante")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {inquiries.details.map((inquiry, index) => (
                        <TableRow
                            key={index}
                            className={getClass(index)}
                        >
                            <TableCell className={classes.tableText}>
                                {inquiry.event_date ? moment(inquiry.event_date).format("DD/MM/YYYY") : "-"}
                            </TableCell>
                            <TableCell className={classes.tableText}>
                                {inquiry.number_of_inquiries ? inquiry.number_of_inquiries : "-"}
                            </TableCell>
                            <TableCell className={classes.tableText}>
                                {inquiry.company_segment ? inquiry.company_segment : "-"}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper >
    )
}
