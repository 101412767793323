import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import DownloadCSVErrorDialog from "./DownloadCSVErrorDialog/DownloadCSVErrorDialog"
import CSVLimitSizeDialog from "./CSVLimitSizeDialog/CSVLimitSizeDialog"
import moment from 'moment'

function ExportCSV (props) {
	const {t} = useTranslation()

    const { pageParams, csv_endpoint, file_name, is_able } = props
	
    let [isCsvAble, setIsCsvAble] = useState(is_able); 
   
    const downloadCSV = () => {

		setIsCsvAble(false)
		let csv_filters = {}
		
		if (pageParams.filters) {
			Object.keys(pageParams.filters).forEach((key, index) => {
				csv_filters[key] = pageParams.filters[key]
			})
		}

		axios({
			url: csv_endpoint,
			method: 'GET',
			responseType: 'blob',
			params: csv_filters
		  }).then((response) => {
            setIsCsvAble(true);
            
			(response.headers['csv_line_number'] < response.headers['csv_limit_line_number']) 
            ? handleCSVLimitSizeDialogOpen()
            : handleCSVLimitSizeDialogClose();

			let datetime = moment().format("YYYY-MM-DDTHH-mm-SS")
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', file_name + datetime + '.csv' );
			document.body.appendChild(link);
			link.click();
		  }).catch(error => {
			setIsCsvAble(true)
			handleDownloadCSVErrorDialogOpen()
		})
	}; 
   
	let [downloadCSVErrorDialog, setDownloadCSVErrorDialog] = useState(false); 

	const handleDownloadCSVErrorDialogOpen = () => {
		setDownloadCSVErrorDialog(true);
	};
	
	const handleDownloadCSVErrorDialogClose = () => {
		setDownloadCSVErrorDialog(false);
	};

	let [csvLimitSizeDialog, setCSVLimitSizeDialog] = useState(false); 

	const handleCSVLimitSizeDialogOpen = () => {
		setCSVLimitSizeDialog(true);
	};
	
	const handleCSVLimitSizeDialogClose = () => {
		setCSVLimitSizeDialog(false);
	};

		return (
			<div>
                <div style={{margin: "auto 20px auto auto"}}>
                    <div
                        style={{ padding: "5px 30px", margin:"5px", cursor: !isCsvAble ? 'wait' : 'default' }}
                        className={`normalText normalMediumSize button standard${!isCsvAble ? 'disabled' : ''}`}
                        onClick={isCsvAble ? downloadCSV : null}                        
                    >
                        {!isCsvAble ? t("in_processing") : t("csv_export")}
                    </div>
                </div>		
                <DownloadCSVErrorDialog
                    open={downloadCSVErrorDialog}
                    onClose={handleDownloadCSVErrorDialogClose}
                />
                <CSVLimitSizeDialog
                    open={csvLimitSizeDialog}
                    onClose={handleCSVLimitSizeDialogClose}
                />
			</div>
		);
	
}

export default ExportCSV;