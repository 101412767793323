import React, {useState, useEffect} from 'react'
import { useTranslation } from "react-i18next";
import {GetAlertsComponent,setObjectByPath, colorGreen, colorYellow, colorRed} from "./utils"
import TextField from "@material-ui/core/TextField";
import MenuItem from '@material-ui/core/MenuItem';
import _ from "lodash"
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from "@material-ui/core/Tooltip";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import HelpIcon from "@material-ui/icons/Help";

const options = [
    {
        value:"positive",
        description:"Positivo"
    },
    {
        value:"negative",
        description:"Negativo"
    },
    {
        value:"neutral",
        description:"Neutro"
    }
]

const getAlertColor = (value) => {
    switch(value){
        case "positive":
            return colorGreen
        case "negative":
            return colorRed
        case "neutral":
            return colorYellow
        default:
            return null
    }
}


export function ActionIndicatorBox (props) {
    const {t} = useTranslation()
    let {node} = props
    let {read_only} = props
    let {handleConfigurationOpen} = props
    let {handleNodeRemove} = props
    let optionsObject = options.filter(option => option.value === node.node.properties.flag)[0]

    return (
        <div>
            {!read_only ? 
                <div style={{display:"flex", alignItems: "center"}}>
                    <div 
                        className={["normalText", "normalMediumSize", "bold"].join(" ")} 
                        style={{display:"flex", width:"100%", cursor:"pointer", margin: "auto 0px", color:getAlertColor((optionsObject || {value:null}).value)}} 
                        onClick={() => handleConfigurationOpen(node)}
                    >
                        {t(node.node.properties.description)}
                        {(node.node.alerts || []).length > 0?
                            <GetAlertsComponent alerts={node.node.alerts} />  : null}  
                    </div>
                    <IconButton style={{width: 32, height: 32}} aria-label="duplicate" onClick={() => props.handleNodeDuplication(node)}>
                        <ContentCopyIcon style={{width: 20, height: 20}} />
                    </IconButton>
                    <IconButton style={{width: 32, height: 32}} aria-label="close" onClick={() => handleNodeRemove(node)}>
                        <CloseIcon style={{width: 20, height: 20}} />
                    </IconButton>
                </div>
                :
                <div style={{display:"flex"}}>
                    <div 
                        className={["normalText", "normalMediumSize", "bold"].join(" ")} 
                        style={{display:"flex", width:"100%", cursor:"pointer", margin: "auto 0px", color:getAlertColor((optionsObject || {value:null}).value)}} 
                        onClick={() => handleConfigurationOpen(node)}
                    >
                        {t(node.node.properties.description)}
                    </div>
                </div>
            }
        </div>
    )
}

export function ActionIndicatorConfiguration(props){
    const { t } = useTranslation();
    let {node} = props
    let {read_only} = props
    let {handleNodeChange} = props
    let {handleConfigurationClose} = props

    const [nodeState, setNodeState] = useState(node)

    useEffect(()=>{
        setNodeState(node)
    },[node])

    const handleNodeStateChange = (obj_key,value) => {
        let new_node = _.cloneDeep(nodeState)
        setObjectByPath(new_node.node,obj_key,value)
        setNodeState(new_node)
    }

    const handleSave = () => {
        handleConfigurationClose()
        handleNodeChange(nodeState)
    }

    const validateAllValues = () => {
        return (
            (nodeState.node.properties.flag && nodeState.node.properties.flag.length >0) &&
            (nodeState.node.properties.description && nodeState.node.properties.description.length >0) &&
            (nodeState.node.properties.enum && nodeState.node.properties.enum.length >0)
        )
    }

    return(
        <div style={{flexGrow:"1", padding: "20px", display:"flex", flexDirection:"column"}}>
            <div style={{display: "flex"}}>
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>{t(node.node.description)}</span>
                <Tooltip title={<div className="tooltipText"><ul>{node.node.helperText.map((help,index) => (<li key={index}>{t(help)}</li>))}</ul></div>}>
                    <HelpIcon style={{fontSize: "14px", display:"flex", margin:"auto 5px"}}/>
                </Tooltip>
            </div>
            <div className="internalCardContainer" style={{display:"flex", flexDirection: "column"}}>
                <TextField
                    label={t("Tipo")}
                    style={{textAlign:"center", width:"80%", margin:"3px auto"}}
                    className="filterTextbox" 
                    size="small" fullWidth 
                    value={nodeState.node.properties.flag}
                    onChange={(e) => handleNodeStateChange("properties.flag", e.target.value)}
                    InputProps={{
                        readOnly: read_only
                      }}
                    select
                >
                    {options.map((option, index) => (
                        <MenuItem key={index} value={option.value}><em className="normalText">{t(option.description)}</em></MenuItem>

                    ))}
                </TextField>
                <TextField
                    label={t("Descrição da Razão")}
                    style={{textAlign:"center", width:"80%", margin:"3px auto"}}
                    className="filterTextbox" 
                    size="small" fullWidth 
                    value={nodeState.node.properties.description}
                    onChange={(e) => handleNodeStateChange("properties.description", e.target.value)}
                    InputProps={{
                        readOnly: read_only
                      }}    
                />       
                <TextField 
                    label={t("Enumerador")}
                    style={{textAlign:"center", width:"80%", margin:"3px auto"}}
                    className="filterTextbox" 
                    size="small" fullWidth 
                    value={nodeState.node.properties.enum}
                    onChange={(e) => handleNodeStateChange("properties.enum", e.target.value)}
                    InputProps={{
                        readOnly: read_only
                      }}    
                />                
            </div>
            <div style={{display:"flex", margin: "auto auto 5px auto"}}>
                <div
                    className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")}
                    style={{width:"120px"}}
                    onClick={handleConfigurationClose}
                >
                    {read_only ? "X" : t("discard")}
                </div>
                {!read_only && 
                <div
                    className={
                        validateAllValues()
                        ?
                        ["button", "standard", "normalText", "normalMediumSize"].join(" ")
                        :
                        ["button", "standard", "normalText", "normalMediumSize","disabled"].join(" ")}
                    style={{width:"120px"}}
                    onClick={validateAllValues() ? handleSave : null}
                >
                    {t("save")}
                </div>}     
            </div>
        </div>
    )
}

export class ActionIndicatorBoxClass {
    constructor(rulesContext){
        this.rulesContext = rulesContext
    }
        
    validate(node){
        let put_error = false
        let alerts = []
        let values_list = options.reduce((acc, option) => {
            acc.push(option.value)
            return acc
        },[])
        //Validate if node has no children
        if((node.node.children || []).length !== 0){
            put_error = true
            alerts.push("O nó de Alertas não pode ter filhos")
        }
        //Validate if a decision was choosen
        if (!values_list.includes(node.node.properties.flag)){
            put_error = true
            alerts.push("Clique no nó deste Alerta, abra a configuração e escolha um tipo válido")
        }
        //Validate if a description was written
        if (node.node.properties.description.length === 0){
            put_error = true
            alerts.push("Clique no nó deste Alerta, abra a configuração e escreva uma descrição")
        }
        //Validate if a enum was written
        if (node.node.properties.enum.length === 0){
            put_error = true
            alerts.push("Clique no nó deste Alerta, abra a configuração e defina um enumerador")
        }
        //Validate if parent is valid
        if (node.parentNode != null) {
            if(node.parentNode.on_error){
                put_error = true
                alerts.push("O nó superior está inválido")
            }
        }
        return {validated: !put_error, alerts: alerts}
    }

    getType(node){
        return "void"
    }
}