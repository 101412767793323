import React from "react";
import { useTranslation } from "react-i18next";
import SerasaNegativeNotesCard from "../../../Cards/SerasaNegativeNotesCard/SerasaNegativeNotesCard";
import {titleCase, formatAddress, formatPhoneObject, getScoreInterpretation, formatAsMoney} from '../../../utils/utils'
import DataField from "../../../../@GeneralComponents/DataField";
import AnalysisCardContainer from "../../../utils/Components/AnalysisCardContainer";
import moment from "moment";
import NumberDescription from "../../../../@GeneralComponents/NumberDescription";

function Serasa(props) {

    const {t} = useTranslation()
    
    let {
        serasa,
        serasaScore2Img,
        serasaProbability2Img
    } = props;
    
    let {
        riskscoring,
        name,
        document_number,
        document_status,
        address,
        phone,
        birthdate,
        mother_name,
        gender
    } = serasa

    let {
        score,
        percentual_default_probability,
        message
    } = riskscoring

    let showRiskScoring = !!score 
    const showEstimatedIncome = serasa.estimated_income

    return (
        <div className="avoidPageBreakInside analysisCard" style={{minWidth: '900px'}}>
            <div 
                className={["blueText", "subtitleSize", "bold"].join(" ")}
                style={{margin:"auto 0px"}}
            >
                Serasa
            </div>
            <AnalysisCardContainer className="analysisCardContainer" grow={true}>
                <div className="analysisCard">
                    <div className="blueText subtitleSize" style={{marginBottom: "10px"}}>{t("Dados básicos")}</div>
                    <div style={{display:"flex", flexDirection:"column"}}>
                    {name&&
                    <DataField
                        title={t("name")}
                        label={titleCase(name,"-")}
                        size={"normalMediumSize"}
                        titleNoWrap={false}
                    />}
                    {document_number&&
                    <DataField
                        title={t("document_number")}
                        label={document_number}
                        size={"normalMediumSize"}
                        titleNoWrap={false}
                    />}
                    {document_status&&
                    <DataField
                        title={t("document_status")}
                        label={titleCase(t(document_status),"-")}
                        size={"normalMediumSize"}
                        titleNoWrap={false}
                    />}
                    {gender&&
                        <DataField
                        title={t("gender")}
                        label={titleCase(t(gender),"-")}
                        size={"normalMediumSize"}
                        titleNoWrap={false}
                    />}
                    {address&&
                    <DataField
                        title={t("address")}
                        label={formatAddress(address)}
                        size={"normalMediumSize"}
                        titleNoWrap={false}
                    />}
                    {phone&&
                    <DataField
                        title={t("Telefone")}
                        label={formatPhoneObject(phone)}
                        size={"normalMediumSize"}
                        titleNoWrap={false}
                    />}
                    {birthdate&&
                    <DataField
                        title={t("birthdate")}
                        label={moment(birthdate).format("DD/MM/YYYY")}
                        size={"normalMediumSize"}
                        titleNoWrap={false}
                    />}
                    {mother_name&&
                    <DataField
                        title={t("mother_name")}
                        label={titleCase(mother_name,"-")}
                        size={"normalMediumSize"}
                        titleNoWrap={false}
                    />}
                    </div>
                </div>
            </AnalysisCardContainer>
            {showRiskScoring && (
            <AnalysisCardContainer className="analysisCardContainer avoidPageBreakInside" grow={true}>
                <div className="analysisCard" style={{display:"flex", flexDirection:"column"}}>
                    <div className="blueText subtitleSize" style={{marginBottom: "10px"}}>{t("serasa_risk_scoring")}</div>
                    <div style={{display:"grid", gridTemplateColumns:"50% 50%"}}>
                        <NumberDescription
                            description="/1000"
                            total={score}
                            secondDescription={t("serasa_score")}
                        />
                        <div style={{width: '100px', margin:"auto"}}>
                            <img src={serasaScore2Img} alt="Score" />    
                        </div>
                    </div>
                    <div style={{display:"grid", gridTemplateColumns:"50% 50%"}}>
                        <NumberDescription
                            description={t("serasa_defaulted_probability")}
                            total={`${percentual_default_probability.toFixed(1).replace(".", ",")}%`}
                        />
                        <div style={{width: '100px', margin:"auto"}}>
                            <img src={serasaProbability2Img} alt="Probabilidade de Inadimplência" />    
                        </div>
                    </div>
                    <DataField
                        title={t("score_interpretation")}
                        label={message?t(message):t(getScoreInterpretation(score, "natural_person"))}
                        size={"normalMediumSize"}
                        titleNoWrap={false}
                    />
                    {showEstimatedIncome&&
                    <DataField
                        title={t("estimated_income")}
                        label={formatAsMoney("BRL", serasa.estimated_income)}
                        size={"normalMediumSize"}
                        titleNoWrap={false}
                    />}
                </div>
            </AnalysisCardContainer>
            )}
            {!showRiskScoring && (
            <AnalysisCardContainer className="analysisCardContainer avoidPageBreakInside" grow={true}>
                <div className="analysisCard">
                    <div className="blueText subtitleSize">{t("serasa_risk_scoring")}</div>
                    <div className="alignCenter" style={{ margin: "40px auto" }}>
                        <NumberDescription
                            description={t("serasa_score_not_available")}
                            secondDescription={((serasa||{}).riskscoring||{}).message?serasa.riskscoring.message:""}
                            total=""
                        />
                    </div>
                </div>
            </AnalysisCardContainer>
            )}
            {(serasa || {}).pefin ? 
            <div className="analysisCardContainer avoidPageBreakInside">
                <SerasaNegativeNotesCard negative_notes_data={serasa.pefin} has_negative_note={serasa.pefin.has_pefin} negative_note_type={"Pefin"} />
            </div> 
            : null}
            {(serasa || {}).refin ? 
            <div className="analysisCardContainer avoidPageBreakInside">
                <SerasaNegativeNotesCard negative_notes_data={serasa.refin} has_negative_note={serasa.refin.has_refin} negative_note_type={"Refin"} />
            </div> 
            : null}
            {(serasa || {}).dishonoured_check ? 
            <div className="analysisCardContainer avoidPageBreakInside">
                <SerasaNegativeNotesCard negative_notes_data={serasa.dishonoured_check} has_negative_note={serasa.dishonoured_check.has_dishonoured_check} negative_note_type={t("dishonoured_check")} />
            </div>
            : null}
            {(serasa || {}).lawsuits ? 
            <div className="analysisCardContainer avoidPageBreakInside">
                <SerasaNegativeNotesCard negative_notes_data={serasa.lawsuits} has_negative_note={serasa.lawsuits.has_lawsuits} negative_note_type={t("lawsuits")} />
            </div> 
            : null}
            {(serasa || {}).protests ? 
            <div className="analysisCardContainer avoidPageBreakInside">
                <SerasaNegativeNotesCard negative_notes_data={serasa.protests} has_negative_note={serasa.protests.has_protests} negative_note_type={t("protests")} />
            </div> 
            : null}
            {(serasa || {}).bankruptcy ? 
            <div className="analysisCardContainer avoidPageBreakInside">
                <SerasaNegativeNotesCard negative_notes_data={serasa.bankruptcy} has_negative_note={serasa.bankruptcy.has_bankruptcy} negative_note_type={t("bankruptcy")} />
            </div>
            : null}
            {(serasa || {}).spc_mentions ? 
            <div className="analysisCardContainer avoidPageBreakInside">
                <SerasaNegativeNotesCard negative_notes_data={serasa.spc_mentions} has_negative_note={serasa.spc_mentions.has_spc_mentions} negative_note_type={t("spc_mentions")} />
            </div>
            : null}
            {(serasa || {}).inquiries ? (
            <div className="analysisCardContainer avoidPageBreakInside">
                <SerasaNegativeNotesCard negative_notes_data={serasa.inquiries} has_negative_note={serasa.inquiries.has_inquiries} negative_note_type={t("inquiries")} />
            </div>
            ) : null}
            {(serasa || {}).stolen_documents ? (
            <div className="analysisCardContainer avoidPageBreakInside">
                <SerasaNegativeNotesCard negative_notes_data={serasa.stolen_documents} has_negative_note={serasa.stolen_documents.has_stolen_documents} negative_note_type={t("stolen_documents")} />
            </div>
            ) : null}
            {(serasa || {}).partner_data ? (
            <div className="analysisCardContainer avoidPageBreakInside">
                <SerasaNegativeNotesCard negative_notes_data={serasa.partner_data} has_negative_note={serasa.partner_data.has_partner_data} negative_note_type={t("partner_data")} />
            </div>
            ) : null}
        </div>
    );
}

export default Serasa
