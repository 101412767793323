import React from "react";
import * as S from './styles';
import logoQI from '../../../assets/logoQI.png'
import { CircularProgress } from "@material-ui/core";

const OfflineWarningScreen = (props) => {
    return(
        <S.Container hide={props.hide}>
            <S.Wrapper>
                <S.LogoQI src={logoQI} alt="QI Tech"/>
                <S.Title>Parece que você está offline...</S.Title>
                <S.Description>Você será redirecionado(a) novamente para área de trabalho assim que a conexão for reestabelecida.</S.Description>
                
                <S.Warning>Se você possui algum trabalho não salvo, não recarregue a página até a reconexão para evitar perder o seu progresso.</S.Warning>

                <S.ReconnectWrapper>
                    <S.ReconnectText>Tentando reconectar...</S.ReconnectText>
                    <CircularProgress style={{height: 16, width: 16}}/>
                </S.ReconnectWrapper>
            </S.Wrapper>
        </S.Container>
    );
}

export default OfflineWarningScreen;