import React, { useEffect } from "react";
import ValidatedImage from "../../utils/ValidatedImage"
import CNHOCRDialog from "./CNHOCRDialog/CNHOCRDialog"
import { CircularProgress } from '@material-ui/core';
import axios from "axios";
import LegacyCNHOCR from "./LegacyCNHOCR/LegacyCNHOCR";
import NewCNHOCR from "./NewCNHOCR/NewCNHOCR";
import { useTranslation } from "react-i18next";

function CNHOCRCard(props){

    const { t } = useTranslation()

// Set inputs
const { cnh } = props
const { cnh_verification }      = props.decision_metadata
const { document_verification } = props.decision_metadata

//Set state variables
const [CNHOCR, setOCR]              = React.useState(null)
const [CNHOCRFront, setOCRFront]    = React.useState(null)
const [CNHOCRBack, setOCRBack]      = React.useState(null)


//Set initial state of image dialog box
let [OCRPictureOpen, setOCRPictureOpen] = React.useState(false);

//Set handlers to open and close dialog
const handleOCRPictureOpen = () => {
    setOCRPictureOpen(true);
};
const handleOCRPictureClose = () => {
    setOCRPictureOpen(false);
};

useEffect(() => {
    const timer = setTimeout(() => {
        if (cnh.ocr_key){
            axios.get('/dash/ocr/' + cnh.ocr_key).then( response => {
                let ocr_data = response.data.processed_object
                if (ocr_data == null) throw new Error()
                setOCR(ocr_data);             
            }).catch(error => {
                setOCR(undefined)
            })
        }

        else {
            if (cnh.ocr_front_key) {
                axios.get('/dash/ocr/' + cnh.ocr_front_key).then( response => {
                    let ocr_data_front = response.data.processed_object 
                    if (ocr_data_front == null) throw new Error()
                    setOCRFront(ocr_data_front);             
                }).catch(error => {
                    setOCRFront(undefined)
                })
            }

            if (cnh.ocr_back_key) {
                axios.get('/dash/ocr/' + cnh.ocr_back_key).then( response => {
                    let ocr_data_back = response.data.processed_object 
                    if (ocr_data_back == null) throw new Error()
                    setOCRBack(ocr_data_back);             
                }).catch(error => {
                    setOCRBack(undefined)
                })
            }
        }
    }, 500);
    return () => {
        clearTimeout(timer)
    }
}, [cnh])

useEffect(() => {
    if (CNHOCRFront !== null || CNHOCRBack!== null){
        if (CNHOCRFront && CNHOCRBack) {
                    let ocr_data_joined = {...CNHOCRFront,...CNHOCRBack}
                    setOCR(ocr_data_joined)
                }
        else if (CNHOCRFront) setOCR(CNHOCRFront)
        else if (CNHOCRBack)  setOCR(CNHOCRBack)
        else setOCR(undefined)
    }
},[CNHOCRFront, CNHOCRBack])


let getPictureContent = (picture) => {
    if (picture === undefined) {
        return (          
            <div 
                className={["labelText", "normalMediumSize"].join(" ")} 
                style={{textAlign:"center", margin:"auto"}}
            >
                {t("information_unavailable")}
            </div>
        )
    } 
    else{
        return (
            <ValidatedImage
                image_key={picture}
                dash_api_endpoint_url={'/dash/ocr/image'}
                imageStyle = {{margin:"auto", width:"100%", height:"100%",position:"absolute", top:"-9999px",bottom:"-9999px",left:"-9999px",right:"-9999px", borderRadius: "5px", objectFit:"cover"}}
                imageAlt = {"Foto do Documento"}
            />
        );
    }
};

// Renderiza o card
if (CNHOCR === null) {
    return (
        <div style={{minHeight: "250px"}} className="analysisCard">
            <div className={["blueText", "subtitleSize"].join(" ")}>OCR</div>
            <div style={{display:"flex", flexGrow:"1"}}>
                <CircularProgress style={{margin:"auto"}} />
            </div>
        </div>
    );    
}
else if (CNHOCR === undefined){
    return (
        <div style={{minHeight: "250px"}} className="analysisCard">
            <div className={["blueText", "subtitleSize"].join(" ")}>CNH OCR</div>
            <div style={{display:"flex", flexGrow:"1"}}>
                <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                    {t("information_unavailable")}
                </div>
            </div>
        </div>
    )
}
else {
    return (
        <div style={{minHeight: "500px"}} className="analysisCard">
            <div className={["blueText", "subtitleSize"].join(" ")}>CNH OCR</div>
            <div className="internalCardContainer" style={{display:"flex", flexGrow:"1", maxHeight:"500px",flexDirection: "row"}}>
                <div style={{display:"flex", width:"28%", maxHeight:"64vw", flexDirection: "column", marginRight:"31px"}}>
                    <div style={{display:"block", width:"100%", height:"90%", margin: "0 auto", overflow:"hidden", position:"relative", alignItems: "center", justifyContent: "center",borderRadius: "5px" }}>
                            { cnh.ocr_key ? getPictureContent( cnh.ocr_key ) : 
                            cnh.ocr_front_key ? getPictureContent( cnh.ocr_front_key ) : getPictureContent( cnh.ocr_back_key )}
                    </div>
                    {(cnh.ocr_key || cnh.ocr_front_key || cnh.ocr_back_key) ? 
                    <div 
                        onClick={handleOCRPictureOpen}
                        className={["blueText", "normalSmallSize", "link"].join(" ")} 
                        style={{margin:"10px", textAlign:"center"}}
                    >
                        Ver fotos
                    </div> : null}
                </div>
                {Object.keys(cnh_verification || {}).length!==0?
                    <LegacyCNHOCR 
                        cnh_verification={cnh_verification}
                        CNHOCR={CNHOCR}
                    />:
                    <NewCNHOCR 
                        document_verification={document_verification}
                        CNHOCR={CNHOCR}
                    />}
                {(cnh.ocr_key || cnh.ocr_front_key || cnh.ocr_back_key) ? 
                    <CNHOCRDialog
                        open={OCRPictureOpen}
                        onClose={handleOCRPictureClose}
                        image_list={[cnh.ocr_key, cnh.ocr_front_key, cnh.ocr_back_key]}
                />: null}
            </div>
        </div>
    );
}
}

export default CNHOCRCard