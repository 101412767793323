import React from "react";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import warningIcon from "../../../../assets/cardEntryIcons/warningIcon.svg"
import fraudLogo from '../../../../assets/cardEntryIcons/fraud.svg';
import moment from 'moment'
import {formatAsMoney, checkNested, formatCard, colorRed} from "../../utils/utils"
import {getTransactionBlockStatusIcon, getTransactionStatusIcon, getAnalysisStatusColor, getAnalysisStatusSiglum, getAnalysisStatus} from "../../utils/utilsOrder"
import Tooltip from "@material-ui/core/Tooltip";

function OrderCard(props){
    let {order} = props

    return (
        <Card className="listCard">
            <Link
                className={["listCardItem", "link", "subtitleSize", "breakWord", "bold"].join(" ")}
                style={{flex:"1 1 110px", textAlign:"center"}}
                to={"/order/" + order.order_key}
            >
                   {checkNested(order, "seller", "name") ? (order.seller || {}).name || "-" : (order.customer || {}).name || "-"}
            </Link> 
            {checkNested(order, "seller", "name") ?
            <div 
                    className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                    style={{flex:"1 1 100px", textAlign:"center"}}
                >
                    {(order.customer || {}).name || "-"}
            </div> : null}
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                style={{flex:"1 1 100px", textAlign:"center"}}
            >
                {(order.customer || {}).document_number || "-"}
            </div>
            {order.payment.transactions.length <= 1 ? 
            <div 
            className={["listCardItem", "normalText", "subtitleSize", "noWrap"].join(" ")}
            style={{flex:"1 1 180px", textAlign:"center"}}
            >
                {checkNested(order, "payment", "transactions") ? 
                checkNested(order.payment.transactions[0], "bin") & checkNested(order.payment.transactions[0], "last_4") ? 
                formatCard(order.payment.transactions[0].bin, order.payment.transactions[0].last_4) : "-" :"-"}
            </div> :
            <div 
            className={["listCardItem", "normalText", "subtitleSize", "noWrap"].join(" ")}
            style={{flex:"1 1 180px", textAlign:"center"}}
            >
                <img src={warningIcon} alt="Warning Logo" />
                <div className="labelSpace" style={{color:"#DFC124"}}>Vários Cartões </div>
            </div>}
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")} 
                style={{flex:"1 1 130px", textAlign:"center"}}  
            >
                {formatAsMoney(order.payment.total_amount, "-")}
            </div>
            <div 
                className={["listCardItem", "normalText", "normalMediumSize", "bold"].join(" ")} 
                style={{flex:"1 1 45px", textAlign:"center", cursor: "default"}}  
            >
                {order.card_order_status === "fraud"
                ?
                <Tooltip title={<p className="tooltipText">Fraude</p>}>
                <div className={["circle", "small"].join(" ")} style={{backgroundColor:colorRed}}>
                    <img style={{width:"16px"}} src={fraudLogo} alt="fraud Logo" />
                </div>
                </Tooltip>
                :
                <Tooltip title={<p className="tooltipText">{getAnalysisStatus(order.analysis_status)}</p>}>
                <div className={["circle", "small"].join(" ")} style={{backgroundColor:getAnalysisStatusColor(order.analysis_status)}}>
                    {getAnalysisStatusSiglum(order.analysis_status)}
                </div>
                </Tooltip>}
            </div>
            <div 
                className={["listCardItem", "normalText", "subtitleSize","noWrap"].join(" ")}
                style={{flex:"1 1 110px", textAlign:"center"}}
            >
                {moment(order.order_date).format("DD/MM/YYYY HH:mm:ss")}
            </div>
            <div 
                className={["listCardItem", "normalText", "normalMediumSize", "bold"].join(" ")}  
                style={{flex:"1 1 35px", textAlign:"center", cursor: "default"}}  
            >
                {order.payment.transactions.length > 1 ?
                getTransactionBlockStatusIcon(order.payment.transactions) :
                getTransactionStatusIcon(order.payment.transactions[0].status, "small", true)}
            </div>
        </Card>
    )
}

export default OrderCard