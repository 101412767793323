import React from "react";
import BoavistaSummaryTotal from "../BoavistaSummaryData/BoavistaSummaryTotal";

function BoavistaProtestsSummaryCard(props) {

    const { protests } = props

    return (
        <div style={{display: 'grid',gridTemplateColumns: '22.5% 20% 57.5%',marginTop:'25px',marginBottom:'25px'}}>
            <BoavistaSummaryTotal
                description={'total_number_of_events'}
                total={protests.total_number_of_events}
            />
            <BoavistaSummaryTotal
                description={'total_amount'}
                total={protests.total_amount}
            />
        </div>
    );
}

export default BoavistaProtestsSummaryCard
                            