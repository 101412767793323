import styled from 'styled-components'

export const UpdatePermissionsWrapper = styled.div`
    width: 100%;
    padding: 16px 0 8px 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const HeadingTitles = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const AlertAppend = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
`;

export const AlertBox = styled.div`
    width: fit-content;
    display: ${(props) => props.isEditing ? 'flex' : 'none'};
    justify-content: center;
    align-items: center;
    height: 32px;
    gap: 8px;
    padding: 0px 16px;
    background-color: #FFCC99;
    font-size: 14px;
    font-family: "Open Sans";
    font-weight: 700;
    color: #000;
    border-radius: 16px;
`;

export const SectionHeading = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: fit-content;
    justify-content: space-between;
`;