//Importing particular modules
import {getFilterInitialSate, addFilters, filterChange, changeApply} from "../../Utils/FilterBox/utilsFilter"



//Defining function to change filter cache
export const changeFiltersCache = (filterTypes, filterParam, filterParamValue, local_storage_object_preffix) => {
    
    let filterObject = getFilterInitialSate(filterTypes)
    filterObject = addFilters(filterParam, filterObject)
    filterObject = filterChange(filterParamValue, filterParam, filterTypes[filterParam], filterObject)
    filterObject = changeApply(filterObject)
    
    let filters = filterObject.toPass
    let pageParams = {page: 1, filters: filters}

    let filterObjectName = local_storage_object_preffix + "FilterObject"
    let pageParamsName = local_storage_object_preffix + "PageParams"

    sessionStorage.setItem(pageParamsName, JSON.stringify(pageParams))
    sessionStorage.setItem(filterObjectName, JSON.stringify(filterObject))
}