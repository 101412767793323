import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from 'moment'
import {formatAsMoneyMinimum2Digits} from "../../../../utils/utils"

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
  },
  tableText: {
    fontFamily: "Open Sans",
    fontWeight: 'normal',
    fontSize: "12px",
    lineHeight: "16px",
    color: "#141414",
    textAlign: "center",
    margin: "10px",
    padding: "6px",
    maxWidth: "80px"
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: '#6F6F6F',
    fontSize: "14px",
    lineHeight: "19px",
    padding: "6px",
    maxWidth: "80px"
  },
  even: {
    background: "#F3F3F3",
  },
  odd: {
    background: "#FFFFFF",
  },
}));

function DAFNTable(props) {
  const classes = useStyles();
  useTheme();

  const getClass = (rowIndex) => {
    if (rowIndex % 2 === 0) {
      return classes.even;
    }

    return classes.odd;
  };

  return (
    <Paper className={classes.tableStyle}>
      <Table style={{ padding: "8px" }}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader}>Data de Inscrição</TableCell>
            <TableCell className={classes.tableHeader}>CPF</TableCell>
            <TableCell className={classes.tableHeader}>Nome</TableCell>
            <TableCell className={classes.tableHeader}>Origem da Dívida</TableCell>
            <TableCell className={classes.tableHeader}>Ajuízado</TableCell>
            <TableCell className={classes.tableHeader}>Situação</TableCell>
            <TableCell className={classes.tableHeader}>Tipo de Devedor</TableCell>
            <TableCell className={classes.tableHeader}>Unidade Responsável</TableCell>
            <TableCell className={classes.tableHeader}>Valor</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.dafn_data.map((debt,index) => (
            <TableRow
              key={index}
              className={getClass(index)}
            >
              <TableCell className={classes.tableText}>
                {debt.date == null ? "-" : moment(debt.date).format('DD/MM/YYYY')}
              </TableCell>
              <TableCell style={{whiteSpace:"nowrap"}} className={classes.tableText}>
                {debt.document_number}
              </TableCell>
              <TableCell className={classes.tableText}>
                {debt.name}
              </TableCell>
              <TableCell className={classes.tableText}>
                {debt.mainly_revenue}
              </TableCell>
              <TableCell className={classes.tableText}>
                {debt.judged === true ? "Sim" : debt.judged === false ? "Não" : "-"}
              </TableCell>
              <TableCell className={classes.tableText}>
                {debt.status}
              </TableCell>
              <TableCell className={classes.tableText}>
                {debt.debtor_type}
              </TableCell>
              <TableCell className={classes.tableText}>
                {debt.city == null ? "-" : debt.city + " - "+ debt.uf}
              </TableCell>
              {typeof(debt.value)==="number"
              ? <TableCell className={classes.tableText}>
                  {formatAsMoneyMinimum2Digits(debt.value)}
                </TableCell>
              : <TableCell className={classes.tableText}>
                {`R$ ${debt.value}`}
              </TableCell>
              }
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default DAFNTable;
