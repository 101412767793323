import React, { useState, useContext } from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputMask from "react-input-mask";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import axios from "axios";
import AuthContext from "../../../context/auth-context"
import CompanyContext from "../../../context/companies-context"
import MenuItem from '@material-ui/core/MenuItem';

const theme = createMuiTheme({
    overrides: { MuiDialog: { 
        paperWidthSm:{
            width:"600px"
        },
        paperScrollPaper:{
            display: "flex",
            maxHeight: "calc(100% - 10px)",
            flexDirection: "column"
        }
    }},
    palette:{
        primary: {
            main:"#0b1f82"
        },
    }
  });

const UserInvite = (props) => {
    let user_data = useContext(AuthContext).user_data
    let companyList = useContext(CompanyContext).onboarding_list

    let initial_state = {
        first_name: {
            value:"",
            error: false,
            verified: false,
            error_message: "Somente letras, espaços e acima de 2 caracteres são aceitos"
        },
        last_name: {
            value:"",
            error: false,
            verified: false,
            error_message: "Somente letras, espaços e acima de 2 caracteres são aceitos"    
        },
        email: {
            value:"",
            error: false,
            verified: false,
            error_message: "E-mail inválido"
        },
        document_number: {
            value:"",
            error: false,
            verified: false,
            error_message: "CPF inválido"
        },
        form_error: {
            error_first_name: true,
            error_last_name: true, 
            error_document_number: true, 
            error_email: true, 
            valid: false,
        }
    }

    const [firstName, setFirstName] = useState(initial_state.first_name)
    const [lastName, setLastName] = useState(initial_state.last_name)
    const [email, setEmail] = useState(initial_state.email)
    const [documentNumber, setDocumentNumber] = useState(initial_state.document_number)
    const [formError, setFormError] = useState(initial_state.form_error);
    const [companyValue, setCompanyValue] = useState("business_group")
    const [disableAll, setDisableAll] = useState(false)

    const cleanAll = () => {
        setFirstName(initial_state.first_name)
        setLastName(initial_state.last_name)
        setEmail(initial_state.email)
        setDocumentNumber(initial_state.document_number)
    }

    const getRegex = (type) => {
        switch(type) {
            case "first_name":
            case "last_name":
                return "^[a-zA-Z \u00C0-\u00FF]*$"
            case "email":
                return "^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"
            case "document_number":
                return "^[0-9]{3}[.][0-9]{3}[.][0-9]{3}[-][0-9]{2}$"
            default:
        }
    }

    const handleChange = (event) => {
        let error
        let item
        let setFunction
        let regex

        regex = new RegExp(getRegex(event.target.name));

        if (!regex.test(event.target.value) || event.target.value === "") {
            error = true
        }else {
            if ((event.target.name === "first_name" || event.target.name === "last_name") && 
            event.target.value.length < 3) error = true
            else error = false
        }
        switch (event.target.name) {
            case "first_name":
                item = firstName
                setFunction = setFirstName
                break
            case "last_name":
                item = lastName
                setFunction = setLastName
                break;
            case "email":
                item = email
                setFunction = setEmail
                break;
            case "document_number":
                item = documentNumber
                setFunction = setDocumentNumber
                break;
            default:
        }
        setFunction({...item, error: error, value: event.target.value})
        let newFormError = {...formError}
        newFormError["error_" + event.target.name] = error
        let valid = true
        Object.keys(newFormError).every(k => {
            if (k !== "valid"){
                if (newFormError[k] === true) {
                    valid = false
                    return false
                }
            }
            return true
        })
        newFormError.valid = valid
        setFormError(newFormError);
  };

    const handleInvite = (event) => {
        if (disableAll) return
        setDisableAll(true)
        props.setSnackBarStatus({
            opened:true, 
            severity:"info", 
            message: "Espere um momento enquanto a sua requisição é processada"
        })
        let roles = []
        let payload = {
            email: email.value,
            document_number: documentNumber.value,
            first_name: firstName.value,
            last_name: lastName.value,
            roles: roles,
        }
        if (user_data.business_group_key && companyValue !== "business_group") payload['company_key'] = companyValue
        axios.post("/dash/user", payload)
        .then((response) => {
            props.setSnackBarStatus({
                opened:true, 
                severity:"success", 
                message: "Usuário convidado com sucesso"
            })
            handleClose();
            props.onUpdate(payload.company_key ? {company_key: companyValue}: null)
            setDisableAll(false)
        })
        .catch((error) => {
            let errorMessage
            if ((error.response || {}).status === 409) {
                cleanAll()
                errorMessage = "E-mail Já cadastrado"
            } else {
                cleanAll()
                errorMessage = "Houve um problema ao convidar este usuário"
            }
            props.setSnackBarStatus({
                opened:true, 
                severity:"error", 
                message: errorMessage
            })
            setDisableAll(false)
        });
    };

    const handleClose = () => {
        cleanAll()
        props.onClose()
    }

    return (
        <ThemeProvider theme={theme}>
        <Dialog
            open={props.open}
            onClose={handleClose}
            aria-labelledby="change-password-dialog"
            keepMounted={false}
        >
            <DialogTitle
                className={['blueText', "subtitleSize"].join(" ")} 
                id="change-password-dialog"
            >
                Convidar Usuário
            </DialogTitle>
            <DialogContent style={{display: "flex", flexDirection: "column"}}>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <TextField
                        disabled={disableAll}
                        autoFocus
                        name="first_name"
                        label="Nome"
                        type="text"
                        style={{width:"50%", margin: "auto"}} 
                        onChange={handleChange}
                        value={firstName.value}
                        error={firstName.error}
                        helperText={firstName.error ? firstName.error_message : " "}
                        fullWidth
                    />
                    <TextField
                        disabled={disableAll}
                        name="last_name"
                        label="Sobrenome"
                        type="text"
                        style={{width:"50%", margin: "auto"}} 
                        onChange={handleChange}
                        value={lastName.value}
                        error={lastName.error}
                        helperText={lastName.error ? lastName.error_message : " "}
                        fullWidth
                    />
                    <InputMask
                        mask="999.999.999-99"
                        onChange={handleChange}
                        value={documentNumber.value}
                        disabled={disableAll}
                    >
                        <TextField
                            
                            name="document_number"
                            label="CPF"
                            type="text"
                            style={{width:"50%", margin: "auto"}} 
                            error={documentNumber.error}
                            helperText={documentNumber.error ? documentNumber.error_message : " "}
                            fullWidth
                        />
                    </InputMask>
                    <TextField
                        disabled={disableAll}
                        name="email"
                        label="E-mail"
                        type="text"
                        style={{width:"50%", margin: "auto"}} 
                        onChange={handleChange}
                        value={email.value}
                        error={email.error}
                        helperText={email.error ? email.error_message : " "}
                        fullWidth
                    />
                    {user_data.business_group_key ? companyList ? 
                    <TextField
                        disabled={disableAll}
                        name="company"
                        label="Cliente"
                        style={{width:"50%", margin: "auto"}} 
                        onBlur={props.onBlur} 
                        onChange={e => setCompanyValue(e.target.value)} 
                        size="small" fullWidth
                        default
                        value={companyValue} 
                        select
                    >
                        <MenuItem value={"business_group"}><em className={["normalText", "normalMediumSize"].join(" ")}>Todos</em></MenuItem>
                        {companyList.companies.map((companyInfo, index)=>(
                            <MenuItem key={index} value={companyInfo.company_key}><em className={["normalText", "normalMediumSize"].join(" ")}>{companyInfo.name}</em></MenuItem>
                        ))}
                    </TextField> :null : null }
                </div>
            </DialogContent>
            <DialogActions>
                <div
                    className={["button", "cancel", "normalText", "normalMediumSize"].join(" ")} 
                    onClick={handleClose} 
                >
                    Cancelar
                </div>
                <div
                    className={
                        formError.valid ? 
                        ["button", "standard", "normalText", "normalMediumSize"].join(" "):
                        ["button", "standard", "normalText", "normalMediumSize", "disabled"].join(" ")   
                    }
                    onClick={formError.valid ? handleInvite : null} 
                >
                    Convidar
                </div>
            </DialogActions>
        </Dialog>
        </ThemeProvider>
    );
};
export default UserInvite;