import React, { useContext } from "react"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { getReportIndicatorIcon, getTransactionStatusIcon, getTransactionDirectionIcon, getReasonDescription } from "../PIX/utils/utilsTransaction"
import moment from 'moment'
import {
    getTableRowClass, formatAsMoney, getAnalysisStatus,
    getAnalysisStatusSiglum, getAnalysisStatusColor, getAgencyInfo
} from "../PIX/utils/utils"
import { Link } from "react-router-dom"
import Tooltip from "@material-ui/core/Tooltip"
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline"
import AuthContext from "../../context/auth-context"

const useStyles = makeStyles((theme) => ({
    tableStyle: {
        border: "none",
        boxShadow: "none",
    },
    blueText: {
        fontFamily: "Open Sans",
        fontWeight: "600",
        color: "#0b1f82"
    },
    redText: {
        fontFamily: "Open Sans",
        fontWeight: "600",
        color: "#D91A1A"
    },
    tableText: {
        fontFamily: "Open Sans",
        fontSize: "12px",
        lineHeight: "16px",
        textAlign: "center",
        margin: "10px",
        padding: "6px",
    },
    tableHeader: {
        textAlign: "center",
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: "normal",
        color: '#6F6F6F',
        fontSize: "14px",
        lineHeight: "19px",
        padding: "6px",
    }
}))

function TransactionHistoricTable(props) {
    const classes = useStyles()
    useTheme()

    const { alert_triggers_transactions } = props
    let business_group_key = useContext(AuthContext).user_data.business_group_key

    const getCounterPartObject = (transaction) => {
        let counter_part_account_object = (transaction.transaction_direction === 'sent' ?
            transaction.destination_account :
            transaction.source_account)
        return counter_part_account_object
    }

    const getAgencies = (transaction) => {
        let [institution_name] = getAgencyInfo(getCounterPartObject(transaction))
        return institution_name
    }
    return (
        <Paper className={classes.tableStyle}>
            <Table style={{ padding: "8px" }}>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableHeader}>ID</TableCell>
                        {business_group_key&&<TableCell className={classes.tableHeader}>Companhia</TableCell>}
                        <TableCell className={classes.tableHeader}>Valor</TableCell>
                        <TableCell className={classes.tableHeader}>Relatos</TableCell>
                        <TableCell className={classes.tableHeader}>Tipo</TableCell>
                        <TableCell className={classes.tableHeader} style={{ width: "10%" }}>Contraparte</TableCell>
                        <TableCell className={classes.tableHeader} style={{ width: "10%" }}>Status de Fraude</TableCell>
                        <TableCell className={classes.tableHeader}>Data</TableCell>
                        <TableCell className={classes.tableHeader} style={{ width: "10%" }}>Status da Transação</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.historic.map((transaction, index) => (
                        <TableRow
                            key={index}
                            className={getTableRowClass(index)}
                        >
                            <TableCell
                                className={[classes.tableText, classes.blueText, "link", "noWrap"].join(" ")}
                            >
                                <Link
                                    className={
                                        alert_triggers_transactions ?
                                            alert_triggers_transactions.filter((item) => item === transaction.transaction_key).length ?
                                                "linkRed" :
                                                "link" :
                                            "link"
                                    }
                                    to={"/pix-transaction/" + transaction.transaction_key}
                                >
                                    {alert_triggers_transactions ?
                                        alert_triggers_transactions.filter((item) => item === transaction.transaction_key).length ?
                                            <Tooltip title={<p className="tooltipText">Transação responsável por um trigger</p>}>
                                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <ErrorOutlineIcon style={{ color: "#D91A1A", width: "18px", marginRight: "5px" }} />
                                                    {transaction.transaction_key}
                                                </div>
                                            </Tooltip> :
                                            <div>
                                                {transaction.transaction_key}
                                            </div> :
                                        <div>
                                            {transaction.transaction_key}
                                        </div>
                                    }
                                </Link>
                            </TableCell>
                            {business_group_key&&
                            <TableCell className={classes.tableText}>
                                {transaction.company_name?transaction.company_name:"-"}
                            </TableCell>}
                            <TableCell className={classes.tableText}>
                                {formatAsMoney(transaction.amount, "-")}
                            </TableCell>
                            <TableCell className={[classes.tableText, "breakWord"].join(" ")}>
                                {getReportIndicatorIcon(transaction.infraction_reports)}
                            </TableCell>
                            <TableCell className={[classes.tableText].join(" ")}>
                                {getTransactionDirectionIcon(transaction.transaction_direction, "small", true)}
                            </TableCell>
                            <TableCell className={classes.tableText}>
                                {getAgencies(transaction) ?
                                    <Tooltip title={<p className="tooltipText">{getAgencies(transaction)}</p>}>
                                        <div className={["textOverflow", "centerText", "labelSpace"].join(" ")}>
                                            {getAgencies(transaction)}
                                        </div>
                                    </Tooltip>
                                    : (getCounterPartObject(transaction)).participant}
                            </TableCell>
                            <TableCell className={classes.tableText}>
                                <Tooltip title={<p className="tooltipText" style={{ textAlign: "center" }}>
                                    {getAnalysisStatus(transaction.analysis_status)}
                                    <br />{getReasonDescription(transaction)}
                                </p>}>
                                    <div className={["circle", "small"].join(" ")} style={{ backgroundColor: getAnalysisStatusColor(transaction.analysis_status) }}>
                                        {getAnalysisStatusSiglum(transaction.analysis_status)}
                                    </div>
                                </Tooltip>
                            </TableCell>
                            <TableCell className={classes.tableText}>
                                {moment(transaction.transaction_date).format("DD/MM/YYYY HH:mm:ss")}
                            </TableCell>
                            <TableCell className={classes.tableText}>
                                {getTransactionStatusIcon(transaction.transaction_status, "small", true)}
                            </TableCell>
                            <TableCell className={classes.tableText}>

                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    )
}

export default TransactionHistoricTable
