//Importing modules
import React, {useState} from "react";
import { makeStyles} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip'
import EditIcon from '@material-ui/icons/Edit';
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import {getTableRowClass, translateFieldValue, checkForGeoShapeField} from "../../../utils/utils";
import ManualEditDialog from "./EditItem/ManualIEditDialog/ManualEditDialog";
import moment from 'moment';

//Defining CSS for List Contents Table
const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
  },
  blueText: {
    fontFamily: "Open Sans",
    fontWeight: "600",
    color: "#0b1f82"
},
  tableText: {
    fontFamily: "Open Sans",
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "center",
    margin: "10px",
    padding: "10px",
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: '#6F6F6F',
    fontSize: "14px",
    lineHeight: "19px",
    padding: "6px",
  }
}));


//Defining List Contents Table component
function ListContentsTable(props) {
  //Starting predefined styles to be used on table
  const classes = useStyles();

  //Getting data from props
  let { listContents } = props
  let { setListContents } = props
  let { list } = props
  let { companyKey, userData, handleReloadPage, permissions } = props

  const handleSelectedItemList = (event, index) => {
      let newListContentsState = [...listContents]
      newListContentsState[index].isSelected = event.target.checked
      setListContents(newListContentsState)
  }

  const [isCheckAll, setIsCheckAll ] = useState(false)

  const handleSelectedAll = (e) => {
    let newListContentsState = [...listContents]
    setIsCheckAll(!isCheckAll)
    newListContentsState.map(item => item.isSelected = !isCheckAll)
    setListContents(newListContentsState)
  }

  const [openManualEditDialog, setOpenManualEditDialog] = useState(false)

  const [listItem, setListItem ] = useState({})

  const handleEditItem = (e, index) => {
    setOpenManualEditDialog(true)
    setListItem({...listContents[index]})
  }

  let isGeoShapeField = checkForGeoShapeField(list.fields)
  let hasKeyFieldOnly = list.fields.length === 1
  let canEdit = !isGeoShapeField && !hasKeyFieldOnly

  const getFieldUpdateInfo = (listContent) => {
    return listContent.update_events 
      ? listContent.update_events.map((update_event, idx) => (<p>{(idx + 1) + ". Modificado em: " + moment(update_event.created_at).format("DD/MM/YYYY")} <br/>  {" por: " + update_event.analyst_name + "."}</p>))
      : "Sem Modificações"
  }

  //Rendering Table
  return (
    <>
    <Paper className={classes.tableStyle}>
      <Table style={{ padding: "8px" }}>
        <TableHead>
            <TableRow>
                {permissions.delete_item&&
                <TableCell className={classes.tableHeader}>
                  <Tooltip title={<p className="tooltipText">{"Selecionar todos"}</p>}>
                    <Checkbox
                            checked={isCheckAll}
                            onChange={handleSelectedAll}
                            color="primary"
                            name="selectAll"
                        />
                  </Tooltip>
                </TableCell>}
                {list.fields.map((fieldSpecifications ,index) => (
                  index === 0 ? 
                  <Tooltip key={`tooltip-${index}`} title={<p className="tooltipText">{"valor chave"}</p>}>
                    <TableCell key={index} className={classes.tableHeader}>
                    {fieldSpecifications.name}
                    </TableCell>
                  </Tooltip> : 
                <TableCell key={index} className={classes.tableHeader}>
                    {fieldSpecifications.name}
                </TableCell>))}
                {permissions.update_item&&
                <TableCell className={classes.tableHeader} style={{width: '8%'}}>
                </TableCell>}
                <TableCell className={classes.tableHeader} style={{width: '8%'}}>
                </TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {listContents.map((rowData,index) => (
                <TableRow
                key={`list-content-${index}`}
                className={getTableRowClass(index)}
                > 
                  {permissions.delete_item&&
                    <TableCell className={[classes.tableText, "breakWord"].join(" ")} style={{width:"10%"}}>
                        <Checkbox
                            checked={listContents[index].isSelected}
                            onChange={(e) => handleSelectedItemList(e,index)}
                            color="primary"
                        />
                    </TableCell>}
                    {list.fields.map((fieldSpecifications ,index) => (
                    <TableCell key={`field-specifications-${index}`} className={[classes.tableText].join(" ")} style={{width: "100px"}}>
                        {translateFieldValue(rowData.values[fieldSpecifications.name],fieldSpecifications.type.enum)}
                    </TableCell>))}
                  {!permissions.update_item?
                    null
                    :
                    canEdit?
                    <TableCell 
                      className={classes.tableText} 
                      onClick={(e) => handleEditItem(e,index)} 
                      style={{cursor:"pointer", width:"50px"}}>
                        <EditIcon />
                    </TableCell>
                    :
                    isGeoShapeField?
                    <TableCell 
                      className={classes.tableText} style={{color: "gray", width:"50px"}}>
                        <Tooltip title={<p className="tooltipText" style={{width:"250px", textAlign:"center"}}>{"Listas contendo dados do tipo Polígono Geográfico não suportam edição manual. Para adicionar/excluir dados, favor usar nossa API."}</p>}>
                          <EditIcon />
                      </Tooltip>
                    </TableCell>
                    :
                    <TableCell 
                      className={classes.tableText} style={{color: "gray", width:"50px"}}>
                        <Tooltip title={<p className="tooltipText" style={{width:"250px", textAlign:"center"}}>{"Valores chaves não podem ser editados. Para alterá-los, utilize os recursos de excluir e inserir item"}</p>}>
                            <EditIcon />
                      </Tooltip>
                    </TableCell>
                  }
                    <TableCell className={classes.tableText} style={{color: "gray", width:"50px"}}>
                          <Tooltip title={<p className="tooltipText" style={{width:"250px", textAlign:"center"}}>{getFieldUpdateInfo(rowData)}</p>}>
                            <ErrorOutlineIcon />
                          </Tooltip>
                      </TableCell>
                </TableRow>

            ))}
        </TableBody>
      </Table>
    </Paper>
      <ManualEditDialog
                  open={openManualEditDialog}
                  listItem={listItem}
                  list={list}
                  handleReloadPage = {handleReloadPage}
                  onClose={() => setOpenManualEditDialog(false)}
                  companyKey={companyKey} 
                  userData={userData} 
              />
      
    </>
  );
}



//Exporting ListContentsTable component as default
export default ListContentsTable;
