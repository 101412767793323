import React from 'react'

function SelectLockTypeContent (props){

    let { setLockType } = props
    let { setPersonType } = props

    return (
        <div>
            <div>
                <div className="labelText" style={{marginBottom:"10px"}}>
                    Tipo de Bloqueio:
                </div>
                <div style={{ display:"flex", justifyContent: "space-evenly", margin:"auto 0px", marginBottom:"30px"}}>
                    <div
                        className={props.lockType === "customer" ? 
                        ["button", "clicked", "normalText", "normalMediumSize"].join(" ") : 
                        ["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")}
                        onClick={() => setLockType("customer")} 
                        style={{width:"30%", display:"flex", alignItems:"center", justifyContent:"center"}}
                    >
                        Comprador
                    </div>
                    <div
                        className={props.lockType === "seller" ? 
                        ["button", "clicked", "normalText", "normalMediumSize"].join(" ") : 
                        ["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")}
                        onClick={() => setLockType("seller")} 
                        style={{width:"30%", display:"flex", alignItems:"center", justifyContent:"center"}}
                    >
                        Payout do Vendedor
                    </div>
                    <div
                        className={props.lockType === "seller_transactional" ? 
                        ["button", "clicked", "normalText", "normalMediumSize"].join(" ") : 
                        ["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")}
                        onClick={() => setLockType("seller_transactional")} 
                        style={{width:"30%", display:"flex", alignItems:"center", justifyContent:"center"}}
                    >
                        Transação do Vendedor
                    </div>
                </div>            
            </div>
            <div>
                <div className="labelText" style={{marginBottom:"10px"}}>
                    Natureza Jurídica:
                </div>
                <div style={{ display:"flex", justifyContent: "space-evenly", margin:"auto 0px"}}>
                    <div
                        className={props.personType === "natural_person" ? 
                            ["button", "clicked", "normalText", "normalMediumSize"].join(" ") : 
                            ["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")} 
                        onClick={() => setPersonType("natural_person")} 
                        style={{width:"40%"}}
                    >
                        Pessoa Física
                    </div>
                    <div
                        className={props.personType === "legal_person" ? 
                            ["button", "clicked", "normalText", "normalMediumSize"].join(" ") : 
                            ["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")} 
                        onClick={() => setPersonType("legal_person")} 
                        style={{width:"40%"}}
                    >
                        Pessoa Jurídica
                    </div>
                </div>            
            </div>
        </div>
    )
}

export default SelectLockTypeContent