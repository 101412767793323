import React,{useContext} from "react";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import moment from 'moment'
import {getBillPaymentStatusIcon, getReasonDescription } from "../../utils/utilsBillPayment"
import {formatAsMoney, getAnalysisStatusColor, 
        getAnalysisStatusSiglum, getAnalysisStatus} from "../../utils/utils"
import Tooltip from "@material-ui/core/Tooltip";
import AuthContext from "../../../../context/auth-context";

function BillPaymentCard(props){

    let is_fraud = (props.bill_payment.fraud_status === "fraud")

    let {user_data} = useContext(AuthContext)

    return (
        <Card className="listCard">
            <Link
                className={
                    is_fraud ? 
                    ["listCardItem", "linkRed", "subtitleSize", "breakWord", "bold"].join(" ")
                    :
                    ["listCardItem", "link", "subtitleSize", "breakWord", "bold"].join(" ")}
                style={{flex:"1 1 110px", textAlign:"center"}}
                to={"/bill-payment/" + props.bill_payment.bill_payment_key}
            >
                {is_fraud ?
                    <Tooltip title={<p className="tooltipText">Transação reportada como fraude</p>}>
                        <div style={{display:"flex", justifyContent: "center", alignItems: "center"}}>
                            {props.bill_payment.id}
                        </div>
                    </Tooltip> :
                    <div>
                        {props.bill_payment.id}
                    </div>
                    }
            </Link>
            {user_data.business_group_key &&
            <div 
                className={["listCardItem", "normalText"].join(" ")}
                style={{flex:"1 1 80px", textAlign:"center"}}
            >
                {props.bill_payment.company_name}
            </div>}


            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")} 
                style={{flex:"1 1 130px", textAlign:"center"}}  
            >
                {formatAsMoney(props.bill_payment.amount, "-")}
            </div>
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                style={{flex:"1 1 100px", textAlign:"center"}}
            >
                {(props.bill_payment.payer || {}).document_number || "-"}
            </div>
            <div 
                className={["listCardItem", "normalText", "normalMediumSize", "bold"].join(" ")} 
                style={{flex:"1 1 45px", textAlign:"center", cursor: "default"}}  
            >
                <Tooltip title={<p className="tooltipText" style={{textAlign: "center"}}>
                    {getAnalysisStatus(props.bill_payment.analysis_status)}
                    <br/>{getReasonDescription(props.bill_payment)} 
                </p>}>
                <div className={["circle", "small"].join(" ")} style={{backgroundColor:getAnalysisStatusColor(props.bill_payment.analysis_status)}}>
                    {getAnalysisStatusSiglum(props.bill_payment.analysis_status)}
                </div>
                </Tooltip>
            </div>
            <div 
                className={["listCardItem", "normalText", "subtitleSize","noWrap"].join(" ")}
                style={{flex:"1 1 110px", textAlign:"center"}}
            >
                {moment(props.bill_payment.bill_payment_date).format("DD/MM/YYYY HH:mm:ss")}
            </div>
            <div 
                className={["listCardItem", "normalText", "normalMediumSize", "bold"].join(" ")}  
                style={{flex:"1 1 35px", textAlign:"center", cursor: "default"}}  
            >
                {getBillPaymentStatusIcon(props.bill_payment.bill_payment_status, "small", true)}
            </div>
        </Card>
    )
}

export default BillPaymentCard