import React from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Filter from "./Filter/Filter";

const theme = createMuiTheme({
  overrides: { MuiInputBase: { input: { padding: "6px 0px 3px 5px" } } },
});

function FilterBox(props) {
  const filtersObj = Object.keys(props.filters).map((filter_name) => (
    <ThemeProvider key={filter_name} theme={theme}>
      <Filter
        key={filter_name}
        filter_name={filter_name}
        filter={props.filters[filter_name]}
        value={props.parameters[filter_name] || '' }
        onChange={(filter_name, data) => {
          props.onChange(filter_name, data);
        }}
        onBlur={(filter_name, data) => {
            props.onChange(filter_name, data);
        }}
      />
    </ThemeProvider>
  ));

  return (
    <div className="filterBoxContainer normalSmallSize">
      <div style={{ display: "flex", flexWrap: "wrap" }}>{filtersObj}</div>
    </div>
  );
}

export default FilterBox;
