import { t } from 'i18next'
import React from 'react'
import DataField from "../../utils/DataField"

function CustomDataCard(props) {

    const { data } = props

    const f = (key) => {
        return (key[0].toUpperCase()+key.substring(1)).replaceAll("_"," ")
    }

    return (
        <div className="analysisCard">
            <div className={["blueText", "subtitleSize"].join(" ")}>{t("custom_data")}</div>
            <div className="internalCardContainer"  style={{display: "flex", flexDirection: "column"}}>
                {Object.entries(data.custom_data).map(([key, value]) => (
                    (typeof value === 'object')?
                    Object.entries(data.custom_data[key]).map(([key1, value1]) => (
                        (typeof value1 === 'object')?
                        Object.entries(data.custom_data[key][key1]).map(([key2, value2]) => (
                            (typeof value2 === 'object')?
                            Object.entries(data.custom_data[key][key1][key2]).map(([key3, value3]) => (
                                (typeof value3 === 'object')?
                                Object.entries(data.custom_data[key][key1][key2][key3]).map(([key4, value4]) => (
                                <DataField
                                    key={`${key}.${key1}.${key2}.${key3}.${key4}.${value4}`}
                                    title={`${f(key)} > ${f(key1)} > ${f(key2)} > ${f(key3)} > ${f(key4)}`}
                                    label={value4}
                                    size={"normalMediumSize"}
                                    titleNoWrap={true}
                                    margin="5px"
                                    isTranslated={false}
                                />
                                ))
                                :
                                <DataField
                                    key={`${key}.${key1}.${key2}.${key3}.${value3}`}
                                    title={`${f(key)} > ${f(key1)} > ${f(key2)} > ${f(key3)}`}
                                    label={value3}
                                    size={"normalMediumSize"}
                                    titleNoWrap={true}
                                    margin="5px"
                                    isTranslated={false}
                                />
                            ))
                            :
                            <DataField
                                key={`${key}.${key1}.${key2}.${value2}`}
                                title={`${f(key)} > ${f(key1)} > ${f(key2)}`}
                                label={value2}
                                size={"normalMediumSize"}
                                titleNoWrap={true}
                                margin="5px"
                                isTranslated={false}
                            />
                        ))
                        :
                        <DataField
                            key={`${key}.${key1}.${value1}`}
                            title={`${f(key)} > ${f(key1)}`}
                            label={value1}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="5px"
                            isTranslated={false}
                        />
                    ))
                    :
                    <DataField
                        key={`${key}.${value}`}
                        title={`${f(key)}`}
                        label={value}
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        margin="5px"
                        isTranslated={false}
                    />
                ))}
            </div>
        </div>
    )
}

export default CustomDataCard