import React, { useState, useContext } from "react";
import {Link} from "react-router-dom";
import AuthContext from "../../../../context/auth-context"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import Collapse from "@material-ui/core/Collapse";
import DataField from "../../utils/DataField"
import {getCurrentAnalystColor, correctRentalStoryType, getCurrentAnalystText, translateClientType, formatPhone, getStatusColor, getStatus, getStatusSiglum, time_feature, checkNested, clientAge, formatAsMoney, getReservationFormat, getRentalDaysFormat} from "../../utils/utils"
import { Tooltip } from "@material-ui/core";
import CurrentAnalystDialog from "./CurrentAnalystDialog/CurrentAnalystDialog"


function RentalAgreementCard(props) {
    
    let user_key = useContext(AuthContext).user_data.user_key
    let roles = useContext(AuthContext).user_data.roles

    let usedCurrentAnalyst = props.rental_agreement.current_analyst ? {...props.rental_agreement.current_analyst}: null

    let enableDialog = usedCurrentAnalyst != null && 
        user_key !== (usedCurrentAnalyst || {}).analyst_key ? true : false
    const [expanded, setExpanded] = useState(false);
    const [openCurrentAnalystDialog, setOpenCurrentAnalystDialog] = useState(false);

    const handleExpand = (event) => {
        event.stopPropagation();
        setExpanded(!expanded);
    };

    const checkDocumentType = (rental_agreement) => {
        if (!rental_agreement || !rental_agreement.client || !rental_agreement.client.document_number) return "-"
        if (rental_agreement.client.document_number.length === 14) return "CPF"
        return "EXT"
    }

    return (
        <Card className={["listCard", "collapse"].join(" ")}>
            <div style={{display:"flex", justifyContent: "space-between"}}>
                {roles.includes("rental_agreements_viewer_last_24hrs")&&!roles.includes("rental_agreements_viewer")&&
                <div
                    className={["listCardItem", "subtitleSize", "blueText"].join(" ")}
                    style={{flex:"1 1 180px", textAlign:"center"}}
                >
                    Locação #{props.rental_agreement.rental_agreement_code}
                </div>}
                {roles.includes("rental_agreements_viewer")?
                ["pending", "in_manual_analysis"].includes(props.rental_agreement.fraud_status)&&enableDialog ?
                <div 
                    className={["listCardItem", "link", "subtitleSize", "blueText"].join(" ")}
                    style={{flex:"1 1 180px", textAlign:"center"}}
                    onClick={() => setOpenCurrentAnalystDialog(true)}
                >
                    Locação #{props.rental_agreement.rental_agreement_code}
                </div> 
                :
                <Link 
                    className={["listCardItem", "link", "subtitleSize", "blueText"].join(" ")}
                    style={{flex:"1 1 180px", textAlign:"center"}}
                    to={"/rental-agreement/" + props.rental_agreement.rental_agreement_key}
                >
                    Locação #{props.rental_agreement.rental_agreement_code}
                </Link>
                :
                null}
                <div 
                    className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                    style={{flex:"1 1 200px", textAlign:"center"}}
                >
                    {(props.rental_agreement.client || {}).type ? translateClientType(props.rental_agreement.client.type) : null}
                </div>
                <div 
                    className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                    style={{flex:"1 1 200px", textAlign:"center"}}
                >
                    {checkDocumentType(props.rental_agreement)}: {checkNested(props.rental_agreement,"client","document_number") ? props.rental_agreement.client.document_number : "-"}
                </div>
                <div 
                    className={["listCardItem", "labelText", "subtitleSize"].join(" ")}
                    style={{flex:"1 1 200px", textAlign:"center"}}
                >
                    {time_feature(props.rental_agreement)}
                </div>
                <div 
                    className={["listCardItem", "normalText", "normalMediumSize", "bold"].join(" ")} 
                    style={{flex:"1 1 45px", textAlign:"center", cursor: "default"}}  
                >
                    <Tooltip title={<p className="tooltipText">{getStatus(props.rental_agreement.fraud_status)}</p>}>
                    <div className={["circle", "small"].join(" ")} style={{backgroundColor:getStatusColor(props.rental_agreement.fraud_status)}}>
                        {getStatusSiglum(props.rental_agreement.fraud_status)}
                    </div>
                    </Tooltip>
                </div>
                <div 
                    className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                    style={{flex:"1 1 100px", textAlign:"center"}}
                >
                    {(props.rental_agreement.rental_store_type) ? correctRentalStoryType(props.rental_agreement.rental_store_type) : "-"}
                </div>
                <div 
                    className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                    style={{flex:"1 1 100px", textAlign:"center"}}
                >
                    Grupo: {props.rental_agreement.car.model_group}
                </div>
                {["pending", "in_manual_analysis"].includes(props.rental_agreement.fraud_status) ?
                <div 
                    className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                    style={{flex:"1 1 20px", textAlign:"center"}}
                >
                    <Tooltip title={<p className="tooltipText">{getCurrentAnalystText(usedCurrentAnalyst)}</p>}>
                        <div 
                            className={["circle", "superSmall"].join(" ")} 
                            style={{backgroundColor: getCurrentAnalystColor(usedCurrentAnalyst)}} 
                        />
                    </Tooltip>
                </div> : null}
                <div>
                    <IconButton onClick={handleExpand}>
                        {expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </IconButton>
                </div>
            </div>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
            <div className={["internalCollapse", "threeColumn"].join(" ")}>
                <DataField
                    title={"Nome"}
                    label={checkNested(props.rental_agreement, "client", "name") ? 
                        props.rental_agreement.client.name : "-"
                    }
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                />
                <DataField
                    title={"Data de Nascimento"}
                    label={
                        checkNested(props.rental_agreement, "client", "birthdate") ? 
                        clientAge(props.rental_agreement.client.birthdate) : "-"
                    }
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                />
                <DataField
                    title={"Loja de Retirada"}
                    label={checkNested(props.rental_agreement, "rental_store") ? 
                        props.rental_agreement.rental_store : "-"
                    }
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                />
                <DataField
                    title={"Telefone"}
                    label={checkNested(props.rental_agreement, "client", "phones") ? 
                        props.rental_agreement.client.phones.length > 0 ? formatPhone(props.rental_agreement.client.phones[0], "-") : "-" : "-"
                    }
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                />
                <DataField
                    title={"Data da Reserva"}
                    label={getReservationFormat(props.rental_agreement)}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    display={"inline"}
                />
                <DataField
                    title={"Data de Devolução"}
                    label={getRentalDaysFormat(props.rental_agreement)}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                />
                <DataField
                    title={"Valor Total"}
                    label={checkNested(props.rental_agreement, "final_price") ? 
                        formatAsMoney(props.rental_agreement.final_price, "-") : "-"
                    }
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                />
            </div>
            <ObservationComponent fraud_status_events={props.rental_agreement.fraud_status_events} />
            </Collapse>
            {enableDialog ?
            <CurrentAnalystDialog
                open={openCurrentAnalystDialog}
                onClose={() => setOpenCurrentAnalystDialog(false)}
                currentAnalyst={usedCurrentAnalyst}
                ra_key={props.rental_agreement.rental_agreement_key}            
            /> : null
            }
        </Card>
    );
}

export default RentalAgreementCard;

function ObservationComponent(props) {
  if (props.fraud_status_events == null){
    return null
  }
  else if (props.fraud_status_events[props.fraud_status_events.length - 1].observation == null){
    return null
  }
  else {
    return (
        <div style={{margin:"20px"}}>
            <DataField
                title={"Observações"}
                label={props.fraud_status_events[props.fraud_status_events.length - 1].observation}
                size={"normalMediumSize"}
                titleNoWrap={true}
            />
        </div>
    )
  }
}