import React from 'react'
import DataField from "../../utils/DataField"
import {formatAsMoney} from "../../utils/utils"
import blueDeposit from '../../../../assets/cardEntryIcons/blueWithdrawal.svg';
import moment from 'moment'

function DepositCard(props) {

    let { deposit } = props

    return (
        <div className="analysisCard">
            <div style={{display:"flex"}}>
                <img style={{margin:"auto 8px auto 0px"}} src={blueDeposit} alt="deposit Logo"/>
                <div className={["blueText", "subtitleSize"].join(" ")}>Depósito</div>
            </div>
            <div className="internalCardContainer"  style={{display: "flex", flexDirection: "column"}}>
                <div className="internalCardContainer">
                    <div style={{display:"flex", marginTop:"5px"}}>
                        <div style={{width:"65%"}}>
                            <div className={["labelText", "normalMediumSize", "bold"].join(" ")}>
                                VALOR DO DEPÓSITO
                            </div>
                            <div className={["normalText", "moneySize", "bold"].join(" ")}>
                                {formatAsMoney(deposit.amount, "-")}
                            </div>
                        </div>
                    </div>
                </div>
                <DataField
                    title={"Data do Depósito"}
                    label={moment(deposit.deposit_date).format("DD/MM/YYYY")}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                />
                <DataField
                    title={"Horário do Depósito"}
                    label={moment(deposit.deposit_date).format("HH:mm:ss")}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                />
            </div>
        </div>
    )
}

export default DepositCard