import React from 'react'
import alarmIcon from "../../../../../assets/cardEntryIcons/alarm.svg"
import {getSeverityStatus, getSeverityColor, 
        getAlertStatusText,getAlertStatusColor, getEventTypeName} from "../../../utils/utilsAlert"
import Triggers from "./Triggers/Triggers"
import {formatAsMoney} from '../../../utils/utils'
import {Tooltip, IconButton} from "@material-ui/core";

function AlertsCard(props) {

    let { event_type } = props.alert || ""

    let { showEventType } = props

    let showAlertAmount = (props.alert.amount > 0)

    return (
        <div className="analysisCard">
            <div style={{display: "flex"}}>
                <img className="cardTitleIconStyle" style={{maxHeight: "19px"}} src={alarmIcon} alt="alarmIcon Logo" />
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>Alerta</span>
                <div className={["listCardItem", "normalText", "normalSmallSize"].join(" ")} style={{marginLeft:"auto"}} >
                    <div className="statusTag" style={{backgroundColor:getSeverityColor(props.alert.risk_level)}}>
                        {getSeverityStatus(props.alert.risk_level)}
                    </div>
                </div>
            </div>
            <div className="internalCardContainer" style={{display:"flex", flexDirection:"column"}}>
            {showEventType && !!event_type &&
                <div style={{display:"flex"}}>
                    <div className={["normalText", "normalMediumSize", "noWrap"].join(" ")}>
                        Tipo do Evento:
                    </div>
                    <div 
                        className={["normalText", "normalMediumSize", "labelSpace", "bold"].join(" ")} 
                    >
                        {getEventTypeName(props.alert.event_type)}
                    </div>
                </div>}
                <div style={{display:"flex", paddingTop: "10px"}}>
                    <div className={["normalText", "normalMediumSize", "noWrap"].join(" ")}>
                        Status:
                    </div>
                    <div 
                        className={["normalText", "normalMediumSize", "labelSpace", "bold"].join(" ")} 
                        style={{color:getAlertStatusColor(props.alert.alert_status)}}
                    >
                        {getAlertStatusText(props.alert.alert_status)}
                    </div>
                </div>
                {showAlertAmount &&
                    <div style={{display:"flex", paddingTop: "10px"}}>
                        <div className={["normalText", "normalMediumSize", "noWrap"].join(" ")}>
                            Valor:
                        </div>
                        <div 
                            className={["normalText", "normalMediumSize", "labelSpace", "bold"].join(" ")} 
                        >
                            {formatAsMoney(props.alert.amount)}
                        </div>
                    </div>
                }
            </div>
            <div className="internalCardContainer" style={{display:"flex", flexDirection: "column", flexGrow:"1"}}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}> 
                    <div className={["blueText", "normalSmallSize"].join(" ")}>
                        Gatilhos:
                    </div>
                    <Tooltip title={<p className="tooltipText">{"Quantidade total de gatilhos por alerta"}</p>}>
                        <div style={{marginLeft: '2px'}}>
                            <IconButton size="small" >
                                {props.alert.triggers.length}
                            </IconButton>
                        </div> 
                    </Tooltip>  
                </div>
                <Triggers triggers={props.alert.triggers}/>
            </div>
        </div>
    )
}

export default AlertsCard


