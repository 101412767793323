import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 224px;
    height: 80px;
    border: 1px solid #bababa;
    background-color: #f4f4f4;
    border-radius: 8px;
    padding: 4px 12px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
export const Title = styled.div`
    font-size: 16px;
    font-weight: bold;
    color: #0b1f82;
    letter-spacing: 0.5px;
    margin-bottom: 4px;
`;
export const Line = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
`;
export const Label = styled.div`
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.5px;
    color: #222;
`;
export const Value = styled.div`
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.5px;
    color: #222; 
`;