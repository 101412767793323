import React from 'react'
import merchantIcon from "../../../../../assets/cardEntryIcons/MerchantIcon.svg"
import TransactionHistoric from "./TransactionHistoric/TransactionHistoric"
import AlertsHistoric from "./AlertsHistoric/AlertsHistoric"

function HistoricCard(props) {

    let alert_triggers_transactions = []
    for (let trigger of props.alert.triggers){
        const { transaction_key} = trigger.transaction
        alert_triggers_transactions.push(transaction_key)
    }

    return (
        <div className="analysisCard">
            <div style={{display: "flex"}}>
                <img className="cardTitleIconStyle" src={merchantIcon} alt="merchantIcon Logo" />
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>Merchant | Históricos</span>
            </div>
            <div className="internalCardContainer">
                <div className={["blueText", "normalSmallSize"].join(" ")}>
                    Histórico de Transações
                </div>
                <div>
                    <TransactionHistoric alert={props.alert} link_key={[props.alert.merchant_id,props.alert.acquirer_id]} alert_triggers_transactions={alert_triggers_transactions}/>
                </div>                
            </div>
            <div className="internalCardContainer">
                <div className={["blueText", "normalSmallSize"].join(" ")}>
                    Histórico de Alertas
                </div>
                <div>
                    <AlertsHistoric link_key={[props.alert.merchant_id,props.alert.acquirer_id]}/>
                </div>                
            </div>
        </div>
    )
}

export default HistoricCard