import React from "react";
import FilterBox from "../../../Utils/FilterBox/FilterBox"
import {orderFilterTypes} from "../../utils/utils"

function Filter(props) {
	
    const { setPageParams } = props

    return (
        <FilterBox 
            setPageParams={setPageParams}
            filterTypes={orderFilterTypes}
            local_storage_object_preffix={props.local_storage_object_preffix}
        />
    )

}

export default Filter