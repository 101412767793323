import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from "@material-ui/core/Tooltip";
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
  },
  tableText: {
    fontFamily: "Open Sans",
    fontWeight: 'normal',
    fontSize: "12px",
    lineHeight: "16px",
    color: "#141414",
    textAlign: "center",
    margin: "10px",
    padding: "6px",
    minWidth: "120px"
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: '#6F6F6F',
    fontSize: "14px",
    lineHeight: "19px",
    padding: "6px",
  },
  even: {
    background: "#F3F3F3",
  },
  odd: {
    background: "#FFFFFF",
  },
}));

function PEPRelatedTable(props) {
  const classes = useStyles();
  useTheme();

  let counter = 0

  const getClass = (rowIndex) => {
    counter = counter + 1
    if (rowIndex % 2 === 0) {
      return classes.even;
    }

    return classes.odd;
  };

  const getToolTipText = (companies) => {
    return (
      <div>
        <span style={{color:"white"}}>{"Sócios nas seguintes empresas: "}</span>
        {companies.map((company,index) => (
          <li key={index} style={{color:"white"}}>
            {company}
          </li>
          ))
        }
      </div>
    );
  }

  return (
    <Paper className={classes.tableStyle}>
      <Table style={{ padding: "8px" }}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader}>Relação</TableCell>
            <TableCell className={classes.tableHeader}>Nome</TableCell>
            <TableCell className={classes.tableHeader}>PEP</TableCell>
            <TableCell className={classes.tableHeader}>Cargo</TableCell>
            <TableCell className={classes.tableHeader}>Início de Mandato</TableCell>
            <TableCell className={classes.tableHeader}>Término de Mandato</TableCell>
            <TableCell className={classes.tableHeader}>Término da Vigência</TableCell>
            <TableCell className={classes.tableHeader}>Órgão</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.related_pep_data.map((person,index) => (
            person.is_pep !== true ?
            <TableRow
              key={100*index}
              className={getClass(counter)}
            >
              <TableCell style={{whiteSpace:"nowrap"}} className={classes.tableText}>
                <span>{person.relation}</span>
                {person.companies.length === 0 ? null : 
                <Tooltip title={<p className="tooltipText">{getToolTipText(person.companies)}</p>}>
                  <HelpIcon style={{marginLeft: "5px", width:"15px", display:"inline-flex", verticalAlign: 'middle'}} />
                </Tooltip>}
              </TableCell>
              <TableCell style={{whiteSpace:"nowrap"}} className={classes.tableText}>
                {person.person_name}
              </TableCell>
              <TableCell style={{whiteSpace:"nowrap"}} className={classes.tableText}>
                {person.is_pep === false ? 'Não' : "-"}
              </TableCell>
              <TableCell style={{whiteSpace:"nowrap"}} className={classes.tableText}>
                {"-"}
              </TableCell>
              <TableCell className={classes.tableText}>
                {"-"}
              </TableCell>
              <TableCell className={classes.tableText}>
                {"-"}
              </TableCell>
              <TableCell className={classes.tableText}>
                {"-"}
              </TableCell>
              <TableCell className={classes.tableText}>
                {"-"}
              </TableCell>
            </TableRow>
            : person.information.map((mandate,index_2) => (
              <TableRow
                key={100*index + index_2}
                className={getClass(counter)}
              >
                <TableCell style={{whiteSpace:"nowrap"}} className={classes.tableText}>
                  <span>{person.relation}</span>
                  {person.companies.length === 0 ? null : 
                  <Tooltip title={<p className="tooltipText">{getToolTipText(person.companies)}</p>}>
                    <HelpIcon style={{marginLeft: "5px", width:"15px", display:"inline-flex", verticalAlign: 'middle'}} />
                  </Tooltip>}
                </TableCell>
                <TableCell style={{whiteSpace:"nowrap"}} className={classes.tableText}>
                  {person.person_name}
                </TableCell>
                <TableCell style={{whiteSpace:"nowrap"}} className={classes.tableText}>
                  {"Sim"}
                </TableCell>
                <TableCell style={{whiteSpace:"nowrap"}} className={classes.tableText}>
                  {mandate.role_description == null ? "-" : mandate.role_description}
                </TableCell>
                <TableCell className={classes.tableText}>
                  {mandate.mandate_start_date == null ? "-" : moment(mandate.mandate_start_date).format('DD/MM/YYYY')}
                </TableCell>
                <TableCell className={classes.tableText}>
                  {mandate.mandate_end_date == null ? "-" : moment(mandate.mandate_end_date).format('DD/MM/YYYY')}
                </TableCell>
                <TableCell className={classes.tableText}>
                  {mandate.listing_end_date == null ? "-" : moment(mandate.listing_end_date).format('DD/MM/YYYY')}
                </TableCell>
                <TableCell className={classes.tableText}>
                  {mandate.agency_name == null ? "-" : mandate.uf == null ? mandate.agency_name : 
                    mandate.agency_name + " - "+ mandate.uf}
                </TableCell>
              </TableRow>
            ))
            ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default PEPRelatedTable;
