import React, { useState} from "react";
import { useContext} from 'react'
import AuthContext from "../../../../context/auth-context"
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import Collapse from "@material-ui/core/Collapse";
import { formatCard, getAcquirer, colorGreen } from "../../utils/utils"
import moment from 'moment'
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import UnlockDialog from "./UnlockDialog/UnlockDialog"
import terminalLogo from '../../../../assets/cardEntryIcons/terminalIcon.svg'
import cardLogo from '../../../../assets/cardEntryIcons/randomEntry.svg'
import merchantLogo from '../../../../assets/cardEntryIcons/MerchantIconGrey.svg'
import peopleLogo from '../../../../assets/cardEntryIcons/peopleGrey.svg'
import lockLogo from '../../../../assets/cardEntryIcons/lockIcon.svg'
import unlockLogo from '../../../../assets/cardEntryIcons/unlockIcon.svg'

function LockCard(props){

    let user_data = useContext(AuthContext).user_data

    const [openUnlockDialog, setOpenUnlockDialog] = useState(false);

    const [expanded, setExpanded] = useState(false);

    const handleExpand = (event) => {
        event.stopPropagation();
        setExpanded(!expanded);
    };
    

    return (
        <Card className={["listCard","collapse"].join(" ")}>
            <div style={{display:"flex", justifyContent: "space-between"}}>
                {user_data.business_group_key ?
                <div 
                    className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                    style={{flex:"2 1 180px", textAlign:"center"}}
                >
                    {props.lock.company_name || "Todas"}
                </div> : null}
                {props.lock.lock_type === "terminal" ?
                <div 
                    className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                    style={{flex:"1 1 110px", textAlign:"center"}}
                >
                    <div style={{display:"flex", justifyContent:"center"}}>
                        <img src={terminalLogo} alt="Terminal Logo" />
                        <div className="labelSpace">Terminal {props.lock.terminal_id}</div>
                    </div>
                </div> : null}
                {props.lock.lock_type === "card" ?
                <div 
                    className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                    style={{flex:"1 1 110px", textAlign:"center"}}
                >
                    <div style={{display:"flex", justifyContent:"center"}}>
                        <img src={cardLogo} alt="Card Logo" />
                        <div className="labelSpace">{formatCard(props.lock.bin, props.lock.last4)}</div>
                    </div>
                </div> : 
                <div 
                    className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                    style={{flex:"1 1 110px", textAlign:"center"}}
                >
                    <div style={{display:"flex", justifyContent:"center"}}>
                        <img src={merchantLogo} alt="Merchant Logo" />
                        <div className="labelSpace">
                            {(((props.lock.lock_events || [{}])[0] || {}).alert_metadata || {}).merchant_name || props.lock.merchant_id}
                        </div>
                    </div>
                </div>}
                {props.lock.lock_type === "card" ?
                <div 
                    className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                    style={{flex:"1 1 110px", textAlign:"center"}}
                >
                    <div style={{display:"flex", justifyContent:"center"}}>
                        <img src={peopleLogo} alt="People Logo" />
                        <div className="labelSpace">Usuário {props.lock.cardholder_id}</div>
                    </div>
                </div> : 
                <div 
                    className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                    style={{flex:"1 1 110px", textAlign:"center"}}
                >
                    Adquirente {getAcquirer(props.lock.acquirer_id)}
                </div>}
                {props.lock.lock_source === "manually_input" ? 
                <div 
                    className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                    style={{flex:"1 1 110px", textAlign:"center"}}
                >
                    <div style={{display:"flex", justifyContent:"center"}}>
                        <img src={lockLogo} alt="Card Logo" />
                        <div className="labelSpace">Manual</div>
                    </div>
                </div> :
                <Link 
                    className={["listCardItem", "blueText", "link", "subtitleSize"].join(" ")}
                    style={{flex:"1 1 110px", textAlign:"center"}}
                    to={"/alert/" + props.lock.alert_key}
                >
                    <div style={{display:"flex", justifyContent:"center"}}>
                        <img src={lockLogo} alt="Card Logo" />
                        <div className="labelSpace">
                            {props.lock.lock_source === "automatically_alert" ? "Alerta Crítico" : "Alerta Manual"}
                        </div>
                    </div>
                </Link>}
                <div 
                    className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                    style={{flex:"1 1 110px", textAlign:"center"}}
                >
                    {moment(props.lock.lock_date).format("DD/MM/YYYY HH:mm:ss")}
                </div>
                {props.lock.lock_status === "locked" ? 
                <div 
                    className={["listCardItem", "link", "subtitleSize", "bold"].join(" ")}
                    style={{flex:"1 1 110px", textAlign:"center"}}
                    onClick={() => setOpenUnlockDialog(true)}
                >
                    <div style={{display:"flex", justifyContent:"center"}}>
                        <img src={unlockLogo} alt="Unlock Logo" />
                        <div className="labelSpace">Desbloquear</div>
                    </div>
                </div> : 
                <div 
                    className={["listCardItem", "normalText", "subtitleSize", "bold"].join(" ")}
                    style={{flex:"1 1 110px", textAlign:"center", color: colorGreen}}
                >
                    Desbloqueado
                </div>}
                <div 
                    className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                    style={{flex:"0 1 70px", textAlign:"center"}}
                >
                    <IconButton onClick={handleExpand}>
                        {expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </IconButton>
                </div>
            </div>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
            <div className={["internalCollapse", "oneColumn"].join(" ")}>
                {props.lock.lock_events ? props.lock.lock_events.map((event, index) => (
                    <div key={index} style={{display: "flex", flexDirection: "column", margin: "20px 0px"}}>
                        <div className={["blueText", "subtitleSize"].join(" ")}>
                            {event.new_status === "locked" ? "Bloqueio": "Desbloqueio"}                            
                        </div>
                        {event.new_status === "locked" && props.lock.lock_source === "automatically_alert" ?
                        <div style={{display: "flex", margin: "5px 0px"}}>
                            <div className={["normalText", "normalMediumSize"].join(" ")}>
                                Alerta gerado automaticamente por gatilho crítico.
                            </div>                  
                        </div> :
                        <React.Fragment>            
                        <div style={{display: "flex", margin: "5px 0px"}}>
                            <div className={["normalText", "normalMediumSize"].join(" ")}>
                                Responsável:
                            </div>
                            <div className={["labelText", "normalMediumSize", "labelSpace"].join(" ")}>
                            {event.analyst_name}
                            </div>                    
                        </div>
                        <div style={{display: "flex", margin: "5px 0px"}}>
                            <div className={["normalText", "normalMediumSize"].join(" ")}>
                                E-mail:
                            </div>
                            <div className={["labelText", "normalMediumSize", "labelSpace"].join(" ")}>
                                {event.analyst_email}
                            </div>                    
                        </div>
                        {event.observation ? 
                        <div style={{display: "flex", margin: "5px 0px"}}>
                            <div className={["normalText", "normalMediumSize"].join(" ")}>
                                Observação:
                            </div>
                            <div className={["labelText", "normalMediumSize", "labelSpace"].join(" ")}>
                                {event.observation}
                            </div>                    
                        </div>: null }
                        {event.new_status === "unlocked" ?
                        <div style={{display: "flex", margin: "5px 0px"}}>
                            <div className={["normalText", "normalMediumSize"].join(" ")}>
                                Data do Desbloqueio:
                            </div>
                            <div className={["labelText", "normalMediumSize", "labelSpace"].join(" ")}>
                                {moment(event.event_date).format("DD/MM/YYYY HH:mm:ss")}
                            </div>                    
                        </div>: null }
                        </React.Fragment>}
                    </div>                    
                )) : null}
            </div>
            </Collapse>
            {props.lock.lock_status === "locked" ?
            <UnlockDialog
                open={openUnlockDialog}
                onClose={() => setOpenUnlockDialog(false)}
                lock={props.lock}
                local_storage_object_preffix={props.local_storage_object_preffix} 
                setPageParams={props.setPageParams}             
            /> : null
            }

        </Card>
    )
}

export default LockCard