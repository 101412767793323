import React from 'react'
import DataField from "../../utils/DataField"
import {formatAsMoney} from "../../utils/utils"
import blueWithdrawal from '../../../../assets/cardEntryIcons/blueWithdrawal.svg';
import moment from 'moment'

function WithdrawalCard(props) {

    let { withdrawal } = props

    return (
        <div className="analysisCard">
            <div style={{display:"flex"}}>
                <img style={{margin:"auto 8px auto 0px"}} src={blueWithdrawal} alt="withdrawal Logo"/>
                <div className={["blueText", "subtitleSize"].join(" ")}>Saque</div>
            </div>
            <div className="internalCardContainer"  style={{display: "flex", flexDirection: "column"}}>
                <div className="internalCardContainer">
                    <div style={{display:"flex", marginTop:"5px"}}>
                        <div style={{width:"65%"}}>
                            <div className={["labelText", "normalMediumSize", "bold"].join(" ")}>
                                VALOR DO SAQUE
                            </div>
                            <div className={["normalText", "moneySize", "bold"].join(" ")}>
                                {formatAsMoney(withdrawal.amount, "-")}
                            </div>
                        </div>
                    </div>
                </div>
                <DataField
                    title={"Data do Saque"}
                    label={moment(withdrawal.withdrawal_date).format("DD/MM/YYYY")}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                />
                <DataField
                    title={"Horário do Saque"}
                    label={moment(withdrawal.withdrawal_date).format("HH:mm:ss")}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                />
            </div>
        </div>
    )
}

export default WithdrawalCard