import React, { useReducer, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {translateOutcomeTreeData} from "../treeUtils"
import { useHistory } from "react-router-dom";
import axios from "axios"
import { CircularProgress } from '@material-ui/core';
import {decisionReducer} from "../../../Utils/requestUtils"
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from '@material-ui/icons/Clear';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from "react-i18next";

const getConfirmationIcon = (success) => {
    if (success){
        return (<CheckIcon className="fraudStatusIcon" style={{color: '#73C580', width:"40px", height:"40px"}}/>)
    }
    else{
        return (<ClearIcon className="fraudStatusIcon" style={{color: '#D91A1A', width:"40px", height:"40px"}}/>)
    }
  }

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

function SaveRuleDialog(props) {
    const { t } = useTranslation();
    let {ruleDefinition, userVariablesAvailable } = props

    const history = useHistory()
    const [ruleDescription, setRuleDescription] = useState("")
    
    const [dialogState, dispatchDialogState] = useReducer(
        decisionReducer,
        {isLoading: false, isError: false, finished:false}
    )

    const handleCloseDialog = () => {
        props.onClose()
        setTimeout(function (){dispatchDialogState({type:"default_state"})},500)
    }

    const handleFinish = () =>{
        dispatchDialogState({type:"send_request_init"})
        let finalTreeData = translateOutcomeTreeData(props.treeData)
        let payload={
            name:ruleDefinition.name,
            description: ruleDescription,
            tree_data:finalTreeData,
            user_variables: userVariablesAvailable
        }
        if (ruleDefinition.rule_key === "new_rule") {
            let headersRequest = {
                headers: {
                    "COMPANY-KEY": ruleDefinition.company_key
                }
            }
            payload.event_type = ruleDefinition.event_type
            setTimeout(function () {
                let url = '/dash/rule_engine/rule?environment=' + ruleDefinition.environment
                axios.post(url, payload, headersRequest).then(response => {
                    dispatchDialogState({type:"send_request_success"})
                  }).catch(error => {
                    if ((error.response || {}).status === 403) dispatchDialogState({type: "send_request_failure_403"})
                    else if ((error.response || {}).status === 409) dispatchDialogState({type: "send_request_failure_409"})  
                    else dispatchDialogState({type: "send_request_failure"})    
                  });
            }, 1000);            
        }
        // delete payload 
        else{
            let headersRequest = {
                headers: {
                    "COMPANY-KEY": ruleDefinition.company_key
                }
            }
            setTimeout(function () {
                axios.put('/dash/rule_engine/rule/' + ruleDefinition.rule_key + "?environment=" + ruleDefinition.environment, payload, headersRequest).then(response => {
                    dispatchDialogState({type:"send_request_success"})
                  }).catch(error => {
                    if ((error.response || {}).status === 403) dispatchDialogState({type: "send_request_failure_403"})
                    else if ((error.response || {}).status === 409) dispatchDialogState({type: "send_request_failure_409"})  
                    else dispatchDialogState({type: "send_request_failure"})    
                  });
            }, 1000);            
        }

    }

    const handleAlert = () => {
        alert("rule_change_save_message")
    }

    if (dialogState.finished && dialogState.isLoading){
        return (
            <Dialog 
                open={props.open} 
                onClose={() => history.push("/rules-inbox")} 
                keepMounted={false}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle style={{paddingBottom: "0px"}} className={["blueText", "normalMediumSize"].join(" ")}>{t("Salvando Regra")}</DialogTitle>
                <DialogContent>
                    <div style={{display:"flex", width: "310px", height:"217px"}}>
                        <CircularProgress style={{margin:"auto"}} />
                    </div>
                </DialogContent>
            </Dialog>

        )
    }
    else if (dialogState.finished){
        let confirmationData = {    
            message: dialogState.message,
            success: !dialogState.isError
        }
        return (
            <Dialog 
                open={props.open} 
                onClose={confirmationData.success ? () => history.push("/rules-inbox") : handleCloseDialog} 
                keepMounted={false}
                disableBackdropClick
            >
                <DialogTitle style={{paddingBottom: "0px"}} className={["blueText", "normalMediumSize"].join(" ")}>{t("Confirmação de Revisão")}</DialogTitle>
                <DialogContent>
                <div style={{width: "310px", height:"217px"}}>
                    <div className="internalCardContainer" style={{display:"flex", height:"40%", justifyContent:"center"}}>
                        {getConfirmationIcon(confirmationData.success)}
                    </div>
                    <div className="internalCardContainer" style={{display:"flex", textAlign:"center", justifyContent:"center"}}>
                        {confirmationData.message}
                    </div>
                    <div className="internalCardContainer" style={{display:"flex", justifyContent:"center"}}>
                        <div 
                            className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                            onClick={confirmationData.success ? () => history.push("/rules-inbox") : handleCloseDialog}
                        >
                            {confirmationData.success ? t("VOLTAR À TELA INICIAL") : "FECHAR"}
                        </div>
                    </div>
                </div>
                </DialogContent>
            </Dialog>
        )

    }
    return (
        <Dialog 
        open={props.open} 
        onClose={handleCloseDialog} 
        keepMounted={false}
        >
            <DialogTitle style={{paddingBottom: "0px"}} className={["blueText", "normalMediumSize"].join(" ")}>{t("Salvar Regra")}</DialogTitle>
            <DialogContent style={{paddingBottom: "180px"}}>
                <div 
                    style={{
                        width:"366px", height: "160px", 
                        display:"flex", flexDirection:"column", 
                        marginBottom:"30px", alignItems:"center"
                    }}
                >
                    <div 
                        className={["internalCardContainer", "normalText", "normalMediumSize"].join(" ")} 
                        style={{width:"90%", textAlign:"center"}}>
                        {t("A versão corrente da regra será substituída pela árvore atual!")} <strong>{t("Deseja mesmo continuar?")}</strong>
                    </div>
                    <div 
                        className={["internalCardContainer", "normalText", "normalMediumSize"].join(" ")} 
                        style={{width:"90%"}}>
                        {t("Para continuar, inclua a descrição da alteração:")}
                    </div>
                    <div style={{width: "90%", marginBottom: "15px"}}>
                        <TextField
                            error={!ruleDescription.length > 0}
                            label={t("Descrição da Alteração")}
                            style={{textAlign:"center"}} 
                            className="filterTextbox" 
                            size="small" fullWidth 
                            value={ruleDescription}
                            multiline
                            onChange={(e) => setRuleDescription(e.target.value)}
                            required
                        />  
                    </div>
                    <div className="internalCardContainer" style={{display:"flex", marginTop: "10px"}}>
                        <div
                            className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")}
                            style={{width:"120px"}}
                            onClick={handleCloseDialog}
                        >
                            {t("Cancelar")}
                        </div>
                        {ruleDescription ?
                        <div
                            className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                            style={{width:"120px"}}
                            onClick={handleFinish}
                        >
                            {t("save")}
                        </div>  :
                        <div
                            className={["button", "standard", "disabled", "normalText", "normalMediumSize"].join(" ")}
                            style={{width:"120px"}}
                            onClick={handleAlert}

                        >
                            {t("save")}
                        </div>}   
                    </div>
                </div>
          </DialogContent>
        </Dialog>
    )
}

export default SaveRuleDialog;