import React, { useState, useContext } from "react";
import AuthContext from "../../../../context/auth-context"
import { Link } from "react-router-dom";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import Collapse from "@material-ui/core/Collapse";
import DataField from "./DataField/DataField";
import moment from "moment";
import {  
  getCurrentAnalystColor, 
  getCurrentAnalystText,
  translateFraudStatus, 
  fraudStatusSiglum, 
  fraudStatusColor, 
  titleCase, 
  clientAge, 
  getProperMetadata, 
  replaceAll} from "../../utils/utils"
import { Tooltip } from "@material-ui/core";

function LegalPersonCard(props) {

    let user_data = useContext(AuthContext).user_data
    const [expanded, setExpanded] = useState(false);

    let decision_metadata = getProperMetadata(props.legal_person.analysis_status_events)

    let usedCurrentAnalyst = props.legal_person && props.legal_person.current_analyst ? { ...props.legal_person.current_analyst } : null;

    const handleExpand = (event) => {
        event.stopPropagation();
        setExpanded(!expanded);
    };

  ///////Get Phone Format
  const getPhone = (phoneList) => {
    if (phoneList.length === 0){
      return ("-")
    }
    else {
      return ("+" + phoneList[0].international_dial_code + " " + phoneList[0].area_code + " " +phoneList[0].number)
    }
  }

  let requestType = props.legal_person.partnership_key ? "Análise de Sócio" : "Cliente #".concat(props.legal_person.id)

  let showLinkToLpAnalysis = !(props.legal_person.analysis_status === "in_queue" || 
                                props.legal_person.analysis_status === "pending"|| 
                                props.legal_person.analysis_status === "waiting_for_data") 

  return (
    <Card className={["listCard","collapse"].join(" ")}>
        <div style={{display:"flex", justifyContent: "space-between"}}>
          {showLinkToLpAnalysis
            ? <Link 
              className={["listCardItem", "link", "subtitleSize", "bold"].join(" ")}
              style={{flex:"2 1 120px", textAlign:"center"}}
              to={"/legal-person/" + props.legal_person.legal_person_key}
            >
                {requestType}
            </Link>
            : <div 
              className={["listCardItem", "subtitleSize", "bold"].join(" ")}
              style={{flex:"2 1 120px", textAlign:"center"}}
            >
                {requestType}
            </div>
            }
            {user_data.business_group_key&&
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                style={{flex:"2 1 200px", textAlign:"center"}}
            >
                {props.legal_person.company_name || "-"}
            </div>}
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                style={{flex:"2 1 250px", textAlign:"center"}}
            >
                {titleCase(props.legal_person.legal_name, "-")}
            </div>
            <div 
                className={["listCardItem", "normalText", "subtitleSize", "noWrap"].join(" ")}
                style={{flex:"1 1 175px", textAlign:"center"}}
            >
                {props.legal_person.document_number}
            </div>
            <div 
                className={["listCardItem", "normalText", "normalMediumSize", "bold"].join(" ")} 
                style={{flex:"1 1 45px", textAlign:"center", cursor: "default"}}  
            >
                <Tooltip title={<p className="tooltipText">{translateFraudStatus(props.legal_person.analysis_status)}</p>}>
                <div className={["circle", "small"].join(" ")} style={{backgroundColor:fraudStatusColor(props.legal_person.analysis_status)}}>
                    {fraudStatusSiglum(props.legal_person.analysis_status)}
                </div>
                </Tooltip>
            </div>
            <div 
                className={["listCardItem", "normalText", "subtitleSize", "noWrap"].join(" ")}
                style={{flex:"1 1 110px", textAlign:"center"}}
            >
                {moment(props.legal_person.created_at).format("DD/MM/YYYY HH:mm:ss")}
            </div>
            {["pending", "in_manual_analysis"].includes(props.legal_person.analysis_status) && user_data.roles.includes("onboarding_analyst_heartbeat") ?
              <div 
                  className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                  style={{flex:"1 1 20px", textAlign:"center"}}
              >
                  <Tooltip title={<p className="tooltipText">{getCurrentAnalystText(usedCurrentAnalyst)}</p>}>
                      <div 
                          className={["circle", "superSmall"].join(" ")} 
                          style={{backgroundColor: getCurrentAnalystColor(usedCurrentAnalyst)}} 
                      />
                  </Tooltip>
              </div> : null}
            <div
                className="listCardItem"
                style={{flex:"0 1 70px", textAlign:"center"}}
            >
                <IconButton onClick={handleExpand}>
                    {expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </IconButton>
            </div>
        </div>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div className={["internalCollapse", "twoColumn"].join(" ")}>
            <DataField
              name="Data de Fundação"
              data={clientAge(props.legal_person.foundation_date, "-")}
            />
            <DataField name="Telefone" data={props.legal_person.phones == null ? "-" : getPhone(props.legal_person.phones)} />
            <DataField
              name="Website"
              data={props.legal_person.website}
            />
            <DataField
              name="Tipo de análise"
              data={decision_metadata == null ? 'pendente no sistema' :
                Object.keys(decision_metadata).length === 0 ? 'pendente no sistema' : decision_metadata.rule_identifier
              }
            />
        </div>
        <ObservationComponent analysis_status_events={props.legal_person.analysis_status_events} />
      </Collapse>
    </Card>
  );
}

export default LegalPersonCard;

function ObservationComponent(props) {
  if (props.analysis_status_events === undefined) {
    return null;
  } else if (
    props.analysis_status_events[props.analysis_status_events.length - 1]
      .observation === null
  ) {
    return null;
  } else {
    let analyst = null;
    let on_hold_analyst = null;
    let last_event =
      props.analysis_status_events[props.analysis_status_events.length - 1];
    let on_hold_event = props.analysis_status_events.filter(
      (item) => item.new_status === "on_hold"
    )[0];

    if (on_hold_event === last_event) {
      let string =
        on_hold_event.analyst_name == null
          ? "Análise feita no dia %day às %time.\n"
          : "Análise feita por %name, %email no dia %day às %time.\n";
      let mapObj = {
        "%name": on_hold_event.analyst_name,
        "%email": on_hold_event.analyst_email,
        "%day": moment(on_hold_event.event_date).format("DD/MM/YYYY"),
        "%time": moment(on_hold_event.event_date).format("HH:mm"),
      };
      analyst = string == null ? null : replaceAll(string, mapObj);
      return (
        <div style={{ margin: "20px" }}>
          <DataField
            name="Em espera - Observações"
            data={`${analyst} ${on_hold_event.observation}`}
          />
        </div>
      );
    } else if (on_hold_event !== last_event && on_hold_event !== undefined) {
      let on_hold_string =
        on_hold_event.analyst_name == null
          ? "Análise feita no dia %day às %time:\n"
          : "Análise feita por %name, %email no dia %day às %time:\n";
      let on_hold_mapObj = {
        "%name": on_hold_event.analyst_name,
        "%email": on_hold_event.analyst_email,
        "%day": moment(on_hold_event.event_date).format("DD/MM/YYYY"),
        "%time": moment(on_hold_event.event_date).format("HH:mm"),
      };
      on_hold_analyst =
        on_hold_string == null
          ? null
          : replaceAll(on_hold_string, on_hold_mapObj);

      let string =
        last_event.analyst_name == null
          ? "Análise feita no dia %day às %time:\n"
          : "Análise feita por %name, %email no dia %day às %time:\n";
      let mapObj = {
        "%name": last_event.analyst_name,
        "%email": last_event.analyst_email,
        "%day": moment(last_event.event_date).format("DD/MM/YYYY"),
        "%time": moment(last_event.event_date).format("HH:mm"),
      };
      analyst = string == null ? null : replaceAll(string, mapObj);
      return (
        <div style={{ margin: "20px" }}>
          <DataField
            name="Em espera - Observações"
            data={`${on_hold_analyst} "${on_hold_event.observation}"`}
          />
          <DataField
            name="Análise manual - Observações"
            data={`${analyst} "${last_event.observation}"`}
          />
        </div>
      );
    } else {
      return (
        <div style={{ margin: "20px" }}>
          <DataField
            name="Observações"
            data={
              props.analysis_status_events[
                props.analysis_status_events.length - 1
              ].observation
            }
          />
        </div>
      );
    }
  }
}
