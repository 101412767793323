import React from "react";

function CardListHeader(props){
    return (
        <div className="listCardHeader">
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                style={{flex:"1 1 110px", textAlign:"center"}}
            >
                ID do Depósito
            </div>
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                style={{flex:"1 1 130px", textAlign:"center"}}
            >
                Valor
            </div>
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                style={{flex:"1 1 100px", textAlign:"center"}}
            >
                Documento do Cliente
            </div>
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                style={{flex:"1 1 45px", textAlign:"center"}}
            >
                Status de Análise
            </div>
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                style={{flex:"1 1 110px", textAlign:"center"}}
            >
                Data do Depósito
            </div>
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                style={{flex:"1 1 35px", textAlign:"center"}}
            >
                Status do Depósito
            </div>
        </div>
    )
}

export default CardListHeader