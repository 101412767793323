import React, {useContext} from "react";
import AuthContext from "../../../../context/auth-context"
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import {titleCase, formatCard, getEntryIcon,getFraudColor,getFraudStatusSiglum, getFraudStatus, checkNested, getTransactionStatusText} from "../../utils/utils"
import moment from 'moment'
import { Tooltip } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import ReplayIcon from '@material-ui/icons/Replay';
import CheckIcon from "@material-ui/icons/Check";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import getSymbolFromCurrency from 'currency-symbol-map'

const getTransactionStatusIcon = (status) => {
    const colorGreen = '#73C580'
    const colorRed ='#D91A1A'
    const colorGrey = 'rgb(170, 167, 153)'

    switch(status){
      case "not_authorized":
      case "chargeback":
      case "partial_chargeback":
        return (
            <Tooltip title={<p className="tooltipText">{getTransactionStatusText(status)}</p>}>
                <div className={["circle", "small"].join(" ")} style={{backgroundColor: colorRed}}>
                    <ErrorOutlineIcon  style={{width:"18px", height:"18px"}}/>
                </div>
            </Tooltip>
        );
      case "authorized":
      case "cleared":
      case "captured":
        return (
            <Tooltip title={<p className="tooltipText">{getTransactionStatusText(status)}</p>}>
            <div className={["circle", "small"].join(" ")} style={{backgroundColor: colorGreen}}>
                <CheckIcon style={{width:"18px", height:"18px"}}/>
            </div>
            </Tooltip>
        );
      case "cancelled":
      case "partially_cancelled":
        return (
            <Tooltip title={<p className="tooltipText">{getTransactionStatusText(status)}</p>}>
                <div className={["circle", "small"].join(" ")} style={{backgroundColor: colorGrey}}>
                    <ReplayIcon  style={{width:"18px", height:"18px"}}/>
                </div>
            </Tooltip>
        );
      default:
        return (
            <Tooltip title={<p className="tooltipText">{getTransactionStatusText(status)}</p>}>
        <div className={["circle", "small"].join(" ")} style={{backgroundColor: colorGrey}}>
            <HelpOutlineIcon  style={{width:"18px", height:"18px"}}/>
        </div>
        </Tooltip>
        );
    }
  }

function TransactionCard(props){
    
    
    let user_data = useContext(AuthContext).user_data

    return (
        <Card className="listCard">
            <Link 
                className={["listCardItem", "link", "subtitleSize", "breakWord", "bold"].join(" ")}
                style={{flex:"1 1 130px", textAlign:"center"}}
                to={"/transaction/" + props.transaction.transaction_key}
            >
                {props.transaction.id}
            </Link>
            {user_data.business_group_key ? 
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                style={{flex:"2 1 180px", textAlign:"center"}}
            >
                {props.transaction.company_name}
            </div> : null}
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                style={{flex:"3 1 230px", textAlign:"center"}}
            >
                {props.transaction.merchant == null ? "-" : titleCase(props.transaction.merchant.name, "-")}
            </div>
            <div 
                className={["listCardItem", "normalText", "subtitleSize", "noWrap"].join(" ")}
                style={{flex:"1 1 180px", textAlign:"center"}}
            >
                {checkNested(props.transaction, "card", "bin") & checkNested(props.transaction, "card", "last4") ? formatCard(props.transaction.card.bin, props.transaction.card.last4) : "-"}
            </div>
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                style={{flex:"1 1 40px", textAlign:"center"}}
            >
                {checkNested(props.transaction, "enriched_data", "cardholder_document_number") ? props.transaction.enriched_data.cardholder_document_number : "-"}
            </div>
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                style={{flex:"1 1 130px", textAlign:"center"}}               
            >
                {props.transaction.amount == null ? "-" : (getSymbolFromCurrency(props.transaction.currency)|| props.transaction.currency) + " " + (props.transaction.amount/100).toLocaleString('de-DE', {minimumFractionDigits: 2})}
            </div>
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                style={{flex:"1 1 50px", textAlign:"center"}}
            >
                {getEntryIcon(props.transaction.pan_entry_mode)}
            </div>
            <div 
                className={["listCardItem", "normalText", "normalMediumSize", "bold"].join(" ")} 
                style={{flex:"1 1 45px", textAlign:"center", cursor: "default"}}  
            >
                <Tooltip title={<p className="tooltipText">{getFraudStatus(props.transaction.fraud_status)}</p>}>
                <div className={["circle", "small"].join(" ")} style={{backgroundColor:getFraudColor(props.transaction.fraud_status)}}>
                    {getFraudStatusSiglum(props.transaction.fraud_status)}
                </div>
                </Tooltip>
            </div>
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                style={{flex:"1 1 110px", textAlign:"center"}}
            >
                {moment(props.transaction.authorization_date).format("DD/MM/YYYY HH:mm:ss")}
            </div>
            <div 
                className={["listCardItem", "normalText", "normalMediumSize", "bold"].join(" ")}  
                style={{flex:"1 1 35px", textAlign:"center", cursor: "default"}}   
            >
                {getTransactionStatusIcon(props.transaction.transaction_status)}
            </div>
        </Card>
    )
}

export default TransactionCard