import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  GetAlertsComponent,
  setObjectByPath,
  validateConstantValues,
  translateType,
} from "./utils";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import RulesContext from "../../../../context/rules-context";
import _ from "lodash";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const getDatetimeFormatted = (value) => {
    if (moment(value, "DD/MM/YYYY ", true).isValid()) {
        return moment(value, "DD/MM/YYYY ", true).format("YYYY-MM-DD");
    } else if (moment(value, "DD/MM/YYYY HH:mm:ss", true).isValid()) {
        return moment(value, "DD/MM/YYYY HH:mm:ss", true).format(
        "YYYY-MM-DDTHH:mm:ssZ"
        );
    } else return value;
    };

export function ScriptsBox(props) {
    const { t } = useTranslation();
    let { node } = props;
    let { handleConfigurationOpen } = props;
    let { handleNodeRemove } = props;
    let rulesList = useContext(RulesContext);
    let event = rulesList.scripts_list.filter(
        (script) => script.script_key === node.node.properties.script_key
    )[0];
    let { read_only } = props;

    return (
        <div>
        {!read_only ? (
            <div style={{display:"flex", alignItems: "center"}}>
            <div
                style={{
                display: "flex",
                width: "100%",
                cursor: "pointer",
                margin: "auto 0px",
                }}
                onClick={() => handleConfigurationOpen(node)}
            >
                {t((event || { description: "" }).description)}
                {(node.node.alerts || []).length > 0 ? (
                <GetAlertsComponent alerts={node.node.alerts} error={node.node.on_error} />
                ) : null}
            </div>
            <IconButton style={{width: 32, height: 32}} aria-label="duplicate" onClick={() => props.handleNodeDuplication(node)}>
                <ContentCopyIcon style={{width: 20, height: 20}} />
            </IconButton>
            <IconButton style={{width: 32, height: 32}} aria-label="close" onClick={() => handleNodeRemove(node)}>
                <CloseIcon style={{width: 20, height: 20}} />
            </IconButton>
            </div>
        ) : (
            <div style={{ display: "flex" }}>
            <div
                style={{
                display: "flex",
                width: "100%",
                cursor: "pointer",
                margin: "auto 0px",
                }}
                onClick={() => handleConfigurationOpen(node)}
            >
                {t((event || { description: "" }).description)}
            </div>
            </div>
        )}
        </div>
    );
    }

    export function ScriptsConfiguration(props) {
    const { t } = useTranslation();
    let { node } = props;
    let { read_only } = props;
    let { handleNodeChange } = props;
    let { handleConfigurationClose } = props;
    let rulesList = useContext(RulesContext);

    const [nodeState, setNodeState] = useState(node);

    useEffect(() => {
        setNodeState(node);
    }, [node]);

    const validateAllValues = () => {
        if (
        !validateConstantValues("string", nodeState.node.properties.script_key) &&
        !validateConstantValues("string", nodeState.node.metadata.script_key)
        )
        return false;
        return true;
    };

    const handleScriptChange = (e) => {
        let script = rulesList.scripts_list.filter(
        (script) => script.script_key === e.target.value
        )[0];
        let new_node = _.cloneDeep(nodeState);
        setObjectByPath(new_node.node, "properties.script_key", e.target.value);
        setObjectByPath(new_node.node, "properties.parameters", script.parameters);
        setObjectByPath(new_node.node,"properties.description",script.description);
        setObjectByPath(new_node.node,"properties.selected_result", "");
        setObjectByPath(new_node.node, "metadata.script_type", "");
        setNodeState(new_node);
    };

    const handleResultChange = (e) => {
        let script = rulesList.scripts_list.filter(
            (script) => script.script_key === nodeState.node.properties.script_key
            )[0];
        let new_node = _.cloneDeep(nodeState);
        setObjectByPath(new_node.node,"properties.selected_result", e.target.value);
        setObjectByPath(new_node.node, "metadata.script_type", script.response_configuration[e.target.value]);
        setNodeState(new_node);
    };

    const handleSave = () => {
        let new_node = _.cloneDeep(nodeState);
        let keysToFormat = Object.keys(new_node.node.properties).filter(
        (node_property) => node_property !== "script_key"
        );
        keysToFormat.map((element, index, array) =>
        setObjectByPath(
            new_node.node,
            "properties." + element,
            getDatetimeFormatted(nodeState.node.properties[element])
        )
        );
        handleConfigurationClose();
        handleNodeChange(new_node);
    };

    return (
        <div
        style={{
            flexGrow: "1",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            width: "100%",
        }}
        >
        <div style={{ display: "flex" }}>
            <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>
            {t(node.node.description)}
            </span>
            <Tooltip
            title={
                <div className="tooltipText">
                <ul>
                    {node.node.helperText.map((help, index) => (
                    <li key={index}>{t(help)}</li>
                    ))}
                </ul>
                </div>
            }
            >
            <HelpIcon
                style={{ fontSize: "14px", display: "flex", margin: "auto 5px" }}
            />
            </Tooltip>
        </div>
        <div
            className="internalCardContainer"
            style={{ display: "flex", flexDirection: "column" }}
        >
            <TextField
            label={t("Tipo")}
            style={{ textAlign: "center", width: "80%", margin: "3px auto" }}
            className="filterTextbox"
            size="small"
            fullWidth
            value={nodeState.node.properties.script_key}
            onChange={handleScriptChange}
            select
            InputProps={{
                readOnly: read_only,
            }}
            >
            {rulesList.scripts_list.map((script, index) => (
                <MenuItem key={index} value={script.script_key}>
                <em className="normalText">
                    {t(script.description) }
                </em>
                </MenuItem>
            ))}
            </TextField>
            
            {nodeState.node.properties.script_key ?
                <TextField
            label={t("Resultado")}
            style={{ textAlign: "center", width: "80%", margin: "3px auto" }}
            className="filterTextbox"
            size="small"
            fullWidth
            value={nodeState.node.properties.selected_result}
            onChange={handleResultChange}
            select
            InputProps={{
                readOnly: read_only,
            }}
            >
            {rulesList.scripts_list
            .filter(script => script.script_key === nodeState.node.properties.script_key)
            .flatMap(script => 
                Object.entries(script.response_configuration).map(([key, value], index) => (
                <MenuItem key={index} value={key}>
                    <em className="normalText">
                    {(`${key} (${t(value)})`)}
                    </em>
                </MenuItem>
                ))
            )}
            </TextField>
            :null    
        }
        </div>
        <div style={{ display: "flex", margin: "auto auto 5px auto" }}>
            <div
            className={[
                "button",
                "onlyboarder",
                "normalText",
                "normalMediumSize",
            ].join(" ")}
            style={{ width: "120px" }}
            onClick={handleConfigurationClose}
            >
            {read_only ? "X" : t("discard")}
            </div>
            {!read_only && (
            <div
                className={
                validateAllValues()
                    ? ["button", "standard", "normalText", "normalMediumSize"].join(
                        " "
                    )
                    : [
                        "button",
                        "standard",
                        "normalText",
                        "normalMediumSize",
                        "disabled",
                    ].join(" ")
                }
                style={{ width: "120px" }}
                onClick={validateAllValues() ? handleSave : null}
            >
                {t("save")}
            </div>
            )}
        </div>
        </div>
    );
    }

export class ScriptsBoxClass {
    constructor(rulesContext) {
        this.rulesContext = rulesContext;
    }
    validate(node) {
        let put_error = false;
        let alerts = [];
        let parameters = node.node.properties.parameters || [];
        let children = node.node.children || [];
        if (children.length !== parameters.length) {
        put_error = true;
        alerts.push(
            `O nó de Script '${node.node.properties.description}' deve conter ${parameters.length} filhos`
        );
        } else {
        for (let childIndex = 0; childIndex < children.length; childIndex++) {
            let childType = children[childIndex].instance.getType(
            children[childIndex]
            );
            if (childType !== parameters[childIndex].type) {
            put_error = true;
            alerts.push(`O ${childIndex + 1}° deve ser do tipo ${translateType(parameters[childIndex].type)}. No momento ele é ${childType}`);
                }
            }
        }

        if (
        !node.node.metadata.script_type ||
        node.node.metadata.script_type === ""
        ) {
        put_error = true;
        alerts.push("Há um problema com o tipo desse campo");
        }

        if (node.parentNode != null) {
        if (node.parentNode.on_error) {
            put_error = true;
            alerts.push("O nó superior está inválido");
        }
        }
        for (let parameterIndex = 0; parameterIndex < parameters.length; parameterIndex++){
            alerts.push(`O ${(parameterIndex  + 1 )}° filho desse nó é o parâmetro '${parameters[parameterIndex].name}' e deve ser do tipo '${parameters[parameterIndex].type}' `);
        }
        return { validated: !put_error, alerts: alerts };
    }

    getType(node) {
        return node.metadata.script_type;
    }
    }
