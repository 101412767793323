import React, {useState, useEffect, useContext} from 'react'
import { useTranslation } from "react-i18next";
import {GetAlertsComponent,setObjectByPath, colorVelvet} from "./utils"
import TextField from "@material-ui/core/TextField";
import MenuItem from '@material-ui/core/MenuItem';
import _ from "lodash"
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import RulesContext from "../../../../context/rules-context"
import DeleteIcon from '@material-ui/icons/Delete';
import LockIcon from "@material-ui/icons/Lock";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const riskLevelOptions = [
    {
        value:"low",
        description:"Baixo Risco"
    },
    {
        value:"medium",
        description:"Médio Risco"
    },
    {
        value:"high",
        description:"Alto Risco"
    },
    {
        value:"critical",
        description:"Crítico"
    }
]

// const irregularityTypeOptions = [
//     {
//         value:"fraud",
//         description:"Fraude"
//     },
//     {
//         value:"compliance",
//         description:"Compliance"
//     }
// ]

export function ActionTriggerBox (props) {
    const { t } = useTranslation();

    let {node} = props
    let {read_only} = props
    let {handleConfigurationOpen} = props
    let {handleNodeRemove} = props

    let boxSettings = useContext(RulesContext).boxes[node.node.type].settings
    let riskLevel = riskLevelOptions.filter(option => option.value === node.node.properties.risk_level)[0]

    const getLockTypeDescription = (lock_object) => {
        let lock = (boxSettings[node.node.properties.link_key_type] || {locks:[]}).locks.filter(
            available_lock => available_lock.lock_type === lock_object.lock_type && available_lock.locked_event_type === lock_object.locked_event_type
        )[0]

        return lock ? lock.lock_type_description : ""
    }

    return (
        <div>
            {!read_only ? 
                <div style={{display:"flex", alignItems: "center"}}>
                    <div 
                        className={["normalText", "normalMediumSize", "bold"].join(" ")} 
                        style={{display:"flex", width:"100%", cursor:"pointer", margin: "auto 0px"}} 
                        onClick={() => handleConfigurationOpen(node)}
                    >
                        <div style={{display: "flex",flexDirection: "column",margin: "auto 0px"}}>
                            <div className={["normalText","normalMediumSize"].join(" ")}>
                            Trigger {t((riskLevel || {description:""}).description)} em {t((boxSettings[node.node.properties.link_key_type]  || {description:""}).description)}
                            </div>
                            <div className={["normalText","normalSmallSize"].join(" ")}>
                            {t(node.node.properties.trigger_details)} {
                                node.node.properties.locks.length > 0 
                                ?
                                <Tooltip title={<p className="tooltipText">{
                                    <ul>
                                        {node.node.properties.locks.map((lock,index) => (
                                            <li key={index}>
                                                {getLockTypeDescription(lock)}
                                            </li>
                                        ))}
                                    </ul>
                                }</p>}>
                                    <LockIcon style={{marginLeft:"3px 5px", width:"14px", height:"14px"}} />
                                </Tooltip>
                                :
                                null}
                            </div>
                        </div>
                        {(node.node.alerts || []).length > 0?
                            <GetAlertsComponent alerts={node.node.alerts} />  : null}  
                    </div>
                    <IconButton style={{width: 32, height: 32}} aria-label="duplicate" onClick={() => props.handleNodeDuplication(node)}>
                        <ContentCopyIcon style={{width: 20, height: 20}} />
                    </IconButton>
                    <IconButton style={{width: 32, height: 32}} aria-label="close" onClick={() => handleNodeRemove(node)}>
                        <CloseIcon style={{width: 20, height: 20}} />
                    </IconButton>
                </div>
            :
                <div style={{display:"flex"}}>
                    <div 
                        className={["normalText", "normalMediumSize", "bold"].join(" ")} 
                        style={{display:"flex", width:"100%", cursor:"pointer", margin: "auto 0px"}} 
                        onClick={() => handleConfigurationOpen(node)}
                    >
                        <div style={{display: "flex",flexDirection: "column",margin: "auto 0px"}}>
                            <div className={["normalText","normalMediumSize"].join(" ")}>
                            Trigger {t((riskLevel || {description:""}).description)} em {t((boxSettings[node.node.properties.link_key_type]  || {description:""}).description)}
                            </div>
                            <div className={["normalText","normalSmallSize"].join(" ")}>
                                {t(node.node.properties.trigger_details)} 
                                {node.node.properties.locks.length > 0 
                                    ?
                                    <Tooltip title={<p className="tooltipText">{
                                        <ul>
                                            {node.node.properties.locks.map((lock,index) => (
                                                <li key={index}>
                                                    {getLockTypeDescription(lock)}
                                                </li>
                                            ))}
                                        </ul>
                                    }</p>}>
                                        <LockIcon style={{marginLeft:"3px 5px", width:"14px", height:"14px"}} />
                                    </Tooltip>
                                :
                                    null}
                            </div>
                        </div>   
                    </div>
                </div>
            }
        </div>
    )
}

export function ActionTriggerConfiguration(props){
    const { t } = useTranslation();
    let {node} = props
    let {read_only} = props
    let {handleNodeChange} = props
    let {handleConfigurationClose} = props

    let boxSettings = useContext(RulesContext).boxes[node.node.type].settings
    let irregularityTypeOptions = useContext(RulesContext).boxes[node.node.type].settings.irregularity_types
    
    const [nodeState, setNodeState] = useState(node)

    useEffect(()=>{
        setNodeState(node)
    },[node])

    const handleAdd = () => {
        let new_node = _.cloneDeep(nodeState)
        let default_lock = {"locked_event_type": "", "lock_type": ""}
        new_node.node.properties.locks = [...new_node.node.properties.locks, default_lock]
        setNodeState(new_node)
    }

    const handleRemove = (index) => {
        let new_node = _.cloneDeep(nodeState)
        new_node.node.properties.locks.splice(index,1)
        setNodeState(new_node)
    }

    const handleNodeStateChange = (obj_key,value) => {
        let new_node = _.cloneDeep(nodeState)
        setObjectByPath(new_node.node,obj_key,value)
        if (obj_key === "properties.link_key_type"){
            new_node.node.properties.locks = []
        }
        setNodeState(new_node)
    }

    const handleLockChange = (e, index) => {
        let new_node = _.cloneDeep(nodeState)
        let splitted = e.target.value.split("|")
        new_node.node.properties.locks.splice(index,1,{"locked_event_type": splitted[0], "lock_type": splitted[1]})
        setNodeState(new_node)
    }

    const handleSave = () => {
        handleConfigurationClose()
        handleNodeChange(nodeState)
    }

    const validateAllValues = () => {
        let withoutDuplicatedLocks = nodeState.node.properties.locks.filter(
            (lock,index,locks)=>(
                locks.findIndex(_lock=>(_lock.lock_type === lock.lock_type && _lock.locked_event_type === lock.locked_event_type))===index
            ))
        if (withoutDuplicatedLocks.length !== nodeState.node.properties.locks.length) return false

        let risk_level_validation = nodeState.node.properties.risk_level && nodeState.node.properties.risk_level.length > 0
        let irregularity_type_validation = nodeState.node.properties.irregularity_type && nodeState.node.properties.irregularity_type.length > 0
        let link_key_type_validation = nodeState.node.properties.link_key_type && nodeState.node.properties.link_key_type.length > 0
        let category_validation = nodeState.node.properties.category && nodeState.node.properties.category.length > 0
        let category_description_validation = nodeState.node.properties.category_description && nodeState.node.properties.category_description.length > 0
        let trigger_details_validation = nodeState.node.properties.trigger_details && nodeState.node.properties.trigger_details.length > 0
        let locks_validation = true
        for (var i = 0; i < nodeState.node.properties.locks.length; i++) {
            let lock = nodeState.node.properties.locks[i]
            let valid_lock = boxSettings[nodeState.node.properties.link_key_type].locks.filter(
                available_lock => available_lock.lock_type === lock.lock_type && available_lock.locked_event_type === lock.locked_event_type
            )
            if (valid_lock.length === 0){
                locks_validation = false
                break
            }
        }

        return (
            risk_level_validation && 
            irregularity_type_validation &&
            link_key_type_validation &&
            category_validation &&
            category_description_validation && 
            trigger_details_validation &&
            locks_validation)
    }

    return(
        <div style={{flexGrow:"1", padding: "20px", display:"flex", flexDirection:"column"}}>
            <div style={{display: "flex"}}>
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>{t(node.node.description)}</span>
                <Tooltip title={<div className="tooltipText"><ul>{node.node.helperText.map((help,index) => (<li key={index}>{t(help)}</li>))}</ul></div>}>
                    <HelpIcon style={{fontSize: "14px", display:"flex", margin:"auto 5px"}}/>
                </Tooltip>
            </div>
            <div className="internalCardContainer" style={{display:"flex", flexDirection: "column"}}>
                <TextField
                    label={t("Tipo da Irregularidade")}
                    style={{textAlign:"center", width:"80%", margin:"3px auto"}}
                    className="filterTextbox" 
                    size="small" fullWidth
                    error={!(nodeState.node.properties.irregularity_type && nodeState.node.properties.irregularity_type.length > 0)}
                    value={nodeState.node.properties.irregularity_type}
                    onChange={(e) => handleNodeStateChange("properties.irregularity_type", e.target.value)}
                    select
                    InputProps={{
                        readOnly: read_only
                      }}
                >
                    {irregularityTypeOptions.map((option, index) => (
                        <MenuItem key={index} value={option.value}><em className="normalText">{t(option.description)}</em></MenuItem>

                    ))}
                </TextField>
                <TextField
                    label={t("Severidade do Trigger")}
                    style={{textAlign:"center", width:"80%", margin:"3px auto"}}
                    className="filterTextbox" 
                    size="small" fullWidth
                    error={!(nodeState.node.properties.risk_level && nodeState.node.properties.risk_level.length > 0)}
                    value={nodeState.node.properties.risk_level}
                    onChange={(e) => handleNodeStateChange("properties.risk_level", e.target.value)}
                    select
                    InputProps={{
                        readOnly: read_only
                      }}
                >
                    {riskLevelOptions.map((option, index) => (
                        <MenuItem key={index} value={option.value}><em className="normalText">{t(option.description)}</em></MenuItem>

                    ))}
                </TextField>
                <TextField
                    label={t("Tipo do Trigger")}
                    style={{textAlign:"center", width:"80%", margin:"3px auto"}}
                    className="filterTextbox" 
                    size="small" fullWidth 
                    value={nodeState.node.properties.link_key_type}
                    error={!(nodeState.node.properties.link_key_type && nodeState.node.properties.link_key_type.length > 0)}
                    onChange={(e) => handleNodeStateChange("properties.link_key_type", e.target.value)}
                    select
                    InputProps={{
                        readOnly: read_only
                      }}
                >
                    {Object.keys(boxSettings).map((option, index) => {
                        if (boxSettings[option].description) {
                            return(<MenuItem key={index} value={option}><em className="normalText">{t(boxSettings[option].description)}</em></MenuItem>)
                        }
                        return <></>
                    })
                }
                </TextField>
                <TextField
                    label={t("Detalhes do Trigger")}
                    style={{textAlign:"center", width:"80%", margin:"3px auto"}}
                    className="filterTextbox" 
                    size="small" fullWidth 
                    value={nodeState.node.properties.trigger_details}
                    error={!(nodeState.node.properties.trigger_details && nodeState.node.properties.trigger_details.length > 0)}
                    onChange={(e) => handleNodeStateChange("properties.trigger_details", e.target.value)}
                    InputProps={{
                        readOnly: read_only
                      }}
                />
                <TextField 
                    label={t("Descrição da Categoria")}
                    style={{textAlign:"center", width:"80%", margin:"3px auto"}}
                    className="filterTextbox" 
                    size="small" fullWidth 
                    value={nodeState.node.properties.category_description}
                    error={!(nodeState.node.properties.category_description && nodeState.node.properties.category_description.length > 0)}
                    onChange={(e) => handleNodeStateChange("properties.category_description", e.target.value)}
                    InputProps={{
                        readOnly: read_only
                      }}
                />
                <TextField 
                    label={t("Enumerador da Categoria")}
                    style={{textAlign:"center", width:"80%", margin:"3px auto"}}
                    className="filterTextbox" 
                    size="small" fullWidth 
                    value={nodeState.node.properties.category}
                    error={!(nodeState.node.properties.category && nodeState.node.properties.category.length > 0)}
                    onChange={(e) => handleNodeStateChange("properties.category", e.target.value)}
                    InputProps={{
                        readOnly: read_only
                      }}
                />
                {nodeState.node.properties.link_key_type !== ""
                ?
                <React.Fragment>
                {nodeState.node.properties.locks.map((row,index) => (
                    <div key={index} style={{display:"flex", width:"80%", margin:"3px auto"}}>
                        <TextField 
                            label={t("Bloqueio")}
                            error={
                                row.locked_event_type + "|" + row.lock_type === "|" || 
                                nodeState.node.properties.locks.filter(
                                    lock => lock.locked_event_type + "|" + lock.lock_type === row.locked_event_type + "|" + row.lock_type
                                ).length >1
                            }
                            style={{textAlign:"center"}}
                            className="filterTextbox" 
                            size="small" fullWidth 
                            value={row.locked_event_type + "|" + row.lock_type === "|" ? "" : row.locked_event_type + "|" + row.lock_type}
                            onChange={(e) => handleLockChange(e,index)}
                            select
                            InputProps={{
                                readOnly: read_only
                              }}
                        >
                            {boxSettings[nodeState.node.properties.link_key_type].locks.map((lock, lock_index) => (
                                <MenuItem 
                                    key={lock_index} 
                                    value={lock.locked_event_type + "|" + lock.lock_type}
                                >
                                    <em className={"normalText"}>
                                        {t(lock.lock_type_description)}
                                    </em>
                                </MenuItem>
                            ))}
                        </TextField>
                        {!read_only &&
                        <DeleteIcon onClick={() => handleRemove(index)} style={{fill:colorVelvet, margin:"auto 0px", cursor: "pointer"}}/>
                        }
                    </div>
                ))}
                {!read_only && nodeState.node.properties.locks.length !== boxSettings[nodeState.node.properties.link_key_type].locks.length
                ?
                <div
                    style={{cursor:"pointer", margin:"5px auto"}}
                    onClick={handleAdd}
                    className={["blueText", "normalSMallSize"].join(" ")}
                >
                    {t("+ BLOQUEIO")}
                </div>
                :
                null}
                </React.Fragment>
                :
                null}
            </div>
            <div style={{display:"flex", margin: "auto auto 5px auto"}}>
                <div
                    className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")}
                    style={{width:"120px"}}
                    onClick={handleConfigurationClose}
                    >
                    {read_only ? "X" : t("discard")}
                </div>
                {!read_only && 
                <div
                    className={
                        validateAllValues()
                        ?
                        ["button", "standard", "normalText", "normalMediumSize"].join(" ")
                        :
                        ["button", "standard", "normalText", "normalMediumSize","disabled"].join(" ")}
                    style={{width:"120px"}}
                    onClick={validateAllValues() ? handleSave : null}
                >
                    {t("save")}
                </div>}   
            </div>
        </div>
    )
}

export class ActionTriggerBoxClass {
    constructor(rulesContext){
        this.rulesContext = rulesContext
    }
        
    validate(node){
        let put_error = false
        let alerts = []

        let riskLevels = riskLevelOptions.reduce((acc, option) => {
            acc.push(option.value)
            return acc
        },[])

        let irregularityTypeOptions = this.rulesContext.boxes[node.node.type].settings.irregularity_types
        let irregularityTypes = irregularityTypeOptions.reduce((acc, option) => {
            acc.push(option.value)
            return acc
        },[])

        let boxSettings = this.rulesContext.boxes[node.node.type].settings

        if((node.node.children || []).length !== 0){
            put_error = true
            alerts.push("O nó de Triggers não pode ter filhos")
        }
        if (!riskLevels.includes(node.node.properties.risk_level)){
            put_error = true
            alerts.push("Clique no nó deste Trigger, abra a configuração e escolha uma severidade válida")
        }
        if (!irregularityTypes.includes(node.node.properties.irregularity_type)){
            put_error = true
            alerts.push("Clique no nó deste Trigger, abra a configuração e escolha uma irregularidade válida")
        }
        if (!Object.keys(boxSettings).includes(node.node.properties.link_key_type)){
            put_error = true
            alerts.push("Clique no nó deste Trigger, abra a configuração e escolha um tipo válido")
        }
        if (node.node.properties.trigger_details.length === 0){
            put_error = true
            alerts.push("Clique no nó deste Trigger, abra a configuração e escreva um detalhe do trigger")
        }
        if (node.node.properties.category.length === 0){
            put_error = true
            alerts.push("Clique no nó deste Trigger, abra a configuração e defina uma categoria")
        }
        if (node.node.properties.category_description.length === 0){
            put_error = true
            alerts.push("Clique no nó deste Trigger, abra a configuração e defina uma descrição da categoria")
        }
        for (var i = 0; i < node.node.properties.locks.length; i++) {
            let lock = node.node.properties.locks[i]
            let valid_lock = boxSettings[node.node.properties.link_key_type].locks.filter(
                available_lock => available_lock.lock_type === lock.lock_type && available_lock.locked_event_type === lock.locked_event_type
            )
            if (valid_lock.length === 0){
                put_error = true
                alerts.push("Clique no nó deste Trigger, abra a configuração e escolha bloqueios válidos")
            }
        }
        let withoutDuplicatedLocks = node.node.properties.locks.filter(
            (lock,index,locks)=>(
                locks.findIndex(_lock=>(_lock.lock_type === lock.lock_type && _lock.locked_event_type === lock.locked_event_type))===index
            ))
        if (withoutDuplicatedLocks.length !== node.node.properties.locks.length) {
            put_error = true
            alerts.push("Este trigger não aceita bloqueios duplicados")
        }
        if (node.parentNode != null) {
            if(node.parentNode.on_error){
                put_error = true
                alerts.push("O nó superior está inválido")
            }
        }
        return {validated: !put_error, alerts: alerts}
    }

    getType(node){
        return "void"
    }
}