import React, { useEffect } from "react";
import { CircularProgress } from '@material-ui/core';
import axios from "axios";
import CustomerHistoricTable from "./CustomerHistoricTable/CustomerHistoricTable"
import historyClockIcon from '../../../../assets/cardEntryIcons/clockHistoryBlue.svg';


function CustomerHistoricCard(props) {

    const order_key = props.order.order_key
    const order_id  = props.order.id
  
    const [customerHistoric, setCustomerHistoric] = React.useState(null)
  
    useEffect(() => {
        const timer = setTimeout(() => {
            axios.get("/dash/card_order/order/" + order_key + "/customer_historic").then(response=>{
                let filteredOrders = response.data.filter(order=>order.id !== order_id)
                setCustomerHistoric(filteredOrders)
            }).catch(error=>{
                setCustomerHistoric(undefined)
            })

        }, 500);
        return () => {
            clearTimeout(timer)
        }
    }, [order_key, order_id])
  
    if (customerHistoric === null) {
      return (
        <div className="analysisCard">
            <div style={{display: "flex"}}>
                <img className="cardTitleIconStyle" src={historyClockIcon} alt="historyClockIcon Logo" />
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>{"Histórico do Comprador"}</span>
            </div>
            <div className="internalCardContainer">
                <div style={{display: "flex", height: "160px"}}>
                    <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                        <CircularProgress />
                    </div>
                </div>       
            </div>
        </div>
      );    
    }
    else if (customerHistoric === undefined){
      return (
        <div className="analysisCard">
            <div style={{display: "flex"}}>
                <img className="cardTitleIconStyle" src={historyClockIcon} alt="historyClockIcon Logo" />
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>{"Histórico do Comprador"}</span>
            </div>
            <div className="internalCardContainer">
                <div style={{display: "flex", height: "160px"}}>
                    <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                        Informação Indisponível
                    </div>
                </div>        
            </div>
        </div>
      )
    }
    else {
      return (
        <div className="analysisCard">
            <div style={{display: "flex"}}>
                <img className="cardTitleIconStyle" src={historyClockIcon} alt="historyClockIcon Logo" />
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>{"Histórico do Comprador"}</span>
            </div>
            {customerHistoric.length === 0 ?
            <div className="internalCardContainer" style={{margin:"auto"}}>
                <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                    Nenhum histórico do comprador foi localizado
                </div>
            </div> :             
            <div className="internalCardContainer">
                <div style={{marginTop:"-5px"}}>
                    <CustomerHistoricTable historicalData={customerHistoric} />
                </div>
            </div> 
            }
        </div>
      );
    }
  }
  
export default CustomerHistoricCard;