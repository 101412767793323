import React from 'react';
import peopleIcon from '../../../../assets/cardEntryIcons/people.svg';
import emailIcon from '../../../../assets/cardEntryIcons/emailBlue.svg';
import clockIcon from '../../../../assets/cardEntryIcons/Clock.svg';
import phoneIconBlue from '../../../../assets/cardEntryIcons/phoneBlue.svg';
import mapMarkerIcon from '../../../../assets/cardEntryIcons/MapMarker.svg';
import Tooltip from "@material-ui/core/Tooltip";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { titleCase, clientAge, formatPhone, formatAddress, checkNested, getAlertsFlagIcon, formatBureauInfo} from "../../utils/utils";
import DataField from "../../utils/DataField";

function CustomerCard(props) {
    let decision_metadata
    if (props.order.analysis_events.length === 0 || props.order.analysis_events[0].decision_metadata == null){
        decision_metadata = {}
    }
    else {
        decision_metadata = props.order.analysis_events[0].decision_metadata
    }

    if (props.order.customer){
        return (
            <div className="analysisCard">
                <div style={{display: "flex"}}>
                    <img className="cardTitleIconStyle" src={peopleIcon} alt="peopleIcon Logo" />
                    <span className={["blueText", "subtitleSize", "labelSpace", "breakWord"].join(" ")}>Comprador</span>
                </div>
                <div className="internalCardContainer" style={{display: "flex", flexDirection: "column"}}>
                    <div style={{display: "inline", marginTop: "5px", marginBottom: "5px", marginLeft:"0px", lineHeight: "20px", fontSize: "22px"}}>
                        {titleCase(props.order.customer.name, "-")}
                        {checkNested(decision_metadata,"registration_data","name","flag") ?
                        <Tooltip title={checkNested(decision_metadata,"registration_data","name","description") ? 
                                        checkNested(decision_metadata,"registration_data","name","fetched_value") ?
                                        <p className="tooltipText">{decision_metadata.registration_data.name.description + "(" + titleCase(decision_metadata.registration_data.name.fetched_value,"-") + ")"}</p> :
                                        <p className="tooltipText">{decision_metadata.registration_data.name.description}</p> : ""}>
                            {getAlertsFlagIcon(decision_metadata.registration_data.name.flag, "10px","30px")}                   
                        </Tooltip> : null}
                    </div>
                    {(props.order.customer.document_number) ? 
                    <div style={{display: "inline", marginTop: "5px", marginBottom: "5px", marginLeft:"0px", lineHeight: "20px"}}>
                        <span className={["labelText", props.size, "labelSpace"].join(" ")} style={{verticalAlign: "middle", marginLeft:"0px"}}>
                            {props.order.customer.document_number || "-"}
                        </span>
                        {checkNested(decision_metadata,"registration_data","document_number","flag") ?
                        <Tooltip title={checkNested(decision_metadata,"registration_data","document_number","description") ? 
                                        checkNested(decision_metadata,"registration_data","document_number","fetched_value") ?
                                        <p className="tooltipText">{decision_metadata.registration_data.document_number.description + "(" + decision_metadata.registration_data.document_number.fetched_value + ")"}</p> :
                                        <p className="tooltipText">{decision_metadata.registration_data.document_number.description}</p> : ""}>
                            {getAlertsFlagIcon(decision_metadata.registration_data.document_number.flag, "10px","20px")}                       
                        </Tooltip> : null}
                    </div> : null}
                    <DataField
                        title={"Data de Nascimento"}
                        label={clientAge(props.order.customer.birthdate, "-")}
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        margin="5px"
                        display={"inline"}
                        check={checkNested(decision_metadata,"registration_data", "birthdate", "flag") ? decision_metadata.registration_data.birthdate.flag : null}
                        tooltipText={checkNested(decision_metadata,"registration_data", "birthdate", "description") ? 
                                     checkNested(decision_metadata,"registration_data", "birthdate", "fetched_value") ?
                                     decision_metadata.registration_data.birthdate.description + "(" + clientAge(decision_metadata.registration_data.birthdate.fetched_value) + ")": 
                                     decision_metadata.registration_data.birthdate.description : null}
                    /> 
                    {checkNested(decision_metadata,"registration_data","mother_name","fetched_value") ?
                    <DataField
                        title={"Nome da Mãe"}
                        label={titleCase(decision_metadata.registration_data.mother_name.fetched_value, "-")}
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        margin="5px"
                        display={"inline"}
                    /> : null}
                    {props.order.customer.registration_date ?
                    <DataField
                        titleImage={clockIcon}
                        titleTooltipText="Data de Registro"
                        alt={"clockIcon Logo"}
                        label={clientAge(props.order.customer.registration_date, "-", "months_days",props.order.order_date)}
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        margin="5px"
                        display={"inline"}
                    /> : null}
                    {(props.order.customer.address) ? 
                    <a
                        href={"https://www.google.com/maps/search/?api=1&query=" + 
                            formatAddress(props.order.customer.address,"-").replace(/ /g, "%20").replace(/,/, '%2C')}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{color: "#0b1f82"}}
                    >
                        <DataField
                            titleImage={mapMarkerIcon}
                            titleTooltipText="Endereço"
                            alt={"mapMarkerIcon Logo"}
                            label={formatAddress(props.order.customer.address) || "-"}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="5px"
                            display={"inline"}
                            labelClasses={"blueTextLabel"}
                            labelClassesOverride={true}
                            check={checkNested(decision_metadata, "registration_data", "address", "flag") ? decision_metadata.registration_data.address.flag : null}
                            tooltipText={checkNested(decision_metadata, "registration_data", "address", "description") ? decision_metadata.registration_data.address.description : null}
                        > 
                            {checkNested(decision_metadata, "registration_data", "address", "additional_addresses") ?
                            (
                                decision_metadata.registration_data.address.additional_addresses.length > 0 ?
                                    <Tooltip title={<p className="tooltipText">{formatBureauInfo(decision_metadata.registration_data.address.additional_addresses, "endereços")}</p>}>
                                        <MoreHorizIcon style={{verticalAlign:"middle", fill:"black"}}/>
                                    </Tooltip>
                                : null)
                            : null }

                        </DataField>
                    </a>: null}
                    {(props.order.customer.phone) ? 
                    <DataField
                        titleImage={phoneIconBlue}
                        titleTooltipText="Telefone"
                        alt={"phoneIconBlue Logo"}
                        label={formatPhone(props.order.customer.phone, "-")}
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        margin="5px"
                        display={"inline"}
                        check={checkNested(decision_metadata, "registration_data", "phone_number", "flag") ? decision_metadata.registration_data.phone_number.flag : null}
                        tooltipText={checkNested(decision_metadata, "registration_data", "phone_number", "description") ? decision_metadata.registration_data.phone_number.description : null}
                    > 
                        {checkNested(decision_metadata, "registration_data", "phone_number", "additional_phones") ?
                            (
                                decision_metadata.registration_data.phone_number.additional_phones.length > 0 ?
                                    <Tooltip title={<p className="tooltipText">{formatBureauInfo(decision_metadata.registration_data.phone_number.additional_phones, "telefones")}</p>}>
                                        <MoreHorizIcon style={{verticalAlign:"middle"}}/>
                                    </Tooltip>
                                : null)
                        : null }
                    </DataField>: null} 
                    {(props.order.customer.email) ? 
                    <DataField
                        titleImage={emailIcon}
                        titleTooltipText="Email"
                        alt={"emailIcon Logo"}
                        label={props.order.customer.email || "-"}
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        margin="5px"
                        display={"inline"}
                        check={checkNested(decision_metadata, "registration_data", "email", "flag") ? decision_metadata.registration_data.email.flag : null}
                        tooltipText={checkNested(decision_metadata, "registration_data", "email", "description") ? decision_metadata.registration_data.email.description : null}
                    >
                        {checkNested(decision_metadata, "registration_data", "email", "additional_emails") ?
                            (
                                decision_metadata.registration_data.email.additional_emails.length > 0 ?
                                    <Tooltip title={<p className="tooltipText">{formatBureauInfo(decision_metadata.registration_data.email.additional_emails, "emails")}</p>}>
                                        <MoreHorizIcon style={{verticalAlign:"middle", fill:"black"}}/>
                                    </Tooltip>
                                : null)
                        : null }
                    </DataField> : null}
                </div>
            </div>
        )
    }
    else {
        return null
    }
}



//Exporting component
export default CustomerCard