//Importing modules
import React from "react";
import { makeStyles} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from '@material-ui/core/Checkbox';
import {getTableRowClass} from "../../../utils/utils";



//Defining CSS for List Contents Table
const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
  },
  blueText: {
    fontFamily: "Open Sans",
    fontWeight: "600",
    color: "#0b1f82"
},
  tableText: {
    fontFamily: "Open Sans",
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "center",
    margin: "10px",
    padding: "10px",
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: '#6F6F6F',
    fontSize: "14px",
    lineHeight: "19px",
    padding: "6px",
  }
}));


//Defining List Contents Table component
function SharedListPermissionTable(props) {
  //Starting predefined styles to be used on table
  const classes = useStyles();


  //Getting data from props
  let { sharedListPermissions } = props
  let { setSharedListPermissions } = props


  const handleToggleCanRead = (event, index) => {
      let newListContentsState = [...sharedListPermissions]
      newListContentsState[index].can_read = event.target.checked
      setSharedListPermissions(newListContentsState)
  }


  const handleToggleCanWrite = (event, index) => {
    let newListContentsState = [...sharedListPermissions]
    newListContentsState[index].can_write = event.target.checked
    setSharedListPermissions(newListContentsState)
}


  //Rendering Table
  return (
    <Paper className={classes.tableStyle}>
      <Table style={{ padding: "8px" }}>
        <TableHead>
            <TableRow>
                <TableCell className={classes.tableHeader}>
                  Nome da Companhia
                </TableCell>
                <TableCell className={classes.tableHeader}>
                  Permissão de Leitura
                </TableCell>
                <TableCell className={classes.tableHeader}>
                  Permissão de Escrita
                </TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {sharedListPermissions.map((rowData,index) => (
                <TableRow
                key={index}
                className={getTableRowClass(index)}
                > 
                    <TableCell className={[classes.tableText, "breakWord"].join(" ")}>
                      {rowData.company_name}
                    </TableCell>
                    <TableCell className={[classes.tableText, "breakWord"].join(" ")}>
                        <Checkbox
                            checked={rowData.can_read}
                            onChange={(e) => handleToggleCanRead(e,index)}
                            disabled={!rowData.edit_can_read}
                            color="primary"
                        />
                    </TableCell>
                    <TableCell className={[classes.tableText, "breakWord"].join(" ")}>
                        <Checkbox
                            checked={rowData.can_write}
                            onChange={(e) => handleToggleCanWrite(e,index)}
                            disabled={!rowData.edit_can_write}
                            color="primary"
                        />
                    </TableCell>

                </TableRow>
            ))}
        </TableBody>
      </Table>
    </Paper>
    
  );
}



//Exporting SharedListPermissionTable component as default
export default SharedListPermissionTable;
