import React from "react";
import FilterBox from "../../../Utils/FilterBox/FilterBox"

function Filter(props) {
	
    const { setPageParams, local_storage_object_preffix } = props

    let filterTypes = {}


    if (local_storage_object_preffix === 'ra_sac'){
        filterTypes = {
            document_number: {
                type: "masked_string",
                maskType:"999.999.999-99",
                disabled: false,
                validation:true,
                validation_rule: {type: "equal", value:14},
                description: "CPF"
            },
            ra: {
                type: "string",
                description: "RA"
            }
        }
    }else{
        filterTypes = {
            document_number: {
                type: "masked_string",
                maskType:"999.999.999-99",
                disabled: false,
                validation:true,
                validation_rule: {type: "equal", value:14},
                description: "CPF"
            },
            rne: {
                type: "string",
                description: "RNE"
            },
            rental_agreement_date: {
                type: "date",
                disabled: false,
                validation:false,
                description: "Data"
            },
            rental_agreement_code: {
                type: "string",
                disabled: false,
                validation:false,
                description: "Número da Locação"
            },
            rental_store: {
                type: "string",
                disabled: false,
                validation:false,
                description: "Loja de Retirada"
            },
            status: {
                type: "enum",
                enumList: [
                    ["Pendente", "pending"],
                    ["Aprovado Manualmente", "manually_approved"],
                    ["Reprovado Manualmente", "manually_reproved"],
                    ["Aprovado Automaticamente", "automatically_approved"],
                    ["Reprovado Automaticamente", "automatically_reproved"],
                    ["Aprovado por tempo", "approved_by_time"],
                    ["Reprovado por tempo", "reproved_by_time"]
                ],
                disabled: false,
                validation:false,
                description: "Status"
            }
        }
    }

    return (
        <FilterBox 
            setPageParams={setPageParams}
            filterTypes={filterTypes}
            local_storage_object_preffix={props.local_storage_object_preffix}
        />
    )
}

export default Filter