import React, {useState, useContext, useEffect} from 'react'
import { useTranslation } from "react-i18next";
import {GetAlertsComponent,setObjectByPath, comparisionOptions, getConstantTextFields, validateConstantValues, translateType} from "./utils"
import TextField from "@material-ui/core/TextField";
import InputMask from "react-input-mask";
import NumberFormat from 'react-number-format';
import MenuItem from '@material-ui/core/MenuItem';
import RulesContext from "../../../../context/rules-context"
import _ from "lodash"
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
// BÁSICAS - Comparação do Campo do Evento

const getDatetimeFormatted = (value) => {
    if (moment(value, "DD/MM/YYYY ",true).isValid()){
        return moment(value, "DD/MM/YYYY ", true).format("YYYY-MM-DD") 
    }
    else if(moment(value, "DD/MM/YYYY HH:mm:ss",true).isValid()){
        return moment(value, "DD/MM/YYYY HH:mm:ss", true).format("YYYY-MM-DDTHH:mm:ssZ") 
    }
    else return value
}

function returnVisualPattern (value) {
    return (
        moment(value, "YYYY-MM-DD",true).isValid() 
        ?
        moment(value).format("DD/MM/YYYY")
        :
        moment(value, "YYYY-MM-DDTHH:mm:ssZ",true).isValid() 
        ?
        moment(value).format("DD/MM/YYYY HH:mm:ss")
        :
        value)
}

export function ComparisionEventFieldBox (props) {
    const {t} = useTranslation()
    let {node} = props
    let {handleConfigurationOpen} = props
    let {handleNodeRemove} = props
    let rulesList = useContext(RulesContext)
    let event = rulesList.fields_mapper_list.filter(rule => rule.path === node.node.properties.field_path)[0]
    let comparision = comparisionOptions.filter(comparision => comparision.value === node.node.properties.comparision_type)[0]
    let {read_only} = props
    
    let helperString = comparision ? 
        comparision.value === "in_between" ? "Está " : "É " : ""

    let values =  comparision ? 
        comparision.value === "in_between" ? returnVisualPattern(node.node.properties.min_value) + " e " + returnVisualPattern(node.node.properties.max_value) : 
        typeof node.node.properties.value === "boolean" ? node.node.properties.value ? "Verdadeiro" : "Falso" : 
        returnVisualPattern(node.node.properties.value): ""

    return (
        <div>
            {!read_only ?
                <div style={{display:"flex", alignItems: "center"}}>
                    <div 
                        style={{display:"flex", width:"100%", cursor:"pointer", margin: "0px"}} 
                        onClick={() => handleConfigurationOpen(node)}
                    >
                        <div style={{display: "flex",flexDirection: "column",margin: "auto 0px"}}>
                            <div className={["normalText","normalMediumSize"].join(" ")}>
                            {t((event || {description:""}).description)}
                            </div>
                            <div className={["normalText","normalSmallSize"].join(" ")}>
                            {t(helperString + (comparision||{description:""}).description.toLowerCase() + " " + values)}
                            </div>
                        </div>
                        {(node.node.alerts || []).length > 0?
                            <GetAlertsComponent alerts={node.node.alerts} />  : null}  
                    </div>
                    <IconButton style={{width: 32, height: 32}} aria-label="duplicate" onClick={() => props.handleNodeDuplication(node)}>
                        <ContentCopyIcon style={{width: 20, height: 20}} />
                    </IconButton>
                    <IconButton style={{width: 32, height: 32}} aria-label="close" onClick={() => handleNodeRemove(node)}>
                        <CloseIcon style={{width: 20, height: 20}} />
                    </IconButton>
                </div>
            :
                <div style={{display:"flex"}}>
                    <div 
                        style={{display:"flex", width:"100%", cursor:"pointer", margin: "0px"}} 
                        onClick={() => handleConfigurationOpen(node)}
                    >
                        <div style={{display: "flex",flexDirection: "column",margin: "auto 0px"}}>
                            <div className={["normalText","normalMediumSize"].join(" ")}>
                            {t((event || {description:""}).description)}
                            </div>
                            <div className={["normalText","normalSmallSize"].join(" ")}>
                            {t(helperString + (comparision||{description:""}).description.toLowerCase() + " " + values)}
                            </div>
                        </div>      
                    </div>
                </div>
            }
        </div>
    )
}

export function ComparisionEventFieldConfiguration(props){
    const { t } = useTranslation();
    let {node} = props
    let {handleNodeChange} = props
    let {handleConfigurationClose} = props
    let rulesList = useContext(RulesContext)
    let {read_only} = props

    const [nodeState, setNodeState] = useState(node)

    useEffect(()=>{
        setNodeState(node)
    },[node])

    const validateAllValues = (fieldType) => {
        let validation
        let validationValue = getDatetimeFormatted(nodeState.node.properties.value)
        let validationMinValue = getDatetimeFormatted(nodeState.node.properties.min_value)
        let validationMaxValue = getDatetimeFormatted(nodeState.node.properties.max_value)
        if (!fieldType || fieldType === "") validation = false
        else{
            validation = (
                validateConstantValues(fieldType,validationValue) || (
                    validateConstantValues(fieldType,validationMinValue) && validateConstantValues(fieldType,validationMaxValue)
                )
            )
        }
        return validation
    }

    const removeNonComparableEvents = () => {
        let filteredList = rulesList.fields_mapper_list.filter(field => {
            let fieldOptions = getOptions(field.type)
            if (fieldOptions.length > 0) return true
            else return false
        })
        return filteredList
    }

    const getOptions = (fieldType) => {
        let optionsList = []
        if (fieldType && fieldType !== ""){
            let filteredOptions = comparisionOptions.filter(option => option.types.includes(fieldType))
            optionsList = filteredOptions
        } 
        return optionsList
    }

    const handleEventChange = (e) => {
        let event = rulesList.fields_mapper_list.filter(rule => rule.path === e.target.value)[0]
        let new_node = _.cloneDeep(nodeState)
        setObjectByPath(new_node.node,"properties.field_path",e.target.value)
        setObjectByPath(new_node.node,"metadata.field_type",event.type)
        setObjectByPath(new_node.node,"properties.comparision_type","")
        setObjectByPath(new_node.node,"properties.value","")
        setObjectByPath(new_node.node,"properties.min_value",null)
        setObjectByPath(new_node.node,"properties.max_value",null)
        setNodeState(new_node)
    }

    const handleComparisionChange = (newComparision) => {
        let new_node = _.cloneDeep(nodeState)
        setObjectByPath(new_node.node,"properties.comparision_type",newComparision)
        if(newComparision === "in_between"){
            setObjectByPath(new_node.node,"properties.value",null)
            setObjectByPath(new_node.node,"properties.min_value","")
            setObjectByPath(new_node.node,"properties.max_value","")
        }
        else{
            setObjectByPath(new_node.node,"properties.value","")
            setObjectByPath(new_node.node,"properties.min_value",null)
            setObjectByPath(new_node.node,"properties.max_value",null)
        }
        setNodeState(new_node)
    }

    const handleValuesChange = (path, val) => {
        let new_node = _.cloneDeep(nodeState)
        setObjectByPath(new_node.node,path,val)
        setNodeState(new_node)
    }

    const handleSave = () => {
        let new_node = _.cloneDeep(nodeState)
        setObjectByPath(new_node.node,"properties.value",getDatetimeFormatted(nodeState.node.properties.value)) 
        setObjectByPath(new_node.node,"properties.min_value",getDatetimeFormatted(nodeState.node.properties.min_value)) 
        setObjectByPath(new_node.node,"properties.max_value",getDatetimeFormatted(nodeState.node.properties.max_value)) 
        handleConfigurationClose()
        handleNodeChange(new_node)
    }

    const createdFields = (constant_type, extraProps={}, propertyName="value") => {
        let standardField = getConstantTextFields(constant_type)
        let pathChange = "properties." + propertyName
        switch(constant_type) {
            case "number":
                return (
                     <NumberFormat 
                        {...standardField.props}
                        {...extraProps}
                        error={!validateConstantValues(constant_type,nodeState.node.properties[propertyName])}
                        disabled={getOptions(constant_type).length === 0}
                        value={nodeState.node.properties[propertyName] || ""}
                        onValueChange={(e) => handleValuesChange(pathChange, e.floatValue)}
                        InputProps={{
                            readOnly: read_only
                          }}
                    />
                )    
            case "bool":
                return (                        
                    <TextField 
                        {...extraProps}
                        {...standardField.props} 
                        error={!validateConstantValues(constant_type,nodeState.node.properties[propertyName])}
                        disabled={getOptions(constant_type).length === 0}  
                        value={nodeState.node.properties[propertyName] == null ? "" : nodeState.node.properties[propertyName]}
                        onChange={(e) => handleValuesChange(pathChange, e.target.value)}
                        InputProps={{
                            readOnly: read_only
                          }}
                    />
                )
            case "string":
                return (
                        <TextField 
                        {...extraProps}
                        {...standardField.props}
                        error={!validateConstantValues(constant_type,nodeState.node.properties[propertyName])}
                        disabled={getOptions(constant_type).length === 0} 
                        value={nodeState.node.properties[propertyName] || ""}
                        onChange={(e) => handleValuesChange(pathChange, e.target.value)}
                        InputProps={{
                            readOnly: read_only
                          }}
                    />
                )    
            case "datetime":
                return (
                    <InputMask 
                        {...extraProps}
                        {...standardField.props} 
                        error={!validateConstantValues(constant_type,getDatetimeFormatted(nodeState.node.properties[propertyName]))}
                        disabled={getOptions(constant_type).length === 0}
                        value={returnVisualPattern(nodeState.node.properties[propertyName]) || ""}
                        onChange={(e) => handleValuesChange(pathChange, e.target.value)}
                        InputProps={{
                            readOnly: read_only
                          }}                        
                    />
                )   
            case "time":
                return (
                    <InputMask 
                        {...extraProps}
                        {...standardField.props} 
                        error={!validateConstantValues(constant_type,nodeState.node.properties[propertyName])}
                        disabled={getOptions(constant_type).length === 0}
                        value={nodeState.node.properties[propertyName] || ""}
                        onChange={(e) => handleValuesChange(pathChange, e.target.value)}
                        InputProps={{
                            readOnly: read_only
                          }}                        
                    />
                )           
            default:
                throw new Error ("Invalid field Type")
        }
    }

    return(
        <div style={{flexGrow:"1", padding: "20px", display:"flex", flexDirection:"column"}}>
            <div style={{display: "flex"}}>
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>{t(node.node.description)}</span>
                <Tooltip title={<div className="tooltipText"><ul>{node.node.helperText.map((help,index) => (<li key={index}>{t(help)}</li>))}</ul></div>}>

                    <HelpIcon style={{fontSize: "14px", display:"flex", margin:"auto 5px"}}/>
                </Tooltip>
            </div>
            <div className="internalCardContainer" style={{display:"flex", flexDirection: "column"}}>
                <TextField 
                    label={t("Campo")}
                    style={{textAlign:"center", width:"80%", margin:"3px auto"}}
                    className="filterTextbox" 
                    size="small" fullWidth 
                    value={nodeState.node.properties.field_path || ""}
                    onChange={handleEventChange}
                    select
                    InputProps={{
                        readOnly: read_only
                      }}
                >
                    {removeNonComparableEvents().map((field, index) => (
                        <MenuItem key={index} value={field.path}><em className="normalText">{t(field.description) + " ("+ t(translateType(field.type))+ ")"}</em></MenuItem>
                    ))}
                </TextField>
                <TextField
                    label={t("Comparação")}
                    style={{textAlign:"center", width:"80%", margin:"3px auto"}}
                    className="filterTextbox" 
                    size="small" fullWidth 
                    value={nodeState.node.properties.comparision_type}
                    onChange={(e) => handleComparisionChange(e.target.value)}
                    select
                    disabled={getOptions(nodeState.node.metadata.field_type).length === 0}
                    InputProps={{
                        readOnly: read_only
                      }}
                >
                    {getOptions(nodeState.node.metadata.field_type).map((option, index) => (
                        <MenuItem key={index} value={option.value}><em className="normalText">{t(option.description)}</em></MenuItem>

                    ))}
                </TextField>
                {
                nodeState.node.properties.comparision_type === "in_between" 
                ?
                <React.Fragment>
                    {createdFields(
                        nodeState.node.metadata.field_type,
                        {style:{textAlign:"center", width:"80%", margin:"3px auto"}, label:t("Menor Valor")}, 
                        "min_value"
                    )}
                    {createdFields(
                        nodeState.node.metadata.field_type,
                        {style:{textAlign:"center", width:"80%", margin:"3px auto"}, label:t("Maior Valor")}, 
                        "max_value"
                    )}
                </React.Fragment>
                :
                nodeState.node.metadata.field_type && nodeState.node.metadata.field_type !== "" 
                ?
                createdFields(
                    nodeState.node.metadata.field_type, 
                    {style:{textAlign:"center", width:"80%", margin:"3px auto"}, label: t("Valor")}
                ) 
                : 
                <TextField 
                    style={{textAlign:"center", width:"80%", margin:"3px auto"}}
                    className="filterTextbox" 
                    size="small" fullWidth 
                    disabled
                    InputProps={{
                        readOnly: read_only
                      }}
                />} 
            </div>
            <div style={{display:"flex", margin: "auto auto 5px auto"}}>
                <div
                    className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")}
                    style={{width:"120px"}}
                    onClick={handleConfigurationClose}
                >
                    {read_only ? "X" : t("discard")}
                </div>
                {!read_only && 
                <div
                    className={
                        validateAllValues(nodeState.node.metadata.field_type)
                        ?
                        ["button", "standard", "normalText", "normalMediumSize"].join(" ")
                        :
                        ["button", "standard", "normalText", "normalMediumSize","disabled"].join(" ")}
                    style={{width:"120px"}}
                    onClick={validateAllValues(nodeState.node.metadata.field_type) ? handleSave : null}
                >
                    {t("save")}
                </div>}   
            </div>
        </div>
    )
}

export class ComparisionEventFieldBoxClass {
    constructor(rulesContext){
        this.rulesContext = rulesContext
    }

    validate(node){
        let put_error = false
        let alerts = []
        let validationValue = getDatetimeFormatted(node.node.properties.value)
        let validationMinValue = getDatetimeFormatted(node.node.properties.min_value)
        let validationMaxValue = getDatetimeFormatted(node.node.properties.max_value)
        let fieldType = node.node.metadata.field_type
        //Validate if node has one or two children
        if((node.node.children || []).length !== 0){
            put_error = true
            alerts.push("Este nó não deve possuir filhos")
        }
        if(node.node.properties.field_path === ""){
            put_error = true
            alerts.push("Clique neste nó, abra a configuração e escolha um Campo válido")
        }
        else if(fieldType === ""){
            put_error = true
            alerts.push("Há um problema com o tipo desse campo")
        }
        else{
            if(!(
                validateConstantValues(fieldType,validationValue) || 
                (validateConstantValues(fieldType,validationMinValue) && validateConstantValues(fieldType,validationMaxValue)))){
                put_error = true
                alerts.push("Clique neste nó, abra a configuração e escreva um valor válido")
            }
        }
        if(node.node.properties.comparision_type === ""){
            put_error = true
            alerts.push("Clique neste nó, abra a configuração e escolha uma comparação válida")
        }

        //Validate if parent is valid
        if (node.parentNode != null) {
            if(node.parentNode.on_error){
                put_error = true
                alerts.push("O nó superior está inválido")
            }
        }
        return {validated: !put_error, alerts: alerts}
    }

    getType(node){
        return "bool"
    }
}
