import {basicBoxesList} from "./Boxes/utils"
import {getTypeComponents} from "./BoxTypeMapper"
import _ from "lodash"
import { v4 as uuidv4 } from 'uuid';

function createIncomeTree (node, rulesContext, userVariablesAvailable){
    if (node.treeIndex === -1) return node.node
    let basicBoxesCopy  = _.cloneDeep(basicBoxesList) // IMPORTANT
    let basicBox = basicBoxesCopy.filter(box => box.type === node.node.type)[0]
    node.node = {...basicBox, ...node.node}
    node.node.expanded = true
    node.node.instance = getTypeComponents({type:node.node.type, rulesContext:rulesContext, userVariablesAvailable: userVariablesAvailable}).instance
    node.node.id = uuidv4()
    return node.node
}

function createOutcomeTree (node){
    if (node.treeIndex === -1) return node.node
    let {properties, type, children, metadata} = node.node
    let node_object = {properties:properties, type:type}    
    if (children) node_object = {...node_object, children:children}
    if (metadata) node_object = {...node_object, metadata:metadata}
    return node_object
}

function defaultGetNodeKey({ treeIndex }) {
    return treeIndex;
}

export function translateOutcomeTreeData (incomeTreeData) {
    let a = map({
        treeData:incomeTreeData,
        getNodeKey:defaultGetNodeKey,
        callback:createOutcomeTree,
        ignoreCollapsed:false
    })
    return a
}
  
export function translateIncomeTreeData (incomeTreeData,rulesContext,userVariablesAvailable) {
    let a = map({
        treeData:incomeTreeData,
        getNodeKey:defaultGetNodeKey,
        callback:createIncomeTree,
        ignoreCollapsed:false,
        callbackExtraProperties:rulesContext,
        userVariablesAvailable: userVariablesAvailable
    })
    return a
}

export function walk({
    treeData,
    getNodeKey,
    ignoreCollapsed = true,
  }) {
    if (!treeData || treeData.length < 1) {
      return;
    }
    
    let [childIndex, newTreeData, anyError]  = walkDescendants({
      getNodeKey,
      ignoreCollapsed,
      isPseudoRoot: true,
      node: { children: treeData },
      currentIndex: -1,
      path: [],
      lowerSiblingCounts: [],
      treeData: []
    });

    return {childIndex:childIndex, treeData:newTreeData, anyError:anyError}
  }

function walkDescendants({
    getNodeKey,
    ignoreCollapsed,
    isPseudoRoot = false,
    node,
    parentNode = null,
    currentIndex,
    path = [],
    lowerSiblingCounts = [],
    treeData = [],
    anyError=false
  }) {
    // The pseudo-root is not considered in the path
    const selfPath = isPseudoRoot
      ? []
      : [...path, getNodeKey({ node, treeIndex: currentIndex })];
    const selfInfo = isPseudoRoot
      ? null
      : {
          node,
          parentNode,
          path: selfPath,
          lowerSiblingCounts,
          treeIndex: currentIndex,
        };
  
    if (!isPseudoRoot) {
        let {validated, alerts} = selfInfo.node.instance.validate(selfInfo);
        if (validated === false) anyError=true
        selfInfo.node.on_error = !validated
        selfInfo.node.alerts = alerts
        if (selfInfo.parentNode == null){
            let {children, ...nodeWithoutChildren} = selfInfo.node
            treeData = addNodeUnderParent({
                treeData: treeData,
                parentKey: null,
                expandParent: true,
                ignoreCollapsed: false,
                getNodeKey,
                newNode: nodeWithoutChildren,
            }).treeData
        }
        else{
            let {children, ...nodeWithoutChildren} = selfInfo.node
            treeData = addNodeUnderParent({
                treeData: treeData,
                parentKey: path[path.length -1],
                ignoreCollapsed: false,
                expandParent: selfInfo.parentNode.expanded,
                getNodeKey,
                newNode: nodeWithoutChildren,
            }).treeData
        }
    }
  
    // Return self on nodes with no children
    if (!node.children) {
      return [currentIndex, treeData, anyError];
    }
  
    // Get all descendants
    let childIndex = currentIndex;
    const childCount = node.children.length;
    if (typeof node.children !== 'function') {
      for (let i = 0; i < childCount; i += 1) {
        [childIndex, treeData, anyError] = walkDescendants({
          getNodeKey,
          ignoreCollapsed,
          node: node.children[i],
          parentNode: isPseudoRoot ? null : node,
          currentIndex: childIndex + 1,
          lowerSiblingCounts: [...lowerSiblingCounts, childCount - i - 1],
          path: selfPath,
          treeData: treeData,
          anyError: anyError
        });
      }
    }
  
    return [childIndex,treeData, anyError];
  }



  

export function addNodeUnderParent({
    treeData,
    newNode,
    parentKey = null,
    getNodeKey,
    ignoreCollapsed = true,
    expandParent = false,
    addAsFirstChild = false,
  }) {
    if (parentKey === null) {
      return addAsFirstChild
        ? {
            treeData: [newNode, ...(treeData || [])],
            treeIndex: 0,
          }
        : {
            treeData: [...(treeData || []), newNode],
            treeIndex: (treeData || []).length,
          };
    }
  
    let insertedTreeIndex = null;
    let hasBeenAdded = false;
    const changedTreeData = map({
      treeData,
      getNodeKey,
      ignoreCollapsed,
      callback: ({ node, treeIndex, path }) => {
        const key = path ? path[path.length - 1] : null;
        // Return nodes that are not the parent as-is
        if (hasBeenAdded || key !== parentKey) {
          return node;
        }
        hasBeenAdded = true;
  
        const parentNode = {
          ...node,
        };
  
        if (expandParent) {
          parentNode.expanded = true;
        }
  
        // If no children exist yet, just add the single newNode
        if (!parentNode.children) {
          insertedTreeIndex = treeIndex + 1;
          return {
            ...parentNode,
            children: [newNode],
          };
        }
  
        if (typeof parentNode.children === 'function') {
          throw new Error('Cannot add to children defined by a function');
        }
  
        let nextTreeIndex = treeIndex + 1;
        for (let i = 0; i < parentNode.children.length; i += 1) {
          nextTreeIndex +=
            1 +
            getDescendantCount({ node: parentNode.children[i], ignoreCollapsed });
        }
  
        insertedTreeIndex = nextTreeIndex;
  
        const children = addAsFirstChild
          ? [newNode, ...parentNode.children]
          : [...parentNode.children, newNode];
  
        return {
          ...parentNode,
          children,
        };
      },
    });
  
    if (!hasBeenAdded) {
      throw new Error('No node found with the given key.');
    }
  
    return {
      treeData: changedTreeData,
      treeIndex: insertedTreeIndex,
    };
  }


  export function map({
    treeData,
    getNodeKey,
    callback,
    ignoreCollapsed = true,
    callbackExtraProperties=null,
    userVariablesAvailable = null
  }) {
    if (!treeData || treeData.length < 1) {
      return [];
    }
  
    return mapDescendants({
        callback,
        getNodeKey,
        ignoreCollapsed,
        callbackExtraProperties:callbackExtraProperties,
        userVariablesAvailable: userVariablesAvailable,
        isPseudoRoot: true,
        node: { children: treeData },
        currentIndex: -1,
        path: [],
        lowerSiblingCounts: [],
      }).node.children;
  }

  function mapDescendants({
    callback,
    getNodeKey,
    ignoreCollapsed,
    callbackExtraProperties=null,
    userVariablesAvailable=null,
    isPseudoRoot = false,
    node,
    parentNode = null,
    currentIndex,
    path = [],
    lowerSiblingCounts = [],
  }) {
    const nextNode = { ...node };
  
    // The pseudo-root is not considered in the path
    const selfPath = isPseudoRoot
      ? []
      : [...path, getNodeKey({ node: nextNode, treeIndex: currentIndex })];
    const selfInfo = {
      node: nextNode,
      parentNode,
      path: selfPath,
      lowerSiblingCounts,
      treeIndex: currentIndex,
    };
  
    // Return self on nodes with no children or hidden children
    if (
      !nextNode.children ||
      (nextNode.expanded !== true && ignoreCollapsed && !isPseudoRoot)
    ) {
      return {
        treeIndex: currentIndex,
        node: callback(selfInfo,callbackExtraProperties, userVariablesAvailable),
      };
    }
  
    // Get all descendants
    let childIndex = currentIndex;
    const childCount = nextNode.children.length;
    if (typeof nextNode.children !== 'function') {
      nextNode.children = nextNode.children.map((child, i) => {
        const mapResult = mapDescendants({
          callback,
          getNodeKey,
          ignoreCollapsed,
          callbackExtraProperties:callbackExtraProperties,
          userVariablesAvailable: userVariablesAvailable,
          node: child,
          parentNode: isPseudoRoot ? null : nextNode,
          currentIndex: childIndex + 1,
          lowerSiblingCounts: [...lowerSiblingCounts, childCount - i - 1],
          path: selfPath,
        });
        childIndex = mapResult.treeIndex;
  
        return mapResult.node;
      });
    }
  
    return {
      node: callback(selfInfo,callbackExtraProperties, userVariablesAvailable),
      treeIndex: childIndex,
    };
  }

  export function getDescendantCount({ node, ignoreCollapsed = true }) {
    return (
      getNodeDataAtTreeIndexOrNextIndex({
        getNodeKey: () => {},
        ignoreCollapsed,
        node,
        currentIndex: 0,
        targetIndex: -1,
      }).nextIndex - 1
    );
  }

  function getNodeDataAtTreeIndexOrNextIndex({
    targetIndex,
    node,
    currentIndex,
    getNodeKey,
    path = [],
    lowerSiblingCounts = [],
    ignoreCollapsed = true,
    isPseudoRoot = false,
  }) {
    // The pseudo-root is not considered in the path
    const selfPath = !isPseudoRoot
      ? [...path, getNodeKey({ node, treeIndex: currentIndex })]
      : [];
  
    // Return target node when found
    if (currentIndex === targetIndex) {
      return {
        node,
        lowerSiblingCounts,
        path: selfPath,
      };
    }
  
    // Add one and continue for nodes with no children or hidden children
    if (!node.children || (ignoreCollapsed && node.expanded !== true)) {
      return { nextIndex: currentIndex + 1 };
    }
  
    // Iterate over each child and their descendants and return the
    // target node if childIndex reaches the targetIndex
    let childIndex = currentIndex + 1;
    const childCount = node.children.length;
    for (let i = 0; i < childCount; i += 1) {
      const result = getNodeDataAtTreeIndexOrNextIndex({
        ignoreCollapsed,
        getNodeKey,
        targetIndex,
        node: node.children[i],
        currentIndex: childIndex,
        lowerSiblingCounts: [...lowerSiblingCounts, childCount - i - 1],
        path: selfPath,
      });
  
      if (result.node) {
        return result;
      }
  
      childIndex = result.nextIndex;
    }
  
    // If the target node is not found, return the farthest traversed index
    return { nextIndex: childIndex };
  }

export function isDescendant(older, younger) {
    return (
        !!older.children &&
        typeof older.children !== 'function' &&
        older.children.some(
        child => child === younger || isDescendant(child, younger)
        )
    );
}