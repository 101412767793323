import React, { useCallback, useEffect, useState } from "react";
import { ErrorOutline, Check } from "@mui/icons-material";
import axios from 'axios';
import { colorRed, colorGreen } from "../../../containers/Utils/utils";
import { colorYellow } from "../../../containers/Onboarding/utils/utils";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ProcessesTable from './ProcessesTable'
import InputLabel from '@mui/material/InputLabel';
import Pagination from "@mui/material/Pagination";
import UpdateIcon from '@mui/icons-material/Update';

import * as S from './styles';
import { Tooltip } from "@mui/material";
import Input from "../../common/Input";

export default function ProcessesEDPCard(props) {
    
    const { person_type, person_key, consolidated, company_key } = props;
    const [processes, setProcesses] = useState([]);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);

    const retrieveProcesses = useCallback(() => {
        axios.get(`/dash/external_data_proxy/bigboost/${person_type}/processes?person_key=${person_key}`, {
            headers: {
                'COMPANY-KEY': company_key
            }
        }).then(response => {
            setProcesses(response.data.data);
            setLoading(false);
        }).catch(error => {
            console.log(error);
            setLoading(false);
            setError(true);
        })
    }, [person_key, person_type, company_key])
    
    useEffect(() => {
        retrieveProcesses();
    }, [retrieveProcesses])

    const INITIAL_PAGE_SIZE = 25

    const [pageAmount, setPageAmount] = useState(processes.length / INITIAL_PAGE_SIZE)
    const [searchValue, setSearchValue] = useState('');
    const [pageSize, setPageSize] = useState(INITIAL_PAGE_SIZE);
    const [page, setPage] = useState(1);
    const [processesSubset, setProcessesSubset] = useState([]);
    const [polarity, setPolarity] = useState(null);
    const [courtType, setCourtType] = useState(null);
    const [courtOptions, setCourtOptions] = useState([]);

    useEffect(() => {
        if (processes) {
            let options = {};
            for (let i = 0; i < processes.length; i++) {
                const process = processes[i];
                if (process.court_data?.court_type) {

                    const courtType = process.court_data?.court_type.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()

                    if (!Object.keys(options).includes(courtType)) {
                        options[courtType] = 1;
                    } else {
                        options[courtType]++;
                    }
                } 
            }

            const sortedKeys = Object.entries(options).sort(([, valueA], [, valueB]) => valueB - valueA).map(([key]) => key.charAt(0).toUpperCase() + key.slice(1).toLowerCase());
            setCourtOptions(sortedKeys);
        }
    }, [processes])


    useEffect(() => {
        let filtered = processes.filter(process => (process.process_type || '').toLowerCase().includes((searchValue || '').normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()));
        
        if (polarity) {
            filtered = filtered.filter(process => process.polarity === polarity);
        }
        
        if (courtType) {
            filtered = filtered.filter(process => process.court_data?.court_type?.toUpperCase() === courtType.toUpperCase());
        }
        
        setPageAmount(Math.ceil(filtered.length / pageSize))
        const slices = [];
        for (let i = 0; i < filtered.length; i += pageSize) {
            slices.push(filtered.slice(i, i + pageSize));
        }
        setProcessesSubset(slices[page - 1] ?? []);
    }, [processes, polarity, searchValue, courtType, pageSize, page])

    const getCardIcon = (processes) => {
        if (processes.length === 0) return <Check style={{ color: colorGreen }} />
        return <ErrorOutline style={{ color: colorRed }} />;
    }

    const prettify = (court) => {
        if (court.toUpperCase() === 'CIVEL') return 'Cível';
        if (court.toUpperCase() === 'ESPECIAL CIVEL') return 'Especial cível';
        if (court.toUpperCase() === 'TRIBUTARIA') return 'Tributária';
        if (court.toUpperCase() === 'ESPECIAL TRIBUTARIA') return 'Especial tributária';
        return court
    }

    const consolidatedData = () => {
        if (consolidated.consolidated_data) {
            const { maximum_paginated_processes,
                    total_processes,
                    total_processes_as_author,
                    total_processes_as_defendant,
                    total_processes_as_neutral_party } = consolidated.consolidated_data;

            const paginated_total_processes_as_author = processes.filter(process => process.polarity === 'active').length;
            const paginated_total_processes_as_defendant = processes.filter(process => process.polarity === 'passive').length;
            const paginated_total_processes_as_neutral_party = processes.filter(process => process.polarity === 'neutral').length;

            const assertNumberOfProcesses = (localAmount, consolidatedAmount) => {
                if (consolidatedAmount && localAmount < consolidatedAmount) {
                    return consolidatedAmount;
                } else {
                    return localAmount;
                }
            }

            let total_processes_in_bureau = total_processes;
            if (!total_processes_in_bureau) {
                total_processes_in_bureau = (total_processes_as_author ?? 0) + (total_processes_as_defendant ?? 0) + (total_processes_as_neutral_party ?? 0)
            }
            return (
                <S.ConsolidatedWrapper>
                    {maximum_paginated_processes &&
                        <S.Subtitle>
                            <Tooltip title={`Os processos mapeados no momento da análise estão limitados a um valor fixo de unidades. ${processes.length < (maximum_paginated_processes < total_processes_in_bureau ? maximum_paginated_processes : total_processes_in_bureau) ? 
                                'Aguarde a finalização da paginação dos processos para analisar todos os resultados.' : ''}`} placement="right">
                                    Processos mapeados: {processes.length}/{maximum_paginated_processes < total_processes_in_bureau ? maximum_paginated_processes : total_processes_in_bureau}
                            </Tooltip>
                            {processes.length < (maximum_paginated_processes < total_processes_in_bureau ? maximum_paginated_processes : total_processes_in_bureau) &&
                                <S.Warn style={{color: colorYellow}}>
                                    <UpdateIcon style={{width: "24px", height: "24px", color: colorYellow}} />
                                    O mapeamento ainda está em curso
                                </S.Warn>
                            }
                        </S.Subtitle>
                    }
                    {total_processes &&
                        <S.Subtitle>
                            <S.Label>Total de processos encontrados:</S.Label> {total_processes}
                        </S.Subtitle>
                    }
                    {!total_processes &&
                        <S.Subtitle>
                            <S.Label>Total de processos encontrados:</S.Label> {
                            assertNumberOfProcesses(paginated_total_processes_as_author, total_processes_as_author) + 
                            assertNumberOfProcesses(paginated_total_processes_as_defendant, total_processes_as_defendant) + 
                            assertNumberOfProcesses(paginated_total_processes_as_neutral_party, total_processes_as_neutral_party)}
                        </S.Subtitle>
                    }
                    <S.ConsolidatedRow>
                        {assertNumberOfProcesses(paginated_total_processes_as_defendant, total_processes_as_defendant) > 0 ?
                            <S.PassiveHighlighter>
                                <S.Subtitle style={{color: "#a60505"}}><S.Label style={{color: "#a60505"}}>Menções em polo passivo:</S.Label> {assertNumberOfProcesses(paginated_total_processes_as_defendant, total_processes_as_defendant)}</S.Subtitle>
                            </S.PassiveHighlighter> : <></>
                        }
                        {assertNumberOfProcesses(paginated_total_processes_as_author, total_processes_as_author) > 0 ?
                            <S.Subtitle><S.Label>Menções em polo ativo:</S.Label> {assertNumberOfProcesses(paginated_total_processes_as_author, total_processes_as_author)}</S.Subtitle> : <></>
                        }
                        {/* {assertNumberOfProcesses(paginated_total_processes_as_neutral_party, total_processes_as_neutral_party) > 0 ?
                            <S.Subtitle><S.Label>Menções em polo neutro:</S.Label> {assertNumberOfProcesses(paginated_total_processes_as_neutral_party, total_processes_as_neutral_party)}</S.Subtitle> : <></>
                        } */}
                    </S.ConsolidatedRow>
                </S.ConsolidatedWrapper>
            );
        }
    }

    if (loading) {
        return (
            <S.Container>
                <S.Header>
                    <S.Title>Processos Judiciais</S.Title>
                </S.Header>
                <S.Subtitle style={{color: "#555", width: "100%", textAlign: "center"}}>Carregando processos...</S.Subtitle>
            </S.Container>
        )
    }

    if (error) {
        return (
            <S.Container>
                <S.Header>
                    <S.Title>Processos Judiciais</S.Title>
                </S.Header>
                <S.Subtitle style={{color: "#555", width: "100%", textAlign: "center"}}>Ocorreu um erro. Por favor, tente novamente.</S.Subtitle>
            </S.Container>
        )
    }

    return (
        <S.Container>
            <S.Header>
                <S.Title>Processos Judiciais</S.Title>
                {getCardIcon(processes)}
            </S.Header>
            {processes.length > 0 &&
                <S.ProcessesWrapper>
                    {consolidatedData()}
                    <S.Toolbar>
                        <S.InputWrapper>
                            <Input value={searchValue} setValue={setSearchValue} label="Pesquisar por tipo de processo"/>
                        </S.InputWrapper>
                        <S.SelectWrapper>
                            <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
                                <InputLabel id="polarity-select-small-label">Filtrar polaridade</InputLabel>
                                <Select labelId="polarity-select-small-label" value={polarity} label="Filtrar polaridade" onChange={(e) =>{
                                    setPolarity(e.target.value);
                                    setPage(1);
                                }}>
                                    <MenuItem value={null}>Todas</MenuItem>
                                    <MenuItem value={'passive'}>Passiva</MenuItem>
                                    <MenuItem value={'active'}>Ativa</MenuItem>
                                    <MenuItem value={'neutral'}>Neutra</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl sx={{ m: 1, minWidth: 256 }} size="small">
                                <InputLabel id="type-select-small-label">Ordenar por tipo de tribunal</InputLabel>
                                <Select labelId="type-select-small-label" value={courtType} label="Ordenar por tipo de tribunal" onChange={(e) =>{
                                    setCourtType(e.target.value);
                                    setPage(1);
                                }}>
                                    <MenuItem value={null}>Nenhuma</MenuItem>
                                    {courtOptions.map((option) => (
                                        <MenuItem value={option}>{prettify(option)}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </S.SelectWrapper>
                        <S.SelectWrapper>
                            <FormControl sx={{ m: 1, minWidth: 160 }} size="small">
                                <InputLabel id="page-size-select-small-label">Itens por página</InputLabel>
                                <Select labelId="page-size-select-small-label" value={pageSize} label="Itens por página" onChange={(e) =>{
                                    setPageSize(e.target.value);
                                    setPage(1);
                                }}>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={25}>25</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                    <MenuItem value={100}>100</MenuItem>
                                </Select>
                            </FormControl>
                        </S.SelectWrapper>
                        <Pagination count={pageAmount} value={page} onChange={(_, value) => {
                            setPage(value);
                        }} size="small"/>
                    </S.Toolbar>
                    {processesSubset.length > 0 &&
                        <S.TableWrapper>
                            <ProcessesTable processes_data={processesSubset} />
                        </S.TableWrapper>
                    }
                </S.ProcessesWrapper>
            }
        </S.Container>
    )
}