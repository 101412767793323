import React from "react";	
import NumberFormat from 'react-number-format';
import TextField from "@material-ui/core/TextField";



function GeoPointTextField (props){
    const {value, error, valueChangeFunction} = props

    const handleChangeValue = (changedValue, valueType) => {
        let newValue = value

        if (valueType === "latitude"){
            newValue[1] = changedValue
        }
        if (valueType === "longitude"){
            newValue[0] = changedValue
        }

        valueChangeFunction(newValue)
        return
    }


    return (
        <div className="internalCardContainer" style={{display:"flex"}}>
            <div style={{width:"100%", display:"flex", flexDirection: "row"}}>
                <NumberFormat
                        customInput={TextField}
                        type="text"
                        size="small"
                        decimalSeparator={","}
                        style={{textAlign:"center", width:"47.5%", marginRight:"auto"}}
                        error={error}
                        label={"Latitude"}
                        variant={"outlined"}
                        value={value[1]}
                        isAllowed= {(values) => {
                            const {floatValue} = values;
                            return (floatValue <= 99.999999 && floatValue >= -99.999999) || floatValue == null;
                        }}
                        onValueChange={(e) => handleChangeValue(e.floatValue, "latitude")}
                /> 
                <NumberFormat
                    customInput={TextField}
                    type="text"
                    size="small"
                    decimalSeparator={","}
                    style={{textAlign:"center", width:"47.5%"}}
                    error={error}
                    label={"Longitude"}
                    variant={"outlined"}
                    value={value[0]}
                    isAllowed= {(values) => {
                        const {floatValue} = values;
                        return (floatValue <= 999.999999 && floatValue >= -999.999999) || floatValue == null;
                    }}
                    onValueChange={(e) => handleChangeValue(e.floatValue, "longitude")}
                /> 
            </div>
        </div>
    )
}



export default GeoPointTextField
