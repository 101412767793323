import React, {useState} from "react";
import OFACTable from "./OFACTable/OFACTable";
import Collapse from "@material-ui/core/Collapse";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import IconButton from "@material-ui/core/IconButton";
import {getCardIcon} from "../../../utils/utils"

function OFACCard(props) {

    const [expanded, setExpanded] = useState(false);
    const [firstTime, setFirstTime] = useState(true);

    const {ofac, isPDF} = props

    let initialExpand =  isPDF ? isPDF : ofac.flag === "negative"

    if (initialExpand !== expanded && firstTime===true){
        setExpanded(initialExpand);
    }
    const handleExpand = (event) => {
        event.stopPropagation();
        setFirstTime(false)
        setExpanded(!expanded);
    };

    return (
        <div className="analysisCard">
            <div style={{display:"flex"}}>
                <div 
                    className={["blueText", "subtitleSize", "bold"].join(" ")}
                    style={{margin:"auto 0px"}}
                >
                    OFAC
                </div>
                <div style={{margin:"auto 10px"}}>
                    {getCardIcon(ofac.flag)}
                </div>
                {ofac.data.length === 0 ? null :
                <IconButton style={{marginLeft: "auto"}} onClick={handleExpand}>
                    {expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </IconButton>             
                }                    
            </div>
            {ofac.data.length === 0 ? null :
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <div 
                    className={["normalText", "normalMediumSize", "internalCardContainer"].join(" ")}
                >
                    <OFACTable ofac_data={ofac.data}/>
                </div>
            </Collapse> 
            }     
        </div>
        );
}

export default OFACCard