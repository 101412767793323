import React, { useReducer } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";
import axios from "axios"
import { CircularProgress } from '@material-ui/core';
import ConfirmationContent from "./ConfirmationContent"
import {decisionReducer} from "../../../../Utils/requestUtils"


function ConfirmationDialog(props) {
    const history = useHistory()

    const [dialogState, dispatchDialogState] = useReducer(
        decisionReducer,
        {isLoading: false, isError: false, finished:false}
    )

    const handleFinish = (analysis_status) =>{
        dispatchDialogState({type:"send_request_init"})
        let payload={
            analysis_status: analysis_status,
        }
        if (props.observation != null && props.observation !== ""){
            payload ={...payload, observation: props.observation}
        }
        setTimeout(function () {
            axios.put('/dash/card_order/order/' + props.order.order_key, payload).then(response => {
                dispatchDialogState({type:"send_request_success"})
              }).catch(error => { 
                if ((error.response || {}).status === 403) dispatchDialogState({type: "send_request_failure_403"})
                else if ((error.response || {}).status === 409) dispatchDialogState({type: "send_request_failure_409"})  
                else dispatchDialogState({type: "send_request_failure"})    
              });
        }, 1000);
    }

    if (dialogState.finished && dialogState.isLoading){
        return (
            <Dialog 
                open={props.open} 
                onClose={() => history.push("/orders-inbox")} 
                keepMounted={false}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle style={{paddingBottom: "0px"}} className={["blueText", "normalMediumSize"].join(" ")}>Finalizar alerta</DialogTitle>
                <DialogContent>
                    <div style={{display:"flex", width: "310px", height:"217px"}}>
                        <CircularProgress style={{margin:"auto"}} />
                    </div>
                </DialogContent>
            </Dialog>

        )
    }
    else if (dialogState.finished){
        let confirmationData = {    
            message: dialogState.message,
            success: !dialogState.isError
        }
        return (
            <Dialog 
                open={props.open} 
                onClose={() => history.push("/orders-inbox")} 
                keepMounted={false}
                disableBackdropClick
            >
                <DialogTitle style={{paddingBottom: "0px"}} className={["blueText", "normalMediumSize"].join(" ")}>Confirmação de Revisão</DialogTitle>
                <DialogContent>
                    <ConfirmationContent onClose={() => history.push("/orders-inbox")} data={confirmationData} />
                </DialogContent>
            </Dialog>
        )

    }
    return (
        <Dialog 
        open={props.open} 
        onClose={props.onClose} 
        keepMounted={false}
        >
            <DialogTitle style={{paddingBottom: "0px"}} className={["blueText", "normalMediumSize"].join(" ")}>Finalizar alerta</DialogTitle>
            <DialogContent>
                <div>
                    <div>
                        <TextField
                            fullWidth
                            multiline
                            variant="outlined"
                            margin="dense"
                            rows="5"
                            defaultValue={props.observation}
                            disabled={true}
                        />
                    </div>
                    <div className="internalCardContainer" style={{display:"flex", justifyContent:"center"}}>
                        {props.isApproved === true ?
                        <div 
                            className={["button", "approved", "normalText", "normalMediumSize"].join(" ")}
                            onClick={() => handleFinish("manually_approved")}
                        >
                            Aprovar
                        </div> :
                        props.isApproved === false ?
                        <div 
                            className={["button", "reproved", "normalText", "normalMediumSize"].join(" ")}
                            onClick={() => handleFinish("manually_reproved")}
                        >
                            Reprovar
                        </div> : null
                        }
                        <div 
                            className={["button", "cancel", "normalText", "normalMediumSize"].join(" ")}
                            onClick={props.onClose}
                        >
                            Cancelar 
                        </div>
                  </div>
              </div>
          </DialogContent>
        </Dialog>
    )
}

export default ConfirmationDialog;