import React, {useEffect, useReducer, useCallback } from "react";
import { dataFetchReducer } from "../../Utils/requestUtils"
import Pagination from "@material-ui/lab/Pagination";
import { CircularProgress } from '@material-ui/core';
import axios from "axios";
import NaturalPersonSearchTable from "./SearchHistoricTables/NaturalPersonSearchTable";

function SearchNaturalPersons(props) {

    const { search_term, pageParams, setPageParams } = props

    const [pageState, dispatchPageState] = useReducer(
        dataFetchReducer,
        {fetchedData: null, isLoading: true, isError: false} 
    )

    const doRequest = useCallback(
        (payload) => {
            dispatchPageState({type: "data_fetch_init"})
            axios.get("/dash/onboarding/natural_persons/search_any", { 
                params: payload
            }).then(response=>{
                dispatchPageState({
                    type: "data_fetch_success",
                    payload: response.data
                })          
            }).catch(error => {
                if ((error.response || {}).status === 403) dispatchPageState({type: "data_fetch_failure_403"})
                else if ((error.response || {}).status === 404) dispatchPageState({type: "data_fetch_failure_404"})  
                else dispatchPageState({type: "data_fetch_failure"})    
            })
        },[]
    )

    useEffect(() => {
        let payload = {
            "page_number": pageParams.page - 1,
            "page_rows": 25,
            search_term
        }
        doRequest(payload)
    },[doRequest, pageParams, search_term])
    
    const handleChangePage = (event, page) => {
		if (pageState.page === page) {
			return
		}
		setPageParams({page: page})
	}

	const contentContainer = (search_any_results_list) => {

        let search_any_results_list_filtered = search_any_results_list.filter(search_any_result => search_any_result['data'].length !== 0)
        let number_of_natural_persons = search_any_results_list_filtered.length

		if (number_of_natural_persons === 0) {
            return (
                <div className='emptyContainer'>
                    <p className={["labelText", "normalSize"].join(" ")}>
                        Nenhuma pessoa relacionada à busca por {search_term}.
                    </p>
                    <p className={["labelText", "normalSmallSize"].join(" ")} style={{padding: "0 140px"}}>
                        Certifique-se que você realizou a busca pelo número do documento (CPF).
                    </p>
                </div>
            )
		}
        else {
            return (
                <div className='cardContainer'>
                    {search_any_results_list_filtered.map((search_any_result, index) => {
                        return (
                            <>
                                {search_any_result['company_name'] &&
                                    <div key={index} className={["blueText", "normalSmallSize"].join(" ")} style={{marginTop: "20px", marginBottom: "20px"}}>
                                        Resultados para o Cliente: {search_any_result['company_name']}
                                    </div>
                                }
                                <NaturalPersonSearchTable historic={search_any_result['data']} />
                                <div style={{marginTop: "10px"}}>
                                    <Pagination
                                        className="pagination" 
                                        page={pageParams.page} 
                                        count={parseInt(search_any_result["number_of_pages"])}
                                        onChange={(e, page) => handleChangePage(e, page)}
                                    />
                                </div>
                            </>
                        )
                    })}
                </div>
            )
		}
	}
	
	if (pageState.fetchedData) {
		return (
			<div style={{ width: "auto", paddingBottom: "30px" }}>
				<div>{contentContainer(pageState.fetchedData['search_any_results'])}</div>
				<div style={{marginTop: "10px"}}>
                    <Pagination 
                        className="pagination" 
                        page={pageParams.page} 
                        count={parseInt(pageState.fetchedData["number_of_pages"])}
                        onChange={(e, page) => handleChangePage(e, page)}
                    />
				</div>
			</div>
		);
	}
    else if (pageState.isError){
		return (
			<div className='emptyContainer'>
                <p className={["blueText", "normalSmallSize"].join(" ")} style={{margin:"auto", textAlign:"center"}}>
                    {pageState.errorMessage}
                </p>
            </div>
		)
	}
	else if (pageState.isLoading) {
		return(
			<div style={{ width: "auto" }}>
				<div className='circularProgressCenter'>
					<CircularProgress />
				</div>
			</div>
		)
    }
    else return null
	
}

export default SearchNaturalPersons