import React from "react";

function NotAppliedQuizScoreBox() {
    
    return (
        <div className="analysisCard" style={{ display: "flex", flexDirection: "column", flexGrow:"1"}}>
            <div className={["blueText", "subtitleSize"].join(" ")}>Quiz</div>
            <div style={{ display: 'flex', flexDirection:"row", alignItems:"center"}}>
                <div 
                    className={["normalText", "normalMediumSize"].join(" ")} 
                    style={{marginRight: '10px', display: 'flex', flexDirection:"row", justifyContent: "center"}}
                >
                    Quiz:
                </div>
                <div 
                    className={["labelText", "normalMediumSize"].join(" ")} 
                >
                    Não está disponível
                </div>
            </div>
        </div>
    )
}

export default NotAppliedQuizScoreBox;