import React, {useState, useEffect} from 'react'
import { useTranslation } from "react-i18next";
import {GetAlertsComponent, comparisionOptions, translateType,setObjectByPath,validateConstantValues} from "./utils"
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from "@material-ui/core/Tooltip";
import _ from "lodash"
import HelpIcon from "@material-ui/icons/Help";
import Checkbox from '@material-ui/core/Checkbox';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const theme = createMuiTheme({
    palette:{
        primary: {
            main:"#0b1f82"
        },
    }
  });

export function InBetweenBox (props) {
    const { t } = useTranslation();
    let {node} = props
    let {read_only} = props
    let {handleConfigurationOpen} = props
    let {handleNodeRemove} = props

    return (
        <div>
            {!read_only ?
                <div style={{display:"flex", alignItems: "center"}}>
                    <div 
                        style={{display:"flex", width:"100%", cursor:"pointer", margin: "0px"}} 
                        onClick={() => handleConfigurationOpen(node)}
                    >
                        <div style={{display: "flex",flexDirection: "column",margin: "auto 0px"}}>
                            <div className={["normalText","normalMediumSize"].join(" ")}>
                                {t(node.node.description)}
                            </div>
                            <div className={["normalText","normalSmallSize"].join(" ")}>
                                {node.node.properties.greater_than_equal === true && node.node.properties.less_than_equal === true
                                ?
                                t("Considerar igualdades nas duas extremidades")
                                :
                                node.node.properties.greater_than_equal === true
                                ?
                                t("Considerar valores maiores ou iguais")
                                :
                                node.node.properties.less_than_equal === true
                                ?
                                t("Considerar valores menores ou iguais")
                                :
                                t("Desconsiderar igualdades")
                                }
                            </div>
                        </div>
                        {(node.node.alerts || []).length > 0?
                        <GetAlertsComponent alerts={node.node.alerts} />  : null}       
                    </div>
                    <IconButton style={{width: 32, height: 32}} aria-label="duplicate" onClick={() => props.handleNodeDuplication(node)}>
                        <ContentCopyIcon style={{width: 20, height: 20}} />
                    </IconButton>
                    <IconButton style={{width: 32, height: 32}} aria-label="close" onClick={() => handleNodeRemove(node)}>
                        <CloseIcon style={{width: 20, height: 20}} />
                    </IconButton>
                </div>
                :
                <div style={{display:"flex"}}>
                    <div 
                        style={{display:"flex", width:"100%", cursor:"pointer", margin: "0px"}} 
                        onClick={() => handleConfigurationOpen(node)}
                    >
                        <div style={{display: "flex",flexDirection: "column",margin: "auto 0px"}}>
                            <div className={["normalText","normalMediumSize"].join(" ")}>
                                {t(node.node.description)}
                            </div>
                            <div className={["normalText","normalSmallSize"].join(" ")}>
                                {node.node.properties.greater_than_equal === true && node.node.properties.less_than_equal === true
                                ?
                                t("Considerar igualdades nas duas extremidades")
                                :
                                node.node.properties.greater_than_equal === true
                                ?
                                t("Considerar valores maiores ou iguais")
                                :
                                node.node.properties.less_than_equal === true
                                ?
                                t("Considerar valores menores ou iguais")
                                :
                                t("Desconsiderar igualdades")
                                }
                            </div>
                        </div> 
                    </div>
                </div>
            }
        </div>
    )
}

export function InBetweenConfiguration(props){
    const { t } = useTranslation();
    let {node} = props
    let {read_only} = props
    let {handleNodeChange} = props
    let {handleConfigurationClose} = props

    const [nodeState, setNodeState] = useState(node)

    useEffect(()=>{
        setNodeState(node)
    },[node])

    const handleNodeStateChange = (obj_key,value) => {
        let new_node = _.cloneDeep(nodeState)
        setObjectByPath(new_node.node,obj_key,value)
        setNodeState(new_node)
    }

    const handleSave = () => {
        handleConfigurationClose()
        handleNodeChange(nodeState)
    }

    const validateAllValues = () => {
        return true
    }

    return(
        <div style={{flexGrow:"1", padding: "20px", display:"flex", flexDirection:"column"}}>
            <div style={{display: "flex"}}>
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>{t(node.node.description)}</span>
                <Tooltip title={<div className="tooltipText"><ul>{node.node.helperText.map((help,index) => (<li key={index}>{t(help)}</li>))}</ul></div>}>
                    <HelpIcon style={{fontSize: "14px", display:"flex", margin:"auto 5px"}}/>
                </Tooltip>
            </div>
            <div className="internalCardContainer" style={{display:"flex", flexDirection: "column"}}>
                <ThemeProvider theme={theme}>
                    <div>
                        <div style={{display:"flex", justifyContent:"space-evenly"}}>
                            <div style={{display:"flex"}}>
                                <Checkbox
                                    onChange={(e) => handleNodeStateChange("properties.greater_than_equal", e.target.checked)}
                                    checked={nodeState.node.properties.greater_than_equal}
                                    color="primary"
                                />
                                <div>
                                    <p 
                                        className={["normalText", "normalMediumSize"].join(" ")} 
                                    >   
                                        {t("Considerar Maior OU Igual")}
                                    </p>
                                </div>
                            </div>
                            <div style={{display:"flex"}}>
                                <Checkbox
                                    onChange={(e) => handleNodeStateChange("properties.less_than_equal", e.target.checked)}
                                    checked={nodeState.node.properties.less_than_equal}
                                    color="primary"
                                />
                            
                                <div>
                                    <p 
                                        className={["normalText", "normalMediumSize"].join(" ")} 
                                    >   
                                        {t("Considerar Menor OU Igual")}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </ThemeProvider>        
            </div>
            <div style={{display:"flex", margin: "auto auto 5px auto"}}>
                <div
                    className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")}
                    style={{width:"120px"}}
                    onClick={handleConfigurationClose}
                >
                    {read_only ? "X" : t("discard")}
                </div>
                {!read_only && 
                <div
                    className={
                        validateAllValues()
                        ?
                        ["button", "standard", "normalText", "normalMediumSize"].join(" ")
                        :
                        ["button", "standard", "normalText", "normalMediumSize","disabled"].join(" ")}
                    style={{width:"120px"}}
                    onClick={validateAllValues() ? handleSave : null}
                >
                    {t("save")}
                </div>}   
            </div>
        </div>
    )
}

export class InBetweenBoxClass {
    constructor(rulesContext){
        this.rulesContext = rulesContext
    }
    validate(node){
        let put_error = false
        let alerts = []
        let filteredOption = comparisionOptions.filter(option => option.value === "in_between")[0]
        let typeString = filteredOption.types.reduce((acc, type, index, arr) => {
            let endString = index === arr.length -1 ? "." : index === arr.length -2 ? " ou " : ", "
            return acc.concat(translateType(type) + endString)
        }, "")

        //Validate if node has 2 children
        if((node.node.children || []).length !== 3){
            put_error = true
            alerts.push("Essa comparação sempre deve possuir 3 filhos")
        }
        //Validate if children has same type
        else{
            let first_type = node.node.children[0].instance.getType(node.node.children[0])
            let second_type = node.node.children[1].instance.getType(node.node.children[1])
            let third_type = node.node.children[2].instance.getType(node.node.children[2])
            if (first_type !== second_type || first_type !==  third_type){
                put_error = true
                alerts.push("Todos os filhos devem ter os mesmo tipo. Atualmente os filhos são, respectivamente, " + translateType(first_type) + ", " + translateType(second_type) + " e " + translateType(third_type))
            }
            else{
                if(!filteredOption.types.includes(first_type)){
                    put_error = true
                    alerts.push("Os filhos devem ser "+typeString+" Atualmente os filhos são " + translateType(first_type))
                }
            }
        }
        if (!validateConstantValues("bool",node.node.properties.greater_than_equal)){
            put_error = true
            alerts.push("A propriedade 'Considerar Maior OU Igual' deve ser um 'Binário' válido")
        }
        if (!validateConstantValues("bool",node.node.properties.less_than_equal)){
            put_error = true
            alerts.push("A propriedade 'Considerar Menor OU Igual' deve ser um 'Binário' válido")
        }
        //Validate if parent is valid
        if (node.parentNode != null) {
            if(node.parentNode.on_error){
                put_error = true
                alerts.push("O nó superior está inválido")
            }
        }
        return {validated: !put_error, alerts: alerts}
    }

    getType(node){
        return "bool"
    }
}