import React, {useState, useEffect} from 'react'
import { useTranslation } from "react-i18next";
import {GetAlertsComponent, validateConstantValues, formatAsMoney, colorVelvet} from "./utils"
import TextField from "@material-ui/core/TextField";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CurrencyInput from 'react-currency-input';
import DeleteIcon from '@material-ui/icons/Delete';


export function ActionCreditProposalBox (props) {
    const {t} = useTranslation()
    let {node, read_only, handleConfigurationOpen, handleNodeRemove} = props
    
    const interest_rate_unit = {
        // annual_interest_rate: t('interest_rate_unit_year'),
        monthly_interest_rate: t('interest_rate_unit_month'),
        cdi_percentage: t('interest_rate_unit_cdi'),
    }

    return (
        <div>
                <div style={{display:"flex", alignItems: "center"}}>
                    <div 
                        style={{display:"flex", width:"100%", cursor:"pointer", margin: "0px"}} 
                        onClick={() => handleConfigurationOpen(node)}
                    >
                        <div style={{display: "flex",flexDirection: "column",margin: "auto 0" }}>
                            <div className={["normalText","normalMediumSize"].join(" ")}>
                                {t(node.node.description)}
                            </div>
                            <div style={{display:'flex'}}>
                            {!!node.node.properties.credit_amount_value &&
                                <div className={["normalText","normalSmallSize"].join(" ")} style={{marginRight: '20px'}} >
                                    {`${t("credit_amount")}: ${formatAsMoney(node.node.properties.credit_amount_value)}`}
                                </div>}
                                {!!node.node.properties.interest_rates[0].interest_rate_value &&
                                <div className={["normalText","normalSmallSize"].join(" ")}style={{marginRight: '20px', display: 'flex'}}>
                                    {`${t("rates")}: `}
                                    {node.node.properties.interest_rates.map(interest_rate => (
                                        <div style={{margin: '0 10px'}}>
                                            {interest_rate.interest_rate_value}
                                            {interest_rate_unit[interest_rate.interest_rate_type]}
                                        </div>
                                    ))}
                                </div>}
                                {node.node.properties.number_of_installments_value &&
                                <div className={["normalText","normalSmallSize"].join(" ")}style={{marginRight: '10px'}}>
                                    {`${t(node.node.properties.number_of_installments_value)} ${t("installments")}`}
                                </div>}

                            </div>
                        </div>
                        {!read_only && (node.node.alerts || []).length > 0 ?
                        <GetAlertsComponent alerts={node.node.alerts} />  : null}  
                    </div>
                    {!read_only && <><IconButton style={{width: 32, height: 32}} aria-label="duplicate" onClick={() => props.handleNodeDuplication(node)}>
                        <ContentCopyIcon style={{width: 20, height: 20}} />
                    </IconButton>
                    <IconButton style={{width: 32, height: 32}} aria-label="close" onClick={() => handleNodeRemove(node)}>
                        <CloseIcon style={{width: 20, height: 20}} />
                    </IconButton></>}
                </div>
        </div>
    )
}

export function ActionCreditProposalConfiguration(props){
    const { t } = useTranslation();
    let {node, read_only, handleNodeChange, handleConfigurationClose} = props

    let initial_credit_amount_value = node.node.properties.credit_amount_value ? formatAsMoney(node.node.properties.credit_amount_value) : 0
    const [nodeState, setNodeState] = useState(node)
    const [moneyValue, setMoneyValue] = useState(initial_credit_amount_value)

    useEffect(()=>{
        setNodeState(node)
    },[node])

    const handleNodeStateChange = (obj_key, value) => {
        let new_node = {...nodeState}
        if(obj_key === 'credit_amount_value'){
            setMoneyValue(value)
            new_node.node.properties[obj_key] = value
        }
        else {
            new_node.node.properties[obj_key] = Number(value)
        }
        setNodeState(new_node)
    }

    const handleNodeStateChangeInterestRate = (obj_key, value, index) => {
        let new_node = {...nodeState}
        new_node.node.properties.interest_rates[index]['interest_rate_type']=obj_key
        new_node.node.properties.interest_rates[index]['interest_rate_value']=Number(value)
        setNodeState(new_node)
    }

    const handleRemove = (index) => {
        let new_node = {...nodeState}
        new_node.node.properties.interest_rates.splice(index,1)
        setNodeState(new_node)
    }

    const handleSave = () => {
        handleConfigurationClose()
        let new_node = {...nodeState}
        if (!(typeof new_node.node.properties.credit_amount_value === 'number')){
            new_node.node.properties.credit_amount_value = Number(new_node.node.properties.credit_amount_value.replace(/[.,R$ ]/g, ''))
        }
        handleNodeChange(nodeState)
    }

    const validateAllValues = () => {
        return (
            (!!nodeState.node.properties.interest_rates && nodeState.node.properties.interest_rates.length >0) && 
            !!nodeState.node.properties.credit_amount_value &&
            (!!nodeState.node.properties.number_of_installments_value && nodeState.node.properties.number_of_installments_value >0)
        )
    }

    return(
        <div style={{flexGrow:"1", padding: "20px", display:"flex", flexDirection:"column"}}>
            <div style={{display: "flex"}}>
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>{t(node.node.description)}</span>
                <Tooltip title={<div className="tooltipText"><ul>{node.node.helperText.map((help,index) => (<li key={index}>{t(help)}</li>))}</ul></div>}>
                    <HelpIcon style={{fontSize: "14px", display:"flex", margin:"auto 5px"}}/>
                </Tooltip>
            </div>
            <div className="filterDescriptionCredit" style={{ margin: '20px 0 10px 30px'}}>{t("credit_amount")}</div>
                <CurrencyInput 
                    precision="2"
                    decimalSeparator="," 
                    thousandSeparator="." 
                    prefix="R$ "
                    size="small" 
                    name="amount"
                    className={!validateConstantValues("number", nodeState.node.properties.credit_amount_value) ? "filterCurrencyCreditError" : "filterCurrencyCredit" }
                    value={moneyValue}
                    onChange={(e) => handleNodeStateChange("credit_amount_value", e)}
                    style={{ width: 'clamp(110px, 170px, 300px)' , marginLeft: '30px'}}
                /> 
                {node.node.properties.interest_rates.map((interest_rate, index) => (
                        <>
                            <div className="filterDescriptionCredit" style={{ margin: '20px 0 5px 30px'}}>{t(interest_rate.interest_rate_type)}</div>
                            <div style={{display: 'flex'}}>
                                <TextField 
                                    type="number"
                                    style={{textAlign:"center", width: '60%', margin:"3px auto", marginLeft: nodeState.node.properties.interest_rates.length > 1 ? 'auto' : '30px'}}
                                    className="filterTextbox" 
                                    size="small" fullWidth 
                                    value={nodeState.node.properties.interest_rates[index].interest_rate_value}
                                    onChange={(e) => handleNodeStateChangeInterestRate(interest_rate.interest_rate_type, e.target.value, index)}
                                    InputProps={{
                                        readOnly: read_only
                                    }}
                                />   
                                {!read_only && nodeState.node.properties.interest_rates.length > 1 &&
                                    <DeleteIcon onClick={() => handleRemove(index)} style={{fill:colorVelvet, margin:"auto 30px auto 0", cursor: "pointer"}}/>
                                    }
                            </div>
                        </>
                    ))
                }
            <div className="filterDescriptionCredit" style={{ margin: '20px 0 5px 30px'}}>{t('number_of_installments')}</div>
                    <TextField 
                        type="number"
                        style={{textAlign:"left", width:"170px", margin:"3px 20px 3px 30px"}}
                        className="filterTextbox"  
                        size="small" fullWidth 
                        value={nodeState.node.properties.number_of_installments_value}
                        onChange={(e) => handleNodeStateChange('number_of_installments_value', e.target.value)}
                        InputProps={{
                            readOnly: read_only
                        }}
                    />   
                <div style={{display:"flex", margin: "20px auto 5px auto"}}>
                    <div
                        className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")}
                        style={{width:"120px"}}
                        onClick={handleConfigurationClose}
                    >
                        {read_only ? "X" : t("discard")}
                    </div>
                    {!read_only && 
                    <div
                        className={
                            validateAllValues()
                            ?
                            ["button", "standard", "normalText", "normalMediumSize"].join(" ")
                            :
                            ["button", "standard", "normalText", "normalMediumSize","disabled"].join(" ")}
                        style={{width:"120px"}}
                        onClick={validateAllValues() ? handleSave : null}
                    >
                        {t("save")}
                    </div>}   
                </div>
        </div>
    )
}

export class ActionCreditProposalBoxClass {
    constructor(rulesContext){
        this.rulesContext = rulesContext
    }
    
    validate(node){
        let put_error = false
        let alerts = []

        if((node.node.children || []).length !== 0){
            put_error = true
            alerts.push("box_validation_no_children_allowed")
        }
        //Validate value is not length 0
        node.node.properties.interest_rates.map(interest_rate => {
            if(!validateConstantValues("number", interest_rate.interest_rate_value)){
                put_error = true
                if (alerts.indexOf ("box_validation_no_null_value_allowed") === -1){
                    alerts.push("box_validation_no_null_value_allowed")
                }
            }
            return interest_rate
        })
        if(!validateConstantValues("number", node.node.properties.credit_amount_value)){
            put_error = true
            alerts.push("box_validation_no_null_value_allowed")
        }
        if(!validateConstantValues("number", node.node.properties.number_of_installments_value)){
            put_error = true
            // alerts.push("box_validation_no_null_value_allowed")
            if (alerts.indexOf ("box_validation_no_null_value_allowed") === -1){
                alerts.push("box_validation_no_null_value_allowed")
            }
        }
        //Validate if parent is valid
        if (node.parentNode != null) {
            if(node.parentNode.on_error){
                put_error = true
                // alerts.push("box_validation_invalid_parent_node")
                if (alerts.indexOf ("box_validation_no_null_value_allowed") === -1){
                    alerts.push("box_validation_no_null_value_allowed")
                }
            }
        }
        return {validated: !put_error, alerts: alerts}
    }

    getType(node){
        return 'void'
    }
}