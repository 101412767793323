import React from 'react';
export default class FileUploadToBase64 extends React.Component {

    state = {
      files: [],
    };

  handleChange(e) {
    e.persist()
    let files = e.target.files;

    var allFiles = [];
    for (var i = 0; i < files.length; i++) {
      let file = files[i];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        
        if(file.size > this.props.fileSize){
          e.target.value = ""
          alert(this.props.maxFileSizeAlert)
        }
        else 
        {
          let base64 = reader.result
          base64 = base64.split("base64,")
          base64 = base64[1]

          let fileInfo = {
            name: file.name,
            type: file.type,
            base64: base64,
          };
  
          allFiles.push(fileInfo);
  
          if(allFiles.length === files.length){
            if(this.props.multiple) this.props.onDone(allFiles);
            else this.props.onDone(allFiles[0]);
          }
        }
      } 
    } 
  }

  render() {
    return (
        <input
        type="file"
        accept={this.props.accept}
        onChange={ this.handleChange.bind(this) }
        multiple={ this.props.multiple } 
        />      
    );
  }
}

FileUploadToBase64.defaultProps = {
  multiple: false,
};