import "./Modal.css"
import React from 'react'

function Modal (props){

    const handleClick = (event, canClose) => {
        event.stopPropagation()
        if(canClose) props.closeModal()
    }

    return (
        <div className="Overlay" onClick={props.closeModal}>
            <div className={`Modal ${props.class_size}` }onClick={handleClick}>
                <span className="Modal__close" onClick={(event) => handleClick(event, true)}> + </span>
                <div className="Modal__title">{props.title}</div>
                <div className="Modal__body">{props.children}</div>
            </div>
        </div>
    )
}

export default Modal