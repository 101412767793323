import React, { useState} from 'react'
import moment from "moment"
import {titleCase, checkForGeoShapeField } from "../../utils/utils"
import InsertCSVDialog from './InsertCSVDialog/InsertCSVDialog';
import DeleteCSVDialog from './DeleteCSVDialog/DeleteCSVDialog'
import ExportCSVnoFilters from '../../../Utils/ExportCSV/ExportCSVnoFilters';
import DeleteManyDialog from './DeleteManyDialog/DeleteManyDialog';
import GeoShapeDialog from './GeoShapeDialog';
import Tooltip from '@material-ui/core/Tooltip';
import LanguageIcon from '@mui/icons-material/Language';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

function ListOverviewCard(props){
    let {userData, companyKey, list_key, permissions} = props

    let deleteMany = props.list.fields.length === 1 && props.list.fields[0].type.enum === "string"

    let hasGeoShapeField = checkForGeoShapeField(props.list.fields) 

    const[openCSVInsertDialog,setOpenCSVInsertDialog] = useState(false)

    const[openCSVDeleteDialog,setOpenCSVDeleteDialog] = useState(false)

    const[openDeleteManyDialog,setOpenDeleteManyDialog] = useState(false)

    const[openGeoShapeDialog, setOpenGeoShapeDialog] = useState(false)

    const [ dialogTitle, setDialogTitle ] = useState("")

    const handleInsertCSV = (event) =>{
        hasGeoShapeField ? setOpenGeoShapeDialog(true) : setOpenCSVInsertDialog(true)
        setDialogTitle(event.target.innerText)
    }

    const handleDeleteCSV = (event) =>{
        hasGeoShapeField ? setOpenGeoShapeDialog(true) : setOpenCSVDeleteDialog(true)
        setDialogTitle(event.target.innerText)
    }

    return(
        <div style={{display: "flex", justifyContent: "space-between"}}>
            <div style={{display: "flex", flexDirection: "column", width: "75%"}}>
                <div className={["blueText", "titleSize"].join(" ")} style={{margin:"5px 0px"}}>
                        {"Lista - " + titleCase(props.list.name, "-")}
                </div>
                {props.list.created_at ? 
                    <Tooltip placement='right' title="Data de criação">
                        <p style={{margin: 0, marginTop: 8, width: 'fit-content', padding: 0, display: 'flex', alignItems: 'center', gap: 4, marginBottom: 4}} className="labelText"><AccessTimeIcon style={{width: 16, height: 16}} />{moment(props.list.created_at).format("DD/MM/YYYY - HH:mm")}</p>
                    </Tooltip>
                    : <></>}
                    {userData.roles.includes("view_regions") ?
                        (props.list.region === 'sa_east_1' ?
                            <Tooltip placement='right' title="Região">
                                <p style={{margin: 0, width: 'fit-content', padding: 0, display: 'flex', alignItems: 'center', gap: 4}} className="labelText"><LanguageIcon style={{width: 16, height: 16}} /> América do Sul</p>
                            </Tooltip> :
                            <Tooltip placement='right' title="Região">
                                <p style={{margin: 0, width: 'fit-content', padding: 0, display: 'flex', alignItems: 'center', gap: 4}} className="labelText"><LanguageIcon style={{width: 16, height: 16}} /> América do Norte</p>
                            </Tooltip>)
                         : <></>}
                {(props.list.observation) ? 
                <div style={{display: "inline", marginTop: "5px", marginBottom: "5px", marginLeft:"0px", lineHeight: "20px"}}>
                    <span className={["labelText", props.size, "labelSpace"].join(" ")} style={{verticalAlign: "middle", marginLeft:"0px"}}>
                        {props.list.observation || "-"}
                    </span>
                </div> : null}
                </div>
                <div > 
                    {permissions.read_item&&
                        <ExportCSVnoFilters
                            csv_endpoint={'/dash/lists/list/' + list_key + '/items/csv'}
                            file_name = "List_"
                            hasGeoShapeField = {hasGeoShapeField}
                            /> 
                    }
                    {permissions.create_item&&
                        <Tooltip title={<ul className="tooltipText" style={{marginLeft: '-40px',listStyle: 'none', textAlign: 'center'}}>
                            <li> {"- Utilizar ';' (ponto e vírgula) como separador,"}</li>
                            <li> - Formatar os dados conforme padronização descrita na documentação da API.</li>
                        </ul>}>
                            <div 
                                style={{ padding: "5px 30px", margin:"5px" }}
                                className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                                onClick={handleInsertCSV}
                            >
                                Inserir Itens via .CSV
                            </div>
                        </Tooltip>}
                    {permissions.delete_item&&
                        <Tooltip title={<ul className="tooltipText" style={{marginLeft: '-40px',listStyle: 'none', textAlign: 'center'}}>
                            <li> {"- Utilizar ';' (ponto e vírgula) como separador,"}</li>
                            <li> - Valor dos dados deve ser exatamente igual aos dados exportados via CSV.</li>
                        </ul>}>
                            <div 
                                style={{ padding: "4px 30px", margin:"5px" }}
                                className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")}
                                onClick={handleDeleteCSV}
                            >
                                Excluir Itens via .CSV
                            </div>
                        </Tooltip>}
                    {permissions.delete_item&&deleteMany&&
                        <Tooltip title={<ul className="tooltipText" style={{marginLeft: '-40px',listStyle: 'none', textAlign: 'center'}}>
                            <li> Permite excluir vários itens de uma única vez. </li>
                            <li> {"- Incluir itens separados por ; (ponto e vírgula)"}</li>
                            <li> - Valor dos dados deve ser exatamente igual aos dados exportados via CSV.</li>
                        </ul>}>
                            <div 
                                style={{ padding: "4px 30px", margin:"5px" }}
                                className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")}
                                onClick={() => setOpenDeleteManyDialog(true)}
                            >
                                Excluir Item(s)
                            </div>
                        </Tooltip>}
                </div>
                <GeoShapeDialog
                    open={openGeoShapeDialog}
                    onClose={() => setOpenGeoShapeDialog(false)}
                    dialogTitle={dialogTitle}
                    type="changeByCSV"
                />
                <InsertCSVDialog
                    open={openCSVInsertDialog}
                    list={props.list}
                    companyKey={companyKey}
                    userData={userData}
                    handleReloadPage = {props.handleReloadPage}
                    onClose={() => setOpenCSVInsertDialog(false)}
                />
                <DeleteCSVDialog
                    open={openCSVDeleteDialog}
                    list={props.list}
                    companyKey={companyKey}
                    userData={userData}
                    handleReloadPage = {props.handleReloadPage}
                    onClose={() => setOpenCSVDeleteDialog(false)}
                />
                <DeleteManyDialog
                    open={openDeleteManyDialog}
                    list={props.list}
                    companyKey={companyKey}
                    userData={userData}
                    handleReloadPage = {props.handleReloadPage}
                    onClose={() => setOpenDeleteManyDialog(false)}
                />
            </div>
    )
}

export default ListOverviewCard