import React, { useContext, useState, useReducer, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";
import AuthContext from "../../../../context/auth-context";
import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit"
import CloseIcon from "@material-ui/icons/Close";
import { default_roles_groups_manager } from "../../utils"
import RolesGroupPermissions from "../RolesGroupPermissions/RolesGroupPermissions";
import SingleSelectDropdown from "../../SingleSelectDropdown/SingleSelectDropdown";
import { decisionReducer } from "../../../Utils/requestUtils";
import Button from "../../../@GeneralComponents/Button";
import ConfirmEditGroup from "./ConfirmEditGroup/ConfirmEditGroup";
import ConfirmDeleteGroup from "./ConfirmDeleteGroup/ConfirmDeleteGroup";
import useInput from "../../hooks/use-input";
import FormField from "../../../@GeneralComponents/FormField";
import { Tooltip } from "@material-ui/core";
import { formatGroupName } from "../../utils";
import { removeSpecialCharacters, removeDuplicatesFromArrayOfObjects } from "../../../Utils/utils";

function EditGroupPermissions (props) {

    let user_data = useContext(AuthContext).user_data
    const { t } = useTranslation();
    
    const [selectedEditGroup, setSelectedEditGroup] = useState("choose_group_to_edit")
    const [eventsIsLoaded, setEventsIsLoaded] = useState(false)
    const [rolesGroupsManager, setRolesGroupsManager] = useState(default_roles_groups_manager)

    useEffect(()=>{
        if (eventsIsLoaded) return;
        const eventsEndpoint = '/dash/rule_engine/event_types'
        axios
        .get(eventsEndpoint)
        .then(response=>{
            let updatedRulesViewerOption = []
            for (const eventType of response.data) {
                if (["bankslip", "bankslip_alert", "bankslip_expiration"].includes(eventType.enum)) {
                  updatedRulesViewerOption.push(
                    {
                      "name": "read_rules_bankslip",
                      "description": "Visualizar regras relativas a boletos.",
                    }
                    )
                  }
                if (["pix_recipient_list_append", "pix_recipient_list_limit_update_request", "pix_limit_update_request"].includes(eventType.enum)) {
                  updatedRulesViewerOption.push(
                    {
                      "name": "read_rules_pix_limits",
                      "description": "Visualizar regras relativas a limites PIX."
                    }
                  )
                }
                if (["bill_payment", "bill_payment_alert", "bill_payment_expiration"].includes(eventType.enum)) {
                  updatedRulesViewerOption.push(
                    {
                      "name": "read_rules_bill_payment",
                      "description": "Visualizar regras relativas a pagamento de contas.",
                    }
                    )
                    }
                if (["card_order_alert", "card_order_order"].includes(eventType.enum)) {
                  updatedRulesViewerOption.push(
                    {
                      "name": "read_rules_card_order",
                      "description": "Visualizar regras relativas a pedidos.",
                    }
                    )
                  }
                if (["car_rental_rental_agreement", "car_rental_rental_agreement_expiration", "car_rental_rental_agreement_suggestion", "car_rental_reservation"].includes(eventType.enum)) {
                  updatedRulesViewerOption.push(
                    {
                      "name": "read_rules_car_rental",
                      "description": "Visualizar regras relativas a aluguel de carros.",
                    }
                    )
                }
                if (["credit_analysis_legal_person", "credit_analysis_legal_person_manual_analysis"].includes(eventType.enum)) {
                  updatedRulesViewerOption.push(
                    {
                      "name": "read_rules_credit_analysis_legal_person",
                      "description": "Visualizar regras relativas à análise de crédito de pessoa jurídica.",
                    }
                    )
                  }
                if (["credit_analysis_natural_person", "credit_analysis_natural_person_manual_analysis"].includes(eventType.enum)) {
                  updatedRulesViewerOption.push(
                    {
                      "name": "read_rules_credit_analysis_natural_person",
                      "description": "Visualizar regras relativas à análise de crédito de pessoa física.",
                    }
                    )
                  }
                if (["currency_exchange_operation", "currency_exchange_operation_alert"].includes(eventType.enum)) {
                  updatedRulesViewerOption.push(
                    {
                      "name": "read_rules_currency_exchange",
                      "description": "Visualizar regras relativas a câmbio.",
                    }
                    )
                  }
                if (["onboarding_legal_person", "onboarding_legal_person_manual_analysis", "onboarding_legal_person_partner"].includes(eventType.enum)) {
                  updatedRulesViewerOption.push(
                    {
                      "name": "read_rules_onboarding_legal_person",
                      "description": "Visualizar regras relativas a cadastro de pessoa jurídica.",
                    }
                    )
                  }
                if (["onboarding_natural_person", "onboarding_natural_person_manual_analysis", "onboarding_natural_person_partner", "onboarding_natural_person_reanalysis"].includes(eventType.enum)) {
                  updatedRulesViewerOption.push(
                    {
                      "name": "read_rules_onboarding_natural_person",
                      "description": "Visualizar regras relativas à cadastro de pessoa física.",
                    }
                    )
                  }
                if (["pix_dict_operation", "pix_dict_operation_alert", "pix_dict_operation_expiration"].includes(eventType.enum)) {
                  updatedRulesViewerOption.push(
                    {
                      "name": "read_rules_pix_dict_operation",
                      "description": "Visualizar regras relativas à operações de chave pix.",
                    }
                    )
                  }
                if (["pix_transaction", "pix_transaction_alert", "pix_transaction_expiration"].includes(eventType.enum)) {
                  updatedRulesViewerOption.push(
                    {
                      "name": "read_rules_pix_transaction",
                      "description": "Visualizar regras relativas à transações pix.",
                    }
                    )
                  }
                if (["wire_transfer", "wire_transfer_alert", "wire_transfer_expiration"].includes(eventType.enum)) {
                  updatedRulesViewerOption.push(
                    {
                      "name": "read_rules_wire_transfer",
                      "description": "Visualizar regras relativas à transferências bancárias.",
                    }
                    )
                  }
                if (["withdrawal", "withdrawal_alert"].includes(eventType.enum)) {
                  updatedRulesViewerOption.push(
                    {
                      "name": "read_rules_withdrawal",
                      "description": "Visualizar regras relativas a saques.",
                    }
                    )
                  }
                }
            updatedRulesViewerOption = removeDuplicatesFromArrayOfObjects(updatedRulesViewerOption)
            for (let roleGroup of rolesGroupsManager){
                if (roleGroup.manager_role === 'rule_manager'){
                    roleGroup.roles = [...roleGroup.roles, ...updatedRulesViewerOption]
                    roleGroup.roles = removeDuplicatesFromArrayOfObjects(roleGroup.roles)
                }
            }
            setRolesGroupsManager(rolesGroupsManager)
            setEventsIsLoaded(true)
        })
      },[props.user, eventsIsLoaded, rolesGroupsManager])

    let user_roles = user_data.roles;
    const rolesGroupSortedAlphabetically = rolesGroupsManager.sort((a, b) => a.name === b.name ? 0 : a.name > b.name ? 1 : -1)
    const allowedRolesGroups = rolesGroupSortedAlphabetically.filter(roles_group => user_roles.includes(roles_group['manager_role']))

    let checkedInitialState = {}
    allowedRolesGroups.forEach((group)=>(
        group.roles.forEach((role)=>{
            checkedInitialState[role.name] = false
        })
    ))

    const [rolesChecked, setRolesChecked] = useState(checkedInitialState)

    const selectedEditGroupHandler = (value) => {
        setSelectedEditGroup(value)
        if (value==="choose_group_to_edit"){
            setRolesChecked(checkedInitialState)
        } else {
            const group_roles = ((props.groups.filter((group)=>(group.name===value))[0]||{}).roles||[])
            let checkedState = {}
            allowedRolesGroups.forEach((group)=>(
                group.roles.forEach((role)=>{
                    if (group_roles.includes(role.name)) {
                        checkedState[role.name] = true
                    } else {
                        checkedState[role.name] = false
                    }
                })
            ))
            setRolesChecked(checkedState)
        }
    }

    const [saveEdit, dispatchSaveEdit] = useReducer(
        decisionReducer,
        {isLoading: false, isError: false, finished:false}
    )
    
    const onSaveEdit = () => {
        let roles = []
        Object.entries(rolesChecked).forEach(([key,value])=>{
            if (value===true) {
                roles.push(key)
            }
        })
        const payload = {
            "name": editGroupName?formatGroupName(newGroupNameValue):selectedEditGroup,
            "roles": roles
        }
        dispatchSaveEdit({type:"send_request_init"})
        setTimeout(function () {
            axios
            .put(`/dash/group/${selectedEditGroup}`,payload)
            .then(()=>{
                dispatchSaveEdit({type: "send_request_success"})
                props.onUpdateGroups()
                props.onUpdate()
                if (editGroupName) {
                    props.setSelectedGroup(formatGroupName(newGroupNameValue))
                }
                props.onClose()
            })
            .catch(error => {
                if ((error.response || {}).status === 403) dispatchSaveEdit({type: "send_request_failure_403"})
                else dispatchSaveEdit({type: "send_request_failure"})   
            })
        },1000)
    }

    const [deleteGroup, dispatchDeleteGroup] = useReducer(
        decisionReducer,
        {isLoading: false, isError: false, finished:false}
    )
    
    const onDeleteGroup = () => {
        dispatchDeleteGroup({type:"send_request_init"})
        setTimeout(function () {
            axios
            .delete(`/dash/group/${selectedEditGroup}`)
            .then(()=>{
                dispatchDeleteGroup({type: "send_request_success"})
                props.onUpdateGroups()
                props.onUpdate()
                props.onClose()
            })
            .catch(error => {
                if ((error.response || {}).status === 403) dispatchDeleteGroup({type: "send_request_failure_403"})
                else dispatchDeleteGroup({type: "send_request_failure"})   
            })
        },1000)
    }

    const transformGroupName = (groupName) => {
        if (groupName==="choose_permissions_separately") {
            return t("choose_permissions_separately")
        }
        const words = groupName.split("_");
        const firstWordCapitalized = words[0].charAt(0).toUpperCase() + words[0].slice(1);
        words.shift()
        return `${firstWordCapitalized} ${words.join(" ")}`;
    }

    const [editGroupName, setEditGroupName] = useState(false)
    const [confirmEditGroupOpen, setConfirmEditGroupOpen] = useState(false)
    const [confirmDeleteGroupOpen, setConfirmDeleteGroupOpen] = useState(false)

    const validateValue = (value) => {
        if ([""," "].includes(value.replace(/\s+/g, ' ').trim())) return false
        else return true
    }

    const {
        value: newGroupNameValue,
        isValid: newGroupNameIsValid,
        hasError: newGroupNameHasError,
        valueChangeHandler: newGroupNameChangeHandler,
        inputBlurHandler: newGroupNameBlurHandler,
        reset: newGroupNameReset
    } = useInput(validateValue);

    const editHandler = () => {
        setEditGroupName(!editGroupName)
        newGroupNameReset()
    }

    return (
        <Dialog
        open={props.open}
        onClose={props.onClose}
        keepMounted={false}
        fullWidth={true}
        maxWidth={"md"}
        >
            <DialogTitle
                disableTypography
                className={['blueText', "subtitleSize", "dialogTitleWithIcon"].join(" ")}
                style={{padding:"20px 24px 0px"}}
            >
                {t("edit_group")}
                <IconButton onClick={props.onClose}>
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <div style={{display:"flex", flexDirection:"column", marginBottom:"30px"}}>
                    <div style={{display:"grid", gridTemplateColumns:"50% 50%", marginBottom:"10px"}}>
                        <div style={{display:"flex", flexDirection:"column", width: "100%"}}>
                            <div style={{display:"flex", flexDirection:"column"}}>
                                <div className="normalText normalMediumSize bold">
                                    {t("group_permission_name")}
                                </div>
                                <div style={{display:"flex", flexDirection:"row", marginBottom:"10px"}}>
                                    <SingleSelectDropdown
                                        groups={props.groups}
                                        selected={selectedEditGroup}
                                        selectedHandler={selectedEditGroupHandler}
                                        isLoading={saveEdit.isLoading}
                                        optionTransform={transformGroupName}
                                        additionalStyles={{width: "100%"}}
                                        initialSelection={"choose_group_to_edit"}
                                    />
                                    {selectedEditGroup==="choose_group_to_edit"?
                                    <div style={{width:"38px"}}></div>
                                    :<Tooltip title={<p className="tooltipText">{t("edit_group_tooltip_message")}</p>}>
                                        <IconButton 
                                            onClick={()=>editHandler()} 
                                            style={{padding:"4px", minWidth:"38px", margin:"0px 0px auto 0px"}}
                                        >
                                            <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                                            <EditIcon style={{height:"18px", width:"18px"}}/>
                                            <div className="normalTinySize">{t("name")}</div>
                                            </div>
                                        </IconButton>
                                    </Tooltip>}
                                </div>
                            </div>
                            {selectedEditGroup!=="choose_group_to_edit"&&editGroupName&&
                            <FormField
                                formFieldStyle={{marginRight:"38px"}}
                                labelValue={"new_group_permission_name"}
                                labelDescriptionValue={"new_group_permission_name_description"}
                                onChange={newGroupNameChangeHandler}
                                onBlur={newGroupNameBlurHandler}
                                fieldValue={removeSpecialCharacters(newGroupNameValue)}
                                hasError={newGroupNameHasError}
                                errorMessage={"group_permission_name_error_message"}
                                inputStyle={{width:"100%", padding:"10px"}}
                            />}
                        </div>
                        <div style={{display:"flex", flexDirection:"column"}}>
                            <Button
                                button_option={(selectedEditGroup==="choose_group_to_edit")?"standard_disabled_blue":"standard"}
                                onClick={()=>(selectedEditGroup==="choose_group_to_edit")?null:setConfirmDeleteGroupOpen(true)} 
                                buttonLabel={t("delete_group")}
                                buttonStyle={{minWidth:"252px", margin: "19px 0px 5px auto"}}
                                isLoading={deleteGroup.isLoading}
                            />
                            <Button
                                button_option={(selectedEditGroup==="choose_group_to_edit"||(editGroupName&&!newGroupNameIsValid))?"standard_disabled_blue":"standard"}
                                onClick={()=>(selectedEditGroup==="choose_group_to_edit"||(editGroupName&&!newGroupNameIsValid))?null:setConfirmEditGroupOpen(true)} 
                                buttonLabel={t("save_edit")}
                                buttonStyle={{minWidth:"252px", margin: "5px 0px auto auto"}}
                                isLoading={saveEdit.isLoading}
                            />
                        </div>
                        <ConfirmDeleteGroup
                            selectedEditGroup={selectedEditGroup}
                            open={confirmDeleteGroupOpen}
                            onClose={()=>setConfirmDeleteGroupOpen(false)} 
                            onDeleteGroup={() => onDeleteGroup()}
                            saveEdit={deleteGroup}
                        />
                        <ConfirmEditGroup
                            selectedEditGroup={selectedEditGroup}
                            open={confirmEditGroupOpen}
                            onClose={()=>setConfirmEditGroupOpen(false)} 
                            onSaveEdit={() => onSaveEdit()}
                            saveEdit={saveEdit}
                        />
                    </div>
                    <div className="normalText normalMediumSize bold">
                        {t("group_permissions")}
                    </div>
                    <div className="labelText normalSmallSize">
                        {t("group_permissions_description")}
                    </div>
                    {allowedRolesGroups.map((rolesGroup) =>
                        <RolesGroupPermissions
                            key={rolesGroup.manager_role}
                            rolesGroup={rolesGroup}
                            rolesChecked={rolesChecked}
                            setRolesChecked={setRolesChecked}
                        />
                    )}
                </div>
            </DialogContent>
        </Dialog>
    );
};
export default EditGroupPermissions;
