import React, {useState,useContext} from 'react'
import { useTranslation } from "react-i18next";
import ExternalNodeComponent from "./ExternalNodeComponent"
import { v4 as uuidv4 } from 'uuid';
import {getTypeComponents} from "../BoxTypeMapper"
import Collapse from "@material-ui/core/Collapse";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import IconButton from "@material-ui/core/IconButton";
import {basicBoxesList, categoriesList} from "../Boxes/utils"
import RulesContext from "../../../../context/rules-context"
import _ from "lodash"
import BookmarkTwoTone from '@material-ui/icons/BookmarkTwoTone';

function BoxLibrary (props) {

    const { userVariablesAvailable } = props
    let rulesContext = useContext(RulesContext)
    const { t } = useTranslation();

    let basicBoxesCopy  = _.cloneDeep(basicBoxesList) // IMPORTANT

    const boxesList = basicBoxesCopy.reduce((acc,box) => {
        if (rulesContext.boxes[box.type] != null){
            box.instance = getTypeComponents({type:box.type, rulesContext:rulesContext, userVariablesAvailable:userVariablesAvailable}).instance
            acc.push(box)
        }
        return acc
    },[])

    const initialCategoriesCollapse = categoriesList.reduce((acc,category) => {
        acc[category.name] = false
        return acc
    },{})

    const [categoriesCollapse, setCategoriesCollapse] = useState(initialCategoriesCollapse)

    const handleCollapseButton = (category) => {
        let newCategoriesCollapse = {...categoriesCollapse}
        newCategoriesCollapse[category] = !categoriesCollapse[category]
        setCategoriesCollapse(newCategoriesCollapse)
    }

    const getCategoryList = (category) => {
        return filteredList.filter(box => box.category === category)
    }


    let {searchString} = props;
    
    let filteredList = boxesList.filter(box=>box.description.toLowerCase().includes(searchString.toLowerCase()))
    
    return (
        <div style={{flex: "1 1 40%", overflow:"auto"}}>
            {categoriesList.map((category,index)=>(
                getCategoryList(category.name).length > 0
                ?
                <React.Fragment key={index}>
                <div className={["bold", "normalMediumSize", "darkGray"].join(" ")} style={{display:"flex", justifyContent:"space-between"}}>
                    <div style={{margin:"auto 5px"}}>
                        {t(category.description)}
                    </div>
                    <BookmarkTwoTone 
                        style={{margin:"auto 0 auto auto", width: "18px", color: category.color, filter: "brightness(80%)"}}
                    />
                    <IconButton style={{margin: "auto 0", padding:"5px"}} onClick={() => handleCollapseButton(category.name)}>
                        {categoriesCollapse[category.name] ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </IconButton>
                </div>
                <Collapse in={categoriesCollapse[category.name]} timeout="auto" unmountOnExit>
                    <div
                        style={{display:"flex", flexDirection: "column"}}
                        className={["normalText", "subtitleSize"].join(" ")}
                    >
                        {getCategoryList(category.name).map((node,index)=>(
                            <div key={index} style={{borderRadius: "4px", width: "93%"}}>
                                <ExternalNodeComponent node={{...node, id: uuidv4()}} />
                            </div>
                        ))}
                    </div>
                </Collapse>
                </React.Fragment>   
                :
                null
            ))}
        </div>
    )
}

export default BoxLibrary
