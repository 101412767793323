import React from "react";
import { useTranslation } from "react-i18next"
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {formatAsMoneyMinimum2Digits} from "../../../../utils/utils"

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
  },
  tableText: {
    fontFamily: "Open Sans",
    fontWeight: 'normal',
    fontSize: "12px",
    lineHeight: "16px",
    color: "#141414",
    textAlign: "center",
    margin: "10px",
    padding: "6px",
    maxWidth: "80px"
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: '#6F6F6F',
    fontSize: "14px",
    lineHeight: "19px",
    padding: "6px",
    maxWidth: "80px"
  },
  even: {
    background: "#F3F3F3",
  },
  odd: {
    background: "#FFFFFF",
  },
}));

function ElectoralDonorsTable(props) {
  const classes = useStyles();
  useTheme();

  const { t } = useTranslation()

  const getClass = (rowIndex) => {
    if (rowIndex % 2 === 0) {
      return classes.even;
    }

    return classes.odd;
  };

  return (
    <Paper className={classes.tableStyle}>
      <Table style={{ padding: "8px", marginBottom: "30px" }}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader}>Atualmente é Doador Eleitoral</TableCell>
            <TableCell className={classes.tableHeader}>Historicamente é Doador Eleitoral</TableCell>
            <TableCell className={classes.tableHeader}>Quantidade Total de Eleições Doada</TableCell> 
            <TableCell className={classes.tableHeader}>Valor Total Doado</TableCell>
            <TableCell className={classes.tableHeader}>Partidos Doados</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow className={getClass(0)}>
              <TableCell className={classes.tableText}>
              {props.electoral_donors_data[0].is_currently_electoral_donor === false ? "não" :  "sim"}
              </TableCell>
              <TableCell className={classes.tableText}>
              {props.electoral_donors_data[0].is_historical_electoral_donor === false ? "não" :  "sim"}
              </TableCell>
              <TableCell className={classes.tableText}>
                {props.electoral_donors_data[0].total_donations_amount ? props.electoral_donors_data[0].total_donations_amount : "-"}
              </TableCell>
              <TableCell className={classes.tableText}>
              {props.electoral_donors_data[0].total_donations_value ? 
              formatAsMoneyMinimum2Digits(props.electoral_donors_data[0].total_donations_value)
            : "-"}
              </TableCell>
              <TableCell className={classes.tableText}>
              {props.electoral_donors_data[0].parties_that_received_donation 
                ? props.electoral_donors_data[0].parties_that_received_donation.join(", ")
                : " - "}
              </TableCell>
            </TableRow>
        </TableBody>
      </Table>
      {props.electoral_donors_data[0].election_donation_data ?
      <>
        <div className={["blueText", "normalMediumSize", "bold"].join(" ")}
            style={{margin:"10px"}}>
            Detalhes
        </div>
        <Table style={{ padding: "8px" }}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader}>Ano</TableCell>
              <TableCell className={classes.tableHeader}>Quantidade de Doações</TableCell>
              <TableCell className={classes.tableHeader}>Valor Doado</TableCell>
              <TableCell className={classes.tableHeader}>Financiamento Colaborativo</TableCell>
              <TableCell className={classes.tableHeader}>Partido</TableCell> 
              <TableCell className={classes.tableHeader}>Destinatário da Doação</TableCell>
              <TableCell className={classes.tableHeader}>Político</TableCell>
              <TableCell className={classes.tableHeader}>Documento do Candidato</TableCell>
              <TableCell className={classes.tableHeader}>Nº do Candidato</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.electoral_donors_data[0].election_donation_data.map((election,index) => (
              <TableRow
                key={index}
                className={getClass(index)}
              >
                <TableCell className={classes.tableText}>
                  {election.election_year ? election.election_year : "-"}
                </TableCell>
                <TableCell className={classes.tableText}>
                  {election.donations_amount ? election.donations_amount : "-"}
                </TableCell>
                <TableCell className={classes.tableText}>
                  {election.total_donation_value ? formatAsMoneyMinimum2Digits(election.total_donation_value) : "-"}
                </TableCell>
                <TableCell className={classes.tableText}>
                  {election.is_crowdfunding === false ? 'não': 'sim'}
                </TableCell>
                <TableCell className={classes.tableText}>
                  {election.party_name ? election.party_name : "-"}
                </TableCell>
                <TableCell className={classes.tableText}>
                  {election.donation_recipient ? t(election.donation_recipient.toLowerCase()) : "-"}
                </TableCell>
                <TableCell className={classes.tableText}>
                  {election.politician_full_name ? election.politician_full_name : "-"}
                </TableCell>
                <TableCell className={classes.tableText}>
                  {election.politician_doc_number ? election.politician_doc_number : "-"}
                </TableCell>
                <TableCell className={classes.tableText}>
                  {election.politician_ballot_number ? election.politician_ballot_number : "-"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </> : null}
    </Paper>
  );
}

export default ElectoralDonorsTable;
