import React, { useReducer } from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import TextField from "@material-ui/core/TextField"
import { useHistory } from "react-router-dom"
import axios from "axios"
import { CircularProgress } from '@material-ui/core'
import ConfirmationContent from "./ConfirmationContent"
import { decisionReducer } from "../../../../Utils/requestUtils"

function ConfirmationDialog(props) {

    const { open, manualAnalysisStatus, onClose, observation_data, credit_proposal_natural_person_key } = props

    const history = useHistory()
    let title = ""
    let buttonClass = ["button", "reproved", "normalText", "normalMediumSize"].join(" ")
    switch (manualAnalysisStatus) {
        case "manually_approved":
            title = "Aprovar"
            buttonClass = ["button", "approved", "normalText", "normalMediumSize"].join(" ")
            break
        case "manually_reproved":
            title = "Reprovar"
            buttonClass = ["button", "reproved", "normalText", "normalMediumSize"].join(" ")
            break
        default:
            title = ""
    }

    const [dialogState, dispatchDialogState] = useReducer(
        decisionReducer,
        { isLoading: false, isError: false, finished: false }
    )

    const handleFinish = () => {
        dispatchDialogState({ type: "send_request_init" })
        let payload = {
            analysis_status: manualAnalysisStatus
        }
        if (observation_data && observation_data !== "") {
            payload = { ...payload, observation: observation_data }
        }
        setTimeout(function () {
            axios.put('/dash/credit_analysis/natural_person/' + credit_proposal_natural_person_key, payload).then(response => {
                dispatchDialogState({ type: "send_request_success" })
            }).catch(error => {
                if ((error.response || {}).status === 403) dispatchDialogState({ type: "send_request_failure_403" })
                else if ((error.response || {}).status === 409) dispatchDialogState({ type: "send_request_failure_409" })
                else dispatchDialogState({ type: "send_request_failure" })
            })
        }, 1000)

    }

    if (dialogState.finished && dialogState.isLoading) {
        return (
            <Dialog
                open={open}
                onClose={() => history.push("/credit-analysis-natural-persons-inbox")}
                keepMounted={false}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle style={{ paddingBottom: "0px", width: "500px" }} className={["blueText", "normalMediumSize"].join(" ")}>{title}</DialogTitle>
                <DialogContent>
                    <div style={{ display: "flex", width: "310px", height: "217px", margin: "auto" }}>
                        <CircularProgress style={{ margin: "auto" }} />
                    </div>
                </DialogContent>
            </Dialog>

        )
    }
    else if (dialogState.finished) {
        let confirmationData = {
            message: dialogState.message,
            success: !dialogState.isError
        }
        return (
            <Dialog
                open={open}
                onClose={() => history.push("/credit-analysis-natural-persons-inbox")}
                keepMounted={false}
                disableBackdropClick
            >
                <DialogTitle style={{ paddingBottom: "0px", width: "500px" }} className={["blueText", "normalMediumSize"].join(" ")}>Confirmação de Revisão</DialogTitle>
                <DialogContent>
                    <ConfirmationContent onClose={() => history.push("/credit-analysis-natural-persons-inbox")} data={confirmationData} />
                </DialogContent>
            </Dialog>
        )
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            keepMounted={false}
        >
            <DialogTitle style={{ paddingBottom: "0px", width: "500px" }} className={["blueText", "normalMediumSize"].join(" ")}>{title}</DialogTitle>
            <DialogContent>
                <div>
                    <div>
                        <TextField
                            fullWidth
                            multiline
                            variant="outlined"
                            margin="dense"
                            label="Observação"
                            defaultValue={observation_data ? observation_data : "Sem Observação"}
                            disabled={true}
                        />
                    </div>
                    <div className="internalCardContainer" style={{ display: "flex", justifyContent: "center" }}>
                        <div
                            className={buttonClass}
                            onClick={handleFinish}
                        >
                            {title}
                        </div>
                        <div
                            className={["button", "cancel", "normalText", "normalMediumSize"].join(" ")}
                            onClick={onClose}
                        >
                            Cancelar
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default ConfirmationDialog