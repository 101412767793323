import React from "react";
import CEAFTable from "./CEAFTable";
import CEISTable from "./CEISTable";
import CNEPTable from "./CNEPTable";
import CEPIMTable from "./CEPIMTable";
import { ErrorOutline, Check } from "@material-ui/icons";
import {colorGreen, colorRed, colorYellow} from "../../../utils/utils"
import { useTranslation } from 'react-i18next';
import * as S from './styles';


export default function AdministrativeSanctionsCard(props) {
    const { ceaf, ceis, cepim, cnep } = props;
    const { t } = useTranslation();

    const getCardIcon = (types) => {
        if (types.includes('negative')) return <ErrorOutline style={{ color: colorRed }} />;
        if (types.includes('neutral'))  return <ErrorOutline style={{ color: colorYellow }} />;
        if (types.includes('positive'))  return <Check style={{ color: colorGreen }} />;
        return <ErrorOutline />;
    }

    return (
        <S.Container>
            <S.Header>
                <S.Title>
                    {t('administrative_sanctions')}
                </S.Title>
            </S.Header>
            <S.ContentWrapper>
                {cepim &&
                    <S.Content>
                        <S.Title style={{fontSize: "14px"}}>
                            {t('cepim')}
                            {getCardIcon([cepim.flag])}
                        </S.Title>
                        {cepim.flag === 'negative' &&
                            <CEPIMTable cepim_data={cepim.data} />
                        }
                    </S.Content>
                }
                {ceis &&
                    <S.Content>
                        <S.Title style={{fontSize: "14px"}}>
                            {t('ceis')}
                            {getCardIcon([ceis.flag])}
                        </S.Title>
                        {ceis.flag === 'negative' &&
                            <CEISTable ceis_data={ceis.data} />
                        }
                    </S.Content>
                }
                {cnep &&
                    <S.Content>
                        <S.Title style={{fontSize: "14px"}}>
                            {t('cnep')}
                            {getCardIcon([cnep.flag])}
                        </S.Title>
                        {cnep.flag === 'negative' &&
                            <CNEPTable cnep_data={cnep.data} />
                        }
                    </S.Content>
                }
                {ceaf &&
                    <S.Content>
                        <S.Title style={{fontSize: "14px"}}>
                            {t('ceaf')}
                            {getCardIcon([ceaf.flag])}
                        </S.Title>
                        {ceaf.flag === 'negative' &&
                            <CEAFTable ceaf_data={ceaf.data} />
                        }
                    </S.Content>
                }
            </S.ContentWrapper>
        </S.Container>
    );
}