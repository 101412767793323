import React from "react";
import Tooltip from "@material-ui/core/Tooltip"

function DataField(props) {

    if (props.label == null) return null

    let displayType = "inline"
    let marginSize= "5px"
    let marginRight = "5px"
    let titleClasses = ["normalText", props.size]
    let labelClasses = ["labelText", props.size, "labelSpace"]
    let labelStyles = {verticalAlign: "middle"}
    let titleStyles = {verticalAlign: "middle"}
    let imageTitleStyles = {verticalAlign: "-0.4em", marginRight: "10px"}

    if (props.titleNoWrap){
        titleClasses.push("noWrap")
    }
    if (props.labelNoWrap){
        labelClasses.push("noWrap")
    }

    if (props.disabled){
        titleClasses.push("disabled")
        labelClasses.push("disabled")
    }

    if (props.margin){
        marginSize=props.margin
    }

    if (props.marginRight){
        marginRight=props.marginRight
    }

    if (props.display){
        displayType=props.display
    }

    if (props.labelStyles){
        labelStyles={...labelStyles, ...props.labelStyles}
    }

    if (props.titleStyles){
        titleStyles={...titleStyles, ...props.titleStyles}
    }

    if (props.labelClasses){
        labelClasses = props.labelClassesOverride ? [props.labelClasses, props.size, "labelSpace"] : [...labelClasses, ...props.labelClasses]
    }

    if (props.titleClasses){
        titleClasses=[...titleClasses, ...props.titleClasses]
    }

    if (props.titleImage) {
        return (
            <div style={{display: displayType, marginTop: marginSize, marginBottom: marginSize, marginRight: marginRight, lineHeight: "20px"}}>
                <span className={titleClasses.join(" ")} style={imageTitleStyles}>
                <Tooltip title={props.titleTooltipText == null ? "" : <p className="tooltipText">{props.titleTooltipText}</p>}>
                    <img src={props.titleImage} alt={props.alt}/>
                </Tooltip>
                </span>
                <span className={labelClasses.join(" ")} style={labelStyles}>
                    {props.label}
                </span>
                {props.children}        
            </div>
        );
    }
    else {
        return (
            <div style={{display: displayType, marginTop: marginSize, marginBottom: marginSize, marginRight: marginRight, lineHeight: "20px"}}>
                <span className={titleClasses.join(" ")} style={titleStyles}>
                    {props.title}:
                </span>
                <span className={labelClasses.join(" ")} style={labelStyles}>
                    {props.label}
                </span>
                {props.children}        
            </div>
        );
    }
}

export default DataField;