import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CircularProgress } from '@material-ui/core';
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";
import RuleHistoryTable from "./RuleHistoryTable";

function RuleHistory(props) {
    const { t } = useTranslation();
    const {rule} = props

    const [tablePage, setTablePage] = React.useState(1)
    const [ruleHistory, setRuleHistory] = useState(null)

    useEffect(() => {
        if (ruleHistory === null){
                let params = {
                    "page_number": tablePage -1,
                    "page_rows": 10,
                    "environment": rule.environment
                }
                const timer = setTimeout(() => {
                    axios.get("/dash/rule_engine/rule/" + rule.rule_key + "/history", {
                        params: params
                    }).then(response=>{
                        setRuleHistory(response.data)
                    }).catch(error=>{
                        setRuleHistory(undefined)
                    })
                }, 500);
                return () => {
                    clearTimeout(timer)
                }
        }
    }, [ruleHistory, tablePage, rule])

    const handleChangePage = (event, page) => {
		if (tablePage === page) {
			return
		}
        setTablePage(page)
        setRuleHistory(null)
	}
  
    if (ruleHistory=== null) {
      return (
        <div style={{display: "flex", height: "160px"}}>
            <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                <CircularProgress />
            </div>
        </div>
      );    
    }
    else if (ruleHistory === undefined ){
        return (
          <div style={{display: "flex", height: "160px"}}>
              <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                  {t("Informação Indisponível")}
              </div>
          </div>
        )
      }
    else {
        return (
            <div className="internalCardContainer">
                <div>
                    <RuleHistoryTable ruleHistory={ruleHistory.data}/>
                </div>
                <div style={{display:"flex", marginTop: "10px"}}>
                    <Pagination style={{marginLeft:"auto"}} className='pagination' page={tablePage} count={ruleHistory['number_of_pages']} onChange={(e,page) => handleChangePage(e, page)} />
                </div>
            </div>
        );
    }
  }
  
  export default RuleHistory;