import React, { useState, useContext } from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import FraudDialog from "../FraudDialog/FraudDialog"
import AuthContext from "../../../../../context/auth-context";
import FraudHistoryTable from "../../../../@GeneralComponents/FraudHistoryTable";

function FraudHistory(props) {

    let {bill_payment, dashAPIEndpointUrl} = props
    let roles = useContext(AuthContext).user_data.roles
    let can_write = roles.includes("update_bill_payments")

    let fraud_history = (bill_payment.fraud_events || [])    

    const [openDialog, setOpenDialog] = useState(false)
    const [expanded, setExpanded] = useState(false);

    const handleFraudStatus = () => {
        setOpenDialog(true)
    };

    const handleDialogClose = () => {
        setOpenDialog(false)
    };

    const handleExpand = (event) => {
        event.stopPropagation();
        setExpanded(!expanded);
    };

    return (
        <div className="analysisCard" >
            <div style={{display: "flex"}}>
                <div className={["blueText", "subtitleSize"].join(" ")}
                    style={{padding: "10px 0"}}
                    >
                    Histórico de Fraude
                </div>                    
                <IconButton style={{marginLeft: "auto"}} onClick={handleExpand}>
                    {expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </IconButton>                
            </div>            
            <Collapse in={expanded} timeout="auto" unmountOnExit style={{margin: "10px"}}>
                <div className="internalCardContainer" style={{display: "flex", flexDirection: "column"}}>
                <FraudHistoryTable transaction_key={bill_payment.bill_payment_key} fraud_history={fraud_history} dashAPIEndpointUrl={dashAPIEndpointUrl}/>
                    {can_write && 
                        <div style={{display: "flex", justifyContent: "flex-end", marginTop: "20px"}}> 
                            <div
                            className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                            style={{fontWeight:"600", paddingTop:"7px"}}
                            onClick={handleFraudStatus}
                            >
                                Adicionar Histórico
                            </div>
                        </div>}                
                </div>            
            </Collapse>                       
            <FraudDialog
                open={openDialog}
                onClose={handleDialogClose}
                bill_payment={bill_payment}
            />
        </div>
        );

}

export default FraudHistory;
