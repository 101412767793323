import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from 'moment';
import {getWireTransferStatusIcon, getReasonDescription } from "../WireTransfer/utils/utilsWireTransfer"
import {getTableRowClass,formatAsMoney,
        getAnalysisStatusColor, getAnalysisStatusSiglum,
        getAnalysisStatus,
        getAgencyInfo} from "../WireTransfer/utils/utils"
import { Link } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import { getTransactionDirectionIcon } from "../PIX/utils/utilsTransaction";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
  },
  blueText: {
    fontFamily: "Open Sans",
    fontWeight: "600",
    color: "#0b1f82"
},
redText: {
    fontFamily: "Open Sans",
    fontWeight: "600",
    color: "#D91A1A"
},
  tableText: {
    fontFamily: "Open Sans",
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "center",
    margin: "10px",
    padding: "6px",
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: '#6F6F6F',
    fontSize: "14px",
    lineHeight: "19px",
    padding: "6px",
  }
}));

function WireTransferTable(props) {
    const classes = useStyles();
    useTheme();

    const {alert_triggers_transactions} = props

    const getCounterPartObject=(wireTransfer)=>{
        let counter_part_account_object = (wireTransfer.wire_transfer_direction === 'sent' ? 
                                        wireTransfer.destination_account : 
                                        wireTransfer.source_account)
        return counter_part_account_object
    }

    const getAgencies=(wireTransfer)=>{
        let [institution_name] = getAgencyInfo (getCounterPartObject(wireTransfer))
        return institution_name
    }

    return (
        <Paper className={classes.tableStyle}>
            <Table style={{ padding: "8px" }}>
                <TableHead>
                <TableRow>
                    <TableCell className={classes.tableHeader}>ID</TableCell>
                    <TableCell className={classes.tableHeader}>Valor</TableCell>
                    <TableCell className={classes.tableHeader}>Tipo</TableCell>
                    <TableCell className={classes.tableHeader}style={{width: "10%"}}>Contraparte</TableCell>
                    <TableCell className={classes.tableHeader} style={{width: "10%"}}>Status de Fraude</TableCell>
                    <TableCell className={classes.tableHeader}>Data</TableCell>
                    <TableCell className={classes.tableHeader}style={{width: "10%"}}>Status da Transferência</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                    {props.historic.map((wireTransfer,index) => (
                    <TableRow
                        key={index}
                        className={getTableRowClass(index)}
                    > 
                        <TableCell
                            className={[classes.tableText, classes.blueText, "breakWord", "link"].join(" ")}
                        >
                            <Link
                                className={
                                    alert_triggers_transactions ?
                                    alert_triggers_transactions.filter((item) => item === wireTransfer.wire_transfer_key).length ?
                                    "linkRed" :
                                    "link" : 
                                    "link"
                                }
                                to={"/wire-transfer/" + wireTransfer.wire_transfer_key}
                            >
                                {alert_triggers_transactions ?
                                    alert_triggers_transactions.filter((item) => item === wireTransfer.wire_transfer_key).length ? 
                                        <Tooltip title={<p className="tooltipText">Transação responsável por um trigger</p>}>
                                            <div style={{display:"flex", justifyContent: "center", alignItems: "center"}}>
                                                <ErrorOutlineIcon style={{color:"#D91A1A", width:"18px", marginRight: "5px"}}/>
                                                {wireTransfer.wire_transfer_key}
                                            </div>
                                        </Tooltip> :
                                        <div>
                                            {wireTransfer.wire_transfer_key}
                                        </div> :
                                        <div>
                                            {wireTransfer.wire_transfer_key}
                                        </div> 
                                    }
                            </Link>
                        </TableCell>
                        <TableCell className={classes.tableText}>
                            {formatAsMoney(wireTransfer.amount, "-")}
                        </TableCell>
                        <TableCell className={[classes.tableText].join(" ")}>
                            {getTransactionDirectionIcon (wireTransfer.wire_transfer_direction, "small", true)}
                        </TableCell>
                        <TableCell className={classes.tableText}>
                            {getAgencies (wireTransfer) ?
                            <Tooltip title={<p className="tooltipText">{getAgencies(wireTransfer)}</p>}>
                                <div className={["textOverflow","centerText","labelSpace"].join(" ")}>
                                {getAgencies(wireTransfer)}
                                </div>
                            </Tooltip>
                            : (getCounterPartObject(wireTransfer)).participant}
                        </TableCell>
                        <TableCell className={classes.tableText}>
                            <Tooltip title={<p className="tooltipText" style={{textAlign: "center"}}>
                                {getAnalysisStatus(wireTransfer.analysis_status)}
                                <br/>{getReasonDescription(wireTransfer)}
                            </p>}>
                                <div className={["circle", "small"].join(" ")} style={{backgroundColor:getAnalysisStatusColor(wireTransfer.analysis_status)}}>
                                    {getAnalysisStatusSiglum(wireTransfer.analysis_status)}
                                </div>
                            </Tooltip>
                        </TableCell>
                        <TableCell className={classes.tableText}>
                            {moment(wireTransfer.wire_transfer_date).format("DD/MM/YYYY HH:mm:ss")}
                        </TableCell>
                        <TableCell className={classes.tableText}>
                            {getWireTransferStatusIcon(wireTransfer.wire_transfer_status, "small", true)}                            
                        </TableCell>
                        <TableCell className={classes.tableText}>

                        </TableCell>    
                    </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );
}

export default WireTransferTable;
