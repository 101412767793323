import React, {useContext} from "react";
import FilterBox from "../../../Utils/FilterBox/FilterBox"
import CompaniesContext from "../../../../context/companies-context"
import {transactionFilterTypes as filterTypes} from "../../utils/utils"

function Filter(props) {

    let companies = useContext(CompaniesContext).onboarding_list
	
    const { setPageParams } = props

    //TODO JUST ADD IF REQUEST COMPLETES BEFORE LOCKSINBOX FIRST MOUNT
    //Maybe if you clean storage inside this if block it will work as expected
    if (companies){
        let enumList = companies.companies.sort((a, b) => a.name === b.name ? 0 : a.name > b.name ? 1 : -1).map(company =>{
            return [company.name, company.company_key]
        })
        filterTypes['company_key'] = {
            type: "enum",
            enumList: [
                ["Nenhum", ""],
                ...enumList
            ],  
            disabled: false,
            validation:false,
            description: "Cliente"
        }
    }
    
    return (
        <FilterBox 
            setPageParams={setPageParams}
            filterTypes={filterTypes}
            local_storage_object_preffix={props.local_storage_object_preffix}
        />
    )

}

export default Filter