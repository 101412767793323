import React from 'react'
import DataField from "../../utils/DataField"
import {formatAsMoney} from "../../utils/utils"
import blueWireTransfer from '../../../../assets/cardEntryIcons/blueWireTransfer.svg';
import {getWireTransferType} from '../../utils/utilsWireTransfer'
import moment from 'moment'

function WireTransferCard(props) {

    let { wireTransfer } = props

    let wire_transfer_type_dictionary = {
        ted: "TED", 
        doc: "DOC",
        internal_transfer: "Transferência Interna"
    }  

    return (
        <div className="analysisCard">
            <div style={{display:"flex"}}>
                <img style={{margin:"auto 8px auto 0px"}} src={blueWireTransfer} alt="withdrawal Logo"/>
                <div className={["blueText", "subtitleSize"].join(" ")}>Transferência</div>
            </div>
            <div className="internalCardContainer"  style={{display: "flex", flexDirection: "column"}}>
                <div className="internalCardContainer">
                    <div style={{display:"flex", marginTop:"5px"}}>
                        <div style={{width:"100%"}}>
                            <div className={["labelText", "normalMediumSize", "bold"].join(" ")}>
                                VALOR TRANSFERIDO
                            </div>
                            <div className={["normalText", "moneySize", "bold"].join(" ")}>
                                {formatAsMoney(wireTransfer.amount, "-")}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={["labelText", "normalMediumSize", "bold"].join(" ")} style={{marginBottom: "8px"}}>
                    {wire_transfer_type_dictionary[wireTransfer.wire_transfer_type]}                     
                </div>
                <div className={["normalMediumSize"].join(" ")} style={{display: "flex"}}>
                    {"Direção: "} 
                    <div className="labelText" style={{marginLeft: "5px"}}>
                    {getWireTransferType(wireTransfer.wire_transfer_direction)}
                    </div>
                </div>
                <DataField
                    title={"Data da Transferência"}
                    label={moment(wireTransfer.wire_transfer_date).format("DD/MM/YYYY")}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                />
                <DataField
                    title={"Horário da Transferência"}
                    label={moment(wireTransfer.wire_transfer_date).format("HH:mm:ss")}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                />
            </div>
        </div>
    )
}

export default WireTransferCard