import React from 'react'
import people from '../../../../assets/cardEntryIcons/people.svg';
import peopleGreen from '../../../../assets/cardEntryIcons/peopleGreen.svg';
import Tooltip from "@material-ui/core/Tooltip"
import Account from './Account/Account';
import DataField from "../../utils/DataField"
import PersonInformation from './PersonInformation/PersonInformation'

function EnvolvedParties(props) {
    let {billPayment} = props

    return (
        <div className="analysisCard">
            <div className="internalCardContainer" style={{display: "flex"}}>
                <img style={{margin:"auto 8px auto 0px"}} src={people} alt="people Logo"/>  
                <div className={["blueText", "subtitleSize"].join(" ")}>Partes Envolvidas</div>      
            </div>
            <div className="internalCardContainer" style={{marginTop:"15px"}}>
                <div style={{width:"100%", backgroundColor:"#DEF2FE", 
                            borderRadius:"12px", display:'flex', marginBottom:"20px"}}>
                    <div style={{width:"50%", padding:"20px"}}>
                        <div className={["blueText", "normalSize"].join(" ")} style={{display:"flex"}}>
                            PAGADOR
                            <Tooltip title={<p className="tooltipText">Cliente</p>}>
                                <div style={{display: "flex", margin: "auto auto auto 10px"}}>
                                    <img className="pinIconStyle" src={peopleGreen} alt="clientIcon Logo" />  
                                </div>
                            </Tooltip> 
                        </div>
                        <PersonInformation person={billPayment.payer}/>
                    </div>
                    <div style={{width:"50%", padding:"20px"}}>
                        <div className={["blueText", "normalSize"].join(" ")}>DADOS BANCÁRIOS</div>
                        <Account accountObject={billPayment.payer.account}/>
                    </div>
                </div>
                <div style={{width:"100%", backgroundColor:"#DEF2FE", 
                            borderRadius:"12px", display:'flex', marginBottom:"20px"}}>
                    <div style={{width:"50%", padding:"20px"}}>
                        <div className={["blueText", "normalSize"].join(" ")} style={{display:"flex"}}>
                            COMPANHIA
                        </div>
                        <PersonInformation person={billPayment.company}/>
                    </div>
                    <div style={{width:"50%", padding:"20px"}}>
                        <div className={["blueText", "normalSize"].join(" ")}>DADOS</div>
                        <div className="internalCardContainer" style={{display: "flex", flexDirection: "column"}}>
                            <DataField
                                title={"Razão Social"}
                                label={((billPayment.company.legal_name) || null)}
                                size={"normalMediumSize"}
                                titleNoWrap={true}
                                margin="5px"
                            />
                            <DataField
                                title={"Serviço fornecido"}
                                label={((billPayment.company.provided_service) || null)}
                                size={"normalMediumSize"}
                                titleNoWrap={true}
                                margin="5px"
                            /> 
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default EnvolvedParties