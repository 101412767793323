import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

const useStyles = makeStyles((theme) => ({
  container: {
      display: "flex",
      margin: "2px 5px 2px 5px"
  },
  positiveIcon: {
    marginRight: "5px",
    color: "#73C580",
    lineHeight: "14px"
  },
  negativeIcon: {
    marginRight: "5px",
    color: "#D91A1A"
  },
  attentionIcon: {
    marginRight: "5px",
    color: "#DDBB08"
  }
}));

function Alert(props) {
  const classes = useStyles();
  useTheme();

  const getIcon = (type) => {
    switch (type) {
      case "positive":
        return <CheckIcon className={classes.positiveIcon}/>;
      case "negative":
        return <ErrorOutlineIcon className={classes.negativeIcon} />;
      case "neutral":
        return <ErrorOutlineIcon className={classes.attentionIcon} />;
      default:
        return <ErrorOutlineIcon />;
    }
  };

  return (
    <div className={classes.container}>
      <div style={{margin: "auto 0 auto 0", textAlign: "center", display: "flex"}}>
        {getIcon(props.alert_data.flag)}
      </div>
      <div>
        <p style={{margin: '2px'}}>
          {props.alert_data.description}
        </p>
      </div>
    </div>
  );
}

export default Alert;
