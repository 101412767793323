import React from 'react'
import creditcard from '../../../../../assets/cardEntryIcons/creditcard.svg';
import { checkNested, getCard } from '../../../utils/utils';
import moment from "moment"

function PaymentCard(props) {

    if (!checkNested(props.alert, "link_key_data", "card") || props.alert.link_key_data.card == null){
        return (
            <div className="analysisCard">
                <div style={{display: "flex"}}>
                    <img className="cardTitleIconStyle" src={creditcard} alt="creditcard Logo" />
                    <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>Cartão</span>
                </div>
                <div style={{display: "flex", flexGrow:"1"}}>
                    <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                        Informação Indisponível
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="analysisCard">
            <div style={{display: "flex"}}>
                <img className="cardTitleIconStyle" src={creditcard} alt="creditcard Logo" />
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>Cartão</span>
            </div>
            <div className="internalCardContainer">
                <div style={{display:"flex", height: "120px"}}>
                    {getCard(props.alert.link_key_data.card)}
                </div>
            </div>
            <div className="internalCardContainer">
                {
                    checkNested(props.alert.link_key_data, "card", "issuing_date") ? 
                    <div style={{display: "flex", margin: "5px 0px"}}>
                        <div className={["normalText", "normalMediumSize", "noWrap"].join(" ")}>
                            Data de emissão:
                        </div>
                        <div className={["labelText", "normalMediumSize", "labelSpace"].join(" ")}>
                            {moment(props.alert.link_key_data.card.issuing_date).format("DD/MM/YYYY")}
                        </div>
                    </div>:null
                }
                {
                    checkNested(props.alert.link_key_data, "card", "unblock_date") ? 
                    <div style={{display: "flex", margin: "5px 0px"}}>
                        <div className={["normalText", "normalMediumSize", "noWrap"].join(" ")}>
                            Data de desbloqueio:
                        </div>
                        <div className={["labelText", "normalMediumSize", "labelSpace"].join(" ")}>
                            {moment(props.alert.link_key_data.card.unblock_date).format("DD/MM/YYYY")}
                        </div>
                    </div>:null
                }    
                {
                    checkNested(props.alert.link_key_data, "card", "total_credit_limit") ? 
                    <div style={{display: "flex", margin: "5px 0px"}}>
                        <div className={["normalText", "normalMediumSize", "noWrap"].join(" ")}>
                            Limite de Crédito:
                        </div>
                        <div className={["labelText", "normalMediumSize", "labelSpace"].join(" ")}>
                            {"R$ " + (props.alert.link_key_data.card.total_credit_limit/100).toLocaleString('de-DE', {minimumFractionDigits: 2})}
                        </div>
                    </div>:null
                } 
                {
                    checkNested(props.alert.link_key_data, "card", "used_credit_limit") ? 
                    <div style={{display: "flex", margin: "5px 0px"}}>
                        <div className={["normalText", "normalMediumSize", "noWrap"].join(" ")}>
                            Limite utilizado:
                        </div>
                        <div className={["labelText", "normalMediumSize", "labelSpace"].join(" ")}>
                            {"R$ " + (props.alert.link_key_data.card.used_credit_limit/100).toLocaleString('de-DE', {minimumFractionDigits: 2})}
                        </div>
                    </div>:null
                }
                {
                    checkNested(props.alert.link_key_data, "card", "issuer_country_code") ? 
                    <div style={{display: "flex", margin: "5px 0px"}}>
                        <div className={["normalText", "normalMediumSize", "noWrap"].join(" ")}>
                            País:
                        </div>
                        <div className={["labelText", "normalMediumSize", "labelSpace"].join(" ")}>
                            {props.alert.link_key_data.card.issuer_country_code}
                        </div>
                    </div>:null
                }
            </div>
        </div>
    )
}

export default PaymentCard