import React, {useState} from "react";	
import NumberFormat from 'react-number-format';
import TextField from "@material-ui/core/TextField";
import MenuItem from '@material-ui/core/MenuItem';



function GeoPointTextField (props){
    const {value, latitudeError, longitudeError, longitudeValueChangeFunction, latitudeValueChangeFunction, read_only} = props
    
    const getInitialDirection = (geoPoint, coordinateType) => {
        let isPositive = true
        let initialDirection = ""

        if (Array.isArray(geoPoint)){
            if (geoPoint.length === 2){
                if (geoPoint[1] < 0 && coordinateType === "latitude"){
                    isPositive = false
                }

                if (geoPoint[0] < 0 && coordinateType === "longitude"){
                    isPositive = false
                }                
            }
        }
        
        if (!isPositive){
            if (coordinateType === "latitude"){
                initialDirection = "S"
            }
            else if (coordinateType === "longitude") {
                initialDirection = "W"
            }
            else throw new Error()
        }
        else{
            if (coordinateType === "latitude"){
                initialDirection = "N"
            }
            else if (coordinateType === "longitude") {
                initialDirection = "E"
            }
            else throw new Error()
        }

        return initialDirection
    }

    const [latitudeDirection,setLatitudeDirection] = useState(getInitialDirection(value, "latitude"))
    const [longitudeDirection,setLongitudeDirection] = useState(getInitialDirection(value, "longitude"))

    const handleChangeValue = (value, direction, valueType) => {
        if ((direction === "W" || direction === "S") && value != null){
            value = -1 * value
        }
        if (valueType === "latitude"){
            setLatitudeDirection(direction)
            latitudeValueChangeFunction(value)
        }

        if (valueType === "longitude"){
            setLongitudeDirection(direction)
            longitudeValueChangeFunction(value)
        }
        return value
    }


    return (
        <div className="internalCardContainer" style={{display:"flex"}}>
            <div style={{width:"80%", display:"flex", flexDirection: "column", margin:"0px auto"}}>
                <p className={["labelText", "normalMediumSize", "bold"].join(" ")} style={{textAlign:"left"}}>Latitude</p>
                <div style={{display:"flex", flexDirection: "row"}}>
                    <TextField 
                        style={{textAlign:"center", width:"15%", margin:"0px 20px auto auto"}}
                        value={latitudeDirection}
                        onChange={(e) => handleChangeValue(value[1], e.target.value, "latitude")}
                        size="small"
                        error={latitudeError}
                        fullWidth
                        select
                        InputProps={{
                            readOnly: read_only
                          }}
                    >
                        <MenuItem key={0} value={"N"}><em className="normalText">N</em></MenuItem>
                        <MenuItem key={1} value={"S"}><em className="normalText">S</em></MenuItem>
                    </TextField>  
                    <NumberFormat
                            customInput={TextField}
                            type="text"
                            size="small"
                            decimalSeparator={","}
                            style={{textAlign:"center", width:"75%", margin:"3px auto"}}
                            error={latitudeError}
                            label={""}
                            value={Math.abs(value[1])}
                            allowNegative={false}
                            isAllowed= {(values) => {
                                const {floatValue} = values;
                                return floatValue <= 99.999999 || floatValue == null;
                            }}
                            onValueChange={(e) => handleChangeValue(e.floatValue, latitudeDirection, "latitude")}
                            InputProps={{
                                readOnly: read_only
                              }}
                    /> 
                </div>
                <p className={["labelText", "normalMediumSize", "bold"].join(" ")} style={{textAlign:"left", marginTop:"20px"}}>Longitude</p>
                <div style={{display:"flex", flexDirection: "row",}}>
                    <TextField 
                        style={{textAlign:"center", width:"15%", margin:"0px 20px auto auto"}}
                        value={longitudeDirection}
                        onChange={(e) => handleChangeValue(value[0], e.target.value, "longitude")}
                        size="small" 
                        error={longitudeError}
                        fullWidth
                        select
                        InputProps={{
                            readOnly: read_only
                          }}
                    >
                        <MenuItem key={0} value={"E"}><em className="normalText">E</em></MenuItem>
                        <MenuItem key={1} value={"W"}><em className="normalText">W</em></MenuItem>
                    </TextField> 
                    <NumberFormat
                        customInput={TextField}
                        type="text"
                        size="small"
                        decimalSeparator={","}
                        style={{textAlign:"center", width:"75%", margin:"3px auto"}}
                        error={longitudeError}
                        label={""}
                        value={Math.abs(value[0])}
                        allowNegative={false}
                        isAllowed= {(values) => {
                            const {floatValue} = values;
                            return floatValue <= 999.999999 || floatValue == null;
                        }}
                        onValueChange={(e) => handleChangeValue(e.floatValue, longitudeDirection, "longitude")}
                        InputProps={{
                            readOnly: read_only
                          }}
                    /> 
                </div>
            </div>
        </div>
    )
}



export default GeoPointTextField
