import React, { useContext, useEffect, useState } from "react"
import { useParams } from 'react-router-dom'
import AuthContext from "../../../context/auth-context"
import { formatAsCPFCNPJ } from "../../Utils/utils"
import SearchBankslips from "./SearchBankslips"
import SearchBillPayments from "./SearchBillPayments"
import SearchDictOperations from "./SearchDictOperations"
import SearchTransactions from "./SearchTransactions"
import SearchWireTransfers from "./SearchWireTransfers"
import SearchWithdrawals from "./SearchWithdrawals"
import SearchNaturalPersons from "./SearchNaturalPersons"
import SearchLegalPersons from "./SearchLegalPersons"
import { formatAsISBP } from "../utils/utilsTransaction"

const getDictTypeTranslation = (status) => {
    switch (status.toLowerCase()) {
        case "portabilidade":
            return "claim_portability"
        case "cadastro":
            return "registration"
        case "reivindicação":
            return "claim_ownership"
        default:
            return status
    }
}

function SearchInbox(props) {

    let search_term = useParams().search_term

    const [pageParamsBankslips, setPageParamsBankslips] = useState({ page: 1 })
    const [pageParamsBillPayments, setPageParamsBillPayments] = useState({ page: 1 })
    const [pageParamsDictOperations, setPageParamsDictOperations] = useState({ page: 1 })
    const [pageParamsLegalPersons, setPageParamsLegalPersons] = useState({ page: 1 })
    const [pageParamsNaturalPersons, setPageParamsNaturalPersons] = useState({ page: 1 })
    const [pageParamsTransactions, setPageParamsTransactions] = useState({ page: 1 })
    const [pageParamsWireTransfers, setPageParamsWireTransfers] = useState({ page: 1 })
    const [pageParamsWithdrawals, setPageParamsWithdrawals] = useState({ page: 1 })

    useEffect(() => {
        setPageParamsBankslips({ page: 1 })
        setPageParamsBillPayments({ page: 1 })
        setPageParamsDictOperations({ page: 1 })
        setPageParamsLegalPersons({ page: 1 })
        setPageParamsNaturalPersons({ page: 1 })
        setPageParamsTransactions({ page: 1 })
        setPageParamsWireTransfers({ page: 1 })
        setPageParamsWithdrawals({ page: 1 })
    }, [search_term])

    let isCPForCNPJ = false
    let search_term_without_specific_caracters = search_term.replace(/[/.-]/g, '')

    let isOnlyNumericDigits = /^\d+$/.test(search_term_without_specific_caracters)

    if (isOnlyNumericDigits) {
        isCPForCNPJ = search_term_without_specific_caracters.length === 11 || search_term_without_specific_caracters.length === 14
    }

    let isISBP = typeof formatAsISBP(search_term) === "object"

    let search_term_formatted = !isCPForCNPJ
        ? isISBP
            ? formatAsISBP(search_term)[1]
            : getDictTypeTranslation(search_term)
        : formatAsCPFCNPJ(search_term_without_specific_caracters)


    let roles = useContext(AuthContext).user_data.roles


    return (
        <div style={{ width: "auto", paddingBottom: "30px" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <div >
                    <p className={["blueText", "titleSize"].join(" ")}>BUSCA GERAL</p>
                </div>
                <p className={["blueText", "subtitleSize"].join(" ")}>
                    {isISBP
                        ? `Exibindo resultados para a busca por ${formatAsISBP(search_term)[0]} : ${formatAsISBP(search_term)[1]}`
                        : search_term_formatted === "claim_portability" || search_term_formatted === "registration" || search_term_formatted === "claim_ownership"
                            ? `Exibindo resultados para a busca por ${search_term}`
                            : `Exibindo resultados para a busca por ${search_term_formatted}`}
                </p>
            </div>
            <div className="analysisCard">
                <div className="internalCardContainer">
                    {roles.includes("read_onboarding_natural_persons") ?
                        <div>
                            <div className={["blueText", "normalSize"].join(" ")}>
                                Pessoas Físicas
                            </div>
                            <div style={{ marginTop: "10px" }}>
                                <SearchNaturalPersons search_term={search_term_formatted} pageParams={pageParamsNaturalPersons} setPageParams={setPageParamsNaturalPersons} />
                            </div>
                        </div>
                        : null}
                    {roles.includes("read_onboarding_legal_persons") ?
                        <div>
                            <div className={["blueText", "normalSize"].join(" ")} style={{ marginTop: "30px" }}>
                                Pessoas Jurídicas
                            </div>
                            <div style={{ marginTop: "10px" }}>
                                <SearchLegalPersons search_term={search_term_formatted} pageParams={pageParamsLegalPersons} setPageParams={setPageParamsLegalPersons} />
                            </div>
                        </div>
                        : null}
                    {roles.includes("read_bankslips") ?
                        <div>
                            <div className={["blueText", "normalSize"].join(" ")} style={{ marginTop: "30px" }}>
                                Boletos
                            </div>
                            <div style={{ marginTop: "10px" }}>
                                <SearchBankslips search_term={search_term_formatted} pageParams={pageParamsBankslips} setPageParams={setPageParamsBankslips} />
                            </div>
                        </div> : null}
                    {roles.includes("read_bill_payments") ?
                        <div>
                            <div className={["blueText", "normalSize"].join(" ")} style={{ marginTop: "30px" }}>

                                Pagamento de Contas
                            </div>
                            <div style={{ marginTop: "10px" }}>
                                <SearchBillPayments search_term={search_term_formatted} pageParams={pageParamsBillPayments} setPageParams={setPageParamsBillPayments} />
                            </div>
                        </div> : null}
                    {roles.includes("read_wire_transfers") ?
                        <div>
                            <div className={["blueText", "normalSize"].join(" ")} style={{ marginTop: "30px" }}>

                                Transferências
                            </div>
                            <div style={{ marginTop: "10px" }}>
                                <SearchWireTransfers search_term={search_term_formatted} pageParams={pageParamsWireTransfers} setPageParams={setPageParamsWireTransfers} />
                            </div>
                        </div> : null}
                    {roles.includes("read_withdrawals") ?
                        <div>
                            <div className={["blueText", "normalSize"].join(" ")} style={{ marginTop: "30px" }}>

                                Saques
                            </div>
                            <div style={{ marginTop: "10px" }}>
                                <SearchWithdrawals search_term={search_term_formatted} pageParams={pageParamsWithdrawals} setPageParams={setPageParamsWithdrawals} />
                            </div>
                        </div> : null}
                    {roles.includes("read_pix_transactions") ?
                        <div>
                            <div className={["blueText", "normalSize"].join(" ")} style={{ marginTop: "30px" }}>
                                Transações Pix
                            </div>
                            <div style={{ marginTop: "10px" }}>
                                <SearchTransactions search_term={search_term_formatted} pageParams={pageParamsTransactions} setPageParams={setPageParamsTransactions} />
                            </div>
                        </div>
                        : null}
                    {roles.includes("read_pix_dict_operations") ?
                        <div>
                            <div className={["blueText", "normalSize"].join(" ")} style={{ marginTop: "30px" }}>

                                Alterações na DICT
                            </div>
                            <div style={{ marginTop: "10px" }}>
                                <SearchDictOperations search_term={search_term_formatted} pageParams={pageParamsDictOperations} setPageParams={setPageParamsDictOperations} />
                            </div>
                        </div> : null}
                </div>
            </div>
        </div>
    )
}

export default SearchInbox

