import React, { useEffect, useState } from "react";
import { PDFDocument } from "pdf-lib";

export default function PDFContent({ arrayBuffer, imageStyle, imageAlt }) {
    const [pdfFileData, setPdfFileData] = useState(null);

    const pdfStyle = {
        borderRadius: imageStyle.borderRadius,
        margin: "auto",
        width: "560px",
        height: "560px"
    }

    function renderPdf(uint8array) {
        const tempblob = new Blob([uint8array], {
            type: "application/pdf",
        });
        const docUrl = URL.createObjectURL(tempblob);
        setPdfFileData(docUrl);
    }

    function range(start, end) {
        let length = end - start + 1;
        return Array.from({ length }, (_, i) => start + i - 1);
    }

    useEffect(() => {
        async function fetchData() {
            async function extractPdfPage() {
                const pdfSrcDoc = await PDFDocument.load(arrayBuffer);
                const pdfNewDoc = await PDFDocument.create();
                const pages = await pdfNewDoc.copyPages(
                    pdfSrcDoc,
                    range(1, pdfSrcDoc.getPageCount())
                );
                pages.forEach((page) => pdfNewDoc.addPage(page));
                const newpdf = await pdfNewDoc.save();
                return newpdf;
            }

            const newPdfDoc = await extractPdfPage();
            renderPdf(newPdfDoc);
        }

        fetchData();
    }, [arrayBuffer]);

    if (!pdfFileData) {
        return null
    } else {
        return (
            <iframe
                style={pdfStyle}
                alt={imageAlt}
                title="PdfFrame"
                src={pdfFileData}
                type="application/pdf"
            ></iframe>
        );
    }
}