import React, {useState, useReducer} from "react";
import InputContent from "./InputContent"
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import {decisionReducer} from "../../../../Utils/requestUtils";
import Typography from '@material-ui/core/Typography';
import { getConfirmationIconDialog} from "../../../utils/utils"
import { CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';



const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });



const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });



function SharedListPermissionCreationDialog(props) {
    
  const handleDialogClose = () => {
    onClose()
    setNewSharedListPermissions(unpermissionedCompanies)
  }


  let {unpermissionedCompanies, list_key, onClose, setReloadSharedListPermissionsTable, companyKey, userData} = props


  const [newSharedListPermissions, setNewSharedListPermissions] = useState(unpermissionedCompanies)


  const [dialogState, setDialogState] = useReducer(
    decisionReducer,
    {isLoading: false, isError: false, finished:false}
  ) 


  const checkPermissionsGiven = () => {
    if (newSharedListPermissions.filter(item => (item.can_read || item.can_write)).length > 0) return true
    else return false
  }


  const onSubmit = () => {
    if (!checkPermissionsGiven()) return
    let payload = newSharedListPermissions.reduce((acc, item) =>{
        if (item.can_read === true || item.can_write === true){
          let newObject = {
            company_key: item.company_key,
            can_read: item.can_read,
            can_write:item.can_write
          }
          acc = [...acc, newObject]
        }
        return acc
    },[])

    payload = {"shared_list_permissions": payload}
    setDialogState({type:"send_request_init"})
    let requestHeaders = {headers:{}}
    if (userData.business_group_key) {
        requestHeaders = {headers:{company_key:companyKey}}
    }
    axios.post('/dash/lists/' + list_key + '/shared_list_permission', payload, requestHeaders).then(response => {
        setDialogState({type:"send_request_success"})
    }).catch(error => {
        setDialogState({type: "send_request_failure"})
    });
  }


  if (dialogState.isLoading){
    return (
      <Dialog 
      open={props.open} 
      onClose={handleDialogClose}
      disableBackdropClick
      maxWidth="lg"
      keepMounted={false}>
          <DialogTitle 
              className={['blueText', "subtitleSize"].join(" ")} 
              onClose={handleDialogClose}
          >
              Novas Permissões
          </DialogTitle>
          <DialogContent >
              <div style={{width:"400px", minHeight: "160px", display:"flex", flexDirection:"column", marginBottom:"30px"}}>
                <CircularProgress style={{margin:"auto"}}/>
              </div>
          </DialogContent>
      </Dialog>
    )
  }
  else if (dialogState.finished) {
    return (
      <Dialog 
      open={props.open} 
      onClose={handleDialogClose}
      disableBackdropClick
      maxWidth="lg"
      keepMounted={false}>
          <DialogTitle 
              className={['blueText', "subtitleSize"].join(" ")} 
              onClose={() => {
                onClose()
                setReloadSharedListPermissionsTable(true)
                }           
              }
          >
              Novas Permissões
          </DialogTitle>
          <DialogContent >
              <div style={{width:"400px", minHeight: "160px", display:"flex", flexDirection:"column", marginBottom:"30px"}}>
                <div style={{ display:"flex", flexDirection:"column", flexGrow:"1"}}>
                  <div style={{margin:"auto"}}>
                      {getConfirmationIconDialog(!dialogState.isError)}
                  </div>
                  <div style={{margin:"10px auto", textAlign:"center"}}>
                      {dialogState.message}
                  </div>
                  <div
                      onClick={() => {
                          onClose()
                          setReloadSharedListPermissionsTable(true)
                      }} 
                      className={["button","standard", "normalText", "normalMediumSize"].join(" ")} 
                      style={{margin:"20px auto 10px auto", width:"auto"}}
                  >
                      VOLTAR À PÁGINA INICIAL
                  </div>
                </div>
              </div>
          </DialogContent>
      </Dialog>
    )
  }
  else {
    return (
      <Dialog 
      open={props.open} 
      onClose={handleDialogClose}
      disableBackdropClick
      maxWidth="lg"
      keepMounted={false}>
          <DialogTitle 
              className={['blueText', "subtitleSize"].join(" ")} 
              onClose={handleDialogClose}
          >
              Novas Permissões
          </DialogTitle>
          <DialogContent >
              <div style={{width:"400px", minHeight: "160px", display:"flex", flexDirection:"column", marginBottom:"30px"}}>
                <InputContent 
                  newSharedListPermissions = {newSharedListPermissions}
                  setNewSharedListPermissions = {setNewSharedListPermissions}
                  list_key = {list_key}
                  onClose={handleDialogClose}
                />
                <div style={{marginTop:"20px", marginLeft:"auto"}}>
                  <div
                      className={(!checkPermissionsGiven()) ? ["button","standard", "disabled", "normalText", "normalMediumSize"].join(" ") : ["button","standard", "normalText", "normalMediumSize"].join(" ")} 
                      onClick={onSubmit} 
                  >
                      Salvar Permissões
                  </div>
                </div>
              </div>
          </DialogContent>
      </Dialog>
    )
  }
}

export default SharedListPermissionCreationDialog