import React, {useState, useReducer} from 'react';
import TextField from '@material-ui/core/TextField'; 
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import {getTextField, validateConstantValues, getConfirmationIconDialog, translateErrorMessage} from "../../../utils/utils";
import _ from "lodash";
import {decisionReducer} from "../../../../Utils/requestUtils";
import { CircularProgress } from '@material-ui/core';
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";

const useStyles = makeStyles((theme) => ({
    tableStyle: {
      border: "none",
      boxShadow: "none",
      width:"max-content"
    },
    blueText: {
      fontFamily: "Open Sans",
      fontWeight: "600",
      color: "#0b1f82"
  },
    tableText: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      lineHeight: "16px",
      textAlign: "center",
      margin: "10px",
      padding: "10px"
    },
    tableHeader: {
      textAlign: "center",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: "normal",
      color: '#6F6F6F',
      fontSize: "14px",
      lineHeight: "19px",
      padding: "10px",
    }
}));

function DeleteMany (props){
    const classes = useStyles();
    let { list, onClose, handleReloadPage, companyKey, userData } = props

    const referenceFieldProperties = list.fields.reduce((acc,item) => {
        acc.push({fieldName: item.name, fieldType: item.type.enum})
        return acc
    },[])
    
    const getLineRepresentationObject = () => {
        let lineRepresentationObject = referenceFieldProperties.reduce((acc,item) => {
            let initialValue
            let initialError
            if (item.fieldType === "geo_point") {
                initialValue = [null, null] 
                initialError = true
            }
            else {
                initialValue = ""
                initialError = true
            }
            acc[item.fieldName] = {value:initialValue,
                                   type:item.fieldType,
                                   error:initialError}
            return acc
        },{})
        lineRepresentationObject.isEditable = true
        return lineRepresentationObject
    }

    const translateType = (type) => {
        switch(type){
            case "bool":
                return ("Binário")
            case "number":
                return ("Número")
            case "string":
                return ("Texto")
            case "datetime":
                return ("Data e Hora")
            case "time":
                return ("Hora")
            case "timedelta":
                return ("Período de Tempo")
            case "geo_point":
                return ("Coordenada Geográfica")
            case "geo_shape":
                return ("Polígono Geográfico")
            case "":
                return ("Selecione")
            default:
                throw new Error("Invalid type" + type)
        }
    }

    const [inputedListContents, setInputedListContents] = useState([_.cloneDeep(getLineRepresentationObject())])
    const [editableListLine, setEditableListLine]    = useState(_.cloneDeep(getLineRepresentationObject()))
    const [customizedErrorMessage, setCustomizedErrorMessage] = useState("")    
    const [showDeleteButton, setshowDeleteButton] = useState(false)    
    const [disabled, setDisabled] = useState(false)  

    const handleEditListLine = (index) => {
        setDisabled(false)
        setshowDeleteButton(false)
        let newInputListContents = [...inputedListContents]
        newInputListContents[index].isEditable = true
        let newEditableListLine = {...newInputListContents[index]}

        for(let field_name in newEditableListLine){
            if(newEditableListLine[field_name].value){
                newEditableListLine[field_name].value = newEditableListLine[field_name].value.join("\n")
            }
        }

        setInputedListContents(newInputListContents)
        setEditableListLine(newEditableListLine)
    }

    const checkEditableLines = () => {
        let editableLineIndex = null
        if (editableListLine !== null) {
            for(let i = 0; i < inputedListContents.length; i++) {
                if(inputedListContents[i].isEditable === true) {
                    editableLineIndex = i
                    break
                };
            }
        }
        return editableLineIndex
    }

    const handleChangeValue = (newValue, fieldName) => {
        let newEditableListLine = _.cloneDeep(editableListLine)
        newEditableListLine[fieldName].value = newValue
        newEditableListLine[fieldName].error = validateConstantValues(newEditableListLine[fieldName].type, newEditableListLine[fieldName].value)
        setEditableListLine(newEditableListLine)
    }


    const checkEditableFieldsError = () => {
        let isError = referenceFieldProperties.reduce((acc, item) => {
            if (editableListLine[item.fieldName].error === true) acc = true
            return acc
        },false)
        return isError
    }


    const handleConfirm = () => {
        let editableLineIndex = checkEditableLines()
        let newEditableListLine = _.cloneDeep(editableListLine)
        let newInputedListContents = _.cloneDeep(inputedListContents)

        newEditableListLine.isEditable = false

        for(let field_name in newEditableListLine){
            if(newEditableListLine[field_name].value){
                newEditableListLine[field_name].value = newEditableListLine[field_name].value.split("\n")
                newEditableListLine[field_name].value = newEditableListLine[field_name].value.filter(el => el!== "")
            }
        }
        
        if (editableLineIndex == null){
            newInputedListContents = [...inputedListContents, newEditableListLine]
        }
        else {
            newInputedListContents[editableLineIndex] = newEditableListLine
        }
        
        setInputedListContents(newInputedListContents)
        setshowDeleteButton(true)
        setDisabled(true)
    }

    const [dialogState, setDialogState] = useReducer(
        decisionReducer,
        {isLoading: false, isError: false, finished:false}
    ) 

    const onSubmit = () => {
        if (!(checkEditableLines() === null && inputedListContents.length > 0)) return
        let list_items

        for(let field_name in inputedListContents[0]){
            if(inputedListContents[0][field_name].value){
                list_items = inputedListContents[0][field_name].value
            }
        }
        
        let payload = {list_items: list_items}
        setDialogState({type:"send_request_init"})
        let requestHeaders = {headers:{}}
        if (userData.business_group_key) {
            requestHeaders = {headers:{company_key:companyKey}}
        }
        axios.put('/dash/lists/' + list.list_key + '/items/by_key_field_value', payload, requestHeaders).then(response => {
            setDialogState({type:"send_request_success"})
        }).catch(error => {
            setDialogState({type: "send_request_failure"})
            setCustomizedErrorMessage(error.response.data.message)
        });
    }

    const renderTextField= (field) => {
        let fieldObject = editableListLine[field.fieldName]
        let standardField = getTextField(fieldObject.type)
        switch(field.fieldType) {
            case "string":
                return (
                    <TextField 
                        {...standardField.props}
                        error={fieldObject.error}
                        value={fieldObject.value}
                        onChange={(e) => handleChangeValue(e.target.value,field.fieldName)}
                        label={translateType(field.fieldType)}
                        variant="outlined"
                        multiline
                        disabled={disabled}
                        size="small"    
                    />
                )       
            default:
                throw new Error ("Invalid field Type")
        }
    }


    if(dialogState.finished && dialogState.isLoading){
		return (
            <div style={{width: "310px", minHeight:"217px", display:"flex"}}>
                <CircularProgress style={{margin:"auto"}} />
            </div>
		)
    }
    else if (dialogState.finished){
        let confirmationData = {    
            message: dialogState.message,
            success: !dialogState.isError
        }
		return (
        <div style={{width: "310px", minHeight:"217px"}}>
            <div className="internalCardContainer" style={{display:"flex", height:"40%", justifyContent:"center"}}>
                {getConfirmationIconDialog(confirmationData.success)}
            </div>
            <div className={["internalCardContainer", "normalMediumSize"].join(" ")} style={{display:"flex", textAlign:"center", justifyContent:"center"}}>
                {confirmationData.success ? confirmationData.message : translateErrorMessage(customizedErrorMessage)}
            </div>
            <div className="internalCardContainer" style={{display:"flex", justifyContent:"center"}}>
                <div 
                    className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                    onClick={() => {
                        onClose()
                        handleReloadPage()
                    }} 
                >
                    VOLTAR À TELA INICIAL 
                </div>
            </div>
        </div>
		)
    }
    else {
        return(
            <Paper className={classes.tableStyle}>
            <div className={['blueText', "normalMediumSize"].join(" ")} style={{marginTop:"40px", marginBottom:"20px", fontWeight:"600", backgroundColor:"#EFEFEF", padding:"10px 15px"}}>
                <div style={{display:"flex", flexDirection:"column", marginTop:"5px"}}>
                    <Table style={{ padding: "8px" }}>
                        <TableHead>
                            <TableRow>
                                {referenceFieldProperties.map((fieldProperties, index) => (
                                <TableCell key={index} className={classes.tableHeader} style={{display: "flex", justifyContent: "center"}}>
                                    {fieldProperties.fieldName}
                                    <Tooltip title={<div className="tooltipText">
                                        Adicione um item por linha (para mover para a próxima linha, pressione a tecla "Enter" do seu teclado).</div>}>
                                        <HelpIcon style={{fontSize: "14px", display:"flex", margin:"auto 5px"}}/>
                                    </Tooltip>
                                </TableCell>))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                                <TableRow> 
                                    {referenceFieldProperties.map((field, innerIndex) =>
                                    <TableCell padding={"default"} 
                                    key={innerIndex} 
                                    className={classes.tableText}
                                    style={{width:"250px"}}>
                                        {renderTextField(field)}
                                    </TableCell>)}
                            </TableRow>
                        </TableBody>
                    </Table>
                    <div style={{cursor:"pointer", fontWeight:"600", marginBottom:"10px",marginTop:"5px", marginLeft:"auto"}}
                            onClick={checkEditableFieldsError() ? null : handleConfirm}
                            className={checkEditableFieldsError() ? "labelText" : "blueTextLabel"}>
                        {checkEditableLines() == null ? null : "CONFIRMAR EXCLUSÕES"}
                    </div>
                </div>
            </div>
            {showDeleteButton && 
            <div>
                <div className="internalCardContainer" style={{display:"flex", flexDirection: "column", marginTop: "10px", marginBottom: "0px"}}>
                    <div style={{width:"300px", textAlign: "justify", margin:"0 0 auto 0"}}>
                        <strong>Tem certeza que deseja excluir este(s) item(ns)?</strong>
                        <p>Certifique-se que cada item esteja devidamente formatado de acordo com a informação original a ser excluída. </p>
                    </div>
                    <div style={{display:"flex", flexDirection: "row", justifyContent: "center", marginTop: "10px", marginBottom: "0px"}}>
                        <div
                            className={
                                (checkEditableLines() === null  && inputedListContents.length > 0) &&
                                ["button", "standard", "normalText", "normalMediumSize"].join(" ")
                            }
                            style={{width:"120px", fontWeight:"600", paddingTop:"7px"}}
                            onClick={checkEditableLines() === null ? () => handleEditListLine(0) : null} 
                        >
                            Editar
                        </div>  
                        <div
                            className={
                                (checkEditableLines() === null  && inputedListContents.length > 0) ?
                                ["button", "reproved", "normalText", "normalMediumSize"].join(" ") :
                                ["button", "disabled", "standard", "normalText", "normalMediumSize"].join(" ")
                            }
                            style={{width:"120px", fontWeight:"600", paddingTop:"7px"}}
                            onClick={(checkEditableLines() === null && inputedListContents.length > 0) ? onSubmit : null}
                        >
                            Excluir
                        </div>  
                    </div>
                </div>
            </div>}
            </Paper>
        )
    }
}

export default DeleteMany
