import React from "react"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core"
import { formatAsMoney } from "../../../utils/utils"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
  },
  tableText: {
    fontFamily: "Open Sans",
    fontWeight: 'normal',
    fontSize: "12px",
    lineHeight: "16px",
    color: "#141414",
    textAlign: "center",
    margin: "10px",
    padding: "6px",
    minWidth: "120px"
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: '#6F6F6F',
    fontSize: "14px",
    lineHeight: "19px",
    padding: "6px",
  },
  even: {
    background: "#F3F3F3",
  },
  odd: {
    background: "#FFFFFF",
  },
}))

function ProtestsTable({ protests_data, isQuod }) {
  const { t } = useTranslation()

  const classes = useStyles()
  useTheme()

  const getClass = (rowIndex) => {
    if (rowIndex % 2 === 0) {
      return classes.even
    }
  }

  return (
    isQuod ? (
      <Paper className={classes.tableStyle}>
        <Table style={{ padding: "8px" }}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader}>{t("Cartório")}</TableCell>
              <TableCell className={classes.tableHeader}>{t("Cidade")}</TableCell>
              <TableCell className={classes.tableHeader}>{t("Quantidade de Protestos")}</TableCell>
              <TableCell className={classes.tableHeader}>{t("Valor Total")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {protests_data.map((violation, index) => (
              <TableRow
                key={index}
                className={getClass(index)}
              >
                <TableCell className={classes.tableText}>
                  {violation.notary_name == null ? "-" : violation.notary_name.toUpperCase()}
                </TableCell>
                <TableCell className={classes.tableText}>
                  {violation.city == null ? "-" : violation.city.toUpperCase()}
                </TableCell>
                <TableCell className={classes.tableText}>
                  {violation.protests == null ? "-" : violation.protests}
                </TableCell>
                <TableCell className={classes.tableText}>
                  {violation.amount == null ? "-" : formatAsMoney("BRL", violation.amount)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    ) : (
      <Paper className={classes.tableStyle}>
        <Table style={{ padding: "8px" }}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader}>{t("notarys_office")}</TableCell>
              <TableCell className={classes.tableHeader}>{t("city")}</TableCell>
              <TableCell className={classes.tableHeader}>{t("protest_date")}</TableCell>
              <TableCell className={classes.tableHeader}>{t("expiration_date")}</TableCell>
              <TableCell className={classes.tableHeader}>{t("amount")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {protests_data.map((violation, index) => (
              <TableRow
                key={index}
                className={getClass(index)}
              >
                <TableCell className={classes.tableText}>
                  {violation.description == null ? "-" : violation.description.toUpperCase()}
                </TableCell>
                <TableCell className={classes.tableText}>
                  {violation.city == null ? "-" : violation.city.toUpperCase() + " - " + violation.state.toUpperCase()}
                </TableCell>
                <TableCell className={classes.tableText}>
                  {violation.protest_date == null ? "-" : t("date_format", {date_format: violation.protest_date})}

                </TableCell>
                <TableCell className={classes.tableText}>
                  {violation.expiration_date == null ? "-" : t("date_format", {date_format: violation.expiration_date})}
                </TableCell>
                <TableCell className={classes.tableText}>
                  {violation.amount == null ? "-" : formatAsMoney("BRL", violation.amount)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    )
  )
}

export default ProtestsTable
