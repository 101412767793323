import React, {
  useEffect,
  useContext,
  useState,
} from "react";
import AuthContext from "../../../context/auth-context";
import CreditAnalysisClientProfile from "./CreditAnalysisClientProfile/CreditAnalysisClientProfile";
import Summary from "./Summary/Summary";
import Indicators from "../Cards/Indicators/Indicators"
import SCR from "./Cards/SCRCard/SCR"
import Serasa from "./Cards/SerasaCard/Serasa"
import Observation from "./Observation/Observation"
import ErrorBoundary from '../../Utils/ErrorBoundary'
import { getProperMetadata, getPDFHeader, objetoVazio, colorDarkBlueQI, checkNested } from "../utils/utils"
import CreditProposalCard from "../Cards/CreditProposalCard/CreditProposalCard";
import DAFNCard from "../Cards/DAFNCard/DAFNCard";
import UNSCCard from "./Cards/UNSCCard/UNSCCard";
import OFACCard from "./Cards/OFACCard/OFACCard";
import PEPCard from "./Cards/PEPCard/PEPCard";
import Boavista from "./Cards/BoavistaCard/Boavista";
import ScorePositivo from "./Cards/ScorePositivoCard/ScorePositivo";
import PEPRelatedCard from "./Cards/PEPRelatedCard/PEPRelatedCard";
import ProtestsCard from "../Cards/ProtestsCard/ProtestsCard";
import CustomDataCard from "../Cards/CustomDataCard/CustomDataCard";
import SCRtoPDF from "./Cards/SCRCard/SCRtoPDF";
import SerasaToPDF from "./Cards/SerasaCard/SerasaToPDF";
import SummaryToPDF from "./Summary/SummaryToPDF";
import { useTranslation } from "react-i18next";
import Quod from "../Cards/Quod/Quod";
import BoavistaNoData from "./Cards/BoavistaCard/BoavistaNoData";
import AnalysisCardContainer from "../utils/Components/AnalysisCardContainer";
import ManualAnalysisBeforeRuleExecution from "./ManualAnalysisBeforeRuleExecution/ManualAnalysisBeforeRuleExecution";
import Payslip from "./Payslip/Payslip";
import BankStatement from "./BankStatement/BankStatement";
import BureauBasicDataCard from "./Cards/BureauBasicDataCard/BureauBasicDataCard";
import BoavistaToPDF from "./Cards/BoavistaCard/BoavistaToPDF";
import CriminalRecordsCard from "./Cards/CriminalRecordsCard/CriminalRecordsCard"
import ProcessesCard from "./Cards/ProcessesCard/ProcessesCard"

function CreditAnalysisNaturalPersonCards(props) {

  const { t } = useTranslation();
  
  let user_data = useContext(AuthContext).user_data;
  
  const { credit_analysis, preparePDF, setButtonExport2PDF, pdf, credit_proposal_natural_person_key, expandedStates, isChained } = props
  const { expandedSCR, setExpandedSCR, expandedSerasa, setExpandedSerasa, expandedBoavista, setExpandedBoavista, expandedQuod, setExpandedQuod, expandedScorePositivo, setExpandedScorePositivo } = expandedStates

  const [scoreToImg, setScoreToImg] = useState("");
  const [hasSummaryScoreImg, setHasSummaryScoreImg] = useState(false);

  const [scrToExpire2Img, setScrToExpire2Img] = useState("");
  const [scrOverdue2Img, setScrOverdue2Img] = useState("");
  const [hasSCRImg, setHasSCRImg] = useState(false);

  const [serasaScore2Img, setSerasaScore2Img] = useState("");
  const [serasaProbability2Img, setSerasaProbability2Img] = useState("");
  const [hasSerasaImg, setHasSerasaImg] = useState(false);

  const [scrHistoryTotal2Img, setScrHistoryTotal2Img] = useState("");
  const [scrHistoryToExpireOverdue2Img, setScrHistoryToExpireOverdue2Img] = useState("");
  const [hasSCRHistoryImg, setHasSCRHistoryImg] = useState(false);

  const [boavistaInquiries2Img, setBoavistaInquiries2Img] = useState("");
  const [hasBoavistaInquiriesImg, setHasBoavistaInquiriesImg] = useState(false);

  const imagesReady = {
    summaryScore: {
      hasInPDF: hasSummaryScoreImg,
      isReady: !!scoreToImg
    },
    SCR: {
      hasInPDF: hasSCRImg,
      isReady: !!scrToExpire2Img && !!scrOverdue2Img
    },
    SCRHistory: {
      hasInPDF: hasSCRHistoryImg,
      isReady: !!scrHistoryTotal2Img && !!scrHistoryToExpireOverdue2Img
    },
    Serasa: {
      hasInPDF: hasSerasaImg,
      isReady: !!serasaScore2Img && !!serasaProbability2Img
    },
    BoavistaInquiries: {
      hasInPDF: hasBoavistaInquiriesImg,
      isReady: !!boavistaInquiries2Img
    }
  }

  const showExportToPDFButtonHandler = (imagesReady) => {
    for (let key of Object.keys(imagesReady)) {
      if (imagesReady[key].hasInPDF === true && imagesReady[key].isReady === false) {
        return false
      }
    }
    return true
  }

  let showExportToPDFButton = showExportToPDFButtonHandler(imagesReady)

  useEffect(() => {
    if (preparePDF && showExportToPDFButton) {
      setTimeout(() => {
        setButtonExport2PDF(true);
      }, "15000");
    }
  }, [showExportToPDFButton, preparePDF, setButtonExport2PDF]);

  let decision_metadata = credit_analysis.fetchedData
    .credit_proposal_natural_person_analysis_events
    ? getProperMetadata(
      credit_analysis.fetchedData
        .credit_proposal_natural_person_analysis_events
    )
    : "";

  let isManualAnalysisBeforeRule = credit_analysis.fetchedData.analysis_status === "in_manual_analysis_before_rule_execution";
  let hasManualApprovalBeforeRule = credit_analysis.fetchedData.credit_proposal_natural_person_analysis_events.filter((event)=>((event||{}).new_status==="manually_approved_before_rule_execution")).length === 1

  let hasAutomaticDecision =
    credit_analysis.fetchedData.analysis_status === "automatically_approved" ||
    credit_analysis.fetchedData.analysis_status === "automatically_reproved";

  let [payslipPictureOpen, setPayslipPictureOpen] = useState(false);
  let [bankStatementPictureOpen, setBankStatementPictureOpen] = useState(false);

  return (
    <>
      <div style={{ display: "grid", gridTemplateColumns: "32.5% 35% 32.5%" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <AnalysisCardContainer grow={true}>
            <CreditProposalCard credit_analysis={credit_analysis.fetchedData} />
          </AnalysisCardContainer>
          {credit_analysis.fetchedData.custom_data &&
            <AnalysisCardContainer grow={true}>
              <CustomDataCard data={credit_analysis.fetchedData} />
            </AnalysisCardContainer>}
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <AnalysisCardContainer grow={true}>
            <CreditAnalysisClientProfile credit_analysis={credit_analysis.fetchedData}/>
          </AnalysisCardContainer>
          <AnalysisCardContainer grow={true}>
            <Summary
              credit_analysis={credit_analysis.fetchedData}
              preparePDF={preparePDF}
              setScoreToImg={setScoreToImg}
              setHasSummaryScoreImg={setHasSummaryScoreImg}
            />
          </AnalysisCardContainer>
        </div>
        <AnalysisCardContainer grow={true}>
          <Indicators indicators={decision_metadata.indicators} />
        </AnalysisCardContainer>
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {checkNested(credit_analysis.fetchedData,"documents","payslip","images")&&
        <AnalysisCardContainer grow={true}>
          <Payslip
            payslipPictureOpen={payslipPictureOpen}
            handlePayslipPictureOpen={()=>setPayslipPictureOpen(true)}
            handlePayslipPictureClose={()=>setPayslipPictureOpen(false)}
            sourceImageList={credit_analysis.fetchedData.documents.payslip.images}
          />
        </AnalysisCardContainer>}
        {checkNested(credit_analysis.fetchedData,"documents","bank_statement","images")&&
        <AnalysisCardContainer grow={true}>
          <BankStatement
            bankStatementPictureOpen={bankStatementPictureOpen}
            handleBankStatementPictureOpen={()=>setBankStatementPictureOpen(true)}
            handleBankStatementPictureClose={()=>setBankStatementPictureOpen(false)}
            sourceImageList={credit_analysis.fetchedData.documents.bank_statement.images}
          />
        </AnalysisCardContainer>}
      </div>
      <div style={{display:"flex", flexDirection:"column", gap:"2px"}}>
        {(decision_metadata || {}).registration_data &&
          <AnalysisCardContainer grow={false}>
            <BureauBasicDataCard
              registration_data={decision_metadata.registration_data}
            />
          </AnalysisCardContainer>}
        {(decision_metadata || {}).protests &&
          <AnalysisCardContainer grow={false}>
            <ProtestsCard
              protests={decision_metadata.protests}
              isPDF={false}
            />
          </AnalysisCardContainer>}
        {(decision_metadata || {}).ofac &&
          <AnalysisCardContainer grow={false}>
            <OFACCard ofac={decision_metadata.ofac} isPDF={false} />
          </AnalysisCardContainer>
        }
        {(decision_metadata || {}).unsc &&
          <AnalysisCardContainer grow={false}>
            <UNSCCard unsc={decision_metadata.unsc} isPDF={false} />
          </AnalysisCardContainer>
        }
        {(decision_metadata || {}).pep &&
          <AnalysisCardContainer grow={false}>
            <PEPCard pep={decision_metadata.pep} isPDF={false} />
          </AnalysisCardContainer>
        }
        {(decision_metadata || {}).related_pep &&
          <AnalysisCardContainer grow={false}>
            <PEPRelatedCard related_pep={decision_metadata.related_pep} isPDF={false} />
          </AnalysisCardContainer>
        }
        {(decision_metadata || {}).dafn &&
          <AnalysisCardContainer grow={false}>
            <DAFNCard
              dafn={decision_metadata.dafn}
              isPDF={false}
              person_type={"natural"}
            />
          </AnalysisCardContainer>
        }
        {(decision_metadata || {}).criminal_records ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<CriminalRecordsCard criminal_records={decision_metadata.criminal_records}/>
						</ErrorBoundary>
					</div> : null}
        {(decision_metadata || {}).processes ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<ProcessesCard processes={decision_metadata.processes}/>
						</ErrorBoundary>
					</div> : null}
        {(decision_metadata || {}).boavista_data ?
          (!objetoVazio(decision_metadata.boavista_data) && (decision_metadata.boavista_data.data.length) === 1) ?
            (<div className="analysisCardContainer pagebreak">
              <ErrorBoundary>
                <Boavista
                  boavista={decision_metadata.boavista_data}
                  setExpandedBoavista={setExpandedBoavista}
                  expandedBoavista={expandedBoavista}
                  isPDF={false}
                  preparePDF={preparePDF}
                  setBoavistaInquiries2Img={setBoavistaInquiries2Img}
                  setHasBoavistaInquiriesImg={setHasBoavistaInquiriesImg}
                />
              </ErrorBoundary>
            </div>)
            :
            (<div className="analysisCardContainer"><ErrorBoundary><BoavistaNoData /></ErrorBoundary></div>)
          : null}
        {(decision_metadata || {}).score_positivo ?
          (!objetoVazio(decision_metadata.score_positivo) && decision_metadata.score_positivo.data && (decision_metadata.score_positivo.data.length) === 1) ?
            (<div className="analysisCardContainer pagebreak">
              <ErrorBoundary>
                <ScorePositivo
                  score_positivo={decision_metadata.score_positivo}
                  setExpandedScorePositivo={setExpandedScorePositivo}
                  expandedScorePositivo={expandedScorePositivo}
                />
              </ErrorBoundary>
            </div>)
            :
            (<div className="analysisCardContainer"><ErrorBoundary><BoavistaNoData /></ErrorBoundary></div>)
          : null}
        {(decision_metadata || {}).quod &&
          <AnalysisCardContainer grow={false}>
            <Quod
              quod={decision_metadata.quod}
              expandedQuod={expandedQuod}
              setExpandedQuod={setExpandedQuod}
            />
          </AnalysisCardContainer>}
        {(decision_metadata || {}).serasa?.document_number &&
          <AnalysisCardContainer grow={false}>
            <Serasa
              serasa={decision_metadata.serasa}
              setExpandedSerasa={setExpandedSerasa}
              expandedSerasa={expandedSerasa}
              preparePDF={preparePDF}
              setSerasaScore2Img={setSerasaScore2Img}
              setSerasaProbability2Img={setSerasaProbability2Img}
              setHasSerasaImg={setHasSerasaImg}
            />
          </AnalysisCardContainer>}
        {(decision_metadata || {}).scr &&
          <div className="analysisCardContainer pagebreak">
            <ErrorBoundary>
              <SCR
                scr={decision_metadata.scr}
                setExpandedSCR={setExpandedSCR}
                expandedSCR={expandedSCR}
                preparePDF={preparePDF}
                setScrToExpire2Img={setScrToExpire2Img}
                setScrOverdue2Img={setScrOverdue2Img}
                setScrHistoryTotal2Img={setScrHistoryTotal2Img}
                setScrHistoryToExpireOverdue2Img={setScrHistoryToExpireOverdue2Img}
                setHasSCRImg={setHasSCRImg}
                setHasSCRHistoryImg={setHasSCRHistoryImg}
              />
            </ErrorBoundary>
          </div>}
        {!isChained&&(isManualAnalysisBeforeRule||hasManualApprovalBeforeRule)&&
        <AnalysisCardContainer grow={false}>
          <ManualAnalysisBeforeRuleExecution
            credit_proposal_natural_person_key={credit_proposal_natural_person_key}
            getNaturalPersonByKey={props.getNaturalPersonByKey?props.getNaturalPersonByKey:null}
            credit_analysis={credit_analysis.fetchedData}
            isManualAnalysisBeforeRule={isManualAnalysisBeforeRule}
          />
        </AnalysisCardContainer>}
        {!isManualAnalysisBeforeRule&&!hasAutomaticDecision && (
          <AnalysisCardContainer grow={false}>
            <Observation
              credit_analysis={credit_analysis.fetchedData}
              credit_proposal_natural_person_key={credit_proposal_natural_person_key}
              user_data={user_data}
              isChained={isChained}
            />
          </AnalysisCardContainer>
        )}
      </div>
      {preparePDF && (
        <div style={{ display: "none" }}>
          <div style={{ paddingBottom: "40px" }} ref={pdf}>
            <div>
              {getPDFHeader(t("credit_analysis_natural_person_subtitle"), colorDarkBlueQI)}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "10px 0px",
              }}
            >
              <AnalysisCardContainer className="analysisCardContainer avoidPageBreakInside" grow={false}>
                <CreditProposalCard credit_analysis={credit_analysis.fetchedData} />
              </AnalysisCardContainer>
              <AnalysisCardContainer className="analysisCardContainer avoidPageBreakInside" grow={false}>
                <CreditAnalysisClientProfile credit_analysis={credit_analysis.fetchedData} />
              </AnalysisCardContainer>
              <AnalysisCardContainer className="analysisCardContainer avoidPageBreakInside" grow={false}>
                <SummaryToPDF credit_analysis={credit_analysis.fetchedData} scoreToImg={scoreToImg} />
              </AnalysisCardContainer>
              <AnalysisCardContainer className="analysisCardContainer avoidPageBreakInside" grow={false}>
                <Indicators indicators={decision_metadata.indicators} />
              </AnalysisCardContainer>
            </div>
            <div style={{display:"flex", flexDirection:"column", gap:"2px"}}>
              {(decision_metadata || {}).protests ? (
                <AnalysisCardContainer className="analysisCardContainer avoidPageBreakInside" grow={false}>
                  <ProtestsCard
                    protests={decision_metadata.protests}
                    isPDF={true}
                  />
                </AnalysisCardContainer>
              ) : null}
              {(decision_metadata || {}).ofac ? (
                <AnalysisCardContainer className="analysisCardContainer avoidPageBreakInside" grow={false}>
                  <OFACCard ofac={decision_metadata.ofac} isPDF={true} />
                </AnalysisCardContainer>
              ) : null}
              {(decision_metadata || {}).unsc ? (
                <AnalysisCardContainer className="analysisCardContainer avoidPageBreakInside" grow={false}>
                  <UNSCCard unsc={decision_metadata.unsc} isPDF={true} />
                </AnalysisCardContainer>
              ) : null}
              {(decision_metadata || {}).pep ? (
                <AnalysisCardContainer className="analysisCardContainer avoidPageBreakInside" grow={false}>
                  <PEPCard pep={decision_metadata.pep} isPDF={true} />
                </AnalysisCardContainer>
              ) : null}
              {(decision_metadata || {}).related_pep ? (
                <AnalysisCardContainer className="analysisCardContainer avoidPageBreakInside" grow={false}>
                  <PEPRelatedCard
                    related_pep={decision_metadata.related_pep}
                    isPDF={true}
                  />
                </AnalysisCardContainer>
              ) : null}
              {(decision_metadata || {}).dafn ? (
                <AnalysisCardContainer className="analysisCardContainer avoidPageBreakInside" grow={false}>
                  <DAFNCard
                    dafn={decision_metadata.dafn}
                    isPDF={true}
                    person_type={"natural"}
                  />
                </AnalysisCardContainer>
              ) : null}
              {(decision_metadata || {}).boavista_data ?
                (!objetoVazio(decision_metadata.boavista_data) && (decision_metadata.boavista_data.data.length) === 1) ?
                  (<AnalysisCardContainer grow={true}>
                    <BoavistaToPDF
                      boavista={decision_metadata.boavista_data}
                      isPDF={true}
                      hasBoavistaInquiriesImg={hasBoavistaInquiriesImg}
                      boavistaInquiries2Img={boavistaInquiries2Img}
                    />
                  </AnalysisCardContainer>)
                  :
                  (<div className="analysisCardContainer"><ErrorBoundary><BoavistaNoData /></ErrorBoundary></div>)
                : null}
              {(decision_metadata || {}).quod ? (
                <AnalysisCardContainer grow={false}>
                  <Quod
                    quod={decision_metadata.quod}
                    expandedQuod={true}
                    setExpandedQuod={setExpandedQuod}
                    isPDF={true}
                  />
                </AnalysisCardContainer>
              ) : null}
              {(decision_metadata || {}).serasa?.document_number&&
                <AnalysisCardContainer grow={true}>
                    <SerasaToPDF
                      serasa={decision_metadata.serasa}
                      expandedSerasa={true}
                      serasaScore2Img={serasaScore2Img}
                      serasaProbability2Img={serasaProbability2Img}
                    />
                </AnalysisCardContainer>}
              {(decision_metadata || {}).scr &&
                <AnalysisCardContainer grow={true}>
                    <SCRtoPDF
                      scr={decision_metadata.scr}
                      expandedSCR={true}
                      scrToExpire2Img={scrToExpire2Img}
                      scrOverdue2Img={scrOverdue2Img}
                      scrHistoryTotal2Img={scrHistoryTotal2Img}
                      scrHistoryToExpireOverdue2Img={
                        scrHistoryToExpireOverdue2Img
                      }
                    />
                </AnalysisCardContainer>}
              {!hasAutomaticDecision && (
                <AnalysisCardContainer className="analysisCardContainer avoidPageBreakInside" grow={false}>
                  <Observation
                    credit_analysis={credit_analysis.fetchedData}
                    credit_proposal_natural_person_key={credit_proposal_natural_person_key}
                    user_data={user_data}
                  />
                </AnalysisCardContainer>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CreditAnalysisNaturalPersonCards;
