import React, { useEffect } from "react";
import { CircularProgress } from '@material-ui/core';
import axios from "axios";
import WithdrawalTable from "./WithdrawalHistoricTable"
import Pagination from "@material-ui/lab/Pagination";

function WithdrawalHistoric(props) {

    const {document_number} = props
    const {final_date} = props
    const {withdrawal_key} = props
    const {alert_triggers_transactions} = props
  
    const [userHistoric, setUserHistoric] = React.useState(null)
    const [tablePage, setTablePage] = React.useState(1)
  
    useEffect(() => {
        if (userHistoric === null){
            if (document_number==null) setUserHistoric(undefined)
            else{
                let params = {
                    "page_number": tablePage -1,
                    "page_rows": 10,
                    "document_number": document_number,
                    "final_date": final_date
                }
                const timer = setTimeout(() => {
                    axios.get('/dash/withdrawal/withdrawals', {
                        params: params
                    }).then(response=>{
                        let filteredWithdrawals = response.data.data.filter(withdrawal => withdrawal.withdrawal_key !== withdrawal_key)
                        let filteredResponseObject = {...response.data, data:filteredWithdrawals}
                        setUserHistoric(filteredResponseObject)
                    }).catch(error=>{
                        setUserHistoric(undefined)
                    })
                }, 500);
                return () => {
                    clearTimeout(timer)
                }

            }
        }
    }, [userHistoric,tablePage,document_number,final_date, withdrawal_key])

    const handleChangePage = (event, page) => {
		if (tablePage === page) {
			return
		}
        setTablePage(page)
        setUserHistoric(null)
	}
  
    if (userHistoric === null) {
      return (
        <div style={{display: "flex", height: "160px"}}>
            <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                <CircularProgress />
            </div>
        </div>
      );    
    }
    else if (userHistoric === undefined){
      return (
        <div style={{display: "flex", height: "60px"}}>
            <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                Informação Indisponível
            </div>
        </div>
      )
    } 
    else if (userHistoric.data.length === 0 && userHistoric.number_of_pages >= tablePage){
        return (
          <div style={{display: "flex", height: "60px"}}>
              <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                Sem Histórico de Saques
              </div>
          </div>
        )
      }
    else {
        return (
                <div>
                    <div>
                        <WithdrawalTable historic={userHistoric.data} alert_triggers_transactions={alert_triggers_transactions}/>
                    </div>
                    <div style={{display:"flex", marginTop: "10px"}}>
                        <Pagination style={{marginLeft:"auto"}} className='pagination' page={tablePage} count={userHistoric['number_of_pages']} onChange={(e,page) => handleChangePage(e, page)} />
                    </div>
                </div>
        );
    }
  }
  
  export default WithdrawalHistoric;