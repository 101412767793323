//Importin modules
import React, { useState, useEffect } from "react";
import Timer from "./Timer";
import moment from 'moment';



//Defining time counter component
function TimeCounter (props) {

    //Defining state handler for total seconds and clock tic
    const [totalSeconds, setTotalSeconds] = useState(null)
    const tick = (totalSeconds) => {
        totalSeconds = totalSeconds-1
        setTotalSeconds(totalSeconds)
    }

    //ON first render calculate the amount of time left
    useEffect(() => {
        const timer = setTimeout(() => {
        let limit = moment(props.order.manual_analysis_limit_date)
        let now = moment()
        let totalSeconds = limit.diff(now, 'seconds')
        setTotalSeconds(totalSeconds)
        },500)
        return function cleanup() {
        setTotalSeconds(null)
        clearTimeout(timer)  
        }
    },[props.order.manual_analysis_limit_date])


    //Every second reduce the totalSeconds
    useEffect(()=> {
        if (totalSeconds){
            const timer = setTimeout(function () {tick(totalSeconds)} , 1000)
            return () => {
                clearTimeout(timer)
            }
        }    
    },[totalSeconds])


    //Returning component to be rendered
    if (totalSeconds > 0){
        let remaining_minutes = Math.floor(totalSeconds/60)
        let remaining_seconds = totalSeconds%60
        return (
            <div 
                className={["normalText, substitleSize", "bold"].join(" ")} 
                style={{margin:"auto", color:"#0b1f82"}}
            >                
                <Timer manual_analysis_limit_date={props.order.manual_analysis_limit_date} time={{minutes: remaining_minutes, seconds: remaining_seconds}}/>
            </div>
        ) 
    }
    else if (totalSeconds != null){
        return (
            <div 
                className={["normalText, substitleSize", "bold"].join(" ")} 
                style={{margin:"auto", color:"#D91A1A"}}
            >
                TEMPO RESTANTE:  00:00             
            </div>
        ) 
    }
    else {
        return (
            null
        )
    }
}



//Exporting time counter component
export default TimeCounter;