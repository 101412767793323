import React from 'react'
import complianceLogo from '../../../assets/cardEntryIcons/compliance.svg';
import fraudLogo from '../../../assets/cardEntryIcons/fraud.svg';
import Tooltip from "@material-ui/core/Tooltip";
import merchantGreyLogo from '../../../assets/cardEntryIcons/MerchantIconGrey.svg';
import peopleGreyLogo from '../../../assets/cardEntryIcons/peopleGrey.svg';
import arrowInLogo from '../../../assets/cardEntryIcons/arrowIn.svg';
import arrowOutLogo from '../../../assets/cardEntryIcons/arrowOut.svg';
import {colorGreen, colorRed, colorYellow, colorOrange, colorVelvet, colorGrey, checkNested} from "./utils"

export const getSeverityStatus = (status) => {
    switch(status){
        case "low":
            return "Baixo Risco"
        case "medium":
            return "Médio Risco"
        case "high":
            return "Alto Risco"
        case "critical":
            return "Crítico"
        default:
            return status
    }
}
export const getSeverityStatusSiglum = (status) => {
    switch(status){
        case "low":
            return "B"
        case "medium":
            return "M"
        case "high":
            return "A"
        case "critical":
            return "C"
        default:
            return "?"
    }
}

export const getAlertStatusText = (status) => {
    switch(status){
        case "pending":
        case "in_manual_analysis":
            return "Pendente"
        case "blocked":
            return "Bloqueado"
        case "solved_false_positive":
            return "Tratado - Falso Positivo"
        case "solved_true_positive":
            return "Tratado - Verdadeiro Positivo"
        case "silenced":
            return "Silenciado"
        default:
            return status
    }
}

export const getAlertStatusTextSiglum = (status) => {
    switch(status){
        case "pending":
        case "in_manual_analysis":
            return "P"
        case "blocked":
            return "B"
        case "solved_true_positive":
        case "solved_false_positive":
            return "T"
        case "silenced":
            return "S"
        default:
            return "?"
    }
}

//Color Utils
export const getSeverityColor = (status) => {
    switch(status){
        case "low":
            return colorYellow
        case "medium":
            return colorOrange
        case "high":
            return colorVelvet
        case "critical":
            return colorRed
        default:
            return colorGrey
    }
}

export const getAlertStatusColor = (status) => {
    switch(status){
        case "pending":
        case "in_manual_analysis":
            return colorYellow
        case "blocked":
            return colorRed
        case "solved_false_positive":    
            return colorGreen
        case "solved_true_positive":
            return colorRed
        case "silenced":
        default:
            return colorGrey
    }
}

export const getAlertUrlFromEventGroup = (eventGroup) => {
    switch(eventGroup){
        case "banking":
            return "/banking-alert/"
        case "card_order":
            return "/card-order-alert/"
        case "card_issuance":
            return "/card-issuance-alert/"
        case "currency_exchange":
            return "/currency-exchange-alert/"
        default:
            return "/invalid-alert/"
    }
}

export const formatIrregularity = (irregularity_type, decrease=false, asTooltip=false) => {
    let text
    let icon    
    switch (irregularity_type) {
        case "compliance":
            text = "Compliance"
            icon = complianceLogo
            break
        case "fraud":
            text = "Fraude"
            icon = fraudLogo
            break
        default:
            return "-"
    }
    if (asTooltip){
        return (
            <div style={{display:"flex", justifyContent:"center"}}>
                <Tooltip title={<p className="tooltipText">{text}</p>}>
                    <img style={decrease ? {width:"16px"} : null} src={icon} alt="irregularity Logo" />
                </Tooltip>
            </div>

        )
    }
    else{
        return(
            <div style={{display:"flex", justifyContent:"center"}}>
                <img style={decrease ? {width:"16px"} : null} src={icon} alt="irregularity Logo" />
                <div className="labelSpace">{text}</div>
            </div>
        )
    } 
}

const getGreaterRisk = (risk_level) => {
    if (risk_level.includes("critical")){
        return "critical"
    }
    else if (risk_level.includes("high")){
        return "high"
    }
    else if (risk_level.includes("medium")){
        return "medium"
    }
    else {
        return "low"
    }
}

export function getTriggerRisk (array){
    let allCategories = array.map(function(trigger) {
        return trigger.category;
      });
    let uniqueItems = Array.from(new Set(allCategories))
    
    let changedArray = uniqueItems.map(function(category){
        let filteredObj = array.filter((trigger)=>{
            return trigger['category'].includes(category);
        });
        let workedRiskObj = filteredObj.reduce((acc, trigger) => {
            acc.allRisks.push(trigger.risk_level)
            acc.allTerminals.push((trigger.transaction || {}).terminal_id)
            return acc
        }, {
            allRisks: [],
            allTerminals: []
        })

        let allTerminals = workedRiskObj.allTerminals.filter(function(terminal) {
            return terminal != null;
        });
        
        return [category, getGreaterRisk(workedRiskObj.allRisks), allTerminals]
    })
    var riskObject = {};
    changedArray.forEach(function(data){
        riskObject[data[0]] = data[1]
        riskObject['terminals'] = data[2]
    });
    let maximizedTrigger = array.map(function(trigger) {
        let category = trigger.category
        return {...trigger, terminalList: riskObject['terminals'], risk_level: riskObject[category]}
    });
    return maximizedTrigger
}

export const formatListCardName = (link_type, order) => {
    if (order == null) return "-"
    let logo = null
    let name = "-"
    switch (link_type) {
        case "seller":
            logo = merchantGreyLogo
            name = checkNested(order, "seller", "name") ? order.seller.name : "-"
            break
        case "customer":
            logo = peopleGreyLogo
            name = checkNested(order, "customer", "name") ? order.customer.name : "-"
            break
        default:
            break
    }
    return(
        <div style={{display:"flex", justifyContent:"center"}}>
            <img src={logo} alt="name Logo" />
            <div className="labelSpace">{name}</div>
        </div>
    )
}

export const  getLockData = (alert) => {
    let lockData = {}
    switch(alert.link_key_type){
        case "customer":
            lockData.type = "Comprador"
            lockData.documentType = alert.link_key_data.customer.type === "natural_person" ? "CPF" : "CNPJ"
            lockData.documentNumber = alert.customer_document_number
            lockData.id = alert.customer_id
            return lockData
        case "seller":
            lockData.type = "Vendedor"
            lockData.documentType = alert.link_key_data.seller.type === "natural_person" ? "CPF" : "CNPJ"
            lockData.documentNumber = alert.seller_document_number
            lockData.id = alert.seller_id
            return lockData
        default:
            return lockData
    }
}  

export const getLinkKeyTypeSiglum = (lock_key_type) =>{
    let text
    let icon
    switch(lock_key_type){
        case "payer":
            text = "Pagador"
            icon = arrowOutLogo
            break
        case "recipient":
            text = "Recebedor"
            icon = arrowInLogo
            break
        case "client":
            text = "Cliente"
            icon = peopleGreyLogo
            break
        case "customer":
            text = "Comprador"
            icon = peopleGreyLogo
            break
        case "seller":
            text = "Vendedor"
            icon = merchantGreyLogo
            break
        default:
            return null
    }
    return (
        <div style={{display:"flex", justifyContent:"center"}}>
            <Tooltip title={<p className="tooltipText">{text}</p>}>
                <img src={icon} alt="irregularity Logo" />
            </Tooltip>
        </div>
    )
}

export const getPayload = (alert) => {
    switch(alert.link_key_type){
        case "customer":
            return {
                customer_id: alert.customer_id,
                customer_document_number: alert.customer_document_number,
            }
        case "seller":
            return {
                seller_id: alert.seller_id,
                seller_document_number: alert.seller_document_number,
            }  
        default:
            return {}
    }
}

export const getLinkKeyTypesFromEventGroup = (eventGroup)=>{
    switch (eventGroup){
        case "banking":
            return ([
                ["Pagador", "payer"],
                ["Cliente", "client"],
                ["Recebedor", "recipient"]
            ])
        case "card_order":
            return ([
                ["Vendedor", "seller"],
                ["Comprador", "customer"]
            ])
        default:
            return []
    }
}

export const getEventGroupName = (event_group)=>{
    switch (event_group){
        case "banking":
            return "Banking"
        case "card_order":
            return "Card Order"
        case "currency_exchange":
            return "Operação de Câmbio"
        default:
            return event_group
    }
}

export const getGroupFromRole = (role) => {
    switch(role){
        case "read_banking_alerts":
            return("banking")
        case "read_card_order_alerts":
            return("card_order")
        case "read_currency_exchange_alerts":
            return("currency_exchange")
        default:
            return null
    }
}

export const getFilterGroupOptions = (roles) => {
    let event_groups = []
    for (let i in roles) {
        let group = getGroupFromRole(roles[i])
        if (group!==null){
            event_groups=event_groups.filter(inboundGroup => inboundGroup!==group);
            event_groups.push(group);
        }
    }
    return event_groups
}

export const setAlertsInSessionStorage = (response) => {
    let alertsArray = []
    response.map(alert => alertsArray.push(alert.alert_key))
    sessionStorage.setItem('alertsArray', JSON.stringify(alertsArray))
}

export const setGetAlertsParamsInSessionStorage = (paramsObj, number_of_pages) => {
    sessionStorage.setItem('alertsParams', JSON.stringify(paramsObj))
    sessionStorage.setItem('alertsNumberOfPages', JSON.stringify(number_of_pages))
}

export const getEventTypeName = (event_type)=>{
    switch (event_type){
        case "bankslip":
            return "Boleto"
        case "bill_payment":
            return "Pagamento de Contas"
        case "wire_transfer":
            return "Transferencia Bancária"
        case "withdrawal":
            return "Saque"
        case "pix_transaction":
            return "Transação PIX"
        case "pix_dict_operation":
            return "Operação na Dict"
        case "card_order":
            return "Card Order"
        default:
            return event_type
    }
}

export const alertNoDuplicatedAmount = (alert) => {

    const source_obj_keys_list = alert.triggers.map(trigger => ((trigger || {}).trigger_group || "").source_evaluated_object_key)
    
    source_obj_keys_list.map((obj, index) => {
        
        let indexOf_ = source_obj_keys_list.indexOf(obj)
        
        let lastIndexOf_ = source_obj_keys_list.lastIndexOf(obj)
        
        let indexofIsEqualToLastIndexOf = (lastIndexOf_ !== indexOf_)
        
        if(indexofIsEqualToLastIndexOf){
            let alert_trigger_index_to_remove_aumont = indexOf_
            alert.triggers[alert_trigger_index_to_remove_aumont].trigger_group.amount = 0
            source_obj_keys_list[index] = index
        } 
        return source_obj_keys_list[index]
    })
}

export const addAmountToAlert = (alert) => {
    return alert.triggers.reduce((acc, trigger) => {
            return acc += ((trigger.trigger_group || {}).amount || 0)
        }, 0)
    }
