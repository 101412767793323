import React from "react";
import { useTranslation } from "react-i18next";
import Select from "@material-ui/core/Select";
import MenuItem from '@mui/material/MenuItem';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

function OrderBy(props) {
	
    const { t } = useTranslation()

    const handleClicks = (event) => {
        props.handleChangeOrderBy(event.target.value)
    }

    const theme = createMuiTheme({
        overrides: { MuiInputBase: { input:{ padding: '6px 0px 3px 5px'}}}
      });
    
    const valueToViewer = (value) => {
      if (value === 'ascending'){
        return('oldest')
      }
      else if (value === 'descending'){
        return('newest')
      }
      else {return value}
    }

    return (
      <div className="filterBoxContainer normalSmallSize">
        <div className="filterBox">
          <ThemeProvider theme={theme}>
            <Select
                    className="selectedOrderBy normalSmallSize"
                    displayEmpty
                    renderValue={() => (
                        <>
                          {props.selectedValue ? t(valueToViewer(props.selectedValue)) : t('order_by')}
                        </>
                      )}
                    onChange={handleClicks}>
                <MenuItem value={'descending'}>{t("newest")}</MenuItem>
                <MenuItem value={'ascending'}>{t("oldest")}</MenuItem>
            </Select>
          </ThemeProvider>
        </div> 
    </div>
    )

}

export default OrderBy
