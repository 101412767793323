import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {getTransactionBlockStatusIcon, getTransactionStatusIcon, getAnalysisStatusColor, getAnalysisStatusSiglum, getAnalysisStatus} from "../../../../utils/utilsOrder"
import moment from 'moment'
import {getTableRowClass,formatAsMoney} from "../../../../utils/utils"
import { Link } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
  },
  blueText: {
    fontFamily: "Open Sans",
    fontWeight: "600",
    color: "#0b1f82"
},
  tableText: {
    fontFamily: "Open Sans",
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "center",
    margin: "10px",
    padding: "6px",
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: '#6F6F6F',
    fontSize: "14px",
    lineHeight: "19px",
    padding: "6px",
  }
}));

function OrderTable(props) {
    const classes = useStyles();
    useTheme();

    return (
        <Paper className={classes.tableStyle}>
            <Table style={{ padding: "8px" }}>
                <TableHead>
                <TableRow>
                    <TableCell className={classes.tableHeader}>ID</TableCell>
                    <TableCell className={classes.tableHeader}>Valor</TableCell>
                    <TableCell className={classes.tableHeader}>Status de Fraude</TableCell>
                    <TableCell className={classes.tableHeader}>Data</TableCell>
                    <TableCell className={classes.tableHeader}>Status das Transações</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                    {props.historic.map((order,index) => (
                    <TableRow
                        key={index}
                        className={getTableRowClass(index)}
                    > 
                        <TableCell
                            className={[classes.tableText, classes.blueText, "breakWord", "link"].join(" ")}
                        >
                            <Link
                                className="link"
                                to={"/order/" + order.order_key}
                            >
                                {order.order_key}
                            </Link>
                        </TableCell>
                        <TableCell className={classes.tableText}>
                            {formatAsMoney(order.payment.total_amount, "-")}
                        </TableCell>
                        <TableCell className={classes.tableText}>
                            <Tooltip title={<p className="tooltipText">{getAnalysisStatus(order.analysis_status)}</p>}>
                                <div className={["circle", "small"].join(" ")} style={{backgroundColor:getAnalysisStatusColor(order.analysis_status)}}>
                                    {getAnalysisStatusSiglum(order.analysis_status)}
                                </div>
                            </Tooltip>
                        </TableCell>
                        <TableCell className={classes.tableText}>
                            {moment(order.order_date).format("DD/MM/YYYY HH:mm:ss")}
                        </TableCell>
                        <TableCell className={classes.tableText}>
                            {order.payment.transactions.length > 1 ?
                             getTransactionBlockStatusIcon(order.payment.transactions) :
                             getTransactionStatusIcon(order.payment.transactions[0].status, "small", true)}                            
                        </TableCell>
                    </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );
}

export default OrderTable;
