import React from "react";
import { useTranslation } from "react-i18next";
import BoavistaSummaryTotal from "../BoavistaSummaryData/BoavistaSummaryTotal";

function BoavistaIncomeCard (props) {

  const { income } = props
  const { t } = useTranslation()

  return (
    <div  className="avoidPageBreakInside" style={{padding:"10px"}}>
      <div className="analysisCard" style={{height:"100%"}}>
        <div className={["blueText", "subtitleSize"].join(" ")} style={{marginBottom: "10px"}}>{t("presumed_income_tier_boavista")}</div>
        <div style={{display:"grid", gridTemplateColumns:"50% 50%"}}>
          <BoavistaSummaryTotal
              description={'minimum_amount'}
              total={income.minimum_amount}
          />
          <BoavistaSummaryTotal
              description={'maximum_amount'}
              total={income.maximum_amount}
          />
        </div>
      </div>
    </div>
  )
}

export default BoavistaIncomeCard