import moment from "moment";

export function removeSpecialCharacters(text) {
    let specialChars = /[^a-zA-Z0-9\s_]/g;
    return text.replace(specialChars, "");
}

export function replaceSpacesToUnderline(text) {
    return  text.replace(/\s/g, "_");
}

export function removeDuplicatedUnderscore(text) {
    return text.replace("__", "_");
}

export function justNumbers(text) {
    let cleanedText = text.replace(/\D/g, '')
    if (cleanedText==="") {
        return String(0)
    } else {
        return cleanedText
    }
}

export function removeNotFirstHifens (string) {
    const firstItemString = string[0]
    const trimmedString = string.slice(1)
    const replacedTrimmedString = trimmedString.replace("-","")
    return firstItemString + replacedTrimmedString
}

export function removeUnecessaryZero (string) {
    const negativeNumber = string[0] === "-"
    const onlyNumbers = String(Number(string.replace("-","")))
    if (negativeNumber) {
        return "-" + onlyNumbers
    } else {
        return onlyNumbers
    }
}

export function justIntegers(text) {
    const replacedText = text.replace(/[^\d-]/g, '')
    if (["0","00",""].includes(replacedText)) {
        return "0"
    } else if (["0-","--"].includes(replacedText)) {
        return "-"
    }
    else {
        return removeUnecessaryZero(removeNotFirstHifens(replacedText))
    }
}

export function replaceDot(str) {
    return str.replace(/\./g, ",");
}

export function countCommas(str) {
    return str.split(",").length - 1;
}

export function removeFirstZero(string) {
    let negativeSign = ""
    if (string[0] === "-") {
        negativeSign = "-"
    }
    const replacedNumber = string.replace("-","")
    if (replacedNumber.slice(0,2)!=="0,"&&replacedNumber[0]==="0") {
        return negativeSign + replacedNumber.substring(1);
    } else {
        return negativeSign + replacedNumber
    }
}

export function removeExtraCommas(string) {
    if (countCommas(string)>1&&string.charAt(string.length - 1) === ',') {
        return string.slice(0, -1);
    } else {
        return string
    }
}

export function justFloat(text) {
    let replacedText = replaceDot(text.replace(/[^0-9.,-]/g, ''))
    if (["0","00",""].includes(replacedText)) {
        return "0"
    }  else if (replacedText==="-0") {
        return "-0"
    } else if (["0-","--"].includes(replacedText)) {
        return "-"
    } else {
        return removeFirstZero(removeNotFirstHifens(removeExtraCommas(replacedText)).slice(0,20))
    }
}

export function formatToBrMoney (value) {
    if (value==="") {
        return "0,00"
    }
    let numbers = String(Number(value.slice(0,17)));
    if (numbers.length === 1) {
        return `0,0${numbers}`;
    } else if (numbers.length === 2) {
        return `0,${numbers}`;
    } else {
        let decimalPart = numbers.slice(-2)
        let integerPart = numbers.slice(0, -2)
        let formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
        return `${formattedIntegerPart},${decimalPart}`
    }
}

export function isEven(number) {
    return number % 2 === 0;
}

export function isNotNull (value) {
    if (value===""||value==="_"||value===null||value===undefined) {
        return false
    } else {
        return true
    }
}

export function listSorter (list, sorter) {
    return list.sort((a, b) => a[sorter] === b[sorter]? 0 : a[sorter] > b[sorter] ? 1 : -1)
}

export function countStringInString(searchString, string) {
    let positionOfOccurence = string.indexOf(searchString)
    let countVariable = 0;
    while (positionOfOccurence !== -1) {
        countVariable++;
        positionOfOccurence = string.indexOf(searchString, positionOfOccurence + 1);
    }
    return countVariable
}

export const getDatetimeFormatted = (value) => {
    if (moment(value, "DD/MM/YYYY ",true).isValid()) {
        return moment(value, "DD/MM/YYYY ", true).format("YYYY-MM-DD") 
    } else if (moment(value, "DD/MM/YYYY",true).isValid()) {
        return moment(value, "DD/MM/YYYY", true).format("YYYY-MM-DD") 
    }
    else if(moment(value, "DD/MM/YYYY HH:mm:ss",true).isValid()){
        return moment(value, "DD/MM/YYYY HH:mm:ss", true).format("YYYY-MM-DDTHH:mm:ssZ") 
    }
    else return value
}

export const datetimeMask = value => {
    let v = value.replace(/\D/g,'').slice(0, 14);
    if (v.length >= 13) {
        return `${v.slice(0,2)}/${v.slice(2,4)}/${v.slice(4,8)} ${v.slice(8,10)}:${v.slice(10,12)}:${v.slice(12)}`;
    }
    else if (v.length >= 11) {
        return `${v.slice(0,2)}/${v.slice(2,4)}/${v.slice(4,8)} ${v.slice(8,10)}:${v.slice(10)}`;
    }
    else if (v.length >= 9) {
        return `${v.slice(0,2)}/${v.slice(2,4)}/${v.slice(4,8)} ${v.slice(8)}`;
    }
    else if (v.length >= 5) {
        return `${v.slice(0,2)}/${v.slice(2,4)}/${v.slice(4)}`;
    }
    else if (v.length >= 3) {
        return `${v.slice(0,2)}/${v.slice(2)}`;
    }
    return v
}

export const timeMask = value => {
    let v = value.replace(/\D/g,'').slice(0, 6);
    if (v.length >= 5) {
        return `${v.slice(0,2)}:${v.slice(2,4)}:${v.slice(4)}`;
    }
    else if (v.length >= 3) {
        return `${v.slice(0,2)}:${v.slice(2)}`;
    }
    return v
}

export const stringToFloat = (string) => {
    return parseFloat(String(string).replace(",","."))
}

export const isGeoPoint = (value) => {
    let north = stringToFloat((value||[])[0]||0)
    let east = stringToFloat((value||[])[1]||0)
    let isGeoPoint = true
    if (!(north>=-90&&north<=90)) {
        isGeoPoint = false
    } else if (!(east>=-180&&east<=180)) {
        isGeoPoint = false
    }
    return isGeoPoint
}

export const isValidDate = (days,months,years) => {
    if (years<0||years>new Date().getFullYear()) {
        return false
    } else if (months<0||months>12) {
        return false
    } else if (days<0||days>31) {
        return false
    }
    return true
}

export const isValidTime = (hours,minutes,seconds) => {
    if (hours<0||hours>23) {
        return false
    } else if (minutes<0||minutes>59) {
        return false
    } else if (seconds<0||seconds>59) {
        return false
    }
    return true
}

export const isTime = (value) => {
    if (value.length === 8) {
        let hours = Number(value.slice(0,2))
        let minutes = Number(value.slice(3,5))
        let seconds = Number(value.slice(6,8))
        return isValidTime(hours, minutes, seconds)
    } else {
        return false
    }
}

export const isDatetime = (value) => {
    if (value.length===19) {
        let days = Number(value.slice(0,2))
        let months = Number(value.slice(3,5))
        let years = Number(value.slice(6,10))
        let hours = Number(value.slice(11,13))
        let minutes = Number(value.slice(14,16))
        let seconds = Number(value.slice(17,19))
        return isValidDate(days, months, years)&&isValidTime(hours, minutes, seconds)
    } else {
        return false
    }
}

export const isDate = (value) => {
    if (value.length===10) {
        let days = Number(value.slice(0,2))
        let months = Number(value.slice(3,5))
        let years = Number(value.slice(6,10))
        return isValidDate(days, months, years)
    } else {
        return false
    }
}

export const isGeoShape = (geoShape) => {
    geoShape = geoShape || [[0,0]]
    let isGeoShape = true
    geoShape.forEach((geoPoint)=>{
        let north = stringToFloat(geoPoint[0])
        let east = stringToFloat(geoPoint[1])
        if (!(north>=-90&&north<=90)) {
            isGeoShape = false
        } else if (!(east>=-180&&east<=180)) {
            isGeoShape = false
        }
    })
    return isGeoShape
}