import React from 'react'
import { useTranslation } from "react-i18next";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import MenuItem from '@material-ui/core/MenuItem';
import NumberFormat from 'react-number-format';
import InputMask from "react-input-mask";
import moment from 'moment'
import i18next from "i18next";
import alarmIcon from "../../../../assets/rulesIcon/alarm.svg"
//import conditionIcon from "../../../../assets/rulesIcon/condition.svg"
import bifurcationIcon from "../../../../assets/rulesIcon/bifurcation.svg"
import decisionIcon from "../../../../assets/rulesIcon/Decision.svg"
import listIcon from "../../../../assets/rulesIcon/list.svg"
import blocksIcon from "../../../../assets/rulesIcon/block.svg"
import numericIcon from "../../../../assets/rulesIcon/numeric.svg"
import locationIcon from "../../../../assets/rulesIcon/location.svg"
import calendarIcon from "../../../../assets/rulesIcon/calendar.svg"
import clockIcon from "../../../../assets/rulesIcon/clock.svg"
import trueIcon from "../../../../assets/rulesIcon/true.svg"
import textIcon from "../../../../assets/rulesIcon/text.svg"
import cloudIcon from "../../../../assets/rulesIcon/cloud.svg"
import databaseIcon from "../../../../assets/rulesIcon/database.svg"
import signalsIcon from "../../../../assets/rulesIcon/letter_s.svg"
import equalIcon from "../../../../assets/rulesIcon/equal_to.svg"
import notEqualIcon from "../../../../assets/rulesIcon/not_equal.svg"
import greaterThanIcon from "../../../../assets/rulesIcon/greater_than.svg"
import lessThanIcon from "../../../../assets/rulesIcon/less_than.svg"
import greaterEqualIcon from "../../../../assets/rulesIcon/greater_equal.svg"
import lessEqualIcon from "../../../../assets/rulesIcon/less_equal.svg"
import inBetweenIcon from "../../../../assets/rulesIcon/in_between.svg"
import plusIcon from "../../../../assets/rulesIcon/plus.svg"
import logIcon from "../../../../assets/rulesIcon/log.svg"
import minusIcon from "../../../../assets/rulesIcon/minus.svg"
import signInversorIcon from "../../../../assets/rulesIcon/-1.svg"
import deviceIcon from "../../../../assets/rulesIcon/device.svg"
import greenDeviceIcon from "../../../../assets/rulesIcon/green_device.svg"
import lowerCaseIcon from "../../../../assets/rulesIcon/lower_case.svg"
import upperCaseIcon from "../../../../assets/rulesIcon/upper_case.svg"
import textBlackIcon from "../../../../assets/rulesIcon/text_black.svg"
import extractTimeIcon from "../../../../assets/rulesIcon/extract_time.svg"
import timedeltaIcon from "../../../../assets/rulesIcon/timer.svg"
import polygonIcon from "../../../../assets/rulesIcon/polygon.svg"
import multiplicationIcon from "../../../../assets/rulesIcon/multiplication.svg"
import divisionIcon from "../../../../assets/rulesIcon/divide.svg"
import distanceIcon from "../../../../assets/rulesIcon/distance.svg"
import exclamationIcon from "../../../../assets/rulesIcon/exclamation.svg"
import scoreModifierIcon from "../../../../assets/rulesIcon/score_modifier.svg"
import scoreRetrieveIcon from "../../../../assets/rulesIcon/score_retrieve.svg"
import labelIcon from "../../../../assets/rulesIcon/label.svg"
import numberOfInstallmentsIcon from "../../../../assets/rulesIcon/number_of_installments.svg"
import creditLimitAmountIcon from "../../../../assets/rulesIcon/credit_limit_amount.svg"
import amountIcon from "../../../../assets/rulesIcon/amount.svg"
import interestRateIcon from "../../../../assets/rulesIcon/interest_rate.svg"
import creditProposalIcon from "../../../../assets/rulesIcon/credit_proposal.svg"
import CodeIcon from "../../../../assets/rulesIcon/code.svg"
import scriptIcon from "../../../../assets/rulesIcon/script.svg"
import reduceDecimalPlaces from "../../../../assets/rulesIcon/reduce_decimal_places.svg"
import machineLearningIcon from "../../../../assets/rulesIcon/machine-learning.svg"
import moduleIcon from "../../../../assets/rulesIcon/module.svg"
import maximumIcon from "../../../../assets/rulesIcon/maximum.svg"
import minimumIcon from "../../../../assets/rulesIcon/minimum.svg"
import getVariableIcon from "../../../../assets/rulesIcon/get_variable.svg"
import updateVariableIcon from "../../../../assets/rulesIcon/update_variable.svg"
import variableBoxIcon from "../../../../assets/rulesIcon/variable_box.svg"
import andIcon from "../../../../assets/rulesIcon/and.svg"
import notIcon from "../../../../assets/rulesIcon/not.svg"
import orIcon from "../../../../assets/rulesIcon/or.svg"
import abacusIcon from "../../../../assets/rulesIcon/abacus.svg"
import accumulateIcon from "../../../../assets/rulesIcon/accumulate.svg"
import verifyIcon from "../../../../assets/rulesIcon/verify.svg"
import comparisonIcon from "../../../../assets/rulesIcon/comparison.svg"
import changeCircleIcon from "../../../../assets/rulesIcon/change_circle.svg"
import conditionalIcon from "../../../../assets/rulesIcon/conditional.svg"

export function truncateDecimals(number, digits) {
    var multiplier = Math.pow(10, digits),
        adjustedNum = number * multiplier,
        truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);
    return truncatedNum / multiplier;
};

export function translateType (type){
    switch(type){
        case "string":
            return "Texto"
        case "bool":
            return "Binário"
        case "number":
            return "Número"
        case "datetime":
            return "Data e Hora"
        case "time":
            return "Hora"
        case "timedelta":
            return "Período de Tempo"
        case "geo_point":
            return "Coordenada Geográfica"
        case "geo_shape":
            return "Polígono Geográfico"
        case "void":
            return "Não possui retorno"
        case "":
            return "Inválido"
        default:
            throw new Error ("Invalid field Type " + type)
    }
}

export function getAlertsComponent (alerts) {
    return(
        <Tooltip title={<p className="tooltipText">{alerts.map((alert,index) => (
                    <li key={index}>
                        {alert}
                    </li>))}</p>}>
            <ErrorOutlineIcon 
                style={{
                    verticalAlign:"middle", 
                    width:"20px", 
                    height:"20px", 
                    color:colorRed, 
                    marginTop:"auto",
                    marginBottom:"auto", 
                    marginRight:"5px",
                    marginLeft:"auto"}}
            /> 
        </Tooltip>
    )    
}

export function GetAlertsComponent ({alerts, error=true}) {
    const { t } = useTranslation();
    let color = colorRed
    if (!error){
        color = colorGreen
    }
    return(
        <Tooltip title={<p className="tooltipText">{alerts.map((alert,index) => (
                    <li key={index}>
                        {t(alert)}
                    </li>))}</p>}>
            <ErrorOutlineIcon 
                style={{
                    verticalAlign:"middle", 
                    width:"20px", 
                    height:"20px", 
                    color:color, 
                    marginTop:"auto",
                    marginBottom:"auto", 
                    marginRight:"5px",
                    marginLeft:"auto"}}
            /> 
        </Tooltip>
    )    
}
export function setObjectByPath(obj, path, value) {
    var schema = obj;
    var pList = path.split('.');
    var len = pList.length;
    for(var i = 0; i < len-1; i++) {
        var elem = pList[i];
        if( !schema[elem] ) schema[elem] = {}
        schema = schema[elem];
    }
    schema[pList[len-1]] = value;
}

export const colorGreen = '#73C580'
export const colorRed ='#D91A1A'
export const colorYellow = "#FFBB24"
export const colorVelvet = '#DD5C5C'

export const categoriesList=[
    {
        name:"basic",
        description:"Básicas",
        color:"#568ec7"
    },
    {
        name:"condition",
        description:"Condições",
        color:"#90C3E0"
    },
    {
        name:"comparision",
        description:"Comparações",
        color:"#FFCCCC"
    },
    {
        name:"math",
        description:"Operações",
        color:"#629CA0"
    },
    {
        name:"string_handling",
        description:"Manipulação de Texto",
        color:"#999999"
    },
    {
        name:"user_variables",
        description:"Variáveis do Usuário",
        color:"#edb879"
    },
    {
        name:"data_source",
        description:"Dados",
        color:"#F4EDA8"
    },
    {
        name:"constant",
        description:"Constante",
        color:"#FF9999"
    },
    {
        name:"action",
        description:"Ações",
        color:"#AEDEB8"
    },
    {
        name:"lists",
        description:"Listas",
        color:"#e0a8cb"
    },
    {
        name:"device_scan",
        description:"Scan do Dispositivo",
        color:"#bfceea"
    },
    {
        name:"advanced",
        description:"Avançadas",
        color:"#42f5dd"
    }
]

export const categoryColors = (category) => {
    let filteredCategory = categoriesList.filter(categoryObject => categoryObject.name === category)
    if (filteredCategory.length === 0){
        throw new Error("a correspondent category object to category " + category + " must be created")
    } 
    else{
        return filteredCategory[0].color
    }
}

export function getConstantTextFields (constant_type) {
    switch(constant_type) {
        case "string":
            return(
            <TextField 
                className="filterTextbox" 
                size="small" fullWidth 
            />                     
            )
        case "bool":
            return(
                <TextField 
                    className="filterTextbox" 
                    size="small" fullWidth
                    select
                >
                <MenuItem key={0} value={true}><em className="normalText">Verdadeiro</em></MenuItem>
                <MenuItem key={1} value={false}><em className="normalText">Falso</em></MenuItem>
            </TextField>                      
            )
        case "number":
            return(
                <NumberFormat
                    customInput={TextField}
                    type="text"
                    decimalSeparator={","}
                />
            )
        case "datetime":
            return(
                <InputMask 
                    mask={"99/99/9999 99:99:99"}
                    maskPlaceholder=""
                >
                    <TextField 
                        autoFocus 
                    />
                </InputMask>
            )
        case "time":
            return(
                <InputMask 
                    mask={"99:99:99"}
                    maskPlaceholder=""
                >
                    <TextField 
                        autoFocus 
                    />
                </InputMask>
            )
        case "timedelta":
            return(
                <InputMask 
                    mask={"999 Di\\as, 99 Hor\\as, 99 Minutos, 99 Segundos"}
                    maskPlaceholder=""
                >
                    <TextField 
                        autoFocus 
                    />
                </InputMask>
            )
        case "geo_point":
            return(
                <NumberFormat
                    customInput={TextField}
                    type="text"
                    decimalSeparator={","}
                />
            )
        default:
            throw new Error ("Invalid field Type " + constant_type)
    }
}

export const validateConstantValues = (constant_type,value) => {
    switch(constant_type) {
        case "string":
            if(typeof value !== "string") return false
            else return true
        case "bool":
            if (typeof value !== "boolean") return false
            else return true
        case "number":
            if (typeof value !== "number") return false
            else return true
        case "datetime":
            return moment(value, moment.ISO_8601, true).isValid()
        case "time":
            return moment(value, "HH:mm:ss", true).isValid()
        case "timedelta":
            return (typeof value === "string" && /^P(-?[0-9]+Y)?(-?[0-9]+M)?(-?[0-9]+W)?(-?[0-9]+D)?(T(-?[0-9]+H)?(-?[0-9]+M)?(-?[0-9]+S)?)?$/.test(value))
        case "geo_point":
            if (Array.isArray(value)){
                if (value.length === 2){
                    if ((typeof value[0] === "number" && value[0] <= 180 && value[0] >= -180) &&
                        (typeof value[1] === "number" && value[1] <= 90 && value[1] >= -90)) {
                            return true
                        }
                }
            }
            return false
        default:
            throw new Error ("Invalid field Type " + constant_type)
    }
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const comparisionOptions = [
    {
        value:"greater_than",
        description:"Maior que",
        types:["datetime", "number", "time", "timedelta"]
    },
    {
        value:"less_than",
        description:"Menor que",
        types:["datetime", "number", "time", "timedelta"]
    },
    {
        value:"greater_than_equal",
        description:"Maior ou Igual a",
        types:["datetime", "number", "time", "timedelta"]
    },
    {
        value:"less_than_equal",
        description:"Menor ou Igual a",
        types:["datetime", "number", "time", "timedelta"]
    },
    {
        value:"equal_to",
        description:"Igual a",
        types:["datetime", "number", "bool", "string", "time", "timedelta"]
    },
    {
        value:"not_equal_to",
        description:"Diferente de",
        types:["datetime", "number", "bool", "string", "time", "timedelta"]
    },
    {
        value:"in_between",
        description:"Entre",
        types:["datetime", "number", "time", "timedelta"]
    }
]

export const formatAsMoney = (value) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value / 100);
  };

export const basicBoxesList = [
    // {
    //     category: "advanced",
    //     type:"conditional_partners_status_box",
    //     description: "Status Quadro Societário Condicional",
    //     helperText:["Este nó deve ser utilizado para se criar comparações com os Status do quadro societário da empresa;", "O primeiro filho é sempre o que será executado caso a condição seja verdadeira, enquanto o segundo será o caminho caso a condição seja falsa;", "O segundo filho não é obrigatório;", "Este nó não possui retorno."],
    //     logoSVG: conditionIcon,
    //     properties:{
    //         reference:"absolute",
    //         status:"",
    //         comparision_type:"",
    //         value:"",
    //         min_value: null,
    //         max_value:null
    //     }
    // },
    // {
    //     category: "data_source",
    //     type:"partners_status_box",
    //     description: "Status Quadro Societário",
    //     helperText:["Este nó deve ser utilizado para se obter o número de sócios do quadro societário que respeitam determinado Status", "O tipo de retorno desse nó é numérico;", "Este nó não possui filhos."],
    //     logoSVG: cloudIcon,
    //     properties:{
    //         reference:"absolute",
    //         status:""
    //     }
    // },
    // {
    //     category: "advanced",
    //     type:"conditional_special_search_box",
    //     description: "Buscas Especiais Condicional",
    //     helperText:["Este nó deve ser utilizado para se criar comparações com os resultados das Buscas Especiais;", "O primeiro filho é sempre o que será executado caso a condição seja verdadeira, enquanto o segundo será o caminho caso a condição seja falsa;", "O segundo filho não é obrigatório;", "Este nó não possui retorno."],
    //     logoSVG: conditionIcon,
    //     properties:{
    //         special_search:"",
    //         return_type:"",
    //         comparision_type:"",
    //         value:""
    //     }
    // },
    // {
    //     category: "data_source",
    //     type:"special_search_box",
    //     description: "Buscas Especiais",
    //     helperText:["Este nó deve ser utilizado quando se deve realizar Buscas Especiais;", "Um tipo de Busca deve ser sempre selecionado, e dependendo desta, deve-se preencher alguns parâmetros necessários;", "O tipo de retorno deste nó varia com a Busca selecionada;", "Este nó não possui filhos."],
    //     logoSVG: databaseIcon,
    //     properties:{
    //         special_search:"",
    //         return_type:""
    //     }
    // },
    // {
    //     category: "advanced",
    //     type:"conditional_bureau_retrieval_box",
    //     description: "Recuperação de dados em Bureau Condicional",
    //     helperText:["Este nó deve ser utilizado para se criar comparações com os dados que são recuperados de um Bureau;", "O primeiro filho é sempre o que será executado caso a condição seja verdadeira, enquanto o segundo será o caminho caso a condição seja falsa;", "O segundo filho não é obrigatório;", "Este nó não possui retorno."],
    //     logoSVG: conditionIcon,
    //     properties:{
    //         document_path:"",
    //         bureau_field:"",
    //         bureau_field_type:"",
    //         comparision_type:"",
    //         value:"",
    //         min_value: null,
    //         max_value:null
    //     }
    // },
    // {
    //     category: "data_source",
    //     type:"bureau_retrieval_box",
    //     description: "Recuperação de dados em Bureau",
    //     helperText:["Este nó deve ser utilizado para se recuperar um dado encontrado em Bureau", "O tipo de retorno desse depende de qual dados está sendo recuperado;", "Este nó não possui filhos."],
    //     logoSVG: cloudIcon,
    //     properties:{
    //         document_path:"",
    //         bureau_field:"",
    //         bureau_field_type:""
    //     }
    // },
    // {
    //     category: "advanced",
    //     type:"conditional_bureau_validation_box",
    //     description: "Validação em Bureau Condicional",
    //     helperText:["Este nó deve ser utilizado para se criar comparações com as validações de dados em Bureau", "O primeiro filho é sempre o que será executado caso a condição seja verdadeira, enquanto o segundo será o caminho caso a condição seja falsa;", "O segundo filho não é obrigatório;", "Este nó não possui retorno."],
    //     logoSVG: conditionIcon,
    //     properties:{
    //         document_path:"",
    //         field_path:"",
    //         bureau_field:"",
    //         comparision_type:"",
    //         value:"",
    //     }
    // },
    // {
    //     category: "data_source",
    //     type:"bureau_validation_box",
    //     description: "Validação em Bureau",
    //     helperText:["Este nó deve ser utilizado para se obter o Binário de uma validação de dados em Bureau", "O tipo de retorno desse nó é binário;", "Este nó não possui filhos."],
    //     logoSVG: cloudIcon,
    //     properties:{
    //         document_path:"",
    //         field_path:"",
    //         bureau_field:""
    //     }
    // },
    {
        category: "advanced",
        type:"conditional_event_field_box",
        description: "Campo do Evento Condicional",
        helperText:["Este nó deve ser utilizado para criar condições comparativas com os Dados do Evento;", "O primeiro filho é sempre o que será executado caso a condição seja verdadeira, enquanto o segundo será o caminho caso a condição seja falsa;", "O segundo filho não é obrigatório;", "Este nó não possui retorno."],
        logoSVG: conditionalIcon,
        properties:{
            field_path:"",
            comparision_type:"",
            value:"",
            min_value: null,
            max_value:null
        },
        metadata:{
            field_type:""
        }
    },
    {
        category: "advanced",
        type:"conditional_signals_box",
        description: "Signals Condicional",
        helperText:["Este nó deve ser utilizado para criar condições comparativas com os Signals;", "O primeiro filho é sempre o que será executado caso a condição seja verdadeira, enquanto o segundo será o caminho caso a condição seja falsa;", "O segundo filho não é obrigatório;", "Este nó não possui retorno."],
        logoSVG: conditionalIcon,
        properties:{
            signal_key: "",
            comparision_type:"",
            value:"",
            min_value: null,
            max_value:null
        },
        metadata:{
            signal_type: ""
        }
    },
    {
        category: "advanced",
        type:"comparision_event_field_box",
        description: "Comparação de Campo do Evento",
        helperText:["Este nó deve ser utilizado para criar comparações com os Dados do Evento;", "Este nó não possui filhos;", "Este nó possui retorno Binário."],
        logoSVG: comparisonIcon,
        properties:{
            field_path:"",
            comparision_type:"",
            value:"",
            min_value: null,
            max_value:null
        },
        metadata:{
            field_type:""
        }
    },
    {
        category: "condition",
        type:"conditional_box",
        description: "Se/Portanto",
        helperText:["Este nó é utilizado para criar condições - Se algo acontecer, execute isso, senão, aquilo;", "Este nó deve ter obrigatoriamente 2 ou 3 filhos;", "O primeiro filho deve ser um nó cujo retorno é binário (Verdadeiro ou Falso);", "O segundo filho é o que deve ser executado caso o primeiro filho seja verdadeiro, e o terceiro, caso falso;", "O terceiro filho não é obrigatório;", "Este nó não possui retorno."],
        logoSVG: bifurcationIcon,
        properties:{}
    },
    {
        category: "condition",
        type:"or_box",
        description: "Ou",
        helperText:["Este nó deve ser utilizado para juntar retornos binários sob a condição de Ou;", "Ele deve possuir ao menos 1 filho, e todos eles devem ter retorno binário;", "Este nó retornará 'Verdadeiro' caso ao menos um dos filhos seja 'Verdadeiro';", "Este nó possui um retorno binário."],
        logoSVG: orIcon,
        properties:{}
    },
    {
        category: "condition",
        type:"and_box",
        description: "E",
        helperText:["Este nó deve ser utilizado para juntar retornos binários sob a condição de E;", "Ele deve possuir ao menos 1 filho, e todos eles devem ter retorno binário;", "Este nó retornará 'Falso' caso ao menos um dos filhos seja 'Falso';", "Este nó possui um retorno binário."],
        logoSVG: andIcon,
        properties:{}
    },
    {
        category: "condition",
        type:"not_box",
        description: "Não",
        helperText:["Este nó deve ser utilizado para inverter um retorno binário;", "Ele deve possuir um único filho, cujo retorno é binário;", "Este nó retornará 'Falso' caso o filho seja 'Verdadeiro' e vice-versa;", "Este nó possui um retorno binário."],
        logoSVG: notIcon,
        properties:{}
    },
    {
        category: "comparision",
        type:"greater_than_box",
        description: "Maior que",
        helperText:["Este nó deve ser utilizado para comparar dois valores;", "Ele deve necessariamente ter dois filhos do mesmo tipo;", "Este nó aceita comparações com filhos 'Hora', 'Data e Hora' e 'Número';", "Este nó possui um retorno binário."],
        logoSVG: greaterThanIcon,
        properties:{}
    },
    {
        category: "comparision",
        type:"less_than_box",
        description: "Menor que",
        helperText:["Este nó deve ser utilizado para comparar dois valores;", "Ele deve necessariamente ter dois filhos do mesmo tipo;", "Este nó aceita comparações com filhos 'Hora', 'Data e Hora' e 'Número';", "Este nó possui um retorno binário."],
        logoSVG: lessThanIcon,
        properties:{}
    },
    {
        category: "comparision",
        type:"greater_than_equal_box",
        description: "Maior ou igual a",
        helperText:["Este nó deve ser utilizado para comparar dois valores;", "Ele deve necessariamente ter dois filhos do mesmo tipo;", "Este nó aceita comparações com filhos 'Hora', 'Data e Hora' e 'Número';", "Este nó possui um retorno binário."],
        logoSVG: greaterEqualIcon,
        properties:{}
    },
    {
        category: "comparision",
        type:"less_than_equal_box",
        description: "Menor ou igual a",
        helperText:["Este nó deve ser utilizado para comparar dois valores;", "Ele deve necessariamente ter dois filhos do mesmo tipo;", "Este nó aceita comparações com filhos 'Hora', 'Data e Hora' e 'Número';", "Este nó possui um retorno binário."],
        logoSVG: lessEqualIcon,
        properties:{}
    },
    {
        category: "comparision",
        type:"equal_to_box",
        description: "Igual",
        helperText:["Este nó deve ser utilizado para comparar dois valores;", "Ele deve necessariamente ter dois filhos do mesmo tipo;", "Este nó aceita comparações com filhos 'Hora', 'Data e Hora', 'Número', 'Texto' e 'Binário';", "Este nó possui um retorno binário."],
        logoSVG: equalIcon,
        properties:{}
    },
    {
        category: "comparision",
        type:"in_between_box",
        description: "Entre",
        helperText:["Este nó deve ser utilizado para se comparar valores sobre a perspectiva de 'Entre';", "Ele deve necessariamente ter três filhos do mesmo tipo;", "O valor do Primeiro filho deve estar entre o valor do Segundo e do Terceiro. Caso marcado, este nó pode considerar igualdades","Este nó aceita comparações com filhos 'Hora', 'Data e Hora', 'Número' e 'Período de Tempo';", "Este nó possui um retorno binário."],
        logoSVG: inBetweenIcon,
        properties:{
            greater_than_equal:false,
            less_than_equal:false
        }
    },
    {
        category: "comparision",
        type:"not_equal_to_box",
        description: "Diferente de",
        helperText:["Este nó deve ser utilizado para comparar dois valores;", "Ele deve necessariamente ter dois filhos do mesmo tipo;", "Este nó aceita comparações com filhos 'Hora', 'Data e Hora', 'Número', 'Texto' e 'Binário';", "Este nó possui um retorno binário."],
        logoSVG: notEqualIcon,
        properties:{}
    },
    {
        category: "action",
        type:"action_decision_box",
        description: "Decisão",
        helperText:["Este nó deve ser utilizado quando se quer executar uma decisão, 'Aprovar', 'Reprovar' ou 'Derivar para Análise Manual';", "A regra terminará a sua execução ao encontrar um nó deste tipo;", "Um nó deste tipo não deve possuir filhos;", "Este nó não possui retorno."],
        logoSVG: decisionIcon,
        properties:{
            value:"",
            reason: "",
            reason_description: ""
        }
    },
    {
        category: "action",
        type:"action_custom_variable_box",
        description: "Variável de Saída",
        helperText:["Este nó deve ser utilizado quando se deseja criar uma variável de output;", "Ele deve possuir dois filhos;", "O primeiro filho é o nome da variável de saída;", "O segundo filho é o valor da variável de saída;", "Este nó não possui retorno."],
        logoSVG: CodeIcon,
        properties:{}
    },
    {
        category: "data_source",
        type:"onboarding_enrichment_box",
        description: "Variáveis de Onboarding",
        helperText:["Este nó deve ser utilizado para recuperar dados obtidos na execução da regra de Onboarding;", "Deve-se escolher qual dado deseja-se recuperar;", "Este nó não possui filhos;", "O seu retorno depende de qual dado está sendo recuperado."],
        logoSVG: databaseIcon,
        properties:{
            value:""
        }
    },
    {
        category: "action",
        type:"action_interest_rate_box",
        description: "interest_rate",
        helperText:["Este nó deve ser utilizado quando se deseja incluir um indexador da dívida;", "Um nó deste tipo não deve possuir filhos;", "Este nó não possui retorno."],
        logoSVG: interestRateIcon,
        properties:{
            interest_rate_type:"",
            interest_rate_value:"",
        }
    },
    {
        category: "action",
        type:"action_number_of_installments_box",
        description: "number_of_installments",
        helperText:["Este nó deve ser utilizado quando se deseja incluir o número de parcelas;", "Um nó deste tipo não deve possuir filhos;", "Este nó não possui retorno."],
        logoSVG: numberOfInstallmentsIcon,
        properties:{
            number_of_installments_value:"",
        }
    },
    {
        category: "action",
        type:"action_credit_limit_amount_box",
        description: "credit_limit_amount",
        helperText:["Este nó deve ser utilizado para calcular ou incluir o valor do limite de crédito", "Este nó deve possuir obrigatoriamente 1 filho, que pode ser uma operação para cálculo do limite ou diretamente o valor do limite em R$", "Este nó não possui retorno."],
        logoSVG: creditLimitAmountIcon,
        properties:{}
    },
    {
        category: "action",
        type:"action_credit_proposal_box",
        description: "credit_proposal",
        helperText:["Este nó deve ser utilizado quando se deseja incluir uma proposa de crédito com valor, taxas e número de parcelas;", "Um nó deste tipo não deve possuir filhos;", "Este nó não possui retorno."],
        logoSVG: creditProposalIcon,
        properties:
            {
                "credit_amount_value": 0,
                "number_of_installments_value": "",
                "interest_rates":[
                    // {
                    //     interest_rate_type: 'annual_interest_rate', 
                    //     interest_rate_value: ""
                    // },
                    {
                        interest_rate_type: 'monthly_interest_rate', 
                        interest_rate_value: ""
                    },
                    {
                        interest_rate_type: 'cdi_percentage', 
                        interest_rate_value: ""
                    }
                ]
            },
    },
    {
        category: "action",
        type:"action_trigger_box",
        description: "Triggers",
        helperText:["Este nó deve ser utilizado quando se deseja criar um Trigger;", "Estes Triggers são utilizados para a criação de alertas e são separados em diferentes graus de severidade;", "Um Trigger pode ser utilizado para a criação de bloqueios automáticos;", "Um nó deste tipo não deve possuir filhos;", "Este nó não possui retorno."],
        logoSVG: exclamationIcon,
        properties:{
            risk_level: "",
            irregularity_type: "",
            link_key_type: "",
            category: "",
            category_description: "",
            trigger_details: "",
            locks:[]
        }
    },
    {
        category: "action",
        type:"action_indicator_box",
        description: "Indicadores",
        helperText:["Este nó deve ser utilizado quando se quer gerar um alerta;", "Os indicadores possuem tipos: 'Positivo', 'Negativo' e 'Neutro'; descrição e um enumerador;", "Um nó deste tipo não deve possuir filhos;", "Este nó não possui retorno."],
        logoSVG: alarmIcon,
        properties:{
            flag:"",
            description: "",
            enum: ""
        }
    },
    {
        category: "action",
        type:"action_risk_level_box",
        description: "Nível de Risco",
        helperText:["Este nó deve ser utilizado quando se quer gerar um nível de risco;", "Os indicadores possuem tipos: 'Baixo','Alto' e 'Médio'; descrição e um enumerador;", "Um nó deste tipo não deve possuir filhos;", "Este nó não possui retorno."],
        logoSVG: exclamationIcon,
        properties:{
            value:""
        }
    },
    {
        category: "action",
        type:"action_pack_box",
        description: "Bloco de Ações",
        helperText:["Este nó deve ser utilizado quando se quer executar múltiplas ações;", "Este nó deve possuir ao menos um filho, e todos eles não podem ter retorno;", "A regra irá executar todos os filhos deste nó;","Este nó não possui retorno."],
        logoSVG: blocksIcon,
        properties:{}
    },
    {
        category: "math",
        type:"subtract_box",
        description: "Subtração",
        helperText:["Este nó deve ser utilizado quando se deseja realizar uma subtração entre dois números;", "Ele deve possuir necessariamente dois filhos de tipo 'Número';", "Este nó possui retorno numérico."],
        logoSVG: minusIcon,
        properties:{}
    },
    {
        category: "math",
        type:"sum_box",
        description: "Soma",
        helperText:["Este nó deve ser utilizado quando se deseja realizar uma adição entre números;", "Ele deve possuir ao menos um filhos, e todos eles devem retornar 'Número';", "Este nó possui retorno numérico."],
        logoSVG: plusIcon,
        properties:{}
    },
    {
        category: "math",
        type:"log_box",
        description: "Logarítimo",
        helperText:["Este nó deve ser utilizado quando se deseja calcular o logarítimo de um número;", "Ele deve possuir um filho;", "Este nó possui retorno numérico."],
        logoSVG: logIcon,
        properties:{}
    },
    {
        category: "math",
        type:"multiplication_box",
        description: "Multiplicação",
        helperText:["Este nó deve ser utilizado quando se deseja realizar uma multiplicação entre números;", "Ele deve possuir dois filhos, e todos eles devem retornar 'Número';", "Este nó possui retorno numérico."],
        logoSVG: multiplicationIcon,
        properties:{}
    },
    {
        category: "math",
        type:"division_box",
        description: "Divisão",
        helperText:["Este nó deve ser utilizado quando se deseja realizar uma divisão entre números;", "Ele deve possuir dois filhos, e todos eles devem retornar 'Número';", "Este nó possui retorno numérico."],
        logoSVG: divisionIcon,
        properties:{}
    },
    {
        category: "math",
        type:"number_round_box",
        description: "number_round_box",
        helperText:["Este nó deve ser utilizado para arredondar um número decimal","Este nó deve possuir obrigatoriamente 1 único filho, que pode ser uma operação que retorne um 'Número' ou diretamente um 'Número'", "Este nó retorna um 'Número'."],
        logoSVG: reduceDecimalPlaces, 
        properties:{
            ndigits_value: 1
        }
    },
    {
        category: "math",
        type:"sign_inverter_box",
        description: "Inversor de sinais",
        helperText:["Este nó deve ser utilizado quando se deseja inverter o sinal de um número;", "Ele deve possuir um único filho, cujo tipo é 'Número';", "Este nó possui retorno numérico."],
        logoSVG: signInversorIcon,
        properties:{}
    },
    {
        category: "math",
        type:"polygon_contains_point_box",
        description: "Poligono contém",
        helperText:["Este nó deve ser utilizado quando se deseja definir se um par de coordenadas está contido dentro de um polígono geográfico;", "Ele deve possuir necessariamente dois filhos;", "O primeiro filho deve ser do tipo Polígono Geográfico;","O segundo filho deve ser do tipo Coordenada Geográfica", "Este nó possui retorno binário."],
        logoSVG: polygonIcon,
        properties:{}
    },
    {
        category: "math",
        type:"geo_distance_box",
        description: "Distância entre pontos",
        helperText:["Este nó deve ser utilizado quando se deseja definir a distância entre dois pares de coordenadas geográficas;", "Ele deve possuir necessariamente dois filhos;", "Ambos os filhos devem ser do tipo Coordenada Geográfica;", "Este nó possui retorno numérico;","Este nó retorna valores em metros."],
        logoSVG: distanceIcon,
        properties:{}
    },
    {
        category: "math",
        type:"module_box",
        description: "Módulo",
        helperText:["Este nó deve ser utilizado para obter o valor absoluto de um número;", "O retorno será sempre um número positivo;", "Este nó possui um filho;", "Este nó possui retorno numérico."],
        logoSVG: moduleIcon,
        properties:{}
    },
    {
        category: "math",
        type:"random_number_box",
        description: "Número Aleatório",
        helperText:["Este nó retorna um número aleatório, entre 0 e 1, com quatro casas decimais;", "O retorno será sempre um número positivo;", "Este nó possui retorno numérico."],
        logoSVG: moduleIcon,
        properties:{}
    },
    {
        category: "math",
        type:"maximum_box",
        description: "Máximo",
        helperText:["Este nó deve ser utilizado para obter o valor máximo entre dois números;", "Este nó possui dois filho;", "Este nó possui retorno numérico."],
        logoSVG: maximumIcon,
        properties:{}
    },
    {
        category: "math",
        type:"minimum_box",
        description: "Mínimo",
        helperText:["Este nó deve ser utilizado para obter o valor mínimo entre dois números;", "Este nó possui dois filho;", "Este nó possui retorno numérico."],
        logoSVG: minimumIcon,
        properties:{}
    },
    {
        category: "lists",
        type:"lists_get_column_from_field_box",
        description: "Recuperar valor de Lista a partir de campo",
        helperText:["Este nó deve ser usado para recuperar um valor de uma determinada lista;", "Este nó não possui filhos;", "O tipo de retorno depende do valor a ser recuperado."],
        logoSVG: listIcon,
        properties:{
            list_key:"",
            field_path:"",
            list_column_name:""
        },
        metadata:{
            list_type: "",
            field_type:"",
            list_column_type:""
        }
    },
    {
        category: "lists",
        type:"lists_get_column_box",
        description: "Recuperar valor de Lista",
        helperText:["Este nó deve ser usado para recuperar um valor de uma determinada lista;", "Este nó possui apenas um filho;", "O tipo de retorno depende do valor a ser recuperado."],
        logoSVG: listIcon,
        properties:{
            list_key:"",
            list_column_name:""
        },
        metadata:{
            list_type: "",
            list_column_type:""
        }
    },
    {
        category: "lists",
        type:"lists_includes_box",
        description: "Lista inclui",
        helperText:["Este nó deve ser usado para verificar se um dado está incluído na lista;", "Este nó possui um único filho que deve possuir o mesmo tipo que o campo chave da lista;", "O tipo de retorno desse nó é binário."],
        logoSVG: listIcon,
        properties:{
            list_key:""
        },
        metadata:{
            list_type: ""
        }
    },
    {
        category: "lists",
        type:"lists_includes_field_box",
        description: "Lista inclui campos",
        helperText:["Este nó deve ser usado para verificar se um campo está incluído na lista;", "Este nó não possui filhos;", "O tipo de retorno desse nó é binário."],
        logoSVG: listIcon,
        properties:{
            list_key:"",
            field_path:""
        },
        metadata:{
            list_type: "",
            field_type:""
        }
    },
    {
        category: "data_source",
        type:"signals_box",
        description: "Signals",
        helperText:["Este nó deve ser usado para definir um Signal;", "Um Signal nunca deve possuir um filho;", "O tipo de retorno desse nó varia com o Signal escolhido."],
        logoSVG: signalsIcon,
        properties:{
            signal_key: ""
        },
        metadata:{
            signal_type: ""
        }
    },
    {
        category: "data_source",
        type:"scripts_box",
        description: "Scripts",
        helperText:["Este nó deve ser usado para definir um Script;", "O número de filhos deste nó varia de acordo com o Script escolhido;", "O tipo de retorno desse nó varia com o Script escolhido."],
        logoSVG: scriptIcon,
        properties:{
            script_key: ""
        },
        metadata:{
            script_type: ""
        }
    },
    {
        category: "data_source",
        type:"processors_variables_box",
        description: "Variáveis do Processador",
        helperText:["Este nó deve ser utilizado para recuperar dados obtidos nos pré processamentos;", "Deve-se escolher qual dado deseja-se recuperar;", "Este nó não possui filhos;", "O seu retorno depende de qual dado está sendo recuperado."],
        logoSVG: variableBoxIcon,
        properties:{
            path:""
        }
    },
    {
        category: "data_source",
        type:"ocr_data_retrieval_box",
        description: "Recuperar Dados de OCR",
        helperText:[],
        logoSVG: databaseIcon,
        properties:{
            "template":"",
            "ocr_data_path":""
        }
    },
    {
        category: "data_source",
        type:"score_retrieval_box",
        description: "Recuperar Score",
        helperText:["Este nó deve ser utilizado para se recuperar o valor do Score da Árvore;", "Esse valor é sempre inicialmente zero, e pode ser modificado com a a caixa de 'Modificar Score';", "Este nó não possui nenhum filho;" ,"Este nó possui retorno numérico."],
        logoSVG: scoreRetrieveIcon,
        properties:{}
    },
    {
        category: "data_source",
        type:"score_modifier_box",
        description: "Incrementar Score",
        helperText:["Este nó deve ser utilizado para se incrementar o valor do Score da Árvore;", "Esse valor inicia-se em 0, e será somado ao filho deste nó;", "Este nó deve possuir um único filho, com retorno Numérico, cujo valor será somado ao valor atual do Score;" ,"Este nó não possui retorno."],
        logoSVG: scoreModifierIcon,
        properties:{}
    },
    {
        category: "data_source",
        type:"machine_learning_box",
        description: "Variáveis de Machine Learning",
        helperText:["Este nó deve ser utilizado para recuperar um valor obtido por execução de um modelo de machine learning;", "Deve-se escolher qual dado deseja-se recuperar;", "Este nó não possui filhos;", "O seu retorno depende de qual dado está sendo recuperado."],
        logoSVG: machineLearningIcon,
        properties:{
            model_hash:""
        }
    },
    {
        category: "advanced",
        type:"comparision_signals_box",
        description: "Comparação de Signals",
        helperText:["Este nó deve ser utilizado para criar comparações com os Signals;", "Este nó não possui filhos;", "Este nó possui retorno Binário."],
        logoSVG: comparisonIcon,
        properties:{
            signal_key: "",
            comparision_type:"",
            value:"",
            min_value: null,
            max_value:null
        },
        metadata:{
            signal_type: ""
        }
    },
    {
        category: "data_source",
        type:"event_field_box",
        description: "Campo do Evento",
        helperText:["Este nó deve ser usado para definir um Campo do Evento;", "Um Campo do Evento nunca deve possuir um filho;", "O tipo de retorno desse nó varia com o Campo do Evento escolhido."],
        logoSVG:cloudIcon,
        properties:{
            field_path: ""
        },
        metadata:{
            field_type: ""
        }
    },
    {
        category: "constant",
        type:"constant_amount_box",
        description: "Valor (R$)",
        helperText:["Este nó deve ser utilizado para escrever um valor em R$;", "Ele não deve possuir filhos;", "Possui um retorno 'Número'."],
        logoSVG:amountIcon, 
        properties:{
            value: ""
        }
    },
    {
        category: "constant",
        type:"constant_number_box",
        description: "Número",
        helperText:["Este nó deve ser utilizado para escrever um número;", "Ele não deve possuir filhos;", "Possui um retorno 'Número'."],
        logoSVG:numericIcon,
        properties:{
            value: ""
        }
    },
    {
        category: "constant",
        type:"constant_string_box",
        description: "Texto",
        helperText:["Este nó deve ser utilizado para escrever um texto;", "Ele não deve possuir filhos;", "Possui um retorno 'Texto'."],
        logoSVG:textIcon,
        properties:{
            value: ""
        }
    },
    {
        category: "constant",
        type:"constant_bool_box",
        description: "Binário",
        helperText:["Este nó deve ser utilizado para escrever um binário;", "Ele não deve possuir filhos;", "Possui um retorno 'Binário'."],
        logoSVG:trueIcon,
        properties:{
            value: true
        }
    },
    {
        category: "constant",
        type:"constant_datetime_box",
        description: "Data e Hora",
        helperText:["Este nó deve ser utilizado para escrever uma data e hora;", "Ele não deve possuir filhos;", "Possui um retorno 'Data e Hora'."],
        logoSVG:calendarIcon,
        properties:{
            value:""
        }
    },
    {
        category: "constant",
        type:"constant_time_box",
        description: "Hora",
        helperText:["Este nó deve ser utilizado para escrever um hora;", "Ele não deve possuir filhos;", "Possui um retorno 'Hora'."],
        logoSVG:clockIcon,
        properties:{value:""}
    },
    {
        category: "constant",
        type:"constant_timedelta_box",
        description: "Período de Tempo",
        helperText:["Este nó deve ser utilizado para escrever um período de tempo;", "Ele não deve possuir filhos;", "Possui um retorno 'Período de Tempo'."],
        logoSVG:timedeltaIcon,
        properties:{value:""}
    },
    {
        category: "constant",
        type:"constant_geo_point_box",
        description: "Coordenada Geográfica",
        helperText:["Este nó deve ser utilizado para escrever uma coordenada geográfica;", "Ele não deve possuir filhos;", "São aceitos valores de Longitude entre +180 e -180;","São aceitos valores de Latitude entre +90 e -90;","Possui um retorno 'Coordenada Geográfica'."],
        logoSVG:locationIcon,
        properties:{
            value: ""
        }
    },
    {
        category: "math",
        type:"time_extraction_box",
        description: "Extração de Horário",
        helperText:["Este nó deve ser utilizado para retirar um Horário de um Data e Horário;", "Ele deve possuir um filho;", "Possui um retorno 'Horário'."],
        logoSVG:extractTimeIcon,
        properties:{}
    },
    {
        category: "math",
        type:"date_extraction_box",
        description: "Extração de Data",
        helperText:["Este nó deve ser utilizado para retirar uma Data de um Data e Horário;", "Ele deve possuir um filho;", "Possui um retorno 'Data e Horário'."],
        logoSVG:extractTimeIcon,
        properties:{}
    },
    {
        category: "math",
        type:"weekday_extraction_box",
        description: "Extração de Dia da Semana",
        helperText:["Este nó deve ser utilizado para se extrair o dia da semana de um Data e Horário;", "Ele não deve possuir filhos;", "O seu retorno irá variar de 1 a 7, onde 1 é Domingo e 7 Sábado","Possui um retorno 'Numérico'."],
        logoSVG:extractTimeIcon,
        properties:{}
    },
    {
        category: "string_handling",
        type:"string_to_lower_box",
        description: "Texto para minúscula",
        helperText:["Este nó deve ser utilizado para transformar um 'Texto' em letras minúsculas;", "Ele deve possuir um único filho do tipo 'Texto';", "Possui um retorno 'Texto'."],
        logoSVG:lowerCaseIcon,
        properties:{}
    },
    {
        category: "string_handling",
        type:"string_to_upper_box",
        description: "Texto para maiúscula",
        helperText:["Este nó deve ser utilizado para transformar um 'Texto' em letras maiúsculas;", "Ele deve possuir um único filho do tipo 'Texto';", "Possui um retorno 'Texto'."],
        logoSVG:upperCaseIcon,
        properties:{}
    },
    {
        category: "string_handling",
        type:"string_contain_box",
        description: "Texto contém",
        helperText:["Este nó deve ser utilizado para se um 'Texto' contém outro;", "Ele deve possuir dois filhos do tipo 'Texto';", "Caso o primeiro filho contenha o segundo, retornará 'Verdadeiro', caso contrário, 'Falso';", "Possui um retorno 'Binário'."],
        logoSVG:textBlackIcon,
        properties:{
            decode_escape_string:false
        }
    },
    {
        category: "string_handling",
        type:"string_concatenate_box",
        description: "Concatenar texto",
        helperText:["Este nó deve ser utilizado para concatenar dois 'Texto';", "Ele deve possuir dois filhos do tipo 'Texto';", "Possui um retorno 'Texto'."],
        logoSVG:textBlackIcon,
        properties:{
            decode_escape_string:false
        }
    },
    {
        category: "string_handling",
        type:"string_split_box",
        description: "Dividir texto",
        helperText:["Este nó deve ser utilizado para dividir um 'Texto' e coletar uma das partes;", "Ele deve possuir um único filho do tipo 'Texto';", "O filho deste nó será o 'Texto' onde a divisão ocorrerá;", "A propriedade 'Dvidir em' será os pontos onde a divisão ocorrerá, enquanto 'Coletar índice' é o índice a ser coletado. Por fim, 'Máximo de Divisões' diz quantas divisões serão feitas. Se nada for preenchido todas as divisões ocorrerá;", "Caso tente-se coletar um índice não existente, '' será retornado;", "O 1º valor da divisão será o índice 0, o 2º, 1 e assim por diante. Índices negativos também são aceitos: -1 siginifica o último, -2 o penúltimo;","Possui um retorno 'Texto'."],
        logoSVG:textBlackIcon,
        properties:{
            "to_split":"",
            "get_index":0
        }
    },
    {
        category: "string_handling",
        type:"string_slice_box",
        description: "Coletar parte do texto",
        helperText:["Este nó deve ser utilizado para coletar uma fatia de um 'Texto';", "Ele deve possuir um único filho do tipo 'Texto';", "O filho deste nó será o 'Texto' de onde a fatia será coletada;", "Caso a propriedade 'Do começo' esteja marcada, a fatia iniciará-se do começo (Índice 0), caso contrário, do valor 'Índice Inicial'", "Caso a propriedade 'Ao fim' esteja marcada, a fatia conterá os caracteres até o fim do 'Texto', caso contrário, até o valor 'Índice Final' (não inclui este);", "Ambas propriedades 'Índices' aceitam valores negativos, -1, significa o último caracter, -2 o penúltimo e assim por diante;","Possui um retorno 'Texto'."],
        logoSVG:textBlackIcon,
        properties:{
            "from_start":true,
            "to_end":true
        }
    },
    {
        category: "string_handling",
        type:"string_replace_box",
        description: "Substituir texto",
        helperText:["Este nó deve ser utilizado para substituir ocorrências em um 'Texto' por novos valores;", "Ele deve possuir um único filho do tipo 'Texto';", "O filho deste nó será o 'Texto' onde a substituição ocorrerá;", "A propriedade 'A substituir' será os valores que serão substituídos, enquanto 'Novo Valor' serão os novos valores. Por fim, 'Máximo de Substituições' diz quantas substituições serão feitas. Se nada for preenchido todas as ocorrências serão substituídas;","Possui um retorno 'Texto'."],
        logoSVG:textBlackIcon,
        properties:{
            "replaced":"",
            "to_replace":""
        }
    },
    {
        category: "string_handling",
        type:"string_normalize_box",
        description: "Normalizar Texto",
        helperText:["Este nó deve ser utilizado para remover todos os acentos e caracteres especiais em um 'Texto';", "Ele deve possuir um único filho do tipo 'Texto';", "O filho deste nó será o 'Texto' onde a remoção ocorrerá;","Possui um retorno 'Texto'."],
        logoSVG:textBlackIcon,
        properties:{}
    },
    {
        category: "string_handling",
        type:"string_similarity_box",
        description: "Comparação de Textos",
        helperText:["Este nó deve ser utilizado para comparar dois 'Textos' diferentes;", "Ele deve possuir dois filhos do tipo 'Texto';", "Os filhos deste nó serão comparados e resultará em uma porcentagem de similaridade que varia entre 0 e 100","Possui um retorno 'Número'."],
        logoSVG:textBlackIcon,
        properties:{}
    },
    {
        category: "string_handling",
        type:"string_length_box",
        description: "Contar caracteres do Texto",
        helperText:["Este nó deve ser utilizado para contar o número de caracteres em um texto;", "Ele deve possuir um único filho do tipo 'Texto', que terá os caracteres contabilizados;", "Possui um retorno 'Número'."],
        logoSVG:textBlackIcon,
        properties:{}
    },
    {
        category: "device_scan",
        type:"device_scan_data_retrieval_box",
        description: "Recuperação de Dados do Dispositivo",
        helperText:["Este nó deve ser usado para recuperar o valor de um dado do dispositivo;", "Um nó deste tipo não deve possuir filhos;", "O tipo de retorno desse nó varia com o Dado do Dispositivo escolhido."],
        logoSVG:deviceIcon,
        properties:{
            session_data_path:""            
        }
    },
    {
        category: "device_scan",
        type:"device_scan_user_same_devices_box",
        description: "Mesmo usuário com mesmo dispositivo",
        helperText:["Este nó deve ser usado para recuperar se o usuário já foi visto com este dispositivo antes;", "Um nó deste tipo não aceita filhos;", "Pode-se escolher que o batimento aconteça com o CPF do Usuário e/ou a sua identificação do cadastro;", "Além disso, pode-se optar por verificar os eventos de Scan do Dispositivo apenas na própria companhia, ou em toda a base de dados ZAIG;", "Este nó possui retorno do tipo 'Binário'."],
        logoSVG:deviceIcon,
        properties:{
            document_number_search:false,
            external_user_id_search:false,
            outside_company_search:false,
            timedelta_value:null
        }
    },
    {
        category: "device_scan",
        type:"device_scan_user_different_devices_box",
        description: "Mesmo usuário com diferentes dispositivos",
        helperText:["Este nó deve ser usado para recuperar se o usuário já foi visto com outros dispositivos antes;", "Um nó deste tipo não aceita filhos;", "Pode-se escolher que o batimento aconteça com o CPF do Usuário e/ou a sua identificação do cadastro;", "Além disso, pode-se optar por verificar os eventos de Scan do Dispositivo apenas na própria companhia, ou em toda a base de dados ZAIG;", "Este nó possui retorno do tipo 'Binário'."],
        logoSVG:deviceIcon,
        properties:{
            document_number_search:false,
            external_user_id_search:false,
            outside_company_search:false,
            timedelta_value:null
        }
    },
    {
        category: "device_scan",
        type:"device_scan_device_different_users_box",
        description: "Mesmo dispositivo com diferentes usuários",
        helperText:["Este nó deve ser usado para recuperar se o dispositivo já foi visto com outros usuários antes;", "Um nó deste tipo não aceita filhos;", "Pode-se escolher que o batimento aconteça com o CPF do Usuário e/ou a sua identificação do cadastro;", "Além disso, pode-se optar por verificar os eventos de Scan do Dispositivo apenas na própria companhia, ou em toda a base de dados ZAIG;", "Este nó possui retorno do tipo 'Binário'."],
        logoSVG:deviceIcon,
        properties:{
            document_number_search:false,
            external_user_id_search:false,
            outside_company_search:false,
            timedelta_value:null,
            method: null
        }
    },
    {
        category: "device_scan",
        type:"device_scan_device_fraud_box",
        description: "Dispositivo visto em fraudes",
        helperText:["Este nó deve ser usado para recuperar se o dispositivo já foi marcado como fraude antes;", "Um nó deste tipo não aceita filhos;", "Além disso, pode-se optar por verificar os eventos de Scan do Dispositivo apenas na própria companhia, ou em toda a base de dados ZAIG;", "Este nó possui retorno do tipo 'Binário'."],
        logoSVG:deviceIcon,
        properties:{
            outside_company_search:false,
            timedelta_value:null,
            method: null
        }
    },
    {
        category: "device_scan",
        type:"device_scan_existent_box",
        description: "Scan do Dispositivo existente",
        helperText:["Este nó deve ser usado para recuperar se existe na base de dados ZAIG um Scan desse dispositivo usado para cadastro;", "Este nó possui retorno do tipo 'Binário'."],
        logoSVG:deviceIcon,
        properties:{}
    },
    {
        category: "device_scan",
        type:"device_scan_gps_coordinates_existent_box",
        description: "Coordenadas GPS do Dispositivo disponíveis",
        helperText:["Este nó deve ser usado para recuperar se há localização GPS do Scan do dispositivo;", "Este nó possui retorno do tipo 'Binário'."],
        logoSVG:deviceIcon,
        properties:{}
    },
    {
        category: "device_scan",
        type:"device_scan_gps_coordinates_box",
        description: "Coordenadas GPS do Dispositivo",
        helperText:["Este nó deve ser usado para recuperar a localização GPS do Scan do dispositivo;", "Este nó possui retorno do tipo 'Coordenada Geográfica'.",  "Caso inexista GPS disponível de dispositivo, é retornada a coordenada [0, 0]."],
        logoSVG:deviceIcon,
        properties:{}
    },
    {
        category: "advanced",
        type:"event_field_counter_box",
        description: "Contador de Campo do Evento",
        helperText:["Este nó deve ser utilizado para se contar ocorrências de Campos do Evento em um periodo determinado;", "Deve-se escolher os Campos que serão contados, o número mínimo de correspondências, e qual o prazo anterior que será considerado;", "Caso nenhum período de tempo seja especificado, será considerado todo o histórico;", "O Número de Correspondências é a quantidade mínima dos Campos escolhidos que devem ser iguais para entrar na contagem;","Este nó possui retorno numérico."],
        logoSVG: abacusIcon,
        properties:{
            field_paths:[""],
            match_number: 1,
            timedelta_value:null
        }
    },
    {
        category: "advanced",
        type:"event_field_cached_counter_box",
        description: "Contador de Campo do Evento com Memoria Cache",
        helperText:["Este nó deve ser utilizado para se contar ocorrências cacheadas de Campos do Evento em um periodo determinado;", "Deve-se escolher os Campos que serão contados, o número mínimo de correspondências, e qual o prazo anterior que será considerado;", "Caso nenhum período de tempo seja especificado, será considerado todo o histórico;", "O Número de Correspondências é a quantidade mínima dos Campos escolhidos que devem ser iguais para entrar na contagem;","Este nó possui retorno numérico."],
        logoSVG: abacusIcon,
        properties:{
            field_paths:[""],
            match_number: 1,
            timedelta_value:null
        }
    },
    {
        category: "advanced",
        type:"event_field_swap_counter_box",
        description: "Contador de Campo do Evento com Memória Cache",
        helperText:["Este nó deve ser utilizado para se contar ocorrências cacheadas de Campos do Evento em um periodo determinado;", "Deve-se escolher os Campos que serão contados, o número mínimo de correspondências, e qual o prazo anterior que será considerado;", "Caso nenhum período de tempo seja especificado, será considerado todo o histórico;", "O Número de Correspondências é a quantidade mínima dos Campos escolhidos que devem ser iguais para entrar na contagem;","Este nó possui retorno numérico."],
        logoSVG: abacusIcon,
        properties:{
            field_paths:[""],
            match_number: 1,
            timedelta_value:null
        }
    },
    {
        category: "advanced",
        type:"status_counter_box",
        description: "Contador de Status",
        helperText:["Este nó deve ser utilizado para se contar ocorrências de Status em um periodo determinado;", "Deve-se escolher qual Status e quais tipos serão contados, sob o aspecto de OU, e qual o prazo anterior que será considerado;", "Caso nenhum período de tempo seja especificado, será considerado todo o histórico;" ,"Este nó possui retorno numérico."],
        logoSVG: abacusIcon,
        properties:{
            path:"",
            anchor_path:"",
            status_list:[],
            timedelta_value:null
        }
    },
    {
        category: "advanced",
        type:"event_field_accumulator_box",
        description: "Acumulador de Campo do Evento",
        helperText:["Este nó deve ser utilizado para se obter a soma de ocorrências anteriores de Campos do Evento em um periodo determinado;", "Deve-se escolher o Campo que será acumulado, quais os Campos de Referência, o número mínimo de correspondências, e qual o prazo anterior que será considerado;", "Exemplo: Caso o Valor da Transação seja escolhido em Campo Acumulado e CPF, em Campo de Referência, o nó retornará a soma dos Valores de Transação para o CPF atual;", "Caso nenhum período de tempo seja especificado, será considerado todo o histórico;", "Este nó possui retorno numérico."],
        logoSVG: accumulateIcon,
        properties:{
            accumulated_field_path:"",
            referenced_field_paths:[""],
            match_number: 1,
            timedelta_value:null
        }
    },
    {
        category: "advanced",
        type:"event_field_cached_accumulator_box",
        description: "Acumulador de Campo do Evento com Memoria Cache",
        helperText:["Este nó deve ser utilizado para se obter a soma de ocorrências anteriores de Campos do Evento cacheadas em um periodo determinado;", "Deve-se escolher o Campo que será acumulado, quais os Campos de Referência, o número mínimo de correspondências, e qual o prazo anterior que será considerado;", "Exemplo: Caso o Valor da Transação seja escolhido em Campo Acumulado e CPF, em Campo de Referência, o nó retornará a soma dos Valores de Transação para o CPF atual;", "Caso nenhum período de tempo seja especificado, será considerado todo o histórico;", "Este nó possui retorno numérico."],
        logoSVG: accumulateIcon,
        properties:{
            accumulated_field_path:"",
            referenced_field_paths:[""],
            match_number: 1,
            timedelta_value:null
        }
    },
    {
        category: "advanced",
        type:"event_field_swap_accumulator_box",
        description: "Acumulador de Campo do Evento com Memória Cache",
        helperText:["Este nó deve ser utilizado para se obter a soma de ocorrências anteriores de Campos do Evento cacheadas em um periodo determinado;", "Deve-se escolher o Campo que será acumulado, quais os Campos de Referência, o número mínimo de correspondências, e qual o prazo anterior que será considerado;", "Exemplo: Caso o Valor da Transação seja escolhido em Campo Acumulado e CPF, em Campo de Referência, o nó retornará a soma dos Valores de Transação para o CPF atual;", "Caso nenhum período de tempo seja especificado, será considerado todo o histórico;", "Este nó possui retorno numérico."],
        logoSVG: accumulateIcon,
        properties:{
            accumulated_field_path:"",
            referenced_field_paths:[""],
            match_number: 1,
            timedelta_value:null
        }
    },
    {
        category: "advanced",
        type:"event_field_variability_box",
        description: "Variabilidade de Campo do Evento",
        helperText:["Este nó deve ser utilizado para se obter a contagem da variação de um Campo com relação a outros, em um período determinado;", "Deve-se escolher os Campos de Referência, o número mínimo de correspondências desses campos, e por fim o Campo Variado, cuja contagem de ocorrências distintas acontecerá;", "Exemplo: Caso o Número de Telefone seja escolhido em Campo Variado e CPF, em Campo de Referência, o nó retornará a contagem de Números de Telefone diferentes para o CPF atual;", "Caso nenhum período de tempo seja especificado, será considerado todo o histórico;","Este nó possui retorno numérico."],
        logoSVG: changeCircleIcon,
        properties:{
            variable_field_path:"",
            referenced_field_paths:[""],
            match_number: 1,
            timedelta_value:null
        }
    },
    {
        category: "advanced",
        type:"verify_lock_box",
        description: "Verificar Bloqueios",
        helperText:["Este nó deve ser utilizado para obter se existe um determinado Bloqueio em quem está sendo analisado;", "Deve-se escolher qual tipo de Bloqueio deseja-se verificar;", "Este nó possui retorno Binário."],
        logoSVG: verifyIcon,
        properties:{
            lock_type:""
        }
    },
    {
        category: "basic",
        type:"label_box",
        description: "Label",
        helperText:["Este nó deve ser utilizado como comentário;", "Um nó deste tipo não deve possuir filhos;", "Este nó não possui retorno."],
        logoSVG: labelIcon,
        properties:{
            label:""
        }
    },
    {
        category: "action",
        type:"sms_box",
        description: "Validação SMS",
        helperText:["Este nó deve ser utilizado quando se quer enviar um SMS de validação ao usuário;", "Um nó deste tipo não deve possuir filhos;", "Este nó não possui retorno."],
        logoSVG: greenDeviceIcon,
        properties:{
            label:""
        }
    },
    {
        category: "action",
        type:"challenge_box",
        description: "Validação",
        helperText:["Este nó deve ser utilizado quando se quer enviar uma validação ao usuário;", "Um nó deste tipo não deve possuir filhos;", "Este nó possui retorno Binário."],
        logoSVG: greenDeviceIcon,
        properties:{}
    },
    {
        category: "action",
        type:"pre_authorization_setter_box",
        description: "Definir valor da Pré-Autorização",
        helperText:["Este nó deve ser utilizado para definir o valor da Pré-Autorização;", "Um nó deste tipo deve possuir um filho do tipo 'Número';", "Este nó não possui retorno."],
        logoSVG: greenDeviceIcon,
        properties:{
            label:""
        }
    },
    {
        category: "user_variables",
        type:"update_variable_box",
        description: "Alterar Variável",
        helperText:["Este nó deve ser utilizado para alterar o valor de alguma variável;", "Um nó deste tipo deve possuir um filho do tipo 'Texto', 'Número' ou 'Binário';", "O tipo do filho deve estar de acordo com a variável escolhida;", "Este nó não possui retorno."],
        logoSVG: updateVariableIcon,
        properties:{
            variable_name:""
        }
    },
    {
        category: "user_variables",
        type:"get_variable_box",
        description: "Resgatar Variável",
        helperText:["Este nó deve ser utilizado para recuperar o valor de alguma variável;", "Um nó deste tipo não deve possuir filhos;", "Este nó possui retorno."],
        logoSVG: getVariableIcon,
        properties:{
            variable_name:""
        }
    }

]

export const handleTranslation = (list) => {
    return list.map(eachElement => {
      eachElement.description = i18next.t(eachElement.path.split('.').join(''))
      return eachElement
    })
  }
  
export const handleAlphabeticallySort = (list, property_to_sort_by) => {
return list.sort((a,b) => a[property_to_sort_by] === b[property_to_sort_by] ? 0 : a[property_to_sort_by] > b[property_to_sort_by] ? 1 : -1)
}
