import React, {useState, useReducer, useContext} from 'react'
import { useTranslation } from "react-i18next"
import {useStateConnectedToCache, decisionReducer} from "../../../Utils/requestUtils";
import ErrorBoundary from "../../../Utils/ErrorBoundary"
import Filter from './Filter/Filter';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { CircularProgress } from '@material-ui/core';
import {getConfirmationIcon} from "../../../Utils/utils"
import AuthContext from "../../../../context/auth-context"
import axios from "axios";
import Modal from '../../../@Components/Modal/Modal';
import * as S from './styles'

function ReportTemplateItem(props){
    const [t] = useTranslation()
    const {templatesList, handleReloadPage} = props
    const userData = useContext(AuthContext).user_data

    const [open, setOpen] = useState(false)

    const local_storage_object_preffix = "report" 
    const [pageParams, setPageParams] = useStateConnectedToCache(local_storage_object_preffix)
    const [dialogState, setDialogState] = useReducer(
        decisionReducer,
        {isLoading: false, isError: false, finished:false}
    ) 
    const [customizedErrorMessage, setCustomizedErrorMessage] = useState("")  

    const handleSubmit = (report_template_key) => {
        let payload = {
            report_template_key: report_template_key
         }
        let filters = {} 
        Object.keys(pageParams.filters).forEach((key,index) => {
            filters[key] = pageParams.filters[key]
        })
        payload['filters'] = filters

        setDialogState({type:"send_request_init"})
        let requestHeaders = {headers:{}}
        if (userData.business_group_key) {
            requestHeaders = {headers:{company_key:userData.company_key}}
        }
        axios.post('/dash/report/report', payload, requestHeaders).then(response => {
            setDialogState({type:"send_request_success"})

        }).catch(error => {
            setDialogState({type: "send_request_failure"})
            setCustomizedErrorMessage(error.response.data.message)
        });

    }

    const [reportTemplate, setReportTemplate] = useState("")
    const handleOpenModal = (index) =>{
        setOpen(true)
        setReportTemplate(templatesList.report_templates[index])
    }

    const closeModal = () => {
        setOpen(false)
        handleReloadPage()
    }

    if(dialogState.finished && dialogState.isLoading){
		return (
            <div style={{width: "310px", minHeight:"217px", display:"flex"}}>
                <CircularProgress style={{margin:"auto"}} />
            </div>
		)
    }
    else if (dialogState.finished){
        let confirmationData = {    
            message: dialogState.message,
            success: !dialogState.isError
        }
		return (   
         <Dialog 
            open={open} 
            onClose={closeModal} 
            keepMounted={false}
         >
             <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginRight:"24px"}}>
                 <DialogTitle style={{paddingBottom: "0px"}} className={["blueText", "normalMediumSize"].join(" ")}>{t("report_request")}</DialogTitle>
                 <IconButton size="small" onClick={closeModal}>
                     <CloseIcon fontSize="small"/> 
                 </IconButton >
             </div>
             
             <DialogContent >
                <div style={{width: "310px", minHeight:"217px"}}>
                    <div className="internalCardContainer" style={{display:"flex", height:"40%", justifyContent:"center"}}>
                        {getConfirmationIcon(confirmationData.success)}
                    </div>
                    <div className={["internalCardContainer", "normalMediumSize"].join(" ")} style={{display:"flex", textAlign:"center", justifyContent:"center"}}>
                        {confirmationData.success ? confirmationData.message : customizedErrorMessage}
                    </div>
                    <div className="internalCardContainer" style={{display:"flex", justifyContent:"center"}}>
                        <div 
                            className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                            onClick={()=> {setOpen(false); handleReloadPage()}} 
                        >
                            {t("go_back_action") }
                        </div>
                    </div>
                </div>
             </DialogContent>
         </Dialog>
		)
    }else{
        return (
            <div style={{padding: 0, margin: 0}}>
                <div className="listCardItem normalText subtitleSize" style={{display: 'flex', flexDirection: 'column'}}> 
                    <div style={{padding: "5px 30px", margin:"5px", fontWeight:'bold'}}> 
                        {(userData.business_group_key && templatesList.company_name) ? templatesList.company_name : null}
                    </div>
                    <div style={{ display: 'grid', width: "100%", gridTemplateColumns: 'auto auto auto auto'}}>
                        {templatesList.report_templates.map((report_template, index) => (
                        <S.ReportButton key={report_template.report_template_key} 
                            id={report_template.report_template_key} 
                            onClick={() => handleOpenModal(index)} 
                            >
                            {report_template.name}
                        </S.ReportButton> 
                        ))}
                    </div>
                </div>
                {open &&
                    <Modal 
                        closeModal={closeModal}
                        class_size={"Modal_size_reports"}
                        title={t("report_request")}
                        >
                            <div className='Modal_body_reports' style={{ display: 'flex'}}>
                                <div className={["internalCollapse"].join(" ")} style={{display: "flex", justifyContent: "space-around"}}> 
                                    <ErrorBoundary doNothing><Filter local_storage_object_preffix={local_storage_object_preffix} setPageParams={setPageParams} filters={reportTemplate.filters}/></ErrorBoundary>
                                </div>
                                <div className="internalCardContainer Modal_button">
                                    <div
                                        style={{ padding: "5px 30px", margin:"5px", width:"150px" }}
                                        className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                                        onClick={() => {handleSubmit(reportTemplate.report_template_key)}}                        
                                    >
                                        {t("submit_action")}
                                    </div>
                                </div>
                            </div>
                    </Modal>}
                </div>
                )
    }

}

export default ReportTemplateItem