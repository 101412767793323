import React, { useState } from "react";
import Collapse from "@material-ui/core/Collapse";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import IconButton from "@material-ui/core/IconButton";
import {boavista_translations} from "../../../../utils/utils";
import BoavistaNegativeNotesTable from "./BoavistaNegativeNotesTable/BoavistaNegativeNotesTable"
import { useTranslation } from "react-i18next";
import BoavistaDebtsSummaryCard from "../BoavistaSummaryCard/BoavistaDebtsSummaryCard";
import BoavistaInquiriesSummaryCard from "../BoavistaSummaryCard/BoavistaInquiriesSummaryCard";
import BoavistaProtestsSummaryCard from "../BoavistaSummaryCard/BoavistaProtestsSummaryCard";

function BoavistaNegativeNotesCard(props) {

    const { 
        negative_notes_data,
        has_negative_note,
        negative_note_type,
        tableStyle,
        preparePDF,
        setHasBoavistaInquiriesImg,
        setBoavistaInquiries2Img
    } = props



    const { t } = useTranslation()

    const [expanded, setExpanded] = useState(true);

    const handleExpand = (event) => {
        event.stopPropagation();
        setExpanded(!expanded);
    };
    
    return (
        <div className="avoidPageBreakInside" style={{padding:"10px"}}>
        <div className="analysisCard">
            <div style={{display:"flex"}}>
                <div 
                    className={["blueText", "subtitleSize", "bold"].join(" ")}
                    style={{margin:"auto 10px"}}
                >
                    {t(negative_note_type)}
                </div>
                <div style={{margin:"auto 10px"}}>
                    {has_negative_note ?  boavista_translations.negative.siglum : boavista_translations.positive.siglum}
                </div>
                {has_negative_note&&
                <IconButton style={{marginLeft: "auto"}} onClick={handleExpand}>
                    {expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </IconButton>}
            </div>
            <div style={{gridTemplateColumns: tableStyle}}>
            {has_negative_note &&
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    {(negative_note_type==="debts")&&
                        <BoavistaDebtsSummaryCard 
                            debts={negative_notes_data}
                        />}
                    {(negative_note_type==="inquiries")&&
                        <BoavistaInquiriesSummaryCard 
                            inquiries={negative_notes_data}
                            preparePDF={preparePDF}
                            setHasBoavistaInquiriesImg = {setHasBoavistaInquiriesImg}
                            setBoavistaInquiries2Img = {setBoavistaInquiries2Img}
                        />}
                    {(negative_note_type==="protests")&&
                        <BoavistaProtestsSummaryCard protests={negative_notes_data}/>}
                    <div className={["normalText", "normalMediumSize", "internalCardContainer"].join(" ")}>
                        <BoavistaNegativeNotesTable negative_notes_data={negative_notes_data} negative_note_type={negative_note_type} isPDF={false}/>
                    </div>
                </Collapse>}
            </div>
        </div>
        </div>
    );
}

export default BoavistaNegativeNotesCard