import {useState, useEffect} from 'react'
import i18next from "i18next";

export const dataFetchReducer = (state, action) => {
    const { t } = i18next
    let defaultMessage
    switch(action.type){
        case "data_fetch_init":
            return {
                ...state,
                isLoading: true,
                isError: false,
                fetchedData: null                  
            }
        case "data_fetch_reset":
                return {
                    ...state,
                    isLoading: true,
                    isError: false,
                    fetchedData: []                  
                }
        case "data_fetch_success":
            return {
                ...state,
                isLoading: false,
                isError: false,
                fetchedData: action.payload                    
            }
        case "data_fetch_failure_403":
            defaultMessage = t("forbidden_message")
            return {
                ...state,
                isLoading: false,
                isError: true,
                errorMessage: action.message || defaultMessage
            }
        case "data_fetch_failure_404":
            defaultMessage = t("not_found_message")
            return {
                ...state,
                isLoading: false,
                isError: true,
                errorMessage: action.message || defaultMessage
            }
        case "data_fetch_failure":
            defaultMessage = t("unexpect_condition_message")
            return {
                ...state,
                isLoading: false,
                isError: true,
                errorMessage: action.message || defaultMessage
            }
        default:
            throw new Error("It is coming " + action.type);  

    }
}

export const decisionReducer = (state, action) => {
    const { t } = i18next
    switch(action.type){
        case "send_request_init":
            return {
                ...state,
                isLoading: true,
                isError: false,
                finished:true       
            }
        case "send_request_success":
            return {
                ...state,
                isLoading: false,
                isError: false,
                finished:true,
                message: t("success_message")                   
            }
        case "send_request_failure_403":
            return {
                ...state,
                isLoading: false,
                isError: true,
                finished:true,
                message: t("forbidden_message")                   
            }
        case "send_request_failure_409":
            return {
                ...state,
                isLoading: false,
                isError: true,
                finished:true,
                message: t("decision_already_taken_message")              
            }
        case "send_request_failure":
            return {
                ...state,
                isLoading: false,
                isError: true,
                finished:true, 
                message: t("unexpect_condition_message")                    
            }
        case "default_state":
            return {
                isLoading: false,
                isError: false,
                finished:false,     
            }
        default:
            throw new Error("It is coming " + action.type);  
    }
}

export const useStateConnectedToCache = (local_storage_object_preffix, initialFilters=null) => {
    let initialPageParams = {page:1, filters:{}}
    if (initialFilters){
        initialPageParams.filters = initialFilters
    }
    const [pageParams, setPageParams] = useState(
        JSON.parse(sessionStorage.getItem(local_storage_object_preffix + "PageParams")) || initialPageParams
    );
    useEffect(() => {
        sessionStorage.setItem(local_storage_object_preffix + "PageParams", JSON.stringify(pageParams))
    },[pageParams, local_storage_object_preffix]);
    return [pageParams, setPageParams]
}
