import React, {useState, useContext} from 'react'
import {useHistory} from "react-router-dom"
import TextField from '@material-ui/core/TextField';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import {getConfirmationIcon } from "../../utils/utils"
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputMask from "react-input-mask";
import axios from 'axios'
import { CircularProgress } from '@material-ui/core';
import AuthContext from '../../../../context/auth-context';
import moment from "moment"

const theme = createMuiTheme({
    palette:{
        primary: {
            main:"#0b1f82"
        },
        secondary: {
            main: "#FF0000"
        },
    }
  });

  function validateCPF(cpf) {
    if (typeof cpf !== "string") return false
    cpf = cpf.replace(/[^\d]+/g,'')
    
    if (
        !cpf ||
        cpf.length !== 11 ||
        cpf === "00000000000" ||
        cpf === "11111111111" ||
        cpf === "22222222222" ||
        cpf === "33333333333" ||
        cpf === "44444444444" ||
        cpf === "55555555555" ||
        cpf === "66666666666" ||
        cpf === "77777777777" ||
        cpf === "88888888888" ||
        cpf === "99999999999" 
    ) return false

    var sum = 0
    var remainder
    for (var count = 1; count <= 9; count++) 
        sum = sum + parseInt(cpf.substring(count-1, count)) * (11 - count)
    remainder = (sum * 10) % 11
    if ((remainder === 10) || (remainder === 11))  remainder = 0
    if (remainder !== parseInt(cpf.substring(9, 10)) ) return false

    sum = 0
    for (count = 1; count <= 10; count++) 
        sum = sum + parseInt(cpf.substring(count-1, count)) * (12 - count)
    remainder = (sum * 10) % 11
    if ((remainder === 10) || (remainder === 11))  remainder = 0
    if (remainder !== parseInt(cpf.substring(10, 11))) return false

    return true
}


function InputContent (props){

    let store_account = useContext(AuthContext).user_data.rental_store

    const [pendingState, setPendingState] = useState(false)
    const [status, setStatus] = useState(null)

    const [nameValue, setNameValue] = useState("")
    const [cpfValue, setCPFValue] = useState("")
    const [birthdateValue, setBirthdateValue] = useState("")
    const [emailValue, setEmailValue] = useState("")
    const [areaCodeValue, setAreaCodeValue] = useState("")
    const [numberValue, setNumberValue] = useState("")
    const [postalCodeValue, setPostalCodeValue] = useState("")
    const [estimatedFinalDateValue, setEstimatedFinalDateValue] = useState("")

    const [nameError, setNameError] = useState(true)
    const [cpfError, setCPFError] = useState(true)
    const [birthdateError, setBirthdateError] = useState(true)
    const [areaCodeError, setAreaCodeError] = useState(true)
    const [numberError, setNumberError] = useState(true)
    const [postalCodeError, setPostalCodeError] = useState(true)
    const [estimatedFinalDateError, setEstimatedFinalDateError] = useState(true)
    const [storeValue, setStoreValue] = useState(store_account || null)
    const [carGroupValue, setCarGroupValue] = useState(null)

    const local_stores = ["AJU3","AMC2","APG1","APH1","APQ1","APS3","BEL4","BEL5","BEL6","BHZ4","BHZ5","BHZ6","BHZ7","BHZ8","BRP2",
    "BSB2","BSB4","BSB5","BSB6","BSS1","BTM1","CAC4","CAC5","CAN2","CBT2","CGB4","CGB5","CGHU","CGR2","CGR3",
    "CGY1","CKS5","CKS6","CPQ1","CPQ2","CPQ6","CPV1","CTG3","CWB1","CWB2","DIQ4","DUQ1","EMB1","EMB2","EMB3",
    "EMB5","ENP2","FES2","FLN3","FLN5","FOR2","FOR3","FRC2","FRM1","GCV2","GRU1","GRU3","GUP2","GYN3","GYN4","IGU7",
    "IMP6","IND3","IOS5","IPN3","JBA1","JDF3","JDF4","JDI3","JDO3","JOI5","JOI6","JPA2","JPA3","KGO1","KRJ1","KRJ3","KRJ4","LAJ1",
    "LDB7","LDB8","MAB4","MAB5","MAO3","MCP2","MCP3","MCZ2","MCZ3","MGF3","MGF4","MOC5","MOG3","MVF1","NAT4","NAT5",
    "NHA4","NLN1","NVT4","OPS3","OSC1","PCB2","PDG1","PGZ2","PMW3","PMW4","POA3","POA4","PPR3","PTO3","PTO4","PVH3","QBF1",
    "QTI1","QTI2","RAO3","RAO4","RBR3","REC4","REC5","REC6","RES3","RI4G","RIO1","RIO2","RIO3","RIO4","RIO9","RIOA","RIOC","RIOG","RIOI",
    "RION","RIOS","SAO2","SAO3","SAOG","SAOI","SAOJ","SAON","SAOP","SAOS","SAOT","SBC5","SCA2","SCP1","SJK3","SLZ3","SLZ4","SMR3","SOD2",
    "SPJD","SPRT","SPSA","SPVL","SPWP","SSA2","SSA6","SSA7","SSA8","SSA9","SSZ4","STA1","STC1","STL2","SUZ3","SVI2","TAB1","TAU2","THE4","THE5",
    "TUV1","TXF3","TXF4","UBA4","UDI5","UDI6","VDC3","VDC4","VIX2","VIX5"];
    const car_groups = require("../../../../assets/json/group_cars.json");
    const car_group_keys = Object.keys(car_groups).sort((a, b) => a === b ? 0 : a > b ? 1 : -1)

    let history = useHistory()
    
    let nameInputObject = {
        label: "Nome"
    }

    let birthdateInputObject = {
        label: "Data de Nascimento",
        validation_size: 10
    }

    let cpfInputObject = {
        label: "CPF",
        validation_size: 14,
        mask_type: "999.999.999-99",
    }

    let emailInputObject = {
        label: "Email"    }
    
    let areaCodeInputObject = {
        label: "Código de Área",
        validation_size: 2,
        mask_type: "99",
    }

    let numberInputObject = {
        label: "Número de Telefone",
        validation_size_max: 9,
        validation_size_min: 8,
        mask_type: "999999999",
    }

    let estimatedFinalDateInputObject = {
        label: "Data Estimada de Devolução",
        validation_size: 10
    }

    let postalCodeInputObject = {
        label: "CEP",
        validation_size: 9,
        mask_type: "99999-999",
    }

    const handleChangeName = (event) => {
        setNameValue(event.target.value)
        if (event.target.value.length < 4){
            setNameError(true)
        } else {
            setNameError(false)
        }
    }

    const handleChangeCPF = (event) => {
        setCPFValue(event.target.value)
        if (event.target.value.length !== cpfInputObject.validation_size){
            setCPFError(true)
        }
        else {
            let only_number = event.target.value.replace(/\D/g, "");
            console.log(only_number)
            if (validateCPF(only_number)) setCPFError(false)
            else setCPFError(true)
        }
      }

    const handleChangeEmail = (event) => {
        setEmailValue(event.target.value)
    }

    const handleChangeAreaCode = (event) => {
        setAreaCodeValue(event.target.value)
        if (event.target.value.length !== areaCodeInputObject.validation_size){
            setAreaCodeError(true)
        }
        else {
            setAreaCodeError(false)
        }
    }

    const handleChangeNumber = (event) => {
        setNumberValue(event.target.value)
        if (event.target.value.length > numberInputObject.validation_size_max || 
            event.target.value.length < numberInputObject.validation_size_min){
            setNumberError(true)
        } else {
            setNumberError(false)
        }
    }

    const handleChangeBirthdate = (event) => {
        setBirthdateValue(event.target.value)
        if (event.target.value.length !== birthdateInputObject.validation_size){
            setBirthdateError(true)
        } else {
            setBirthdateError(false)
        }
    }

    const handleChangeEstimatedFinalDate = (event) => {
        setEstimatedFinalDateValue(event.target.value)
        if (event.target.value.length !== estimatedFinalDateInputObject.validation_size){
            setEstimatedFinalDateError(true)
        } else {
            setEstimatedFinalDateError(false)
        }
    }

    const handleChangePostalCode = (event) => {
        setPostalCodeValue(event.target.value)
        if (event.target.value.length !== postalCodeInputObject.validation_size){
            setPostalCodeError(true)
        }
        else {
            setPostalCodeError(false)
        }
    }

    const handleReturn = () => {
        props.onClose()
        props.setPageParams({page: 1, filters: JSON.parse(sessionStorage.getItem(props.local_storage_object_preffix + 'PageParams')).filters})
    }

    const onSubmit = () => {
        if (nameError ||
            birthdateError ||
            estimatedFinalDateError ||
            cpfError || 
            areaCodeError ||
            numberError ||
            postalCodeError ||
            storeValue == null ||
            carGroupValue == null ||
            emailValue.length === 0) return
        let formatedDate = moment(estimatedFinalDateValue, 'YYYY-MM-DD').format()
        let rentalAgreementDate = moment(new Date()).format();
        let payload = {
                        "car_rental_estimated_final_date": formatedDate,
                        "rental_agreement_date": rentalAgreementDate,
                        "reservation": {
                            "id": "0",
                            "channel": "walkin",
                            "reservation_date": rentalAgreementDate,
                            "sales_channel" : "walkin"
                        },
                        "rental_store": storeValue,
                        "car": {
                            "model_group": carGroupValue,
                        },
                        "client":{
                            "name": nameValue,
                            "birthdate": birthdateValue,
                            "type": "natural_person",
                            "document_number": cpfValue,
                            "email": emailValue,
                            "residential_address": {
                                "postal_code": postalCodeValue
                            },
                            "phones": [
                                {
                                    "international_dial_code": "55",
                                    "area_code": areaCodeValue,
                                    "number": numberValue
                                }
                            ]
                        }
                    }
        
        setPendingState(true)
        axios.post('/dash/car_rental/rental_agreement/manual_request', payload).then(response => {
            setPendingState(false)
            history.push("/rental-agreements-inbox")
            setNameValue("")
            setCPFValue("")
            setBirthdateValue("")
            setEmailValue("")
            setAreaCodeValue("")
            setNumberValue("")
            setPostalCodeValue("")
            setEstimatedFinalDateValue("")
            props.onClose()
        }).catch(error => {
            setPendingState(false)
            setStatus({success: false, message: "Não foi possível processar sua requisição"})
        });
      }
    
    if (pendingState){
        return(
            <div style={{display:"flex", flexGrow:"1"}}>
                <CircularProgress style={{margin:"auto"}}/>
            </div>
        )
    }
    if (status != null) {
        return(
            <div style={{ display:"flex", flexDirection:"column", flexGrow:"1"}}>
                <div style={{margin:"5px auto"}}>
                    {getConfirmationIcon(status.success)}
                </div>
                <div style={{margin:"5px auto"}}>
                    {status.message}
                </div>
                <div
                    onClick={handleReturn} 
                    className={["button","standard", "normalText", "normalMediumSize"].join(" ")} 
                    style={{margin:"auto auto 20px auto", width:"auto"}}
                >
                    VOLTAR À PÁGINA INICIAL
                </div>
            </div>
        )
    }

    return (
        <div style={{ display:"flex", flexDirection:"column", flexGrow:"1"}}>
            <div style={{margin:"10px", flexDirection:"column", display:"flex", flexGrow:"1"}}>
                <ThemeProvider theme={theme}>
                    <div style={{margin:"5px auto", width:"90%"}}>
                            <TextField 
                                autoFocus 
                                id="standard-basic" 
                                label={nameInputObject.label}
                                value={nameValue}
                                sx={{ width: 300 }}
                                onChange={handleChangeName}
                                variant="outlined"
                                size="small"
                                fullWidth
                                InputProps={{ sx: { height: 80 }, style: {fontSize: 14} }}
                                InputLabelProps={{style: {fontSize: 14}}}
                            />
                        </div>
                    <div style={{margin:"5px auto", width:"90%"}}>
                        <InputMask 
                            mask={cpfInputObject.mask_type}
                            maskPlaceholder=""
                            value={cpfValue}
                            onChange={handleChangeCPF}
                        >
                            <TextField 
                                autoFocus 
                                id="standard-basic" 
                                color={cpfError? "secondary": "primary"} 
                                label={cpfInputObject.label}
                                variant="outlined"
                                size="small"
                                fullWidth
                                InputProps={{ sx: { height: 80 }, style: {fontSize: 14} }}
                                InputLabelProps={{style: {fontSize: 14}}}
                            />
                        </InputMask>
                    </div>
                    <div style={{margin:"5px auto", width:"90%"}}>
                        <InputMask 
                            mask={postalCodeInputObject.mask_type}
                            maskPlaceholder=""
                            value={postalCodeValue}
                            onChange={handleChangePostalCode}
                        >
                            <TextField 
                                autoFocus 
                                id="standard-basic" 
                                color={postalCodeError? "secondary": "primary"} 
                                label={postalCodeInputObject.label}
                                variant="outlined"
                                size="small"
                                fullWidth
                                InputProps={{ sx: { height: 80 }, style: {fontSize: 14} }}
                                InputLabelProps={{style: {fontSize: 14}}}
                            />
                        </InputMask>
                    </div>
                    <div style={{margin:"5px auto", width:"90%"}}>
                        <TextField 
                            autoFocus 
                            id="standard-basic" 
                            label={emailInputObject.label}
                            value={emailValue}
                            onChange={handleChangeEmail}
                            type="email"
                            variant="outlined"
                            size="small"
                            fullWidth
                            InputProps={{ sx: { height: 80 }, style: {fontSize: 14} }}
                            InputLabelProps={{style: {fontSize: 14}}}
                        />
                    </div>
                    <div style={{margin:"5px auto", width:"90%"}}>
                        <InputMask 
                            mask={areaCodeInputObject.mask_type}
                            maskPlaceholder=""
                            value={areaCodeValue}
                            onChange={handleChangeAreaCode}
                        >
                            <TextField 
                                autoFocus 
                                id="standard-basic" 
                                color={areaCodeError? "secondary": "primary"} 
                                label={areaCodeInputObject.label}
                                variant="outlined"
                                size="small"
                                fullWidth
                                InputProps={{ sx: { height: 80 }, style: {fontSize: 14} }}
                                InputLabelProps={{style: {fontSize: 14}}}
                            />
                        </InputMask>
                    </div>
                    <div style={{margin:"5px auto", width:"90%"}}>
                        <InputMask 
                            mask={numberInputObject.mask_type}
                            maskPlaceholder=""
                            value={numberValue}
                            onChange={handleChangeNumber}
                        >
                            <TextField 
                                autoFocus 
                                id="standard-basic" 
                                color={numberError? "secondary": "primary"} 
                                label={numberInputObject.label}
                                variant="outlined"
                                size="small"
                                fullWidth
                                InputProps={{ sx: { height: 80 }, style: {fontSize: 14} }}
                                InputLabelProps={{style: {fontSize: 14}}}
                            />
                        </InputMask>
                    </div>
                    <div style={{margin:"5px auto", width:"90%"}}>{birthdateInputObject.label}</div>
                    <div style={{margin:"0px auto", width:"90%"}}>
                        <TextField 
                            autoFocus 
                            id="standard-basic" 
                            color={birthdateError? "secondary": "primary"}
                            value={birthdateValue}
                            onChange={handleChangeBirthdate}
                            type="date"
                            variant="outlined"
                            size="small"
                            fullWidth
                            InputProps={{ sx: { height: 80 }, style: {fontSize: 14} }}
                            InputLabelProps={{ shrink: true, }}
                        />
                    </div>
                    <div style={{margin:"5px auto", width:"90%"}}>{estimatedFinalDateInputObject.label}</div>
                    <div style={{margin:"0px auto", width:"90%"}}>
                        <TextField 
                            autoFocus 
                            id="standard-basic" 
                            color={estimatedFinalDateError? "secondary": "primary"}
                            value={estimatedFinalDateValue}
                            onChange={handleChangeEstimatedFinalDate}
                            type="date"
                            variant="outlined"
                            size="small"
                            fullWidth
                            InputProps={{ sx: { height: 80 }, style: {fontSize: 14} }}
                            InputLabelProps={{ shrink: true, }}
                        />
                    </div>
                    {store_account ? null :
                    <div style={{margin:"10px auto", width:"90%"}}>
                        <Autocomplete
                            id="combo-box-demo"
                            value={storeValue}
                            onChange={(event, newValue) => {
                                setStoreValue(newValue);
                            }}
                            options={local_stores}
                            getOptionLabel={(option) => option}
                            renderInput={(params) => 
                                <TextField 
                                    {...params} 
                                    autoFocus 
                                    label="Selecione uma loja" 
                                    color="primary" 
                                    variant="outlined" 
                                    size="small"
                                    fullWidth
                                    InputProps={{ sx: { height: 80 }, style: {fontSize: 14} }}
                                    InputLabelProps={{style: {fontSize: 14}}}
                                />}
                        />
                    </div>}
                    <div style={{margin:"10px auto", width:"90%"}}>
                        <Autocomplete
                            id="combo-box-demo"
                            value={carGroupValue}
                            onChange={(event, newValue) => {
                                setCarGroupValue(newValue);
                            }}
                            options={car_group_keys}
                            getOptionLabel={(option) => option}
                            style={{ width: 300 }}
                            renderInput={(params) => 
                                <TextField 
                                    {...params} 
                                    autoFocus 
                                    label="Selecione um grupo de veículo" 
                                    color="primary" 
                                    variant="outlined" 
                                    size="small"
                                    fullWidth
                                    InputLabelProps={{style: {fontSize: 14}}}
                                />}
                        />
                </div>
                </ThemeProvider>
            </div>
            <div style={{display:"flex", flexDirection:"colunm", justifyContent: "center", margin:"auto 0px 20px 0px"}}>
                <div
                    className={
                            nameError ||
                            birthdateError ||
                            cpfError ||
                            areaCodeError ||
                            numberError ||
                            postalCodeError ||
                            storeValue == null ||
                            carGroupValue == null ||
                            emailValue.length === 0 ? 
                            ["button","standard", "disabled", "normalText", "normalMediumSize"].join(" ") : ["button","standard", "normalText", "normalMediumSize"].join(" ")} 
                    onClick={onSubmit} 
                    style={{width:"40%"}}
                >
                    Finalizar
                </div>
            </div>
        </div>
    )

}

export default InputContent
