import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import ChargebackDialog from "./ChargebackDialog";

function FraudDialog(props) {
    let {transaction, open, onClose} = props    

    const [chargebackType, setChargebackType] = useState("")

    const [openChargebackDialog, setOpenChargebackDialog] = useState(false)
    const handleChargebackType = (event) => {
        setOpenChargebackDialog(true)
        setChargebackType(event.target.id)
    };
    const handleChargebackDialogClose = () => {
        setOpenChargebackDialog(false)
    };

    return (
        <Dialog 
        open={open} 
        onClose={onClose}
        keepMounted={false}
        >
            <DialogTitle style={{paddingBottom: "0px", marginLeft:"10px"}} className={["blueText", "normalMediumSize"].join(" ")}>Selecione o status da transação</DialogTitle>
            <div style={{display:"flex", justifyContent: "space-between", margin:"20px"}}>
                <div
                    id="chargeback"
                    className={["button", "standard", "normalText", "normalMediumSize"].join(" ")                            }
                    style={{display: "flex", justifyContent:"center", alignItems: "center", width: "150px", fontWeight:"600"}}
                    onClick={handleChargebackType}
                >
                    Estorno Completo
                </div>
                <div
                    id="partial_chargeback"
                    className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                    style={{display: "flex", justifyContent:"center", alignItems: "center", width: "150px", fontWeight:"600"}}
                    onClick={handleChargebackType}
                >
                    Estorno Parcial
                </div>
            </div>
            <ChargebackDialog
                    open={openChargebackDialog}
                    onClose={handleChargebackDialogClose}
                    transaction={transaction}
                    chargeback_type={chargebackType}
                />
        </Dialog>
    )
}

export default FraudDialog;