import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from 'moment'
import {getTableRowClass, checkNested,formatCard, titleCase} from "../../../utils/utils"
import {getTransactionStatusIcon, getTransactionStatusText, getPaymentType, getTransactionStatusColor} from "../../../utils/utilsOrder"
import Tooltip from "@material-ui/core/Tooltip";
import getSymbolFromCurrency from 'currency-symbol-map'
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";



const useStyles = makeStyles((theme) => ({
    tableStyle: {
      border: "none",
      boxShadow: "none",
      width:"100%"
    },
    blueText: {
      fontFamily: "Open Sans",
      fontWeight: "600",
      color: "#0b1f82"
  },
    tableText: {
      fontFamily: "Open Sans",
      fontSize: "14px",
      lineHeight: "19px",
      textAlign: "center",
      margin: "10px",
      padding: "6px",
    },
    smallText: {
        fontFamily: "Open Sans",
        fontSize: "12px",
        lineHeight: "16px",
        textAlign: "center",
        margin: "10px",
        padding: "6px",
      },
    link:{
        cursor: "pointer",
        "&:hover": {
            textDecoration: "underline",
          },
    },
    tableHeader: {
      textAlign: "center",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: "normal",
      color: '#6F6F6F',
      fontSize: "16px",
      lineHeight: "22px",
      padding: "6px",
    }
}));

function TransactionsDialog (props) {
    const classes = useStyles();
    const currency = props.payment.currency

    useTheme();

    return (
        <Dialog
            open={props.open} 
            onClose={props.onClose} 
            keepMounted={false}
            maxWidth={"md"}
        >
          <DialogTitle className={["blueText", "subtitleSize"].join(" ")}>Transações</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Paper className={classes.tableStyle} style={{marginBottom:"15px"}}>
                <Table style={{ padding: "8px" }}>
                    <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableHeader}>ID</TableCell>
                        <TableCell className={classes.tableHeader}>Nome do Portador</TableCell>
                        <TableCell className={classes.tableHeader}>Cartão</TableCell>
                        <TableCell className={classes.tableHeader}>Vencimento do Cartão</TableCell>
                        <TableCell className={classes.tableHeader}>Valor</TableCell>
                        <TableCell className={classes.tableHeader}>Parcelas</TableCell>
                        <TableCell className={classes.tableHeader}>Modalidade</TableCell>
                        <TableCell className={classes.tableHeader}>Status</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {props.payment.transactions.map((transaction,index) => (
                        <TableRow
                        key={index}
                        className={getTableRowClass(index)}
                        > 
                        <TableCell className={[classes.tableText, "breakWord"].join(" ")}>
                            {transaction.id}
                        </TableCell>
                        <TableCell className={classes.tableText}>
                            {transaction.cardholder_name == null ? "-" : titleCase(transaction.cardholder_name, "-")}    
                        </TableCell>
                        <TableCell className={[classes.tableText, "breakWord"].join(" ")}> 
                            {checkNested(transaction, "bin") & checkNested(transaction, "last_4") ? 
                            formatCard(transaction.bin, transaction.last_4) : "-"}         
                        </TableCell>
                        <TableCell className={classes.tableText}>
                            {moment(transaction.expiration_date).format("YYYY/MM")}
                        </TableCell>
                        <TableCell className={classes.tableText}>
                            {transaction.amount == null ? "-" : getSymbolFromCurrency(currency) + " " + (transaction.amount/100).toLocaleString('de-DE', {minimumFractionDigits: 2})}
                        </TableCell>
                        <TableCell className={[classes.tableText, "breakWord"].join(" ")}>
                            {checkNested(transaction, "installments") ? transaction.installments : "1"}
                        </TableCell>
                        <TableCell className={classes.tableText}>
                            {checkNested(transaction, "payment_type") ? getPaymentType(transaction.payment_type) : "-"}
                        </TableCell>
                        <TableCell className={classes.tableText}>
                            <Tooltip title={<p className="tooltipText">{getTransactionStatusText(transaction.status)}</p>}>
                            <div className={["circle", "small"].join(" ")} style={{backgroundColor: getTransactionStatusColor(transaction.status)}}>
                                {getTransactionStatusIcon(transaction.status, "small", true)}
                            </div>
                            </Tooltip>
                        </TableCell>    
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                </Paper>
          </DialogContent>
        </Dialog>
      );
}

export default TransactionsDialog