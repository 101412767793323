import React from "react";
import FilterBox from "../../../Utils/FilterBox/FilterBox"

function Filter(props) {
	
    const { setPageParams } = props

    let filterTypes = {
        document_number: {
            type: "brazilian_document_numbers",
            disabled: false,
            validation:true,
            validation_rule: {type: "multiple_equal", values:[14,18]},
            description: "CPF/CNPJ"
        },
        bill_payment_date: {
            type: "date",
            disabled: false,
            validation:false,
            validation_rule: null,
            description: "Data"
        },
        external_id: {
            type: "string",
            disabled: false,
            validation:false,
            validation_rule: null,
            description: "Número do Pagamento de Contas"
          },
        bill_payment_status: {
            type: "enum",
            enumList: [
                ["Nenhum", ""],
                ["Esperando Processamento", "created"],
                ["Processada", "completed"]
            ],
            disabled: false,
            validation:false,
            validation_rule: null,
            description: "Status do Pagamento de Contas"
          },
        analysis_status: {
            type: "enum",
            enumList: [
                ["Nenhum", ""],
                ["Pendente", "pending"],
                ["Aprovado Manualmente", "manually_approved"],
                ["Reprovado Manualmente", "manually_reproved"],
                ["Aprovado Automaticamente", "automatically_approved"],
                ["Reprovado Automaticamente", "automatically_reproved"],
                ["Aprovado por tempo", "approved_by_time"]
            ],
            disabled: false,
            validation:false,
            description: "Status da Análise"
        },
        fraud_status: {
            type: "enum",
            enumList: [
                ["Reportada como fraude", "fraud"],
                ["Não Reportada como fraude", "created"],                
            ],
            disabled: false,
            validation:false,
            description: "Status de Fraude"
        }
    }

    return (
        <FilterBox 
            setPageParams={setPageParams}
            filterTypes={filterTypes}
            local_storage_object_preffix={props.local_storage_object_preffix}
        />
    )

}

export default Filter