import React from "react";
import { useTranslation } from "react-i18next";
import "../../../../../assets/styles/generalStyles.scss";

const FormTitle = props => {

  const { t } = useTranslation();

  return (
  <div>
    <p className={["formTitleText", "bold", "blueTextLabel"].join(" ")}>
        {t(props.value)}
    </p>
    <hr className={"formBlueLine"}></hr>
    <div className={"formSmallWhiteSpace"}></div>
  </div>
)}

export default FormTitle;

