import React from 'react'
import transactionIcon from '../../../../assets/cardEntryIcons/TransactionArrow.svg';
import goodPinIcon from '../../../../assets/cardEntryIcons/goodpin.svg';
import badPinIcon from '../../../../assets/cardEntryIcons/badpin.svg';
import neutralPinIcon from '../../../../assets/cardEntryIcons/neutralpin.svg';
import {getEntryText, getTransactionType, getBoolIcon,getPinText} from "../../utils/utils"
import Tooltip from "@material-ui/core/Tooltip";
import getSymbolFromCurrency from 'currency-symbol-map'

function TransactionCard(props) {

    return (
        <div className="analysisCard">
            <div style={{display: "flex"}}>
                <img className="cardTitleIconStyle" src={transactionIcon} alt="transactionIcon Logo" />
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>Transação</span>
                <Tooltip title={<p className="tooltipText">{getPinText(props.transaction.pin_sent)}</p>}>
                    <div style={{display: "flex", marginLeft: "auto"}}>
                        {props.transaction.pin_sent == null ?
                        <img className="pinIconStyle" src={neutralPinIcon} alt="pinIcon Logo" /> :
                        props.transaction.pin_sent ?
                        <img className="pinIconStyle" src={goodPinIcon} alt="pinIcon Logo" /> :
                        <img className="pinIconStyle" src={badPinIcon} alt="pinIcon Logo" />}
                        {getBoolIcon(props.transaction.pin_sent)}  
                    </div>
                </Tooltip>         
            </div>
            <div className="internalCardContainer">
                <div className={["labelText", "normalMediumSize", "bold"].join(" ")}>
                    Valor da Transação
                </div>
                <div className={["normalText", "moneySize", "bold"].join(" ")}>
                    {props.transaction.amount == null ? "-" : getSymbolFromCurrency(props.transaction.currency) + " " + (props.transaction.amount/100).toLocaleString('de-DE', {minimumFractionDigits: 2})}
                </div>
                <div className={["labelText", "normalMediumSize", "bold"].join(" ")}>
                    {props.transaction.currency}
                </div>
            </div>
            <div className="internalCardContainer">
                <div style={{display: "flex", margin: "5px 0px"}}>
                    <div className={["normalText", "normalMediumSize", "noWrap"].join(" ")}>
                        Entry Mode:
                    </div>
                    <div className={["labelText", "normalMediumSize", "labelSpace"].join(" ")}>
                        {getEntryText(props.transaction.pan_entry_mode)}
                    </div>
                </div>
                <div style={{display: "flex", margin: "5px 0px"}}>
                    <div className={["normalText", "normalMediumSize", "noWrap"].join(" ")}>
                        Modalidade:
                    </div>
                    <div className={["labelText", "normalMediumSize", "labelSpace"].join(" ")}>
                        {getTransactionType(props.transaction.transaction_type)}
                    </div>
                </div>
                <div style={{display: "flex", margin: "5px 0px"}}>
                    <div className={["normalText", "normalMediumSize", "noWrap"].join(" ")}>
                        Parcelas:
                    </div>
                    <div className={["labelText", "normalMediumSize", "labelSpace"].join(" ")}>
                        {props.transaction.installments}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TransactionCard