import React, { useEffect } from "react";
import { CircularProgress } from '@material-ui/core';
import axios from "axios";
import AlertsTable from "./AlertsTable/AlertsTable"
import Pagination from "@material-ui/lab/Pagination";

function AlertsHistoric(props) {

    const {link_key} = props
  
    const [userHistoric, setUserHistoric] = React.useState(null)
    const [tablePage, setTablePage] = React.useState(1)
  
    useEffect(() => {
        if (userHistoric === null){
    
            let params = {
				"page_number": tablePage -1,
                "page_rows": 4,
                "merchant_id": link_key[0],
                "acquirer_id": link_key[1]
            }
            const timer = setTimeout(() => {
                axios.get('/dash/card_issuance_backoffice/alerts', {
					params: params
				}).then(response=>{
                    setUserHistoric(response.data)
                }).catch(error=>{
                    setUserHistoric(undefined)
                })

            }, 500);
            return () => {
              clearTimeout(timer)
            }
        }
    }, [userHistoric,tablePage,link_key])

    const handleChangePage = (event, page) => {
		if (tablePage === page) {
			return
		}
        setTablePage(page)
        setUserHistoric(null)
	}
  
    if (userHistoric === null) {
      return (
        <div style={{display: "flex", height: "160px"}}>
            <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                <CircularProgress />
            </div>
        </div>
      );    
    }
    else if (userHistoric === undefined || userHistoric.data.length === 0){
      return (
        <div style={{display: "flex", height: "160px"}}>
            <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                Informação Indisponível
            </div>
        </div>
      )
    }
    else {
      return (
          <div>
              <div>
                  <AlertsTable historic={userHistoric.data} />
              </div>
              <div style={{display:"flex", marginTop: "10px"}}>
                <Pagination style={{marginLeft:"auto"}} className='pagination' page={tablePage} count={userHistoric['number_of_pages']} onChange={(e,page) => handleChangePage(e, page)} />
              </div>
          </div>
      );
    }
  }
  
  export default AlertsHistoric;