import React, {useEffect, useState} from "react";
import axios from "axios";
import { CircularProgress } from '@material-ui/core';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css'
import InnerImageZoom from 'react-inner-image-zoom';
import PDFContent from "./Components/PDFContent";

function ValidatedImage(props) {

    let imageStyle  = {margin:"auto", borderRadius: "5px"}
    let imageAlt    = ""
    
    if (props.imageStyle){
        imageStyle={...imageStyle, ...props.imageStyle}
    }

    if (props.imageAlt){
        imageAlt = props.imageAlt
    }
    

    const { image_key, dash_api_endpoint_url, fileType } = props

    const [image, setImage] = useState(null)
    const [arrayBuffer, setArrayBuffer] = useState(null)


    useEffect(() => {
        const timer = setTimeout(() => {
            if (image_key && dash_api_endpoint_url){
                setImage(null)
                let params = {}
                if (fileType) {
                    params["file_type"] = fileType
                }
                axios.get(dash_api_endpoint_url + "/" + image_key, {responseType:"arraybuffer",params:params}).then( response => {
                    const arrayBuffer = response.data;
                    const image = Buffer.from(arrayBuffer, 'binary').toString('base64')
                    if (image == null) throw new Error()
                    setArrayBuffer(arrayBuffer);
                    setImage(image);
                }).catch(error => {
                    setImage(undefined)
                })
            }
            else {
                setImage(undefined)
            }
        }, 500);
        return () => {
            clearTimeout(timer)
        }
    }, [image_key, dash_api_endpoint_url, fileType])

    if (image === null || fileType === null || (fileType === "pdf" && arrayBuffer=== null)){
        return (          
            <div 
                className={["labelText", "normalMediumSize"].join(" ")} 
                style={{textAlign:"center", margin:"auto"}}
            >
                <CircularProgress style={{margin:"auto"}} />
            </div>
        ) 
    } else if (fileType === "pdf") {
        return (
            <PDFContent
                arrayBuffer={arrayBuffer}
                imageStyle={imageStyle}
                imageAlt={imageAlt}
            />
        )
    }
    else {
        if (props.zoom_enabled) {
            return(<InnerImageZoom
                style   = {imageStyle}
                src     = {`data:image/jpg;base64,${image}`}
                alt     = {imageAlt}
                zoomScale = {1.5}
            />);
        }
        else {return (
            <img
                style   = {imageStyle}
                src     = {`data:image/jpg;base64,${image}`}
                alt     = {imageAlt}
            />
        );
        }
    }
}

export default ValidatedImage;