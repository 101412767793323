import React, { useEffect, useContext, useReducer, useState } from "react";
import { dataFetchReducer } from "../../Utils/requestUtils";
import { useParams } from "react-router-dom";
import axios from "axios";
import CustomerHistoricCard from "./CustomerHistoricCard/CustomerHistoricCard"
import SellerHistoricCard from "./SellerHistoricCard/SellerHistoricCard"
import CustomerCard from "./CustomerCard/CustomerCard"
import SellerCard from "./SellerCard/SellerCard"
import ScoreBox from "./ScoreBox/ScoreBox"
import TimeCounter from "./TimeCounter/TimeCounter"
import AlertCard from "./AlertCard/AlertCard"
import Summary from "./Summary/Summary"
import ObservationCard from "./ObservationCard/ObservationCard"
import PaymentCard from "./PaymentCard/PaymentCard"
import ProductsCard from "./ProductsCard/ProductsCard"
import ReportFraudDialog from "./Dialogs/ReportFraudDialog"
import ErrorBoundary from "../../Utils/ErrorBoundary";
import AuthContext from "../../../context/auth-context"
import {useHistory} from "react-router-dom"
import moment from 'moment'


function Order(props){
    let history = useHistory()

    let roles = useContext(AuthContext).user_data.roles
    if (!roles.includes("read_card_order_orders")){
        history.push("")
    }

    let { order_key } = useParams();

    const [order, dispatchOrder] = useReducer(
        dataFetchReducer,
        {data: null, isLoading: true, isError: false} 
    )

    useEffect(() => {
		dispatchOrder({type: "data_fetch_init"})
		const timer = setTimeout(() => {
			axios.get('/dash/card_order/order/' + order_key).then( response => {
                dispatchOrder({
                    type: "data_fetch_success",
                    payload: response.data
                }) 
			}).catch(error => {
                if ((error.response || {}).status === 403) dispatchOrder({type: "data_fetch_failure_403"})
                else if ((error.response || {}).status === 404) dispatchOrder({type: "data_fetch_failure_404"})  
                else dispatchOrder({type: "data_fetch_failure"})    
			})	
		}, 500);
		return () => {
			clearTimeout(timer)
        }
    }, [order_key])

    const [reportFraudDialog, setReportFraudDialog] = useState(false)

    const handleReportFraudDialogOpen = () => {
        setReportFraudDialog(true)
    }
    const handleReportFraudDialogClose = () => {
        setReportFraudDialog(false)
    }

    if (order.fetchedData){
        return (
            <div style={{minWidth:"900px", paddingBottom: "30px"}}>
                <div className="analysisCardContainer">
                    <ErrorBoundary>
                        <div>
                            <div className={["blueText", "titleSize"].join(" ")} style={{margin:"5px 0px"}}>
                                {"Pedido " + order.fetchedData.id}
                            </div>
                            <div className={["labelText", "subtitleSize"].join(" ")} style={{margin:"5px 0px"}}>
                                {moment(order.fetchedData.order_date).format("DD/MM/YYYY - HH:mm:ss")}
                            </div>
                        </div>
                        {order.fetchedData.manual_analysis_limit_date != null &&
                        (order.fetchedData.analysis_status === "pending" || order.fetchedData.analysis_status === "in_manual_analysis") ?
                        <div className="analysisCardContainer" style={{flexDirection:"column-reverse", flexGrow:"1"}}>
                            <ErrorBoundary>
                                <div className={["labelText", "subtitleSize"].join(" ")} style={{display:"inline", marginBottom:"auto", marginTop:"auto", textAlign:"right"}}>
                                    <TimeCounter order={order.fetchedData}/>
                                </div>
                            </ErrorBoundary>
                        </div> : null}
                        {order.fetchedData.card_order_status !== "fraud" ?
                        <div style={{margin: "auto 50px auto auto"}}>
                            <div 
                                className={["button", "standard", "normalText", "normalMediumSize"].join(" ")} 
                                style={{textAlign:"center", margin:"auto", width:"150px"}}
                                onClick={handleReportFraudDialogOpen}
                            >
                                Marcar como Fraude
                            </div>
                        </div>
                        :null}
                    </ErrorBoundary>
                </div>
                <div style={{display: "flex"}}>
                    <div className="analysisCardContainer" style={{ width: "30%"}}>
                            <ErrorBoundary>
                                <CustomerCard order={order.fetchedData}/>
                            </ErrorBoundary> 
                    </div>
                    <div style={{width: "40%"}}>
                        <div className="analysisCardContainer">
                            <ErrorBoundary>
                                <ProductsCard order={order.fetchedData}/>
                            </ErrorBoundary>      
                        </div>
                    </div>
                    <div style={{width: "30%"}}>
                        {((((order.fetchedData.analysis_events || [])[0] || {}).decision_metadata || {}).model || {}).score != null ?
                        <div className="analysisCardContainer" style={{display:"flex", flexGrow:"1"}}>
                            <ErrorBoundary>
                                <ScoreBox analysis_events={order.fetchedData.analysis_events}/>
                            </ErrorBoundary>   
                        </div>: null}
                        <div className="analysisCardContainer" style={{display:"flex", flexGrow:"1"}}>
                            <ErrorBoundary>
                                <Summary order={order.fetchedData}/>
                            </ErrorBoundary>   
                        </div>
                        <div className="analysisCardContainer" style={{display:"flex", flexGrow:"1"}}>
                            <ErrorBoundary>
                                <AlertCard analysis_events={order.fetchedData.analysis_events}/>
                            </ErrorBoundary>   
                        </div>
                    </div>
                </div>
                <div style={{display: "flex"}}>
                    <div style={{display:"flex", flexGrow: "1"}}>
                        <div className="analysisCardContainer" style={{ minHeight: "250px", flexGrow: "1"}}>
                            <ErrorBoundary>
                                <PaymentCard order={order.fetchedData}/>
                            </ErrorBoundary>                            
                        </div>
                    </div>
                    <div style={{width: "60%"}}>
                        <div className="analysisCardContainer" style={{ minHeight: "250px", display: "flex", flexGrow: "1"}}>
                            <ErrorBoundary>
                                <SellerCard order={order.fetchedData}/>
                            </ErrorBoundary> 
                        </div>
                    </div>
                </div>
                <div style={{display:"flex"}}>
                    <div className="analysisCardContainer" style={{ minHeight: "250px", display: "flex", flexGrow: 1}}>
                        <ErrorBoundary>
                            <CustomerHistoricCard order={order.fetchedData}/>
                        </ErrorBoundary> 
                    </div>
                </div>
                <div style={{display:"flex"}}>
                    <div className="analysisCardContainer" style={{ minHeight: "250px", display: "flex", flexGrow: 1}}>
                        <ErrorBoundary>
                            <SellerHistoricCard order={order.fetchedData}/>
                        </ErrorBoundary> 
                    </div>
                </div>
                {(order.fetchedData.analysis_status === "pending" || order.fetchedData.analysis_status === "in_manual_analysis" ||
                 order.fetchedData.analysis_status === "manually_approved" || order.fetchedData.analysis_status === "manually_reproved") ?
                <div style={{display:"flex"}}>
                    <div className="analysisCardContainer" style={{ minHeight: "170px", display: "flex", flexGrow: 1}}>
                        <ErrorBoundary>
                            <ObservationCard order={order.fetchedData}/>
                        </ErrorBoundary> 
                    </div>
                </div> : null}
                <ReportFraudDialog
                    order_key={order.fetchedData.order_key}
                    open={reportFraudDialog}
				    onClose={handleReportFraudDialogClose}
                />

            </div>
        )
    }
	else if(order.isError){
		return (
            <div style={{height: "80vh", display:"flex"}}>
                <p className={["blueText", "titleSize"].join(" ")} style={{margin:"auto", textAlign:"center"}}>
                    {order.errorMessage}
                </p>
            </div>
		)
	}
  
  return null;
}

export default Order









