import React, { useContext } from "react";
import AuthContext from "../../../../../context/auth-context";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import {
  getTableRowClass,
  getAnalysisStatusColor,
  getAnalysisStatusSiglum,
  getAnalysisStatus,
} from "../../../utils/utils";
import { Link } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
  },
  blueText: {
    fontFamily: "Open Sans",
    fontWeight: "600",
    color: "#0b1f82",
  },
  tableText: {
    fontFamily: "Open Sans",
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "center",
    margin: "10px",
    padding: "6px",
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: "#6F6F6F",
    fontSize: "14px",
    lineHeight: "19px",
    padding: "6px",
  },
}));

function HistoryTable(props) {
  const classes = useStyles();
  useTheme();

  const { history } = props
  let user_data = useContext(AuthContext).user_data

  if (history.length===1) {
    return (
        <div className="alignCenter">Análise não possui histórico</div>
    )
  } else {
      return (
        <Paper className={classes.tableStyle}>
          <Table style={{ padding: "8px" }}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeader}>ID</TableCell>
                {user_data.business_group_key&&
                <TableCell className={classes.tableHeader}>Companhia</TableCell>}
                <TableCell className={classes.tableHeader}>Nome</TableCell>
                <TableCell className={classes.tableHeader}>CPF</TableCell>
                <TableCell className={classes.tableHeader}>
                  Status da Análise
                </TableCell>
                <TableCell className={classes.tableHeader}>Data</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history.map((naturalPerson, index) => props.current_natural_person_key !== naturalPerson.natural_person_key ? (
                <TableRow key={index} className={getTableRowClass(index)}>
                  <TableCell className={[classes.tableText,classes.blueText,"breakWord","link"].join(" ")}>
                    {!["pending","in_queue","waiting_for_data","failed"].includes(naturalPerson.analysis_status)?
                    <Link
                      className="link"
                      to={"/natural-person/" + naturalPerson.natural_person_key}
                    >
                      {naturalPerson.partnership_key?"Análise de Sócio":naturalPerson.analyst_request?"Requisição Manual":"Cliente #".concat(naturalPerson.id)}
                    </Link>
                    :
                    <TableCell className={classes.tableText}>
                      {naturalPerson.partnership_key?"Análise de Sócio":naturalPerson.analyst_request?"Requisição Manual":"Cliente #".concat(naturalPerson.id)}
                    </TableCell>}
                  </TableCell>
                  {user_data.business_group_key&&
                  <TableCell className={classes.tableText}>
                    {naturalPerson.company_name}
                  </TableCell>}
                  <TableCell className={classes.tableText}>
                    {naturalPerson.name?naturalPerson.name:"-"}
                  </TableCell>
                  <TableCell className={[classes.tableText, "breakWord"].join(" ")}>
                    {naturalPerson.document_number}
                  </TableCell>
                  <TableCell className={classes.tableText}>
                    <Tooltip
                      title={
                        <p className="tooltipText">
                          {getAnalysisStatus(naturalPerson.analysis_status)}
                        </p>
                      }
                    >
                      <div
                        className={["circle", "small"].join(" ")}
                        style={{
                          backgroundColor: getAnalysisStatusColor(
                            naturalPerson.analysis_status
                          ),
                        }}
                      >
                        {getAnalysisStatusSiglum(naturalPerson.analysis_status)}
                      </div>
                    </Tooltip>
                  </TableCell>
                  <TableCell className={classes.tableText}>
                    {moment(naturalPerson.created_at).format(
                      "DD/MM/YYYY HH:mm:ss"
                    )}
                  </TableCell>
                </TableRow>
              ) : null)}
            </TableBody>
          </Table>
        </Paper>
      );
  }
}

export default HistoryTable;
