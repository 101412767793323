import React, { useEffect, useContext, useReducer } from "react";
import { dataFetchReducer } from "../../Utils/requestUtils";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment"
import ErrorBoundary from "../../Utils/ErrorBoundary";
import AuthContext from "../../../context/auth-context"
import {useHistory} from "react-router-dom"
import DepositCard from "./DepositCard/DepositCard"
import DecisionBox from "./DecisionBox/DecisionBox"
import AlertCard from "./AlertCard/AlertCard"
import ClientCard from "./ClientCard/ClientCard"
import TerminalCard from "./TerminalCard/TerminalCard"
import HistoricCard from "./HistoricCard/HistoricCard"
import NextTransaction from "../../Utils/NextTransaction/NextTransaction";

function Deposit(props){
    let history = useHistory()

    let roles = useContext(AuthContext).user_data.roles
    if (!roles.includes("read_deposits")){
        history.push("")
    }

    let { deposit_key } = useParams();

    const [deposit, dispatchDeposit] = useReducer(
        dataFetchReducer,
        {fetchedData: null, isLoading: true, isError: false} 
    )

    useEffect(() => {
		dispatchDeposit({type: "data_fetch_init"})
		const timer = setTimeout(() => {
			axios.get('/dash/deposit/deposit/' + deposit_key).then( response => {
                dispatchDeposit({
                    type: "data_fetch_success",
                    payload: response.data
                })   
			}).catch(error => {
                if ((error.response || {}).status === 403) dispatchDeposit({type: "data_fetch_failure_403"})
                else if ((error.response || {}).status === 404) dispatchDeposit({type: "data_fetch_failure_404"})  
                else dispatchDeposit({type: "data_fetch_failure"})    
			})	
		}, 500);
		return () => {
			clearTimeout(timer)
        }
    }, [deposit_key])

    if (deposit.fetchedData){
        return (
            <div style={{minWidth:"900px", paddingBottom: "30px"}}>
                <div className="analysisCardContainer">
                    <ErrorBoundary>
                        <div style={{display:"flex", flexDirection:"column"}}>
                            <div className={["blueText", "titleSize"].join(" ")} style={{margin:"5px 0px"}}>
                                Depósito {deposit.fetchedData.id}
                            </div>
                            <div className={["labelText", "subtitleSize"].join(" ")} style={{margin:"5px 0px"}}>
                                {moment(deposit.fetchedData.deposit_date).format("DD/MM/YYYY - HH:mm:ss")}
                            </div>
                        </div>
                    </ErrorBoundary>
                </div>
                <NextTransaction history={history} transaction_key={deposit_key} path={"/deposit"} suffix={"deposit"} page_name={"Depósitos"}/> 
                <div style={{display: "flex", margin: "10px 0px"}}>
                    <div style={{width: "33%"}}>
                        <div className="analysisCardContainer">
                            <ErrorBoundary>
                                <DepositCard deposit={deposit.fetchedData}/>
                            </ErrorBoundary>
                        </div>
                        <div className="analysisCardContainer">
                            <ErrorBoundary>
                                <ClientCard deposit={deposit.fetchedData}/>
                            </ErrorBoundary> 
                        </div>
                    </div>
                    <div style={{width: "66%"}}>
                    <div style={{display:"flex"}}>
                            <div style={{width: "50%"}}>
                                <div className="analysisCardContainer">
                                    <ErrorBoundary>
                                        <DecisionBox 
                                            analysis_events={deposit.fetchedData.analysis_events}
                                            analysis_status={deposit.fetchedData.analysis_status}
                                        />
                                    </ErrorBoundary>
                                </div>
                            </div>
                            <div style={{width: "50%"}}>
                                <div className="analysisCardContainer" style={{minHeight:"140px"}}>
                                    <ErrorBoundary>
                                        <AlertCard analysis_events={deposit.fetchedData.analysis_events}/>
                                    </ErrorBoundary>   
                                </div>
                            </div>
                        </div>
                        <div className="analysisCardContainer" style={{ minHeight: "250px"}}>
                            <ErrorBoundary>
                                <TerminalCard 
                                    deposit={deposit.fetchedData} 
                                />
                            </ErrorBoundary>
                        </div>
                    </div>
                </div>
                <div className="analysisCardContainer">
                    <ErrorBoundary>
                        <HistoricCard deposit={deposit.fetchedData}/>
                    </ErrorBoundary>			
                </div>
            </div>
        )
    }
	else if(deposit.isError){
		return (
            <div style={{height: "80vh", display:"flex"}}>
                <p className={["blueText", "titleSize"].join(" ")} style={{margin:"auto", textAlign:"center"}}>
                    {deposit.errorMessage}
                </p>
            </div>
		)
	}
  
  return null;
}

export default Deposit;