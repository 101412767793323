import React, {useEffect, useCallback, useReducer, useContext, useState} from "react";
import { dataFetchReducer, useStateConnectedToCache } from "../../Utils/requestUtils";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { CircularProgress } from '@material-ui/core';
import AuthContext from "../../../context/auth-context";
import axios from "axios";
import {useHistory} from "react-router-dom"
import DialogError from '../DialogError'
import Pagination from "@material-ui/lab/Pagination";
import ReportItem from "../ReportsList/ReportItem/ReportItem";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: "22px",
    lineHeight: "27px",
    color: "#0b1f82",
    width: "auto"

  },
  error: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: "22px",
    lineHeight: "27px",
    color: "#0b1f82",
    marginUp: "300px"
  },
  subtitle: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "25px",
    color: "#0b1f82",
  },
  cardContainer: {
    width: "100%",
    display: "block",
  },
  pagination: {
    float: "right"
  },
  emptyContainer: {
    width: "100%",
    verticalAlign: "middle",
    textAlign: "center",
    padding: "30px",
    background: "white",
    boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.2)",
    borderRadius: "10px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    minWidth: "1100px"
  },
  buttonAnalyze: {
    boxSizing: "border-box",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.12)",
    backgroundColor: "#0b1f82",
    fontFamily: "Open Sans",
    fontSize: "13px",
    fontWeight: "bold",
    lineHeight: "18px",
    textAlign: "center",
    color: "#ffffff",
    borderRadius: "30px",
    textTransform: "capitalize",
    margin: "10px 7px 10px 7px",
    padding: "8px 20px 8px 20px",
    minWidth: "100px",
    "&:hover": {
      backgroundColor: "#204e68",
    }
  },
}))

function ReportsList(props) {
  const {t} = useTranslation()
  let history = useHistory()
  const classes = useStyles()
  useTheme()

  let roles = useContext(AuthContext).user_data.roles;
  if (!(['read_reports', 'read_reports_onboarding_natural_person', 'read_reports_onboarding_legal_person'].some(role => roles.includes(role)))) {
      history.push("");
  }

  const {isReloading} = props
  const local_storage_object_preffix = "reportsList"

  const [pageParams, setPageParams] = useStateConnectedToCache(local_storage_object_preffix)

  const [pageState, dispatchPageState] = useReducer(
    dataFetchReducer,
    { fetchedData: null, isLoading: true, isError: false }
  )

  const doRequest = useCallback(
    (payload) => {
      dispatchPageState({ type: "data_fetch_init" })

      axios.get("/dash/report/reports", {
        params: payload
      }).then(response => {
        dispatchPageState({
          type: "data_fetch_success",
          payload: response.data
        })    
        }).catch(error => {
          if ((error.response || {}).status === 403) dispatchPageState({type: "data_fetch_failure_403"})
          else if ((error.response || {}).status === 404) dispatchPageState({type: "data_fetch_failure_404"})  
          else dispatchPageState({type: "data_fetch_failure"})    
      })
  },[]
)

useEffect(() => {
  let payload = {
      "page_number": pageParams.page - 1,
      "page_rows": 25
  }
  Object.keys(pageParams.filters).forEach((key,index) => {
      payload[key] = pageParams.filters[key]
  })
        const timer_ = setTimeout(() => {
            doRequest(payload)
        }, 100);
        return () => {
            clearTimeout(timer_)
		}	
    },[doRequest, pageParams, isReloading])
  
    const handleChangePage = (event, page, filters) => {
		if (pageState.page === page) {
			return
		}
		setPageParams({page: page, filters: filters})
	}

	const contentContainer = (dataList) => {

    const nr_of_reports = dataList.data.length
    if (nr_of_reports === 0) {
      return (
        <div className={classes.emptyContainer}>
          <p className={classes.subtitle}>
            {t("no_reports_available")}
          </p>
        </div>
      )
    }

    let reports = dataList.data.map((reportData, index) => (
      <ReportItem key={index} reportData={reportData} />
    ))

    return (
        <div className='cardContainer' >
              <p className={classes.subtitle} style={{margin: '0 0 40px 30px'}}>{t("reports_available")}</p>

          <div className="listCardHeader" style={{display: 'grid',  gridTemplateColumns: '33% 33% 33%'}}>
              <div className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}>
                {t("report_title")}
              </div>
              <div className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}>
                {t("issue_date")}
              </div>
          </div>
          {reports}
      </div>
    )
  }
  const [fileErrorDialogOpen, setFileErrorDialogOpen] = useState(false)

  const [fileErrorDialogType, setFileErrorDialogType] = useState("")

  const handleFileErrorDialogClose = () => {
    setFileErrorDialogType("")
    setFileErrorDialogOpen(false)
  }

  if (pageState.fetchedData) {
    return (
      <div style={{ width: "auto", paddingBottom: "30px" }}>
        <div>{contentContainer(pageState.fetchedData)}</div>
        <div>
          <Pagination
            className="pagination"
            page={pageParams.page}
            count={parseInt(pageState.fetchedData["number_of_pages"])}
            onChange={(e, page) => handleChangePage(e, page, pageParams.filters)}
          />
        </div>
          <DialogError open={fileErrorDialogOpen}
                        onClose={handleFileErrorDialogClose}
                        errorType={fileErrorDialogType}/>
			</div>
		);
	}
	else if (pageState.isError){
		return (
			<div style={{ width: "auto" }}>
                <div style={{height: "55vh", display:"flex"}}>
                    <p className={["blueText", "titleSize"].join(" ")} style={{margin:"auto", textAlign:"center"}}>
                    {pageState.errorMessage}
                    </p>
                </div>
			</div>
		)
	}
	else if (pageState.isLoading) {
		return(
			<div style={{ width: "auto" }}>
				<div className='circularProgressCenter'>
					<CircularProgress />
				</div>
			</div>
		)
    }
    else return null
	
	}

export default ReportsList