import React  from "react";
import { useTranslation } from "react-i18next";
import FilterBox from "../../../Utils/FilterBox/FilterBox"


function Filter(props) {
    const { t } = useTranslation();
	
    const { setPageParams } = props
    const { company } = props

    let eventsEnumList = []

    if(company?.length){

        let eventsMappingList = []
        let eventNoDuplicate = []
        let companyEventFilter = company.map((currentCompany) => {
           return currentCompany.company_events.reduce((acc, event) => {
                let new_filter = [event.field_mapping.event_type.description, event.field_mapping.event_type.enum]
                acc.push(new_filter)
                return acc
            }, [])
        })

        companyEventFilter.forEach((currentEventMapping) => {
            currentEventMapping.forEach((currentEvent) => {
                eventNoDuplicate.push(currentEvent)
            })

        })

        eventNoDuplicate.filter(function (currentElement) {
            if (eventsMappingList.indexOf(currentElement.toString()) < 0) {
                eventsMappingList.push(currentElement.toString());
            }
            return eventsMappingList
        });
   
        eventsMappingList.map((currentEventElement) => {
            return eventsEnumList.push(currentEventElement.split(','))
        })
    
    }
    else {
        eventsEnumList = ((company || {}).company_events || []).reduce((acc,event) => {
            let new_filter = [event.field_mapping.event_type.description, event.field_mapping.event_type.enum]
            acc.push(new_filter)
            return acc
        },[])
    }

    if (props.role_filters.length ) {
        eventsEnumList = eventsEnumList.filter(item => props.role_filters.includes(item[1]))
    }

    eventsEnumList = eventsEnumList
                        .sort((a, b) => a[0] === b[0] ? 0 : a[0] > b[0] ? 1 : -1)

    let filterTypes = {
        event_type: {
            type: "enum",
            enumList: [ ...eventsEnumList],
            disabled: false,
            validation:false,
            description: t("Tipo do Evento")
        }
    }

    if(company?.length){

        let enumList = company.sort((a, b) => a.name === b.name ? 0 : a.name > b.name ? 1 : -1).reduce((acc, company) => {
            let new_filter = [company.name, company.company_key]
            acc.push(new_filter)
            return acc
        }, [])

        filterTypes['company_key'] = {
            type: "enum",
            enumList: [...enumList],  
            disabled: false,
            validation:false,
            description: t("Cliente")
        }

    } 
    return (
        <FilterBox 
            setPageParams={setPageParams}
            filterTypes={filterTypes}
            local_storage_object_preffix={props.local_storage_object_preffix}
        />
    )

}

export default Filter
