import React, { useState, useEffect } from "react";
import Chart from "chart.js/auto";
import { colorDarkBlueQIShadow } from '../utils'

function BarChart(props) {

  const [chart, setChart] = useState();

  useEffect(() => {

    if(chart){
      chart.destroy()
    }

    const ctx = document.getElementById(props.id).getContext('2d', {willReadFrequently: true})

    const data = {
        labels: props.labels, 
        datasets: [
        {
          label: props.label1,
          data: props.dataValue1,
          fill: true,
          backgroundColor: [colorDarkBlueQIShadow],
          barPercentage: 0.7,
          minBarLength: 2,
          width: props.width
        }
      ]
    }
    
    const barChart = new Chart(ctx, {
      type: 'bar',
      data: data,
      options: {
        scales: {
          y: {
              beginAtZero: true,
          }
        }
      },
    });

    setChart(barChart)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    return (
        <div>
          <canvas id={props.id} width='500'></canvas>
        </div>
    );
  }
  

export default BarChart;

