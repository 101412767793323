import React, { useEffect, useCallback, useContext, useState, useReducer } from "react"
import { dataFetchReducer } from "../../Utils/requestUtils"
import { useParams } from "react-router-dom"
import Observation from "./Observation/Observation"
import Messages from "./Messages/Messages"
import Alerts from "./Alerts/Alerts"
import Rental from "./Rental/Rental"
import AnalysisStatusBox from "./AnalysisStatusBox/AnalysisStatusBox"
import ClientProfile from "./ClientProfile/ClientProfile"
import Store from "./Store/Store"
import History from "./History/History"
import AnalystModal from "./AnalystModal/AnalystModal"
import axios from "axios"
import ErrorBoundary from "../../Utils/ErrorBoundary"
import { translateClientType } from "../utils/utils"
import { useHistory } from 'react-router-dom'
import AuthContext from '../../../context/auth-context'
import moment from 'moment';
import ScoreBox from "./ScoreBox/ScoreBox"
import QuizScoreBox from "./QuizScoreBox/QuizScoreBox"
import NotAppliedQuizScoreBox from "./QuizScoreBox/NotAppliedQuizScoreBox"
import DriverLicenseCard from "./DriverLicenseCard"

function RentalAgreement(props) {
    let history = useHistory()
    let user_data = useContext(AuthContext).user_data

    if (!user_data.roles.includes("rental_agreements_viewer")) {
        history.push("")
    }

    let { rental_agreement_key } = useParams()

    const [rental_agreement, dispatchRentalAgreement] = useReducer(
        dataFetchReducer,
        { fetchedData: null, isLoading: true, isError: false }
    )
    const [currentAnalyst, setCurrentAnalyst] = useState(null)
    const [analystDialogOpen, setAnalystDialogOpen] = useState(true)
    const [quizResults, setQuizResults] = useState(null)
    const [showQuizResults, setShowQuizResults] = useState(false)
    const [cleanQuizInterval, setCleanQuizInterval] = useState(null)
    const [receivedAnalystMountedTimeBonus, setReceivedAnalystMountedTimeBonus] = useState(false)
    const [manualAnalysisLimitDate, setManualAnalysisLimitDate] = useState(null)
    const [cleanAnalystBonusInterval, setCleanAnalystBonusInterval] = useState(null)
    const [totalSeconds, setTotalSeconds] = useState(null)

    const tick = (totalSeconds) => {
        totalSeconds = totalSeconds-1
        setTotalSeconds(totalSeconds)
    }

    useEffect(() => {
        const timer = setTimeout(() => {
        let limit = moment(manualAnalysisLimitDate)
        let now = moment()
        let totalSeconds = limit.diff(now, 'seconds')
        setTotalSeconds(totalSeconds)
        },500)
        return function cleanup() {
        setTotalSeconds(null)
        clearTimeout(timer)  
        }
    },[manualAnalysisLimitDate])

    useEffect(()=> {
        if (totalSeconds){
            const timer = setTimeout(function () {tick(totalSeconds)} , 1000)
            return () => {
                clearTimeout(timer)
            }
        }
    },[totalSeconds])    

    const analystHeartBeat = useCallback(
        (isMounted) => {
            let payload = {
                analystMounted: isMounted
            }
            axios.put('/dash/car_rental/rental_agreement/' + rental_agreement_key + "/analyst_heartbeat", payload).then(response => {
                setCurrentAnalyst(response.data.analyst)
            }).catch(error => { console.log(error) })
        }, [rental_agreement_key]
    )

    useEffect(() => {
        dispatchRentalAgreement({ type: "data_fetch_init" })
        const timer = setTimeout(() => {
            axios.get('/dash/car_rental/rental_agreement/' + rental_agreement_key).then(response => {
                dispatchRentalAgreement({
                    type: "data_fetch_success",
                    payload: response.data
                })
                setQuizResults(response.data.quiz_results)
                setReceivedAnalystMountedTimeBonus(response.data.received_analyst_mounted_time_bonus)
                setManualAnalysisLimitDate(response.data.manual_analysis_limit_date) 
                setShowQuizResults(!!((response.data || {}).quiz_results || []).length)
            }).catch(error => {
                if ((error.response || {}).status === 403) dispatchRentalAgreement({ type: "data_fetch_failure_403" })
                else if ((error.response || {}).status === 404) dispatchRentalAgreement({ type: "data_fetch_failure_404" })
                else dispatchRentalAgreement({ type: "data_fetch_failure" })
            })
        }, 500)

        const timer3 = setInterval(async () => {
            try {
                const response = await axios.get('/dash/car_rental/rental_agreement/' + rental_agreement_key)
                setQuizResults(response.data.quiz_results)
                setShowQuizResults(!!((response.data || {}).quiz_results || []).length)
            } catch (err) { console.error(err) }
        }, 50000)
        
        setCleanQuizInterval(timer3)
        setCleanAnalystBonusInterval(timer3)

        return () => {
            clearTimeout(timer)
        }

    }, [rental_agreement_key])

    useEffect(() => {
        if (showQuizResults && receivedAnalystMountedTimeBonus) {
            clearInterval(cleanQuizInterval)
        }
    }, [showQuizResults, cleanQuizInterval, receivedAnalystMountedTimeBonus, cleanAnalystBonusInterval])

    useEffect(() => {
        if (((rental_agreement.fetchedData || {}).fraud_status === "pending" ||
            (rental_agreement.fetchedData || {}).fraud_status === "in_manual_analysis")
        ) {
            analystHeartBeat(true)
            const timer = setInterval(() => {
                analystHeartBeat(true)
            }, 15000)
            return () => {
                clearInterval(timer)
                analystHeartBeat(false)
            }
        }
    }, [analystHeartBeat, rental_agreement])

    const [driverLicenseKey, setDriverLicenseKey] = useState(null);
    const [scanned, setScanned] = useState(false);

    useEffect(() => {
        if (!driverLicenseKey && !scanned) {
            if ((rental_agreement?.fetchedData?.client?.additional_pictures || []).length > 0) {
                setScanned(true);
                const pictures = rental_agreement?.fetchedData?.client?.additional_pictures;
                for (let i = 0; i < pictures.length; i++) {
                    let finished = false;
                    axios.get(`/dash/car_rental/image/${pictures[i]}/metadata`).then(response => {
                        if (response?.data?.type === 'driver_license') {
                            setDriverLicenseKey(pictures[i]);
                            finished = true;
                        }
                    })
                    if (finished) break;
                }
            }
        }
    }, [rental_agreement, driverLicenseKey, scanned])

    if (rental_agreement.fetchedData) {
        let fraud_status_events = (rental_agreement.fetchedData.fraud_status_events || [{}])
        let manual_analysis_reason = (fraud_status_events[0].decision_metadata || {}).reason_description
        let time_event = (fraud_status_events.filter((event)=>(["approved_by_time","reproved_by_time"].includes((event||{}).new_status)))||[])
        let time_reason = ((time_event[0]||{}).decision_metadata||{}).reason_description
        if (rental_agreement.fetchedData.rental_store) {
            rental_agreement.fetchedData.rental_store = rental_agreement.fetchedData.rental_store.toUpperCase()
        }
        if (rental_agreement.fetchedData.devolution_store) {
            rental_agreement.fetchedData.devolution_store = rental_agreement.fetchedData.devolution_store.toUpperCase()
        }

        let suggestion = (((rental_agreement.fetchedData.fraud_status_events || [{}])[0].decision_metadata || {}) || {}).suggestion

        let lastEventIndex = ((rental_agreement.fetchedData.fraud_status_events || [{}]).length - 1)

        let upgrade_status

        let currentUpgradeStatus = (rental_agreement.fetchedData.fraud_status_events || [{}])[lastEventIndex].upgrade_status

        let isCurrentUpgradeStatusNull = (currentUpgradeStatus === null)

        let islastEventIndexBiggerThanZero = (lastEventIndex > 0)

        let hasPreviousUpgradeStatus = islastEventIndexBiggerThanZero && (((rental_agreement.fetchedData.fraud_status_events || [{}])[lastEventIndex - 1].upgrade_status === "automatically_approved" ||
            (rental_agreement.fetchedData.fraud_status_events || [{}])[lastEventIndex - 1].upgrade_status === "automatically_reproved"))

        if (isCurrentUpgradeStatusNull && hasPreviousUpgradeStatus) {
            upgrade_status = (rental_agreement.fetchedData.fraud_status_events || [{}])[lastEventIndex - 1].upgrade_status
        } else {
            upgrade_status = (rental_agreement.fetchedData.fraud_status_events || [{}])[lastEventIndex].upgrade_status
        }

        let fraud_status = rental_agreement.fetchedData.fraud_status

        let hasUpgrade = !!(rental_agreement.fetchedData.car || [{}]).upgrade_model_group

        let isFraudStatusRepproved = (fraud_status === "automatically_reproved" || fraud_status === "manually_reproved")

        let showUpgradeStatus = hasUpgrade && !isFraudStatusRepproved && upgrade_status

        let showHistory = !((rental_agreement.fetchedData || {}).is_manual_analysis || "")

        return (
            <div style={{ display: "flex", flexDirection: "column", paddingBottom: "30px", minWidth: "900px" }}>
                <div className="analysisCardContainer">
                    <div className={["blueText", "titleSize"].join(" ")}>
                        Aluguel {rental_agreement.fetchedData.rental_agreement_code} {(rental_agreement.fetchedData.client || {}).type ? "- Cliente " + translateClientType(rental_agreement.fetchedData.client.type) : null}
                    </div>
                    <div style={{margin:"4px 5px",  display: "grid"}}>
                        {rental_agreement.fetchedData.reservation.id? " - Reserva: " + rental_agreement.fetchedData.reservation.id: null}
                    </div>
                </div>
                <div style={{ display: "flex" }}>
                    <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
                        <div className="analysisCardContainer">
                            <ErrorBoundary>
                                <ClientProfile rental_agreement={rental_agreement.fetchedData} />
                            </ErrorBoundary>
                        </div>
                        {driverLicenseKey &&
                            <div className="analysisCardContainer" style={{ flexGrow: "1" }}>
                                <ErrorBoundary>
                                    <DriverLicenseCard image_key={driverLicenseKey}/>
                                </ErrorBoundary>
                            </div>
                        }
                        <div className="analysisCardContainer" style={{ flexGrow: "1" }}>
                            <ErrorBoundary>
                                <Store rental_agreement={rental_agreement.fetchedData} />
                            </ErrorBoundary>
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
                            <div style={{ display: "grid", gridTemplateColumns: "50% 50%" }}>
                                <div className="analysisCardContainer">
                                    <ErrorBoundary>
                                        <AnalysisStatusBox 
                                            totalSeconds={totalSeconds}
                                            manual_analysis_limit_date={manualAnalysisLimitDate} 
                                            fraud_status={fraud_status} 
                                            showUpgradeStatus={showUpgradeStatus} 
                                            upgrade_status={upgrade_status}
                                            manual_analysis_reason={manual_analysis_reason}
                                            time_reason={time_reason}
                                            suggestion={suggestion}
                                        />
                                    </ErrorBoundary>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", flexGrow:"1"}}>
                                    <div className="analysisCardContainer" style={{flexGrow:"1"}}>
                                        <ErrorBoundary>
                                            <ScoreBox fraud_status_events={rental_agreement.fetchedData.fraud_status_events}/>
                                        </ErrorBoundary>
                                    </div>
                                    <div className="analysisCardContainer" style={{flexGrow:"1"}}>
                                        {showQuizResults?
                                        <ErrorBoundary>
                                            <QuizScoreBox quiz_score={(quizResults||[])[0]}/>
                                        </ErrorBoundary>
                                        :<ErrorBoundary>
                                            <NotAppliedQuizScoreBox/>
                                        </ErrorBoundary>}
                                    </div>
                                </div>
                            </div>
                        <div style={{ display: "flex", width: "100%", minHeight: "300px", flexGrow:"1"}}>
                            <div className="analysisCardContainer" style={{ width: "50%", flexGrow:"1"}}>
                                <ErrorBoundary>
                                    <Rental rental_agreement={rental_agreement.fetchedData} />
                                </ErrorBoundary>
                            </div>
                            <div className="analysisCardContainer" style={{ width: "50%", flexGrow:"1" }}>
                                <ErrorBoundary>
                                    <Alerts fraud_status_events={rental_agreement.fetchedData.fraud_status_events} />
                                </ErrorBoundary>
                            </div>
                        </div>
                    </div>

                </div>
                {['ALF1', 'ARQ2', 'ARU2', 'ARU3', 'BAL1', 'BGX2', 'BNU4', 'BPS4', 'BPS5', 'BVB1', 'BYO1', 'CLV3', 'CLV4', 'CPL2', 'CUR1', 'CXJ1', 'DOU3', 'GNH1', 'GRM1', 'GVR1', 'GVR2', 'IJU1', 'ITA2', 'ITQ1', 'JCB1', 'JJG1', 'LVB1', 'PAM1', 'PAS1', 'PFB1', 'PNZ4', 'QHE1', 'QHG2', 'QJA1', 'ROO2', 'SRO1', 'STG1', 'STM1', 'TFL1', 'TGQ1', 'TML1', 'TQR1'].indexOf(rental_agreement.fetchedData.rental_store.toUpperCase()) > -1 ?
                    null :
                    <div style={{ display: "flex", width: "100%" }}>
                        <div className="analysisCardContainer" style={{ width: "100%", display: "block" }}>
                            <ErrorBoundary>
                                <Messages rental_agreement={rental_agreement.fetchedData} />
                            </ErrorBoundary>
                        </div>
                    </div>}
                <div style={{display:"flex" , width: "100%"}}>
                    {showHistory ?
                    <div className="analysisCardContainer" style={{width: "50%"}}>
                        <ErrorBoundary>
                            <History ra_key={rental_agreement_key} />
                        </ErrorBoundary>
                    </div>
                    : null}
                    <div className="analysisCardContainer" style={{width: "50%", display: "block"}}>
                        <ErrorBoundary>
                            <Observation totalSeconds={totalSeconds} rental_agreement={rental_agreement.fetchedData} manual_analysis_limit_date={manualAnalysisLimitDate} fraud_status={fraud_status} upgrade_status={upgrade_status} showUpgradeStatus={showUpgradeStatus} />
                        </ErrorBoundary>
                    </div>
                </div>
                {["pending", "in_manual_analysis"].includes(rental_agreement.fetchedData.fraud_status) &&
                    currentAnalyst != null && currentAnalyst.analyst_key !== user_data.user_key && analystDialogOpen ?
                    <AnalystModal
                        currentAnalyst={currentAnalyst}
                        open={analystDialogOpen}
                        onClose={() => setAnalystDialogOpen(false)}
                    /> : null}
            </div>
        )
    }
    else if (rental_agreement.isError) {
        return (
            <div style={{ height: "80vh", display: "flex" }}>
                <p className={["blueText", "titleSize"].join(" ")} style={{ margin: "auto", textAlign: "center" }}>
                    {rental_agreement.errorMessage}
                </p>
            </div>
        )
    }

    return null
}

export default RentalAgreement