import React from 'react'
import merchantIcon from '../../../../assets/cardEntryIcons/MerchantIcon.svg';
import mapMarker from '../../../../assets/cardEntryIcons/MapMarker.svg';
import MerchantMap from "./MerchantMap/MerchantMap"
import { formatAddress, checkNested, titleCase, getBoolIcon, getTerminalType, getAcquirer } from "../../utils/utils"

function MerchantCard(props) {

    let address = formatAddress(props.transaction.merchant)

    return (
        <div className="analysisCard">
            <div style={{display: "flex"}}>
                <img className="cardTitleIconStyle" src={merchantIcon} alt="merchantIcon Logo" />
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>Merchant</span>
            </div>
            <div className="internalCardContainer">
                <MerchantMap address={address}/>
            </div>
            <div className="internalCardContainer" style={{display:"flex"}}>
                <img className="cardTitleIconStyle" src={mapMarker} alt="mapMarker Logo" />
                <div className="labelSpace">
                    <div className={["labelText", "normalMediumSize"].join(" ")}>
                        {address}
                    </div>
                    <div className={["labelText", "normalMediumSize"].join(" ")}>
                        {checkNested(props.transaction, "merchant", "postal_code") ? "CEP: " + props.transaction.merchant.postal_code : null }                        
                    </div>
                </div>
            </div>
            <div className="internalCardContainer">
                <div style={{display:"flex"}}>
                    <img className="cardTitleIconStyle" src={merchantIcon} alt="merchantIcon Logo" />
                    <div className={["labelText", "normalMediumSize", "labelSpace"].join(" ")}>
                        {props.transaction.merchant == null ? "-" : titleCase(props.transaction.merchant.name, "-")}                        
                    </div>
                </div>
                <div style={{display: "flex", margin: "5px 0px"}}>
                    <div className={["normalText", "normalMediumSize"].join(" ")}>
                        Merchant ID:
                    </div>
                    <div className={["labelText", "normalMediumSize", "labelSpace"].join(" ")}>
                        {checkNested(props.transaction, "merchant", "merchant_id") ? props.transaction.merchant.merchant_id : "-" }
                    </div>
                </div>
                <div style={{display: "flex", margin: "5px 0px"}}>
                    <div className={["normalText", "normalMediumSize"].join(" ")}>
                        Adquirente:
                    </div>
                    <div className={["labelText", "normalMediumSize", "labelSpace"].join(" ")}>
                        {checkNested(props.transaction, "merchant", "acquirer_id") ? getAcquirer(props.transaction.merchant.acquirer_id) : "-" }
                        {checkNested(props.transaction, "enriched_data", "acquirer") ? " - " + props.transaction.enriched_data.acquirer : "" }
                    </div>
                </div>
                <div style={{display: "flex", margin: "5px 0px"}}>
                    <div className={["normalText", "normalMediumSize"].join(" ")}>
                        MCC:
                    </div>
                    <div className={["labelText", "normalMediumSize", "labelSpace"].join(" ")}>
                        {checkNested(props.transaction, "merchant", "mcc") ? props.transaction.merchant.mcc : "-" }
                        {checkNested(props.transaction, "enriched_data", "mcc_description") ? " - " + props.transaction.enriched_data.mcc_description : "" }
                    </div>
                </div>
            </div>
            <div className="internalCardContainer">
                <div className={["blueText", "normalSmallSize"].join(" ")}>
                    {!checkNested(props.transaction, "terminal", "id") ? "Terminal" :
                    "Terminal " + props.transaction.terminal.id}
                </div>
                {
                    checkNested(props.transaction, "terminal", "terminal_type") ? 
                    <div style={{display: "flex", margin: "5px 0px"}}>
                        <div className={["normalText", "normalMediumSize"].join(" ")}>
                            Tipo:
                        </div>
                        <div className={["labelText", "normalMediumSize", "labelSpace"].join(" ")}>
                            {getTerminalType(props.transaction.terminal.terminal_type)}
                        </div>                    
                    </div> : null
                }
                {
                    checkNested(props.transaction, "terminal", "pin_entry_capability") ? 
                    <div style={{display: "flex", margin: "5px 0px"}}>
                        <div className={["normalText", "normalMediumSize"].join(" ")}>
                            Input de senha
                        </div>
                        <div style={{marginLeft: "10px"}}>
                            {getBoolIcon(props.transaction.terminal.pin_entry_capability)}
                        </div>                    
                    </div> : null
                }
                {
                    checkNested(props.transaction, "terminal", "magnetic_stripe_capability") ? 
                    <div style={{display: "flex", margin: "5px 0px"}}>
                        <div className={["normalText", "normalMediumSize"].join(" ")}>
                            Tarja
                        </div>
                        <div style={{marginLeft: "10px"}}>
                            {getBoolIcon(props.transaction.terminal.magnetic_stripe_capability)}
                        </div>                    
                    </div> : null
                }
                {
                    checkNested(props.transaction, "terminal", "contactless_capability") ? 
                    <div style={{display: "flex", margin: "5px 0px"}}>
                        <div className={["normalText", "normalMediumSize"].join(" ")}>
                            Contactless
                        </div>
                        <div style={{marginLeft: "10px"}}>
                            {getBoolIcon(props.transaction.terminal.contactless_capability)}
                        </div>                    
                    </div> : null
                }
                {
                    checkNested(props.transaction, "terminal", "chip_capability") ? 
                    <div style={{display: "flex", margin: "5px 0px"}}>
                        <div className={["normalText", "normalMediumSize"].join(" ")}>
                            Chip
                        </div>
                        <div style={{marginLeft: "10px"}}>
                            {getBoolIcon(props.transaction.terminal.chip_capability)}
                        </div>                    
                    </div> : null
                }
            </div>
        </div>
    )
}

export default MerchantCard