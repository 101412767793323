//Importing modules
import React, {useState, useEffect, useContext} from 'react'
import merchantIcon from '../../../../assets/cardEntryIcons/MerchantIcon.svg';
import emailIcon from '../../../../assets/cardEntryIcons/emailBlue.svg';
import clockIcon from '../../../../assets/cardEntryIcons/Clock.svg';
import phoneIconBlue from '../../../../assets/cardEntryIcons/phoneBlue.svg';
import mapMarkerIcon from '../../../../assets/cardEntryIcons/MapMarker.svg';
import { CircularProgress } from '@material-ui/core';
import AuthContext from "../../../../context/auth-context"
import axios from 'axios'
import {titleCase, clientAge, formatPhone, formatAddress} from "../../utils/utils"
import DataField from "../../utils/DataField"
import {Link} from 'react-router-dom'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import TinyBarChart from "./TinyBarChart"



//Defining SellerCard component
function SellerCard(props) {

    //Get user data from context
    let user_data = useContext(AuthContext).user_data


    //Defining objects state handlers
    let [seller, setSeller] = useState(null)
    let [barChartData, setBarChartData] = useState(null)


    //Making requests to Dash API to retrieve seller historic data and seller onboarding data
    useEffect(() => {
        const timer = setTimeout(() => {
            let payload = {}
            if (user_data.business_group_key){
                payload["company_key"] = props.order.company_key
            }
            axios.get('/dash/onboarding/external_id/' + props.order.seller.id, {params: payload}).then( response => {
                let seller_data = response 
                if (seller_data == null) throw new Error()
                setSeller(seller_data);             
            }).catch(error => {
                setSeller(undefined)
            })
            axios.get("/dash/card_order/order/"+ props.order.order_key +"/seller_historic").then( response => {
                let seller_bar_chart_data = response.data
                if (seller_bar_chart_data == null) throw new Error()
                setBarChartData(seller_bar_chart_data);             
            }).catch(error => {
                setBarChartData(undefined)
            })
        }, 500);
        return () => {
            clearTimeout(timer)
        }
    }, [props.order, user_data])


    //Elements to be Rendered inside component
    if (seller === null || barChartData === null){
        return (
            <div className="analysisCard">
            <div style={{display: "flex"}}>
                <img className="cardTitleIconStyle" src={merchantIcon} alt="merchantIcon Logo" />
                <span className={["blueText", "subtitleSize", "labelSpace", "breakWord"].join(" ")}>Vendedor {props.order.seller.id}</span>
            </div>
            <div style={{display: "flex", flexGrow:"1"}}>
                <CircularProgress style={{margin:"auto"}}/>
            </div>
        </div>
        )
    }
    else if (props.order.seller) {
        return (
            <div className="analysisCard">
                <div style={{display: "flex"}}>
                    <img className="cardTitleIconStyle" src={merchantIcon} alt="merchantIcon Logo" />
                    <span className={["blueText", "subtitleSize", "labelSpace", "breakWord"].join(" ")}>Vendedor</span>
                </div>
                <div className="internalCardContainer" style={{display: "flex", flexDirection: "row"}}>
                    <div style={{display: "flex", flexDirection: "column", width:"60%", paddingRight:"20px"}}>
                        <div style={{display: "inline", marginTop: "5px", marginBottom: "5px", marginLeft:"0px", lineHeight: "20px", fontSize: "22px"}}>
                            {seller === undefined ? titleCase(props.order.seller.name, "-") :
                            (seller.type === "natural_person" && user_data.roles.includes("read_onboarding_natural_persons")) ?
                            <Link 
                                to={"/natural-person/" + seller.data.natural_person_key}
                                className={["link", "blueText", "normalMediumSize", "bold"].join(" ")}
                            >
                                {titleCase(props.order.seller.name, "-")}
                            </Link> :
                            (seller.type === "legal_person" && user_data.roles.includes("read_onboarding_legal_persons")) ?
                            <Link 
                                to={"/legal-person/" + seller.data.legal_person_key}
                                className={["link", "blueText", "normalMediumSize", "bold"].join(" ")}
                            >
                                {titleCase(props.order.seller.name, "-")}
                            </Link> :
                            titleCase(props.order.seller.name, "-")}
                            {props.order.seller.url ? <a href={props.order.seller.url} style={{color:"#0b1f82", verticalAlign: "-0.2em", marginLeft: "10px"}}><ExitToAppIcon/></a> : null} 
                        </div>
                        {(props.order.seller.document_number) ? 
                        <div style={{display: "inline", marginTop: "5px", marginBottom: "5px", marginLeft:"0px", lineHeight: "20px"}}>
                            <span className={["labelText", props.size, "labelSpace"].join(" ")} style={{verticalAlign: "middle", marginLeft:"0px"}}>
                                {props.order.seller.document_number || "-"}
                            </span>
                        </div> : null}
                        {(props.order.seller.address) ? 
                        <a
                            href={"https://www.google.com/maps/search/?api=1&query=" + 
                                formatAddress(props.order.seller.address,"-").replace(/ /g, "%20").replace(/,/, '%2C')}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{color: "#0b1f82"}}
                        >
                        <DataField
                            titleImage={mapMarkerIcon}
                            titleTooltipText="Endereço"
                            alt={"mapMarkerIcon Logo"}
                            label={formatAddress(props.order.seller.address) || "-"}
                            size={"normalMediumSize"}
                            labelStyles={{verticalAlign:"middle"}}
                            titleNoWrap={true}
                            margin="5px"
                            display={"inline"}
                            labelClasses={"blueTextLabel"}
                            labelClassesOverride={true}
                        /> 
                        </a>: null}
                    </div>
                    <div style={{display: "flex", flexDirection: "column", width:"35%"}}>
                        {props.order.seller.registration_date ?
                        <DataField
                            titleImage={clockIcon}
                            titleTooltipText="Data de Registro"
                            alt={"clockIcon Logo"}
                            label={clientAge(props.order.seller.registration_date, "-", "months_days", props.order.order_date, true)}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="5px"
                            display={"inline"}
                        /> : null}
                        {(props.order.seller.phone) ? 
                        <DataField
                            titleImage={phoneIconBlue}
                            titleTooltipText="Telefone"
                            alt={"phoneIconBlue Logo"}
                            label={formatPhone(props.order.seller.phone, "-")}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="5px"
                            display={"inline"}
                        /> : null} 
                        {(props.order.seller.email) ? 
                        <DataField
                            titleImage={emailIcon}
                            titleTooltipText="Email"
                            alt={"emailIcon Logo"}
                            labelStyles={{verticalAlign:"middle", wordWrap:"break-word"}}
                            label={props.order.seller.email || "-"}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="5px"
                            display={"inline"}
                        /> : null}
                    </div>
                </div>
                {barChartData === undefined ? null :
                <div style={{display:"flex",flexDirection:"row"}}>
                    <div style={{width:"33%"}}>
                        <TinyBarChart 
                        data={barChartData} 
                        isMoney={true}
                        text={"Valor Médio das Vendas"}
                        field={"average_amount"} 
                        currency={props.order.payment.currency}
                        />
                    </div>
                    <div style={{width:"33%"}}>
                        <TinyBarChart 
                        data={barChartData} 
                        isMoney={true}
                        text={"Valor Total das Vendas"}
                        field={"total_amount"} 
                        currency={props.order.payment.currency}
                        />                        </div>
                    <div style={{width:"33%"}}>
                        <TinyBarChart 
                        data={barChartData} 
                        isMoney={false}
                        text={"Vendas Realizadas"}
                        field={"orders"} 
                        currency={props.order.payment.currency}
                        />                        
                    </div>                    
                </div>}
            </div>
        )
    }
    else {
        return null
    }
}



//Exporting component
export default SellerCard