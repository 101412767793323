import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "8px"
  },
  label: {
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "22px",
    color: "#141414",
  },
  data: {
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "22px",
    color: "#6F6F6F",
  },
}));

function DataField(props) {
  const classes = useStyles();
  useTheme();

  return (
    <div className={classes.container}>
      <span className={classes.label}>{props.name}: </span>
      <span className={classes.data}>{props.data}</span>
    </div>
  );
}

export default DataField;
