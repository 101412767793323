import React, { useReducer, useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import axios from "axios"
import { getAcquirer, formatCard } from "../../../utils/utils"
import { CircularProgress } from '@material-ui/core';
import ConfirmationContent from "./ConfirmationContent"
import {decisionReducer, dataFetchReducer} from "../../../../Utils/requestUtils"
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createMuiTheme({
    palette:{
        primary: {
            main:"#0b1f82"
        },
    }
  });

function UnlockDialod(props) {

    const [dialogState, dispatchDialogState] = useReducer(
        decisionReducer,
        {isLoading: false, isError: false, finished:false}
    )

    const [observationBox, setObservationBox] = useState("")

    const handleObservationBoxChange = (event) => {
        setObservationBox(event.target.value)
    };

    const handleApprove = () =>{
        dispatchDialogState({type:"send_request_init"})
        let payload={
            lock_status: "unlocked"
        }
        if (observationBox && observationBox.length > 0){
            payload['observation'] = observationBox
        }
        setTimeout(function () {
            axios.put('/dash/card_issuance_backoffice/lock/' + props.lock.lock_key, payload).then(response => {
                dispatchDialogState({type:"send_request_success"})
              }).catch(error => {
                if ((error.response || {}).status === 403) dispatchDialogState({type: "send_request_failure_403"})
                else if ((error.response || {}).status === 409) dispatchDialogState({type: "send_request_failure_409"})  
                else dispatchDialogState({type: "send_request_failure"})    
              });
        }, 1000);
    }

    const handleReturn = () => {
        props.onClose()
        props.setPageParams({page: 1, filters: JSON.parse(sessionStorage.getItem(props.local_storage_object_preffix + 'PageParams')).filters})
    }

    const [alert, dispatchAlert] = useReducer(
        dataFetchReducer,
        props.lock.lock_source === "automatically_alert" ? {fetchedData: null, isLoading: true, isError: false}:null
    )

    useEffect(() => {
        if (props.lock.lock_source === "automatically_alert"){
            dispatchAlert({type: "data_fetch_init"})   
            axios.get('/dash/card_issuance_backoffice/alert/' + props.lock.alert_key).then( response => {
                dispatchAlert({
                    type: "data_fetch_success",
                    payload: response.data
                })   
            }).catch(error => {
                if ((error.response || {}).status === 403) dispatchAlert({type: "data_fetch_failure_403"})
                else if ((error.response || {}).status === 404) dispatchAlert({type: "data_fetch_failure_404"})  
                else dispatchAlert({type: "data_fetch_failure"})    
            })	
        }
    }, [props.lock])

    if ((dialogState.finished && dialogState.isLoading) || (alert || {}).isLoading){
        return (
            <Dialog 
                open={props.open} 
                onClose={props.onClose} 
                keepMounted={false}
                disableBackdropClick
            >
                <DialogTitle style={{paddingBottom: "0px"}} className={["blueText", "normalMediumSize"].join(" ")}>Desbloquear</DialogTitle>
                <DialogContent>
                    <div style={{display:"flex", width: "310px", height:"217px"}}>
                        <CircularProgress style={{margin:"auto"}} />
                    </div>
                </DialogContent>
            </Dialog>

        )
    }
    else if (dialogState.finished){
        let confirmationData = {    
            message: dialogState.message,
            success: !dialogState.isError
        }
        return (
            <Dialog 
                open={props.open} 
                onClose={handleReturn} 
                keepMounted={false}
                disableBackdropClick
            >
                <DialogTitle style={{paddingBottom: "0px"}} className={["blueText", "normalMediumSize"].join(" ")}>Confirmação de Revisão</DialogTitle>
                <DialogContent>
                    <ConfirmationContent onClose={handleReturn} data={confirmationData} />
                </DialogContent>
            </Dialog>
        )
    }
    else if((alert || {}).isError){
        return (
            <Dialog 
                open={props.open} 
                onClose={props.onClose} 
                keepMounted={false}
            >
                <DialogTitle style={{paddingBottom: "0px"}} className={["blueText", "normalMediumSize"].join(" ")}>Desbloquear</DialogTitle>
                <DialogContent>
                    <div style={{display:"flex", width: "310px", height:"217px"}}>
                        <div className={["normalText", "normalMediumSize"].join(" ")} style={{margin: "auto", textAlign:"center"}}>
                            Houve um problema da nossa parte.<br />Tente recarregar a página.<br />Se o problema persistir entre em contato conosco.                   
                        </div>
                    </div>
                </DialogContent>
            </Dialog>

        )        
    }
    else if(((alert || {}).fetchedData || {}).alert_status === "in_manual_analysis"){
        return (
            <Dialog 
                open={props.open} 
                onClose={props.onClose} 
                keepMounted={false}
            >
                <DialogTitle style={{paddingBottom: "0px"}} className={["blueText", "normalMediumSize"].join(" ")}>Desbloquear</DialogTitle>
                <DialogContent>
                    <div style={{display:"flex", width: "310px", height:"217px", flexDirection: "column"}}>
                        <div className={["normalText", "normalMediumSize"].join(" ")} style={{marginTop: "50px", textAlign:"center"}}>
                            Esse Bloqueio ainda possui um alerta aberto. Faça o desbloqueio e finalize o alerta.                           
                        </div>
                        <Link
                            className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                            style={{margin: "10px auto", width:"50%", textDecoration: "none"}}
                            to={"/alert/" + alert.fetchedData.alert_key}
                        >
                            Ir para o Alerta
                        </Link>
                    </div>
                </DialogContent>
            </Dialog>
        ) 
    }
    else{
        return (
            <Dialog 
            open={props.open} 
            onClose={props.onClose} 
            keepMounted={false}
            >
                <DialogTitle style={{paddingBottom: "0px"}} className={["blueText", "normalMediumSize"].join(" ")}>Desbloquear</DialogTitle>
                <DialogContent>
                    <div>
                        <div className="internalCardContainer">
                            {props.lock.lock_type === "card" ? 
                            <div 
                                className={["normalText", "normalMediumSize"].join(" ")}
                            >
                                Você tem certeza que deseja Desbloquear o seguinte Cartão:
                                <div style={{display: "flex", margin: "5px 0px", justifyContent: "center"}}>
                                    <div className={["normalText", "normalMediumSize"].join(" ")}>
                                        ID:
                                    </div>
                                    <div className={["labelText", "normalMediumSize", "labelSpace"].join(" ")}>
                                        {props.lock.cardholder_id}
                                    </div>                    
                                </div>
                                <div style={{display: "flex", margin: "5px 0px", justifyContent: "center"}}>
                                    <div className={["normalText", "normalMediumSize"].join(" ")}>
                                        Cartão:
                                    </div>
                                    <div className={["labelText", "normalMediumSize", "labelSpace"].join(" ")}>
                                        {formatCard(props.lock.bin, props.lock.last4)}
                                    </div>                    
                                </div>
                            </div> : props.lock.lock_type === "merchant" ?
                            <div 
                                className={["normalText", "normalMediumSize"].join(" ")}
                            >
                                Você tem certeza que deseja Desbloquear o seguinte Merchant:
                                <div style={{display: "flex", margin: "5px 0px", justifyContent: "center"}}>
                                    <div className={["normalText", "normalMediumSize"].join(" ")}>
                                    {(((props.lock.lock_events || [{}])[0] || {}).alert_metadata || {}).merchant_name ? "Nome:" : "ID:"}
                                    </div>
                                    <div className={["labelText", "normalMediumSize", "labelSpace"].join(" ")}>
                                    {(((props.lock.lock_events || [{}])[0] || {}).alert_metadata || {}).merchant_name || props.lock.merchant_id}
                                    </div>                    
                                </div>
                                <div style={{display: "flex", margin: "5px 0px", justifyContent: "center"}}>
                                    <div className={["normalText", "normalMediumSize"].join(" ")}>
                                        Adquirente:
                                    </div>
                                    <div className={["labelText", "normalMediumSize", "labelSpace"].join(" ")}>
                                        {getAcquirer(props.lock.acquirer_id)}
                                    </div>                    
                                </div>
                            </div> :
                            <div 
                                className={["normalText", "normalMediumSize"].join(" ")}
                            >
                                Você tem certeza que deseja Desbloquear o seguinte Terminal:
                                <div style={{display: "flex", margin: "5px 0px", justifyContent: "center"}}>
                                    <div className={["normalText", "normalMediumSize"].join(" ")}>
                                        ID:
                                    </div>
                                    <div className={["labelText", "normalMediumSize", "labelSpace"].join(" ")}>
                                        {props.lock.merchant_id}
                                    </div>                    
                                </div>
                                <div style={{display: "flex", margin: "5px 0px", justifyContent: "center"}}>
                                    <div className={["normalText", "normalMediumSize"].join(" ")}>
                                        Adquirente:
                                    </div>
                                    <div className={["labelText", "normalMediumSize", "labelSpace"].join(" ")}>
                                        {getAcquirer(props.lock.acquirer_id)}
                                    </div>                    
                                </div>
                                <div style={{display: "flex", margin: "5px 0px", justifyContent: "center"}}>
                                    <div className={["normalText", "normalMediumSize"].join(" ")}>
                                        Terminal:
                                    </div>
                                    <div className={["labelText", "normalMediumSize", "labelSpace"].join(" ")}>
                                        {props.lock.terminal_id}
                                    </div>                    
                                </div>
                            </div>}
                        </div>
                        <ThemeProvider theme={theme}>
                            <TextField  
                                fullWidth
                                multiline
                                variant="outlined"
                                rows="5"
                                label={"Observações"}
                                color="primary"
                                onChange={handleObservationBoxChange}
                            />
                        </ThemeProvider>
                        <div className="internalCardContainer" style={{display:"flex", justifyContent:"center"}}>
                            <div 
                                className={["button", "approved", "normalText", "normalMediumSize"].join(" ")}
                                style={{minWidth: "70px"}}
                                onClick={handleApprove}
                            >
                                SIM 
                            </div>
                            <div 
                                className={["button", "reproved", "normalText", "normalMediumSize"].join(" ")}
                                style={{minWidth: "70px"}}
                                onClick={props.onClose}
                            >
                                NÃO
                            </div>
                      </div>
                  </div>
              </DialogContent>
            </Dialog>
        )
    }
}

export default UnlockDialod;