import React from "react";
import DataField from "../../../utils/DataField"
import {titleCase, clientAge, checkNested,formatPhone, formatAddress, formatBureauInfo, translateGender, getProperMetadata} from "../../../utils/utils"
import MapIcon from "@material-ui/icons/Map";
import Tooltip from "@material-ui/core/Tooltip";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useTranslation } from "react-i18next";

function LegacyClientProfileAnalyst(props) {
    let decision_metadata = getProperMetadata(props.natural_person.analysis_status_events)
    let registration_data = decision_metadata.registration_data
    let score_unico = ((props.natural_person.custom_data||{}).unico_response||{}).score
    if (!score_unico) {
        score_unico = (props.natural_person.custom_data||{}).score_unico
    }

    const { t } = useTranslation()

    return (
        <div className="analysisCard" style={{height:"100%"}}>
            <div className={["blueText", "subtitleSize"].join(" ")}>{t("basic_data")}</div>
            <div className="internalCardContainer" style={{display: "flex", flexDirection: "column"}}>
                <DataField
                    title={"Nome"}
                    label={checkNested(registration_data, "name", "description") ? 
                        titleCase(registration_data.name.description, "-"): "-"
                    }
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                />
                <DataField
                    title={"Nome da Mãe"}
                    label={checkNested(registration_data, "mother_name", "description") ? 
                        titleCase(registration_data.mother_name.description, "-"): "-"
                    }
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                />
                <DataField
                    title={"CPF"}
                    label={props.natural_person.document_number || "-"}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                />
                {
                    checkNested(registration_data, "nationality", "description") ?
                    <DataField
                        title={"Nacionalidade"}
                        label={checkNested(registration_data, "nationality", "description") ? registration_data.nationality.description: "-"
                        }
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        margin="5px"
                    />
                    : null
                }
                <DataField
                    title={"Gênero"}
                    label={checkNested(registration_data, "gender", "description") ? 
                        translateGender(registration_data.gender.description,"-"): "-"
                    }
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                />
                <DataField
                    title={"Data de Nascimento"}
                    label={checkNested(registration_data, "birthdate", "description") ? 
                        clientAge(registration_data.birthdate.description,"-"): "-"
                    }
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                />
                <DataField
                    title={"Telefone"}
                    label={((registration_data || {}).phone_number || []).length > 0 ? 
                        formatPhone(registration_data.phone_number[0], "-") : "-"
                    }
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                >
                    {((registration_data || {}).phone_number || [{}]).length > 1 ?
                    <Tooltip title={<p className="tooltipText">{formatBureauInfo(registration_data.phone_number.slice(1), "telefones")}</p>}>
                        <MoreHorizIcon style={{verticalAlign:"middle"}}/>
                    </Tooltip> : null}
                </DataField>
                <DataField
                    title={"Email"}
                    label={((registration_data || {})["e-mail"] || []).length > 0 ? 
                        registration_data["e-mail"][0].email || "-" : "-"
                    }
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                >
                {((registration_data || {})["e-mail"]|| [{}]).length > 1 ?
                    <Tooltip title={<p className="tooltipText">{formatBureauInfo(registration_data["e-mail"].slice(1), "emails")}</p>}>
                        <MoreHorizIcon style={{verticalAlign:"middle"}}/>
                    </Tooltip> : null}
                </DataField>
                <DataField
                    title={"Endereço"}
                    label={((registration_data || {}).address || []).length > 0 ? 
                        formatAddress(registration_data.address[0], "-") : "-"
                    }
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                >
                    {((registration_data || {}).address || []).length > 0 ? 
                    <a
                        href={"https://www.google.com/maps/search/?api=1&query=" + 
                            formatAddress(registration_data.address[0],"-").replace(/ /g, "%20").replace(/,/, '%2C')}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <MapIcon style={{verticalAlign: "middle", width: "20px", height:"20px",cursor:"pointer", color:"#777",margin: "auto 5px"}} />
                    </a>: null}
                    {((registration_data || {}).address || [{}]).length > 1 ?
                    <Tooltip title={<p className="tooltipText">{formatBureauInfo(registration_data.address.slice(1), "endereços")}</p>}>
                        <MoreHorizIcon style={{verticalAlign:"middle"}}/>
                    </Tooltip> : null}
                </DataField>
                {score_unico&&
                <>
                    <div className={["normalMediumSize","bold"].join(" ")} style={{marginTop: '15px'}}>
                        {`${t("additional_data")}:`}
                    </div>
                    <DataField
                        title={t("score_unico")}
                        label={score_unico}
                        size={"normalMediumSize"}
                        titleNoWrap={false}
                    />
                </>}
                {decision_metadata.enricher_income?.data?.[0]?.monthly_income &&
                <DataField
                    title={"Renda Mensal"}
                    label={`R$${(decision_metadata.enricher_income.data[0].monthly_income / 100).toFixed(2).replace('.', ',')}`}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                />}
            </div>
        </div>
    );
}

export default LegacyClientProfileAnalyst;
