import React, { useContext, useState, useReducer } from "react";
import { useTranslation } from "react-i18next";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";
import AuthContext from "../../../../context/auth-context";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { default_roles_groups } from "../../utils"
import RolesGroupPermissions from "../RolesGroupPermissions/RolesGroupPermissions";
import { decisionReducer } from "../../../Utils/requestUtils";
import Button from "../../../@GeneralComponents/Button";
import FormField from "../../../@GeneralComponents/FormField";
import useInput from "../../../CreditAnalysis/utils/hooks/use-input";
import { formatGroupName } from "../../utils";
import { removeSpecialCharacters } from "../../../Utils/utils";

function CreateGroupPermissions (props) {

    let user_data = useContext(AuthContext).user_data
    const { t } = useTranslation();

    let user_roles = user_data.roles;
    const rolesGroupSortedAlphabetically = default_roles_groups.sort((a, b) => a.name === b.name ? 0 : a.name > b.name ? 1 : -1)
    const allowedRolesGroups = rolesGroupSortedAlphabetically.filter(roles_group => user_roles.includes(roles_group['manager_role']))

    let checkedInitialState = {}
    allowedRolesGroups.forEach((group)=>(
        group.roles.forEach((role)=>(
            checkedInitialState[role.name] = false
        ))
    ))
    const [rolesChecked, setRolesChecked] = useState(checkedInitialState)
    
    const [save, dispatchSave] = useReducer(
        decisionReducer,
        {isLoading: false, isError: false, finished:false}
    )

    const validateValue = (value) => {
        if ([""," "].includes(value.replace(/\s+/g, ' ').trim())) return false
        else return true
    }

    const {
        value: groupNameValue,
        isValid: groupNameIsValid,
        hasError: groupNameHasError,
        valueChangeHandler: groupNameChangeHandler,
        inputBlurHandler: groupNameBlurHandler,
        reset: groupNameReset
    } = useInput(validateValue);
    
    const onSave = () => {
        let roles = []
        Object.entries(rolesChecked).forEach(([key,value])=>{
            if (value===true) {
                roles.push(key)
            }
        })
        const payload = {
            "name": formatGroupName(groupNameValue),
            "roles": roles
        }
        console.log(payload)
        dispatchSave({type:"send_request_init"})
        setTimeout(function () {
            axios
            .post('/dash/group',payload)
            .then(()=>{
                dispatchSave({type: "send_request_success"})
                props.onUpdate()
                props.onUpdateGroups()
                closeHandler()
            })
            .catch(error => {
                if ((error.response || {}).status === 403) dispatchSave({type: "send_request_failure_403"})
                else dispatchSave({type: "send_request_failure"})   
            })
        },1000)
    }

    const closeHandler = () => {
        groupNameReset()
        setRolesChecked(checkedInitialState)
        props.onClose()
    }

    return (
        <Dialog
        open={props.open}
        onClose={closeHandler}
        keepMounted={false}
        fullWidth={true}
        maxWidth={"md"}
        >
            <DialogTitle
                disableTypography
                className={['blueText', "subtitleSize", "dialogTitleWithIcon"].join(" ")}
            >
                {t("create_group")}
                <IconButton onClick={closeHandler}>
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent >
                <div style={{display:"flex", flexDirection:"column", marginBottom:"30px"}}>
                    <div style={{display:"flex", alginItems:"center", justifyContent:"space-between", marginBottom:"10px"}}>
                        <FormField
                            labelValue={"group_permission_name"}
                            onChange={groupNameChangeHandler}
                            onBlur={groupNameBlurHandler}
                            fieldValue={removeSpecialCharacters(groupNameValue)}
                            hasError={groupNameHasError}
                            errorMessage={"group_permission_name_error_message"}
                            inputStyle={{padding:"10px"}}
                        />
                        <Button
                            button_option={groupNameIsValid?"standard":"standard_disabled_blue"}
                            onClick={groupNameIsValid?() => onSave():null}
                            buttonLabel={t("create_group")}
                            buttonStyle={{minWidth:"200px", margin: "auto 0px"}}
                            isLoading={save.isLoading}
                        />
                    </div>
                    <div className="normalText normalMediumSize bold">
                        {t("group_permissions")}
                    </div>
                    <div className="labelText normalSmallSize">
                        {t("group_permissions_description")}
                    </div>
                    {allowedRolesGroups.map((rolesGroup) =>
                        <RolesGroupPermissions
                            key={rolesGroup.manager_role}
                            rolesGroup={rolesGroup}
                            rolesChecked={rolesChecked}
                            setRolesChecked={setRolesChecked}
                        />
                    )}
                </div>
            </DialogContent>
        </Dialog>
    );
};
export default CreateGroupPermissions;
