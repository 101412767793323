import React, { useState, useEffect } from "react"
import ProcessesTable from "./ProcessesTable/ProcessesTable"
import Collapse from "@material-ui/core/Collapse"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp"
import IconButton from "@material-ui/core/IconButton"
import { getCardIcon } from "../../../utils/utils"
import {getCardIconCredit} from "../../../utils/utils"

function ProcessesCard(props) {

    const {isQuod, processes, isPDF} = props
    const [expanded, setExpanded] = useState(false)
    const [firstTime, setFirstTime] = useState(true)

    let initialExpand = processes.flag === "positive" ? false : true
    if (initialExpand !== expanded && firstTime === true) {
        setExpanded(initialExpand)
    }

    const handleExpand = (event) => {
        event.stopPropagation()
        setFirstTime(false)
        setExpanded(!expanded)
    }

    useEffect(()=>{
        if(isPDF){
            setExpanded(true)
        }
    }, [isPDF])

    return (
        isQuod ? (
            <div className="avoidPageBreakInside analysisCard">
                <div style={{ display: "flex" }}>
                    <div
                        className={["blueText", "subtitleSize", "bold"].join(" ")}
                        style={{ margin: "auto 0px" }}
                    >
                        Processos Judiciais
                    </div>
                    <div style={{ margin: "auto 10px" }}>
                        {getCardIconCredit(processes.has_lawsuits)}
                    </div>
                    {processes.details.length === 0 ? null :
                        <IconButton style={{ marginLeft: "auto" }} onClick={handleExpand}>
                            {expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                        </IconButton>
                    }
                </div>
                {processes.details.length === 0 ? null :
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <div
                            className={["normalText", "normalMediumSize", "internalCardContainer"].join(" ")}
                        >
                            <ProcessesTable processes_data={processes.details} isQuod={true} />
                        </div>
                    </Collapse>
                }
            </div>
        ) : (
            <div className="avoidPageBreakInside analysisCard">
                <div style={{ display: "flex" }}>
                    <div
                        className={["blueText", "subtitleSize", "bold"].join(" ")}
                        style={{ margin: "auto 0px" }}
                    >
                        Processos Judiciais
                    </div>
                    <div style={{ margin: "auto 10px" }}>
                        {getCardIcon(processes.flag)}
                    </div>
                    {processes.data.length === 0 ? null :
                        <IconButton style={{ marginLeft: "auto" }} onClick={handleExpand}>
                            {expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                        </IconButton>
                    }
                </div>
                {processes.data.length === 0 ? null :
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <div
                            className={["normalText", "normalMediumSize", "internalCardContainer"].join(" ")}
                        >
                            <ProcessesTable processes_data={processes.data} />
                        </div>
                    </Collapse>
                }
            </div>
        )
    )
}


export default ProcessesCard