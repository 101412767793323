import React from 'react'
import {getFraudColor, getFraudStatus, getFraudStatusIcon, getSignalsFlagIcon, getDecisionFlagIcon} from "../../utils/utils"

function Summary(props) {

    let {fraud_events} = props.transaction

    if (fraud_events == null || fraud_events.length === 0) {
        return(
            <div className="analysisCard">
                <div style={{display: "flex", flexGrow:"1"}}>
                    <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                        Informação Indisponível
                    </div>
                </div>
            </div>
        )
    }

    let {decision_metadata} = fraud_events[0]

    if (decision_metadata == null) {decision_metadata = {}}

    const renderDecision = () => {
        if (decision_metadata.signals === null || decision_metadata.signals === undefined) {
            if (decision_metadata) {
                return (
                    <div className="internalCardContainer">
                        <div style={{display: 'flex', margin: '5px 20px'}}>
                            <div style={{display: "flex", margin: "auto 0px"}}>
                                {getDecisionFlagIcon(props.transaction.fraud_status)}
                            </div>
                            <div className={["normalText", "normalSmallSize", "labelSpace"].join(" ")} style={{marginTop:"auto",marginBottom:"auto",}}>
                                {decision_metadata.reason_description}
                            </div>
                        </div>
                    </div>
                )
            } else {
                return <></>
            }
        } else if (decision_metadata.signals.length > 0) {
            return (
                <div className="internalCardContainer">
                    {decision_metadata.signals.map((signal, index) => (
                        <div key={index} style={{display: "flex", margin: "5px 20px"}}>
                            <div style={{display:"flex", margin:"auto 0px"}}>
                                {getSignalsFlagIcon(signal.flag)}
                            </div>
                            <div className={["normalText", "normalSmallSize", "labelSpace"].join(" ")} style={{marginTop:"auto",marginBottom:"auto",}}>
                                {signal.description}
                            </div>                         
                        </div>
                    ))}
                </div>
            )
        }
        return <></>
    }
    
    return (
        <div className="analysisCard">
            <div className="internalCardContainer" style={{display: "flex"}}>
                <div className="circle" style={{backgroundColor: getFraudColor(props.transaction.fraud_status)}}>
                    {getFraudStatusIcon(props.transaction.fraud_status)}
                </div>
            </div>
            <div className="internalCardContainer">
                <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{display: "flex", margin: "5px auto"}}>
                        <div className={["normalText", "normalMediumSize", "noWrap"].join(" ")}>
                            Decisão:
                        </div>
                        <div 
                            className={["labelText", "normalMediumSize", "labelSpace", "bold"].join(" ")} 
                            style={{color:getFraudColor(props.transaction.fraud_status)}}
                        >
                            {getFraudStatus(props.transaction.fraud_status)}
                        </div>
                    </div>
                </div>

            </div>
            {renderDecision()}
        </div>
    )
}

export default Summary