import React, {useContext} from "react";
import FilterBox from "../../../Utils/FilterBox/FilterBox"
import { getLinkKeyTypesFromEventGroup } from "../../utils/utilsAlert"
import {whitelist_ck_show_banking_alert_event_type} from '../../utils/utils'
import AuthContext from "../../../../context/auth-context";
import CompaniesContext from "../../../../context/companies-context"

function Filter(props) {
	
    const { setPageParams, eventGroup } = props

    let company_key = useContext(AuthContext).user_data.company_key 
    

    let pixCompanies = useContext(CompaniesContext)?.pix_companies?.companies ?? []
    let billPaymentCompanies = useContext(CompaniesContext)?.bill_payment_companies?.companies ?? []
    let bankslipCompanies = useContext(CompaniesContext)?.bankslip_companies?.companies ?? []
    let wireTransferCompanies = useContext(CompaniesContext)?.wire_transfer_companies?.companies ?? []
    let withdrawalCompanies = useContext(CompaniesContext)?.withdrawal_companies?.companies ?? []

    let bankingCompanies = [
        ...pixCompanies, 
        ...billPaymentCompanies, 
        ...withdrawalCompanies, 
        ...bankslipCompanies, 
        ...wireTransferCompanies
    ]

    // solução temporária para renderizar os events_type do Máxima. Futuramente, renderizar events-type dinamicamente. 
    const alert_event_type = [ 
        ["Boleto", "bankslip"],
        ["Pagamento de Contas", "bill_payment"],
        ["Transferencia Bancária", "wire_transfer"],
        // ["Saque", "withdrawal"], //Máxima não tem
        ["Transação PIX", "pix_transaction"],
        // ["Operação na Dict", "pix_dict_operation"], //Máxima não tem
    ]

    let showEventTypeFilter = whitelist_ck_show_banking_alert_event_type.includes(company_key)

    let filterTypes = {
        alert_status: {
            type: "enum",
            enumList: [
                ["Em Análise", "in_manual_analysis"],
                ["Tratado - Verdadeiro Positivo", "solved_true_positive"],
                ["Tratado - Falso Positivo", "solved_false_positive"],
            ],
            disabled: false,
            validation:false,
            description: "Status do Alerta"
        },
        // risk_level: { // TEMPORARY SOLUTION - sql query is not working. 
        //     type: "enum",
        //     enumList: [
        //         ["Baixo Risco", "low"],
        //         ["Médio Risco", "medium"],
        //         ["Alto Risco", "high"],
        //         ["Crítico", "critical"]
        //     ],
        //     disabled: false,
        //     validation:false,
        //     description: "Risco"
        // },
        irregularity_type:{
            type: "enum",
            enumList: [
                ["Fraude", "fraud"],
                ["Compliance", "compliance"]
            ],
            disabled: false,
            validation:false,
            description: "Tipo de Irregularidade"
        },
        link_key_type:{
            type: "enum",
            enumList: getLinkKeyTypesFromEventGroup(eventGroup),
            disabled: false,
            validation:false,
            description: "Tipo de Entidade"
        },
        link_key:{
            type: "string",
            disabled: false,
            validation:false,
            description: "Chave do Alerta"
        },
        alert_date: {
            type: "date",
            disabled: false,
            validation:false,
            validation_rule: null,
            description: "Data"
        },
        minimum_triggers: {
            type: "numeric",
            disabled: false,
            validation: false,
            validation_rule: null,
            description: "Mínimo de Gatilhos"
        }
    }

    let link_key_CPF_CNPJ = {
        type: "brazilian_document_numbers",
        disabled: false,
        validation:true,
        validation_rule: {type: "multiple_equal", values:[14,18]},
        description: "Chave do Alerta"
    }

    if (eventGroup === "banking"){
        filterTypes.link_key = link_key_CPF_CNPJ
    }
    
    let event_type = {
        type: "enum",
        enumList: [...alert_event_type],
        disabled: false,
        validation:false,
        description: "Tipo do Evento"
    }
    
    if (eventGroup === "banking" && showEventTypeFilter){
        filterTypes["event_type"] = event_type
    }    

    if (bankingCompanies){
        let enumList = bankingCompanies
                            .sort((a, b) => a.name === b.name ? 0 : a.name > b.name ? 1 : -1)
                            .map(company => {return [company.name, company.company_key]})    

        filterTypes['company_key'] = {
            type: "enum",
            enumList: [
                ["Nenhum", ""],
                ...enumList
            ],  
            disabled: false,
            validation:false,
            description: "Companhia"
        }
    
    }
    
    return (
        <FilterBox 
            setPageParams={setPageParams}
            filterTypes={filterTypes}
            local_storage_object_preffix={props.local_storage_object_preffix}
        />
    )

}

export default Filter