import React, { useState, useEffect } from "react";
import ValidatedImage from "../../utils/ValidatedImage"
import { CircularProgress } from '@material-ui/core';
import RNEOCRDialog from "./RNEOCRDialog/RNEOCRDialog";
import axios from "axios";
import { useTranslation } from "react-i18next";

function NationalRegistryOfForeignersCard(props){

    const { t } = useTranslation()

    const { national_registry_of_foreigners } = props

    const [frontOCR, setFrontOCR] = useState(null)
    const [backOCR, setBackOCR] = useState(null)
    let [OCRPictureOpen, setOCRPictureOpen] = useState(false);

    const handleOCRPictureOpen = () => {
        setOCRPictureOpen(true);
    };
    const handleOCRPictureClose = () => {
        setOCRPictureOpen(false);
    };

    useEffect(() => {
        const timer = setTimeout(() => {

            if (national_registry_of_foreigners.ocr_front_key){
                axios.get('/dash/ocr/' + national_registry_of_foreigners.ocr_front_key).then(response => {
                    let front_ocr_data = response.data.processed_object
                    if (front_ocr_data == null) throw new Error()
                    setFrontOCR(front_ocr_data);             
                }).catch(error => {
                    setFrontOCR(undefined)
                })
            }
            if (national_registry_of_foreigners.ocr_back_key){
                axios.get('/dash/ocr/' + national_registry_of_foreigners.ocr_back_key).then(response => {
                    let back_ocr_data = response.data.processed_object
                    if (back_ocr_data == null) throw new Error()
                    setBackOCR(back_ocr_data);             
                }).catch(error => {
                    setBackOCR(undefined)
                })
            }
        }, 500);
        return () => {
            clearTimeout(timer)
        }
    }, [national_registry_of_foreigners])

    let getPictureContent = (picture) => {
        if (picture === undefined) {
            return (          
                <div 
                    className={["labelText", "normalMediumSize"].join(" ")} 
                    style={{textAlign:"center", margin:"auto"}}
                >
                    {t("information_unavailable")}
                </div>
            )
        } 
        else{
            return (
                <ValidatedImage
                    image_key={picture}
                    dash_api_endpoint_url={'/dash/ocr/image'}
                    imageStyle = {{margin:"auto", width:"100%", height:"100%",position:"absolute", top:"-9999px",bottom:"-9999px",left:"-9999px",right:"-9999px", borderRadius: "5px", objectFit:"cover"}}
                    imageAlt = {"Foto do Documento"}
                />
            );
        }
    };

    if (frontOCR === null || backOCR === null) {
        return (
            <div style={{minHeight: "250px"}} className="analysisCard">
                <div className={["blueText", "subtitleSize"].join(" ")}>Registro Nacional de Estrangeiros OCR</div>
                <div style={{display:"flex", flexGrow:"1"}}>
                    <CircularProgress style={{margin:"auto"}} />
                </div>
            </div>
        );    
    }
    else if (frontOCR === undefined || backOCR === undefined){
        return (
            <div style={{minHeight: "250px"}} className="analysisCard">
                <div className={["blueText", "subtitleSize"].join(" ")}>Registro Nacional de Estrangeiros OCR</div>
                <div style={{display:"flex", flexGrow:"1"}}>
                    <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                        {t("information_unavailable")}
                    </div>
                </div>
            </div>
        )
    }
    else {
        return (
            <div style={{minHeight: "450px"}} className="analysisCard">
                <div className={["blueText", "subtitleSize"].join(" ")}>Registro Nacional de Estrangeiros OCR</div>
                <div className="internalCardContainer" style={{display:"flex", flexGrow:"1", maxHeight:"450px",flexDirection: "row", justifyContent: "center"}}>
                    <div style={{display:"flex", width:"28%", maxHeight:"64vw", flexDirection: "column"}}>
                        <div style={{display:"block", width:"100%", height:"90%", margin: "0 auto", overflow:"hidden", position:"relative", alignItems: "center", justifyContent: "center",borderRadius: "5px" }}>
                            {national_registry_of_foreigners.ocr_front_key ? getPictureContent(national_registry_of_foreigners.ocr_front_key) : <></>}
                        </div>
                        {(national_registry_of_foreigners.ocr_front_key && national_registry_of_foreigners.ocr_back_key) ?
                        <div 
                            onClick={handleOCRPictureOpen}
                            className={["blueText", "normalSmallSize", "link"].join(" ")} 
                            style={{margin:"10px", textAlign:"center"}}
                        >
                            Ver fotos
                        </div>: <></>}
                    </div>
                    {(national_registry_of_foreigners.ocr_front_key || national_registry_of_foreigners.ocr_back_key) ? 
                        <RNEOCRDialog
                            open={OCRPictureOpen}
                            onClose={handleOCRPictureClose}
                            image_list={[national_registry_of_foreigners.ocr_front_key, national_registry_of_foreigners.ocr_back_key]}
                    />: null}
                </div>
            </div>
        );
    }
}

export default NationalRegistryOfForeignersCard