import React from "react";
import DataField from "../../utils/DataField";
import {
  titleCase,
  formatAddress,
  formatPhone,
  formatAsMoney,
} from "../../utils/utils";
import { getSalesChannel } from "../../utils/utilsTransaction";
import ErrorBoundary from "../../../Utils/ErrorBoundary";
import FaceReconCard from "../FaceReconCard/FaceReconCard";

function ClientCard(props) {
  let { transaction } = props;

  return (
    <div className="analysisCard">
      <div className={["blueText", "subtitleSize"].join(" ")}>Cliente</div>
      <div
        className="internalCardContainer"
        style={{ display: "flex", flexDirection: "column" }}
      >
        {transaction.face_recognition_key && (
          <ErrorBoundary>
            <FaceReconCard
              name={"client_selfie"}
              registration_key={transaction.face_recognition_key}
            />
          </ErrorBoundary>
        )}
        <DataField
          title={"ID do Cliente:"}
          label={(transaction.client || {}).id}
          size={"normalMediumSize"}
          titleNoWrap={true}
          margin="5px"
        />
        <DataField
          title={"Nome:"}
          label={titleCase((transaction.client || {}).name, null)}
          size={"normalMediumSize"}
          titleNoWrap={true}
          margin="5px"
        />
        <DataField
          title={
            (transaction.client || {}).type === "natural_person"
              ? "CPF:"
              : "CNPJ:"
          }
          label={(transaction.client || {}).document_number}
          size={"normalMediumSize"}
          titleNoWrap={true}
          margin="5px"
        />
        <DataField
          title={"Telefone:"}
          label={formatPhone((transaction.client || {}).phone, null)}
          size={"normalMediumSize"}
          titleNoWrap={true}
          margin="5px"
        />
        <DataField
          title={"Endereço:"}
          label={formatAddress((transaction.client || {}).address, null)}
          size={"normalMediumSize"}
          titleNoWrap={true}
          margin="5px"
        />
        <DataField
          title={"Canal de Venda:"}
          label={getSalesChannel(
            (transaction.client || {}).sales_channel
          )}
          size={"normalMediumSize"}
          titleNoWrap={true}
          margin="5px"
        />
        <DataField
          title={"Segmento:"}
          label={(transaction.client || {}).segment}
          size={"normalMediumSize"}
          titleNoWrap={true}
          margin="5px"
        />
        {(transaction.client || {}).monthly_income && (
          <DataField
            title={"Renda Mensal:"}
            label={formatAsMoney(
              (transaction.client || {}).monthly_income
            )}
            size={"normalMediumSize"}
            titleNoWrap={true}
          />
        )}
        {(transaction.client || {}).declared_assets && (
          <DataField
            title={"Bens Declarados:"}
            label={formatAsMoney(
              (transaction.client || {}).declared_assets
            )}
            size={"normalMediumSize"}
            titleNoWrap={true}
          />
        )}
      </div>
    </div>
  );
}

export default ClientCard;
