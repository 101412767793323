import React from "react";
import { useTranslation } from "react-i18next";
import { formatBoavistaData } from "../../../../utils/utils";

function BoavistaSummaryDateAmountCard(props) {

    const { description, date, amount } = props

    const amount_description = description + '_amount'
    const date_description = description + '_date'

    const { t } = useTranslation()

    return (
        <div>
            <div 
                className={["normalText","analysisCardSummaryLargeSize","analysisCardSummaryColor", "alignCenter"].join(" ")}
                style={{padding:"10px"}}
            >
                {formatBoavistaData(amount_description, t(amount))}
            </div>
            <div className={["normalText","normalMediumSize","analysisCardSummaryColor", "alignCenter"].join(" ")}>
                {t(description)}
            </div>
            <div className={["normalText","normalMediumSize","analysisCardSummaryColor", "alignCenter"].join(" ")}>
                {t("date")+": "+formatBoavistaData(date_description, t(date))}
            </div>
        </div>
    )
}


export default BoavistaSummaryDateAmountCard