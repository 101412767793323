import React, { useState } from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import Tooltip from '@material-ui/core/Tooltip'
import { useTranslation } from "react-i18next";

function SingleSelectDropdown(props) {

  const { groups, optionTransform, selected, initialSelection } = props

  const { t } = useTranslation()

  const [expanded, setExpanded] = useState(false)

  const changeHandler = (event) => {
    if (props.setSelected) {
      props.setSelected(event.target.value)
    } else {
      props.selectedHandler(event.target.value)
    }
    setExpanded(false)
  }

  const noGroupChoosenObject = {
    name: initialSelection,
    roles: [],
    members: [],
    manager_has_permition: true
  }
  const options = [noGroupChoosenObject,...groups||[].sort((a, b) => a === b ? 0 : a > b ? 1 : -1)]

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%", ...props.additionalStyles }}>
      <div
        className="singleSelectDropdownSelected"
        onClick={() => (setExpanded(!expanded))}
      >
        <div style={props.initial && selected === props.initial ? { fontWeight: "600" } : {}}>{optionTransform(selected)}</div>
        {expanded ?
          <ArrowDropUpIcon style={{ color: "#878787", width: "18px", height: "18px" }} /> :
          <ArrowDropDownIcon style={{ color: "#878787", width: "18px", height: "18px" }} />}
      </div>
      {expanded &&
        <ul className="multiSelectDropdownOptions" style={{ position: "" }}>
          {options
            .map(option => {
              return (
                option.manager_has_permition?
                  <li
                    className="multiSelectDropdownOption"
                    style={props.initial && option.name === props.initial ? { fontWeight: "600" } : {}}
                  >
                      <input
                        type="radio"
                        value={option.name}
                        checked={option.name === selected}
                        className="multiSelectDropdownCheckbox"
                        onChange={(event) => { changeHandler(event) }}
                      ></input>
                      <span value={option.name} className={option.manager_has_permition?null:"gray"}>{optionTransform(option.name)}</span>
                  </li>
                  :
                  <Tooltip 
                    key={option.name} 
                    title={<p className="tooltipText">{t("not_manager_tooltip")}</p>}
                    enterDelay={500}
                    leaveDelay={200}
                    placement="left"
                  >
                    <li
                      className="multiSelectDropdownOption"
                      style={props.initial && option.name === props.initial ? { fontWeight: "600" } : {}}
                      disabled={true}
                    >
                        <input
                          type="radio"
                          value={option.name}
                          checked={option.name === selected}
                          className="multiSelectDropdownCheckbox"
                          onChange={(event) => { changeHandler(event) }}
                          disabled={true}
                        ></input>
                        <span value={option.name} className={option.manager_has_permition?null:"gray"}>{optionTransform(option.name)}</span>
                    </li>
                  </Tooltip>
              )
            })}
        </ul>}
    </div>
  )
}

export default SingleSelectDropdown;