import React, {
  useEffect,
  useCallback,
  useContext,
  useReducer,
  useState,
  useRef,
} from "react"
import { dataFetchReducer } from "../Utils/requestUtils"
import { CircularProgress } from "@material-ui/core"
import axios from "axios"
import ErrorBoundary from "../Utils/ErrorBoundary"
import { useHistory } from "react-router-dom"
import AuthContext from "../../context/auth-context"
import CompaniesContext from "../../context/companies-context"
import TextField from "@material-ui/core/TextField"
import MenuItem from "@material-ui/core/MenuItem"
import { generateDashboards } from "./utils"
import FilterBox from "./FilterBox/FilterBox"

function Dashboard(props) {
  let history = useHistory()
  let user_data = useContext(AuthContext).user_data

  let business_group_companies = useContext(CompaniesContext).onboarding_list
  let business_group_companies_credit_analysis = useContext(CompaniesContext).credit_analysis_companies
  
  let companies = [
    { company_key: user_data.company_key, company_name: "Este cliente" },
  ]

  let companies_credit_analysis = [
    { company_key: user_data.company_key, company_name: "Este cliente" },
  ]

  if (business_group_companies !== null) {
    companies = business_group_companies.companies
  }

  if (business_group_companies_credit_analysis !== null) {
    companies_credit_analysis = business_group_companies_credit_analysis.companies
  }

  if (
    !user_data.roles.includes("read_onboarding_dashboard") &&
    !user_data.roles.includes("read_onboarding_filtered_company_dashboard") &&
    !user_data.roles.includes("read_credit_analysis_filtered_company_dashboard") &&
    !user_data.roles.includes("rental_agreements_dashboard_viewer") &&
    !user_data.roles.includes("read_pix_transaction_dashboard") &&
    !user_data.roles.includes("read_wire_transfer_dashboard") &&
    !user_data.roles.includes("read_bankslip_dashboard") &&
    !user_data.roles.includes("read_pix_dict_operation_dashboard") &&
    !user_data.roles.includes("read_bill_payment_dashboard") &&
    !user_data.roles.includes("read_alerts_dashboard")
  ) {
    history.push("")
  }

  const [pageState, dispatchPageState] = useReducer(dataFetchReducer, {
    fetchedData: null,
    isLoading: true,
    isError: false,
  })

  let dashboards = generateDashboards(
    user_data.roles,
    user_data.company_key,
    companies, 
    companies_credit_analysis,
    user_data.business_group_key
  )
  let dashboards_dict = dashboards.reduce(
    (a, x) => ({
      ...a,
      [x.enum]: x,
    }),
    {}
  )

  const [iFrameState, setIFrameState] = useState({
    iFrameRef: null,
    dashboardEnum: dashboards.length > 0 ? dashboards[0].enum : null,
    dashboard: dashboards.length > 0 ? dashboards[0] : null,
    iFrameLoaded: false,
  })

  const [iFrameParameters, setIFrameParameters] = useState({})

  let iFrameRef = useRef(null)

  let openMessageChannel = () => {
    const eventName = "establishMessageChannel"
    const payload = null
    const event = {
      eventName,
      clientType: "EMBEDDING",
      payload,
    }

    iFrameRef.current.contentWindow.postMessage(event, pageState.fetchedData["url"])
  }

  let postParameters = (parameters) => {
    const eventName = "updateParameterValues"
    const payload = { parameters }
    const event = {
      eventName,
      clientType: "EMBEDDING",
      payload,
    }

    iFrameRef.current.contentWindow.postMessage(event, pageState.fetchedData["url"])
  }

  const buildAndPostParameters = (parameters) => {
    let parametersPostObject = {}
    for (let i = 0; i < Object.keys(parameters).length; i++) {
      let parameter_name = Object.keys(parameters)[i]
      let parameter_value = parameters[parameter_name]

      if (iFrameState.dashboard.filters[parameter_name].type === "date_range") {
        if (parameter_value.startDate == null) {
          parametersPostObject[parameter_name + "StartDate"] = null
        } else {
          parametersPostObject[parameter_name + "StartDate"] = parameter_value.startDate.format()
        }

        if (parameter_value.endDate == null) {
          parametersPostObject[parameter_name + "EndDate"] = null
        } else {
          parametersPostObject[parameter_name + "EndDate"] = parameter_value.endDate.format()
        }
      } else {
        parametersPostObject[parameter_name] = parameter_value
      }
    }
    postParameters(parametersPostObject)
  }

  let updateAllFilters = (allParameters) => {
    buildAndPostParameters(allParameters)
    setIFrameParameters(allParameters)
  }

  let updateFilters = (filter_name, data) => {
    let newParameters = { ...iFrameParameters }
    newParameters[filter_name] = data
    buildAndPostParameters(newParameters)
    setIFrameParameters(newParameters)
  }

  const handleIFrame = () => {
    openMessageChannel()
    setIFrameParameters({})

    let defaultParameters = {}
    for (let i = 0; i < Object.keys(dashboards_dict[iFrameState.dashboardEnum].filters).length; i++) {
      let filter_name = Object.keys(dashboards_dict[iFrameState.dashboardEnum].filters)[i]
      let defaultValue = dashboards_dict[iFrameState.dashboardEnum].filters[filter_name].defaultValue

      if (!(defaultValue == null)) {
        defaultParameters[filter_name] = defaultValue
      }
    }

    updateAllFilters(defaultParameters)

    setIFrameState({
      iFrameRef: iFrameRef.current,
      dashboardEnum: iFrameState.dashboardEnum,
      dashboard: iFrameState.dashboard,
      iFrameLoaded: true,
    })
  }

  const renderSelector = (dashboards) => {
    if (dashboards.length < 2) return null
    else {
      return (
        <div className="filterContainer">
          <div className="filterDescription">Dashboard</div>
          <TextField
            style={{ textAlign: "center" }}
            onBlur={props.onBlur}
            onChange={(e) =>
              setIFrameState({
                iFrameRef: null,
                dashboardEnum: e.target.value,
                dashboard: dashboards_dict[e.target.value],
                iFrameLoaded: false,
              })
            }
            className="filterTextbox"
            size="small"
            fullWidth
            value={iFrameState.dashboardEnum}
            select
          >
            {dashboards.map((dashboard, index) => (
              <MenuItem key={index} value={dashboard.enum}>
                <em className="normalText">{dashboard.name}</em>
              </MenuItem>
            ))}
          </TextField>
        </div>
      )
    }
  }

  const doRequest = useCallback(() => {
    if (!iFrameState.dashboardEnum) return
    dispatchPageState({ type: "data_fetch_init" })
    axios
      .get("/dash/dashboards/" + iFrameState.dashboardEnum)
      .then((response) => {
        dispatchPageState({
          type: "data_fetch_success",
          payload: response.data,
        })
      })
      .catch((error) => {
        if (error.response.status === 403)
          dispatchPageState({ type: "data_fetch_failure_403" })
        else if (error.response.status === 404)
          dispatchPageState({ type: "data_fetch_failure_404" })
        else dispatchPageState({ type: "data_fetch_failure" })
      })
  }, [iFrameState.dashboardEnum])

  useEffect(() => {
    const timer_1 = setTimeout(() => {
      doRequest()
    }, 1000)
    return () => {
      clearTimeout(timer_1)
    }
  }, [doRequest])

  const renderFilters = () => {
    if (iFrameState.dashboard === null) return null
    else if (!iFrameState.iFrameLoaded) return null
    else if (iFrameState.dashboard.filters) {
      return (
        <div style={{ display: "flex" }}>
          <ErrorBoundary doNothing>
            <FilterBox
              filters={iFrameState.dashboard.filters}
              parameters={iFrameParameters}
              onChange={(filter_name, data) => {
                updateFilters(filter_name, data)
              }}
            />
          </ErrorBoundary>
        </div>
      )
    } else {
      return null
    }
  }

  const renderContent = () => {
    if (!iFrameState.dashboardEnum) {
      return (
        <div style={{ flexGrow: "1", display: "flex" }}>
          <p
            className={["blueText", "titleSize"].join(" ")}
            style={{ margin: "auto", textAlign: "center" }}
          >
            Você não possui permissões. Por favor contate seu administrador.
          </p>
        </div>
      )
    } else if (pageState.fetchedData) {
      return (
        <React.Fragment>
          <div style={{ display: "flex" }}>
            <ErrorBoundary doNothing>
              {renderSelector(dashboards)}
            </ErrorBoundary>
          </div>
          {renderFilters()}
          <div style={{ flexGrow: "1", display: "flex", flexDirection: 'column', height: '100vh' }}>
            <ErrorBoundary>
              <iframe
                title="dashboard"
                src={pageState.fetchedData["url"]}
                style={{ border: "none", flexGrow: "1" }}
                width="100%"
                height="100vh"
                locale="pt-BR"
                ref={iFrameRef}
                onLoad={handleIFrame}
              />
            </ErrorBoundary>
          </div>
        </React.Fragment>
      )
    } else if (pageState.isError) {
      return (
        <React.Fragment>
          <div style={{ display: "flex" }}>
            <ErrorBoundary doNothing>
              {renderSelector(dashboards)}
            </ErrorBoundary>
          </div>

          <div style={{ flexGrow: "1", display: "flex" }}>
            <p
              className={["blueText", "titleSize"].join(" ")}
              style={{ margin: "auto", textAlign: "center" }}
            >
              {pageState.errorMessage}
            </p>
          </div>
        </React.Fragment>
      )
    } else if (pageState.isLoading) {
      return (
        <div style={{ height: "55vh", display: "flex" }}>
          <CircularProgress style={{ margin: "auto" }} />
        </div>
      )
    } else return null
  }
  return (
    <div
      style={{
        width: "auto",
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div style={{ display: "flex" }}>
        <div>
          <p className={["blueText", "titleSize"].join(" ")}>DASHBOARD</p>
        </div>
      </div>
      {renderContent()}
    </div>
  )
}

export default Dashboard
