import React from 'react'
import Tooltip from "@material-ui/core/Tooltip";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import PublishIcon from '@material-ui/icons/Publish';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

export const colorGreen = '#73C580'
export const colorRed ='#D91A1A'
export const colorYellow = "#FFBB24"
export const colorOrange = '#F77B21'
export const colorVelvet ='#DD5C5C'
export const colorGrey = 'rgb(170, 167, 153)'

export const getBillPaymentStatusText = (status) => {
    switch(status){
        case "created":
            return "Transação Requisitada"
        case "completed":
            return "Transação Completada"
        default:
            return status
    }
}

export const getBillPaymentStatusColor = (status) => {
    switch(status){
        case "created":
            return colorYellow
        case "completed":
            return colorGreen
        default:
            return colorGrey
    }
}

export const getBillPaymentStatusIcon = (status, icon_size_class, showTooltip=false) => {
    let circle_size
    let icon_size
    let icon_component
    let icon_backgroud_color = getBillPaymentStatusColor(status)
    if (icon_size_class === "big"){
        circle_size = ""
        icon_size = "45px"
    }
    else if (icon_size_class === "small"){
        circle_size = "small"
        icon_size = "18px"
    }
    else throw new Error("Please a new size is needed for setup")
    switch(status){
        case "completed":
            icon_component = <AccountBalanceIcon  style={{width:icon_size, height:icon_size}}/>
            break       
        case "created":
            icon_component = <PublishIcon  style={{width:icon_size, height:icon_size}}/>
            break   
        default:
            icon_component = <HelpOutlineIcon  style={{width:icon_size, height:icon_size}}/>
            break   
    }
    if (showTooltip){
        return (
            <Tooltip title={<p className="tooltipText">{getBillPaymentStatusText(status)}</p>}>
                <div className={["circle", circle_size].join(" ")} style={{backgroundColor: icon_backgroud_color}}>
                    {icon_component}
                </div>
            </Tooltip>
        )
    }
    else{
        return (
            <div className={["circle", circle_size].join(" ")} style={{backgroundColor: icon_backgroud_color}}>
                {icon_component}
            </div>
        )
    }
}

export const getReasonDescription = (transaction) => {
    let last_index = (transaction.analysis_events || []).length ? ((transaction.analysis_events || []).length - 1) : 0
    return  ((((transaction.analysis_events || [])[last_index] || {}).decision_metadata || {}).reason_description || "-")
}
