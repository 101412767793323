import React, { useContext } from "react";
import FilterBox from "../../../Utils/FilterBox/FilterBox"
import CompaniesContext from "../../../../context/companies-context"
import { useTranslation } from "react-i18next";

function Filter(props) {
	
    const { setPageParams, pageParams, isAbasteceAi, clicked, setClicked } = props
    const { t } = useTranslation()

    let companies = useContext(CompaniesContext).onboarding_list

    let filterTypes = {
        document_number: {
            type: "masked_string",
            maskType:"99.999.999/9999-99",
            disabled: false,
            validation:true,
            validation_rule: {type: "equal", value:18},
            description: "CNPJ"
        },
        registration_date: {
            type: "date",
            disabled: false,
            validation:false,
            description: "Data"
        },
        external_id: {
            type: "string",
            disabled: false,
            validation:false,
            description: "ID"
        },
        status: {
            type: "enum",
            enumList: [
                ["Em Processamento", "pending"],
                ["Em Análise Manual", "in_manual_analysis"],
                ["Aprovado Manualmente", "manually_approved"],
                ["Reprovado Manualmente", "manually_reproved"],
                ["Aprovado Automaticamente", "automatically_approved"],
                ["Reprovado Automaticamente", "automatically_reproved"],
                ["Em Espera", "on_hold"]
            ],
            disabled: false,
            validation:false,
            description: "Status"
        }
    }
    
    const handleClicks = (status) => {
        if (clicked[status]===false) {
            setPageParams({ page: pageParams.page, filters: { "status": status } })
        } else {
            setPageParams({ page: pageParams.page, filters: {} })
        }
        
        if (!clicked[status]===true) {
            const allClickedFalse = { on_hold:false, in_manual_analysis:false }
            setClicked({...allClickedFalse,[status]:!clicked[status]})
        } else {
            setClicked({...clicked,[status]:!clicked[status]})
        }
    }

    //TODO JUST ADD IF REQUEST COMPLETES BEFORE LOCKSINBOX FIRST MOUNT
    //Maybe if you clean storage inside this if block it will work as expected
    if (companies){
        let enumList = companies.companies.sort((a, b) => a.name === b.name ? 0 : a.name > b.name ? 1 : -1).map(company =>{
            return [company.name, company.company_key]
        })
        filterTypes['company_key'] = {
            type: "enum",
            enumList: [
                ["Nenhum", ""],
                ...enumList
            ],  
            disabled: false,
            validation:false,
            description: "Cliente"
        }
    }

    return (
        <>
            <FilterBox 
                setPageParams={setPageParams}
                filterTypes={filterTypes}
                local_storage_object_preffix={props.local_storage_object_preffix}
            />
            {!isAbasteceAi&&
            <div className="filterBoxContainer normalText bold normalSmallSize">
                <div
                    className={clicked.in_manual_analysis?"filterButtonSelected filterButton":"filterButton"}
                    onClick={() => handleClicks("in_manual_analysis")}
                >
                    {t("Em Análise Manual")}
                </div>
                <div
                    className={clicked.on_hold?"filterButtonSelected filterButton":"filterButton"}
                    onClick={() => handleClicks("on_hold")}
                >
                    {t("Em Espera")}
                </div>
            </div>}
        </>
    )

}

export default Filter
