import React from "react";

function Reason(props) {

  return (
      <div className="analysisCard">
        <div className={["blueText", "subtitleSize"].join(" ")}>Motivo</div>
        <div className="internalCardContainer" style={{display:"flex", flexGrow:"1"}}>
            <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                {props.reason}
            </div>
        </div>      
      </div>
  );
}

export default Reason;
