import React from 'react'
import peopleIcon from "../../../../../assets/cardEntryIcons/people.svg"
import TransactionHistoric from "./TransactionHistoric/TransactionHistoric"
import AlertsHistoric from "./AlertsHistoric/AlertsHistoric"

function MerchantCard(props) {

    let alert_triggers_transactions = []
    let alert_triggers_sms_validation = {}

    for (let trigger of props.alert.triggers){
        const { transaction_key} = trigger.transaction
        const send_validation = trigger.send_validation ? trigger.send_validation : false
        const validation_status = trigger.validation?.validation_status
        if(send_validation && validation_status){
            alert_triggers_sms_validation[transaction_key]={
                    validation_status: validation_status
                }
        }
        alert_triggers_transactions.push(transaction_key)
    }
    
    return (
        <div className="analysisCard">
            <div style={{display: "flex"}}>
                <img className="cardTitleIconStyle" src={peopleIcon} alt="peopleIcon Logo" />
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>Usuário | Históricos</span>
            </div>
            <div className="internalCardContainer">
                <div className={["blueText", "normalSmallSize"].join(" ")}>
                    Histórico de Transações
                </div>
                <div>
                    <TransactionHistoric alert={props.alert} link_key={[props.alert.cardholder_id,props.alert.bin,props.alert.last4]} alert_triggers_transactions={alert_triggers_transactions} alert_triggers_sms_validation={alert_triggers_sms_validation}/>
                </div>                
            </div>
            <div className="internalCardContainer">
                <div className={["blueText", "normalSmallSize"].join(" ")}>
                    Histórico de Alertas
                </div>
                <div>
                    <AlertsHistoric link_key={[props.alert.cardholder_id,props.alert.bin,props.alert.last4]}/>
                </div>                
            </div>
        </div>
    )
}

export default MerchantCard