import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {colorRed} from '../../utils/utils'
import InformationIcon from '@material-ui/icons/ErrorOutline';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  
function GeoShapeDialog(props){

  let { onClose, dialogTitle, type } = props

  const dialogMesage = (type) => {
    switch(type){
      case "exportCSV":
        return (
        <>
          <p>Listas contendo dados do tipo <b>Polígono Geográfico</b> não suportam exportação de dados via CSV.</p>
        </>
        )
      case "changeByCSV":
        return (
        <>
          <p>Listas contendo dados do tipo <b>Polígono Geográfico</b> não suportam alterações pela nossa plataforma.</p>
          <p>Para alterações na lista, favor utilizar nossa API.</p>
        </>
        )
      default:
        return
    }
  } 
  
  return(
      <Dialog 
      open={props.open} 
      onClose={onClose}
      disableBackdropClick
      maxWidth="lg"
      keepMounted={false}>
          <DialogTitle 
              className={['blueText', "subtitleSize"].join(" ")} 
              onClose={onClose}
          >
              {dialogTitle}
          </DialogTitle>
          <DialogContent >
              <div style={{width: "430px", minHeight:"217px", padding:"8px 24px"}}>
                  <div className="internalCardContainer" style={{display:"flex", height:"40%", justifyContent:"center"}}>
                      <InformationIcon style={{fontSize:"80px",fill: colorRed}}/>
                  </div>
                  <div className={["internalCardContainer", "normalMediumSize"].join(" ")} style={{display:"flex",flexDirection:"column", textAlign:"center", justifyContent:"center"}}>
                     {dialogMesage(type)}
                  </div>
              </div>
          </DialogContent>
      </Dialog>
  )
}

export default GeoShapeDialog