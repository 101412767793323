import React, { useReducer } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios"
import { CircularProgress } from '@material-ui/core';
import ConfirmationContent from "./ConfirmationContent"
import {decisionReducer} from "../../../../Utils/requestUtils"

function DeleteListDialog(props) {

    const [dialogState, dispatchDialogState] = useReducer(
        decisionReducer,
        {isLoading: false, isError: false, finished:false}
    )


    const handleConfirm = () =>{
        dispatchDialogState({type:"send_request_init"})
        let requestHeaders = {headers:{}}
        if (props.user_data.business_group_key) {
            requestHeaders = {headers:{company_key:props.list.company_key}}
        }
        setTimeout(function () {
            axios.delete('/dash/lists/list/' + props.list.list_key,requestHeaders).then(response => {
                dispatchDialogState({type:"send_request_success"})
              }).catch(error => {
                if ((error.response || {}).status === 403) dispatchDialogState({type: "send_request_failure_403"})
                else if ((error.response || {}).status === 409) dispatchDialogState({type: "send_request_failure_409"})  
                else dispatchDialogState({type: "send_request_failure"})    
              });
        }, 1000);
    }


    if (dialogState.finished && dialogState.isLoading){
        return (
            <Dialog 
                open={props.open} 
                onClose={props.handleUpdatePage} 
                keepMounted={false}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle style={{paddingBottom: "0px"}} className={["blueText", "normalMediumSize"].join(" ")}>Exclusão de Lista</DialogTitle>
                <DialogContent>
                    <div style={{display:"flex", width: "310px", height:"217px"}}>
                        <CircularProgress style={{margin:"auto"}} />
                    </div>
                </DialogContent>
            </Dialog>

        )
    }
    else if (dialogState.finished){
        let confirmationData = {    
            message: dialogState.message,
            success: !dialogState.isError
        }
        return (
            <Dialog 
                open={props.open} 
                onClose={props.handleUpdatePage} 
                keepMounted={false}
                disableBackdropClick
            >
                <DialogTitle style={{paddingBottom: "0px"}} className={["blueText", "normalMediumSize"].join(" ")}>Exclusão de Lista</DialogTitle>
                <DialogContent>
                    <ConfirmationContent onClose={props.handleUpdatePage} data={confirmationData} />
                </DialogContent>
            </Dialog>
        )
    }
    else {
        return (
            <Dialog 
            open={props.open} 
            onClose={props.onClose} 
            keepMounted={false}
            >
                <DialogTitle style={{paddingBottom: "0px"}} className={["blueText", "normalMediumSize"].join(" ")}>Exclusão de Lista</DialogTitle>
                <DialogContent>
                    <div style={{marginBottom:"25px", width: "310px"}}>
                        <div className="internalCardContainer">
                            <div    
                                className={["normalText", "normalMediumSize"].join(" ")}
                                style={{fontSize:"16px", margin:"10px 30px 30px 30px", textAlign:"center"}}
                            >
                                <p>Você tem certeza que deseja <b>excluir</b> esta lista?</p>
                            </div>
                        </div>
                        <div className="internalCardContainer" style={{display:"flex", justifyContent:"center"}}>
                            <div 
                                className={["button", "approved", "normalText", "normalMediumSize"].join(" ")}
                                style={{minWidth: "70px"}}
                                onClick={handleConfirm}
                            >
                                SIM 
                            </div>
                            <div 
                                className={["button", "reproved", "normalText", "normalMediumSize"].join(" ")}
                                style={{minWidth: "70px"}}
                                onClick={props.handleUpdatePage}
                            >
                                NÃO
                            </div>
                      </div>
                  </div>
              </DialogContent>
            </Dialog>
        )
    }
}

export default DeleteListDialog;