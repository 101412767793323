import React , { useState } from 'react';
import SectionContainer from '../../../@GeneralComponents/SectionContainer';
import ErrorBoundary from '../../../Utils/ErrorBoundary';
import BaseCheckbox from '../../../@GeneralComponents/BaseCheckbox';

function RolesGroupPermissions(props) {

  const { rolesGroup, rolesChecked, setRolesChecked } = props

  const [expandedContainer, setExpandedContainer] = useState(false);
  const expandedContainerHandler = event => {
    event.stopPropagation();
    setExpandedContainer(!expandedContainer);
  };

  const rolesCheckedHandler = (key) => {
    if (rolesChecked[key]===true){
        setRolesChecked({...rolesChecked,[key]:false})
    } else {
        setRolesChecked({...rolesChecked,[key]:true})
    }
  }

  return (
    <SectionContainer
      sectionClasses="internalSectionContainer"
      sectionTitle={rolesGroup.name}
      sectionTitleStyle={{fontWeight:"400"}}
      sectionSubtitle={rolesGroup.description}
      sectionSubtitleClassName="labelText normalSmallSize"
      isCollape={true}
      collapeState={expandedContainer}
      collapeStateHandler={expandedContainerHandler}
    >
      <ErrorBoundary>
        <div style={{display:"flex", flexDirection:"column"}}>
          {rolesGroup.roles.map((role)=>(
            <BaseCheckbox
              key={`${rolesGroup.manager_role}_${role.name}`}
              id={role.name}
              checked={rolesChecked[role.name]}
              onChange={() => rolesCheckedHandler(role.name)}
              label={role.description}
              checkboxStyles={{display:"flex", alignItems: "center", margin:"5px 0px"}}
              inputStyles={{minWidth:"18px", minHeight:"18px", margin:"2px"}}
            />
          ))}
        </div>
      </ErrorBoundary>
    </SectionContainer>
  )
}

export default RolesGroupPermissions;