import React, { useState } from 'react';
import * as S from './styles';

const Input = (props) => {
    const [focus, setFocus] = useState(props.value ? 'focused' : 'unfocused');
    
    const handleFocus = () => {
        setFocus('focused');
    };
    
    const handleBlur = () => {
        setFocus('unfocused');
    };
    
    const handleChange = (e) => {
        if (props.setValue) props.setValue(e.target.value);
    };

    
    return (
        <S.Container>
            <S.Label $focusedcolor="#0b1f82" $focus={focus} $content={props.value}>
            {props.label}
            </S.Label>
            <S.Input
                $focusedcolor={"#0b1f82"}
                $contentcolor={"#000"}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={handleChange}
                type={props.type}
                disabled={props.disabled}
                value={props.value}
            />
      </S.Container>
    );
};

export default Input;