import React from 'react'
import getSymbolFromCurrency from 'currency-symbol-map'
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from '@material-ui/icons/Clear';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import redCross from '../../../assets/cardEntryIcons/redCross.svg';
import greenCheck from '../../../assets/cardEntryIcons/greenCheck.svg';

export const colorGreen = '#73C580'
export const colorRed ='#D91A1A'
export const colorYellow = "#FFBB24"
export const colorOrange = '#F77B21'
export const colorVelvet ='#DD5C5C'
export const colorBlue = "#0b1f82"
export const colorGrey = 'rgb(170, 167, 153)'

export const getTableRowClass = (rowIndex) => {
    if (rowIndex % 2 === 0) {
      return "even";
    }
  
    return "odd";
}

export const getAnalysisStatus = (status) => {
    switch(status){
        case "pending":
        case "in_manual_analysis":
          	return "Pendente";
        case "manually_approved":
            return ("Aprovado Manualmente");
        case "automatically_approved":
            return ("Aprovado Automaticamente");
        case "manually_reproved":
            return ("Reprovado Manualmente");
        case "automatically_reproved":
            return ("Reprovado Automaticamente");
        case "approved_by_time":
            return "Aprovado automaticamente por tempo";
        case "repproved_by_time":
            return "Reprovado automaticamente por tempo";
        default:
            return status;
    }
}
  
export const getAnalysisStatusSiglum = (status) => {
    switch(status){
        case "pending":
        case "in_manual_analysis":
          	return "P";
        case "manually_approved":
        case "manually_reproved":
            return "M";
        case "automatically_approved":
        case "automatically_reproved":
            return "A";
        case "approved_by_time":
        case "repproved_by_time":
            return "T";
        default:
            return "?";
    }
}

export const setAllowChanges = (status, roles) => {
    if (!roles.includes("operations_analyzer")){
        return false
    }
    switch(status){
        case "pending":
        case "in_manual_analysis":
            return true
        default:
            return false
    }
}

export const getAnalysisStatusColor = (status) => {
    switch(status){
        case "automatically_approved":
        case "manually_approved":
        case "approved_by_time":
            return colorGreen
        case "automatically_reproved":
        case "manually_reproved":
            return colorRed
        default:
            return colorGrey
    }
}

export const formatDecision = (decision) => {
    let color
    let text
    let icon
    if (decision==="automatically_reproved" || decision==="manually_reproved" || decision==="repproved_by_time") {
        color = colorRed
        text = "REPROVADO"
        icon = <ClearIcon style={{width:"50px", height:"50px", margin:"auto 0px"}}/>
    }
    else if (decision==="pending" ){
        color = colorBlue
        text = "EM ANÁLISE"
        icon = <ErrorOutlineIcon style={{width:"50px", height:"50px", margin:"auto 0px"}}/>
    }
    else if (decision==="in_manual_analysis") {
        color = colorBlue
        text = "EM ANÁLISE MANUAL"
        icon = <ErrorOutlineIcon style={{width:"50px", height:"50px", margin:"auto 0px"}}/>
    } 
    else if (decision==="automatically_approved" || decision==="manually_approved" || decision==="approved_by_time"){
        color = colorGreen
        text = "APROVADO"
        icon = <CheckIcon style={{width:"50px", height:"50px", margin:"auto 0px"}}/>
    }
    else{
        color = colorGrey
        text = "INDISPONÍVEL"
        icon = <HelpOutlineIcon style={{width:"50px", height:"50px", margin:"auto 0px"}}/>
    }
    return ({color:color, text:text, icon:icon})
}

export const formatAsMoney = (value, nullCase=null) => {
    if (value == null) return nullCase
    return ("R$ " + (value/100).toLocaleString('de-DE', {minimumFractionDigits: 2}))    
}

export const formatAsMoneyUniversal = (currency, value, nullCase=null) => {
    let symbolFromCurrency= getSymbolFromCurrency(currency)
    if (value == null) return nullCase
    return (currency + " " + symbolFromCurrency + " " + (value/100).toLocaleString('de-DE', {minimumFractionDigits: 2}))    
}

export const titleCase = (str, nullCase) => {
    if (str == null) {
      return nullCase
    }
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  };

export const formatAddress = (merchant, nullCase) => {
    if (merchant == null) return nullCase
    let street = titleCase(merchant.street, "")
    let city = titleCase(merchant.city, "")
    let number = merchant.number ? merchant.number : ""
    let uf = merchant.uf ? merchant.uf : ""
    let country = merchant.country === "BRA" ? ", Brasil" : `, ${merchant.country}` 

    let addressString = number !== "" ? street + ", " + number : street
    addressString = city !== "" ? addressString +  ", " + city : addressString
    addressString = uf !== "" ? addressString +  " - " + uf : addressString
    addressString = addressString + country
    return (addressString)
  }
  
  export const formatPhone  = (phoneObj,nullCase) => {
      if (phoneObj == null) return nullCase
  
      let ddiString = phoneObj.internation_dial_code == null ? "" : phoneObj.internation_dial_code + " "
      let dddString = phoneObj.area_code == null ? "" : "(" + phoneObj.area_code + ") "
      let numberString = phoneObj.number == null ? "" : phoneObj.number
  
      return ddiString + dddString + numberString
  
  }

  export const getConfirmationIcon = (status) => {
    if (status){
        return (<CheckIcon className="fraudStatusIcon" style={{color: colorGreen, width:"40px", height:"40px"}}/>)
    }
    else{
        return (<ClearIcon className="fraudStatusIcon" style={{color: colorRed, width:"40px", height:"40px"}}/>)
    }
  }

  export const getAlertsFlagIcon = (flag, margin=null) => {
    let margin_ = "auto"
    if (margin !=null ){
        margin_ = margin
    }
    switch(flag){
        case "positive":
            return (<CheckIcon style={{verticalAlign: "middle", width:"20px", height:"20px", color:colorGreen, marginTop:"auto",marginBottom:"auto", marginRight:margin_,marginLeft:margin_}}/>)
        case "negative":
            return (<ErrorOutlineIcon style={{verticalAlign:"middle", width:"20px", height:"20px", color:colorRed, marginTop:"auto",marginBottom:"auto", marginRight:margin_,marginLeft:margin_}}/>)
        case "neutral":
        case "attention":
            return(<ErrorOutlineIcon style={{verticalAlign:"middle", width:"20px", height:"20px", color:colorYellow, marginTop:"auto",marginBottom:"auto", marginRight:margin_,marginLeft:margin_}}/>)
        default:
            return(<ErrorOutlineIcon style={{verticalAlign:"middle", width:"20px", height:"20px", color:colorGrey, marginTop:"auto",marginBottom:"auto", marginRight:margin_,marginLeft:margin_}}/>)
    }
  }
  export const getBoolIcon = (bool) => {
    if (bool == null) return null
    if (bool){
      return (
        <img src={greenCheck} alt="GreenCheck Logo" />      
      )
    }
    else {
      return (
        <img src={redCross} alt="redCross Logo" />    
      )
    }
  }

  export const getAlertsFlagColor = (flag) => {
    switch(flag){
        case "positive":
            return colorGreen
        case "negative":
            return colorRed
        case "attention":
        case "neutral":
            return colorYellow
        default:
            return colorGrey
    }
}

export const formatBureauInfo = (object, identifier) => {
    let formatFunction
    switch(identifier){
        case "endereços":
            formatFunction = formatAddress
            break
        case "telefones":
            formatFunction = formatPhone
            break
        default:
    }
    return (
        <div>
            Outros {identifier}:
            <ul>
                {object.map((item,index) => (
                    <li key={index}>
                        {formatFunction(item)} - {item.description}
                    </li>
                ))}
            </ul>
        </div>
    )
}