import React from 'react'
import { useTranslation } from "react-i18next";
import {GetAlertsComponent, comparisionOptions, translateType} from "./utils"
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from "@material-ui/core/Tooltip";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import HelpIcon from "@material-ui/icons/Help";

export function LessThanEqualBox (props) {
    const { t } = useTranslation();
    let {node} = props
    let {read_only} = props
    let {handleConfigurationOpen} = props
    let {handleNodeRemove} = props

    return (
        <div>
            {!read_only ?
            <div style={{display:"flex", alignItems: "center"}}>
                <div style={{display:"flex", width:"100%", cursor:"pointer", margin: "auto 0px"}} onClick={() => handleConfigurationOpen(node)}>
                    {t(node.node.description)}
                    {(node.node.alerts || []).length > 0?
                    <GetAlertsComponent alerts={node.node.alerts} />  : null} 
                </div>
                <IconButton style={{width: 32, height: 32}} aria-label="duplicate" onClick={() => props.handleNodeDuplication(node)}>
                        <ContentCopyIcon style={{width: 20, height: 20}} />
                    </IconButton>
                    <IconButton style={{width: 32, height: 32}} aria-label="close" onClick={() => handleNodeRemove(node)}>
                        <CloseIcon style={{width: 20, height: 20}} />
                    </IconButton>
            </div>
            :
            <div style={{display:"flex"}}>
                <div style={{display:"flex", width:"100%", cursor:"pointer", margin: "auto 0px"}} onClick={() => handleConfigurationOpen(node)}>
                    {t(node.node.description)}
                </div>
            </div>
            }
        </div>
    )
}

export function LessThanEqualConfiguration(props){
    const { t } = useTranslation();
    let {node} = props
    let {read_only} = props
    let {handleConfigurationClose} = props

    return(
        <div style={{flexGrow:"1", padding: "20px", display:"flex", flexDirection:"column"}}>
            <div style={{display: "flex"}}>
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>{t(node.node.description)}</span>
                <Tooltip title={<div className="tooltipText"><ul>{node.node.helperText.map((help,index) => (<li key={index}>{t(help)}</li>))}</ul></div>}>
                    <HelpIcon style={{fontSize: "14px", display:"flex", margin:"auto 5px"}}/>
                </Tooltip>
            </div>
            <div className={["internalCardContainer", "normalText", "normalMediumSize"].join("  ")} style={{display:"flex", flexDirection: "column"}}>
                {t("Não há configurações disponíveis para esse nó.")}
            </div>
            <div style={{display:"flex", margin: "auto auto 5px auto"}}>
                <div
                    className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")}
                    style={{width:"120px"}}
                    onClick={handleConfigurationClose}
                >
                    {read_only ? "X" : t("discard")}
                </div>
            </div>
        </div>
    )
}

export class LessThanEqualBoxClass {
    constructor(rulesContext){
        this.rulesContext = rulesContext
    }
    validate(node){
        let put_error = false
        let alerts = []
        let filteredOption = comparisionOptions.filter(option => option.value === "less_than_equal")[0]
        let typeString = filteredOption.types.reduce((acc, type, index, arr) => {
            let endString = index === arr.length -1 ? "." : index === arr.length -2 ? " ou " : ", "
            return acc.concat(translateType(type) + endString)
        }, "")

        //Validate if node has 2 children
        if((node.node.children || []).length !== 2){
            put_error = true
            alerts.push("Uma comparação sempre deve possuir 2 filhos")
        }
        //Validate if children has same type
        else{
            let first_type = node.node.children[0].instance.getType(node.node.children[0])
            let second_type = node.node.children[1].instance.getType(node.node.children[1])
            if (first_type !== second_type){
                put_error = true
                alerts.push("Os dois filhos devem ter os mesmo tipo. Atualmente os filhos são, respectivamente, " + translateType(first_type) + " e " + translateType(second_type))
            }
            else{
                if(!filteredOption.types.includes(first_type)){
                    put_error = true
                    alerts.push("Os filhos devem ser "+typeString+" Atualmente os filhos são " + translateType(first_type))
                }
            }
        }
        //Validate if parent is valid
        if (node.parentNode != null) {
            if(node.parentNode.on_error){
                put_error = true
                alerts.push("O nó superior está inválido")
            }
        }
        return {validated: !put_error, alerts: alerts}
    }

    getType(node){
        return "bool"
    }
}