import React, {useEffect,useCallback, useContext, useReducer, useState} from "react";
import {useStateConnectedToCache, dataFetchReducer} from "../../Utils/requestUtils"
import Filter from "./Filter/Filter"
import { CircularProgress } from "@material-ui/core";
import axios from "axios";
import ErrorBoundary from "../../Utils/ErrorBoundary"
import {useHistory} from 'react-router-dom'
import AuthContext from '../../../context/auth-context'
import RentalAgreementCardSAC from "./RentalAgreementCard/RentalAgreementCardSAC";

function RentalAgreementsInboxSAC(props) {
    let history = useHistory()
    let user_data = useContext(AuthContext).user_data

    if (!(user_data.roles.includes("read_car_rental_agreements_filtered_sac"))){
        history.push("")
    }

	const [documentNumber, setDocumentNumber] = useState(null)

    let local_storage_object_preffix = "ra_sac"
    
    const [pageParams, setPageParams] = useStateConnectedToCache(local_storage_object_preffix)

    const [pageState, dispatchPageState] = useReducer(
        dataFetchReducer,
        {fetchedData: null, isLoading: true, isError: false} 
    )

    const doRequest = useCallback(
        (payload, isRoutine, documentNumber) => {
            if (!isRoutine){
                dispatchPageState({type: "data_fetch_init"})  
            }
			if(documentNumber){
				axios.get("/dash/car_rental/rental_agreements/sac", {
					params: payload
				}).then(response=>{
					dispatchPageState({
						type: "data_fetch_success",
						payload: response.data
					})          
				}).catch(error => {
					if ((error.response || {}).status === 403) dispatchPageState({type: "data_fetch_failure_403"})
					else if ((error.response || {}).status === 404) dispatchPageState({type: "data_fetch_failure_404"})  
					else dispatchPageState({type: "data_fetch_failure"})    
				})
			}
        },[]
    )

    useEffect(() => {
		let payload = { }
		Object.keys(pageParams.filters).forEach((key,index) => {
            payload[key] = pageParams.filters[key]
			setDocumentNumber(pageParams.filters[key])
        })
		if(documentNumber){
            doRequest(payload, false, documentNumber)
		}
    },[doRequest, pageParams, documentNumber])

    
	const contentContainer = (dataList) => {
		if (!dataList.data) return <></>;
		let nr_of_cards = dataList['data'].length;
		let cards = dataList['data'].map((ra) => (
			<ErrorBoundary key={ra.rental_agreement_key}>
				<RentalAgreementCardSAC rental_agreement={ra}/>
			</ErrorBoundary>
		))
		if (nr_of_cards === 0) {
			return (
			<div className='emptyContainer'>
				<p className={["blueText", "subtitleSize"].join(" ")}>
					{`Nenhum aluguel encontrado referente ao CPF ${documentNumber}`}
				</p>
			</div>
			)
		}
		else {
			return <div className='cardContainer'>{cards}</div>
		}
	}

	return (
		<div style={{ width: "auto", paddingBottom: "30px"}}>
			<div style={{display:"flex"}}>
				<p className={["blueText", "titleSize"].join(" ")}>ALUGUÉIS SAC</p>
				<div style={{ margin: "auto 30px auto auto" }}>
					{props.is_role_manager&&
						<div
							style={{ padding: "5px 30px", "margin": "5px", minWidth: props.is_role_manager && '200px'}}
							className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
							onClick={props.handleViewChange}                        
						>
							Ir para Aluguéis
						</div>}
				</div>
			</div>
		<ErrorBoundary doNothing><Filter local_storage_object_preffix={"ra_sac"} setPageParams={setPageParams} /></ErrorBoundary>
		{documentNumber && <div>
			<ErrorBoundary>{
				pageState.fetchedData
						? contentContainer(pageState.fetchedData) 
						: pageState.isLoading
							? <div className="circularProgressCenter">
									<CircularProgress />
								</div>
							: pageState.isError 
								? <div style={{height: "55vh", display:"flex"}}>
									<p className={["blueText", "titleSize"].join(" ")} style={{margin:"auto", textAlign:"center"}}>
										{pageState.errorMessage}
									</p>
								</div>: null}
			</ErrorBoundary>
		</div>}
	</div>
	);
	}

export default (RentalAgreementsInboxSAC);
