import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import GetAppIcon from '@material-ui/icons/GetApp';
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
  },
  tableText: {
    fontFamily: "Open Sans",
    fontWeight: 'normal',
    fontSize: "12px",
    lineHeight: "16px",
    color: "#141414",
    textAlign: "center",
    margin: "10px",
    padding: "6px",
    minWidth: "120px"
  },
  tableTextJustify: {
    fontFamily: "Open Sans",
    fontWeight: 'normal',
    fontSize: "12px",
    lineHeight: "16px",
    color: "#141414",
    textAlign: "justify",
    margin: "10px",
    padding: "6px",
    minWidth: "120px"
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: '#6F6F6F',
    fontSize: "14px",
    lineHeight: "19px",
    padding: "6px",
  },
  even: {
    background: "#F3F3F3",
  },
  odd: {
    background: "#FFFFFF",
  },
}));

function CNDTTable(props) {
  const classes = useStyles();
  useTheme();

  const {cndt_data} = props

  let counter = 0

  const getClass = (rowIndex) => {
    counter = counter +1
    if (rowIndex % 2 === 0) {
      return classes.even;
    }

    return classes.odd;
  };    

   return (
    <Paper className={classes.tableStyle}>
      <Table style={{ padding: "8px" }}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader}>Número de Protocolo</TableCell>
            <TableCell className={classes.tableHeader}>Descrição</TableCell>
            {cndt_data[0].result_file_link && <TableCell className={classes.tableHeader}>Certidão</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {cndt_data.map((item,index) => (
            <TableRow key={index} className={getClass(counter)} > 
              <TableCell className={classes.tableText}>
                {item.protocol_number}
              </TableCell>
              <TableCell className={classes.tableTextJustify}>
                {item.description}
              </TableCell>
              {item.result_file_link && <TableCell className={classes.tableTextJustify}>
                <Tooltip title={<p className="tooltipText">Download da Certidão</p>}>
                  <a className="button standard normalText normalMediumSize" 
                      href={item.result_file_link} 
                      style={{textDecoration: 'none'}}
                      download>
                    <GetAppIcon style={{width: '100px', fontSize: 'medium'}}/>
                  </a>
                </Tooltip>
              </TableCell>}
            </TableRow>
            ))
        }
        </TableBody>
      </Table>
    </Paper>
  );
}

export default CNDTTable;
