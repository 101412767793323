import React, {useState, useContext, useEffect} from 'react'
import { useTranslation } from "react-i18next";
import {GetAlertsComponent,setObjectByPath, validateConstantValues, colorVelvet} from "./utils"
import TextField from "@material-ui/core/TextField";
import MenuItem from '@material-ui/core/MenuItem';
import RulesContext from "../../../../context/rules-context"
import _ from "lodash"
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import DeleteIcon from '@material-ui/icons/Delete';
import TimeDeltaTextField from './Components/TimeDeltaTextField';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
//BÁSICAS - Contador de Status

export function StatusCounterBox (props) {
    const { t } = useTranslation();
    let {node} = props
    let {read_only} = props
    let {handleConfigurationOpen} = props
    let {handleNodeRemove} = props
    let rulesContext = useContext(RulesContext)
    const statusOptions = rulesContext.boxes[node.node.type].settings.status_types
    const selectedStatusObject = statusOptions.filter(option => option.path === node.node.properties.path)[0]

    const field = rulesContext.fields_mapper_list.filter(event => event.path === node.node.properties.anchor_path)[0]

    let helperString = selectedStatusObject 
                            ? 
                            node.node.properties.status_list.reduce((acc, status, idx, src) => {
                                let option = selectedStatusObject.options.filter(option => option.enum === status)[0]
                                let description = (option || {description:""}).description
                                if (idx === 0) acc = acc + description
                                else if (idx === src.length -1) acc = acc + " e " + description
                                else acc = acc + ", " + description
                                return acc
                            },"")
                            :
                            ""

    function getVisualPattern(value){
        let weekSplit = value.split("W")
        let weekValue = ""
        if (weekSplit.length > 1){
            weekValue = weekSplit[0].replace(/\D/g, "");
            weekValue = /^0*$/.test(weekValue) ? "" :  weekValue + t(" Semanas, ")
            value = weekSplit[1]
        }
        let daySplit = value.split("D")
        let dayValue = ""
        if (daySplit.length > 1){
            dayValue = daySplit[0].replace(/\D/g, "");
            dayValue = /^0*$/.test(dayValue) ? "" :  dayValue + t(" Dias, ")
            value = daySplit[1]
        }
        let hourSplit = value.split("H")
        let hourValue = ""
        if (hourSplit.length > 1){
            hourValue = hourSplit[0].replace(/\D/g, "");
            hourValue = /^0*$/.test(hourValue) ? "" :  hourValue + t(" Horas, ")
            value = hourSplit[1]
        }
        let minuteSplit = value.split("M")
        let minuteValue = ""
        if (minuteSplit.length > 1){
            minuteValue = minuteSplit[0].replace(/\D/g, "");
            minuteValue = /^0*$/.test(minuteValue) ? "" :  minuteValue + t(" Minutos, ")
            value = minuteSplit[1]
        }
        let secondSplit = value.split("S")
        let secondValue = ""
        if (secondSplit.length > 1){
            secondValue = secondSplit[0].replace(/\D/g, "");
            secondValue = /^0*$/.test(secondValue) ? "" :  secondValue + t(" Segundos")
            value = secondSplit[1]
        }
        return weekValue + dayValue + hourValue + minuteValue + secondValue
    }        
                    
    return (
        <div>
            {!read_only ?
                <div style={{display:"flex", alignItems: "center"}}>
                    <div 
                        style={{display:"flex", width:"100%", cursor:"pointer", margin: "0px"}} 
                        onClick={() => handleConfigurationOpen(node)}
                    >
                        <div style={{display: "flex",flexDirection: "column",margin: "auto 0px"}}>
                            <div className={["normalText","normalMediumSize"].join(" ")}>
                            {t("Contar")} {t((selectedStatusObject || {description:""}).description)}: {t(helperString)}
                            </div>
                            <div className={["normalText","normalSmallSize"].join(" ")}>
                            {t("Para o mesmo")} {t((field || {description:""}).description)}
                            {node.node.properties.timedelta_value 
                            ? 
                            t(" nos últimos ") + t(getVisualPattern(node.node.properties.timedelta_value))
                            :
                            t(" em todo o histórico")}
                            </div>
                        </div>
                        {(node.node.alerts || []).length > 0?
                        <GetAlertsComponent alerts={node.node.alerts} />  : null}       
                    </div>
                    <IconButton style={{width: 32, height: 32}} aria-label="duplicate" onClick={() => props.handleNodeDuplication(node)}>
                        <ContentCopyIcon style={{width: 20, height: 20}} />
                    </IconButton>
                    <IconButton style={{width: 32, height: 32}} aria-label="close" onClick={() => handleNodeRemove(node)}>
                        <CloseIcon style={{width: 20, height: 20}} />
                    </IconButton>
                </div>
                :
                <div style={{display:"flex"}}>
                    <div 
                        style={{display:"flex", width:"100%", cursor:"pointer", margin: "0px"}} 
                        onClick={() => handleConfigurationOpen(node)}
                    >
                        <div style={{display: "flex",flexDirection: "column",margin: "auto 0px"}}>
                            <div className={["normalText","normalMediumSize"].join(" ")}>
                            {t("Contar")} {t((selectedStatusObject || {description:""}).description)}: {t(helperString)}
                            </div>
                            <div className={["normalText","normalSmallSize"].join(" ")}>
                            {t("Para o mesmo")} {t((field || {description:""}).description)}
                            {node.node.properties.timedelta_value 
                            ? 
                            t(" nos últimos ") + t(getVisualPattern(node.node.properties.timedelta_value))
                            :
                            t(" em todo o histórico")}
                            </div>
                        </div>      
                    </div>
                </div>
            }
        </div>
    )
}

export function StatusCounterConfiguration(props){
    const { t } = useTranslation();
    let {node} = props
    let {read_only} = props
    let {handleNodeChange} = props
    let {handleConfigurationClose} = props
    let rulesContext = useContext(RulesContext)
    const statusOptions = rulesContext.boxes[node.node.type].settings.status_types

    const [nodeState, setNodeState] = useState(node)
    const [statusObject, setStatusObject] = useState(statusOptions.filter(option => option.path === node.node.properties.path)[0])

    useEffect(()=>{
        setNodeState(node)
        setStatusObject(statusOptions.filter(option => option.path === node.node.properties.path)[0])
    },[node,statusOptions])

    const validateAllValues = () => {
        let selectedObject = statusOptions.filter(option => option.path === nodeState.node.properties.path)

        let anchorPathValidation = nodeState.node.properties.anchor_path && nodeState.node.properties.anchor_path.length >0

        let pathValidation = selectedObject.length === 1

        let availableOptions = (selectedObject[0] || {options:[]}).options.reduce((acc,option) => {
            acc.push(option.enum)
            return acc
        },[])
    
        let statusListValidation
        if (new Set(nodeState.node.properties.status_list).size !== nodeState.node.properties.status_list.length){
            statusListValidation = false
        }
        else{
            statusListValidation = nodeState.node.properties.status_list.filter(status => !availableOptions.includes(status)).length === 0
        }

        let timedelta_validation = nodeState.node.properties.timedelta_value 
                                    ? 
                                    validateConstantValues("timedelta",nodeState.node.properties.timedelta_value)
                                    :
                                    true


        return anchorPathValidation && pathValidation && statusListValidation && timedelta_validation
    }

    const handleAdd = () => {
        let new_node = _.cloneDeep(nodeState)
        new_node.node.properties.status_list = [...new_node.node.properties.status_list, ""]
        setNodeState(new_node)
    }

    const handleRemove = (index) => {
        let new_node = _.cloneDeep(nodeState)
        new_node.node.properties.status_list.splice(index,1)
        setNodeState(new_node)
    }

    const handleNodeStateChange = (obj_key,value) => {
        let new_node = _.cloneDeep(nodeState)
        if (obj_key === "properties.path"){
            setStatusObject(statusOptions.filter(option => option.path === value)[0])
            new_node.node.properties.status_list = [""]
        }
        setObjectByPath(new_node.node,obj_key,value)
        setNodeState(new_node)
    }

    const handleStatusChange = (e, index) => {
        let new_node = _.cloneDeep(nodeState)
        new_node.node.properties.status_list.splice(index,1,e.target.value)
        setNodeState(new_node)
    }

    const handleSave = () => {
        handleConfigurationClose()
        handleNodeChange(nodeState)
    }


    return(
        <div style={{flexGrow:"1", padding: "20px", display:"flex", flexDirection:"column"}}>
            <div style={{display: "flex"}}>
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>{t(node.node.description)}</span>
                <Tooltip title={<div className="tooltipText"><ul>{node.node.helperText.map((help,index) => (<li key={index}>{t(help)}</li>))}</ul></div>}>
                    <HelpIcon style={{fontSize: "14px", display:"flex", margin:"auto 5px"}}/>
                </Tooltip>
            </div>
            <div className="internalCardContainer" style={{display:"flex", flexDirection: "column"}}>
                <TextField 
                    label={t("Campo Âncora")}
                    style={{textAlign:"center", width:"80%", margin:"3px auto"}}
                    className="filterTextbox" 
                    size="small" fullWidth 
                    value={nodeState.node.properties.anchor_path}
                    onChange={(e) => handleNodeStateChange("properties.anchor_path", e.target.value)}
                    select
                    InputProps={{
                        readOnly: read_only
                      }}
                >
                    { rulesContext.fields_mapper_list.filter(field => field.type === "string" && field.elasticsearch_type === "keyword").map((field, index) => (
                        <MenuItem key={index} value={field.path}><em className="normalText">{t(field.description)}</em></MenuItem>
                    ))}
                </TextField>
                <TextField 
                    label={t("Tipo do Status")}
                    style={{textAlign:"center", width:"80%", margin:"3px auto"}}
                    className="filterTextbox" 
                    size="small" fullWidth 
                    value={nodeState.node.properties.path}
                    onChange={(e) => handleNodeStateChange("properties.path", e.target.value)}
                    select
                    InputProps={{
                        readOnly: read_only
                      }}
                >
                    {statusOptions.map((option, index) => (
                        <MenuItem key={index} value={option.path}><em className="normalText">{t(option.description)}</em></MenuItem>
                    ))}
                </TextField>
                {nodeState.node.properties.status_list.map((row,index) => (
                    <div key={index} style={{display:"flex", width:"80%", margin:"3px auto"}}>
                        <TextField 
                            label={"Status"}
                            error={nodeState.node.properties.status_list[index] === "" || nodeState.node.properties.status_list.filter(status => status === nodeState.node.properties.status_list[index]).length >1}
                            style={{textAlign:"center"}}
                            className="filterTextbox" 
                            size="small" fullWidth 
                            value={nodeState.node.properties.status_list[index] || ""}
                            onChange={(e) => handleStatusChange(e,index)}
                            select
                            InputProps={{
                                readOnly: read_only
                              }}
                        >
                            {statusObject.options.map((option, option_index) => (
                                <MenuItem key={option_index} value={option.enum}>
                                    <em className={"normalText"}>
                                        {t(option.description)}
                                    </em>
                                </MenuItem>
                            ))}
                        </TextField>
                        {index !== 0 && !read_only
                        ?
                        <DeleteIcon onClick={() => handleRemove(index)} style={{fill:colorVelvet, margin:"auto 0px", cursor: "pointer"}}/>
                        :
                        null}
                    </div>
                ))}
                {read_only && nodeState.node.properties.status_list.length === (statusObject || {options:[]}).options.length ? null :
                <div
                    style={{cursor:"pointer", margin:"5px auto"}}
                    onClick={handleAdd}
                    className={["blueText", "normalSMallSize"].join(" ")}
                >
                    {t("+ NOVO CAMPO")}
                </div>}
                <TimeDeltaTextField
                    value={nodeState.node.properties.timedelta_value ? nodeState.node.properties.timedelta_value : ""}
                    error={nodeState.node.properties.timedelta_value ? !validateConstantValues("timedelta",nodeState.node.properties.timedelta_value) : false}
                    valueChangeFunction={(value) => handleNodeStateChange("properties.timedelta_value", value)}
                    label={"Período de Tempo considerado"}
                    read_only={read_only}
                />             
            </div>
            <div style={{display:"flex", margin: "auto auto 5px auto"}}>
                <div
                    className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")}
                    style={{width:"120px"}}
                    onClick={handleConfigurationClose}
                >
                    {read_only ? "X" : t("discard")}
                </div>
                {!read_only && 
                <div
                    className={
                        validateAllValues()
                        ?
                        ["button", "standard", "normalText", "normalMediumSize"].join(" ")
                        :
                        ["button", "standard", "normalText", "normalMediumSize","disabled"].join(" ")}
                    style={{width:"120px"}}
                    onClick={validateAllValues() ? handleSave : null}
                >
                    {t("save")}
                </div>}   
            </div>
        </div>
    )
}

export class StatusCounterBoxClass {
    constructor(rulesContext){
        this.rulesContext = rulesContext
    }
    validate(node){
        let put_error = false
        let alerts = []
        
        let statusOptions = this.rulesContext.boxes[node.node.type].settings.status_types
        let values_list = statusOptions.reduce((acc, option) => {
            acc.push(option.path)
            return acc
        },[])

        
        //Validate if node has one or two children
        if((node.node.children || []).length !== 0){
            put_error = true
            alerts.push("Este nó não deve possuir filhos")
        }
        if (!values_list.includes(node.node.properties.path)){
            put_error = true
            alerts.push("Clique no nó deste Contador de Status, abra a configuração e escolha um Tipo válido")
        }
        if(!node.node.properties.anchor_path || node.node.properties.anchor_path === ""){
            put_error = true
            alerts.push("Clique no nó deste Contador de Status, abra a configuração e escolha um Campo Âncora válido")
        }
        else{
            let statusObject = statusOptions.filter(option => option.path === node.node.properties.path)[0]
            let available_status_list = statusObject.options.reduce((acc, option) => {
                acc.push(option.enum)
                return acc
            },[])

            if((node.node.properties.status_list|| []).length === 0){
                put_error = true
                alerts.push("Este nó deve ter opções escolhidas como Status")
            }
            else{
                if (new Set(node.node.properties.status_list).size !== node.node.properties.status_list.length){
                    put_error = true
                    alerts.push("Este nó não deve ter Status duplicados")
                }
                let invalidFields = node.node.properties.status_list.filter(status => !available_status_list.includes(status))
                if (invalidFields.length > 0){
                    put_error = true
                    alerts.push("Todos os Campos deste nó devem ter opções válidas")
                }
            }
        }
        if (node.node.properties.timedelta_value) {
            if (!validateConstantValues("timedelta",node.node.properties.timedelta_value)){
                put_error = true
                alerts.push("Deve ser fornecido um período de tempo válido")
            }
        }
        //Validate if parent is valid
        if (node.parentNode != null) {
            if(node.parentNode.on_error){
                put_error = true
                alerts.push("O nó superior está inválido")
            }
        }
        return {validated: !put_error, alerts: alerts}
    }

    getType(node){
        return "number"
    }
}
