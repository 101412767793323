import React, { useState, useEffect, useContext, useCallback } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import AuthContext from "../../../context/auth-context";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckIcon from "@material-ui/icons/Check";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import {
  SortableTreeWithoutDndContext as SortableTree,
  changeNodeAtPath,
  toggleExpandedForAll,
  removeNodeAtPath,
  addNodeUnderParent,
  getNodeAtPath,
} from "react-sortable-tree";
import { walk, translateIncomeTreeData } from "./treeUtils";
import { getTypeComponents } from "./BoxTypeMapper";
import BoxConfiguration from "./EngineUtilsComponents/BoxConfiguration";
import BoxLibrary from "./EngineUtilsComponents/BoxLibrary";
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import StandardNodeComponent from "./EngineUtilsComponents/StandardNodeComponent";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import NodeRemovalConfirmation from "./Dialogs/NodeRemovalConfirmation";
import SaveRuleDialog from "./Dialogs/SaveRuleDialog";
import DecisionRuleDraftDialog from "./Dialogs/DecisionRuleDraftDialog";
import SaveRuleDraftDialog from "./Dialogs/SaveRuleDraftDialog";
import SaveNewDraftDialog from "./Dialogs/SaveNewDraftDialog";
import { CreateUserVariables } from "./CreateUserVariables/CreateUserVariables";
import SaveIcon from '@material-ui/icons/Save';
import RulesContext from "../../../context/rules-context";
import moment from "moment";
import { useTranslation } from "react-i18next";
import FilterText from "../../@GeneralComponents/FilterText";
import SearchIcon from "@material-ui/icons/Search";
import { listSorter } from "../utils";
import { CircularProgress } from "@material-ui/core";
import { useParams } from "react-router-dom";
import Button from "../../@GeneralComponents/Button";
import { useHistory } from "react-router-dom";
import DraftsIcon from '@material-ui/icons/Drafts';
import LabIcon from '../../../assets/rulesIcon/lab.svg'
import ExpandIcon from '../../../assets/rulesIcon/expand.svg'
import MinimizeIcon from '../../../assets/rulesIcon/minimize.svg'
import { SimulateRuleDialog } from "./SimulateRuleDialog/SimulateRuleDialog";
import { v4 } from "uuid"

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0b1f82",
    },
  },
});

const nodeGeneralTypes = "GeneralNode"; //////////IMPORTANT

function RuleTreeEngine(props) {

  let { ruleDefinition, toRuleDraft } = props;
  let { rule_key } = useParams();
  let history = useHistory()

  const [userVariablesAvailable, setUserVariablesAvailable] = useState(null)
  const [treeState, setTreeState] = useState(null);
  const [initialTreeData, setInitialTreeData] = useState(null);
  const [simulateRuleDialogOpen, setSimulateRuleDialogOpen] = useState(false);
  const [isRuleTouched, setIsRuleTouched] = useState(false);

  const [duplicateSnackbarOpen, setDuplicateSnackbarOpen] = useState(false);

  useEffect (() => {
    if (ruleDefinition) {
      setUserVariablesAvailable(listSorter(ruleDefinition.user_variables,"variable_name"))
    }
  },[ruleDefinition])

  let rulesContext = useContext(RulesContext);
  const { t } = useTranslation();

  let isHistoric = (!ruleDefinition.is_current_version && !ruleDefinition.isDraft);
  let hasRuleDraft = ruleDefinition.hasRuleDraft
  let isDraft = ruleDefinition.isDraft;
  let read_only = isHistoric || (!isDraft && hasRuleDraft)
  let user_data = useContext(AuthContext).user_data;
  let isSimulator = user_data.roles.includes("rule_simulator");
  let isEditor = user_data.roles.includes("rule_editor");
  let isDraftEditor = user_data.roles.includes("rule_draft_editor");
  let isViewer = user_data.roles.includes("rule_viewer");
  let isNewRule = rule_key === "new_rule"

  const doTreeDataRequest = useCallback(()=>{
    let initialTreeData = translateIncomeTreeData(
      ruleDefinition.tree_data,
      rulesContext,
      userVariablesAvailable
    )
    setInitialTreeData(initialTreeData)
    setTreeState({ treeData: initialTreeData });
    let newTreeData = walk({
      treeData: initialTreeData,
      getNodeKey: getNodeKey,
      ignoreCollapsed: false,
    });
    if ((newTreeData || { anyError: true }).anyError) setInvalidTree(true);
    else setInvalidTree(false);
  },[ruleDefinition, rulesContext, userVariablesAvailable])

  useEffect(() => {
    if (userVariablesAvailable&&!treeState) {
      doTreeDataRequest()
    }
  }, [userVariablesAvailable, treeState, doTreeDataRequest]);

  const [libraryExpanded, setLibraryExpanded] = useState(true);
  const [boxConfiguration, setBoxConfiguration] = useState({ open: false });
  const [removalConfirmationDialog, setRemovalConfirmationDialog] = useState({
    open: false,
  });

  const [saveRuleDialog, setSaveRuleDialog] = useState(false);

  const [saveNewDraftDialog, setSaveNewDraftDialog] = useState(false);
  const [saveRuleDraftDialog, setSaveRuleDraftDialog] = useState(false);
  const [decisionRuleDraftDialog, setDecisionRuleDraftDialog] = useState(false);

  const [decisionRuleDraft, setDecisionRuleDraft] = useState("");

  const [invalidTree, setInvalidTree] = useState(undefined);

  const [filters, setFilters] = useState({
    search_component: ""
  })

  const getNodeKey = ({ node }) => node.id;

  const handleConfigureNodeComponent = (node) => {
    return <StandardNodeComponent {...node} />;
  };

  const handleExpand = (event) => {
    handleConfigurationClose();
    setLibraryExpanded(!libraryExpanded);
  };

  const handleConfigurationClose = () => {
    setBoxConfiguration({ open: false });
  };

  const handleConfigurationOpen = (node) => {
    setLibraryExpanded(true);
    goRecursively(treeState.treeData);
    setBoxConfiguration({ open: true, node: node });
  };

  const handleNodeExpansion = (expanded) => {
    let newTreeData = toggleExpandedForAll({
      treeData: treeState.treeData,
      expanded,
    });
    setTreeState({ treeData: newTreeData });
  };

  const handleNodeRemove = (node) => {
    setRemovalConfirmationDialog({ open: true, node: node });
  };

  function deepcopy_node(original_node) {
    let prototypeDescriptors = Object.getOwnPropertyDescriptors(Object.getPrototypeOf(original_node));
    let protoClone = Object.create(null, prototypeDescriptors);
    let node_deep_copy = Object.create(protoClone, Object.getOwnPropertyDescriptors(original_node));

    if (original_node.children) {
      node_deep_copy.children = original_node.children.map((child) => {
        return deepcopy_node(child);
      });
    }

    return node_deep_copy;
  }

  function cycle_node_ids(input_node) {
    let new_uuid = v4();
    let updated_node = {...input_node, id: new_uuid};
    
    if (input_node.children) {
      updated_node.children = input_node.children.map((child, i) => {
        return cycle_node_ids(child);
      });
    }
    
    return updated_node;
  }

  function reorder_siblings(tree_data, node_path, original_node_id, updated_node_id) {
    
    let parent_node = getNodeAtPath({
      treeData: tree_data,
      path: node_path.slice(0, node_path.length - 1),
      getNodeKey: getNodeKey,
    }).node;

    for (let i = 0; i < parent_node.children.length; i++) {
      if (parent_node.children[i].id === updated_node_id) {
        for (let j = 0; j < parent_node.children.length; j++) {
          if (parent_node.children[j].id === original_node_id) {
            let deep_copied_updated_node = deepcopy_node(parent_node.children[i]);
            parent_node.children.splice(i, 1);
            parent_node.children.splice(j, 0, deep_copied_updated_node);
            return
          }
        }
      }
    }
  }

  const handleNodeDuplication = (node) => {

    let node_deep_copy = deepcopy_node(node.node);
    let updated_node = cycle_node_ids(node_deep_copy);

    let original_node_id = node.node.id
    let updated_node_id  = updated_node.id

    node.node.expanded = false
    updated_node.expanded = false


    let new_tree_data = addNodeUnderParent({
      treeData: treeState.treeData,
      parentKey: node.path[node.path.length - 2],
      expandParent: true,
      getNodeKey: getNodeKey,
      newNode: updated_node,
      addAsFirstChild: true,
    }).treeData

    reorder_siblings(new_tree_data, node.path, original_node_id, updated_node_id);

    if (boxConfiguration.node) {
      let existentOpenNode = getNodeAtPath({
        treeData: new_tree_data,
        path: boxConfiguration.node.path,
        getNodeKey: getNodeKey,
      });
      if (!existentOpenNode) handleConfigurationClose();
    }
    goRecursively(new_tree_data);
    setDuplicateSnackbarOpen(true);
  }

  const goRecursively = (treeData) => {
    setIsRuleTouched(true)
    handleConfigurationClose();
    let newTreeData = walk({
      treeData: treeData,
      getNodeKey: getNodeKey,
      ignoreCollapsed: false,
    });
    if ((newTreeData || { anyError: true }).anyError) setInvalidTree(true);
    else setInvalidTree(false);
    setTreeState({ treeData: (newTreeData || { treeData: [] }).treeData });
  };

  const handleNodeChange = (node) => {
    let new_tree_data = changeNodeAtPath({
      treeData: treeState.treeData,
      path: node.path,
      getNodeKey: getNodeKey,
      newNode: node.node,
    });
    goRecursively(new_tree_data);
  };

  const deleteNodeFromTree = (node) => {
    let new_tree_data = removeNodeAtPath({
      treeData: treeState.treeData,
      path: node.path,
      getNodeKey: getNodeKey,
      newNode: node.node,
    });

    if (boxConfiguration.node) {
      let existentOpenNode = getNodeAtPath({
        treeData: new_tree_data,
        path: boxConfiguration.node.path,
        getNodeKey: getNodeKey,
      });
      if (!existentOpenNode) handleConfigurationClose();
    }
    goRecursively(new_tree_data);
  };

  const myGenerateNode = (node) => {
    let changeNodeInfo = {};
    let is_active = false;
    if (boxConfiguration.node) {
      if (boxConfiguration.node.node.id === node.node.id) {
        is_active = true;
      }
    }
    if (is_active && !read_only) {
      changeNodeInfo.className = "nodeActive";
    } else if (node.node.on_error) {
      changeNodeInfo.className = "rulesError";
    }

    changeNodeInfo.title = getTypeComponents({
      type: node.node.type,
      handleConfigurationOpen: handleConfigurationOpen,
      handleNodeRemove: handleNodeRemove,
      handleNodeDuplication: handleNodeDuplication,
      userVariablesAvailable: userVariablesAvailable,
      node: node,
      read_only: read_only,
    }).box_component;

    return changeNodeInfo;
  };

  const sendToRuleDraft = () => {
    history.push(`/rule/${ruleDefinition.rule_key}/rule_draft`)
    toRuleDraft()
  }

  const buttonsDisplayHandler = () => {
    let buttonsDisplay = {
      go_back: {
        show: false
      },
      discard: {
        show: false
      },
      deny_draft: {
        show: false
      },
      approve_draft: {
        show: false,
        disabled: false
      },
      save_draft: {
        show: false,
        disabled: false
      },
      save_new_draft: {
        show: false,
        disabled: false
      },
      save: {
        show: false,
        disabled: false
      },
      go_to_rule_draft: {
        show: false
      }
    }

    if (isHistoric || (isViewer && !isDraftEditor && !isEditor)) {
      buttonsDisplay.go_back.show = true
    } else if (isDraft) {
      if (isDraftEditor && !isEditor) {
        buttonsDisplay.go_back.show = true
      } else if (!isDraftEditor && isEditor) {
        buttonsDisplay.go_back.show = true
        buttonsDisplay.deny_draft.show = true
        buttonsDisplay.approve_draft.show = true
      } else if (isDraftEditor && isEditor) {
        buttonsDisplay.go_back.show = true
        buttonsDisplay.deny_draft.show = true
        buttonsDisplay.approve_draft.show = true
        buttonsDisplay.save_draft.show = true
      }
    } else {
      if (hasRuleDraft) {
        buttonsDisplay.go_back.show = true
        buttonsDisplay.go_to_rule_draft.show = true
      } else {
        if (isDraftEditor && !isEditor) {
          buttonsDisplay.go_back.show = true
          buttonsDisplay.save_new_draft.show = true
        } else if (!isDraftEditor && isEditor) {
          buttonsDisplay.go_back.show = true
          buttonsDisplay.save.show = true
        } else if (isDraftEditor && isEditor) {
          buttonsDisplay.go_back.show = true
          buttonsDisplay.save_new_draft.show = true
          buttonsDisplay.save.show = true
        }
      }
    }
    
    if (isNewRule) {
      buttonsDisplay.save_draft.show = false
    }
    if (invalidTree) {
      buttonsDisplay.save_draft.disabled = true
      buttonsDisplay.save.disabled = true
      buttonsDisplay.save_new_draft.disabled = true
    }

    if (isDraft && initialTreeData && treeState && initialTreeData!==treeState.treeData) {
      buttonsDisplay.approve_draft.disabled = true
    }

    return buttonsDisplay
  }
  
  const buttonsDisplay = buttonsDisplayHandler()

  const invalidTreeText = t("A árvore está inválida, deve possuir pelo menos um nó e não deve haver erros.")
  const saveRuleDraftFirstText = t("Salve as alterações do rascunho de regras antes de aprová-lo.")
  const changedTreeText = t("A árvore foi alterada, salve suas alterações antes de executar o teste de regra.")

  const decisionHandler = (decision) => {
    setDecisionRuleDraft(decision);
    setDecisionRuleDraftDialog(true);
  }

  const returnUpperRightBoxes = () => {
    return (
      <div style={{display:"flex", margin: "auto 0 auto auto"}}>
        {buttonsDisplay.go_back.show&&
        <Button
          button_option="standard_onlyboarder"
          buttonLabel="go_back"
          onClick={() => history.push("/rules-inbox")}
          buttonStyle={{minWidth: "100px", whiteSpace: "nowrap"}}
        />}
        {buttonsDisplay.deny_draft.show&&
          <Button
            button_option="standard_onlyboarder"
            buttonLabel="deny_draft"
            onClick={() => decisionHandler("deny")}
            buttonStyle={{whiteSpace: "nowrap"}}
          >
            <DeleteIcon style={{height: "15px", width: "20px"}}/>
          </Button>}
        {buttonsDisplay.approve_draft.show&&
          <Button
            tooltip={buttonsDisplay.approve_draft.disabled?saveRuleDraftFirstText:null}
            button_option={buttonsDisplay.approve_draft.disabled? "standard_disabled":"standard"}
            buttonLabel="approve_draft"
            onClick={() => buttonsDisplay.approve_draft.disabled? null:decisionHandler("approve")}
            buttonStyle={{whiteSpace: "nowrap"}}
          >
            <CheckIcon style={{height: "15px", width: "20px"}}/>
          </Button>}
        {buttonsDisplay.save_draft.show&&
          <Button
            tooltip={buttonsDisplay.save_draft.disabled?invalidTreeText:null}
            button_option={buttonsDisplay.save_draft.disabled? "standard_disabled":"standard"}
            buttonLabel="save_draft"
            onClick={() => buttonsDisplay.save_draft.disabled? null:setSaveRuleDraftDialog(true)}
            buttonStyle={{whiteSpace: "nowrap"}}
          >
            <SaveIcon style={{height: "15px", width: "20px"}}/>
          </Button>}
        {buttonsDisplay.save_new_draft.show&&
          <Button
            tooltip={buttonsDisplay.save_new_draft.disabled?invalidTreeText:null}
            button_option={buttonsDisplay.save_new_draft.disabled? "standard_disabled":"standard"}
            buttonLabel="save_new_draft"
            onClick={() => buttonsDisplay.save_new_draft.disabled? null:setSaveNewDraftDialog(true)}
            buttonStyle={{whiteSpace: "nowrap"}}
          >
            <SaveIcon style={{height: "15px", width: "20px"}}/>
          </Button>}
        {buttonsDisplay.save.show&&
          <Button
            tooltip={buttonsDisplay.save.disabled?invalidTreeText:null}
            button_option={buttonsDisplay.save.disabled? "standard_disabled":"standard"}
            buttonLabel="save"
            onClick={() => buttonsDisplay.save.disabled? null:setSaveRuleDialog(true)}
            buttonStyle={{minWidth: "100px", whiteSpace: "nowrap"}}
          />}
        {buttonsDisplay.go_to_rule_draft.show&&
          <Button
            button_option="standard"
            buttonLabel="go_to_rule_draft"
            onClick={() => sendToRuleDraft()}
            buttonStyle={{whiteSpace: "nowrap"}}
          >
            <DraftsIcon style={{height: "15px", width: "20px"}}/>
          </Button>}
      </div>
    )
  }

  if (userVariablesAvailable&&treeState) {
    return (
      <DndProvider backend={HTML5Backend}>
        <ThemeProvider theme={theme}>
          <div className="ruleEnginePage">
            {!read_only && libraryExpanded ? (
              <div
                className="ruleEngineSidebar"
                style={{width: "fit-content", padding: 16}}
              >
                <div>
                  <CreateUserVariables
                    treeState={treeState}
                    userVariablesAvailable={userVariablesAvailable}
                    setUserVariablesAvailable={setUserVariablesAvailable}
                  />
                  <div className="internalCardContainer">
                    <div className={[ "blueText", "subtitleSize",].join(" ")}>
                      {t("components")}
                    </div>
                    <div style={{width: "100%"}}>
                    <div className={["normalText", "normalSmallSize", "justifyText"].join(" ")} style={{wordWrap: "break-word", position: "relative"}}>
                    {t("add_component_description")}
                    </div>
                </div>
                  </div>
                  <FilterText 
                    id={"search_component"}
                    labelValue={t("search_component")}
                    filters={filters}
                    setFilters={setFilters}
                    icon={<SearchIcon style={{width:"15px", height:"15px"}}/>}
                  />
                </div>
                <BoxLibrary searchString={filters.search_component} userVariablesAvailable={userVariablesAvailable}/>
                {boxConfiguration.open&&
                  <BoxConfiguration
                    node={boxConfiguration.node}
                    handleNodeChange={handleNodeChange}
                    handleConfigurationClose={handleConfigurationClose}
                    read_only={read_only}
                    userVariablesAvailable={userVariablesAvailable}
                  />}
              </div>
            ) : (
              <div>
                {boxConfiguration.open && (
                  <BoxConfiguration
                    node={boxConfiguration.node}
                    handleConfigurationClose={handleConfigurationClose}
                    read_only={read_only}
                    userVariablesAvailable={userVariablesAvailable}
                  />
                )}
              </div>
            )}
            <div
              style={{
                padding: "0px 30px",
                flexGrow: "2",
                flexDirection: "column",
                display: "flex",
                width: "100%"
              }}
            >
              <div style={{ display: "flex", flexDirection:"column", width: "100%"}}>
                  <div>
                    <div
                      className={["blueText", "titleSize"].join(" ")}
                      style={{ margin: "5px 0px" }}
                    >
                      {t("Regra - ") + t(ruleDefinition.name)}
                    </div>
                    {read_only && (
                      <div className="normalMediumSize">
                        <p>
                          {ruleDefinition.version_date &&
                            t("read_only_version")+" "+
                            moment(ruleDefinition.version_date).format("DD/MM/YYYY HH:mm:ss")+". "+
                            t("read_only_description")
                          }
                        </p>
                        {!isDraft&&ruleDefinition.hasRuleDraft&&
                        <p> 
                          {t("this_rule_has_draft")}
                        </p>}
                      </div>
                    )}
                  </div>
                  <div className="buttonRowWrapper">
                    {!read_only&&(
                      <Button
                        button_option="standard"
                        buttonLabel=""
                        onClick={handleExpand}
                        buttonStyle={{padding:"10px", margin:"auto 0"}}
                      >
                        <DoubleArrowIcon style={{height: "15px", width: "15px", scale:"1.3", transform:libraryExpanded?"scaleX(-1)":null}}/>
                      </Button>
                    )}
                    <Button
                      button_option="standard"
                      buttonLabel=""
                      onClick={() => handleNodeExpansion(true)}
                      buttonStyle={{padding:"10px", margin:"auto 0"}}
                    >
                      <img src={ExpandIcon} alt="Expand Icon" style={{height: "15px", width: "15px", scale:"1.3"}}/>
                    </Button>
                    <Button
                      button_option="standard"
                      buttonLabel=""
                      onClick={() => handleNodeExpansion(false)}
                      buttonStyle={{padding:"10px", margin:"auto 0"}}
                    >
                      <img src={MinimizeIcon} alt="Minimize Icon" style={{height: "15px", width: "15px", scale:"1.3"}}/>
                    </Button>
                    {isSimulator&&
                    <Button
                      tooltip={!isRuleTouched?null:changedTreeText}
                      button_option={!isRuleTouched?"standard":"standard_disabled"}
                      buttonLabel=""
                      onClick={() => !isRuleTouched?setSimulateRuleDialogOpen(true):null}
                      buttonStyle={{padding:"10px", margin:"auto auto auto 0"}}
                    >
                      <img src={LabIcon} alt="Lab Icon" style={{height: "15px", width: "15px", scale:"1.3"}}/>
                    </Button>}
                    {isSimulator&&!isRuleTouched&&
                    <SimulateRuleDialog
                      open={simulateRuleDialogOpen}
                      onCloseDialog={() => setSimulateRuleDialogOpen(false)}
                      ruleKey={rule_key}
                      companyKey={ruleDefinition.company_key}
                    />}
                    {returnUpperRightBoxes()}
                  </div>
                </div>
              <div style={{ flexGrow: "2" }}>
                <SortableTree
                  treeData={treeState.treeData}
                  onChange={(treeData) => goRecursively(treeData)}
                  dndType={nodeGeneralTypes}
                  nodeContentRenderer={handleConfigureNodeComponent}
                  getNodeKey={getNodeKey}
                  generateNodeProps={(node) => myGenerateNode(node)}
                />
              </div>
            </div>
          </div>
          {!read_only && !isDraft && (
            <SaveRuleDialog
              open={saveRuleDialog}
              onClose={() => setSaveRuleDialog(false)}
              ruleDefinition={ruleDefinition}
              treeData={treeState.treeData}
              userVariablesAvailable={userVariablesAvailable}
            />
          )}
          {removalConfirmationDialog.node ? (
            <NodeRemovalConfirmation
              open={removalConfirmationDialog.open}
              onClose={() => setRemovalConfirmationDialog({ open: false })}
              node={removalConfirmationDialog.node}
              deleteNodeFromTree={deleteNodeFromTree}
            />
          ) : null}
          {isDraft && (
            <SaveRuleDraftDialog
              open={saveRuleDraftDialog}
              onClose={() => setSaveRuleDraftDialog(false)}
              ruleDraftDefinition={ruleDefinition}
              treeData={treeState.treeData}
              userVariablesAvailable={userVariablesAvailable}
              doTreeDataRequest={doTreeDataRequest}
            />
          )}
          {isDraft && (
            <DecisionRuleDraftDialog
              open={decisionRuleDraftDialog}
              onClose={() => setDecisionRuleDraftDialog(false)}
              ruleDraftDefinition={ruleDefinition}
              decision={decisionRuleDraft}
            />
          )}
          {!isDraft && (
            <SaveNewDraftDialog
              open={saveNewDraftDialog}
              onClose={() => setSaveNewDraftDialog(false)}
              ruleDraftDefinition={ruleDefinition}
              treeData={treeState.treeData}
              userVariablesAvailable={userVariablesAvailable}
            />
          )}
        </ThemeProvider>
        <Snackbar
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                open={duplicateSnackbarOpen}
                autoHideDuration={5000}
                onClose={() => setDuplicateSnackbarOpen(false)}
            >
            <Alert
                variant="standard"
                onClose={() => {setDuplicateSnackbarOpen(false)}}
                severity="warning"
            >
              <AlertTitle>Nó duplicado</AlertTitle>
                Lembre-se de revisá-lo antes de salvar a regra.
            </Alert>
        </Snackbar>
      </DndProvider>
    );
  } else {
    return (
      <div style={{ height: "100%", display: "flex" }}>
        <CircularProgress style={{ margin: "auto" }} />
      </div>
    );
  }
}

export default RuleTreeEngine;
