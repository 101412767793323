import React from "react";
import Alert from "./Alert/Alert"
import {getProperMetadata, getRiskLevel} from "../../utils/utils"
import DataField from "../../utils/DataField"
import { useTranslation } from "react-i18next";

const getStatus = (status, ratioColor) => {
	switch (status) {
		case "pending":
		case "in_manual_analysis":
			return (<span style={{color: ratioColor}}>Pendente</span>)
		case "manually_approved":
			return (<span style={{color: '#73C580'}}>Aprovado Manualmente</span>)
		case "automatically_approved":
			return (<span style={{color: '#73C580'}}>Aprovado Automaticamente</span>)
		case "manually_reproved":
			return (<span style={{color: '#D91A1A'}}>Reprovado Manualmente</span>)
		case "automatically_reproved":
			return (<span style={{color: '#D91A1A'}}>Reprovado Automaticamente</span>)
        case "automatically_challenged":
            return (<span style={{color: '#DDBB08'}}>Contestado Automaticamente</span>)
        case "manually_challenged":
            return (<span style={{color: '#DDBB08'}}>Contestado Manualmente</span>)
        case "on_hold":
            return (<span style={{color: '#F5AF2F'}}>Em Espera</span>)
		default:
			return (<span style={{color: ratioColor}}>{status}</span>)
	}
}

function Summary(props) {    
    let decision_metadata = getProperMetadata(props.natural_person.analysis_status_events)
    let { t } = useTranslation()

    if (Object.keys(decision_metadata || {}).length === 0) {
		return (
            <div className="analysisCard">
                <div className={["blueText", "subtitleSize"].join(" ")}>Resumo</div>
                <div style={{margin:"auto"}} className={["labelText", "normalMediumSize"].join(" ")}>Informação Indisponível</div>
            </div>
		);        
  	}
  	else{
		let nr_of_itens = 0
		let nr_of_check = 0
		const lists = Object.keys(decision_metadata)
        var count_no_alerts = 0
        let total_itens_list = lists.filter(metadata_key => {
            if (decision_metadata[metadata_key] && typeof decision_metadata[metadata_key] === "object"){
                if (decision_metadata[metadata_key].flag != null) {return true}
                if (metadata_key === 'document_verification') {return true}
            }
            return false
        })
		let total_itens = total_itens_list.length-count_no_alerts
		let alerts=[]
		for (const metadata_key of total_itens_list) {
            if(decision_metadata[metadata_key].description!=null){
                alerts.push({
                    flag: decision_metadata[metadata_key].flag,
                    description: decision_metadata[metadata_key].description
                })
            }
            
            if(metadata_key === 'document_verification'){
                
                if((decision_metadata['document_verification']['rg'] || {}).description != null){
                    alerts.push({
                        flag: decision_metadata['document_verification']['rg'].flag,
                        description: decision_metadata['document_verification']['rg'].description
                    })
                }

                if((decision_metadata['document_verification']['cnh'] || {}).description != null){
                    alerts.push({
                        flag: decision_metadata['document_verification']['cnh'].flag,
                        description: decision_metadata['document_verification']['cnh'].description
                    })
                }
            }

            if (decision_metadata[metadata_key].flag !== "neutral"){
                nr_of_itens = nr_of_itens + 1
                if (decision_metadata[metadata_key].flag === "positive"){
                    nr_of_check = nr_of_check + 1
                }
            }
        }
        
        let is_indicator_reference = false ///////////////////////Gambiarra praajeitar o FRONT momentaneamente pra Abastece Ai
        if (nr_of_itens === 0) {
            for (var i = 0; i < (decision_metadata.indicators || []).length; i++){
                nr_of_itens = nr_of_itens + 1
                let signal = decision_metadata.indicators[i]
                is_indicator_reference = true
                if (signal.flag === "positive") nr_of_check = nr_of_check + 1
            }
        }

		let ratio = nr_of_check/nr_of_itens*100
		let color = '#DDBB08'
		if (ratio >= 80){
			color = '#73C580'
		}
		else if (ratio < 50){
			color = '#D91A1A'
		}

        let score = (decision_metadata || {}).tree_score === 0 ? "0" : (decision_metadata || {}).tree_score
        let emailage = decision_metadata?.result_emailage?.email_score
        let custom_variables_list = decision_metadata?.custom_variables ? Object.entries(decision_metadata.custom_variables) : []
        let user_variables_list = decision_metadata?.user_variables ? decision_metadata.user_variables : []
        let risk_level = props.natural_person.risk_level
        let machine_learning_metadata = (decision_metadata || {}).machine_learning_metadata || []

		return (
                <div className="analysisCard">
                    <div className={["blueText", "subtitleSize"].join(" ")}>Resumo</div>
                    <div style={{display:"flex"}} className="internalCardContainer">
                        {nr_of_itens === 0 ? null :
                        <div 
                            style={{color: color, borderColor: color, borderWidth:"2px", borderStyle:"solid", margin: "0px 5px"}} 
                            className={["circle", "big","normalText", "subtitleSize", "bold"].join(" ")}
                        >
                            {ratio.toFixed(0) + '%'}
                        </div>
                        }
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", margin: "auto 5px"}}>
                            <div className={["normalText", "normalMediumSize"].join(" ")}>
                                Status: {getStatus(props.natural_person.analysis_status, color)}
                            </div>
                            {
                                decision_metadata.reason ? 
                                <div className={["normalText", "normalMediumSize"].join(" ")}>
                                    Razão: {decision_metadata.reason_description || decision_metadata.reason}
                                </div>
                                :
                                null
                            }
                            {
                                score ? 
                                <div className={["normalText", "normalMediumSize"].join(" ")}>
                                    Score: {score}
                                </div>
                                :
                                null
                            }
                            {(risk_level && risk_level !== "undefined") ?
                                <div className={["normalText", "normalMediumSize"].join(" ")}>
                                    Nível de risco: {getRiskLevel(risk_level)}
                                </div>
                            : null}
                            <div className={["normalText", "normalSmallSize"].join(" ")}>
                                {
                                    is_indicator_reference
                                    ?
                                    "Nenhuma verificação de itens"
                                    :
                                    nr_of_check + "/" + nr_of_itens + " itens aprovados"
                                }
                            </div>
                                {is_indicator_reference
                                ?
                                <div className={["normalText", "normalSmallSize"].join(" ")}>
                                    {nr_of_check}/{nr_of_itens} Indicadores Positivos
                                </div>
                                :
                                nr_of_check !== nr_of_itens && total_itens !== nr_of_itens
                                ?
                                <React.Fragment>
                                    <div className={["normalText", "normalSmallSize"].join(" ")}>
                                        {nr_of_itens - nr_of_check}/{nr_of_itens} itens reprovados
                                    </div>
                                    <div className={["normalText", "normalSmallSize"].join(" ")}>
                                        {total_itens-nr_of_itens}/{total_itens} itens não verificados
                                    </div>
                                </React.Fragment>
                                :
                                nr_of_check !== nr_of_itens
                                ?
                                <div className={["normalText", "normalSmallSize"].join(" ")}>
                                    {nr_of_itens - nr_of_check}/{nr_of_itens} itens reprovados
                                </div>
                                :
                                null
                                }
                        </div>
                    </div>
                    <div  className="internalCardContainer" style={{display: 'flex', flexDirection: 'column'}}>
                    {(alerts.length!==0)&&
                    <div className={["normalMediumSize","bold"].join(" ")} style={{marginTop: '15px'}}>
                        {`${t("alerts")}:`}
                    </div>}
                    {alerts.map((alert, index) => (
                    <Alert key={index} alert_data={alert} />))}
                    {!!emailage &&
                        <>
                            <div className={["normalMediumSize","bold"].join(" ")} style={{marginTop: '15px'}}>
                                {`${t("score")}:`}
                            </div>
                            <DataField
                                title={t("score_emailage")}
                                label={emailage}
                                size={"normalMediumSize"}
                                titleNoWrap={false}
                            />
                        </>}
                    {!!custom_variables_list.length && 
                        <>
                            <div className={["normalMediumSize","bold"].join(" ")} style={{marginTop: '15px'}}>Variáveis de saída:</div>
                            {custom_variables_list.map((custom_variable, index) => (
                                <div key={index} style={{display: 'flex', flexDirection: 'column'}}>
                                    <DataField
                                        title={custom_variable[0]}
                                        label={custom_variable[1].toString()}
                                        size={"normalMediumSize"}
                                        titleNoWrap={false}
                                    />
                                </div>
                                ))}
                        </>}
                    {!!user_variables_list.length && 
                    <>
                        <div className={["normalMediumSize","bold"].join(" ")} style={{marginTop: '15px'}}>
                            Variáveis do motor:</div>
                        {user_variables_list.map((custom_variable, index) => (
                            <div key={index} style={{display: 'flex', flexDirection: 'column'}}>
                                <DataField
                                    title={custom_variable.variable_name}
                                    label={custom_variable.variable_value.toString()}
                                    size={"normalMediumSize"}
                                    titleNoWrap={false}
                                />
                            </div>))}
                    </>}
                    {(Object.keys(machine_learning_metadata).length !== 0 && machine_learning_metadata.length!==0)&&
                    <>
                        <div className={["normalMediumSize","bold"].join(" ")} style={{marginTop: '15px'}}>
                            {`${t("machine_learning_metadata")}:`}</div>
                            {machine_learning_metadata.map((machine_learning, index) => (
                                <div key={index} style={{display: 'flex', flexDirection: 'column'}}>
                                    <DataField
                                        title={machine_learning.model_name}
                                        label={machine_learning.score.toString()}
                                        size={"normalMediumSize"}
                                        titleNoWrap={false}
                                    />
                                </div>))}
                    </>}
                </div>
				</div>
		);
  	}
}

export default Summary;
