import React, {useState, useContext, useEffect} from 'react'
import { useTranslation } from "react-i18next";
import {GetAlertsComponent,setObjectByPath, getConstantTextFields, validateConstantValues,comparisionOptions, translateType} from "./utils"
import TextField from "@material-ui/core/TextField";
import InputMask from "react-input-mask";
import NumberFormat from 'react-number-format';
import MenuItem from '@material-ui/core/MenuItem';
import RulesContext from "../../../../context/rules-context"
import _ from "lodash"
import IconButton from '@material-ui/core/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment'
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import TimeDeltaTextField from './Components/TimeDeltaTextField';
// BÁSICAS - Signals Condicional 

const getDatetimeFormatted = (value) => {
    if (moment(value, "DD/MM/YYYY ",true).isValid()){
        return moment(value, "DD/MM/YYYY ", true).format("YYYY-MM-DD") 
    }
    else if(moment(value, "DD/MM/YYYY HH:mm:ss",true).isValid()){
        return moment(value, "DD/MM/YYYY HH:mm:ss", true).format("YYYY-MM-DDTHH:mm:ssZ") 
    }
    else return value
}

function returnVisualPattern (value) {
    return (
        moment(value, "YYYY-MM-DD",true).isValid() 
        ?
        moment(value).format("DD/MM/YYYY")
        :
        moment(value, "YYYY-MM-DDTHH:mm:ssZ",true).isValid() 
        ?
        moment(value).format("DD/MM/YYYY HH:mm:ss")
        :
        value)
}

const nonPropertiesList = [
    'signal_key',
    'comparision_type',
    'value',
    'min_value',
    'max_value',
]


export function ConditionalSignalsBox (props) {
    const { t } = useTranslation();
    let {node} = props
    let {handleConfigurationOpen} = props
    let {handleNodeRemove} = props
    let rulesList = useContext(RulesContext)
    let event = rulesList.signals_list.filter(signal => signal.signal_key === node.node.properties.signal_key)[0]
    let comparision = comparisionOptions.filter(comparision => comparision.value === node.node.properties.comparision_type)[0]
    let {read_only} = props
    
    let helperString = comparision ? 
        comparision.value === "in_between" ? "Está " : "É " : ""

    let values =  comparision ? 
        comparision.value === "in_between" ? returnVisualPattern(node.node.properties.min_value) + " e " + returnVisualPattern(node.node.properties.max_value) : 
        typeof node.node.properties.value === "boolean" ? node.node.properties.value ? "Verdadeiro" : "Falso" : 
        returnVisualPattern(node.node.properties.value): ""
        
    return (
        <div>
            {!read_only ?
                <div style={{display:"flex", alignItems: "center"}}>
                    <div 
                        style={{display:"flex", width:"100%", cursor:"pointer", margin: "0px"}} 
                        onClick={() => handleConfigurationOpen(node)}
                    >
                        <div style={{display: "flex",flexDirection: "column",margin: "auto 0px"}}>
                            <div className={["normalText","normalMediumSize"].join(" ")}>
                            {t("Se")} {t((event || {description:""}).description)}
                            </div>
                            <div className={["normalText","normalSmallSize"].join(" ")}>
                            {t(helperString + (comparision||{description:""}).description.toLowerCase() + " " + values)}
                            </div>
                        </div>
                        {(node.node.alerts || []).length > 0?
                        <GetAlertsComponent alerts={node.node.alerts} />  : null}       
                    </div>
                    <IconButton style={{width: 32, height: 32}} aria-label="duplicate" onClick={() => props.handleNodeDuplication(node)}>
                        <ContentCopyIcon style={{width: 20, height: 20}} />
                    </IconButton>
                    <IconButton style={{width: 32, height: 32}} aria-label="close" onClick={() => handleNodeRemove(node)}>
                        <CloseIcon style={{width: 20, height: 20}} />
                    </IconButton>
                </div>
            :
                <div style={{display:"flex"}}>
                    <div 
                        style={{display:"flex", width:"100%", cursor:"pointer", margin: "0px"}} 
                        onClick={() => handleConfigurationOpen(node)}
                    >
                        <div style={{display: "flex",flexDirection: "column",margin: "auto 0px"}}>
                            <div className={["normalText","normalMediumSize"].join(" ")}>
                            {t("Se")} {t((event || {description:""}).description)}
                            </div>
                            <div className={["normalText","normalSmallSize"].join(" ")}>
                            {t(helperString + (comparision||{description:""}).description.toLowerCase() + " " + values)}
                            </div>
                        </div> 
                    </div>
                </div>
            }
        </div>
    )
}

export function ConditionalSignalsConfiguration(props){
    const { t } = useTranslation();
    let {node} = props
    let {handleNodeChange} = props
    let {handleConfigurationClose} = props
    let rulesList = useContext(RulesContext)
    let {read_only} = props

    const [nodeState, setNodeState] = useState(node)

    useEffect(()=>{
        setNodeState(node)
    },[node])

    const getSignalFromContext = (signal_key) => {
        return rulesList.signals_list.filter(signal => signal.signal_key === signal_key)[0]
    }

    const getPropertyFromSignal = (signal, property_name) => {
        return signal.properties.filter(property => property.name === property_name)[0]
    }

    const getOptions = (fieldType) => {
        let optionsList = []
        if (fieldType && fieldType !== ""){
            let filteredOptions = comparisionOptions.filter(option => option.types.includes(fieldType))
            optionsList = filteredOptions
        } 
        return optionsList
    }

    const handleComparisionChange = (newComparision) => {
        let new_node = _.cloneDeep(nodeState)
        setObjectByPath(new_node.node,"properties.comparision_type",newComparision)
        if(newComparision === "in_between"){
            setObjectByPath(new_node.node,"properties.value",null)
            setObjectByPath(new_node.node,"properties.min_value","")
            setObjectByPath(new_node.node,"properties.max_value","")
        }
        else{
            setObjectByPath(new_node.node,"properties.value","")
            setObjectByPath(new_node.node,"properties.min_value",null)
            setObjectByPath(new_node.node,"properties.max_value",null)
        }
        setNodeState(new_node)
    }

    const validateProperty = (property_value, property_name) => {
        let signalObject = getSignalFromContext(nodeState.node.properties.signal_key)
        let propertyObject = getPropertyFromSignal(signalObject,property_name)
        let validation
        if (propertyObject.optional && (property_value == null || property_value === "")){
            validation = true
        }
        else{
            validation = validateConstantValues(propertyObject.type, getDatetimeFormatted(property_value))
        }
        return validation
    }

    const validateAllValues = (fieldType) => {
        let validation
        let validationValue = getDatetimeFormatted(nodeState.node.properties.value)
        let validationMinValue = getDatetimeFormatted(nodeState.node.properties.min_value)
        let validationMaxValue = getDatetimeFormatted(nodeState.node.properties.max_value)
        if (!fieldType || fieldType === "") validation = false
        else{
            validation = (
                validateConstantValues(fieldType,validationValue) || (
                    validateConstantValues(fieldType,validationMinValue) && validateConstantValues(fieldType,validationMaxValue)
                )
            )
        }
        if ((nodeState.node.properties.comparision_type || "").length === 0) validation = false
        if (!validation) return validation
        else{
            if (!validateConstantValues("string", nodeState.node.properties.signal_key) && !validateConstantValues("string", nodeState.node.metadata.signal_type)) return false
            let keysToValidate = Object.keys(nodeState.node.properties).filter(node_property => !nonPropertiesList.includes(node_property))
            let invalidKeys = keysToValidate.filter(element => !validateProperty(nodeState.node.properties[element],element))
            if (invalidKeys.length === 0) return true
            else return false
        }
    }

    const handleEventChange = (e) => {
        let signal = rulesList.signals_list.filter(signal => signal.signal_key === e.target.value)[0]
        let new_node = _.cloneDeep(nodeState)
        let nullPropertiesObject = signal.properties.reduce((acc,property)=>{
            acc[property.name] = null
            return acc
        },{})
        new_node.node.properties = nullPropertiesObject
        setObjectByPath(new_node.node,"properties.signal_key",e.target.value)
        setObjectByPath(new_node.node,"metadata.signal_type",signal.return_type)
        setObjectByPath(new_node.node,"properties.comparision_type","")
        setObjectByPath(new_node.node,"properties.value","")
        setObjectByPath(new_node.node,"properties.min_value",null)
        setObjectByPath(new_node.node,"properties.max_value",null)
        setNodeState(new_node)
    }

    const handlePropertyChange = (path,val) => {
        let new_node = _.cloneDeep(nodeState)
        val = val === "" ? null : val
        setObjectByPath(new_node.node,path,val)
        setNodeState(new_node)
    }

    const handleSave = () => {
        let new_node = _.cloneDeep(nodeState)
        let keysToFormat = Object.keys(new_node.node.properties).filter(node_property => !nonPropertiesList.includes(node_property))
        keysToFormat.map((element, index, array) => (
            setObjectByPath(new_node.node,"properties." + element,getDatetimeFormatted(nodeState.node.properties[element])) 
        ))
        handleConfigurationClose()
        handleNodeChange(new_node)
    }

    const createdFields = (fieldsDynamic, constant_type, extraProps={}, propertyName="value") => {
        if (!['comparisionValue', 'propertyValue'].includes(fieldsDynamic)) throw new Error ("Invalid fieldDynamic " + fieldsDynamic)
        let standardField = getConstantTextFields(constant_type)
        let pathChange = "properties." + propertyName
        switch(constant_type) {
            case "number":
                return (
                     <NumberFormat 
                        {...standardField.props}
                        {...extraProps}
                        error={fieldsDynamic === "propertyValue" ?                            
                            !validateProperty(nodeState.node.properties[propertyName], propertyName)
                            :
                            !validateConstantValues(constant_type,nodeState.node.properties[propertyName])
                        }
                        disabled={fieldsDynamic === "propertyValue" ? false :getOptions(constant_type).length === 0} 
                        value={nodeState.node.properties[propertyName] || ""}
                        onValueChange={(e) => handlePropertyChange(pathChange, e.floatValue)}
                        InputProps={{
                            readOnly: read_only
                          }}
                    />
                )    
            case "bool":
                return (                        
                    <TextField 
                        {...extraProps}
                        {...standardField.props} 
                        error={fieldsDynamic === "propertyValue" ?                            
                            !validateProperty(nodeState.node.properties[propertyName], propertyName)
                            :
                            !validateConstantValues(constant_type,nodeState.node.properties[propertyName])
                        } 
                        disabled={fieldsDynamic === "propertyValue" ? false :getOptions(constant_type).length === 0} 
                        value={nodeState.node.properties[propertyName] == null ? "" : nodeState.node.properties[propertyName]}
                        onChange={(e) => handlePropertyChange(pathChange, e.target.value)}
                        InputProps={{
                            readOnly: read_only
                          }}
                    />
                )
            case "string":
                return (
                        <TextField 
                        {...extraProps}
                        {...standardField.props}
                        error={fieldsDynamic === "propertyValue" ?                            
                            !validateProperty(nodeState.node.properties[propertyName], propertyName)
                            :
                            !validateConstantValues(constant_type,nodeState.node.properties[propertyName])
                        }
                        disabled={fieldsDynamic === "propertyValue" ? false :getOptions(constant_type).length === 0} 
                        value={nodeState.node.properties[propertyName] || ""}
                        onChange={(e) => handlePropertyChange(pathChange, e.target.value)}
                        InputProps={{
                            readOnly: read_only
                          }}
                    />
                )    
            case "datetime":
                return (
                    <InputMask 
                        {...extraProps}
                        {...standardField.props}
                        error={fieldsDynamic === "propertyValue" ?                            
                        !validateProperty(getDatetimeFormatted(nodeState.node.properties[propertyName]), propertyName)
                        :
                        !validateConstantValues(constant_type,getDatetimeFormatted(nodeState.node.properties[propertyName]))
                        }
                        disabled={fieldsDynamic === "propertyValue" ? false :getOptions(constant_type).length === 0} 
                        value={nodeState.node.properties[propertyName] || ""}
                        onChange={(e) => handlePropertyChange(pathChange, e.target.value)}
                        InputProps={{
                            readOnly: read_only
                          }}                        
                    />
                )   
            case "time":
                return (
                    <InputMask 
                        {...extraProps}
                        {...standardField.props} 
                        error={fieldsDynamic === "propertyValue" ?                            
                            !validateProperty(nodeState.node.properties[propertyName], propertyName)
                            :
                            !validateConstantValues(constant_type,nodeState.node.properties[propertyName])
                        }
                        disabled={fieldsDynamic === "propertyValue" ? false :getOptions(constant_type).length === 0} 
                        value={nodeState.node.properties[propertyName] || ""}
                        onChange={(e) => handlePropertyChange(pathChange, e.target.value)}
                        InputProps={{
                            readOnly: read_only
                          }}                        
                    />
                )          
            case "timedelta":
                return (
                    <TimeDeltaTextField
                        {...extraProps}
                        value={nodeState.node.properties[propertyName] || ""}
                        error={
                            fieldsDynamic === "propertyValue" ?
                            !validateProperty(nodeState.node.properties[propertyName], propertyName)
                            :
                            !validateConstantValues(constant_type,nodeState.node.properties[propertyName])
                        }
                        valueChangeFunction={(value) => handlePropertyChange(pathChange, value)}
                        InputProps={{
                            readOnly: read_only
                          }}
                    />
                )                     
            default:
                throw new Error ("Invalid field Type: " + constant_type)
        }
    }

    return(
        <div style={{flexGrow:"1", padding: "20px", display:"flex", flexDirection:"column", width:"100%"}}>
            <div style={{display: "flex"}}>
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>{t(node.node.description)}</span>
                <Tooltip title={<div className="tooltipText"><ul>{node.node.helperText.map((help,index) => (<li key={index}>{t(help)}</li>))}</ul></div>}>

                    <HelpIcon style={{fontSize: "14px", display:"flex", margin:"auto 5px"}}/>
                </Tooltip>
            </div>
            <div className="internalCardContainer" style={{display:"flex", flexDirection: "column"}}>
                <TextField
                    label={t("Signal")}
                    style={{textAlign:"center", width:"80%", margin:"3px auto"}} 
                    className="filterTextbox" 
                    size="small" fullWidth 
                    value={nodeState.node.properties.signal_key}
                    onChange={handleEventChange}
                    select
                    InputProps={{
                        readOnly: read_only
                      }}
                >
                    {rulesList.signals_list.map((signal, index) => (
                        <MenuItem key={index} value={signal.signal_key}><em className="normalText">{t(signal.description) + " ("+t(translateType(signal.return_type))+")"}</em></MenuItem>
                    ))}
                </TextField>
                {nodeState.node.properties.signal_key && nodeState.node.properties.signal_key !== ""
                ?
                getSignalFromContext(nodeState.node.properties.signal_key).properties.map((property,index) =>(
                    createdFields(
                        "propertyValue", 
                        property.type, 
                        {
                            style:{textAlign:"center", width:"80%", margin:"3px auto"}, 
                            key:index, 
                            label:property.description
                        },
                        property.name
                    )
                ))
                :
                null}
                <TextField
                    label={t("Comparação")}
                    style={{textAlign:"center", width:"80%", margin:"3px auto"}} 
                    className="filterTextbox" 
                    size="small" fullWidth 
                    value={nodeState.node.properties.comparision_type}
                    onChange={(e) => handleComparisionChange(e.target.value)}
                    select
                    disabled={getOptions(nodeState.node.metadata.signal_type).length === 0}
                    InputProps={{
                        readOnly: read_only
                      }}
                >
                    {getOptions(nodeState.node.metadata.signal_type).map((option, index) => (
                        <MenuItem key={index} value={option.value}><em className="normalText">{t(option.description)}</em></MenuItem>

                    ))}
                </TextField>
                {
                nodeState.node.properties.comparision_type === "in_between" 
                ?
                <React.Fragment>
                    {createdFields(
                        "comparisionValue", 
                        nodeState.node.metadata.signal_type,
                        {style:{textAlign:"center", width:"80%", margin:"3px auto"}, label:"Menor Valor"}, 
                        "min_value"
                    )}
                    {createdFields(
                        "comparisionValue", 
                        nodeState.node.metadata.signal_type,
                        {style:{textAlign:"center", width:"80%", margin:"3px auto"}, label:"Maior Valor"}, 
                        "max_value"
                    )}
                </React.Fragment>
                :
                nodeState.node.metadata.signal_type && nodeState.node.metadata.signal_type !== "" 
                ?
                createdFields(
                    "comparisionValue", 
                    nodeState.node.metadata.signal_type, 
                    {style:{textAlign:"center", width:"80%", margin:"3px auto"}, label:"Valor"}, 
                    "value"
                ) 
                : 
                <TextField 
                    style={{textAlign:"center", width:"80%", margin:"3px auto"}} 
                    className="filterTextbox" 
                    size="small" fullWidth 
                    disabled
                    InputProps={{
                        readOnly: read_only
                      }}
                />} 
            </div>
            <div style={{display:"flex", margin: "auto auto 5px auto"}}>
                <div
                    className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")}
                    style={{width:"120px"}}
                    onClick={handleConfigurationClose}
                >
                    {read_only ? "X" : t("discard")}
                </div>
                {!read_only && 
                <div
                    className={
                        validateAllValues(nodeState.node.metadata.signal_type)
                        ?
                        ["button", "standard", "normalText", "normalMediumSize"].join(" ")
                        :
                        ["button", "standard", "normalText", "normalMediumSize","disabled"].join(" ")}
                    style={{width:"120px"}}
                    onClick={validateAllValues(nodeState.node.metadata.signal_type) ? handleSave : null}
                >
                    {t("save")}
                </div>}   
            </div>
        </div>
    )
}

export class ConditionalSignalsBoxClass {
    constructor(rulesContext){
        this.rulesContext = rulesContext
    }
    validate(node){
        let put_error = false
        let alerts = []
        let validationValue = getDatetimeFormatted(node.node.properties.value)
        let validationMinValue = getDatetimeFormatted(node.node.properties.min_value)
        let validationMaxValue = getDatetimeFormatted(node.node.properties.max_value)
        let signalType = node.node.metadata.signal_type
        //Validate if node has one or two children
        if((node.node.children || []).length < 1 || (node.node.children || []).length >2){
            put_error = true
            alerts.push("Este nó deve possuir 1 ou 2 filhos")
        }
        if(!node.node.properties.signal_key || node.node.properties.signal_key === ""){
            put_error = true
            alerts.push("Clique no nó deste Signal, abra a configuração e escolha um tipo válido")
        }
        else if(!signalType || signalType === ""){
            put_error = true
            alerts.push("Há um problema com o tipo desse campo")
        }
        else{
            if(!(
                validateConstantValues(signalType,validationValue) || 
                (validateConstantValues(signalType,validationMinValue) && validateConstantValues(signalType,validationMaxValue)))){
                put_error = true
                alerts.push("Clique neste nó, abra a configuração e escreva um valor a ser comparado válido")
            }
        }
        if(node.node.properties.comparision_type === ""){
            put_error = true
            alerts.push("Clique neste nó, abra a configuração e escolha uma comparação válida")
        }
        //Validate if parent is valid
        if (node.parentNode != null) {
            if(node.parentNode.on_error){
                put_error = true
                alerts.push("O nó superior está inválido")
            }
        }
        return {validated: !put_error, alerts: alerts}
    }

    getType(node){
        return "void"
    }
}