import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    padding: 16px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: #0b1f8211;
    border: 1px solid #0b1f8288;
    border-radius: 8px;
    gap: 8px;
`;

export const SwitchWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
`;

export const Message = styled.p`
    margin: 0;
    flex: 1;
`;

export const Label = styled.p`
    margin: 0;
    font-size: 10px;
    text-align: center;
`;