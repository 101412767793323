import React from "react";
import {getStoresContent} from "../../../utils/utils"

function StoresHeader(props) {

    return (
        <div style={{display:"flex", justifyContent: "space-around"}}>
            <div style={{display:"flex", flexDirection:"column"}}>
                <div className={["blueText", "subtitleSize"].join(" ")} style={{textAlign:"center"}}>
                    Loja de Retirada
                </div>
                <div className={["labelText", "normalMediumSize"].join(" ")} style={{textAlign:"center"}}>
                    {props.storeInfo.rental_store == null ? props.rental_agreement.rental_store == null ? 
                    "Indisponível" : props.rental_agreement.rental_store : 
                    getStoresContent(props.storeInfo, "rental_store")}
                </div>
            </div>
            <div style={{display:"flex", flexDirection:"column"}}>
                <div className={["blueText", "subtitleSize"].join(" ")} style={{textAlign:"center"}}>
                    Loja de Devolução
                </div>
                <div className={["labelText", "normalMediumSize"].join(" ")} style={{textAlign:"center"}}>
                    {props.storeInfo.devolution_store == null ? props.rental_agreement.devolution_store == null ? 
                    "Indisponível" : props.rental_agreement.devolution_store : 
                    getStoresContent(props.storeInfo, "devolution_store")}
                </div>
            </div>
        </div>
    )
}

export default StoresHeader