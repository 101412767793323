import React, { useEffect } from "react";
import HistoricTable from "./HistoricTable/HistoricTable";
import { CircularProgress } from '@material-ui/core';
import axios from "axios";


function History(props) {
    const { ra_key } = props

    const [userHistoric, setUserHistoric] = React.useState(null)

    useEffect(() => {
        const timer = setTimeout(() => {
            axios.get('/dash/car_rental/rental_agreement/' + ra_key + '/historic').then( response => {
                let historicList = response.data.filter(function(ra) {
                    return ra.rental_agreement_key !== ra_key
                })              
                setUserHistoric(historicList);
            }).catch(error => {
                setUserHistoric(undefined)
            })	
        }, 500);
        return () => {
            clearTimeout(timer)
        }
    }, [ra_key])

    if (userHistoric === null) {
        return (
            <div style={{minHeight: "250px"}} className="analysisCard">
                <div className={["blueText", "subtitleSize"].join(" ")}>Cruzamento de Dados</div>
                <div style={{display:"flex", flexGrow:"1"}}>
                    <CircularProgress style={{margin:"auto"}} />
                </div>
            </div>
        );    
    }
    else if (userHistoric === undefined){
        return (
            <div style={{minHeight: "250px"}} className="analysisCard">
                <div className={["blueText", "subtitleSize"].join(" ")}>Cruzamento de Dados</div>
                <div style={{display:"flex", flexGrow:"1"}}>
                    <div className={["labelText", "subtitleSize"].join(" ")} style={{margin:"auto"}}>
                        Informação Indisponível
                    </div>
                </div>
            </div>
        )
    }
    else {
        return (
            <div className="analysisCard" style={{minHeight: "250px"}}>
                <div className={["blueText", "subtitleSize"].join(" ")}>Cruzamento de Dados</div>
                <div style={{display:"flex", flexGrow:"1"}}>
                    <HistoricTable historicalData={userHistoric}/>
                </div>
            </div>
        );
    }
}

export default History;