import React, { useState, useEffect, useCallback } from "react";
import ProfilePictureDialog from "./ProfilePictureDialog/ProfilePictureDialog";
import ProfilePictureDialogCD from "./ProfilePictureDialog/ProfilePictureDialogCD";
import ValidatedImage from "../../utils/ValidatedImage"
import MapIcon from "@material-ui/icons/Map";
import axios from "axios";
import DataField from "../../utils/DataField"
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PhoneEnabledIcon from '@material-ui/icons/PhoneEnabled';
import {getValidationText, getValidationColor,formatBureauInfo, checkNested, titleCase, formatPhone, clientAge, translateGender, formatAddress, translatePhoneType} from "../../utils/utils"

function ClientProfile(props) {

    let decision_metadata = ((props.rental_agreement.fraud_status_events || [{}])[0]).decision_metadata
    let registration_data = ( decision_metadata || {}).registration_data

    let [profilePictureOpen, setProfilePictureOpen] = useState(false);
    let [profilePictureCDOpen, setProfilePictureCDOpen] = useState(false);
    let [validationData, setValidationData] = useState(undefined)

    const [validationInterval, setValidationInterval] = useState(null)

    const [pastRentals, setPastRentals] = React.useState(null);

    const fetchValidation = useCallback(
        (sms_link_validation_key) => {
            axios.get('/dash/validations/sms_link_validation/' + sms_link_validation_key).then(response => {
                setValidationData(response.data)
            }).catch(error => {
                console.log(error.response)
            })
        }, [])


    useEffect(() => {
        let sms_link_validation_key = ( decision_metadata || {}).sms_link_validation_key
        if (sms_link_validation_key === null) {
            setValidationData(null) 
        }
        else if (sms_link_validation_key != null){

            fetchValidation(sms_link_validation_key)
            
            const timer = setInterval(() => {
                fetchValidation(sms_link_validation_key)
            }, 10000);
            
            setValidationInterval(timer)
        }
    }, [fetchValidation, decision_metadata]);

    useEffect(() => {
        if (validationData) {
            clearInterval(validationInterval)
        }
    }, [validationData, validationInterval])

    useEffect(() => {
        const timer = setTimeout(() => {
            if (!checkNested(props.rental_agreement, "client", "document_number")) setPastRentals(undefined)
            else{
                axios.get('/dash/car_rental/rental_agreements', {
                    params: {"page_number": 0, "page_rows": 25, "document_number": props.rental_agreement.client.document_number}
                }).then(response => {
                    let pastRentalsList = response.data.data.filter(function(ra) {
                        return ra.rental_agreement_key !== props.rental_agreement.rental_agreement_key
                    })
                    setPastRentals(pastRentalsList);
                }).catch(error => {
                    console.log(error.response)
                    setPastRentals(undefined)
                })
            }
        }, 500);
        return () => {
        clearTimeout(timer);
        };
    }, [props.rental_agreement]);

    const handleProfilePictureOpenClick = () => {
        setProfilePictureOpen(true);
    };

    const handleProfilePictureClose = () => {
        setProfilePictureOpen(false);
    };

    const handleProfilePictureCDOpenClick = () => {
        setProfilePictureCDOpen(true);
    };

    const handleProfilePictureCDClose = () => {
        setProfilePictureCDOpen(false);
    };

    let getPictureContent = (picture) => {
        if (picture == null) {
            return (          
                <div 
                    className={["labelText", "normalMediumSize"].join(" ")} 
                    style={{textAlign:"center", margin:"auto"}}
                >
                    Informação Indisponível
                </div>
            )
        } 
        else{
            return (
                <ValidatedImage
                image_key={picture}
                dash_api_endpoint_url={'/dash/car_rental/image/profile_picture'}
                imageStyle = {{margin:"auto", width:"100px", maxHeight:"130px", borderRadius: "5px"}}
                imageAlt = {"Foto do Cliente"}
                />
            );
        }
    };

    return (
        <div className="analysisCard">
            <div style={{ display: "flex"}}>
                <div>
                    <div className={["blueText", "subtitleSize"].join(" ")}>Perfil</div>
                    <div style={{display:"flex", width:"120px", minHeight:"130px"}}>
                        {getPictureContent((props.rental_agreement.client || {}).face_picture)}
                    </div>
                    {pastRentals ? pastRentals.length > 0 ?
                    <div 
                        onClick={handleProfilePictureOpenClick}
                        className={["blueText", "normalSmallSize", "link"].join(" ")} 
                        style={{margin:"10px", textAlign:"center"}}
                    >
                        Ver fotos
                    </div> : null : null}
                    {(decision_metadata || {}).creddefense_passages ? 
                    (decision_metadata || {}).creddefense_passages.length > 0 ?
                    <div 
                        onClick={handleProfilePictureCDOpenClick}
                        className={["blueText", "normalSmallSize", "link"].join(" ")} 
                        style={{margin:"10px", textAlign:"center"}}
                    >
                        CredDefense
                    </div> : null : null}
                </div>
                <div className="labelSpace">
                    <DataField
                        title={"Nome"}
                        label={checkNested(props.rental_agreement, "client", "name") ? titleCase(props.rental_agreement.client.name, "-") : "-"}
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        display={"inline"}
                        margin="10px"
                        check={checkNested(registration_data, "name", "flag") ? registration_data.name.flag : null}
                        tooltipText={checkNested(registration_data, "name", "flag") ? registration_data.name.flag === "positive" ? "Nome validado" : 
                            checkNested(registration_data, "name", "description") ? "Nome encontrado em Bureau: " + titleCase(registration_data.name.description) : null : null
                        }
                    />
                    <DataField
                        title={"CPF"}
                        label={checkNested(props.rental_agreement, "client", "document_number") ? props.rental_agreement.client.document_number : "-"}
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        margin="10px"
                    />
                    <DataField
                        title={"Gênero"}
                        label={checkNested(props.rental_agreement, "client", "gender") ? translateGender(props.rental_agreement.client.gender) : "-"}
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        margin="10px"
                        check={checkNested(registration_data, "gender", "flag") ? registration_data.gender.flag : null}
                        tooltipText={checkNested(registration_data, "gender", "flag") ? registration_data.gender.flag === "positive" ? "Gênero validado" : 
                            checkNested(registration_data, "gender", "description") ? translateGender(registration_data.gender.description) : null : null
                        }
                    />
                    <DataField
                        title={"Data de Nascimento"}
                        label={checkNested(props.rental_agreement, "client", "birthdate") ? clientAge(props.rental_agreement.client.birthdate,"-") : "-"}
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        display={"inline"}
                        margin="10px"
                        check={checkNested(registration_data, "birthdate", "flag") ? registration_data.birthdate.flag : null}
                        tooltipText={checkNested(registration_data, "birthdate", "flag") ? registration_data.birthdate.flag === "positive" ? "Data validada" : 
                            checkNested(registration_data, "birthdate", "description") ? clientAge(registration_data.birthdate.description) : null : null
                        }
                    />
                    <DataField
                        title={"Nome da Mãe"}
                        label={checkNested(props.rental_agreement, "client", "mother_name") ? titleCase(props.rental_agreement.client.mother_name, "-") : "-"}
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        margin="10px"
                        check={checkNested(registration_data, "mother_name", "flag") ? registration_data.mother_name.flag : null}
                        tooltipText={checkNested(registration_data, "mother_name", "flag") ? registration_data.mother_name.flag === "positive" ? "Nome validado" : 
                            checkNested(registration_data, "mother_name", "description") ? "Nome da mãe encontrado em Bureau: " + titleCase(registration_data.mother_name.description) : null : null
                        }
                    />
                    <div style={{marginTop: "10px", marginBottom: "10px"}}>
                    <DataField
                        title={"Email"}
                        label={checkNested(props.rental_agreement, "client", "email") ? props.rental_agreement.client.email || "-" : "-"}
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        margin="10px"
                        display={"inline"}
                        check={((registration_data || {}).email || []).length > 0 ? registration_data.email[0].flag : null}
                        tooltipText={((registration_data || {}).email || []).length > 0 ? registration_data.email[0].description : null}
                    >
                    {((registration_data || {}).email|| [{}]).length > 1 ?
                        <Tooltip title={<p className="tooltipText">{formatBureauInfo(registration_data.email.slice(1), "emails")}</p>}>
                            <MoreHorizIcon style={{verticalAlign:"middle"}}/>
                        </Tooltip> : null}
                    </DataField>
                    </div>
                    <DataField
                        title={"Telefone"}
                        label={checkNested(props.rental_agreement, "client", "phones") ? 
                            props.rental_agreement.client.phones.length > 0 ? formatPhone(props.rental_agreement.client.phones[0], "-") : "-" : "-"
                        }
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        margin="10px"
                        check={((registration_data || {}).phone_number || []).length > 0 ? registration_data.phone_number[0].flag : null}
                        tooltipText={((registration_data || {}).phone_number || []).length > 0 ? registration_data.phone_number[0].description : null}
                    >
                        {checkNested(props.rental_agreement, "client", "phones") ? props.rental_agreement.client.phones.length > 0 ?
                        <Tooltip title={<p className="tooltipText">{translatePhoneType(props.rental_agreement.client.phones[0].type)}</p>} arrow placement="right">
                            <HelpIcon style={{fontSize: "14px", display:"flex", margin:"auto 5px"}}/>
                        </Tooltip> : null : null
                        }
                        {((registration_data || {}).phone_number || [{}]).length > 1 ?
                        <Tooltip title={<p className="tooltipText">{formatBureauInfo(registration_data.phone_number.slice(1), "telefones")}</p>}>
                            <MoreHorizIcon style={{verticalAlign:"middle"}}/>
                        </Tooltip> : null}
                        {validationData !== undefined ? 
                        <Tooltip title={<p className="tooltipText">{getValidationText(validationData)}</p>} arrow placement="right">
                            <div className={["circle", "littleSmall"].join(" ")} style={{backgroundColor: getValidationColor(validationData), marginLeft: "5px"}}>
                                <PhoneEnabledIcon style={{fontSize: "10px", display:"flex", margin:"auto 5px"}}/>
                            </div>
                        </Tooltip> : null}
                    </DataField>
                    <DataField
                        title={"Endereço"}
                        label={checkNested(props.rental_agreement, "client", "residential_address") ? formatAddress(props.rental_agreement.client.residential_address,"-") : "-"}
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        display={"inline"}
                        margin="10px"
                        check={((registration_data || {}).address || []).length > 0 ? registration_data.address[0].flag : null}
                        tooltipText={((registration_data || {}).address || []).length > 0 ? registration_data.address[0].description : null}
                    >
                       {checkNested(props.rental_agreement, "client", "residential_address") ?
                        <a
                            href={"https://www.google.com/maps/search/?api=1&query=" + 
                                formatAddress(props.rental_agreement.client.residential_address,"-").replace(/ /g, "%20").replace(/,/, '%2C')}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <MapIcon style={{verticalAlign: "middle", width: "20px", height:"20px",cursor:"pointer", color:"#777",margin: "auto 5px"}} />
                        </a>: null}
                        {((registration_data || {}).address || [{}]).length > 1 ?
                        <Tooltip title={<p className="tooltipText">{formatBureauInfo(registration_data.address.slice(1), "endereços")}</p>}>
                            <MoreHorizIcon style={{verticalAlign:"middle"}}/>
                        </Tooltip> : null}
                    </DataField>
                    <div style={{marginTop: "10px", marginBottom: "10px"}}>
                        <DataField
                            title={"Segmento do Cliente"}
                            label={checkNested(props.rental_agreement, "client", "segment") ? props.rental_agreement.client.segment || "-" : "-"}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="10px"
                            display={"inline"}
                        >
                        </DataField>
                    </div>

                </div>
                {pastRentals ? pastRentals.length > 0 ? 
                <ProfilePictureDialog
                    open={profilePictureOpen}
                    onClose={handleProfilePictureClose}
                    data={pastRentals}
                /> : null : null}
                {(decision_metadata || {}).creddefense_passages ? 
                (decision_metadata || {}).creddefense_passages.length > 0 ?
                <ProfilePictureDialogCD
                    open={profilePictureCDOpen}
                    onClose={handleProfilePictureCDClose}
                    ra={props.rental_agreement}
                />: null : null}
            </div>
        </div>
    );
}

export default ClientProfile;