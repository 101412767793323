import React from "react";
import { useTranslation } from "react-i18next";
import "../../../../../assets/styles/generalStyles.scss";

const FormField = (props) => {

  const { hasError, labelValue, onChange, onBlur, fieldValue} = props
  const { t } = useTranslation();
  
  const inputClasses = hasError ?
    ["normalText", "formControl", "invalid", "bold"].join(" ")
    : ["normalText", "formControl", "bold"].join(" ");

  const errorMessage = String(labelValue) + "_error_message"

  return (
  <div className={inputClasses}>
    <div>{t(labelValue)}</div>
    <input
      type={"text"}
      id={labelValue.replace("_","-")}
      onChange={onChange}
      onBlur={onBlur}
      value={fieldValue}
      disabled={props.disabled?props.disabled:false}
    />
    {hasError ? (
      <p className={"normalSmallSize__error"}>
        {t(errorMessage)}
      </p>
    ) : (
      <div className={"formMediumWhiteSpace"}></div>
    )}
  </div>
)}

export default FormField;
