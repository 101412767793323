import React from "react"
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {getConfirmationIcon} from "../../utils"

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    textAlign: "center",
    marginBottom: "10px",
    float: "left",
  }
}));

function FileDownloadErrorDialog(props) {
  const classes = useStyles();
  useTheme();

  const { errorType } = props
  
    if (errorType !== "") {
        return (
            <Dialog open={props.open} onClose={props.onClose} keepMounted={false}>
                <DialogContent className={classes.dialogContent}>
                    <div style={{ width: "330px", height:"300px"}}>
                        <div className="internalCardContainer" style={{display:"flex", height:"30%", justifyContent:"center"}}>
                            {getConfirmationIcon(false)}
                        </div>
                        <div className="internalCardContainer" style={{display:"flex", textAlign:"center", justifyContent:"center", flexDirection:"column"}}>
                            {errorType === "notFound" ?
                            <div>
                                <p style={{margin:0}}>Não foi possível localizar o seu arquivo.</p>
                                <p>Espere alguns minutos e tente novamente. Se o problema persistir entre em contato conosco através do email <b>suporte.caas@qitech.com.br</b>, informando esta mensagem.</p>
                            </div> :
                            <div>
                                <p style={{margin:0}}>Ocorreu um erro durante a geração do seu arquivo.</p>
                                <p>Por favor, entre em contato com nosso suporte através do email <b>suporte.caas@qitech.com.br</b>, informando esta mensagem.</p>
                            </div> }          
                        </div>
                        <div className="internalCardContainer" style={{display:"flex", justifyContent:"center"}}>
                            <div 
                                className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                                onClick={props.onClose}
                            >
                                VOLTAR À TELA INICIAL 
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
    else {
        return null
    }
}

export default FileDownloadErrorDialog;