import React from "react";
import { getCardIcon } from "../../../utils/utils"

function RiskAddressCard(props) {

    const {risk_address} = props

    return (
        <div className="analysisCard">
            <div style={{display:"flex"}}>
                <div 
                    className={["blueText", "subtitleSize", "bold"].join(" ")}
                    style={{margin:"auto 0px"}}
                >
                    Endereços de Risco
                </div>
                <div style={{margin:"auto 10px"}}>
                    {getCardIcon(risk_address.flag)}
                </div>                 
            </div> 
        </div>
        );
}

export default RiskAddressCard