import React, {useState} from "react";
import SelectLockTypeContent from "./SelectLockTypeContent"
import InputContent from "./InputContent"
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

function NewLockDialog(props) {
    let {open} = props
    let {onClose} = props
    let {handleRefreshPage} = props

    const [lockType,setLockType] = useState(null)
    const [personType,setPersonType] = useState(null)
    
    const handleDialogClose = () => {
      onClose()
      setTimeout(() => {
          setLockType(null)
          setPersonType(null)
      }, 500)
  }

    let dialogContent = null

    if (!lockType || !personType){
        dialogContent = <SelectLockTypeContent 
        setPersonType={setPersonType} 
        setLockType={setLockType}
        lockType={lockType}
        personType={personType}
        />
    }
    else (
        dialogContent = <InputContent 
            setLockType={setLockType}
            setPersonType={setPersonType}
            lockType={lockType}
            personType={personType}
            onClose={handleDialogClose}
            handleRefreshPage={handleRefreshPage}
        />
    )

    return (
        <Dialog 
        open={open} 
        onClose={handleDialogClose}
        disableBackdropClick
        keepMounted={false}>
            <DialogTitle 
                className={['blueText', "subtitleSize"].join(" ")} 
                onClose={handleDialogClose}
            >
                Novo Bloqueio
            </DialogTitle>
            <DialogContent >
                <div style={{width:"466px", minHeight: "220px", display:"flex", flexDirection:"column", marginBottom:"30px"}}>
                    {dialogContent}
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default NewLockDialog