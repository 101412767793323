//Importing modules
import React from 'react'



//Defining timer component
function Timer (props) {

    //Defining component to be rendered
    return (
        <div className={["blueText", "substitleSize"].join(" ")}>
            TEMPO RESTANTE:  {props.time.minutes < 10 ? `0${props.time.minutes}` : props.time.minutes}:{props.time.seconds < 10 ? `0${props.time.seconds}` : props.time.seconds}
        </div>
    )   
}



//Exporting Timer component
export default Timer;