import React, {useContext} from "react";
import FilterBox from "../../../Utils/FilterBox/FilterBox"
import CompaniesContext from "../../../../context/companies-context"

function Filter(props) {

    let companies = useContext(CompaniesContext).onboarding_list
	
    const { setPageParams } = props
    const { initialFilters } = props

    let filterTypes = {
        lock_date: {
            type: "date",
            disabled: false,
            validation:false,
            description: "Data"
        },
        merchant_id: {
            type: "string",
            disabled: false,
            validation:false,
            description: "ID do Merchant"
        },
        acquirer_id: {
            type: "string",
            disabled: false,
            validation:false,
            description: "ID do Adquirente"
        },
        terminal_id: {
            type: "string",
            disabled: false,
            validation:false,
            description: "ID do Terminal"
        },
        bin: {
            type: "masked_string",
            maskType:"999999",
            disabled: false,
            validation:true,
            validation_rule: {type: "equal", value:6},
            description: "BIN do Cartão"
        },
        last4: {
            type: "masked_string",
            maskType:"9999",
            disabled: false,
            validation:true,
            validation_rule: {type: "equal", value:4},
            description: "Last4 do Cartão"
        },
        cardholder_id: {
            type: "string",
            disabled: false,
            validation:false,
            description: "ID do CardHolder"
        },
        lock_source: {
            type: "enum",
            enumList: [
                ["Nenhum", ""],
                ["Manual", "manually_input"],
                ["Por Alerta Manual", "manually_alert"],
                ["Por Alerta Automático", "automatically_alert"]
            ],
            disabled: false,
            validation:false,
            description: "Fonte do Bloqueio"
            },
        lock_type: {
            type: "enum",
            enumList: [
                ["Nenhum", ""],
                ["Cartão/Usuário", "card"],
                ["Merchant", "merchant"],
                ["Terminal", "terminal"]
            ],
            disabled: false,
            validation:false,
            description: "Tipo do Bloqueio"
            },
        lock_status: {
            type: "enum",
            enumList: [
                ["Nenhum", ""],
                ["Bloqueados", "locked"],
                ["Desbloqueados", "unlocked"]
            ],  
            disabled: false,
            validation:false,
            description: "Status"
        }
    }


    //TODO JUST ADD IF REQUEST COMPLETES BEFORE LOCKSINBOX FIRST MOUNT
    //Maybe if you clean storage inside this if block it will work as expected
    if (companies){
        let enumList = companies.companies.sort((a, b) => a.name === b.name ? 0 : a.name > b.name ? 1 : -1).map(company =>{
            return [company.name, company.company_key]
        })
        filterTypes['company_key'] = {
            type: "enum",
            enumList: [
                ["Nenhum", ""],
                ...enumList
            ],  
            disabled: false,
            validation:false,
            description: "Cliente"
        }
    }

    return (
        <FilterBox 
            setPageParams={setPageParams}
            filterTypes={filterTypes}
            local_storage_object_preffix={props.local_storage_object_preffix}
            initialFilters={initialFilters}
        />
    )

}

export default Filter