import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {getTableRowClass, titleCase} from "../../../utils/utils"
import getSymbolFromCurrency from 'currency-symbol-map'
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles((theme) => ({
    tableStyle: {
      border: "none",
      boxShadow: "none",
      width:"100%"
    },
    blueText: {
      fontFamily: "Open Sans",
      fontWeight: "600",
      color: "#0b1f82"
  },
    tableText: {
      fontFamily: "Open Sans",
      fontSize: "14px",
      lineHeight: "19px",
      textAlign: "center",
      margin: "10px",
      padding: "6px",
    },
    smallText: {
        fontFamily: "Open Sans",
        fontSize: "12px",
        lineHeight: "16px",
        textAlign: "center",
        margin: "10px",
        padding: "6px",
      },
    link:{
        cursor: "pointer",
        "&:hover": {
            textDecoration: "underline",
          },
    },
    tableHeader: {
      textAlign: "center",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: "normal",
      color: '#6F6F6F',
      fontSize: "16px",
      lineHeight: "22px",
      padding: "30px",
    }
}));

function ProductsDialog (props) {
    const classes = useStyles();
    const currency = props.order.payment.currency

    useTheme();

    return (
        <Dialog
            open={props.open} 
            onClose={props.onClose} 
            keepMounted={false}
            maxWidth={"md"}
            marginBotto
        >
        <DialogTitle className={["blueText", "subtitleSize"].join(" ")}>Lista de Produtos</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Paper className={classes.tableStyle} style={{marginBottom:"15px"}}>
                    <Table style={{ padding: "8px" }}>
                        <TableHead>
                            <TableRow>
                            <TableCell className={classes.tableHeader}>Produto</TableCell>
                            <TableCell className={classes.tableHeader}>Quantidade</TableCell>
                            <TableCell className={classes.tableHeader}>Valor Unitário</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.order.products.map((product,index) => (
                            <TableRow
                                key={index}
                                className={getTableRowClass(index)}
                            > 
                                <TableCell className={[classes.tableText, "breakWord"].join(" ")}>
                                    {titleCase(product.name,"-")}
                                </TableCell>
                                <TableCell className={classes.tableText}>
                                {product.quantity == null ? "-" : product.quantity}    
                                </TableCell>
                                <TableCell className={classes.tableText}>
                                {product.unit_cost == null ? "-" : getSymbolFromCurrency(currency) + " " + (product.unit_cost/100).toLocaleString('de-DE', {minimumFractionDigits: 2})}
                                </TableCell>  
                            </TableRow>
                            ))}
                        </TableBody>
                    </Table>
            </Paper>
        </DialogContent>
        </Dialog>
      );
}

export default ProductsDialog