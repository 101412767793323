import React from "react";
import { getBranchesInArrayFormat } from "../../../PIX/utils/utils";
import FilterBox from "../../../Utils/FilterBox/FilterBox"

function Filter(props) {
	
    const { setPageParams } = props

    const enumISPBList = getBranchesInArrayFormat()

    let filterTypes = {
        client_document_number: {
            type: "brazilian_document_numbers",
            disabled: false,
            validation:true,
            validation_rule: {type: "multiple_equal", values:[14,18]},
            description: "CPF/CNPJ"
        },
        wire_transfer_date: {
            type: "date",
            disabled: false,
            validation:false,
            validation_rule: null,
            description: "Data"
        },
        external_id: {
            type: "string",
            disabled: false,
            validation:false,
            validation_rule: null,
            description: "Número da Transferência"
        },
        lower_amount_limit: {
            type: "value",
            disabled: false,
            validation:false,
            validation_rule: null,
            description: "Valor Mínimo"
        },
        upper_amount_limit: {
            type: "value",
            disabled: false,
            validation:false,
            validation_rule: null,
            description: "Valor Máximo"
        },
        source_account_participant: {
            type: "enum",
            enumList: enumISPBList,
            disabled: false,
            validation:false,
            validation_rule: null,
            description: "Instituição de Origem"
        },
        destination_account_participant: {
            type: "enum",
            enumList: enumISPBList,
            disabled: false,
            validation:false,
            validation_rule: null,
            description: "Instituição de Destino"
        },
        wire_transfer_status: {
            type: "enum",
            enumList: [
                ["Nenhum", ""],
                ["Esperando Processamento", "created"],
                ["Processada", "completed"]
            ],
            disabled: false,
            validation:false,
            validation_rule: null,
            description: "Status da Transferência"
          },
        wire_transfer_type: {
        type: "enum",
        enumList: [
            ["TED", "ted"],
            ["DOC", "doc"],
            ["Transferência Interna", "internal_transfer"]
        ],
        disabled: false,
        validation:false,
        validation_rule: null,
        description: "Tipo da Transferência"
        },
        analysis_status: {
            type: "enum",
            enumList: [
                ["Nenhum", ""],
                ["Pendente", "pending"],
                ["Aprovado Manualmente", "manually_approved"],
                ["Reprovado Manualmente", "manually_reproved"],
                ["Aprovado Automaticamente", "automatically_approved"],
                ["Reprovado Automaticamente", "automatically_reproved"],
                ["Aprovado por tempo", "approved_by_time"]
            ],
            disabled: false,
            validation:false,
            description: "Status da Análise"
        },
        fraud_status: {
            type: "enum",
            enumList: [
                ["Reportada como fraude", "fraud"],
                ["Não Reportada como fraude", "created"],                
            ],
            disabled: false,
            validation:false,
            description: "Status de Fraude"
        }
    }

    return (
        <FilterBox 
            setPageParams={setPageParams}
            filterTypes={filterTypes}
            local_storage_object_preffix={props.local_storage_object_preffix}
        />
    )

}

export default Filter