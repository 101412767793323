import React from "react";
import {boavista_translations} from "../../../../utils/utils";
import BoavistaNegativeNotesTable from "./BoavistaNegativeNotesTable/BoavistaNegativeNotesTable"
import { useTranslation } from "react-i18next";
import BoavistaProtestsSummaryCard from "../BoavistaSummaryCard/BoavistaProtestsSummaryCard";
import BoavistaDebtsSummaryCardToPDF from "../BoavistaSummaryCard/BoavistaDebtsSummaryCardToPDF";
import BoavistaInquiriesSummaryCardToPDF from "../BoavistaSummaryCard/BoavistaInquiriesSummaryCardToPDF";

function BoavistaNegativeNotesCardToPDF(props) {

    const { 
        negative_notes_data,
        has_negative_note,
        negative_note_type,
        tableStyle,
        boavistaInquiries2Img
    } = props

    const { t } = useTranslation()
    
    return (
        <div className="avoidPageBreakInside" style={{padding:"10px"}}>
            <div className="analysisCard">
                <div style={{display:"flex"}}>
                    <div 
                        className={["blueText", "subtitleSize", "bold"].join(" ")}
                        style={{margin:"auto 10px"}}
                    >
                        {t(negative_note_type)}
                    </div>
                    <div style={{margin:"auto 10px"}}>
                        {has_negative_note ?  boavista_translations.negative.siglum : boavista_translations.positive.siglum}
                    </div>
                </div>
                {has_negative_note &&
                <div style={{gridTemplateColumns: tableStyle}}>
                    {(negative_note_type==="debts")&&
                        <BoavistaDebtsSummaryCardToPDF
                            debts={negative_notes_data}
                        />}
                    {(negative_note_type==="inquiries")&&
                        <BoavistaInquiriesSummaryCardToPDF
                            inquiries={negative_notes_data}
                            boavistaInquiries2Img = {boavistaInquiries2Img}
                        />}
                    {(negative_note_type==="protests")&&
                        <BoavistaProtestsSummaryCard protests={negative_notes_data}/>}
                    <div className={["normalText", "normalMediumSize", "internalCardContainer"].join(" ")}>
                        <BoavistaNegativeNotesTable negative_notes_data={negative_notes_data} negative_note_type={negative_note_type} isPDF={true}/>
                    </div>
                </div>}
            </div>
        </div>
    );
}

export default BoavistaNegativeNotesCardToPDF