import React from 'react'
import moment from "moment";
import {colorRed} from "../../../utils/utils"

function Timer (props) {

    let { time, limit_date} = props

    if (time.minutes >= 1){
        return (
            <div className={["blueText", "substitleSize"].join(" ")}>
                Prazo Limite: {moment(limit_date).format("HH:mm:ss").toString()}
            </div>
        )   
    }
    else if (time.seconds <0 || (time.minutes ===0 && time.seconds ===0)){
        return null
    }
    else {
        return (
            <div className={["normalText", "substitleSize", "bold"].join(" ")} style={{color: colorRed}}>
                Tempo Restante: {time.minutes}:{time.seconds < 10 ? `0${time.seconds}` : time.seconds}
            </div>
        )
    }

}

export default Timer;