import React from 'react'
import DataField from "../../utils/DataField"
import { formatAsMoney } from "../../utils/utils"
import { useTranslation } from "react-i18next";

function CreditProposalCard(props) {

    const { t } = useTranslation();
    
    let { credit_analysis } = props
    
    return (
        <div className="analysisCard">
            <div className={["blueText", "subtitleSize"].join(" ")} style={{marginBottom: "10px"}}>{t("credit_proposal")}</div>
            <div style={{display: "flex", flexDirection: "column"}}>
                {(credit_analysis||{}).financial&&
                <DataField
                    title={t("credit_proposal_amount")}
                    label={formatAsMoney(credit_analysis.financial.currency, credit_analysis.financial.amount)}
                    size={"normalMediumSize"}
                    titleNoWrap={false}
                />}
                {(credit_analysis||{}).financial&&(credit_analysis.financial||{}).number_of_installments&&
                <>
                <DataField
                    title={t("number_of_installments")}
                    label={credit_analysis.financial.number_of_installments}
                    size={"normalMediumSize"}
                    titleNoWrap={false}
                />
                <DataField
                    title={t("first_installment")}
                    label={!!credit_analysis.installments_enrichment ? formatAsMoney("BRL", credit_analysis.installments_enrichment[0].total_amount): t("not_available")}
                    size={"normalMediumSize"}
                    titleNoWrap={false}
                />
                </>}
                {(credit_analysis||{}).financial&&(credit_analysis.financial||{}).interest_type &&
                <DataField
                    title={t("interest_type")}
                    label={t(credit_analysis.financial.interest_type)}
                    size={"normalMediumSize"}
                    titleNoWrap={false}
                />}
                {(credit_analysis||{}).financial&&(credit_analysis.financial||{}).annual_interest_rate&&
                <DataField
                    title={t("annual_interest_rate")}
                    label={credit_analysis.financial.annual_interest_rate ? credit_analysis.financial.annual_interest_rate + " %" : "0 %"}
                    size={"normalMediumSize"}
                    titleNoWrap={false}
                />}
                {(credit_analysis||{}).financial&&(credit_analysis.financial||{}).cdi_percentage&&
                <DataField
                    title={t("cdi_percentage")}
                    label={credit_analysis.financial.cdi_percentage ? credit_analysis.financial.cdi_percentage + " %" : "0 %"}
                    size={"normalMediumSize"}
                    titleNoWrap={false}
                />}
                {(credit_analysis||{}).credit_proposal_status&&
                <DataField
                    title={t("situation")}
                    label={t(credit_analysis.credit_proposal_status)}
                    size={"normalMediumSize"}
                    titleNoWrap={false}
                />}
                {credit_analysis.credit_type&&
                <DataField
                    title={t("credit_type")}
                    label={t(credit_analysis.credit_type)}
                    size={"normalMediumSize"}
                    titleNoWrap={false}
                    labelClasses={["bold"]}
                />}
                <DataField
                    title={t("credit_proposal_date_label")}
                    label={t("request_date", {request_date: credit_analysis.credit_request_date})}
                    size={"normalMediumSize"}
                    titleNoWrap={false}
                />
            </div>
        </div>
    )
}
export default CreditProposalCard