//Importing modules
import React, { useReducer, useEffect, useState } from 'react';
import SharedListPermissionEditionDialog from "./SharedListPermissionEditionDialog/SharedListPermissionEditionDialog";
import SharedListPermissionCreationDialog from "./SharedListPermissionCreationDialog/SharedListPermissionCreationDialog";
import { CircularProgress } from '@material-ui/core';
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import peopleIcon from '../../../../assets/cardEntryIcons/people.svg';
import axios from "axios";
import {dataFetchReducer} from "../../../Utils/requestUtils";
import SharedListPermissionTable from "./SharedListPermissionTable/SharedListPermissionTable";



//Defining List Contents Card component
function SharedListPermissionCard(props) {

    //Extracting data from props
    let { list, userData, companyKey, businessGroupCompanies} = props


    //Creating object to represent sharedListPermissions state
    const [sharedListPermissions, setSharedListPermissions] = useState([])


    //Defining state to trigger table reload
    const [reloadSharedListPermissionsTable, setReloadSharedListPermissionsTable] = useState(false)

    const [expanded, setExpanded] = useState(false);

    const handleExpand = (event) => {
        event.stopPropagation();
        setExpanded(!expanded);
    };

    //Defining function to check if any item of the table is selected
    const checkItemSelected = () => {
        let isItemSelected = sharedListPermissions.reduce((acc,item) => {
            if (acc === false && 
                ((item.edit_can_read === true && item.can_read === true) ||
                (item.edit_can_write === true && item.can_write === true))) {
                acc = true
            }
            return acc
        },false)
        return isItemSelected
    }

    //Defining SharedListPermissionCreationDialog open a close actions
    const [openSharedListPermissionCreationDialog, setOpenSharedListPermissionCreationDialog] = useState(false);
    const handleOpenSharedListPermissionCreationDialog = () => {
        setOpenSharedListPermissionCreationDialog(true)
    }
    const handleCloseSharedListPermissionCreationDialog = () => {
        setOpenSharedListPermissionCreationDialog(false)
    }


    //Defining SharedListPermissionEditionDialog open and close actions
    const [openSharedListPermissionEditionDialog, setOpenSharedListPermissionEditionDialog] = useState(false);
    const handleOpenSharedListPermissionEditionDialog = () => {
        setOpenSharedListPermissionEditionDialog(true)
    }
    const handleCloseSharedListPermissionEditionDialog = () => {
        setOpenSharedListPermissionEditionDialog(false)
    }


    //Making request to Dash API to retrieve shared list permission information
    const [sharedListPermissionData, dispatchSharedListPermissionData] = useReducer(
        dataFetchReducer,
        {fetchedData: null, isLoading: true, isError: false} 
    )

    useEffect(() => {
        dispatchSharedListPermissionData({type: "data_fetch_init"})
        let requestHeaders = {headers:{}}
        if (userData.business_group_key) {
            requestHeaders = {headers:{company_key:companyKey}}
        }
		const timer = setTimeout(() => {
			axios.get('/dash/lists/' + list.list_key + '/shared_list_permissions', requestHeaders).then( response => {
                let initialSharedListsPermissionState = response.data.reduce((acc,item) => {
                    let currentSharedListsPermissionObject = {...item, edit_can_read:!item.can_read, edit_can_write:!item.can_write}
                    acc = [...acc,currentSharedListsPermissionObject]
                    return acc
                },[])
                initialSharedListsPermissionState = initialSharedListsPermissionState.filter((company)=>(company.company_key !== list.company_key))
                setSharedListPermissions(initialSharedListsPermissionState)
                dispatchSharedListPermissionData({
                    type: "data_fetch_success",
                    payload: response.data
                })
			}).catch(error => {
                if ((error.response || {}).status === 403) dispatchSharedListPermissionData({type: "data_fetch_failure_403"})
                else if ((error.response || {}).status === 404) dispatchSharedListPermissionData({type: "data_fetch_failure_404"}) 
                else dispatchSharedListPermissionData({type: "data_fetch_failure"})    
			})	
        }, 500);
		return () => {
            setReloadSharedListPermissionsTable(false)
			clearTimeout(timer)
        }
    }, [list, reloadSharedListPermissionsTable, userData, companyKey])


    //Making request to Dash API to retrieve all companies in the same business group
    const [businessGroupCompaniesData, dispatchBusinessGroupCompaniesData] = useReducer(
        dataFetchReducer,
        {fetchedData: null, isLoading: true, isError: false} 
    )

    useEffect(() => {
        if (userData.business_group_key != null) {
            dispatchBusinessGroupCompaniesData({type: "data_fetch_init"}) 
            setTimeout(() => {
                if (businessGroupCompanies == null) {
                    dispatchBusinessGroupCompaniesData({type: "data_fetch_failure"}) 
                }
                dispatchBusinessGroupCompaniesData({
                    type: "data_fetch_success",
                    payload: businessGroupCompanies
                })
            }, 500)
        }
        else {
            dispatchBusinessGroupCompaniesData({type: "data_fetch_init"}) 
            const timer = setTimeout(() => {
                axios.get('/dash/company/lists_api/companies').then( response => {
                    dispatchBusinessGroupCompaniesData({
                        type: "data_fetch_success",
                        payload: response.data
                    })
                }).catch(error => {
                    if ((error.response || {}).status === 403) dispatchBusinessGroupCompaniesData({type: "data_fetch_failure_403"})
                    else if ((error.response || {}).status === 404) dispatchBusinessGroupCompaniesData({type: "data_fetch_failure_404"})  
                    else dispatchBusinessGroupCompaniesData({type: "data_fetch_failure"})    
                })	
            }, 500);
            return () => {
                setReloadSharedListPermissionsTable(false)
                clearTimeout(timer)
            }
        }
    }, [list, businessGroupCompanies, userData])

    
    let unpermissionedCompanies = () => {
        if (sharedListPermissionData.fetchedData && businessGroupCompaniesData.fetchedData) {
            return businessGroupCompaniesData.fetchedData.reduce((acc,currentValue)=>{
                let checkPermissions = sharedListPermissions.reduce((innerAcc1, innerCurrentValue1) => {
                    if (innerCurrentValue1.company_key === currentValue.company_key || currentValue.company_key === companyKey){
                        innerAcc1 = true
                    }
                    return innerAcc1
                }, false)
                if (!checkPermissions) {
                  let newPermissionObject  = {
                    company_name: currentValue.company_name,
                    company_key: currentValue.company_key,
                    can_read: false,
                    can_write: false
                  }
                    acc = [...acc, newPermissionObject]
                }
                return acc.filter((company)=>(company.company_key !== list.company_key))
            },[])
        }
        else {
            return []
        }
    }


    //Rendering card depending on request response
    if(sharedListPermissionData.isLoading || businessGroupCompaniesData.isLoading){
		return (
			<div className="analysisCard">
                <div style={{display:"flex", height:"300px"}}>
                    <CircularProgress style={{margin:"auto"}} />
                </div>
			</div>
		)
    }
    else if (sharedListPermissionData.fetchedData) {
        return(
            <div className="analysisCard" style={{width:"100%", flexGrow:"1"}}>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div style={{display: "flex"}}>
                        <img className="cardTitleIconStyle" src={peopleIcon} alt="Icon Logo" />
                        <span className={["blueText", "subtitleSize", "labelSpace", "breakWord"].join(" ")}>Permissões de Acesso à Lista</span>
                    </div>
                    <IconButton onClick={handleExpand}>
                        {expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </IconButton>
                </div>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <div className={"internalCollapse"}>
                        <div style={{display: "flex", justifyContent:"flex-end", marginTop: "10px"}}>
                            {businessGroupCompaniesData.isError ? null :
                            unpermissionedCompanies().length > 0 ? 
                            <div 
                                style={{ padding: "5px 30px", margin:"5px" }}
                                className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                                onClick={handleOpenSharedListPermissionCreationDialog}
                            >
                                Gerenciar Permissões
                            </div> : null}
                        </div>
                        <div style={{display:"flex", marginTop:"10px",flexDirection:"column"}}>
                            {sharedListPermissionData.fetchedData.length === 0 ? 
                            <div style={{display:"flex", margin:"20px auto", flexDirection:"column", verticalAlign: "center", textAlign: "center"}}
                            className={["blueText"].join(" ")}>
                                <span>A lista está vazia</span>
                            </div> :
                            <SharedListPermissionTable setSharedListPermissions={setSharedListPermissions} sharedListPermissions={sharedListPermissions}/>}
                        </div>
                        <div style={{display: "flex", justifyContent:"flex-end", marginTop: "10px"}}>
                            {sharedListPermissionData.fetchedData.length === 0 ? null : 
                            <div 
                                style={{ padding: "5px 50px", margin:"5px" }}
                                className={checkItemSelected() ?
                                    ["button", "standard", "normalText", "normalMediumSize"].join(" ") :
                                    ["button", "disabled", "standard", "normalText", "normalMediumSize"].join(" ")}
                                onClick={checkItemSelected() ?
                                        handleOpenSharedListPermissionEditionDialog:
                                        null}
                            >
                                Salvar Edições
                            </div>}
                        </div>
                    </div>
                </Collapse>
                <SharedListPermissionCreationDialog
                    sharedListPermissions={sharedListPermissions}
                    unpermissionedCompanies={unpermissionedCompanies()}
                    list_key={list.list_key}
                    companyKey={companyKey}
                    userData={userData}
                    open={openSharedListPermissionCreationDialog}
                    setReloadSharedListPermissionsTable = {setReloadSharedListPermissionsTable}
                    onClose={handleCloseSharedListPermissionCreationDialog}
                />
                <SharedListPermissionEditionDialog
                    sharedListPermissions={sharedListPermissions}
                    list_key={list.list_key}
                    companyKey={companyKey}
                    userData={userData}
                    open={openSharedListPermissionEditionDialog}
                    setReloadSharedListPermissionsTable = {setReloadSharedListPermissionsTable}
                    onClose={handleCloseSharedListPermissionEditionDialog}
                />
            </div>
        )
    }
    else if(sharedListPermissionData.isError){
		return (
			<div className="analysisCard">
				<p className={["blueText", "titleSize"].join(" ")} style={{margin:"auto", textAlign:"center"}}>
					{sharedListPermissionData.errorMessage}
				</p>
			</div>
		)
    }
    else return null;

}

//Exporting ListContentsCard as default
export default SharedListPermissionCard