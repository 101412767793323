import React from "react";
import { useTranslation } from "react-i18next";

const FormField = (props) => {

  const { labelValue, onChange, fieldValue} = props
  const { t } = useTranslation();
  
  const inputClasses = props.hasError ?
    ["normalText", "formControl", "invalid", "bold"].join(" ")
    : ["normalText", "formControl", "bold"].join(" ");

  return (
  <div className={inputClasses} style={{...props.formFieldStyle}}>
    <div>{t(labelValue)}</div>
    <div className="labelText normalSmallSize">
      {t(props.labelDescriptionValue)}
    </div>
    <input
      type={"text"}
      id={labelValue}
      onChange={onChange}
      onBlur={props.onBlur?props.onBlur:null}
      value={fieldValue}
      style={{...props.inputStyle}}
    />
    {props.hasError ? (
      <p className={"normalSmallSize__error"}>
        {t(props.errorMessage)}
      </p>
    ) : (
      <div className={"formMediumWhiteSpace"}></div>
    )}
  </div>
)}

export default FormField;
