import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #cccc;
    border-radius: 10px;
    padding: 21px;
    display:flex;
    flex-grow: 1;
    flex-direction: column;
`;

export const ImageWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Title = styled.div`
    color: #0b1f82;
    font-size: 16px;
    margin-bottom: 16px;
    font-weight: bold;
    font-family: 'Open Sans';
`;