import React from "react";

function Reason(props) {

    const { manual_analysis_reason, time_reason, automatic_analysis_reason } = props

    return (
        <>
            {manual_analysis_reason&&
            <div style={{ margin: "10px 0px" }}>
                <span style={{ margin: "auto 5px auto 0px" }} className="normalMediumSize normalText">
                    {"Razão da derivação"}:
                </span>
                <span className="normalMediumSize labelText" style={{display:"inline-block", wordBreak:"break-all"}}>
                    {manual_analysis_reason}
                </span>
            </div>}
            {time_reason&&
            <div style={{ margin: "10px 0px" }}>
                <span style={{ margin: "auto 5px auto 0px" }} className="normalMediumSize normalText">
                    {"Razão da decisão por tempo"}:
                </span>
                <span className="normalMediumSize labelText" style={{display:"inline-block", wordBreak:"break-all"}}>
                    {time_reason}
                </span>
            </div>}
            {automatic_analysis_reason&&
            <div style={{ margin: "10px 0px" }}>
                <span style={{ margin: "auto 5px auto 0px" }} className="normalMediumSize normalText">
                    {"Razão da decisão"}:
                </span>
                <span className="normalMediumSize labelText" style={{display:"inline-block", wordBreak:"break-all"}}>
                    {automatic_analysis_reason}
                </span>
            </div>}
        </>
        
    );
}

export default Reason;
