import React, { useEffect } from "react";
import { CircularProgress } from '@material-ui/core';
import axios from "axios";
import SellerHistoricTable from "./SellerHistoricTable/SellerHistoricTable"
import Pagination from "@material-ui/lab/Pagination";
import historyClockIcon from '../../../../assets/cardEntryIcons/clockHistoryBlue.svg';


function SellerHistoricCard(props) {

    const order_key       = props.order.order_key
    const document_number = props.order.seller.document_number
    const occurrence_date = props.order.order_date
  
    const [sellerHistoric, setSellerHistoric] = React.useState(null)
    const [tablePage, setTablePage] = React.useState(1)
  
    useEffect(() => {
        if (sellerHistoric === null){
            if (document_number == null) setSellerHistoric(undefined)
            else{
                let params = {
                    "page_number": tablePage -1,
                    "page_rows": 10,
                    "seller_document_number": document_number,
                    "final_date": occurrence_date
                }
                const timer = setTimeout(() => {
                    axios.get('/dash/card_order/orders', {
                        params: params
                    }).then(response=>{
                        let filteredOrders = response.data.data.filter(order=>order.order_key !== order_key)
                        let filteredResponseObject = {...response.data, data: filteredOrders}
                        setSellerHistoric(filteredResponseObject)
                    }).catch(error=>{
                        setSellerHistoric(undefined)
                    })
                }, 500);
                return () => {
                    clearTimeout(timer)
                }
            }
        }
    }, [sellerHistoric,tablePage, order_key,document_number,occurrence_date])

    const handleChangePage = (event, page) => {
		if (tablePage === page) {
			return
		}
        setTablePage(page)
        setSellerHistoric(null)
	}
  
    if (sellerHistoric === null) {
        return (
          <div className="analysisCard">
              <div style={{display: "flex"}}>
                    <img className="cardTitleIconStyle" src={historyClockIcon} alt="historyClockIcon Logo" />
                    <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>{"Histórico do Vendedor"}</span>
              </div>
              <div className="internalCardContainer">
                  <div style={{display: "flex", height: "160px"}}>
                      <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                          <CircularProgress />
                      </div>
                  </div>       
              </div>
          </div>
        );    
      }
      else if (sellerHistoric === undefined){
        return (
          <div className="analysisCard">
              <div style={{display: "flex"}}>
                    <img className="cardTitleIconStyle" src={historyClockIcon} alt="historyClockIcon Logo" />
                    <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>{"Histórico do Vendedor"}</span>
              </div>
              <div className="internalCardContainer">
                  <div style={{display: "flex", height: "160px"}}>
                      <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                          Informação Indisponível
                      </div>
                  </div>        
              </div>
          </div>
        )
      }
      else {
        return (
          <div className="analysisCard">
            <div style={{display: "flex"}}>
                <img className="cardTitleIconStyle" src={historyClockIcon} alt="historyClockIcon Logo" />
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>{"Histórico do Vendedor"}</span>
            </div>
            {sellerHistoric.data.length === 0 ?
            <div className="internalCardContainer" style={{margin:"auto"}}>
                <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                    Nenhum histórico do vendedor foi localizado
                </div>
            </div> :             
            <div className="internalCardContainer">
                  <div>
                      <div style={{marginTop: "5px"}}>
                          <SellerHistoricTable data={sellerHistoric.data} />
                      </div>
                      <div style={{display:"flex", marginTop: "10px"}}>
                          <Pagination style={{marginLeft:"auto"}} className='pagination' page={tablePage} count={sellerHistoric['number_of_pages']} onChange={(e,page) => handleChangePage(e, page)} />
                      </div>
                  </div> 
            </div> 
            }
        </div>
        );
      }
  }
  
export default SellerHistoricCard;