import React, { useState, useEffect } from "react";
import Chart from "chart.js/auto";

function DoughnutChart(props) {

  const [chart, setChart] = useState();

  useEffect(() => {

    if(chart){
      chart.destroy()
    }

    const ctx = document.getElementById(props.id).getContext('2d', {willReadFrequently: true})

    const data = {
        labels: props.labels,
        datasets: [{
          data: props.dataValue, 
          backgroundColor: props.backgroundColor,
          hoverOffset: 4
        }]
    }
    
    const DoughnutChart = new Chart(ctx, {
      type: 'doughnut',
      options: { responsive: true, maintainAspectRatio: false, cutout: props.cutout },
      data: data,
    });

    setChart(DoughnutChart)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    return (
        <div>
            <div style={{display: "flex", flexDirection: "column",  alignItems: "center"}}>
                <div style={{position: 'relative', zIndex: 1}}> 
                    <canvas id={props.id} width={props.size} height={props.size}></canvas>
                </div>
                {props.score && <div style={{width: props.size, height: props.size, position: 'relative', zIndex: 1, display: "flex", justifyContent: "center", alignItems: "center", fontSize: props.label1size, color: props.labelColor1, marginTop: props.marginTop}}>{props.score}</div>}
                {props.scoreMax && <div className={["labelText","centerText"].join(" ")} style={{width: props.size, height: props.size, position: 'relative', zIndex: 2, display: "flex", justifyContent: "center", fontSize: props.label2size, alignItems: "center", margin: "15px"}}>{props.scoreMax}</div>}
                <div className={["normalMediumSize","centerText"].join(" ")} style={{marginTop: props.size, width: props.size, display: 'flex', padding: '5px', flexDirection: 'column'}}>
                    {props.subtitle}
                    <br/>
                    <span style={{marginTop: '10px'}}>{props.subtitle2}</span>
                </div> 
            </div>
        </div>
    );
  }
  

export default DoughnutChart;

