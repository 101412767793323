import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ValidatedImage from "../ValidatedImage"
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { t } from "i18next";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: "16px",
    color: "#0b1f82",
    padding: "16px 24px 0px 24px",
  },
  dialogContent: {
    textAlign: "center",
    marginBottom: "10px",
    float: "left",
  },
  cardSlider: {
    width: "300px",
    height: "75%",
    margin: "auto auto 0 auto",
    padding: "auto",
  },
  centralizeArrow: {
    height: "40%",
  },
  arrows: {
    color: "#0b1f82",
  },
  mainCard: {
    position: "relative",
    display: "inline-block",
    textAlign: "center",
    width: "auto",
    height: "auto",
    float: "center",
    margin: "auto",
  },
  buttonPrevious: {
    cursor: "pointer",
    width: "25%",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: "#ddddf9",
    },
  },
  buttonNext: {
    cursor: "pointer",
    width: "25%",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: "#ddddf9",
    },
  },
  label: {
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "19px",
    color: "#000",
  },
  data: {
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "19px",
    color: "#6F6F6F",
  },
  paragraph: {
    margin: "0",
    width: "100%",
  },
}));

function OCRDialog(props) {
  const classes = useStyles();
  useTheme();

  const { name, open, onClose, source_image, fileType } = props

  return (
        <Dialog open={open} onClose={onClose} keepMounted={false}>
            <DialogTitle className={classes.dialogTitle} style={{marginBottom: "8px"}}>{t(name)}</DialogTitle>
            <DialogContent className={classes.dialogContent} style={{ width: "600px", height: "600px"}}>
                <div style={{display:"flex", margin:"auto", justifyContent: "center", width: "100%", height: "100%"}}>
                    <div className="labelSpace" style={{order: "2", display:"flex", width: "100%", height: "100%"}}>
                        {source_image ?
                        <ValidatedImage
                          image_key={source_image}
                          dash_api_endpoint_url={'/dash/ocr/image'}
                          imageStyle = {{maxWidth: "750px", maxHeight: "750px", borderRadius: "5px"}}
                          imageAlt = {t(name)}
                          fileType = {fileType}
                        /> :
                        <div 
                            className={["labelText", "normalMediumSize"].join(" ")} 
                            style={{textAlign:"center", margin:"auto"}}
                        >
                            {t("information_unavailable")}
                        </div>                        
                        }
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default OCRDialog;
