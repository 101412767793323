import React from "react"
import QITechFavicon from "../../assets/favicon/favicon.ico"

function getFaviconEl() {
    return document.getElementById("favicon");
}

function getTitleEl() {
    return document.getElementById("page-title");
}

function Favicon() {

    const handleQITech = () => {
        const favicon = getFaviconEl(); 
        favicon.href = QITechFavicon
        const title = getTitleEl();
        title.innerText = "QITech"
    };

    
      return (
          <div>
            {handleQITech()}
          </div>
      )  
}

export default Favicon