import React from 'react'
import moment from "moment";
import {colorLightRed, colorLightGrey} from "../../../utils/utils"

function Timer (props) {

    if (props.time.minutes >= 1){
        return (
            <div className="normalText normalMediumSize">
                <span >Prazo Limite:</span>
                <span className="bold" style={{backgroundColor: colorLightGrey, borderRadius:"4px", margin:"5px 0px", padding:"5px"}}>{moment(props.ra_limit).format("HH:mm:ss").toString()}</span>
            </div>
        )   
    }
    else if (props.time.seconds <0 || (props.time.minutes ===0 && props.time.seconds ===0)){
        return null
    }
    else {
        return (
            <div className="normalText normalMediumSize">
                <span >Tempo Restante:</span>
                <span className="bold" style={{backgroundColor: colorLightRed, borderRadius:"4px", margin:"5px 0px", padding:"5px"}}>{props.time.minutes}:{props.time.seconds < 10 ? `0${props.time.seconds}` : props.time.seconds}</span>
            </div>
        )
    }

}

export default Timer;