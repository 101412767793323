import React, { useEffect, useCallback, useReducer, useState } from "react";
import { Switch } from '@mui/material';
import { CircularProgress } from "@material-ui/core";
import { dataFetchReducer } from "../../Utils/requestUtils";
import axios from "axios";
import { useTranslation } from "react-i18next";

function UnicoBypassSwitch() {
    const {t} = useTranslation()

    const [checked, setChecked] = useState(false);
    const [alertError, setAlertError] = useState(false);

    const [pageState, dispatchPageState] = useReducer(
        dataFetchReducer,
        { fetchedData: null, isLoading: true, isError: false }
    );

    const doRequest = useCallback(
        (payload) => {
            dispatchPageState({ type: "data_fetch_init" });
            axios.get("/dash/car_rental/unico_bypass", {
                params: payload
            }).then(response => {
                dispatchPageState({
                    type: "data_fetch_success",
                    payload: response.data
                });
                setChecked(response.data.unico_bypass);
            }).catch(error => {
                if ((error.response || {}).status === 403) {
                    dispatchPageState({ type: "data_fetch_failure_403" });
                } else if ((error.response || {}).status === 404) {
                    dispatchPageState({ type: "data_fetch_failure_404" });
                } else {
                    dispatchPageState({ type: "data_fetch_failure" });
                }
            });
        },
        []
    );

    useEffect(() => {
        let payload = {};
        const timer_ = setTimeout(() => {
            doRequest(payload);
        }, 300);
        return () => {
            clearTimeout(timer_);
        };
    }, [doRequest]);

    const handleChange = () => {
        let payload = {
            "unico_bypass": !checked
        };
        dispatchPageState({ type: "data_fetch_init" });
        axios.put(
            "/dash/car_rental/unico_bypass",
            payload
        ).then(response => {
            dispatchPageState({
                type: "data_fetch_success",
                payload: response.data
            });
            setChecked(response.data.unico_bypass);
        }).catch(error => {
            setAlertError(true);
            setTimeout(() => setAlertError(false), 5000);
            dispatchPageState({
                type: "data_fetch_success",
                payload: {"unico_bypass": checked}
            });
        });
    };

    if (pageState.isLoading) {
        return (
            <><CircularProgress /></>
        );
    }

    if (pageState.isError && !pageState.fetchedData) {
        return null;
    }

    return (
        <>
            <div style={{ display: "flex", alignItems: "center" }}>
                <p className={["redText", "titleSize"].join(" ")}>Bypass Unico</p>
                <Switch
                    color="error"
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            </div>

            {alertError && (
                <div style={{
                    position: "fixed",
                    bottom: 20,
                    right: 130,
                    backgroundColor: "red",
                    color: "white",
                    padding: "10px 20px",
                    borderRadius: "5px",
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.2)"
                }}>
                    {t("error_message")}
                </div>
            )}
        </>
    );
}

export default UnicoBypassSwitch;
