import React, { useState } from "react"
import { RuleEngineButton } from "../../../@GeneralComponents/RuleEngineButton"
import { VariablesDialog } from "./VariablesDialog/VariablesDialog"
import { useTranslation } from "react-i18next"

export function CreateUserVariables (props) {

    const { treeState, userVariablesAvailable, setUserVariablesAvailable } = props;
    const [variablesDialogOpen, setVariablesDialogOpen] = useState(false)
    const { t } = useTranslation()

    const dialogOpenHandler = () => {
        setVariablesDialogOpen(true)
    }

    return (
        <>
            <div className="internalCardContainer">
                <div className={["blueText","subtitleSize"].join(" ")}>
                {t("variables")}
                </div>
                <div style={{width: "100%"}}>
                    <div className={["normalText", "normalSmallSize", "justifyText"].join(" ")} style={{wordWrap: "break-word", position: "relative"}}>
                    {t("create_variables_description")}
                    </div>
                </div>
            </div>
            <div className="internalCardContainer">
                <RuleEngineButton 
                    label={"manage_variables_button"}
                    onClick={() => dialogOpenHandler()}
                />
                {userVariablesAvailable&&
                <VariablesDialog
                    open={variablesDialogOpen}
                    onCloseDialog={() => setVariablesDialogOpen(false)}
                    treeState={treeState}
                    userVariablesAvailable={userVariablesAvailable}
                    setUserVariablesAvailable={setUserVariablesAvailable}
                />}
            </div>
        </>
    )
}