import React, { useEffect, useContext, useReducer } from "react";
import { useParams } from "react-router-dom";
import {useHistory} from "react-router-dom"
import axios from "axios";
import moment from "moment"

import { dataFetchReducer } from "../../Utils/requestUtils";
import ErrorBoundary from "../../Utils/ErrorBoundary";
import AuthContext from "../../../context/auth-context"

import OperationCard from "./OperationCard/OperationCard"
import TimerBox from "./TimerBox/TimerBox"
import DecisionBox from "./DecisionBox/DecisionBox"
import AlertCard from "./AlertCard/AlertCard"
import ObservationCard from "./ObservationCard/ObservationCard"
import BranchCard from "./BranchCard/BranchCard";
import EnvolvedParties from "./EnvolvedParties/EnvolvedParties"
import HistoricCard from "./HistoricCard/HistoricCard"
import ImportDeclarationCard from "./ImportDeclarationCard/ImportDeclarationCard";

function Operation(props){
    let history = useHistory()

    let roles = useContext(AuthContext).user_data.roles
    if (!roles.includes("operations_viewer")){
        history.push("")
    }

    let { operation_key } = useParams();
    
    const [operationState, dispatchOperation] = useReducer(
        dataFetchReducer,
        {fetchedData: null, isLoading: true, isError: false} 
    )

    useEffect(() => {
		dispatchOperation({type: "data_fetch_init"})
		const timer = setTimeout(() => {
			axios.get('/dash/currency_exchange/operation/' + operation_key).then( response => {    
                dispatchOperation({
                    type: "data_fetch_success",
                    payload: response.data
                })  
			}).catch(error => {
                if ((error.response || {}).status === 403) dispatchOperation({type: "data_fetch_failure_403"})
                else if ((error.response || {}).status === 404) dispatchOperation({type: "data_fetch_failure_404"})  
                else dispatchOperation({type: "data_fetch_failure"})    
			})	
		}, 500);
		return () => {
			clearTimeout(timer)
        }
    }, [operation_key])

    if (operationState.fetchedData){
        return (
            <div style={{minWidth:"900px", paddingBottom: "30px"}}>
                <div className="analysisCardContainer">
                    <ErrorBoundary>
                        <div style={{display:"flex", flexDirection:"column"}}>
                            <div className={["blueText", "titleSize"].join(" ")} style={{margin:"5px 0px"}}>
                                Operação {operationState.fetchedData.id}
                            </div>
                            <div className={["labelText", "subtitleSize"].join(" ")} style={{margin:"5px 0px"}}>
                                {moment(operationState.fetchedData.operation_date).format("DD/MM/YYYY - HH:mm:ss")}
                            </div>
                        </div>
                    </ErrorBoundary>
                </div>
                <div style={{display: "flex", margin: "0px 0px"}}>
                    <div style={{width: "33%"}}>
                        <div className="analysisCardContainer">
                            <ErrorBoundary>
                                <OperationCard operation={operationState.fetchedData}/>
                            </ErrorBoundary>
                        </div>
                        <div className="analysisCardContainer">
                            <ErrorBoundary>
                                <BranchCard operation={operationState.fetchedData}/>
                            </ErrorBoundary> 
                        </div>
                        <div className="analysisCardContainer">
                            <ErrorBoundary>
                                <ImportDeclarationCard operation={operationState.fetchedData}/>
                            </ErrorBoundary> 
                        </div>
                    </div>
                    <div style={{width: "66%"}}>
                        <div style={{display:"flex"}}>
                            <div style={{width: "50%"}}>
                                <div className="analysisCardContainer">
                                    <ErrorBoundary>
                                        <TimerBox operation={operationState.fetchedData}/>
                                    </ErrorBoundary>
                                </div>
                                <div className="analysisCardContainer">
                                    <ErrorBoundary>
                                        <DecisionBox 
                                            analysis_events={operationState.fetchedData.analysis_events}
                                            analysis_status={operationState.fetchedData.analysis_status}
                                        />
                                    </ErrorBoundary>
                                </div>
                            </div>
                            <div style={{width: "50%"}}>
                                <div className="analysisCardContainer" style={{minHeight:"150px"}}>
                                    <ErrorBoundary>
                                        <AlertCard analysis_events={operationState.fetchedData.analysis_events}/>
                                    </ErrorBoundary>   
                                </div>
                            </div>
                        </div>
                        <div className="analysisCardContainer">
                            <ErrorBoundary>
                                <EnvolvedParties operation={operationState.fetchedData} />
                            </ErrorBoundary>
                        </div>
                    </div>
                </div>
                <div className="analysisCardContainer">
                    <ErrorBoundary>
                        <HistoricCard operation={operationState.fetchedData}/>
                    </ErrorBoundary>			
                </div>
                <div className="analysisCardContainer">
                    <ErrorBoundary>
                        <ObservationCard operation={operationState.fetchedData}/>
                    </ErrorBoundary>			
                </div>
            </div>
        )
    }
	else if(operationState.isError){
		return (
            <div style={{height: "80vh", display:"flex"}}>
                <p className={["blueText", "titleSize"].join(" ")} style={{margin:"auto", textAlign:"center"}}>
                    {operationState.errorMessage}
                </p>
            </div>
		)
	}
  
  return null;
}

export default Operation;