import React, {useState, useEffect} from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createMuiTheme({
    palette:{
        primary: {
            main:"#0b1f82"
        },
        secondary: {
            main: "#FF0000"
        }
    }
});


const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

function NewRuleDialog(props) {
    const history = useHistory()
    const { t } = useTranslation();
    let {setRuleDefinition} = props
    let {rule_key} = props
    let {company} = props

    function compareNames(a, b) {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
    }
    
    if (Array.isArray(company)) {
        company.sort(compareNames)
    }

    const handleDialogClose = () => {
        history.push("/rules-inbox")
    }

    const [ruleName, setRuleName] = useState("")
    const [ruleDescription, setRuleDescription] = useState("")
    const [eventType, setEventType] = useState(null)
    const [companyEvent, setcompanyEvent] = useState("")

    const validateAllValues = () => {
        if (!company.length){
            return ruleName.length > 0 && ruleDescription.length >0 && eventType
        } else{
            return ruleName.length > 0 && ruleDescription.length > 0 && eventType && companyEvent
        }
    }
    
    const [ companyEventType, setCompanyEventType ] = useState([])

    useEffect(() => {
        setCompanyEventType(companyEvent)
    }
    ,[companyEvent])

    return (
        <Dialog 
            open={props.open} 
            onClose={handleDialogClose}
            disableBackdropClick
            disableEscapeKeyDown
            keepMounted={false}
        >
            <DialogTitle 
                className={['blueText', "subtitleSize"].join(" ")} 
                onClose={handleDialogClose}
            >
                {t("Nova Regra")}
            </DialogTitle>
            <DialogContent >
                <div style={{padding:"20px", display:"flex", flexDirection:"column", marginBottom:"30px"}}>
                    <ThemeProvider theme={theme}>
                    <div className="internalCardContainer">
                        <TextField
                            error={!ruleName.length > 0}
                            label={t("Nome da Regra")}
                            style={{textAlign:"center"}} 
                            className="filterTextbox" 
                            size="small" fullWidth 
                            value={ruleName}
                            onChange={(e) => setRuleName(e.target.value)}
                            autoFocus
                        />  
                    </div>
                    <div className="internalCardContainer">
                        <TextField
                            error={!ruleDescription.length > 0}
                            label={t("Descrição")}
                            style={{textAlign:"center"}} 
                            className="filterTextbox" 
                            size="small" fullWidth 
                            value={ruleDescription}
                            multiline
                            onChange={(e) => setRuleDescription(e.target.value)}
                        />  
                    </div>
                    {company.length ?  <div className="internalCardContainer">
                        <Autocomplete
                            id="combo-box-demo"
                            value={companyEvent}
                            onChange={(event, newValue) => {
                                setcompanyEvent(newValue);
                            }}
                            options={company}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => 
                                <TextField 
                                    error={!companyEvent}
                                    {...params} 
                                    label={t("Selecione um Cliente")} 
                                    variant="outlined" 
                                />}
                        />
                    </div>: null}
                    {companyEvent  && 
                    <div className="internalCardContainer">
                        <Autocomplete
                            id="combo-box-demo"
                            value={eventType}
                            onChange={(event, newValue) => {
                                setEventType(newValue);
                            }}
                            options={companyEventType?.company_events?.sort((a, b)=> a.field_mapping.event_type.description === b.field_mapping.event_type.description ? 0 : a.field_mapping.event_type.description > b.field_mapping.event_type.description ? 1 : -1)}
                            getOptionLabel={(option) => option.field_mapping.event_type.description}
                            renderInput={(params) => 
                                <TextField 
                                    error={!eventType}
                                    {...params} 
                                    label="Selecione um Evento" 
                                    variant="outlined" 
                                />}
                        />
                    </div>  
                    }
                    {!company.length ?
                    <div className="internalCardContainer">
                        <Autocomplete
                            id="combo-box-demo"
                            value={eventType}
                            onChange={(event, newValue) => {
                                setEventType(newValue);
                            }}
                            options={company.company_events
                                .sort((a, b)=> a.field_mapping.event_type.description === b.field_mapping.event_type.description ? 0 : a.field_mapping.event_type.description > b.field_mapping.event_type.description ? 1 : -1)}
                            getOptionLabel={(option) => option.field_mapping.event_type.description}
                            renderInput={(params) => 
                                <TextField 
                                    error={!eventType}
                                    {...params} 
                                    label="Selecione um Evento" 
                                    variant="outlined" 
                                />}
                        />
                    </div>  
                    : null}
                    </ThemeProvider>                    
                    <div className="internalCardContainer" style={{display:"flex", marginTop: "10px"}}>
                        <div
                            className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")}
                            style={{width:"120px"}}
                            onClick={handleDialogClose}
                        >
                            {t("discard")}
                        </div>
                        <div
                            className={
                                validateAllValues()
                                ?
                                ["button", "standard", "normalText", "normalMediumSize"].join(" ")
                                :
                                ["button", "standard", "normalText", "normalMediumSize","disabled"].join(" ")}
                            style={{width:"120px"}}
                            onClick={validateAllValues() ? () => setRuleDefinition({
                                rule_key:rule_key, 
                                user_variables:[],
                                tree_data:[],
                                name:ruleName, 
                                description:ruleDescription, 
                                event_type:eventType.field_mapping.event_type.enum,
                                is_current_version: true,
                                company_key: companyEvent.company_key || [],
                                isDraft: false,
                                hasRuleDraft: false,
                                environment: companyEvent ? companyEvent.environment : eventType.field_mapping.event_type.environment
                            }
                            ) : null}
                        >
                            {t("save")}
                        </div>   
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default NewRuleDialog