import React, { useEffect, useContext, useReducer, useState } from "react";	
import {dataFetchReducer} from "../../Utils/requestUtils"
import { useParams } from "react-router-dom";
import axios from "axios";
import ObservationCard from "./ObservationCard/ObservationCard"
import AlertsCard from "./AlertsCard/AlertsCard"
import UserCard from "./UserCard/UserCard"
import HistoricCard from "./HistoricCard/HistoricCard"
import ErrorBoundary from "../../Utils/ErrorBoundary"
import AuthContext from "../../../context/auth-context"
import {useHistory} from "react-router-dom"
import LocksCard from './LocksCard/LocksCard'
import CreateLockDialog from "./CreateLockDialog/CreateLockDialog"
import moment from 'moment'

function Alert(props){
    let history = useHistory()

    let roles = useContext(AuthContext).user_data.roles
    if (!roles.includes("read_card_order_alerts")){
        history.push("")
    }

    let { alert_key } = useParams();

    const [refreshPage, setRefreshPage] = useState(false)

    const [openCreateLockDialog, setOpenCreateLockDialog] = useState(false)

    const [alert, dispatchAlert] = useReducer(
        dataFetchReducer,
        {fetchedData: null, isLoading: true, isError: false} 
    )

    let enableLockActions = (
        (alert.fetchedData || {}).alert_status === "in_manual_analysis" && 
        roles.includes("create_card_order_locks")
    )

    useEffect(() => {
        dispatchAlert({type: "data_fetch_init"}) 
		const timer = setTimeout(() => {
			axios.get('/dash/card_order/alert/' + alert_key).then( response => {
                dispatchAlert({
                    type: "data_fetch_success",
                    payload: response.data
                })   
			}).catch(error => {
                if ((error.response || {}).status === 403) dispatchAlert({type: "data_fetch_failure_403"})
                else if ((error.response || {}).status === 404) dispatchAlert({type: "data_fetch_failure_404"})  
                else dispatchAlert({type: "data_fetch_failure"})    
			})	
		}, 500);
		return () => {
			clearTimeout(timer)
        }
    }, [alert_key, refreshPage])

    const handleCreateLockButtonClick = () => {
        setOpenCreateLockDialog(true)
    }

    const handleRefreshPage = () => {
        setRefreshPage(!refreshPage)
    }

    const handleCreateLockDialogClose = (refresh=false) => {
        if (refresh) handleRefreshPage()
		setOpenCreateLockDialog(false);
	};


    if (alert.fetchedData){
        return (
            <div style={{minWidth:"900px", paddingBottom: "30px"}}>
                <div className="analysisCardContainer">
                    <ErrorBoundary>
                        <div>
                            <div className={["blueText", "titleSize"].join(" ")} style={{margin:"5px 0px"}}>
                                {(alert.fetchedData.link_key_type === "customer") ? 
                                    "Alertas - Comprador " + alert.fetchedData.customer_id :
                                    "Alertas - Vendedor " + alert.fetchedData.seller_id}
                            </div>
                            <div className={["labelText", "subtitleSize"].join(" ")} style={{margin:"5px 0px"}}>
                                {moment(alert.fetchedData.occurrence_date).format("DD/MM/YYYY - HH:mm:ss")}
                            </div>
                        </div>           
                        <div style={{margin: "auto 50px auto auto"}}>
                            {enableLockActions ? 
                            <div 
                                className={["button", "standard", "normalText", "normalMediumSize"].join(" ")} 
                                style={{textAlign:"center", margin:"auto", width:"150px"}}
                                onClick={handleCreateLockButtonClick}
                            >
                                Criar Bloqueio
                            </div>
                            :null}
                        </div>
                    </ErrorBoundary>
                </div>
                <div style={{display: "flex", flexGrow: 1}}>
                    <div className="analysisCardContainer" style={{width: "40%"}}>
                        <ErrorBoundary>   
                            <AlertsCard alert={alert.fetchedData} />
                        </ErrorBoundary>
                    </div>
                    <div className="analysisCardContainer" style={{width: "60%"}}>
                        <ErrorBoundary>   
                            <UserCard alert={alert.fetchedData} />
                        </ErrorBoundary>
                    </div>
                </div>
                <div style={{display: "flex"}}>
                    <div  className="analysisCardContainer"  style={{minHeight: "250px", display: "flex", flexGrow: 1}}>
                        <ErrorBoundary>   
                            <HistoricCard alert={alert.fetchedData} />
                        </ErrorBoundary>
                    </div>
                </div>
                {(alert.fetchedData.locks || []).length > 0 ?
                <div style={{display: "flex"}}>
                    <div  className="analysisCardContainer"  style={{display: "flex", flexGrow: 1}}>
                        <ErrorBoundary>   
                            <LocksCard 
                                handleRefreshPage={handleRefreshPage} 
                                locks={alert.fetchedData.locks} 
                                enableLockActions={enableLockActions}
                            />
                        </ErrorBoundary>
                    </div>
                </div>                
                :null}
                <div style={{display: "flex"}}>
                    <div className="analysisCardContainer" style={{ minHeight: "170px", display: "flex", flexGrow: 1}}>
                        <ErrorBoundary>
                            <ObservationCard alert={alert.fetchedData}/>
                        </ErrorBoundary>			
                    </div>
                </div>
                {alert.fetchedData.alert_status === "in_manual_analysis" ?
                <CreateLockDialog
                    open={openCreateLockDialog}
                    onClose={handleCreateLockDialogClose}
                    alert={alert.fetchedData}
                /> : null}

            </div>
        )
    }
    else if(alert.isError){
		return (
			<div style={{height: "80vh", display:"flex"}}>
				<p className={["blueText", "titleSize"].join(" ")} style={{margin:"auto", textAlign:"center"}}>
					{alert.errorMessage}
				</p>
			</div>
		)
    }
    else return null;
}

export default Alert