import React from "react"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { validateCPF, validateCNPJ } from "../../../../../../utils/utils"

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
  },
  tableText: {
    fontFamily: "Open Sans",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "16px",
    color: "#141414",
    textAlign: "center",
    margin: "10px",
    padding: "6px",
    minWidth: "120px"
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: "#6F6F6F",
    fontSize: "14px",
    lineHeight: "19px",
    padding: "6px",
  },
  even: {
    background: "#F3F3F3",
  },
  odd: {
    background: "#FFFFFF",
  },
}))

function EnvolvedPartiesTable(props) {
  const classes = useStyles()
  useTheme()

  const { isQuod, processes_data, index } = props

  const getClass = (rowIndex) => {
    if (rowIndex % 2 === 0) {
      return classes.even
    }

    return classes.odd
  }

  return (
    isQuod ? (
      <Paper className={classes.tableStyle}>
        <Table style={{ padding: "8px" }}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader}>Nome</TableCell>
              <TableCell className={classes.tableHeader}>CPF/CNPJ</TableCell>
              <TableCell className={classes.tableHeader}>Papel</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.tableText}>
                {processes_data[index].process_author}
              </TableCell>
              <TableCell style={{ whiteSpace: "nowrap" }} className={classes.tableText}>
                -
              </TableCell>
              <TableCell className={classes.tableText}>
                AUTOR
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableText}>
                {processes_data[index].defendant_name}
              </TableCell>
              <TableCell style={{ whiteSpace: "nowrap" }} className={classes.tableText}>
                {processes_data[index].defendant_document_number == null ? "-" :
                  validateCPF(processes_data[index].defendant_document_number)
                    ?
                    processes_data[index].defendant_document_number.replace(/[^\d]+/g, "").replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4")
                    :
                    validateCNPJ(processes_data[index].defendant_document_number)
                      ? processes_data[index].defendant_document_number.replace(/[^\d]+/g, "").replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5")
                      :
                      processes_data[index].defendant_document_number}
              </TableCell>
              <TableCell className={classes.tableText}>
                RÉU
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    ) : (
      <Paper className={classes.tableStyle}>
        <Table style={{ padding: "8px" }}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader}>Nome</TableCell>
              <TableCell className={classes.tableHeader}>CPF/CNPJ</TableCell>
              <TableCell className={classes.tableHeader}>Papel</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {processes_data[index].parties.map((party, index) => (
              <TableRow
                key={index}
                className={getClass(index)}
              >
                <TableCell className={classes.tableText}>
                  {party.name}
                </TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }} className={classes.tableText}>
                  {party.document == null ? "-" :
                    validateCPF(party.document)
                      ?
                      party.document.replace(/[^\d]+/g, "").replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4")
                      :
                      validateCNPJ(party.document)
                        ? party.document.replace(/[^\d]+/g, "").replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5")
                        :
                        party.document}
                </TableCell>
                <TableCell className={classes.tableText}>
                  {party.type}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    )
  )
}

export default EnvolvedPartiesTable
