import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
      fontFamily: "Open Sans",
      fontWeight: 600,
      fontSize: "16px",
      color: "#0b1f82",
      padding: "16px 24px 0px 24px",
    },
    dialogContent: {
      width: "480px",
      height: "240px",
      marginBottom: "10px",
      float: "left",
      display:"flex",
      flexDirection:"column"
    },
    textClass: {
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontSize: "14px",
      lineHeight: "19px"
    }
  }));

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const DialogTitle = withStyles(styles)((props) => {
const { children, classes, onClose, ...other } = props;
return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
    <Typography variant="h6">{children}</Typography>
    {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
        </IconButton>
    ) : null}
    </MuiDialogTitle>
);
});

function AnalystModal(props) {
    let {open} = props
    let {onClose} = props
    let {analysisType} = props
    let history = useHistory()
    let message = (analysisType !== "reservation" ?
        props.currentAnalyst.analyst_name + " é o analista responsável por este aluguel" :
        props.currentAnalyst.analyst_name + " é o analista responsável por esta reserva"
      )


    const classes = useStyles();
    useTheme();

  const getDialogContent=()=>{
        return(
            <div>
                <div className="labelText" style={{marginBottom:"30px"}}>
                    {message}
                </div>
                <div style={{ display:"flex", flexDirection: "column", alignItems: "center", margin:"auto 0px"}}>
                    <div
                        className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                        onClick={() => history.goBack()} 
                        style={{width:"60%", marginBottom: "30px", display:"flex", alignItems:"center", justifyContent:"center"}}
                    >
                      Voltar para Tela de Listagem
                    </div>
                    <div
                        className={["button", "clicked", "normalText", "normalMediumSize"].join(" ")}
                        onClick={() => onClose()} 
                        style={{width:"60%", marginBottom: "30px", display:"flex", alignItems:"center", justifyContent:"center"}}
                    >
                      Prosseguir mesmo assim
                    </div>
                </div>            
            </div>
        )
  }

    return (
        <div>
            <Dialog
                open={open} 
                onClose={() => history.goBack()} 
                keepMounted={false}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle 
                    className={['blueText', "subtitleSize"].join(" ")} 
                    onClose={() => history.goBack()}
                >
                  {analysisType !== "reservation" ?
                    "Aluguel já está em Análise" :
                    "Reserva já está em Análise"
                  }
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                  {getDialogContent()}
                </DialogContent>



            </Dialog>
        </div>
    );
}

export default AnalystModal