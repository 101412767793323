import React, {useEffect, useReducer, useCallback, useContext, useState} from "react";
import { dataFetchReducer, useStateConnectedToCache } from "../../Utils/requestUtils";
import Pagination from "@material-ui/lab/Pagination";
import { CircularProgress } from '@material-ui/core';
import axios from "axios";
import ListCard from "./ListCard/ListCard";
import Filter from './Filter/Filter'
import CompaniesContext from "../../../context/companies-context";
import NewListDialog from "./NewListDialog/NewListDialog"
import ErrorBoundary from '../../Utils/ErrorBoundary'
import AuthContext from "../../../context/auth-context"
import {useHistory} from "react-router-dom"
import { useTranslation } from "react-i18next";

function ListsInbox(props) {
    let history = useHistory()
    const { t } = useTranslation();

    let user_data = useContext(AuthContext).user_data
    let username = user_data.email
    if (!user_data.roles.includes("lists_viewer")){
        history.push("")
    }

    let businessGroupCompanies = useContext(CompaniesContext).lists_companies

    let local_storage_object_preffix = "Lists"

    const [pageParams, setPageParams] = useStateConnectedToCache(local_storage_object_preffix)
    const [openNewList, setOpenNewList] = useState(false)
    const [pageState, dispatchPageState] = useReducer(
        dataFetchReducer,
        {fetchedData: null, isLoading: true, isError: false}
    )
    
    const doRequest = useCallback(
        (payload) => {
            dispatchPageState({type: "data_fetch_init"})
            payload["username"] = username
            axios
            .get("/dash/lists/lists", {
                params: payload
            }).then(response=>{
                dispatchPageState({
                    type: "data_fetch_success",
                    payload: response.data
                })
            }).catch(error => {
                if ((error.response || {}).status === 403) dispatchPageState({type: "data_fetch_failure_403"})
                else if ((error.response || {}).status === 404) dispatchPageState({type: "data_fetch_failure_404"})
                else dispatchPageState({type: "data_fetch_failure"})
            })
        },[username]
    )

    useEffect(() => {
        let payload = {
            "page_number": pageParams.page - 1,
            "page_rows": 50
        }
        Object.keys(pageParams.filters).forEach((key,index) => {
            payload[key] = pageParams.filters[key]
        })
        const timer_ = setTimeout(() => {
            doRequest(payload)
        }, 300);
        return () => {
            clearTimeout(timer_)
		}
    },[doRequest,pageParams])

    const handleUpdatePage = () => {
        let payload = {
            "page_number": pageParams.page - 1,
            "page_rows": 50
        }
        Object.keys(pageParams.filters).forEach((key,index) => {
            payload[key] = pageParams.filters[key]
        })
        doRequest(payload)
    }

    const [listsNameEnum, setListsNameEnum] = useState([])

    useEffect(()=>{
        const getLists = async () => {
            const response = await axios.get("/dash/lists/lists", 
                {
                    params: {"username":username}
                }
            )
            const all_lists = response.data.data
            const list_name_enum = all_lists.map(list => [list.name, list.list_key])
            setListsNameEnum(list_name_enum)
        }

        getLists()
    }, [username])

    const handleChangePage = (event, page, filters) => {
		if (pageState.page === page) {
			return
		}
		setPageParams({page: page, filters: filters})
    }

    const handleOpenNewListDialog = () => {
		setOpenNewList(true);
	};

	const handleCloseNewListDialog = () => {
		setOpenNewList(false);
    };

	const contentContainer = (dataList) => {

		let nr_of_cards = dataList['data'].length
		let cards = dataList['data'].map((list) => (
			<ErrorBoundary key={list.list_key}>
                <ListCard
                    list={list}
                    user_data={user_data}
                    local_storage_object_preffix={local_storage_object_preffix}
                    setPageParams={setPageParams}
                    handleUpdatePage={handleUpdatePage}
                />
			</ErrorBoundary>
		))

		if (nr_of_cards === 0) {
			return (
			<div className='emptyContainer'>
				<p className={["blueText", "subtitleSize"].join(" ")}>
					{t("Nenhuma lista encontrada.")}
				</p>
			</div>
			)
		}
		else {
			return (
                <div className='cardContainer'>
                    <div className="listCardHeader">
                    {user_data.business_group_key &&   
                        <div 
                            className={["listCardItem", "normalText", "normalMediumSize", "bold"].join(" ")} 
                            style={{flex:"1 1 45px", textAlign:"center", cursor: "default"}}  
                        >
                            {t("Cliente")}
                        </div>}
                        <div 
                            className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                            style={{flex:"1 1 110px", textAlign:"center"}}
                        >
                            {t("Nome")}
                        </div>
                        <div 
                            className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                            style={{flex:"1 1 130px", textAlign:"center"}}
                        >
                            {t("Descrição")}
                        </div>
                        <div 
                            className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                            style={{flex:"1 1 110px", textAlign:"center"}}
                        >
                            {t("Data da Criação")}
                        </div>
                        {user_data.roles.includes("multiple_environment_viewer") ? <div 
                            className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                            style={{flex:"1 1 110px", textAlign:"center"}}
                        >
                            {t("Região")}
                        </div>: null}
                        <div 
                            className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                            style={{flex:"1 1 110px", textAlign:"center"}}
                        />
                    </div>
                    {cards}
                </div>
            )
		}
	}


	if (pageState.fetchedData && (user_data.business_group_key == null || businessGroupCompanies !== null)) {
        return (
			<div style={{ width: "auto", paddingBottom: "30px" }}>
				<div style={{display:"flex"}}>
					<div>
						<p className={["blueText", "titleSize"].join(" ")}>{t("LISTAS")}</p>
					</div>
                    <div style={{margin: "auto 20px auto auto"}}>
                        {user_data.roles.includes('lists_analyzer') ?
                        <div
                            className={["normalText", "button", "standard"].join(" ")}
                            onClick={handleOpenNewListDialog}
                        >
                            {t("ADICIONAR NOVA LISTA")}
                        </div>: null
                        }
					</div>
				</div>
                <ErrorBoundary doNothing>
                    <Filter local_storage_object_preffix={local_storage_object_preffix} setPageParams={setPageParams} companies={businessGroupCompanies} listsNameEnum={listsNameEnum}/>
                </ErrorBoundary>
				<div>{contentContainer(pageState.fetchedData)}</div>
				<div>
                    <Pagination
                        className="pagination"
                        page={pageParams.page}
                        count={parseInt(pageState.fetchedData["number_of_pages"])}
                        onChange={(e, page) => handleChangePage(e, page, pageParams.filters)}
                    />
				</div>
                <NewListDialog
					open={openNewList}
                    onClose={handleCloseNewListDialog}
                    setPageParams={setPageParams}
                    businessGroupCompanies={businessGroupCompanies}
                    user_data={user_data}
                    local_storage_object_preffix={local_storage_object_preffix}
				/>
			</div>
		);
	}
	else if (pageState.isError){
		return (
			<div style={{ width: "auto" }}>
				<div style={{display:"flex"}}>
					<div>
						<p className={["blueText", "titleSize"].join(" ")}>{t("LISTAS")}</p>
					</div>
                    <div style={{margin: "auto 20px auto auto"}}>
                        {user_data.roles.includes('lists_analyzer') ?
                        <div
                            className={["normalText", "button", "standard"].join(" ")}
                            onClick={handleOpenNewListDialog}
                        >
                            {t("ADICIONAR NOVA LISTA")}
                        </div>: null
                        }
					</div>
				</div>
                <div style={{height: "55vh", display:"flex"}}>
                    <p className={["blueText", "titleSize"].join(" ")} style={{margin:"auto", textAlign:"center"}}>
                        {pageState.errorMessage}
                    </p>
                </div>
                <NewListDialog
					open={openNewList}
                    onClose={handleCloseNewListDialog}
                    setPageParams={setPageParams}
                    businessGroupCompanies={businessGroupCompanies}
                    user_data={user_data}
                    local_storage_object_preffix={local_storage_object_preffix}
				/>
			</div>
		)
	}
	else if (pageState.isLoading || (user_data.business_group_key == null || businessGroupCompanies === null)) {
		return(
			<div style={{ width: "auto" }}>
                <div style={{display:"flex"}}>
					<div>
						<p className={["blueText", "titleSize"].join(" ")}>{t("LISTAS")}</p>
					</div>
                    <div style={{margin: "auto 20px auto auto"}}>
                        {user_data.roles.includes('lists_analyzer') ?
                        <div
                            className={["normalText", "button", "standard"].join(" ")}
                            onClick={handleOpenNewListDialog}
                        >
                            {t("ADICIONAR NOVA LISTA")}
                        </div>: null
                        }
					</div>
				</div>
				<div className='circularProgressCenter'>
					<CircularProgress />
				</div>
                <NewListDialog
                    open={openNewList}
                    onClose={handleCloseNewListDialog}
                    businessGroupCompanies={businessGroupCompanies}
                    user_data={user_data}
                    setPageParams={setPageParams}
                    local_storage_object_preffix={local_storage_object_preffix}
				/>
			</div>
		)
    }
    else return null

}

export default ListsInbox