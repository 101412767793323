import React from 'react'

function CardOrderLockTypes (props){

    let { setLockType } = props

    return (
        <div style={{ flexWrap:"wrap", display:"flex", justifyContent: "space-evenly", margin:"auto 0px"}}>
            <div
                className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")} 
                onClick={() => setLockType("customer")} 
                style={{width:"40%", margin:"10px 0"}}
            >
                Comprador
            </div>
            <div
                className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")} 
                onClick={() => setLockType("seller")} 
                style={{width:"40%", margin:"10px 0"}}
            >
                Payout do Vendedor
            </div>
            <div
                className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")} 
                onClick={() => setLockType("seller_transactional")} 
                style={{width:"45%", margin:"10px 0"}}
            >
                Transação do Vendedor
            </div>
        </div>
    )
}

export default CardOrderLockTypes