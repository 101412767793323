import React from 'react'
import MenuItem from "@material-ui/core/MenuItem";
import moment from 'moment'

//Initialize Filter Object
export const getFilterInitialSate = (filterTypes, initialFilters=null) => {
    let initial_state ={
        params:{},
        filters: [],
        toPass:{},
        select_value: "",
        order_by: null
    }
    if (initialFilters){
        initial_state = Object.keys(initialFilters).reduce((acc, initial_key)=>{
            acc.filters.push(initial_key)
            acc.toPass[initial_key] = initialFilters[initial_key]
            return acc
        }, initial_state)
    }
    Object.keys(filterTypes).map(function (filter_name) {
        let value = ""
        let applyBool = false
        if (initialFilters){
            if (Object.keys(initialFilters).includes(filter_name)){
                value = initialFilters[filter_name]
                applyBool = true
            }
        }
        initial_state.params[filter_name] = 
            {
                value:value,
                error:false,
                applied: applyBool,
                disabled:filterTypes[filter_name].disabled,
                description:filterTypes[filter_name].description,
                type: filterTypes[filter_name].type,
                exclude: filterTypes[filter_name].exclude,
                forceKeyValue: filterTypes[filter_name].forceKeyValue,

            }
        if (filterTypes[filter_name].type === "date"){
            initial_state.params[filter_name].changed = false
            initial_state.params[filter_name].initial_date_value = null
            initial_state.params[filter_name].final_date_value = null
        }
        return null
        })
    return initial_state
}

//Add filter boxes
export const addFilters = (column_name, filterObject) => {
    filterObject.filters.push(column_name)
    filterObject.params[column_name].value = ""
    if (filterObject.params[column_name].type === "date"){
        filterObject.params[column_name].initial_date_value = null
        filterObject.params[column_name].final_date_value = null
    }
    filterObject.select_value = ""
    return filterObject
}

//Close filter boxes
export const filterClose = (column_name, filterObject) => {
    let key_toPass = column_name
    if (filterObject.params[column_name].forceKeyValue){
        key_toPass=filterObject.params[column_name].forceKeyValue
    }

    let doRequest = false
    let new_filters = filterObject.filters.filter((el) => el !== column_name);
    filterObject.filters = new_filters
    if (filterObject.params[column_name].applied) {
        doRequest = true
        if (filterObject.params[column_name].type === "date"){
            filterObject.params[column_name].changed = true
        }
    }
    if (filterObject.params[column_name].type === "date"){
        filterObject.params[column_name].initial_date_value = null
        filterObject.params[column_name].final_date_value = null
        delete filterObject.toPass.initial_date
        delete filterObject.toPass.final_date
    }
    filterObject.params[column_name].value = ""
    filterObject.params[column_name].error = false
    delete filterObject.toPass[key_toPass]
    delete filterObject.toPass.initial_date
    delete filterObject.toPass.final_date
    return [filterObject, doRequest]
}

//Transform filterObject for changes, dont include date types 
export const filterChange = (value, column_name, filterType, filterObject) => {
    let key_toPass = column_name
    if (filterObject.params[column_name].forceKeyValue){
        key_toPass=filterObject.params[column_name].forceKeyValue
    }

    filterObject.params[column_name].value = value
    if (value.length === 0){
        delete filterObject.toPass[key_toPass]
        filterObject.params[column_name].error = false
        return filterObject
    }
    if (filterType.validation){
        if (validateFilter(value, filterType.validation_rule)){
            filterObject.params[column_name].error = false
            filterObject.toPass[key_toPass] = value
        }
        else{
            delete filterObject.toPass[key_toPass]
            filterObject.params[column_name].error = true
        }
    }
    if (filterType.type === "value"){
        filterObject.params[column_name].error = false
        value = value.slice(3)
        value = value.replace(",","")
        value = value.replace(".","")
        value = Number(value)
        filterObject.toPass[key_toPass] = value
    }
    else{
        filterObject.params[column_name].error = false
        filterObject.toPass[key_toPass] = value
    }
    return filterObject
}

//Before Request switch apply key of filters according to what exists in toPass
export const changeApply = (filterObject) => {
    Object.keys(filterObject.params).forEach((key,index) => {
        if (Object.keys(filterObject.toPass).includes(key) || 
            Object.keys(filterObject.toPass).includes(filterObject.params[key].forceKeyValue)){
            filterObject.params[key].applied = true
        }
        else {
            filterObject.params[key].applied = false
        }
    })
    return filterObject
}


//Transform filterObject for dateType Changes
export const dateChange = (column_name, newDates, filterObject) => {
    filterObject.params[column_name].changed = true
    filterObject.params[column_name].initial_date_value = newDates.initial_date_value
    filterObject.params[column_name].final_date_value = newDates.final_date_value
    if(newDates.initial_date_value){
        filterObject.toPass.initial_date = moment(newDates.initial_date_value).startOf("day").toISOString()
    }
    else{
        delete filterObject.toPass.initial_date
    }
    if(newDates.final_date_value){
        filterObject.toPass.final_date = moment(newDates.final_date_value).endOf("day").toISOString()
    }
    else{
        delete filterObject.toPass.final_date
    }
    return filterObject
}

//Check whether the date object changed or not, if yes, whether exists initial date and final date in toPass. If yes trigger request
export const verifyRequest = (column_name, filterObject) => {
    let doRequest = false
    if (filterObject.params[column_name].changed) {
        filterObject.params[column_name].changed = false
        if (["final_date", "initial_date"].some((val) => Object.keys(filterObject.toPass).includes(val))){
            filterObject.params[column_name].applied = true
        }
        else {
            filterObject.params[column_name].applied = false
        }
        doRequest = true
    }
    return [filterObject, doRequest]
}


//Get the options for selecting filters
export const getSelectItens = (filterObject) => {
    let excludedFilters = filterObject.filters.reduce((acc, existent_filter) => {
        acc.push(existent_filter)
        if ((filterObject.params[existent_filter].exclude || []).length > 0){
            filterObject.params[existent_filter].exclude.map(excluded => acc.push(excluded))
        }
        return acc
    }, [])

    let selectItems = Object.keys(filterObject.params)
    .filter((column_name) => !excludedFilters.includes(column_name))
    .map((column_name) => (
        <MenuItem key={column_name} value={column_name}>
          {filterObject.params[column_name].description}
        </MenuItem>
    ))
    return selectItems
}

const validateFilter = (value, rule) => {
    if (rule.type === "equal"){
        if (value.length === rule.value) return true
        else return false
    }
    else if (rule.type === "multiple_equal"){
        if (rule.values.includes(value.length)) return true
        else return false
    }
    else return true
}