import React, { useEffect, useCallback, useReducer, useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { dataFetchReducer, useStateConnectedToCache } from "../../Utils/requestUtils"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { CircularProgress } from '@material-ui/core'
import AuthContext from "../../../context/auth-context"
import axios from "axios"
import ErrorBoundary from '../../Utils/ErrorBoundary'
import {useHistory} from "react-router-dom"
import DialogError from '../DialogError'
import Card from "@material-ui/core/Card";
import Collapse from "@material-ui/core/Collapse";
import Pagination from "@material-ui/lab/Pagination";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import IconButton from "@material-ui/core/IconButton";
import ReportTemplateItem from "./ReportTemplateItem/ReportTemplateItem";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: "22px",
    lineHeight: "27px",
    color: "#0b1f82",
    width: "auto"

  },
  error: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: "22px",
    lineHeight: "27px",
    color: "#0b1f82",
    marginUp: "300px"
  },
  subtitle: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "25px",
    color: "#0b1f82",
  },
  cardContainer: {
    width: "100%",
    display: "block",
  },
  pagination: {
    float: "right"
  },
  emptyContainer: {
    width: "100%",
    verticalAlign: "middle",
    textAlign: "center",
    padding: "30px",
    background: "white",
    boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.2)",
    borderRadius: "10px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    minWidth: "1100px"
  },
  buttonAnalyze: {
    boxSizing: "border-box",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.12)",
    backgroundColor: "#0b1f82",
    fontFamily: "Open Sans",
    fontSize: "13px",
    fontWeight: "bold",
    lineHeight: "18px",
    textAlign: "center",
    color: "#ffffff",
    borderRadius: "30px",
    textTransform: "capitalize",
    margin: "10px 7px 10px 7px",
    padding: "8px 20px 8px 20px",
    minWidth: "100px",
    "&:hover": {
      backgroundColor: "#204e68",
    }
  },
}))

function ReportsTemplatesInbox(props) {
  const {t} = useTranslation()
  const {isReloading, handleReloadPage} = props
  let history = useHistory()
  const classes = useStyles();
  useTheme();
  
  let roles = useContext(AuthContext).user_data.roles;
  if (!(['read_reports', 'read_reports_onboarding_natural_person', 'read_reports_onboarding_legal_person'].some(role => roles.includes(role)))) {
      history.push("");
  }

  const local_storage_object_preffix = "reports"

  const [pageParams, setPageParams] = useStateConnectedToCache(local_storage_object_preffix)

  const [pageState, dispatchPageState] = useReducer(
      dataFetchReducer,
      {fetchedData: null, isLoading: true, isError: false} 
  )

  const handleChangePage = (event, page) => {
		if (pageState.page === page) {
			return
    }
		setPageParams({page: page})
	}

  const doRequest = useCallback(
      (payload) => {
          dispatchPageState({type: "data_fetch_init"})  
          console.log(payload)
          axios.get("/dash/report/report_templates", {
              params: payload
          }).then(response=>{
              dispatchPageState({
                  type: "data_fetch_success",
                  payload: response.data
              })          
          }).catch(error => {
              if ((error.response || {}).status === 403) dispatchPageState({type: "data_fetch_failure_403"})
              else if ((error.response || {}).status === 404) dispatchPageState({type: "data_fetch_failure_404"})  
              else dispatchPageState({type: "data_fetch_failure"})    
          })
      },[]
  )

  useEffect(() => {
      let payload = {
          "page_number": pageParams.page - 1,
          "page_rows": 25
      }
      const timer_ = setTimeout(() => {
          doRequest(payload)
      }, 100);
      return () => {
          clearTimeout(timer_)
  }	
  },[doRequest, pageParams, isReloading])

const [expanded, setExpanded] = useState(false);

const handleExpand = (event) => {
    event.stopPropagation();
    setExpanded(!expanded);
};

const contentContainer = (fetchedData) => {

  let reports = undefined

  let reportData = fetchedData[0]

    reports = [reportData].map((templatesList, index) => (
      <ErrorBoundary key={index}> 
          <ReportTemplateItem templatesList={templatesList.data} handleReloadPage={handleReloadPage}/>
      </ErrorBoundary>))
  
      return (
      <Card className={["listCard","collapse"].join(" ")}>
        <div style={{display:"flex", justifyContent: "space-between"}}>
              <p className={classes.subtitle}>{t("report_request")}</p>
          <div
              className="listCardItem"
              style={{flex:"0 1 70px", textAlign:"center"}}
          >
              <IconButton onClick={handleExpand}>
                  {expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </IconButton>
          </div>
        </div>

          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <div className={["internalCollapse"].join(" ")} style={{display: "flex", justifyContent: "space-around"}}>
              {reports}
            </div>
          </Collapse>

          <div>
            <Pagination
              className="pagination"
              page={pageParams.page}
              count={parseInt(reportData.number_of_pages)}
              onChange={(e, page) => handleChangePage(e, page)}
            />
          </div>
          
      </Card>

      )
	}
  const [fileErrorDialogOpen, setFileErrorDialogOpen] = useState(false)

	const [fileErrorDialogType, setFileErrorDialogType] = useState("")

	// const handleFileErrorDialogOpen = (errorType) => {
	// 	setFileErrorDialogType(errorType)
	// 	setFileErrorDialogOpen(true)
	// };
	
	const handleFileErrorDialogClose = () => {
		setFileErrorDialogType("")
		setFileErrorDialogOpen(false)
	};

	if (pageState.fetchedData) {
		return (
			<div style={{ width: "auto", paddingBottom: "30px" }}>
				<div>{contentContainer(pageState.fetchedData)}</div>
          <DialogError open={fileErrorDialogOpen}
                        onClose={handleFileErrorDialogClose}
                        errorType={fileErrorDialogType}/>
			</div>
		);
	}
	else if (pageState.isError){
		return (
			<div style={{ width: "auto" }}>
                <div style={{height: "55vh", display:"flex"}}>
                    <p className={["blueText", "titleSize"].join(" ")} style={{margin:"auto", textAlign:"center"}}>
                      {pageState.errorMessage === "Você não possui permissões para acessar essa página" 
                        ? t("request_report_denied")
                        : pageState.errorMessage}
                    </p>
                </div>
			</div>
		)
	}
	else if (pageState.isLoading) {
		return(
			<div style={{ width: "auto" }}>
				<div className='circularProgressCenter'>
					<CircularProgress />
				</div>
			</div>
		)
    }
    else return null
	
	}

export default ReportsTemplatesInbox