import React from "react";
import { useTranslation } from "react-i18next"
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from 'moment'
import {formatAsMoneyMinimum2Digits} from "../../../../utils/utils"

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
  },
  tableText: {
    fontFamily: "Open Sans",
    fontWeight: 'normal',
    fontSize: "12px",
    lineHeight: "16px",
    color: "#141414",
    textAlign: "center",
    margin: "10px",
    padding: "6px",
    maxWidth: "80px"
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: '#6F6F6F',
    fontSize: "14px",
    lineHeight: "19px",
    padding: "6px",
    maxWidth: "80px"
  },
  even: {
    background: "#F3F3F3",
  },
  odd: {
    background: "#FFFFFF",
  },
}));

function PublicEmployeeTable(props) {
  const classes = useStyles();
  useTheme();

  const { t } = useTranslation()

  const getClass = (rowIndex) => {
    if (rowIndex % 2 === 0) {
      return classes.even;
    }

    return classes.odd;
  };
  return (
    <Paper className={classes.tableStyle}>
      <Table style={{ padding: "8px", marginBottom: "30px" }}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader}>Atualmente é Servidor Público</TableCell> 
            <TableCell className={classes.tableHeader}>Total de Cargos Ativos</TableCell>
            <TableCell className={classes.tableHeader}>Renda</TableCell> 
            <TableCell className={classes.tableHeader}>Total de Cargos Exercidos</TableCell> 
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow className={getClass(0)}>
              <TableCell className={classes.tableText}>
              {props.public_employee_data[0].is_public_employee === false ? "não" :  "sim"}
              </TableCell>
              <TableCell className={classes.tableText}>
              {props.public_employee_data[0].total_active_professions}
              </TableCell>
              <TableCell className={classes.tableText}>
              {props.public_employee_data[0].total_income ? formatAsMoneyMinimum2Digits(props.public_employee_data[0].total_income * 100): "-"}
              </TableCell>
              <TableCell className={classes.tableText}>
                {props.public_employee_data[0].total_professions}
              </TableCell>
            </TableRow>
        </TableBody>
      </Table>
        {props.public_employee_data[0].professions.length > 0 &&
          <>
          <div className={["blueText", "normalMediumSize", "bold"].join(" ")}
              style={{margin:"10px"}}>
              Detalhes
          </div>
          <Table style={{ padding: "8px" }}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeader}>Área</TableCell> 
                <TableCell className={classes.tableHeader}>Setor</TableCell>
                <TableCell className={classes.tableHeader}>Cargo</TableCell>
                <TableCell className={classes.tableHeader}>Status</TableCell>
                <TableCell className={classes.tableHeader}>Renda</TableCell>
                <TableCell className={classes.tableHeader}>Data de Início</TableCell>
                <TableCell className={classes.tableHeader}>Data de Término</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.public_employee_data[0].professions.map((role,index) => (
                <TableRow
                  key={index}
                  className={getClass(index)}
                >
                  <TableCell className={classes.tableText}>
                    {role.area ? role.area : "-"}
                  </TableCell>
                  <TableCell className={classes.tableText}>
                    {role.company_name ? role.company_name : "-"}
                  </TableCell>
                  <TableCell className={classes.tableText}>
                    {role.level && role.level !== "Inválido" ? role.level : '-'} 
                  </TableCell>
                  <TableCell className={classes.tableText}>
                    {role.status ? t(role.status.toLowerCase()) : "-"}
                  </TableCell>
                  <TableCell className={classes.tableText}>
                    {role.income ? formatAsMoneyMinimum2Digits(role.income * 100) : role.income_range ? role.income_range : "-"}
                  </TableCell>
                  <TableCell className={classes.tableText}>
                    {role.start_date == null ? "-" : moment(role.start_date).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell className={classes.tableText}>
                    {role.end_date == null ? "-" : moment(role.end_date).format('DD/MM/YYYY')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>}
    </Paper>
  );
}

export default PublicEmployeeTable;
