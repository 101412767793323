import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import CheckIcon from "@material-ui/icons/Check";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {getTableRowClass, titleCase,colorRed, colorGreen, colorYellow,colorGrey} from "../../../utils/utils"

const useStyles = makeStyles((theme) => ({
    tableStyle: {
        border: "none",
        boxShadow: "none",
    },
    tableText: {
        fontFamily: "Open Sans",
        fontWeight: 'normal',
        fontSize: "12px",
        lineHeight: "16px",
        color: "#141414",
        textAlign: "center",
        margin: "10px",
        padding: "6px",
    },
    tableHeader: {
        textAlign: "center",
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: "normal",
        color: '#6F6F6F',
        fontSize: "14px",
        lineHeight: "19px",
        padding: "6px",
    }
}));

function PartnersDialogDecision(props) {
  const classes = useStyles();
  useTheme();

  return (
    <Dialog
        open={props.open} 
        onClose={props.onClose} 
        keepMounted={false}
        maxWidth="md"
        fullWidth
    >
        <DialogTitle className={["blueText", "subtitleSize"].join(" ")}>Quadro Societário</DialogTitle>
        <DialogContent>
            <Paper className={classes.tableStyle}>
            <Table style={{ marginBottom: "20px" }}>
                <TableHead>
                <TableRow>
                    <TableCell className={classes.tableHeader}>Nome</TableCell>
                    <TableCell className={classes.tableHeader}>CPF/CNPJ</TableCell>
                    <TableCell className={classes.tableHeader}>Validado</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {props.list_of_partners.map((partner,index) => (
                    <TableRow
                    key={index}
                    className={getTableRowClass(index)}
                    >
                    <TableCell className={classes.tableText}>
                        {titleCase(partner.name, "-")}
                    </TableCell>
                    <TableCell className={classes.tableText}>
                                {partner.document_number == null ? "-" : 
                                    validateCPF(partner.document_number) 
                                    ? 
                                    partner.document_number.replace(/[^\d]+/g,'').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,"$1.$2.$3-$4") 
                                    :
                                    validateCNPJ(partner.document_number) 
                                    ? partner.document_number.replace(/[^\d]+/g,'').replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"$1.$2.$3/$4-$5")
                                    :
                                    partner.document_number}
                            </TableCell>
                    <TableCell className={classes.tableText}>
                        {getCardIcon(partner)}
                    </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </Paper>
        </DialogContent>
    </Dialog>
  );
}

export default PartnersDialogDecision;

const getCardIcon = (partner) => {
    if (partner.flag === "positive"){
        return(
            <Tooltip title={<p className="tooltipText">Sócio validado em Bureaus</p>}>
                <CheckIcon style={{color:colorGreen}}/>
            </Tooltip>    
        )
    }
    else if (partner.flag === "negative"){
        return(
            <Tooltip title={<p className="tooltipText">Sócio não validado em Bureaus</p>}>
                <ErrorOutlineIcon style={{color:colorRed}}/>
            </Tooltip>
        )
    }
    else if (partner.flag === "neutral"){
        return(
            <Tooltip title={<p className="tooltipText">Sócio encontrado em Bureau não fornecido na requisição</p>}>
                <ErrorOutlineIcon style={{color:colorYellow}}/>
            </Tooltip>    
        )
    }
    else{
        return(
            <Tooltip title={<p className="tooltipText">Informação Indiponível</p>}>
                <ErrorOutlineIcon style={{color:colorGrey}}/>
            </Tooltip>      
        )
    }
};

function validateCPF(cpf) {
    if (typeof cpf !== "string") return false
    cpf = cpf.replace(/[^\d]+/g,'')

    if (
        !cpf ||
        cpf.length !== 11 ||
        cpf === "00000000000" ||
        cpf === "11111111111" ||
        cpf === "22222222222" ||
        cpf === "33333333333" ||
        cpf === "44444444444" ||
        cpf === "55555555555" ||
        cpf === "66666666666" ||
        cpf === "77777777777" ||
        cpf === "88888888888" ||
        cpf === "99999999999" 
    ) return false

    var sum = 0
    var remainder
    for (var count = 1; count <= 9; count++) 
        sum = sum + parseInt(cpf.substring(count-1, count)) * (11 - count)
    remainder = (sum * 10) % 11
    if ((remainder === 10) || (remainder === 11))  remainder = 0
    if (remainder !== parseInt(cpf.substring(9, 10)) ) return false

    sum = 0
    for (count = 1; count <= 10; count++) 
        sum = sum + parseInt(cpf.substring(count-1, count)) * (12 - count)
    remainder = (sum * 10) % 11
    if ((remainder === 10) || (remainder === 11))  remainder = 0
    if (remainder !== parseInt(cpf.substring(10, 11))) return false

    return true
}

function validateCNPJ(cnpj) {
    if (typeof cnpj !== "string") return false
    cnpj = cnpj.replace(/[^\d]+/g,'');
    if(cnpj === '') return false;
    if (cnpj.length !== 14) return false;

    if (cnpj === "00000000000000" || 
        cnpj === "11111111111111" || 
        cnpj === "22222222222222" || 
        cnpj === "33333333333333" || 
        cnpj === "44444444444444" || 
        cnpj === "55555555555555" || 
        cnpj === "66666666666666" || 
        cnpj === "77777777777777" || 
        cnpj === "88888888888888" || 
        cnpj === "99999999999999") return false;

    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0,tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
            pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado.toString() !== digitos.charAt(0)) return false;
        
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0,tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado.toString() !== digitos.charAt(1)) return false;
           
    return true;
}
