import React,{useContext} from "react";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import moment from 'moment'
import {getDepositStatusIcon, getReasonDescription } from "../../utils/utilsDeposit"
import {formatAsMoney, getAnalysisStatusColor, 
        getAnalysisStatusSiglum, getAnalysisStatus} from "../../utils/utils"
import Tooltip from "@material-ui/core/Tooltip";
import AuthContext from "../../../../context/auth-context";

function DepositCard(props){

    let {user_data} = useContext(AuthContext)

    return (
        <Card className="listCard">
            <Link
                className={["listCardItem", "link", "subtitleSize", "breakWord", "bold"].join(" ")}
                style={{flex:"1 1 110px", textAlign:"center"}}
                to={"/deposit/" + props.deposit.deposit_key}
            >
                {props.deposit.id}
            </Link>
            {user_data.business_group_key &&
            <div 
                className={["listCardItem", "normalText"].join(" ")}
                style={{flex:"1 1 80px", textAlign:"center"}}
            >
                {props.deposit.company_name}
            </div>}
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")} 
                style={{flex:"1 1 130px", textAlign:"center"}}  
            >
                {formatAsMoney(props.deposit.amount, "-")}
            </div>
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                style={{flex:"1 1 100px", textAlign:"center"}}
            >
                {(props.deposit.client || {}).document_number || "-"}
            </div>
            <div 
                className={["listCardItem", "normalText", "normalMediumSize", "bold"].join(" ")} 
                style={{flex:"1 1 45px", textAlign:"center", cursor: "default"}}  
            >
                <Tooltip title={<p className="tooltipText" style={{textAlign: "center"}}>
                    {getAnalysisStatus(props.deposit.analysis_status)}
                    <br/>{getReasonDescription(props.deposit)}
                </p>}>
                <div className={["circle", "small"].join(" ")} style={{backgroundColor:getAnalysisStatusColor(props.deposit.analysis_status)}}>
                    {getAnalysisStatusSiglum(props.deposit.analysis_status)}
                </div>
                </Tooltip>
            </div>
            <div 
                className={["listCardItem", "normalText", "subtitleSize","noWrap"].join(" ")}
                style={{flex:"1 1 110px", textAlign:"center"}}
            >
                {moment(props.deposit.deposit_date).format("DD/MM/YYYY HH:mm:ss")}
            </div>
            <div 
                className={["listCardItem", "normalText", "normalMediumSize", "bold"].join(" ")}  
                style={{flex:"1 1 35px", textAlign:"center", cursor: "default"}}  
            >
                {getDepositStatusIcon(props.deposit.deposit_status, "small", true)}
            </div>
        </Card>
    )
}

export default DepositCard