import React from 'react'
import CheckIcon from "@material-ui/icons/Check"
import ClearIcon from '@material-ui/icons/Clear'
import getSymbolFromCurrency from 'currency-symbol-map'

export const colorGreen = '#73C580'
export const colorRed = '#D91A1A'

export const getTableRowClass = (rowIndex) => {
  if (rowIndex % 2 === 0) {
    return "even"
  }

  return "odd"
}

export const getConfirmationIcon = (status) => {
  if (status) {
    return (<CheckIcon className="fraudStatusIcon" style={{ color: colorGreen, width: "40px", height: "40px" }} />)
  }
  else {
    return (<ClearIcon className="fraudStatusIcon" style={{ color: colorRed, width: "40px", height: "40px" }} />)
  }
}

export const setTransactionsInSessionStorage = (response, local_storage_object_preffix) => {
  let transactionsArray = []
  response.map(transaction => transactionsArray.push(transaction[`${local_storage_object_preffix}_key`]))
  sessionStorage.setItem(`${local_storage_object_preffix}sArray`, JSON.stringify(transactionsArray))
}

export const setGetTransactionsParamsInSessionStorage = (paramsObj, number_of_pages, local_storage_object_preffix) => {
  sessionStorage.setItem(`${local_storage_object_preffix}sParams`, JSON.stringify(paramsObj))
  sessionStorage.setItem(`${local_storage_object_preffix}sNumberOfPages`, JSON.stringify(number_of_pages))
}

export const formatAsCPFCNPJ = (value) => {
  let formatCPF = value.replace(/\D/g, '').length === 11
  let formatCNPJ = value.replace(/\D/g, '').length === 14

  if (formatCPF) {
    let value1 = value.slice(0, 3)
    let value2 = value.slice(3, 6)
    let value3 = value.slice(6, 9)
    let value4 = value.slice(9, 11)
    return value1 + "." + value2 + "." + value3 + "-" + value4
  }
  else if (formatCNPJ) {
    let value1 = value.slice(0, 2)
    let value2 = value.slice(2, 5)
    let value3 = value.slice(5, 8)
    let value4 = value.slice(8, 12)
    let value5 = value.slice(12, 14)
    return value1 + "." + value2 + "." + value3 + "/" + value4 + "-" + value5
  }
  else return value
}

export const formatAsCPFCNPJMasked = (value) => {
  let formatCPF = value.length === 11

  if (formatCPF) {
    let value2 = value.slice(3, 6)
    let value3 = value.slice(6, 9)
    return "***." + value2 + "." + value3 + "-**"
  }
  else {
    let value2 = value.slice(2, 5)
    let value3 = value.slice(5, 8)
    let value4 = value.slice(8, 12)
    return "**." + value2 + "." + value3 + "/" + value4 + "-**"
  }
}

export const formatAsMoneyUniversal = (currency, value, nullCase = null) => {
  let symbolFromCurrency = getSymbolFromCurrency(currency)
  if (value == null) return nullCase
  return (symbolFromCurrency + " " + (value / 100).toLocaleString('pt-BR', { minimumFractionDigits: 2 }))
}

export const formatAsMoney = (value, currency = "BRL") => {
  if (value) {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: currency,
    }).format(value / 100)
  }
}

export function removeSpecialCharacters(text) {
  let specialChars = /[^a-zA-Z0-9\s_]/g;
  return text.replace(specialChars, "");
}

export function  removeDuplicatesFromArrayOfObjects(inputArray) {
  const uniqueArray = [];
  const seenNames = new Set();
  
  inputArray.forEach(obj => {
    if (!seenNames.has(obj.name)) {
      uniqueArray.push(obj);
      seenNames.add(obj.name);
    }
  });
  
  return uniqueArray;
}

export function levenshteinDistance(a, b) {
  const matrix = [];

  for (let i = 0; i <= b.length; i++) {
      matrix[i] = [i];
  }

  for (let j = 0; j <= a.length; j++) {
      matrix[0][j] = j;
  }

  for (let i = 1; i <= b.length; i++) {
      for (let j = 1; j <= a.length; j++) {
          if (b.charAt(i - 1) === a.charAt(j - 1)) {
              matrix[i][j] = matrix[i - 1][j - 1];
          } else {
              matrix[i][j] = Math.min(
                  matrix[i - 1][j - 1] + 1,
                  matrix[i][j - 1] + 1,
                  matrix[i - 1][j] + 1
              );
          }
      }
  }

  return matrix[b.length][a.length];
}

export function sortByLevenshteinDistance(arr, key, targetStr) {
  return arr.sort((a, b) => {
      const distA = levenshteinDistance(a[key], targetStr);
      const distB = levenshteinDistance(b[key], targetStr);
      return distA - distB;
  });
}