import React, {useContext} from "react";
import AuthContext from "../../../../context/auth-context"
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import {formatIrregularity,getSeverityColor,getSeverityStatus,getSeverityStatusSiglum,getAlertStatusColor,getAlertStatusText, getAlertStatusTextSiglum, formatListCardName} from "../../utils/utilsAlert"
import moment from 'moment'
import Tooltip from "@material-ui/core/Tooltip";

function AlertCard(props){

    let user_data = useContext(AuthContext).user_data

    return (
        <Card className="listCard">
            <Link
                className={["listCardItem", "link", "subtitleSize", "breakWord", "bold"].join(" ")}
                style={{flex:"2 1 180px", textAlign:"center"}}
                to={"/alert/" + props.alert.alert_key}
            >
                {props.alert.link_key_type === "cardholder" ? props.alert.cardholder_id : props.alert.merchant_id}
            </Link>
            {user_data.business_group_key ?
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                style={{flex:"2 1 180px", textAlign:"center"}}
            >
                {props.alert.company_name || "-"}
            </div> : null}
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                style={{flex:"3 1 230px", textAlign:"center"}}
            >
                {formatListCardName(props.alert.link_key_type, props.alert.link_key_data)}
            </div>
            <div 
                className={["listCardItem", "normalText", "normalMediumSize", "bold"].join(" ")} 
                style={{flex:"1 1 45px", textAlign:"center", cursor: "default"}}  
            >
                <Tooltip title={<p className="tooltipText">{"Severidade do alerta: " + getSeverityStatus(props.alert.risk_level)}</p>}>
                    <div className={["circle", "small"].join(" ")} style={{backgroundColor:getSeverityColor(props.alert.risk_level)}}>
                        {getSeverityStatusSiglum(props.alert.risk_level)}
                    </div>
                </Tooltip>
            </div>
            <Tooltip title={<p className="tooltipText">Quantidade de gatilhos no alerta</p>}>
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                style={{flex:"1 1 80px", textAlign:"center"}}
            >
                <div className="triggerNumberStyle">
                    {props.alert.triggers == null || props.alert.triggers.length === 0 ? null : props.alert.triggers.length}
                </div>               
            </div>
            </Tooltip>
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                style={{flex:"1 1 45px", textAlign:"center"}} 
            >
                {formatIrregularity(props.alert.irregularity_type, false, true)}
            </div>
            <div 
                className={["listCardItem", "normalText", "normalMediumSize", "bold"].join(" ")} 
                style={{flex:"1 1 45px", textAlign:"center", cursor: "default"}}  
            >
                <Tooltip title={<p className="tooltipText">{getAlertStatusText(props.alert.alert_status)}</p>}>
                <div className={["circle", "small"].join(" ")} style={{backgroundColor:getAlertStatusColor(props.alert.alert_status)}}>
                    {getAlertStatusTextSiglum(props.alert.alert_status)}
                </div>
                </Tooltip>
            </div>
            <div 
                className={["listCardItem", "normalText", "subtitleSize","noWrap"].join(" ")}
                style={{flex:"1 1 110px", textAlign:"center"}}
            >
                {moment(props.alert.occurrence_date).format("DD/MM/YYYY HH:mm:ss")}
            </div>
        </Card>
    )
}

export default AlertCard