import React from 'react'
import legalPerson from '../../../../../../assets/cardEntryIcons/legalPerson.svg';
import naturalPerson from '../../../../../../assets/cardEntryIcons/naturalPerson.svg';

function PersonTypeIcon(props) {
    let { personType } = props
    return(
        <div
            style={{width:"25%", marginRight:"15px", padding:"0px"}}
        >
            <div className="centralize" style={{backgroundColor:"#FFFFFF",
            borderRadius:"12px", height:"80px", width:"80px"}}>
                {personType==='natural_person'?
                <div>
                    <img style={{margin:"auto"}} src={naturalPerson} alt="person_type Logo"/>
                </div>:
                <div>
                    <img style={{margin:"auto"}} src={legalPerson} alt="person_type Logo"/>
                </div>}
            </div>
        </div>
    )
}

export default PersonTypeIcon