import React, {useState} from 'react'
import { CircularProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createMuiTheme({
    palette:{
        primary: {
            main:"#0b1f82"
        },
    }
  });

function CompaniesContent (props){

    const [value, setValue] = useState(null)

    const {companiesList} = props

    const handleSelectedCompany = () => {
        if (value){
            props.setSelectCompany({
                status:false,
                company_key: value.company_key
            })
        }
    }
    
    if (companiesList==null){
        return (
            <div style={{display:"flex", flexGrow:"1"}}>
                <CircularProgress style={{margin:"auto"}}/>
            </div>
        )
    }
    else {
        return (
            <div style={{ display:"flex", flexDirection:"column", flexGrow:"1"}}>
                <div style={{margin:"10px auto"}}>
                    <ThemeProvider theme={theme}>
                        <Autocomplete
                            id="combo-box-demo"
                            value={value}
                            onChange={(event, newValue) => {
                            setValue(newValue);
                            }}
                            options={companiesList.companies}
                            getOptionLabel={(option) => option.name}
                            style={{ width: 300 }}
                            renderInput={(params) => 
                                <TextField 
                                    onKeyPress= {(e) => {if (e.key === 'Enter') {handleSelectedCompany()}}} 
                                    {...params} 
                                    autoFocus 
                                    label="Selecione um cliente" 
                                    color="primary" 
                                    variant="outlined" 
                                />}
                        />
                    </ThemeProvider>
                </div>
                <div
                    className={value == null ? ["button","standard", "disabled", "normalText", "normalMediumSize"].join(" ") : ["button","standard", "normalText", "normalMediumSize"].join(" ")} 
                    onClick={handleSelectedCompany} 
                    style={{margin:"auto auto 20px auto", width:"40%"}}>
                    Selecionar
                </div>
            </div>
        )
    }

}

export default CompaniesContent