import React, { useState } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { getEventTypeName } from "../../utils/utilsLock"

const theme = createMuiTheme({
    overrides: { MuiInputBase: { input:{ padding: '6px 0px 3px 5px'}}}
  });  

function EventTypeFilter(props) {

    const [currentValue, setCurrentValue] = useState(props.initialEventType)

    return(
        <div className="filterBoxContainer normalSmallSize">
            <div className="filterBox">
            <ThemeProvider theme={theme}>
                <Select 
                    className="selectedFilter normalSmallSize"
                    displayEmpty
                    renderValue={currentValue===undefined?() => "Tipo de Evento":() => getEventTypeName(currentValue)}
                    value={currentValue}
                    onChange={(e)=> {
                        props.handleFilterEventTypeChange(e)
                        setCurrentValue(e.target.value)
                    }}
                >
                    {props.filterEventTypeOptions.map(
                        eventType => 
                            (
                                <MenuItem 
                                    key={eventType} 
                                    value={eventType}
                                >
                                    {getEventTypeName(eventType)}
                                </MenuItem>
                            )
                    )}
                </Select>
            </ThemeProvider> 
            </div>
        </div>
    )
}

export default EventTypeFilter;