import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ValidatedImage from "../../../utils/ValidatedImage"
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";


const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: "16px",
    color: "#0b1f82",
    padding: "16px 24px 0px 24px",
  },
  dialogContent: {
    textAlign: "center",
    marginBottom: "10px",
    float: "left",
  },
  centralizeArrow: {
    height: "40%",
  },
  arrows: {
    color: "#0b1f82",
  },
  mainCard: {
    position: "relative",
    display: "inline-block",
    textAlign: "center",
    width: "auto",
    height: "auto",
    float: "center",
    margin: "auto",
  },
  buttonPrevious: {
    cursor: "pointer",
    width: "25%",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: "#ddddf9",
    },
  },
  buttonNext: {
    cursor: "pointer",
    width: "25%",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: "#ddddf9",
    },
  },
  label: {
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "19px",
    color: "#000",
  },
  data: {
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "19px",
    color: "#6F6F6F",
  },
  paragraph: {
    margin: "0",
    width: "100%",
  },
}));

function MilitaryRegistryDialog(props) {
  const classes = useStyles();
  useTheme();

  const [counter, setCounter] = useState(0);

  const image_list_filtered = props.image_list.filter(image => image != null)

  const clickNext = () => {
    if (counter + 1 === image_list_filtered.length) {
      setCounter(0);
    } else {
      setCounter(counter + 1);
    }
  };

  const clickPrevious = () => {
    if (counter === 0) {
      setCounter(image_list_filtered.length - 1);
    } else {
      setCounter(counter - 1);
    }
  };

  return (
        <Dialog open={props.open} onClose={props.onClose} keepMounted={false} fullWidth={true} maxWidth={"lg"}>
            <DialogTitle className={classes.dialogTitle}>Fotos</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <div style={{display:"flex", margin:"auto", justifyContent: "center"}}>
                    <div className="labelSpace" style={{order: "2", display:"flex"}}>
                        {image_list_filtered[counter] ?
                        <ValidatedImage
                        image_key={image_list_filtered[counter]}
                        dash_api_endpoint_url={'/dash/ocr/image'}
                        imageStyle = {{maxWidth: "100px", maxHeight: "100px", borderRadius: "5px"}}
                        imageAlt = {"Foto do Documento"}
                        zoom_enabled = {true}
                        /> :
                        <div 
                            className={["labelText", "normalMediumSize"].join(" ")} 
                            style={{textAlign:"center", margin:"auto"}}
                        >
                            Informação Indisponível
                        </div>                        
                        }
                    </div>
                    {image_list_filtered.length < 2 ? null :
                    <React.Fragment>
                    <div onClick={clickPrevious} className={classes.buttonPrevious} style={{order: "1", display:"flex"}}>
                        <ArrowBackIosIcon style={{margin:"auto"}} className={classes.arrows} />
                    </div>
                    <div onClick={clickNext} className={classes.buttonPrevious} style={{order: "3", display:"flex", marginLeft:"5px"}}>
                        <ArrowForwardIosIcon style={{margin:"auto"}} className={classes.arrows} />
                    </div>
                    </React.Fragment>}
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default MilitaryRegistryDialog;
