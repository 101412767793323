import React, { useEffect } from "react";
import { CircularProgress } from '@material-ui/core';
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";
import TransactionHistoricTable from "./TransactionHistoricTable";

function TransactionHistoric(props) {

    const {document_number} = props
    const {final_date} = props
    const {transaction_key} = props
    const {alert_triggers_transactions} = props
  
    const [userHistoric, setUserHistoric] = React.useState(null)
    const [tablePage, setTablePage] = React.useState(1)
  
    useEffect(() => {
        if (userHistoric === null){
            if (document_number==null) setUserHistoric(undefined)
            else{
                let params = {
                    "page_number": tablePage -1,
                    "page_rows": 10,
                    "document_number": document_number,
                    "final_date": final_date
                }
                const timer = setTimeout(() => {
                    axios.get('/dash/pix_backoffice/transactions', {
                        params: params
                    }).then(response=>{
                        let filteredTransactions = response.data.data.filter(transaction => transaction.transaction_key !== transaction_key)
                        let filteredResponseObject = {...response.data, data:filteredTransactions}
                        setUserHistoric(filteredResponseObject)
                    }).catch(error=>{
                        setUserHistoric(undefined)
                    })
                }, 500);
                return () => {
                    clearTimeout(timer)
                }
            }
        }
    }, [userHistoric,tablePage,document_number,final_date, transaction_key])

    const handleChangePage = (event, page) => {
		if (tablePage === page) {
			return
		}
        setTablePage(page)
        setUserHistoric(null)
	}
  
    if (userHistoric === null) {
      return (
        <div style={{display: "flex", height: "160px"}}>
            <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                <CircularProgress />
            </div>
        </div>
      );    
    }
    else if (userHistoric === undefined ){
        return (
          <div style={{display: "flex", height: "60px"}}>
              <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                  Informação Indisponível
              </div>
          </div>
        )
    }
    else if (userHistoric.data.length === 0 && userHistoric.number_of_pages >= tablePage){
    return (
        <div style={{display: "flex", height: "60px"}}>
            <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
            Sem Histórico de Transação
            </div>
        </div>
    )
    }
    else {
        return (
                <div>
                    <div>
                        <TransactionHistoricTable 
                            historic={userHistoric.data} 
                            alert_triggers_transactions={alert_triggers_transactions}
                        />
                    </div>
                    <div style={{display:"flex", marginTop: "10px"}}>
                        <Pagination style={{marginLeft:"auto"}} className='pagination' page={tablePage} count={userHistoric['number_of_pages']} onChange={(e,page) => handleChangePage(e, page)} />
                    </div>
                </div>
        );
    }
  }
  
  export default TransactionHistoric;