import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Table, TableBody, TableCell, TableRow, Paper, Collapse, IconButton, Tooltip } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import {formatAsDate,getMapRiskCalculationTreeObj,formatAsMoney,scr_table_obj_translation,scr_submodalities_translation,scr_modalities_translation} from "../../utils/utils";

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
    margin: "10px 50px"
  },
  tableHeader_: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: 900,
    lineHeight: "16px",
    color: "#6F6F6F",
    textAlign: "left",
    margin: "10px",
    padding: "6px 6px 6px 0px",
  },
  tableHeader: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: 900,
    lineHeight: "16px",
    color: "#FFFFFF",
    textAlign: "left",
    margin: "10px",
    padding: "6px 6px 6px 20px",
  },
  tableHeader2: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "16px",
    color: "#FFFFFF",
    textAlign: "left",
    margin: "10px",
    padding: "6px 6px 6px 30px",
  },
  tableHeader3: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: 900,
    lineHeight: "16px",
    color: "#6F6F6F",
    textAlign: "left",
    margin: "10px",
    padding: "6px 6px 6px 40px",
  },
  tableText: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#6F6F6F",
    textAlign: "left",
    margin: "10px",
    padding: "6px 6px 6px 50px",
  },
  tableValue: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: 900,
    lineHeight: "16px",
    color: "#6F6F6F",
    textAlign: "left",
    margin: "10px",
    padding: "6px 6px 6px 20px",
  },
  tableValue1: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#6F6F6F",
    textAlign: "left",
    margin: "10px",
    padding: "6px 6px 6px 20px",
  },
  even: {
    background: "#F3F3F3",
  },
  odd: {
    background: "#FFFFFF",
  },
  bg_header_1: {
    background: "#7C99AC",
  },
  bg_header_1_hidden: {
    background: "#7C99AC",
    display: "none",
  },
  bg_header_2: {
    background: "#92A9BD",
  },
  bg_header_2_hidden: {
    background: "#92A9BD",
    display: "none",
  },
  bg_header_3: {
    background: "#D3DEDC",
  },
  bg_header_3_hidden: {
    background: "#D3DEDC",
    display: "none",
  },
  bg_header_3_white: {
    background: "white",
    border: "none",
    width: "10px"
  },
  bg_header_3_white_hidden: {
    background: "white",
    display: "none",
    border: "none",
    width: "10px"
  },
  bg_header_4: {
    background: "#ededed",
  },
  bg_header_4_hidden: {
    background: "#ededed",
    display: "none",
  },
  bg_header_5: {
    background: "#2b6a92",
  },
  bg_icon: {
    background: "none",
  },
  // level 6
  row_modality: {
    background: "white",
    // border: "none",
    // boxShadow: "none",
    // fontFamily: "Open Sans",
    // textAlign: "left",
    // margin: "10px",
    // padding: "6px 6px 6px 0px",
    // lineHeight: "16px",
    // fontSize: "14px",
    // // fontWeight: "900",
    // color: "#6F6F6F",
    // paddingLeft: "100px",
  },
  row_modality_hidden:{
    background: "white",
    display: "none"
  }
}));

function SCRTable(props) {

  const classes = useStyles();
  useTheme();

  const {t} = useTranslation()

  let {scr_data: {reference_date,risk_calculation_tree,risk_calculation_tree_foreign_currency,receive_coobligation},
    showDropDown, isPDF } = props;

  const mapped_risk_obj = getMapRiskCalculationTreeObj(risk_calculation_tree);
  const mapped_risk_obj_foreign_currency = getMapRiskCalculationTreeObj(risk_calculation_tree_foreign_currency);

  let credit_to_be_expired_periods = scr_table_obj_translation["credit_to_be_expired"];
  let credit_overdue_periods = scr_table_obj_translation["credit_overdue"];
  let financial_loss_periods = scr_table_obj_translation["financial_loss"];
  let credit_to_be_released_periods = scr_table_obj_translation["credit_to_be_released"];
  let credit_limit_periods = scr_table_obj_translation["credit_limit"];
  
  let modalities01to14 = ["01","02","03","04","05","06","07","08","09","10","11","12","13","14" ];
  let modalities01_13 = ["01","02","03","04","05","06","07","08","09","10","11","12","13"];
  
  // domain_group => credit_to_be_expired, credit_overdue, financial_loss, credit_to_be_released, credit_limit

  // 1 modalidade Apenas (não precisa trazer o título da modalidade)
  const getXByPeriodByModality = ( risk_obj, risk_obj_foreign_currency, domain_group, description, modality_code, expanded ) => {
    return (
      <>
        <TableRow className={expanded ? classes.bg_header_4 : classes.bg_header_4_hidden}>
          <TableCell className={classes.tableText}style={{ paddingLeft: "60px" }}>{t(domain_group + "_" + description)}</TableCell>
          <TableCell className={classes.tableValue1}>{risk_obj ? formatAsMoney("BRL", risk_obj["sum"]) : formatAsMoney("BRL", 0)}</TableCell>
          <TableCell className={classes.tableValue1}>{risk_obj_foreign_currency ? formatAsMoney("BRL", risk_obj_foreign_currency["sum"]) : formatAsMoney("BRL", 0)}</TableCell>
          <TableCell className={classes.bg_header_3_white}style={{ padding: "0px" }}></TableCell>
        </TableRow>
        {getSubmodalities(risk_obj, risk_obj_foreign_currency, modality_code, expanded,)}
      </>
    );
  };

  const getAllPeriods = ( risk_obj, risk_obj_foreign_currency, periods_translation_list, domain_group, modality_code, expanded) => {
    return (
      <>
        {periods_translation_list.map((period) =>
          (risk_obj || {})[period] || (risk_obj_foreign_currency || {})[period] 
           ? getXByPeriodByModality(risk_obj[period], risk_obj_foreign_currency[period], domain_group, period, modality_code, expanded)
           : null
        )}
      </>
    );
  };
  
  //  Mais de uma modalidade => quem define é a modality list (de 01 á 13, de 01 à 14) 
  const getXByPeriodByModalityListXXTOYY = ( risk_obj, risk_obj_foreign_currency, domain_group, description, modality_list, expanded) => {
    return (
      <>
        <TableRow className={expanded ? classes.bg_header_4 : classes.bg_header_4_hidden}>
          <TableCell className={classes.tableText}style={{ paddingLeft: "60px" }}>{t(domain_group + "_" + description)}</TableCell>
          <TableCell className={classes.tableValue1}>{risk_obj ? formatAsMoney("BRL", risk_obj["sum"]) : formatAsMoney("BRL", 0)}</TableCell>
          <TableCell className={classes.tableValue1}>{risk_obj_foreign_currency ? formatAsMoney("BRL", risk_obj_foreign_currency["sum"]) : formatAsMoney("BRL", 0)}</TableCell>
          <TableCell className={classes.bg_header_3_white} style={{ padding: "0px" }}></TableCell>
        </TableRow>
        {getModalityXXtoYYAndSubmodalities( risk_obj, risk_obj_foreign_currency, modality_list, expanded)}
      </>
    );
  };

  const getAllPeriodsByModalityListXXTOYY = ( risk_obj, risk_obj_foreign_currency, periods_translation_list, domain_group, modality_list, expanded) => {
    return (
      <>
        {periods_translation_list.map(
          (period) =>((risk_obj || {})[period] ||  (risk_obj_foreign_currency || {})[period]) && 
           getXByPeriodByModalityListXXTOYY(  risk_obj[period],  risk_obj_foreign_currency[period],  domain_group,  period,  modality_list,  expanded)
        )}
      </>
    );
  };

  const getModalityXXtoYYAndSubmodalities = ( risk_obj, risk_obj_foreign_currency, modality_list, expanded) => {
    return (
      <>
        {modality_list.map((modality, index) => {
          let protected_modality_risk_obj = ( ( risk_obj || {} )[modality] )
          let protected_modality_risk_obj_foreign_currency = ( ( risk_obj_foreign_currency || {} )[modality] )
          return protected_modality_risk_obj || protected_modality_risk_obj_foreign_currency ? (
            <>
              <TableRow key={index} className={expanded ? classes.row_modality : classes.row_modality_hidden}>
                <TableCell className={classes.tableText} style={{ paddingLeft: "60px" }}>
                  {t(scr_modalities_translation[modality])}
                </TableCell>
                <TableCell className={classes.tableValue1}>
                  {protected_modality_risk_obj ? formatAsMoney("BRL", protected_modality_risk_obj["sum"]) : formatAsMoney("BRL", 0)}
                </TableCell>
                <TableCell className={classes.tableValue1}>
                  {protected_modality_risk_obj_foreign_currency ? formatAsMoney("BRL", protected_modality_risk_obj_foreign_currency["sum"]) : formatAsMoney("BRL", 0)}
                </TableCell>
                <TableCell className={classes.bg_header_3_white} style={{ padding: "0px" }}></TableCell>
              </TableRow>
              {getSubmodalities(risk_obj, risk_obj_foreign_currency, modality, expanded)}
            </>
          ) : null
        })}
      </>
    );
  };

// submodalidades 
  const getSubmodalities = ( risk_obj, risk_obj_foreign_currency, modality_code, expanded) => {
    let submodalities = Object.entries(scr_submodalities_translation[modality_code]);
    return (
      <>
        {submodalities.map((submodality, index) => {
          let [submodality_code, submodality_description] = submodality;
          let protected_submodality_risk_obj = ( ( ( risk_obj || {} )[modality_code] || {} )[submodality_code] )
          let protected_submodality_risk_obj_foreign_currency = ( ( ( risk_obj_foreign_currency || {} )[modality_code] || {} )[submodality_code] )
          return (
            <>
              {(protected_submodality_risk_obj || protected_submodality_risk_obj_foreign_currency ) && (
                <TableRow key={index} className={expanded ? classes.row_modality : classes.row_modality_hidden}>
                  <TableCell className={classes.tableText} style={{ paddingLeft: "70px" }}>{t(submodality_description)}
                  </TableCell>
                  <TableCell className={classes.tableValue1}>
                    {protected_submodality_risk_obj ? formatAsMoney("BRL", protected_submodality_risk_obj["sum"]) : formatAsMoney("BRL", 0)}
                  </TableCell>
                  <TableCell className={classes.tableValue1}>
                    {protected_submodality_risk_obj_foreign_currency ? formatAsMoney("BRL", protected_submodality_risk_obj_foreign_currency["sum"]) : formatAsMoney("BRL", 0)}
                  </TableCell>
                  <TableCell className={classes.bg_header_3_white} style={{ padding: "0px" }}></TableCell>
                </TableRow>
              )}
            </>
          );
        })}
      </>
    );
  };

  const getHeader = () => (
    <TableRow className={classes.bg_header_5}>
      <TableCell className={classes.tableHeader}></TableCell>
      <TableCell className={classes.tableHeader}>R$</TableCell>
        <Tooltip title={<p className="tooltipText">{t("amount_subject_to_exchange_rate_variation")}</p>}>
          <TableCell className={classes.tableHeader}>R$ *</TableCell>
        </Tooltip>
      <Tooltip title={<p className="tooltipText">{t("expand_colapse_all")}</p>}>
        <TableCell className={classes.bg_header_3_white} style={{ padding: "0px" }}>
          {expandedAll 
            ? <IconButton size="small" onClick={handleCollapseAll}><RemoveCircleOutlineIcon size="small" style={{ color: "#BBB" }}/></IconButton>
            : <IconButton size="small" onClick={handleExpandAll}><AddCircleOutlineIcon size="small" style={{ color: "#BBB" }}/></IconButton>}
        </TableCell>
      </Tooltip>
  </TableRow>
  )
  const getRow = (row_style, cell_style_tittle, cell_style_value, risk_obj, risk_obj_foreign_currency, description, handleExpand=null, showExpandIcon=null, expanded=null) => {
    return (
      <TableRow className={row_style}>
        <TableCell className={cell_style_tittle}>{description}</TableCell>
        <TableCell className={cell_style_value}>{formatAsMoney("BRL", risk_obj)}</TableCell>
        <TableCell className={cell_style_value}>{formatAsMoney("BRL", risk_obj_foreign_currency)}</TableCell>
        <TableCell className={classes.bg_header_3_white} style={{ padding: "0px" }}>
          <IconButton size="small" onClick={handleExpand}>
                {showExpandIcon
                      ? expanded
                        ? <RemoveIcon fontSize="small" style={{color:'#BBB'}}/> 
                        : <AddIcon fontSize="small" style={{color:'#BBB'}}/>
                      : null}
          </IconButton>  
        </TableCell>
      </TableRow>
    );
  };
 
  const [expandedHistory, setExpandedHistory] = useState(false);

  const handleExpandHistory = (event) => {
    event.stopPropagation();
    setExpandedHistory(!expandedHistory);
  };

  const [expandedA, setExpandedA] = useState(false);

  const handleExpandA = (event) => {
    event.stopPropagation();
    setExpandedA(!expandedA);
  };

  const [expandedB, setExpandedB] = useState(false);

  const handleExpandB = (event) => {
    event.stopPropagation();
    setExpandedB(!expandedB);
  };

  const [expandedC, setExpandedC] = useState(false);

  const handleExpandC = (event) => {
    event.stopPropagation();
    setExpandedC(!expandedC);
  };

  const [expandedD, setExpandedD] = useState(false);

  const handleExpandD = (event) => {
    event.stopPropagation();
    setExpandedD(!expandedD);
  };

  const [expandedE, setExpandedE] = useState(false);

  const handleExpandE = (event) => {
    event.stopPropagation();
    setExpandedE(!expandedE);
  };

  const [expandedF, setExpandedF] = useState(true);

  const handleExpandF = (event) => {
    event.stopPropagation();
    setExpandedF(!expandedF);
  };

  const [expandedG, setExpandedG] = useState(false);

  const handleExpandG = (event) => {
    event.stopPropagation();
    setExpandedG(!expandedG);
  };

  const [expandedH, setExpandedH] = useState(false);

  const handleExpandH = (event) => {
    event.stopPropagation();
    setExpandedH(!expandedH);
  };

  // CARTEIRA DE CRÉDITO
  const [expandedI, setExpandedI] = useState(true);

  const handleExpandI = (event) => {
    event.stopPropagation();
    setExpandedI(!expandedI);
  };

  const [expandedAll, setExpandedAll] = useState(false);

  const handleExpandAll = (event) => {
    event.stopPropagation();
    setExpandedA(true);
    setExpandedB(true);
    setExpandedC(true);
    setExpandedD(true);
    setExpandedE(true);
    setExpandedG(true);
    setExpandedH(true);
    setExpandedAll(!expandedAll);
  };

  const handleCollapseAll = (event) => {
    event.stopPropagation();
    setExpandedA(false);
    setExpandedB(false);
    setExpandedC(false);
    setExpandedD(false);
    setExpandedE(false);
    setExpandedG(false);
    setExpandedH(false);
    setExpandedAll(!expandedAll);
  };

  useEffect(() => {
    if (!showDropDown) {
      setExpandedHistory(true);
    }
    if (isPDF) {
      setExpandedHistory(true);
      setExpandedA(true);
      setExpandedB(true);
      setExpandedC(true);
      setExpandedD(true);
      setExpandedE(true);
      setExpandedG(true);
      setExpandedH(true);
    }
  }, [showDropDown, isPDF]);
  let showExpandIconA = (!!mapped_risk_obj['credit_to_be_expired_modality_01_to_13_obj']['sum'] ||!!mapped_risk_obj_foreign_currency['credit_to_be_expired_modality_01_to_13_obj']['sum']) //A VENCER
  let showExpandIconB = (!!mapped_risk_obj['credit_overdue_modality_01_to_13_obj']['sum'] ||!!mapped_risk_obj_foreign_currency['credit_overdue_modality_01_to_13_obj']['sum']) // VENCIDO
  let showExpandIconC = (!!mapped_risk_obj['financial_loss_modality_14_obj']['sum'] ||!!mapped_risk_obj_foreign_currency['financial_loss_modality_14_obj']['sum']) // PREJUÍZO
  let showExpandIconD = (!!mapped_risk_obj.interfinancial_transfers_sum ||!!mapped_risk_obj_foreign_currency.interfinancial_transfers_sum) // Repasses Interfinanceiros
  let showExpandIconE = (!!mapped_risk_obj.coobligation_sum ||!!mapped_risk_obj_foreign_currency.coobligation_sum) // Coobrigações
  let showExpandIconF = !expandedI && !expandedD && !expandedE // RESPONSABILIDADE TOTAL
  let showExpandIconG = (!!mapped_risk_obj['credit_to_be_released_modality_01_to_13_obj']['sum'] ||!!mapped_risk_obj_foreign_currency['credit_to_be_released_modality_01_to_13_obj']['sum']) // Creditos a liberar
  let showExpandIconH = (!!mapped_risk_obj['credit_limit_modality_19_obj']['sum'] ||!!mapped_risk_obj_foreign_currency['credit_limit_modality_19_obj']['sum']) // Limite de Crédito
  let showExpandIconI = !expandedA && !expandedB && !expandedC // CARTEIRA DE CRÉDITO


  // A VENCER
  const AllCreditToBeExpiredModality01to13 = getAllPeriodsByModalityListXXTOYY(
    mapped_risk_obj["credit_to_be_expired_modality_01_to_13_obj"],
    mapped_risk_obj_foreign_currency[
      "credit_to_be_expired_modality_01_to_13_obj"
    ],
    credit_to_be_expired_periods,
    "credit_to_be_expired",
    modalities01_13,
    expandedA,
    showExpandIconA,
    handleExpandA
  );
  // VENCIDO
  const AllCreditOverdueModality01to13 = getAllPeriodsByModalityListXXTOYY(
    mapped_risk_obj["credit_overdue_modality_01_to_13_obj"],
    mapped_risk_obj_foreign_currency["credit_overdue_modality_01_to_13_obj"],
    credit_overdue_periods, "credit_overdue", modalities01_13, expandedB
  );
  // PREJUÍZO
  const AllFinancialLossModality01to14 = getAllPeriodsByModalityListXXTOYY(
    mapped_risk_obj["financial_loss_modality_14_obj"],
    mapped_risk_obj_foreign_currency["financial_loss_modality_14_obj"],
    financial_loss_periods,
    "financial_loss",
    modalities01to14,
    expandedC,
  );
  // REPASSES INTERFINANCEIROS
  const AllCreditToBeExpiredModality14 = getAllPeriods(
    mapped_risk_obj["credit_to_be_expired_modality_14_obj"],
    mapped_risk_obj_foreign_currency["credit_to_be_expired_modality_14_obj"],
    credit_to_be_expired_periods,
    "credit_to_be_expired",
    "14",
    expandedD
  );
  const AllCreditOverdueModality14 = getAllPeriods(
    mapped_risk_obj["credit_overdue_modality_14_obj"],
    mapped_risk_obj_foreign_currency["credit_overdue_modality_14_obj"],
    credit_overdue_periods,
    "credit_overdue",
    "14",
    expandedD
  );
  const AllCreditToBeReleaseModality14 = getAllPeriods(
    mapped_risk_obj["credit_to_be_released_modality_14_obj"],
    mapped_risk_obj_foreign_currency["credit_to_be_released_modality_14_obj"],
    credit_to_be_released_periods,
    "credit_to_be_released",
    "14",
    expandedD
  );
  // COOBRIGAÇÕES (E)
  const AllCreditToBeExpiredModality15 = getAllPeriods(
    mapped_risk_obj["credit_to_be_expired_modality_15_obj"],
    mapped_risk_obj_foreign_currency["credit_to_be_expired_modality_15_obj"],
    credit_to_be_expired_periods,
    "credit_to_be_expired",
    "15",
    expandedE
  );
  const AllCreditOverdueModality15 = getAllPeriods(
    mapped_risk_obj["credit_overdue_modality_15_obj"],
    mapped_risk_obj_foreign_currency["credit_overdue_modality_15_obj"],
    credit_overdue_periods,
    "credit_overdue",
    "15", 
    expandedE
  );
  const AllCreditToBeReleasedModality15 = getAllPeriods(
    mapped_risk_obj["credit_to_be_released_modality_15_obj"],
    mapped_risk_obj_foreign_currency["credit_to_be_released_modality_15_obj"],
    credit_to_be_released_periods,
    "credit_to_be_released",
    "15", 
    expandedE
  );
  // CRÉDITOS À LIBERAR (G)
  const AllCreditToBeReleasedModality01to13 = getAllPeriodsByModalityListXXTOYY(mapped_risk_obj["credit_to_be_released_modality_01_to_13_obj"], mapped_risk_obj_foreign_currency["credit_to_be_released_modality_01_to_13_obj"],credit_to_be_released_periods, "credit_to_be_released", modalities01_13, expandedG);
  // LIMITE DE CRÉDITO (H) 
  const AllCreditLimit = getAllPeriods( mapped_risk_obj["credit_limit_modality_19_obj"], mapped_risk_obj_foreign_currency["credit_limit_modality_19_obj"], credit_limit_periods, "credit_limit", "19", expandedH);


  const header = getHeader()
  const total_risk_FGHI = getRow(classes.bg_header_5, classes.tableHeader, classes.tableHeader, mapped_risk_obj["total_risk_sum"], mapped_risk_obj_foreign_currency["total_risk_sum"],t("scr_table_total_risk_sum")) 
  const total_responsability_F = getRow(classes.bg_header_1, classes.tableHeader, classes.tableHeader, mapped_risk_obj["total_responsability_sum"], mapped_risk_obj_foreign_currency["total_responsability_sum"],t("scr_table_total_responsability_sum"), handleExpandF, showExpandIconF, expandedF) 
  const credit_to_be_released_G = getRow(classes.bg_header_1, classes.tableHeader, classes.tableHeader, mapped_risk_obj["credit_to_be_released_modality_01_to_13_obj"]["sum"], mapped_risk_obj_foreign_currency["credit_to_be_released_modality_01_to_13_obj"]["sum"],t("scr_table_credit_to_be_released_G"), handleExpandG, showExpandIconG, expandedG) 
  const credit_limit_H = getRow(classes.bg_header_1, classes.tableHeader, classes.tableHeader, mapped_risk_obj["credit_limit_modality_19_obj"]["sum"], mapped_risk_obj_foreign_currency["credit_limit_modality_19_obj"]["sum"],t("scr_table_credit_limit_H"), handleExpandH, showExpandIconH, expandedH) 
  const indirect_risk_I = getRow(classes.bg_header_1, classes.tableHeader, classes.tableHeader, mapped_risk_obj["indirect_risk_sum"], mapped_risk_obj_foreign_currency["indirect_risk_sum"],t("scr_table_indirect_risk_sum_I")) 
  const received_coobrigation = getRow(classes.bg_header_1, classes.tableHeader, classes.tableHeader, receive_coobligation, null, t("scr_table_receive_coobligation")) 
  
  const loan_portifolio_ABC = expandedF
    ? getRow(classes.bg_header_2, classes.tableHeader2, classes.tableHeader2, mapped_risk_obj["loan_portfolio_sum"], mapped_risk_obj_foreign_currency["loan_portfolio_sum"],t("scr_table_loan_portfolio_sum"), handleExpandI, showExpandIconI, expandedI) 
    : getRow(classes.bg_header_2_hidden, classes.tableHeader2, classes.tableHeader2, mapped_risk_obj["loan_portfolio_sum"], mapped_risk_obj_foreign_currency["loan_portfolio_sum"],t("scr_table_loan_portfolio_sum"), handleExpandI, showExpandIconI, expandedI) 

  const interfinancial_transfers_D = expandedF
    ? getRow(classes.bg_header_2, classes.tableHeader2, classes.tableHeader2, mapped_risk_obj["interfinancial_transfers_sum"], mapped_risk_obj_foreign_currency["interfinancial_transfers_sum"],t("scr_table_interfinancial_transfers_sum_D"), handleExpandD, showExpandIconD, expandedD) 
    : getRow(classes.bg_header_2_hidden, classes.tableHeader2, classes.tableHeader2, mapped_risk_obj["interfinancial_transfers_sum"], mapped_risk_obj_foreign_currency["interfinancial_transfers_sum"],t("scr_table_interfinancial_transfers_sum_D"), handleExpandD, showExpandIconD, expandedD) 
  
  const coobligations_E = expandedF
    ? getRow(classes.bg_header_2, classes.tableHeader2, classes.tableHeader2, mapped_risk_obj["coobligation_sum"], mapped_risk_obj_foreign_currency["coobligation_sum"],t("scr_table_coobligation_sum_E"), handleExpandE, showExpandIconE, expandedE) 
    : getRow(classes.bg_header_2_hidden, classes.tableHeader2, classes.tableHeader2, mapped_risk_obj["coobligation_sum"], mapped_risk_obj_foreign_currency["coobligation_sum"],t("scr_table_coobligation_sum_E"), handleExpandE, showExpandIconE, expandedE) 

  const credit_to_be_expired_A = expandedI
    ? getRow(classes.bg_header_3, classes.tableHeader3, classes.tableValue, mapped_risk_obj["credit_to_be_expired_modality_01_to_13_obj"]["sum"], mapped_risk_obj_foreign_currency["credit_to_be_expired_modality_01_to_13_obj"]["sum"],t("scr_table_to_be_expired_A"), handleExpandA, showExpandIconA, expandedA) 
    : getRow(classes.bg_header_3_hidden, classes.tableHeader3, classes.tableValue, mapped_risk_obj["credit_to_be_expired_modality_01_to_13_obj"]["sum"], mapped_risk_obj_foreign_currency["credit_to_be_expired_modality_01_to_13_obj"]["sum"],t("scr_table_to_be_expired_A"), handleExpandA, showExpandIconA, expandedA) 
  
  const credit_overdue_B = expandedI
    ? getRow(classes.bg_header_3, classes.tableHeader3, classes.tableValue, mapped_risk_obj["credit_overdue_modality_01_to_13_obj"]["sum"], mapped_risk_obj_foreign_currency["credit_overdue_modality_01_to_13_obj"]["sum"],t("scr_table_overdue_B"), handleExpandB, showExpandIconB, expandedB) 
    : getRow(classes.bg_header_3_hidden, classes.tableHeader3, classes.tableValue, mapped_risk_obj["credit_overdue_modality_01_to_13_obj"]["sum"], mapped_risk_obj_foreign_currency["credit_overdue_modality_01_to_13_obj"]["sum"],t("scr_table_overdue_B"), handleExpandB, showExpandIconB, expandedB) 

  const financial_loss_B = expandedI
    ? getRow(classes.bg_header_3, classes.tableHeader3, classes.tableValue, mapped_risk_obj["financial_loss_modality_14_obj"]["sum"], mapped_risk_obj_foreign_currency["financial_loss_modality_14_obj"]["sum"],t("scr_table_financial_loss_C"), handleExpandC, showExpandIconC, expandedC) 
    : getRow(classes.bg_header_3_hidden, classes.tableHeader3, classes.tableValue, mapped_risk_obj["financial_loss_modality_14_obj"]["sum"], mapped_risk_obj_foreign_currency["financial_loss_modality_14_obj"]["sum"],t("scr_table_financial_loss_C"), handleExpandC, showExpandIconC, expandedC) 
  
  const assumed_coobligation = expandedE
    ? getRow(classes.bg_header_4, classes.tableText, classes.tableValue1, mapped_risk_obj["assumed_coobligation_sum"], mapped_risk_obj_foreign_currency["assumed_coobligation_sum"],t("scr_table_assumed_coobligation_sum")) 
    : getRow(classes.bg_header_4_hidden, classes.tableText, classes.tableValue1, mapped_risk_obj["assumed_coobligation_sum"], mapped_risk_obj_foreign_currency["assumed_coobligation_sum"],t("scr_table_assumed_coobligation_sum")) 
  
  const warrant = expandedE
    ? getRow(classes.bg_header_4, classes.tableText, classes.tableValue1, mapped_risk_obj["warrant_obj"]['sum'], mapped_risk_obj_foreign_currency["warrant_obj"]['sum'],t("scr_table_warrant")) 
    : getRow(classes.bg_header_4_hidden, classes.tableText, classes.tableValue1, mapped_risk_obj["warrant_obj"]['sum'], mapped_risk_obj_foreign_currency["warrant_obj"]['sum'],t("scr_table_warrant")) 
      
  return (
    <div className={isPDF 
          ? "SCRtableContainer_width_pdf" 
          : "SCRtableContainer_width"}>
      {showDropDown && (
        <div className={isPDF?"internalCardContainer":"internalCardContainer SCRtableContainer"}>
          <div className={["blueText", "normalMediumSize", "bold"].join(" ")} style={{ padding: "10px" }}>{formatAsDate(reference_date)}</div>
          {!isPDF&&
          <IconButton style={{ marginLeft: "auto" }} onClick={handleExpandHistory}>
            {expandedHistory ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </IconButton>}
        </div>
      )}
      <Collapse in={expandedHistory} timeout="auto" unmountOnExit>
        <Paper className={classes.tableStyle}>
          <Table>
            <TableBody>
              {header}
              {total_risk_FGHI}
              {total_responsability_F}
              {loan_portifolio_ABC}
              {credit_to_be_expired_A}
                {AllCreditToBeExpiredModality01to13}
              {credit_overdue_B}
                {AllCreditOverdueModality01to13}
              {financial_loss_B}
                {AllFinancialLossModality01to14}
              {interfinancial_transfers_D}
                {AllCreditToBeExpiredModality14}
                {AllCreditOverdueModality14}
                {AllCreditToBeReleaseModality14}
              {coobligations_E}
                {assumed_coobligation}
                {warrant}
                  {AllCreditToBeExpiredModality15}
                  {AllCreditOverdueModality15}
                  {AllCreditToBeReleasedModality15}
              {credit_to_be_released_G}
                {AllCreditToBeReleasedModality01to13}
              {credit_limit_H}
                {AllCreditLimit}
              {indirect_risk_I}
              {received_coobrigation}
            </TableBody>
          </Table>
        </Paper>
      </Collapse>
    </div>
  );
}

export default SCRTable;
