import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
  },
  tableText: {
    fontFamily: "Open Sans",
    fontWeight: 'normal',
    fontSize: "12px",
    lineHeight: "16px",
    color: "#141414",
    textAlign: "center",
    margin: "10px",
    padding: "6px",
    minWidth: "120px"
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: '#6F6F6F',
    fontSize: "14px",
    lineHeight: "19px",
    padding: "6px",
  },
  even: {
    background: "#F3F3F3",
  },
  odd: {
    background: "#FFFFFF",
  },
  status: {
    boxSizing: "border-box",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.12)",
    fontFamily: "Open Sans",
    fontSize: "13px",
    lineHeight: "18px",
    textAlign: "center",
    color: "#141414",
    borderRadius: "30px",
    textTransform: "capitalize",
    margin: "10px",
    padding: "6px",
    width: "80%",
  },
}));

function MediaTable(props) {
  const classes = useStyles();
  useTheme();

  const getClass = (rowIndex) => {
    if (rowIndex % 2 === 0) {
      return classes.even;
    }

    return classes.odd;
  };

  const getLabelIcon = (label) => {
    switch (label) {
      case "positive":
        return (
          <div style={{backgroundColor: '#73C580'}} className={classes.status}>
            <span>Positivo</span>
          </div>
        )
      case "slightly_positive":
        return (
          <div style={{backgroundColor: '#73C580'}} className={classes.status}>
            <span>Levemente positivo</span>
          </div>
        )
      case "negative":
        return (
          <div style={{backgroundColor: '#D91A1A'}} className={classes.status}>
            <span>Negativo</span>
          </div>
        )
      case "slightly_negative":
        return (
          <div style={{backgroundColor: '#D91A1A'}} className={classes.status}>
            <span>Levemente negativo</span>
          </div>
        )
      case "neutral":
        return (
          <div style={{backgroundColor: '#DDBB08'}} className={classes.status}>
            <span>Neutro</span>
          </div>
        )
      case "polarized":
          return (
            <div style={{backgroundColor: '#DDBB08'}} className={classes.status}>
              <span>Polarizado</span>
            </div>
          )
      default:
        return (
          <div style={{backgroundColor: 'rgb(170, 167, 153)'}} className={classes.status}>
            <span>Indisponível</span>
          </div>
        )
    }
  }

  return (
    <Paper className={classes.tableStyle}>
      <Table style={{ padding: "8px" }}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader}>Data de publicação</TableCell>
            <TableCell className={classes.tableHeader}>Título da notícia</TableCell>
            <TableCell className={classes.tableHeader}>Fonte</TableCell>
            <TableCell className={classes.tableHeader}>Link</TableCell>
            <TableCell className={classes.tableHeader}>Sentimento</TableCell>
            <TableCell className={classes.tableHeader}>Categoria</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.media_data.map((exposure,index) => (
            <TableRow
              key={index}
              className={getClass(index)}
            >
              <TableCell className={classes.tableText}>
                {exposure.date == null ? "-" : moment(exposure.date).format('DD/MM/YYYY')}
              </TableCell>
              <TableCell className={classes.tableText}>
                "{exposure.title}"
              </TableCell>
              <TableCell className={classes.tableText}>
                {exposure.font}
              </TableCell>
              <TableCell className={classes.tableText}>
                <a
                  href={exposure.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Link
                </a>
              </TableCell>
              <TableCell className={classes.tableText}>
                {getLabelIcon(exposure.label)}
              </TableCell>
              <TableCell className={classes.tableText}>
                {exposure.category == null ? "-" : exposure.category.length === 0 ? "-" : exposure.category.join(", ")}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default MediaTable;
