//Importing modules
import React, { useEffect, useContext, useReducer, useState } from "react";	
import {dataFetchReducer} from "../../Utils/requestUtils"
import { useParams } from "react-router-dom";
import axios from "axios";
import ListOverviewCard from "./ListOverviewCard/ListOverviewCard"
import ListContentsCard from "./ListContentsCard/ListContentsCard"
import SharedListPermissionCard from "./SharedListPermissionCard/SharedListPermissionCard"
import ErrorBoundary from "../../Utils/ErrorBoundary"
import AuthContext from "../../../context/auth-context"
import CompaniesContext from "../../../context/companies-context"
import {useHistory} from "react-router-dom"
import NoReadAccessDialog from "./NoReadAccessDialog/NoReadAccessDialog";

function List(props){
    let history = useHistory()
    let userData = useContext(AuthContext).user_data
    let username = userData.email
    let companiesContext = useContext(CompaniesContext)

    const local_storage_object_preffix = "List"

    sessionStorage.removeItem("ListFilterObject") 
    sessionStorage.removeItem("ListPageParams") 

    if (!userData.roles.includes("lists_viewer")){
        history.push("")
    }

    //Defining noReadAccessDialogState
    const [openNoReadAccessDialog, setOpenNoReadAccessDialog] = useState(true); 
    const handleCloseNoReadAccessDialog = () => {
        setOpenNoReadAccessDialog(false)
        history.push("/lists-inbox")
    }


    //Defining state variable to reload page
    const [isReloading, setIsReloading] = useState(false)
    const handleReloadPage = () => {
        setIsReloading(true)
    }

    //Making request o Dash API to retrieve list data
    let { list_key } = useParams();

    const [userPermission, dispatchUserPermission] = useReducer(
        dataFetchReducer,
        {fetchedData: null, isLoading: true, isError: false} 
    )

    useEffect(() => {
        axios.get(`/dash/lists/list_user_permission/${username}/list/${list_key}`).then(response => {
            dispatchUserPermission({
                type: "data_fetch_success",
                payload: response.data
            })
        }).catch(error => {
            if ((error.response || {}).status === 403) dispatchUserPermission({type: "data_fetch_failure_403"})
            else if ((error.response || {}).status === 404) dispatchUserPermission({type: "data_fetch_failure_404"})  
            else dispatchUserPermission({type: "data_fetch_failure"})
        })
    }, [username,list_key])

    const [list, dispatchList] = useReducer(
        dataFetchReducer,
        {fetchedData: null, isLoading: true, isError: false} 
    )
    
    useEffect(() => {
        dispatchList({type: "data_fetch_init"})
        const timer = setTimeout(() => {
            axios.get('/dash/lists/list/' + list_key).then( response => {
                dispatchList({
                    type: "data_fetch_success",
                    payload: response.data
                })   
            }).catch(error => {
                if ((error.response || {}).status === 403) dispatchList({type: "data_fetch_failure_403"})
                else if ((error.response || {}).status === 404) dispatchList({type: "data_fetch_failure_404"})  
                else dispatchList({type: "data_fetch_failure"})    
            })	
        }, 500);
        return () => {
            setIsReloading(false)
            clearTimeout(timer)
        }
    }, [list_key, isReloading])

    const canCreateSharedListHandler = () => {
        let can_create_shared_list = false
        let list_owner_company_key = list.fetchedData.company_key
        if (userData.business_group_key != null){
            let business_group_data = companiesContext.lists_companies.business_group_data
            let company_data_list = business_group_data.filter((company)=>(company.company_key===list_owner_company_key))
            if (company_data_list.length === 1) {
                let company_data = company_data_list[0]
                can_create_shared_list = company_data.can_create_shared_list
            }
        }
        else {
            let company_data = companiesContext.lists_feature_toggle
            can_create_shared_list = company_data.can_create_shared_list
        }
        return can_create_shared_list
    }

    if(list.isError){
        return (
            <div style={{height: "80vh", display:"flex"}}>
                <p className={["blueText", "titleSize"].join(" ")} style={{margin:"auto", textAlign:"center"}}>
                    {list.errorMessage}
                </p>
            </div>
        )
    }
    else if (list.fetchedData&&userPermission.fetchedData){
        const is_owner = list.fetchedData.is_owner
        const permissions_list = userPermission.fetchedData.permissions
        const permissions = {
            read_list: permissions_list.includes('read_list'),
            read_item: permissions_list.includes('read_item'),
            delete_item: permissions_list.includes('delete_item'),
            update_item: permissions_list.includes('update_item'),
            create_item: permissions_list.includes('create_item')
        }
        let can_create_shared_list = canCreateSharedListHandler()
        console.log("can_create_shared_list ", can_create_shared_list)

        if (permissions.read_item) {
            return (
                <div style={{minWidth:"100%", paddingBottom: "30px"}}>
                    <div className="analysisCardContainer" style={{flexDirection:"column", display: "flex", flexGrow: 1}}>
                        <ErrorBoundary>   
                            <ListOverviewCard list={list.fetchedData} permissions={permissions} handleReloadPage={handleReloadPage} companyKey={list.fetchedData.company_key} userData={userData} list_key={list_key}/>
                        </ErrorBoundary>
                    </div>
                    <div style={{ display:"flex", flexDirection:"column"}}>
                        {(userData.roles.includes("lists_analyzer") && can_create_shared_list && is_owner) ? 
                            <div className="analysisCardContainer">
                                <ErrorBoundary>   
                                    <SharedListPermissionCard list={list.fetchedData}  companyKey={list.fetchedData.company_key} userData={userData} businessGroupCompanies={(companiesContext.lists_companies ? companiesContext.lists_companies : {}).business_group_data}/>
                                </ErrorBoundary>
                            </div> : null}
                    </div>
                    <div className="analysisCardContainer" style={{minWidth: "100%", flexGrow:"1", height:"max-content"}}>
                        <ErrorBoundary>   
                            <ListContentsCard list={list.fetchedData} permissions={permissions} handleReloadPage={handleReloadPage} companyKey={list.fetchedData.company_key} userData={userData} local_storage_object_preffix={local_storage_object_preffix}/>
                        </ErrorBoundary>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div style={{minWidth:"900px", paddingBottom: "30px"}}>
                    <NoReadAccessDialog
                    open={openNoReadAccessDialog}
                    onClose={() => handleCloseNoReadAccessDialog()}
                    />
                </div>
            )
        }
    }
    else return null;
}



//Exporting component as default
export default List