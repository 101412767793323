import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
`;

export const Counter = styled.p`
    display: ${(props) => props.hide ? "none": "flex"};
    font-family: "Open Sans";
    font-size: 12px;
    color: ${(props) => props.alert ? "#FF0000" : "#222222"};
    line-height: 12px;
`;