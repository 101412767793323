import React, {useState, useEffect} from 'react'
import { useTranslation } from "react-i18next";
import {GetAlertsComponent} from "./utils"
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import _ from 'lodash';
import { MenuItem, Select } from '@material-ui/core';

export function ChallengeBox (props) {
    const { t } = useTranslation();
    let {node} = props
    let {read_only} = props
    let {handleConfigurationOpen} = props
    let {handleNodeRemove} = props

    return (
        <div>
            {!read_only ?
                <div style={{display:"flex", alignItems: "center"}}>
                    <div style={{display:"flex", width:"100%", cursor:"pointer", margin: "auto 0px"}} onClick={() => handleConfigurationOpen(node)}>
                        {t("Enviar Validação")}
                        {(node.node.alerts || []).length > 0?
                        <GetAlertsComponent alerts={node.node.alerts} />  : null} 
                    </div>
                    <IconButton style={{width: 32, height: 32}} aria-label="duplicate" onClick={() => props.handleNodeDuplication(node)}>
                        <ContentCopyIcon style={{width: 20, height: 20}} />
                    </IconButton>
                    <IconButton style={{width: 32, height: 32}} aria-label="close" onClick={() => handleNodeRemove(node)}>
                        <CloseIcon style={{width: 20, height: 20}} />
                    </IconButton>
                </div>
            :
                <div style={{display:"flex"}}>
                    <div style={{display:"flex", width:"100%", cursor:"pointer", margin: "auto 0px"}} onClick={() => handleConfigurationOpen(node)}>
                        {t("Enviar Validação")}
                    </div>
                </div>
            }
        </div>
    )
}

export function ChallengeConfiguration(props){
    const { t } = useTranslation();
    let {handleConfigurationClose} = props
    let {handleNodeChange} = props
    let {node} = props
    let {read_only} = props

    const [nodeState, setNodeState] = useState(node)

    const handleUpdateChallengeType = (challenge_type) => {
        let new_node = _.cloneDeep(nodeState)
        new_node.node.properties.challenge_type = challenge_type
        setNodeState(new_node)
    }
    const handleUpdateLinkSendingMethod = (link_sending_method) => {
        let new_node = _.cloneDeep(nodeState)
        new_node.node.properties.link_sending_method = link_sending_method
        setNodeState(new_node)
    }

    useEffect(() => {
        if (!nodeState.node.properties.challenge_type && node.node.instance.rulesContext.boxes.challenge_box.settings.challenge_types.length <= 1) {
            handleUpdateChallengeType(node.node.instance.rulesContext.boxes.challenge_box.settings.challenge_types[0].enum)
        }
        if (!nodeState.node.properties.link_sending_method && nodeState?.node?.properties?.challenge_type?.link_sending_methods?.length <= 1) {
            handleUpdateLinkSendingMethod(nodeState.node.properties.challenge_type.link_sending_methods[0].enum) // ??????
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nodeState])

    const validateAllValues = () => {
        if (!nodeState.node.properties.challenge_type) return false;
        if (nodeState.node.properties.challenge_type === 'link_validation' && !nodeState.node.properties.link_sending_method) return false;
        return true;
    }

    const handleSave = () => {
        handleConfigurationClose()
        handleNodeChange(nodeState)
    }

    const getChallengeTypeByEnum = (enumerator) => {
        for (let i = 0; i < node.node.instance.rulesContext.boxes.challenge_box.settings.challenge_types.length; i++) {
            if (node.node.instance.rulesContext.boxes.challenge_box.settings.challenge_types[i].enum === enumerator) {
                return node.node.instance.rulesContext.boxes.challenge_box.settings.challenge_types[i]
            }
        }
        return null;
    }

    return(
        <div style={{flexGrow:"1", padding: "20px", display:"flex", flexDirection:"column"}}>
            <div style={{display: "flex"}}>
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>{t(node.node.description)}</span>
                <Tooltip title={<div className="tooltipText"><ul>{node.node.helperText.map((help,index) => (<li key={index}>{t(help)}</li>))}</ul></div>}>
                    <HelpIcon style={{fontSize: "14px", display:"flex", margin:"auto 5px"}}/>
                </Tooltip>
            </div>
            <div className={["internalCardContainer", "normalText", "normalMediumSize"].join("  ")} style={{display:"flex", flexDirection: "column"}}>
                {node.node.instance.rulesContext.boxes.challenge_box.settings.challenge_types.length > 1 ?
                    <>
                        <p className="blueText">{t('challenge_type')}</p>
                        <Select 
                            value={nodeState.node.properties.challenge_type}
                            onChange={(e) => {
                                handleUpdateChallengeType(e.target.value)
                            }}
                            label={t('challenge_type')}
                        >
                            {node.node.instance.rulesContext.boxes.challenge_box.settings.challenge_types.map((challenge_type) => (
                                <MenuItem key={challenge_type.enum} value={challenge_type.enum}>{t(challenge_type.enum)}</MenuItem>
                            ))}
                        </Select>
                    </> : <></>
                }
                {nodeState.node.properties.challenge_type === 'link_validation' ?
                    getChallengeTypeByEnum(nodeState.node.properties.challenge_type).link_sending_methods.length > 1 ?
                    <>
                        <p className='blueText'>{t('link_sending_method')}</p>
                        <Select 
                            value={nodeState.node.properties.link_sending_method}
                            onChange={(e) => {
                                handleUpdateLinkSendingMethod(e.target.value)
                            }}
                            label={t('link_sending_method')}
                        >
                            {getChallengeTypeByEnum(nodeState.node.properties.challenge_type).link_sending_methods.map((link_sending_method) => (
                                <MenuItem key={link_sending_method.enum} value={link_sending_method.enum}>{t(link_sending_method.enum)}</MenuItem>
                            ))}
                        </Select>
                    </> :
                        <></>
                        :
                    <></>
                }
                {node.node.instance.rulesContext.boxes.challenge_box.settings.challenge_types.length <= 1 && node.node.instance.rulesContext.boxes.challenge_box.settings.challenge_types[0].link_sending_methods.length <= 1 &&
                    <div className={["internalCardContainer", "normalText", "normalMediumSize"].join("  ")} style={{display:"flex", flexDirection: "column"}}>
                        {t("Não há configurações disponíveis para esse nó.")}
                    </div>
                }
            </div>
            <div style={{display:"flex", margin: "auto auto 5px auto"}}>
                <div
                    className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")}
                    style={{width:"120px"}}
                    onClick={handleConfigurationClose}
                >
                    {read_only ? "X" : t("discard")}
                </div>
                <div
                    className={
                        validateAllValues()
                        ?
                        ["button", "standard", "normalText", "normalMediumSize"].join(" ")
                        :
                        ["button", "standard", "normalText", "normalMediumSize","disabled"].join(" ")}
                    style={{width:"50%"}}
                    onClick={validateAllValues() ? handleSave : null}
                >
                    {t("save")}
                </div>
            </div>
        </div>
    )
}

export class ChallengeBoxClass {
    constructor(rulesContext){
        this.rulesContext = rulesContext
    }
    validate(node){
        let put_error = false
        let alerts = []

        if((node.node.children || []).length !== 0){
            put_error = true
            alerts.push("Este nó não deve possuir filhos")
        }
        //Validate if parent is valid
        if (node.parentNode != null) {
            if(node.parentNode.on_error){
                put_error = true
                alerts.push("O nó superior está inválido")
            }
        }
        return {validated: !put_error, alerts: alerts}
    }

    getType(node){
        return "bool"
    }
}