import React from 'react'
import DataField from "../../../utils/DataField"
import {titleCase,upperCase,checkNested} from "../../../utils/utils"
import moment from "moment"

function NewCNHOCR(props){
    const { document_verification } = props
    const { CNHOCR } = props

    return(
        <div style={{display:"flex", width:"70%", flexDirection: "column"}}>
            <DataField
                    title={"Nome"}
                    label={titleCase(CNHOCR.name, "-")}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={checkNested(document_verification, "cnh", "name", "flag") ? document_verification.cnh.name.flag : null}
                    tooltipText={checkNested(document_verification, "cnh", "name", "flag") ? document_verification.cnh.name.flag === "positive" ? "Nome validado" : 
                        (checkNested(document_verification, "cnh", "name", "description") && checkNested(document_verification, "cnh", "name","similarity")) ? 
                        "Nome encontrado em Bureau: " + titleCase(document_verification.cnh.name.description) + " (Similaridade de " + (document_verification.cnh.name.similarity*100).toFixed(0)  + "% )": 
                        checkNested(document_verification, "cnh", "name", "description") ? "Nome encontrado em Bureau: " + titleCase(document_verification.cnh.name.description) : 
                        checkNested(document_verification, "cnh", "name","similarity") ? "Similaridade de " + (document_verification.cnh.name.similarity*100).toFixed(0)  + "%" : "Indisponível" :  "Indisponível"}
            />
            <DataField
                    title={"CPF"}
                    label={CNHOCR.cpf_number ? CNHOCR.cpf_number : "-"}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={checkNested(document_verification, "cnh", "document_number", "flag") ? document_verification.cnh.document_number.flag : null}
                    tooltipText={checkNested(document_verification, "cnh", "document_number", "flag") ? document_verification.cnh.document_number.flag === "positive" ? "CPF validado" : 
                    checkNested(document_verification.cnh.document_number.description) ? "CPF encontrado em Bureau: " + titleCase(document_verification.cnh.document_number.description) :
                    "Indisponível"  : "Indisponível"}
            />                    
            <DataField
                    title={"Data de Nascimento"}
                    label={CNHOCR.birthdate ? moment(CNHOCR.birthdate).format('DD/MM/YYYY') : "-"}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={checkNested(document_verification, "cnh", "birthdate", "flag") ? document_verification.cnh.birthdate.flag : null}
                    tooltipText={checkNested(document_verification, "cnh", "birthdate", "flag") ? document_verification.cnh.birthdate.flag === "positive" ? "Data validada" : 
                    checkNested(document_verification.cnh.birthdate.description) ? "Data de nascimento encontrada em Bureau: " + moment(document_verification.cnh.birthdate.description).format('DD/MM/YYYY') :
                    "Indisponível" : "Indisponível"}
            />
            <DataField
                    title={"Nome do Pai"}
                    label={titleCase(CNHOCR.father_name, "-")}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={checkNested(document_verification, "cnh", "father_name", "flag") ? document_verification.cnh.father_name.flag : null}
                    tooltipText={checkNested(document_verification, "cnh", "father_name", "flag") ? document_verification.cnh.father_name.flag === "positive" ? "Nome validado" : 
                        (checkNested(document_verification, "cnh", "father_name", "description") && checkNested(document_verification, "cnh", "father_name","similarity")) ? 
                        "Nome encontrado em Bureau: " + titleCase(document_verification.cnh.father_name.description) + " (Similaridade de " + (document_verification.cnh.father_name.similarity*100).toFixed(0)  + "% )": 
                        checkNested(document_verification, "cnh", "father_name", "description") ? "Nome encontrado em Bureau: " + titleCase(document_verification.cnh.father_name.description) : 
                        checkNested(document_verification, "cnh", "father_name","similarity") ? "Similaridade de " + (document_verification.cnh.father_name.similarity*100).toFixed(0)  + "%" : "Indisponível" :  "Indisponível"}
            />
            <DataField
                    title={"Nome da Mãe"}
                    label={titleCase(CNHOCR.mother_name, "-")}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={checkNested(document_verification,"cnh",  "mother_name", "flag") ? document_verification.cnh.mother_name.flag : null}
                    tooltipText={checkNested(document_verification, "cnh", "mother_name", "flag") ? document_verification.cnh.mother_name.flag === "positive" ? "Nome validado" : 
                        (checkNested(document_verification, "cnh", "mother_name", "description") && checkNested(document_verification, "cnh", "mother_name","similarity")) ? 
                        "Nome encontrado em Bureau: " + titleCase(document_verification.cnh.mother_name.description) + " (Similaridade de " + (document_verification.cnh.mother_name.similarity*100).toFixed(0)  + "% )": 
                        checkNested(document_verification, "cnh", "mother_name", "description") ? "Nome encontrado em Bureau: " + titleCase(document_verification.cnh.mother_name.description) : 
                        checkNested(document_verification, "cnh", "mother_name","similarity") ? "Similaridade de " + (document_verification.cnh.mother_name.similarity*100).toFixed(0)  + "%" : "Indisponível" :  "Indisponível"}
            />
            <DataField
                    title={"RG"}
                    label={upperCase(CNHOCR.rg_number, "-")}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={checkNested(document_verification, "cnh", "rg_number", "flag") ? document_verification.cnh.rg_number.flag : null}
                    tooltipText={checkNested(document_verification, "cnh", "rg_number", "flag") ? document_verification.cnh.rg_number.flag === "positive" ? "Número do RG validado" : 
                    checkNested(document_verification.cnh.rg_number.description) ? "Número de RG encontrado em Bureau: " + titleCase(document_verification.cnh.rg_number.description) :
                    "Indisponível" : "Indisponível"}
                    
            />
            <DataField
                    title={"Orgão Emissor do RG"}
                    label={upperCase(CNHOCR.rg_issuer, "-")}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={checkNested(document_verification, "cnh", "rg_issuer", "flag") ? document_verification.cnh.rg_issuer.flag : null}
                    tooltipText={checkNested(document_verification, "cnh", "rg_issuer", "flag") ? document_verification.cnh.rg_issuer.flag === "positive" ? "Orgão emissor do RG validado" : 
                    checkNested(document_verification.cnh.rg_issuer.description) ? "Orgão emissor encontrado em Bureau: " + upperCase(document_verification.cnh.rg_issuer.description) :
                    "Indisponível" : "Indisponível"}
            />
            <DataField
                    title={"Estado Emissor do RG"}
                    label={upperCase(CNHOCR.rg_issuer_state, "-")}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={checkNested(document_verification, "cnh", "rg_issuer_state", "flag") ? document_verification.cnh.rg_issuer_state.flag : null}
                    tooltipText={checkNested(document_verification, "cnh", "rg_issuer_state", "flag") ? document_verification.cnh.rg_issuer_state.flag === "positive" ? "Estado emissor do RG validado" : 
                    checkNested(document_verification.cnh.rg_issuer_state.description) ? "Estado emissor encontrado em Bureau: " + upperCase(document_verification.cnh.rg_issuer_state.description) :
                    "Indisponível" : "Indisponível"}
            />
            <DataField
                    title={"CNH"}
                    label={CNHOCR.cnh_number ? CNHOCR.cnh_number : "-"}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={checkNested(document_verification, "cnh", "cnh_register_number", "flag") ? document_verification.cnh.cnh_register_number.flag : null}
                    tooltipText={checkNested(document_verification, "cnh", "cnh_register_number", "flag") ? document_verification.cnh.cnh_register_number.flag === "positive" ? "Número da CNH validado" : 
                    checkNested(document_verification.cnh.cnh_register_number.description) ? "Número da CNH encontrado em Bureau: " + titleCase(document_verification.cnh.cnh_register_number.description) :
                    "Indisponível" : "Indisponível"}
            />
            <DataField
                    title={"Categoria da Habilitação"}
                    label={upperCase(CNHOCR.category, "-")}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={checkNested(document_verification, "cnh", "cnh_category", "flag") ? document_verification.cnh.cnh_category.flag : null}
                    tooltipText={checkNested(document_verification, "cnh", "cnh_category", "flag") ? document_verification.cnh.cnh_category.flag === "positive" ? "Habilitação validada" : 
                    checkNested(document_verification.cnh.cnh_category.description) ? "Habilitação encontrada em Bureau: " + titleCase(document_verification.cnh.cnh_category.description) :
                    "Indisponível" : "Indisponível"}
            />
            <DataField
                    title={"Data de Emissão"}
                    label={CNHOCR.issuance_date ? moment(CNHOCR.issuance_date).format('DD/MM/YYYY') : "-"}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={checkNested(document_verification, "cnh", "cnh_issuance_date", "flag") ? document_verification.cnh.cnh_issuance_date.flag : null}
                    tooltipText={checkNested(document_verification, "cnh", "cnh_issuance_date", "flag") ? document_verification.cnh.cnh_issuance_date.flag === "positive" ? "Data validada" : 
                    checkNested(document_verification.cnh.cnh_issuance_date.description) ? "Data de emissão encontrada em Bureau: " + moment(document_verification.cnh.cnh_issuance_date.description).format('DD/MM/YYYY') :
                    "Indisponível" : "Indisponível"}
            />
            <DataField
                    title={"Data de Vencimento"}
                    label={CNHOCR.expiration_date ? moment(CNHOCR.expiration_date).format('DD/MM/YYYY') : "-"}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={checkNested(document_verification, "cnh", "cnh_expiration_date", "flag") ? document_verification.cnh.cnh_expiration_date.flag : null}
                    tooltipText={checkNested(document_verification, "cnh", "cnh_expiration_date", "flag") ? document_verification.cnh.cnh_expiration_date.flag === "positive" ? "Data validada" : 
                    checkNested(document_verification.cnh.cnh_expiration_date.description) ? "Data de vencimento encontrada em Bureau: " + moment(document_verification.cnh.cnh_expiration_date.description).format('DD/MM/YYYY') :
                    "Indisponível" : "Indisponível"}
            />
            <DataField
                    title={"Data da Primeira Habilitação"}
                    label={CNHOCR.first_issuance_date ? moment(CNHOCR.first_issuance_date).format('DD/MM/YYYY') : "-"}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={checkNested(document_verification, "cnh", "cnh_first_issuance_date", "flag") ? document_verification.cnh.cnh_first_issuance_date.flag : null}
                    tooltipText={checkNested(document_verification, "cnh", "cnh_first_issuance_date", "flag") ? document_verification.cnh.cnh_first_issuance_date.flag === "positive" ? "Data validada" : 
                    checkNested(document_verification.cnh.cnh_first_issuance_date.description) ? "Data de da primeira habilitação encontrada em Bureau: " + moment(document_verification.cnh.cnh_first_issuance_date.description).format('DD/MM/YYYY') :
                    "Indisponível" : "Indisponível"}
            />
            <DataField
                    title={"Cidade Emissora"}
                    label={(CNHOCR.cnh_issuer_city && CNHOCR.cnh_issuer_state) ? [titleCase(CNHOCR.cnh_issuer_city, "-"), upperCase(CNHOCR.cnh_issuer_state, "-")].join(", ") : titleCase(CNHOCR.cnh_issuer_city, "-")}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={checkNested(document_verification, "cnh", "cnh_issuer_city", "flag") ? document_verification.cnh.cnh_issuer_city.flag : null}
                    tooltipText={checkNested(document_verification, "cnh", "cnh_issuer_city", "flag") ? document_verification.cnh.cnh_issuer_city.flag === "positive" ? "Local de emissão da CNH validado" : 
                    checkNested(document_verification.cnh.cnh_issuer_city.description) ? "Local de emissão encontrado em Bureau: " + titleCase(document_verification.cnh.cnh_issuer_city.description) :
                    "Indisponível" : "Indisponível"}
                    
            />
        </div>
    )
}

export default NewCNHOCR