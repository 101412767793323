import React from "react";
import "../../../../../assets/styles/generalStyles.scss";

const FormLabel = props => {
  return (
    <div>
      <p className={["formLabelFieldText", "bold"].join(" ")}>
        {props.value}
      </p>
      <div className={"formVerySmallWhiteSpace"}></div>
    </div>
  )
}

export default FormLabel;