import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
  },
  tableText: {
    fontFamily: "Open Sans",
    fontWeight: 'normal',
    fontSize: "12px",
    lineHeight: "16px",
    color: "#141414",
    textAlign: "center",
    margin: "10px",
    padding: "6px",
    maxWidth: "80px"
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: '#6F6F6F',
    fontSize: "14px",
    lineHeight: "19px",
    padding: "6px",
  },
  even: {
    background: "#F3F3F3",
  },
  odd: {
    background: "#FFFFFF",
  },
}));

function SerasaPartnersTable(props) {
  const {t} = useTranslation()
  const classes = useStyles();
  useTheme();

  let {partners_data, report_type} = props
  let showScoreAndDataEntry = report_type !== "business_information_report"

  const getClass = (rowIndex) => rowIndex % 2 === 0 ? classes.even : classes.odd

  return (
    <Paper className={classes.tableStyle}>
      <Table style={{ padding: "8px" }}>
        <TableHead>
        <TableRow>
            <TableCell className={classes.tableHeader}>{t("name")}</TableCell>
            <TableCell className={classes.tableHeader}>{t("document_number")}</TableCell>
            <TableCell className={classes.tableHeader}>{`${t("ownership_percentage")} %`}</TableCell>
            {showScoreAndDataEntry && <TableCell className={classes.tableHeader}>{t("entry_date")}</TableCell>}
            <TableCell className={classes.tableHeader}>{t("restrictions")}</TableCell>
            {showScoreAndDataEntry && <TableCell className={classes.tableHeader}>Score</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {partners_data.map((partner,index) => (
            <TableRow
              key={index}
              className={getClass(index)}
            >
              <TableCell className={classes.tableText}>
                {partner.name}
              </TableCell>
              <TableCell className={classes.tableText}>
                  {partner.document_number}
              </TableCell>
              <TableCell className={classes.tableText}>
                {`${partner.ownership_percentage} %`}
              </TableCell>
              {showScoreAndDataEntry && <TableCell className={classes.tableText}>
                {partner.entry_date ? moment(partner.entry_date).format("DD/MM/YYYY"): t("not_available")}
              </TableCell>}
              <TableCell className={classes.tableText}>
                {partner.has_restrictions === true ? t("yes") : t("no")}
              </TableCell>
              {showScoreAndDataEntry && <TableCell className={classes.tableText}>
                {partner.riskscoring ? partner.riskscoring : t("not_available")}
              </TableCell>}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default SerasaPartnersTable;