import React from 'react'
import PersonTypeIcon from "./PersonTypeIcon/PersonTypeIcon"

function PersonInformation(props) {

    let { person } = props

    let personType           = person.type
    let personName           = person.name
    let personDocumentNumber = person.document_number

    return(
        <div className="internalCardContainer" style={{display: "flex", marginTop:"15px"}}>
            <PersonTypeIcon personType={personType} />
            <div className={["subtitleSize"].join(" ")} 
                style={{width:"70%", display:"flex", alignItems:"center"}}>
                <div>
                    <div className="labelText" style={{marginBottom:"10px"}}>
                        {personName}
                    </div>
                    <div style={{display:"flex"}}>
                        <div style={{marginRight:"5px"}}>
                            {personType==='natural_person'?'CPF ':'CNPJ '}
                        </div>
                        <div className="labelText">
                            {personDocumentNumber}
                        </div>
                    </div>
                </div>
            </div>  
        </div>
    )
}

export default PersonInformation