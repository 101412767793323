import React, { useEffect } from "react";
import { CircularProgress } from '@material-ui/core';
import axios from "axios";
import OperationTable from "./OperationTable/OperationTable"
import Pagination from "@material-ui/lab/Pagination";

function OperationHistoric(props) {

    const {document_number} = props
    const {final_date} = props
    const {operation_key} = props
  
    const [userHistoric, setUserHistoric] = React.useState(null)
    const [tablePage, setTablePage] = React.useState(1)
  
    useEffect(() => {
        if (userHistoric === null){
            if (document_number==null) setUserHistoric(undefined)
            else{
                let params = {
                    "page_number": tablePage -1,
                    "page_rows": 5,
                    "document_number": document_number,
                    "final_date": final_date
                }
                const timer = setTimeout(() => {
                    axios.get('/dash/currency_exchange/operations', {
                        params: params
                    }).then(response=>{
                        let filteredTransactions = response.data.data.filter(transaction => transaction.operation_key !== operation_key)
                        let filteredResponseObject = {...response.data, data:filteredTransactions}
                        setUserHistoric(filteredResponseObject)
                    }).catch(error=>{
                        setUserHistoric(undefined)
                    })
                }, 500);
                return () => {
                    clearTimeout(timer)
                }

            }
        }
    }, [userHistoric,tablePage,document_number,final_date, operation_key])

    const handleChangePage = (event, page) => {
		if (tablePage === page) {
			return
		}
        setTablePage(page)
        setUserHistoric(null)
	}
  
    if (userHistoric === null) {
      return (
        <div style={{display: "flex", height: "160px"}}>
            <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                <CircularProgress />
            </div>
        </div>
      );    
    }
    else if (userHistoric === undefined || userHistoric.data.length === 0){
      return (
        <div style={{display: "flex", height: "160px"}}>
            <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                Informação Indisponível
            </div>
        </div>
      )
    }
    else {
        return (
                <div>
                    <div>
                        <OperationTable historic={userHistoric.data} />
                    </div>
                    <div style={{display:"flex", marginTop: "10px"}}>
                        <Pagination style={{marginLeft:"auto"}} className='pagination' page={tablePage} count={userHistoric['number_of_pages']} onChange={(e,page) => handleChangePage(e, page)} />
                    </div>
                </div>
        );
    }
  }
  
  export default OperationHistoric;