import React, {useContext} from 'react'
import AuthContext from "../../../../context/auth-context"
import moment from "moment"
import AlertsCard from "./AlertsCard/AlertsCard"
import PaymentCard from "./PaymentCard/PaymentCard"
import UserCard from "./UserCard/UserCard"
import HistoricCard from "./HistoricCard/HistoricCard"
import ErrorBoundary from "../../../Utils/ErrorBoundary"
import {colorRed} from "../../utils/utils"

function CardHolderAlert(props) {
    let user_data = useContext(AuthContext).user_data

    return (
        <div>
            <div className="analysisCardContainer" style={{flexDirection:"column"}}>
                <ErrorBoundary>           
                    <div className={["blueText", "titleSize"].join(" ")} style={{margin:"5px 0px"}}>
                        Alertas {user_data.business_group_key ? props.alert.company_name ? props.alert.company_name : null : null} - Usuário {props.alert.cardholder_id}
                    </div>
                    {props.alert.alert_status === "in_manual_analysis" ?  props.alert.lock_key ?
                    <div className={["normalText", "subtitleSize", "bold"].join(" ")} style={{margin:"5px 0px", color: colorRed}}>
                        Bloqueado
                    </div> : null :null}
                    <div className={["labelText", "subtitleSize"].join(" ")} style={{margin:"5px 0px"}}>
                        {moment(props.alert.occurrence_date).format("DD/MM/YYYY - HH:mm:ss")}
                    </div>
                </ErrorBoundary>
            </div>
            <div style={{display: "flex", minHeight: "250px"}}>
                <div className="analysisCardContainer" style={{width: "40%"}}>
                    <ErrorBoundary>   
                        <AlertsCard alert={props.alert} />
                    </ErrorBoundary>
                </div>
                <div className="analysisCardContainer" style={{width: "28%"}}>
                    <ErrorBoundary>   
                        <PaymentCard alert={props.alert} />
                    </ErrorBoundary>
                </div>
                <div className="analysisCardContainer" style={{width: "32%"}}>
                    <ErrorBoundary>   
                        <UserCard alert={props.alert} />
                    </ErrorBoundary>
                </div>
            </div>
            <div  className="analysisCardContainer"  style={{marginTop: "0px"}}>
                <ErrorBoundary>   
                    <HistoricCard alert={props.alert} />
                </ErrorBoundary>
            </div>
        </div>
    )
}

export default CardHolderAlert