import React, {useContext, useState} from 'react'
import TextField from "@material-ui/core/TextField";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { setAllowChanges, replaceAll } from "../../utils/utils"
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import AuthContext from "../../../../context/auth-context"
import ConfirmationDialog from "./ConfirmationDialog/ConfirmationDialog";
import moment from "moment"
import CompaniesContext from "../../../../context/companies-context"
import ObservationTextField from '../../../../components/common/ObservationTextField';

const theme = createMuiTheme({
    overrides: { MuiInputBase: { root:{
        fontFamily: "Open Sans",
        fontSize: "14px",
        lineHeight: "19px",

    }}},
    palette:{
        primary: {
            main:"#0b1f82"
        },
    }
  });

const risk_level_enumList = [
    ["low","Baixo Risco"],
    ["medium","Médio Risco"],
    ["high", "Alto Risco"],
    ["critical", "Risco Crítico"]
  ]

const risk_level_dictionary = {
    low: "Baixo Risco",
    medium: "Médio Risco",
    high: "Alto Risco"
}
   
function Observation(props) {

    let companiesSettings = useContext(CompaniesContext).settings

    let showRiskLevel = !!companiesSettings ? (((companiesSettings || {}).settings || {}).onboarding ||{}).has_risk_level : false
    
    const [openDialog, setOpenDialog] = useState(false)
    const [manualAnalysisStatus, setManualAnalysisStatus] = useState(null)

    const handleApprovedOpenClick = () => {
        setManualAnalysisStatus("manually_approved")
        setOpenDialog(true)
    };

    const handleReprovedOpenClick = () => {
        setManualAnalysisStatus("manually_reproved")
        setOpenDialog(true)
    };

    const handleChallengedOpenClick = () => {
        setManualAnalysisStatus("manually_challenged")
        setOpenDialog(true)
    };

    const handleOnHoldOpenClick = () => {
        setManualAnalysisStatus("on_hold")
        setOpenDialog(true)
    };

    const handleDialogClose = () => {
        setOpenDialog(false)
        setTimeout(function (){setManualAnalysisStatus(null)}, 300)
    };

    let userData = useContext(AuthContext).user_data
    let roles = useContext(AuthContext).user_data.roles
    let allowChanges = setAllowChanges(props.natural_person.analysis_status, roles, "np")
    let status = props.natural_person.analysis_status;
    let observation = null
    let analyst = null
    if (!allowChanges || status === "on_hold"){
        let last_event = props.natural_person.analysis_status_events == null || props.natural_person.analysis_status_events.length ===0? null :
            props.natural_person.analysis_status_events[props.natural_person.analysis_status_events.length-1]
        if (last_event){
            observation = last_event.observation
            let string = last_event.analyst_name == null ? "Análise feita no dia %day às %time.\n" : "Análise feita por %name, %email no dia %day às %time.\n"
            let mapObj = {
                "%name": last_event.analyst_name,
                "%email": last_event.analyst_email,
                "%day": moment(last_event.event_date).format("DD/MM/YYYY"),
                "%time": moment(last_event.event_date).format("HH:mm"),
            }
            analyst = string == null ? null :replaceAll(string, mapObj)
        }
    }

    let risk_level_status = showRiskLevel ? props.natural_person.risk_level : undefined

    const [riskLevelStatus, setRiskLevelStatus] = useState("")

    const handleRiskLevelChange = (event) => {
        setRiskLevelStatus(event.target.value)
    }

    const [observationBox, setObservationBox] = useState(allowChanges 
        ? "" 
        : observation == null 
            ? analyst
            : analyst != null 
                ? `${analyst} ${observation}`
                : observation)

    return (
        <div className="analysisCard">
            <span className={["blueText", "subtitleSize"].join(" ")}>Revisão</span>
            <div className="internalCardContainer">
                {status === "on_hold" && (
                    
                    <div>
                    <div style={{margin:'20px 0px'}}>
                        <div className={["yellowText", "subtitleSize"].join(" ")} style={{marginTop: "10px"}}>Observação - Em espera</div>
                        <div>
                            {`${analyst} ${observation}`}
                        </div>
                    </div>
                    <div className={["blueText", "subtitleSize"].join(" ")} style={{marginTop: "10px"}}>Observação - Análise manual</div>
                    </div>
                )}
                <ObservationTextField content={observationBox} setContent={setObservationBox} service="onboarding" disable={!allowChanges} />
            {!allowChanges ?
                (showRiskLevel && risk_level_status !== undefined && risk_level_status !== "undefined") ?
                <div>
                    <div className={["blueText", "subtitleSize"].join(" ")} style={{marginTop: "10px"}}>Nível de Risco</div>
                    <ThemeProvider theme={theme} >
                        <TextField 
                            style={{margin: "20px 0px"}}        
                            fullWidth
                            multiline
                            variant="outlined"
                            value={risk_level_dictionary[risk_level_status]}
                            disabled={!allowChanges}
                        />
                    </ThemeProvider>
                </div>
                : null: null}
            </div>
            {!allowChanges ? null :
                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
                    {showRiskLevel
                        ? <div style={{width: "150px", marginRight: 24}}>
                            <div className={["blueText", "subtitleSize"].join(" ")} style={{marginBottom: 8}} >Nível de Risco</div>
                            <ThemeProvider theme={theme} >
                                <Select 
                                    style={{width: "100%"}}
                                    displayEmpty
                                    value={riskLevelStatus}
                                    onChange={handleRiskLevelChange}
                                >
                                    {risk_level_enumList.map(
                                        risk_level_enum => <MenuItem key={risk_level_enum[0]} value={risk_level_enum[0]}>{risk_level_enum[1]}</MenuItem>
                                    )}
                                </Select>
                            </ThemeProvider>
                        </div>
                        : null
                    }
                    <div style={{display: "flex", justifyContent: "flex-end", marginTop: "25px"}}>
                        <div 
                            className={["button", "approved", "normalText", "normalMediumSize"].join(" ")}
                            style={{marginLeft:"auto", alignItems: "center", justifyContent: "center", display: "flex"}}
                            onClick={handleApprovedOpenClick}
                        >
                            APROVAR
                        </div>
                        {["123-123-123", "35635639-0dfe-41e9-a785-9728bd334cee", "09100019-9471-4755-96e8-bc1c1fb46136", "8a4ff9e6-4298-49a5-a6d0-c0898b0d7336", "39c55dc0-dc11-4323-8c42-297c46f48085", "9e129589-8077-41aa-88ae-a5ab4de2d1be", "812f17e6-6b0c-4d0f-8e8b-34dc5dd0123c","4efbf14b-947e-42d9-b293-8209b0940139"].includes(props.natural_person.company_key) ?  <div 
                            className={["button", "challenged", "normalText", "normalMediumSize"].join(" ")}
                            style={{marginLeft:"auto", alignItems: "center", justifyContent: "center", display: "flex"}}
                            onClick={handleChallengedOpenClick}
                        >
                            CONTESTAR
                        </div> : null}
                        {(["456-456-456", "946fedd1-ae5d-430e-9d27-1a754801e705", "86cae100-d9a1-4f05-9535-e46a32d8fb77","438a3f9b-9681-4e0d-bc58-350d2ed98983","abe8ca59-1f9b-4f52-8d85-d209057a18aa"].includes(userData.business_group_key) || ["123-123-123","3147eb44-1eb9-485b-a7ee-89d89fca81db","64520138-3e5b-4c2a-856a-33e1b199b0cb","5a413013-d9b5-4cb6-851a-96e7bb0b15c6","02f9d99a-a7a3-49e6-a5e9-cc5fc5c74ed3","64520138-3e5b-4c2a-856a-33e1b199b0cb"].includes(props.natural_person.company_key)) && props.natural_person.analysis_status === 'in_manual_analysis' ?  <div 
                            className={["button", "challenged", "normalText", "normalMediumSize"].join(" ")}
                            style={{marginLeft:"auto", alignItems: "center", justifyContent: "center", display: "flex"}}
                            onClick={handleOnHoldOpenClick}
                        >
                            COLOCAR EM ESPERA
                        </div> : null}
                        <div 
                            className={["button", "reproved", "normalText", "normalMediumSize"].join(" ")}
                            style={{marginLeft:"auto", alignItems: "center", justifyContent: "center", display: "flex"}}
                            onClick={handleReprovedOpenClick}
                        >
                            REPROVAR
                        </div>
                    </div>
                </div>
            }
            {!allowChanges ? null :
            <ConfirmationDialog
                open={openDialog}
                manualAnalysisStatus={manualAnalysisStatus}
                onClose={handleDialogClose}
                data={observationBox}
                natural_person_key={props.natural_person_key}
                user_data={props.user_data}
                riskLevelStatus={riskLevelStatus}
            />
            }
        </div>
    )
}

export default Observation
