import React, { useEffect } from "react";
import ValidatedImage from "../../utils/ValidatedImage"
import MilitaryRegistryDialog from "./MilitaryRegistryDialog/MilitaryRegistryDialog"
import { CircularProgress } from '@material-ui/core';
import axios from "axios";
import { useTranslation } from "react-i18next";

function MilitaryRegistryCard(props){

    const { t } = useTranslation()

// Set inputs
const { military_registry } = props

//Set state variables
const [OCR, setOCR]              = React.useState(null)

//Set initial state of image dialog box
let [OCRPictureOpen, setOCRPictureOpen] = React.useState(false);

//Set handlers to open and close dialog
const handleOCRPictureOpen = () => {
    setOCRPictureOpen(true);
};
const handleOCRPictureClose = () => {
    setOCRPictureOpen(false);
};

useEffect(() => {
    const timer = setTimeout(() => {
        if (military_registry.ocr_key){
            axios.get('/dash/ocr/' + military_registry.ocr_key).then( response => {
                let ocr_data = response.data.processed_object
                if (ocr_data == null) throw new Error()
                setOCR(ocr_data);             
            }).catch(error => {
                setOCR(undefined)
            })
        }
    }, 500);
    return () => {
        clearTimeout(timer)
    }
}, [military_registry])

let getPictureContent = (picture) => {
    if (picture === undefined) {
        return (          
            <div 
                className={["labelText", "normalMediumSize"].join(" ")} 
                style={{textAlign:"center", margin:"auto"}}
            >
                {t("information_unavailable")}
            </div>
        )
    } 
    else{
        return (
            <ValidatedImage
                image_key={picture}
                dash_api_endpoint_url={'/dash/ocr/image'}
                imageStyle = {{margin:"auto", width:"100%", height:"100%",position:"absolute", top:"-9999px",bottom:"-9999px",left:"-9999px",right:"-9999px", borderRadius: "5px", objectFit:"cover"}}
                imageAlt = {"Foto do Documento"}
            />
        );
    }
};

// Renderiza o card
if (OCR === null) {
    return (
        <div style={{minHeight: "250px"}} className="analysisCard">
            <div className={["blueText", "subtitleSize"].join(" ")}>Registro Militar OCR</div>
            <div style={{display:"flex", flexGrow:"1"}}>
                <CircularProgress style={{margin:"auto"}} />
            </div>
        </div>
    );    
}
else if (OCR === undefined){
    return (
        <div style={{minHeight: "250px"}} className="analysisCard">
            <div className={["blueText", "subtitleSize"].join(" ")}>Registro Militar OCR</div>
            <div style={{display:"flex", flexGrow:"1"}}>
                <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                    {t("information_unavailable")}
                </div>
            </div>
        </div>
    )
}
else {
    return (
        <div style={{minHeight: "450px"}} className="analysisCard">
            <div className={["blueText", "subtitleSize"].join(" ")}>Registro Militar OCR</div>
            <div className="internalCardContainer" style={{display:"flex", flexGrow:"1", maxHeight:"450px",flexDirection: "row"}}>
                <div style={{display:"flex", width:"28%", maxHeight:"64vw", flexDirection: "column"}}>
                    <div style={{display:"block", width:"100%", height:"90%", margin: "0 auto", overflow:"hidden", position:"relative", alignItems: "center", justifyContent: "center",borderRadius: "5px" }}>
                        {military_registry.ocr_key && getPictureContent( military_registry.ocr_key )}
                    </div>
                    {military_registry.ocr_key&&
                    <div 
                        onClick={handleOCRPictureOpen}
                        className={["blueText", "normalSmallSize", "link"].join(" ")} 
                        style={{margin:"10px", textAlign:"center"}}
                    >
                        Ver fotos
                    </div>}
                </div>
                {military_registry.ocr_key&&
                    <MilitaryRegistryDialog
                        open={OCRPictureOpen}
                        onClose={handleOCRPictureClose}
                        image_list={[military_registry.ocr_key]}
                />}
            </div>
        </div>
    );
}
}

export default MilitaryRegistryCard