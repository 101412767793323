import React from 'react'
import Modal from '../../@Components/Modal/Modal';
import { Card } from '@material-ui/core';
import moment from 'moment';
import {formatAsCPFCNPJMasked, formatAsCPFCNPJ } from "../../Utils/utils"
import {titleCase} from '../utils/utils'

function ModalLegalPerson(props){

    const {closeModal, nameToSearch, personList, analyzedObj, setAnalyzedObj, onSubmit} = props

    return(
        <Modal 
            closeModal={closeModal}
            title="Consulta por Nome"
            >
            <div className='Modal_body_queryByName' style={{ display: 'flex'}}>
                <div className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                    style={{display: 'flex', justifyContent: 'start', margin: '40px'}}
                >
                    <strong style={{marginRight: '10px'}}>Exibindo resultados para:</strong>
                    <span>{nameToSearch}</span>
                </div>
                <div className='cardContainer' style={{margin: '0px 30px'}} >
                    <div className="listCardHeader" style={{display: 'grid',  gridTemplateColumns: '25% 25% 25% 25%'}}>
                        <div className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}>
                            Razão Social
                        </div>
                        <div className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}>
                            Documento
                        </div>
                        <div className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}>
                            Data de Fundação
                        </div>
                    </div>
                {personList['data'].map((legal_person, index) => (
                    <Card key={legal_person.document} className={["listCard","collapse"].join(" ")} >
                        <div style={{display: 'grid',  gridTemplateColumns: '25% 25% 25% 25%'}}>
                            <div className={["listCardItem", "normalText", "subtitleSize"].join(" ")}>
                                {titleCase(legal_person.legal_name)}
                            </div>
                            <div className={["listCardItem", "normalText", "subtitleSize"].join(" ")}>
                                {formatAsCPFCNPJMasked(legal_person.document)}
                            </div>
                            <div className={["listCardItem", "normalText", "subtitleSize"].join(" ")}> 
                                {moment(legal_person.foundation_date).format("DD/MM/YYYY")}
                            </div>
                            <div className={["listCardItem", "normalText", "normalMediumSize", "bold"].join(" ")}>
                                {analyzedObj[index] ? 
                                <div className={"button standard disabled"}
                                    style={{minWidth: '100%'}}
                                >
                                    {"Solicitado"}
                                </div>
                                :
                                <div
                                    id={formatAsCPFCNPJ(legal_person.document)}
                                    onClick={(event) => {onSubmit(event); setAnalyzedObj({...analyzedObj, [index]: true})}} 
                                    className={"button standard "} 
                                    style={{minWidth: '100%'}}
                                >
                                    {"Analisar"}
                                </div>}
                            </div>
                        </div>
                    </Card>))}
                </div>
            </div>
        </Modal>

    )
}

export default ModalLegalPerson