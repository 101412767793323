import React from "react"
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import moment from "moment"
import { formatAsMoney } from '../../../../Utils/utils'

const useStyles = makeStyles((theme) => ({
    tableStyle: {
        border: "none",
        boxShadow: "none",
    },
    tableText: {
        fontFamily: "Open Sans",
        fontWeight: "normal",
        fontSize: "12px",
        lineHeight: "16px",
        color: "#141414",
        textAlign: "center",
        margin: "10px",
        padding: "6px",
        minWidth: "120px"
    },
    tableHeader: {
        textAlign: "center",
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: "normal",
        color: "#6F6F6F",
        fontSize: "14px",
        lineHeight: "19px",
        padding: "6px",
    },
    even: {
        background: "#F3F3F3",
    },
    odd: {
        background: "#FFFFFF",
    },
}))

export const NegativeAnnotationsTable = ({ negative_annotations }) => {

    const { t } = useTranslation()

    const classes = useStyles()
    useTheme()

    const getClass = (rowIndex) => {
        if (rowIndex % 2 === 0) {
            return classes.even
        }

        return classes.odd
    }

    const uppercaseFirstLetter = string => string[0].toUpperCase() + string.substring(1)


    return (
        <Paper className={classes.tableStyle}>
            <Table style={{ padding: "8px" }}>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableHeader}>{t("Empresa")}</TableCell>
                        <TableCell className={classes.tableHeader}>{t("Número do Contrato")}</TableCell>
                        <TableCell className={classes.tableHeader}>{t("Natureza")}</TableCell>
                        <TableCell className={classes.tableHeader}>{t("Data da Entrada")}</TableCell>
                        <TableCell className={classes.tableHeader}>{t("Data de Inclusão")}</TableCell>
                        <TableCell className={classes.tableHeader}>{t("Status")}</TableCell>
                        <TableCell className={classes.tableHeader}>{t("Tipo Participante")}</TableCell>
                        <TableCell className={classes.tableHeader}>{t("Valor")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {negative_annotations.map((annotation, index) => (
                        <TableRow
                            key={index}
                            className={getClass(index)}
                        >
                            <TableCell className={classes.tableText}>
                                {annotation.company_name == null ? "-" : annotation.company_name}
                            </TableCell>
                            <TableCell className={classes.tableText}>
                                {annotation.contract_number ? annotation.contract_number : "-"}
                            </TableCell>
                            <TableCell className={classes.tableText}>
                                {annotation.nature ? annotation.nature : "-"}
                            </TableCell>
                            <TableCell className={classes.tableText}>
                                {annotation.entry_date ? moment(annotation.entry_date).format("DD/MM/YYYY") : "-"}
                            </TableCell>
                            <TableCell className={classes.tableText}>
                                {annotation.inclusion_date ? moment(annotation.inclusion_date).format("DD/MM/YYYY") : "-"}
                            </TableCell>
                            <TableCell className={classes.tableText}>
                                {annotation.notation_status ? uppercaseFirstLetter(annotation.notation_status) : "-"}
                            </TableCell>
                            <TableCell className={classes.tableText}>
                                {annotation.participant_type ? uppercaseFirstLetter(annotation.participant_type) : "-"}
                            </TableCell>
                            <TableCell className={classes.tableText}>
                                {annotation.amount ? formatAsMoney(annotation.amount) : "-"}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper >
    )
}
