import React from 'react'
import moment from "moment";
import {colorRed} from "../../../utils/utils"

function Timer (props) {

    if (props.time.minutes >= 1){
        return (
            <div className={["blueText", "substitleSize"].join(" ")}>
                Prazo Limite: {moment(props.manual_analysis_limit).format("HH:mm:ss").toString()}
            </div>
        )   
    }
    else if (props.time.seconds <0 || (props.time.minutes ===0 && props.time.seconds ===0)){
        return null
    }
    else {
        return (
            <div className={["normalText", "substitleSize", "bold"].join(" ")} style={{color: colorRed}}>
                Tempo Restante: {props.time.minutes}:{props.time.seconds < 10 ? `0${props.time.seconds}` : props.time.seconds}
            </div>
        )
    }

}

export default Timer;