import React from "react";
import DataField from "../../utils/DataField";
import PersonTypeIcon from "./PersonTypeIcon/PersonTypeIcon";
import people from "../../../../assets/cardEntryIcons/people.svg";
import { formatAddress, formatPhone } from "../../utils/utils";
import { getSalesChannel } from "../../utils/utils";
import ClientMap from "./ClientMap/ClientMap";
import ErrorBoundary from "../../../Utils/ErrorBoundary";
import FaceReconCard from "../FaceReconCard/FaceReconCard";

function ClientCard(props) {
  let { deposit } = props;
  let client = deposit.client;

  let address = formatAddress(client.address);

  return (
    <div className="analysisCard">
      <div style={{ display: "flex" }}>
        <img
          style={{ margin: "auto 8px auto 0px" }}
          src={people}
          alt="people Logo"
        />
        <div className={["blueText", "subtitleSize"].join(" ")}>Cliente</div>
      </div>
      <div className={["blueText", "normalSmallSize"].join(" ")}>
        {(client || {}).id}
      </div>
      <div
        className="internalCardContainer"
        style={{ display: "flex", marginTop: "15px" }}
      >
        <PersonTypeIcon personType={client.type} />
        <div className={["subtitleSize"].join(" ")} style={{ width: "70%" }}>
          <div
            className="internalCardContainer"
            style={{ display: "flex", flexDirection: "column" }}
          >
            {deposit.face_recognition_key && (
              <ErrorBoundary>
                <FaceReconCard
                  name={"client_selfie"}
                  registration_key={deposit.face_recognition_key}
                />
              </ErrorBoundary>
            )}
            <DataField
              title={"Telefone"}
              label={formatPhone((client || {}).phone, null)}
              size={"normalMediumSize"}
              titleNoWrap={true}
              margin="5px"
            />
            <DataField
              title={"Canal"}
              label={getSalesChannel((client || {}).sales_channel)}
              size={"normalMediumSize"}
              titleNoWrap={true}
              margin="5px"
            />
            <DataField
              title={"Segmento"}
              label={(client || {}).segment}
              size={"normalMediumSize"}
              titleNoWrap={true}
              margin="5px"
            />
          </div>
        </div>
      </div>
      <div className="internalCardContainer">
        <ClientMap address={address} />
      </div>
      <DataField
        title={"Endereço"}
        label={formatAddress((client || {}).address, null)}
        size={"normalMediumSize"}
        titleNoWrap={true}
        margin="5px"
      />
    </div>
  );
}

export default ClientCard;
