import React, {useState, useEffect, useContext} from 'react'
import { useTranslation } from "react-i18next";
import {GetAlertsComponent,setObjectByPath,translateType} from "./utils"
import TextField from "@material-ui/core/TextField";
import MenuItem from '@material-ui/core/MenuItem';
import _ from "lodash"
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RulesContext from "../../../../context/rules-context"
//SCAN DO DISPOSITIVO - Recuperação de Dados do Dispositivo

export function DeviceScanDataRetrievalBox (props) {
    const { t } = useTranslation();
    let {node} = props
    let {read_only} = props
    let {handleConfigurationOpen} = props
    let {handleNodeRemove} = props

    let deviceScanList = useContext(RulesContext).boxes[node.node.type].settings.device_options
    let scanData = deviceScanList.filter(deviceData => deviceData.path === node.node.properties.session_data_path)[0]

    return (
        <div>
            {!read_only ? 
                <div style={{display:"flex", alignItems: "center"}}>
                    <div style={{display:"flex", width:"100%", cursor:"pointer", margin: "auto 0px"}} onClick={() => handleConfigurationOpen(node)}>
                        {t((scanData || {description:""}).description)}
                        {(node.node.alerts || []).length > 0?
                        <GetAlertsComponent alerts={node.node.alerts} />  : null} 
                    </div>
                    <IconButton style={{width: 32, height: 32}} aria-label="duplicate" onClick={() => props.handleNodeDuplication(node)}>
                        <ContentCopyIcon style={{width: 20, height: 20}} />
                    </IconButton>
                    <IconButton style={{width: 32, height: 32}} aria-label="close" onClick={() => handleNodeRemove(node)}>
                        <CloseIcon style={{width: 20, height: 20}} />
                    </IconButton>
                </div>
                :
                <div style={{display:"flex"}}>
                    <div style={{display:"flex", width:"100%", cursor:"pointer", margin: "auto 0px"}} onClick={() => handleConfigurationOpen(node)}>
                        {t((scanData || {description:""}).description)}
                    </div>
                </div>
            }
        </div>
    )
}

export function DeviceScanDataRetrievalConfiguration(props){
    const { t } = useTranslation();
    let {node} = props
    let {read_only} = props
    let {handleNodeChange} = props
    let {handleConfigurationClose} = props

    let deviceScanList = useContext(RulesContext).boxes[node.node.type].settings.device_options.sort((a, b) => a.description === b.description ? 0 : a.description > b.description ? 1 : -1)

    const [nodeState, setNodeState] = useState(node)

    useEffect(()=>{
        setNodeState(node)
    },[node])

    const handleEventChange = (e) => {
        let new_node = _.cloneDeep(nodeState)
        setObjectByPath(new_node.node,"properties.session_data_path",e.target.value)
        setNodeState(new_node)
    }

    const handleSave = () => {
        handleConfigurationClose()
        handleNodeChange(nodeState)
    }

    const validateAllValues = () => {
        return (nodeState.node.properties.session_data_path && nodeState.node.properties.session_data_path.length >0)
    }

    return(
        <div style={{flexGrow:"1", padding: "20px", display:"flex", flexDirection:"column"}}>
            <div style={{display: "flex"}}>
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>{t(node.node.description)}</span>
                <Tooltip title={<div className="tooltipText"><ul>{node.node.helperText.map((help,index) => (<li key={index}>{t(help)}</li>))}</ul></div>}>
                    <HelpIcon style={{fontSize: "14px", display:"flex", margin:"auto 5px"}}/>
                </Tooltip>
            </div>
            <div className="internalCardContainer" style={{display:"flex", flexDirection: "column", width: '300px'}}>
                <TextField
                    label={t("Campo")}
                    style={{textAlign:"center", width:"80%", margin:"3px auto"}}
                    className="filterTextbox" 
                    size="small" fullWidth 
                    value={nodeState.node.properties.session_data_path}
                    onChange={handleEventChange}
                    select
                    InputProps={{
                        readOnly: read_only
                      }}
                >
                    {deviceScanList.map((deviceData, index) => (
                        <MenuItem key={index} value={deviceData.path}><em className="normalText">{t(deviceData.description) + " ("+t(translateType(deviceData.type))+")"}</em></MenuItem>
                    ))}
                </TextField>             
            </div>
            <div style={{display:"flex", margin: "auto auto 5px auto"}}>
                <div
                    className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")}
                    style={{width:"120px"}}
                    onClick={handleConfigurationClose}
                >
                    {read_only ? "X" : t("discard")}
                </div>
                {!read_only && 
                <div
                    className={
                        validateAllValues()
                        ?
                        ["button", "standard", "normalText", "normalMediumSize"].join(" ")
                        :
                        ["button", "standard", "normalText", "normalMediumSize","disabled"].join(" ")}
                    style={{width:"120px"}}
                    onClick={validateAllValues() ? handleSave : null}
                >
                    {t("save")}
                </div>}   
            </div>
        </div>
    )
}

export class DeviceScanDataRetrievalBoxClass {
    constructor(rulesContext){
        this.rulesContext = rulesContext
    }
    validate(node){
        let put_error = false
        let alerts = []
        //Validate if node has no children
        if((node.node.children || []).length !== 0){
            put_error = true
            alerts.push("Este nó não deve possuir filhos")
        }
        if(!node.node.properties.session_data_path || node.node.properties.session_data_path === ""){
            put_error = true
            alerts.push("Clique no nó deste Dado do Dispositivo, abra a configuração e escolha um tipo válido")
        }
        //Validate if parent is valid
        if (node.parentNode != null) {
            if(node.parentNode.on_error){
                put_error = true
                alerts.push("O nó superior está inválida")
            }
        }
        return {validated: !put_error, alerts: alerts}
    }

    getType(node){
        let deviceScanList = this.rulesContext.boxes[node.type].settings.device_options
        let scanData = deviceScanList.filter(deviceData => deviceData.path === node.properties.session_data_path)[0]
        return (scanData || {type:""}).type
    }
}