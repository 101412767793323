import React from "react";
import { useTranslation } from "react-i18next";

function ScorePositivoScoreCard (props){

  const { score } = props
  const { t } = useTranslation()

  return (
    <div  className="avoidPageBreakInside" style={{padding:"10px"}}>
      <div className="analysisCard" style={{height:"100%"}}>
        <div className={["blueText", "subtitleSize"].join(" ")} style={{marginBottom: "10px"}}>{t("Score")}
        </div>
          <p className={["normalText","analysisCardSummaryLargeSize","analysisCardSummaryColor", "alignCenter"].join(" ")}>
            {score}/1000
          </p>
      </div>
    </div>
  )
}

export default ScorePositivoScoreCard