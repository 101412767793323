import React, {useState, useContext, useEffect} from 'react'
import { useTranslation } from "react-i18next";
import {GetAlertsComponent, translateType, setObjectByPath} from "./utils"
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import RulesContext from "../../../../context/rules-context"
import TextField from "@material-ui/core/TextField";
import MenuItem from '@material-ui/core/MenuItem';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import _ from "lodash"

export function ListsGetColumnFromFieldBox (props) {
    const { t } = useTranslation();
    let {node} = props
    let {read_only} = props
    let {handleConfigurationOpen} = props
    let {handleNodeRemove} = props
    let rulesList = useContext(RulesContext)
    let list = rulesList.lists.filter(list => list.list_key === node.node.properties.list_key)[0]
    let column = (list || {fields:[]}).fields.filter(field => field.name === node.node.properties.list_column_name)[0]

    return (
        <div>
            {!read_only ?
                <div style={{display:"flex", alignItems: "center"}}>
                    <div 
                        style={{display:"flex", width:"100%", cursor:"pointer", margin: "0px"}} 
                        onClick={() => handleConfigurationOpen(node)}
                    >
                        <div style={{display: "flex",flexDirection: "column",margin: "auto 0px"}}>
                            <div className={["normalText","normalMediumSize"].join(" ")}>
                            {column ? t("Recuperar ") + t((column || {name:""}).name): ""}
                            </div>
                            <div className={["normalText","normalSmallSize"].join(" ")}>
                            {list ? t("Da Lista ") + t((list || {name:""}).name) : ""}
                            </div>
                        </div>
                        {(node.node.alerts || []).length > 0?
                        <GetAlertsComponent alerts={node.node.alerts} />  : null}       
                    </div>
                    <IconButton style={{width: 32, height: 32}} aria-label="duplicate" onClick={() => props.handleNodeDuplication(node)}>
                        <ContentCopyIcon style={{width: 20, height: 20}} />
                    </IconButton>
                    <IconButton style={{width: 32, height: 32}} aria-label="close" onClick={() => handleNodeRemove(node)}>
                        <CloseIcon style={{width: 20, height: 20}} />
                    </IconButton>
                </div>
            :
                <div style={{display:"flex"}}>
                    <div 
                        style={{display:"flex", width:"100%", cursor:"pointer", margin: "0px"}} 
                        onClick={() => handleConfigurationOpen(node)}
                    >
                        <div style={{display: "flex",flexDirection: "column",margin: "auto 0px"}}>
                            <div className={["normalText","normalMediumSize"].join(" ")}>
                            {column ? t("Recuperar ") + t((column || {name:""}).name): ""}
                            </div>
                            <div className={["normalText","normalSmallSize"].join(" ")}>
                            {list ? t("Da Lista ") + t((list || {name:""}).name) : ""}
                            </div>
                        </div>     
                    </div>
                </div>
            }
        </div>
    )
}


export function ListsGetColumnFromFieldConfiguration(props){
    const { t } = useTranslation();
    let {node} = props
    let {read_only} = props
    let {handleNodeChange} = props
    let {handleConfigurationClose} = props
    let rulesList = useContext(RulesContext)

    const [nodeState, setNodeState] = useState(node)

    const getAvailableField = () => {
        let availableList
        if (nodeState.node.metadata.list_type !== "geo_shape"){
            availableList = rulesList.fields_mapper_list.filter(field => field.type === nodeState.node.metadata.list_type)
        }
        else{
            availableList = rulesList.fields_mapper_list.filter(field => field.type === "geo_point")
        }
        return availableList
    }

    const getSelectedList = () => {
        let list = rulesList.lists.filter(list => list.list_key === nodeState.node.properties.list_key)[0]
        return list || {fields:[]}
    }

    useEffect(()=>{
        setNodeState(node)
    },[node])

    const handleListChange = (e) => {
        let list = rulesList.lists.filter(list => list.list_key === e.target.value)[0]
        let new_node = _.cloneDeep(nodeState)
        setObjectByPath(new_node.node,"properties.list_key",e.target.value)
        setObjectByPath(new_node.node,"metadata.list_type",list.fields[0].type.enum)
        setNodeState(new_node)
    }

    const handleEventChange = (e) => {
        let event = rulesList.fields_mapper_list.filter(field => field.path === e.target.value)[0]
        let new_node = _.cloneDeep(nodeState)
        setObjectByPath(new_node.node,"properties.field_path",e.target.value)
        setObjectByPath(new_node.node,"metadata.field_type",event.type)
        setNodeState(new_node)
    }

    const handleColumnChange = (e) => {
        let column = getSelectedList().fields.filter(field => field.name === e.target.value)[0]
        let new_node = _.cloneDeep(nodeState)
        setObjectByPath(new_node.node,"properties.list_column_name",e.target.value)
        setObjectByPath(new_node.node,"metadata.list_column_type",column.type.enum)
        setNodeState(new_node)
    }

    const handleSave = () => {
        handleConfigurationClose()
        handleNodeChange(nodeState)
    }

    const validateAllValues = () => {
        return (
            (nodeState.node.properties.list_key && nodeState.node.properties.list_key.length >0) &&
            (nodeState.node.properties.field_path && nodeState.node.properties.field_path.length >0) &&
            (nodeState.node.properties.list_column_name && nodeState.node.properties.list_column_name.length >0)
        )
    }

    return(
        <div style={{flexGrow:"1", padding: "20px", display:"flex", flexDirection:"column"}}>
            <div style={{display: "flex"}}>
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>{t(node.node.description)}</span>
                <Tooltip title={<div className="tooltipText"><ul>{node.node.helperText.map((help,index) => (<li key={index}>{t(help)}</li>))}</ul></div>}>
                    <HelpIcon style={{fontSize: "14px", display:"flex", margin:"auto 5px"}}/>
                </Tooltip>
            </div>
            <div className="internalCardContainer" style={{display:"flex", flexDirection: "column", width: '300px'}}>
                <TextField
                    label={t("Lista")}
                    style={{textAlign:"center", width:"80%", margin:"3px auto"}}
                    className="filterTextbox" 
                    size="small" fullWidth 
                    value={nodeState.node.properties.list_key}
                    onChange={handleListChange}
                    select
                    InputProps={{
                        readOnly: read_only
                      }}
                >
                    {rulesList.lists.map((list, index) => (
                        <MenuItem key={index} value={list.list_key}><em className="normalText">{t(list.name) + " ("+ t(translateType(list.fields[0].type.enum)) +")"}</em></MenuItem>
                    ))}
                </TextField>             
                <TextField
                    disabled={!(nodeState.node.properties.list_key && nodeState.node.properties.list_key.length >0)}
                    label={t("Campo")}
                    style={{textAlign:"center", width:"80%", margin:"3px auto"}}
                    className="filterTextbox" 
                    size="small" fullWidth 
                    value={nodeState.node.properties.field_path}
                    onChange={handleEventChange}
                    select
                    InputProps={{
                        readOnly: read_only
                      }}
                >
                    {getAvailableField().map((field, index) => (
                        <MenuItem key={index} value={field.path}><em className="normalText">{t(field.description) + " ("+ t(translateType(field.type)) +")"}</em></MenuItem>
                    ))}
                </TextField>     
                <TextField
                    disabled={!(nodeState.node.properties.list_key && nodeState.node.properties.list_key.length >0)}
                    label={t("Coluna Recuperada")}
                    style={{textAlign:"center", width:"80%", margin:"3px auto"}}
                    className="filterTextbox" 
                    size="small" fullWidth 
                    value={nodeState.node.properties.list_column_name}
                    onChange={handleColumnChange}
                    select
                    InputProps={{
                        readOnly: read_only
                      }}
                >
                    {getSelectedList().fields.map((field, index) => (
                        <MenuItem key={index} value={field.name}><em className="normalText">{t(field.name) + " (" + t(translateType(field.type.enum)) + ")"}</em></MenuItem>
                    ))}
                </TextField>           
            </div>
            <div style={{display:"flex", margin: "auto auto 5px auto"}}>
                <div
                    className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")}
                    style={{width:"120px"}}
                    onClick={handleConfigurationClose}
                >
                    {read_only ? "X" : t("discard")}
                </div>
                {!read_only && 
                <div
                    className={
                        validateAllValues()
                        ?
                        ["button", "standard", "normalText", "normalMediumSize"].join(" ")
                        :
                        ["button", "standard", "normalText", "normalMediumSize","disabled"].join(" ")}
                    style={{width:"120px"}}
                    onClick={validateAllValues() ? handleSave : null}
                >
                    {t("save")}
                </div>}   
            </div>
        </div>
    )
}


export class ListsGetColumnFromFieldBoxClass {
    constructor(rulesContext){
        this.rulesContext = rulesContext
    }
    validate(node){
        let put_error = false
        let alerts = []

        //Validate if node has at least one children
        if((node.node.children || []).length !== 0){
            put_error = true
            alerts.push("Este nó deve não deve ter filhos")
        }
        
        if(!node.node.properties.list_key || node.node.properties.list_key === ""){
            put_error = true
            alerts.push("Clique no nó desta Lista, abra a configuração e escolha uma lista válida")
        }
        else if(!node.node.metadata.list_type || node.node.metadata.list_type === ""){
            put_error = true
            alerts.push("Há um problema com o tipo desse campo")
        }

        if(!node.node.properties.field_path || node.node.properties.field_path === ""){
            put_error = true
            alerts.push("Clique no nó desta Lista, abra a configuração e escolha um campo válido")
        }
        else if(!node.node.metadata.field_type || node.node.metadata.field_type === ""){
            put_error = true
            alerts.push("Há um problema com o tipo desse campo")
        }

        if(!node.node.properties.list_column_name || node.node.properties.list_column_name === ""){
            put_error = true
            alerts.push("Clique no nó desta Lista, abra a configuração e escolha uma coluna válida")
        }
        else if(!node.node.metadata.list_column_type || node.node.metadata.list_column_type === ""){
            put_error = true
            alerts.push("Há um problema com o tipo dessa coluna")
        }

        if (node.node.metadata.field_type !== node.node.metadata.list_type && node.node.metadata.list_type !== "geo_shape"){
            put_error = true
            alerts.push("O tipo do campo é diferente do tipo do valor chave da lista escolhida")
        }
        else if (node.node.metadata.list_type === "geo_shape" && node.node.metadata.field_type !== "geo_point"){
            put_error = true
            alerts.push("Lista com campo chave do tipo " + translateType("geo_shape") + " aceitam apenas filhos do tipo "+ translateType("geo_point"))
        }

        //Validate if parent is valid
        if (node.parentNode != null) {
            if(node.parentNode.on_error){
                put_error = true
                alerts.push("O nó superior está inválido")
            }
        }
        return {validated: !put_error, alerts: alerts}
    }
    getType(node){
        return node.metadata.list_column_type
    }
}