import React from 'react'
import ProductsTable from "./ProductsTable/ProductsTable"
import orderIcon from '../../../../assets/cardEntryIcons/order.svg';
import clockIcon from '../../../../assets/cardEntryIcons/Clock.svg';
import getSymbolFromCurrency from 'currency-symbol-map'
import moment from 'moment'
import DataField from "../../utils/DataField";
import { formatIrregularity } from '../../utils/utils';


function ProductsCard(props) {
    let {order} = props

    return (
        <div className="analysisCard" style={{display: "flex", flexDirection:"column", flexGrow:"1"}}>
            <div style={{display: "flex"}}>
                <img className="cardTitleIconStyle" src={orderIcon} alt="transactionIcon Logo" />
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>Pedido</span>
                {
                    order.card_order_status === "fraud" ?
                    formatIrregularity({
                        asTooltip:true, 
                        additionalStyle:{margin:"auto auto auto 10px", backgroundColor:"red"}
                    })
                    :
                    null
                }
            </div>
            <div style={{display:"flex", flexDirection: "row"}} className="internalCardContainer">
                <div>
                    <DataField
                            titleImage={clockIcon}
                            titleTooltipText="Data do Pedido"
                            alt={"clockIcon Logo"}
                            label={moment(order.order_date).format("DD/MM/YYYY - HH:mm:ss")}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="5px"
                            display={"inline"}
                    />
                </div>
                <div style={{display:"flex", flexGrow:"1", textAlign:"right", flexDirection:"column"}}>
                    <div className={["labelText", "normalMediumSize", "bold"].join(" ")}>
                        Valor Total do Pedido
                    </div>
                    <div className={["normalText", "moneySize", "bold"].join(" ")}>
                        {order.payment.total_amount == null ? "-" : getSymbolFromCurrency(order.payment.currency) + " " + ((order.payment.total_amount)/100).toLocaleString('de-DE', {minimumFractionDigits: 2})}
                    </div>
                </div>
            </div>
            <div className={["blueText", "normalMediumSize", "bold"].join(" ")}>
                PRODUTOS
            </div>
            <div>
                    <ProductsTable order={order} />
            </div>
        </div>
    )
}

export default ProductsCard