import React, {useState, useContext} from 'react'
import { CircularProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import CompaniesContext from "../../../../context/companies-context"

const theme = createMuiTheme({
    palette:{
        primary: {
            main:"#0b1f82"
        },
    }
  });

function SelectCompany (props){

    const [value, setValue] = useState(null)
    let companyList = useContext(CompaniesContext).card_issuance_backoffice_list

    const handleSelectedCompany = () => {
        if (value){
            props.setSelectCompany({
                status:false,
                company_key: value.company_key
            })
        }
    }

    const handleSelectBusinessGroup = () => {
        props.setSelectCompany({
            status: false,
        });
    }
    
    if (companyList==null){
        return (
            <div style={{display:"flex", flexGrow:"1"}}>
                <CircularProgress style={{margin:"auto"}}/>
            </div>
        )
    }
    else {
        return (
            <div style={{ display:"flex", flexDirection:"column", flexGrow:"1"}}>
                <div style={{margin:"10px auto"}}>
                    <ThemeProvider theme={theme}>
                        <Autocomplete
                            id="combo-box-demo"
                            value={value}
                            onChange={(event, newValue) => {
                            setValue(newValue);
                            }}
                            options={companyList.business_group_data}
                            getOptionLabel={(option) => option.company_name}
                            style={{ width: 300 }}
                            renderInput={(params) => 
                                <TextField 
                                    onKeyPress= {(e) => {if (e.key === 'Enter') {handleSelectedCompany()}}} 
                                    {...params} 
                                    autoFocus 
                                    label="Selecione um cliente" 
                                    color="primary" 
                                    variant="outlined" 
                                />}
                        />
                    </ThemeProvider>
                </div>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginTop: "32px", width: "100%"}}>
                    <div
                        className={value == null ? ["button","standard", "disabled", "normalText", "normalMediumSize"].join(" ") : ["button","standard", "normalText", "normalMediumSize"].join(" ")} 
                        onClick={handleSelectedCompany} 
                        style={{margin:"0 0 0 0", width:"40%"}}>
                        Selecionar
                    </div>
                    <div
                        className="button standard"
                        onClick={handleSelectBusinessGroup} 
                        style={{margin:"0 0 0 0", width:"40%"}}>
                        Selecionar todos
                    </div>
                </div>
            </div>
        )
    }

}

export default SelectCompany