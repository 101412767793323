const DESCRIPTIONS = {
  "deposit": {
    "description": "deposit_description",
    "docs": "https://docs.qitech.com.br/documentation/caas/banking/deposits/introduction",
    "use_webhook": true
  },
  "withdrawal": {
    "description": "withdrawal_description",
    "docs": "https://docs.qitech.com.br/documentation/caas/banking/withdrawals",
    "use_webhook": true
  },
  "wire_transfer": {
    "description": "wire_transfer_description",
    "docs": "https://docs.qitech.com.br/documentation/caas/banking/wire_transfers",
    "use_webhook": true
  },
  "onboarding": {
    "description": "onboarding_description",
    "docs": "https://docs.qitech.com.br/documentation/caas/onboarding/introduction",
    "use_webhook": true
  },
  "card_order": {
    "description": "card_order_description",
    "docs": "https://docs.qitech.com.br/documentation/caas/card_order/introduction",
    "use_webhook": true
  },
  "credit_analysis": {
    "description": "credit_analysis_description",
    "docs": "https://docs.qitech.com.br/documentation/caas/credit_analysis/introduction",
    "use_webhook": true
  },
  "bankslip": {
    "description": "bankslip_description",
    "docs": "https://docs.qitech.com.br/documentation/caas/banking/bankslips",
    "use_webhook": true
  },
  "bill_payment": {
    "description": "bill_payment_description",
    "docs": "https://docs.qitech.com.br/documentation/caas/banking/bill_payments",
    "use_webhook": true
  },
  "data": {
    "description": "data_description",
    "docs": "https://docs.qitech.com.br/"
  },
  "car_rental": {
    "description": "car_rental_description",
    "docs": "https://docs.zaig.com.br/car_rental/#introducao",
    "use_webhook": true
  },
  "card_issuance": {
    "description": "card_issuance_description",
    "docs": "https://docs.qitech.com.br/documentation/caas/card_issuance/introduction",
    "use_webhook": false
  },
  "pix": {
    "description": "pix_description",
    "docs": "https://docs.qitech.com.br/documentation/caas/banking/pix_transactions",
    "use_webhook": false
  },
  "ocr": {
    "description": "ocr_description",
    "docs": "https://docs.qitech.com.br/documentation/caas/ocr/api/introduction",
    "use_webhook": false
  },
  "face_recognition": {
    "description": "face_recognition_description",
    "docs": "https://docs.qitech.com.br/documentation/caas/face_recognition/api/introduction",
    "use_webhook": false
  },
  "device_scan": {
    "description": "device_scan_description",
    "docs": "https://docs.qitech.com.br/documentation/caas/device_scan/android/introduction",
    "use_webhook": false
  }
};

export default DESCRIPTIONS;