import React from "react";
import BoavistaSummaryDateAmountCard from "../BoavistaSummaryData/BoavistaSummaryDateAmount";
import BoavistaSummaryTotal from "../BoavistaSummaryData/BoavistaSummaryTotal";

function BoavistaDebtsSummaryCardToPDF(props) {
    
    const { debts } = props

    return (
        <div style={{display: "grid",gridTemplateColumns: "20% 20% 20% 20% 20%",marginTop:"25px",marginBottom:"25px"}}>
            <BoavistaSummaryDateAmountCard
                description={"oldest_occurrence"}
                date={debts.oldest_occurrence_date}
                amount={debts.oldest_occurrence_amount}
            />
            <BoavistaSummaryDateAmountCard
                description={"highest_occurrence"}
                date={debts.highest_occurrence_date}
                amount={debts.highest_occurrence_amount}
            />
            <BoavistaSummaryTotal
                description={"total_amount"}
                total={debts.total_amount}
            />
            <BoavistaSummaryTotal
                description={"total_number_of_events"}
                total={debts.total_number_of_events}
            />
            <BoavistaSummaryTotal
                description={"total_number_of_guarantors"}
                total={debts.total_number_of_guarantors}
            />
        </div>
    );
}

export default BoavistaDebtsSummaryCardToPDF
                            