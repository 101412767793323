import React, {useState, useEffect} from 'react'
import { useTranslation } from "react-i18next";
import {GetAlertsComponent, setObjectByPath, getConstantTextFields, validateConstantValues, translateType, truncateDecimals} from "./utils"
import TextField from "@material-ui/core/TextField";
import NumberFormat from 'react-number-format';
import _ from "lodash"
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
//MANIPULAÇÃO DE TEXTO - Substituir Texto

export function StringReplaceBox (props) {
    const { t } = useTranslation();
    let {node} = props
    let {read_only} = props
    let {handleConfigurationOpen} = props
    let {handleNodeRemove} = props

    return (
        <div>
            {!read_only ?
                <div style={{display:"flex", alignItems: "center"}}>
                    <div 
                        style={{display:"flex", width:"100%", cursor:"pointer", margin: "0px"}} 
                        onClick={() => handleConfigurationOpen(node)}
                    >
                        <div style={{display: "flex",flexDirection: "column",margin: "auto 0px"}}>
                            <div className={["normalText","normalMediumSize"].join(" ")}>
                                {t("Substituir")} '{t(node.node.properties.replaced)}' {t("por")} '{t(node.node.properties.to_replace)}'
                            </div>
                            <div className={["normalText","normalSmallSize"].join(" ")}>
                            {node.node.properties.max_replace ? t(truncateDecimals(node.node.properties.max_replace,0)) + t(" ocorrência(s)") : t("Todas as ocorrências")}
                            </div>
                        </div>
                        {(node.node.alerts || []).length > 0?
                        <GetAlertsComponent alerts={node.node.alerts} />  : null}       
                    </div>
                    <IconButton style={{width: 32, height: 32}} aria-label="duplicate" onClick={() => props.handleNodeDuplication(node)}>
                        <ContentCopyIcon style={{width: 20, height: 20}} />
                    </IconButton>
                    <IconButton style={{width: 32, height: 32}} aria-label="close" onClick={() => handleNodeRemove(node)}>
                        <CloseIcon style={{width: 20, height: 20}} />
                    </IconButton>
                </div>
            :
                <div style={{display:"flex"}}>
                    <div 
                        style={{display:"flex", width:"100%", cursor:"pointer", margin: "0px"}} 
                        onClick={() => handleConfigurationOpen(node)}
                    >
                        <div style={{display: "flex",flexDirection: "column",margin: "auto 0px"}}>
                            <div className={["normalText","normalMediumSize"].join(" ")}>
                                {t("Substituir")} '{t(node.node.properties.replaced)}' {t("por")} '{t(node.node.properties.to_replace)}'
                            </div>
                            <div className={["normalText","normalSmallSize"].join(" ")}>
                            {node.node.properties.max_replace ? t(truncateDecimals(node.node.properties.max_replace,0)) + t(" ocorrência(s)") : t("Todas as ocorrências")}
                            </div>
                        </div>     
                    </div>
                </div>
            }
        </div>
    )
}

export function StringReplaceConfiguration(props){
    const { t } = useTranslation();
    let {handleConfigurationClose} = props
    let {handleNodeChange} = props
    let {node} = props
    let {read_only} = props
    
    const [nodeState, setNodeState] = useState(node)

    useEffect(()=>{
        setNodeState(node)
    },[node])

    const handleNodeStateChange = (obj_key,value) => {
        let new_node = _.cloneDeep(nodeState)
        if (value === undefined) value = null
        setObjectByPath(new_node.node,obj_key,value)
        setNodeState(new_node)
    }

    const handleSave = () => {
        handleConfigurationClose()
        handleNodeChange(nodeState)
    }

    const validateAllValues = () => {
        let replaced_validation = validateConstantValues("string",nodeState.node.properties.replaced)
        let to_replace_validation = validateConstantValues("string",nodeState.node.properties.to_replace)
        let max_replace_validation = true
        if (nodeState.node.properties.max_replace){
            max_replace_validation = validateConstantValues("number",nodeState.node.properties.max_replace)
        }
        return replaced_validation && to_replace_validation && max_replace_validation
    }

    return(
        <div style={{flexGrow:"1", padding: "20px", display:"flex", flexDirection:"column"}}>
            <div style={{display: "flex"}}>
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>{t(node.node.description)}</span>
                <Tooltip title={<div className="tooltipText"><ul>{node.node.helperText.map((help,index) => (<li key={index}>{t(help)}</li>))}</ul></div>}>

                    <HelpIcon style={{fontSize: "14px", display:"flex", margin:"auto 5px"}}/>
                </Tooltip>
            </div>
            <div className="internalCardContainer" style={{display:"flex", flexDirection: "column"}}>
                <TextField
                    label={t("A substituir")}
                    style={{textAlign:"center", width:"80%", margin:"3px auto"}}                
                    error={!validateConstantValues("string",nodeState.node.properties.replaced)}
                    {...getConstantTextFields("string").props}
                    value={nodeState.node.properties.replaced}
                    onChange={(e) => handleNodeStateChange("properties.replaced", e.target.value)}
                    InputProps={{
                        readOnly: read_only
                      }}
                />
                <TextField
                    label={t("Novo Valor")}
                    style={{textAlign:"center", width:"80%", margin:"3px auto"}}                
                    error={!validateConstantValues("string",nodeState.node.properties.to_replace)}
                    {...getConstantTextFields("string").props}
                    value={nodeState.node.properties.to_replace}
                    onChange={(e) => handleNodeStateChange("properties.to_replace", e.target.value)}
                    InputProps={{
                        readOnly: read_only
                      }}
                />       
                <NumberFormat
                    label={t("Máximo de Substituições")}
                    style={{textAlign:"center", width:"80%", margin:"3px auto"}}
                    {...getConstantTextFields("number").props}
                    value={nodeState.node.properties.max_replace}
                    onValueChange={(e) => handleNodeStateChange("properties.max_replace", e.floatValue)}
                    InputProps={{
                        readOnly: read_only
                      }}
                />      
            </div>
            <div style={{display:"flex", margin: "auto auto 5px auto"}}>
            <div
                    className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")}
                    style={{width:"120px"}}
                    onClick={handleConfigurationClose}
                >
                    {read_only ? "X" : t("discard")}
                </div>
                {!read_only && 
                <div
                    className={
                        validateAllValues() 
                        ?
                        ["button", "standard", "normalText", "normalMediumSize"].join(" ")
                        :
                        ["button", "standard", "normalText", "normalMediumSize","disabled"].join(" ")}
                    style={{width:"120px"}}
                    onClick={validateAllValues() ? handleSave : null}
                >
                    {t("save")}
                </div>}   
            </div>
        </div>
    )
}

export class StringReplaceBoxClass {
    constructor(rulesContext){
        this.rulesContext = rulesContext
    }
    validate(node){
        let put_error = false
        let alerts = []
        //Validate if node has at least one children
        if((node.node.children || []).length !== 1){
            put_error = true
            alerts.push("Este nó precisa ter apenas um filho")
        }
        //Validate if children of the node is string
        else{
            let childrenType = node.node.children[0].instance.getType(node.node.children[0])
            if (childrenType !== "string"){
                put_error = true
                alerts.push("O filho deste nó deve ter um retorno 'Texto'. Atualmente é " + translateType(childrenType))
            }
        }
        if (!validateConstantValues("string",node.node.properties.replaced)){
            put_error = true
            alerts.push("A propriedade 'A substituir' deve ser um 'Texto' válido")
        }
        if (!validateConstantValues("string",node.node.properties.to_replace)){
            put_error = true
            alerts.push("A propriedade 'Novo Valor' deve ser um 'Texto' válido")
        }
        if (node.node.properties.max_replace && !validateConstantValues("number",node.node.properties.max_replace)){
            put_error = true
            alerts.push("A propriedade 'Máximo de Substituições' deve ser um 'Número' válido ou nulo")
        }
        //Validate if parent is valid
        if (node.parentNode != null) {
            if(node.parentNode.on_error){
                put_error = true
                alerts.push("O nó superior está inválida")
            }
        }
        return {validated: !put_error, alerts: alerts}
    }

    getType(node){
        return "string"
    }
}