import React from 'react'
import {getTypeComponents} from "../BoxTypeMapper"
import * as S from './styles'

function BoxConfiguration (props) {
    let {node} = props
    let {handleNodeChange} = props
    let {handleConfigurationClose} = props
    let {read_only} = props
    let {userVariablesAvailable} = props

    return (
        <S.ConfigurationBoxContainer>
        {getTypeComponents({
        type:node.node.type,
        handleNodeChange:handleNodeChange,
        handleConfigurationClose:handleConfigurationClose,
        userVariablesAvailable: userVariablesAvailable,
        node:node,
        read_only:read_only
    }).configuration_component}
    </S.ConfigurationBoxContainer>
    )
}

export default BoxConfiguration