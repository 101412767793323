import React, {useState} from "react";
import Collapse from "@material-ui/core/Collapse";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import IconButton from "@material-ui/core/IconButton";
import {getCardIcon} from "../../../utils/utils"
import FGTSTable from "./FGTSTable/FGTSTable";

function FGTSCard(props) {

    let {fgts} = props

    const [expanded, setExpanded] = useState(false);
    const [firstTime, setFirstTime] = useState(true);

    let initialExpand = fgts.flag === "positive"? false : true
    if (initialExpand !== expanded && firstTime===true){
        setExpanded(initialExpand);
    }
    const handleExpand = (event) => {
        event.stopPropagation();
        setFirstTime(false)
        setExpanded(!expanded);
    };

    return (
        <div className="analysisCard">
            <div style={{display:"flex"}}>
                <div 
                    className={["blueText", "subtitleSize", "bold"].join(" ")}
                    style={{margin:"auto 0px"}}
                >
                    FGTS
                </div>
                <div style={{margin:"auto 10px"}}>
                    {getCardIcon(fgts.flag)}
                </div>    
                {((fgts.data || [])[0] || {}).result_file_link ?
                <IconButton style={{marginLeft: "auto"}} onClick={handleExpand}>
                    {expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </IconButton>             
                : null }                    
            </div>
            {((fgts.data || [])[0] || {}).result_file_link ?
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <div 
                    className={["normalText", "normalMediumSize", "internalCardContainer"].join(" ")}
                >
                    <FGTSTable fgts_data={fgts.data}/>
                </div>
            </Collapse> 
            : null}                  
            </div>
        );
}


export default FGTSCard