import React, { useEffect } from "react";
import { CircularProgress } from '@material-ui/core';
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";
import AlertsHistoricTable from "./AlertsHistoricTable"

function BankingAlertHistoric(props) {

    const {link_key} = props
    const {alert_key} = props
    const {final_date} = props
    const {showEventType} = props

    const [userHistoric, setUserHistoric] = React.useState(null)
    const [tablePage, setTablePage] = React.useState(1)
  
    useEffect(() => {
        if (userHistoric === null){
            if(link_key == null) setUserHistoric(undefined)
            else{
                let params = {
                    "page_number": tablePage -1,
                    "page_rows": 10,
                    "link_key": link_key,
                    "final_date": final_date
                }
                const timer = setTimeout(() => {
                    axios.get('/dash/alerts/alerts', {
                      params: params
                    }).then(response=>{
                        let filteredAlerts = response.data.data.filter(alert => alert.alert_key !== alert_key)
                        let filteredResponseObject = {...response.data, data: filteredAlerts}
                        setUserHistoric(filteredResponseObject)
                    }).catch(error=>{
                        setUserHistoric(undefined)
                    })
                }, 500);
                return () => {
                  clearTimeout(timer)
                }
            }
        }
    }, [userHistoric,tablePage,alert_key, link_key, final_date])

    const handleChangePage = (event, page) => {
		if (tablePage === page) {
			return
		}
        setTablePage(page)
        setUserHistoric(null)
	}
  
    if (userHistoric === null) {
      return (
        <div style={{display: "flex", height: "160px"}}>
            <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                <CircularProgress />
            </div>
        </div>
      );    
    }
    else if (userHistoric === undefined ){
      return (
        <div style={{display: "flex", height: "60px"}}>
            <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                Informação Indisponível
            </div>
        </div>
      )
    }
    else if (userHistoric.data.length === 0 && userHistoric.number_of_pages >= tablePage){
      return (
        <div style={{display: "flex", height: "60px"}}>
            <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
              Sem Histórico de Alertas
            </div>
        </div>
      )
    }
    else {
      return (
          <div>
              <div>
                <AlertsHistoricTable historic={userHistoric.data} showEventType={showEventType}/>
              </div>
              <div style={{display:"flex", marginTop: "10px"}}>
                <Pagination style={{marginLeft:"auto"}} className='pagination' page={tablePage} count={userHistoric['number_of_pages']} onChange={(e,page) => handleChangePage(e, page)} />
              </div>
          </div>
      );
    }
  }
  
  export default BankingAlertHistoric;