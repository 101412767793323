import React, {useContext} from "react";
import AuthContext from "../../../../context/auth-context";
import {whitelist_ck_show_banking_alert_event_type} from "../../utils/utils"

function CardListHeader(props){

    let company_key = useContext(AuthContext).user_data.company_key 
    let business_group_key = useContext(AuthContext).user_data.business_group_key 

    let showEventType = whitelist_ck_show_banking_alert_event_type.includes(company_key)

    return (
        <div className="listCardHeader">
            {showEventType ?
                <div 
                    className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                    style={{flex:"1 1 100px", textAlign:"center"}}
                >
                    Tipo do Evento
                </div> 
                :
                null
            }
            {business_group_key&&
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                style={{flex:"2 1 180px", textAlign:"center"}}
            >
                Companhia
            </div>}
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                style={{flex:"2 1 180px", textAlign:"center"}}
            >
                Chave do Alerta
            </div>
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                style={{flex:"1 1 45px", textAlign:"center"}}
            >
                Valor
            </div>
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                style={{flex:"1 1 45px", textAlign:"center"}}
            >
                Tipo de Entidade
            </div>
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                style={{flex:"1 1 45px", textAlign:"center"}}
            >
                Tipo do Alerta
            </div>
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                style={{flex:"1 1 80px", textAlign:"center"}}
            >
                Gatilhos
            </div>
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                style={{flex:"1 1 45px", textAlign:"center"}}
            >
                Severidade
            </div>
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                style={{flex:"1 1 45px", textAlign:"center"}}
            >
                Status
            </div>
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                style={{flex:"1 1 110px", textAlign:"center"}}
            >
                Data do Alerta
            </div>
        </div>
    )
}

export default CardListHeader