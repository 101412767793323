import React, { useState, useEffect} from 'react';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import moment from "moment";
import 'react-dates/lib/css/_datepicker.css';
import IconButton from "@material-ui/core/IconButton";
import FastRewindIcon from '@material-ui/icons/FastRewind';
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import InputMask from "react-input-mask";
import MenuItem from '@material-ui/core/MenuItem';
import CurrencyInput from 'react-currency-input';
import { useTranslation } from "react-i18next";

function Filter(props) {
	
    const [focus, setFocus] = useState(null)
    const {t} = useTranslation()

    useEffect(() => {
        moment.locale('pt');
        moment.updateLocale('pt', {
            months : [
                "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho",
                "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
            ]
        });
        moment.updateLocale('pt', {
            weekdaysMin : [
                "Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"
            ]
        });
    })

    const handleFocusChange = (focus) =>{
        if (focus === null && (props.value.initialDate || props.value.finalDate)){
            props.onBlur()
        }
        setFocus(focus)
    }

    const handleDateClear = (focus) =>{
        if (!props.value.initialDate && !props.value.finalDate) return
        props.onChange({initial_date_value:null, final_date_value:null})
        props.onBlur()
    }

    const getMomentDate = (date, nullCase) =>{
        if (date == null) return nullCase
        else return moment(date)
    }

    const brazilianDocumentMaskTranformer = (e) => {
        let {nextState} = e
        let {previousState} = e
        let {currentState} = e
        let {value} = nextState
        let {selection} = nextState
        let {enteredString} = nextState

        if (!(previousState && currentState)) return {...nextState,value}
        if (value.length !== 14) return {...nextState,value}
        value = currentState.value.replace(/\D/g,'');
        let formatCPF = true
        if (value.length === 12 && enteredString === "") formatCPF = true     
        else if (value.length > 11) formatCPF = false       
        if(formatCPF){
            let value1 = value.slice(0,3)
            let value2 = value.slice(3,6)
            let value3 = value.slice(6,9)
            let value4 = value.slice(9,11)
            value = value1+"."+value2+"."+value3+"-"+value4
        }
        else{
            let value1 = value.slice(0,2)
            let value2 = value.slice(2,5)
            let value3 = value.slice(5,8)
            let value4 = value.slice(8,12)
            let value5 = value.slice(12,14)
            value = value1+"."+value2+"."+value3+"/"+value4+"-"+value5
            selection = {start: 16, end:16}
        }
        return {
            ...nextState,
            selection,
            value
        }
    }
       

    if (props.filter_type.type === "masked_string") {
        return (
          <div className={props.filter.error === true ? "filterContainerError" : "filterContainer"}>
          <div className="filterDescription">{props.filter.description}</div>
          <InputMask
            onKeyPress= {(e) => {if (e.key === 'Enter') {props.onBlur()}}}
            disabled={props.filter.disabled}
            mask={props.filter_type.maskType}
            maskPlaceholder="" 
            onBlur={props.onBlur} 
            onChange={props.onChange} 
            value={props.value}>
              <TextField className="filterTextbox" size="small" fullWidth />
            </InputMask>
          <IconButton style={{float: "right"}} size="small" onClick={props.onClose}>
            <CloseIcon fontSize="small"/>
          </IconButton>
        </div>
        )
    }
    else if (props.filter_type.type === "brazilian_document_numbers") {
        let maskType = props.value.length > 14 ? "99.999.999/9999-99" : "999.999.999-99"
        return (
          <div className={props.filter.error === true ? "filterContainerError" : "filterContainer"}>
          <div className="filterDescription">{props.filter.description}</div>
          <InputMask
            onKeyPress= {(e) => {if (e.key === 'Enter') {props.onBlur()}}}
            disabled={props.filter.disabled}
            beforeMaskedStateChange={brazilianDocumentMaskTranformer}
            mask={maskType}
            maskPlaceholder="" 
            onBlur={props.onBlur} 
            onChange={props.onChange} 
            value={props.value}>
              <TextField className="filterTextbox" size="small" fullWidth />
            </InputMask>
          <IconButton style={{float: "right"}} size="small" onClick={props.onClose}>
            <CloseIcon fontSize="small"/>
          </IconButton>
        </div>
        )
    }
    else if (props.filter_type.type === "numeric") {
        return (
            <div className="filterContainer">
                <div className="filterDescription">{props.filter.description}</div>
                <TextField
                    onKeyPress= {(e) => {if (e.key === 'Enter') {props.onBlur()}}}
                    disabled={props.filter.disabled}
                    onBlur={props.onBlur} 
                    onChange={props.onChange} 
                    className="filterTextbox" 
                    size="small" 
                    value={props.value}
                    type="number"
                    pattern="[0-9]*"
                    fullWidth  />
                <IconButton style={{float: "right"}} size="small" onClick={props.onClose}>
                    <CloseIcon fontSize="small"/>
                </IconButton>
            </div>
        )

    }
    else if (props.filter_type.type === "value") {
        return (
          <div className={props.filter.error === true ? "filterContainerError" : "filterContainer"}>
          <div className="filterDescription">{props.filter.description}</div>
            <CurrencyInput 
                precision="2"
                decimalSeparator="," 
                thousandSeparator="." 
                prefix="R$ "
                onKeyPress= {(e) => {if (e.key === 'Enter') {props.onBlur()}}}
                disabled={props.filter.disabled}
                onBlur={props.onBlur} 
                onChangeEvent={props.onChange} 
                className="filterTextboxCurrency" 
                size="small" 
                value={props.value}/>
          <IconButton style={{float: "right"}} size="small" onClick={props.onClose}>
            <CloseIcon fontSize="small"/>
          </IconButton>
        </div>
        )
    }
    else if (props.filter_type.type === "enum") {
        return (
            <div className="filterContainer">
                <div className="filterDescription">{props.filter.description}</div>
                <TextField 
                    disabled={props.filter.disabled}
                    style={{textAlign:"center"}} 
                    onBlur={props.onBlur} 
                    onChange={props.onChange} 
                    className="filterTextbox" 
                    size="small" fullWidth 
                    value={props.value} 
                    select
                >
                    {props.filter_type.enumList.map((enumList, index)=>(
                        <MenuItem key={index} value={enumList[1]}><em className="normalText">{t(enumList[0])}</em></MenuItem>
                    ))}
                </TextField>
                <IconButton style={{float: "right"}} size="small" onClick={props.onClose}>
                    <CloseIcon fontSize="small"/>
                </IconButton>
            </div>
        )
    }
    else if (props.filter_type.type === "date"){
        return (
          <div className="filterContainer">
            <div className="filterDescription">{props.filter.description}</div>
            <div>
                <DateRangePicker
                    disabled={props.filter.disabled}
                    startDatePlaceholderText={"Início"}
                    endDatePlaceholderText={"Final"}
                    startDate={getMomentDate(props.value.initialDate, null)}
                    startDateId="initial_date_id"
                    endDate={getMomentDate(props.value.finalDate, null)}
                    endDateId="final_date_id"
                    onDatesChange={({ startDate, endDate }) => props.onChange({ initial_date_value:startDate, final_date_value:endDate })}
                    focusedInput={focus}
                    onFocusChange={focus => handleFocusChange(focus)}
                    showDefaultInputIcon={false}
                    isOutsideRange={day => (moment().diff(day.startOf("day")) < 0)}
                    minimumNights={0}
                    small={true}
                    displayFormat={() => "DD/MM"}
                    readOnly={true}
                />
                <div style={{float: "right", margin: "8px 0 0 0"}}>
                    <IconButton onClick={handleDateClear} size="small">
                        <FastRewindIcon fontSize="small"/>
                    </IconButton>
                </div>
            </div>
            <IconButton style={{float: "right"}} size="small" onClick={props.onClose}>
              <CloseIcon fontSize="small"/>
            </IconButton>
          </div>
        )
    } 
    else {
        return (
            <div className="filterContainer">
                <div className="filterDescription">{props.filter.description}</div>
                <TextField
                onKeyPress= {(e) => {if (e.key === 'Enter') {props.onBlur()}}}
                disabled={props.filter.disabled}
                onBlur={props.onBlur} 
                onChange={props.onChange} 
                className="filterTextbox" 
                size="small" 
                value={props.value}
                fullWidth  />
                <IconButton style={{float: "right"}} size="small" onClick={props.onClose}>
                    <CloseIcon fontSize="small"/>
                </IconButton>
            </div>
        )
    }
}
  
  export default Filter;