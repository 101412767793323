import React from "react";
import { quiz_score_translation } from "../../utils/utils";

function QuizScoreBox(props) {

    let { quiz_score } = props
    
    return (
        <div className="analysisCard" style={{ display: "flex", flexDirection: "column", flexGrow:"1"}}>
            <div className={["blueText", "subtitleSize"].join(" ")}>Quiz</div>
            <div style={{ display: 'flex', flexDirection:"row", alignItems:"center"}}>
                <div 
                    className={["normalText", "normalMediumSize"].join(" ")} 
                    style={{display: 'flex', flexDirection:"row", justifyContent: "center"}}
                >
                    Score do Quiz:
                </div>
                <div 
                    className="normalMediumSize"
                    style={{margin: "5px 0px", padding:"5px"}}
                >
                    <span className="labelText">{quiz_score.score}</span>
                    <span className="labelText">{" /1000"}</span>
                    <span className="normalText" style={{margin: "5px", padding:"5px", borderRadius:"4px"}}>{quiz_score_translation[quiz_score.result_enum].description}</span>
                </div>
            </div>
        </div>
    )
}

export default QuizScoreBox;