import React from "react"
import TopSecondaryBarItem from "./TopSecondaryBarItem/TopSecondaryBarItem"
import { useTranslation } from "react-i18next";

export default function TopSecondaryBar (props) {
    
    const { selectedProduct, pages, routes, selectedPage, setSelectedPage, allowedPages } = props
    const { t } = useTranslation();

    if (pages[selectedProduct]) {
        return (
            <nav className="topSecondaryBar">
                {pages[selectedProduct]&&
                pages[selectedProduct].map((page)=>(
                    allowedPages.includes(page)&&
                    <TopSecondaryBarItem
                        key={page}
                        page={page}
                        selectedPage={selectedPage}
                        setSelectedPage={setSelectedPage}
                        routes={routes}
                    />
                ))}
                <div style={{ fontFamily: 'Open Sans', flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                    <div style={{fontWeight: 'bold', color: '#0b1f82', marginRight: '4px'}}>
                        {t('Dúvidas')}?
                    </div>
                    {t('Acesse a')}
                    <a style={{marginLeft: "4px"}} href="https://39563484.hs-sites.com/pt-br/help-center-qi-tech" target="_blank" rel="noopener noreferrer">
                        {t('central de conhecimento')}
                    </a>
                </div>
            </nav>
        )
    } else {
        return (
        <nav className="topSecondaryBar">
        </nav>
        )
    }
}



