import React from 'react'
import complianceLogo from '../../../assets/cardEntryIcons/compliance.svg';
import fraudLogo from '../../../assets/cardEntryIcons/fraud.svg';
import merchantGreyLogo from '../../../assets/cardEntryIcons/MerchantIconGrey.svg';
import peopleGreyLogo from '../../../assets/cardEntryIcons/peopleGrey.svg';
import {checkNested,titleCase, formatCard, formatAddress} from "./utils"
import moment from "moment"
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import Tooltip from "@material-ui/core/Tooltip";

//Translators
export const getSeverityStatus = (status) => {
    switch(status){
        case "low":
            return "Baixo Risco"
        case "medium":
            return "Médio Risco"
        case "high":
            return "Alto Risco"
        case "critical":
            return "Crítico"
        default:
            return status
    }
}
export const getSeverityStatusSiglum = (status) => {
    switch(status){
        case "low":
            return "B"
        case "medium":
            return "M"
        case "high":
            return "A"
        case "critical":
            return "C"
        default:
            return "?"
    }
}
export const getAlertStatusText = (status) => {
    switch(status){
        case "pending":
        case "in_manual_analysis":
            return "Pendente"
        case "blocked":
            return "Bloqueado"
        case "solved_true_positive":
        case "solved_false_positive":
            return "Tratado"
        case "silenced":
            return "Silenciado"
        default:
            return status
    }
}

export const getAlertStatusTextSiglum = (status) => {
    switch(status){
        case "pending":
        case "in_manual_analysis":
            return "P"
        case "blocked":
            return "B"
        case "solved_true_positive":
        case "solved_false_positive":
            return "T"
        case "silenced":
            return "S"
        default:
            return "?"
    }
}

//Color Utils
const colorGreen = '#73C580'
const colorRed ='#D91A1A'
const colorYellow = "#FFBB24"
const colorOrange = '#F77B21'
const colorVelvet ='#DD5C5C'
const colorGrey = 'rgb(170, 167, 153)'

export const getSeverityColor = (status) => {
    switch(status){
        case "low":
            return colorYellow
        case "medium":
            return colorOrange
        case "high":
            return colorVelvet
        case "critical":
            return colorRed
        default:
            return colorGrey
    }
}

export const getAlertStatusColor = (status) => {
    switch(status){
        case "pending":
        case "in_manual_analysis":
            return colorYellow
        case "blocked":
            return colorRed
        case "solved_true_positive":
        case "solved_false_positive":    
            return colorGreen
        case "silenced":
        default:
            return colorGrey
    }
}

//Trigger Utils
export function getTriggerRisk (array){
    let allCategories = array.map(function(trigger) {
        return trigger.category;
      });
    let uniqueItems = Array.from(new Set(allCategories))
    
    let changedArray = uniqueItems.map(function(category){
        let filteredObj = array.filter((trigger)=>{
            return trigger['category'].includes(category);
        });
        let workedRiskObj = filteredObj.reduce((acc, trigger) => {
            acc.allRisks.push(trigger.risk_level)
            acc.allTerminals.push((trigger.transaction || {}).terminal_id)
            return acc
        }, {
            allRisks: [],
            allTerminals: []
        })

        let allTerminals = workedRiskObj.allTerminals.filter(function(terminal) {
            return terminal != null;
        });
        
        return [category, getGreaterRisk(workedRiskObj.allRisks), allTerminals]
    })
    var riskObject = {};
    changedArray.forEach(function(data){
        riskObject[data[0]] = data[1]
        riskObject['terminals'] = data[2]
    });
    let maximizedTrigger = array.map(function(trigger) {
        let category = trigger.category
        return {...trigger, terminalList: riskObject['terminals'], risk_level: riskObject[category]}
    });
    return maximizedTrigger
}

export const getTerminalsList = (triggers) => {
    let allTerminals = triggers.reduce((acc, trigger) => {
        acc.push((trigger.transaction || {}).terminal_id)
        return acc
    }, [])

    allTerminals = allTerminals.filter(function(terminal) {
        return terminal != null;
    });

    return allTerminals
}

const getGreaterRisk = (risk_level) => {
    if (risk_level.includes("critical")){
        return "critical"
    }
    else if (risk_level.includes("high")){
        return "high"
    }
    else if (risk_level.includes("medium")){
        return "medium"
    }
    else {
        return "low"
    }
}

//Map Utils
export async function formatMerchantMapObj (transactions){
    const provider = new OpenStreetMapProvider();
    let merchantMapObj = transactions.map(async function(transaction){
        let companyName = titleCase(transaction.company_name, "-")
        let merchantId = checkNested(transaction, "merchant", "merchant_id") ? transaction.merchant.merchant_id : "-"
        let name = checkNested(transaction, "merchant", "name") ? transaction.merchant.name : "-"
        let card = checkNested(transaction, "card", "bin") & checkNested(transaction, "card", "last4") ? 
            formatCard(transaction.card.bin, transaction.card.last4) : "-"
        let authorizationDate = transaction.authorization_date == null ? "-" : moment(transaction.authorization_date).format("DD/MM/YYYY, HH:mm:ss")
        let amount = transaction.amount == null ? "-" : "R$ " + (transaction.amount/100).toLocaleString('de-DE', {minimumFractionDigits: 2})
        let address = transaction.merchant ==null ? null : formatAddress(transaction.merchant)
        const result = await provider.search({ query: address})
        if (result.length === 0){
            return null
        }
        let obj = {
            company_name: companyName,
            merchant_id: merchantId,
            name: name,
            payment_card: card,
            authorization_date: authorizationDate,
            amount: amount,
            coordinates:[result[0].y, result[0].x]
        }
        return obj
    })
    return Promise.all(merchantMapObj)       
}

export function replaceAll(str,mapObj){
    var re = new RegExp(Object.keys(mapObj).join("|"),"gi");

    return str.replace(re, function(matched){
        return mapObj[matched.toLowerCase()];
    });
}

//General Utils

export const isOpenAlert = (status) => {
    switch(status){
        case "pending":
        case "in_manual_analysis":
            return true
        default:
            return false
    }
}

export const setAllowChanges = (status, roles) => {
    if (!roles.includes("card_issuance_alert_analyzer")){
        return false
    }
    switch(status){
        case "pending":
        case "in_manual_analysis":
            return true
        default:
            return false
    }
}

export const formatIrregularity = (irregularity_type, decrease=false, asTooltip=false) => {
    let text
    let icon    
    switch (irregularity_type) {
        case "compliance":
            text = "Compliance"
            icon = complianceLogo
            break
        case "fraud":
            text = "Fraude"
            icon = fraudLogo
            break
        default:
            return "-"
    }
    if (asTooltip){
        return (
            <div style={{display:"flex", justifyContent:"center"}}>
                <Tooltip title={<p className="tooltipText">{text}</p>}>
                    <img style={decrease ? {width:"16px"} : null} src={icon} alt="irregularity Logo" />
                </Tooltip>
            </div>

        )
    }
    else{
        return(
            <div style={{display:"flex", justifyContent:"center"}}>
                <img style={decrease ? {width:"16px"} : null} src={icon} alt="irregularity Logo" />
                <div className="labelSpace">{text}</div>
            </div>
        )
    } 
}

export const formatListCardName = (link_type, transaction) => {
    if (transaction == null) return "-"
    let logo = null
    let name = "-"
    switch (link_type) {
        case "merchant":
            logo = merchantGreyLogo
            name = checkNested(transaction, "merchant", "name") ? transaction.merchant.name : "-"
            break
        case "cardholder":
            logo = peopleGreyLogo
            name = checkNested(transaction, "enriched_data", "cardholder_name") ? 
                   transaction.enriched_data.cardholder_name : 
                   checkNested(transaction, "enriched_data", "cardholder_trading_name") ?
                   transaction.enriched_data.cardholder_trading_name : 
                   "-"
            break
        default:
            break
    }
    return(
        <div style={{display:"flex", justifyContent:"center"}}>
            <img src={logo} alt="name Logo" />
            <div className="labelSpace">{name}</div>
        </div>
    )
}

export const getAlertStatusDecision = (irregularity_type, lock_key, complianceReport, dialog) => {
    let alert_status_decision
    if (irregularity_type === "compliance"){
        alert_status_decision = complianceReport ? "solved_true_positive" : "solved_false_positive"
    }
    else {
        if (dialog === "lock"){
            alert_status_decision = "solved_true_positive"
        }
        else if (dialog === "unlock"){
            alert_status_decision = "solved_false_positive"
        }
        else {
            alert_status_decision = lock_key ? "solved_true_positive" : "solved_false_positive"
        }
    }
    return alert_status_decision
}

export const getPayload = (alert) => {
    if (alert.link_key_type === "cardholder"){
        return {
            cardholder_id: alert.cardholder_id,
            bin: alert.bin,
            last4: alert.last4
        }
    }
    else {
        let payload = {
            merchant_id: alert.merchant_id,
            acquirer_id: alert.acquirer_id
        }
        if (((alert.link_key_data || {}).merchant || {}).name){
            payload['alert_metadata'] = {
                merchant_name: ((alert.link_key_data || {}).merchant || {}).name
            }
        }
        return payload
    }
}








