import React, { useEffect, useReducer, useCallback, useContext, useState } from "react";
import SectionContainer from "../../@GeneralComponents/SectionContainer";
import AuthContext from "../../../context/auth-context";
import { useTranslation } from "react-i18next";
import { default_roles_groups } from "../utils";
import RolesGroups from "./RolesGroups/RolesGroups";
import { CircularProgress } from '@material-ui/core'
import ErrorBoundary from "../../Utils/ErrorBoundary";
import axios from "axios";
import SingleSelectDropdown from "../SingleSelectDropdown/SingleSelectDropdown";
import {decisionReducer} from "../../Utils/requestUtils";
import { removeDuplicatesFromArrayOfObjects } from "../../Utils/utils";
import Button from "../../@GeneralComponents/Button";
import ConfirmChangeGroup from "../Dialogs/ConfirmChangeGroup/ConfirmChangeGroup";
import CreateGroupPermissions from "../Dialogs/CreateGroupPermissions/CreateGroupPermissions";
import EditGroupPermissions from "../Dialogs/EditGroupPermissions/EditGroupPermissions";
import * as S from './styles'
import {
  dataFetchReducer
} from "../../Utils/requestUtils";

const UserRolesManagement = (props) => {

  const [permissionsToRemove, setPermissionsToRemove] = useState({roles: [], company_key: ""})
  const [permissionsToAdd, setPermissionsToAdd] = useState({roles: [], company_key: ""})
  const [refresher, setRefresher] = useState(false)

  const { t } = useTranslation();

  let user_data = useContext(AuthContext).user_data;
  let user_company_key = user_data.company_key
  let user_business_group_key = user_data.business_group_key
  let user_roles = user_data.roles;

  const [editGroupDialogOpened, setEditGroupDialogOpened] = useState(false);
  const [createGroupDialogOpened, setCreateGroupDialogOpened] = useState(false);

  const showReadOnboardingNaturalFiltered = {
    company: [
      '123-123-123', // Zaig - local
      '09100019-9471-4755-96e8-bc1c1fb46136', // Master PD
      'b81a015d-640e-4d11-8921-1ab46f168c8d', // Zaig PD
      '64520138-3e5b-4c2a-856a-33e1b199b0cb' // Zaig SB
    ],
    business_group: [
      '456-456-456', // Zaig - local
      'c00b7063-5777-4eb2-9153-a06eaf52971b' // Master PD
    ]
  }
  
  const isWhitelist = showReadOnboardingNaturalFiltered.company.includes(user_company_key) || showReadOnboardingNaturalFiltered.business_group.includes(user_business_group_key)
  
  const [listsOptions, setListsOptions] = useState([])
  const [listsLoaded, setListsLoaded] = useState(false)
  const [eventsIsLoaded, setEventsIsLoaded] = useState(false)
  const [rulesViewerOptions, setRulesViewerOptions] = useState([])

  const [selectedListsToReadList, setSelectedListsToReadList] = useState(undefined)
  const [selectedListsToCreateItem, setSelectedListsToCreateItem] = useState(undefined)
  const [selectedListsToDeleteItem, setSelectedListsToDeleteItem] = useState(undefined)
  const [selectedListsToReadItem, setSelectedListsToReadItem] = useState(undefined)
  const [selectedListsToUpdateItem, setSelectedListsToUpdateItem] = useState(undefined)

  const [pageState, dispatchPageState] = useReducer(dataFetchReducer, {
    fetchedData: null,
    isLoading: true,
    isError: false,
  });

  const doRequest = useCallback(
    () => {
      let params = {}
      if (props.user.company_key) params["company_key"] = props.user.company_key;
      dispatchPageState({type: "data_fetch_init"})
        axios.get(`/dash/user/${props.user.email}/roles`, {params})
        .then(response=>{
            dispatchPageState({
                type: "data_fetch_success",
                payload: response.data
            })          
        }).catch(error => {
            if ((error.response || {}).status === 403) dispatchPageState({type: "data_fetch_failure_403"})
            else if ((error.response || {}).status === 404) dispatchPageState({type: "data_fetch_failure_404"})  
            else dispatchPageState({type: "data_fetch_failure"})    
        })
    },[props.user.email, props.user.company_key]
  )

  const updateUserPermissions = async () => {
    let status_codes = []
    if (permissionsToAdd.roles.length > 0) {
      await axios.post("/dash/user/" + props.user.username + "/roles", permissionsToAdd).then((response) => {status_codes.push(response.status)}).catch((error) => {console.log(JSON.stringify(error))});
    }
    if (permissionsToRemove.roles.length > 0) {
      await axios.delete("/dash/user/" + props.user.username + "/roles", { data: permissionsToRemove }).then((response) => {status_codes.push(response.status)}).catch((error) => {console.log(JSON.stringify(error))});
    }
    if (status_codes.length > 0) {
      setRefresher(!refresher);
      setPermissionsToAdd({roles: [], company_key: ""})
      setPermissionsToRemove({roles: [], company_key: ""})
      
      if (status_codes.every((element) => element === 200 )) {
        props.snackbarSetter(true);
      }
    }
  }


  
  useEffect(() => {
    const timer_ = setTimeout(() => {
        doRequest()
    }, 300);
    return () => {
      clearTimeout(timer_)
    }	
  },[doRequest, refresher])
  
  useEffect(()=>{
    if (eventsIsLoaded) return;
    const eventsEndpoint = '/dash/rule_engine/event_types'
    axios
    .get(eventsEndpoint)
    .then(response=>{
        let updatedRulesViewerOption = []
        for (const eventType of response.data) {
            if (["bankslip", "bankslip_alert", "bankslip_expiration"].includes(eventType.enum)) {
              updatedRulesViewerOption.push(
                {
                  "name": "read_rules_bankslip",
                  "description": "Visualizar regras relativas a boletos.",
                }
                )
              }
            if (["pix_recipient_list_append", "pix_recipient_list_limit_update_request", "pix_limit_update_request"].includes(eventType.enum)) {
                updatedRulesViewerOption.push(
                  {
                    "name": "read_rules_pix_limits",
                    "description": "Visualizar regras relativas a limites PIX."
                  }
                )
              }
            if (["bill_payment", "bill_payment_alert", "bill_payment_expiration"].includes(eventType.enum)) {
              updatedRulesViewerOption.push(
                {
                  "name": "read_rules_bill_payment",
                  "description": "Visualizar regras relativas a pagamento de contas.",
                }
                )
                }
            if (["card_order_alert", "card_order_order"].includes(eventType.enum)) {
              updatedRulesViewerOption.push(
                {
                  "name": "read_rules_card_order",
                  "description": "Visualizar regras relativas a pedidos.",
                }
                )
              }
            if (["car_rental_rental_agreement", "car_rental_rental_agreement_expiration", "car_rental_rental_agreement_suggestion", "car_rental_reservation"].includes(eventType.enum)) {
              updatedRulesViewerOption.push(
                {
                  "name": "read_rules_car_rental",
                  "description": "Visualizar regras relativas a aluguel de carros.",
                }
                )
            }
            if (["credit_analysis_legal_person", "credit_analysis_legal_person_manual_analysis"].includes(eventType.enum)) {
              updatedRulesViewerOption.push(
                {
                  "name": "read_rules_credit_analysis_legal_person",
                  "description": "Visualizar regras relativas à análise de crédito de pessoa jurídica.",
                }
                )
              }
            if (["credit_analysis_natural_person", "credit_analysis_natural_person_manual_analysis"].includes(eventType.enum)) {
              updatedRulesViewerOption.push(
                {
                  "name": "read_rules_credit_analysis_natural_person",
                  "description": "Visualizar regras relativas à análise de crédito de pessoa física.",
                }
                )
              }
            if (["currency_exchange_operation", "currency_exchange_operation_alert"].includes(eventType.enum)) {
              updatedRulesViewerOption.push(
                {
                  "name": "read_rules_currency_exchange",
                  "description": "Visualizar regras relativas a câmbio.",
                }
                )
              }
            if (["onboarding_legal_person", "onboarding_legal_person_manual_analysis", "onboarding_legal_person_partner"].includes(eventType.enum)) {
              updatedRulesViewerOption.push(
                {
                  "name": "read_rules_onboarding_legal_person",
                  "description": "Visualizar regras relativas a cadastro de pessoa jurídica.",
                }
                )
              }
            if (["onboarding_natural_person", "onboarding_natural_person_manual_analysis", "onboarding_natural_person_partner", "onboarding_natural_person_reanalysis"].includes(eventType.enum)) {
              updatedRulesViewerOption.push(
                {
                  "name": "read_rules_onboarding_natural_person",
                  "description": "Visualizar regras relativas à cadastro de pessoa física.",
                }
                )
              }
            if (["pix_dict_operation", "pix_dict_operation_alert", "pix_dict_operation_expiration"].includes(eventType.enum)) {
              updatedRulesViewerOption.push(
                {
                  "name": "read_rules_pix_dict_operation",
                  "description": "Visualizar regras relativas à operações de chave pix.",
                }
                )
              }
            if (["pix_transaction", "pix_transaction_alert", "pix_transaction_expiration"].includes(eventType.enum)) {
              updatedRulesViewerOption.push(
                {
                  "name": "read_rules_pix_transaction",
                  "description": "Visualizar regras relativas à transações pix.",
                }
                )
              }
            if (["wire_transfer", "wire_transfer_alert", "wire_transfer_expiration"].includes(eventType.enum)) {
              updatedRulesViewerOption.push(
                {
                  "name": "read_rules_wire_transfer",
                  "description": "Visualizar regras relativas à transferências bancárias.",
                }
                )
              }
            if (["withdrawal", "withdrawal_alert"].includes(eventType.enum)) {
              updatedRulesViewerOption.push(
                {
                  "name": "read_rules_withdrawal",
                  "description": "Visualizar regras relativas a saques.",
                }
                )
              }
                                                                                                                                                                                                      
      updatedRulesViewerOption = removeDuplicatesFromArrayOfObjects(updatedRulesViewerOption)
      setRulesViewerOptions(updatedRulesViewerOption)
      setEventsIsLoaded(true)
            }
    })
  },[props.user, eventsIsLoaded])

  useEffect(()=>{
    if (listsLoaded) return;
    let listsEndpoint = '/dash/lists/lists?page_rows=100000000'
    if (props.user.company_key) {
      listsEndpoint = '/dash/lists/lists?page_rows=100000000&company_key='+props.user.company_key
    }
    axios
    .get(listsEndpoint)
    .then(response=>{
      let lists=response.data.data
      let lists_keys = []
      lists.forEach(function(obj) {
        lists_keys.push(obj.list_key)
      })
      setListsOptions(lists.filter((list)=>((list.is_owner===true)||((list.is_owner===false)&&(list.can_read===true))||((list.is_owner===false)&&(list.can_write===true)))))
      let listsPermissionsEndpoint = `/dash/lists/list_user_permission/${props.user.email}`
      if (props.user.company_key) {
        listsPermissionsEndpoint = `/dash/lists/list_user_permission/${props.user.email}?company_key=${props.user.company_key}`
      }
      axios
      .get(listsPermissionsEndpoint)
      .then(response=>{
        let listsPermissions = response.data
        let selectedInitialState = {
          read_list: [],
          create_item: [],
          delete_item: [],
          read_item: [],
          update_item: []
        }
        listsPermissions.forEach(function(obj) {
          if (lists_keys.includes(obj.list_key)) {
            if ((obj.permissions).includes("read_list")) {
              selectedInitialState.read_list.push(obj.list_key)
            }
            if ((obj.permissions).includes("create_item")) {
              selectedInitialState.create_item.push(obj.list_key)
            }
            if ((obj.permissions).includes("delete_item")) {
              selectedInitialState.delete_item.push(obj.list_key)
            }
            if ((obj.permissions).includes("read_item")) {
              selectedInitialState.read_item.push(obj.list_key)
            }
            if ((obj.permissions).includes("update_item")) {
              selectedInitialState.update_item.push(obj.list_key)
            }
          }
        })
        setSelectedListsToReadList(selectedInitialState.read_list)
        setSelectedListsToCreateItem(selectedInitialState.create_item)
        setSelectedListsToDeleteItem(selectedInitialState.delete_item)
        setSelectedListsToReadItem(selectedInitialState.read_item)
        setSelectedListsToUpdateItem(selectedInitialState.update_item)
        setListsLoaded(true)
      })
    })
  },[props.user, listsLoaded])
  
  const listsToggleOption = ({permission_name, list_key}) => {
    let newSelected = prevSelected => {
      const newArray = [...prevSelected]
      if (newArray.includes(list_key)) {
        return newArray.filter(item => item !== list_key)
      } else {
        newArray.push(list_key)
        return newArray;
      }
    }
    if (permission_name==="read_list") {
      setSelectedListsToReadList(newSelected)
    } else if (permission_name==="create_item") {
      setSelectedListsToCreateItem(newSelected)
    } else if (permission_name==="delete_item") {
      setSelectedListsToDeleteItem(newSelected)
    } else if (permission_name==="read_item") {
      setSelectedListsToReadItem(newSelected)
    } else if (permission_name==="update_item") {
      setSelectedListsToUpdateItem(newSelected)
    }
  }

  const getInitialState = (role_name) => {
    if (pageState.fetchedData.roles.includes(role_name)) {
      return true;
    } else {
      return false;
    }
  };

  const [checkedListsViewer, setCheckedListsViewer] = useState(true)

  const [saveLoading, setSaveLoading] = useState(false)
  const [confirmChangeDialogOpened, setConfirmChangeDialogOpened] = useState(false);
  
  const saveLists = (
    listsOptions,
    selectedListsToReadList,
    selectedListsToCreateItem,
    selectedListsToDeleteItem,
    selectedListsToReadItem,
    selectedListsToUpdateItem
    ) => {
      let payload = []
      listsOptions.forEach(function(option){
      let obj = {
        "list_key": option.list_key,
        "permissions": []
      }
      if (selectedListsToReadList.includes(option.list_key)) {
        obj["permissions"].push("read_list")
      } 
      if (selectedListsToCreateItem.includes(option.list_key)) {
        obj["permissions"].push("create_item")
      } 
      if (selectedListsToDeleteItem.includes(option.list_key)) {
        obj["permissions"].push("delete_item")
      } 
      if (selectedListsToReadItem.includes(option.list_key)) {
        obj["permissions"].push("read_item")
      } 
      if (selectedListsToUpdateItem.includes(option.list_key)) {
        obj["permissions"].push("update_item")
      }
      payload.push(obj)
    })
    setSaveLoading(true)
    axios
    .put('/dash/lists/list_user_permission/'+props.user.email, payload)
    .finally(() => setSaveLoading(false))
  }

  let roles_groups = default_roles_groups
  const rolesGroupSortedAlphabetically = roles_groups.sort((a, b) => a.name === b.name ? 0 : a.name > b.name ? 1 : -1)
  let allowedRolesGroups = rolesGroupSortedAlphabetically.filter(roles_group => user_roles.includes(roles_group['manager_role']))

  const initialGroup = (props.user.groups.length===0?"choose_permissions_separately":props.user.groups[0])
  const [selectedGroup, setSelectedGroup] = useState(initialGroup)

  const transformGroupName = (groupName) => {
    if (groupName==="choose_permissions_separately") {
      return t("choose_permissions_separately")
    }
    const words = groupName.split("_");
    const firstWordCapitalized = words[0].charAt(0).toUpperCase() + words[0].slice(1);
    words.shift()
    return `${t("group")} - ${firstWordCapitalized} ${words.join(" ")}`;
  }
  
  const [saveSelected, dispatchSaveSelected] = useReducer(
    decisionReducer,
    {isLoading: false, isError: false, finished:false}
  )

  const onSaveSelected = () => {
    if (initialGroup==="choose_permissions_separately"&&selectedGroup==="choose_permissions_separately"){
      return
    }
    else if (initialGroup!=="choose_permissions_separately"&&selectedGroup==="choose_permissions_separately"){
      setTimeout(function () {
        dispatchSaveSelected({type: "send_request_init"}) 
        axios
        .delete(`/dash/group/${initialGroup}/user/${props.selectedUserEmail}`)
        .then(()=>{
          dispatchSaveSelected({type: "send_request_success"})
          props.onUpdateGroups()
          props.onUpdate()
          setSelectedGroup(selectedGroup)
          setConfirmChangeDialogOpened(false)
        })
        .catch(error => {
            if ((error.response || {}).status === 403) dispatchSaveSelected({type: "send_request_failure_403"})
            else dispatchSaveSelected({type: "send_request_failure"})   
        })
      },1000)
    } else {
      setTimeout(function () {
        dispatchSaveSelected({type: "send_request_init"}) 
        axios
        .put(`/dash/group/${selectedGroup}/user/${props.selectedUserEmail}`)
        .then(()=>{
          dispatchSaveSelected({type: "send_request_success"})
          props.onUpdateGroups()
          props.onUpdate()
          setSelectedGroup(selectedGroup)
          setConfirmChangeDialogOpened(false)
        })
        .catch(error => {
            if ((error.response || {}).status === 403) dispatchSaveSelected({type: "send_request_failure_403"})
            else dispatchSaveSelected({type: "send_request_failure"})   
        })
      },1000)
    }
  }

  if (pageState.fetchedData) {
    return (
      <>
      <div style={{display:"flex", flexDirection:"column"}}>
        <div className={["blueText", "subtitleSize"].join(" ")} style={{margin:"0px"}}>
            {`${t("manage_permissions")}`}
        </div>
        <div className="labelText normalMediumSize">
          {`${props.user.first_name} ${props.user.last_name} - CPF ${props.user.document_number} - ${props.user.email}`}
        </div>
        <SectionContainer
          sectionClasses="sectionContainer"
          isCollape={false}
        >
          <div className="userRolesManagementRowContainer">
            <div className="userRolesManagementContainer">
              <div className="userRolesManagementSubtitle">
                <div className="normalText normalMediumSize bold">
                  {t("choose_the_permission_method")}
                </div>
                <div className="labelText normalSmallSize">
                  {t("choose_the_permission_method_description")}
                </div>
              </div>
              <ErrorBoundary>
                <SingleSelectDropdown
                  groups={props.groups}
                  selected={selectedGroup}
                  initial={initialGroup}
                  setSelected={setSelectedGroup}
                  isLoading={saveSelected.isLoading}
                  optionTransform={transformGroupName}
                  initialSelection={"choose_permissions_separately"}
                />
              </ErrorBoundary>
            </div>
            <div className="groupsButtonContainer">
              <Button
                button_option="standard"
                onClick={() => setCreateGroupDialogOpened(true)}
                buttonLabel={t("create_group")}
                buttonStyle={{minWidth: "200px", margin: "5px 0px 5px auto"}}
              />
              <Button
                button_option="standard"
                onClick={() => setEditGroupDialogOpened(true)}  
                buttonLabel={t("edit_group")}
                buttonStyle={{minWidth: "200px", margin: "5px 0px 5px auto"}}
              />
              {initialGroup!==selectedGroup&&
              <Button
                button_option={"standard"}
                onClick={() => setConfirmChangeDialogOpened(true)}
                buttonLabel={t("save_group")}
                buttonStyle={{minWidth: "200px", margin: "5px 0px 5px auto"}}
                isLoading={saveSelected.isLoading}
              />}
            </div>
            <ConfirmChangeGroup
              open={confirmChangeDialogOpened}
              onClose={()=>setConfirmChangeDialogOpened(false)} 
              onSave={() => onSaveSelected()}
              saveSelected={saveSelected}
            />
            <CreateGroupPermissions
              open={createGroupDialogOpened}
              onClose={()=>setCreateGroupDialogOpened(false)} 
              onUpdateGroups={props.onUpdateGroups}
              onUpdate={props.onUpdate}
            />
            <EditGroupPermissions
              groups={props.groups}
              open={editGroupDialogOpened}
              onClose={()=>setEditGroupDialogOpened(false)}
              onUpdateGroups={props.onUpdateGroups}
              onUpdate={props.onUpdate}
              setSelectedGroup={setSelectedGroup}
            />
          </div>
        </SectionContainer>
        <SectionContainer
            sectionClasses="sectionContainer"
            isCollape={false}
        >
          <S.SectionHeading>
            <S.AlertAppend>
              <S.HeadingTitles>
                <div className="normalText normalMediumSize bold">
                  {t("user_permissions")}
                </div>
                <div className="labelText normalSmallSize" style={{marginBottom:"10px"}}>
                  {(initialGroup==="choose_permissions_separately")?t("user_permissions_choose_permissions_separately_description"):t("user_permissions_group_description")}
                </div>
              </S.HeadingTitles>
              <S.AlertBox isEditing={permissionsToAdd.roles.length > 0 || permissionsToRemove.roles.length > 0}>
                <p style={{fontSize: 16, lineHeight: 16}}>!</p> Alterações não salvas
              </S.AlertBox>
            </S.AlertAppend>
            <Button
                  button_option="standard"
                  onClick={() => updateUserPermissions()}
                  buttonLabel={t("update_permissions")}
                  buttonStyle={{minWidth: "200px", height: "fit-content"}}
            />
          </S.SectionHeading>
          
          {allowedRolesGroups.map((rolesGroup, index) =>
            <div key={rolesGroup.name} style={{display:"flex", flexDirection:"column"}}>
              <ErrorBoundary>
                <RolesGroups
                  permissionsToAdd={permissionsToAdd}
                  setPermissionsToAdd={setPermissionsToAdd}
                  permissionsToRemove={permissionsToRemove}
                  setPermissionsToRemove={setPermissionsToRemove}
                  initialGroup={initialGroup}
                  key={index}
                  rolesGroup={rolesGroup}
                  user={props.user}
                  saveLists={saveLists}
                  saveLoading={saveLoading}
                  listsStates={{
                    checkedListsViewer,
                    selectedListsToReadList,
                    selectedListsToCreateItem,
                    selectedListsToDeleteItem,
                    selectedListsToReadItem,
                    selectedListsToUpdateItem
                  }}
                  listsSetStates={{
                    setCheckedListsViewer,
                    setSelectedListsToReadList,
                    setSelectedListsToCreateItem,
                    setSelectedListsToDeleteItem,
                    setSelectedListsToReadItem,
                    setSelectedListsToUpdateItem
                  }}
                  listsOptions={listsOptions}
                  listsToggleOption={listsToggleOption}
                  listsLoaded={listsLoaded}
                  getInitialState={getInitialState}
                  isWhitelist={isWhitelist}
                  rulesViewerOptions={rulesViewerOptions}
                  eventsIsLoaded={eventsIsLoaded}
                  pageState={pageState}
                >
                </RolesGroups>
              </ErrorBoundary>
            </div>
          )}
        </SectionContainer>
      </div>
    </>
    );
  } else if (pageState.isError){
		return (
			<div style={{ width: "auto" }}>
                <div style={{height: "55vh", display:"flex"}}>
                    <p className={["blueText", "titleSize"].join(" ")} style={{margin:"auto", textAlign:"center"}}>
                    {pageState.errorMessage}
                    </p>
                </div>
			</div>
		)
	} else if (pageState.isLoading) {
		return(
			<div style={{ width: "auto" }}>
				<div className='circularProgressCenter'>
					<CircularProgress />
				</div>
			</div>
		)
  } else return null
	}

export default UserRolesManagement;