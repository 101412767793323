import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import DownloadCSVErrorDialog from "./DownloadCSVErrorDialog/DownloadCSVErrorDialog"
import CSVLimitSizeDialog from "./CSVLimitSizeDialog/CSVLimitSizeDialog"
import moment from 'moment'

function ExportCSVHistory (props) {
    
    const { t } = useTranslation();

    const { csv_endpoint, file_name } = props
   
    let [isLoadingCSV, setIsLoadingCSV] = useState(false); 
   
    const downloadCSV = () => {
		setIsLoadingCSV(true)
		axios({
			url: csv_endpoint,
			method: 'GET',
			responseType: 'blob',
		  }).then((response) => {
            setIsLoadingCSV(false);
            
			(response.headers['csv_line_number'] === response.headers['csv_limit_line_number']) 
            ?
            handleCSVLimitSizeDialogOpen()
            : 
            handleCSVLimitSizeDialogClose();

			let datetime = moment().format("YYYY-MM-DDTHH-mm-SS")
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', file_name + datetime + '.csv' );
			document.body.appendChild(link);
			link.click();
		  }).catch(error => {
			setIsLoadingCSV(false)
			handleDownloadCSVErrorDialogOpen()
		})
	}; 
   
	let [downloadCSVErrorDialog, setDownloadCSVErrorDialog] = useState(false); 

	const handleDownloadCSVErrorDialogOpen = () => {
		setDownloadCSVErrorDialog(true);
	};
	
	const handleDownloadCSVErrorDialogClose = () => {
		setDownloadCSVErrorDialog(false);
	};

	let [csvLimitSizeDialog, setCSVLimitSizeDialog] = useState(false); 

	const handleCSVLimitSizeDialogOpen = () => {
		setCSVLimitSizeDialog(true);
	};
	
	const handleCSVLimitSizeDialogClose = () => {
		setCSVLimitSizeDialog(false);
	};
		return (
			<div>
				{isLoadingCSV ? 
				<div 
					style={{ padding: "5px 20px", cursor: "wait"}}
					className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
				>
					{t("Carregando")}
				</div> 
				: 
				<div
					style={{ padding: "5px 30px", display:"flex", float: "right"}}
					className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
					onClick={downloadCSV}                        
				>
					{t("Exportar CSV")}
				</div>}		
                <DownloadCSVErrorDialog
                    open={downloadCSVErrorDialog}
                    onClose={handleDownloadCSVErrorDialogClose}
                />
                <CSVLimitSizeDialog
                    open={csvLimitSizeDialog}
                    onClose={handleCSVLimitSizeDialogClose}
                />
			</div>
		);
	
}

export default ExportCSVHistory;