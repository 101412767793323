import React from "react"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import Tooltip from "@material-ui/core/Tooltip"
import CourtDialog from "./CourtDialog/CourtDialog"
import PartiesDialog from "./PartiesDialog/PartiesDialog"
import moment from "moment"
import HelpIcon from "@material-ui/icons/Help"

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
  },
  tableText: {
    fontFamily: "Open Sans",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "16px",
    color: "#141414",
    textAlign: "center",
    margin: "10px",
    padding: "6px",
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: "#6F6F6F",
    fontSize: "14px",
    lineHeight: "19px",
    padding: "6px",
  },
  even: {
    background: "#F3F3F3",
  },
  odd: {
    background: "#FFFFFF",
  },
  circle: {
    height: "11px",
    borderRadius: "50%",
    width: "11px",
    margin: "auto 3px"
  },
  seeMoreLink: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "19px",
    color: "#0b1f82",
    cursor: "pointer",
    textAlign: "right",
    margin: "auto",
    "&:hover": {
      textDecoration: "underline",
    },
  }
}))


function ProcessesTable(props) {
  const classes = useStyles()
  useTheme()
  const { isQuod } = props
  
  function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  const [courtOpen, setCourtOpen] = React.useState({ status: false, index: 0 })
  const [partiesOpen, setPartiesOpen] = React.useState({ status: false, index: 0 })

  const handleCourtOpen = (index) => {
    setCourtOpen({ status: true, index: index })
  }

  const handleCourtClose = () => {
    let newCourt = { ...courtOpen }
    newCourt.status = false
    setCourtOpen(newCourt)
  }

  const handlePartiesOpen = (index) => {
    setPartiesOpen({ status: true, index: index })
  }

  const handlePartiesClose = () => {
    let newPartiesOpen = { ...partiesOpen }
    newPartiesOpen.status = false
    setPartiesOpen(newPartiesOpen)
  }

  const formatPolarity = (polarity) => {
    switch (polarity) {
      case "active":
        return (
          <div style={{ display: "flex" }}>
            <div style={{ display: "flex", margin: "auto" }}>
              <span>Ativo</span>
              <div className={classes.circle} style={{ backgroundColor: "#73C580" }} />
            </div>
          </div>
        )
      case "passive":
        return (
          <div style={{ display: "flex" }}>
            <div style={{ display: "flex", margin: "auto" }}>
              <span>Passivo</span>
              <div className={classes.circle} style={{ backgroundColor: "#D91A1A" }} />
            </div >
          </div >
        )
      case "neutral":
        return (
          <div style={{ display: "flex" }}>
            <div style={{ display: "flex", margin: "auto" }}>
              <span>Neutro</span>
              <div className={classes.circle} style={{ backgroundColor: "#DDBB08" }} />
            </div>
          </div>
        )
      default:
        return (
          <div style={{ display: "flex" }}>
            <div style={{ display: "flex", margin: "auto" }}>
              <span>Indisponível</span>
              <div className={classes.circle} style={{ backgroundColor: "rgb(170, 167, 153)" }} />
            </div>

          </div>
        )
    }
  }

  const getClass = (rowIndex) => {
    if (rowIndex % 2 === 0) {
      return classes.even
    }

    return classes.odd
  }

  return (
    isQuod ? (
      <Paper className={classes.tableStyle}>
        <Table style={{ padding: "8px" }}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader}>Nº do Processo</TableCell>
              <TableCell className={classes.tableHeader}>Nº do Protocolo</TableCell>
              <TableCell className={classes.tableHeader}>Tipo</TableCell>
              <TableCell className={classes.tableHeader}>Data</TableCell>
              <TableCell className={classes.tableHeader}>Tribunal</TableCell>
              <TableCell className={classes.tableHeader}>Estado</TableCell>
              <TableCell className={classes.tableHeader}>Tipo de Parte</TableCell>
              <TableCell className={classes.tableHeader}>Pessoas Envolvidas</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.processes_data.map((process, index) => (
              <TableRow
                key={index}
                className={getClass(index)}
              >
                <TableCell className={classes.tableText}>
                  {process.process_number ? process.process_number : "-"}
                </TableCell>
                <TableCell className={classes.tableText}>
                  {process.protocol_number ? process.protocol_number : "-"}
                </TableCell>
                <TableCell className={classes.tableText}>
                  {process.process_type ? process.process_type : "-"}
                </TableCell>
                <TableCell className={classes.tableText}>
                  {process.distribution_date ? moment(process.distribution_date).format("DD/MM/YYYY") : "-"}
                </TableCell>
                <TableCell className={classes.tableText}>
                  <div style={{ display: "flex" }}>
                    <div className={classes.seeMoreLink} onClick={() => handleCourtOpen(index)}>{process.forum}</div>
                  </div>
                </TableCell>
                <TableCell className={classes.tableText}>
                  {process.state ? process.state : "-"}
                </TableCell>
                <TableCell className={classes.tableText}>
                  {process.justice_type ? process.justice_type : "-"}
                </TableCell>
                <TableCell className={classes.tableText}>
                  <div style={{ display: "flex" }}>
                    <div className={classes.seeMoreLink} onClick={() => handlePartiesOpen(index)}>Ver Lista</div>
                  </div>
              </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <CourtDialog processes_data={props.processes_data} index={courtOpen.index} open={courtOpen.status} onClose={handleCourtClose} isQuod={true} />
        <PartiesDialog processes_data={props.processes_data} index={partiesOpen.index} open={partiesOpen.status} onClose={handlePartiesClose} isQuod={true} />
      </Paper>
    ) : (
      <Paper className={classes.tableStyle}>
        <Table style={{ padding: "8px" }}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader}>Nº do Processo</TableCell>
              <TableCell className={classes.tableHeader}>Tipo</TableCell>
              <TableCell className={classes.tableHeader}>Data</TableCell>
              <TableCell className={classes.tableHeader}>Tribunal</TableCell>
              <TableCell className={classes.tableHeader}>Tipo de Tribunal</TableCell>
              <TableCell className={classes.tableHeader}>Estado</TableCell>
              <TableCell className={classes.tableHeader}>Situação</TableCell>
              <TableCell className={classes.tableHeader}>Polo</TableCell>
              <TableCell className={classes.tableHeader}>Tipo de Parte</TableCell>
              <TableCell className={classes.tableHeader}>Pessoas Envolvidas</TableCell>
              <TableCell className={classes.tableHeader}>Assunto Principal</TableCell>
              <TableCell className={classes.tableHeader}>Última Atualização</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.processes_data.map((process, index) => (
              <TableRow
                key={index}
                className={getClass(index)}
              >
                <TableCell className={classes.tableText}>
                  {process.number}
                </TableCell>
                <TableCell className={classes.tableText}>
                  {process.type}
                </TableCell>
                <TableCell className={classes.tableText}>
                  {process.date == null ? "-" : moment(process.date).format("DD/MM/YYYY")}
                </TableCell>
                <TableCell className={classes.tableText}>
                  <div style={{ display: "flex" }}>
                    <div className={classes.seeMoreLink} onClick={() => handleCourtOpen(index)}>{process.court.name}</div>
                  </div>
                </TableCell>
                <TableCell className={classes.tableText}>
                  {process.court.type ? process.court.type : "-"}
                </TableCell>
                <TableCell className={classes.tableText}>
                  {process.uf}
                </TableCell>
                <TableCell className={classes.tableText}>
                  {process.status}
                </TableCell>
                <TableCell className={classes.tableText}>
                  {formatPolarity(process.polarity)}
                </TableCell>
                <TableCell className={classes.tableText}>
                  {process.party_type}
                </TableCell>
                <TableCell className={classes.tableText}>
                  <div style={{ display: "flex" }}>
                    <div className={classes.seeMoreLink} onClick={() => handlePartiesOpen(index)}>Ver Lista</div>
                  </div>
                </TableCell>
                <TableCell className={classes.tableText}>
                  <span>{process.main_subject ? process.main_subject : "-"}</span>
                  {process.keywords_found && process.keywords_found.length > 0 &&
                    <Tooltip title={
                      <div className="tooltipText">
                        <strong style={{display:'block',textAlign:'center',marginBottom: "5px"}}>Palavras-Chave:</strong>
                          <ul>
                            {process.keywords_found.map((keyword, i) => (
                            <li key={i}>{capitalize(keyword)}</li>
                            ))}      
                          </ul>
                      </div>
                      }>
                      <HelpIcon style={{ marginLeft: "5px", width: "15px", display: "inline-flex", verticalAlign: "middle", marginBottom:'5 px' }} />
                    </Tooltip>
                  }
                </TableCell>
                <TableCell className={classes.tableText}>
                  <span>{process.last_update.date == null ? "-" : moment(process.last_update.date).format("DD/MM/YYYY")}</span>
                  {process.last_update.content == null ? null :
                    <Tooltip title={
                      <p className="tooltipText">{
                        <li>
                          {process.last_update.content}
                        </li>
                      }</p>}
                    >
                      <HelpIcon style={{ marginLeft: "5px", width: "15px", display: "inline-flex", verticalAlign: "middle" }} />
                    </Tooltip>
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <CourtDialog processes_data={props.processes_data} index={courtOpen.index} open={courtOpen.status} onClose={handleCourtClose} />
        <PartiesDialog processes_data={props.processes_data} index={partiesOpen.index} open={partiesOpen.status} onClose={handlePartiesClose} />
      </Paper>
    )
  )
}

export default ProcessesTable
