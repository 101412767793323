import React, {useContext} from 'react';
import AuthContext from "../../../../context/auth-context"
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

function AnalystSnackBar(props) {
    let message_
    let user_key = useContext(AuthContext).user_data.user_key
    if (props.currentAnalyst.analyst_key === user_key){
        message_ = "Você é o analista responsável por esta análise"
    }
    else {
        message_ = props.currentAnalyst.analyst_name + " é o analista responsável por esta análise"
    }
    
    return (
        <div>
            <Snackbar
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
                }}
                open={props.open}
                onClose={props.onClose}
                autoHideDuration={6000}
                message={message_}
                action={
                    <React.Fragment>
                      <IconButton size="small" aria-label="close" color="inherit" onClick={props.onClose}>
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </React.Fragment>
                  }
            />
        </div>
    );
}

export default AnalystSnackBar