import React, { useState, useEffect, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import moment from "moment";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import axios from "axios";
import Tooltip from "@material-ui/core/Tooltip";
import RenameRuleDialog from "./RenameRuleDialog";
import DeleteRuleDialog from "./DeleteRuleDialog";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import RuleHistory from "./RuleHistory";
import RuleDraft from "./RuleDraft";
import AuthContext from "../../../../context/auth-context";
import ExportCSVHistory from "../../../Utils/ExportCSV/ExportCSVHistory";
import ColoredStatus from "../../../@GeneralComponents/ColoredStatus"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import brSvg from "../../../../assets/br.svg";
import usSvg from "../../../../assets/us.svg";

function RuleCard(props) {

  let history = useHistory()
  let { company, rule, setSnackBarStatus, handleUpdate } = props;

  const roles = useContext(AuthContext).user_data.roles;

  const user_language = useContext(AuthContext).user_data.locale;

  const { t } = useTranslation();

  const [ruleOptionsOpen, setRuleOptionsOpen] = useState(false);
  const [renameRuleDialogOpen, setRenameRuleDialogOpen] = useState(false);
  const optionsRef = useRef(null);
  const [disableAll, setDisableAll] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const isActiveRule = () => {
    if (!company.length) {
      let activeRuleEvent = company.company_events.filter(
        (event) => event.active_rule_key === rule.rule_key
      );
      return activeRuleEvent.length > 0;
    } else {
      let activeRuleEvent = company.filter((currentCompany) => {
        return currentCompany.company_events.some((currentCompanyEvent) => {
          return currentCompanyEvent.active_rule_key === rule.rule_key;
        });
      });
      return activeRuleEvent.length > 0;
    }
  };

  const handleRequestFinish = (severity, message) => {
    setSnackBarStatus({
      opened: true,
      severity: severity,
      message: message,
    });
    handleUpdate();
    setDisableAll(false);
  };

  const handleActivate = () => {
    if (disableAll) return;
    setDisableAll(true);
    let payload = {
      active_rule_key: rule.rule_key,
    };

    let headersRequest = {
      headers: {
        "COMPANY-KEY": rule.company_key,
      },
    };

    axios
      .put(
        "/dash/rule_engine/company_event/" +
          rule.event_type.enum +
          "/active_rule" + 
          "?environment=" +
          rule.environment ,
        payload,
        headersRequest
      )
      .then((response) => {
        handleRequestFinish("success", "Regra ativada com sucesso");
      })
      .catch((error) => {
        handleRequestFinish("error", "Não foi possível ativar essa regra");
      });
  };

  const [openDeleteRuleDialog, setOpenDeleteRuleDialog] = useState(false);

  const handleExclude = () => {
    if (disableAll) return;
    setDisableAll(true);
    axios
      .delete("/dash/rule_engine/rule/" + rule.rule_key + "?environment=" + rule.environment)
      .then((response) => {
        handleRequestFinish("success", "Regra excluída com sucesso");
      })
      .catch((error) => {
        if ((error.response || {}).status === 409)
          handleRequestFinish(
            "error",
            "Não é possível excluir uma regra ativa!"
          );
        else
          handleRequestFinish("error", "Não foi possível excluir essa regra!");
      });
  };

  const handleDuplicate = () => {
    if (disableAll) return;
    setDisableAll(true);
    let payload = {};
    let headersRequest = {
      headers: {
        "COMPANY-KEY": rule.company_key || "",
      },
    };
    axios
      .post(
        `/dash/rule_engine/rule/${rule.rule_key}/copy?environment=${rule.environment}`,
        payload,
        headersRequest
      )
      .then((response) => {
        handleRequestFinish("success", "Regra duplicada com sucesso");
      })
      .catch((error) => {
        handleRequestFinish("error", "Não foi possível duplicar essa regra");
      });
  };
  useEffect(() => {
    function handleClickOutside(event) {
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        setRuleOptionsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [optionsRef]);

  const handleRenameDialogOpen = () => {
    setRenameRuleDialogOpen(true);
  };
  const handleRenameDialogClose = () => {
    setRenameRuleDialogOpen(false);
  };

  const handleExpand = (event) => {
    event.stopPropagation();
    setExpanded(!expanded);
  };

  let gridTemplateColumns = ""
  if (company.length && roles.includes("multiple_environment_viewer")) {
    gridTemplateColumns = "12% 14% 15% 14% 9% 11% 11% 6% 4% 4%"
  } else if (company.length && !roles.includes("multiple_environment_viewer")){
    gridTemplateColumns = "15% 14% 18% 14% 9% 11% 11% 4% 4%"
  } else if (!company.length && roles.includes("multiple_environment_viewer")){
    gridTemplateColumns = "15% 23% 12% 10% 12% 12% 6% 5% 5%"
  } else if (!company.length && !roles.includes("multiple_environment_viewer")) {
    gridTemplateColumns = "18% 23% 15% 10% 12% 12% 5% 5%"
  }


  return (
    <div style={{ marginBottom: "5px" }}>
      <Card className="listCard">
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <div style={{display: "grid", gridTemplateColumns: gridTemplateColumns}}>
            <div
              className={[
                "listCardItem",
                "normalText",
                "normalMediumSize",
                "bold",
              ].join(" ")}
              style={{
                textAlign: "center",
                cursor: "default",
              }}
            >
              {(rule.event_type || {}).description || {}
                ? t(rule.event_type.description)
                : "-"}
            </div>
            {company.length ? (
              <div
                className={[
                  "listCardItem",
                  "normalThistoryext",
                  "normalMediumSize",
                  "bold",
                ].join(" ")}
                style={{
                  textAlign: "center",
                  cursor: "default",
                }}
              >
                {rule.company ? rule.company : "-"}
              </div>
            ) : null}
            <Link
              className={[
                "listCardItem",
                "link",
                "normalMediumSize",
                "breakWord",
                "bold",
              ].join(" ")}
              style={{ textAlign: "center" }}
              to={"/rule/" + rule.rule_key + "/" + rule.version}
            >
              {rule.name ? t(rule.name) : "-"}
            </Link>
              <div
                className={[
                  "listCardItem",
                  "normalText",
                  "normalMediumSize",
                  "noWrap",
                ].join(" ")}
                style={{ textAlign: "center" }}
              >
                {!rule.version_date?" - ":
                user_language === "en"
                  ? moment(rule.version_date).format("MM/DD/YYYY HH:mm:ss")
                  : moment(rule.version_date).format("DD/MM/YYYY HH:mm:ss")}
              </div>
            <div
              className={[
                "listCardItem",
                "normalText",
                "normalMediumSize",
                "bold",
              ].join(" ")}
              style={{
                textAlign: "center",
                cursor: "default",
              }}
            >
            <ColoredStatus
              isButton={false}
              status={isActiveRule()?"active":"inactive"}
              style={{minWidth:"71px"}}
            />
            </div>
            <div
              className={[
                "listCardItem",
                "normalText",
                "normalMediumSize",
                "noWrap",
              ].join(" ")}
              style={{ textAlign: "center" }}
            >
              {!rule.rule_date?" - ":
              user_language === "en"
                ? moment(rule.rule_date).format("MM/DD/YYYY HH:mm:ss")
                : moment(rule.rule_date).format("DD/MM/YYYY HH:mm:ss")
            }
            </div>
            <div
              className={[
                "listCardItem",
                "normalText",
                "normalMediumSize",
                "bold",
              ].join(" ")}
              style={{
                textAlign: "center",
                cursor: "default",
              }}
            >
            {rule.has_rule_draft&&
            <ColoredStatus
              isButton={true}
              status={"has_rule_draft"}
              onClick={()=>history.push("/rule/" + rule.rule_key + "/rule_draft")}
            />}
            </div>
            {roles.includes("multiple_environment_viewer")?<div
              className={[
                "listCardItem",
                "normalText",
                "subtitleSize",
                "noWrap",
              ].join(" ")}
              style={{ textAlign: "center" }}
            >
              {(rule.region === "sa_east_1")
                ?
                  <Tooltip title={
                    <p className="tooltipText" style={{textAlign: "center"}}>
                      Hospedado em ambiente Brasil
                    </p>}>
                    <img src={brSvg} alt="Brazil Flag" width="30" height="30"/>
                  </Tooltip>
                : 
                <Tooltip title={
                  <p className="tooltipText" style={{textAlign: "center"}}>
                    Hospedado em ambiente Estados Unidos 
                  </p>}>
                  <img src={usSvg} alt="US Flag" width="30" height="30"/>
                </Tooltip>
              }
            </div>:null}
            <div
              className={[
                "listCardItem",
                "normalText",
                "subtitleSize",
                "noWrap",
              ].join(" ")}
              style={{ textAlign: "center" }}
            >
              <MoreHorizIcon
                style={{ verticalAlign: "middle", cursor: "pointer" }}
                onClick={() => setRuleOptionsOpen(!ruleOptionsOpen)}
              />
            </div>
            <div
              className={[
                "listCardItem",
                "normalText",
                "subtitleSize",
                "noWrap",
              ].join(" ")}
              style={{ textAlign: "center" }}
            >
              <IconButton onClick={handleExpand}>
                {expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </IconButton>
            </div>
          </div>
          <Collapse
            in={expanded}
            timeout="auto"
            unmountOnExit
            style={{ margin: "10px" }}
          >
            <div
              style={{
                paddingTop: "30px",
              }}
            >
              <div className={["blueText", "normalMediumSize"].join(" ")}>
                {t("has_rule_draft")}
              </div>
              <RuleDraft rule={rule}/>
            </div>
            <div
              style={{
                paddingTop: "30px",
              }}
            >
              <div className={["blueText", "normalMediumSize"].join(" ")}>
                {t("Histórico de Versões")}
              </div>

              <RuleHistory rule={rule} />

              {roles.includes("rule_viewer") && (
                <ExportCSVHistory
                  csv_endpoint={
                    "/dash/rule_engine/rule/" + rule.rule_key + "/history/csv?environment=" + rule.environment 
                  }
                  file_name="Histórico de Versões de Regra"
                />
              )}
            </div>
          </Collapse>
        </div>

        {ruleOptionsOpen ? (
          <div className="listCardPlusContainer" ref={optionsRef}>
            <div
              className={[
                "normalText",
                "normalMediumSize",
                "listCardPlusOptions",
              ].join(" ")}
              onClick={handleRenameDialogOpen}
            >
              <span style={{ margin: "auto" }}>{t("Renomear Regra")}</span>
            </div>
            <div
              className={[
                "normalText",
                "normalMediumSize",
                "listCardPlusOptions",
              ].join(" ")}
              onClick={handleDuplicate}
            >
              <span style={{ margin: "auto" }}>{t("Duplicar Regra")}</span>
            </div>
            <div
              className={[
                "normalText",
                "normalMediumSize",
                "listCardPlusOptions",
              ].join(" ")}
              onClick={() => setOpenDeleteRuleDialog(true)}
            >
              <span style={{ margin: "auto" }}>{t("Excluir Regra")}</span>
            </div>
            {isActiveRule() ? null : (
              <div
                className={[
                  "normalText",
                  "normalMediumSize",
                  "listCardPlusOptions",
                ].join(" ")}
                onClick={handleActivate}
              >
                <span style={{ margin: "auto" }}>{t("Ativar Regra")}</span>
              </div>
            )}
          </div>
        ) : null}
        <DeleteRuleDialog open={openDeleteRuleDialog} onClose={() => setOpenDeleteRuleDialog(false)} onConfirm={handleExclude}/>
        <RenameRuleDialog
          open={renameRuleDialogOpen}
          onClose={handleRenameDialogClose}
          handleRequestFinish={handleRequestFinish}
          rule={rule}
        />
      </Card>
    </div>
  );
}

export default RuleCard;
