import React, {useContext} from "react";
import AuthContext from "../../../../context/auth-context";

function CardListHeader(props){
    let {user_data} = useContext(AuthContext)
    
    return (
        <div className="listCardHeader">
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                style={{flex:"1 1 110px", textAlign:"center"}}
            >
                ID da Transação
            </div>
            {user_data.business_group_key &&
            <div 
                className={["listCardItem", "normalText"].join(" ")}
                style={{flex:"1 1 80px", textAlign:"center"}}
            >
                Companhia
            </div>}
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                style={{flex:"1 1 130px", textAlign:"center"}}
            >
                Tipo do Relato
            </div>
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                style={{flex:"1 1 100px", textAlign:"center"}}
            >
                Valor da Transação
            </div>
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                style={{flex:"1 1 100px", textAlign:"center"}}
            >
                Instituição Contraparte
            </div>
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                style={{flex:"1 1 150px", textAlign:"center"}}
            >
                Data Limite de Análise
            </div>
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                style={{flex:"1 1 45px", textAlign:"center"}}
            >
                Status
            </div>
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                style={{flex:"1 1 110px", textAlign:"center"}}
            >
                Data do Relato
            </div>
        </div>
    )
}

export default CardListHeader