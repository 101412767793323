import styled from "styled-components";

export const Container = styled.div`
    background: #FFFFFF;
    border: 1px solid #cccc;
    border-radius: 10px;
    padding: 21px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin: 10px;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-top: 4px;
    margin-bottom: 12px;
`;

export const Title = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-family: "Open Sans";
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    color: #0b1f82;
    line-height: 20px;
    font-weight: 600;
`;

export const ContentWrapper = styled.div`
    width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 32px;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;