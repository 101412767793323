import React, {useContext, useState, useEffect} from 'react'
import { useTranslation } from "react-i18next";
import {GetAlertsComponent, translateType, setObjectByPath} from "./utils"
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import TextField from "@material-ui/core/TextField";
import MenuItem from '@material-ui/core/MenuItem';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RulesContext from "../../../../context/rules-context"
import _ from "lodash"

export function ListsIncludesBox (props) {
    const { t } = useTranslation();
    let {node} = props
    let {read_only} = props
    let {handleConfigurationOpen} = props
    let {handleNodeRemove} = props
    let rulesList = useContext(RulesContext)
    let list = rulesList.lists.filter(list => list.list_key === node.node.properties.list_key)[0]

    return (
        <div>
            {!read_only ?
                <div style={{display:"flex", alignItems: "center"}}>
                    <div style={{display:"flex", width:"100%", cursor:"pointer", margin: "auto 0px"}} onClick={() => handleConfigurationOpen(node)}>
                        {t((list || {name:""}).name)}
                        {(node.node.alerts || []).length > 0?
                        <GetAlertsComponent alerts={node.node.alerts} />  : null} 
                    </div>
                    <IconButton style={{width: 32, height: 32}} aria-label="duplicate" onClick={() => props.handleNodeDuplication(node)}>
                        <ContentCopyIcon style={{width: 20, height: 20}} />
                    </IconButton>
                    <IconButton style={{width: 32, height: 32}} aria-label="close" onClick={() => handleNodeRemove(node)}>
                        <CloseIcon style={{width: 20, height: 20}} />
                    </IconButton>
                </div>
            :
                <div style={{display:"flex"}}>
                    <div style={{display:"flex", width:"100%", cursor:"pointer", margin: "auto 0px"}} onClick={() => handleConfigurationOpen(node)}>
                        {t((list || {name:""}).name)}
                    </div>
                </div>
            }
        </div>
    )
}

export function ListsIncludesConfiguration(props){
    const { t } = useTranslation();
    let {node} = props
    let {read_only} = props
    let {handleNodeChange} = props
    let {handleConfigurationClose} = props
    let rulesList = useContext(RulesContext)

    const [nodeState, setNodeState] = useState(node)

    useEffect(()=>{
        setNodeState(node)
    },[node])

    const handleEventChange = (e) => {
        let list = rulesList.lists.filter(list => list.list_key === e.target.value)[0]
        let new_node = _.cloneDeep(nodeState)
        setObjectByPath(new_node.node,"properties.list_key",e.target.value)
        setObjectByPath(new_node.node,"metadata.list_type",list.fields[0].type.enum)
        setNodeState(new_node)
    }

    const handleSave = () => {
        handleConfigurationClose()
        handleNodeChange(nodeState)
    }

    const getAvailableLists = () => {
        let availableList = rulesList.lists.filter(list => list.fields[0].type.enum !== "geo_point")
        return availableList
    }

    const validateAllValues = () => {
        return (nodeState.node.properties.list_key && nodeState.node.properties.list_key.length >0)
    }

    return(
        <div style={{flexGrow:"1", padding: "20px", display:"flex", flexDirection:"column"}}>
            <div style={{display: "flex"}}>
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>{t(node.node.description)}</span>
                <Tooltip title={<div className="tooltipText"><ul>{node.node.helperText.map((help,index) => (<li key={index}>{t(help)}</li>))}</ul></div>}>
                    <HelpIcon style={{fontSize: "14px", display:"flex", margin:"auto 5px"}}/>
                </Tooltip>
            </div>
            <div className="internalCardContainer" style={{display:"flex", flexDirection: "column", width: '300px'}}>
                <TextField
                    label={t("Lista")}
                    style={{textAlign:"center", width:"80%", margin:"3px auto"}}
                    className="filterTextbox" 
                    size="small" fullWidth 
                    value={nodeState.node.properties.list_key}
                    onChange={handleEventChange}
                    select
                    InputProps={{
                        readOnly: read_only
                      }}
                >
                {getAvailableLists().map((list, index) => (
                        <MenuItem key={index} value={list.list_key}><em className="normalText">{t(list.name) + " ("+ t(translateType(list.fields[0].type.enum)) +")"}</em></MenuItem>
                ))}
                </TextField>             
            </div>
            <div style={{display:"flex", margin: "auto auto 5px auto"}}>
                <div
                    className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")}
                    style={{width:"120px"}}
                    onClick={handleConfigurationClose}
                >
                    {read_only ? "X" : t("discard")}
                </div>
                {!read_only && 
                <div
                    className={
                        validateAllValues()
                        ?
                        ["button", "standard", "normalText", "normalMediumSize"].join(" ")
                        :
                        ["button", "standard", "normalText", "normalMediumSize","disabled"].join(" ")}
                    style={{width:"120px"}}
                    onClick={validateAllValues() ? handleSave : null}
                >
                    {t("save")}
                </div>}   
            </div>
        </div>
    )
}

export class ListsIncludesBoxClass {
    constructor(rulesContext){
        this.rulesContext = rulesContext
    }
    validate(node){
        let put_error = false
        let alerts = []

        //Validate node properties
        if(!node.node.properties.list_key || node.node.properties.list_key === ""){
            put_error = true
            alerts.push("Clique no nó desta Lista, abra a configuração e escolha uma opção válida")
        }
        else if(!node.node.metadata.list_type || node.node.metadata.list_type === ""){
            put_error = true
            alerts.push("Há um problema com o tipo desse campo")
        }

        //Validate if node has at least one children
        if((node.node.children || []).length !==1 ){
            put_error = true
            alerts.push("Este nó deve possuir um único filho")
        }
        else{
            let child_type = node.node.children[0].instance.getType(node.node.children[0])
            if (node.node.metadata.list_type !== "geo_shape" && child_type !== node.node.metadata.list_type) {
                put_error = true
                alerts.push("O filho deve possuir o mesmo tipo do campo chave da lista")
            }
            else if (node.node.metadata.list_type === "geo_shape" && child_type !== "geo_point"){
                put_error = true
                alerts.push("Lista com campo chave do tipo " + translateType("geo_shape") + " aceitam apenas filhos do tipo "+ translateType("geo_point"))
            }
        }

        //Validate if parent is valid
        if (node.parentNode != null) {
            if(node.parentNode.on_error){
                put_error = true
                alerts.push("O nó superior está inválido")
            }
        }
        return {validated: !put_error, alerts: alerts}
    }
    getType(node){
        return "bool"
    }
}