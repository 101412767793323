import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper"
import { formatBoavistaData } from '../../../../../utils/utils';
import { useTranslation } from "react-i18next";
import Tooltip from '@material-ui/core/Tooltip'


const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
  },
  tableText: {
    fontFamily: "Open Sans",
    fontWeight: 'normal',
    fontSize: "12px",
    lineHeight: "16px",
    color: "#141414",
    textAlign: "center",
    margin: "4px",
    padding: "6px",
    minWidth: "60px",
    whiteSpace: "nowrap"
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: '#6F6F6F',
    fontSize: "14px",
    lineHeight: "19px",
    padding: "6px",
  },
  even: {
    background: "#F3F3F3",
  },
  odd: {
    background: "#FFFFFF",
  },
}));

  function BoavistaNegativeNotesTable(props) {

    const {t} = useTranslation()
    const classes = useStyles();
    useTheme();
    let {negative_notes_data,negative_note_type,isPDF} = props

    const headerFields = ["event_date","occurrence_date","company","occurrence_type","amount","situation","condition", "notary_code","city","state"]
    const addInfoFields = ["contract_id","available_date","informed_by_consultant","ibge_city_code"]

    if (negative_note_type==="debts"&&isPDF) {
      headerFields.pop()
      headerFields.pop()
      addInfoFields.unshift("occurrence_date")
      addInfoFields.push("city")
      addInfoFields.push("state")
    }

    const getClass = (rowIndex) => {
      if (rowIndex % 2 === 0) {
        return classes.even;
      }
      return classes.odd;
    };

    const addInfoTextHandler = (negativeNote,addInfoFields) => {
      let addInfoObj = Object.create({})
      for (let field of addInfoFields.values()) {
        if (negativeNote[field] !== undefined) {
          addInfoObj[field] = negativeNote[field]
        }
      }
      if (Object.keys(addInfoObj).length===0) {
        return null
      } 
      return <div>
        {Object.keys(addInfoObj).map((key, index)=>(
          <p key={index}>
            {t(key)}: {formatBoavistaData(key,t(addInfoObj[key]))}
          </p>))}
      </div>
    }

    return (
      <div>
        <Paper className={classes.tableStyle}>
          <Table style={{ padding: "8px", marginBottom: '26px' }}>
              <TableHead>
                <TableRow>
                  {headerFields.map((field) => (
                    negative_notes_data.occurrences[0][field]!==undefined?
                    <TableCell key={field} className={classes.tableHeader}>{t(field)}</TableCell>
                    :null))}
                  {!isPDF&&addInfoTextHandler(negative_notes_data.occurrences[0],addInfoFields)?
                    <TableCell className={classes.tableHeader}>{t("additional_information")}</TableCell>
                    :null}
                </TableRow>
              </TableHead>
              <TableBody>
                {negative_notes_data.occurrences.map((negativeNote, index) => (
                  <TableRow key={index} className={getClass(index)}>
                    {headerFields.map((field) => (
                      (negativeNote[field]!==undefined)&&
                      <TableCell style={{wordBreak:"break-all"}} className={classes.tableText} key={field}>
                        {formatBoavistaData(field,t(negativeNote[field]))}
                      </TableCell>
                    ))}
                    {!isPDF&&addInfoTextHandler(negativeNote,addInfoFields)&&
                      <Tooltip title={<div className="tooltipText">{addInfoTextHandler(negativeNote,addInfoFields)}</div>}>
                        <TableCell className={classes.tableText}>...</TableCell>
                      </Tooltip>}
                    </TableRow>)
                  )
                }
              </TableBody>
            </Table>
        </Paper>
        {negative_note_type==="debts"&&isPDF&&
        <Paper className={classes.tableStyle}>
          <Table style={{ padding: "8px", marginBottom: '26px' }}>
              <TableHead>
                <TableRow>
                  {addInfoFields.map((field) => (
                    negative_notes_data.occurrences[0][field]!==undefined?
                    <TableCell key={field} className={classes.tableHeader}>{t(field)}</TableCell>
                    :null))}
                </TableRow>
              </TableHead>
              <TableBody>
                {negative_notes_data.occurrences.map((negativeNote, index) => (
                  <TableRow key={index} className={getClass(index)}>
                    {addInfoFields.map((field) => (
                      (negativeNote[field]!==undefined)&&
                      <TableCell style={{wordBreak:"break-all"}} className={classes.tableText} key={field}>
                        {formatBoavistaData(field,t(negativeNote[field]))}
                      </TableCell>
                    ))}
                  </TableRow>)
                  )
                }
              </TableBody>
          </Table>
        </Paper>}
    </div>
    );
  }


export default BoavistaNegativeNotesTable;