import React from "react";
import { useTranslation } from "react-i18next";
import SerasaNegativeNotesCard from "../../../Cards/SerasaNegativeNotesCard/SerasaNegativeNotesCard";
import SerasaPartnersCard from "../../../Cards/SerasaPartners/SerasaPartnersCard";
import {
    getScoreInterpretation,
    titleCase
} from "../../../utils/utils";
import moment from "moment";
import NumberDescription from "../../../../@GeneralComponents/NumberDescription";
import AnalysisCardContainer from "../../../utils/Components/AnalysisCardContainer";
import DataField from "../../../utils/DataField";

function SerasaToPDF(props) {

    const { t } = useTranslation()

    let { serasa, serasaScore2Img, serasaProbability2Img } = props

    let {
        riskscoring,
        name,
        document_number,
        document_status,
        foundation_date,
        partners_data,
    } = serasa;

    let {
        score,
        percentual_default_probability,
        message,
        risk_level,
        suggested_market_practice
    } = riskscoring

    let showRiskScoring = !!score

    return (
        <div className="avoidPageBreakInside analysisCard" style={{display: 'flex', flexDirection:"column"}}>
            <div
                className={["blueText", "subtitleSize", "bold"].join(" ")}
                style={{ margin: "auto 0px" }}
            >
                Serasa
            </div>
            <AnalysisCardContainer className="avoidPageBreakInside analysisCardContainer" grow={true}>
                <div className="analysisCard">
                    <div className="blueText subtitleSize" style={{ marginBottom: "10px" }}>{t("Dados básicos")}</div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        {name &&
                            <DataField
                                title={t("name")}
                                label={titleCase(name, "-")}
                                size={"normalMediumSize"}
                                titleNoWrap={false}
                            />}
                        {document_number &&
                            <DataField
                                title={t("document_number")}
                                label={document_number}
                                size={"normalMediumSize"}
                                titleNoWrap={false}
                            />}
                        {document_status &&
                            <DataField
                                title={t("document_status")}
                                label={titleCase(t(document_status), "-")}
                                size={"normalMediumSize"}
                                titleNoWrap={false}
                            />}
                        {foundation_date &&
                            <DataField
                                title={t("foundation_date")}
                                label={moment(foundation_date).format("DD/MM/YYYY")}
                                size={"normalMediumSize"}
                                titleNoWrap={false}
                            />}
                    </div>
                </div>
            </AnalysisCardContainer>
            {showRiskScoring && (
                <AnalysisCardContainer className="analysisCardContainer avoidPageBreakInside" grow={true}>
                    <div className="analysisCard">
                        <div className="blueText subtitleSize" style={{ marginBottom: "10px" }}>{t("serasa_risk_scoring")}</div>
                        <div style={{ display: "grid", gridTemplateColumns: "50% 50%" }}>
                            <NumberDescription
                                description="/1000"
                                total={score}
                                secondDescription={t("serasa_score")}
                            />
                            <div style={{ width: '100px' }}>
                                <img src={serasaScore2Img} alt="Score" />
                            </div>
                        </div>
                        <div style={{ display: "grid", gridTemplateColumns: "50% 50%" }}>
                            <NumberDescription
                                description={t("serasa_defaulted_probability")}
                                total={`${percentual_default_probability.toFixed(1).replace(".", ",")}%`}
                            />
                            <div style={{ width: '100px' }}>
                                <img src={serasaProbability2Img} alt="Probabilidade de Inadimplência" />
                            </div>
                        </div>
                        <DataField
                            title={t("score_interpretation")}
                            label={message ? t(message) : t(getScoreInterpretation(score, "natural_person"))}
                            size={"normalMediumSize"}
                            titleNoWrap={false}
                        />
                        {risk_level &&
                            <DataField
                                title={t("risk_level")}
                                label={t(risk_level)}
                                size={"normalMediumSize"}
                                titleNoWrap={false}
                            />}
                        {suggested_market_practice &&
                            <DataField
                                title={t("suggested_market_practice")}
                                label={t(suggested_market_practice)}
                                size={"normalMediumSize"}
                                titleNoWrap={false}
                            />}
                    </div>
                </AnalysisCardContainer>
            )}
            {!showRiskScoring && (
                <AnalysisCardContainer className="analysisCardContainer avoidPageBreakInside" grow={true}>
                    <div className="analysisCard">
                        <div className="blueText subtitleSize">{t("serasa_risk_scoring")}</div>
                        <div className="alignCenter" style={{ margin: "40px auto" }}>
                            <NumberDescription
                                description={t("serasa_score_not_available")}
                                secondDescription={((serasa || {}).riskscoring || {}).message ? serasa.riskscoring.message : ""}
                                total=""
                            />
                        </div>
                    </div>
                </AnalysisCardContainer>
            )}
            {(serasa || {}).partners_data ?
                <div className="analysisCardContainer avoidPageBreakInside">
                    <SerasaPartnersCard partners_data={partners_data} report_type={serasa?.report_type} />
                </div>
                : null}
            {(serasa || {}).pefin ?
                <div className="analysisCardContainer avoidPageBreakInside">
                    <SerasaNegativeNotesCard negative_notes_data={serasa.pefin} has_negative_note={serasa.pefin.has_pefin} negative_note_type={"Pefin"} />
                </div>
                : null}
            {(serasa || {}).refin ?
                <div className="analysisCardContainer avoidPageBreakInside">
                    <SerasaNegativeNotesCard negative_notes_data={serasa.refin} has_negative_note={serasa.refin.has_refin} negative_note_type={"Refin"} />
                </div>
                : null}
            {(serasa || {}).dishonoured_check ?
                <div className="analysisCardContainer avoidPageBreakInside">
                    <SerasaNegativeNotesCard negative_notes_data={serasa.dishonoured_check} has_negative_note={serasa.dishonoured_check.has_dishonoured_check} negative_note_type={t("dishonoured_check")} />
                </div>
                : null}
            {(serasa || {}).overdue_debts ?
                <div className="analysisCardContainer avoidPageBreakInside">
                    <SerasaNegativeNotesCard negative_notes_data={serasa.overdue_debts} has_negative_note={serasa.overdue_debts.has_overdue_debts} negative_note_type={t("overdue_debts")} />
                </div>
                : null}
            {(serasa || {}).lawsuits ?
                <div className="analysisCardContainer avoidPageBreakInside">
                    <SerasaNegativeNotesCard negative_notes_data={serasa.lawsuits} has_negative_note={serasa.lawsuits.has_lawsuits} negative_note_type={t("lawsuits")} />
                </div>
                : null}
            {(serasa || {}).protests ?
                <div className="analysisCardContainer avoidPageBreakInside">
                    <SerasaNegativeNotesCard negative_notes_data={serasa.protests} has_negative_note={serasa.protests.has_protests} negative_note_type={t("protests")} />
                </div>
                : null}
            {(serasa || {}).bankruptcy ?
                <div className="analysisCardContainer avoidPageBreakInside">
                    <SerasaNegativeNotesCard negative_notes_data={serasa.bankruptcy} has_negative_note={serasa.bankruptcy.has_bankruptcy} negative_note_type={t("bankruptcy")} />
                </div>
                : null}
            {(serasa || {}).spc_mentions ?
                <div className="analysisCardContainer avoidPageBreakInside">
                    <SerasaNegativeNotesCard negative_notes_data={serasa.spc_mentions} has_negative_note={serasa.spc_mentions.has_spc_mentions} negative_note_type={t("spc_mentions")} />
                </div>
                : null}
        </div>
    );
}

export default SerasaToPDF
