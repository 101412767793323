import React from "react";
import { formatDecision } from "../../utils/utils";

function DecisionBox(props) {

    let reason          = (((props.analysis_events || [])[0] || {}).decision_metadata || {}).reason
    let description     = (((props.analysis_events || [])[0] || {}).decision_metadata || {}).reason_description
    let decisionObject  = formatDecision(props.analysis_status)

    if (description != null && description !== undefined) {
        reason = description
    }

    return (
        <div className="analysisCardNoFlex">
            <div style={{display:"flex", alignItems:"center", marginLeft:"50px", justifyContent:'center'}}>
                <div 
                    className={["circle", "bold", "normalText", "subtitleSize"].join(" ")} 
                    style={{color:"white", marginRight:"8px", marginTop:"auto", marginLeft:0,
                            backgroundColor: decisionObject.color,
                            flex:'0 0 60px'}}
                >
                    {decisionObject.icon}
                </div>
                <div
                    style={{display:"flex", alignItems:"center", 
                    flexWrap:"wrap", minHeight:"50px", paddingRight:"5px",
                    margin:"auto 0px"}}
                >
                    <div className="decisionText">
                        Decisão
                    </div>
                    <div className={["normalText", "subtitleSize", "bold"].join(" ")} 
                        style={{margin:"auto 0px", color: decisionObject.color, width:"100%"}}>
                        {decisionObject.text}
                    </div>
                </div>
            </div>
            <div style={{display:"flex", marginTop:"15px", justifyContent:'center', marginLeft:0}}>
                <div className={["blueText", "subtitleSize"].join(" ")} style={{margin:"0px 5px auto 0px"}}>
                    Razão: 
                </div>
                <div className={["labelText", "subtitleSize"].join(" ")}
                    style={{margin:"0px 0px auto 0px"}}>
                    {(reason === null || reason===undefined)?"Razão Indisponível":reason}
                </div>
            </div>
        </div>
    );
}

export default DecisionBox;