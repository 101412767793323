import React from 'react'
import TimeCounter from "./TimeCounter/TimeCounter"
import { getAnalysisStatus, getAnalysisStatusColor } from "../../utils/utils"

function TimerBox (props) {
    let {billPayment} = props
    if(billPayment.analysis_status === "pending" || billPayment.analysis_status === "in_manual_analysis"){
        return (
            <TimeCounter bill_payment={billPayment}/>
        )
    }
    else{
        return(
            <div className="analysisCard" style={{height:"100%", borderWidth:"1px", borderStyle:"solid", borderColor:getAnalysisStatusColor(billPayment.analysis_status)}}>
                <div 
                    className={["normalText", "substitleSize", "bold"].join(" ")} 
                    style={{margin:"auto", color:getAnalysisStatusColor(billPayment.analysis_status)}}
                >
                    {getAnalysisStatus(billPayment.analysis_status)}                    
                </div>
            </div>
        )
    }
}

export default TimerBox