import React, { useEffect, useContext, useReducer, useState } from "react";
import { dataFetchReducer } from "../../Utils/requestUtils";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment"
import ErrorBoundary from "../../Utils/ErrorBoundary";
import AuthContext from "../../../context/auth-context"
import {useHistory} from "react-router-dom"
import TimerBox from "./TimerBox/TimerBox"
import BankslipCard from "./BankslipCard/BankslipCard"
import DecisionBox from "./DecisionBox/DecisionBox"
import AlertCard from "./AlertCard/AlertCard"
import ObservationCard from "./ObservationCard/ObservationCard"
import ClientCard from "./ClientCard/ClientCard"
import EnvolvedParties from "./EnvolvedParties/EnvolvedParties"
import HistoricCard from "./HistoricCard/HistoricCard"
import FraudCard from "./Fraud/FraudCard/FraudCard"
import FraudHistory from "./Fraud/FraudHistory/FraudHistory"
import FraudDialog from "./Fraud/FraudDialog/FraudDialog"
import NextTransaction from "../../Utils/NextTransaction/NextTransaction";
import CustomDataCard from "./CustomDataCard/CustomDataCard";

function Bankslip(props){
    let history = useHistory()
    let is_fraud = false 

    let roles = useContext(AuthContext).user_data.roles
    if (!roles.includes("read_bankslips")){
        history.push("")
    }
    let can_write = roles.includes("update_bankslips")

    let { bankslip_key } = useParams();

    const [openDialog, setOpenDialog] = useState(false)

    const handleFraudStatus = () => {
        setOpenDialog(true)
    };

    const handleDialogClose = () => {
        setOpenDialog(false)
    };

    const [bankslip, dispatchBankslip] = useReducer(
        dataFetchReducer,
        {fetchedData: null, isLoading: true, isError: false} 
    )

    useEffect(() => {
		dispatchBankslip({type: "data_fetch_init"})
		const timer = setTimeout(() => {
			axios.get('/dash/bankslip/bankslip/' + bankslip_key).then( response => {
                dispatchBankslip({
                    type: "data_fetch_success",
                    payload: response.data
                })   
			}).catch(error => {
                if ((error.response || {}).status === 403) dispatchBankslip({type: "data_fetch_failure_403"})
                else if ((error.response || {}).status === 404) dispatchBankslip({type: "data_fetch_failure_404"})  
                else dispatchBankslip({type: "data_fetch_failure"})    
			})	
		}, 500);
		return () => {
			clearTimeout(timer)
        }
    }, [bankslip_key])

    if (bankslip.fetchedData){

        is_fraud = bankslip.fetchedData.fraud_status === "fraud"

        return (
            <div style={{minWidth:"900px", paddingBottom: "30px"}}>
                <div className="analysisCardContainer">
                    <ErrorBoundary>
                        <div style={{display:"flex", flexDirection:"column", width: "100%"}}>
                            <div style={{display:"flex", justifyContent: "space-between"}}>
                                <div className={["blueText", "titleSize"].join(" ")} style={{margin:"5px 0px"}}>
                                    Boleto {bankslip.fetchedData.id}
                                </div>
                                {(!is_fraud && can_write) &&
                                    <div
                                        className={["button", "reproved", "normalText", "normalMediumSize"].join(" ")                            }
                                        style={{display: "flex", alignItems: "center", width: "150px", fontWeight:"600"}}
                                        onClick={handleFraudStatus}
                                    >
                                        Reportar como fraude
                                    </div>}
                            </div>
                            <div className={["labelText", "subtitleSize"].join(" ")} style={{margin:"5px 0px"}}>
                                {moment(bankslip.fetchedData.bankslip_payment_date).format("DD/MM/YYYY - HH:mm:ss")}
                            </div>
                        </div>
                    </ErrorBoundary>
                </div>
                <NextTransaction history={history} transaction_key={bankslip_key} path={"/bankslip"} suffix={"bankslip"} page_name={"Boletos"}/>                        
                <div style={{display: "flex", margin: "10px 0px"}}>
                    <div style={{display: "flex", flexDirection:"column", width: "33%"}}>
                        <div style={{flexGrow: "1"}} className="analysisCardContainer">
                            <ErrorBoundary>
                                <BankslipCard bankslip={bankslip.fetchedData}/>
                            </ErrorBoundary>
                        </div>
                        <div style={{flexGrow: "1"}} className="analysisCardContainer">
                            <ClientCard bankslip={bankslip.fetchedData}/>
                        </div>
                        {bankslip.fetchedData.custom_data&&
                        <div style={{flexGrow: "1"}} className="analysisCardContainer">
                            <ErrorBoundary>
                                <CustomDataCard data={bankslip.fetchedData} />
                            </ErrorBoundary>
                        </div>}
                    </div>
                    <div style={{display: "flex", flexDirection:"column", width: "66%"}}>
                        <div style={{display:"flex"}}>
                            <div style={{display: "flex", flexDirection:"column", width: "50%"}}>
                                <div className="analysisCardContainer" style={{flexGrow: "1"}}>
                                    <ErrorBoundary>
                                        <TimerBox bankslip={bankslip.fetchedData}/>
                                    </ErrorBoundary>
                                </div>
                                <div className="analysisCardContainer" style={{flexGrow: "1"}}>
                                    <ErrorBoundary>
                                        <DecisionBox 
                                            analysis_events={bankslip.fetchedData.analysis_events}
                                            analysis_status={bankslip.fetchedData.analysis_status}
                                        />
                                    </ErrorBoundary>
                                </div>
                            </div>
                            <div style={{display: "flex", flexDirection:"column", width: "50%"}}>
                                {is_fraud && 
                                <div className="analysisCardContainer" style={{flexGrow: "1"}}>
                                    <ErrorBoundary>
                                        <FraudCard />                                    
                                    </ErrorBoundary>   
                                </div>}
                                <div className="analysisCardContainer" style={{minHeight:"150px", flexGrow: "1"}}>
                                    <ErrorBoundary>
                                        <AlertCard analysis_events={bankslip.fetchedData.analysis_events}/>
                                    </ErrorBoundary>   
                                </div>
                            </div>
                        </div>
                        <div className="analysisCardContainer" style={{flexGrow: "1"}}>
                            <EnvolvedParties bankslip={bankslip.fetchedData} />
                        </div>
                    </div>
                </div>
                <div className="analysisCardContainer">
                    <HistoricCard bankslip={bankslip.fetchedData}/>
                </div>
                {is_fraud &&
                    <div className="analysisCardContainer" >
                        <ErrorBoundary>
                            <FraudHistory bankslip={bankslip.fetchedData} can_write={can_write} dashAPIEndpointUrl={"dash/bankslip/bankslip/"}/>
                        </ErrorBoundary>   
                    </div>}
                <div className="analysisCardContainer">
                    <ErrorBoundary>
                        <ObservationCard bankslip={bankslip.fetchedData}/>
                    </ErrorBoundary>			
                </div>
                <FraudDialog
                    open={openDialog}
                    onClose={handleDialogClose}
                    bankslip={bankslip.fetchedData}
                />
            </div>
        )
    }
	else if(bankslip.isError){
		return (
            <div style={{height: "80vh", display:"flex"}}>
                <p className={["blueText", "titleSize"].join(" ")} style={{margin:"auto", textAlign:"center"}}>
                    {bankslip.errorMessage}
                </p>
            </div>
		)
	}
  
  return null;
}

export default Bankslip;