import React, {useState, useEffect} from 'react'
import { CircularProgress } from '@material-ui/core';
import axios from "axios";
import peopleIcon from "../../../../assets/cardEntryIcons/people.svg"
import HistoryTable from './HistoryTable/HistoryTable';
import Pagination from "@material-ui/lab/Pagination";

function History(props) {

    let {naturalPerson} = props

    const [history, setHistory] = useState(null)
    const [tablePage, setTablePage] = React.useState(1)

    useEffect(() => {
        if (naturalPerson == null) return;
        let params = {
            "page_number": tablePage -1,
            "page_rows": 5,
            "document_number": naturalPerson.document_number
        }

        axios.get('/dash/onboarding/natural_persons', {params: params}).then( response => {
            setHistory(response.data);             
        }).catch(error => {
            setHistory(undefined)
        })
    }, [tablePage, naturalPerson])

    const handleChangePage = (event, page) => {
		if (tablePage === page) {
			return
		}
        setTablePage(page)
        setHistory(null)
	}
    
    if (history === null) {
        return (
            <div className="analysisCard" style={{minHeight: "250px"}}>
                <div className={["blueText", "subtitleSize"].join(" ")}>Histórico de Análises</div>
                <div style={{display:"flex", flexGrow:"1"}}>
                    <CircularProgress style={{margin:"auto"}} />
                </div>
            </div>
        );    
    }
    else if (history === undefined){
        return (
            <div className="analysisCard" style={{minHeight: "250px"}}>
                <div className={["blueText", "subtitleSize"].join(" ")}>Histórico de Análises</div>
                <div style={{display:"flex", flexGrow:"1"}}>
                    <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                        Informação Indisponível
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className="analysisCard">
            <div style={{display: "flex"}}>
                <img className="cardTitleIconStyle" src={peopleIcon} alt="peopleIcon Logo" />
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>Histórico de Análises</span>
            </div>
            <div className="internalCardContainer">
                <div>
                    <div>
                        <HistoryTable history={history.data} current_natural_person_key={naturalPerson.natural_person_key}/>
                    </div>
                    {history.data.length>1&&
                    <div style={{display:"flex", marginTop: "10px"}}>
                        <Pagination style={{marginLeft:"auto"}} className='pagination' page={tablePage} count={history['number_of_pages']} onChange={(e,page) => handleChangePage(e, page)} />
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default History