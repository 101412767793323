import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { justNumbers } from "../RulesEngine/utils";

export const TimedeltaInput = (props) => {
    
    const { id, labelValue, timedelta, onChange, onBlur, error, errorMessage } = props
    const { t } = useTranslation();
    const [isFocused, setIsFocused] = useState(false);

    useEffect(()=>{
        if (!timedelta) {
            onChange({
                "years": 0,
                "months": 0,
                "days": 0,
                "hours": 0,
                "minutes": 0,
                "seconds": 0
            })
        }
    },[timedelta,onChange])

    function formatTimedelta (value,type) {
        let newTimedelta = timedelta
        newTimedelta[type] = Number(justNumbers(value).slice(0,5))
        return newTimedelta
    }

    function handleChange (event, type) {
        const { value } = event.target;
        const newTimedelta = formatTimedelta(value,type)
        onChange(newTimedelta);
    }

    const handleBlur = (event, type) => {
        setIsFocused(false);
        const { value } = event.target;
        const newTimedelta = formatTimedelta(value,type)
        onBlur(newTimedelta);
    };
    
    const handleFocus = () => {
        setIsFocused(true);
    };

    let showMainLabelValue=true
    if (props.showMainLabelValue===false) {
        showMainLabelValue=false
    }
    
    if (!timedelta) {
        return null
    } else {
        return (
            <>
                {showMainLabelValue&&
                <label className="normalText normalSmallSize" htmlFor={id}>{`${t(labelValue)}`}</label>}
                <div  style={{display:"grid", gridTemplateColumns:"15.5% 15.5% 15.5% 15.5% 15.5% 15.5%", gap:"1.16%"}}>
                    {["days","months","years","hours","minutes","seconds"].map((type)=>(
                    <div key={type} className="labelInput">
                        <label className="normalText normalSmallSize" htmlFor={id}>{t(type)}</label>
                        <input
                            className={!isFocused&&error?"errorInput":null}
                            id={id}
                            name={id}
                            value={timedelta[type]}
                            onChange={(event) => handleChange(event, type)}
                            disabled={props.isDisabled?props.isDisabled:false}
                            onBlur={(event) => handleBlur(event, type)}
                            onFocus={handleFocus}
                        />
                    </div>
                    ))}
                </div>
                <span className="labelInput normalSmallSize__error" style={{height:"13px"}}>
                    {!isFocused&&error?t(errorMessage):null}
                </span>
            </>
        );
    }
}
