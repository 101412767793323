import React, {useState} from "react";
import EuropeUnionTable from "./EuropeUnionTable/EuropeUnionTable";
import Collapse from "@material-ui/core/Collapse";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import IconButton from "@material-ui/core/IconButton";
import {getCardIcon} from "../../../utils/utils"

function EuropeUnionCard(props) {

    const [expanded, setExpanded]   = useState(false);
    const [firstTime, setFirstTime] = useState(true);

    const {europeUnion}    = props
    let initialExpand   = europeUnion.flag === "positive"? false : true

    if (initialExpand !== expanded && firstTime===true){
        setExpanded(initialExpand);
    }

    const handleExpand = (event) => {
        event.stopPropagation();
        setFirstTime(false)
        setExpanded(!expanded);
    };

    return (
        <div className="analysisCard">
            <div style={{display:"flex"}}>
                <div 
                    className={["blueText", "subtitleSize", "bold"].join(" ")}
                    style={{margin:"auto 0px"}}
                >
                    União Europeia
                </div>
                <div style={{margin:"auto 10px"}}>
                    {getCardIcon(europeUnion.flag)}
                </div>
                {!!(((europeUnion || {}).data || []).length) ? 
                <IconButton style={{marginLeft: "auto"}} onClick={handleExpand}>
                    {expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </IconButton>      
                : null       
                }                    
            </div>
            {!!(((europeUnion || {}).data || []).length) ? 
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <div 
                    className={["normalText", "normalMediumSize", "internalCardContainer"].join(" ")}
                >
                    <EuropeUnionTable europeUnion_data={europeUnion.data}/>
                </div>
            </Collapse> 
            : null
            }     
        </div>
    );
}

export default EuropeUnionCard