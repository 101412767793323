import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse/Collapse';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

function SectionContainer (props) {

  const {isCollape} = props

  let sectionClasses = "sectionContainer"
  let sectionStyles = {}
  let sectionTitleStyle = {}
  let sectionSubtitleClassName = "normalSmallSize normalText"

  if (props.sectionClasses) {
    sectionClasses=props.sectionClasses
  }

  if (props.sectionStyles) {
    sectionStyles = props.sectionStyles
  }

  if (props.sectionTitleStyle) {
    sectionTitleStyle = props.sectionTitleStyle
  }

  if (props.sectionSubtitleClassName) {
    sectionSubtitleClassName = props.sectionSubtitleClassName
  }

  if (isCollape===true) {
    return (      
      <section 
        className={sectionClasses}
        style={sectionStyles}
      >
        <div style={{ display: 'flex', alignItems:"center"}}>
          {(props.sectionTitle||props.sectionSubtitle)&&
          <div style={{ display: 'flex', flexDirection:'column', margin:props.sectionSubtitle?"6.5px 0px":"8px 0px"}}>
            {props.sectionTitle&&
            <div className="normalMediumSize normalText" style={sectionTitleStyle}>{props.sectionTitle}</div>}
            {props.sectionSubtitle&&
            <div className={sectionSubtitleClassName}>
              {props.sectionSubtitle}
            </div>}
          </div>}
          <IconButton
            style={{ marginLeft: 'auto' }}
            onClick={props.collapeStateHandler}
          >
          {props.collapeState ? <ArrowDropUpIcon/>:<ArrowDropDownIcon/>}
          </IconButton>
        </div>
        <Collapse in={props.collapeState} timeout="auto" unmountOnExit>
          {props.children}
        </Collapse>
      </section>
    )
  } else {
    return (
      <section className={sectionClasses} style={sectionStyles} >
        {(props.sectionTitle||props.sectionSubtitle)&&
        <div style={{ display: 'flex', flexDirection:'column', margin:props.sectionSubtitle?"6.5px 0px":"8px 0px"}}>
          {props.sectionTitle&&
          <div className="normalMediumSize normalText" style={sectionTitleStyle}>{props.sectionTitle}</div>}
          {props.sectionSubtitle&&
          <div className={sectionSubtitleClassName}>
            {props.sectionSubtitle}
          </div>}
        </div>}
        {props.children}
      </section>
    )
  }
}

export default SectionContainer