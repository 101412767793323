import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
  },
  tableText: {
    fontFamily: "Open Sans",
    fontWeight: 'normal',
    fontSize: "12px",
    lineHeight: "16px",
    color: "#141414",
    textAlign: "center",
    margin: "10px",
    padding: "6px",
    minWidth: "120px"
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: '#6F6F6F',
    fontSize: "14px",
    lineHeight: "19px",
    padding: "6px",
  },
  even: {
    background: "#F3F3F3",
  },
  odd: {
    background: "#FFFFFF",
  },
}));

function OFACTable(props) {
  const classes = useStyles();
  useTheme();

  let counter = 0

  const getClass = (rowIndex) => {
    counter = counter + 1
    if (rowIndex % 2 === 0) {
      return classes.even;
    }

    return classes.odd;
  };

  const getToolTipText = (programs) => {
    return (
      <div>
        <span style={{color:"white"}}>{"Outros Programas: "}</span>
        {programs.slice(1,programs.length).map((program,index) => (
          <li key={index} style={{color:"white"}}>
            {program}
          </li>
          ))
        }
      </div>
    );
  }
  
  const renderProgram = (program) => {
    if (program.length === 1) {
      return (program[0])
    }    
    else {
      return (
        <div>
          <span>{program[0]}</span>
          <Tooltip title={<p className="tooltipText">{getToolTipText(program)}</p>}>
            <HelpIcon style={{marginLeft: "5px", width:"15px", display:"inline-flex", verticalAlign: 'middle'}} />
          </Tooltip> 
        </div>
      )
    }

  }

  return (
    <Paper className={classes.tableStyle}>
      <Table style={{ padding: "8px" }}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader}>Chave de Referência</TableCell>
            <TableCell className={classes.tableHeader}>Tipo de Chave</TableCell>
            <TableCell className={classes.tableHeader}>Nome</TableCell>
            <TableCell className={classes.tableHeader}>Observação</TableCell>
            <TableCell className={classes.tableHeader}>Programa</TableCell>
            <TableCell className={classes.tableHeader}>Outros Nomes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.ofac_data.map((key,index) => (
            key.information == null ? 
            <TableRow
              key={10*index}
              className={getClass(counter)}
            > 
              <TableCell style={{whiteSpace:"nowrap"}} className={classes.tableText}>
                {key.search_key}
              </TableCell>
              <TableCell style={{whiteSpace:"nowrap"}} className={classes.tableText}>
                {key.search_type === 'name'? 'Nome': key.search_type === 'address' ? 'Endereço' : null}
              </TableCell>
              <TableCell style={{whiteSpace: "nowrap"}} className={classes.tableText}>
                Nada encontrado
              </TableCell>
              <TableCell className={classes.tableText}>
                {}
              </TableCell>
              <TableCell className={classes.tableText}>
                {}
              </TableCell>
              <TableCell className={classes.tableText}>
                {}
              </TableCell>
            </TableRow>
            :
            key.information.map((match,index_2) => (
            <TableRow
              key={100*index + index_2}
              className={getClass(counter)}
            > 
              <TableCell style={{whiteSpace:"nowrap"}} className={classes.tableText}>
                {key.search_key}
              </TableCell>
              <TableCell style={{whiteSpace:"nowrap"}} className={classes.tableText}>
                {key.search_type === 'name'? 'Nome': key.search_type === 'address' ? 'Endereço' : key.search_type}
              </TableCell>
              <TableCell style={{whiteSpace: "nowrap"}} className={classes.tableText}>
                {match.name}
              </TableCell>
              <TableCell className={classes.tableText}>
                {match.observation}
              </TableCell>
              <TableCell className={classes.tableText}>
                {match.program == null ? "-" : match.program.length === 0 ? "-" : renderProgram(match.program)}
              </TableCell>
              <TableCell className={classes.tableText}>
                {match.other_names == null ? "-" : match.other_names.length === 0 ? "-" : match.other_names.join("; ")}
              </TableCell>
            </TableRow>
            ))))}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default OFACTable;
