import React from 'react'
import DataField from "../../utils/DataField"
import {getDevicePlatformName} from "../../utils/utilsTransaction"
import DeviceMap from "./DeviceMap/DeviceMap"
import {getBoolIcon} from "../../utils/utils"

function DeviceCard(props) {

    let device_scan_object = {
        device_coordinates: [-23.5971, -46.7003],
        suspect_app: true,
        violated_device: false
    }

    return (
        <div className="analysisCard">
            <div className={["blueText", "subtitleSize"].join(" ")}>Dispositivo</div>
            <div className="internalCardContainer">
                <DeviceMap coordinates={(device_scan_object || {}).device_coordinates}/>
            </div>
            <div className="internalCardContainer"  style={{display: "flex", flexDirection: "column"}}>
                <DataField
                    title={"Tipo de Dispositivo:"}
                    label={getDevicePlatformName(props.dict_operation.source.channel,props.dict_operation.source.platform)}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                />
                <DataField
                    title={"IP:"}
                    label={props.dict_operation.source.ip}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                />
                <div style={{display: "flex"}}>
                    <div style={{width: "50%", display:'flex', flexDirection: "column"}}>
                    <DataField
                        title={"Aplicativos Suspeitos? "}
                        label={getBoolIcon(device_scan_object.suspect_app)}
                        size={"normalMediumSize"}
                        titleNoWrap={false}
                        margin="5px"
                    />
                    <DataField
                        title={"Dispositivo Violado? "}
                        label={getBoolIcon(device_scan_object.violated_device)}
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        margin="5px"
                    />
                    <DataField
                        title={"Fraudes Anteriores? "}
                        label={getBoolIcon(false)}
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        margin="5px"
                    />
                    <DataField
                        title={"Contatos de Risco? "}
                        label={getBoolIcon(true)}
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        margin="5px"
                    />
                    </div>
                    <div style={{width: "50%", display:'flex', flexDirection: "column"}}>
                    <DataField
                        title={"Dispositivo Confiável? "}
                        label={getBoolIcon(false)}
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        margin="5px"
                    />
                    <DataField
                        title={"Modelo de Risco? "}
                        label={getBoolIcon(true)}
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        tooltipText="aaaaa"
                        margin="5px"
                    />
                    <DataField
                        title={"Região de Risco? "}
                        label={getBoolIcon(true)}
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        margin="5px"
                    />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeviceCard