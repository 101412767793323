import React from "react";
import {getIndicatorsFlagIcon} from "./utils"
import Tooltip from "@material-ui/core/Tooltip"
import { useTranslation } from "react-i18next";

function DataField(props) {
    const { t } = useTranslation();

    if (props.label == null) return null

    let displayType = "inline"
    let marginSize= "5px"
    let titleClasses = ["normalText", props.size]
    let labelClasses = ["labelText", props.size, "labelSpace"]
    let labelStyles = {verticalAlign: "middle", whiteSpace: "pre-wrap"}
    let titleStyles = {verticalAlign: "middle"}
    let isTranslated = true

    if (props.titleNoWrap){
        titleClasses.push("noWrap")
    }
    if (props.labelNoWrap){
        labelClasses.push("noWrap")
    }

    if (props.disabled){
        titleClasses.push("disabled")
        labelClasses.push("disabled")
    }

    if (props.margin){
        marginSize=props.margin
    }

    if (props.display){
        displayType=props.display
    }

    if (props.labelStyles){
        labelStyles={...labelStyles, ...props.labelStyles}
    }

    if (props.titleStyles){
        titleStyles={...titleStyles, ...props.titleStyles}
    }

    if (props.isTranslated!==undefined){
        isTranslated=props.isTranslated
    }

    return (
        <div style={{display: displayType, marginTop: marginSize, marginBottom: marginSize, lineHeight: "20px"}}>
            <span className={titleClasses.join(" ")} style={titleStyles}>
                {isTranslated?t(props.title):String(props.title)}:
            </span>
            <span className={labelClasses.join(" ")} style={labelStyles}>
                {isTranslated?t(props.label):String(props.label)}
            </span>
            {props.check == null ? null : 
            <Tooltip title={props.tooltipText == null ? "" : <p className="tooltipText">{props.tooltipText}</p>}>
                {getIndicatorsFlagIcon(props.check, "5px")}
            </Tooltip>}
            {props.children}        
        </div>
    );
}

export default DataField;