import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {getTableRowClass} from "../CardOrder/utils/utils"
import {getSeverityStatusSiglum, getSeverityColor, getSeverityStatus, getAlertStatusColor, getAlertStatusText, getAlertStatusTextSiglum} from "../CardOrder/utils/utilsAlert"
import { Link } from "react-router-dom";
import moment from 'moment'
import Tooltip from '@material-ui/core/Tooltip'
import { getAlertUrlFromEventGroup, getEventTypeName } from "../Alerts/utils/utilsAlert";

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
  },
  blueText: {
    fontFamily: "Open Sans",
    fontWeight: "600",
    color: "#0b1f82"
},
  tableText: {
    fontFamily: "Open Sans",
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "center",
    margin: "10px",
    padding: "6px",
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: '#6F6F6F',
    fontSize: "14px",
    lineHeight: "19px",
    padding: "6px",
  }
}));
function AlertsTable(props) {
  const classes = useStyles();
  useTheme();

  const {showEventType} = props

  return (
    <Paper className={classes.tableStyle}>
      <Table style={{ padding: "8px" }}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader}>ID</TableCell>
            {showEventType && 
            <TableCell className={classes.tableHeader}>Tipo do Evento</TableCell>}
            <TableCell className={classes.tableHeader}>Severidade</TableCell>
            <TableCell className={classes.tableHeader}>Nº de Gatilhos</TableCell>
            <TableCell className={classes.tableHeader}>Data de disparo</TableCell>
            <TableCell className={classes.tableHeader}>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.historic.map((alert,index) => (
            <TableRow
              key={index}
              className={getTableRowClass(index)}
            > 
              <TableCell
                className={[classes.tableText, classes.blueText, "breakWord", "link"].join(" ")}
              >
                <Link
                    className="link"
                    to={getAlertUrlFromEventGroup(alert.event_group) + alert.alert_key}
                >
                    {alert.alert_key}
                </Link>
              </TableCell>
              {showEventType &&
                <TableCell className={classes.tableText}>
                  {alert.event_type ? 
                    getEventTypeName(alert.event_type) :
                    " - "}
                </TableCell>}
              <TableCell className={classes.tableText}>
                <Tooltip title={<p className="tooltipText">{"Severidade do alerta: " + getSeverityStatus(alert.risk_level)}</p>}>
                    <div className={["circle", "small"].join(" ")} style={{backgroundColor:getSeverityColor(alert.risk_level)}}>
                        {getSeverityStatusSiglum(alert.risk_level)}
                    </div>
                </Tooltip>
              </TableCell>
              <TableCell className={classes.tableText}>
                {alert.triggers_count}
              </TableCell>
              <TableCell className={classes.tableText}>
                {moment(alert.occurrence_date).format("DD/MM/YYYY HH:mm:ss")}
              </TableCell>
              <TableCell className={classes.tableText}>
                <Tooltip title={<p className="tooltipText">{getAlertStatusText(alert.alert_status)}</p>}>
                    <div className={["circle", "small"].join(" ")} style={{backgroundColor:getAlertStatusColor(alert.alert_status)}}>
                        {getAlertStatusTextSiglum(alert.alert_status)}
                    </div>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default AlertsTable;
