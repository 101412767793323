import styled from 'styled-components'

export const Container = styled.div`
    padding-bottom: 32px;
    width: auto;
`;

export const SectionTitle = styled.p`
    color: #0b1f82;
    font-family: "Open Sans";
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.15px;
    font-weight: 600;
    margin: 20px 0;
`;

export const Wrapper = styled.div`
    grid-template-columns: 35% 65%;
    display: grid;
    
`;

export const LeftSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px;
    gap: 16px;
    grid-column: 1;
`;
export const LeftSectionHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

export const UsersLabel = styled.p`
    color: #0b1f82;
    font-family: "Open Sans";
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.15px;
    font-weight: 600;
    margin: 0;
`;

export const RightSection = styled.div`
    width: 100%;
    display: flex;
    padding: 16px;
    grid-column: 2;
`;

export const PageSelectorWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
`;

export const LeftButton = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-color: cyan;
    border: 1px solid darkblue;
    cursor: pointer;
`;

export const RightButton = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-color: lightblue;
    border: 1px solid darkblue;
    cursor: pointer;
`;

export const PageNumber = styled.p`
    font-family: "Open Sans";
    font-size: 16px;
    width: 16px;
    text-align: center;
    font-weight: 600;
    color: darkblue;
`;

export const ContentContainerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 100%;
`;