import React from "react";
import { useTranslation } from "react-i18next";

export const FilterOption = (props) => {
    
    const { id, labelValue, options, filters, setFilters } = props
    const { t } = useTranslation();

    function handleInputChange(event) {
        const { name, value } = event.target;
        setFilters({
            ...filters,
            [name]: value
        });
        if (props.onChange) {
            props.onChange()
        }
    }

    let hasEmptyOption = true
    if (props.hasEmptyOption===false) {
        hasEmptyOption = false
    }

    return (
        <div className="filter normalText normalSmallSize">
            <label htmlFor={id}>{t(labelValue)}:</label>
            <select
                name={id}
                value={filters[id]}
                onChange={handleInputChange}
            >
                {hasEmptyOption&&
                <option value="">{id?`${t("Selecione o")} ${t(id).toLowerCase()}`:""}</option>}
                {options.map((option) => (
                    <option key={option} value={option}>
                        {t(option)}
                    </option>
                ))}
            </select>
            <span className="normalSmallSize__error" style={{height:"13px"}}>
                {props.errorMessage}
            </span>
        </div>
    );
}

export default FilterOption;