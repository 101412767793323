import React from "react"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { getTableRowClass, translateFraudStatus, fraudStatusSiglum, fraudStatusColor, getProperMetadata, titleCase } from "../../../Onboarding/utils/utils"
import { Link } from "react-router-dom"
import Tooltip from "@material-ui/core/Tooltip"
import moment from 'moment'
import { matchedFieldTranslation } from '../../utils/utils'

const useStyles = makeStyles((theme) => ({
    tableStyle: {
        border: "none",
        boxShadow: "none",
    },
    blueText: {
        fontFamily: "Open Sans",
        fontWeight: "600",
        color: "#0b1f82"
    },
    redText: {
        fontFamily: "Open Sans",
        fontWeight: "600",
        color: "#D91A1A"
    },
    tableText: {
        fontFamily: "Open Sans",
        fontSize: "12px",
        lineHeight: "16px",
        textAlign: "center",
        margin: "10px",
        padding: "6px",
    },
    tableHeader: {
        textAlign: "center",
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: "normal",
        color: '#6F6F6F',
        fontSize: "14px",
        lineHeight: "19px",
        padding: "6px",
    }
}))

const getMatchedFields = (legal_persons) => {
    let matchedFieldsArray = []
    for (let legal_person of legal_persons) {
        matchedFieldsArray.push(Object.keys(legal_person['highlight']))
    }
    for (let matchedField of matchedFieldsArray) {
        if (matchedField.indexOf('company_key') > -1) {
            let ckIndex = matchedField.indexOf('company_key')
            matchedField.splice(ckIndex, 1)
        }
    }
    return matchedFieldsArray
}

const getRegistrationData = (status_events) => {

    let decision_metadata = getProperMetadata(status_events)
    let registration_data = (decision_metadata || {}).registration_data
    return registration_data
}

function LegalPersonSearchTable(props) {

    const matchedFieldsArray = getMatchedFields(props.historic)

    const classes = useStyles()
    useTheme()

    return (
        <>
            <Paper className={classes.tableStyle}>
                <Table style={{ padding: "8px" }}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHeader}  style={{ width: "5%" }}>ID</TableCell>
                            <TableCell className={classes.tableHeader}  style={{ width: "25%" }}>Razão Social</TableCell>
                            <TableCell className={classes.tableHeader}  style={{ width: "18%" }}>Doc</TableCell>
                            <TableCell className={classes.tableHeader}  style={{ width: "12%" }}>Status</TableCell>
                            <TableCell className={classes.tableHeader}  style={{ width: "20%" }}>Data</TableCell>
                            <TableCell className={classes.tableHeader}  style={{ width: "20%" }}>Campo encontrado</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.historic.map((legal_person, index) => (
                            <TableRow
                                key={index}
                                className={getTableRowClass(index)}
                            >
                                <TableCell
                                    className={[classes.tableText, "idOverflow"].join(" ")}
                                >
                                    {!(legal_person.analysis_status === "in_queue" || legal_person.analysis_status === "pending")
                                        ? <Link
                                            className={classes.blueText}
                                            to={"/legal-person/" + legal_person.legal_person_key}
                                        >
                                            {legal_person.id ? legal_person.id : "id"}
                                        </Link>
                                        : <div
                                            className="normalSmallSize bold"
                                            to={"/legal-person/" + legal_person.legal_person_key}
                                        >
                                            {legal_person.id ? legal_person.id : "id"}
                                        </div>}
                                </TableCell>
                                <TableCell className={classes.tableText}>
                                    <div className={
                                        matchedFieldsArray[index].includes("legal_name")
                                            ? "blueTextSearchAny"
                                            : null}
                                    >
                                        {legal_person.legal_name
                                            ? legal_person.legal_name
                                            : getRegistrationData(legal_person.analysis_status_events) == null
                                                ? "-"
                                                : (getRegistrationData(legal_person.analysis_status_events).legal_name || {}).description == null
                                                    ? "-"
                                                    : titleCase(getRegistrationData(legal_person.analysis_status_events).legal_name.description, "-")}
                                    </div>
                                </TableCell>
                                <TableCell className={classes.tableText}>
                                    <div className={
                                        matchedFieldsArray[index].includes("document_number")
                                            ? "blueTextSearchAny"
                                            : null}
                                    >
                                        {legal_person.document_number}
                                    </div>
                                </TableCell>
                                <TableCell className={classes.tableText}>
                                    <Tooltip title={<p className="tooltipText">{translateFraudStatus(legal_person.analysis_status)}</p>}>
                                        <div className={["circle", "small"].join(" ")} style={{ backgroundColor: fraudStatusColor(legal_person.analysis_status) }}>
                                            {fraudStatusSiglum(legal_person.analysis_status)}
                                        </div>
                                    </Tooltip>
                                </TableCell>
                                <TableCell className={classes.tableText}>
                                    {moment(legal_person.created_at).format("DD/MM/YYYY HH:mm:ss")}
                                </TableCell>
                                <TableCell className={classes.tableText}>
                                    {matchedFieldsArray[index].map(matchedField => matchedFieldTranslation(matchedField)).join(", ")}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </>
    )
}

export default LegalPersonSearchTable
