import React from "react"
import ErrorBoundary from "../../../Utils/ErrorBoundary"

const AnalysisCardContainer = (props) => {

    const { grow } = props

    let className = "analysisCardContainer"

    if (props.className) {
        className = props.className
    }

    return (
        <div  style={grow?{ flexGrow: "1" }:null} className={className}>
            <ErrorBoundary>
                {props.children}
            </ErrorBoundary>
        </div>
    )
}

export default AnalysisCardContainer