import React from "react";
import "../../../../../assets/styles/generalStyles.scss";
import { useTranslation } from "react-i18next";

const FormLabel = (props) => {

  const { t } = useTranslation();

  const dropdownChangeHandler = (event) => {
    props.onChangeFilter(event.target.value);
  };

  return (
    <div className={["dropdown"]}>
      <b className={["normalText"]}>{t(props.listName)}</b>
      <select value={props.selected} onChange={dropdownChangeHandler} disabled={props.disabled?props.disabled:false}>
        {props.items.map((item) => (
          <option key={item} value={item}>
            {t(item)}
          </option>
        ))}
      </select>
      <div className={"formMediumWhiteSpace"}></div>
    </div>
  );
};

export default FormLabel;
