import React from 'react';

function BaseCheckbox(props) {

  const { label, id, checked, onChange } = props

  let labelClasses="description-medium"
  let labelStyles = { verticalAlign: 'middle', marginLeft: '5px'}
  let inputStyles = {}
  let checkboxStyles = {}

  if (props.labelClasses) {
    labelClasses=props.labelClasses
  }
  if (props.labelStyles) {
    labelStyles=props.labelStyles
  }
  if (props.inputStyles) {
    inputStyles=props.inputStyles
  }
  if (props.checkboxStyles) {
    checkboxStyles=props.checkboxStyles
  }

  return (
    <div style={checkboxStyles}>
      <input
        type="checkbox"
        value={checked}
        id={id}
        checked={checked}
        onChange={onChange}
        style={inputStyles}
      />
      <label className={labelClasses} style={labelStyles} htmlFor={id}>{label}</label>
    </div>
  )
}

export default BaseCheckbox;