import React from "react";
import Collapse from "@material-ui/core/Collapse";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import IconButton from "@material-ui/core/IconButton";
import ScorePositivoScoreCard from "./ScorePositivoScoreCard/ScorePositivoScoreCard";
import ScorePositivoCreditLimitCard from "./ScorePositivoCreditLimitCard/ScorePositivoCreditLimitCard";
import ScorePositivoIncomeCard from "./ScorePositivoIncomeCard/ScorePositivoIncomeCard";
import ScorePositivoBasicDataCard from "./ScorePositivoBasicDataCard/ScorePositivoBasicDataCard";

function ScorePositivo(props) {

    let {score_positivo,
        expandedScorePositivo, 
        setExpandedScorePositivo
    } = props

    const ScorePositivo_data = score_positivo.data[0]

    const handleExpand = (event) => {
        event.stopPropagation();
        setExpandedScorePositivo(!expandedScorePositivo);
    };

    return (
        <div className="analysisCard">
            <div style={{display:"flex"}}>
                <div 
                    className={["blueText", "subtitleSize", "bold"].join(" ")}
                    style={{margin:"auto 0px"}}
                >
                    Score Positivo
                </div>
                <IconButton style={{marginLeft: "auto"}} onClick={handleExpand}>
                    {expandedScorePositivo ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </IconButton>
            </div>
            <Collapse in={expandedScorePositivo} timeout="auto" unmountOnExit>
                <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                    <div style={{display: "grid", gridTemplateColumns: '60% 40%'}}>
                        {(ScorePositivo_data)? 
                            <ScorePositivoBasicDataCard data={ScorePositivo_data.personal_data}/>
                        : null}
                        <div style={{display: "flex", flexDirection: "column"}} >
                            {(ScorePositivo_data || {}).score_positivo.score ?
                                <ScorePositivoScoreCard score={ScorePositivo_data.score_positivo.score}/>
                            : null}
                            {(ScorePositivo_data || {}).credit_limit.monthly_income ?
                                <ScorePositivoIncomeCard income={ScorePositivo_data.credit_limit.monthly_income}/>
                            : null}
                            {(ScorePositivo_data || {}).credit_limit.credit_limit_value ?
                                <ScorePositivoCreditLimitCard income={ScorePositivo_data.credit_limit.credit_limit_value}/>
                            : null}
                        </div>
                    </div>
                </div>
            </Collapse>
        </div>
    );
}

export default ScorePositivo
