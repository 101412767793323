import React, { useState, useEffect } from "react"
import ProtestsTable from "./ProtestsTable/ProtestsTable"
import Collapse from "@material-ui/core/Collapse"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp"
import IconButton from "@material-ui/core/IconButton"
import { getCardIcon } from "../../utils/utils"
import { useTranslation } from "react-i18next"

function ProtestsCard(props) {

    const { t } = useTranslation()

    const [expanded, setExpanded] = useState(false)
    const [firstTime, setFirstTime] = useState(true)

    const { protests, isPDF, isQuod } = props

    let initialExpand = isQuod 
                            ? protests?.has_protests 
                            : protests.flag 
                                ? protests.flag === "negative" 
                                : false

    if (initialExpand !== expanded && firstTime === true) {
        setExpanded(initialExpand)
    }

    const handleExpand = (event) => {
        event.stopPropagation()
        setFirstTime(false)
        setExpanded(!expanded)
    }

    useEffect(()=>{
        if(isPDF){
            setExpanded(true)
        }
    }, [isPDF])

    return (
        isQuod ? (
            <div className="avoidPageBreakInside analysisCard">
                <div style={{ display: "flex" }}>
                    <div
                        className={["blueText", "subtitleSize", "bold"].join(" ")}
                        style={{ margin: "auto 0px" }}
                    >
                        {t("protests")}
                    </div>
                    <div style={{ margin: "auto 10px" }}>
                        {getCardIcon(protests?.has_protests)}
                    </div>
                    {!isPDF&&protests?.details[0]?.notaries?.length > 0 &&
                        <IconButton style={{ marginLeft: "auto" }} onClick={handleExpand}>
                            {expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                        </IconButton>
                    }
                </div>
                {protests?.details[0]?.notaries?.length > 0 &&
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <div
                            className={["normalText", "normalMediumSize", "internalCardContainer"].join(" ")}
                        >
                            <ProtestsTable protests_data={protests?.details[0]?.notaries} isQuod={isQuod} />
                        </div>
                    </Collapse>
                }
            </div>
        ) : (
            <div className="avoidPageBreakInside analysisCard">
                <div style={{ display: "flex" }}>
                    <div
                        className={["blueText", "subtitleSize", "bold"].join(" ")}
                        style={{ margin: "auto 0px" }}
                    >
                        {t("protests")}
                    </div>
                    <div style={{ margin: "auto 10px" }}>
                        {getCardIcon(protests.flag)}
                    </div>
                    {protests.data?.length === 0 ? null :
                        <IconButton style={{ marginLeft: "auto" }} onClick={handleExpand}>
                            {expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                        </IconButton>
                    }
                </div>
                {protests.data?.length === 0 ? null :
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <div
                            className={["normalText", "normalMediumSize", "internalCardContainer"].join(" ")}
                        >
                            <ProtestsTable protests_data={protests.data} />
                        </div>
                    </Collapse>
                }
            </div>
        )
    )
}

export default ProtestsCard