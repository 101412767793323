import React, { useEffect } from "react";
import ValidatedImage from "../../utils/ValidatedImage"
import RGOCRDialog from "./RGOCRDialog/RGOCRDialog"
import { CircularProgress } from '@material-ui/core';
import axios from "axios";
import LegacyRGOCR from "./LegacyRGOCRCard/LegacyRGOCRCard"
import NewRGOCR from "./NewRGOCRCard/NewRGOCRCard"
import { useTranslation } from "react-i18next";

function RGOCRCard(props){

const { t } = useTranslation()

const { rg }                    = props
const { rg_verification }       = props.decision_metadata
const { document_verification } = props.decision_metadata

//Set initial states
const [RGOCR, setOCR]               = React.useState(null)
const [RGOCRFront, setOCRFront]     = React.useState(null)
const [RGOCRBack, setOCRBack]       = React.useState(null)

//Set initial state of image dialog box
let [OCRPictureOpen, setOCRPictureOpen] = React.useState(false);

//Set handlers to open and close dialog
const handleOCRPictureOpen = () => {
    setOCRPictureOpen(true);
};
const handleOCRPictureClose = () => {
    setOCRPictureOpen(false);
};

useEffect(() => {
    const timer = setTimeout(() => {
        if (rg.ocr_front_key) {
            axios.get('/dash/ocr/' + rg.ocr_front_key).then( response => {
                let ocr_data_front = response.data.processed_object 
                if (ocr_data_front == null) throw new Error()
                setOCRFront(ocr_data_front);             
            }).catch(error => {
                setOCRFront(undefined)
            })
        }

        if (rg.ocr_back_key) {
            axios.get('/dash/ocr/' + rg.ocr_back_key).then( response => {
                let ocr_data_back = response.data.processed_object 
                if (ocr_data_back == null) throw new Error()
                setOCRBack(ocr_data_back);             
            }).catch(error => {
                setOCRBack(undefined)
            })
        }
    }, 500);
    return () => {
        clearTimeout(timer)
    }
}, [rg])

useEffect(() => {
    if (RGOCRFront !== null || RGOCRBack!== null){
        if (RGOCRFront && RGOCRBack) {
                    let ocr_data_joined = {...RGOCRFront,...RGOCRBack}
                    setOCR(ocr_data_joined)
                }
        else if (RGOCRFront) setOCR(RGOCRFront)
        else if (RGOCRBack)  setOCR(RGOCRBack)
        else setOCR(undefined)
    }
},[RGOCRFront, RGOCRBack])

let getPictureContent = (picture) => {
    if (picture === undefined) {
        return (          
            <div 
                className={["labelText", "normalMediumSize"].join(" ")} 
                style={{textAlign:"center", margin:"auto"}}
            >
                Imagem Indisponível
            </div>
        )
    } 
    else{
        return (
            <ValidatedImage
                image_key={picture}
                dash_api_endpoint_url={'/dash/ocr/image'}
                imageStyle = {{margin:"auto", width:"100%", height:"100%",position:"absolute", top:"-9999px",bottom:"-9999px",left:"-9999px",right:"-9999px", borderRadius: "5px", objectFit:"cover"}}
                imageAlt = {"Foto do Documento"}
            />
        );
    }
};

if (RGOCR === null) {
    return (
        <div className="analysisCard" style={{minHeight: "250px"}}>
            <div className={["blueText", "subtitleSize"].join(" ")}>RG OCR</div>
            <div style={{display:"flex", flexGrow:"1"}}>
                <CircularProgress style={{margin:"auto"}} />
            </div>
        </div>
    );    
}
else if (RGOCR === undefined){
    return (
        <div className="analysisCard" style={{minHeight: "250px"}}>
            <div className={["blueText", "subtitleSize"].join(" ")}>OCR</div>
            <div style={{display:"flex", flexGrow:"1"}}>
                <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                    {t("information_unavailable")}
                </div>
            </div>
        </div>
    )
}
else {
    return (
        <div className="analysisCard" style={{minHeight: "450px"}}>
            <div className={["blueText", "subtitleSize"].join(" ")}>RG OCR</div>
            <div className="internalCardContainer" style={{display:"flex", flexGrow:"1", maxHeight:"450px",flexDirection: "row"}}>
                <div style={{display:"flex", width:"28%", maxHeight: "64vw", flexDirection: "column", marginRight:"31px"}}>
                    <div style={{display:"block", width:"100%", height:"90%", margin: "0 auto", overflow:"hidden", position:"relative", alignItems: "center", justifyContent: "center",borderRadius: "5px" }}>
                            {rg.ocr_front_key ? getPictureContent( rg.ocr_front_key ) : getPictureContent( rg.ocr_back_key )}
                    </div>
                    {(rg.ocr_front_key || rg.ocr_back_key) ? 
                    <div 
                        onClick={handleOCRPictureOpen}
                        className={["blueText", "normalSmallSize", "link"].join(" ")} 
                        style={{margin:"10px", textAlign:"center"}}
                    >
                        Ver fotos
                    </div> : null}
                </div>
                {Object.keys(rg_verification || {}).length!==0?
                    <LegacyRGOCR 
                        rg_verification={rg_verification}
                        RGOCR={RGOCR}
                    />:
                    <NewRGOCR 
                        document_verification={document_verification}
                        RGOCR={RGOCR}
                    />
                }
            </div>
            {(rg.ocr_front_key || rg.ocr_back_key) ? 
                <RGOCRDialog
                    open={OCRPictureOpen}
                    onClose={handleOCRPictureClose}
                    image_list={[rg.ocr_front_key, rg.ocr_back_key]}
            />: null}
        </div>
    );
}
}


export default RGOCRCard