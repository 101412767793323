import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next"
import { GetAlertsComponent, validateConstantValues, getConstantTextFields } from "./utils"
import NumberFormat from 'react-number-format'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Tooltip from "@material-ui/core/Tooltip"
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import HelpIcon from "@material-ui/icons/Help"

let this_box_type = "number"

export function ActionNumberOfInstallmentsBox(props) {
    const { t } = useTranslation()
    let { node, read_only, handleConfigurationOpen, handleNodeRemove } = props

    return (
        <div>
            <div style={{display:"flex", alignItems: "center"}}>
                <div
                    style={{ display: "flex", width: "100%", cursor: "pointer", margin: "0px" }}
                    onClick={() => handleConfigurationOpen(node)}
                >
                    <div style={{ display: "flex", flexDirection: "column", margin: "auto 0px" }}>
                        <div className={["normalText", "normalMediumSize"].join(" ")}>
                            {t(node.node.description)}
                        </div>
                        <div className={["normalText", "normalSmallSize"].join(" ")}>
                            {node.node.properties.number_of_installments_value ? t(node.node.properties.number_of_installments_value) : ""}
                        </div>
                    </div>
                    {!read_only && (node.node.alerts || []).length > 0 ?
                        <GetAlertsComponent alerts={node.node.alerts} /> : null}
                </div>
                {!read_only && <><IconButton style={{width: 32, height: 32}} aria-label="duplicate" onClick={() => props.handleNodeDuplication(node)}>
                        <ContentCopyIcon style={{width: 20, height: 20}} />
                    </IconButton>
                    <IconButton style={{width: 32, height: 32}} aria-label="close" onClick={() => handleNodeRemove(node)}>
                        <CloseIcon style={{width: 20, height: 20}} />
                    </IconButton></>}
            </div>
        </div>
    )
}

export function ActionNumberOfInstallmentsConfiguration(props) {
    const { t } = useTranslation()
    let { node, read_only, handleNodeChange, handleConfigurationClose } = props
    let standardTextField = getConstantTextFields(this_box_type)

    const [nodeState, setNodeState] = useState(node)

    useEffect(() => {
        setNodeState(node)
    }, [node])

    const handleNodeStateChange = (obj_key, value) => {
        let new_node = { ...nodeState }
        new_node.node.properties[obj_key] = value
        setNodeState(new_node)
    }

    const handleSave = () => {
        handleConfigurationClose()
        handleNodeChange(nodeState)
    }

    const validateAllValues = () => {
        return (
            (!!nodeState.node.properties.number_of_installments_value && Number(nodeState.node.properties.number_of_installments_value) > 0
                && Number.isInteger(nodeState.node.properties.number_of_installments_value))
        )
    }

    return (
        <div style={{ flexGrow: "1", padding: "20px", display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex" }}>
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>{t(node.node.description)}</span>
                <Tooltip title={<div className="tooltipText"><ul>{node.node.helperText.map((help, index) => (<li key={index}>{t(help)}</li>))}</ul></div>}>
                    <HelpIcon style={{ fontSize: "14px", display: "flex", margin: "auto 5px" }} />
                </Tooltip>
            </div>
            <div className="internalCardContainer" style={{ display: "flex", flexDirection: "column" }}>
                <NumberFormat
                    style={{ textAlign: "center", width: "80%", margin: "3px auto" }}
                    error={!validateConstantValues(this_box_type, nodeState.node.properties.number_of_installments_value)}
                    {...standardTextField.props}
                    value={nodeState.node.properties.number_of_installments_value}
                    onValueChange={(e) => handleNodeStateChange("number_of_installments_value", e.floatValue, e)}
                    InputProps={{
                        readOnly: read_only
                    }}
                />
            </div>
            <div style={{ display: "flex", margin: "auto auto 5px auto" }}>
                <div
                    className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")}
                    style={{ width: "120px" }}
                    onClick={handleConfigurationClose}
                >
                    {read_only ? "X" : t("discard")}
                </div>
                {!read_only &&
                    <div
                        className={
                            validateAllValues()
                                ?
                                ["button", "standard", "normalText", "normalMediumSize"].join(" ")
                                :
                                ["button", "standard", "normalText", "normalMediumSize", "disabled"].join(" ")}
                        style={{ width: "120px" }}
                        onClick={validateAllValues() ? handleSave : null}
                    >
                        {t("save")}
                    </div>}
            </div>
        </div>
    )
}

export class ActionNumberOfInstallmentsBoxClass {
    constructor(rulesContext) {
        this.rulesContext = rulesContext
    }

    validate(node) {
        let put_error = false
        let alerts = []

        if ((node.node.children || []).length !== 0) {
            put_error = true
            alerts.push("box_validation_no_children_allowed")
        }
        //Validate value is not length 0
        if (!validateConstantValues(this_box_type, node.node.properties.number_of_installments_value)) {
            put_error = true
            alerts.push("box_validation_no_null_value_allowed")
        }
        if (!Number.isInteger(node.node.properties.number_of_installments_value)) {
            put_error = true
            alerts.push("box_validation_value_must_be_a_intenger")
        }
        //Validate if parent is valid
        if (node.parentNode != null) {
            if (node.parentNode.on_error) {
                put_error = true
                alerts.push("box_validation_invalid_parent_node")
            }
        }
        return { validated: !put_error, alerts: alerts }
    }

    getType(node) {
        return 'void'
    }
}