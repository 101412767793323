import React, { useEffect } from "react";
import BoavistaSummaryTotal from "../BoavistaSummaryData/BoavistaSummaryTotal";
import BarChart from "../../../../utils/Components/BarChart"
import { useTranslation } from "react-i18next";
import { formatAsDate, handleHTML2Img } from "../../../../utils/utils"

function BoavistaInquiriesSummaryCard(props) {

    const { inquiries,
        setHasBoavistaInquiriesImg,
        setBoavistaInquiries2Img,
        preparePDF
    } = props

    const { t } = useTranslation()

    const dataObjHandler = (data,inquiries) => {
        for (let inquiry of inquiries.inquiries_by_periods) {
            if ((inquiry["number_of_events"]!==undefined)&&(inquiry["start_date"]!==undefined)){
                data.dataValues.push(inquiry["number_of_events"])
                data.dataLabels.push(formatAsDate(inquiry["start_date"].substring(0,7)))
            }
        }
        data.dataValues.reverse()
        data.dataLabels.reverse()
    }

    let data = {dataValues:[],dataLabels:[]}
    dataObjHandler(data,inquiries)

    const showInquiries = !!inquiries

    useEffect(()=>{
        if(showInquiries&&preparePDF){
            setHasBoavistaInquiriesImg(true)
            handleHTML2Img('boavistaInquiries2Img', setBoavistaInquiries2Img)
        }
    })


    return (
        <div style={{display: 'grid',gridTemplateColumns: '50% 50%',marginTop:'25px',marginBottom:'25px'}}>
            <BoavistaSummaryTotal
                description={"total_number_of_events_last_90_days"}
                total={inquiries.total_number_of_events_last_90_days}
            />
            <div style={{alignItems:"center", width:"550px"}}>
                <div 
                    className={["blueText", "normalMediumSize", "bold", 'internalColapse'].join(" ")}
                    style={{margin:"0px 20px", padding:"10px 40px", textAlign: 'center'}}
                >
                    {t("events_last_90_days")}
                </div>
                <div id="boavistaInquiries2Img" style={{width: '100%', size:"225px"}}>
                    <BarChart
                        id="inquiries_occurrences"
                        labels = {data.dataLabels}
                        label1={t("inquiries_occurrences")}
                        dataValue1 = {data.dataValues}
                    />
                </div>
            </div>
        </div>
    );
}

export default BoavistaInquiriesSummaryCard
                            