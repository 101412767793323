import React from 'react'
import {formatAddress,checkNested,titleCase,getAcquirer} from "../../../utils/utils"
import merchantIcon from "../../../../../assets/cardEntryIcons/MerchantIcon.svg"
import mapMarker from "../../../../../assets/cardEntryIcons/MapMarker.svg"

import MerchantMap from "./MerchantMap/MerchantMap"

function MerchantCard(props) {

    let address = checkNested(props.alert, "link_key_data", "merchant") ? formatAddress(props.alert.link_key_data.merchant) : null

    if (props.alert.link_key_data == null){
        return (
            <div className="analysisCard">
                <div style={{display: "flex"}}>
                    <img className="cardTitleIconStyle" src={merchantIcon} alt="merchantIcon Logo" />
                    <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>Merchant | Dados</span>
                </div>
                <div style={{display: "flex", height: "198px"}}>
                    <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                        Informação Indisponível
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="analysisCard" style={{flexDirection: "row"}}>
            <div style={{height:"220px", width:"40%", paddingRight:"10px", display:"flex", flexDirection:"column"}}>
                <div style={{display: "flex"}}>
                    <img className="cardTitleIconStyle" src={merchantIcon} alt="merchantIcon Logo" />
                    <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>Merchant | Dados</span>
                </div>
                <div style={{marginTop:"auto", marginBottom:"auto"}}>
                    <div className="internalCardContainer">
                        <div style={{display:"flex"}}>
                            <img className="cardTitleIconStyle" src={merchantIcon} alt="merchantIcon Logo" />
                            <div className={["labelText", "normalMediumSize", "labelSpace"].join(" ")}>
                                {props.alert.link_key_data.merchant == null ? "-" : titleCase(props.alert.link_key_data.merchant.name, "-")}                        
                            </div>
                        </div>
                        <div style={{display: "flex", margin: "5px 0px"}}>
                            <div className={["normalText", "normalMediumSize"].join(" ")}>
                                Adquirente:
                            </div>
                            <div className={["labelText", "normalMediumSize", "labelSpace"].join(" ")}>
                                {checkNested(props.alert.link_key_data, "merchant", "acquirer_id") ? getAcquirer(props.alert.link_key_data.merchant.acquirer_id) : "-" }
                            </div>                    
                        </div>
                        <div style={{display: "flex", margin: "5px 0px"}}>
                            <div className={["normalText", "normalMediumSize"].join(" ")}>
                                MCC:
                            </div>
                            <div className={["labelText", "normalMediumSize", "labelSpace"].join(" ")}>
                                {checkNested(props.alert.link_key_data, "merchant", "mcc") ? props.alert.link_key_data.merchant.mcc : "-" }
                            </div>                    
                        </div>
                    </div>
                    <div className="internalCardContainer" style={{display:"flex"}}>
                        <img className="cardTitleIconStyle" src={mapMarker} alt="mapMarker Logo" />
                        <div className="labelSpace">
                            <div className={["labelText", "normalMediumSize"].join(" ")}>
                                {address}
                            </div>
                            <div className={["labelText", "normalMediumSize"].join(" ")}>
                                {checkNested(props.alert.link_key_data, "merchant", "postal_code") ? "CEP: " + props.alert.link_key_data.merchant.postal_code : null }                        
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
            <div style={{height:"220px", width:"60%"}}>
                <MerchantMap address={address} />
            </div>
        </div>
    )
}

export default MerchantCard