import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
    width: "calc(100% - 40px)"
  },
  tableText: {
    fontFamily: "Open Sans",
    fontWeight: 'normal',
    fontSize: "12px",
    lineHeight: "16px",
    color: "#141414",
    textAlign: "center",
    margin: "10px",
    padding: "6px",
    minWidth: "120px"
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: '#6F6F6F',
    fontSize: "14px",
    lineHeight: "19px",
    padding: "6px",
  },
  even: {
    background: "#F3F3F3",
  },
  odd: {
    background: "#FFFFFF",
  },
}));

function CNEPTable(props) {
  const classes = useStyles();
  useTheme();

  const getClass = (rowIndex) => {
    if (rowIndex % 2 === 0) {
      return classes.even;
    }

    return classes.odd;
  };

  if (!Array.isArray(props.cnep_data) || props.cnep_data.length <= 0) {
    return <></>;
  }

  return (
    <Paper className={classes.tableStyle}>
      <Table style={{ padding: "8px" }}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader}>Documento</TableCell>
            <TableCell className={classes.tableHeader}>Razão Social</TableCell>
            <TableCell className={classes.tableHeader}>Número do Processo</TableCell>
            <TableCell className={classes.tableHeader}>Tipo de Sanção</TableCell>
            <TableCell className={classes.tableHeader}>Início</TableCell>
            <TableCell className={classes.tableHeader}>Final</TableCell>
            <TableCell className={classes.tableHeader}>Valor</TableCell>
            <TableCell className={classes.tableHeader}>Órgão Sancionador</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.cnep_data.map((process,index) => (
            <TableRow
              key={index}
              className={getClass(index)}
            > 
              <TableCell style={{whiteSpace:"nowrap"}} className={classes.tableText}>
                {process.document_number}
              </TableCell>
              <TableCell style={{whiteSpace: "nowrap"}} className={classes.tableText}>
                {process.social_name}
              </TableCell>
              <TableCell className={classes.tableText}>
                {process.process_number}
              </TableCell>
              <TableCell className={classes.tableText}>
                {process.sanction_type}
              </TableCell>
              <TableCell className={classes.tableText}>
                {process.start_date == null ? "-" : moment(process.start_date).format('DD/MM/YYYY')}
              </TableCell>
              <TableCell className={classes.tableText}>
                {process.end_date == null ? "-" : moment(process.end_date).format('DD/MM/YYYY')}
              </TableCell>
              <TableCell className={classes.tableText}>
                {process.value == null ? "-" : "R$ " + process.value}
              </TableCell>
              <TableCell className={classes.tableText}>
                {process.sanction_organ}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default CNEPTable;
