import React from "react";
import {getAlertsFlagIcon,getAlertsFlagColor} from "../../utils/utils"

function AlertCard(props) {

    let indicators = (((props.analysis_events || [])[0] || {}).decision_metadata || {}).indicators
    let signals = (((props.analysis_events || [])[0] || {}).decision_metadata || {}).signals

    indicators = indicators || signals

    if (indicators == null) {
        return (
        <div className="analysisCard">
            <div className={["blueText", "subtitleSize"].join(" ")}>Alertas</div>
            <div style={{display:"flex", flexGrow:"1"}}>
                <div className={["labelText", "subtitleSize"].join(" ")} style={{margin:"auto"}}>
                    Informação Indisponível
                </div>
            </div>
        </div>
        );        
    }
    else{
        return (
            <div className="analysisCard">
                <div className={["blueText", "subtitleSize"].join(" ")}>Alertas</div>
                <div className="internalCardContainer">
                    {indicators.map((indicator, index) => (
                        <div key={index} style={{display: "flex", margin: "5px 0px"}}>
                            <div style={{display:"flex", margin:"auto 0px"}}>
                                {getAlertsFlagIcon(indicator.flag)}
                            </div>
                            <div 
                                className={["normalText", "normalMediumSize", "labelSpace"].join(" ")} 
                                style={{marginTop:"auto",marginBottom:"auto", color: getAlertsFlagColor(indicator.flag)}}
                            >
                                {indicator.description}
                            </div>                         
                        </div>
                    ))}
                </div>
            </div>
            );
    }
}

export default AlertCard;
