import React, { useState } from "react";
import DataField from "../../utils/DataField";
import { checkNested, formatPhone, formatAddress } from "../../utils/utils";

import OCRDialog from "../../utils/Components/OCRDialog";
import { Fragment } from "react";
import DescriptionIcon from "@material-ui/icons/Description";
import { useTranslation } from "react-i18next";
import moment from "moment";

function LegalRepresentatives(props) {
  const { t } = useTranslation();

  let [OCRPictureOpen, setOCRPictureOpen] = useState(false);

  //Set handlers to open and close dialog
  const handleOCRPictureOpen = () => {
    setOCRPictureOpen(true);
  };
  const handleOCRPictureClose = () => {
    setOCRPictureOpen(false);
  };

  if (
    props.legal_person.legal_representatives === undefined ||
    props.legal_person.legal_representatives.length === 0
  ) {
    return (
      <div
        className="analysisCard"
        style={{ minHeight: "250px", height: "100%" }}
      >
        <div className={["blueText", "subtitleSize"].join(" ")}>
          {t("legal_representatives")}
        </div>
        <div style={{ display: "flex", flexGrow: "1" }}>
          <div
            className={["labelText", "normalMediumSize"].join(" ")}
            style={{ margin: "auto" }}
          >
            {t("information_unavailable")}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="analysisCard" style={{ height: "100%" }}>
      <div className={["blueText", "subtitleSize"].join(" ")}>
        {t("legal_representatives")}
      </div>
      {checkNested(props.legal_person, "legal_representatives") &&
        props.legal_person.legal_representatives.map(
          (legal_representative, index) => (
            <div
              key={index}
              className="internalCardContainer"
              style={{ display: "flex", flexDirection: "column" }}
            >
              {props.legal_person.legal_representatives.length !== 1 && (
                <div
                  className={["normalText", "normalMediumSize", "bold"].join(
                    " "
                  )}
                  style={{ marginBottom: "10px" }}
                >{`${[index + 1]}. ${t("legal_representative")}`}</div>
              )}
              {checkNested(
                legal_representative,
                "documents",
                "letter_of_attorney",
                "ocr_key"
              ) && (
                <div style={{ display: "flex" }}>
                  <div className={["normalText", "normalMediumSize"].join(" ")}>
                    {t("letter_of_attorney")}
                  </div>
                  <Fragment>
                    <DescriptionIcon
                      onClick={handleOCRPictureOpen}
                      style={{
                        verticalAlign: "middle",
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                        color: "#777",
                        margin: "auto 5px",
                      }}
                    />
                    <OCRDialog
                      name={"letter_of_attorney"}
                      open={OCRPictureOpen}
                      onClose={handleOCRPictureClose}
                      source_image={
                        legal_representative.documents.letter_of_attorney
                          .ocr_key
                      }
                      fileType={"pdf"}
                    />
                  </Fragment>
                </div>
              )}
              {checkNested(legal_representative, "name") && (
                <DataField
                  title={t("name")}
                  label={legal_representative.name}
                  size={"normalMediumSize"}
                  titleNoWrap={true}
                />
              )}
              {
                <DataField
                  title={t("document_number")}
                  label={
                    checkNested(legal_representative, "document_number")
                      ? legal_representative.document_number
                      : "Dado não recebido"
                  }
                  size={"normalMediumSize"}
                  titleNoWrap={true}
                />
              }
              {
                <DataField
                  title={t("birthdate")}
                  label={
                    checkNested(legal_representative, "birthdate")
                      ? moment(legal_representative.birthdate).format(
                          "DD/MM/YYYY"
                        )
                      : "Dado não recebido"
                  }
                  size={"normalMediumSize"}
                  titleNoWrap={true}
                />
              }
              {
                <DataField
                  title={t("mother_name")}
                  label={
                    checkNested(legal_representative, "mother_name")
                      ? legal_representative.mother_name
                      : "Dado não recebido"
                  }
                  size={"normalMediumSize"}
                  titleNoWrap={true}
                />
              }
              {
                <DataField
                  title={t("address")}
                  label={
                    checkNested(legal_representative, "address")
                      ? formatAddress(legal_representative.address)
                      : "Dado não recebido"
                  }
                  size={"normalMediumSize"}
                  titleNoWrap={true}
                ></DataField>
              }
              {
                <DataField
                  title={"Telefone"}
                  label={
                    checkNested(legal_representative, "phones")
                      ? formatPhone(legal_representative.phones[0])
                      : "Dado não recebido"
                  }
                  size={"normalMediumSize"}
                  titleNoWrap={true}
                ></DataField>
              }
            </div>
          )
        )}
    </div>
  );
}

export default LegalRepresentatives;
