import React, { useEffect, useContext, useReducer, useState, useCallback } from "react";
import { dataFetchReducer } from "../../Utils/requestUtils";
import AuthContext from "../../../context/auth-context"
import { useParams } from "react-router-dom";
import axios from "axios";
import ClientProfile from "./ClientProfile/ClientProfile";
import ClientProfileAnalyst from "./ClientProfile/ClientProfileAnalyst";
import AnalystSnackBar from "./AnalystSnackBar/AnalystSnackBar";
import Summary from "./Summary/Summary";
import Partners from "./Partners/Partners";
import Indicators from "./Indicators/Indicators"
import DeviceScan from "./DeviceScan/DeviceScan"
import EconomicalActivityCard from "./Cards/EconomicalActivityCard/EconomicalActivityCard"
import RiskActivityCard from "./Cards/RiskActivityCard/RiskActivityCard"
import MediaCard from "./Cards/MediaCard/MediaCard"
import ProcessesEDPCard from "../../../components/Cards/ProcessesEDP";
import DAFNCard from "./Cards/DAFNCard/DAFNCard"
import IbamaCard from "./Cards/IbamaCard/IbamaCard"
import ProtestsCard from "./Cards/ProtestsCard/ProtestsCard"
import SlaveryCard from "./Cards/SlaveryCard/SlaveryCard"
import CNDTCard from "./Cards/CNDTCard/CNDTCard"
import CNDFCard from "./Cards/CNDFCard/CNDFCard"
import CNJIneligibilityCard from "./Cards/CNJIneligibilityCard/CNJIneligibilityCard"
import CVMCard from "./Cards/CVMCard/CVMCard"
import OFACCard from "./Cards/OFACCard/OFACCard"
import SintegraCard from "./Cards/SintegraCard/SintegraCard"
import UNSCCard from "./Cards/UNSCCard/UNSCCard"
import RiskAddressCard from "./Cards/RiskAddressCard/RiskAddressCard"
import IRCard from "./Cards/IRCard/IRCard"
import UKCard from "./Cards/UKCard/UKCard"
import EUCard from "./Cards/EUCard/EUCard"
import ProcessesCard from "./Cards/ProcessesCard/ProcessesCard"
import InterpolCard from "./Cards/InterpolCard/InterpolCard"
import WorldBankCard from "./Cards/WorldBankCard/WorldBankCard"
import PartnershipCard from "./Cards/PartnershipCard/PartnershipCard"
import CustomDataCard from "../Cards/CustomDataCard/CustomDataCard"
import Observation from "./Observation/Observation"
import moment from 'moment'
import ErrorBoundary from '../../Utils/ErrorBoundary'
import { useHistory} from 'react-router-dom'
import {checkNested, getProperMetadata} from "../utils/utils";
import LegacyClientProfile from "./ClientProfile/legacy/ClientProfile";
import FaceReconCard from "../Cards/FaceReconCard/FaceReconCard";
import LegacyCallout from "../../../components/legacyCallout/LegacyCallout";
import LegacyClientProfileAnalyst from "./ClientProfile/legacy/ClientProfileAnalyst";
import PDFDownloadErrorDialog from "../utils/PDFDownload/PDFDownloadErrorDialog/PDFDownloadErrorDialog"
import PDFDownloadButton from "../utils/PDFDownload/PDFDownloadButton/PDFDownloadButton"
import FGTSCard from "./Cards/FGTS/FGTSCard";
import ElectoralDonorsCard from "./Cards/ElectoralDonorsCard/ElectoralDonorsCard";
import LegalRepresentatives from "./LegalRepresentatives/LegalRepresentatives";
import CNHOCRCard from "../Cards/CNHOCRCard/CNHOCRCard";
import RGOCRCard from "../Cards/RGOCRCard/RGOCRCard";
import {CircularProgress } from '@material-ui/core'
import History from "./History/History";
import AdministrativeSanctionsCard from "./Cards/AdministrativeSanctionsCard";
import CompanyStatuteCard from "../Cards/CompanyStatuteCard/CompanyStatuteCard";
import FinalBeneficiariesCard from "../Cards/FinalBeneficiariesCard";
import Serasa from "./Cards/SerasaCard/Serasa"
import AnalysisCardContainer from "../utils/Components/AnalysisCardContainer"

function LegalPerson(props) {
    let history = useHistory()
    let user_data = useContext(AuthContext).user_data
	const [legacy, setLegacy] = useState(false);
	const LegacyText = "Essa secção foi recentemente aprimorada para melhorar a visualização dos dados. Em breve, essa atualização será implementada permanentemente."
    
    if (
			!user_data.roles.includes("read_onboarding_legal_persons")&&
			!user_data.roles.includes("card_issuance_transaction_read_onboarding_legal_person_by_key")&&
			!user_data.roles.includes("card_issuance_alert_read_onboarding_legal_person_by_key")
		){
      history.push("")
    }
	let { legal_person_key } = useParams();

	const [currentAnalyst, setCurrentAnalyst] = useState(null)

    const [expandedSerasa, setExpandedSerasa] = useState(true)

    const [legal_person, dispatchLegalPerson] = useReducer(
        dataFetchReducer,
        {fetchedData: null, isLoading: true, isError: false} 
    )
	const [pdfErrorDialogOpen, setPDFErrorDialogOpen] = useState(false)

	const [pdfErrorDialogType, setPDFErrorDialogType] = useState("")

	const [snackBarOpen, setSnackBarOpen] = useState(true)

	const handlePDFErrorDialogOpen = (errorType) => {
		setPDFErrorDialogType(errorType)
		setPDFErrorDialogOpen(true)
	};
	
	const handlePDFErrorDialogClose = () => {
		setPDFErrorDialogType("")
		setPDFErrorDialogOpen(false)
	};

	const showCard = {
		face_recon: checkNested(legal_person.fetchedData,"legal_representatives")&&checkNested((legal_person.fetchedData.legal_representatives||[{}])[0],"face","registration_key"),
		rg_ocr_card: checkNested(legal_person.fetchedData, "legal_representatives")&&(legal_person.fetchedData.legal_representatives.length!==0)&&(((legal_person.fetchedData.legal_representatives[0].documents || {}).rg || {}).ocr_front_key || ((legal_person.fetchedData.legal_representatives[0].documents || {}).rg || {}).ocr_back_key),
		cnh_ocr_card: checkNested(legal_person.fetchedData, "legal_representatives")&&(legal_person.fetchedData.legal_representatives.length!==0)&&(((legal_person.fetchedData.legal_representatives[0].documents || {}).cnh || {}).ocr_key || ((legal_person.fetchedData.legal_representatives[0].documents || {}).cnh || {}).ocr_front_key ||((legal_person.fetchedData.legal_representatives[0].documents || {}).cnh || {}).ocr_back_key),
		company_statute_card: checkNested(legal_person.fetchedData, "documents")&&(legal_person.fetchedData.documents.length!==0)&&((legal_person.fetchedData.documents.company_statute || {}).ocr_key),
		legal_representatives: checkNested(legal_person.fetchedData, "legal_representatives")&&(legal_person.fetchedData.legal_representatives.length!==0),
		partnership_card: checkNested(legal_person.fetchedData,"partnership_key"),
		device_scan: checkNested(legal_person.fetchedData,"source", "session_id")
	}

	let inputDataWidth = "50%"
	let outputDataWidth = "50%"
	if ((showCard.legal_representatives)) {
		inputDataWidth = "66.6%"
		outputDataWidth = "33.3%"
	}

    useEffect(() => {
		dispatchLegalPerson({type: "data_fetch_init"})
		const timer = setTimeout(() => {
			axios.get('/dash/onboarding/legal_person/' + legal_person_key).then( response => {
                dispatchLegalPerson({
                    type: "data_fetch_success",
                    payload: response.data
                })   
			}).catch(error => {
                if ((error.response || {}).status === 403) dispatchLegalPerson({type: "data_fetch_failure_403"})
                else if ((error.response || {}).status === 404) dispatchLegalPerson({type: "data_fetch_failure_404"})  
                else dispatchLegalPerson({type: "data_fetch_failure"})    
			})	
		}, 500);
		return () => {
			clearTimeout(timer)
        }
    }, [legal_person_key])

	const analystHeartBeat = useCallback(
        () => {
			if (user_data.roles.includes("onboarding_analyst_heartbeat")) {
				let payload = {}
				axios.put('/dash/onboarding/legal_person/' + legal_person_key + "/analyst_heartbeat", payload).then(response => {
					setCurrentAnalyst(response.data.analyst)
				}).catch(error => { console.log(error) })
			}
        }, [user_data.roles, legal_person_key]
    )

	useEffect(() => {
		analystHeartBeat(true)
		const timer = setInterval(() => {
			analystHeartBeat(true)
		}, 15000)
		return () => {
			clearInterval(timer)
			analystHeartBeat(false)
		}
    }, [analystHeartBeat])

	const [hasPartnersInManualAnalysis, setHasPartnersInManualAnalysis] = useState(false);

	useEffect(() => {
		if (legal_person.fetchedData) {
			if (legal_person.fetchedData.partners) {
				for (let i = 0; i < (legal_person.fetchedData.partners || []).length; i++) {
					if (legal_person.fetchedData.partners[i].analysis_status === 'in_manual_analysis') {
						setHasPartnersInManualAnalysis(true);
					}
				}
			}
		}
	}, [legal_person])

 	if (legal_person.fetchedData) {
        let decision_metadata = getProperMetadata(legal_person.fetchedData.analysis_status_events)
		
        let hasAutomaticDecision = (legal_person.fetchedData.analysis_status === "automatically_approved" || legal_person.fetchedData.analysis_status === "automatically_reproved" ) 
		let showObservationCard = !hasAutomaticDecision

		const selectProcessesTable = (registration_date, decision_metadata) => {
			if (!(decision_metadata || {}).processes) return <></>
			const date = new Date(registration_date);
			const index_creation_date = new Date('2024-06-09T20:39:46Z');
			if (date.getTime() > index_creation_date.getTime()) {
				return (
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<ProcessesEDPCard person_type={'legal_person'} person_key={legal_person_key} consolidated={decision_metadata.processes} company_key={legal_person.fetchedData?.company_key}/>
						</ErrorBoundary>
					</div> 
				);
			}

			return (
				<div className="analysisCardContainer">
					<ErrorBoundary>
						<ProcessesCard processes={decision_metadata.processes}/>
					</ErrorBoundary>
				</div> 
			);
		}

		return(
			<div style={{minWidth: "900px", overflow: "hidden", paddingBottom: "30px"}}>
				<div className="analysisCardContainer" style={{display:"flex"}}>
					<ErrorBoundary>                    
						<div>
							<div className={["blueText", "titleSize"].join(" ")} style={{margin:"5px 0px"}}>
								Análise {Object.keys(decision_metadata || {}).length === 0 ?
								'pendente no sistema' : (decision_metadata || {}).rule_identifier} - Pessoa Jurídica
								{user_data.business_group_key == null ? null : "- " + legal_person.fetchedData.company_name}
							</div>
							<div className={["labelText", "subtitleSize"].join(" ")} style={{margin:"5px 0px"}}>
								Cliente {legal_person.fetchedData.analyst_request === true ? 
									"requisitado por " + (legal_person.fetchedData.analysis_status_events || [{}])[0].analyst_name + " no dia " + moment(legal_person.fetchedData.registration_date).format("DD/MM/YYYY")  + " às " + moment((legal_person.fetchedData.analysis_status_events || [{}])[0].event_date).format("HH:mm") :
									"#" + legal_person.fetchedData.id + " registrado no dia " + moment(legal_person.fetchedData.registration_date).format("DD/MM/YYYY") + " às " + moment(legal_person.fetchedData.registration_date).format("HH:mm")}
							</div>
						</div>
						{user_data.roles.includes("read_pdf_onboarding") &&
						<div style={{margin: "auto 50px auto auto"}}>
							<PDFDownloadButton personKey={legal_person_key} person={legal_person.fetchedData} type={'legal'}
								dashAPIEndpointUrl={"/dash/onboarding/legal_person"}
								handlePDFDownloadErrorDialogOpenClick={handlePDFErrorDialogOpen}/>
						</div>}
					</ErrorBoundary>
				</div>
				<div style={{display: "flex", margin: "10px 0px"}}>
					<div style={{width: inputDataWidth, display:"flex", flexDirection:"column"}}>
						{showCard.partnership_card&&
						<div style={{flexGrow: "1"}} className="analysisCardContainer">
							<ErrorBoundary>
								<PartnershipCard partnership_key={legal_person.fetchedData.partnership_key}/>
							</ErrorBoundary>
						</div>}
						{legacy ? showCard.face_recon ?

							<div className="analysisCardContainer" style={{display: 'flex', flexDirection: 'column'}}>
								<LegacyCallout legacy={legacy} setLegacy={setLegacy} text={LegacyText}/>
								<div className="analysisCardContainer" style={{display:"grid", gridTemplateColumns: "50% 50%", margin: 0}}>
									<div style={{marginRight:"10px"}}>
										<ErrorBoundary>
											<FaceReconCard
												name={"client_selfie"}
												registration_key={legal_person.fetchedData.legal_representatives[0].face.registration_key}
											/>
										</ErrorBoundary>
									</div>
									<div style={{marginLeft:"10px"}}>
									<ErrorBoundary>
										{legal_person.fetchedData.analyst_request === true ?
										<LegacyClientProfileAnalyst legal_person={legal_person.fetchedData}/> :						
										<LegacyClientProfile legal_person={legal_person.fetchedData}/>}
									</ErrorBoundary>
									</div>
								</div>
							</div>
							:
							<div className="analysisCardContainer" style={{flexGrow: "1", display: 'flex', flexDirection: 'column'}}>
								<LegacyCallout legacy={legacy} setLegacy={setLegacy} text={LegacyText}/>
								<ErrorBoundary>
									{legal_person.fetchedData.analyst_request === true ?
									<LegacyClientProfileAnalyst legal_person={legal_person.fetchedData}/> :
									<LegacyClientProfile legal_person={legal_person.fetchedData}/>}
								</ErrorBoundary>
							</div>	
							:
							<div className="analysisCardContainer" style={{height:"100%", flexGrow: "1", display: 'flex', flexDirection: 'column'}}>
								<LegacyCallout legacy={legacy} setLegacy={setLegacy} text={LegacyText}/>
								<ErrorBoundary>
									{legal_person.fetchedData.analyst_request === true?
									<ClientProfileAnalyst legal_person={legal_person.fetchedData}/> :
									<ClientProfile legal_person={legal_person.fetchedData}/>}
								</ErrorBoundary>
							</div>
						}
						{showCard.legal_representatives?
						<div className="analysisCardContainer" style={{display:"grid", gridTemplateColumns: "50% 50%", flexGrow: "1"}}>
							<div style={{marginRight:"10px", flexGrow: "1"}}>
								<ErrorBoundary>
									<LegalRepresentatives legal_person={legal_person.fetchedData}/>
								</ErrorBoundary>
							</div>
							<div style={{marginLeft:"10px", flexGrow: "1"}}>
								<ErrorBoundary>
									<Partners legal_person={legal_person.fetchedData}/>
								</ErrorBoundary>
							</div>
						</div>
						:
						<div className="analysisCardContainer" style={{height:"100%", flexGrow: "1"}}>
							<ErrorBoundary>
								<Partners legal_person={legal_person.fetchedData}/>
							</ErrorBoundary>
						</div>}
						{showCard.cnh_ocr_card&&
						<div className="analysisCardContainer">
							<ErrorBoundary>
								<CNHOCRCard cnh= {legal_person.fetchedData.legal_representatives[0].documents.cnh} decision_metadata = {decision_metadata}/>
							</ErrorBoundary>
						</div>}
						{showCard.rg_ocr_card&&
						<div className="analysisCardContainer">
							<ErrorBoundary>
								<RGOCRCard rg= {legal_person.fetchedData.legal_representatives[0].documents.rg} decision_metadata = {decision_metadata}/>
							</ErrorBoundary>
						</div>}
						{showCard.company_statute_card&&	
						<div className="analysisCardContainer">
							<ErrorBoundary>
								<CompanyStatuteCard ocr={legal_person.fetchedData.documents.company_statute} ocr_type={"company_statute"} decision_metadata = {decision_metadata}/>
							</ErrorBoundary>
						</div>}
						{showCard.device_scan &&
						<div style={{flexGrow: "1", flexDirection: "column"}} className="analysisCardContainer">
							<ErrorBoundary>
								<DeviceScan legal_person={legal_person.fetchedData}/>
							</ErrorBoundary>
						</div>}
						{legal_person.fetchedData.custom_data&&
						<div className="analysisCardContainer" style={{flexGrow: "1"}}>
							<ErrorBoundary>
								<CustomDataCard data={legal_person.fetchedData} />
							</ErrorBoundary>
							</div>}
						</div>
						<div style={{width: outputDataWidth, display:"flex", flexDirection:"column"}}>
							<div style={{flexGrow: "1"}} className="analysisCardContainer">
								<ErrorBoundary>
									<Summary legal_person={legal_person.fetchedData}/>
								</ErrorBoundary>
							</div>
											{((decision_metadata || {}).indicators || []).length > 0 &&
							<div style={{flexGrow: "1"}} className="analysisCardContainer">
								<ErrorBoundary>
									<Indicators indicators={decision_metadata.indicators}/>
								</ErrorBoundary>
							</div>}
						</div>
					</div>
					<div>
						<div className="analysisCardContainer">
							<ErrorBoundary>
								<History legalPerson={legal_person.fetchedData}/>
							</ErrorBoundary>
						</div>
					</div>
					<div style={{display:"flex", flexDirection:"column", gap:"2px"}}>
                    {(decision_metadata || {}).risk_activity ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<RiskActivityCard risk_activity={decision_metadata.risk_activity}/>
						</ErrorBoundary>
					</div> : null}
                    {(decision_metadata || {}).economical_activity ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<EconomicalActivityCard economical_activity={decision_metadata.economical_activity}/>
						</ErrorBoundary>
					</div> : null}					
                    {(decision_metadata || {}).media_exposure ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<MediaCard media_exposure={decision_metadata.media_exposure}/>
						</ErrorBoundary>
					</div> : null}
                    {selectProcessesTable(legal_person.fetchedData.registration_date, decision_metadata)}
					{(decision_metadata || {}).final_beneficiaries && 
						<div className="analysisCardContainer">
							<FinalBeneficiariesCard metadata={decision_metadata}/>
						</div>
					}
					{((decision_metadata || {}).ceaf || (decision_metadata || {}).cepim || (decision_metadata || {}).ceis || (decision_metadata || {}).cnep) &&
						<ErrorBoundary>
							<AdministrativeSanctionsCard cepim={(decision_metadata || {}).cepim} ceis={(decision_metadata || {}).ceis} cnep={(decision_metadata || {}).cnep} ceaf={(decision_metadata || {}).ceaf}/>
						</ErrorBoundary>
					}
                    {(decision_metadata || {}).dafn ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<DAFNCard dafn={decision_metadata.dafn}/>
						</ErrorBoundary>
					</div> : null}
                    {(decision_metadata || {}).ibama ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<IbamaCard ibama={decision_metadata.ibama}/>
						</ErrorBoundary>
					</div> : null}
                    {(decision_metadata || {}).protests ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<ProtestsCard protests={decision_metadata.protests}/>
						</ErrorBoundary>
					</div> : null}
                    {(decision_metadata || {}).slavery ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<SlaveryCard slavery={decision_metadata.slavery}/>
						</ErrorBoundary>
					</div> : null}
                    {(decision_metadata || {}).ofac ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<OFACCard ofac={decision_metadata.ofac}/>
						</ErrorBoundary>
					</div> : null}
                    {(decision_metadata || {}).unsc ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<UNSCCard unsc={decision_metadata.unsc}/>
						</ErrorBoundary>
					</div> : null}
                    {(decision_metadata || {}).cnj_ineligibility ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<CNJIneligibilityCard cnj_ineligibility={decision_metadata.cnj_ineligibility}/>
						</ErrorBoundary>
					</div> : null}
                    {(decision_metadata || {}).cvm ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<CVMCard cvm={decision_metadata.cvm}/>
						</ErrorBoundary>
					</div> : null}
                    {(decision_metadata || {}).risk_address ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<RiskAddressCard risk_address={decision_metadata.risk_address}/>
						</ErrorBoundary>
					</div> : null}
                    {(decision_metadata || {}).uk_sanctions ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<UKCard unitedKingdom={decision_metadata.uk_sanctions}/>
						</ErrorBoundary>
					</div> : null}
					{(decision_metadata || {}).eu_sanctions ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<EUCard europeUnion={decision_metadata.eu_sanctions}/>
						</ErrorBoundary>
					</div> : null}
                    {(decision_metadata || {}).interpol ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<InterpolCard interpol={decision_metadata.interpol}/>
						</ErrorBoundary>
					</div> : null}
					{(decision_metadata || {}).world_bank ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<WorldBankCard worldBank={decision_metadata.world_bank}/>
						</ErrorBoundary>
					</div> : null}
                    {(decision_metadata || {}).cndt ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<CNDTCard cndt={decision_metadata.cndt}/>
						</ErrorBoundary>
					</div> : null}
                    {(decision_metadata || {}).cndf ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<CNDFCard cndf={decision_metadata.cndf}/>
						</ErrorBoundary>
					</div> : null}
					{(decision_metadata || {}).electoral_donors &&
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<ElectoralDonorsCard electoral_donors={decision_metadata.electoral_donors}/>
						</ErrorBoundary>
					</div>
					}
                    {(decision_metadata || {}).sintegra ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<SintegraCard sintegra={decision_metadata.sintegra}/>
						</ErrorBoundary>
					</div> : null}
                    {(decision_metadata || {}).receita_federal_status ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<IRCard receita_federal_status={decision_metadata.receita_federal_status}/>
						</ErrorBoundary>
					</div> : null}
					{(decision_metadata || {}).fgts ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<FGTSCard fgts={decision_metadata.fgts}/>
						</ErrorBoundary>
					</div> : null}
					{(decision_metadata || {}).serasa?.document_number && [
						'3d949b77-0c91-45ce-abce-ac6be3638fc9', // Production - Seven Pounds
						'64520138-3e5b-4c2a-856a-33e1b199b0cb', // Sandbox - Zaig
						'123-123-123' // Local - Test Company
					].includes(user_data.company_key) &&
					<AnalysisCardContainer grow={true}>
						<Serasa
							serasa={decision_metadata.serasa}
							setExpandedSerasa={setExpandedSerasa}
							expandedSerasa={expandedSerasa}
						/>
					</AnalysisCardContainer>}
					{showObservationCard && 
						<div className="analysisCardContainer">
							<ErrorBoundary>
							<Observation has_partners_in_manual_analysis={hasPartnersInManualAnalysis} legal_person={legal_person.fetchedData} legal_person_key={legal_person_key} user_data={user_data}/>
							</ErrorBoundary>
						</div>}
				</div>
				{["pending", "in_manual_analysis"].includes(legal_person.fetchedData.analysis_status) && user_data.roles.includes("onboarding_analyst_heartbeat") && currentAnalyst != null ?
					<AnalystSnackBar
						currentAnalyst={currentAnalyst}
						open={snackBarOpen}
						onClose={() => setSnackBarOpen(false)}
					/> : null}
				<PDFDownloadErrorDialog open={pdfErrorDialogOpen}
										onClose={handlePDFErrorDialogClose}
										errorType={pdfErrorDialogType}/>
			</div>
		);
	}
	else if (legal_person.isLoading) {
		return(
			<div style={{ width: "auto" }}>
				<div className='circularProgressCenter'>
					<CircularProgress />
				</div>
			</div>
		)
    }
	else if(legal_person.isError){
		return (
            <div style={{height: "80vh", display:"flex"}}>
                <p className={["blueText", "titleSize"].join(" ")} style={{margin:"auto", textAlign:"center"}}>
                    {legal_person.errorMessage}
                </p>
            </div>
		)
	}
  
  return null;
}

export default LegalPerson;