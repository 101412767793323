import React, {useState, useReducer} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { CircularProgress } from '@material-ui/core';
import {decisionReducer} from "../../../Utils/requestUtils"
import axios from 'axios'
import ConfirmationContent from "./ConfirmationContent"
import {translateLockType} from "../../utils/utilsLock"

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const theme = createMuiTheme({
    overrides: { MuiInputBase: { root:{
        fontFamily: "Open Sans",
        fontSize: "14px",
        lineHeight: "19px",
        background: "#F7F7F7"

    }}},
    palette:{
        primary: {
            main:"#0b1f82"
        },
        secondary:{
            main:"#D91A1A"
        }
    }
  });

const DialogTitle = withStyles(styles)((props) => {
const { children, classes, onClose, ...other } = props;
return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
    <Typography variant="h6">{children}</Typography>
    {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
        </IconButton>
    ) : null}
    </MuiDialogTitle>
);
});



const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: "16px",
    color: "#0b1f82",
    padding: "16px 24px 0px 24px",
  },
  dialogContent: {
    width: "480px",
    height: "240px",
    marginBottom: "10px",
    float: "left",
    display:"flex",
    flexDirection:"column"
  },
  textClass: {
	fontFamily: "Open Sans",
	fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "19px"
  }
}));

function CreateLockDialog(props) {
    const {alert} = props
    let {open} = props
    let {onClose} = props

    const classes = useStyles();
    useTheme();
    
    const [lockType, setLockType] = useState(alert.link_key_type === "customer" ? "customer" : null)
    const [observation, setObservation] = useState("")

    const handleObservationBoxChange = (event) => {
        setObservation(event.target.value)
    };

    const [dialogState, dispatchDialogState] = useReducer(
        decisionReducer,
        {isLoading: false, isError: false, finished:false}
    )

    const handleDialogClose = () => {
        onClose(dialogState.finished && !dialogState.isError)
        setTimeout(()=>{
            dispatchDialogState({type:"default_state"})
            setObservation("")
        },300)
    }

    const handleFinish = () =>{
        dispatchDialogState({type:"send_request_init"})
        let payload={
            "lock_type": lockType,
            "lock_source": "manually_alert",
            "alert_key":alert.alert_key
        }
        if (alert.link_key_type === "customer"){
            payload['customer_document_number'] = alert.customer_document_number
        }
        else{
            payload['seller_document_number'] = alert.seller_document_number
            if (alert.link_key_data.seller.name){
                payload['alert_metadata'] = {
                    "seller_name": alert.link_key_data.seller.name
                }
            }
        }

        if (observation && observation !== ""){
            payload = {...payload, observation: observation}
        }
        setTimeout(function () {
            axios.post('/dash/card_order/lock', payload).then(response => {
                dispatchDialogState({type:"send_request_success"})
            }).catch(error => {  
                if ((error.response || {}).status === 403) dispatchDialogState({type: "send_request_failure_403"})
                else if ((error.response || {}).status === 409) dispatchDialogState({type: "send_request_failure_409"})  
                else dispatchDialogState({type: "send_request_failure"})    
            });
        }, 1000);    
    }

    const getDialogContent=()=>{
        if (lockType === null){
            return(
                <div>
                    <div className="labelText" style={{marginBottom:"10px"}}>
                        Tipo de Bloqueio:
                    </div>
                    <div style={{ display:"flex", justifyContent: "space-evenly", margin:"auto 0px", marginBottom:"30px"}}>
                        <div
                            className={props.lockType === "seller" ? 
                            ["button", "clicked", "normalText", "normalMediumSize"].join(" ") : 
                            ["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")}
                            onClick={() => setLockType("seller")} 
                            style={{width:"30%", display:"flex", alignItems:"center", justifyContent:"center"}}
                        >
                            Payout do Vendedor
                        </div>
                        <div
                            className={props.lockType === "seller_transactional" ? 
                            ["button", "clicked", "normalText", "normalMediumSize"].join(" ") : 
                            ["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")}
                            onClick={() => setLockType("seller_transactional")} 
                            style={{width:"30%", display:"flex", alignItems:"center", justifyContent:"center"}}
                        >
                            Transação do Vendedor
                        </div>
                    </div>            
                </div>
            )
        }
        else if (dialogState.finished && dialogState.isLoading){
            return(
                <CircularProgress style={{margin:"auto"}} />
            )
        }
        else if(dialogState.finished){
            let confirmationData = {    
                message: dialogState.message,
                success: !dialogState.isError
            }
            return(
                <ConfirmationContent onClose={handleDialogClose} data={confirmationData} />
            )            
        }
        else{
            return(
                <React.Fragment>
                <div className="internalCardContainer">
                    <ThemeProvider theme={theme}>
                    <TextField
                        fullWidth
                        multiline
                        variant="outlined"
                        rows="5"
                        color="primary"
                        defaultValue={""}
                        disabled={false}
                        onChange={handleObservationBoxChange}
                    />
                    </ThemeProvider>
                </div>
                <div className="internalCardContainer" style={{display:"flex", justifyContent:"center"}}>
                    <div 
                        className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                        onClick={handleFinish}
                    >
                        Confirmar
                    </div>
                    <div 
                        className={["button", "cancel", "normalText", "normalMediumSize"].join(" ")}
                        onClick={
                            alert.link_key_type === "customer" 
                            ?
                            handleDialogClose
                            :
                            () => setLockType(null)
                        }
                    >
                        Retornar 
                    </div>
                </div>
                </React.Fragment>
            )
        }
    }

    return(
        <Dialog 
            open={open} 
            onClose={handleDialogClose} 
            keepMounted={false}
            disableBackdropClick
            disableEscapeKeyDown
        >
            <DialogTitle 
                className={['blueText', "subtitleSize"].join(" ")} 
                onClose={handleDialogClose}
            >
                Gerar Bloqueio {lockType !== null ? translateLockType(lockType) : ""}
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                {getDialogContent()}
            </DialogContent>
        </Dialog>

    )
}

export default CreateLockDialog;
