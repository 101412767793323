import React from 'react'
import {ConditionalBox, ConditionalBoxClass, ConditionalConfiguration} from "./Boxes/ConditionalBox"
import {OrBox, OrBoxClass, OrConfiguration} from "./Boxes/OrBox"
import {AndBox, AndBoxClass, AndConfiguration} from "./Boxes/AndBox"
import {NotBox, NotBoxClass, NotConfiguration} from "./Boxes/NotBox"
import {EventFieldBox, EventFieldBoxClass, EventFieldConfiguration} from "./Boxes/EventFieldBox"
import {ConstantAmountBox, ConstantAmountBoxClass,ConstantAmountConfiguration} from "./Boxes/ConstantAmountBox"
import {ConstantStringBox, ConstantStringBoxClass, ConstantStringConfiguration} from "./Boxes/ConstantStringBox"
import {ConstantBoolBox, ConstantBoolBoxClass, ConstantBoolConfiguration} from "./Boxes/ConstantBoolBox"
import {ConstantNumberBox, ConstantNumberBoxClass,ConstantNumberConfiguration} from "./Boxes/ConstantNumberBox"
import {GreaterThanBox, GreaterThanBoxClass, GreaterThanConfiguration} from "./Boxes/GreaterThanBox"
import {LessThanBox, LessThanBoxClass, LessThanConfiguration} from "./Boxes/LessThanBox"
import {GreaterThanEqualBox, GreaterThanEqualBoxClass, GreaterThanEqualConfiguration} from "./Boxes/GreaterThanEqualBox"
import {ChallengeBox, ChallengeBoxClass, ChallengeConfiguration} from "./Boxes/ChallengeBox"
import {LessThanEqualBox, LessThanEqualBoxClass, LessThanEqualConfiguration} from "./Boxes/LessThanEqualBox"
import {EqualToBox, EqualToBoxClass, EqualToConfiguration} from "./Boxes/EqualToBox"
import {NotEqualToBox, NotEqualToBoxClass, NotEqualToConfiguration} from "./Boxes/NotEqualToBox"
import {NumberRoundBox, NumberRoundBoxClass, NumberRoundConfiguration} from "./Boxes/NumberRoundBox"
import {InBetweenBox, InBetweenBoxClass, InBetweenConfiguration} from "./Boxes/InBetweenBox"
import {ConstantDatetimeBox, ConstantDatetimeBoxClass,ConstantDatetimeConfiguration} from "./Boxes/ConstantDatetimeBox"
import {ConstantTimeBox, ConstantTimeBoxClass,ConstantTimeConfiguration} from "./Boxes/ConstantTimeBox"
import {ConstantTimedeltaBox, ConstantTimedeltaBoxClass,ConstantTimedeltaConfiguration} from "./Boxes/ConstantTimedeltaBox"
import {ConstantGeoPointBox, ConstantGeoPointBoxClass,ConstantGeoPointConfiguration} from "./Boxes/ConstantGeoPointBox"
import {PolygonContainsPointBox, PolygonContainsPointBoxClass, PolygonContainsPointConfiguration} from "./Boxes/PolygonContainsPointBox"
import {GeoDistanceBox, GeoDistanceBoxClass, GeoDistanceConfiguration} from "./Boxes/GeoDistanceBox"
import {SumBox, SumBoxClass, SumConfiguration} from "./Boxes/SumBox"
import {LogBox, LogBoxClass, LogConfiguration} from "./Boxes/LogBox"
import {SignInverterBox, SignInverterBoxClass, SignInverterConfiguration} from "./Boxes/SignInverterBox"
import {SubtractBox, SubtractBoxClass, SubtractConfiguration} from "./Boxes/SubtractBox"
import {MultiplicationBox, MultiplicationBoxClass, MultiplicationConfiguration} from "./Boxes/MultiplicationBox"
import {DivisionBox, DivisionBoxClass, DivisionConfiguration} from "./Boxes/DivisionBox"
import {ActionDecisionBox, ActionDecisionBoxClass, ActionDecisionConfiguration} from "./Boxes/ActionDecisionBox"
import {ActionIndicatorBox, ActionIndicatorBoxClass, ActionIndicatorConfiguration} from "./Boxes/ActionIndicatorBox"
import { ActionInterestRateBox, ActionInterestRateConfiguration, ActionInterestRateBoxClass } from './Boxes/ActionInterestRateBox'
import { ActionNumberOfInstallmentsBox, ActionNumberOfInstallmentsConfiguration, ActionNumberOfInstallmentsBoxClass } from './Boxes/ActionNumberOfInstallmentsBox'
import { ActionCreditLimitAmountBox, ActionCreditLimitAmountConfiguration, ActionCreditLimitAmountBoxClass } from './Boxes/ActionCreditLimitAmountBox'
import { ActionCreditProposalBox, ActionCreditProposalConfiguration, ActionCreditProposalBoxClass } from './Boxes/ActionCreditProposalBox'
import {ActionRiskLevelBox, ActionRiskLevelBoxClass, ActionRiskLevelConfiguration} from "./Boxes/ActionRiskLevelBox"
import {ActionPackBox, ActionPackBoxClass, ActionPackConfiguration} from "./Boxes/ActionPackBox"
import {ActionTriggerBox, ActionTriggerBoxClass, ActionTriggerConfiguration} from "./Boxes/ActionTriggerBox"
import {ConditionalEventFieldBox, ConditionalEventFieldBoxClass, ConditionalEventFieldConfiguration} from "./Boxes/ConditionalEventFieldBox"
import {ComparisionEventFieldBox, ComparisionEventFieldBoxClass, ComparisionEventFieldConfiguration} from "./Boxes/ComparisionEventFieldBox"
import {TimeExtractionBox, TimeExtractionBoxClass, TimeExtractionConfiguration} from "./Boxes/TimeExtractionBox"
import {DateExtractionBox, DateExtractionBoxClass, DateExtractionConfiguration} from "./Boxes/DateExtractionBox"
import {WeekdayExtractionBox, WeekdayExtractionBoxClass, WeekdayExtractionConfiguration} from "./Boxes/WeekdayExtractionBox"
import {StringToLowerBox, StringToLowerBoxClass, StringToLowerConfiguration} from "./Boxes/StringToLowerBox"
import {StringToUpperBox, StringToUpperBoxClass, StringToUpperConfiguration} from "./Boxes/StringToUpperBox"
import {StringContainBox, StringContainBoxClass, StringContainConfiguration} from "./Boxes/StringContainBox"
import {StringConcatenateBox, StringConcatenateBoxClass, StringConcatenateConfiguration} from "./Boxes/StringConcatenateBox"
import {StringSliceBox, StringSliceBoxClass, StringSliceConfiguration} from "./Boxes/StringSliceBox"
import {StringReplaceBox, StringReplaceBoxClass, StringReplaceConfiguration} from "./Boxes/StringReplaceBox"
import {StringSplitBox, StringSplitBoxClass, StringSplitConfiguration} from "./Boxes/StringSplitBox"
import {StringSimilarityBox, StringSimilarityBoxClass, StringSimilarityConfiguration} from "./Boxes/StringSimilarityBox"
import {StringNormalizeBox, StringNormalizeBoxClass, StringNormalizeConfiguration} from "./Boxes/StringNormalizeBox"
import {StringLengthBox, StringLengthBoxClass, StringLengthConfiguration} from "./Boxes/StringLengthBox"
import {SmsBox, SmsBoxClass, SmsConfiguration} from "./Boxes/SmsBox"
import {DeviceScanDataRetrievalBox, DeviceScanDataRetrievalBoxClass, DeviceScanDataRetrievalConfiguration} from "./Boxes/DeviceScanDataRetrievalBox"
import {DeviceScanUserSameDevicesBox, DeviceScanUserSameDevicesBoxClass, DeviceScanUserSameDevicesConfiguration} from "./Boxes/DeviceScanUserSameDevicesBox"
import {DeviceScanUserDifferentDevicesBox, DeviceScanUserDifferentDevicesBoxClass, DeviceScanUserDifferentDevicesConfiguration} from "./Boxes/DeviceScanUserDifferentDevicesBox"
import {DeviceScanDeviceDifferentUsersBox, DeviceScanDeviceDifferentUsersBoxClass, DeviceScanDeviceDifferentUsersConfiguration} from "./Boxes/DeviceScanDeviceDifferentUsersBox"
import {DeviceScanDeviceFraudBox, DeviceScanDeviceFraudBoxClass, DeviceScanDeviceFraudConfiguration} from "./Boxes/DeviceScanDeviceFraudBox"
import {DeviceScanExistentBox, DeviceScanExistentBoxClass, DeviceScanExistentConfiguration} from "./Boxes/DeviceScanExistentBox"
import {DeviceScanGPSCoordinatesExistentBox, DeviceScanGPSCoordinatesExistentBoxClass, DeviceScanGPSCoordinatesExistentConfiguration} from "./Boxes/DeviceScanGPSCoordinatesExistentBox"
import {DeviceScanGPSCoordinatesBox, DeviceScanGPSCoordinatesBoxClass, DeviceScanGPSCoordinatesConfiguration} from "./Boxes/DeviceScanGPSCoordinatesBox"
import {ListsGetColumnFromFieldBox, ListsGetColumnFromFieldBoxClass, ListsGetColumnFromFieldConfiguration} from "./Boxes/ListsGetColumnFromFieldBox"
import {ListsGetColumnBox, ListsGetColumnBoxClass, ListsGetColumnConfiguration} from "./Boxes/ListsGetColumnBox"
import {ListsIncludesBox, ListsIncludesBoxClass, ListsIncludesConfiguration} from "./Boxes/ListsIncludesBox"
import {ListsIncludesFieldBox, ListsIncludesFieldBoxClass, ListsIncludesFieldConfiguration} from "./Boxes/ListsIncludesFieldBox"
import {SignalsBox, SignalsBoxClass, SignalsConfiguration} from "./Boxes/SignalsBox"
import {ScriptsBox, ScriptsBoxClass, ScriptsConfiguration} from "./Boxes/ScriptsBox" 
import {ConditionalSignalsBox, ConditionalSignalsBoxClass, ConditionalSignalsConfiguration} from "./Boxes/ConditionalSignalsBox"
import {ComparisionSignalsBox, ComparisionSignalsBoxClass, ComparisionSignalsConfiguration} from "./Boxes/ComparisionSignalsBox"
import {EventFieldCounterBox, EventFieldCounterBoxClass, EventFieldCounterConfiguration} from "./Boxes/EventFieldCounterBox"
import {EventFieldSwapCounterBox, EventFieldSwapCounterBoxClass, EventFieldSwapCounterConfiguration} from "./Boxes/EventFieldSwapCounterBox"
import {EventFieldCachedCounterBox, EventFieldCachedCounterBoxClass, EventFieldCachedCounterConfiguration} from "./Boxes/EventFieldCachedCounterBox"
import {EventFieldAccumulatorBox, EventFieldAccumulatorBoxClass, EventFieldAccumulatorConfiguration} from "./Boxes/EventFieldAccumulatorBox"
import {EventFieldSwapAccumulatorBox, EventFieldSwapAccumulatorBoxClass, EventFieldSwapAccumulatorConfiguration} from "./Boxes/EventFieldSwapAccumulatorBox"
import {EventFieldCachedAccumulatorBox, EventFieldCachedAccumulatorBoxClass, EventFieldCachedAccumulatorConfiguration} from "./Boxes/EventFieldCachedAccumulatorBox"
import {EventFieldVariabilityBox, EventFieldVariabilityBoxClass, EventFieldVariabilityConfiguration} from "./Boxes/EventFieldVariabilityBox"
import {VerifyLockBox, VerifyLockBoxClass, VerifyLockConfiguration} from "./Boxes/VerifyLockBox"
import {ProcessorsVariablesBox, ProcessorsVariablesBoxClass, ProcessorsVariablesConfiguration} from "./Boxes/ProcessorsVariablesBox"
import {StatusCounterBox, StatusCounterBoxClass, StatusCounterConfiguration} from "./Boxes/StatusCounterBox"
import {ScoreModifierBox, ScoreModifierBoxClass, ScoreModifierConfiguration} from "./Boxes/ScoreModifierBox"
import {PreAuthorizationSetterBox, PreAuthorizationSetterBoxClass, PreAuthorizationSetterConfiguration} from "./Boxes/PreAuthorizationSetterBox"
import {ScoreRetrievalBox, ScoreRetrievalBoxClass, ScoreRetrievalConfiguration} from "./Boxes/ScoreRetrievalBox"
import {OCRDataRetrievalBox, OCRDataRetrievalBoxClass, OCRDataRetrievalConfiguration} from "./Boxes/OCRDataRetrievalBox"
import {LabelBox, LabelBoxClass, LabelConfiguration} from "./Boxes/LabelBox"
import {OnboardingEnrichmentBox, OnboardingEnrichmentBoxClass, OnboardingEnrichmentConfiguration} from "./Boxes/OnboardingEnrichmentBox"
import {MachineLearningBox, MachineLearningBoxClass, MachineLearningConfiguration} from "./Boxes/MachineLearningBox"
import {ActionCustomVariableBox, ActionCustomVariableBoxClass, ActionCustomVariableConfiguration} from "./Boxes/ActionCustomVariableBox"
import {ModuleBox, ModuleBoxClass, ModuleConfiguration} from "./Boxes/ModuleBox"
import {MinimumBox, MinimumBoxClass, MinimumConfiguration} from "./Boxes/MinimumBox"
import {MaximumBox, MaximumBoxClass, MaximumConfiguration} from "./Boxes/MaximumBox"
import {RandomNumberBox, RandomNumberBoxClass, RandomNumberConfiguration} from "./Boxes/RandomNumberBox"
import {UpdateVariableBox, UpdateVariableBoxClass, UpdateVariableConfiguration} from "./Boxes/UpdateVariableBox"
import {GetVariableBox, GetVariableBoxClass, GetVariableConfiguration} from "./Boxes/GetVariableBox"
// import {ConditionalBureauValidationBox, ConditionalBureauValidationBoxClass, ConditionalBureauValidationConfiguration} from "./Boxes/ConditionalBureauValidationBox"
// import {BureauValidationBox, BureauValidationBoxClass, BureauValidationConfiguration} from "./Boxes/BureauValidationBox"
// import {ConditionalBureauRetrievalBox, ConditionalBureauRetrievalBoxClass, ConditionalBureauRetrievalConfiguration} from "./Boxes/ConditionalBureauRetrievalBox"
// import {BureauRetrievalBox, BureauRetrievalBoxClass, BureauRetrievalConfiguration} from "./Boxes/BureauRetrievalBox"
// import {ConditionalSpecialSearchBox, ConditionalSpecialSearchBoxClass, ConditionalSpecialSearchConfiguration} from "./Boxes/ConditionalSpecialSearchBox"
// import {SpecialSearchBox, SpecialSearchBoxClass, SpecialSearchConfiguration} from "./Boxes/SpecialSearchBox"
// import {ConditionalPartnersStatusBox, ConditionalPartnersStatusBoxClass, ConditionalPartnersStatusConfiguration} from "./Boxes/ConditionalPartnersStatusBox"
// import {PartnersStatusBox, PartnersStatusBoxClass, PartnersStatusConfiguration} from "./Boxes/PartnersStatusBox"

export function getTypeComponents (properties) {
    let {type} = properties
    let {node} = properties
    let {handleConfigurationOpen} = properties
    let {handleConfigurationClose} = properties
    let {handleNodeChange} = properties
    let {handleNodeRemove} = properties
    let {rulesContext} = properties
    let {read_only} = properties
    let {userVariablesAvailable} = properties
    let {handleNodeDuplication} = properties
    let jsx_component
    let instance
    let configuration_component
    switch (type){
        // case "conditional_partners_status_box":
        //     jsx_component = <ConditionalPartnersStatusBox handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen}/>
        //     configuration_component = <ConditionalPartnersStatusConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange}/>
        //     instance = new ConditionalPartnersStatusBoxClass(rulesContext)
        //     break  
        // case "partners_status_box":
        //     jsx_component = <PartnersStatusBox handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen}/>
        //     configuration_component = <PartnersStatusConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange}/>
        //     instance = new PartnersStatusBoxClass(rulesContext)
        //     break  
        // case "conditional_special_search_box":
        //     jsx_component = <ConditionalSpecialSearchBox handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen}/>
        //     configuration_component = <ConditionalSpecialSearchConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange}/>
        //     instance = new ConditionalSpecialSearchBoxClass(rulesContext)
        //     break  
        // case "special_search_box":
        //     jsx_component = <SpecialSearchBox handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen}/>
        //     configuration_component = <SpecialSearchConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange}/>
        //     instance = new SpecialSearchBoxClass(rulesContext)
        //     break
        // case "conditional_bureau_retrieval_box":
        //     jsx_component = <ConditionalBureauRetrievalBox handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen}/>
        //     configuration_component = <ConditionalBureauRetrievalConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange}/>
        //     instance = new ConditionalBureauRetrievalBoxClass(rulesContext)
        //     break  
        // case "bureau_retrieval_box":
        //     jsx_component = <BureauRetrievalBox handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen}/>
        //     configuration_component = <BureauRetrievalConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange}/>
        //     instance = new BureauRetrievalBoxClass(rulesContext)
        //     break 
        // case "conditional_bureau_validation_box":
        //     jsx_component = <ConditionalBureauValidationBox handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen}/>
        //     configuration_component = <ConditionalBureauValidationConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange}/>
        //     instance = new ConditionalBureauValidationBoxClass(rulesContext)
        //     break  
        // case "bureau_validation_box":
        //     jsx_component = <BureauValidationBox handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen}/>
        //     configuration_component = <BureauValidationConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange}/>
        //     instance = new BureauValidationBoxClass(rulesContext)
        //     break 
        // case "lists_field_conditional_box":
        //     jsx_component = <ListsFieldConditionalBox handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen}/>
        //     configuration_component = <ListsFieldConditionalConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange}/>
        //     instance = new ListsFieldConditionalBoxClass(rulesContext)
        //     break
        case "signals_box":
            jsx_component = <SignalsBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <SignalsConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new SignalsBoxClass(rulesContext)
            break
        case "scripts_box":
            jsx_component = <ScriptsBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <ScriptsConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new ScriptsBoxClass(rulesContext)
            break
        case "conditional_signals_box":
            jsx_component = <ConditionalSignalsBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <ConditionalSignalsConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new ConditionalSignalsBoxClass(rulesContext)
            break
        case "conditional_event_field_box":
            jsx_component = <ConditionalEventFieldBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <ConditionalEventFieldConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new ConditionalEventFieldBoxClass(rulesContext)
            break    
        case "comparision_signals_box":
            jsx_component = <ComparisionSignalsBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <ComparisionSignalsConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new ComparisionSignalsBoxClass(rulesContext)
            break
        case "comparision_event_field_box":
            jsx_component = <ComparisionEventFieldBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <ComparisionEventFieldConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new ComparisionEventFieldBoxClass(rulesContext)
            break    
        case "action_pack_box":
            jsx_component = <ActionPackBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <ActionPackConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new ActionPackBoxClass(rulesContext)
            break          
        case "action_indicator_box":
            jsx_component = <ActionIndicatorBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove}  node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <ActionIndicatorConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new ActionIndicatorBoxClass(rulesContext)
            break  
        case "action_interest_rate_box":
            jsx_component = <ActionInterestRateBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove}  node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <ActionInterestRateConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new ActionInterestRateBoxClass(rulesContext)
            break  
        case "action_number_of_installments_box":
            jsx_component = <ActionNumberOfInstallmentsBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove}  node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <ActionNumberOfInstallmentsConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new ActionNumberOfInstallmentsBoxClass(rulesContext)
            break  
        case "action_credit_limit_amount_box":
            jsx_component = <ActionCreditLimitAmountBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove}  node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <ActionCreditLimitAmountConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new ActionCreditLimitAmountBoxClass(rulesContext)
            break  
        case "action_credit_proposal_box":
            jsx_component = <ActionCreditProposalBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove}  node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <ActionCreditProposalConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new ActionCreditProposalBoxClass(rulesContext)
            break  
        case "action_risk_level_box":
            jsx_component = <ActionRiskLevelBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove}  node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <ActionRiskLevelConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new ActionRiskLevelBoxClass(rulesContext)
            break  
        case "action_decision_box":
            jsx_component = <ActionDecisionBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <ActionDecisionConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new ActionDecisionBoxClass(rulesContext)
            break  
        case "action_trigger_box":
            jsx_component = <ActionTriggerBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <ActionTriggerConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new ActionTriggerBoxClass(rulesContext)
            break  
        case "subtract_box":
            jsx_component = <SubtractBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <SubtractConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new SubtractBoxClass(rulesContext)
            break  
        case "sign_inverter_box":
            jsx_component = <SignInverterBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <SignInverterConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new SignInverterBoxClass(rulesContext)
            break  
        case "sum_box":
            jsx_component = <SumBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <SumConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new SumBoxClass(rulesContext)
            break 
        case "log_box":
            jsx_component = <LogBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <LogConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new LogBoxClass(rulesContext)
            break  
        case "multiplication_box":
            jsx_component = <MultiplicationBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <MultiplicationConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new MultiplicationBoxClass(rulesContext)
            break  
        case "division_box":
            jsx_component = <DivisionBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <DivisionConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new DivisionBoxClass(rulesContext)
            break  
        case "constant_time_box":
            jsx_component = <ConstantTimeBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <ConstantTimeConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new ConstantTimeBoxClass(rulesContext)
            break  
        case "constant_datetime_box":
            jsx_component = <ConstantDatetimeBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <ConstantDatetimeConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new ConstantDatetimeBoxClass(rulesContext)
            break  
        case "constant_timedelta_box":
            jsx_component = <ConstantTimedeltaBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <ConstantTimedeltaConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new ConstantTimedeltaBoxClass(rulesContext)
            break  
        case "greater_than_box":
            jsx_component = <GreaterThanBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <GreaterThanConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new GreaterThanBoxClass(rulesContext)
            break
        case "less_than_box":
            jsx_component = <LessThanBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <LessThanConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new LessThanBoxClass(rulesContext)
            break   
        case "greater_than_equal_box":
            jsx_component = <GreaterThanEqualBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <GreaterThanEqualConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new GreaterThanEqualBoxClass(rulesContext)
            break   
        case "challenge_box":
            jsx_component = <ChallengeBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <ChallengeConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new ChallengeBoxClass(rulesContext)
            break   
        case "less_than_equal_box":
            jsx_component = <LessThanEqualBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <LessThanEqualConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new LessThanEqualBoxClass(rulesContext)
            break    
        case "equal_to_box":
            jsx_component = <EqualToBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <EqualToConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new EqualToBoxClass(rulesContext)
            break   
        case "not_equal_to_box":
            jsx_component = <NotEqualToBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <NotEqualToConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new NotEqualToBoxClass(rulesContext)
            break  
        case "number_round_box":
            jsx_component = <NumberRoundBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <NumberRoundConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new NumberRoundBoxClass(rulesContext)
            break  
        case "in_between_box":
            jsx_component = <InBetweenBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <InBetweenConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new InBetweenBoxClass(rulesContext)
            break          
        case "constant_amount_box":
            jsx_component = <ConstantAmountBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <ConstantAmountConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new ConstantAmountBoxClass(rulesContext)
            break
        case "constant_number_box":
            jsx_component = <ConstantNumberBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <ConstantNumberConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new ConstantNumberBoxClass(rulesContext)
            break
        case "constant_bool_box":
            jsx_component = <ConstantBoolBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <ConstantBoolConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new ConstantBoolBoxClass(rulesContext)
            break
        case "constant_string_box":
            jsx_component = <ConstantStringBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <ConstantStringConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new ConstantStringBoxClass(rulesContext)
            break
        case "constant_geo_point_box":
            jsx_component = <ConstantGeoPointBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <ConstantGeoPointConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new ConstantGeoPointBoxClass(rulesContext)
            break
        case "polygon_contains_point_box":
            jsx_component = <PolygonContainsPointBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <PolygonContainsPointConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new PolygonContainsPointBoxClass(rulesContext)
            break
        case "geo_distance_box":
            jsx_component = <GeoDistanceBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <GeoDistanceConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new GeoDistanceBoxClass(rulesContext)
            break
        case "lists_get_column_from_field_box":
            jsx_component = <ListsGetColumnFromFieldBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <ListsGetColumnFromFieldConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new ListsGetColumnFromFieldBoxClass(rulesContext)
            break 
        case "lists_get_column_box":
            jsx_component = <ListsGetColumnBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <ListsGetColumnConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new ListsGetColumnBoxClass(rulesContext)
            break 
        case "lists_includes_box":
            jsx_component = <ListsIncludesBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <ListsIncludesConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new ListsIncludesBoxClass(rulesContext)
            break
        case "lists_includes_field_box":
            jsx_component = <ListsIncludesFieldBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <ListsIncludesFieldConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new ListsIncludesFieldBoxClass(rulesContext)
            break  
        case "event_field_box":
            jsx_component = <EventFieldBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <EventFieldConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new EventFieldBoxClass(rulesContext)
            break
        case "not_box":
            jsx_component = <NotBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <NotConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new NotBoxClass(rulesContext)
            break
        case "and_box":
            jsx_component = <AndBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <AndConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new AndBoxClass(rulesContext)
            break
        case "or_box":
            jsx_component = <OrBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <OrConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new OrBoxClass(rulesContext)
            break
        case "conditional_box":
            jsx_component = <ConditionalBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <ConditionalConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new ConditionalBoxClass(rulesContext)
            break
        case "time_extraction_box":
            jsx_component = <TimeExtractionBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <TimeExtractionConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new TimeExtractionBoxClass(rulesContext)
            break
        case "date_extraction_box":
            jsx_component = <DateExtractionBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <DateExtractionConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new DateExtractionBoxClass(rulesContext)
            break
        case "weekday_extraction_box":
            jsx_component = <WeekdayExtractionBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <WeekdayExtractionConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new WeekdayExtractionBoxClass(rulesContext)
            break
        case "string_to_lower_box":
            jsx_component = <StringToLowerBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <StringToLowerConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new StringToLowerBoxClass(rulesContext)
            break
        case "string_to_upper_box":
            jsx_component = <StringToUpperBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <StringToUpperConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new StringToUpperBoxClass(rulesContext)
            break
        case "string_contain_box":
            jsx_component = <StringContainBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <StringContainConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new StringContainBoxClass(rulesContext)
            break
        case "string_concatenate_box":
            jsx_component = <StringConcatenateBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <StringConcatenateConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new StringConcatenateBoxClass(rulesContext)
            break
        case "string_slice_box":
            jsx_component = <StringSliceBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <StringSliceConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new StringSliceBoxClass(rulesContext)
            break
        case "string_replace_box":
            jsx_component = <StringReplaceBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <StringReplaceConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new StringReplaceBoxClass(rulesContext)
            break
        case "string_split_box":
            jsx_component = <StringSplitBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <StringSplitConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new StringSplitBoxClass(rulesContext)
            break
        case "string_similarity_box":
            jsx_component = <StringSimilarityBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <StringSimilarityConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new StringSimilarityBoxClass(rulesContext)
            break
        case "string_normalize_box":
            jsx_component = <StringNormalizeBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <StringNormalizeConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new StringNormalizeBoxClass(rulesContext)
            break
        case "string_length_box":
            jsx_component = <StringLengthBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <StringLengthConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new StringLengthBoxClass(rulesContext)
            break
        case "sms_box":
            jsx_component = <SmsBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <SmsConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new SmsBoxClass(rulesContext)
            break
        case "device_scan_data_retrieval_box":
            jsx_component = <DeviceScanDataRetrievalBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <DeviceScanDataRetrievalConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new DeviceScanDataRetrievalBoxClass(rulesContext)
            break
        case "device_scan_user_same_devices_box":
            jsx_component = <DeviceScanUserSameDevicesBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <DeviceScanUserSameDevicesConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new DeviceScanUserSameDevicesBoxClass(rulesContext)
            break
        case "device_scan_user_different_devices_box":
            jsx_component = <DeviceScanUserDifferentDevicesBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <DeviceScanUserDifferentDevicesConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new DeviceScanUserDifferentDevicesBoxClass(rulesContext)
            break
        case "device_scan_device_different_users_box":
            jsx_component = <DeviceScanDeviceDifferentUsersBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <DeviceScanDeviceDifferentUsersConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new DeviceScanDeviceDifferentUsersBoxClass(rulesContext)
            break
        case "device_scan_device_fraud_box":
            jsx_component = <DeviceScanDeviceFraudBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <DeviceScanDeviceFraudConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new DeviceScanDeviceFraudBoxClass(rulesContext)
            break
        case "device_scan_existent_box":
            jsx_component = <DeviceScanExistentBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <DeviceScanExistentConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new DeviceScanExistentBoxClass(rulesContext)
            break
        case "device_scan_gps_coordinates_existent_box":
            jsx_component = <DeviceScanGPSCoordinatesExistentBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <DeviceScanGPSCoordinatesExistentConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new DeviceScanGPSCoordinatesExistentBoxClass(rulesContext)
            break
        case "device_scan_gps_coordinates_box":
            jsx_component = <DeviceScanGPSCoordinatesBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <DeviceScanGPSCoordinatesConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new DeviceScanGPSCoordinatesBoxClass(rulesContext)
            break
        case "event_field_counter_box":
            jsx_component = <EventFieldCounterBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <EventFieldCounterConfiguration node={node} userVariables={userVariablesAvailable} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new EventFieldCounterBoxClass(rulesContext)
            break
        case "event_field_cached_counter_box":
            jsx_component = <EventFieldCachedCounterBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <EventFieldCachedCounterConfiguration node={node} userVariables={userVariablesAvailable} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new EventFieldCachedCounterBoxClass(rulesContext)
            break
        case "event_field_swap_counter_box":
                jsx_component = <EventFieldSwapCounterBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
                configuration_component = <EventFieldSwapCounterConfiguration node={node} userVariables={userVariablesAvailable} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
                instance = new EventFieldSwapCounterBoxClass(rulesContext)
                break
        case "event_field_accumulator_box":
            jsx_component = <EventFieldAccumulatorBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <EventFieldAccumulatorConfiguration node={node} userVariables={userVariablesAvailable} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new EventFieldAccumulatorBoxClass(rulesContext)
            break
        case "event_field_cached_accumulator_box":
            jsx_component = <EventFieldCachedAccumulatorBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <EventFieldCachedAccumulatorConfiguration node={node} userVariables={userVariablesAvailable} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new EventFieldCachedAccumulatorBoxClass(rulesContext)
            break
        case "event_field_swap_accumulator_box":
                jsx_component = <EventFieldSwapAccumulatorBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
                configuration_component = <EventFieldSwapAccumulatorConfiguration node={node} userVariables={userVariablesAvailable} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
                instance = new EventFieldSwapAccumulatorBoxClass(rulesContext)
                break
        case "event_field_variability_box":
            jsx_component = <EventFieldVariabilityBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <EventFieldVariabilityConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new EventFieldVariabilityBoxClass(rulesContext)
            break
        case "verify_lock_box":
            jsx_component = <VerifyLockBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <VerifyLockConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new VerifyLockBoxClass(rulesContext)
            break
        case "processors_variables_box":
            jsx_component = <ProcessorsVariablesBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <ProcessorsVariablesConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new ProcessorsVariablesBoxClass(rulesContext)
            break
        case "status_counter_box":
            jsx_component = <StatusCounterBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <StatusCounterConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new StatusCounterBoxClass(rulesContext)
            break
        case "pre_authorization_setter_box":
            jsx_component = <PreAuthorizationSetterBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <PreAuthorizationSetterConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new PreAuthorizationSetterBoxClass(rulesContext)
            break
        case "score_modifier_box":
            jsx_component = <ScoreModifierBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <ScoreModifierConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new ScoreModifierBoxClass(rulesContext)
            break
        case "score_retrieval_box":
            jsx_component = <ScoreRetrievalBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <ScoreRetrievalConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new ScoreRetrievalBoxClass(rulesContext)
            break
        case "ocr_data_retrieval_box":
            jsx_component = <OCRDataRetrievalBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <OCRDataRetrievalConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new OCRDataRetrievalBoxClass(rulesContext)
            break
        case "label_box":
            jsx_component = <LabelBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <LabelConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new LabelBoxClass(rulesContext)
            break
        case "onboarding_enrichment_box":
            jsx_component = <OnboardingEnrichmentBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <OnboardingEnrichmentConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new OnboardingEnrichmentBoxClass(rulesContext)
            break
        case "machine_learning_box":
            jsx_component = <MachineLearningBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <MachineLearningConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new MachineLearningBoxClass(rulesContext)
            break
        case "action_custom_variable_box":
            jsx_component = <ActionCustomVariableBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <ActionCustomVariableConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new ActionCustomVariableBoxClass(rulesContext)
            break
        case "module_box":
            jsx_component = <ModuleBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <ModuleConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new ModuleBoxClass(rulesContext)
            break
        case "random_number_box":
            jsx_component = <RandomNumberBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <RandomNumberConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new RandomNumberBoxClass(rulesContext)
            break
        case "maximum_box":
            jsx_component = <MaximumBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <MaximumConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new MaximumBoxClass(rulesContext)
            break
        case "minimum_box":
            jsx_component = <MinimumBox handleNodeDuplication={handleNodeDuplication} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <MinimumConfiguration node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new MinimumBoxClass(rulesContext)
            break
        case "update_variable_box":
            jsx_component = <UpdateVariableBox handleNodeDuplication={handleNodeDuplication} userVariablesAvailable={userVariablesAvailable} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <UpdateVariableConfiguration userVariablesAvailable={userVariablesAvailable} node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new UpdateVariableBoxClass(rulesContext, userVariablesAvailable)
            break
        case "get_variable_box":
            jsx_component = <GetVariableBox handleNodeDuplication={handleNodeDuplication} userVariablesAvailable={userVariablesAvailable} handleNodeRemove={handleNodeRemove} node={node} handleConfigurationOpen={handleConfigurationOpen} read_only={read_only}/>
            configuration_component = <GetVariableConfiguration userVariablesAvailable={userVariablesAvailable} node={node} handleConfigurationClose={handleConfigurationClose} handleNodeChange={handleNodeChange} read_only={read_only}/>
            instance = new GetVariableBoxClass(rulesContext, userVariablesAvailable)
            break
        default:
            throw new Error("Invalid Box Type:" + type)
    }
    return {box_component: jsx_component, instance: instance, configuration_component: configuration_component}
}
