import React, {useEffect} from "react";
import Collapse from "@material-ui/core/Collapse";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import IconButton from "@material-ui/core/IconButton";
import ProtestsCard from "../ProtestsCard/ProtestsCard";
import { CCFCard } from "../CCFCard";
import { NegativeAnnotationsCard } from "../NegativeAnnotations/NegativeAnnotationsCard";
import ProcessesCard from "../../../Onboarding/LegalPerson/Cards/ProcessesCard/ProcessesCard";
import ErrorBoundary from "../../../Utils/ErrorBoundary";
import { InquiriesCard } from "../Inquiries/InquiriesCard";
import { PartnersCard } from "../Partners/PartnersCard";
import { useTranslation } from "react-i18next";
import DataField from "../../../@GeneralComponents/DataField";

function Quod(props) {

    let { quod, expandedQuod, setExpandedQuod, isPDF } = props

    const { t } = useTranslation()

    const handleExpand = (event) => {
        event.stopPropagation();
        setExpandedQuod(!expandedQuod);
    };

    useEffect(()=>{
        if(isPDF){
            setExpandedQuod(true)
        }
    }, [isPDF, setExpandedQuod])

    return (
        <div className="avoidPageBreakInside analysisCard">
            <div style={{display:"flex"}}>
            <div 
                className={["blueText", "subtitleSize", "bold"].join(" ")}
                style={{margin:"auto 0px"}}
            >
                Quod
            </div>
            {!isPDF&&
            <IconButton style={{marginLeft: "auto"}} onClick={handleExpand}>
                {expandedQuod ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </IconButton>}
            </div>
            <Collapse in={expandedQuod} timeout="auto" unmountOnExit>
                <div style={{display: 'flex', flexDirection: 'column'}}> 
                    <div className="analysisCardContainer">
                        <div className="avoidPageBreakInside analysisCard">
                            <div className="blueText subtitleSize" style={{marginBottom:"10px"}}>
                                {t("general")}
                            </div>
                            {quod.document_number&&
                            <DataField
                                title={t("document_number")}
                                label={quod.document_number}
                                size={"normalMediumSize"}
                                titleNoWrap={false}
                            />}
                            {quod.overal_record_status&&
                            <DataField
                                title={t("status")}
                                label={quod.overal_record_status}
                                size={"normalMediumSize"}
                                titleNoWrap={false}
                            />}
                            {quod.positive_record_status&&
                            <DataField
                                title={t("positive_record_status")}
                                label={quod.positive_record_status}
                                size={"normalMediumSize"}
                                titleNoWrap={false}
                            />}
                        </div>
                    </div>
                    {quod?.protests?
                        <div className="analysisCardContainer">
                            <ErrorBoundary>
                                <ProtestsCard protests={quod.protests} isPDF={isPDF} isQuod={true} />
                            </ErrorBoundary>
                        </div> : null}
                    {quod?.ccf_annotations?
                        <div className="analysisCardContainer">
                            <ErrorBoundary>
                                <CCFCard ccf_data={quod.ccf_annotations} isPDF={isPDF} />
                            </ErrorBoundary>
                        </div> : null}
                    {quod?.lawsuits?
                        <div className="analysisCardContainer">
                            <ErrorBoundary>
                                <ProcessesCard processes={quod.lawsuits} isPDF={isPDF} isQuod={true} />
                            </ErrorBoundary>
                        </div> : null}
                    {quod?.negative_annotations?
                        <div className="analysisCardContainer">
                            <ErrorBoundary>
                                <NegativeAnnotationsCard negative_annotations={quod.negative_annotations} isPDF={isPDF}/>
                            </ErrorBoundary>
                        </div> : null}
                    {quod?.inquiries?
                        <div className="analysisCardContainer">
                            <ErrorBoundary>
                                <InquiriesCard inquiries={quod.inquiries} isPDF={isPDF}/>
                            </ErrorBoundary>
                        </div> : null}
                    {quod?.partners_data?.length ?
                    <div className="analysisCardContainer">
                        <ErrorBoundary>
                            <PartnersCard partners_data={quod.partners_data} isPDF={isPDF}/>
                        </ErrorBoundary>
                    </div> : null}
                </div>
            </Collapse> 
        </div>
    );
}

export default Quod
