import React from "react";
import { useTranslation } from "react-i18next";
import ValidatedImage from "../../utils/ValidatedImage"

function FaceReconCard(props){

    let { name, registration_key } = props

    const { t } = useTranslation()
    
    return (
    <div className="analysisCard" style={{ height: "100%", marginRight: "20px" }}>
        <div className="blueText subtitleSize">{t(name)}</div>
        <div className="internalCardContainer" style={{ display: "flex", flexGrow: 1 }}>
            <div style={{display: "block",width: "100%",height: "100%",margin: "0px",overflow: "hidden",position: "relative",borderRadius: "5px"}}>
                <div style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "5px"}}>
                    <ValidatedImage
                        image_key={registration_key}
                        dash_api_endpoint_url="/dash/face_recognition/image"
                        imageStyle={{margin: "auto",maxWidth: "100%",maxHeight: "100%",borderRadius: "5px",objectFit: "contain"}}
                        imageAlt={t(name)}
                    />
                </div>
            </div>
        </div>
    </div>
    );
}


export default FaceReconCard
