import React from 'react'
import Tooltip from "@material-ui/core/Tooltip";
import computerLogo from '../../../assets/cardEntryIcons/computer.svg';
import contactlessLogo from '../../../assets/cardEntryIcons/contactless.svg';
import tarjaLogo from '../../../assets/cardEntryIcons/tarja.svg';
import chipLogo from '../../../assets/cardEntryIcons/chip.svg';
import randomLogo from '../../../assets/cardEntryIcons/randomEntry.svg';
import redCross from '../../../assets/cardEntryIcons/redCross.svg';
import greenCheck from '../../../assets/cardEntryIcons/greenCheck.svg';
import masterCard from '../../../assets/cardEntryIcons/mastercard.svg';
import visaLogo from '../../../assets/cardEntryIcons/Visa.svg';
import eloLogo from '../../../assets/cardEntryIcons/elo.svg';
import amexLogo from '../../../assets/cardEntryIcons/amex.svg';
import moment from "moment"
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import ReplayIcon from '@material-ui/icons/Replay';
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from '@material-ui/icons/Clear';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import HistoryIcon from '@material-ui/icons/History';
import MobileFriendlyIcon from '@material-ui/icons/MobileFriendly';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import PhonelinkEraseIcon from '@material-ui/icons/PhonelinkErase';
import i18next from "i18next"

// Translators
export const getEntryText = (entry_mode) => {
  switch(entry_mode){
    case "unknown":
      return ("00 - Desconhecido")
    case "typed":
      return ("01 - Manual")
    case "bar_code":
      return ("03 - Código de Barras")
    case "ocr":
      return ("04 - OCR")
    case "chip":
      return ("05 - Cartão com chip")
    case "track_1":
      return ("06 - Track 1 do cartão de tarja")
    case "contactless":
      return ("07 - Contactless EMV")
    case "fallback_typed":
      return ("79 - Após falha com leitura de Chip, inserido manualmente")
    case "fallback_magnetic_stripe":
      return ("80 - Após falha com leitura de Chip, inserido por tarja magnética")
    case "ecommerce":
      return ("81 - E-commerce")
    case "magnetic_stripe":
      return ("90 - Tarja Magnética")
    default:
      return "Indisponível"
  }
}
export const getPinText = (pin_sent) => {
    if(pin_sent == null) return ("Não há informações disponíveis sobre a digitação de senha")
    else if (pin_sent) return ("A senha foi digitada")
    else return ("A senha não foi digitada")
  }

export const getTransactionType = (transaction_type) => {
  switch(transaction_type){
    case "credit":
      return "Crédito"
    case "debit":
      return "Débito"
    case "prepaid":
      return "Pré-pago"
    default:
      return transaction_type
  }
}

export const getTerminalType = (terminal_type) => {
  switch(terminal_type){
    case "0":
      return "Desconhecido"
    case "1":
      return "Nenhum terminal utilizado"
    case "2":
      return "Leitor de tarja magnética"
    case "3":
      return "Código de barras"
    case "4":
      return "OCR - Optical Character Recognition"
    case "5":
      return "Leitor de tarja magnética e de ICC compatível com EMV"
    case "6":
      return "Apenas com inserção de senha"
    case "7":
      return "Leitor de tarja magnética e inserção de senha"
    case "8":
      return "Leitor de tarja magnética, inserção de senha e leitor de ICC compatível com EMV"
    case "9":
      return "Leitor de ICC compatível com EMV"
    default:
      return terminal_type
  }
}
export const getAcquirer = (acquirer_id) => {
  switch(acquirer_id){
    default:
      return acquirer_id
  }
}
export const getTransactionStatusText = (status) => {
  switch(status){
    case "not_authorized":
      return "Não Autorizada"
    case "chargeback":
      return "Chargeback"
    case "partial_chargeback":
      return "Chargeback parcial"
    case "authorized":
      return "Autorizada"
    case "cleared":
      return "Enviada no Clearing"
    case "cancelled":
      return "Cancelada"
    case "partially_cancelled":
      return "Cancelada parcialmente"
    case "captured":
      return "Capturada"
    default:
      return status
  }
}
export const getFraudStatus = (status) => {
  switch(status){
    case "automatically_approved":
      return "Aprovada"
    case "automatically_declined":
    case "automatically_reproved":
      return "Reprovada"
    case "not_analyzed":
      return "Não analisada"
    case "pending":
      return "Pendente"
    default:
      return status
  }
}

export const getFraudStatusSiglum = (status) => {
    switch(status){
      case "automatically_approved":
        return "A"
      case "automatically_declined":
      case "automatically_reproved":
        return "R"
      case "not_analyzed":
        return "NA"
      case "pending":
        return "P"
      default:
        return "?"
    }
  }

//Status Utils
export const getFraudColor = (status) => {
  switch(status){
    case "automatically_approved":
      return colorGreen
    case "automatically_declined":
    case "automatically_reproved":
      return colorRed
    case "not_analyzed":
    case "pending":
    default:
      return colorGrey
  }
}
export const getTimeLineColor = (status) => {
  switch(status){
    case "not_authorized":
    case "chargeback":
    case "partial_chargeback":
      return colorRed
    case "authorized":
    case "cleared":
    case "captured":
      return colorGreen
    case "cancelled":
    case "partially_cancelled":
    default:
      return colorGrey
  }
}


//Payment Card Utils
export const getCard = (card) => {
  return (
    <div className="greyCard">
      {card.brand == null ? null : <img src={getBrand(card.brand)} className="brandStyle" alt="cardBrand Logo" />}
      <div className={["cardNumberStyle", "normalText", "normalSmallSize"].join(" ")}>
        {formatCard(card.bin, card.last4)}
      </div>
      <div className={["validDateStyle", "normalText", "normalSmallSize"].join(" ")}>
        {card.expiration_date == null ? null : moment(card.expiration_date).format("MM/YYYY")}
      </div>
      <div className={["cardTypeStyle", "normalText", "normalSmallSize"].join(" ")}>
        {getCardCategory(card.category)}
      </div>
    </div>
  )
}

export const getCardCategory = (category) => {
  if (category == null) return null
  switch(category){
    case "classic":
      return "Classic"
    case "gold":
      return "Gold"
    case "platinum":
      return "Platinum"
    case "black":
      return "Black/Infinite"
    case "travel":
      return "Travel"
    case "corporate":
      return "Corporate"
    case "prepaid":
      return "Pré-pago"
    default:
      return category
  }
}
const getBrand = (brand) => {
  switch(brand){
    case "visa":
      return visaLogo
    case "mastercard":
      return masterCard
    case "elo":
      return eloLogo
    case "amex":
      return amexLogo
    default:
      return randomLogo
  }
}
export const formatCard = (bin, last4) => {
  bin = bin == null ? "" : bin.toString()
  last4 = last4 == null ? "" : last4.toString()
  let size1 = bin.length
  let size2 = last4.length
  let missing= 16 - size1 -size2
  let a = "*".repeat(missing)
  let cardNumber = bin + a + last4
  return cardNumber.replace(/\S{4}/g, '$& ')
}

//Icons Utils
export const getEntryIcon = (entry_mode) => {
  let logo = null
  switch(entry_mode){
    case "ecommerce":
      logo = computerLogo
      break
    case "fallback_magnetic_stripe":
    case "magnetic_stripe":
      logo = tarjaLogo
      break
    case "contactless":
      logo = contactlessLogo
      break
    case "chip":
      logo = chipLogo
      break
    case "unknown":
    case "typed":
    case "bar_code":
    case "ocr":
    case "track_1":
    case "fallback_typed":
    default:
      logo = randomLogo
      break 
  }
  return (
    <Tooltip title={<p className="tooltipText">{getEntryText(entry_mode)}</p>}>
      <img className="entryIconStyle" src={logo} alt="entryLogo Logo" />
    </Tooltip>
  ) 
}
export const getBoolIcon = (bool) => {
  if (bool == null) return null
  if (bool){
    return (
      <img src={greenCheck} alt="GreenCheck Logo" />      
    )
  }
  else {
    return (
      <img src={redCross} alt="redCross Logo" />    
    )
  }
}
export const getTimeLineIcon = (status) => {
  switch(status){
    case "not_authorized":
    case "chargeback":
    case "partial_chargeback":
      return <ErrorOutlineIcon  style={{width:"30px", height:"30px"}}/>
    case "authorized":
    case "cleared":
    case "captured":
      return <CheckIcon style={{width:"30px", height:"30px"}}/>
    case "cancelled":
    case "partially_cancelled":
      return <ReplayIcon  style={{width:"30px", height:"30px"}}/>
    default:
      return <HelpOutlineIcon  style={{width:"30px", height:"30px"}}/>
  }
}
export const getFraudStatusIcon = (status, decrease=false) => {
  let iconSize = "45px"
  if (decrease) {
    iconSize = "15px"
  }
  switch(status){
    case "automatically_approved":
      return (<CheckIcon className="fraudStatusIcon" style={{width:iconSize, height:iconSize}}/>)
    case "automatically_declined":
    case "automatically_reproved":
      return (<ClearIcon className="fraudStatusIcon" style={{width:iconSize, height:iconSize}}/>)
    case "pending":
    default:
      return(<HelpOutlineIcon className="fraudStatusIcon" style={{width:iconSize, height:iconSize}}/>)
  }
}
export const getSignalsFlagIcon = (flag) => {
  switch(flag){
    case "positive":
      return (<CheckIcon style={{width:"20px", height:"20px", color:colorGreen, margin:"auto"}}/>)
    case "negative":
      return (<ErrorOutlineIcon style={{width:"20px", height:"20px", color:colorRed, margin:"auto"}}/>)
    case "neutral":
    default:
      return(<ErrorOutlineIcon style={{width:"20px", height:"20px", color:colorGrey, margin:"auto"}}/>)
  }
}
export const getDecisionFlagIcon = (flag) => {
    if (flag === "manually_approved" || flag === "automatically_approved") {
      return <CheckIcon style={{width:"20px", height:"20px", color:colorGreen, margin:"auto"}}/>
    } else if (flag === "manually_reproved" || flag === "automatically_reproved" || flag === 'automatically_declined') {
      return <ErrorOutlineIcon style={{width:"20px", height:"20px", color:colorRed, margin:"auto"}}/>
    }
    return <ErrorOutlineIcon style={{width:"20px", height:"20px", color:colorGrey, margin:"auto"}}/>
}
export const getConfirmationIcon = (status) => {
    if (status){
        return (<CheckIcon className="fraudStatusIcon" style={{color: colorGreen, width:"40px", height:"40px"}}/>)
    }
    else{
        return (<ClearIcon className="fraudStatusIcon" style={{color: colorRed, width:"40px", height:"40px"}}/>)
    }
  }
//General Utils
export const titleCase = (str, nullCase) => {
    if (str == null) {
      return nullCase
    }
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  };

export const clientAge = (birthdate, nullCase=null, showCase="years") => {
    if (birthdate == null) return nullCase
    let extraString
    let now = moment()
    let birthdate_ = moment(birthdate)
    
    let years = now.diff(birthdate_, 'year');
    let yearString = years ===1 ? years + " ano" : years + " anos"
    birthdate_.add(years, 'years');
    let months = now.diff(birthdate_, 'months');
    let monthString = months ===1 ? months + " mês" : months + " meses"
    birthdate_.add(months, 'months');
    switch(showCase){
        case "years_months":
            if (years === 0){
                extraString = " (" + months + " meses)"
            }
            else {
                extraString = " (" + yearString + " e " + monthString + ")"
            }
            break
        case "years":
            if (years === 0){
                extraString = " (Menos de um ano)"
            }
            else {
                extraString = " (" + yearString + ")"
            }
            break
        default:
            throw new Error()
    }
    return moment(birthdate).format('DD/MM/YYYY') + extraString
  };

export function checkNested (obj /*, level1, level2, ... levelN*/) {
    if (obj == null) return false
    var args = Array.prototype.slice.call(arguments, 1);

    for (var i = 0; i < args.length; i++) {
        if (!obj || !obj.hasOwnProperty(args[i])) {
        return false;
        }
        obj = obj[args[i]];
    }
    return true;
}
export const formatAddress = (merchant) => {
  if (merchant == null) return null
  let street = titleCase(merchant.street, "")
  let city = titleCase(merchant.city, "")
  let country = merchant.region === "BRA"? ", Brasil" : ""
  let addressString = street !== "" & city !== "" ? street + ", " + city + country : street + city + country
  return (addressString)
}

export const formatPhone  = (phoneObj,nullCase) => {
    if (phoneObj == null) return nullCase

    let ddiString = phoneObj.internation_dial_code == null ? "" : phoneObj.internation_dial_code + " "
    let dddString = phoneObj.area_code == null ? "" : "(" + phoneObj.area_code + ") "
    let numberString = phoneObj.number == null ? "" : phoneObj.number

    return ddiString + dddString + numberString

}

const formatEmail  = (emailObj,nullCase) => {
    if (emailObj == null) return nullCase
    return (emailObj.email == null ? "" : emailObj.email)
}

export const formatExtraInfo = (object, identifier) => {
    let formatFunction
    switch(identifier){
        case "endereços":
            formatFunction = formatAddress
            break
        case "telefones":
            formatFunction = formatPhone
            break
        case "emails":
            formatFunction = formatEmail
            break
        default:
    }
    return (
        <div>
            Outro {identifier} fornecidos:
            <ul>
                {object.slice(1).map((item,index) => (
                    <li key={index}>
                        {formatFunction(item)}
                    </li>
                ))}
            </ul>
        </div>
    )
}

export const getTableRowClass = (rowIndex) => {
  if (rowIndex % 2 === 0) {
    return "even";
  }

  return "odd";
}

export function removeDuplicatesBy(keyFn, array) {
  var mySet = new Set();
  return array.filter(function(x) {
      var key = keyFn(x), isNew = !mySet.has(key);
      if (isNew) mySet.add(key);
      return isNew;
  });
}

export function justNumbers(stringWord) {
  let replacedStringWord = stringWord.replace(/\D/g, '')
    return replacedStringWord
}

export function stringFormatToMoney(numbersAsStringWord) {
  const text = numbersAsStringWord
  if (text.length === 1) return "0,0" + text
  else if (text.length === 2) return "0," + text
  else if (text.length > 3) {
    if (text.slice(0,1)==="0") return text.slice(1,-2) + "," + text.slice(-2)
  }
  return text.slice(0,-2) + "," + text.slice(-2)
}

export const colorGreen = '#73C580'

export const colorRed ='#D91A1A'

const colorYellow = '#DDBB08'

const colorGrey = 'rgb(170, 167, 153)'

export let alertFilterTypes = {
    document_number: {
        type: "brazilian_document_numbers",
        disabled: false,
        validation:true,
        validation_rule: {type: "multiple_equal", values:[14,18]},
        description: "CPF/CNPJ do Cliente"
    },
  alert_date: {
      type: "date",
      disabled: false,
      validation:false,
      description: "Data"
  },
  merchant_id: {
      type: "string",
      disabled: false,
      validation:false,
      description: "ID do Merchant"
  },
  bin: {
      type: "masked_string",
      maskType:"999999",
      disabled: false,
      validation:true,
      validation_rule: {type: "equal", value:6},
      description: "BIN do Cartão"
  },
  last4: {
      type: "masked_string",
      maskType:"9999",
      disabled: false,
      validation:true,
      validation_rule: {type: "equal", value:4},
      description: "Last4 do Cartão"
  },
  cardholder_id: {
      type: "string",
      disabled: false,
      validation:false,
      description: "ID do CardHolder"
  },
  alert_status: {
      type: "enum",
      enumList: [
          ["Pendente", "in_manual_analysis"],
          ["Tratado", "solved"]
      ],
      disabled: false,
      validation:false,
      description: "Status"
    },
  risk_level: {
      type: "enum",
      enumList: [
          ["Baixo Risco", "low"],
          ["Médio Risco", "medium"],
          ["Alto Risco", "high"],
          ["Crítico", "critical"]
      ],
      disabled: false,
      validation:false,
      description: "Risco"
  },
  irregularity_type: {
    type: "enum",
    enumList: [
        ["Fraude", "fraud"],
        ["Compliance", "compliance"]
    ],
    disabled: false,
    validation:false,
    description: "Tipo de irregularidade"
  }  
}

export let transactionFilterTypes = {
    document_number: {
        type: "brazilian_document_numbers",
        disabled: false,
        validation:true,
        validation_rule: {type: "multiple_equal", values:[14,18]},
        description: "Documento do Comprador"
    },
  transaction_date: {
      type: "date",
      disabled: false,
      validation:false,
      validation_rule: null,
      description: "Data"
  },
  transaction_id: {
      type: "string",
      disabled: false,
      validation:false,
      validation_rule: null,
      description: "ID"
    },
  transaction_status: {
      type: "enum",
      enumList: [
          ["Autorizada", "authorized"],
          ["Não Autorizada", "not_authorized"],
          ["Capturada", "captured"],
          ["Cancelada", "cancelled"],
          ["Parcialmente Cancelada", "partially_cancelled"],
          ["Reembolsada", "chargeback"],
          ["Parcialmente Reembolsada", "partial_chargeback"],
          ["Clearing", "cleared"],
          ["Pendente", "pending"]
      ],
      disabled: false,
      validation:false,
      validation_rule: null,
        description: "Status da Transação"
    },
  fraud_status: {
      type: "enum",
      enumList: [
          ["Aprovada", "automatically_approved"],
          ["Reprovada", "automatically_declined"],
      ],
      disabled: false,
      validation:false,
      validation_rule: null,
      description: "Status de Fraude"
  },
cardholder_id: {
    type: "string",
    disabled: false,
    validation:false,
    description: "ID do CardHolder"
}
// ,
// pan_entry_mode: {
//   type: "enum",
//   enumList: [
//     ["Desconhecido", "unknown"],
//     ["Digitado", "typed"],
//     ["Código de Barras",  "bar_code"],
//     ["OCR", "ocr"],
//     ["Chip", "chip"],
//     ["Track 1", "track_1"],
//     ["Contactless", "contactless"],
//     ["Fallback por Digitação", "fallback_typed"],
//     ["Fallback por Tarja Magnética", "fallback_magnetic_stripe"],
//     ["E-commerce", "ecommerce"],
//     ["Tarja Magnética", "magnetic_stripe"]
//   ],
//   disabled: false,
//   validation:false,
//   validation_rule: null,
//   description: "Modo de Entrada"
// }
}

export const getValidationStatusIcon = (status) => {
  const {t} = i18next

  switch(status){
    case "expired":
      return (
        <Tooltip title={<p className="tooltipText">{t(status)}</p>}>
            <div className={["circle", "small"].join(" ")} style={{backgroundColor: colorRed}}>
                <HistoryIcon  style={{color: "#ffffff", width:"18px", height:"18px"}}/>
            </div>
        </Tooltip>
    );
    case "denied":
      return (
          <Tooltip title={<p className="tooltipText">{t(status)}</p>}>
              <div className={["circle", "small"].join(" ")} style={{backgroundColor: colorRed}}>
                  <HighlightOffIcon  style={{color: "#ffffff", width:"18px", height:"18px"}}/>
              </div>
          </Tooltip>
      );
    case "validated":
      return (
          <Tooltip title={<p className="tooltipText">{t(status)}</p>}>
          <div className={["circle", "small"].join(" ")} style={{backgroundColor: colorGreen}}>
              <CheckIcon style={{color: "#ffffff", width:"18px", height:"18px"}}/>
          </div>
          </Tooltip>
      );
    case "message_submited":
      return (
          <Tooltip title={<p className="tooltipText">{t(status)}</p>}>
              <div className={["circle", "small"].join(" ")} style={{backgroundColor: colorYellow}}>
                  <MobileFriendlyIcon  style={{color: "#ffffff", width:"18px", height:"18px"}}/>
              </div>
          </Tooltip>
      );
    case 'message_not_submited':
        return (
            <Tooltip title={<p className="tooltipText">{t(status)}</p>}>
        <div className={["circle", "small"].join(" ")} style={{backgroundColor: colorGrey}}>
            <PauseCircleOutlineIcon  style={{color: "#ffffff", width:"18px", height:"18px"}}/>
        </div>
        </Tooltip>)
    case 'message_failed':
      return (
        <Tooltip title={<p className="tooltipText">{t(status)}</p>}>
    <div className={["circle", "small"].join(" ")} style={{backgroundColor: colorRed}}>
        <PhonelinkEraseIcon  style={{color: "#ffffff", width:"18px", height:"18px"}}/>
    </div>
    </Tooltip>
    );
    default:
      return (
          <Tooltip title={<p className="tooltipText">{t(status)}</p>}>
      <div className={["circle", "small"].join(" ")} style={{backgroundColor: colorGrey}}>
          <HelpOutlineIcon  style={{color: "#ffffff", width:"18px", height:"18px"}}/>
      </div>
      </Tooltip>
      );
  }
}