import React from "react";
import FilterBox from "../../../Utils/FilterBox/FilterBox"
import { getLockTypesFromEventType } from "../../utils/utilsLock"

function Filter(props) {
	
    const { setPageParams, eventType } = props

    let filterTypes = {
        lock_type:{
            type: "enum",
            enumList: getLockTypesFromEventType(eventType),
            disabled: false,
            validation:false,
            description: "Tipo do Bloqueio"
        },
        lock_link_key:{
            type: "string",
            disabled: false,
            validation:false,
            description: "Chave do Bloqueio"
        },
        lock_status: {
            type: "enum",
            enumList: [
                ["Bloqueado", "blocked"],
                ["Desbloqueado", "unblocked"]
            ],  
            disabled: false,
            validation:false,
            description: "Status"
        },
        lock_source: {
            type: "enum",
            enumList: [
                ["Manual", "manual_input"],
                ["Alerta Manual", "manual_alert_analysis"],
                ["Automático", "automatic"]
            ],
            disabled: false,
            validation:false,
            description: "Fonte do Bloqueio"
        },
        lock_date: {
            type: "date",
            disabled: false,
            validation:false,
            validation_rule: null,
            description: "Data"
        }
    }

    return (
        <FilterBox 
            setPageParams={setPageParams}
            filterTypes={filterTypes}
            local_storage_object_preffix={props.local_storage_object_preffix}
            initialFilters={{"lock_status": "blocked"}}
        />
    )

}

export default Filter