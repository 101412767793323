import React from 'react';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import * as Styled from './styles';

// Typescript declaration of the component interface for future implementation. The component props will be an object with these attributes.
//
// interface ObservationTextFieldProps {
//     content: String;
//     setContent: React.Dispatch;
//     service: "onboarding" | "credit_analysis" | "card_order" | "car_rental" | "bankslip" | "bill_payment" | "wire_transfer" | "pix" | "currency_exchange";
//     disable?: bool;
// }

const ObservationTextField = (props) => {

    const limits = {
        "onboarding": 1000,
        "credit_analysis": 1000,
        "card_order": 1000,
        "car_rental": 920,
        "bankslip": 1000, 
        "bill_payment": 1000,
        "wire_transfer": 1000,
        "pix": 1000,
        "currency_exchange": 1000,
    }

    const theme = createMuiTheme({
        overrides: { MuiInputBase: { root:{
            fontFamily: "Open Sans",
            fontSize: "14px",
            lineHeight: "19px",
            background: "#F7F7F7"
    
        }}},
        palette:{
            primary: {
                main: (props.content || '').length  >= limits[props.service] ? "#F00" : "#0b1f82"
            },
        }
    });

    return(
        <Styled.Container>
            <ThemeProvider theme={theme}>
            {props.service === 'car_rental' ? 
                <TextField 
                    fullWidth
                    multiline
                    color="primary"
                    variant="outlined"
                    margin="dense"
                    minRows="5"
                    value={props.content || ''}
                    inputProps={{ maxLength: limits[props.service] }}
                    onChange={(e) => props.setContent(e.target.value)}
                    style ={(props.content || '').length >= limits[props.service] ? { BorderColor: "red" } : {}}
                    disabled={props.disable}
                /> : 
                <TextField 
                    fullWidth
                    multiline
                    color="primary"
                    variant="outlined"
                    margin="dense"
                    minRows="5"
                    defaultValue={props.content || ''}
                    inputProps={{ maxLength: limits[props.service] }}
                    onChange={(e) => props.setContent(e.target.value)}
                    style ={(props.content || '').length >= limits[props.service] ? { BorderColor: "red" } : {}}
                    disabled={props.disable}
                />}
            </ThemeProvider>
            <Styled.Counter alert={(props.content || '').length >= limits[props.service]} hide={props.disable}>
                Caracteres: {(props.content || '').length}/{limits[props.service]}{(props.content || '').length >= limits[props.service] ? " - Limite atingido!" : ""}
            </Styled.Counter>
        </Styled.Container>
        

    );
}

export default ObservationTextField;