import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, useTheme, createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {formatAsMoney} from "../../../utils/utils"

const theme = createMuiTheme({
    overrides: { MuiTableCell: { root:{
        padding:"10px"
    }}}
  });

const useStyles = makeStyles((theme) => ({
    blueText: {
        fontFamily: "Open Sans",
        fontWeight: 600,
        color: "#0b1f82",
    },
    normalText: {
        fontFamily: "Open Sans",
    },
    bold: {
        fontWeight: 600,
    },
    dialogContent: {
        marginBottom: "20px",
        width:"500px"
    },
    subtitleSize: {
        fontSize: "16px",
        lineHeight: "22px"
    },
    normalMediumSize: {
        fontSize: "14px",
        lineHeight: "19px"                
    }
}));

const PriceDescriptionDialog = (props) => {
    const classes = useStyles();
    useTheme();

    return (
        <ThemeProvider theme={theme}>
            <Dialog
                open={props.open}
                onClose={props.onClose}
                aria-labelledby="change-password-dialog"
                keepMounted={false}
            >
                <DialogTitle
                    className={[classes.blueText, classes.subtitleSize].join(" ")}
                    id="rental-agreement-price-description-dialog"
                >
                    Valores
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell 
                                align="left" 
                                className={[classes.blueText, classes.normalMediumSize].join(" ")}
                            >
                                Diárias
                            </TableCell>
                            <TableCell 
                                align="center" 
                                className={[classes.normalText, classes.normalMediumSize].join(" ")}
                            >
                                {formatAsMoney(props.rental_agreement.rental_price, "-")}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell 
                                align="left" 
                                className={[classes.blueText, classes.normalMediumSize].join(" ")}
                            >
                                Desconto
                            </TableCell>
                            <TableCell 
                                align="center" 
                                className={[classes.normalText, classes.normalMediumSize].join(" ")}
                            >
                                {formatAsMoney(props.rental_agreement.discount, "-")}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell 
                                align="left" 
                                className={[classes.blueText, classes.normalMediumSize].join(" ")}
                            >
                                Proteção
                            </TableCell>
                            <TableCell 
                                align="center" 
                                className={[classes.normalText, classes.normalMediumSize].join(" ")}
                            >
                                {formatAsMoney(props.rental_agreement.coverage_price, "-")}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell 
                                align="left" 
                                className={[classes.blueText, classes.normalMediumSize].join(" ")}
                            >
                                Proteção Ocup. Terceiros
                            </TableCell>
                            <TableCell 
                                align="center" 
                                className={[classes.normalText, classes.normalMediumSize].join(" ")}
                            >
                                {formatAsMoney(props.rental_agreement.third_party_coverage_price, "-")}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell 
                                align="left" 
                                className={[classes.blueText, classes.normalMediumSize].join(" ")}
                            >
                                Taxa de Retorno
                            </TableCell>
                            <TableCell 
                                align="center" 
                                className={[classes.normalText, classes.normalMediumSize].join(" ")}
                            >
                                {formatAsMoney(props.rental_agreement.devolution_fee, "-")}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell 
                                align="left" 
                                className={[classes.blueText, classes.normalMediumSize].join(" ")}
                            >
                                Taxa Administrativa
                            </TableCell>
                            <TableCell 
                                align="center" 
                                className={[classes.normalText, classes.normalMediumSize].join(" ")}
                            >
                                {formatAsMoney(props.rental_agreement.administration_fee, "-")}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell 
                                align="left" 
                                className={[classes.blueText, classes.subtitleSize].join(" ")}
                            >
                                Total
                            </TableCell>
                            <TableCell 
                                align="center" 
                                className={[classes.normalText, classes.subtitleSize, classes.bold].join(" ")}
                            >
                                {formatAsMoney(props.rental_agreement.final_price, "-")}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell 
                                align="left" 
                                className={[classes.blueText, classes.normalMediumSize].join(" ")}
                            >
                                Pré Autorização
                            </TableCell>
                            <TableCell 
                                align="center" 
                                className={[classes.normalText, classes.normalMediumSize].join(" ")}
                            >
                                {formatAsMoney(props.rental_agreement.pre_authorization_amount, "-")}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell 
                                align="left" 
                                className={[classes.blueText, classes.normalMediumSize].join(" ")}
                            >
                                Participação Obrigatória
                            </TableCell>
                            <TableCell 
                                align="center" 
                                className={[classes.normalText, classes.normalMediumSize].join(" ")}
                            >
                                {formatAsMoney(props.rental_agreement.coverage_deductible_amount, "-")}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                    </Table>
                </DialogContent>
            </Dialog>            
        </ThemeProvider>
    );
};

export default PriceDescriptionDialog;
