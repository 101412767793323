import React, {useState, useReducer, useContext} from 'react'
import TextField from '@material-ui/core/TextField';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { getConfirmationIconDialog, colorVelvet } from "../../utils/utils"
import {decisionReducer} from "../../../Utils/requestUtils"
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteIcon from '@material-ui/icons/Delete';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import AuthContext from "../../../../context/auth-context"
import axios from 'axios';
import { CircularProgress } from '@material-ui/core';

const theme = createMuiTheme({
    palette:{
        primary: {
            main:"#0b1f82"
        },
        secondary: {
            main: "#FF0000"
        },
    }
  });

function InputContent (props){

    let field_types = ["bool","number","string","datetime", "time", "timedelta", "geo_point", "geo_shape"]
    let key_field_types = ["string", "geo_point", "geo_shape"]

    let standardObject = {
        fieldName: "",
        fieldType:"",
        nameError: true,
        typeError: true
    }

    let listNameObject = {
        name: "",
        error: true
    }

    let listDescriptionObject = {
        description: "",
        error: true
    }

    let listCompanyObject = {
        name: "",
        company_key: "",
        error: true
    }

    const [object, setObject] = useState([{...standardObject}])
    const [listName, setListName] = useState(listNameObject)
    const [listDescription, setListDescription] = useState(listDescriptionObject)
    const [listCompany, setListCompany] = useState(listCompanyObject)

    let businessGroupCompaniesList = [] 
    if ((props.businessGroupCompanies || {}).business_group_data != null){
        businessGroupCompaniesList = props.businessGroupCompanies.business_group_data
    }

    const companyListAutocompleteOptions = businessGroupCompaniesList.reduce((acc,currentValue) => {
            let newOption = currentValue.company_name
            acc.push(newOption)
            return acc
        },[])

    const translateType = (type) => {
        switch(type){
            case "bool":
                return ("Binário")
            case "number":
                return ("Número")
            case "string":
                return ("Texto")
            case "datetime":
                return ("Data e Hora")
            case "time":
                return ("Hora")
            case "timedelta":
                return ("Período de Tempo")
            case "geo_point":
                return ("Coordenada Geográfica")
            case "geo_shape":
                return ("Polígono Geográfico")
            case "":
                return ("Selecione")
            default:
                throw new Error("Invalid type" + type)
        }
    }

    const getAvailableTypes = (index) => {
        let availableTypes = []

        if (index === 0){
            availableTypes = key_field_types
        }
        else {
            availableTypes = field_types
        }

        return availableTypes
    }

    const handleAdd = () => {
        let newObject = [...object, {...standardObject}]
        setObject(newObject)
    }

    const handleDeleteListHeader = (index) => {
        let newObject = [...object]
        newObject.splice(index, 1)

        if (key_field_types.indexOf(newObject[0]) >= 0 ) {
            newObject[0].typeError = false
        }
        else{
            newObject[0].typeError = true
        }
        setObject(newObject) 
    }

    const handleChangeValue = (event,index) => {
        let newObject = [...object]
        newObject[index].fieldName = event.target.value
        if (newObject[index].fieldName.length !== 0) {
            newObject[index].nameError = false
        }
        else{
            newObject[index].nameError = true
        }
        setObject(newObject)
    }

    const handleChangeType = (event,index,reference) => {
        let newObject = [...object]
        newObject[index].fieldType = event
        setObject(newObject)
        console.log(event)
        console.log(reference.indexOf(event))
        if (reference.indexOf(event) >= 0 ) {
            newObject[index].typeError = false
        }
        else{
            newObject[index].typeError = true
        }
    }

    const handleChangeListName = (event) => {
        let newObject = {...listName}
        newObject.name = event.target.value
        if (event.target.value.length !== 0) {
            newObject.error = false
        }
        else{
            newObject.error = true
        }
        setListName(newObject)
    }

    const handleChangeListCompany = (event) => {
        let newObject = {...listCompany}
        newObject.name = event
        newObject.company_key = businessGroupCompaniesList.filter(item => item.company_name === event)[0] ? businessGroupCompaniesList.filter(item => item.company_name === event)[0].company_key : ""
        setListCompany(newObject)
    }

    const handleChangeListDescription = (event) => {
        let newObject = {...listDescription}
        newObject.description = event.target.value
        if (event.target.value.length !== 0) {
            newObject.error = false
        }
        else{
            newObject.error = true
        }
        setListDescription(newObject)
    }

    const [region, setRegion] = useState('us_east_1');

    const handleRegionChange = (e) => {
        setRegion(e.target.value);
    }

    const handleReturn = () => {
        props.onClose()
        props.setPageParams({page: 1, filters: JSON.parse(sessionStorage.getItem(props.local_storage_object_preffix + 'PageParams')).filters})
    }

    const validate = (object) => {
        if (props.user_data.business_group_key && !listCompany.name) return false
        let error_keys = object.filter(object => {
            return (object.nameError === true || object.typeError === true)
        })
        if (error_keys.length > 0 || listName.error === true || object.length === 0 ||  key_field_types.indexOf(object[0].fieldType)  === -1) return false
        else return true
    }

    const [dialogState, setDialogState] = useReducer(
        decisionReducer,
        {isLoading: false, isError: false, finished:false}
    ) 

    const onSubmit = () => {
        if (!validate(object)) return
        let payload = {
            name: listName.name,
            description: listDescription.description,
            region: region
        }
        payload.fields = object.reduce((acc, item) =>{
            let newObject = {
                name: item.fieldName,
                type: item.fieldType,
            }
            acc = [...acc, newObject]
            return acc
        },[])
        let requestHeaders = {headers:{}}
        if (props.user_data.business_group_key) {
            requestHeaders = {headers:{company_key:listCompany.company_key}}
        }
        setDialogState({type:"send_request_init"})
        axios.post('/dash/lists/list', payload, requestHeaders).then(response => {
            setDialogState({type:"send_request_success"})
        }).catch(error => {
            setDialogState({type: "send_request_failure"})
        });
    }

    let user_data = useContext(AuthContext).user_data

    if (dialogState.isLoading){
        return(
            <div style={{display:"flex", flexGrow:"1"}}>
                <CircularProgress style={{margin:"auto"}}/>
            </div>
        )
    }
    else if (dialogState.finished) {
        return(
            <div style={{ display:"flex", flexDirection:"column", flexGrow:"1"}}>
                <div style={{margin:"auto"}}>
                    {getConfirmationIconDialog(!dialogState.isError)}
                </div>
                <div style={{margin:"10px auto"}}>
                    {dialogState.message}
                </div>
                <div
                    onClick={handleReturn} 
                    className={["button","standard", "normalText", "normalMediumSize"].join(" ")} 
                    style={{margin:"20px auto 10px auto", width:"auto"}}
                >
                    VOLTAR À PÁGINA INICIAL
                </div>
            </div>
        )
    }
    else {
        return (
            <div style={{ display:"flex", flexDirection:"column", flexGrow:"1" }}>
                <div style={{display: "flex",justifyContent: "center",flexDirection: "column",margin: "0 auto 10px auto",width: "90%"}}>
                    <ThemeProvider theme={theme}>
                        {props.user_data.business_group_key ?
                        <div style={{display:"flex", flexDirection:"column", marginBottom:"30px"}}>
                            <h3 style={{fontSize: 20, fontWeight: 300, letterSpacing: 0.5, margin: 0}}>Companhia de criação</h3>
                            <p style={{fontSize: 12, fontWeight: 300, letterSpacing: 0.5}}>Selecione a companhia em que a lista deverá ser criada.</p>
                            <Autocomplete
                                id="combo-box-demo"
                                value={listCompany.name}
                                onChange={(event, newValue) => {
                                    handleChangeListCompany(newValue);
                                }}
                                options={companyListAutocompleteOptions}
                                getOptionLabel={(option) => option}
                                style={{ width: 300 }}
                                renderInput={(params) => 
                                    <TextField 
                                        {...params} 
                                        autoFocus 
                                        label="Companhia" 
                                        color="primary" 
                                        variant="outlined" 
                                    />}
                            />
                        </div> : null}
                        <h3 style={{fontSize: 20, fontWeight: 300, letterSpacing: 0.5, margin: 0}}>Identificação</h3>
                        <p style={{fontSize: 12, fontWeight: 300, letterSpacing: 0.5}}>Defina o nome e a descrição da nova lista.</p>
                        <div style={{display:"inline-flex"}}>
                                <div style={{marginBottom:"20px"}}>
                                        <TextField 
                                            color={listName.error? "secondary": "primary"}
                                            label={"Nome da Lista"}
                                            onChange={(e) => handleChangeListName(e)}
                                            value={listName.name}
                                        />
                                </div>
                                <div style={{marginBottom:"20px", marginLeft:"60px"}}>
                                        <TextField 
                                            color={listDescription.error? "secondary": "primary"}
                                            label={"Descrição"}
                                            onChange={(e) => handleChangeListDescription(e)}
                                            value={listDescription.description}
                                        />
                                </div>
                        </div>
                        {user_data.roles.includes("view_regions") &&
                            <div>
                                <h3 style={{fontSize: 20, fontWeight: 300, letterSpacing: 0.5, margin: 0}}>Região</h3>
                                <p style={{fontSize: 12, fontWeight: 300, letterSpacing: 0.5}}>Selecione a região em que a lista deverá ser armazenada. Somente serviços contratados na mesma região da lista poderão ter acesso a ela.</p>
                                <Select value={region} displayEmpty inputProps={{ 'aria-label': 'Without label' }} onChange={handleRegionChange}
                                style={{minWidth: 240}}
                                >
                                    <MenuItem value="us_east_1">América do Norte</MenuItem>
                                    <MenuItem value="sa_east_1">América do Sul</MenuItem>
                                </Select>
                            </div>
                        }
                        <div style={{height: 20}} />
                        {object.map((item, index) => (
                            <div key={index} style={{marginTop:"10px"}}>
                                <h3 style={{fontSize: 20, fontWeight: 300, letterSpacing: 0.5, margin: 0}}>
                                    {index === 0 ? "Campo chave" : String(index + 1) + "º Campo"}
                                </h3>
                                {index === 0 ? 
                                <div className={"normalText"} style={{fontSize:"12px"}}>
                                    Atenção, o primeiro campo definido é a chave da lista, e seu valor deverá ser único entre os itens que forem adicionados nela.
                                </div> : null}
                                <div style={{display:"inline-flex", marginTop:"20px"}}>
                                    <div style={{marginBottom:"20px"}}>
                                        <TextField 
                                            color={object[index].nameError? "secondary": "primary"}
                                            label={"Nome do Campo"}
                                            onChange={(e) => handleChangeValue(e, index)}
                                            value={object[index].fieldName}
                                            key={index}
                                        />
                                    </div>
                                    <div style={{marginBottom:"20px", marginLeft:"60px"}}>
                                        <Autocomplete
                                        id="combo-box-demo"
                                        value={object[index].fieldType}
                                        onChange={(event, newValue) => {
                                            handleChangeType(newValue, index, getAvailableTypes(index));
                                        }}
                                        options={getAvailableTypes(index)}
                                        getOptionLabel={(option) => translateType(option)}
                                        style={{ width: 300 }}
                                        renderInput={(params) => 
                                            <TextField 
                                                {...params} 
                                                autoFocus
                                                label="Tipo" 
                                                color={object[index].typeError? "secondary": "primary"}
                                                variant="outlined" 
                                            />}
                                        />
                                    </div>
                                    { index !== 0 ?
                                        <div onClick={() => handleDeleteListHeader(index)}
                                            style={{cursor:"pointer", width:"50px", margin:"5px 20px"}}>
                                            <DeleteIcon style={{fill:colorVelvet, fontSize:"40px"}}/>
                                        </div> :
                                        <div onClick={() => handleDeleteListHeader(index)}
                                        style={{cursor:"pointer", width:"50px", margin:"5px 20px"}}>
                                        </div>
                                    }
                                </div>
                            </div>
                        ))}
                    </ThemeProvider> 
                    <div
                        style={{width:"-moz-fit-content", cursor:"pointer", fontWeight:"600", marginBottom:"10px",marginTop:"10px"}}
                        onClick={handleAdd}
                        className={"blueTextLabel"}
                    >
                    + Adicionar Campo
                    </div>
                </div>
                <div style={{display:"flex", flexDirection:"row", justifyContent: "center", margin:"auto 30px 20px 0px"}}>
                    <div
                        className={(!validate(object)) ? ["button","standard", "disabled", "normalText", "normalMediumSize"].join(" ") : ["button","standard", "normalText", "normalMediumSize"].join(" ")} 
                        onClick={onSubmit} 
                        style={{width:"40%"}}
                    >
                        Salvar Lista
                    </div>
                </div>
            </div>
        )
    }
}

export default InputContent