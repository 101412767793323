import React, {useState, useEffect} from 'react'
import { useTranslation } from "react-i18next";
import {GetAlertsComponent,setObjectByPath } from "./utils"
import TextField from "@material-ui/core/TextField";
import MenuItem from '@material-ui/core/MenuItem';
import _ from "lodash"
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";

export function GetVariableBox (props) {

    const {t} = useTranslation()
    let {node} = props
    let {read_only} = props
    let {handleConfigurationOpen} = props
    let {handleNodeRemove} = props
    let {userVariablesAvailable} = props

    const [label, setLabel] = useState("")

    useEffect(() => {
        if (userVariablesAvailable.length > 0) {
            let variableObject = userVariablesAvailable.filter((option)=>option.variable_name === node.node.properties.variable_name)[0]
            let label = (variableObject||{}).variable_name? `${t(variableObject.variable_name)} (${t(variableObject.variable_type)})`: ""
            setLabel(label)
        }
    },[userVariablesAvailable, node, t])

    return (
        <div>
            {!read_only ? 
                <div style={{display:"flex", alignItems: "center"}}>
                    <div 
                        className={["normalText", "normalMediumSize", "bold"].join(" ")} 
                        style={{display:"flex", width:"100%", cursor:"pointer", margin: "auto 0px"}} 
                        onClick={() => handleConfigurationOpen(node)}
                    >
                        {label}
                        {(node.node.alerts || []).length > 0?
                            <GetAlertsComponent alerts={node.node.alerts} />  : null}  
                    </div>
                    <IconButton style={{width: 32, height: 32}} aria-label="duplicate" onClick={() => props.handleNodeDuplication(node)}>
                        <ContentCopyIcon style={{width: 20, height: 20}} />
                    </IconButton>
                    <IconButton style={{width: 32, height: 32}} aria-label="close" onClick={() => handleNodeRemove(node)}>
                        <CloseIcon style={{width: 20, height: 20}} />
                    </IconButton>
                </div>
                :
                <div style={{display:"flex"}}>
                    <div 
                        className={["normalText", "normalMediumSize", "bold"].join(" ")} 
                        style={{display:"flex", width:"100%", cursor:"pointer", margin: "auto 0px"}} 
                        onClick={() => handleConfigurationOpen(node)}
                    >
                        {label}
                    </div>
                </div>
            }
        </div>
    )
}

export function GetVariableConfiguration(props){

    const { t } = useTranslation();
    let {node} = props
    let {read_only} = props
    let {handleNodeChange} = props
    let {handleConfigurationClose} = props
    let {userVariablesAvailable} = props

    const [nodeState, setNodeState] = useState(node)

    useEffect(()=>{
        setNodeState(node)
    },[node])

    const handleNodeStateChange = (obj_key,value) => {
        let new_node = _.cloneDeep(nodeState)
        setObjectByPath(new_node.node,obj_key,value)
        setNodeState(new_node)
    }
    
    const handleSave = () => {
        handleConfigurationClose()
        handleNodeChange(nodeState)
    }

    const validateValue = () => {
        if (nodeState.node.properties.variable_name) {
            return true
        } else {
            return false
        }
    }

    return(
        <div style={{flexGrow:"1", padding: "20px", display:"flex", flexDirection:"column"}}>
            <div style={{display: "flex"}}>
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>{t(node.node.description)}</span>
                <Tooltip title={<div className="tooltipText"><ul>{node.node.helperText.map((help,index) => (<li key={index}>{t(help)}</li>))}</ul></div>}>
                    <HelpIcon style={{fontSize: "14px", display:"flex", margin:"auto 5px"}}/>
                </Tooltip>
            </div>
            <div className="internalCardContainer" style={{display:"flex", flexDirection: "column"}}>
                <TextField
                    label={t("variable_name")}
                    style={{textAlign:"center", width:"80%", margin:"3px auto"}}
                    className="filterTextbox"
                    size="small" fullWidth 
                    value={nodeState.node.properties.variable_name}
                    onChange={(e) => handleNodeStateChange("properties.variable_name", e.target.value)}
                    InputProps={{
                        readOnly: read_only
                    }}
                    select
                >
                    {userVariablesAvailable&&
                    userVariablesAvailable.map((option, index) => (
                        <MenuItem key={index} value={option.variable_name}><em className="normalText">{`${option.variable_name} (${t(option.variable_type)})`}</em></MenuItem>
                    ))}
                </TextField>               
            </div>
            <div style={{display:"flex", margin: "auto auto 5px auto"}}>
                <div
                    className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")}
                    style={{width:"120px"}}
                    onClick={handleConfigurationClose}
                >
                    {read_only ? "X" : t("discard")}
                </div>
                {!read_only && 
                <div
                    className={
                        validateValue()
                        ?
                        ["button", "standard", "normalText", "normalMediumSize"].join(" ")
                        :
                        ["button", "standard", "normalText", "normalMediumSize","disabled"].join(" ")}
                    style={{width:"120px"}}
                    onClick={validateValue() ? handleSave : null}
                >
                    {t("save")}
                </div>}     
            </div>
        </div>
    )
}

export class GetVariableBoxClass {
    constructor(rulesContext, userVariablesAvailable){
        this.rulesContext = rulesContext
        this.userVariablesAvailable = userVariablesAvailable
    }

    validate(node){
        let put_error = false
        let alerts = []

        let variableNamesAvailable = this.userVariablesAvailable.reduce((acc, option) => {
            acc.push(option.variable_name)
            return acc
        },[])

        //Validate if a variable was choosen
        if (!variableNamesAvailable.includes(node.node.properties.variable_name)){
            put_error = true
            alerts.push("Clique no nó, abra a configuração e escolha uma variável")
        }

        //Validate if node has one children
        if((node.node.children || []).length !== 0){
            put_error = true
            alerts.push("Esse nó não deve possuir filho")
        }
        
        // Validate if parent is valid
        if (node.parentNode != null) {
            if(node.parentNode.on_error){
                put_error = true
                alerts.push("O nó superior está inválido")
            }
        }
        return {validated: !put_error, alerts: alerts}
    }

    getType(node) {
        let variable = this.userVariablesAvailable.filter(variable => variable.variable_name === node.properties.variable_name)[0]
        return (variable||{variable_type:""}).variable_type
    }
}