import React from "react";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";

const greenIcon = new L.Icon({
    iconUrl: require("../../../../../assets/markerIcons/marker-icon-2x-green.png"),
    shadowUrl: require("../../../../../assets/markerIcons/marker-shadow.png"),
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });
  
  const redIcon = new L.Icon({
    iconUrl: require("../../../../../assets/markerIcons/marker-icon-2x-red.png"),
    shadowUrl: require("../../../../../assets/markerIcons/marker-shadow.png"),
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });
  

function DeviceMap(props){
    let {device_scan} = props
    let gps_coordinates = (device_scan.gps_data || {}).coordinates
    let ip_coordinates = (device_scan.ip_data || {}).coordinates

    if (!gps_coordinates && !ip_coordinates){
        return (
            <div style={{display: "flex", height: "192px"}}>
                <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                    Informação Indisponível
                </div>
            </div>
        )
    }
    else {
        gps_coordinates = gps_coordinates ? gps_coordinates.reverse() : gps_coordinates
        ip_coordinates = ip_coordinates ? ip_coordinates.reverse() : ip_coordinates
        return (
        <Map
            className="ciTransactionMerchant"
            center={gps_coordinates || ip_coordinates}
            zoom={14}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            {gps_coordinates ?
            <Marker
                position={gps_coordinates}
                icon={greenIcon}
            >
                <Popup>Coordenadas do GPS do Dispositivo</Popup>
            </Marker>
            :null}
            {ip_coordinates ?
            <Marker
                position={ip_coordinates}
                icon={redIcon}
            >
                <Popup>Coordenadas do IP do Dispositivo</Popup>
            </Marker>
            :null}
          </Map>
        )
    }

}

export default DeviceMap