import React from 'react'
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import fingerprint from '../../../../assets/cardEntryIcons/fingerprint.svg';
import password from '../../../../assets/cardEntryIcons/password.svg';
import creditcard from '../../../../assets/cardEntryIcons/creditcard.svg';
import number from '../../../../assets/cardEntryIcons/number.svg';
import DataField from "../../utils/DataField"
import {formatAddress, getTerminalType} from "../../utils/utils"
import DeviceMap from "./DeviceMap/DeviceMap"
import Tooltip from "@material-ui/core/Tooltip";

function TerminalCard(props) {

    let terminal = props.deposit.terminal
    let authentication = props.deposit.authentication
    let coordinates = [terminal.latitude, terminal.longitude]

    return(
        <div className="analysisCard">
            <div className={["blueText", "subtitleSize"].join(" ")} style={{display:'flex'}}>
                <LocalAtmIcon />
                <div style={{marginLeft:'8px'}}>Terminal</div>
            </div>
            <div className="internalCardContainer">
                <DeviceMap coordinates={coordinates}/>
            </div>
            <div style={{display:'flex'}}>
                <div style={{width:'65%'}}>
                    <div>
                        <DataField
                            title={"Endereço"}
                            label={formatAddress((terminal || {}).address, null)}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="5px"
                        />
                    </div>
                    <div>
                        <DataField
                            title={"Tipo de Terminal"}
                            label={getTerminalType((terminal || {}).type)}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="5px"
                        />
                    </div>
                </div>
                <div style={{width:'35%'}}>
                    <div style={{width:'50%', display:'flex', margin:'auto auto'}}>
                        <div style={{margin:'auto auto'}}>
                            <Tooltip title={'Digitou Senha'}>
                                <img style={{margin:"auto 8px auto 0px"}} src={password} alt="password Logo"/>
                            </Tooltip>
                            {authentication.used_password?'Sim':'Não'}
                        </div>
                        <div style={{margin:'auto auto'}}>
                            <Tooltip title={'Usou Cartão'}>
                                <img style={{margin:"auto 8px auto 0px"}} src={creditcard} alt="creditcard Logo"/>
                            </Tooltip>
                            {authentication.used_card?'Sim':'Não'}
                        </div>
                    </div>
                    <div style={{width:'50%', display:'flex', margin:'auto auto'}}>
                        <div style={{margin:'auto auto'}}>
                            <Tooltip title={'Usou Impressão Digital'}>
                                <img style={{margin:"auto 8px auto 0px"}} src={fingerprint} alt="fingerprint Logo"/>
                            </Tooltip>
                            {authentication.used_fingerprint?'Sim':'Não'}
                        </div>
                        <div style={{margin:'auto auto'}}>
                            <Tooltip title={'Digitou Numero da Conta'}>
                                <img style={{margin:"auto 8px auto 0px"}} src={number} alt="number Logo"/>
                            </Tooltip>
                            {authentication.typed_account_number?'Sim':'Não'}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TerminalCard