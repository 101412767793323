import React, {useState, useEffect} from "react";
import SelectLockTypeContent from "./SelectLockTypeContent"
import SelectCompany from "./SelectCompany"
import InputContent from "./InputContent"
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

function NewLockDialog(props) {

    const [lockType, setLockType] = useState(null)
    const [selectCompany, setSelectCompany] = useState(null)

    useEffect(() => {
        if (props.user_data.business_group_key){
            setSelectCompany({
                status: true
            })
        }
        else{
            setSelectCompany({
                status: false,
                company_key: props.user_data.company_key
            })
        }
    },[props.user_data])

    const handleDialogClose = () => {
        props.onClose()
        setTimeout(() => {
            if (props.user_data.business_group_key){
                setSelectCompany({
                    status: true
                })
            }
            else{
                setSelectCompany({
                    status: false,
                    company_key: props.user_data.company_key
                })
            }}, 500)
    }
    
    let dialogContent = null

    if (selectCompany===null){
        dialogContent = null
    }
    else if (selectCompany.status){
        dialogContent = <SelectCompany setSelectCompany={setSelectCompany}/>
    }
    else if (!lockType){
        dialogContent = <SelectLockTypeContent setLockType={setLockType}/>
    }
    else (
        dialogContent = <InputContent 
            setLockType={setLockType}
            setPageParams={props.setPageParams}
            lockType={lockType}
            company_key={selectCompany.company_key}
            local_storage_object_preffix={props.local_storage_object_preffix}
            onClose={handleDialogClose}
        />
    )

    return (
        <Dialog 
        open={props.open} 
        onClose={handleDialogClose}
        disableBackdropClick
        keepMounted={false}>
            <DialogTitle 
                className={['blueText', "subtitleSize"].join(" ")} 
                onClose={handleDialogClose}
            >
                Novo Bloqueio
            </DialogTitle>
            <DialogContent >
                <div style={{width:"366px", minHeight: "160px", display:"flex", flexDirection:"column", marginBottom:"30px"}}>
                    {dialogContent}
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default NewLockDialog