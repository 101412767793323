import React, {useEffect, useReducer, useCallback } from "react";
import { dataFetchReducer } from "../../Utils/requestUtils"
import Pagination from "@material-ui/lab/Pagination";
import { CircularProgress } from '@material-ui/core';
import axios from "axios";
import WithdrawalSearchTable from './SearchHistoricTables/WithdrawalSearchTable'

function SearchWithdrawals(props) {

    const { search_term, pageParams, setPageParams } = props

    const [pageState, dispatchPageState] = useReducer(
        dataFetchReducer,
        {fetchedData: null, isLoading: true, isError: false} 
    )

    const doRequest = useCallback(
        (payload) => {
            dispatchPageState({type: "data_fetch_init"})
            axios.get("/dash/withdrawal/withdrawals/search_any", {
                params: payload
            }).then(response=>{
                dispatchPageState({
                    type: "data_fetch_success",
                    payload: response.data
                })          
            }).catch(error => {
                if ((error.response || {}).status === 403) dispatchPageState({type: "data_fetch_failure_403"})
                else if ((error.response || {}).status === 404) dispatchPageState({type: "data_fetch_failure_404"})  
                else dispatchPageState({type: "data_fetch_failure"})    
            })
        },[]
    )

    useEffect(() => {
        let payload = {
            "page_number": pageParams.page - 1,
            "page_rows": 25,
            search_term
        }
        doRequest(payload)	
    },[doRequest, pageParams, search_term])
    
    const handleChangePage = (event, page) => {
		if (pageState.page === page) {
			return
		}
		setPageParams({page: page})
	}

	const contentContainer = (dataList) => {

		let nr_of_transactions = dataList['data'].length
        let transactions = dataList['data']
        

		if (nr_of_transactions === 0) {
			return (
			<div className='emptyContainer'>
				<p className={["labelText", "normalSize"].join(" ")}>
					Nenhum saque relacionado à busca por {search_term}.
				</p>
                <p className={["labelText", "normalSmallSize"].join(" ")} style={{padding: "0 140px"}}>
                    Certifique-se que você realizou a busca considerando um dos seguintes critérios: nome, 
                    número do documento, número do participante (isbp), número da conta.
				</p>
			</div>
			)
		}

		else {
            return (
                <div className='cardContainer'>
                    <WithdrawalSearchTable
                            historic={transactions} 
                        />
                </div>
            )
		}
	}

	if (pageState.fetchedData) {

		return (
			<div style={{ width: "auto", paddingBottom: "30px" }}>
				<div>{contentContainer(pageState.fetchedData)}</div>
				<div style={{marginTop: "10px"}}>
                    <Pagination 
                        className="pagination" 
                        page={pageParams.page} 
                        count={parseInt(pageState.fetchedData["number_of_pages"])}
                        onChange={(e, page) => handleChangePage(e, page)}
                    />
				</div>
			</div>
		);
	}
    else if (pageState.isError){
		return (
			<div className='emptyContainer'>
                <p className={["blueText", "normalSmallSize"].join(" ")} style={{margin:"auto", textAlign:"center"}}>
                    {pageState.errorMessage}
                </p>
            </div>
		)
	}
	else if (pageState.isLoading) {
		return(
			<div style={{ width: "auto" }}>
				<div className='circularProgressCenter'>
					<CircularProgress />
				</div>
			</div>
		)
    }
    else return null
	
}

export default SearchWithdrawals