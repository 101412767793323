import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IconButton, Card, Collapse,Tooltip } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import DataField from "./DataField_notOK/DataField";
import { useTranslation } from "react-i18next";
import { titleCase, credit_translations, formatAsMoney, getProperMetadata } from "../../utils/utils"

function CreditAnalysisLegalPersonCard(props) {

    let { credit_analysis, user_data } = props 

    const { t } = useTranslation();

    const { analysis_status, not_listed } = credit_translations

    const [expanded, setExpanded] = useState(false);

    const handleExpand = (event) => {
        event.stopPropagation();
        setExpanded(!expanded);
    };

    let showLinkToAnalysis = !(credit_analysis.analysis_status === "pending" || credit_analysis.analysis_status === "waiting_for_data" || credit_analysis.analysis_status === "failed" ) 

    let decision_metadata = credit_analysis.credit_proposal_legal_person_analysis_events ? getProperMetadata(credit_analysis.credit_proposal_legal_person_analysis_events) : "" 
    let basic_data = decision_metadata ? (decision_metadata || {}).basic_data : ""

    return (
        <Card className={["listCard","collapse"].join(" ")}>
            <div style={{display:"flex", justifyContent: "space-between"}}>
                {user_data.business_group_key ? credit_analysis.company_name ?
                <div 
                    className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                    style={{flex:"1 1 120px", textAlign:"center"}}
                >
                    {credit_analysis.company_name ? credit_analysis.company_name : "-" }
                </div> : null:null}
                {showLinkToAnalysis
                    ? <Link 
                        className={["listCardItem", "link", "subtitleSize", "bold"].join(" ")}
                        style={{flex:"1 1 120px", textAlign:"center"}}
                        to={"/credit-analysis-legal-person/" + credit_analysis.credit_proposal_legal_person_key}
                    >
                       {credit_analysis.is_manual_analysis ? t("Requisição Manual") : `${t("Cliente")} #${credit_analysis.id}` }
                    </Link>
                    : <div 
                    className={["listCardItem", "subtitleSize", "bold"].join(" ")}
                    style={{flex:"1 1 120px", textAlign:"center"}}
                    >
                       {credit_analysis.is_manual_analysis ? t("Requisição Manual") : `${t("Cliente")} #${credit_analysis.id}`}
                    </div>}
                <div 
                    className={["listCardItem", "normalText", "subtitleSize"].join(" ")} 
                    style={{flex:"1 1 120px", textAlign:"center"}}
                >
                    {(!!credit_analysis.legal_name || !!(basic_data || {}).legal_name)
                        ? titleCase(credit_analysis.legal_name, "-")
                        :  titleCase((basic_data || {}).legal_name, "-")}
                </div>
                <div 
                    className={["listCardItem", "normalText", "subtitleSize", "noWrap"].join(" ")}
                    style={{flex:"1 1 145px", textAlign:"center"}}
                >
                    {credit_analysis.document_number}
                </div>
                <div 
                    className={["listCardItem", "normalText", "subtitleSize", "noWrap"].join(" ")}
                    style={{flex:"1 1 145px", textAlign:"center"}}
                >
                    {credit_analysis.financial 
                        ? formatAsMoney(credit_analysis.financial.currency, credit_analysis.financial.amount)
                        : "-"}
                </div>
                <div 
                    className={["listCardItem", "normalText", "normalMediumSize", "bold"].join(" ")} 
                    style={{flex:"1 1 45px", textAlign:"center", cursor: "default"}}  
                >
                    <Tooltip title={<p className="tooltipText">{analysis_status[credit_analysis.analysis_status] ? t(analysis_status[credit_analysis.analysis_status].description) : t(credit_analysis.analysis_status)}</p>}>
                        <div className={["circle", "small"].join(" ")} style={{backgroundColor: (analysis_status[credit_analysis.analysis_status] || not_listed).color }}>
                            {t((analysis_status[credit_analysis.analysis_status] || not_listed).siglum)}
                        </div>
                    </Tooltip>
                </div>
                <div 
                    className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                    style={{flex:"1 1 70px", textAlign:"center"}}
                >
                    {t("date_time_format", {date_time: credit_analysis.created_at})}
                </div>
                <div
                    className="listCardItem"
                    style={{flex:"0 1 70px", textAlign:"center"}}
                >
                    <IconButton onClick={handleExpand}>
                        {expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </IconButton>
                </div>
            </div>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div className={["internalCollapse", "twoColumn"].join(" ")}>
            {(credit_analysis.constitution_date  || (basic_data || {}).foundation_date) ? 
                <DataField
                name={t("Data de Constituição")}
                data={credit_analysis.constitution_date ?
                        t("date_format", {date_format: credit_analysis.constitution_date})
                        : t("date_format", {date_format: (basic_data || {}).foundation_date})}
                />
            : null}
            {!! credit_analysis.constitution_type && 
                <DataField
                name={t("Tipo")}
                data={credit_analysis.constitution_type}
                />
            }
        </div>
            <ObservationComponent analysis_status_events={credit_analysis.analysis_status_events} />
        </Collapse>
        </Card>
    );
}

export default CreditAnalysisLegalPersonCard;

function ObservationComponent(props) {
    const { t } = useTranslation();

  if (props.analysis_status_events === undefined){
    return null
  }
  else if (props.analysis_status_events[props.analysis_status_events.length - 1].observation === null){
    return null
  }
  else {
    return (
      <div style={{margin:"20px"}}>
        <DataField
          name={t("Observações")}
          data={props.analysis_status_events[props.analysis_status_events.length - 1].observation}
        />
      </div>
    )
  }
}