import React, { useState} from "react";
import { useContext} from 'react'
import AuthContext from "../../../../context/auth-context"
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import Collapse from "@material-ui/core/Collapse";
import { colorGreen } from "../../utils/utils"
import moment from 'moment'
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import UnlockDialog from "./UnlockDialog/UnlockDialog"
import lockLogo from '../../../../assets/cardEntryIcons/lockIcon.svg'
import unlockLogo from '../../../../assets/cardEntryIcons/unlockIcon.svg'
import {translateLockType, translateLockSource, translateLockStatus} from "../../utils/utilsLock"

function LockCard(props){
    let {lock} = props
    let {handleRefreshPage} =props

    let user_data = useContext(AuthContext).user_data

    const [openUnlockDialog, setOpenUnlockDialog] = useState(false);

    const [expanded, setExpanded] = useState(false);

    const handleExpand = (event) => {
        event.stopPropagation();
        setExpanded(!expanded);
    };

    let lockTypeTooltip = translateLockType(lock.lock_type)
    let lockTypeDocument = lock.lock_type === "customer" ? lock.customer_document_number : lock.seller_document_number


    return (
        <Card className={["listCard","collapse"].join(" ")}>
            <div style={{display:"flex", justifyContent: "space-between"}}>
                {user_data.business_group_key ?
                <div 
                    className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                    style={{flex:"2 1 90px", textAlign:"center"}}
                >
                    {lock.company_name || "-"}
                </div> : null}
                <div 
                    className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                    style={{flex:"1 1 55px", textAlign:"center"}}
                >
                    {lockTypeTooltip}
                </div>
                <div 
                    className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                    style={{flex:"1 1 55px", textAlign:"center"}}
                >
                    {lockTypeDocument}
                </div>
                {lock.lock_source === "manually_input" ? 
                <div 
                    className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                    style={{flex:"1 1 55px", textAlign:"center"}}
                >
                    <div style={{display:"flex", justifyContent:"center"}}>
                        <img src={lockLogo} alt="Card Logo" />
                        <div className="labelSpace">{translateLockSource(lock.lock_source)}</div>
                    </div>
                </div> :
                <Link 
                    className={["listCardItem", "blueText", "link", "subtitleSize"].join(" ")}
                    style={{flex:"1 1 55px", textAlign:"center"}}
                    to={"/card-order-alert/" + lock.alert_key}
                >
                    <div style={{display:"flex", justifyContent:"center"}}>
                        <img src={lockLogo} alt="Card Logo" />
                        <div className="labelSpace">
                            {translateLockSource(lock.lock_source)}
                        </div>
                    </div>
                </Link>}
                <div 
                    className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                    style={{flex:"1 1 55px", textAlign:"center"}}
                >
                    {moment(lock.lock_date).format("DD/MM/YYYY HH:mm:ss")}
                </div>
                {lock.lock_status === "blocked" ? 
                <div 
                    className={["listCardItem", "link", "subtitleSize", "bold"].join(" ")}
                    style={{flex:"1 1 55px", textAlign:"center"}}
                    onClick={() => setOpenUnlockDialog(true)}
                >
                    <div style={{display:"flex", justifyContent:"center"}}>
                        <img src={unlockLogo} alt="Unlock Logo" />
                        <div className="labelSpace">Desbloquear</div>
                    </div>
                </div> : 
                <div 
                    className={["listCardItem", "normalText", "subtitleSize", "bold"].join(" ")}
                    style={{flex:"1 1 55px", textAlign:"center", color: colorGreen}}
                >
                    {translateLockStatus(lock.lock_status)}
                </div>}
                <div 
                    className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                    style={{flex:"0 1 70px", textAlign:"center"}}
                >
                    <IconButton onClick={handleExpand}>
                        {expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </IconButton>
                </div>
            </div>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
            <div className={["internalCollapse", "oneColumn"].join(" ")}>
                {lock.lock_events ? lock.lock_events.map((event, index) => (
                    <div key={index} style={{display: "flex", flexDirection: "column", margin: "20px 0px"}}>
                        <div className={["blueText", "subtitleSize"].join(" ")}>
                            {event.new_status === "blocked" ? "Bloqueio": "Desbloqueio"}                            
                        </div>
                        {event.new_status === "blocked" && lock.lock_source === "automatically_alert" ?
                        <div style={{display: "flex", margin: "5px 0px"}}>
                            <div className={["normalText", "normalMediumSize"].join(" ")}>
                                Alerta gerado automaticamente por gatilho crítico.
                            </div>                  
                        </div> :
                        <React.Fragment>            
                        <div style={{display: "flex", margin: "5px 0px"}}>
                            <div className={["normalText", "normalMediumSize"].join(" ")}>
                                Responsável:
                            </div>
                            <div className={["labelText", "normalMediumSize", "labelSpace"].join(" ")}>
                            {event.analyst_name}
                            </div>                    
                        </div>
                        <div style={{display: "flex", margin: "5px 0px"}}>
                            <div className={["normalText", "normalMediumSize"].join(" ")}>
                                E-mail:
                            </div>
                            <div className={["labelText", "normalMediumSize", "labelSpace"].join(" ")}>
                                {event.analyst_email}
                            </div>                    
                        </div>
                        {event.observation ? 
                        <div style={{display: "flex", margin: "5px 0px"}}>
                            <div className={["normalText", "normalMediumSize"].join(" ")}>
                                Observação:
                            </div>
                            <div className={["labelText", "normalMediumSize", "labelSpace"].join(" ")}>
                                {event.observation}
                            </div>                    
                        </div>: null }
                        {event.new_status === "unblocked" ?
                        <div style={{display: "flex", margin: "5px 0px"}}>
                            <div className={["normalText", "normalMediumSize"].join(" ")}>
                                Data do Desbloqueio:
                            </div>
                            <div className={["labelText", "normalMediumSize", "labelSpace"].join(" ")}>
                                {moment(event.event_date).format("DD/MM/YYYY HH:mm:ss")}
                            </div>                    
                        </div>: null }
                        </React.Fragment>}
                    </div>                    
                )) : null}
            </div>
            </Collapse>
            {lock.lock_status === "blocked" ?
            <UnlockDialog
                open={openUnlockDialog}
                onClose={() => setOpenUnlockDialog(false)}
                lock={lock}
                handleRefreshPage={handleRefreshPage}        
            /> : null
            }

        </Card>
    )
}

export default LockCard