import React from "react";
import BoavistaSummaryTotal from "../BoavistaSummaryData/BoavistaSummaryTotal";
import { useTranslation } from "react-i18next";
import { formatAsDate } from "../../../../utils/utils"

function BoavistaInquiriesSummaryCard(props) {

    const { inquiries,
        boavistaInquiries2Img
    } = props

    const { t } = useTranslation()

    const dataObjHandler = (data,inquiries) => {
        for (let inquiry of inquiries.inquiries_by_periods) {
            if ((inquiry["number_of_events"]!==undefined)&&(inquiry["start_date"]!==undefined)){
                data.dataValues.push(inquiry["number_of_events"])
                data.dataLabels.push(formatAsDate(inquiry["start_date"].substring(0,7)))
            }
        }
        data.dataValues.reverse()
        data.dataLabels.reverse()
    }

    let data = {dataValues:[],dataLabels:[]}
    dataObjHandler(data,inquiries)

    return (
        <div style={{display: 'grid',gridTemplateColumns: '33.3% 66.6%',marginTop:'25px',marginBottom:'25px'}}>
            <BoavistaSummaryTotal
                description={"total_number_of_events_last_90_days"}
                total={inquiries.total_number_of_events_last_90_days}
            />
            <div style={{display:"flex", flexDirection:"column", alignItems:"center", width:"550px"}}>
                <div 
                    className={["blueText", "normalMediumSize", "bold", 'internalColapse'].join(" ")}
                    style={{margin: "auto"}}
                >
                    {t("events_last_90_days")}
                </div>
                <img style={{width:"100mm", margin: "auto"}} src={boavistaInquiries2Img} alt={t("inquiries_occurrences")} />
            </div>
        </div>
    );
}

export default BoavistaInquiriesSummaryCard
                            