import React, {useState} from 'react'
import { setAllowChanges, replaceAll } from "../../utils/utils"
import ConfirmationDialog from "./ConfirmationDialog/ConfirmationDialog";
import moment from "moment"
import ObservationTextField from '../../../../components/common/ObservationTextField';
   
function Observation(props) {

    const {credit_analysis, credit_proposal_legal_person_key, user_data} = props 

    const [openDialog, setOpenDialog] = useState(false)
    const [manualAnalysisStatus, setManualAnalysisStatus] = useState(null)

    const handleApprovedOpenClick = () => {
        setManualAnalysisStatus("manually_approved")
        setOpenDialog(true)
    };

    const handleReprovedOpenClick = () => {
        setManualAnalysisStatus("manually_reproved")
        setOpenDialog(true)
    };

    const handleDialogClose = () => {
        setOpenDialog(false)
        setTimeout(function (){setManualAnalysisStatus(null)}, 300)
    };

    let roles = user_data.roles
    let allowChanges = setAllowChanges(credit_analysis.analysis_status, roles, "np")

    let observation = null
    let analyst = null

    // MOCK 
    // credit_analysis.analysis_status_events = null // APAGAR

    if (!allowChanges){
        let last_event = credit_analysis.analysis_status_events == null || credit_analysis.analysis_status_events.length ===0 ? null :
            credit_analysis.analysis_status_events[credit_analysis.analysis_status_events.length-1]
        if (last_event){
            observation = last_event.observation
            let string = last_event.analyst_name == null ? null : "Análise feita por %name, %email no dia %day às %time.\n"
            let mapObj = {
                "%name": last_event.analyst_name,
                "%email": last_event.analyst_email,
                "%day": moment(last_event.event_date).format("DD/MM/YYYY"),
                "%time": moment(last_event.event_date).format("HH:mm"),
            }
            analyst = string == null ? null :replaceAll(string, mapObj)
        }
    }

    const [observationBox, setObservationBox] = useState(allowChanges ? "" : observation == null ? (analyst) : (analyst + observation))

    return (
        <div className="analysisCard">
            <span className={["blueText", "subtitleSize"].join(" ")}>Revisão</span>
            <div className="internalCardContainer">
                <ObservationTextField content={observationBox} setContent={setObservationBox} service="credit_analysis" disable={!allowChanges}/>
            </div>
            {allowChanges &&
                    <div style={{display: "flex", justifyContent: "flex-end", marginTop: "25px"}}>
                        <div 
                            className={["button", "approved", "normalText", "normalMediumSize"].join(" ")}
                            style={{marginLeft:"auto"}}
                            onClick={handleApprovedOpenClick}
                        >
                            APROVAR
                        </div>
                        <div 
                            className={["button", "reproved", "normalText", "normalMediumSize"].join(" ")} 
                            onClick={handleReprovedOpenClick}
                        >
                            REPROVAR
                        </div>
                    </div>}
            {allowChanges &&
                <ConfirmationDialog
                    open={openDialog}
                    manualAnalysisStatus={manualAnalysisStatus}
                    onClose={handleDialogClose}
                    observation_data={observationBox}
                    credit_proposal_legal_person_key={credit_proposal_legal_person_key}
                />}
        </div>
    )
}

export default Observation