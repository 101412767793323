import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
  },
  tableText: {
    fontFamily: "Open Sans",
    fontWeight: 'normal',
    fontSize: "12px",
    lineHeight: "16px",
    color: "#141414",
    textAlign: "center",
    margin: "10px",
    padding: "6px",
    minWidth: "120px"
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: '#6F6F6F',
    fontSize: "14px",
    lineHeight: "19px",
    padding: "6px",
  },
  even: {
    background: "#F3F3F3",
  },
  odd: {
    background: "#FFFFFF",
  },
}));

function MediaTable(props) {
  const classes = useStyles();
  useTheme();

  const getClass = (rowIndex) => {
    if (rowIndex % 2 === 0) {
      return classes.even;
    }

    return classes.odd;
  };

  return (
    <Paper className={classes.tableStyle}>
      <Table style={{ padding: "8px" }}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader}>Ano da ação</TableCell>
            <TableCell className={classes.tableHeader}>Estado</TableCell>
            <TableCell className={classes.tableHeader}>CNPJ</TableCell>
            <TableCell className={classes.tableHeader}>Nome</TableCell>
            <TableCell className={classes.tableHeader}>Endereço</TableCell>
            <TableCell className={classes.tableHeader}>Trabalhadores Envolvidos</TableCell>
            <TableCell className={classes.tableHeader}>Data Inclusão</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.slavery_data.map((process,index) => (
            <TableRow
              key={index}
              className={getClass(index)}
            >
              <TableCell className={classes.tableText}>
                {process.year}
              </TableCell>
              <TableCell style={{whiteSpace:"nowrap"}} className={classes.tableText}>
                {process.uf}
              </TableCell>
              <TableCell style={{whiteSpace: "nowrap"}} className={classes.tableText}>
                {process.document_number}
              </TableCell>
              <TableCell className={classes.tableText}>
                {process.name}
              </TableCell>
              <TableCell className={classes.tableText}>
                {process.address}
              </TableCell>
              <TableCell className={classes.tableText}>
                {process.envolved_workers}
              </TableCell>
              <TableCell className={classes.tableText}>
                {process.inclusion_date == null ? "-" :moment(process.inclusion_date).format('DD/MM/YYYY')}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default MediaTable;
