import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export const LabelInput = (props) => {
    
    const { id, labelValue, value, onChange, onBlur, error, errorMessage } = props

    const { t } = useTranslation();
    const [isFocused, setIsFocused] = useState(false);
    
    function handleChange (event) {
        const { value } = event.target;
        if (props.formatValue) {
            onChange(props.formatValue(value));
        } else {
            onChange(value);
        }
    }

    const handleBlur = (event) => {
        setIsFocused(false);
        onBlur(event.target.value);
    };
    
    const handleFocus = () => {
        setIsFocused(true);
    };
    
    return (
        <div className="labelInput">
            <label className="normalText normalSmallSize" htmlFor={id}>{t(labelValue)}:</label>
            <input
                className={!isFocused&&error===true?"errorInput":null}
                id={!isFocused&&error===true?"error":null}
                name={id}
                placeholder={props.placeholder?props.placeholder:null}
                value={value}
                onChange={handleChange}
                disabled={props.isDisabled?props.isDisabled:false}
                onBlur={handleBlur}
                onFocus={handleFocus}
            />
            <span className="normalSmallSize__error" style={{height:"13px"}}>
                {!isFocused&&error===true?t(errorMessage):null}
            </span>
        </div>
    );
}
