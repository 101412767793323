import React, {useState} from "react";
import PartnersTableDecision from "./PartnersTable/PartnersTable_Decision";
import PartnersTable from "./PartnersTable/PartnersTable";
import {getProperMetadata} from "../../utils/utils"
import PartnersDialog from "./PartnersDialog/PartnersDialog"
import PartnersDialogDecision from "./PartnersDialog/PartnersDialog_Decision"
import { useTranslation } from "react-i18next";

function History(props) {

    const { t } = useTranslation()

    let decision_metadata = getProperMetadata(props.legal_person.analysis_status_events)

    let maxRows = 6

    const [dialogOpen, setDialogOpen] = useState(false);

    let metadataPartners = (
        (((decision_metadata || {}).registration_data || {}).partners || []).length > 0 ?
        [...decision_metadata.registration_data.partners] : null)

    let partnersList = props.legal_person.partners

    if(metadataPartners){
        return (
            <div className="analysisCard" style={{height:"100%"}}>
                <div className={["blueText", "subtitleSize"].join(" ")}>Quadro Societário</div>
                <div style={{flexGrow:"2"}} className="internalCardContainer">
                    <PartnersTableDecision maxRows={maxRows} list_of_partners={metadataPartners}/>
                </div>
                <div className="internalCardContainer" style={{display:"flex"}}>
                    <div className={["normalText", "normalSmallSize"].join(" ")}>
                        Sócios: {metadataPartners.length}
                    </div>
                    {!(metadataPartners.length > maxRows) ? null :
                    <div 
                        className={["blueText", "normalMediumSize", "link"].join(" ")} 
                        style={{marginLeft:"auto"}}
                        onClick={() => setDialogOpen(true)}
                    >
                        Ver mais
                    </div>
                    }
                </div>
                {!(metadataPartners.length > maxRows) ? null :
                <PartnersDialogDecision
                    open={dialogOpen}
                    onClose={() => setDialogOpen(false)}
                    list_of_partners={metadataPartners}
                />}
            </div>
        );
    }
    else if(partnersList){
        return (
            <div className="analysisCard" style={{height:"100%"}}>
                <div className={["blueText", "subtitleSize"].join(" ")}>Quadro Societário</div>
                <div style={{flexGrow:"2"}} className="internalCardContainer">
                    <PartnersTable maxRows={maxRows} list_of_partners={partnersList}/>
                </div>
                <div className="internalCardContainer" style={{display:"flex"}}>
                    <div className={["normalText", "normalSmallSize"].join(" ")}>
                        Sócios: {partnersList.length}
                    </div>
                    {!(partnersList.length > maxRows) ? null :
                    <div 
                        className={["blueText", "normalMediumSize", "link"].join(" ")} 
                        style={{marginLeft:"auto"}}
                        onClick={() => setDialogOpen(true)}
                    >
                        Ver mais
                    </div>
                    }
                </div>
                {!(partnersList.length > maxRows) ? null :
                <PartnersDialog
                    open={dialogOpen}
                    onClose={() => setDialogOpen(false)}
                    list_of_partners={partnersList}
                />}

            </div>
        );
    }
    else {
        return (
            <div className="analysisCard" style={{height:"100%"}}>
                <div className={["blueText", "subtitleSize"].join(" ")}>Quadro Societário</div>
                <div style={{margin:"auto"}} className={["labelText", "normalMediumSize"].join(" ")}>
                    {t("information_unavailable")}
                </div>
            </div>
            );  
    }  
}

export default History;