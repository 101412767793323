import React, { useEffect, useContext, useReducer, useState } from "react";
import { dataFetchReducer } from "../../Utils/requestUtils";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment"
import ErrorBoundary from "../../Utils/ErrorBoundary";
import AuthContext from "../../../context/auth-context"
import {useHistory} from "react-router-dom"
import TimerBox from "./TimerBox/TimerBox"
import BillPaymentCard from "./BillPaymentCard/BillPaymentCard"
import DecisionBox from "./DecisionBox/DecisionBox"
import AlertCard from "./AlertCard/AlertCard"
import ObservationCard from "./ObservationCard/ObservationCard"
import ClientCard from "./ClientCard/ClientCard"
import EnvolvedParties from "./EnvolvedParties/EnvolvedParties"
import HistoricCard from "./HistoricCard/HistoricCard"
import FraudCard from "./Fraud/FraudCard/FraudCard"
import FraudHistory from "./Fraud/FraudHistory/FraudHistory"
import FraudDialog from "./Fraud/FraudDialog/FraudDialog";
import NextTransaction from "../../Utils/NextTransaction/NextTransaction";

function BillPayment(props){
    let history = useHistory()
    let is_fraud = false 

    let roles = useContext(AuthContext).user_data.roles
    if (!roles.includes("read_bill_payments")){
        history.push("")
    }
    let can_write = roles.includes("update_bill_payments")

    let { bill_payment_key } = useParams();

    const [openDialog, setOpenDialog] = useState(false)

    const handleFraudStatus = () => {
        setOpenDialog(true)
    };

    const handleDialogClose = () => {
        setOpenDialog(false)
    };

    const [bill_payment, dispatchBillPayment] = useReducer(
        dataFetchReducer,
        {fetchedData: null, isLoading: true, isError: false} 
    )

    useEffect(() => {
		dispatchBillPayment({type: "data_fetch_init"})
		const timer = setTimeout(() => {
			axios.get('/dash/bill_payment/bill_payment/' + bill_payment_key).then( response => {
                dispatchBillPayment({
                    type: "data_fetch_success",
                    payload: response.data
                })   
			}).catch(error => {
                if ((error.response || {}).status === 403) dispatchBillPayment({type: "data_fetch_failure_403"})
                else if ((error.response || {}).status === 404) dispatchBillPayment({type: "data_fetch_failure_404"})  
                else dispatchBillPayment({type: "data_fetch_failure"})    
			})	
		}, 500);
		return () => {
			clearTimeout(timer)
        }
    }, [bill_payment_key])

    if (bill_payment.fetchedData){

        is_fraud = bill_payment.fetchedData.fraud_status === "fraud"

        return (
            <div style={{minWidth:"900px", paddingBottom: "30px"}}>
                <div className="analysisCardContainer">
                    <ErrorBoundary>
                        <div style={{display:"flex", flexDirection:"column", width: "100%"}}>
                            <div style={{display:"flex", justifyContent: "space-between"}}>
                                <div className={["blueText", "titleSize"].join(" ")} style={{margin:"5px 0px"}}>
                                    Pagamento de Conta {bill_payment.fetchedData.id}
                                </div>
                                {(!is_fraud && can_write) &&
                                    <div
                                        className={["button", "reproved", "normalText", "normalMediumSize"].join(" ")                            }
                                        style={{display: "flex", alignItems: "center", width: "150px", fontWeight:"600"}}
                                        onClick={handleFraudStatus}
                                    >
                                        Reportar como FRAUDE
                                    </div>}
                                </div>
                                <div className={["labelText", "subtitleSize"].join(" ")} style={{margin:"5px 0px"}}>
                                    {moment(bill_payment.fetchedData.bill_payment_date).format("DD/MM/YYYY - HH:mm:ss")}
                                </div>
                            </div>
                    </ErrorBoundary>
                </div>
                <NextTransaction history={history} transaction_key={bill_payment_key} path={"/bill-payment"} suffix={"bill_payment"} page_name={"Pagamento de Contas"}/> 
                <div style={{display: "flex", margin: "10px 0px"}}>
                    <div style={{width: "33%"}}>
                        <div className="analysisCardContainer">
                            <ErrorBoundary>
                                <BillPaymentCard billPayment={bill_payment.fetchedData}/>
                            </ErrorBoundary>
                        </div>
                        <div className="analysisCardContainer">
                            <ErrorBoundary>
                                <ClientCard billPayment={bill_payment.fetchedData}/>
                            </ErrorBoundary> 
                        </div>
                    </div>
                    <div style={{width: "66%"}}>
                        <div style={{display:"flex"}}>
                            <div style={{width: "50%"}}>
                                <div className="analysisCardContainer">
                                    <ErrorBoundary>
                                        <TimerBox billPayment={bill_payment.fetchedData}/>
                                    </ErrorBoundary>
                                </div>
                                <div className="analysisCardContainer">
                                    <ErrorBoundary>
                                        <DecisionBox 
                                            analysis_events={bill_payment.fetchedData.analysis_events}
                                            analysis_status={bill_payment.fetchedData.analysis_status}
                                        />
                                    </ErrorBoundary>
                                </div>
                            </div>
                            <div style={{width: "50%"}}>
                            {is_fraud && 
                                <div className="analysisCardContainer">
                                    <ErrorBoundary>
                                        <FraudCard />                                    
                                    </ErrorBoundary>   
                                </div>}
                                <div className="analysisCardContainer" style={{minHeight:"150px"}}>
                                    <ErrorBoundary>
                                        <AlertCard analysis_events={bill_payment.fetchedData.analysis_events}/>
                                    </ErrorBoundary>   
                                </div>
                            </div>
                        </div>
                        <div className="analysisCardContainer" style={{ minHeight: "250px"}}>
                            <ErrorBoundary>
                                <EnvolvedParties billPayment={bill_payment.fetchedData} />
                            </ErrorBoundary>
                        </div>
                    </div>
                </div>
                <div className="analysisCardContainer">
                    <ErrorBoundary>
                        <HistoricCard billPayment={bill_payment.fetchedData}/>
                    </ErrorBoundary>			
                </div>
                {is_fraud &&
                    <div className="analysisCardContainer" >
                        <ErrorBoundary>
                            <FraudHistory bill_payment={bill_payment.fetchedData} can_write={can_write} dashAPIEndpointUrl={"dash/bill_payment/bill_payment/"}/>
                        </ErrorBoundary>   
                    </div>}
                <div className="analysisCardContainer">
                    <ErrorBoundary>
                        <ObservationCard billPayment={bill_payment.fetchedData}/>
                    </ErrorBoundary>			
                </div>
                <FraudDialog
                    open={openDialog}
                    onClose={handleDialogClose}
                    bill_payment={bill_payment.fetchedData}
                />
            </div>
        )
    }
	else if(bill_payment.isError){
		return (
            <div style={{height: "80vh", display:"flex"}}>
                <p className={["blueText", "titleSize"].join(" ")} style={{margin:"auto", textAlign:"center"}}>
                    {bill_payment.errorMessage}
                </p>
            </div>
		)
	}
  
  return null;
}

export default BillPayment;