import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from 'moment'
import {getTableRowClass} from "../../../../../utils/utils"
import {formatIrregularity,getSeverityStatus, getAlertStatusColor,getAlertStatusText} from "../../../../../utils/utilsAlert"
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
  },
  blueText: {
    fontFamily: "Open Sans",
    fontWeight: "600",
    color: "#0b1f82"
},
  tableText: {
    fontFamily: "Open Sans",
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "center",
    margin: "10px",
    padding: "6px",
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: '#6F6F6F',
    fontSize: "14px",
    lineHeight: "19px",
    padding: "6px",
  }
}));
function AlertsTable(props) {
  const classes = useStyles();
  useTheme();

  return (
    <Paper className={classes.tableStyle}>
      <Table style={{ padding: "8px" }}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader}>ID</TableCell>
            <TableCell className={classes.tableHeader}>Tipo</TableCell>
            <TableCell className={classes.tableHeader}>Severidade</TableCell>
            <TableCell className={classes.tableHeader}>Nº de Gatilhos</TableCell>
            <TableCell className={classes.tableHeader}>Data de disparo</TableCell>
            <TableCell className={classes.tableHeader}>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.historic.map((alert,index) => (
            <TableRow
              key={index}
              className={getTableRowClass(index)}
            > 
              <TableCell
                className={[classes.tableText, classes.blueText, "breakWord", "link"].join(" ")}
              >
                <Link
                    className="link"
                    to={"/alert/" + alert.alert_key}
                >
                    {alert.alert_key}
                </Link>
              </TableCell>
              <TableCell className={classes.tableText}>
                {formatIrregularity(alert.irregularity_type,true)}
              </TableCell>
              <TableCell className={[classes.tableText, "noWrap"].join(" ")}>
                {getSeverityStatus(alert.risk_level)}
              </TableCell>
              <TableCell className={classes.tableText}>
                {alert.triggers == null || alert.triggers.length === 0 ? "-" : alert.triggers.length}
              </TableCell>
              <TableCell className={[classes.tableText, "breakWord"].join(" ")}>
                {moment(alert.occurrence_date).format("DD/MM/YYYY HH:mm:ss")}
              </TableCell>
              <TableCell className={classes.tableText}>
                <div className="statusTag" style={{backgroundColor:getAlertStatusColor(alert.alert_status)}}>
                    {getAlertStatusText(alert.alert_status)}
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default AlertsTable;
