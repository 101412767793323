import React from 'react'

function BankslipLockTypes (props){

    let { setLockType } = props

    return (
        <div style={{ flexWrap:"wrap", display:"flex", justifyContent: "space-evenly", margin:"auto 0px"}}>
            <div
                className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")} 
                onClick={() => setLockType("client_document_number")} 
                style={{width:"40%", margin:"10px 0"}}
            >
                Documento do Cliente
            </div>
            <div
                className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")} 
                onClick={() => setLockType("payer_document_number")} 
                style={{width:"40%", margin:"10px 0"}}
            >
                Documento do Pagador
            </div>
            <div
                className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")} 
                onClick={() => setLockType("recipient_document_number")} 
                style={{width:"45%", margin:"10px 0"}}
            >
                Documento do Recebedor
            </div>
        </div>
    )
}

export default BankslipLockTypes