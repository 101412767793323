//Importing modules
import React, {useState, useEffect} from 'react'
import TextField from '@material-ui/core/TextField';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { getConfirmationIconField, checkNested } from "../../utils/utils"
import pagarme from 'pagarme'
import InputMask from "react-input-mask";

//Defining text box theme
const theme = createMuiTheme({
    palette:{
        primary: {
            main:"#0b1f82"
        },
        secondary: {
            main: "#FF0000"
        },
    }
  });

//Defining input content component
function InputContent (props){
    //Getting atributes from props
    const { onClose } = props
    const { onSuccess } = props

    //Defining inputValues state handler
    const [inputValues, setInputValues] = useState( {
        name:{
            value:"",
            error:true,
            label:"Nome do Portador",
            validation_size_min:1
        },
        cardNumber:{
            value:"",
            error:true,
            label:"Número do Cartão",
            validation_size_max:16,
            validation_size_min:14,
            mask_type:"9999999999999999"
        },
        expirationMonth:{
            value:"",
            error:true,
            label:"Mês de Vencimento",
            validation_size:2,
            mask_type:"99"
        },
        expirationYear:{
            value:"",
            error:true,
            label:"Ano de Vencimento",
            validation_size:2,
            mask_type:"99"
        },
        cardCVV:{
            value:"",
            error:true,
            label:"CVV",
            validation_size_max:4,
            validation_size_min:3,
            mask_type:"9999"
        }
    })


    //Defining cardValidations state handler
    const [cardValidations,setCardValidations] = useState( {
        card : null,
        validations : null
    })

    //Getting error message for text field validation
    const getValidationMessage = (validationItem) =>{
        switch (validationItem){
            case "card_holder_name":
                return "O nome do portador do cartão fornecido é inválido"
            case "card_number":
                return "O número do cartão fornecido é invalido"
            case "card_expiration_date":
                return "A data de vencimento fornecida é inválida"
            case "card_cvv":
                return "O CVV fornecido e inválido"
            default:
                throw new Error()
        }
    }

    //Defining handler for inputValues state change
    const handleChangeInputValues = (event,key) => {
        let newInputValues = {...inputValues}

        newInputValues[key].value = event.target.value

        if (newInputValues[key].validation_size_max && newInputValues[key].validation_size_min) {
            newInputValues[key].error = event.target.value.length > newInputValues[key].validation_size_max || 
                                        event.target.value.length < newInputValues[key].validation_size_min ?
                                        true : false
        }
        else if (newInputValues[key].validation_size) {
            newInputValues[key].error = event.target.value.length !== newInputValues[key].validation_size ? 
                                        true : false
        }
        else if (newInputValues[key].validation_size_min) {
            newInputValues[key].error =  event.target.value.length < newInputValues[key].validation_size_min ?
                                        true : false      
        }
        else if (newInputValues[key].validation_size_max) {
            newInputValues[key].error = event.target.value.length > newInputValues[key].validation_size_max ? 
                                        true : false
        }

        setInputValues(newInputValues)
    }

    //Defining handler for submit button press
    const onSubmit = () => {
        if (inputValues.cardNumber.error ||
            inputValues.expirationMonth.error ||
            inputValues.cardCVV.error ||
            inputValues.expirationYear.error ||
            inputValues.name.error) return
        let card = {
            "card_holder_name": inputValues.name.value,
            "card_number": inputValues.cardNumber.value,
            "card_expiration_date": [inputValues.expirationMonth.value, inputValues.expirationYear.value,].join("/"),
            "card_cvv": inputValues.cardCVV.value 
        }
        let newCardValidationsState = {...cardValidations}
        newCardValidationsState.card = card
        newCardValidationsState.validations = pagarme.validate({card:card})
        setCardValidations(newCardValidationsState)
    }    


    //Defining effect of cardValidations state variable change
    useEffect (() => {
        if (cardValidations.card != null) {
            if (cardValidations.validations.card.card_cvv &&
                cardValidations.validations.card.card_expiration_date &&
                cardValidations.validations.card.card_number &&
                cardValidations.validations.card.card_holder_name){
                onSuccess(cardValidations.card)
                onClose()
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[cardValidations])

    return (
        <div style={{ display:"flex", flexDirection:"column", flexGrow:"1"}}>
            {cardValidations.validations === null ? null :
            <div style={{marginTop:"0px"}}>
                {Object.keys(cardValidations.validations.card).map((item, index) => (
                cardValidations.validations.card[item] === false ?
                <div key={index} className={["redText", "normalSize"].join(" ")}>
                    {getValidationMessage(item)}
                </div>:null))}
            </div>}
            <div style={{margin:"10px", flexDirection:"column", display:"flex", flexGrow:"1"}}>
                <ThemeProvider theme={theme}>
                    <div style={{display:"flex", flexDirection:"row", margin:"10px 70px 10px 70px"}}>
                        <div style={{flexGrow:"1"}}>
                            <TextField 
                                autoFocus
                                color={inputValues.name.error ? "secondary": "primary"} 
                                label={inputValues.name.label}
                                value={inputValues.name.value}
                                onChange={(e) => handleChangeInputValues(e, "name")}
                                fullWidth={true}
                            />
                        </div>
                        {checkNested(cardValidations, "validations", "card", "card_holder_name") ? 
                            getConfirmationIconField(cardValidations.validations.card.card_holder_name) : null}
                    </div>
                    <div style={{display:"flex", flexDirection:"row", margin:"10px 70px 10px 70px"}}>
                        <div style={{flexGrow:"1"}}>
                            <InputMask 
                                mask={inputValues.cardNumber.mask_type}
                                maskPlaceholder=""
                                value={inputValues.cardNumber.value}
                                onChange={(e) => handleChangeInputValues(e, "cardNumber")}
                            >
                                <TextField 
                                    color={inputValues.cardNumber.error? "secondary": "primary"} 
                                    label={inputValues.cardNumber.label}
                                    fullWidth={true}
                                />
                            </InputMask>
                        </div>
                        {checkNested(cardValidations, "validations", "card", "card_number") ? 
                            getConfirmationIconField(cardValidations.validations.card.card_number) : null}
                    </div>
                    <div style={{display:"flex", flexDirection:"row", margin:"10px 70px 10px 70px"}}>
                        <div style={{display:"flex", flexGrow:"1", flexDirection:"row"}}>
                            <div style={{marginRight:"10px"}}>
                                <InputMask 
                                    mask={inputValues.expirationMonth.mask_type}
                                    maskPlaceholder=""
                                    value={inputValues.expirationMonth.value}                         
                                    onChange={(e) => handleChangeInputValues(e,"expirationMonth")}
                                >
                                    <TextField 
                                        color={inputValues.expirationMonth.error? "secondary": "primary"} 
                                        label={inputValues.expirationMonth.label}
                                        fullWidth={true}
                                    />
                                </InputMask>
                            </div>
                            <div style={{marginLeft:"10px"}}>
                                <InputMask 
                                    mask={inputValues.expirationYear.mask_type}
                                    maskPlaceholder=""
                                    value={inputValues.expirationYear.value}
                                    onChange={(e) => handleChangeInputValues(e,"expirationYear")}
                                >
                                    <TextField 
                                        color={inputValues.expirationYear.error? "secondary": "primary"} 
                                        label={inputValues.expirationYear.label}
                                        fullWidth={true}
                                    />
                                </InputMask>
                            </div>
                        </div>
                        {checkNested(cardValidations, "validations", "card", "card_expiration_date") ? 
                            getConfirmationIconField(cardValidations.validations.card.card_expiration_date) : null}
                    </div>
                    <div style={{display:"flex", flexDirection:"row", margin:"10px 70px 10px 70px"}}>
                        <div style={{flexGrow:"1"}}>
                            <InputMask 
                                mask={inputValues.cardCVV.mask_type}
                                maskPlaceholder=""
                                value={inputValues.cardCVV.value}
                                onChange={(e) => handleChangeInputValues(e,"cardCVV")}                         
                            >
                                <TextField 
                                    color={inputValues.cardCVV.error? "secondary": "primary"} 
                                    label={inputValues.cardCVV.label}
                                    fullWidth={true}
                                />
                            </InputMask>
                        </div>
                        {checkNested(cardValidations, "validations", "card", "card_cvv") ? 
                            getConfirmationIconField(cardValidations.validations.card.card_cvv) : null}
                    </div>
                </ThemeProvider>
            </div>
            <div style={{display:"flex", flexDirection:"colunm", justifyContent: "center", margin:"auto 0px 20px 0px"}}>
                <div
                    className={inputValues.cardNumber.error ||
                                inputValues.expirationMonth.error ||
                                inputValues.cardCVV.error ||
                                inputValues.expirationYear.error ||
                                inputValues.name.error ? 
                                ["button","standard", "disabled", "normalText", "normalMediumSize"].join(" ") : ["button","standard", "normalText", "normalMediumSize"].join(" ")} 
                    onClick={onSubmit} 
                    style={{width:"40%"}}
                >
                    Finalizar
                </div>
            </div>
        </div>
    )       
}



//Exporting component
export default InputContent