import React from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Button from "../../../@GeneralComponents/Button";

function ConfirmChangeGroup (props) {

  const { t } = useTranslation();

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      keepMounted={false}
      fullWidth={true}
      maxWidth={"sm"}
    >
        <DialogTitle
          disableTypography
          className={['blueText', "subtitleSize", "dialogTitleWithIcon"].join(" ")}
          style={{padding:"20px 24px 0px"}}
        >
          {t("confirm_change_group")}
          <IconButton onClick={props.onClose}>
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent >
            <div style={{display:"flex", flexDirection:"column"}}>
              <ul className="normalText normalMediumSize" style={{margin:"10px", textAlign:"justify", padding:"0px 20px"}}>
                <li>{t("confirm_change_group_message1")}</li>
                <li>{t("confirm_change_group_message2")}</li>
                <li>{t("confirm_change_group_message3")}</li>
                <li>{t("confirm_change_group_message4")}</li>
              </ul>
              <Button
                button_option={"standard"}
                onClick={props.onSave}
                buttonLabel={t("confirm")}
                buttonStyle={{minWidth:"252px", margin: "20px auto"}}
                isLoading={props.saveSelected.isLoading}
              />
            </div>
        </DialogContent>
    </Dialog>)
}

export default ConfirmChangeGroup;