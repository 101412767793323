import React, { useState } from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import SearchIcon from "@material-ui/icons/Search";
import { useTranslation } from "react-i18next";

function MultiSelectDropdown (props) {

  const { t } = useTranslation();

  const { permission_name, options, selected, setSelected, toggleOption } = props

  const optionsFilteredByPermissionHandler = () => {
    if (['read_list','read_item'].includes(permission_name)){
      return options
      .filter((option)=>(
        ((option.is_owner===true)||((option.is_owner===false)&&(option.can_read===true)))
      ))
    }
    else if (['create_item','delete_item','update_item'].includes(permission_name)){
      return options
      .filter((option)=>(
        ((option.is_owner===true)||((option.is_owner===false)&&(option.can_write===true)))
      ))
    }
  }

  const optionsFiltered = optionsFilteredByPermissionHandler()

  let selectedAllOptions = []
  for (let option of optionsFiltered) {
    selectedAllOptions.push(option.list_key)
  }

  const selectedAllHandler = (event) => {
    event.stopPropagation()
    let status = event.target.checked
    if (!status) {
      setSelected([])
    }
    else {
      setSelected(selectedAllOptions)
    }
  }

  const [expanded, setExpanded] = useState(false)

  const [searchValue, setSearchValue] = useState("")
  const searchValueHandler = (event) => {
    setSearchValue(event.target.value)
  }

  return (
    <div style={{display:"flex", flexDirection: "column", width: "100%"}}>
      <div 
        className="multiSelectDropdownSelected"
        onClick={()=>(setExpanded(!expanded))}
      >
          <div>{selected.length} selected</div>
          {expanded?
          <ArrowDropUpIcon style={{color:"#878787", width:"18px",  height:"18px"}}/>:
          <ArrowDropDownIcon style={{color:"#878787", width:"18px",  height:"18px"}}/>}
      </div>
      {expanded&&
      <div>
        <div style={{display:"flex", flexDirection:"row"}} className="multiSelectDropdownSearch field">
          <SearchIcon style={{color:"#878787", width:"18px",  height:"18px", margin: "10px 0px 10px 12px"}}/>
          <input
            className="multiSelectDropdownSearch input normalSmallSize normalText"
            type="text"
            onChange={searchValueHandler}
            value={searchValue}
          />
        </div>
        <ul className="multiSelectDropdownOptions">
            <li className="multiSelectDropdownOption normalSmallSize labelText">
              <input
                type="checkbox"
                checked={optionsFiltered.length===selected.length}
                className="multiSelectDropdownCheckbox"
                onChange={(event) => selectedAllHandler(event)}
              >
              </input>
              <span>{t("select_all_lists")}</span>
            </li>
            {optionsFiltered
            .filter((option)=>(
              ((option.company_name).toLowerCase().includes(searchValue.toLowerCase())||
              (option.name).toLowerCase().includes(searchValue.toLowerCase()))
            ))
            .map(option => {
              const isSelected = selected.includes(option.list_key)
              return(
                <li key={option.list_key} className="multiSelectDropdownOption">
                    <input
                      type="checkbox"
                      checked={isSelected}
                      className="multiSelectDropdownCheckbox"
                      onChange={() => toggleOption({permission_name, list_key: option.list_key })}
                    ></input>
                    <span>{`${option.company_name} - ${option.name}`}</span>
                </li>
              )
            })}
        </ul>
      </div>}
    </div>
  )
}

export default MultiSelectDropdown;