import React from "react";
import DataField from "../../utils/DataField"
import {titleCase, formatAsMoney, getProperMetadata} from "../../utils/utils"
import { useTranslation } from "react-i18next";

function CreditAnalysisClientProfile(props) {
    const { t } = useTranslation();
    let {credit_analysis} = props

    let decision_metadata = credit_analysis.credit_proposal_legal_person_analysis_events ? getProperMetadata(credit_analysis.credit_proposal_legal_person_analysis_events) : "" 
    let basic_data = decision_metadata ? (decision_metadata || {}).basic_data : ""

    return (
        <div className="analysisCard">
            <div className={["blueText", "subtitleSize"].join(" ")} style={{marginBottom:"10px"}}>{t("Dados Básicos")}</div> 
                <div style={{display: "flex", flexDirection:"column"}}>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        {credit_analysis.legal_name ||  (basic_data || {}).legal_name ? 
                        <DataField
                            title={"Razão Social"}
                            label={credit_analysis.legal_name 
                                ? titleCase(credit_analysis.legal_name, t("Não disponível"))
                                : titleCase(basic_data.legal_name, t("Não disponível"))}
                            size={"normalMediumSize"}
                            titleNoWrap={false}
                        />: null}
                        {credit_analysis.email ? 
                            <DataField
                            title={"Email"}
                            label={credit_analysis.email ? credit_analysis.email : t("Não disponível")}
                            size={"normalMediumSize"}
                            titleNoWrap={false}
                        >
                        </DataField>: null}
                        <DataField
                            title={"CNPJ"}
                            label={credit_analysis.document_number || t("Não disponível")}
                            size={"normalMediumSize"}
                            titleNoWrap={false}
                        />
                        {credit_analysis.monthly_revenue ?
                            <DataField
                            title={"Faturamento mensal"}
                            label={formatAsMoney("BRL",credit_analysis.monthly_revenue) || t("Não disponível")}
                            size={"normalMediumSize"}
                            titleNoWrap={false}
                        />:null}
                    </div>
                </div>
        </div>
    );
}

export default CreditAnalysisClientProfile;
