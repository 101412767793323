import React from "react";
import ValidatedImage from "../../utils/ValidatedImage";
import * as S from "./styles";

export default function DriverLicenseCard(props) {
    const { image_key } = props;
    
    return (
        <S.Container>
            <S.Title>Carteira Nacional de Habilitação</S.Title>
            <S.ImageWrapper>
                <ValidatedImage
                    image_key={image_key}
                    dash_api_endpoint_url={'/dash/car_rental/image/driver_license_picture'}
                    imageStyle = {{margin:"auto", width:"75%", maxWidth: "280px", maxHeight:"auto", borderRadius: "5px"}}
                    imageAlt = {"CNH"}
                />
            </S.ImageWrapper>
        </S.Container>
    );
}