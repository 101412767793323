import React, {useReducer, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import { CircularProgress } from '@material-ui/core';
import { withStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import {decisionReducer} from "../../../Utils/requestUtils"
import axios from 'axios'
import ConfirmationContent from "./ConfirmationContent"

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

  const theme = createMuiTheme({
    overrides: { MuiInputBase: { root:{
        fontFamily: "Open Sans",
        fontSize: "14px",
        lineHeight: "19px",
        background: "#F7F7F7"

    }}},
    palette:{
        primary: {
            main:"#0b1f82"
        },
        secondary:{
            main:"#D91A1A"
        }
    }
  });

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  function ReportFraudDialog(props) {
    const {order_key} = props
    let title = "Reportar Fraude"

    const [dialogState, dispatchDialogState] = useReducer(
        decisionReducer,
        {isLoading: false, isError: false, finished:false}
    )
    
    const [observation, setObservation] = useState("")

    const handleObservationBoxChange = (event) => {
        setObservation(event.target.value)
    };

    const handleDialogClose = () => {
        props.onClose()
        setTimeout(()=>{
            dispatchDialogState({type:"default_state"})
            setObservation("")
        },300)
    }

    const handleFinish = () =>{
        dispatchDialogState({type:"send_request_init"})
        let payload={
            card_order_status: "fraud"
        }
        if (observation && observation !== ""){
            payload = {...payload, observation: observation}
        }
        setTimeout(function () {
            axios.put('/dash/card_order/order/'+ order_key, payload).then(response => {
                dispatchDialogState({type:"send_request_success"})
              }).catch(error => { 
                if ((error.response || {}).status === 403) dispatchDialogState({type: "send_request_failure_403"})
                else if ((error.response || {}).status === 409) dispatchDialogState({type: "send_request_failure_409"})  
                else dispatchDialogState({type: "send_request_failure"})    
              });
        }, 1000);

    }

    if (dialogState.finished && dialogState.isLoading){
        return (
            <Dialog 
                open={props.open} 
                onClose={handleDialogClose} 
                keepMounted={false}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle style={{paddingBottom: "0px"}} className={["blueText", "normalMediumSize"].join(" ")}>{title}</DialogTitle>
                <DialogContent>
                    <div style={{display:"flex", width: "310px", height:"217px"}}>
                        <CircularProgress style={{margin:"auto"}} />
                    </div>
                </DialogContent>
            </Dialog>

        )
    }
    else if (dialogState.finished){
        let confirmationData = {    
            message: dialogState.message,
            success: !dialogState.isError
        }
        return (
            <Dialog 
                open={props.open} 
                onClose={handleDialogClose} 
                keepMounted={false}
                disableBackdropClick
            >
                <DialogTitle style={{paddingBottom: "0px"}} className={["blueText", "normalMediumSize"].join(" ")}>Confirmação de Revisão</DialogTitle>
                <DialogContent>
                    <ConfirmationContent onClose={handleDialogClose} data={confirmationData} />
                </DialogContent>
            </Dialog>
        )
    }
    return (
        <Dialog 
            open={props.open} 
            onClose={handleDialogClose} 
            keepMounted={false}
        >
            <DialogTitle style={{paddingBottom: "0px"}} className={["blueText", "normalMediumSize"].join(" ")}>{title}</DialogTitle>
            <DialogContent>
                <div style={{ width: "310px", height:"217px"}}>
                    <div>
                        <ThemeProvider theme={theme}>
                        <TextField
                            fullWidth
                            multiline
                            variant="outlined"
                            rows="5"
                            color="primary"
                            defaultValue={""}
                            disabled={false}
                            onChange={handleObservationBoxChange}
                        />
                        </ThemeProvider>
                    </div>
                    <div className="internalCardContainer" style={{display:"flex", justifyContent:"center"}}>
                        <div 
                            className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                            onClick={handleFinish}
                        >
                            Confirmar
                        </div>
                        <div 
                            className={["button", "cancel", "normalText", "normalMediumSize"].join(" ")}
                            onClick={handleDialogClose}
                        >
                            Cancelar 
                        </div>
                  </div>
              </div>
          </DialogContent>
        </Dialog>
    )
}

export default ReportFraudDialog