import React from 'react'
import { useTranslation } from "react-i18next"
import { GetAlertsComponent, translateType } from "./utils"
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Tooltip from "@material-ui/core/Tooltip"
import HelpIcon from "@material-ui/icons/Help"
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export function ActionCreditLimitAmountBox(props) {
    const { t } = useTranslation()
    let { node, read_only, handleConfigurationOpen, handleNodeRemove } = props

    return (
        <div>
            <div style={{display:"flex", alignItems: "center"}}>
                <div
                    style={{ display: "flex", width: "100%", cursor: "pointer", margin: "0px" }}
                    onClick={() => handleConfigurationOpen(node)}
                >
                    <div style={{ display: "flex", flexDirection: "column", margin: "auto 0px" }}>
                        <div className={["normalText", "normalMediumSize"].join(" ")}>
                            {t("box_description_credit_limit_amount")}
                        </div>
                    </div>
                    {!read_only && (node.node.alerts || []).length > 0 ?
                        <GetAlertsComponent alerts={node.node.alerts} /> : null}
                </div>
                {!read_only && <><IconButton style={{width: 32, height: 32}} aria-label="duplicate" onClick={() => props.handleNodeDuplication(node)}>
                        <ContentCopyIcon style={{width: 20, height: 20}} />
                    </IconButton>
                    <IconButton style={{width: 32, height: 32}} aria-label="close" onClick={() => handleNodeRemove(node)}>
                        <CloseIcon style={{width: 20, height: 20}} />
                    </IconButton></>}
            </div>
        </div>
    )
}

export function ActionCreditLimitAmountConfiguration(props) {
    const { t } = useTranslation()
    let { node, read_only, handleConfigurationClose } = props

    return (
        <div style={{ flexGrow: "1", padding: "20px", display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex" }}>
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>{t(node.node.description)}</span>
                <Tooltip title={<div className="tooltipText"><ul>{node.node.helperText.map((help, index) => (<li key={index}>{t(help)}</li>))}</ul></div>}>
                    <HelpIcon style={{ fontSize: "14px", display: "flex", margin: "auto 5px" }} />
                </Tooltip>
            </div>
            <div className={["internalCardContainer", "normalText", "normalMediumSize"].join("  ")} style={{display:"flex", flexDirection: "column"}}>
                {t("box_settings_not_available")}
            </div>
            <div style={{ display: "flex", margin: "auto auto 5px auto" }}>
                <div
                    className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")}
                    style={{ width: "120px" }}
                    onClick={handleConfigurationClose}
                >
                    {read_only ? "X" : t("discard")}
                </div>
            </div>
        </div>
    )
}

export class ActionCreditLimitAmountBoxClass {
    constructor(rulesContext) {
        this.rulesContext = rulesContext
    }

    validate(node) {
        let put_error = false
        let alerts = []

        if ((node.node.children || []).length !== 1) {
            put_error = true
            alerts.push("box_validation_must_have_one_children")
        }
        //Validate if first children of the node is Number
        else{
            let first_children = node.node.children[0]
            if (first_children?.instance?.getType(first_children) !== "number"){
                put_error = true
                    alerts.push("O primeiro filho deste nó deve ser um " + translateType("number")+". Atualmente ele é " + translateType(first_children?.instance?.getType(first_children)))
            } 
        }
        //Validate if parent is valid
        if (node.parentNode != null) {
            if (node.parentNode.on_error) {
                put_error = true
                alerts.push("box_validation_invalid_parent_node")
            }
        }
        return { validated: !put_error, alerts: alerts }
    }

    getType(node) {
        return "void"
    }
}