import React from "react";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

function FraudCard(props) {

    return (
        <div className="analysisCard" style={{border: "1px solid #D91A1A"}}>
            <div 
                className={["redText", "normalMediumSize"].join(" ")} 
                style={{display: "flex", margin:"auto", alignItems:"center"}}
            >
                <ErrorOutlineIcon style={{color:"#D91A1A", width:"18px", marginRight: "5px"}}/>
                Transação reportada como Fraude
            </div>
        </div>
        );
    
}

export default FraudCard;
