//Importing public modules modules
import React, { useState } from "react"
import axios from "axios"

//Defining ValidatedPDF
function PDFDownloadButton(props) {
    //Set loading state 
    const [isLoadingPDF, setIsLoadingPDF] = useState(false)

    //Getting pdf key and endpoint to make request
    const { personKey } = props
    const { person } = props
    const { type } = props
    const { dashAPIEndpointUrl } = props
    const { handlePDFDownloadErrorDialogOpenClick } = props

    let filename = person.document_number;

    if (type === 'natural' && person && person.name) {
        filename = person.name + '_' + filename;
    }
    if (type === 'legal' && person && person.legal_name) {
        filename = person.legal_name + '_' + filename;
    }
    
    if (filename === person.document_number) {
        if (type === 'natural' && person) {
            const events = person.analysis_status_events;
            if (Array.isArray(events)) {
                for (let i = 0; i < events.length; i++) {
                    if (events[i].decision_metadata && events[i].decision_metadata.registration_data && events[i].decision_metadata.registration_data.name && events[i].decision_metadata.registration_data.name.description) {
                        filename = events[i].decision_metadata.registration_data.name.description + '_' + filename;
                        break;
                    }
                }
            } 
        }
    
        if (type === 'legal' && person) {
            const events = person.analysis_status_events;
            if (Array.isArray(events)) {
                for (let i = 0; i < events.length; i++) {
                    if (events[i].decision_metadata && events[i].decision_metadata.registration_data && events[i].decision_metadata.registration_data.legal_name && events[i].decision_metadata.registration_data.legal_name.description) {
                        filename = events[i].decision_metadata.registration_data.legal_name.description + '_' + filename;
                        break;
                    }
                }
            } 
        }
    }

    filename = filename
        .trim()
        .toLowerCase()
        .replace(/\s+/g, '_')
        .replace(/[.]/g, '')
        .replace(/[^a-z0-9_]/g, (char) => {
            const specialChars = {
                'ã': 'a', 'á': 'a', 'à': 'a', 'ä': 'a', 'â': 'a',
                'é': 'e', 'è': 'e', 'ë': 'e', 'ê': 'e',
                'í': 'i', 'ì': 'i', 'ï': 'i', 'î': 'i',
                'õ': 'o', 'ó': 'o', 'ò': 'o', 'ö': 'o', 'ô': 'o',
                'ú': 'u', 'ù': 'u', 'ü': 'u', 'û': 'u',
                'ç': 'c', 'ñ': 'n'
            };
            return specialChars[char] || '';
        });

    //Making request to dash api to get pdf
    const download_pdf = () => {
        setIsLoadingPDF(true)
        axios({
            url: dashAPIEndpointUrl + "/" + personKey + "/pdf",
            method: 'GET',
            responseType: 'blob'
        }).then((response) => {
            setIsLoadingPDF(false)
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', filename + '.pdf')
            document.body.appendChild(link)
            link.click()
        }).catch(error => {
            setIsLoadingPDF(false)
            if ((error.response || {}).status === 404) handlePDFDownloadErrorDialogOpenClick("notFound")
            else handlePDFDownloadErrorDialogOpenClick("internalServerError")

        })
    }

    //Defining div to be rendered
    if (isLoadingPDF) {
        return (
            <div
                className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                style={{ textAlign: "center", margin: "auto", width: "150px" }}
            >
                Carregando PDF
            </div>
        )
    }
    else {
        return (
            <div
                className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                style={{ textAlign: "center", margin: "auto", width: "150px" }}
                onClick={download_pdf}
            >
                Exportar para PDF
            </div>
        )
    }

}

export default PDFDownloadButton