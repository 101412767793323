import React, { useState, useEffect, useContext } from "react";
import ConfirmationDialog from "./ConfirmationDialog/ConfirmationDialog";
import moment from 'moment';
import AuthContext from "../../../../context/auth-context"
import {replaceAll, formatAsMoney, setAllowChangesReservations} from "../../utils/utils"
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ObservationTextField from "../../../../components/common/ObservationTextField";

function Observation(props) {

  let roles = useContext(AuthContext).user_data.roles
  let allowChanges = setAllowChangesReservations (props.reservation.fraud_status, roles)
  let hasManualAnalysisLimitDate = !!props.reservation.manual_analysis_limit_date

  const [openDialog, setOpenDialog] = useState(false)
  const [isApproved, setIsApproved] = useState(null)

  let [observationBox, setObservationBox] = useState('')

  let [disableChange, setDisableChange] = useState(false)
  let [difference, setDifference] = useState(null)

  //Calculate initial difference between NOW and limit_date
    useEffect(() => {
        if(allowChanges && hasManualAnalysisLimitDate){
            const timer = setTimeout(() => {
                const now = moment()
                const limit = moment(props.reservation.manual_analysis_limit_date)
                const diff = limit.diff(now, 'seconds')
                setDifference(diff)
            },500)
            return () => {
                clearTimeout(timer)
            }
        }
    },[allowChanges, props.reservation.manual_analysis_limit_date, hasManualAnalysisLimitDate])

    useEffect(() => {
        if (difference === null) {
        return      
        }
        else if (difference < 0 && hasManualAnalysisLimitDate){
        setDisableChange(true)
        }
        else {
        const timer = setTimeout(() => {
            setDisableChange(true)
        },difference*1000)
        return () => {
            clearTimeout(timer)
        }
        }
    },[difference, hasManualAnalysisLimitDate])

    const handleApprovedOpenClick = () => {
        setIsApproved(true)
        setOpenDialog(true)
    };

    const handleReprovedOpenClick = () => {
        setIsApproved(false)
        setOpenDialog(true)
    };

    const handleDialogClose = () => {
        setOpenDialog(false)
        setTimeout(function (){setIsApproved(null)}, 300)
    };

    let observation = null
    let analyst = ""
    let increaseString = ""
    if (!allowChanges){
        let last_event = props.reservation.fraud_status_events == null || props.reservation.fraud_status_events.length ===0? null :
            props.reservation.fraud_status_events[props.reservation.fraud_status_events.length-1]
        if (last_event){
            observation = last_event.observation
            let string = last_event.analyst_name == null ? null : "Análise feita por %name, %email no dia %day às %time.\n"
            increaseString = last_event.pre_authorization_amount ? "Pré Autorização majorada a um valor de " + formatAsMoney(last_event.pre_authorization_amount, "") + ".\n" : ""
            let mapObj = {
                "%name": last_event.analyst_name,
                "%email": last_event.analyst_email,
                "%day": moment(last_event.event_date).format("DD/MM/YYYY"),
                "%time": moment(last_event.event_date).format("HH:mm"),
            }
            analyst = string == null ? "" :replaceAll(string, mapObj)
        }
    }

    const [reason, setReason] = useState('select');

    const updateReason = (newReason) => {
        if (newReason === 'COMPROT') {
            setObservationBox('Reprovado por COMPROT: ');
        } else if (newReason === 'profile_analysis_alerts') {
            setObservationBox('Reprovado por análise de perfil (combo alertas): ');
        } else if (newReason === 'profile_analysis_reserved') {
            setObservationBox('Reprovado por análise de perfil (grupo reservado): ');
        } else if (newReason === 'quiz_high_risk') {
            setObservationBox('Reprovado pelo Quiz de Alto Risco: ');
        } else if (newReason === 'cnh') {
            setObservationBox('CNH ausente/ilegível/vencida: ');
        } else if (newReason === 'suspicious_client') {
            setObservationBox('Cliente Suspeito: ');
        } else {
            setObservationBox('');
        }
        setReason(newReason)
    }

    return (
        <div className="analysisCard">
            <div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between'}}>
                <div className={["blueText", "subtitleSize"].join(" ")}>Observação</div>
                {(allowChanges && !disableChange) &&
                    <div style={{display: 'flex', flexDirection: 'column', marginBottom: 8}}>
                        <div className={["blueText", "normalMediumSize"].join(" ")} style={{marginBottom: 8}}>Motivos de análise de reprova</div>
                        <Select style={reason === 'select' ? {paddingLeft: "8px", minWidth: "100%", color: "#777"} : {paddingLeft: "8px", minWidth: "100%"}} 
                            labelId="reason-selection" id="reason-selection-dropdown" value={reason} label="Razão" onChange={(e) => updateReason(e.target.value)}>
                            <MenuItem value="select" style={{color: "#777"}}>Selecionar</MenuItem>
                            <MenuItem value="COMPROT">COMPROT</MenuItem>
                            <MenuItem value="profile_analysis_alerts">Análise de perfil (combo alertas)</MenuItem>
                            <MenuItem value="profile_analysis_reserved">Análise de perfil (grupo reservado)</MenuItem>
                            <MenuItem value="quiz_high_risk">Quiz alto risco</MenuItem>
                            <MenuItem value="cnh">CNH ausente/ilegíve/vencida</MenuItem>
                            <MenuItem value="suspicious_client">Cliente suspeito (alerta loja)</MenuItem>
                            <MenuItem value="others">Outros</MenuItem>
                        </Select>
                    </div>
                }
            </div>
            <ObservationTextField content={allowChanges ? 
                                    disableChange && hasManualAnalysisLimitDate ? 
                                        "Aprovado automaticamente devido ao esgotamento do tempo" : observationBox : 
                                        observation == null ? (analyst + increaseString) : (analyst + increaseString + observation)} setContent={setObservationBox} service="car_rental" disable={!(allowChanges && !disableChange)}/>
            {(allowChanges && !disableChange) ? 
            <div>
                <div style={{display:"flex", marginTop: "10px"}}>
                    <div 
                        className={["button", "approved", "normalText", "normalMediumSize"].join(" ")} 
                        style={reason === 'select' ? {color:"#141414"} : {backgroundColor: "#999", color: "#141414"}}
                        onClick={reason === 'select' ? handleApprovedOpenClick : () => {}}
                    >
                        APROVAR 
                    </div>     
                    <div 
                        className={["button", "reproved", "normalText", "normalMediumSize"].join(" ")} 
                        style={reason !== 'select' ? {color:"#141414"} : {backgroundColor: "#999", color: "#141414"}}
                        onClick={reason !== 'select' ? handleReprovedOpenClick : () => {}}
                    >
                        REPROVAR 
                    </div>     
                </div>
                <ConfirmationDialog
                    open={openDialog}
                    isApproved={isApproved}
                    data={observationBox}
                    onClose={handleDialogClose}
                    reservation={props.reservation}
                />
            </div> : null
            }
            {(allowChanges && disableChange && !hasManualAnalysisLimitDate) ? 
            <div>
                <div style={{display:"flex", marginTop: "10px"}}>
                    <div 
                        className={["button", "approved", "normalText", "normalMediumSize"].join(" ")} 
                        style={{color:"#141414"}}
                        onClick={handleApprovedOpenClick}
                    >
                        APROVAR 
                    </div>     
                    <div 
                        className={["button", "reproved", "normalText", "normalMediumSize"].join(" ")} 
                        style={{color:"#141414"}}
                        onClick={handleReprovedOpenClick}
                    >
                        REPROVAR 
                    </div>     
                </div>
                <ConfirmationDialog
                    open={openDialog}
                    isApproved={isApproved}
                    data={observationBox}
                    onClose={handleDialogClose}
                    reservation={props.reservation}
                />
            </div> : null}
        </div>
    )        
}

export default Observation;