import React, { useState } from "react";
import axios from "axios";
import GetAppIcon from '@material-ui/icons/GetApp';
import Tooltip from "@material-ui/core/Tooltip";


function FileDownloadButton(props) {   

    const { attachment_key, attachment_name, attachment_content_type, transaction_key, dashAPIEndpointUrl } = props
    const { handleFileDownloadErrorDialogOpenClick } = props

    const [isLoadingFile, setIsLoadingFile] = useState(false); 

    const download_file = () => {
		setIsLoadingFile(true)
		axios({
			url: dashAPIEndpointUrl + transaction_key + "/attachments/" + attachment_key,
			method: 'GET',
            responseType: "arraybuffer"
        	}).then((response) => {
			setIsLoadingFile(false);
            let fileToBase64 = Buffer.from(response.data, 'binary').toString('base64')
            if (fileToBase64 === null) throw new Error()
			const link = document.createElement('a');
			link.href = `data:${attachment_content_type};base64,${fileToBase64}`;
            link.setAttribute('download', attachment_name);
			document.body.appendChild(link);
			link.click();
		  }).catch(error => {
            setIsLoadingFile(false)
            if ((error.response || {}).status === 404) handleFileDownloadErrorDialogOpenClick("notFound")
            else handleFileDownloadErrorDialogOpenClick("internalServerError")    
            		
            console.log(error)
		})
	};

    if (isLoadingFile){
        return (          
            <div 
                className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                style={{textAlign:"center", margin:"auto", width:"150px"}}
            >
                Carregando File
            </div>
        ) 
    }
    else {
        return (
            <button style={{border: "none", cursor: "pointer", color: "#0b1f82"}} onClick={download_file}>
                <Tooltip title={<p className={["tooltipText", "centerText"].join(" ")}>Exportar arquivo</p>}>
                    <div className={["textOverflow","centerText","labelSpace"].join(" ")}>
                        <GetAppIcon />
                    </div>
                </Tooltip> 
            </button>
        );
    }

}

export default FileDownloadButton;