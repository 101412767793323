import React from "react";
import SCRTable from "../../../Cards/SCRTable/SCRTable";
import { formatAsDate, getMapRiskCalculationTreeObj, getTotalBRLandForeignCurrencyObj, formatAsMoney } from "../../../utils/utils";
import moment from 'moment';
import SCRError from "../../../Cards/SCRTable/SCRError";
import { useTranslation } from "react-i18next";
import DataField from "../../../../@GeneralComponents/DataField";
import NumberDescription from "../../../../@GeneralComponents/NumberDescription";
import AnalysisCardContainer from "../../../utils/Components/AnalysisCardContainer";


function SCRtoPDF(props) {
    const {t} = useTranslation()

    const {scr, scrToExpire2Img, scrOverdue2Img, scrHistoryTotal2Img, scrHistoryToExpireOverdue2Img} = props

    const {scr_data, start_relationship_date, report_start_date, report_end_date} = scr

    let scr_data_no_error = scr_data.filter(scr => (scr||{}).validation_error_message == null)

    let showSCRdata = scr_data_no_error.length > 0
    let hasSCRHistory = scr_data_no_error.length > 1

    let scr_data_ref_month = scr_data_no_error.splice(0,1)

    const mapped_risk_obj = showSCRdata ? getMapRiskCalculationTreeObj((scr_data_ref_month[0] || {}).risk_calculation_tree) : ""
    const mapped_risk_obj_foreign_currency = showSCRdata ? getMapRiskCalculationTreeObj((scr_data_ref_month[0] || {}).risk_calculation_tree_foreign_currency) : ""

    const mapped_total_risk_obj = showSCRdata ? getTotalBRLandForeignCurrencyObj(mapped_risk_obj, mapped_risk_obj_foreign_currency) : ""

    let scr_data_history =  [...scr_data_no_error]
    let credit_to_be_expired_mod01_to_13_sum_formatted = showSCRdata ? formatAsMoney("BRL", mapped_total_risk_obj['credit_to_be_expired_modality_01_to_13_sum'])  : ""
    let credit_overdue_mod01_to_13_sum_formatted = showSCRdata ? formatAsMoney("BRL", mapped_total_risk_obj['credit_overdue_modality_01_to_13_sum']) : ""
    let loan_portfolio_sum_formatted = showSCRdata ? formatAsMoney("BRL", mapped_total_risk_obj['loan_portfolio_sum']) : ""

    if (!showSCRdata) {
        return (
            <div className="analysisCard avoidPageBreakInside" style={{display: 'flex'}}>
                <div 
                    className={["blueText", "subtitleSize", "bold"].join(" ")}
                    style={{margin:"15px 0px 10px 0px"}}
                >
                    {t("scr_title")}
                </div>
                <div 
                    className={["labelText", "normalMediumSize", 'internalColapse'].join(" ")}
                    style={{margin:"10px"}}
                >
                    {t("information_not_available")}
                </div>
            </div>
        )
    } else {
        return (
            <div className="avoidPageBreakInside analysisCard" style={{display: 'flex', flexDirection:"column"}}>
                <div 
                    className={["blueText", "subtitleSize", "bold"].join(" ")}
                    style={{margin:"auto 0px"}}
                >
                    {t("scr_title")}
                </div>
                <AnalysisCardContainer className="analysisCardContainer avoidPageBreakInside" grow={true}>
                    <div className="analysisCard">
                        <div className={["blueText", "subtitleSize"].join(" ")} style={{marginBottom:"10px"}}>{t("general")}</div>
                        {report_start_date&&report_end_date&&
                        <DataField
                            title={t("consulted_period")}
                            label={formatAsDate(report_start_date)+" - "+formatAsDate(report_end_date)}
                            size={"normalMediumSize"}
                            titleNoWrap={false}
                        />}
                        {start_relationship_date&&
                        <DataField
                            title={t("relationship_start_date")}
                            label={moment(start_relationship_date).format("DD/MM/YYYY")}
                            size={"normalMediumSize"}
                            titleNoWrap={false}
                        />}
                    </div>
                </AnalysisCardContainer>
                <AnalysisCardContainer className="analysisCardContainer avoidPageBreakInside" grow={true}>
                    <div className="analysisCard">
                        <div className={["blueText", "subtitleSize"].join(" ")} style={{marginBottom:"10px"}}>{t("month_data")}</div>
                        {(scr_data_ref_month[0]||{}).reference_date&&
                        <DataField
                            title={t("reference_month")}
                            label={formatAsDate((scr_data_ref_month[0] || {}).reference_date)}
                            size={"normalMediumSize"}
                            titleNoWrap={false}
                        />}
                        {(scr_data_ref_month[0]||{}).financial_institution_count&&
                        <DataField
                            title={t("financial_institution_count")}
                            label={(scr_data_ref_month[0] || {}).financial_institution_count}
                            size={"normalMediumSize"}
                            titleNoWrap={false}
                        />}
                        {mapped_total_risk_obj&&
                        <DataField
                            title={t("total_responsability_per_total_risk")}
                            label={mapped_total_risk_obj['total_risk']?
                            `${((mapped_total_risk_obj['total_responsability_sum']/mapped_total_risk_obj['total_risk'])*100).toFixed(2).replace(".", ",")} %`
                            :"0,00%"}
                            size={"normalMediumSize"}
                            titleNoWrap={false}
                        />}
                        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-evenly" }}>
                            <img src={scrToExpire2Img} alt="SCR a vencer" />
                            <NumberDescription
                                total={mapped_total_risk_obj['loan_portfolio_sum']?
                                `${((mapped_total_risk_obj['credit_to_be_expired_modality_01_to_13_sum']/mapped_total_risk_obj['loan_portfolio_sum'])*100).toFixed(2).replace(".", ",")} %`
                                :"0,00 %"}
                                description={t("credit_to_be_expired_per_loan_portfolio")}
                                secondDescription={`${credit_to_be_expired_mod01_to_13_sum_formatted}/ ${loan_portfolio_sum_formatted}`}
                            />
                        </div>
                        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-evenly" }}>
                            <img src={scrOverdue2Img} alt="SCR vencido" />
                            <NumberDescription
                                total={mapped_total_risk_obj['loan_portfolio_sum']?
                                `${((mapped_total_risk_obj['credit_overdue_modality_01_to_13_sum']/mapped_total_risk_obj['loan_portfolio_sum'])*100).toFixed(2).replace(".", ",")} %`
                                :"0,00 %"}
                                description={t("overdue_credit_loan_portfolio")}
                                secondDescription={`${credit_overdue_mod01_to_13_sum_formatted}/ ${loan_portfolio_sum_formatted}`}
                            />
                        </div>
                        <div className={["labelText", "normalSmallSize"].join(" ")} style={{fontStyle: 'italic', textAlign:"right"}}>
                            {t("amount_subject_to_exchange_rate_variation")}
                        </div>
                    </div>
                </AnalysisCardContainer>
                <div>
                    <SCRTable scr_data={scr_data_ref_month[0]} showDropDown={false} isPDF={true}/>
                </div>
                {hasSCRHistory && 
                <div className="avoidPageBreakInside">
                    <div 
                        className={["blueText", "subtitleSize", "bold", 'internalColapse'].join(" ")}
                        style={{marginBottom:"10px"}}
                    >
                        {t("historic")}
                    </div>
                    <div style={{display:'flex', gap: '20px', flexDirection: "column", alignItems: 'center'}}> 
                        <img style={{maxWidth: "190mm", margin:"auto"}} src={scrHistoryToExpireOverdue2Img} alt="Total a Vencer x Total Vencido"/>
                        <img style={{maxWidth: "190mm", margin:"auto"}} src={scrHistoryTotal2Img} alt={t("total_responsability_per_total_risk")}/>
                    </div>
                </div>}
                <div className="avoidPageBreakInside">
                    {hasSCRHistory && scr_data_history.map((scr, index) => 
                    <div style={{display:"flex", flexDirection:"column"}}>
                        {(scr.operation_items.length===0||scr.operation_items[0].length===0) 
                            ? <SCRError key={index} scr_data={scr} showDropDown={true}/>
                            : <SCRTable key={index} scr_data={scr} showDropDown={true} ref_month_reference_date={(scr_data_ref_month[0] || {}).reference_date} isPDF={true}/>
                        }
                    </div>)}
                </div>
            </div>
        );
    }
}

export default SCRtoPDF