import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {getTableRowClass, getAgencyInfo }  from "../PIX/utils/utils";
import { Link } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import moment from 'moment'
import { getKeyTypeIcon, getDictTypeIcon, getDictStatus, getDictStatusColor, getDictStatusIcon } from "../PIX/utils/utilsDictOperation";

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
  },
  blueText: {
    fontFamily: "Open Sans",
    fontWeight: "600",
    color: "#0b1f82"
},
redText: {
    fontFamily: "Open Sans",
    fontWeight: "600",
    color: "#D91A1A"
},
  tableText: {
    fontFamily: "Open Sans",
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "center",
    margin: "10px",
    padding: "6px",
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: '#6F6F6F',
    fontSize: "14px",
    lineHeight: "19px",
    padding: "6px",
  }
}));

function DictOperationHistoricTable(props) {
    const classes = useStyles();
    useTheme();

    const {alert_triggers_transactions} = props

    const getCounterPartObject=(dict_operation)=>{
        let counter_part_account_object = (dict_operation.dict_operation_direction === 'claimer' ? 
                                        dict_operation.destination_account : 
                                        dict_operation.source_account)
        return counter_part_account_object
    }

    const getAgencies=(dict_operation)=>{
        let [institution_name] = getAgencyInfo(getCounterPartObject(dict_operation))
        return institution_name
    }

    return (
        <Paper className={classes.tableStyle}>
        <Table style={{ padding: "8px" }}>
            <TableHead>
            <TableRow>
                <TableCell className={classes.tableHeader}>ID</TableCell>
                <TableCell className={classes.tableHeader}>Tipo de Pedido</TableCell>
                <TableCell className={classes.tableHeader}>Chave</TableCell>
                <TableCell className={classes.tableHeader} style={{width:"10%"}}>Contraparte</TableCell>
                <TableCell className={classes.tableHeader}>Data do Pedido</TableCell>
                <TableCell className={classes.tableHeader}>Status da Operação</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {props.historic.map((dict_operation,index) => (
                <TableRow
                key={index}
                className={getTableRowClass(index)}
                > 
                <TableCell
                    className={[classes.tableText, classes.blueText, "breakWord", "link"].join(" ")}
                >
                    <Link
                        className={
                            alert_triggers_transactions ?
                            alert_triggers_transactions.filter((item) => item === dict_operation.dict_operation_key).length ?
                            "linkRed" :
                            "link" : 
                            "link"
                        }
                        to={"/pix-dict/" + dict_operation.dict_operation_key}
                    >
                        {alert_triggers_transactions ?
                        alert_triggers_transactions.filter((item) => item === dict_operation.dict_operation_key).length ? 
                            <Tooltip title={<p className="tooltipText">Transação responsável por um trigger</p>}>
                                <div style={{display:"flex", justifyContent: "center", alignItems: "center"}}>
                                    <ErrorOutlineIcon style={{color:"#D91A1A", width:"18px", marginRight: "5px"}}/>
                                    {dict_operation.dict_operation_key}
                                </div>
                            </Tooltip> :
                            <div>
                                {dict_operation.dict_operation_key}
                            </div> :
                            <div>
                                {dict_operation.dict_operation_key}
                            </div> 
                        }
                    </Link>
                </TableCell>
                <TableCell className={classes.tableText}>
                    {getDictTypeIcon(dict_operation.dict_operation_type)}
                </TableCell>
                <TableCell className={classes.tableText}>
                    {getKeyTypeIcon(dict_operation.dict_key.key_type, dict_operation.dict_key.key_value)}
                </TableCell>
                <TableCell className={[classes.tableText].join(" ")}>
                    {dict_operation.dict_operation_type !== "registration" ?
                        getAgencies(dict_operation) ?
                            <Tooltip title={<p className="tooltipText">{getAgencies(dict_operation)}</p>}>
                                <div className={["textOverflow","centerText","labelSpace"].join(" ")}>
                                {getAgencies(dict_operation)}
                                </div>
                            </Tooltip>
                            : (getCounterPartObject(dict_operation)).participant
                            : "Não Aplicável"}
                </TableCell>
                <TableCell className={classes.tableText}>
                    {moment(dict_operation.dict_operation_creation_date).format("DD/MM/YYYY HH:mm")}
                </TableCell>
                <TableCell className={[classes.tableText, "bold"].join(" ")}>
                    <Tooltip title={<p className="tooltipText">{getDictStatus (dict_operation.dict_operation_status)}</p>}>
                    <div className={["circle", "small"].join(" ")} style={{backgroundColor: getDictStatusColor (dict_operation.dict_operation_status)}}>
                        {getDictStatusIcon (dict_operation.dict_operation_status,"small", true)}
                    </div>
                    </Tooltip>
                </TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        </Paper>
    );
}

export default DictOperationHistoricTable;
