import React from 'react'
import DataField from "../../utils/DataField"
import {titleCase, formatAddress,formatPhone, formatBureauInfo} from "../../utils/utils"
import { getSalesChannel } from "../../utils/utilsTransaction"
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Tooltip from "@material-ui/core/Tooltip"

function ClientCard(props) {

    let decision_metadata = ((props.dict_operation.analysis_events || [{}])[0]).decision_metadata
    let registration_data = ( decision_metadata || {}).registration_data

    return (
        <div className="analysisCard">
            <div className={["blueText", "subtitleSize"].join(" ")}>Cliente</div>
            <div className="internalCardContainer"  style={{display: "flex", flexDirection: "column"}}>
                <DataField
                    title={"ID do Cliente:"}
                    label={(props.dict_operation.client || {}).id}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                />
                <DataField
                    title={"Nome:"}
                    label={titleCase((props.dict_operation.client || {}).name, null)}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={((registration_data || {}).name || {}).flag ? registration_data.name.flag : null}
                    tooltipText={((registration_data || {}).name || {}).flag ? registration_data.name.flag === "positive" ? "Nome validado" : 
                        ((registration_data || {}).name || {}).description  ? "Nome encontrado em Bureau: " + titleCase(registration_data.name.description) : null : null
                    }
                />
                <DataField
                    title={(props.dict_operation.client || {}).type === "natural_person" ? "CPF:" : "CNPJ:"}
                    label={(props.dict_operation.client || {}).document_number}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                />
                <DataField
                    title={"Telefone:"}
                    label={formatPhone((props.dict_operation.client || {}).phone, null)}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={((registration_data || {}).phone_number || []).length > 0 ? registration_data.phone_number[0].flag : null}
                    tooltipText={((registration_data || {}).phone_number || []).length > 0 ? 
                        registration_data.phone_number[0].description : null
                    }
                >
                    {((registration_data || {}).phone_number || [{}]).length > 1 ?
                    <Tooltip title={<p className="tooltipText">{formatBureauInfo(registration_data.phone_number.slice(1), "telefones")}</p>}>
                        <MoreHorizIcon style={{verticalAlign:"middle"}}/>
                    </Tooltip> : null}
                </DataField>
                <DataField
                    title={"Endereço:"}
                    label={formatAddress((props.dict_operation.client || {}).address, null)}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                    check={((registration_data || {}).address || []).length > 0 ? registration_data.address[0].flag : null}
                    tooltipText={((registration_data || {}).address || []).length > 0 ? 
                        registration_data.address[0].description : null
                    }
                >
                    {((registration_data || {}).address || [{}]).length > 1 ?
                    <Tooltip title={<p className="tooltipText">{formatBureauInfo(registration_data.address.slice(1), "telefones")}</p>}>
                        <MoreHorizIcon style={{verticalAlign:"middle"}}/>
                    </Tooltip> : null}
                </DataField>
                <DataField
                    title={"Canal de Venda:"}
                    label={getSalesChannel((props.dict_operation.client || {}).sales_channel)}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                />
                <DataField
                    title={"Segmento:"}
                    label={(props.dict_operation.client || {}).segment}
                    size={"normalMediumSize"}
                    titleNoWrap={true}
                    margin="5px"
                />
            </div>
        </div>
    )
}

export default ClientCard