import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next"
import { GetAlertsComponent, setObjectByPath, validateConstantValues, translateType } from "./utils"
import GeoPointTextField from "./Components/GeoPointTextField"
import _ from "lodash"
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from "@material-ui/core/Tooltip"
import HelpIcon from "@material-ui/icons/Help"

let this_box_type = "geo_point"

const convertArrayToString = (array) => {
    if (array == null || array[0] == null) {
        return ""
    }
    else {
        let latitudeString = ""
        let longitudeString = ""
        if (array[1] < 0) {
            latitudeString = "South " + String(Math.abs(array[1])) + "°"
        }
        else {
            latitudeString = "North " + String(Math.abs(array[1])) + "°"
        }

        if (array[0] < 0) {
            longitudeString = "West " + String(Math.abs(array[0])) + "°"
        }
        else {
            longitudeString = "East " + String(Math.abs(array[0])) + "°"
        }

        return [latitudeString, longitudeString].join(", ")
    }
}

export function ConstantGeoPointBox(props) {
    const { t } = useTranslation()
    let { node } = props
    let { read_only } = props
    let { handleConfigurationOpen } = props
    let { handleNodeRemove } = props

    return (
        <div>
            {!read_only ?
                <div style={{display:"flex", alignItems: "center"}}>
                    <div style={{ display: "flex", width: "100%", cursor: "pointer", margin: "auto 0px" }} onClick={() => handleConfigurationOpen(node)}>
                        {t(convertArrayToString(node.node.properties.value))}
                        {(node.node.alerts || []).length > 0 ?
                            <GetAlertsComponent alerts={node.node.alerts} /> : null}
                    </div>
                    <IconButton style={{width: 32, height: 32}} aria-label="duplicate" onClick={() => props.handleNodeDuplication(node)}>
                        <ContentCopyIcon style={{width: 20, height: 20}} />
                    </IconButton>
                    <IconButton style={{width: 32, height: 32}} aria-label="close" onClick={() => handleNodeRemove(node)}>
                        <CloseIcon style={{width: 20, height: 20}} />
                    </IconButton>
                </div>
                :
                <div style={{ display: "flex" }}>
                    <div style={{ display: "flex", width: "100%", cursor: "pointer", margin: "auto 0px" }} onClick={() => handleConfigurationOpen(node)}>
                        {t(convertArrayToString(node.node.properties.value))}
                    </div>
                </div>
            }
        </div>
    )
}

export function ConstantGeoPointConfiguration(props) {
    const { t } = useTranslation()
    let { node } = props
    let { read_only } = props
    let { handleNodeChange } = props
    let { handleConfigurationClose } = props

    const [nodeState, setNodeState] = useState(null)
    useEffect(() => {
        setNodeState(node)
    }, [node])

    const handleNodeStateChange = (value, type) => {
        let new_node = _.cloneDeep(nodeState)
        let newValue = nodeState.node.properties.value
        if (type === "latitude") {
            if (!Array.isArray(newValue)) {
                newValue = [undefined, undefined]
            }
            newValue[1] = value
        }
        else {
            if (!Array.isArray(newValue)) {
                newValue = [undefined, undefined]
            }
            newValue[0] = value
        }
        setObjectByPath(new_node.node, "properties.value", newValue)
        setNodeState(new_node)
    }

    const handleSave = () => {
        handleConfigurationClose()
        handleNodeChange(nodeState)
    }

    const handleValidateCoordinate = (geoPoint, coordinateType) => {
        let validated = false
        if (Array.isArray(geoPoint)) {
            if (geoPoint.length === 2) {
                if (coordinateType === "longitude" && typeof geoPoint[0] === "number" && geoPoint[0] <= 180 && geoPoint[0] >= -180) {
                    validated = true
                }
                else if (coordinateType === "latitude" && typeof geoPoint[1] === "number" && geoPoint[1] <= 90 && geoPoint[1] >= -90) {
                    validated = true
                }
            }
        }
        return validated
    }

    if (!nodeState) return null

    return (
        <div style={{ flexGrow: "1", padding: "20px", display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex" }}>
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>{t(node.node.description)}</span>
                <Tooltip title={<div className="tooltipText"><ul>{node.node.helperText.map((help, index) => (<li key={index}>{t(help)}</li>))}</ul></div>}>

                    <HelpIcon style={{ fontSize: "14px", display: "flex", margin: "auto 5px" }} />
                </Tooltip>
            </div>
            <GeoPointTextField
                read_only={read_only}
                value={nodeState.node.properties.value}
                longitudeError={!handleValidateCoordinate(nodeState.node.properties.value, "longitude")}
                latitudeError={!handleValidateCoordinate(nodeState.node.properties.value, "latitude")}
                longitudeValueChangeFunction={(value) => handleNodeStateChange(value, "longitude")}
                latitudeValueChangeFunction={(value) => handleNodeStateChange(value, "latitude")}
            />


            <div style={{ display: "flex", margin: "auto auto 5px auto" }}>
                <div
                    className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")}
                    style={{ width: "120px" }}
                    onClick={handleConfigurationClose}
                >
                    {read_only ? "X" : t("discard")}
                </div>
                {!read_only &&
                    <div
                        className={
                            validateConstantValues(this_box_type, nodeState.node.properties.value)
                                ?
                                ["button", "standard", "normalText", "normalMediumSize"].join(" ")
                                :
                                ["button", "standard", "normalText", "normalMediumSize", "disabled"].join(" ")}
                        style={{ width: "120px" }}
                        onClick={validateConstantValues(this_box_type, nodeState.node.properties.value) ? handleSave : null}
                    >
                        {t("save")}
                    </div>}
            </div>
        </div>
    )
}

export class ConstantGeoPointBoxClass {
    constructor(rulesContext) {
        this.rulesContext = rulesContext
    }
    validate(node) {
        let put_error = false
        let alerts = []
        //Validate if node has no children
        if ((node.node.children || []).length !== 0) {
            put_error = true
            alerts.push("O nó de " + translateType(this_box_type) + " não pode ter filhos")
        }
        //Validate value is not lenth 0
        if (!validateConstantValues(this_box_type, node.node.properties.value)) {
            put_error = true
            alerts.push("Este nó deve ter um valor diferente de nulo")
        }
        //Validate if parent is valid
        if (node.parentNode != null) {
            if (node.parentNode.on_error) {
                put_error = true
                alerts.push("O nó superior está inválido")
            }
        }
        return { validated: !put_error, alerts: alerts }
    }

    getType(node) {
        return this_box_type
    }
}