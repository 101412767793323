import React from "react";
import FilterBox from "../../../Utils/FilterBox/FilterBox";

function Filter(props) {
  const { setPageParams } = props;

  let filterTypes = {
    infraction_report_type: {
      type: "enum",
      enumList: [
        ["Nenhum", ""],
        ["Fraude", "fraud"],
        ["Compliance", "compliance"]
      ],
      disabled: false,
      validation: false,
      validation_rule: null,
      description: "Tipo de Relato",
    },
    infraction_report_creator: {
        type: "enum",
        enumList: [
          ["Nenhum", ""],
          ["Recebido", "external"],
          ["Enviado", "client"]
        ],
        disabled: false,
        validation: false,
        validation_rule: null,
        description: "Origem do Relato",
    },
    infraction_report_status: {
        type: "enum",
        enumList: [
          ["Nenhum", ""],
          ["Criado", "created"],
          ["Não Enviado", "not_sent"],
          ["Enviado ao BC", "sent"],
          ["Recebido", "received"],
          ["Reconhecido", "acknowledged"],
          ["Fechado de Acordo", "closed_agreed"],
          ["Fechado não de Acordo", "closed_disagreed"],
          ["Cancelado", "cancelled"],
        ],
        disabled: false,
        validation: false,
        validation_rule: null,
        description: "Status do Relato",
      },
    date: {
      type: "date",
      disabled: false,
      validation: false,
      validation_rule: null,
      description: "Data",
    },
    transaction_id: {
      type: "string",
      disabled: false,
      validation: false,
      validation_rule: null,
      description: "Número da Transação",
    }
  };

  return (
    <FilterBox
      setPageParams={setPageParams}
      filterTypes={filterTypes}
      local_storage_object_preffix={props.local_storage_object_preffix}
    />
  );
}

export default Filter;
