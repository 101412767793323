import axios from "axios";
import React, { useEffect, useState } from "react";

import * as S from './styles';
import { CircularProgress } from "@material-ui/core";

function Counter() {
    const [rentalAgreements, setRentalAgreements] = useState(null);
    const [reservations, setReservations] = useState(null);
    const [error, setError] = useState(false);

    useEffect(() => {
        axios.get('/dash/car_rental/analyst_count').then((response) => {
            setRentalAgreements(response.data.rental_agreements);
            setReservations(response.data.reservations);
        }).catch(() => setError(true))
    }, [])

    if (error) {
        return <></>;
    }

    if (rentalAgreements === null || reservations === null) {
        return (
            <S.Container>
                <CircularProgress style={{width: 32, height: 32}}/>
            </S.Container>
        );
    }

    return (
        <S.Container>
            <S.Title>Análises realizadas hoje</S.Title>
            <S.Line>
                <S.Label>Aluguéis:</S.Label>
                <S.Value>{rentalAgreements}</S.Value>
            </S.Line>
            <S.Line>
                <S.Label>Reservas:</S.Label>
                <S.Value>{reservations}</S.Value>
            </S.Line>
        </S.Container>
    )
}
export default Counter;
