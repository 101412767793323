import React, {useState} from 'react'
import TextField from '@material-ui/core/TextField';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { getConfirmationIcon } from "../../utils/utils"
import InputMask from "react-input-mask";
import axios from 'axios'
import { CircularProgress } from '@material-ui/core';

const theme = createMuiTheme({
    palette:{
        primary: {
            main:"#0b1f82"
        },
        secondary: {
            main: "#FF0000"
        },
    }
  });

function InputContent (props){
    let inputObjects
    if (props.lockType === "card"){
        inputObjects = {
            cardholder_id:{
                description: "ID do Usuário",
                value: "",
                mask: false,
                error: true
            },
            bin:{
                description: "BIN",
                value: "",
                mask: true,
                mask_type: "999999",
                validation_size: 6,
                error: true
            },
            last4:{
                description: "Últimos 4 Dígitos",
                value: "",
                mask: true,
                mask_type: "9999",
                validation_size: 4,
                error: true
            }
        }
    }
    else if (props.lockType === "merchant"){
        inputObjects = {
            merchant_id:{
                description: "ID do Merchant",
                value: "",
                mask: false,
                error: true
            },
            acquirer_id:{
                description: "ID do Adquirente",
                value: "",
                mask: false,
                error: true
            }
        }
    }
    else{
        inputObjects = {
            merchant_id:{
                description: "ID do Merchant",
                value: "",
                mask: false,
                error: true
            },
            acquirer_id:{
                description: "ID do Adquirente",
                value: "",
                mask: false,
                error: true
            },
            terminal_id:{
                description: "ID do Terminal",
                value: "",
                mask: false,
                error: true
            }
        }
    }

    const [object, setObject] = useState(inputObjects)
    const [pendingState, setPendingState] = useState(false)
    const [status, setStatus] = useState(null)
    const [observationBox, setObservationBox] = useState();

    const handleChange = (event, item) => {
        let newObject = {...object}
        newObject[item].value = event.target.value
        if (event.target.value.length === 0) newObject[item].error = true
        else newObject[item].error = false
        if (newObject[item].validation_size){
            if (event.target.value.length !== newObject[item].validation_size){
                newObject[item].error = true
            }
            else {
                newObject[item].error = false
            }
        }
        setObject(newObject)
    }

    const handleBackClick = () => {
        props.setLockType(null)
    }

    const handleReturn = () => {
        props.onClose()
        props.setPageParams({page: 1, filters: JSON.parse(sessionStorage.getItem(props.local_storage_object_preffix + 'PageParams')).filters})
    }

    const validate = (object) => {
        let error_keys = Object.keys(object).filter(item => {
            return object[item].error === true
        })
        if (error_keys.length > 0) return false
        else return true
    }

    const handleObservationBoxChange = (event) => {
        if (event.target.value.length === 0){
            setObservationBox(null)
        }
        else{
            setObservationBox(event.target.value)
        }
    };

    const onSubmit = () => {
        if (!validate(object)) return
        let payload = Object.keys(object).reduce((acc, item) =>{
            if(object[item].value != null){
                acc[item] = object[item].value
            }
            return acc
        },{})
        if (observationBox){
            payload['observation'] = observationBox
        }
        payload['lock_source'] = 'manually_input'
        payload['lock_type'] = props.lockType
        setPendingState(true)

        let requestHeaders = null;

        if (props.company_key) {
            requestHeaders = { headers:{'company_key': props.company_key}}
        }
        
        axios.post('/dash/card_issuance_backoffice/lock', payload, requestHeaders).then(response => {
            setPendingState(false);
            setStatus({success: true, message: "Requisição processada com sucesso"});
        }).catch(error => {
            console.log(error);
            setPendingState(false);
            setStatus({success: false, message: "Não foi possível processar sua requisição"});
        });
    }


    
    if (pendingState){
        return(
            <div style={{display:"flex", flexGrow:"1"}}>
                <CircularProgress style={{margin:"auto"}}/>
            </div>
        )
    }
    if (status != null) {
        return(
            <div style={{ display:"flex", flexDirection:"column", flexGrow:"1"}}>
                <div style={{margin:"auto"}}>
                    {getConfirmationIcon(status.success)}
                </div>
                <div style={{margin:"auto"}}>
                    {status.message}
                </div>
                <div
                    onClick={handleReturn} 
                    className={["button","standard", "normalText", "normalMediumSize"].join(" ")} 
                    style={{margin:"auto auto 20px auto", width:"auto"}}
                >
                    VOLTAR À PÁGINA INICIAL
                </div>
            </div>
        )
    }

    return (
        <div style={{ display:"flex", flexDirection:"column", flexGrow:"1"}}>
            <div style={{display: "flex",justifyContent: "center",flexDirection: "column",margin: "0 auto 10px auto",width: "60%"}}>
            <ThemeProvider theme={theme}>
                {Object.keys(object).map((item, index) => (
                    <InputMask 
                        key={index}
                        mask={object[item].mask ? object[item].mask_type : null}
                        maskPlaceholder=""
                        value={object[item].value}
                        onChange={(e) => handleChange(e, item)}
                    >
                        <TextField 
                            color={object[item].error? "secondary": "primary"}
                            label={object[item].description}
                        />
                    </InputMask>                    
                ))}
                <TextField  
                    style={{marginTop: "10px"}}    
                    fullWidth
                    multiline
                    variant="outlined"
                    rows="5"
                    label={"Observações"}
                    inputProps={{ maxLength: 500 }}
                    color="primary"
                    onChange={handleObservationBoxChange}
                />
            </ThemeProvider>               
            </div>
            <div style={{display:"flex", flexDirection:"row", justifyContent: "center", margin:"auto 0px 20px 0px"}}>
                <div
                    className={!validate(object) ? ["button","standard", "disabled", "normalText", "normalMediumSize"].join(" ") : ["button","standard", "normalText", "normalMediumSize"].join(" ")} 
                    onClick={onSubmit} 
                    style={{width:"40%"}}
                >
                    Finalizar
                </div>
                <div
                    className={["button","cancel"].join(" ")} 
                    onClick={handleBackClick} 
                    style={{width:"40%"}}
                >
                    Retornar
                </div>
            </div>
        </div>
    )

}

export default InputContent