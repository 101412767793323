import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from 'moment'
import {getTableRowClass, titleCase, checkNested, getEntryIcon,getFraudStatusIcon,getFraudStatus, getFraudColor, getTransactionStatusText, getValidationStatusIcon} from "../../../../../utils/utils"
import { Link } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import getSymbolFromCurrency from 'currency-symbol-map'
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import ReplayIcon from '@material-ui/icons/Replay';
import CheckIcon from "@material-ui/icons/Check";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
  },
  blueText: {
    fontFamily: "Open Sans",
    fontWeight: "600",
    color: "#0b1f82"
},
  tableText: {
    fontFamily: "Open Sans",
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "center",
    margin: "10px",
    padding: "6px",
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: '#6F6F6F',
    fontSize: "14px",
    lineHeight: "19px",
    padding: "6px",
  }
}));

const getTransactionStatusIcon = (status) => {
    const colorGreen = '#73C580'
    const colorRed ='#D91A1A'
    const colorGrey = 'rgb(170, 167, 153)'

    switch(status){
      case "not_authorized":
      case "chargeback":
      case "partial_chargeback":
        return (
            <Tooltip title={<p className="tooltipText">{getTransactionStatusText(status)}</p>}>
                <div className={["circle", "small"].join(" ")} style={{backgroundColor: colorRed}}>
                    <ErrorOutlineIcon  style={{color: "#ffffff", width:"18px", height:"18px"}}/>
                </div>
            </Tooltip>
        );
      case "authorized":
      case "cleared":
      case "captured":
        return (
            <Tooltip title={<p className="tooltipText">{getTransactionStatusText(status)}</p>}>
            <div className={["circle", "small"].join(" ")} style={{backgroundColor: colorGreen}}>
                <CheckIcon style={{color: "#ffffff", width:"18px", height:"18px"}}/>
            </div>
            </Tooltip>
        );
      case "cancelled":
      case "partially_cancelled":
        return (
            <Tooltip title={<p className="tooltipText">{getTransactionStatusText(status)}</p>}>
                <div className={["circle", "small"].join(" ")} style={{backgroundColor: colorGrey}}>
                    <ReplayIcon  style={{color: "#ffffff", width:"18px", height:"18px"}}/>
                </div>
            </Tooltip>
        );
      default:
        return (
            <Tooltip title={<p className="tooltipText">{getTransactionStatusText(status)}</p>}>
        <div className={["circle", "small"].join(" ")} style={{backgroundColor: colorGrey}}>
            <HelpOutlineIcon  style={{color: "#ffffff", width:"18px", height:"18px"}}/>
        </div>
        </Tooltip>
        );
    }
  }

function TransactionTable(props) {
  const classes = useStyles();
  useTheme();

  const {alert_triggers_transactions, alert_triggers_sms_validation} = props

  return (
    <Paper className={classes.tableStyle}>
      <Table style={{ padding: "8px" }}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader}>ID</TableCell>
            <TableCell className={classes.tableHeader}>Nome da Loja</TableCell>
            <TableCell className={classes.tableHeader}>Merchant ID</TableCell>
            <TableCell className={classes.tableHeader}>Terminal ID</TableCell>
            <TableCell className={classes.tableHeader}>Entry Mode</TableCell>
            <TableCell className={classes.tableHeader}>Data</TableCell>
            <TableCell className={classes.tableHeader}>Valor</TableCell>
            <TableCell className={classes.tableHeader}>Status de Fraude</TableCell>
            <TableCell className={classes.tableHeader}>Status da Transação</TableCell>
            <TableCell className={classes.tableHeader}>Validação SMS</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.historic.map((transaction,index) => (
            <TableRow
              key={index}
              className={getTableRowClass(index)}
            > 
                <TableCell
                    className={[classes.tableText, classes.blueText, "breakWord", "link"].join(" ")}
                >
                    <Link
                      className={
                          alert_triggers_transactions ?
                          alert_triggers_transactions.filter((item) => item === transaction.transaction_key).length ?
                          "linkRed" :
                          "link":
                          "link"
                      }

                      to={"/transaction/" + transaction.transaction_key}
                    >
                      {alert_triggers_transactions ?
                        alert_triggers_transactions.filter((item) => item === transaction.transaction_key).length ? 
                            <Tooltip title={<p className="tooltipText">Transação responsável por um trigger</p>}>
                                <div style={{display:"flex", justifyContent: "center", alignItems: "center"}}>
                                    <ErrorOutlineIcon style={{color:"#D91A1A", width:"18px", marginRight: "5px"}}/>
                                    {transaction.transaction_key}
                                </div>
                            </Tooltip> :
                      <div>
                          {transaction.transaction_key}
                      </div> :
                      <div>
                          {transaction.transaction_key}
                      </div>
                      }
                  </Link>
              </TableCell>
              <TableCell className={classes.tableText}>
                {transaction.merchant == null ? "-" : titleCase(transaction.merchant.name, "-")}    
              </TableCell>
              <TableCell className={[classes.tableText, "breakWord"].join(" ")}>
                {checkNested(transaction, "merchant", "merchant_id") ? transaction.merchant.merchant_id : "-"}   
              </TableCell>
              <TableCell className={[classes.tableText, "breakWord"].join(" ")}>
                {checkNested(transaction, "terminal", "id") ? transaction.terminal.id : "-"}
              </TableCell>
              <TableCell className={classes.tableText}>
                {getEntryIcon(transaction.pan_entry_mode)}
              </TableCell>
              <TableCell className={classes.tableText}>
                {moment(transaction.authorization_date).format("DD/MM/YYYY HH:mm:ss")}
              </TableCell>
              <TableCell className={classes.tableText}>
                {transaction.amount == null ? "-" : getSymbolFromCurrency(transaction.currency) + " " + (transaction.amount/100).toLocaleString('de-DE', {minimumFractionDigits: 2})}
              </TableCell>
              <TableCell className={classes.tableText}>
                <Tooltip title={<p className="tooltipText">{getFraudStatus(transaction.fraud_status)}</p>}>
                  <div className={["circle", "small"].join(" ")} style={{backgroundColor: getFraudColor(transaction.fraud_status)}}>
                      {getFraudStatusIcon(transaction.fraud_status, true)}
                  </div>
                </Tooltip>
            </TableCell>    
            <TableCell className={classes.tableText}>
                    {getTransactionStatusIcon(transaction.transaction_status)}
            </TableCell>   
            <TableCell className={classes.tableText}>
              {alert_triggers_sms_validation?.[transaction.transaction_key] ? getValidationStatusIcon(alert_triggers_sms_validation[transaction.transaction_key].validation_status) : "-"}
            </TableCell>  
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default TransactionTable;
