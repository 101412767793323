import React from 'react'
import {getIndicatorsFlagIcon, getIndicatorsFlagColor} from "../../utils/utils"
import { useTranslation } from "react-i18next";

function Indicators(props) {

    const { t } = useTranslation();

    let { indicators } = props

    let showIndicator = !!indicators

    return (
        <div className="analysisCard">
            <div className={["blueText", "subtitleSize"].join(" ")} style={{marginBottom:"10px"}}>{t("Indicadores")}</div>
            <div>
                {showIndicator 
                    ? indicators.map((indicator, index) => (
                        <div key={index} style={{display: "flex", margin: "10px 0px"}}>
                            <div style={{display:"flex", margin:"auto 5px"}}>
                                {getIndicatorsFlagIcon(indicator.flag)}
                            </div>
                            <div
                                className={["labelText","normalMediumSize"].join(" ")}
                                style={{
                                    marginTop:"auto",
                                    marginBottom:"auto", 
                                    color: getIndicatorsFlagColor(indicator.flag)
                                }}
                            >
                                {indicator.description}
                            </div>                         
                        </div>
                    ))
                    : <div  style={{display: "flex"}}>
                        <div 
                            className={["labelText", "normalMediumSize"].join(" ")} 
                            style={{
                                marginTop:"auto",
                                marginBottom:"auto", 
                            }}
                        >
                            {t("Não há indicadores cadastrados.")} 
                        </div>                         
                    </div>
                }
            </div>
        </div>
    );
}
// let labelClasses = ["labelText", props.size, "labelSpace"]

export default Indicators;