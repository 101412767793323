import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {getTableRowClass, getAgencyInfo, formatAsMoney}  from "../../../PIX/utils/utils";
import { Link } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import moment from 'moment'
import {matchedFieldTranslation} from '../../utils/utils'

const useStyles = makeStyles((theme) => ({
    tableStyle: {
        border: "none",
        boxShadow: "none",
    },
    blueText: {
        fontFamily: "Open Sans",
        fontWeight: "600",
        color: "#0b1f82"
    },
    redText: {
        fontFamily: "Open Sans",
        fontWeight: "600",
        color: "#D91A1A"
    },
    tableText: {
        fontFamily: "Open Sans",
        fontSize: "12px",
        lineHeight: "16px",
        textAlign: "center",
        margin: "10px",
        padding: "6px",
    },
    tableHeader: {
        textAlign: "center",
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: "normal",
        color: '#6F6F6F',
        fontSize: "14px",
        lineHeight: "19px",
        padding: "6px",
    }
}));

const getMatchedFields = (transactions) => {
    let matchedFieldsArray = []
    for(let transaction of transactions){
        matchedFieldsArray.push(Object.keys(transaction['highlight']))
    }
    for(let matchedField of matchedFieldsArray){
        if(matchedField.indexOf('company_key')>-1){
                let ckIndex = matchedField.indexOf('company_key')
                matchedField.splice(ckIndex,1)
        }
    }
    return matchedFieldsArray 
}

const getAgencies=(transaction_account_object)=>{
    let [institution_name] = getAgencyInfo(transaction_account_object)
    if(institution_name){
        return institution_name
    }else{
        return transaction_account_object.participant
    }
}

function TransactionSearchTable(props) {

    const matchedFieldsArray = getMatchedFields(props.historic)
    
    const classes = useStyles();
    useTheme();

    return (
        <Paper className={classes.tableStyle}>
        <Table style={{ padding: "8px"}}>
            <TableHead>
            <TableRow>
                <TableCell className={classes.tableHeader} style={{ width: "5%" }}>ID</TableCell>
                <TableCell className={classes.tableHeader} style={{ width: "7%" }}>Valor</TableCell>
                <TableCell className={classes.tableHeader} style={{ width: "8%" }}>Chave</TableCell>
                <TableCell className={classes.tableHeader} style={{ width: "15%" }}>Nome Origem</TableCell>
                <TableCell className={classes.tableHeader} style={{ width: "8%" }}>Doc Origem</TableCell>
                <TableCell className={classes.tableHeader} style={{ width: "15%" }}>Nome Destino</TableCell>
                <TableCell className={classes.tableHeader} style={{ width: "8%" }}>Doc Destino</TableCell>
                <TableCell className={classes.tableHeader} style={{ width: "7%" }}>Instituição Origem</TableCell>
                <TableCell className={classes.tableHeader} style={{ width: "7%" }}>Instituição Destino</TableCell>
                <TableCell className={classes.tableHeader} style={{ width: "10%" }}>Data da Transação</TableCell>
                <TableCell className={classes.tableHeader} style={{ width: "10%" }}>Campo encontrado</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {props.historic.map((transaction,index) => (
                <TableRow
                key={index}
                className={getTableRowClass(index)}
                > 
                <TableCell
                    className={[classes.tableText, classes.blueText, "link", "idOverflow"].join(" ")}
                >
                    <Link
                        className="link"
                        to={"/pix-transaction/" + transaction.transaction_key}
                    >
                        {transaction.id}
                    </Link>
                </TableCell>
                <TableCell className={classes.tableText}>
                    {formatAsMoney(transaction.amount, "-")}
                </TableCell>
                <TableCell className={classes.tableText} >
                    {(transaction || {}).dict_key  
                    ? <Tooltip title={<p className={["tooltipText","centerText"].join(" ")}>{transaction.dict_key.key_value}</p>}>
                        <div className={
                            matchedFieldsArray[index].includes("dict_key.key_value")
                            ? ["textOverflow","centerText","labelSpace", "blueTextSearchAny"].join(" ")
                            : ["textOverflow","centerText","labelSpace"].join(" ")}
                        >
                            {transaction.dict_key.key_value}
                        </div>
                    </Tooltip>
                    : "Não Aplicável"}
                </TableCell>
                <TableCell className={classes.tableText}>
                    <div className={
                        matchedFieldsArray[index].includes("source_account.owner.name")
                        ? "blueTextSearchAny"
                        : null}
                    >
                        {transaction.source_account.owner.name}
                    </div>
                </TableCell>
                <TableCell className={classes.tableText}>
                    <div className={
                        matchedFieldsArray[index].includes("source_account.owner.document_number")
                        ? "blueTextSearchAny"
                        : null}
                    >
                        {transaction.source_account.owner.document_number}
                    </div>
                </TableCell>
                <TableCell className={classes.tableText} >
                    <div className={
                        matchedFieldsArray[index].includes("destination_account.owner.name")
                        ? "blueTextSearchAny"
                        : null}
                    >
                        {transaction.destination_account.owner.name}
                    </div>
                </TableCell>
                <TableCell className={classes.tableText} >
                    <div className={
                        matchedFieldsArray[index].includes("destination_account.owner.document_number")
                        ? "blueTextSearchAny"
                        : null}
                    >
                        {transaction.destination_account.owner.document_number}
                    </div>
                </TableCell>
                <TableCell className={[classes.tableText]}>
                    <Tooltip title={<p className={["tooltipText","centerText"].join(" ")}>{getAgencies(transaction.source_account)}</p>}>
                        <div className={
                            matchedFieldsArray[index].includes("source_account.participant")
                            ? ["textOverflow","centerText","labelSpace", "blueTextSearchAny"].join(" ")
                            : ["textOverflow","centerText","labelSpace"].join(" ")}
                        >
                            {getAgencies(transaction.source_account)}
                        </div>
                    </Tooltip>
                </TableCell>
                <TableCell className={[classes.tableText].join(" ")}>
                    <Tooltip title={<p className={["tooltipText","centerText"].join(" ")}>{getAgencies(transaction.destination_account)}</p>}>
                        <div className={
                            matchedFieldsArray[index].includes("destination_account.participant")
                            ? ["textOverflow","centerText","labelSpace", "blueTextSearchAny"].join(" ")
                            : ["textOverflow","centerText","labelSpace"].join(" ")}
                        >
                            {getAgencies(transaction.destination_account)}
                        </div>
                    </Tooltip>
                </TableCell>
                <TableCell className={classes.tableText}>
                    {moment(transaction.transaction_date).format("DD/MM/YYYY HH:mm")}
                </TableCell>
                <TableCell className={classes.tableText}>
                    {matchedFieldsArray[index].map(matchedField => matchedFieldTranslation(matchedField)).join(", ")}
                </TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        </Paper>
    );
}

export default TransactionSearchTable;
