import React from "react";
import BoavistaBasicDataCard from "./BoavistaBasicDataCard/BoavistaBasicDataCard";
import BoavistaScoreCard from "./BoavistaScoreCard/BoavistaScoreCard";
import BoavistaIncomeCard from "./BoavistaIncomeCard/BoavistaIncomeCard";
import BoavistaNegativeNotesCardToPDF from "./BoavistaNegativeNotesCard/BoavistaNegativeNotesCardToPDF";

function BoavistaToPDF(props) {

    let {boavista,
        preparePDF,
        setHasBoavistaInquiriesImg,
        setBoavistaInquiries2Img,
        boavistaInquiries2Img
    } = props

    const boavista_data = boavista.data[0]

    return (
        <div className="avoidPageBreakInside analysisCard" style={{display: 'flex', flexDirection:"column", width: "850px"}}>
            <div
                className={["blueText", "subtitleSize", "bold"].join(" ")}
                style={{margin:"auto 0px"}}
            >
                Boa Vista
            </div>
            {(boavista_data)? 
                <BoavistaBasicDataCard data={boavista_data}/>
            : null}
            {(boavista_data || {}).score_boavista.score ?
                <BoavistaScoreCard score={boavista_data.score_boavista.score}/>
            : null}
            {(boavista_data || {}).income_boavista ?
                <BoavistaIncomeCard income={boavista_data.income_boavista}/>
            : null}
            {(boavista_data || {}).debts&& 
            <BoavistaNegativeNotesCardToPDF 
                preparePDF={preparePDF}
                negative_notes_data={boavista_data.debts}
                has_negative_note={boavista_data.debts.is_available}
                negative_note_type={"debts"}
                tableStyle={"10% 35% 10% 10% 10% 10% 7.5%"}
                isPDFTableStyle={"10% 30% 10% 10% 10% 10% 7.5% 7.5% 5%"}
                />}
            {(boavista_data || {}).inquiries&&
            <BoavistaNegativeNotesCardToPDF 
                preparePDF={preparePDF}
                negative_notes_data={boavista_data.inquiries}
                has_negative_note={boavista_data.inquiries.is_available}
                negative_note_type={"inquiries"} 
                tableStyle={"33.3% 33.3% 33.3%"}
                setHasBoavistaInquiriesImg = {setHasBoavistaInquiriesImg}
                setBoavistaInquiries2Img = {setBoavistaInquiries2Img}
                boavistaInquiries2Img = {boavistaInquiries2Img}
                />}
            {(boavista_data || {}).protests ? 
            <BoavistaNegativeNotesCardToPDF
                negative_notes_data={boavista_data.protests}
                has_negative_note={boavista_data.protests.is_available}
                negative_note_type={"protests"}
                tableStyle={"20% 20% 20% 20% 20%"}
                props={props}/>
            : null}
        </div>
    );
}

export default BoavistaToPDF
