import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ValidatedImage from "./ValidatedImage/ValidatedImage";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { t } from "i18next";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: "16px",
    color: "#0b1f82",
    padding: "16px 24px 0px 24px",
  },
  dialogContent: {
    textAlign: "center",
    marginBottom: "10px",
    float: "left",
  },
  cardSlider: {
    width: "300px",
    height: "75%",
    margin: "auto auto 0 auto",
    padding: "auto",
  },
  centralizeArrow: {
    height: "40%",
  },
  arrows: {
    color: "#0b1f82",
  },
  mainCard: {
    position: "relative",
    display: "inline-block",
    textAlign: "center",
    width: "auto",
    height: "auto",
    float: "center",
    margin: "auto",
  },
  buttonPrevious: {
    cursor: "pointer",
    width: "5%",
    height: "100%",
    margin: "auto",
    borderRadius: "10px",
    paddingTop: "40px",
    paddingBottom: "40px",
    "&:hover": {
      backgroundColor: "#ddddf9",
    },
  },
  buttonNext: {
    cursor: "pointer",
    width: "5%",
    height: "100%",
    margin: "auto",
    borderRadius: "10px",
    paddingTop: "40px",
    paddingBottom: "40px",
    "&:hover": {
      backgroundColor: "#ddddf9",
    },
  },
  label: {
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "19px",
    color: "#000",
  },
  data: {
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "19px",
    color: "#6F6F6F",
  },
  paragraph: {
    margin: "0",
    width: "100%",
  },
}));

function OCRDialog(props) {
  const classes = useStyles();
  useTheme();

  const { name, open, onClose, sourceImageList } = props;
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => prevIndex - 1);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => prevIndex + 1);
  };

  const image_key = sourceImageList[currentIndex]?.ocr_key;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      keepMounted={false}
      maxWidth="lg"
      fullWidth={true}
    >
      <DialogTitle className={classes.dialogTitle}>{t(name)}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div style={{ display: "flex", margin: "auto", justifyContent: "center" }}>
          <div className="labelSpace" style={{ order: "2", display: "flex" }}>
            <IconButton className={classes.buttonPrevious} onClick={handlePrevious} disabled={currentIndex === 0}>
              <ChevronLeftIcon />
            </IconButton>
            {image_key ? (
              <ValidatedImage
                image_key={image_key}
                dash_api_endpoint_url={'/dash/ocr/image'}
                imageStyle={{ minWidth: "1000px", borderRadius: "5px" }}
                imageAlt={t(name)}
              />
            ) : (
              <div className={["labelText", "normalMediumSize"].join(" ")} style={{ textAlign: "center", margin: "auto" }}>
                {t("information_unavailable")}
              </div>
            )}
            <IconButton
              className={classes.buttonNext}
              onClick={handleNext}
              disabled={currentIndex === sourceImageList.length - 1}
            >
              <ChevronRightIcon />
            </IconButton>
          </div>
        </div>
        <div className="subtitleSize normalText bold">{`${currentIndex+1} de ${sourceImageList.length}`}</div>
      </DialogContent>
    </Dialog>
  );
}

export default OCRDialog;