import React from 'react'
import complianceLogo from '../../../assets/cardEntryIcons/compliance.svg';
import fraudLogo from '../../../assets/cardEntryIcons/fraud.svg';
import Tooltip from "@material-ui/core/Tooltip";
import merchantGreyLogo from '../../../assets/cardEntryIcons/MerchantIconGrey.svg';
import peopleGreyLogo from '../../../assets/cardEntryIcons/peopleGrey.svg';
import {colorGreen, colorRed, colorYellow, colorOrange, colorVelvet, colorGrey, checkNested} from "./utils"

export const getSeverityStatus = (status) => {
    switch(status){
        case "low":
            return "Baixo Risco"
        case "medium":
            return "Médio Risco"
        case "high":
            return "Alto Risco"
        case "critical":
            return "Crítico"
        default:
            return status
    }
}
export const getSeverityStatusSiglum = (status) => {
    switch(status){
        case "low":
            return "B"
        case "medium":
            return "M"
        case "high":
            return "A"
        case "critical":
            return "C"
        default:
            return "?"
    }
}

export const getAlertStatusText = (status) => {
    switch(status){
        case "pending":
        case "in_manual_analysis":
            return "Pendente"
        case "blocked":
            return "Bloqueado"
        case "solved_true_positive":
        case "solved_false_positive":
            return "Tratado"
        case "silenced":
            return "Silenciado"
        default:
            return status
    }
}

export const getAlertStatusTextSiglum = (status) => {
    switch(status){
        case "pending":
        case "in_manual_analysis":
            return "P"
        case "blocked":
            return "B"
        case "solved_true_positive":
        case "solved_false_positive":
            return "T"
        case "silenced":
            return "S"
        default:
            return "?"
    }
}

//Color Utils
export const getSeverityColor = (status) => {
    switch(status){
        case "low":
            return colorYellow
        case "medium":
            return colorOrange
        case "high":
            return colorVelvet
        case "critical":
            return colorRed
        default:
            return colorGrey
    }
}

export const getAlertStatusColor = (status) => {
    switch(status){
        case "pending":
        case "in_manual_analysis":
            return colorYellow
        case "blocked":
            return colorRed
        case "solved_true_positive":
        case "solved_false_positive":    
            return colorGreen
        case "silenced":
        default:
            return colorGrey
    }
}




export const formatIrregularity = (irregularity_type, decrease=false, asTooltip=false) => {
    let text
    let icon    
    switch (irregularity_type) {
        case "compliance":
            text = "Compliance"
            icon = complianceLogo
            break
        case "fraud":
            text = "Fraude"
            icon = fraudLogo
            break
        default:
            return "-"
    }
    if (asTooltip){
        return (
            <div style={{display:"flex", justifyContent:"center"}}>
                <Tooltip title={<p className="tooltipText">{text}</p>}>
                    <img style={decrease ? {width:"16px"} : null} src={icon} alt="irregularity Logo" />
                </Tooltip>
            </div>

        )
    }
    else{
        return(
            <div style={{display:"flex", justifyContent:"center"}}>
                <img style={decrease ? {width:"16px"} : null} src={icon} alt="irregularity Logo" />
                <div className="labelSpace">{text}</div>
            </div>
        )
    } 
}

const getGreaterRisk = (risk_level) => {
    if (risk_level.includes("critical")){
        return "critical"
    }
    else if (risk_level.includes("high")){
        return "high"
    }
    else if (risk_level.includes("medium")){
        return "medium"
    }
    else {
        return "low"
    }
}

export function getTriggerRisk (array){
    let allCategories = array.map(function(trigger) {
        return trigger.category;
      });
    let uniqueItems = Array.from(new Set(allCategories))
    
    let changedArray = uniqueItems.map(function(category){
        let filteredObj = array.filter((trigger)=>{
            return trigger['category'].includes(category);
        });
        let workedRiskObj = filteredObj.reduce((acc, trigger) => {
            acc.allRisks.push(trigger.risk_level)
            acc.allTerminals.push((trigger.transaction || {}).terminal_id)
            return acc
        }, {
            allRisks: [],
            allTerminals: []
        })

        let allTerminals = workedRiskObj.allTerminals.filter(function(terminal) {
            return terminal != null;
        });
        
        return [category, getGreaterRisk(workedRiskObj.allRisks), allTerminals]
    })
    var riskObject = {};
    changedArray.forEach(function(data){
        riskObject[data[0]] = data[1]
        riskObject['terminals'] = data[2]
    });
    let maximizedTrigger = array.map(function(trigger) {
        let category = trigger.category
        return {...trigger, terminalList: riskObject['terminals'], risk_level: riskObject[category]}
    });
    return maximizedTrigger
}

export const formatListCardName = (link_type, order) => {
    if (order == null) return "-"
    let logo = null
    let name = "-"
    switch (link_type) {
        case "seller":
            logo = merchantGreyLogo
            name = checkNested(order, "seller", "name") ? order.seller.name : "-"
            break
        case "customer":
            logo = peopleGreyLogo
            name = checkNested(order, "customer", "name") ? order.customer.name : "-"
            break
        default:
            break
    }
    return(
        <div style={{display:"flex", justifyContent:"center"}}>
            <img src={logo} alt="name Logo" />
            <div className="labelSpace">{name}</div>
        </div>
    )
}

export const  getLockData = (alert) => {
    let lockData = {}
    switch(alert.link_key_type){
        case "customer":
            lockData.type = "Comprador"
            lockData.documentType = alert.link_key_data.customer.type === "natural_person" ? "CPF" : "CNPJ"
            lockData.documentNumber = alert.customer_document_number
            lockData.id = alert.customer_id
            return lockData
        case "seller":
            lockData.type = "Vendedor"
            lockData.documentType = alert.link_key_data.seller.type === "natural_person" ? "CPF" : "CNPJ"
            lockData.documentNumber = alert.seller_document_number
            lockData.id = alert.seller_id
            return lockData
        default:
            return lockData
    }
}  


export const getPayload = (alert) => {
    if (alert.link_key_type === "customer"){
        return {
            customer_id: alert.customer_id,
            customer_document_number: alert.customer_document_number,
        }
    }
    else {
        return {
            seller_id: alert.seller_id,
            seller_document_number: alert.seller_document_number,
        }        
    }
}