import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from 'moment'
import { formatAsMoney } from "../../../../utils/utils";

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
  },
  tableText: {
    fontFamily: "Open Sans",
    fontWeight: 'normal',
    fontSize: "12px",
    lineHeight: "16px",
    color: "#141414",
    textAlign: "center",
    margin: "10px",
    padding: "6px",
    minWidth: "120px"
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: '#6F6F6F',
    fontSize: "14px",
    lineHeight: "19px",
    padding: "6px",
  },
  even: {
    background: "#F3F3F3",
  },
  odd: {
    background: "#FFFFFF",
  },
}));

function ProtestsTable(props) {
  const classes = useStyles();
  useTheme();

  const getClass = (rowIndex) => {
    if (rowIndex % 2 === 0) {
      return classes.even;
    }

    return classes.odd;
  };

  return (
    <Paper className={classes.tableStyle}>
      <Table style={{ padding: "8px" }}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader}>Cartório</TableCell>
            <TableCell className={classes.tableHeader}>Cidade</TableCell>
            <TableCell className={classes.tableHeader}>Data do Protesto</TableCell>
            <TableCell className={classes.tableHeader}>Data de Expiração</TableCell>
            <TableCell className={classes.tableHeader}>Valor</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.protests_data.map((violation,index) => (
            <TableRow
              key={index}
              className={getClass(index)}
            >
            <TableCell className={classes.tableText}>
              {violation.description == null ? "-" : violation.description.toUpperCase()}
            </TableCell>
              <TableCell className={classes.tableText}>
                {violation.city == null ? "-" : violation.city.toUpperCase() + " - "+ violation.state.toUpperCase()}
              </TableCell>
              <TableCell className={classes.tableText}>
                {violation.protest_date == null ? "-" : moment(violation.protest_date).format('DD/MM/YYYY')}
              </TableCell>
              <TableCell className={classes.tableText}>
                {violation.expiration_date == null ? "-" : moment(violation.expiration_date).format('DD/MM/YYYY')}
              </TableCell>
              <TableCell className={classes.tableText}>
                {violation.amount == null ? "-" : formatAsMoney(violation.amount) }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default ProtestsTable;
