import React from 'react'
import people from '../../../../assets/cardEntryIcons/people.svg';
import peopleGreen from '../../../../assets/cardEntryIcons/peopleGreen.svg';
import Tooltip from "@material-ui/core/Tooltip"
import Account from './Account/Account';
import PersonInformation from './PersonInformation/PersonInformation'

function EnvolvedParties(props) {
    let {bankslip} = props

    return (
        <div className="analysisCard">
            <div className="internalCardContainer" style={{display: "flex"}}>
                <img style={{margin:"auto 8px auto 0px"}} src={people} alt="people Logo"/>  
                <div className={["blueText", "subtitleSize"].join(" ")}>Partes Envolvidas</div>      
            </div>
            <div className="internalCardContainer" style={{marginTop:"15px"}}>
                {bankslip.payer &&
                    <div style={{width:"100%", backgroundColor:"#DEF2FE", 
                                borderRadius:"12px", display:'flex', marginBottom:"20px"}}>
                        <div style={{width:"50%", padding:"20px"}}>
                            <div className={["blueText", "normalSize"].join(" ")} style={{display:"flex"}}>
                                PAGADOR
                                {bankslip.bankslip_direction === "payed"?
                                <Tooltip title={<p className="tooltipText">Cliente</p>}>
                                    <div style={{display: "flex", margin: "auto auto auto 10px"}}>
                                        <img className="pinIconStyle" src={peopleGreen} alt="clientIcon Logo" />  
                                    </div>
                                </Tooltip> 
                                :null}
                            </div>
                            <PersonInformation person={bankslip.payer}/>
                        </div>
                        <div style={{width:"50%", padding:"20px"}}>
                            <div className={["blueText", "normalSize"].join(" ")}>DADOS BANCÁRIOS</div>
                            <Account accountObject={bankslip.payer.account}/>
                        </div>
                    </div>
                }
                {bankslip.recipient &&
                    <div style={{width:"100%", backgroundColor:"#DEF2FE", 
                                borderRadius:"12px", display:'flex', marginBottom:"20px"}}>
                        <div style={{width:"50%", padding:"20px"}}>
                            <div className={["blueText", "normalSize"].join(" ")} style={{display:"flex"}}>
                                RECEBEDOR
                                {bankslip.bankslip_direction === "received"?
                                <Tooltip title={<p className="tooltipText">Cliente</p>}>
                                    <div style={{display: "flex", margin: "auto auto auto 10px"}}>
                                        <img className="pinIconStyle" src={peopleGreen} alt="clientIcon Logo" />  
                                    </div>
                                </Tooltip> 
                                :null}
                            </div>
                            <PersonInformation person={bankslip.recipient}/>
                        </div>
                        <div style={{width:"50%", padding:"20px"}}>
                            <div className={["blueText", "normalSize"].join(" ")}>DADOS BANCÁRIOS</div>
                            <Account accountObject={bankslip.recipient.account}/>
                        </div>
                    </div>
                }
                {bankslip.final_recipient &&
                    <div style={{width:"100%", backgroundColor:"#DEF2FE", 
                                borderRadius:"12px", display:'flex', marginBottom:"20px"}}>
                        <div style={{width:"50%", padding:"20px"}}>
                            <div className={["blueText", "normalSize"].join(" ")} style={{display:"flex"}}>
                                RECEBEDOR FINAL
                            </div>
                            <PersonInformation person={bankslip.final_recipient}/>
                        </div>
                        <div style={{width:"50%", padding:"20px"}}>
                            <div className={["blueText", "normalSize"].join(" ")}>DADOS BANCÁRIOS</div>
                            <Account accountObject={bankslip.final_recipient.account}/>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default EnvolvedParties