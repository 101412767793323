import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ValidatedImage from "../../../utils/ValidatedImage"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import {getPictureColor ,getPictureType, titleCase ,checkNested} from "../../../utils/utils"
import moment from  'moment'
import DataField from "../../../utils/DataField"

function ProfilePictureDialogCD(props) {

    let credDefense = props.reservation.fraud_status_events[0].decision_metadata.creddefense_passages
    let current_picture = props.reservation.fraud_status_events[0].decision_metadata.processed_face_picture
    let current_similarity = props.reservation.fraud_status_events[0].decision_metadata.picture_verification_similarity

    const [counterRegistration, setCounterRegistration] = useState(0);
    const [counterSimilarities, setCounterSimilarities] = useState(0);

    const clickNext = (isRegistration) => {
        let counter = isRegistration ? counterRegistration : counterSimilarities
        let setFunction = isRegistration ? setCounterRegistration : setCounterSimilarities
        let dataSet = isRegistration ? registration_picture.length : similarities_pictures.length

        if (counter + 1 === dataSet) {
            setFunction(0);
        } else {
            setFunction(counter + 1);
        }
    };

    const clickPrevious = (isRegistration) => {
        let counter = isRegistration ? counterRegistration : counterSimilarities
        let setFunction = isRegistration ? setCounterRegistration : setCounterSimilarities
        let dataSet = isRegistration ? registration_picture.length : similarities_pictures.length

        if (counter === 0) {
            setFunction(dataSet - 1);
        } else {
            setFunction(counter - 1);
        }
    };

    let registration_picture = credDefense.filter(picture => (
        picture.type === "registration"
    ))
    let similarities_pictures = credDefense.filter(picture => (
        picture.type !== "registration"
    ))

    let getPictureContent = (picture) => {
        return(
            <div style={{margin:"20px"}}>
                {picture == null ?
                <div style={{height:"210px"}} className={["normalText", "normalMediumSize", "emptyPicture"].join(" ")}>
                    Informação Indisponível                    
                </div> :
                <ValidatedImage
                image_key={picture}
                dash_api_endpoint_url={'/dash/car_rental/image/face_validation_picture'}
                imageStyle = {{width:"160px", height:"210px", borderRadius: "5px"}}
                imageAlt = {"Foto do Cliente"}
                />
                }
            </div>
        )
    };
    return (
        <Dialog maxWidth={false} open={props.open} onClose={props.onClose} keepMounted={false}>
            <DialogTitle className={["blueText", "subtitleSize"].join(" ")}>Fotos CredDefense</DialogTitle>
            <DialogContent >
                <div style={{display:"flex"}}>
                    <div style={{display:"flex", flexDirection: "column", maxWidth:"200px"}}>
                        <div 
                            className={["normalText", "normalMediumSize"].join(" ")} 
                            style={{textAlign:"center"}}
                        >
                            Foto Atual
                        </div>
                        <div style={{display:"flex", width:"200px", minHeight:"250px"}}>
                            {getPictureContent(current_picture)}
                        </div>
                        <div style={{margin: "0px 20px"}}>
                            <DataField
                                title={"Nome"}
                                label={checkNested(props.ra, "client", "name") ? titleCase(props.reservation.client.name, "-") : "-"}
                                size={"normalSmallSize"}
                                display={"inline"}
                            />
                            <DataField
                                title={"CPF"}
                                label={checkNested(props.ra, "client", "document_number") ? props.reservation.client.document_number : "-"}
                                size={"normalSmallSize"}
                            />
                        </div>
                    </div>
                    <div style={{display:"flex",  flexDirection: "column", maxWidth:"250px"}}>
                        <div 
                            className={["normalText", "normalMediumSize"].join(" ")} 
                            style={{textAlign:"center"}}
                        >
                            Foto de Cadastro
                        </div>
                        <div style={{display:"flex"}}>
                            {registration_picture.length < 2 ? null :
                            <React.Fragment>
                            <div onClick={() => clickPrevious(true)} className="arrowPicture" style={{height:"210px", order:"1"}}>
                                <ArrowBackIosIcon style={{color: "#0b1f82"}}/>
                            </div>
                            <div onClick={() => clickNext(true)} className="arrowPicture" style={{height:"210px", order:"3"}}>
                                <ArrowForwardIosIcon style={{color: "#0b1f82"}}/>
                            </div>
                            </React.Fragment>}
                            <div style={{order: "2", display:"flex", width:"200px", minHeight:"250px", flexDirection: "column"}}>
                                {getPictureContent((registration_picture[counterRegistration] || [{}]).picture_key)}
                                {registration_picture.length === 0 ? null :
                                <div style={{margin: "0px 20px"}}>
                                    <DataField
                                        title={"Nome"}
                                        label={titleCase((registration_picture[counterRegistration] || [{}]).name, "-")}
                                        size={"normalSmallSize"}
                                        display={"inline"}
                                    />
                                    <DataField
                                        title={"CPF"}
                                        label={(registration_picture[counterRegistration] || [{}]).document_number || "-"}
                                        size={"normalSmallSize"}
                                    />
                                    <DataField
                                        title={"Similaridade"}
                                        label={current_similarity + "%"}
                                        size={"normalSmallSize"}
                                        labelStyles={{color: getPictureColor("similarity", current_similarity)}}
                                    />
                                    <DataField
                                        title={"Data"}
                                        label={moment((registration_picture[counterRegistration] || [{}]).insert_date).format("DD/MM/YYYY" )}
                                        size={"normalSmallSize"}
                                    />
                                </div>}
                            </div>
                        </div>
                    </div>
                    {similarities_pictures.length === 0 ? null :
                    <div style={{display:"flex", flexDirection: "column", maxWidth:"250px"}}>
                        <div 
                            className={["normalText", "normalMediumSize"].join(" ")} 
                            style={{textAlign:"center"}}
                        >
                            Fotos Similares
                        </div>
                        <div style={{display:"flex"}}>
                            {similarities_pictures.length < 2 ? null :
                            <React.Fragment>
                            <div onClick={() => clickPrevious(false)} className="arrowPicture" style={{height:"210px", order:"1"}}>
                                <ArrowBackIosIcon style={{color: "#0b1f82"}}/>
                            </div>
                            <div onClick={() => clickNext(false)} className="arrowPicture" style={{height:"210px", order:"3"}}>
                                <ArrowForwardIosIcon style={{color: "#0b1f82"}}/>
                            </div>
                            </React.Fragment>}
                            <div style={{order: "2", display:"flex", width:"200px", minHeight:"250px", flexDirection: "column"}}>
                                {getPictureContent((similarities_pictures[counterSimilarities] || [{}]).picture_key)}
                                <div style={{margin: "0px 20px"}}>
                                    <DataField
                                        title={"Nome"}
                                        label={titleCase((similarities_pictures[counterSimilarities] || [{}]).name, "-")}
                                        size={"normalSmallSize"}
                                        display={"inline"}
                                    />
                                    <DataField
                                        title={"Similaridade"}
                                        label={(similarities_pictures[counterSimilarities] || [{}]).similarity + "%"}
                                        size={"normalSmallSize"}
                                        labelStyles={{color: getPictureColor("similarity", (similarities_pictures[counterSimilarities] || [{}]).similarity)}}
                                    />
                                    <DataField
                                        title={"Tipo"}
                                        label={getPictureType((similarities_pictures[counterSimilarities] || [{}]).type)}
                                        size={"normalSmallSize"}
                                        labelStyles={{color: getPictureColor("status", (similarities_pictures[counterSimilarities] || [{}]).type)}}
                                    />
                                    <DataField
                                        title={"CPF"}
                                        label={(similarities_pictures[counterSimilarities] || [{}]).document_number || "-"}
                                        size={"normalSmallSize"}
                                    />
                                    <DataField
                                        title={"Data"}
                                        label={moment((similarities_pictures[counterSimilarities] || [{}]).insert_date).format("DD/MM/YYYY" )}
                                        size={"normalSmallSize"}
                                    />
                                </div>
                            </div>                            
                        </div>        
                    </div>
                    }
                </div>
            
            </DialogContent>
        </Dialog>
    );
}

export default ProfilePictureDialogCD;
