import React from 'react'
import {getTransactionStatusText} from "../../../utils/utils"
import moment from "moment"

function Timeline(props) {
    return (
        <div className={props.position === 0 ? ["timelineCard", "blueBorder"].join(" ") : "timelineCard"}>
            <div className={["blueText", "normalSmallSize"].join(" ")}>
                {getTransactionStatusText(props.event.new_status)}
            </div>
            <div className="internalCardContainer">
                {
                    props.event.event_date == null ? null :
                    <div style={{display: "flex", margin: "5px 0px"}}>
                        <div className={["normalText", "normalSmallSize", "noWrap"].join(" ")}>
                            Data e Hora:
                        </div>
                        <div className={["labelText", "normalSmallSize", "labelSpace"].join(" ")}>
                            {moment(props.event.event_date).format("DD/MM/YYYY - HH:mm:ss")}
                        </div>                    
                    </div>
                }
                {
                    props.event.response_code == null ? null :
                    <div style={{display: "flex", margin: "5px 0px"}}>
                        <div className={["normalText", "normalSmallSize"].join(" ")}>
                            Response Code:
                        </div>
                        <div className={["labelText", "normalSmallSize", "labelSpace"].join(" ")}>
                            {props.event.response_code}
                        </div>                    
                    </div>
                }
                {
                    props.event.partial_amount == null ? null :
                    <div style={{display: "flex", margin: "5px 0px"}}>
                        <div className={["normalText", "normalSmallSize"].join(" ")}>
                            Valor: 
                        </div>
                        <div className={["labelText", "normalSmallSize", "labelSpace"].join(" ")}>
                            {(props.event.partial_amount/100).toLocaleString('de-DE', {minimumFractionDigits: 2})}
                        </div>                    
                    </div>
                }
            </div>
        </div>
    )
}

export default Timeline