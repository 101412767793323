import React from "react"; 
import Alert from "./Alert/Alert"
import {getProperMetadata, getRiskLevel} from "../../utils/utils"
import DataField from "../../utils/DataField"
import { useTranslation } from "react-i18next";

const getStatus = (status, ratioColor) => {
	switch (status) {
		case "pending":
		case "in_manual_analysis":
			return (<span style={{color: ratioColor}}>Pendente</span>)
		case "manually_approved":
			return (<span style={{color: '#73C580'}}>Aprovado Manualmente</span>)
		case "automatically_approved":
			return (<span style={{color: '#73C580'}}>Aprovado Automaticamente</span>)
		case "manually_reproved":
			return (<span style={{color: '#D91A1A'}}>Reprovado Manualmente</span>)
		case "automatically_reproved":
			return (<span style={{color: '#D91A1A'}}>Reprovado Automaticamente</span>)
		default:
			return (<span style={{color: ratioColor}}>{status}</span>)
	  }
}

function Summary(props) {    
    let decision_metadata = getProperMetadata(props.legal_person.analysis_status_events)
    const { t } = useTranslation()
    
    if (Object.keys(decision_metadata || {}).length === 0) {
		return (
            <div className="analysisCard">
                <div className={["blueText", "subtitleSize"].join(" ")}>{t("summary")}</div>
                <div style={{margin:"auto"}} className={["labelText", "normalMediumSize"].join(" ")}>{t("information_unavailable")}</div>
            </div>
		);        
  	}
  	else{
		let nr_of_itens = 0
		let nr_of_check = 0
		const lists = Object.keys(decision_metadata)
        var count_no_alerts = 0
        let total_itens_list = lists.filter(metadata_key => {
            let filter = false
            if (decision_metadata[metadata_key] && typeof decision_metadata[metadata_key] === "object"){
                if (decision_metadata[metadata_key].flag != null) {filter = true}
            }
            return filter
        })
		let total_itens = total_itens_list.length-count_no_alerts
		let alerts=[]
		for (const metadata_key of total_itens_list) {
            if(decision_metadata[metadata_key].description!=null){
                alerts.push({
                    flag: decision_metadata[metadata_key].flag,
                    description: decision_metadata[metadata_key].description
                })
            }
            if (decision_metadata[metadata_key].flag !== "neutral"){
                nr_of_itens = nr_of_itens + 1
                if (decision_metadata[metadata_key].flag === "positive"){
                    nr_of_check = nr_of_check + 1
                }
            }
        }
		let ratio = nr_of_check/nr_of_itens*100
		let color = '#DDBB08'
		if (ratio >= 80){
			color = '#73C580'
		}
		else if (ratio < 50){
			color = '#D91A1A'
		}
		
        let score = (decision_metadata || {}).tree_score === 0 ? "0" : (decision_metadata || {}).tree_score
        let user_variables_list = decision_metadata?.user_variables ? decision_metadata.user_variables : []
        let risk_level = props.legal_person.risk_level

        return (
			<div className="analysisCard">
				<div className={["blueText", "subtitleSize"].join(" ")}>{t("summary")}</div>
                <div style={{display:"flex"}} className="internalCardContainer">
                    <div 
                        style={{color: color, borderColor: color, borderWidth:"2px", borderStyle:"solid", margin: "0 5px", minWidth: "100px"}} 
                        className={["circle", "big","normalText", "subtitleSize", "bold"].join(" ")}
                    >
                        {ratio.toFixed(0) + '%'}
                    </div>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", margin: "auto 5px"}}>
                        <div className={["normalText", "normalMediumSize"].join(" ")}>
                            Status: {getStatus(props.legal_person.analysis_status, color)}
                        </div>
                        {
                            decision_metadata.reason ? 
                            <div className={["normalText", "normalMediumSize"].join(" ")}>
                                Razão: {decision_metadata.reason_description || decision_metadata.reason}
                            </div>
                            :
                            null
                        }
                        {
                            score ? 
                            <div className={["normalText", "normalMediumSize"].join(" ")}>
                                Score: {score}
                            </div>
                            :
                            null
                        }
                        {(risk_level && risk_level !== "undefined") ?
                            <div className={["normalText", "normalMediumSize"].join(" ")}>
                                Nível de risco: {getRiskLevel(risk_level)}
                            </div>
                        : null
                        }                        
                        <div className={["normalText", "normalSmallSize"].join(" ")}>
                            {nr_of_check}/{nr_of_itens} itens aprovados
                        </div>
                        {nr_of_check === nr_of_itens ? null : 
                            <div className={["normalText", "normalSmallSize"].join(" ")}>
                                {nr_of_itens - nr_of_check}/{nr_of_itens} itens reprovados
                            </div>
                        }
                        {total_itens === nr_of_itens ? null : 
                            <div className={["normalText", "normalSmallSize"].join(" ")}>
                                {total_itens-nr_of_itens}/{total_itens} itens não verificados
                            </div>
                        }
                    </div>
                </div>
                <div  className="internalCardContainer" style={{display: 'flex', flexDirection: 'column'}}>
                    {!!alerts&&
                    <div className={["normalMediumSize","bold"].join(" ")} style={{marginTop: '15px'}}>
                        {`${t("alerts")}:`}
                    </div>}
                    {alerts.map((alert, index) => (
                    <Alert key={index} alert_data={alert} />))}
                    {!!user_variables_list.length && 
                    <>
                        <div className={["normalMediumSize","bold"].join(" ")} style={{marginTop: '15px'}}>
                            Variáveis do motor:</div>
                        {user_variables_list.map((custom_variable, index) => (
                            <div key={index} style={{display: 'flex', flexDirection: 'column'}}>
                                <DataField
                                    title={custom_variable.variable_name}
                                    label={custom_variable.variable_value.toString()}
                                    size={"normalMediumSize"}
                                    titleNoWrap={false}
                                />
                            </div>))}
                    </>}
                </div>
			</div>
		);
  	}
}

export default Summary;
