import React, { useReducer, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";
import axios from "axios"
import { CircularProgress } from '@material-ui/core';
import ConfirmationContent from "./ConfirmationContent/ConfirmationContent";
import { decisionReducer } from "../../../../Utils/requestUtils"
import FileUploadToBase64 from "../../../../Utils/FileUploadToBase64"

function FraudDialog(props) {
    let {bankslip, open, onClose } = props

    const history = useHistory()

    const [dialogState, dispatchDialogState] = useReducer(
        decisionReducer,
        {isLoading: false, isError: false, finished:false}
    )

    const [ observation, setObservationBox ] = useState("")

    const handleObservationBoxChange = (event) => {
        if (event.target.value.length === 0){
            setObservationBox("")
        }
        else{
            setObservationBox(event.target.value)
        }
    };

    const [attachment, setAttachment ] = useState(false)
    
    const getFiles = (files) => {
        setAttachment({...files})
    }

    const handleFinish = () =>{
        dispatchDialogState({type:"send_request_init"})
        let payload = attachment ? 
                        { 
                            fraud_status: "fraud",
                            observation: observation,
                            attachment: attachment
                        } : 
                        { 
                            fraud_status: "fraud",
                            observation: observation,
                        } 

        setTimeout(function () {
            axios.put('/dash/bankslip/bankslip/' + bankslip.bankslip_key, payload).then(response => {
                dispatchDialogState({type:"send_request_success"})
              }).catch(error => { 
                if ((error.response || {}).status === 403) dispatchDialogState({type: "send_request_failure_403"})
                else if ((error.response || {}).status === 409) dispatchDialogState({type: "send_request_failure_409"})  
                else dispatchDialogState({type: "send_request_failure"})    
              });
        }, 1000);
    }

    if (dialogState.finished && dialogState.isLoading){
        return (
            <Dialog 
                open={open} 
                onClose={() => history.push("/bankslips-inbox")} 
                keepMounted={false}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle style={{paddingBottom: "0px"}} className={["blueText", "normalMediumSize"].join(" ")}>Salvar Histórico de Fraude</DialogTitle>
                <DialogContent>
                    <div style={{display:"flex", width: "450px", height:"310px"}}>
                        <CircularProgress style={{margin:"auto"}} />
                    </div>
                </DialogContent>
            </Dialog>

        )
    }
    else if (dialogState.finished){
        let confirmationData = {    
            message: dialogState.message,
            success: !dialogState.isError
        }
        return (
            <Dialog 
                open={open} 
                onClose={() => history.push("/bankslips-inbox")} 
                keepMounted={false}
                disableBackdropClick
            >
                <DialogTitle style={{paddingBottom: "0px"}} className={["blueText", "normalMediumSize"].join(" ")}>Confirmação de Revisão</DialogTitle>
                <DialogContent>
                    <div style={{display:"flex", flexDirection:"column", width: "450px", height:"310px"}}>
                        <ConfirmationContent onClose={() => history.push("/bankslips-inbox")} data={confirmationData} />
                    </div>
                </DialogContent>
            </Dialog>
        )

    }
    return (
        <Dialog 
        open={open} 
        onClose={onClose} 
        keepMounted={false}
        >
            <DialogTitle style={{paddingBottom: "0px"}} className={["blueText", "normalMediumSize"].join(" ")}>Adicionar histórico de fraude</DialogTitle>
            <DialogContent>
                <div style={{display: "flex", flexDirection:"column", width: "450px", height:"350px"}}>
                    <TextField
                        error={!observation}
                        style={{display:"flex", alignItems:"center"}}
                        fullWidth
                        multiline
                        variant="outlined"
                        margin="dense"
                        rows="10"
                        value={observation}
                        onChange={handleObservationBoxChange}
                        label="Observação"
                        required
                    />
                    <div style={{marginBottom: "0px"}}>
                    <p className={["blueText", "normalMediumSize"].join(" ")}>Anexo (Tamanho máximo permitido: 1MB)</p>
                    <FileUploadToBase64 onDone={ getFiles.bind(this) } accept=".jpg, .png,.pdf"/>
                    </div>
                    <div className="internalCardContainer" style={{display:"flex", justifyContent:"center"}}>
                    {observation ? 
                        <div 
                            className={["button", "approved", "normalText", "normalMediumSize"].join(" ")}
                            style={{padding:"5px 30px"}}
                            onClick={handleFinish}
                        >
                            Salvar
                        </div>
                        :
                        <div 
                            className={["button", "disabled", "standard", "normalText", "normalMediumSize"].join(" ")}
                            style={{padding:"5px 30px"}}
                        >
                            Salvar
                        </div>}
                        <div 
                            className={["button", "cancel", "normalText", "normalMediumSize"].join(" ")}
                            style={{padding:"5px 30px"}}
                            onClick={onClose}
                        >
                            Cancelar 
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default FraudDialog;