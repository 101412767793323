import React from "react";
import { useTranslation } from "react-i18next";
import ExportCSV from "../../../Utils//ExportCSV/ExportCSV";
import Card from "@material-ui/core/Card";
import FilterEntry from "./FilterEntry";

function ReportItem(props) {
    const {t} = useTranslation()
    let {reportData, reportData:{report_events} } = props
    let applied_filters = Object.keys(reportData.applied_filters||{}).map((filter, index) => (
        <FilterEntry
            key={index}
            name={filter}
            data={reportData.applied_filters[filter]}
        />
    ))

    const last_index = report_events.length - 1
    const report_date = reportData.report_events[last_index].event_date
    
    return (
        <Card className={["listCard","collapse"].join(" ")} >
            <div style={{display: 'grid',  gridTemplateColumns: '33% 33% 33%'}}>
                <div className={["listCardItem", "normalText", "subtitleSize"].join(" ")}>
                    {reportData.report_template}
                </div>
                <div className={["listCardItem", "normalText", "subtitleSize", "noWrap"].join(" ")} >
                    {t("date_time_format", {date_time: report_date})}
                </div>
                <div className={["listCardItem", "normalText", "normalMediumSize", "bold"].join(" ")}>
                     <ExportCSV
                        pageParams={{}} 
                        csv_endpoint= {"".concat("/dash/report/report/", reportData.report_key, "/file")}
                        file_name = {reportData.report_template}
                        is_able={reportData.report_status === "available" ? true : false}/>
                </div>
            </div>
            <div>
                <div style={{padding: "8px", marginTop:"10px"}} className={["normalMediumSize", "bold", "blueText"].join(" ")}>
                    {t("applied_filters")}
                </div>
                { applied_filters.length > 0 ?
                    <div style={{display: 'grid',  gridTemplateColumns: '25% 25% 25% 25%'}}>
                        {applied_filters}
                    </div> :
                <div className={["labelText", "normalMediumSize", "center"].join(" ")} style={{margin:"auto", textAlign: "center"}}>
                    {t("no_filters_applied")}
                </div>}
            </div>
        </Card>
    )
}

export default ReportItem;