import React, { useState, useReducer, useCallback, useContext } from "react";
import {
  dataFetchReducer
} from "../../../Utils/requestUtils";
import Card from "@material-ui/core/Card";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import ErrorBoundary from "../../../Utils/ErrorBoundary";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import KeyCard from "../KeyCard/KeyCard";
import TokenCard from "../TokenCard/TokenCard";
import { CircularProgress } from "@material-ui/core";
import DESCRIPTIONS from "../../utils/utilsDescriptions";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import axios from "axios";
import KeyActionsDialog from "../KeyActionsDialog/KeyActionsDialog";
import { useTranslation } from "react-i18next";
import AuthContext from "../../../../context/auth-context";
import DeleteWebhookDialog from "../DeleteWebhookDialog/DeleteWebhookDialog";

const useStyles = makeStyles((theme) => ({
  subtitle: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "25px",
    color: "#0b1f82",
  },
  cardTitle: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "25px",
    color: "#0b1f82",
  },
  container: {
    padding: "8px"
  },
  customCard: {
    padding: "10px 15px",
    margin: "10px 0px 10px 0px",
    display: "flex",
    alignContent: "space-evenly",
    backgroundColor: "#F3F3F3"
  },
  link: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: "15px",
    lineHeight: "22px",
    color: "#141414",
  },
  label: {
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#141414",
  },
  data: {
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#6F6F6F",
  }
}))

function ServiceCard(props){
  let { user_data } = useContext(AuthContext);
  const { t } = useTranslation();
  const classes = useStyles();
  useTheme();

  const [expandedKeyCard, setKeyCardExpanded] = useState(false);
  const [openKeyActionDialog, setOpenKeyActionDialog] = useState({'open': false, 'action': 'none', 'key': 'none'})
  const [expandedTokenCard, setTokenCardExpanded] = useState(false);
  const [openTokenActionDialog, setOpenTokenActionDialog] = useState({'open': false, 'action': 'none', 'key': 'none'})
  const [isWebhookVisible, setIsWebhookVisible] = useState(false);
  const [isEditingWebhook, setIsEditingWebhook] = useState(false);
  const [webhookUrlValue, setWebhookUrlValue] = useState('');
  const [signatureKeyValue, setSignatureKeyValue] = useState('');
  const [expandedServiceOptions, setServiceOptionsExpanded] = useState(false);
  const [openDeleteWebhookDialog, setOpenDeleteWebhookDialog] = useState(false);

  const handleKeyExpand = (event) => {
    event.stopPropagation();
    setKeyCardExpanded(!expandedKeyCard);
    if (!expandedKeyCard){
      fetchKeyData();
    }
  };

  const handleTokenExpand = (event) => {
    event.stopPropagation();
    setTokenCardExpanded(!expandedTokenCard);
    if (!expandedTokenCard) {
      fetchTokenData();
    }
  };

  const handleServiceOptionsExpand = (event) => {
    event.stopPropagation();
    setServiceOptionsExpanded(!expandedServiceOptions);
    if (!expandedServiceOptions && DESCRIPTIONS[props.service].use_webhook){
      fetchWebhookData();
    } else if (DESCRIPTIONS[props.service].use_webhook) {
      setIsWebhookVisible(false);
      setIsEditingWebhook(false);
    }
  };

  const [pageKeyState, dispatchPageKeyState] = useReducer(dataFetchReducer, {
    fetchedData: null,
    isLoading: true,
    isError: false,
  });

  const [pageTokenState, dispatchPageTokenState] = useReducer(dataFetchReducer, {
    fetchedData: null,
    isLoading: true,
    isError: false,
  });

  const [pageWebhookState, dispatchPageWebhookState] = useReducer(dataFetchReducer, {
    fetchedData: null,
    isLoading: true,
    isError: false,
  });

  const doKeyRequest = useCallback(
    (payload) => {
      let headers = {};
      if (user_data.business_group_key) {
        headers = {
          "COMPANY-KEY": props.company_key
        }
      }
      dispatchPageKeyState({type: "data_fetch_init"})
      axios.get(`/dash/api_key/${props.service}`, {
        params: payload,
        headers: headers
      }).then(response=>{
        dispatchPageKeyState({
          type: "data_fetch_success",
          payload: response.data
        })
      }).catch(error => {
        if ((error.response || {}).status === 403) dispatchPageKeyState({type: "data_fetch_failure_403"})
        else if ((error.response || {}).status === 404) dispatchPageKeyState({type: "data_fetch_failure_404"})
        else dispatchPageKeyState({type: "data_fetch_failure"})
      })
    },[props.company_key, props.service, user_data.business_group_key]
  )

  const doTokenRequest = useCallback(
    (payload) => {
      let headers = {};
      if (user_data.business_group_key) {
        headers = {
          "COMPANY-KEY": props.company_key
        }
      }
      dispatchPageTokenState({type: "data_fetch_init"})
      axios.get(`/dash/mobile_token/${props.service}`, {
        params: payload,
        headers: headers
      }).then(response=>{
        dispatchPageTokenState({
          type: "data_fetch_success",
          payload: response.data
        })
      }).catch(error => {
        if ((error.response || {}).status === 403) dispatchPageTokenState({type: "data_fetch_failure_403"})
        else if ((error.response || {}).status === 404) dispatchPageTokenState({type: "data_fetch_failure_404"})
        else dispatchPageTokenState({type: "data_fetch_failure"})
      })
    },[props.company_key, props.service, user_data.business_group_key]
  )

  const doWebhookRequest = useCallback(
    (payload) => {
      let headers = {};
      if (user_data.business_group_key) {
        headers = {
          "COMPANY-KEY": props.company_key
        }
      }
      dispatchPageWebhookState({type: "data_fetch_init"})
      axios.get(`/dash/webhook/${props.service}`, {
        params: payload,
        headers: headers
      }).then(response=>{
        dispatchPageWebhookState({
          type: "data_fetch_success",
          payload: response.data
        })
        if (response.data.webhook_url || response.data.signature_key){
          setWebhookUrlValue(response.data.webhook_url != null  ? response.data.webhook_url : '')
          setSignatureKeyValue(response.data.signature_key != null  ? response.data.signature_key : '')
        }
      }).catch(error => {
        if ((error.response || {}).status === 403) dispatchPageWebhookState({type: "data_fetch_failure_403"})
        else if ((error.response || {}).status === 404) dispatchPageWebhookState({type: "data_fetch_failure_404"})
        else dispatchPageWebhookState({type: "data_fetch_failure"})
      })
    },[props.company_key, props.service, user_data.business_group_key]
  )

  const doWebhookSaveRequest = useCallback(
    (payload) => {
      let body = {};
      if (webhookUrlValue) {
        body.webhook_url = webhookUrlValue
      };
      if (signatureKeyValue) {
        body.signature_key = signatureKeyValue
      };
      let headers = {};
      if (user_data.business_group_key) {
        headers = {
          "COMPANY-KEY": props.company_key
        }
      }
      dispatchPageWebhookState({type: "data_fetch_init"})
      axios.put(`/dash/webhook/${props.service}`,
        body, {
        params: payload,
        headers: headers,
      }).then(response=>{
        dispatchPageWebhookState({
          type: "data_fetch_success",
          payload: {"webhook_url": webhookUrlValue, "signature_key": signatureKeyValue}
        })
      }).catch(error => {
        if ((error.response || {}).status === 403) dispatchPageWebhookState({type: "data_fetch_failure_403"})
        else if ((error.response || {}).status === 404) dispatchPageWebhookState({type: "data_fetch_failure_404"})
        else dispatchPageWebhookState({type: "data_fetch_failure"})
      })
    },[props.company_key, props.service, user_data.business_group_key, signatureKeyValue, webhookUrlValue]
  )

  const fetchKeyData = () => {
    const payload = {}
    if (props.region === 'sa-east-1') payload.region = 'sa-east-1'
    const timer_ = setTimeout(() => {
      doKeyRequest(payload)
    }, 300);
    return () => {
      clearTimeout(timer_)
    }
  }

  const fetchTokenData = () => {
    const payload = {}
    const timer_ = setTimeout(() => {
      doTokenRequest(payload)
    }, 300);
    return () => {
      clearTimeout(timer_)
    }
  }

  const fetchWebhookData = () => {
    const payload = {}
    if (props.region === 'sa-east-1') payload.region = 'sa-east-1'
    const timer_ = setTimeout(() => {
      doWebhookRequest(payload)
    }, 300);
    return () => {
      clearTimeout(timer_)
    }
  }

  const saveWebhookData = () => {
    const payload = {}
    if (props.region === 'sa-east-1') payload.region = 'sa-east-1'
    const timer_ = setTimeout(() => {
      doWebhookSaveRequest(payload)
    }, 300);
    return () => {
      clearTimeout(timer_)
    }
  }

  const contentKeyContainer = (dataList) => {
    dataList.sort((a, b) => {
      if (a.status === "active" && b.status !== "active") {
        return -1;
      }
      if (a.status !== "active" && b.status === "active") {
        return 1;
      }
      return new Date(b.created_at) - new Date(a.created_at);
    });

    let nr_of_cards = dataList.length
    let cards = dataList.map((apikey) => (
      <ErrorBoundary key={apikey.api_key}>
        <KeyCard
          apikey={apikey}
          setOpenKeyActionDialog={setOpenKeyActionDialog}
          service={props.service}
        />
      </ErrorBoundary>
    ))

    if (nr_of_cards === 0) {
      return (
        <div className='emptyContainer'>
          <p className={["blueText", "subtitleSize"].join(" ")}>
            {t("no_key_registered")}
          </p>
        </div>
      )
    }
    else {
      return (
        <div className='cardContainer'>
          {cards}
        </div>
      )
    }
  }

  const contentTokenContainer = (dataList) => {
    dataList.sort((a, b) => {
      if (a.status === "active" && b.status !== "active") {
        return -1;
      }
      if (a.status !== "active" && b.status === "active") {
        return 1;
      }
      return new Date(b.created_at) - new Date(a.created_at);
    });

    let nr_of_cards = dataList.length
    let cards = dataList.map((mobile_token) => (
      <ErrorBoundary key={mobile_token.mobile_token}>
        <TokenCard
          mobile_token={mobile_token}
          setOpenTokenActionDialog={setOpenTokenActionDialog}
          service={props.service}
        />
      </ErrorBoundary>
    ))

    if (nr_of_cards === 0) {
      return (
        <div className='emptyContainer'>
          <p className={["blueText", "subtitleSize"].join(" ")}>
            {t("no_key_registered")}
          </p>
        </div>
      )
    }
    else {
      return (
        <div className='cardContainer'>
          {cards}
        </div>
      )
    }
  }

  const validateInput = (input) => {
    if (input){
      return input.length >= 2 && input.length < 128;
    }
    return false;
  };

  const handleEdit = () => {
    setIsEditingWebhook(!isEditingWebhook);
  }

  const handleSave = () => {
      saveWebhookData();
      setIsWebhookVisible(false);
      setIsEditingWebhook(!isEditingWebhook);
  }

  const handleClose = () => {
    if (pageWebhookState.fetchedData) {
      setWebhookUrlValue(pageWebhookState.fetchedData.webhook_url);
      setSignatureKeyValue(pageWebhookState.fetchedData.signature_key);
    } else {
      setWebhookUrlValue('');
      setSignatureKeyValue('');
    }
    setIsWebhookVisible(false);
    setIsEditingWebhook(!isEditingWebhook);
  }

  const contentContainer = () => {
    return (
      <div className={["listCard", "collapse"].join(" ")} /*style={{border:0, boxShadow: "rgba(0, 0, 0, 0.24) 0px 1px 3px"}}*/>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <p className={classes.subtitle} style={{ flexGrow: 0, width: "100px", textAlign: "left" }}>Webhook</p>
          <div style={{ display: 'flex', flexGrow: 1, marginRight: "20px" }}>
            <input
              type="text"
              placeholder="Webhook URL"
              value={webhookUrlValue ? webhookUrlValue : ''}
              onChange={(e) => setWebhookUrlValue(e.target.value)}
              disabled={!isEditingWebhook}
              label="url"
              style={{
                flexGrow: 1,
                margin: "10px",
                padding: '10px 20px',
                backgroundColor: isEditingWebhook ? '#fff' : '#f0f0f0',
                border: '1px solid #ccc',
                borderRadius: "5px",
                color: isEditingWebhook ? '#000' : '#888',
                cursor: isEditingWebhook ? 'text' : 'not-allowed',
              }}
              className={["normalText", "normalMediumSize"].join(" ")}
            />
            <div style={{ position: 'relative', display: 'flex', alignItems: 'center', flexGrow: 1, margin: '10px' }}>
              <input
                type={isWebhookVisible ? "text" : "password"}
                placeholder="Webhook Key"
                value={signatureKeyValue ? signatureKeyValue : ''}
                onChange={(e) => setSignatureKeyValue(e.target.value)}
                disabled={!isEditingWebhook}
                style={{
                  flexGrow: 1,
                  padding: '10px 40px ',
                  backgroundColor: isEditingWebhook ? '#fff' : '#f0f0f0',
                  border: '1px solid #ccc',
                  borderRadius: "5px",
                  color: isEditingWebhook ? '#000' : '#888',
                  cursor: isEditingWebhook ? 'text' : 'not-allowed',
                  boxSizing: 'border-box'
                }}
                className={["normalText", "normalMediumSize"].join(" ")}
              />
              <IconButton
                size="small"
                disabled={!isEditingWebhook}
                onClick={() => setIsWebhookVisible(!isWebhookVisible)}
                style={{
                  position: 'absolute',
                  right: 10,
                  top: '50%',
                  transform: 'translateY(-50%)',
                  padding: 0
                }}
              >
                {isWebhookVisible ? <VisibilityIcon fontSize="small" /> : <VisibilityOffIcon fontSize="small" />}
              </IconButton>
            </div>
          </div>
          <div style={{ display: "flex", marginLeft: "auto", flexShrink: 0 }}>
            {isEditingWebhook ? (
              <>
                <button
                  style={{
                    backgroundColor: validateInput(webhookUrlValue) && validateInput(signatureKeyValue) ? '#73C580' : '#AAA799',
                    color: '#fff',
                    border: 'none',
                    cursor: "pointer",
                    padding: '5px 20px',
                    margin: "5px",
                    width: "80px"
                  }}
                  className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                  onClick={handleSave}
                  disabled={!validateInput(webhookUrlValue) || !validateInput(signatureKeyValue)}>
                  <SaveIcon />
                </button>
                <button
                  style={{
                    backgroundColor: '#AAA799',
                    color: '#fff',
                    border: 'none',
                    cursor: "pointer",
                    padding: '5px 20px',
                    margin: "5px",
                    width: "80px"
                  }}
                  className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                  onClick={handleClose}>
                  <CancelIcon />
                </button>
              </>
            ) : (
              <>
                <button
                  style={{
                    backgroundColor: '#0B1F82',
                    color: '#fff',
                    border: 'none',
                    cursor: "pointer",
                    padding: '5px 20px',
                    margin: "5px",
                    width: "80px"
                  }}
                  className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                  onClick={handleEdit}>
                  <EditIcon />
                </button>
                {/* <button
                  style={{
                    backgroundColor: '#d96159',
                    color: '#fff',
                    border: 'none',
                    cursor: "pointer",
                    padding: '5px 20px',
                    margin: "5px",
                    width: "80px"
                  }}
                  className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                  onClick={() => { setOpenDeleteWebhookDialog(true) }}>
                  <DeleteIcon />
                </button> */}
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  const closeKeyActionsDialog = () => {
    setOpenKeyActionDialog({'open': false, 'action': openKeyActionDialog.action, 'key': openKeyActionDialog.key});
    dispatchPageKeyState({type: "data_fetch_init"});
    fetchKeyData();
  }

  const closeTokenActionsDialog = () => {
    setOpenTokenActionDialog({'open': false, 'action': openTokenActionDialog.action, 'key': openTokenActionDialog.key});
    dispatchPageTokenState({type: "data_fetch_init"});
    fetchTokenData();
  }

  const closeWebhookActionsDialog = () => {
    setOpenDeleteWebhookDialog(false)
    dispatchPageWebhookState({type: "data_fetch_init"});
    fetchWebhookData();
  }

  return (
    <div className={["listCard", "collapse"].join(" ")}>
      <div style={{display:"flex", justifyContent: "space-between"}}>
        <p className={classes.subtitle}>{t(props.service)}</p>
        <a href={DESCRIPTIONS[props.service].docs} target="_blank" rel="noopener noreferrer" className={classes.link} style={{display: "flex", alignItems: "center", flexDirection: "row", gap: 4}}>
          {t("documentation")}
          <HelpOutlineIcon  style={{width:"18px", height:"18px"}}/>
        </a>
      </div>
      <div style={{display:"flex", justifyContent: "space-between"}}>
        <p className={classes.label}>{t(DESCRIPTIONS[props.service].description)}</p>
        <IconButton onClick={handleServiceOptionsExpand} style={{width:"50px", height:"50px", alignSelf:"center"}}>
          {expandedServiceOptions ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </IconButton>
      </div>
      <Collapse in={expandedServiceOptions} timeout="auto" unmountOnExit>
        <Card className={["listCard","collapse"].join(" ")}>
          <div style={{display:"flex", justifyContent: "space-between"}}>
            <div
              className={["listCardItem", classes.cardTitle, "normalSmallSize"].join(" ")}
              style={{flex:"=1 1 100px", textAlign:"center"}}
            >
              {t("access_keys_title")}
            </div>
            <div
              className="listCardItem"
              style={{flex:"0 1 50px", textAlign:"center"}}
            >
              <IconButton onClick={handleKeyExpand}>
                {expandedKeyCard ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </IconButton>
            </div>
          </div>

          <Collapse in={expandedKeyCard} timeout="auto" unmountOnExit>
            <div className={["internalCollapse"].join(" ")}>
            <div style={{display:"flex", justifyContent: "space-between"}}>
              <div
                className={["listCardItem", "bold", "normalSmallSize"].join(" ")}
                style={{flex:"0 1 50px", textAlign:"center"}}
              >
              </div>
              <div
                className={["listCardItem", "bold", "normalSmallSize"].join(" ")}
                style={{flex:"1 1 80px", textAlign:"center"}}
              >
                {t("access_key")}
              </div>
              <div
                className={["listCardItem", "bold", "normalSmallSize"].join(" ")}
                style={{flex:"1 1 100px", textAlign:"center", marginLeft: "3%"}}
              >
                Status
              </div>
              <div
                className={["listCardItem", "bold", "normalSmallSize"].join(" ")}
                style={{flex:"1 1 100px", textAlign:"center",}}
              >
                {t("age")}
              </div>
                <div
                  className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                  style={{flex:"1 1 80px", textAlign:"center"}}
                >
                  <div
                      style={{ padding: "5px 15px", margin:"5px", width:"150px", backgroundColor: "#0b1f82" }}
                      className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                      onClick={() => setOpenKeyActionDialog({'open': true, 'action': 'create_new_key', 'key': 'none'})}
                    >
                      {t("new_key")}
                  </div>
                </div>
              </div>
              {pageKeyState.isLoading  && (
                <div className="circularProgressCenter">
                  <CircularProgress />
                </div>
              )}
              {pageKeyState.fetchedData && (
                <div>{contentKeyContainer(pageKeyState.fetchedData)}</div>
              )}
              {pageKeyState.isError && (
                <div style={{height: "55vh", display:"flex"}}>
                  <p className={["blueText", "titleSize"].join(" ")} style={{margin:"auto", textAlign:"center"}}>
                      {pageKeyState.errorMessage}
                  </p>
                </div>
              )}
              <KeyActionsDialog
                    open={openKeyActionDialog.open}
                    openKeyActionDialog = { openKeyActionDialog }
                    onClose={closeKeyActionsDialog}
                    service={props.service}
                    region={props.region}
                    company_key={props.company_key}
                    type='api_key'
                />
            </div>
          </Collapse>
        </Card>
        {["ocr", "device_scan", "face_recognition"].includes(props.service) && (
          <Card className={["listCard","collapse"].join(" ")}>
            <div style={{display:"flex", justifyContent: "space-between"}}>
              <div
                className={["listCardItem", classes.cardTitle, "normalSmallSize"].join(" ")}
                style={{flex:"=1 1 100px", textAlign:"center"}}
              >
                {t("mobile_tokens_title")}
              </div>
              <div
                className="listCardItem"
                style={{flex:"0 1 50px", textAlign:"center"}}
              >
                <IconButton onClick={handleTokenExpand}>
                  {expandedTokenCard ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </IconButton>
              </div>
            </div>

            <Collapse in={expandedTokenCard} timeout="auto" unmountOnExit>
              <div className={["internalCollapse"].join(" ")}>
              <div style={{display:"flex", justifyContent: "space-between"}}>
                <div
                  className={["listCardItem", "bold", "normalSmallSize"].join(" ")}
                  style={{flex:"0 1 50px", textAlign:"center"}}
                >
                </div>
                <div
                  className={["listCardItem", "bold", "normalSmallSize"].join(" ")}
                  style={{flex:"1 1 80px", textAlign:"center"}}
                >
                  {t("access_key")}
                </div>
                <div
                  className={["listCardItem", "bold", "normalSmallSize"].join(" ")}
                  style={{flex:"1 1 100px", textAlign:"center", marginLeft: "3%"}}
                >
                  Status
                </div>
                <div
                  className={["listCardItem", "bold", "normalSmallSize"].join(" ")}
                  style={{flex:"1 1 100px", textAlign:"center",}}
                >
                  {t("age")}
                </div>
                  <div
                    className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                    style={{flex:"1 1 80px", textAlign:"center"}}
                  >
                    <div
                        style={{ padding: "5px 15px", margin:"5px", width:"150px", backgroundColor: "#0b1f82" }}
                        className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                        onClick={() => setOpenTokenActionDialog({'open': true, 'action': 'create_new_key', 'key': 'none'})}
                      >
                        {t("new_key")}
                    </div>
                  </div>
                </div>
                {pageTokenState.isLoading  && (
                  <div className="circularProgressCenter">
                    <CircularProgress />
                  </div>
                )}
                {pageTokenState.fetchedData && (
                  <div>{contentTokenContainer(pageTokenState.fetchedData)}</div>
                )}
                {pageTokenState.isError && (
                  <div style={{height: "55vh", display:"flex"}}>
                    <p className={["blueText", "titleSize"].join(" ")} style={{margin:"auto", textAlign:"center"}}>
                        {pageTokenState.errorMessage}
                    </p>
                  </div>
                )}
                <KeyActionsDialog
                    open={openTokenActionDialog.open}
                    openKeyActionDialog = { openTokenActionDialog }
                    onClose={closeTokenActionsDialog}
                    service={props.service}
                    region={props.region}
                    company_key={props.company_key}
                    type='mobile_token'
                />
              </div>
            </Collapse>
          </Card>
        )}
        {DESCRIPTIONS[props.service].use_webhook && (
          <>
            {pageWebhookState.isLoading  && (
              <div className="circularProgressCenter">
                <CircularProgress />
              </div>
            )}
            {pageWebhookState.fetchedData && (
              <div>{<div>{contentContainer(pageWebhookState.fetchedData)}</div>}</div>
            )}
            {pageWebhookState.isError && (
              <div style={{height: "55vh", display:"flex"}}>
                <p className={["blueText", "titleSize"].join(" ")} style={{margin:"auto", textAlign:"center"}}>
                    {pageWebhookState.errorMessage}
                </p>
              </div>
            )}
            <DeleteWebhookDialog
              title={"deactivate_webhook"}
              open={openDeleteWebhookDialog}
              onClose={closeWebhookActionsDialog}
              service={props.service}
              region={props.region}
              company_key={props.company_key}
            />
          </>
        )}
      </Collapse>
    </div>
  )
}

export default ServiceCard
