import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next"
import { GetAlertsComponent, setObjectByPath, validateConstantValues, translateType } from "./utils"
import _ from 'lodash'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Tooltip from "@material-ui/core/Tooltip"
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import HelpIcon from "@material-ui/icons/Help"
import TimeDeltaTextField from './Components/TimeDeltaTextField'

let this_box_type = "timedelta"



export function ConstantTimedeltaBox(props) {
    const { t } = useTranslation()
    let { node } = props
    let { read_only } = props
    let { handleConfigurationOpen } = props
    let { handleNodeRemove } = props

    function getVisualPattern(value) {
        let yearSplit = value.split("Y")
        let yearValue = ""
        if (yearSplit.length > 1) {
            yearValue = yearSplit[0].replace(/\D/g, "")
            yearValue = /^0*$/.test(yearValue) ? "" : yearValue + t(" Anos, ")
            value = yearSplit[1]
        }
        let monthSplit = value.split("M")
        let monthValue = ""
        if (monthSplit.length > 1) {
            monthValue = monthSplit[0].replace(/\D/g, "")
            monthValue = /^0*$/.test(monthValue) ? "" : monthValue + t(" Meses, ")
            value = monthSplit.slice(1).join('M');
        }
        let daySplit = value.split("D")
        let dayValue = ""
        if (daySplit.length > 1) {
            dayValue = daySplit[0].replace(/\D/g, "")
            dayValue = /^0*$/.test(dayValue) ? "" : dayValue + t(" Dias, ")
            value = daySplit[1]
        }
        let hourSplit = value.split("H")
        let hourValue = ""
        if (hourSplit.length > 1) {
            hourValue = hourSplit[0].replace(/\D/g, "")
            hourValue = /^0*$/.test(hourValue) ? "" : hourValue + t(" Horas, ")
            value = hourSplit[1]
        }
        let minuteSplit = value.split("M")
        let minuteValue = ""
        if (minuteSplit.length > 1) {
            minuteValue = minuteSplit[0].replace(/\D/g, "")
            minuteValue = /^0*$/.test(minuteValue) ? "" : minuteValue + t(" Minutos, ")
            value = minuteSplit.slice(1).join('M');
        }
        let secondSplit = value.split("S")
        let secondValue = ""
        if (secondSplit.length > 1) {
            secondValue = secondSplit[0].replace(/\D/g, "")
            secondValue = /^0*$/.test(secondValue) ? "" : secondValue + t(" Segundos")
            value = secondSplit[1]
        }
        return yearValue + monthValue + dayValue + hourValue + minuteValue + secondValue
    }

    return (
        <div>
            {!read_only ?
                <div style={{display:"flex", alignItems: "center"}}>
                    <div style={{ display: "flex", width: "100%", cursor: "pointer", margin: "auto 0px" }} onClick={() => handleConfigurationOpen(node)}>
                        {t(getVisualPattern(node.node.properties.value))}
                        {(node.node.alerts || []).length > 0 ?
                            <GetAlertsComponent alerts={node.node.alerts} /> : null}
                    </div>
                    <IconButton style={{width: 32, height: 32}} aria-label="duplicate" onClick={() => props.handleNodeDuplication(node)}>
                        <ContentCopyIcon style={{width: 20, height: 20}} />
                    </IconButton>
                    <IconButton style={{width: 32, height: 32}} aria-label="close" onClick={() => handleNodeRemove(node)}>
                        <CloseIcon style={{width: 20, height: 20}} />
                    </IconButton>
                </div>
                :
                <div style={{ display: "flex" }}>
                    <div style={{ display: "flex", width: "100%", cursor: "pointer", margin: "auto 0px" }} onClick={() => handleConfigurationOpen(node)}>
                        {t(getVisualPattern(node.node.properties.value))}
                    </div>
                </div>
            }
        </div>
    )
}

export function ConstantTimedeltaConfiguration(props) {
    const { t } = useTranslation()
    let { node } = props
    let { read_only } = props
    let { handleNodeChange } = props
    let { handleConfigurationClose } = props

    const [nodeState, setNodeState] = useState(node)

    useEffect(() => {
        setNodeState(node)
    }, [node])


    const handleNodeStateChange = (obj_key, value) => {
        let new_node = _.cloneDeep(nodeState)
        if (value === "P") value = ""
        setObjectByPath(new_node.node, obj_key, value)
        setNodeState(new_node)
    }

    const handleSave = () => {
        handleConfigurationClose()
        handleNodeChange(nodeState)
    }

    return (
        <div style={{ flexGrow: "1", padding: "20px", display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex" }}>
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>{t(node.node.description)}</span>
                <Tooltip title={<div className="tooltipText"><ul>{node.node.helperText.map((help, index) => (<li key={index}>{t(help)}</li>))}</ul></div>}>
                    <HelpIcon style={{ fontSize: "14px", display: "flex", margin: "auto 5px" }} />
                </Tooltip>
            </div>
            <TimeDeltaTextField
                value={nodeState.node.properties.value}
                error={!validateConstantValues(this_box_type, nodeState.node.properties.value)}
                valueChangeFunction={(value) => handleNodeStateChange("properties.value", value)}
                label={null}
                read_only={read_only}
            />
            <div style={{ display: "flex", margin: "auto auto 5px auto" }}>
                <div
                    className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")}
                    style={{ width: "120px" }}
                    onClick={handleConfigurationClose}
                >
                    {read_only ? "X" : t("discard")}
                </div>
                {!read_only &&
                    <div
                        className={
                            validateConstantValues(this_box_type, nodeState.node.properties.value)
                                ?
                                ["button", "standard", "normalText", "normalMediumSize"].join(" ")
                                :
                                ["button", "standard", "normalText", "normalMediumSize", "disabled"].join(" ")}
                        style={{ width: "120px" }}
                        onClick={validateConstantValues(this_box_type, nodeState.node.properties.value) ? handleSave : null}
                    >
                        {t("save")}
                    </div>}
            </div>
        </div>
    )
}

export class ConstantTimedeltaBoxClass {
    constructor(rulesContext) {
        this.rulesContext = rulesContext
    }
    validate(node) {
        let put_error = false
        let alerts = []
        //Validate if node has no children
        if ((node.node.children || []).length !== 0) {
            put_error = true
            alerts.push("O nó de " + translateType(this_box_type) + " não pode ter filhos")
        }
        //Validate value is not lenth 0
        if (!validateConstantValues(this_box_type, node.node.properties.value)) {
            put_error = true
            alerts.push("Este nó deve ter um período de tempo válido")
        }
        //Validate if parent is valid
        if (node.parentNode != null) {
            if (node.parentNode.on_error) {
                put_error = true
                alerts.push("O nó superior está inválido")
            }
        }
        return { validated: !put_error, alerts: alerts }
    }

    getType(node) {
        return this_box_type
    }
}