import React from 'react'
import {getStatus, getStatusColor} from "../../../utils/utils"

function AnalysisStatus (props) {

    const { name, status } = props

    return (
        <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
            <div 
                className={["normalText", "normalMediumSize"].join(" ")} 
            >
                {`${name}:`}
            </div>
            <div 
                className={status==="not_applied"?"labelText normalMediumSize":"normalText normalMediumSize"} 
                style={{backgroundColor: getStatusColor(status), margin: "5px 0px", padding:"5px", borderRadius:"4px"}}
            >
                {getStatus(status)}
            </div>
        </div>
    )
}

export default AnalysisStatus