import React, {useContext} from "react";
import FilterBox from "../../../Utils/FilterBox/FilterBox"
import CompaniesContext from "../../../../context/companies-context"
import {lockFilterTypes} from "../../utils/utilsLock"


function Filter(props) {

    let companies = useContext(CompaniesContext).onboarding_list
	
    const { setPageParams } = props
    const { initialFilters } = props

    //TODO JUST ADD IF REQUEST COMPLETES BEFORE LOCKSINBOX FIRST MOUNT
    //Maybe if you clean storage inside this if block it will work as expected
    if (companies){
        let enumList = companies.business_group_data.map(company =>{
            return [company.company_name, company.company_key]
        })
        lockFilterTypes['company_key'] = {
            type: "enum",
            enumList: [
                ["Nenhum", ""],
                ...enumList
            ],  
            disabled: false,
            validation:false,
            description: "Companhia"
        }
    }

    return (
        <FilterBox 
            setPageParams={setPageParams}
            filterTypes={lockFilterTypes}
            local_storage_object_preffix={props.local_storage_object_preffix}
            initialFilters={initialFilters}
        />
    )

}

export default Filter