import React from "react";
import DataField from "../../../utils/DataField";
import { titleCase, formatAddress, formatListOfObjects } from "../../../utils/utils"
import MapIcon from "@material-ui/icons/Map";
import { useTranslation } from "react-i18next";

function BureauBasicDataCard (props) {

    const { registration_data } = props
    
    const { t } = useTranslation()

    return (
        <div className="analysisCard" style={{height:"100%"}}>
            <div className={["blueText", "subtitleSize"].join(" ")}>{t("bureau_basic_data")}</div>
            <div className="internalCardContainer" style={{display: "flex", flexDirection: "column"}}>
                {(registration_data.name||{}).description&& 
                <DataField
                    title={t("name")}
                    label={titleCase(registration_data.name.description, "-")}
                    size={"normalMediumSize"}
                    titleNoWrap={false}
                />}
                {(registration_data.mother_name||{}).description&& 
                <DataField
                    title={t("mother_name")}
                    label={titleCase(registration_data.mother_name.description, "-")}
                    size={"normalMediumSize"}
                    titleNoWrap={false}
                />}
                {(registration_data.nationality||{}).description&& 
                <DataField
                    title={t("nationality")}
                    label={titleCase(registration_data.nationality.description, "-")}
                    size={"normalMediumSize"}
                    titleNoWrap={false}
                />}
                {(registration_data.birthdate||{}).description&& 
                <DataField
                    title={t("birthdate")}
                    label={t("birthdate_format", {birthdate: registration_data.birthdate.description })}
                    size={"normalMediumSize"}
                    titleNoWrap={false}
                />}
                {(registration_data.gender||{}).description&& 
                <DataField
                    title={t("gender")}
                    label={titleCase(t(registration_data.gender.description), "-")}
                    size={"normalMediumSize"}
                    titleNoWrap={false}
                />}
                {(registration_data.phone_number||[]).length>0&& 
                <DataField
                    title={t("phones")}
                    label={formatListOfObjects(registration_data.phone_number, "phones", t)}
                    size={"normalMediumSize"}
                    titleNoWrap={false}
                />}
                {(registration_data["e-mail"]||[]).length>0&& 
                <DataField
                    title={t("emails")}
                    label={formatListOfObjects(registration_data["e-mail"], "emails", t)}
                    size={"normalMediumSize"}
                    titleNoWrap={false}
                />}
                {(registration_data.address||[]).length>0&&
                <DataField
                    title={t("addresses")}
                    label={formatListOfObjects(registration_data.address,"addresses", t)}
                    size={"normalMediumSize"}
                    titleNoWrap={false}
                >
                    {registration_data.address.map((address)=>(<a
                        href={"https://www.google.com/maps/search/?api=1&query=" + 
                            formatAddress(address,t("-")).replace(/ /g, "%20").replace(/,/, '%2C')}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <MapIcon style={{verticalAlign: "middle", width: "20px", height:"20px",cursor:"pointer", color:"#777",margin: "auto 5px"}} />
                    </a>))}
                </DataField>}
            </div>
        </div>
    );
}

export default BureauBasicDataCard;



