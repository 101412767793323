import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {titleCase} from "../../../../utils/utils"

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
  },
  tableText: {
    fontFamily: "Open Sans",
    fontWeight: 'normal',
    fontSize: "12px",
    lineHeight: "16px",
    color: "#141414",
    textAlign: "center",
    margin: "10px",
    padding: "6px",
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: '#6F6F6F',
    fontSize: "14px",
    lineHeight: "19px",
    padding: "6px",
  },
  even: {
    background: "#F3F3F3",
  },
  odd: {
    background: "#FFFFFF",
  },
  circle: {
		height:'11px',
		borderRadius:'50%',
    width:'11px',
    margin: "auto 3px"
  },
  seeMoreLink: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "19px",
    color: "#0b1f82",
    cursor: "pointer",
    textAlign: "right",
    margin: "auto",
    "&:hover": {
      textDecoration: "underline",
    }, 
  }
}));

function CompaniesRelatedTable(props) {
  const classes = useStyles();
  useTheme();

  const { related_companies_data } = props
  
  const getRelationShipTypeTranslation = (relationship_types) => {
    let relationship_types_translated = []
    
      relationship_types.map((relationship_type)=> {
        switch(relationship_type){
          case "owner":
            relationship_types_translated.push("Proprietário")
            break
          case "employee":
            relationship_types_translated.push("Empregado")
            break
          case "partner":
            relationship_types_translated.push("Sócio")
            break
          case "legal_representative":
            relationship_types_translated.push("Representante Legal")
            break            
          default:
            relationship_types_translated.push(titleCase(relationship_type))
        }
        return relationship_types_translated
      })
      return relationship_types_translated.join(", ")

  }
   

  const getClass = (rowIndex) => {
    if (rowIndex % 2 === 0) {
      return classes.even;
    }
    return classes.odd;
  };

  return (
    <Paper className={classes.tableStyle}>
      <Table style={{ padding: "8px" }}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader}>Razão Social</TableCell>
            <TableCell className={classes.tableHeader}>Documento</TableCell>
            <TableCell className={classes.tableHeader}>Graus de Relação</TableCell>
            <TableCell className={classes.tableHeader}>Número de filiais</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {related_companies_data.map((each_related_companies,index) => (
            <TableRow
              key={index}
              className={getClass(index)}
            >
              <TableCell className={classes.tableText}>
                {each_related_companies.legal_name}
              </TableCell>
              <TableCell className={classes.tableText}>
                  {each_related_companies.document_number}
              </TableCell>
              <TableCell className={classes.tableText}>
                {getRelationShipTypeTranslation(each_related_companies.relationship_types)}
              </TableCell>
                <TableCell className={classes.tableText}>
              {(each_related_companies||{}).number_of_branches 
                    ? each_related_companies.number_of_branches
                    : " - "
                  }
                </TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper> 
  );
}

export default CompaniesRelatedTable;
