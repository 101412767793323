import React from "react";	
import NumberFormat from 'react-number-format';
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";



function TimeDeltaTextField (props){
    const { t } = useTranslation();
    const {value, error, valueChangeFunction, label, read_only} = props

    let fieldLabels = ["years", "months", "days", "hours", "minutes", "seconds"]

    const fromISOStringToList = (isoString) => {
        if (isoString === null) {
            isoString = ""
        }
        let dateSplit = isoString.split("T")[0]

        let yearSplit = dateSplit.split("Y")
        let yearValue = undefined
        if (yearSplit.length > 1){
            yearValue = yearSplit[0].replace(/\D/g, "");
            yearValue = /^0*$/.test(yearValue) ? undefined :  parseFloat(yearValue)
            dateSplit = yearSplit[1]
        }

        let monthSplit = dateSplit.split("M")
        let monthValue = undefined
        if (monthSplit.length > 1){
            monthValue = monthSplit[0].replace(/\D/g, "");
            monthValue = /^0*$/.test(monthValue) ? undefined :  parseFloat(monthValue)
            dateSplit = monthSplit[1]
        }

        let daySplit = dateSplit.split("D")
        let dayValue = undefined
        if (daySplit.length > 1){
            dayValue = daySplit[0].replace(/\D/g, "");
            dayValue = /^0*$/.test(dayValue) ? undefined :  parseFloat(dayValue)
            dateSplit = daySplit[1]
        }

        let timeSplit = isoString.split("T").length > 1 ? value.split("T")[1] : ""

        let hourSplit = timeSplit.split("H")
        let hourValue = undefined
        if (hourSplit.length > 1){
            hourValue = hourSplit[0].replace(/\D/g, "");
            hourValue = /^0*$/.test(hourValue) ? undefined :  parseFloat(hourValue)
            timeSplit = hourSplit[1]
        }

        let minuteSplit = timeSplit.split("M")
        let minuteValue = undefined
        if (minuteSplit.length > 1){
            minuteValue = minuteSplit[0].replace(/\D/g, "");
            minuteValue = /^0*$/.test(minuteValue) ? undefined :  parseFloat(minuteValue)
            timeSplit = minuteSplit[1]
        }

        let secondSplit = timeSplit.split("S")
        let secondValue = undefined
        if (secondSplit.length > 1){
            secondValue = secondSplit[0].replace(/\D/g, "");
            secondValue = /^0*$/.test(secondValue) ? undefined :  parseFloat(secondValue)
            timeSplit = secondSplit[1]
        }
        return [yearValue, monthValue, dayValue, hourValue, minuteValue, secondValue]
    }

    const handleValueChange = (newValue, valueString, valueType) => {
        let isoTimeDelta = null
        let yearString = ""
        let monthString = ""
        let dayString = ""
        let hourString = ""
        let minuteString = ""
        let secondString = ""

        let newValueIndex = ["year","month","day","hour","minute","second"].indexOf(valueType)
        
        valueString[newValueIndex] = newValue

        yearString = valueString[0] == null ? "" : String(valueString[0]) + "Y"
        monthString = valueString[1] == null ? "" : String(valueString[1]) + "M"
        dayString = valueString[2] == null ? "" : String(valueString[2]) + "D"

        hourString = valueString[3] == null ? "" : String(valueString[3]) + "H"
        minuteString = valueString[4] == null ? "" : String(valueString[4]) + "M"
        secondString = valueString[5] == null ? "" : String(valueString[5]) + "S"

        if (yearString !== "" ||
            monthString !== "" ||
            dayString !== "" ||
            hourString !== "" ||
            minuteString !== "" ||
            secondString !== "") {
                if (hourString !== "" ||
                    minuteString !== "" ||
                    secondString !== "") {
                    isoTimeDelta = "P" + yearString + monthString + dayString + "T" + hourString + minuteString + secondString
                }
                else{
                    isoTimeDelta = "P" + yearString + monthString + dayString
                }
        }
        valueChangeFunction(isoTimeDelta)

    }

    return (
        <div className="internalCardContainer" style={{textAlign:"left", display:"flex", flexDirection: "column"}}>
            {label != null ?
            <div style={{width:"80%", margin:"auto"}}>
                <p className={["labelText", "normalMediumSize"].join(" ")} style={{ marginBottom:"0px", marginTop:"10px", width: "100%", textAlign: "center"}}>{label}</p>
            </div> : null}
            <div style={{width:"80%", textAlign:"center", display:"flex", flexDirection: "rows", margin:"3px auto"}}>
            <NumberFormat
                        customInput={TextField}
                        style={{textAlign:"center", width:"20%", marginRight:"auto"}}
                        type="text"
                        size="small"
                        className="inputTextbox"
                        decimalSeparator={","}
                        decimalScale = {0}
                        fixedDecimalScale = {true}
                        isAllowed= {(values) => {
                            const {floatValue} = values;
                            return (floatValue <= 99999 && floatValue >= -99999) || floatValue == null;
                        }}
                        error={error}
                        label={t(fieldLabels[0])}
                        value={fromISOStringToList(value)[0] ?? ""}                        
                        onValueChange={(e) => handleValueChange(e.floatValue, fromISOStringToList(value), "year")}
                        InputProps={{
                            readOnly: read_only
                          }}
                />
            <NumberFormat
                        customInput={TextField}
                        style={{textAlign:"center", width:"20%", marginRight:"auto"}}
                        type="text"
                        size="small"
                        className="inputTextbox"
                        decimalSeparator={","}
                        decimalScale = {0}
                        fixedDecimalScale = {true}
                        isAllowed= {(values) => {
                            const {floatValue} = values;
                            return (floatValue <= 99999 && floatValue >= -99999) || floatValue == null;
                        }}
                        error={error}
                        label={t(fieldLabels[1])}
                        value={fromISOStringToList(value)[1] ?? ""}                        
                        onValueChange={(e) => handleValueChange(e.floatValue, fromISOStringToList(value), "month")}
                        InputProps={{
                            readOnly: read_only
                          }}
                />
            <NumberFormat
                        customInput={TextField}
                        style={{textAlign:"center", width:"20%", marginRight:"auto"}}
                        type="text"
                        size="small"
                        className="inputTextbox"
                        decimalSeparator={","}
                        decimalScale = {0}
                        fixedDecimalScale = {true}
                        isAllowed= {(values) => {
                            const {floatValue} = values;
                            return (floatValue <= 99999 && floatValue >= -99999) || floatValue == null;
                        }}
                        error={error}
                        label={t(fieldLabels[2])}
                        value={fromISOStringToList(value)[2] ?? ""}                        
                        onValueChange={(e) => handleValueChange(e.floatValue, fromISOStringToList(value), "day")}
                        InputProps={{
                            readOnly: read_only
                          }}
                />
            </div>
            <div style={{width:"80%", textAlign:"center", display:"flex", flexDirection: "rows", margin:"3px auto"}}>
            <NumberFormat
                        customInput={TextField}
                        style={{textAlign:"center", width:"20%", marginRight:"auto"}}
                        type="text"
                        size="small"
                        className="inputTextbox"
                        decimalSeparator={","}
                        decimalScale = {0}
                        fixedDecimalScale = {true}
                        isAllowed= {(values) => {
                            const {floatValue} = values;
                            return (floatValue <= 99 && floatValue >= -99) || floatValue == null;
                        }}
                        error={error}
                        label={t(fieldLabels[3])}
                        value={fromISOStringToList(value)[3] ?? ""}                        
                        onValueChange={(e) => handleValueChange(e.floatValue, fromISOStringToList(value), "hour")}
                        InputProps={{
                            readOnly: read_only
                          }}
                />
            <NumberFormat
                        customInput={TextField}
                        style={{textAlign:"center", width:"20%", marginRight:"auto"}}
                        type="text"
                        size="small"
                        className="inputTextbox"
                        decimalSeparator={","}
                        decimalScale = {0}
                        fixedDecimalScale = {true}
                        isAllowed= {(values) => {
                            const {floatValue} = values;
                            return (floatValue <= 99 && floatValue >= -99) || floatValue == null;
                        }}
                        error={error}
                        label={t(fieldLabels[4])}
                        value={fromISOStringToList(value)[4] ?? ""}                        
                        onValueChange={(e) => handleValueChange(e.floatValue, fromISOStringToList(value), "minute")}
                        InputProps={{
                            readOnly: read_only
                          }}
                /> 
                <NumberFormat
                            customInput={TextField}
                            style={{textAlign:"center", width:"20%", marginRight:"auto"}}
                            type="text"
                            size="small"
                            className="inputTextbox"
                            decimalSeparator={","}
                            decimalScale = {0}
                            fixedDecimalScale = {true}
                            isAllowed= {(values) => {
                                const {floatValue} = values;
                                return (floatValue <= 99 && floatValue >= -99) || floatValue == null;
                            }}
                            error={error}
                            label={t(fieldLabels[5])}
                            value={fromISOStringToList(value)[5] ?? ""}                        
                            onValueChange={(e) => handleValueChange(e.floatValue, fromISOStringToList(value), "second")}
                            InputProps={{
                                readOnly: read_only
                            }}
                    />
            </div>
        </div>
    )
}



export default TimeDeltaTextField
