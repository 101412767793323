import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {getTableRowClass, getAgencyInfo, formatAsMoney }  from "../../../BillPayment/utils/utils";
import { Link } from "react-router-dom";
import moment from 'moment'
import {matchedFieldTranslation} from '../../utils/utils'

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
  },
  blueText: {
    fontFamily: "Open Sans",
    fontWeight: "600",
    color: "#0b1f82"
},
redText: {
    fontFamily: "Open Sans",
    fontWeight: "600",
    color: "#D91A1A"
},
  tableText: {
    fontFamily: "Open Sans",
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "center",
    margin: "10px",
    padding: "6px",
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: '#6F6F6F',
    fontSize: "14px",
    lineHeight: "19px",
    padding: "6px",
  }
}));

const getMatchedFields = (transactions) => {
    let matchedFieldsArray = []
    for(let transaction of transactions){
        matchedFieldsArray.push(Object.keys(transaction['highlight']))
    }
    for(let matchedField of matchedFieldsArray){
        if(matchedField.indexOf('company_key')>-1){
                let ckIndex = matchedField.indexOf('company_key')
                matchedField.splice(ckIndex,1)
        }
    }
    return matchedFieldsArray 
}

const getAgencies=(transaction_account_object)=>{
    let [institution_name] = getAgencyInfo(transaction_account_object)
    if(institution_name){
        return institution_name
    }else{
        return transaction_account_object.participant
    }
}

function BillPaymentSearchTable(props) {

    const matchedFieldsArray = getMatchedFields(props.historic)
    
    const classes = useStyles();
    useTheme();

    return (
        <Paper className={classes.tableStyle}>
        <Table style={{ padding: "8px"}}>
            <TableHead>
            <TableRow>
                <TableCell className={classes.tableHeader} style={{ width: "5%" }}>ID</TableCell>
                <TableCell className={classes.tableHeader} style={{ width: "14%" }}>Valor</TableCell>
                <TableCell className={classes.tableHeader} style={{ width: "21%" }}>Nome Pagador</TableCell>
                <TableCell className={classes.tableHeader} style={{ width: "13%" }}>Doc Pagador</TableCell>
                <TableCell className={classes.tableHeader} style={{ width: "21%" }}>Instituição Pagador</TableCell>
                <TableCell className={classes.tableHeader} style={{ width: "13%" }}>Data de Pagamento</TableCell>
                <TableCell className={classes.tableHeader} style={{ width: "13%" }}>Campo encontrado</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {props.historic.map((bill_payment,index) => (
                <TableRow
                key={index}
                className={getTableRowClass(index)}
                > 
                <TableCell
                    className={[classes.tableText, classes.blueText, "link", "idOverflow"].join(" ")}
                >
                    <Link
                        className="link"
                        to={"/bill-payment/" + bill_payment.bill_payment_key}
                    >
                        {bill_payment.id}
                    </Link>
                </TableCell>
                <TableCell className={classes.tableText}>
                    {formatAsMoney(bill_payment.amount, "-")}
                </TableCell>
                <TableCell className={classes.tableText}>
                    <div className={
                        matchedFieldsArray[index].includes("payer.name")
                        ? "blueTextSearchAny"
                        : null}
                    >
                        {bill_payment.payer.name}
                    </div>
                </TableCell>
                <TableCell className={classes.tableText}>
                    <div className={
                        matchedFieldsArray[index].includes("payer.document_number")
                        ? "blueTextSearchAny"
                        : null}
                    >
                        {bill_payment.payer.document_number}
                    </div>
                </TableCell>
                <TableCell className={classes.tableText}>
                    <div className={
                        matchedFieldsArray[index].includes("payer.account.participant")
                        ? "blueTextSearchAny"
                        : null}
                    >
                        {getAgencies(bill_payment.payer.account)}
                    </div>
                </TableCell>
                <TableCell className={classes.tableText}>
                    {moment(bill_payment.bill_payment_date).format("DD/MM/YYYY HH:mm")}
                </TableCell>
                <TableCell className={classes.tableText}>
                    {matchedFieldsArray[index].map(matchedField => matchedFieldTranslation(matchedField)).join(", ")}
                </TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        </Paper>
    );
}

export default BillPaymentSearchTable;
