import React from "react";
import AnalysisStatus from "./AnalysisStatus/AnalysisStatus";
import { CircularProgress } from "@material-ui/core";
import Reason from "./Reason/Reason";
import Suggestion from "../Suggestion/Suggestion";
import Timer from "./Timer/Timer";

function AnalysisStatusBox(props) {
  let {
    totalSeconds,
    showUpgradeStatus,
    upgrade_status,
    fraud_status,
    manual_analysis_limit_date,
    manual_analysis_reason,
    time_reason,
    suggestion,
  } = props;

  if (
    (fraud_status === "pending" || fraud_status === "in_manual_analysis") &&
    manual_analysis_limit_date
  ) {
    if (totalSeconds > 0) {
      let remaining_minutes = Math.floor(totalSeconds / 60);
      let remaining_seconds = totalSeconds % 60;
      return (
        <div className="analysisCard">
          <div
            className={["blueText", "subtitleSize"].join(" ")}
            style={{ paddingBottom: "10px" }}
          >
            Status da Análise
          </div>
          <Timer
            ra_limit={manual_analysis_limit_date}
            time={{ minutes: remaining_minutes, seconds: remaining_seconds }}
          />
          <AnalysisStatus
            name="Upgrade"
            status={
              showUpgradeStatus && upgrade_status !== "in_manual_analysis"
                ? upgrade_status
                : "not_applied"
            }
          />
          <Reason
            manual_analysis_reason={manual_analysis_reason}
            time_reason={time_reason}
          />
          <Suggestion suggestion={suggestion} />
        </div>
      );
    } else if (totalSeconds != null) {
      return (
        <div className="analysisCard">
          <div
            className={["blueText", "subtitleSize"].join(" ")}
            style={{ paddingBottom: "10px" }}
          >
            Status da Análise
          </div>
          <AnalysisStatus name="Aluguel" status="approved_by_time" />
          <AnalysisStatus
            name="Upgrade"
            status={
              showUpgradeStatus && upgrade_status !== "in_manual_analysis"
                ? upgrade_status
                : "not_applied"
            }
          />
          <Reason
            manual_analysis_reason={manual_analysis_reason}
            time_reason={time_reason}
          />
          <Suggestion suggestion={suggestion} />
        </div>
      );
    } else {
      return (
        <div className="analysisCard" style={{ height: "100%" }}>
          <div style={{ margin: "auto" }}>
            <CircularProgress />
          </div>
        </div>
      );
    }
  } else {
    if (
      fraud_status === "automatically_reproved" ||
      fraud_status === "automatically_approved"
    ) {
      return (
        <div className="analysisCard">
          <div
            className={["blueText", "subtitleSize"].join(" ")}
            style={{ paddingBottom: "10px" }}
          >
            Status da Análise
          </div>
          <AnalysisStatus name="Aluguel" status={fraud_status} />
          <AnalysisStatus
            name="Upgrade"
            status={showUpgradeStatus ? upgrade_status : "not_applied"}
          />
          <Reason
            automatic_analysis_reason={manual_analysis_reason}
          />
          <Suggestion suggestion={suggestion} />
        </div>
      );
    } else {
      return (
        <div className="analysisCard">
          <div
            className={["blueText", "subtitleSize"].join(" ")}
            style={{ paddingBottom: "10px" }}
          >
            Status da Análise
          </div>
          <AnalysisStatus name="Aluguel" status={fraud_status} />
          <AnalysisStatus
            name="Upgrade"
            status={showUpgradeStatus ? upgrade_status : "not_applied"}
          />
          <Reason
            manual_analysis_reason={manual_analysis_reason}
            time_reason={time_reason}
          />
          <Suggestion suggestion={suggestion} />
        </div>
      );
    }
  }
}

export default AnalysisStatusBox;
