import React, { useState, useEffect } from "react"
import Collapse from "@material-ui/core/Collapse"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp"
import IconButton from "@material-ui/core/IconButton"
import { useTranslation } from "react-i18next"
import ErrorBoundary from "../../../Utils/ErrorBoundary"
import ProtestsCard from "../ProtestsCard/ProtestsCard"
import { CCFCard } from "../CCFCard";
import { NegativeAnnotationsCard } from "../NegativeAnnotations/NegativeAnnotationsCard";
import { InquiriesCard } from "../Inquiries/InquiriesCard";
import DataField from "../../utils/DataField"

export const PartnersCard = (props) => {

    const { t } = useTranslation()

    const [expanded, setExpanded] = useState(true)
    const [expandedPartner, setExpandedPartner] = useState(false)

    const { partners_data, isPDF } = props

    const handleExpand = (event) => {
        event.stopPropagation()
        setExpanded(!expanded)
    }

    const handleExpandPartner = (event) => {
        event.stopPropagation()
        setExpandedPartner(!expandedPartner)
    }

    useEffect(()=>{
        if(isPDF){
            setExpanded(true)
            setExpandedPartner(true)
        }
    }, [isPDF])

    return (
        <div className="avoidPageBreakInside analysisCard">
            <div style={{ display: "flex" }}>
                <div
                    className={["blueText", "subtitleSize", "bold"].join(" ")}
                    style={{ margin: "auto 0px" }}
                >
                    {t("Sócios")}
                </div>
                <div style={{ margin: "auto 10px" }}>
                </div>
                <IconButton style={{ marginLeft: "auto" }} onClick={handleExpand}>
                    {expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </IconButton>
            </div>
            {partners_data.map((partner, index) => (
                <Collapse key={index}in={expanded} timeout="auto" unmountOnExit>
                    <div className={["normalText", "normalMediumSize", "internalCardContainer"].join(" ")}>
                        <div className="analysisCard">
                            <div style={{display: 'flex', justifyContent: 'space-around', alignItems: 'start'}}> 
                                <div style={{display:"flex", flexDirection:"column"}}> 
                                    <div className="blueText subtitleSize" style={{marginBottom:"10px"}}>
                                        Resumo
                                    </div>
                                    {partner.document_number&&
                                    <DataField
                                        title={t("document_number")}
                                        label={partner.document_number}
                                        size={"normalMediumSize"}
                                        titleNoWrap={false}
                                    />}
                                    {partner.overal_record_status&&
                                    <DataField
                                        title={t("status")}
                                        label={partner.overal_record_status}
                                        size={"normalMediumSize"}
                                        titleNoWrap={false}
                                    />}
                                    {partner.positive_record_status&&
                                    <DataField
                                        title={t("positive_record_status")}
                                        label={partner.positive_record_status}
                                        size={"normalMediumSize"}
                                        titleNoWrap={false}
                                    />}
                                </div>
                                {!isPDF&&
                                <IconButton style={{ marginLeft: "auto" }} onClick={handleExpandPartner}>
                                    {expandedPartner ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                </IconButton>}
                            </div>
                            <Collapse in={expandedPartner} timeout="auto" unmountOnExit>
                                {partner?.protests?
                                    <div className="analysisCardContainer">
                                        <ErrorBoundary>
                                            <ProtestsCard protests={partner.protests} isPDF={isPDF} isQuod={true} />
                                        </ErrorBoundary>
                                    </div> : null}
                                {partner?.ccf_annotations?
                                    <div className="analysisCardContainer">
                                        <ErrorBoundary>
                                            <CCFCard ccf_data={partner.ccf_annotations} isPDF={isPDF} />
                                        </ErrorBoundary>
                                    </div> : null}
                                {partner?.negative_annotations?
                                    <div className="analysisCardContainer">
                                        <ErrorBoundary>
                                            <NegativeAnnotationsCard negative_annotations={partner.negative_annotations} isPDF={isPDF} />
                                        </ErrorBoundary>
                                    </div> : null}
                                {partner?.inquiries?
                                    <div className="analysisCardContainer">
                                        <ErrorBoundary>
                                            <InquiriesCard inquiries={partner.inquiries} isPDF={isPDF} />
                                        </ErrorBoundary>
                                    </div> : null}
                            </Collapse>
                        </div>
                    </div>
                </Collapse>
            ))}
        </div>
    )
}
