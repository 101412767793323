import React, { useState, useReducer } from "react";
import uploadIcon from "../../../assets/cardEntryIcons/Upload.svg"
import { decisionReducer } from "../../Utils/requestUtils";
import { getConfirmationIconDialog, translateErrorMessage } from "../utils/utils";
import { CircularProgress } from '@material-ui/core';
import axios from "axios";

function InsertCSVContent(props) {
  const { userData, companyKey, person_type } = props

  const [uploadedFile, setUploadedFile] = useState(null)
  const [uploadedFileName, setUploadedFileName] = useState(null)
  const [customizedErrorMessage, setCustomizedErrorMessage] = useState("")
  const [fieldsErrorMessage, setFieldsErrorMessage] = useState([])
  const [fileErrorMessage, setFileErrorMessage] = useState(null);
  const hiddenFileInput = React.useRef(null);
  let fileReader

  const [dialogState, setDialogState] = useReducer(
    decisionReducer,
    { isLoading: false, isError: false, finished: false }
  )

  const onSubmit = () => {
    let payload = { base64_file: uploadedFile }
    setDialogState({ type: "send_request_init" })
    let requestHeaders = { headers: {} }
    if (userData.business_group_key) {
      requestHeaders = { headers: { "COMPANY-KEY": companyKey } }
    }
    axios.post(`/dash/onboarding/csv/${person_type}`, payload, requestHeaders).then(response => {
      setDialogState({ type: "send_request_success" })
    }).catch(error => {
      setDialogState({ type: "send_request_failure" })
      setCustomizedErrorMessage(error.response.data.description)
      setFieldsErrorMessage(error.response.data.message)
    });
  }

  const handleUploadedFile = (file) => {
    if (file.type !== "text/csv") {
      setFileErrorMessage("O arquivo deve ser um CSV válido.");
      setUploadedFile(null);
      setUploadedFileName(null);
      return;
    }

    setFileErrorMessage(null);
    fileReader = new FileReader()
    fileReader.onloadstart = setUploadedFile(undefined)
    fileReader.onloadend = handleFileRead
    fileReader.readAsArrayBuffer(file)
    setUploadedFileName(file.name)
  }

  const getFileUploadMessage = (file) => {
    let message
    if (file === null) {
      message = "Para enviar um arquivo .csv basta anexá-lo clicando no botão abaixo."
    }
    else if (file === undefined) {
      message = "Carregando Arquivo"
    }
    else {
      message = uploadedFileName
    }
    return message
  }

  const handleFileRead = (e) => {
    const content = fileReader.result;
    let binary = '';
    let bytes = new Uint8Array(content);
    let len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    setUploadedFile(btoa(binary))
  }

  const handleUploadButtonClick = e => {
    hiddenFileInput.current.click();
  };

  if (dialogState.finished && dialogState.isLoading) {
    return (
      <div>
        <CircularProgress style={{ margin: "auto" }} />
      </div>
    )
  }
  else if (dialogState.finished){
      let confirmationData = {
          message: dialogState.message,
          success: !dialogState.isError
      }
      return (
        <div style={{height: 180}}>
            <div className="internalCardContainer" style={{display:"flex", height:"12%", justifyContent:"center"}}>
                {getConfirmationIconDialog(confirmationData.success)}
            </div>
            <div className="internalCardContainer" style={{display:"flex", textAlign:"center", justifyContent:"center", margin: 0}}>
                {confirmationData.success ? confirmationData.message : translateErrorMessage(customizedErrorMessage)}
            </div>
            {!confirmationData.success && Array.isArray(fieldsErrorMessage) && fieldsErrorMessage.map(field => (
              <div className={["internalCardContainer"]} style={{display:"flex", textAlign:"center", justifyContent:"center", margin: "0"}} key={field}>
                <p className="redText" style={{marginBlockEnd: 8, marginBlockStart: 8}}>"{field}"</p></div>
            ))}
        </div>
      )
  }
  else {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className={["normalMediumSize"].join(" ")} style={{ marginTop: "40px", marginBottom: "20px", textAlign: "center" }}>
          {getFileUploadMessage(uploadedFile)}
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className={["button", "blueText", "dialogInfoSize"].join(" ")} style={{ margin: "auto", textAlign: "center" }} onClick={handleUploadButtonClick}>
            <img className="cardTitleIconStyle" style={{ marginRight: "10px" }} src={uploadIcon} alt="uploadIcon Logo" />
            {uploadedFile === null ? "Anexar Arquivo" : "Substituir Arquivo"}
          </div>
          <input
            type="file"
            accept=".csv"
            ref={hiddenFileInput}
            onChange={e => handleUploadedFile(e.target.files[0])}
            style={{ display: "none" }}
          />
        </div>
        {fileErrorMessage && (
          <div style={{ color: 'red', textAlign: 'center', marginTop: '10px' }}>
            {fileErrorMessage}
          </div>
        )}
        <div onClick={uploadedFile == null ? null : onSubmit}
          style={{ display: "flex", marginTop: "20px", marginLeft: "auto", cursor: "pointer", paddingLeft: "20px", paddingRight: "20px", fontWeight: "600" }}
          className={uploadedFile == null ?
            ["button", "standard", "disabled", "normalText", "normalMediumSize"].join(" ") :
            ["button", "standard", "normalText", "normalMediumSize"].join(" ")}>
          ENVIAR
        </div>
      </div>
    )
  }
}

export default InsertCSVContent
