import React, { useEffect, useContext, useReducer } from "react";	
import {dataFetchReducer} from "../../Utils/requestUtils"
import { useParams } from "react-router-dom";
import axios from "axios";
import CardHolderAlert from "./CardHolderAlert/CardHolderAlert"
import MerchantAlert from "./MerchantAlert/MerchantAlert"
import ObservationCard from "./ObservationCard/ObservationCard"
import ErrorBoundary from "../../Utils/ErrorBoundary"
import AuthContext from "../../../context/auth-context"
import {useHistory} from "react-router-dom"

function Alert(props){
    let history = useHistory()

    let roles = useContext(AuthContext).user_data.roles
    if (!roles.includes("card_issuance_alert_viewer")){
        history.push("")
    }

    let { alert_key } = useParams();

    const [alert, dispatchAlert] = useReducer(
        dataFetchReducer,
        {fetchedData: null, isLoading: true, isError: false} 
    )

    useEffect(() => {
		dispatchAlert({type: "data_fetch_init"})
		const timer = setTimeout(() => {
			axios.get('/dash/card_issuance_backoffice/alert/' + alert_key).then( response => {
                dispatchAlert({
                    type: "data_fetch_success",
                    payload: response.data
                })   
			}).catch(error => {
                if ((error.response || {}).status === 403) dispatchAlert({type: "data_fetch_failure_403"})
                else if ((error.response || {}).status === 404) dispatchAlert({type: "data_fetch_failure_404"})  
                else dispatchAlert({type: "data_fetch_failure"})    
			})	
		}, 500);
		return () => {
			clearTimeout(timer)
        }
    }, [alert_key])

    if (alert.fetchedData){
        if (alert.fetchedData.link_key_type === "cardholder"){
            return (
				<div style={{minWidth:"900px", paddingBottom: "30px"}}>
                    <ErrorBoundary>
                        <CardHolderAlert alert={alert.fetchedData}/>
                    </ErrorBoundary>
					<div className="analysisCardContainer">
                        <ErrorBoundary>
                            <ObservationCard alert={alert.fetchedData}/>
                        </ErrorBoundary>			
					</div>	
				</div>
			)
        }
        else if (alert.fetchedData.link_key_type === "merchant"){
			return (
				<div style={{minWidth:"900px", paddingBottom: "30px"}}>
                    <ErrorBoundary>
					    <MerchantAlert alert={alert.fetchedData}/>
                    </ErrorBoundary>
					<div className="analysisCardContainer">
                        <ErrorBoundary>
						    <ObservationCard alert={alert.fetchedData}/>
                        </ErrorBoundary>
					</div>	
				</div>
			)
        }
        else return null
    }
    else if(alert.isError){
		return (
			<div style={{height: "80vh", display:"flex"}}>
				<p className={["blueText", "titleSize"].join(" ")} style={{margin:"auto", textAlign:"center"}}>
					{alert.errorMessage}
				</p>
			</div>
		)
	}
  
  return null;
}

export default Alert