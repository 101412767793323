import React from 'react'
import moment from 'moment'
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from '@material-ui/icons/Clear';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import redCross from '../../../assets/cardEntryIcons/redCross.svg';
import greenCheck from '../../../assets/cardEntryIcons/greenCheck.svg';

export const formatAsMoney = (value, nullCase=null) => {
    if (value == null) return nullCase
    return ("R$ " + (value/100).toLocaleString('de-DE', {minimumFractionDigits: 2}))    
}

export const getTableRowClass = (rowIndex) => {
    if (rowIndex % 2 === 0) {
      return "even";
    }
  
    return "odd";
  }

export const titleCase = (str, nullCase) => {
    if (str == null) {
      return nullCase
    }
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  };

export function removeDuplicatesBy(keyFn, array) {
    var mySet = new Set();
    return array.filter(function(x) {
        var key = keyFn(x), isNew = !mySet.has(key);
        if (isNew) mySet.add(key);
        return isNew;
    });
}

export function replaceAll(str,mapObj){
  var re = new RegExp(Object.keys(mapObj).join("|"),"gi");

  return str.replace(re, function(matched){
      return mapObj[matched.toLowerCase()];
  });
}

export const getAnalysisStatus = (status) => {
    switch(status){
        case "pending":
        case "in_manual_analysis":
          	return "Pendente";
        case "manually_approved":
            return ("Aprovado Manualmente");
        case "automatically_approved":
            return ("Aprovado Automaticamente");
        case "manually_reproved":
            return ("Reprovado Manualmente");
        case "automatically_reproved":
            return ("Reprovado Automaticamente");
        case "approved_by_time":
            return "Aprovado por tempo";
        case "not_analised":
            return "Não analisado";
        case "automatically_challenged":
            return "Contestado Automaticamente"
        case "manually_challenged":
            return "Contestado Manualmente"
        default:
            return status;
    }
}
  
export const getAnalysisStatusSiglum = (status) => {
    switch(status){
        case "pending":
        case "in_manual_analysis":
          	return "P";
        case "manually_approved":
        case "manually_reproved":
        case "manually_challenged":
        
            return "M";
        case "automatically_approved":
        case "automatically_reproved":
        case "automatically_challenged":

            return "A";
        case "approved_by_time":
            return "T";
        case "not_analised":
            return "NA";
        default:
            return "?";
    }
}


export const getAnalysisStatusColor = (status) => {
    switch(status){
        case "automatically_approved":
        case "manually_approved":
        case "approved_by_time":
            return colorGreen
        case "automatically_reproved":
        case "manually_reproved":
            return colorRed
        case "manually_challenged":
        case "automatically_challenged":
            return colorYellow
        default:
            return colorGrey
    }
}

export const formatAddress = (merchant, nullCase) => {
    if (merchant == null) return nullCase
    let street = titleCase(merchant.street, "")
    let city = titleCase(merchant.city, "")
    let number = merchant.number ? merchant.number : ""
    let uf = merchant.uf ? merchant.uf : ""
    let country = merchant.region === "BRA"? ", Brasil" : ""

    let addressString = number !== "" ? street + ", " + number : street
    addressString = city !== "" ? addressString +  ", " + city : addressString
    addressString = uf !== "" ? addressString +  " - " + uf : addressString
    addressString = addressString + country
    return (addressString)
  }
  
  export const formatPhone  = (phoneObj,nullCase) => {
      if (phoneObj == null) return nullCase
  
      let ddiString = phoneObj.internation_dial_code == null ? "" : phoneObj.internation_dial_code + " "
      let dddString = phoneObj.area_code == null ? "" : "(" + phoneObj.area_code + ") "
      let numberString = phoneObj.number == null ? "" : phoneObj.number
  
      return ddiString + dddString + numberString
  
  }

  export const getConfirmationIcon = (status) => {
    if (status){
        return (<CheckIcon className="fraudStatusIcon" style={{color: colorGreen, width:"40px", height:"40px"}}/>)
    }
    else{
        return (<ClearIcon className="fraudStatusIcon" style={{color: colorRed, width:"40px", height:"40px"}}/>)
    }
  }

  export const getAlertsFlagIcon = (flag, margin=null) => {
    let margin_ = "auto"
    if (margin !=null ){
        margin_ = margin
    }
    switch(flag){
        case "positive":
            return (<CheckIcon style={{verticalAlign: "middle", width:"20px", height:"20px", color:colorGreen, marginTop:"auto",marginBottom:"auto", marginRight:margin_,marginLeft:margin_}}/>)
        case "negative":
            return (<ErrorOutlineIcon style={{verticalAlign:"middle", width:"20px", height:"20px", color:colorRed, marginTop:"auto",marginBottom:"auto", marginRight:margin_,marginLeft:margin_}}/>)
        case "neutral":
        case "attention":
            return(<ErrorOutlineIcon style={{verticalAlign:"middle", width:"20px", height:"20px", color:colorYellow, marginTop:"auto",marginBottom:"auto", marginRight:margin_,marginLeft:margin_}}/>)
        default:
            return(<ErrorOutlineIcon style={{verticalAlign:"middle", width:"20px", height:"20px", color:colorGrey, marginTop:"auto",marginBottom:"auto", marginRight:margin_,marginLeft:margin_}}/>)
    }
  }
  export const getBoolIcon = (bool) => {
    if (bool == null) return null
    if (bool){
      return (
        <img src={greenCheck} alt="GreenCheck Logo" />      
      )
    }
    else {
      return (
        <img src={redCross} alt="redCross Logo" />    
      )
    }
  }

  export const getAlertsFlagColor = (flag) => {
    switch(flag){
        case "positive":
            return colorGreen
        case "negative":
            return colorRed
        case "attention":
        case "neutral":
            return colorYellow
        default:
            return colorGrey
    }
}
  export const clientAge = (birthdate, nullCase=null, showCase="years") => {
    if (birthdate == null) return nullCase
    let extraString
    let now = moment()
    let birthdate_ = moment(birthdate)
    
    let years = now.diff(birthdate_, 'year');
    let yearString = years ===1 ? years + " ano" : years + " anos"
    birthdate_.add(years, 'years');
    let months = now.diff(birthdate_, 'months');
    let monthString = months ===1 ? months + " mês" : months + " meses"
    birthdate_.add(months, 'months');
    switch(showCase){
        case "years_months":
            if (years === 0){
                extraString = " (" + months + " meses)"
            }
            else {
                extraString = " (" + yearString + " e " + monthString + ")"
            }
            break
        case "years":
            if (years === 0){
                extraString = " (Menos de um ano)"
            }
            else {
                extraString = " (" + yearString + ")"
            }
            break
        default:
            throw new Error()
    }
    return moment(birthdate).format('DD/MM/YYYY') + extraString
  };

export const setAllowChanges = (status, roles, role_to_be_verified) => {
    if (!roles.includes(role_to_be_verified)){
        return false
    }
    switch(status){
        case "pending":
        case "in_manual_analysis":
            return true
        default:
            return false
    }
}

export function getAgencyInfo(account_object){
    var branch_json = require("../../../assets/json/agencies.json");
    let institution_object
    let institution_name = null
    let branch_object = null
    if ((account_object || {}).participant){
        institution_object = branch_json[account_object.participant]
        institution_name = (institution_object || {}).institution_name
        if (account_object.branch){
            branch_object = ((institution_object || {}).agencies || {})[account_object.branch]
        }

    }
    return [institution_name,branch_object]
}

export function getBranchesInArrayFormat(){
    var branches_json = require("../../../assets/json/agencies.json");
    let branches = []
    for (let [ispb, branch] of Object.entries(branches_json)){
        branches.push({
            name: branch.institution_name,
            ispb: ispb
        });
    }

    branches.sort(function (a, b) {
        if (a.name > b.name) return 1; 
        else return -1;
      });
    
    let branchesInArrayFormat = []
    for(let branch of branches) {
        branchesInArrayFormat.push([branch.name, branch.ispb])
    }
    return branchesInArrayFormat;
}

export const colorGreen = '#73C580'
export const colorRed ='#D91A1A'
export const colorYellow = "#FFBB24"
export const colorOrange = '#F77B21'
export const colorVelvet ='#DD5C5C'
export const colorGrey = 'rgb(170, 167, 153)'


export const formatBureauInfo = (object, identifier) => {
    let formatFunction
    switch(identifier){
        case "endereços":
            formatFunction = formatAddress
            break
        case "telefones":
            formatFunction = formatPhone
            break
        default:
    }
    return (
        <div>
            Outros {identifier}:
            <ul>
                {object.map((item,index) => (
                    <li key={index}>
                        {formatFunction(item)} - {item.description}
                    </li>
                ))}
            </ul>
        </div>
    )
}
