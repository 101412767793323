import React from "react"
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import InformationIcon from '@material-ui/icons/ErrorOutline';
import {colorRed} from "../../utils/utils";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    textAlign: "center",
    marginBottom: "10px",
    float: "left",
  }
}));

function NoReadAccessDialog(props) {
  const classes = useStyles();
  useTheme();

  return (
        <Dialog open={props.open} onClose={props.onClose} keepMounted={false}>
            <DialogContent className={classes.dialogContent}>
                <div style={{ width: "500px", height:"280px"}}>
                <div className="internalCardContainer" style={{display:"flex", height:"40%", justifyContent:"center"}}>
                      <InformationIcon style={{fontSize:"80px",fill: colorRed}}/>
                  </div>
                    <div className="internalCardContainer" style={{display:"flex", textAlign:"center", justifyContent:"center", flexDirection:"column"}}>
                        <p style={{margin:0, fontSize:"18px"}}><b>Acesso negado</b></p>
                        <p>Você não possui permissão de leitura para esta lista.</p>            
                    </div>
                    <div className="internalCardContainer" style={{display:"flex", justifyContent:"center"}}>
                        <div 
                            className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                            onClick={props.onClose}
                        >
                            VOLTAR À TELA INICIAL 
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default NoReadAccessDialog;