import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import LocalPhoneIcon from "@material-ui/icons/LocalPhone";
import Tooltip from "@material-ui/core/Tooltip";
import EmailIcon from '@material-ui/icons/Email';
import RoomIcon from "@material-ui/icons/Room";
import ContactsIcon from "@material-ui/icons/Contacts";
import moment from "moment"
import {historicIntoTableObject, getTableRowClass} from "../../../utils/utils"

const useStyles = makeStyles((theme) => ({
    tableStyle: {
      border: "none",
      boxShadow: "none",
      width:"100%"
    },
    blueText: {
      fontFamily: "Open Sans",
      fontWeight: "600",
      color: "#0b1f82"
  },
    tableText: {
      fontFamily: "Open Sans",
      fontSize: "14px",
      lineHeight: "19px",
      textAlign: "center",
      margin: "10px",
      padding: "6px",
    },
    smallText: {
        fontFamily: "Open Sans",
        fontSize: "12px",
        lineHeight: "16px",
        textAlign: "center",
        margin: "10px",
        padding: "6px",
      },
    link:{
        cursor: "pointer",
        "&:hover": {
            textDecoration: "underline",
          },
    },
    tableHeader: {
      textAlign: "center",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: "normal",
      color: '#6F6F6F',
      fontSize: "16px",
      lineHeight: "22px",
      padding: "6px",
    }
}));

function HistoryDialog (props) {
    const classes = useStyles();
    useTheme();

    let tableObject = historicIntoTableObject(props.historicalData, null)

    return (
        <Dialog
            open={props.open} 
            onClose={props.onClose} 
            keepMounted={false}
        >
          <DialogTitle className={["blueText", "subtitleSize"].join(" ")}>Histórico</DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <Paper className={classes.tableStyle}>
                <Table style={{ padding: "8px" }}>
                    <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableHeader}>Aluguel</TableCell>
                        <TableCell className={classes.tableHeader}>Data e Hora</TableCell>
                        <TableCell className={classes.tableHeader}>
                            <Tooltip title={<p className="tooltipText">Validação de Telefone</p>}><LocalPhoneIcon /></Tooltip>
                        </TableCell>
                        <TableCell className={classes.tableHeader}>
                            <Tooltip title={<p className="tooltipText">Validação de Código Postal</p>}><RoomIcon /></Tooltip>
                        </TableCell>
                        <TableCell className={classes.tableHeader}>
                            <Tooltip title={<p className="tooltipText">Validação de Documento</p>}><ContactsIcon /></Tooltip>
                        </TableCell>
                        <TableCell className={classes.tableHeader}>
                            <Tooltip title={<p className="tooltipText">Validação de E-mail</p>}><EmailIcon /></Tooltip>
                        </TableCell>
                        <TableCell className={classes.tableHeader}></TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {tableObject.tableData.map((rowData,index) => (
                        <TableRow
                        key={index}
                        className={getTableRowClass(index)}
                        >
                        <TableCell 
                            className={[classes.tableText, classes.blueText, classes.link].join(" ")} 
                        >
                            <Link className="link" to={"/rental-agreement/" + rowData.rental_agreement_key}>
                                Aluguel #{rowData.rental_agreement_code}
                            </Link>
                        </TableCell>
                        <TableCell className={classes.tableText}>
                            {moment(rowData.rental_agreement_date).format("DD/MM/YYYY -HH:mm")}
                        </TableCell>
                        <TableCell className={classes.tableText}>
                            {rowData.phoneCheckIcon}
                        </TableCell>
                        <TableCell className={classes.tableText}>
                            {rowData.locationCheckIcon}
                        </TableCell>
                        <TableCell className={classes.tableText}>
                            {rowData.documentCheckIcon}
                        </TableCell>
                        <TableCell className={classes.tableText}>
                            {rowData.emailCheckIcon}
                        </TableCell>
                        <TableCell className={classes.tableText}>
                            {rowData.statusIcon}
                        </TableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        <TableCell className={[classes.tableText, classes.blueText].join(" ")} >Total</TableCell>
                        <TableCell></TableCell>
                        <TableCell className={[classes.tableText, classes.blueText].join(" ")}>
                        {tableObject.phoneCheck}
                        </TableCell>
                        <TableCell className={[classes.tableText, classes.blueText].join(" ")}>
                        {tableObject.locCheck}
                        </TableCell>
                        <TableCell className={[classes.tableText, classes.blueText].join(" ")}>
                        {tableObject.documentCheck}
                        </TableCell>
                        <TableCell className={[classes.tableText, classes.blueText].join(" ")}>
                        {tableObject.emailCheck}
                        </TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    </TableBody>
                </Table>
            </Paper>
          </DialogContent>
        </Dialog>
      );
}

export default HistoryDialog